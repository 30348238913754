export const details = [
  {
    title: "PV-Planung mit unserem kollaborativen Online-Tool",
    content:
      "Plane deine PV-System selbständig, mit Freunden, der SolarHub Community oder gemeinsam mit unseren Solarexperten genau nach deinen Bedürfnissen.​",
  },
  {
    title: "Sektor-Kopplung und E-Mobilität: ​",
    content:
      "Wir beraten dich gerne zum Thema Batteriespeicher, Wärmepumpe oder E-Ladestation. Dabei verfolgen wir immer einen ganzheitlichen Ansatz und betrachten das gesamte Gebäude. Denn jede Ausgangslage und Zielsetzung ist individuell.​",
  },
  {
    title: "Förderungen",
    content:
      "Wir beraten dich zu Fördermöglichkeiten und stehen bei der Antragsstellung mit Rat und Tat zur Seite.​",
  },
  {
    title: "Visualisierung",
    content:
      "Alle  unsere Systeme beinhalten eine Visualisierungslösung zur Darstellung des erzeugten Stroms. Damit behältst du immer den Überblick und kannst dich jeden Tag aufs Neue an deinem umweltfreundlichen Strom erfreuen.​",
  },
  {
    title: "Vor-Ort Besuch",
    content:
      "Vor der Installation der Anlage kommt der ausführende Installateur bei dir vorbei. Dabei werden die elektrischen Anschlussmöglichkeiten und spezifischen Gegebenheiten geklärt.​",
  },
  {
    title: "Absturzsicherung",
    content:
      "Bei Arbeiten auf dem Dach ist immer auch eine ordnungsgemäße Absturzsicherung einzurichten, sofern eine Höhe von 2m überschritten wird. Diese besteht je nach örtlichen Gegebenheiten entweder aus einem Gerüst oder einer Auffangeinrichtung. ​",
  },
  {
    title: "Anlieferung der Komponenten:​",
    content:
      "Die Lieferung der Komponenten erfolgt i.d.R. 1-3 Werktage vor der Montage. Dafür benötigst Du einen Abstellplatz für 1 Palette mit Modulen (max. 2 m x 1,5 m) und etwas Platz für den Wechselrichter und die Unterkonstruktion.​",
  },
  {
    title: "Montage der PV-Module inkl. Unterkonstruktion​",
    content:
      "Als erstes wird die Unterkonstruktion passgenau angebracht, auf der später die Module installiert werden. Hierbei werden sogenannte Dachhaken auf den Sparren montiert, auf die wiederum lange Schienen befestigt werden. Nachdem die Unterkonstruktion montiert ist werden nun die Module Stück für Stück angebracht.​",
  },
  {
    title: "Elektrischer Anschluss der Anlage​",
    content:
      "Hier werden die elektrischen Verbindungen deiner PV hergestellt. Eine Elektrofachkraft prüft noch einmal ob die Verkabelungen korrekt durchgeführt wurden und verbindet dann die Module mit dem Wechselrichter.​   ",
  },
  {
    title: "Inbetriebnahme & Prüfprotokoll​",
    content:
      "Nachdem alles ordnungsgemäß montiert wurde kann deine Anlage in Betrieb gehen. Das bedeutet sie beginnt Strom zu produzieren. Dabei werden alle Funktionen in einem Prüfprotokoll dokumentiert, um die einwandfreie Funktion nachgewiesen.​",
  },
  {
    title: "Meldepflichten & Kommunikation mit dem Netzbetreiber​",
    content:
      "Wir wissen wie zeitintensiv diese Aufgaben für unsere Kunden sein können. Daher übernehmen wir gerne Antragstellung und Fertigmeldung der Solaranlage beim zuständigen Netzbetreiber und unterstützen bei der Meldung der Anlage bei der Bundesnetzagentur. ​",
  },
  {
    title: "Dokumentation​",
    content:
      "Du erhältst die vollständige Anlagendokumentation mit allen Unterlagen wie die Bedienungsanleitung, technische Datenblätter, Garantieurkunden, Herstellerzertifikate.​",
  },
];
