import React, { useState } from "react";
import styled from "styled-components";
import ladki from "Assets/community/ladki.svg";
import parse from "react-html-parser";
import menu from "Assets/community/menu.svg";
import sharee from "Assets/community/sharee.svg";
import heart from "Assets/community/heart.svg";
import like from "Assets/community/like.svg";
import { useSelector } from "react-redux";
import moment from "moment";

const InsideTopicPostWrapper = styled("div")`
  .inside_replymain {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    width: 560px;
  }
  .left_side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 36px;
      width: 36px;
    }
  }
  .right_side {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .allThings {
    display: flex;
    gap: 13px;
    height: 21px;
    align-items: center;
    img {
      cursor: pointer;
    }
    div {
      cursor: pointer;
    }
  }
  .imp {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .flex_text {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    color: #666666;
  }
  .sameText {
    font-weight: 600;
    font-size: 12px;
    color: #666666;
  }
  .other_same {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }
  .lastpart {
    width: 15px;
  }
`;

const PostCard = ({ data, key }) => {
  return (
    <InsideTopicPostWrapper>
      <div className="inside_replymain" key={key}>
        <div className="left_side">
          <img
            className="howare"
            src={
              data.userAvatar
                ? `http://community.solarhub24.de${data.userAvatar.replace(
                    "{size}",
                    "50"
                  )}`
                : ladki
            }
            style={{ marginRight: "10px" }}
            alt=""
          />
          <div className="line"></div>
        </div>
        <div className="right_side">
          <div className="flex_text">
            <span className="imp">{data.username}</span>
            {parse(data.description)}
          </div>
          <div className="allThings">
            <div className="sameText">
              {data.updateAt
                ? moment(new Date(data.updateAt)).startOf("hour").fromNow()
                : moment(new Date(data.createAt)).startOf("hour").fromNow()}
            </div>
            <div className="sameText">{data.likeCount} likes</div>
          </div>
        </div>
      </div>
    </InsideTopicPostWrapper>
  );
};

export default PostCard;
