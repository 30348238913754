import { Dialog, makeStyles } from "@material-ui/core";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Close from "Assets/Close.svg";
import Group from "../../../popups/SignUpSignIn/images/Group.svg";
import { colors } from "../../../colors";
import success from "Assets/application_step_1/success.svg";
import Error from "Assets/application_step_1/Error.svg";
import progress from "Assets/application_step_1/progress.svg";
import { useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { db } from "../../../firebase";

const PaymentStatusModalWrapper = styled("div")`
  .mainpart {
    width: 700px;
    height: 700px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mainpart img {
    height: 300px;
  }
  .text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 84px;
    color: #40d48f;
  }
  .text1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 84px;
    color: #ff0202;
  }
  .text2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 84px;
    color: #ffe080;
  }
`;

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
      background: "black",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: colors.ctaSolid,
      },
    },
  },
}));

const PaymentStatusModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const stripe = useStripe();

  const [status, setStatus] = useState("");
  const pid = useSelector((state) => state.project.pid);

  const checkStatusHandler = (paymentIntent) => {
    // console.log(paymentIntent, "paymentIntent");
    stripe
      .retrievePaymentIntent(paymentIntent?.client_secret)
      .then(function (response) {
        console.log(response, "response");
        if (
          response.paymentIntent &&
          response.paymentIntent.status === "succeeded"
        ) {
          // Handle successful payment here
          console.log("wow successss");
          setStatus("succeeded");
        } else {
          // Handle unsuccessful, processing, or canceled payments and API errors here
          setStatus(response.paymentIntent.status);
        }
      });
  };

  useEffect(() => {
    if (pid) {
      db.collection("saved_projects")
        .doc(pid)
        .get()
        .then((doc) => {
          checkStatusHandler(
            doc?.data()?.project_details?.payment?.paymentIntent
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open, pid]);

  const handleClose = () => {
    setOpen(false);
  };
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth={false}
        style={{ margin: "-15px" }}
      >
        <div style={{ display: "flex", width: "100%", position: "relative" }}>
          <img
            onMouseEnter={() => setWhichIconShouldBeVisible(true)}
            onMouseLeave={() => setWhichIconShouldBeVisible(false)}
            src={whichIconShouldBeVisible ? Close : Group}
            className={classes.icon}
            onClick={() => setOpen(false)}
          />
        </div>
        <PaymentStatusModalWrapper>
          <div className="mainpart">
            {status !== "" ? (
              status === "succeeded" ? (
                <img src={success} alt="" />
              ) : status === "processing" ? (
                <img src={progress} alt="" />
              ) : (
                <img src={Error} alt="" />
              )
            ) : (
              <img src={success} alt="" />
            )}
            {status !== "" ? (
              <div className="text">Your Payment is {status}</div>
            ) : (
              ""
            )}
          </div>
        </PaymentStatusModalWrapper>
      </Dialog>
    </div>
  );
};

export default PaymentStatusModal;
