import styled from "styled-components";
export const ProductCardWrapper = styled("div")`
  .procut_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 210px;
    img {
      height: 149.21739196777344px;
      width: 214px;
      border-radius: 12px;
    }
  }
  .mekeItCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 149px;
  }
  .secount_part {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    ul {
      padding: 0px;
      padding-left: 16px;
    }
    ul li {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #2d4764;
    }
  }
  .texts {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #2d4764;
  }
  .texts1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.005em;
    color: #2d4764;
  }
  .third_part {
    display: flex;
    align-items: flex-end;
    gap: 7px;
    height: 100%;
    flex-direction: column;
  }
  .down_text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 3px;
    line-height: 19px;
    text-align: right;
    color: #666666;
  }
  .reviews {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-decoration-line: underline;
    color: #2d4764;
  }
  .important {
    display: flex;
    justify-content: space-around;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }
  .selet_prdocut {
    background: #2d4764;
    height: 36.84379959106445px;
    width: 165px;
    font-size: 13px;
    border: 1px solid #2d4764;
    box-sizing: border-box;
    border-radius: 36px;
    color: #ffffff;
    :hover {
      background: #2d4764;
    }
  }
  .change_product {
    height: 40px;
    width: 165px;
    border-radius: 36px;
    border: 1px solid #304864;
    box-sizing: border-box;
    img {
      height: 12px;
      width: 12px;
    }
  }
  .upper_image {
    position: relative;
  }
  .helper_class {
    position: absolute;
    height: 30.26409px !important;
    width: 35px !important;
    border-radius: 0px !important;
    left: 98px;
    bottom: 15px;
  }
  .monileMain {
    display: flex;
    flex-direction: column;
    gap: 26px;
    align-items: center;
    padding: 10px;
  }
  .upper_contianer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 500px) {
    .procut_container {
      height: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 12px;
      justify-content: center;
      width: 95%;
      margin: auto;
      margin-bottom: 14px;
    }
    .procut_container img {
      height: 106px;
      width: 128.024689px;
      border-radius: 12px;
    }
    .change_product img {
      height: 12px;
      width: 12px;
    }
    .product_main {
      width: 95%;
    }
    .ant-rate {
      font-size: 10px;
    }
    .helper_class {
      left: 63px;
      bottom: -4px;
    }
  }
`;
