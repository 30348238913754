import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Accept from "./Assets/Accept";
import Ignore from "./Assets/Ignore";
import AcceptInvition from "pages/Application/community/accept-Invitaion/acceptInvitaion";
import axios from "axios";
import { db } from "../../../../../../firebase";
import ConfirmModal from "pages/Application/community/accept-Invitaion/ConfirmModal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

const MainContainer = styled("div")`
  width: 100%;
  min-height: 165px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  background: #ffffff;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const MainContainerContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const MainContainerDivide = styled("div")`
  width: 2px;
  background-color: #d0d0d0;
  height: 80%;
`;
const SubContainer = styled("div")`
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const SubContainer1 = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 52px;
  @media (max-width: 600px) {
    padding-bottom: 14px;
  }
  .firstButton {
    padding: 3px 0px;
    width: 76px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: #ffd75d;
    border-radius: 12.012px;
  }
  .secoundButton {
    padding: 3px 0px;
    width: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 27px;
    background: #ffffff;
    border: 1.05105px solid #2d4764;
    border-radius: 12.012px;
  }
`;
const RightContainerText = styled("div")`
  font-weight: 600;
  font-size: 14px;
  color: #2d4764;
  margin-top: 6px;
  cursor: pointer;
`;
const RightContainerText1 = styled("div")`
  font-weight: 600;
  font-size: 14px;
  color: #666666;
  margin-top: 6px;
  cursor: pointer;
`;
const PendingNotification = ({ data }) => {
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [userData, setUserData] = useState(null);
  const [chatState, setChatState] = useState(false);
  const currentUser_uid = useSelector((state) => state.user.currentUser.uid);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  useEffect(() => {
    if (data.duration === 0) {
      setChatState(true);
    } else {
      setChatState(false);
    }
    if (data.sender_uid) {
      db.collection("users")
        .doc(data.sender_uid)
        .get()
        .then((doc) => setUserData(doc.data()))
        .catch((error) => console.log(error));
    }
  }, [data]);

  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const ModalFlag = useSelector((state) => state?.notification?.flag);

  useEffect(() => {
    if (ModalFlag) {
      setIsModalVisible2(true);
    } else {
      setIsModalVisible2(false);
    }
  }, [ModalFlag]);

  const onClickHandler = async () => {
    if (data.duration === 0) {
      try {
        const result = await axios.post(
          "https://solarhub24.herokuapp.com/SolarHub-Discourse/invitation/accpetInvitation",
          {
            description: data.description,
            sender_uid: data.sender_uid,
            receiver_uid: currentUser_uid,
          }
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsModalVisible1(true);
    }
  };

  const onClickHandlerDecline = async () => {
    try {
      const result = await axios.post(
        "https://solarhub24.herokuapp.com/SolarHub-Discourse/invitation/declineInvitation",
        {
          sender_uid: data.sender_uid,
          receiver_uid: currentUser_uid,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel1 = () => {
    setIsModalVisible2(false);
  };

  return (
    <MainContainer>
      <Profile
        data={data}
        userData={userData}
        chatState={chatState}
        setChatState={setChatState}
      />
      <MainContainerContainer>
        <MainContainerDivide />
      </MainContainerContainer>
      <SubContainer>
        <SubContainer1>
          {window.innerWidth > 500 ? (
            <>
              <div onClick={() => onClickHandler()}>
                <Accept />
                <RightContainerText>
                  {isEnglish ? "Accept" : "Akzeptieren"}
                </RightContainerText>
              </div>
              <div onClick={() => onClickHandlerDecline()}>
                <Ignore />
                <RightContainerText1>
                  {isEnglish ? "Decline" : "Ablehnen"}
                </RightContainerText1>
              </div>
            </>
          ) : (
            <>
              <div className="firstButton" onClick={() => onClickHandler()}>
                {isEnglish ? "Accept" : "Akzeptieren"}
              </div>
              <div
                className="secoundButton"
                onClick={() => onClickHandlerDecline()}
              >
                {isEnglish ? "Decline" : "Ablehnen"}
              </div>
            </>
          )}
        </SubContainer1>
      </SubContainer>
      <AcceptInvition
        isModalVisible1={isModalVisible1}
        name={userData?.fullName}
        data={data}
        setIsModalVisible1={setIsModalVisible1}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalVisible2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleCancel1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ConfirmModal isModalVisible2={isModalVisible2} />
      </Modal>
    </MainContainer>
  );
};

export default PendingNotification;
