const INITIAL_STATE = {
  isEnglish: true,
  isLoading: true,
  isProjectSaved: false,
  dummyProjectCount: 1,
  whichStyleForMap: true,
  isComingFromUserOverView: false,
  isUserSelectingAdress: false,
  isComingFromUserOverView: false,
  isInverterDone: true,
  isDireactSearch: {
    flag: false,
    category_id: null,
    direactId: null,
    title: "",
  },
  isDireactCategorySearch: {
    category_id: null,
    title: null,
  },
  projectConfiguration: null,
  isTopicDeletedOrEdited: false,
  isPostDeletedOrEditedOrCreated: false,
  isStagingUser: false,
  goOnStepFour: false,
};

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_LANGUAGE_AS_ENGLISH":
      return {
        ...state,
        isEnglish: action.payload,
      };
    case "SET_COMING_OVERVIEW":
      return {
        ...state,
        isComingFromUserOverView: action.payload,
      };
    case "DUMMYPROJECT_PROJECT_ADDED":
      return {
        ...state,
        dummyProjectCount: state.dummyProjectCount + 1,
      };
    case "SET_COMING_OVERVIEW":
      return {
        ...state,
        isComingFromUserOverView: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_USER_SELECTING_ADDRESS":
      return {
        ...state,
        isUserSelectingAdress: action.payload,
      };
    case "SET_PROJECT_SAVED_STATUS":
      return {
        ...state,
        isProjectSaved: action.payload,
      };
    case "SET_STYLE_FOR_MAP":
      return {
        ...state,
        whichStyleForMap: action.payload,
      };
    case "SET_DISCORUSE_STATE":
      return {
        ...state,
        isTopicDeletedOrEdited: !state.isTopicDeletedOrEdited,
      };
    case "SET_DIREACT_SEARCH":
      return {
        ...state,
        isDireactSearch: action.payload,
      };
    case "SET_DIREACT_CATEGORY_SEARCH":
      return {
        ...state,
        isDireactCategorySearch: action.payload,
      };
    case "SET_USER_PASSWORD":
      return {
        ...state,
        isStagingUser: action.payload,
      };
    case "SET_DISCORUSE_STATE_FOR_POST":
      return {
        ...state,
        isPostDeletedOrEditedOrCreated: !state.isPostDeletedOrEditedOrCreated,
      };
    case "SET_PROJECT_CONFIGURATION":
      return {
        ...state,
        projectConfiguration: action.payload,
      };
    case "SET_BAK_TO_STEP_FOUR":
      return {
        ...state,
        goOnStepFour: action.payload,
      };
    case "SET_INVERTER_DONE":
      return {
        ...state,
        isInverterDone: action.payload,
      };
    default:
      return state;
  }
};

export default languageReducer;
