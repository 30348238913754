import React from "react";

//materialui components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  Paper,
  Divider,
  Avatar,
} from "@material-ui/core";

//colors
import { colors } from "../../../colors";
import { commaDotHandler } from "helperMethod";

const FinanceCharts = (props) => {
  const { isEnglish, costs_and_savings, financing_details } = props;
  let monthly_plus;
  monthly_plus =
    costs_and_savings.electricity_cost_before +
    costs_and_savings.feedin_savings -
    financing_details.yearly_financing_amount;
  const a = Math.round(monthly_plus / 12);
  const b = Math.round(costs_and_savings.electricity_cost_before / 12);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "@media (max-width: 560px)": {
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      },
    },
    c1: {
      width: "40%",
      minWidth: "250px",
      margin: "15px 5px 15px 15px",
      display: "flex",
      fontWeight: "bold",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        margin: "auto",
      },
      flexDirection: "column",
      alignItems: "center",
    },
    c2: {
      width: "47%",
      minWidth: "250px",
      backgroundColor: "#f8f8f8",
      borderRadius: "25px",
      padding: "15px",
      margin: "15px 25px 15px 0px",
      "@media (max-width: 430px)": {
        margin: "0px 0px 10px 0px",
      },
      "& h1": {
        color: colors.orangeSolid,
        margin: "0px",
        padding: "0px",
      },
      "& h5": {
        margin: "0px",
        padding: "0px",
        fontSize: "15px",
        marginBottom: "15px",
      },
      "& p": {
        fontSize: "13px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        margin: "5px 0px",
      },
    },
    chart_conatiner: {
      width: "200px",
      minHeight: "10px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
      marginTop: "35px",
    },
    chart_1: {
      width: "92px",
      background: colors.sun_r,
      height: `${(a / (a + b)) * 100}px`,
      alignSelf: "flex-end",
      marginRight: "8px",
      borderRadius: "8px 8px 0px 0px",
      fontSize: "20px",
      display: "flex",

      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    helperp1: {
      fontSize: "9px",
      fontSize: "15px",
      display: "flex",
      alignSelf: "flex-end",
      marginLeft: "10px",
      paddingRight: "13px",
      "@media (max-width: 500px)": {
        paddingRight: "0px",
      },
    },
    helperp2: {
      fontSize: "15px",
      marginLeft: "10px",
      "@media (max-width: 500px)": {
        marginTop: "11px",
      },
    },
    typo_responsive: {
      "@media (max-width: 430px)": {
        fontSize: "9px",
        display: "none",
        position: "absolute",
      },
    },
    logic: {
      display: "none",
      "@media (max-width: 430px)": {
        display: "block",
        textAlign: "center",
        margin: "0px",
        padding: "0px",
      },
    },
    chart_2: {
      width: "92px",
      background: "darkred",
      height: `${(b / (a + b)) * 100}px`,
      maxHeight: `70.137255px`,
      marginLeft: "8px",
      borderRadius: "0px 0px 8px 8px",
      fontSize: "20px",
      color: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <h4 className={classes.logic}>Monthly Electricity Costs</h4>
      <div className={classes.root}>
        <div className={classes.c1}>
          <Typography className={classes.typo_responsive}>
            {isEnglish
              ? "Monthly Electricity Costs"
              : "Monatliche Elektrizitätskosten"}
          </Typography>
          <div className={classes.chart_conatiner}>
            <p className={classes.helperp1}>
              with Solar <br /> system
            </p>
            <div className={classes.chart_1}>
              {commaDotHandler(a, `${isEnglish ? "en" : "de"}`)} €
            </div>
            <Divider />
            <div className={classes.chart_2}>
              {commaDotHandler(b, `${isEnglish ? "en" : "de"}`)} €
            </div>
            <p className={classes.helperp2}>
              without Solar <br /> system
            </p>
          </div>
        </div>
        <div className={classes.c2}>
          <h1>
            {commaDotHandler(
              Math.round(monthly_plus / 12),
              `${isEnglish ? "en" : "de"}`
            )}
            €
          </h1>
          <h5>{isEnglish ? "Monthly cost" : "Monatliches cost"}</h5>
          <Typography>
            <span>
              {isEnglish
                ? "Electricity cost before PV"
                : "Ersparnis durch Eigenverbrauch"}
            </span>
            <span>
              <strong>
                {commaDotHandler(
                  Math.round(costs_and_savings.electricity_cost_before / 12),
                  `${isEnglish ? "en" : "de"}`
                )}{" "}
                EUR
              </strong>
            </span>
          </Typography>
          <Typography>
            <span>{isEnglish ? "Feed-in-tariff" : "Einspeisevergütung"}</span>
            <span>
              <strong>
                {commaDotHandler(
                  Math.round(costs_and_savings.feedin_savings / 12),
                  `${isEnglish ? "en" : "de"}`
                )}{" "}
                EUR
              </strong>
            </span>
          </Typography>
          <Typography>
            <span>{isEnglish ? "Financing rate" : "Finanzierungsrate"}</span>
            <span>
              <strong>
                {commaDotHandler(
                  Math.round(financing_details.yearly_financing_amount / 12),
                  `${isEnglish ? "en" : "de"}`
                )}{" "}
                EUR
              </strong>
            </span>
          </Typography>
          <Divider style={{ margin: "20px 0px" }} />
          <Typography>
            <span>
              <strong>{isEnglish ? "Monthly cost" : "Monatliches cost"}</strong>
            </span>
            <span>
              <strong>
                {monthly_plus > 0
                  ? `${commaDotHandler(
                      Math.round(monthly_plus / 12),
                      `${isEnglish ? "en" : "de"}`
                    )}EUR`
                  : `- ${commaDotHandler(
                      Math.round(monthly_plus / 12),
                      `${isEnglish ? "en" : "de"}`
                    )}EUR`}
              </strong>
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FinanceCharts;
