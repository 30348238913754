import axios from "axios";
import { getError } from "helperMethod";
import { toast } from "react-toastify";

export const getAllLatestTopics = async () => {
  try {
    const res = await axios.get(
      "https://solarhub24.herokuapp.com/SolarHub-Discourse/post/latestTopics"
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const getAllPostfromTopic = async (id) => {
  try {
    const res = await axios.get(
      `https://solarhub24.herokuapp.com/SolarHub-Discourse/post/allPostforTopics?id=${id}`
    );
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const getAllTopicsByCategoryId = async (id) => {
  try {
    const res = await axios.get(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/categories/${id}`
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const updateTopicById = async (id, data) => {
  try {
    const res = await axios.put(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/${id}`,
      data
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const deleteTopicById = async (id, data) => {
  try {
    console.log(data, "rusho");
    const res = await axios.delete(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/${id}`,
      {
        headers: {
          username: data.username,
        },
      }
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const getPostsByTopicId = async (id, data) => {
  try {
    console.log(data, "rusho");
    const res = await axios.get(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/${id}`,
      data
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const createPostWithId = async (data) => {
  try {
    console.log(data, "rusho");
    const res = await axios.post(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/posts`,
      data
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.raw);
    }
  }
};

export const updatedPostById = async (id, data) => {
  try {
    console.log(data, "rusho");
    const res = await axios.put(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/posts/${id}`,
      data
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error("post description is too short");
    }
  }
};

export const deletePostById = async (id, data) => {
  try {
    const res = await axios.delete(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/posts/${id}`,
      {
        headers: {
          username: data.username,
        },
      }
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const getUserByUserName = async (data) => {
  try {
    const res = await axios.get(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/users/${data}`
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};

export const deletePostLike = async (id, data) => {
  try {
    console.log(data, "rusho");
    const res = await axios.delete(
      `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/posts/${id}/like`,
      {
        headers: {
          username: data.username,
        },
      }
    );
    console.log("res", res);
    return res;
  } catch (err) {
    if (err?.response?.data?.errors?.length) {
      toast.error(getError(err?.response?.data?.errors));
    } else {
      toast.error(err?.response?.data?.username);
    }
  }
};
