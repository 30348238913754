import React, { useState, useEffect } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core/";
import { Checkbox } from "antd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataGrid } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import firebase, { db } from "../../../firebase";
import OBJECT__POPUP from "../components/popups/admin.installer.popup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "Components/loader";
import { colors } from "colors";
import { useHubspot } from "../hooks/useHubspot";

const Vendors = () => {
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState("");
  const [run, setRun] = useState(false);
  const [objectDetails, setObjectDetails] = useState({});
  const [isNewObject, setIsNewObject] = useState(true);
  const isLoading = useSelector((state) => state.global.isLoading);
  const [hubspotContactData, setHubspotContactData] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState("contacts");
  const { fetchListHttp } = useHubspot();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  console.log(rowData, "rowData");

  const modifyList = (data, itemType) => {
    console.log("list data", data);
    console.log("item type", itemType);
    const updatedList = [];
    if (itemType === "contacts") {
      data?.results?.map((item) => {
        const tempData = {
          ...item.properties,
          id: item.properties.hs_object_id,
        };
        updatedList.push(tempData);
      });
    }
    if (itemType === "companies") {
      data?.results?.map((item) => {
        const tempData = {
          ...item.properties,
          id: item.properties.hs_object_id,
        };
        updatedList.push(tempData);
      });
    }
    if (itemType === "deals") {
      data?.results?.map((item) => {
        const tempData = {
          ...item.properties,
          id: item.properties.hs_object_id,
        };
        updatedList.push(tempData);
      });
    }
    return updatedList;
  };

  useEffect(async () => {
    const tempData = [];
    sampleContactData.map((item) => {
      const dataSet = {};
      dataSet.id = item.properties.hs_object_id;
      dataSet.hs_id = item.properties.hs_object_id;
      dataSet.firstname = item.properties.firstname;
      dataSet.lastname = item.properties.lastname;
      dataSet.email = item.properties.email;
      dataSet.createdate = item.properties.createdate;
      tempData.push(dataSet);
    });
    try {
      const x = await fetchListHttp(selectedItemType);
      setRowData(modifyList(x?.data, selectedItemType));
      //modifyList(x?.data, selectedItemType);
      console.log("this is received data", x);
    } catch (err) {
      console.log("this is error", err);
    }
    console.log("this is temp data", tempData);
    setHubspotContactData(tempData);
  }, [selectedItemType]);

  const contactsColumns = [
    { field: "hs_object_id", editable: true, width: 200 },
    { field: "firstname", editable: true, width: 200 },
    { field: "lastname", editable: true, width: 200 },
    { field: "email", editable: true, width: 200 },
    { field: "createdate", editable: true, width: 200 },
    { field: "lastmodifieddate", editable: true, width: 200 },
  ];

  const companiesColumns = [
    { field: "hs_object_id", editable: true, width: 200 },
    { field: "name", editable: true, width: 200 },
    { field: "domain", editable: true, width: 200 },
    { field: "createdate", editable: true, width: 200 },
    { field: "hs_lastmodifieddate", editable: true, width: 200 },
  ];

  const dealsColumns = [
    { field: "hs_object_id", editable: true, width: 200 },
    { field: "amount", editable: true, width: 200 },
    { field: "dealname", editable: true, width: 200 },
    { field: "dealstage", editable: true, width: 200 },
    { field: "createdate", editable: true, width: 200 },
    { field: "closedate", editable: true, width: 200 },
    { field: "hs_lastmodifieddate", editable: true, width: 200 },
    { field: "pipeline", editable: true, width: 200 },
  ];
  const getColumns = (itemType) => {
    if (itemType === "contacts") {
      return contactsColumns;
    }
    if (itemType === "companies") {
      return companiesColumns;
    }
    if (itemType === "deals") {
      return dealsColumns;
    }
  };

  return (
    <>
      <Accordion expanded={true} style={{ flex: "1", width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Hubspot Management</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flex: "1",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "15px 30px",
                marginTop: "-10px",
              }}
            >
              <div>
                <Button
                  variant={selectedItemType === "contacts" && "contained"}
                  onClick={() => setSelectedItemType("contacts")}
                >
                  Contacts
                </Button>
                <Button
                  variant={selectedItemType === "companies" && "contained"}
                  onClick={() => setSelectedItemType("companies")}
                >
                  Companies
                </Button>
                <Button
                  variant={selectedItemType === "deals" && "contained"}
                  onClick={() => setSelectedItemType("deals")}
                >
                  Deals
                </Button>
              </div>
              <div>
                <Button variant="contained" onClick={handleClickOpen}>
                  + Add {selectedItemType}
                </Button>
                {/*                 <Button variant="contained" onClick={handleClick}>
                  Handle Click
                </Button> */}
              </div>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ minHeight: "65vh", width: "100%" }}
            >
              {hubspotContactData.length > 0 ? (
                <DataGrid
                  rows={rowData}
                  getRowId={(row) => row.id}
                  columns={getColumns(selectedItemType)}
                  pageSize={7}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <OBJECT__POPUP
            open={open}
            objectDetails={objectDetails}
            setObjectDetails={setObjectDetails}
            openMethod={setOpen}
            newObject={isNewObject}
            setIsNewObject={setIsNewObject}
          />
        </AccordionDetails>
        {isLoading ? (
          <Loader loading={isLoading} color={colors.orangeSolid} />
        ) : (
          ""
        )}
      </Accordion>
    </>
  );
};

export default Vendors;

const sampleContactData = [
  {
    id: "1",
    properties: {
      createdate: "2021-03-09T11:53:50.685Z",
      email: "emailmaria@hubspot.com",
      firstname: "Maria",
      hs_object_id: "1",
      lastmodifieddate: "2022-04-10T06:18:50.027Z",
      lastname: "Johnson (Sample Contact)",
    },
    createdAt: "2021-03-09T11:53:50.685Z",
    updatedAt: "2022-04-10T06:18:50.027Z",
    archived: false,
  },
  {
    id: "51",
    properties: {
      createdate: "2021-03-09T11:53:51.037Z",
      email: "bh@hubspot.com",
      firstname: "Brian",
      hs_object_id: "51",
      lastmodifieddate: "2022-04-21T17:16:20.920Z",
      lastname: "Halligan (Sample Contact)",
    },
    createdAt: "2021-03-09T11:53:51.037Z",
    updatedAt: "2022-04-21T17:16:20.920Z",
    archived: false,
  },
  {
    id: "101",
    properties: {
      createdate: "2021-03-09T11:58:23.925Z",
      email: "info@ensolar.de",
      firstname: "Rouven",
      hs_object_id: "101",
      lastmodifieddate: "2022-05-19T08:37:35.014Z",
      lastname: "Hecke",
    },
    createdAt: "2021-03-09T11:58:23.925Z",
    updatedAt: "2022-05-19T08:37:35.014Z",
    archived: false,
  },
  {
    id: "151",
    properties: {
      createdate: "2021-03-10T08:56:04.628Z",
      email: "info@maile-hausverwaltung.de",
      firstname: "Daniel",
      hs_object_id: "151",
      lastmodifieddate: "2022-07-09T22:14:14.787Z",
      lastname: "Maile",
    },
    createdAt: "2021-03-10T08:56:04.628Z",
    updatedAt: "2022-07-09T22:14:14.787Z",
    archived: false,
  },
  {
    id: "201",
    properties: {
      createdate: "2021-03-10T10:54:32.389Z",
      email: "sebastian.preuss@30grad-solar.com",
      firstname: "Dipl.-Ing. Sebastian",
      hs_object_id: "201",
      lastmodifieddate: "2022-06-17T22:51:17.059Z",
      lastname: "Preuß",
    },
    createdAt: "2021-03-10T10:54:32.389Z",
    updatedAt: "2022-06-17T22:51:17.059Z",
    archived: false,
  },
  {
    id: "251",
    properties: {
      createdate: "2021-03-11T12:10:00.182Z",
      email: "info@j-koenig.de",
      firstname: "Daniel & Franziska",
      hs_object_id: "251",
      lastmodifieddate: "2022-06-05T10:45:37.631Z",
      lastname: "Petri",
    },
    createdAt: "2021-03-11T12:10:00.182Z",
    updatedAt: "2022-06-05T10:45:37.631Z",
    archived: false,
  },
  {
    id: "301",
    properties: {
      createdate: "2021-03-12T08:48:04.334Z",
      email: "m.kar@enact-systems.com",
      firstname: "Manasij",
      hs_object_id: "301",
      lastmodifieddate: "2022-06-01T13:02:44.527Z",
      lastname: "Kar",
    },
    createdAt: "2021-03-12T08:48:04.334Z",
    updatedAt: "2022-06-01T13:02:44.527Z",
    archived: false,
  },
  {
    id: "351",
    properties: {
      createdate: "2021-03-12T09:09:55.847Z",
      email: "deep@enact-systems.com",
      firstname: "Deep",
      hs_object_id: "351",
      lastmodifieddate: "2022-07-12T13:40:10.510Z",
      lastname: "Chakraborty",
    },
    createdAt: "2021-03-12T09:09:55.847Z",
    updatedAt: "2022-07-12T13:40:10.510Z",
    archived: false,
  },
  {
    id: "401",
    properties: {
      createdate: "2021-04-01T10:14:30.266Z",
      email: null,
      firstname: "Timothy",
      hs_object_id: "401",
      lastmodifieddate: "2022-07-02T21:47:48.838Z",
      lastname: "Earnshaw",
    },
    createdAt: "2021-04-01T10:14:30.266Z",
    updatedAt: "2022-07-02T21:47:48.838Z",
    archived: false,
  },
  {
    id: "451",
    properties: {
      createdate: "2021-04-02T04:45:54.939Z",
      email: "zukunftsinvestitionen-bw@vdivde-it.de",
      firstname: "Felix",
      hs_object_id: "451",
      lastmodifieddate: "2022-05-22T05:25:58.128Z",
      lastname: "Wiederstein",
    },
    createdAt: "2021-04-02T04:45:54.939Z",
    updatedAt: "2022-05-22T05:25:58.128Z",
    archived: false,
  },
];
