import { useState } from "react";
import { css } from "@emotion/react";
import {
  ClipLoader,
  BeatLoader,
  DotLoader,
  FadeLoader,
  ScaleLoader,
} from "react-spinners";
import { colors } from "../colors";

// Can be a string as well. Need to ensure each key-value pair ends with ;

function App({ loading, color }) {
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            zIndex: "10",
            background: "rgb(191, 191, 191, 0.1)",
          }}
        >
          <DotLoader color={color} loading={loading} size={150} />
        </div>
      ) : null}
    </>
  );
}

export default App;
