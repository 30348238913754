import React, { useState } from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { colors } from "../../colors";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBlueOverWhite from "../atoms/buttons/button.blueOverWhite";
import { getNumber } from "../../utils/costs";
import placeholder from "../../Assets/placeHolder.jpg";
import ProductDetails from "../../popups/ProductDetails/productDetails.popup";
import { useDispatch, useSelector } from "react-redux";

//
function ProductEntity({ listItem, onProductSelection }) {
  const [productDetailsDialog, setProductDetailsDialog] = useState(false);
  const [imgUrl, setImgUrl] = useState(listItem.imgurl1);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const project = useSelector((state) => state.project);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "250px",
      minWidth: "250px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f8f8f8",
      alignItems: "center",
      position: "relative",
      height: "448px",
      overflow: "hidden",
      margin: "10px",
      border: "1px solid lightgray",

      "& img": {
        width: "100%",
        padding: "10px",
        height: "120px",

        objectFit: "cover",
        backgroundColor: "#fff",
      },
      "& h4": {
        width: "90%",
        marginTop: "10px",
        fontSize: "14px",
        height: "30px",
        margin: "10px 0px",
      },
      "& button": {
        marginTop: "10px",
      },
      "&:hover": {
        //boxShadow:'0 0 18px 0 #f8f8f8'
      },
    },
    container1: {
      display: "flex",
      width: "90%",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "10px",
      "& p": {
        color: "black",
      },
      "& div": {
        "& p": { fontSize: "12px" },
      },
    },
    container2: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "50px",
        height: "50px",
        marginRight: "10px",
        backgroundColor: "#f8f8f8",
      },
      "& p": {
        margin: "0px",
        fontSize: "12px",
      },
    },

    btn2: {
      width: "165px",
      marginLeft: "45px",
      marginTop: "15px",
      color: colors.darkBlue,
      backgroundColor: "#fff",
      fontSize: "12px",
      fontWeight: "bold",
      borderRadius: "50px",
      "@media (max-width: 922px)": {
        marginLeft: "10px",
      },
      margin: "5px 0",
    },
    container3: {
      width: "100%",
      border: `1px solid ${colors.darkBlue}`,
      marginTop: "15px",
      height: "50px",
      textAlign: "center",
      paddingTop: "10px",
      "@media (max-width: 560px)": {
        height: "50px",
      },
    },
    buttonThis: {
      backgroundColor: "rgba(255, 215, 93, 1)",
      width: "100%",
      padding: "10px 10px",
      fontSize: "15px",
      height: "45px",
      bottom: "0px",
      position: "absolute",
      border: "none",
      color: "#000",
      "&:hover": {
        background: "linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%)",
        border: "none",
        cursor: "pointer",
      },
    },
  }));
  const classes = useStyles();
  console.log(listItem, "listItem");
  const dispatch = useDispatch();

  return (
    <Paper className={classes.root}>
      <img alt="" src={imgUrl} onError={() => setImgUrl(placeholder)} />
      <h4>{listItem.name}</h4>
      <div className={classes.container1}>
        <Typography>{listItem.subtype}</Typography>
        <div>
          <Typography>
            <strong>{listItem.applicable_max_capacity}</strong>
          </Typography>
        </div>
      </div>
      <div className={classes.container2}>
        <img alt="" src={"/images/application/application_step_2/img3.png"} />
        <div>
          <p>
            {Array.isArray(listItem?.bullet_point_list) &&
            listItem?.bullet_point_list?.length
              ? listItem?.bullet_point_list[0]
              : "komplett schwarz -"}
          </p>
        </div>
      </div>
      <div className={classes.container2}>
        <img alt="" src={"/images/application/application_step_2/img4.png"} />
        <div>
          <p>
            {Array.isArray(listItem?.bullet_point_list) &&
            listItem?.bullet_point_list?.length
              ? listItem?.bullet_point_list[1]
              : "komplett schwarz -"}
          </p>
        </div>
      </div>
      <ButtonBlueOverWhite onClick={() => setProductDetailsDialog(true)}>
        {isEnglish ? "Product Details" : "Produktdetails"}
      </ButtonBlueOverWhite>
      <Button
        onClick={() => {
          if (listItem.type === "inverter") {
            dispatch({ type: "SET_INVERTER_DONE", payload: false });
          }
          onProductSelection(listItem);
        }}
        className={classes.buttonThis}
      >
        {isEnglish
          ? `Choose  ${Math.round(
              getNumber(
                project?.isB2B ? listItem.price_b2b : listItem.price_b2c
              )
            )} EUR`
          : `Auswählen  ${Math.round(
              getNumber(
                project?.isB2B ? listItem.price_b2b : listItem.price_b2c
              )
            )} EUR`}
      </Button>
      <ProductDetails
        open={productDetailsDialog}
        setOpen={setProductDetailsDialog}
        item={listItem}
      />
    </Paper>
  );
}

export default ProductEntity;
