// function swapCommaDot(str) {
//   if (str === "" || str === undefined) {
//     return str;
//   }
//   var swaps = {
//     ".": ",",
//     ",": ".",
//   };
//   var reg = new RegExp("[" + Object.keys(swaps).join("") + "]", "g");
//   return str.replace(reg, function (x) {
//     return swaps[x];
//   });
// }
// export function swapDotComma(str) {
//   if (str === "" || str === undefined) {
//     return str;
//   }
//   var swaps = {
//     ".": ",",
//     ",": ".",
//   };
//   var reg = new RegExp("[" + Object.keys(swaps).join("") + "]", "g");
//   return str.replace(reg, function (x) {
//     return swaps[x];
//   });
// }

export function parseLocaleNumber(stringNumber, locale) {
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
}

function reverseString(str) {
  return str.split("").reverse().join("");
}

export function commaDotHandler(val, type) {
  let temp = String(val);
  //   if (temp.length === 0) return "";
  if (type === "de") {
    let ind = -1;
    let sar = "";
    let ans = "";
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] === ".") {
        ind = i;
        break;
      }
      sar += temp[i];
    }
    var match = sar.match(/\d/g);
    if (!match) return "";
    var matches = sar.match(/\d/g).join("");
    let c = 0;
    for (let i = matches.length - 1; i >= 0; i--) {
      c = c + 1;
      ans += matches[i];
      if (c % 3 == 0 && i != 0) ans += ".";
    }
    ans = reverseString(ans);
    if (ind == -1) return ans;
    if (ind != -1) ans += ",";
    for (let i = ind + 1; i < temp.length; i++) {
      ans += temp[i];
    }
    return ans;
  }
  let ind = -1;
  let sar = "";
  let ans = "";
  for (let i = 0; i < temp.length; i++) {
    if (temp[i] === ".") {
      ind = i;
      break;
    }
    sar += temp[i];
  }
  var match = sar.match(/\d/g);
  if (!match) return "";
  var matches = sar.match(/\d/g).join("");
  let c = 0;
  for (let i = matches.length - 1; i >= 0; i--) {
    c = c + 1;
    ans += matches[i];
    if (c % 3 == 0 && i != 0) ans += ",";
  }
  ans = reverseString(ans);
  if (ind === -1) {
    return ans;
  }
  if (ind != -1) ans += ".";
  for (let i = ind + 1; i < temp.length; i++) {
    ans += temp[i];
  }
  return ans;
}
export default commaDotHandler;

export const TimeArray = [
  "10min",
  "20min",
  "30min",
  "40min",
  "50min",
  "1hr",
  "1hr:30",
  "2hr",
  "2hr:30",
];

export const getError = (errorArray) => {
  const errors = errorArray.join(" ");
  return errors;
};
