import React, { useEffect, useState } from "react";
import styled from "styled-components";
import hearPump from "Assets/community/hearPump.svg";
import inverter from "Assets/community/inverter.svg";
import spar_panels from "Assets/community/solar_panels.svg";
import { CircularProgress, Button } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import comma from "Assets/community/comma.svg";
import telemarketer from "Assets/community/telemarketer.svg";
import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import SelectTimeModal from "../../../Invitaion/SelectTimeModal";

const SwiperCardWrapper = styled("div")`
  .conteinr {
    height: 507px;
    width: 305px;
    border-radius: 15px;
    background: #f9f9f9;
    border-radius: 15px;
    padding: 15px;
    padding-left: 24px;
    padding-top: 20px;
  }
  .first_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    line-height: 25px;
    margin-bottom: 16px;
    margin-top: 4px;
    letter-spacing: -0.02em;
    color: #2d4764;
  }
  .flex {
    display: flex;
    margin-top: 6px;
  }
  .first_contienr {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .first_part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .first_part_right_part {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-left: 10px;
    align-items: flex-start;
  }
  .class_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 33px;
    line-height: 12px;
    margin-top: 9px;
    margin-left: 9px;
    color: #666666;
  }
  .inside_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    line-height: 16px;
    color: #2d4764;
  }
  .last_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }
  .secound_part {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 16px;
    align-items: flex-start;
  }
  .same_class {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;
    div {
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      margin-left: 9px;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: #666666;
    }
    img {
      height: 20px;
      width: 25px;
    }
  }
  .insideRating3 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 2;
    align-items: center;
    h4 {
      color: #666666;
      font-weight: 600;
      font-size: 24px;
    }
  }
  .divider {
    border: 1px solid #e6e6e6;
  }
  .secound_contienr {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .user_review_text {
    font-family: Nunito;
    font-style: normal;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #666666;
  }
  .user_rating {
    display: flex;
    margin-top: 6px;
    justify-content: center;
    align-items: center;
  }
  .makeItFlex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    align-items: flex-start;
  }
  .mainClassText {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #666666;
  }
  .mainClassFirstText {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #2d4764;
  }
  .flex_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      margin-left: 5px;
      margin-top: 3px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #2d4764;
    }
  }
  .userName {
    margin-bottom: 1px;
  }
  .button {
    width: auto;
    padding-left: 22px;
    padding-right: 22px;
    height: 40px;
    margin-top: 20px;
    background: #ffffff;
    border: 1px solid #2d4764;
    border-radius: 42px;
    color: rgba(45, 71, 100, 1);
    :hover {
      background-color: #ffffff;
    }
  }
`;
const SwiperCard = ({ data, productName }) => {
  console.log(data, "Data");
  const [flag, setFlag] = useState(3);
  const sender_uid = useSelector((state) => state?.user?.currentUser?.uid);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(true);
  const receiver_uid = data?.userData?.uid;
  const waiting_invitaion = useSelector(
    (state) => state?.notification?.waiting_invitaion
  );
  const accepted_invitaion = useSelector(
    (state) => state?.notification?.accepted_invitaion
  );
  const pending_invitaion = useSelector(
    (state) => state?.notification?.pending_invitaion
  );
  const isEnglish = useSelector((state) => state.global.isEnglish);

  const dispatch = useDispatch();

  useEffect(() => {
    for (let i = 0; i < waiting_invitaion.length; i++) {
      if (waiting_invitaion[i]?.receiver_uid === data.userData.uid) {
        return setFlag(1);
      }
    }
    if (pending_invitaion.length > accepted_invitaion.length) {
      if (pending_invitaion.length > 0) {
        return setFlag(4);
      }
    } else {
      for (let i = 0; i < accepted_invitaion.length; i++) {
        if (accepted_invitaion[i]?.receiver_uid) {
          if (
            accepted_invitaion[i]?.receiver_uid?.includes(data.userData.uid)
          ) {
            return setFlag(2);
          }
        } else {
          return setFlag(2);
        }
      }
    }
  }, [waiting_invitaion, accepted_invitaion, pending_invitaion]);
  const history = useHistory();

  const onClickHandler = async () => {
    if (flag == 1) {
      return toast.warning("Invitaion Pending");
    }
    if (flag == 2) {
      return;
    }
    if (flag == 4) {
      return history.push("community/notification");
    }
    setIsModalVisible(true);
  };

  return (
    <SwiperCardWrapper>
      <div className="conteinr">
        <div className="first_text">
          {isEnglish ? "Project description" : " Projektbeschreibung "}{" "}
        </div>
        <div classNam="first_contienr">
          <div className="first_part">
            <img
              style={{ borderRadius: "9px", width: "100px", height: "100px" }}
              src={data.projectData.projectImgUrl[0]}
              alt=""
            />
            <div className="first_part_right_part">
              <div className="inside_text">
                {`${data?.projectData?.updatedData?.roof_type} ${data?.projectData?.updatedData?.roof_material} ${data?.projectData?.updatedData?.roof_tilt} ${data?.projectData?.updatedData?.roof_orientation}`}
              </div>
              <div className="flex">
                <div className="insideRating3">
                  <div
                    style={{
                      position: "relative",
                      height: "48px",
                      bottom: "11px",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "4px",
                        color: "#fbda61",
                        fontSize: "13px",
                      }}
                    >
                      {data.actualRatingData?.rating.toFixed(1)}
                    </span>
                    <CircularProgress
                      style={{
                        color: "#fbda61",
                        marginBottom: "30px",
                        width: "32px",
                        height: "35px",
                        marginTop: "11px",
                      }}
                      variant="determinate"
                      value={data.actualRatingData?.rating * 2 * 10}
                    />
                  </div>
                </div>
                <div className="class_text">installation rating</div>
              </div>
              <div className="last_text">
                {parseInt(
                  data.projectData.energysDetails.total_production / 1000
                )}{" "}
                kwp
              </div>
            </div>
          </div>
          <div className="secound_part">
            <div className="same_class">
              <img src={spar_panels} alt="" />
              <div>
                {
                  data.projectData?.project_details?.components?.items
                    ?.solar_panel?.item?.name
                }
              </div>
            </div>
            <div className="same_class">
              <img src={inverter} alt="" />
              <div>
                {
                  data?.projectData?.project_details?.components?.items
                    ?.inverter?.item?.name
                }
              </div>
            </div>
            <div className="same_class">
              {data?.projectData?.project_details?.components?.items?.heatpump
                ?.item ? (
                <>
                  <img src={hearPump} alt="" />
                  <div>
                    {
                      data.projectData.project_details.components.items.heatpump
                        .item.name
                    }
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="secound_contienr">
          <img src={comma} alt="" />
          <div className="user_review_text">
            {data.actualRatingData?.comment}
          </div>
          <div className="user_rating">
            {data.userData.img_url ? (
              <Avatar src={data.userData.img_url} size="large" />
            ) : (
              <Avatar
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  objectFit: "cover",
                }}
                size="large"
              >
                {data.actualRatingData?.email[0]}
              </Avatar>
            )}
            <div className="makeItFlex">
              <div className="userName">
                <span className="mainClassFirstText">
                  {data.userData.fullName},
                </span>{" "}
                <span className="mainClassText">
                  {data?.projectData?.project_details?.address?.city}
                </span>
              </div>
              <div className="flex_1">
                <Rating
                  name="size-small"
                  value={data.actualRatingData.rating}
                  precision={0.5}
                  readOnly
                  size="small"
                />
                <div className="rating_number">
                  {data.actualRatingData.rating}
                </div>
              </div>
            </div>
          </div>
          <Button
            variant="outlined"
            className="button"
            style={{
              fontSize: flag === 2 && isEnglish ? "" : "12px",
            }}
            startIcon={<img src={telemarketer} alt="profile" />}
            onClick={() => onClickHandler()}
          >
            {flag === 1
              ? isEnglish
                ? "waiting for confirmation"
                : "Auf Bestätigung warten"
              : flag == 2
              ? isEnglish
                ? "Chat with User"
                : "Chatten Sie mit dem Benutzer"
              : flag == 4
              ? isEnglish
                ? "Invitaion pending"
                : "Einladung ausstehend"
              : isEnglish
              ? "Get In Contact"
              : "Kontaktieren"}
          </Button>
        </div>
        <SelectTimeModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          data={data}
          sender_uid={sender_uid}
          receiver_uid={receiver_uid}
        />
      </div>
    </SwiperCardWrapper>
  );
};

export default SwiperCard;
