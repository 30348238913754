import React from "react";
import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const HorizontalScroll1 = ({ menu, isVisible }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      width: "100%",
      // maxWidth: "810px",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 560px)": {
        width: "84vw",
      },
    },
    scroll_container: {
      display: "flex",
      overflow: "hidden",
      width: "810px",
      minWidth: "270px",
      "@media (max-width: 560px)": {
        width: "270x",
      },
    },
    arrow_icon_left: {
      paddingTop: 10,
      paddingBottom: 10,
      width: 40,
      height: 40,
      "@media (min-width: 900PX)": {
        "&:hover": {
          cursor: "pointer",
          paddingTop: 5,
          paddingBottom: 5,
          transition: "0.3s",
        },
      },
      "@media (max-width: 560px)": {
        fontSize: "19px",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    arrow_icon_right: {
      paddingTop: 10,
      paddingBottom: 10,
      width: 40,
      height: 40,
      "@media (min-width: 900PX)": {
        "&:hover": {
          cursor: "pointer",
          paddingTop: 5,
          paddingBottom: 5,
          transition: "0.3s",
        },
      },
      "@media (max-width: 560px)": {
        fontSize: "19px",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  }));
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  //
  const contentWrapper = React.useRef(null);
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {menu && menu.length > 0 ? (
          <>
            <ArrowBackIos
              className={classes.arrow_icon_left}
              onClick={() => {
                sideScroll(contentWrapper.current, 10, 270, -5);
              }}
            />
            <div className={classes.scroll_container} ref={contentWrapper}>
              {menu}
            </div>
            <ArrowForwardIos
              className={classes.arrow_icon_right}
              onClick={() => {
                sideScroll(contentWrapper.current, 10, 270, 5);
              }}
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "378px",
            }}
          >
            <CircularProgress color="rgba(48, 72, 100, 1)" />
          </div>
        )}
      </div>
    </>
  );
};

export default HorizontalScroll1;
