import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Paper, Card, Checkbox } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { colors } from "../../../colors";
import { db } from "../../../firebase";
import ProductDetails from "popups/ProductDetails/productDetails.popup";
import { financingDetails } from "../../../utils/costs";
import Question from "./images/Question.svg";
import { Rate } from "antd";
import cta from "./images/cta.svg";
import addComhover from "Assets/addComhover.svg";
import FeedbackPopup from "popups/feedbackPopup/FeedbackPopup";
import HorizontalScroll1 from "../../../Components/templates/horizontalScroll";
import { costsAndSavings } from "../../../utils/costs";
import ProductEntity from "../../../Components/molecules/product.entity";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import commaDotHandler from "helperMethod";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  Carousel: {
    overflow: "auto",
  },
  topBtns: {
    width: "200px",
  },
}));

let addComponent = () => {};
let removeComponent = () => {};
let tagToName;

function Beneficiaries({ isEnglish, project, dispatchMethods }) {
  const classes = useStyles();
  const [showSub, setShowSub] = useState(false);
  const solar_panel = project?.components.items.solar_panel;
  const inverter = project?.components.items.inverter;
  const battery = project?.components.items.battery;
  const wallbox = project?.components.items.wallbox;
  const heatpump = project?.components.items.heatpump;
  addComponent = dispatchMethods?.addComponent;
  removeComponent = dispatchMethods?.removeComponent;

  tagToName = {
    solar_panel: isEnglish ? "Solar Panel" : "Solarpanels",
    inverter: isEnglish ? "Inverter" : "Wechselrichter",
    battery: isEnglish ? "Battery" : "Batterie",
    wallbox: isEnglish ? "Wall Box" : "Wallbox",
    heatpump: isEnglish ? "Heat Pump" : "Wärmepumpe",
  };

  const [addComponentSubList, setAddComponentSubList] = useState([]);

  useEffect(() => {
    let addComponentSubList1 = [];
    !project.components.items.battery.item &&
      addComponentSubList1.push({
        type: "battery",
        title: isEnglish ? "ADD BATTERY" : "Batterie hinzufügen",
        details: isEnglish
          ? "Choose & customise battery"
          : "Wählen und anpassen der Batterie",
      });
    !project.components.items.wallbox.item &&
      addComponentSubList1.push({
        type: "wallbox",
        title: isEnglish ? "ADD WALLBOX" : "Wallbox hinzufügen",
        details: isEnglish
          ? "Choose & customise wallbox"
          : "Wählen und anpassen der Wallbox",
      });
    !project.components.items.heatpump.item &&
      addComponentSubList1.push({
        type: "heatpump",
        title: isEnglish ? "ADD HEAT PUMP" : "Wärmepumpe hinzufügen",
        details: isEnglish
          ? "Choose & customise heatpump"
          : "Wählen und anpassen der Wärmepumpe",
      });
    setAddComponentSubList(addComponentSubList1);
  }, [project]);

  return (
    <div className={classes.root}>
      <div>
        {solar_panel.item !== null && (
          <KonfComponent
            solarPanel
            isEnglish={isEnglish}
            component={solar_panel}
            project={project}
            toggleWarranty={dispatchMethods?.toggleWarranty}
          />
        )}
        {inverter.item !== null && (
          <KonfComponent
            isEnglish={isEnglish}
            component={inverter}
            project={project}
            toggleWarranty={dispatchMethods?.toggleWarranty}
          />
        )}
        {battery.item && (
          <KonfComponent
            isEnglish={isEnglish}
            component={battery}
            toggleWarranty={dispatchMethods?.toggleWarranty}
          />
        )}
        {wallbox.item && (
          <KonfComponent
            isEnglish={isEnglish}
            component={wallbox}
            toggleWarranty={dispatchMethods?.toggleWarranty}
          />
        )}
        {heatpump.item && (
          <KonfComponent
            isEnglish={isEnglish}
            component={heatpump}
            toggleWarranty={dispatchMethods?.toggleWarranty}
          />
        )}
      </div>

      {showSub ? (
        <>
          {addComponentSubList.map((component) => (
            <AddComponentSub isEnglish={isEnglish} component={component} />
          ))}
        </>
      ) : (
        addComponentSubList.length > 0 && (
          <AddComponent isEnglish={isEnglish} setShowSub={setShowSub} />
        )
      )}
    </div>
  );
}
export default Beneficiaries;

export const KonfComponent = ({
  isEnglish,
  solarPanel,
  component,
  project,
  toggleWarranty,
}) => {
  const featureList = [
    "Good energy management - Smart Home",
    "Connection of the storage tank with additional inverters",
    "5 years warranty",
  ];
  let tagToName;
  tagToName = {
    solar_panel: isEnglish ? "Solar Panel" : "Solarpanels",
    inverter: isEnglish ? "Inverter" : "Wechselrichter",
    battery: isEnglish ? "Battery" : "Batterie",
    wallbox: isEnglish ? "Wall Box" : "Wallbox",
    heatpump: isEnglish ? "Heat Pump" : "Wärmepumpe",
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "900px",
      height: "auto",
      margin: "5px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "25px",
      padding: "10px",
      "@media (max-width:560px)": {
        width: "87vw",
        height: "auto",
        flexDirection: "column",
      },
      marginBottom: window.location.pathname.includes("feedback") ? "20px" : "",
    },
    rootContainer: {
      width: "900px",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      "@media (max-width:560px)": {
        width: "80vw",
        height: "auto",
        flexDirection: "column",
      },
    },
    imgContainer: {
      width: "230px",
      height: "100%",
      display: "flex",
      "@media (max-width: 560px)": {
        marginTop: "25px",
      },
      alignItems: "center",
      justifyContent: "center",
    },
    detailsContainer: {
      width: "470px",
      height: "100%",
      paddingLeft: "15px",
      paddingTop: "25px",
      "@media (max-width: 560px)": {
        width: "90%",
        paddingLeft: "0px",
      },
      "& h1": {
        margin: "0px",
        padding: "0px",
        fontSize: "25px",
        color: colors.darkBlue,
        "@media (max-width: 560px)": {
          fontSize: "18px",
        },
        marginTop: "15px",
      },
      "& h3": {
        margin: "0px",
        padding: "0px",
        "@media (max-width: 560px)": {
          fontSize: "10px",
        },
      },
      "& p": {
        fontSize: "13px",
        display: "flex",
        width: "100%",
        alignItems: "center",
        margin: "5px 0px",
        "& div": {
          width: "5px",
          height: "5px",
          border: "1px solid gray",
          backgroundColor: colors.darkBlue,
          borderRadius: "5px",
          marginRight: "10px",
        },
      },
    },
    buttonContainer: {
      width: "30%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "@media (max-width: 560px)": {
        width: "90%",
        flexWrap: "wrap",
        paddingTop: "2px",
      },
    },
    img: {
      width: "230px",
      height: "150px",
      objectFit: "cover",
      "@media (max-width: 430px)": {
        borderRadius: "10px",
        width: "230px",
        height: "150px",
      },
      background: "#f8f8f8",
    },
    btn1: {
      width: "150px",
      fontSize: "12px",
      fontWeight: "medium",
      borderRadius: "50px",
      "&:hover": {
        cursor: "pointer",
      },
      margin: "5px 0",
      "@media (max-width: 560px)": {
        width: "80%",
      },
      backgroundColor: colors.ctaSolid,
    },
    btn2: {
      width: "165px",
      color: colors.darkBlue,
      backgroundColor: "#fff",
      fontSize: "12px",
      border: `1px solid ${colors.darkBlue}`,
      borderRadius: "50px",
      "@media (max-width: 560px)": {
        width: "80%",
      },
      "&:hover": {
        backgroundColor: colors.darkBlue,
        color: "#fff",
      },
      margin: "5px 0",
      fontWeight: "bold",
    },
    h3Handler: {
      fontSize: "20px",
    },
    btn3: {
      width: "165px",
      color: "#fff",
      backgroundColor: colors.darkBlue,
      fontSize: "12px",
      fontWeight: "bold",
      "@media (max-width: 560px)": {
        width: "80%",
      },
      "&:hover": {
        color: colors.darkBlue,
        backgroundColor: "transparent",
      },
      border: `1px solid ${colors.darkBlue}`,
      borderRadius: "50px",
      margin: "5px 0",
    },
    cardHelper: {
      width: "150px",
      height: "35px",
      borderRadius: "5px",
      marginTop: "15px",
      fontSize: "12px",
      "@media (max-width: 560px)": {
        width: "80%",
        marginBottom: "10px",
      },
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    closeIcon: {
      position: "relative",
      zIndex: "1",
      alignSelf: "flex-start",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.5)",
        transition: "0.3s",
      },
      marginRight: "15px",
      marginBottom: "-15px",
    },
    Flex_Helper: {
      display: "flex",
      height: "200px",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
    reviews: {
      display: "flex",
      height: "36px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
      cursor: "pointer",
      "& h5": {
        color: "rgba(45, 71, 100, 1)",
        fontSize: "14px",
      },
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [productDetailsDialog, setProductDetailsDialog] = useState(false);
  const [ratingsData, setRatingsData] = useState([]);
  const history = useHistory();
  const [FeedbackDialog, setFeedbackDialog] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  let slug = pathname.substring(13);
  // function for caculating average reviews
  const giveAverageRatings = (data) => {
    let rate = 0;
    for (let i = 0; i < data.length; i++) {
      rate = rate + data[i].rating;
    }
    const finalRate = rate / data.length;
    return finalRate;
  };
  //calling useEffect for collecting average reviews
  useEffect(() => {
    db.collection("feedbacks")
      .where("product_id", "==", component?.item?.objectId)
      .get()
      .then((snapshots) => {
        let data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        setRatingsData(data);
      });
  }, [project]);

  return (
    <>
      {!open ? (
        <Paper elevation={3} className={classes.root}>
          {(component?.item?.type !== "solar_panel") &
          (component?.item?.type !== "inverter") ? (
            window.location.pathname.includes("feedback") ? (
              " "
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon
                  onClick={() => removeComponent(component?.item?.type)}
                  className={classes.closeIcon}
                />
              </div>
            )
          ) : (
            <></>
          )}
          <div className={classes.rootContainer}>
            <div className={classes.Flex_Helper}>
              {component?.item?.is_recommand === "true" ? (
                <div
                  style={{
                    backgroundColor: "#FBDA61",
                    borderRadius: "36px",
                    height: "27px",
                    width: "154px",
                  }}
                >
                  {isEnglish ? "Recommendation" : "Empfehlung"}
                </div>
              ) : (
                ""
              )}
              <div className={classes.imgContainer}>
                <img
                  alt=""
                  src={component?.item?.imgurl1}
                  className={classes.img}
                />
              </div>
            </div>
            <div className={classes.detailsContainer}>
              {solarPanel ? (
                <BtnRow1 component={component} />
              ) : (
                <BtnRow2 component={component} />
              )}
              <h1>{tagToName[component?.item?.type]}</h1>
              <h3>{component?.item?.name}</h3>
              {Array.isArray(component?.item?.bullet_point_list)
                ? component?.item?.bullet_point_list?.map((feature) => (
                    <Typography>
                      <div />
                      {feature}
                    </Typography>
                  ))
                : null}
              <Typography
                style={{
                  margin: "0px",
                  marginLeft: "-15px",
                  marginTop: "-5px",
                }}
              >
                {window.location.pathname.includes("feedback") ? (
                  ""
                ) : (
                  <>
                    {component?.item?.type === "solar_panel" ||
                    component?.item?.type === "inverter" ? (
                      <>
                        <Checkbox
                          checked={component?.extended_warranty}
                          onChange={() => toggleWarranty(component)}
                          name="checkedB"
                          color="primary"
                        />
                        {isEnglish ? (
                          <>
                            Increase product warranty to 25 years (€
                            {component?.item?.extended_warranty_cost} per unit)
                          </>
                        ) : (
                          <>
                            Increase product warranty to 25 years (€
                            {component?.item?.extended_warranty_cost} per unit)
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              {!window.location.pathname.includes("feedback") ? (
                ratingsData.length > 0 ? (
                  <div
                    className={classes.reviews}
                    onClick={() => setFeedbackDialog(true)}
                  >
                    {ratingsData.length && (
                      <Rate
                        disabled={true}
                        defaultValue={giveAverageRatings(ratingsData)}
                      />
                    )}
                    <h5>
                      {isEnglish
                        ? `${ratingsData?.length} reviews`
                        : `${ratingsData?.length} Bewertungen`}
                    </h5>
                  </div>
                ) : (
                  " "
                )
              ) : (
                ""
              )}
              <Button
                onClick={() => setProductDetailsDialog(true)}
                variant="contained"
                className={classes.btn2}
              >
                {isEnglish ? "Product Details" : "Produktdetails"}
              </Button>
              {window.location.pathname.includes("feedback") ? (
                " "
              ) : (
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="contained"
                  className={classes.btn3}
                >
                  {isEnglish ? "Change" : "Ändern"}
                </Button>
              )}
              <Card elevation={5} className={classes.cardHelper}>
                <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {costDetails(component, isEnglish, project?.isB2B)}
                </Typography>
              </Card>
              {window.location.pathname.includes("saved") ? (
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "/feedback",
                      state: {
                        id: slug.slice(6, 60),
                      },
                    });
                  }}
                  variant="contained"
                  className={classes.btn2}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {isEnglish ? "Feedbacks" : "Rückmeldungen"}
                </Button>
              ) : null}
            </div>
          </div>
        </Paper>
      ) : (
        <ChooseComponent
          type={component?.item?.type}
          isEnglish={isEnglish}
          setOpen={setOpen}
          component={component}
        />
      )}
      <ProductDetails
        open={productDetailsDialog}
        setOpen={setProductDetailsDialog}
        item={component?.item}
      />
      <FeedbackPopup
        open={FeedbackDialog}
        setOpen={setFeedbackDialog}
        data={ratingsData}
        averageRating={giveAverageRatings(ratingsData)}
      />
    </>
  );
};

const getNumber = (x) => Math.round(parseFloat(x ? x : 0));

const costDetails = (component, isEnglish, isB2B) => {
  if (component?.item) {
    const component_cost = getNumber(
      component?.item[isB2B ? "price_b2b" : "price_b2c"]
    );
    const warranty_cost = getNumber(
      component?.item?.extended_warranty_cost || component_cost * 0.01
    );

    const total_cost = component.extended_warranty
      ? component_cost + warranty_cost
      : component_cost;
    return `${component?.quantity}  x €${total_cost} = €${commaDotHandler(
      component?.quantity * total_cost,
      `${isEnglish ? "en" : "de"}`
    )} `;
  } else {
    return 0;
  }
};

const AddComponent = ({ isEnglish, setShowSub }) => {
  const useStyles = makeStyles((theme) => ({
    rootd: {
      width: "900px",
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "25px",
      "&:hover": {
        backgroundColor: "rgba(253, 184, 0, 0.1)",
        cursor: "pointer",
        transform: "scale(1.01)",
      },

      "@media (max-width: 922px)": {
        width: "600px",
      },
      "@media (max-width: 640px)": {
        width: "400px",
      },
      "@media (max-width: 430px)": {
        width: "87vw",
        height: "100px",
      },
    },
    container1d: {
      width: "600px",
      "@media (max-width: 922px)": {
        width: "200px",
      },
      "@media (max-width: 640px)": {
        width: "150px",
      },
      "@media (max-width: 430px)": {
        width: "auto",
        height: "auto",
      },
      imageHeler: {
        width: "80px",
        borderRadius: "50%",
        height: "80px",
        border: "none",
        backgroundColor: "#E2E2E2",
        "&:hover": {
          transform: "scale(1.5)",
          cursor: "pointer",
        },
        "@media (max-width: 430px)": {
          width: "50px",
          height: "50px",
        },
      },
      height: "150px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: "35px",
    },
    container2d: {
      width: "300px",
      height: "150px",
      "@media (max-width: 430px)": {
        height: "auto",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text1d: {
      fontSize: "25px",
      "@media (max-width: 922px)": {
        fontSize: "20px",
      },
      "@media (max-width: 560px)": {
        width: "150px",
        fontSize: "12px",
        fontWeight: "bold",
      },

      color: colors.darkBlue,
    },
    text2d: {
      fontSize: "18px",
      "@media (max-width: 640px)": {
        fontSize: "13px",
      },
      "@media (max-width: 450px)": {
        fontSize: "10px",
      },
      "@media (max-width: 922px)": {
        fontSize: "8px",
      },
    },
    imageHelper: {
      width: "75px",
      height: "75px",
      "@media (max-width: 640px)": {
        width: "50px",
        height: "50px",
      },
    },
  }));

  const classes = useStyles();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);

  return (
    <Paper
      elevation={3}
      className={classes.rootd}
      onMouseEnter={() => setWhichIconShouldBeVisible(true)}
      onMouseLeave={() => setWhichIconShouldBeVisible(false)}
      onClick={() => {
        setShowSub(true);
      }}
    >
      <div className={classes.container1d}>
        <Typography className={classes.text1d}>
          {isEnglish
            ? "ADD ADDITIONAL COMPONENTS"
            : "Zusätzliche Komponenten ergänzen"}
        </Typography>
      </div>
      <div className={classes.container2d}>
        <img
          alt=""
          className={classes.imageHeler}
          src={whichIconShouldBeVisible ? addComhover : cta}
        />
      </div>
    </Paper>
  );
};

const AddComponentSub = ({ isEnglish, component }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [component]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "900px",
      height: "120px",
      margin: "3px",
      display: "flex",
      "&:hover": {
        backgroundColor: "rgba(253, 184, 0, 0.1)",
        cursor: "pointer",
        transform: "scale(1.01)",
      },
      "@media (max-width: 922px)": {
        width: "600px",
      },
      "@media (max-width: 640px)": {
        width: "400px",
      },
      "@media (max-width: 410px)": {
        width: "323px",
        flexDirection: "row",
        height: "75px",
        marginTop: "10px",
        justifyContent: "space-between",
      },
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "25px",
    },
    imgContainer: {
      width: "75px",
      height: "75px",
      "@media (max-width: 430px)": {
        width: "95px",
        height: "43px",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50px",
      margin: "0px 35px 0px 35px",
      [theme.breakpoints.down("xs")]: {
        margin: "0px 8px 0px 25px",
      },
      backgroundColor: "#FFC220",
    },
    responsiveImage: {
      width: "50px",
      height: "50px",
      color: "white",
      "@media (max-width: 430px)": {
        width: "26.96px",
        height: "30.95px",
      },
    },
    responsiveImage2: {
      width: "50px",
      borderRadius: "50%",
      height: "50px",
      border: "none",
      backgroundColor: "#E2E2E2",
      "@media (max-width: 430px)": {
        width: "35px",
        height: "35px",
      },
    },
    container1: {
      width: "550px",
      "@media (max-width: 922px)": {
        width: "200px",
      },
      "@media (max-width: 640px)": {
        width: "150px",
      },
      "@media (max-width: 430px)": {
        width: "313px",
      },
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    container2: {
      width: "200px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text1: {
      fontSize: "25px",
      color: colors.darkBlue,
      "@media (max-width: 640px)": {
        fontSize: "18px",
      },
      "@media (max-width: 430px)": {
        fontSize: "13px",
        fontWeight: "600",
      },
    },
    text2: {
      fontSize: "18px",
      "@media (max-width: 640px)": {
        fontSize: "10px",
      },
    },
  }));

  const classes = useStyles();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);

  return (
    <>
      {!open ? (
        <Paper
          elevation={3}
          className={classes.root}
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          onClick={() => setOpen(true)}
        >
          <div className={classes.imgContainer}>
            <img
              alt=""
              className={classes.responsiveImage}
              src={`/images/application/application_step_2/${component.type}.svg`}
            />
          </div>

          <div className={classes.container1}>
            <div style={{ display: "flex" }}>
              <Typography className={classes.text1}>
                {component.title}
              </Typography>
              <img style={{ marginLeft: "6px" }} src={Question} alt="" />
            </div>
            <Typography className={classes.text2}>
              {component.details}
            </Typography>
          </div>
          <div className={classes.container2}>
            <img
              alt=""
              className={classes.responsiveImage2}
              src={whichIconShouldBeVisible ? addComhover : cta}
            />
          </div>
        </Paper>
      ) : (
        <ChooseComponent type={component.type} setOpen={setOpen} />
      )}
    </>
  );
};

const TopHeader = ({ isEnglish, project }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: "24px",
      display: "flex",
      alignItems: "center",
    },
    count__container: {
      fontSize: "50px",
      color: colors.darkBlue,
    },
    h2Helper: {
      fontSize: "10px",
      "@media (max-width: 922px)": {
        fontSize: "5px",
      },
    },
    detail__container: {
      margin: "35px 15px",
      "& h1": {
        margin: "0px",
        fontSize: "25px",
        color: colors.darkBlue,
        "@media (max-width: 450px)": {
          fontSize: "15px",
          fontWeight: "bold",
        },
      },
      "& p": {
        margin: "0px",
        color: colors.darkBlue,
        "@media (max-width: 430px)": {
          fontSize: "12px",
        },
      },
    },
    savings_container: {
      display: "flex",
      alignItems: "center",
      justifySelf: "flex-end",
      marginLeft: "100px",
      borderBottom: "2px solid #E7E7E7",
      padding: "2px",
      "@media (max-width: 922px)": {
        flexDirection: "column",
        padding: "0",
        height: "auto",
      },
      "@media (max-width: 477px)": {
        height: "auto",
        flexDirection: "row",
        fontSize: "12px",
        borderBottom: "10px solid #E7E7E7",
        marginLeft: "0px",
        display: "none",
      },
      height: "35px",
      borderRadius: "5px",
      "& h2": {
        color: colors.orangeSolid,
        fontSize: "25px",
        "@media (max-width: 922px)": {
          fontSize: "20px",
        },
      },
      "& p": {
        fontSize: "14px",
        marginLeft: "10px",
      },
      myclass: {
        marginLeft: "20px",
        "@media (max-width: 430px)": {
          marginLeft: "5px",
        },
      },
    },
  }));
  const classes = useStyles();
  const [costs_and_savings, set_costs_and_savings] = useState({});

  useEffect(() => {
    set_costs_and_savings(costsAndSavings(project));
    set_finacing_details(financingDetails(project));
  }, [project]);

  return (
    <div className={classes.root}>
      <div className={classes.count__container}>2</div>
      <div className={classes.detail__container}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <h1>
            {isEnglish
              ? "CHOOSE YOUR PREFERRED COMPONENTS"
              : "Wählen Sie ihre bevorzugten Komponenten"}
          </h1>
          <img className={classes.myclass} src={Question} alt="" />
        </div>
        <p>
          {isEnglish ? "CONFIGURE YOUR SYSTEM" : "Konfigurieren Sie ihr System"}
        </p>
      </div>
      <Card elevation={0} className={classes.savings_container}>
        <h2>
          {`${commaDotHandler(
            Math.round(
              costs_and_savings.electricity_cost_before +
                costs_and_savings.feedin_savings -
                financing_details.yearly_financing_amount
            ),
            `${isEnglish ? "en" : "de"}`
          )} €`}
        </h2>
        <Typography>
          {isEnglish ? "YEARLY SAVINGS IN €" : "Jährliche Einsparungen in €"}
        </Typography>
      </Card>
    </div>
  );
};

const BtnRow1 = ({ component }) => {
  const [selected, setSelected] = useState(1);

  const setComponent = (index, subtype) => {
    db.collection("products")
      .where("type", "==", component.item.type)
      .get()
      .then((snap) => {
        let data = [];
        let newsItem = {};
        snap.forEach((doc, key) => {
          if (doc.data().color[0] === subtype) {
            const prod = doc.data();
            newsItem = {
              ...doc.data(),
              newsId: doc.data().objectId,
              price_b2b: getNumber(doc.data().price_b2b),
              price_b2c: getNumber(doc.data().price_b2c),
            };
            if (prod.instock == "yes") {
              data.push(newsItem);
            }
          }
        });
        if (data.length > 0) {
          addComponent({
            item: data[0],
            quantity: component.quantity,
            extended_warranty: false,
          });
        }
      });
    setSelected(index);
  };

  function compare(a, b) {
    if (a.price_b2b < b.price_b2b) {
      return -1;
    }
    if (a.price_b2b > b.price_b2b) {
      return 1;
    }
    return 0;
  }

  const [trues, setTrues] = useState(false);
  const [trues1, setTrues1] = useState(false);
  const [onClickTure, setOnClickTure] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "165px",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "@media (max-width:560px)": {
        width: "100%",
      },
      borderRadius: "25px",
      backgroundColor:
        selected === 1
          ? "black"
          : trues1
          ? colors.darkBlue
          : trues
          ? "black"
          : colors.darkBlue,
      backgroundColor: trues1
        ? colors.darkBlue
        : selected === 1
        ? "black"
        : trues
        ? "black"
        : colors.darkBlue,
      mouse: "pointer",
    },
    btn1: {
      width: "50%",
      height: "100%",
      fontSize: "14px",
      color: "#fff",
      borderRadius: "50px",
      fontWeight: "medium",
      margin: "5px 0",
      backgroundColor: selected === 1 ? colors.darkBlue : "black",
      "&:hover": {
        cursor: "pointer",
        width: "50%",
        height: "100%",
        fontSize: "14px",
        backgroundColor: colors.darkBlue,
        color: "#fff",
        fontWeight: "medium",
        borderRadius: "50px",
        margin: "5px 0",
        justifyContent: "center",
        alignItems: "center",
      },
      backgroundColor: colors.darkBlue,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      mouse: "pointer",
    },
    btn2: {
      width: "50%",
      height: "100%",
      color: "#fff",
      backgroundColor: selected === 2 ? colors.darkBlue : "black",
      backgroundColor: "black",
      fontSize: "14px",
      borderRadius: "50px",
      margin: "5px 0",
      display: "flex",
      "&:hover": {
        cursor: "pointer",
        width: "50%",
        height: "100%",
        color: "#fff",
        fontSize: "14px",
        backgroundColor: "black",
        borderRadius: "50px",
        margin: "5px 0",
        borderRadius: "50px",
        justifyContent: "center",
        alignItems: "center",
      },
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        onClick={() => setComponent(1, "blue")}
        onMouseEnter={() => setTrues(true)}
        onMouseLeave={() => setTrues(false)}
        className={classes.btn1}
      >
        Blue
      </div>
      <div
        onClick={() => {
          setOnClickTure(true);
          setComponent(2, "black");
        }}
        onMouseEnter={() => setTrues1(true)}
        onMouseLeave={() => setTrues1(false)}
        className={classes.btn2}
      >
        Black
      </div>
    </div>
  );
};

const BtnRow2 = ({ component }) => {
  const [selected, setSelected] = useState(1);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "310px",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "25px",
      backgroundColor: "#fff",
      "@media (max-width: 560px)": {
        width: "100%",
      },
      mouse: "pointer",
      border: "1px solid black",
      padding: "0px 2px",
    },
    btn1: {
      width: "30%",
      height: "90%",
      fontSize: "14px",
      color: selected === 1 ? "#fff" : colors.darkBlue,
      fontWeight: selected === 1 ? "normal" : "bold",
      borderRadius: "50px",
      margin: "5px 0",
      //backgroundColor: selected === 1 ? colors.darkBlue : "black",
      backgroundColor: selected === 1 ? "black" : "#fff",
      display: "flex",
      "&:hover": {
        width: "30%",
        height: "90%",
        fontSize: "14px",
        cursor: "pointer",
        fontWeight: "medium",
        backgroundColor: "black",
        color: "white",
        borderRadius: "50px",
      },
      justifyContent: "center",
      alignItems: "center",
      mouse: "pointer",
    },
    btn2: {
      width: "30%",
      height: "90%",
      color: selected === 2 ? "#fff" : colors.darkBlue,
      //backgroundColor: selected === 1 ? "black" : colors.darkBlue,
      backgroundColor: selected === 2 ? "black" : "#fff",
      fontSize: "14px",
      fontWeight: selected === 2 ? "normal" : "bold",
      borderRadius: "50px",
      margin: "5px 0",
      "&:hover": {
        width: "30%",
        height: "90%",
        fontSize: "14px",
        cursor: "pointer",
        fontWeight: "medium",
        backgroundColor: "black",
        color: "white",
        borderRadius: "50px",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    btn3: {
      width: "30%",
      height: "90%",
      color: selected === 3 ? "#fff" : colors.darkBlue,
      //backgroundColor: selected === 1 ? "black" : colors.darkBlue,
      backgroundColor: selected === 3 ? "black" : "#fff",
      fontSize: "14px",
      fontWeight: selected === 3 ? "normal" : "bold",
      borderRadius: "50px",
      "&:hover": {
        width: "30%",
        height: "90%",
        fontSize: "14px",
        cursor: "pointer",
        fontWeight: "medium",
        backgroundColor: "black",
        color: "white",
        borderRadius: "50px",
      },
      margin: "5px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  const classes = useStyles();
  const setComponent = (index, subtype) => {
    db.collection("products")
      .where("type", "==", component.item.type)
      .get()
      .then((snap) => {
        let data = [];
        let newsItem = {};
        snap.forEach((doc, key) => {
          if (doc.data().subtype === subtype) {
            const prod = doc.data();
            newsItem = {
              ...doc.data(),
              newsId: doc.data().objectId,
              price_b2b: getNumber(doc.data().price_b2b),
              price_b2c: getNumber(doc.data().price_b2c),
            };
            if (prod.instock == "yes") {
              data.push(newsItem);
            }
          }
        });
        if (data.length > 0) {
          console.log(data, "data");
          data.sort(compare);
          addComponent({
            item: data[0],
            quantity: component.quantity,
            extended_warranty: false,
          });
        }
      });
    setSelected(index);
  };

  function compare(a, b) {
    if (a.price_b2b < b.price_b2b) {
      return -1;
    }
    if (a.price_b2b > b.price_b2b) {
      return 1;
    }
    return 0;
  }

  return (
    <div className={classes.root}>
      <div onClick={() => setComponent(1, "standard")} className={classes.btn1}>
        Standard
      </div>
      <div onClick={() => setComponent(2, "hybrid")} className={classes.btn2}>
        Hybrid
      </div>
      <div onClick={() => setComponent(3, "premium")} className={classes.btn3}>
        Premium
      </div>
    </div>
  );
};

const ChooseComponent = ({ type, setOpen, isEnglish, component }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "900px",
      height: "550px",
      display: "flex",
      flexDirection: "column",
      margin: "5px",
      borderRadius: "25px",
      "@media (max-width: 922px)": {
        width: "600px",
      },
      "@media (max-width: 430px)": {
        width: "310px",
      },
      border: `1px solid ${colors.ctaSolid}`,
    },
    title: {
      fontSize: "25px",
    },
    buttonContainer: {
      width: "800px",
      display: "flex",
      padding: "5px 5px",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0px auto",
      marginTop: "24px",
    },
    btn: {
      backgroundColor: "darkred",
      color: "#fff",
      borderRadius: "5px",
    },
    forMoreResponsive: {
      display: "flex",
      "@media (max-width: 922px)": {
        width: "300px",
      },
    },
    closeIcom: {
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.5)",
        transition: "0.3s",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.buttonContainer}>
        <Typography className={classes.title}>
          {isEnglish
            ? `Select ${tagToName[type]}`
            : `auswählen ${tagToName[type]}`}
        </Typography>
        <CloseIcon
          className={classes.closeIcom}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.forMoreResponsive}>
        <HorizontalNewsScroll
          setOpen={setOpen}
          type={type}
          component={component}
        />
      </div>
    </Paper>
  );
};

const HorizontalNewsScroll = ({ setOpen, type, component }) => {
  const [list, setList] = useState([]);
  const project = useSelector((state) => state.project);

  useEffect(() => {
    db.collection("products")
      .where("type", "==", type)
      .get()
      .then((snap) => {
        let data = [];
        let newsItem = {};
        snap.forEach((doc, key) => {
          const prod = doc.data();
          newsItem = { ...doc.data(), newsId: doc.data().objectId };
          if (prod.instock == "yes") {
            if (type == "inverter") {
              const solar_panel_quantity =
                project?.components?.items?.solar_panel?.quantity;
              const solar_panel_capacity =
                project?.components?.items?.solar_panel?.item?.capacity;
              const total_capacity = Math.round(
                solar_panel_capacity * solar_panel_quantity
              );
              if (prod.applicable_max_capacity >= total_capacity) {
                data.push(newsItem);
              }
            } else {
              data.push(newsItem);
            }
          }
        });
        setList(data);
      });
  }, [type]);

  const onProductSelection = (listItem) => {
    console.log(component?.quantity, "component?.quantity");
    addComponent({
      item: listItem,
      quantity: component?.quantity ? component?.quantity : 1,
    });
    setOpen(false);
  };
  const menu = list.map((listItem) => (
    <ProductEntity
      listItem={listItem}
      onProductSelection={onProductSelection}
    />
  ));
  return <HorizontalScroll1 menu={menu} />;
};
