import { Drawer } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import FeatureContributers from "./FeatureContributers";
import Popular from "Assets/community/logo2.svg";
import { ReactComponent as Logo } from "Assets/community/logo1.svg";
import FAQ from "Assets/community/faaq.svg";
import Saved from "Assets/community/saved.svg";
import Profile from "Assets/community/setting.svg";
import noti from "Assets/community/noti.svg";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import NearbyProjectsDrawer from "./NearbyProjectsDrawer";
import LanguageDropdown from "../../../../Components/languageDropdown";

const MobileDrawerWrapper = styled("div")`
  .navbar {
    background-color: white;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  .hub_button {
    background: #ffd75d;
    height: 42px;
    width: 205px;
    border: none;
    border-radius: 42px;
    @media (max-width: 600px) {
      height: 40px;
      margin-top: 0px;
    }
    margin-bottom: 3px;
    color: rgba(45, 71, 100, 1);
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-menu {
    background-color: rgba(255, 255, 255, 1);
    margin-top: 3px;
    width: 218px;
    height: 100vh;
    display: flex;
    @media (max-width: 600px) {
      height: auto;
    }
    justify-content: start;
    flex-direction: column;
    transition: 850ms;
  }
  .solar_text {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    color: #ffff;
    @media (max-width: 600px) {
      padding-left: 0px !important;
    }
    div {
      font-family: Comfortaa;
      font-style: normal;
      font-weight: bold;
      width: 133px;
      font-size: 23.0681px;
      line-height: 26px;
      letter-spacing: 0.1em;
      color: #ffbf1b;
    }
  }
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  #active {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    /* dark blue */
    color: #2d4764;
  }
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 60px;
  }
  .nav-text .link {
    text-decoration: none;
    color: #c7c7c7;
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    @media (max-width: 600px) {
      font-size: 17px;
    }
  }
  .nav-text > .link:hover {
    cursor: pointer;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #2d4764;
  }
  .nav-menu-items {
    padding: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-menu-items.open {
    width: 225px;
  }
  .upper_class {
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .upper_class::-webkit-scrollbar {
    display: none;
  }
  .navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .yourSpan {
    margin-left: 20px;
  }
  .new_class {
    width: 306px;
  }
  .nearBy_project {
    background: #ffffff;
    height: 42px;
    width: 205px;
    border: none;
    @media (max-width: 600px) {
      height: 40px;
    }
    border-radius: 42px;
    margin-bottom: 3px;
    color: rgba(45, 71, 100, 1);
    border: 1px solid #2d4764;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .line_line {
    width: 100%;
    height: 0px;
    border: 1px solid #dfdfdf;
  }
`;

const Routes = [
  {
    title: "Home",
    path: "/community/home",
    icon: <Logo className="home__icon" />,
    cName: "nav-text",
    germanTitle: "Home",
  },
  {
    title: "Popular",
    path: "/community/popular",
    icon: <img src={Popular} alt="popular" />,
    cName: "nav-text",
    germanTitle: "Beliebte Themen",
  },
  {
    title: "Notification",
    path: "/community/notification",
    icon: <img src={noti} alt="notification" />,
    cName: "nav-text",
    germanTitle: "Benachrichtigungen",
  },
  {
    title: "FAQ",
    path: "/community/faq",
    icon: <img src={FAQ} alt="faq" />,
    cName: "nav-text",
    germanTitle: "FAQ",
  },
  {
    title: "Saved",
    path: "/community/saved",
    icon: <img src={Saved} alt="saved" />,
    cName: "nav-text",
    germanTitle: "Gespeicherte Themen",
  },
  {
    title: "Profile",
    path: "/community/profile",
    icon: <img src={Profile} alt="home" />,
    cName: "nav-text",
    germanTitle: "Profil",
  },
];

const MobileDrawer = ({ visible, setVisible }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setVisible(false);
  };
  const history = useHistory();
  return (
    <div>
      <Drawer placement="right" onClose={onClose} visible={visible}>
        <MobileDrawerWrapper>
          <div>
            <nav className="nav-menu">
              <div
                className="solar_text"
                style={{
                  justifyContent: isEnglish ? "center" : "flex-start",
                  paddingLeft: isEnglish ? "" : "34px",
                }}
              >
                <div>Solar Community</div>
              </div>
              <div className="line_line"></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ul className={`nav-menu-items ${!isEnglish && "open"}`}>
                  {Routes.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={item.cName}
                        id={location.pathname == item.path ? "active" : ""}
                      >
                        <div
                          className="link"
                          onClick={() => {
                            history.push(item.path);
                            setVisible(false);
                          }}
                        >
                          {item.icon}
                          <span
                            className="yourSpan"
                            id={location.pathname == item.path ? "active" : ""}
                          >
                            {isEnglish ? item.title : item.germanTitle}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <button
                className="hub_button"
                onClick={() => {
                  setVisible(false);
                  history.push("/community/knowledgehub");
                }}
              >
                {isEnglish ? "Knowledge hub" : "Solarwissen"}
              </button>
              <button
                className="nearBy_project"
                onClick={() => {
                  setVisible(false);
                  setOpen(true);
                }}
              >
                {isEnglish ? "Successful projects" : "Erfolgreiche Projekte"}
              </button>
              <div style={{ margin: "auto", marginTop: "10px" }}>
                <LanguageDropdown />
              </div>
              <FeatureContributers />
            </nav>
          </div>
        </MobileDrawerWrapper>
      </Drawer>
      <NearbyProjectsDrawer open={open} setOpen={setOpen} />
    </div>
  );
};

export default MobileDrawer;
