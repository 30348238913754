import React from "react";

const Back = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1055 7.58008L9.61715 12.0011L14.1055 16.4222"
        stroke="#666666"
        stroke-width="1.18535"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1826 23.4C18.5881 23.4 23.7652 18.2875 23.7652 12C23.7652 5.71246 18.5881 0.6 12.1826 0.6C5.77714 0.6 0.6 5.71246 0.6 12C0.6 18.2875 5.77714 23.4 12.1826 23.4Z"
        stroke="#666666"
        stroke-width="1.2"
      />
    </svg>
  );
};

export default Back;
