import React, { useState, useEffect, useRef } from "react";
import { Divider, StepConnector, Fab } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import WelcomeUserNoAddressSelected from "popups/welcomeUserNoAddress/WecomeUserNoAddress";
import "react-toastify/dist/ReactToastify.css";
import NavigationIcon from "@material-ui/icons/Navigation";
import AddIcon from "@material-ui/icons/Add";
import Stepper from "@material-ui/core/Stepper";
import { useDispatch, useSelector } from "react-redux";
import Step from "@material-ui/core/Step";
import { openPopupWidget } from "react-calendly";
import Polygon from "./partials/images/Polygon.svg";
import talktoExpert from "Assets/vectors/talktoexpert.svg";
import askCommunity from "Assets/vectors/askcommunity.svg";
import cometChat from "Assets/vectors/cometchat.svg";
import closeIcon from "Assets/vectors/closeicon.svg";
import PolygonLeft from "./partials/images/PolygonLeft.svg";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SystemLayout from "./partials/application.step1";
import Configuration from "./partials/application.step2";
import UploadPictures from "./partials/application.step3";
import DashboardHeader from "../../Components/dashboardHeader";
import Tenders from "./partials/application.step4";
import { connect } from "react-redux";
import { colors } from "../../colors";
import Count from "Assets/Count.svg";
import { db } from "../../firebase";
import { useHistory } from "react-router-dom";
import Loader from "../../Components/loader";
import { useLocation } from "react-router-dom";
import { parseReverseGeo } from "../../utils/geo";
import SignUpDialog from "../../popups/SignUpSignIn/signup.popup";
import { useProjectTools } from "hooks/useProjectTools";
import {
  costsAndSavings,
  energyDetails,
  financingDetails,
} from "../../utils/costs";
import DetaildCalculation from "./partials/DetaildCalculation";
import PaymentDoneMessage from "./partials/PaymentDoneMessage";
import SelectYearlyConsumption from "../../Components/yearlyConsumption.dialog";
import {
  calculated_consumption,
  calculated_consumption_b2b,
} from "../../utils/consumption";
import MultipleProjects from "../../popups/multipleProjects/MultipleProjects";
import StepperHeader from "../../Components/molecules/stepper.header";
import IncludedServices from "../../popups/includedServices/IncludeServices";
import { withStyles } from "@material-ui/styles";
import YearlyConsumptionb2bDialog from "Components/yearlyConsumptionb2bDialog";
import ProjectAnalyzedPopup from "popups/projectAnalyzed";
import IsStagingUser from "popups/IsStaginUser/IsStaginUser";
import YoutubeLiveIframe from "./partials/youtubeLiveIframe";

const Application = ({
  currentUser,
  isEnglish,
  project,
  dispatchMethods,
  quote,
  isLoading,
}) => {
  const {
    addComponent,
    setConsumption,
    setProjectAsync,
    setLoading,
    fetchQuoteStart,
    setQuoteStatus,
    addProjectAsync,
    setConsumptionDetails,
    setProject,
    setLanguageAsEnglish,
  } = dispatchMethods;

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        width: "100vw",
        marginTop: "5px",
        minHeight: "90vh",
      },
      stepper_outer: {
        width: "100%",
        maxWidth: "1350px",
        minHeight: "75vh",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      stepper_inner: {
        width: "100%",
        "@media (max-width: 560px)": {
          fontSize: "12px",
        },
      },
      forLast: {
        width: "99%",
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "-25px",
        marginTop: "5px",
        "@media (max-width: 430px)": {
          marginBottom: "0px",
        },
      },

      stepper_container: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width: 900px)": {
          display: "block",
        },
      },
      big_step_button: {
        fontSize: "50px",
        backgroundColor: "#fff",
        height: "500px",
        width: "86px",
        minWidth: "86px",
        display: "flex",
        justifyContent: "center",
        // alignSelf: "center",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "rgba(253, 184, 0, 0.1)",
          width: "86px",
          borderRadius: "22px",
          cursor: "pointer",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        },
        "@media (max-width: 900px)": {
          display: "none",
        },
      },
      big_step_button_icon: {
        fontSize: "100px",
        color: colors.darkBlue,
        "&:hover": {
          paddingLeft: "4px",
          transition: "0.3s",
        },
      },
      small_step_button: {
        display: "none",
        "@media (max-width: 900px)": {
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
        "@media (max-width: 500px)": {
          marginBottom: "22px",
        },
      },
      buttonDiv: {
        width: "1200px",
        alignSelf: "center",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 430px)": {
          justifyContent: "space-around",
          marginTop: "10px",
        },
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100vw",
        display: "flex",
        justifyContent: "center",
      },
      active: {
        color: "rgba(45, 71, 100, 1) !important",
      },
      icon: {
        background: "rgba(45, 71, 100, 1) !important",
      },
      rootss: {
        "&:hover": {
          cursor: "pointer",
        },
      },
      text: {
        "&.MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
          margin: "0px",
        },
        fontWeight: "600",
        "&:hover": {
          cursor: "pointer",
        },
      },
      label: {
        "&.MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
          margin: "0px",
        },
        margin: "0px",
        fontSze: "10px",
        "&:hover": {
          cursor: "pointer",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
      },
      customLabelStyle: {
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
        "&:hover": {
          cursor: "pointer",
          color: "#000",
        },
      },
      stepperActive: {
        borderColor: "rgba(48, 72, 100, 1) !important",
        [theme.breakpoints.down("xs")]: {
          borderTopWidth: "3px",
        },
        borderTopWidth: "5px",
      },
      stepperComplete: {
        borderColor: "rgba(48, 72, 100, 1) !important",
        [theme.breakpoints.down("xs")]: {
          borderTopWidth: "3px",
        },
        borderTopWidth: "5px",
      },
      completed: {
        color: "rgba(45, 71, 100, 1) !important",
      },
      connectorLine: {
        borderTopWidth: "5px",
        [theme.breakpoints.down("xs")]: {
          borderTopWidth: "3px",
        },
      },
      headersetup: {
        "&:hover": {
          cursor: "pointer",
        },
      },
      removePadding: {
        padding: "0px",
        paddingBottom: "9px",
        paddingTop: "20px",
      },
      newdiv: {
        height: "1px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        width: "100%",
      },
      BackButton: {
        margin: "15px 35px",
        backgroundColor: "#fff",
        color: colors.darkBlue,
        [theme.breakpoints.down("xs")]: {
          margin: "0px",
        },
        borderRadius: "25px",
        width: "100px",
        border: `1px solid ${colors.darkBlue}`,
      },
      needMargin: {
        boxShadow: "0px 2px 4px rgb(0 0 0 / 15%)",
        height: "2px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "10px",
        },
        backgroundColor: "#fff",
      },
      bottomFixedIcons: {
        display: "flex",
        justifyContent: "center",
        gap: 25,
        position: "fixed",
        bottom: "30px",
        right: "30px",
        alignItems: "center",
        zIndex: 100,
        [theme.breakpoints.down("xs")]: {
          gap: "11px",
          flexDirection: "column",
          alignItems: "self-end",
          bottom: "81px",
        },
      },
      bottomFixedIconsForMobile: {
        position: "fixed",
        bottom: "15px",
        right: "30px",
        zIndex: 100,
      },
      askTheCommunity: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        width: "61px",
        overflow: "hidden",
        height: "54px",
        transition: "width 0.5s",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px 15px",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "#eee",
          cursor: "pointer",
          width: isEnglish ? "257px" : "328px",
        },
      },
      talkToOurExpert: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        width: "61px",
        overflow: "hidden",
        height: "54px",
        transition: "width 0.5s",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px 15px",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "#eee",
          cursor: "pointer",
        },
      },
      cometChat: {
        backgroundColor: "white",
        padding: "10px 15px",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#eee",
          cursor: "pointer",
        },
      },
      textSize: {
        fontWeight: 300,
        fontSize: 20,
        paddingLeft: "10px",
        color: "#2D4764",
      },
    })
  );

  const classes = useStyles();
  const [show_chat, setshow_chat] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const location = useLocation();
  const { pathname } = location;
  let slug = pathname.substring(13);
  const dispatch = useDispatch();
  const coordinates1 = useSelector(
    (state) => state.user?.geometry?.coordinates
  );
  const cometChatUser = useSelector((state) => state.chat?.user);
  const searchResult1 = useSelector((state) => state.user?.geometry);
  const coordinates = useSelector(
    (state) => state.project?.address?.geometry?.coordinates
  );
  const searchResult = useSelector(
    (state) => state?.project?.address?.geometry
  );
  const isComingFromUserOverView = useSelector(
    (state) => state.global.isComingFromUserOverView
  );
  const [signUpDialog, setSignUpDialog] = useState(false);
  const [includedServicesDialog, setIncludedServicesDialog] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [multipleProjectsDilog, setMultipleProjectsDilog] = useState(false);
  const [onAutomateiclySave, setOnAutomateiclySave] = useState(false);
  const [yearlyConsumptionDialog, setYearlyConsumptionDialog] = useState(false);
  const [allProjets, setAllProjects] = useState([]);
  const { save, fetchConstants, fetchSingleConstants } = useProjectTools();
  const [consumption, setConsumption1] = useState("");
  const [projectAnalyzedPopup, setprojectAnalyzedPopup] = useState(false);
  const [mapaddress, setmapaddress] = useState(null);
  const [showWebinarDialog, setShowWebinarDialog] = useState(false);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [minimized, setminimized] = useState(false);
  const [minimizedUser, setminimizedUser] = useState(null);

  const isImageIsFeatched = useSelector(
    (state) => state.quote.isImageIsFeatched
  );
  const quotestatus = useSelector((state) => state.project.quoteStatus);
  const [showYoutubeLiveIframe, setShowYoutubeLiveIframe] = useState(
    localStorage.getItem("joinedWebinar") ? true : false
  );

  const [userLoaded, setUserLoaded] = useState(false); // dont' know if user logging is in the process by firebase, so firebase will attempt auth
  const history = useHistory();
  const goOnStepFour = useSelector((state) => state.global.goOnStepFour);

  useEffect(() => {
    fetchConstants();
    fetchSingleConstants();
    if (!currentUser) {
      localStorage.removeItem("joinedWebinar");
    }
  }, []);

  useEffect(() => {
    const func = () => {
      db.collection("quotes")
        .where("addr1", "==", mapaddress.address)
        .get()
        .then((snapshots) => {
          let data = [];
          snapshots.forEach((doc) => {
            data.push(doc.data());
          });
          if (data.length > 0 && data[0]?.isVerified === "true") {
            console.log("isVerified", data[0].isverified);
            console.log("found a address");
            console.log(data[0].qrn, "qrn_rush");
            setprojectAnalyzedPopup(true);
          } else {
            setTimeout(func, 10000);
            console.log("not found an address");
          }
        });
    };

    if (quotestatus == "notFound" && mapaddress.address !== null) {
      func();
    }
  }, [quotestatus]);

  useEffect(() => {
    if (goOnStepFour) {
      setActiveStep(3);
    }
  }, [goOnStepFour]);

  useEffect(() => {
    if (project?.project_type === "b2b") {
      setConsumption1(
        Math.round(calculated_consumption_b2b(project?.consumption_details_b2b))
      );
    } else {
      setConsumption1(
        Math.round(calculated_consumption(project?.consumption_details))
      );
    }
  }, [project]);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("b2b") && url.includes("default")) {
      dispatch({ type: "SET_PROJECTTYPE", payload: "b2b" });
    } else {
      dispatch({ type: "SET_PROJECTTYPE", payload: "b2c" });
    }
  }, []);

  useEffect(() => {
    if (project?.payment?.status === "succeeded") {
      setActiveStep(3);
    }
  }, [project?.payment?.status]);

  useEffect(() => {
    if (window.location.pathname.includes("userdashboard")) {
      document.title = "SolarHub - Project configurator";
    } else if (window.location.pathname.includes("admin")) {
      document.title = "SolarHub - Admin panel";
    } else {
      document.title = isEnglish
        ? "SolarHub - PV project configurator"
        : "SolarHub - PV Projekt Konfigurator";
    }
  }, [pathname]);

  useEffect(() => {
    if (project?.payment?.status === "succeeded") {
    } else {
      fetchConstants();
    }
    fetch("https://ipinfo.io/?token=7340f1287afe2a")
      .then((res) => res.json())
      .then((data) => {
        const country = data.country;
        if (country === "DE") {
          setLanguageAsEnglish(false);
        }
      })
      .catch((e) => JSON.stringify(e));
  }, []);

  const isCurrentUserLoading = useSelector((state) => {
    console.log(
      "isCurrentUserLoading updated with, ",
      state.user.isCurrentUserLoading
    );
    return state.user.isCurrentUserLoading;
  });
  useEffect(() => {
    console.log("USER LOADED", !isCurrentUserLoading);

    if (isCurrentUserLoading) return;

    if (location.search.includes("signUpForWebinar")) {
      if (!currentUser) {
        setSignUpDialog(true);
        setShowWebinarDialog(false);
      } else {
        setShowWebinarDialog(true);
        setSignUpDialog(false);
      }
    }
    // setUserLoaded(true);
  }, [location.search, currentUser, isCurrentUserLoading]);

  useEffect(() => {
    setLoading(true);

    if (slug.includes("default")) {
      const address = slug.substring(8).replace(" ", "-").replace(",", "");
      fetch(
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          address +
          ".json?access_token=pk.eyJ1IjoiY2hlbHVkbyIsImEiOiJjazRhMWY0eG8wMHFiM2ttdXk2cnEwMXNoIn0.qGONrbtgEqaTGD5beE7KBw"
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "we were data");
          if (data.features !== undefined) {
            const selectedAddress = parseReverseGeo(data?.features[0]);
            dispatch({
              type: "SET_GEO_CORDINENTS_IF_NO_QUOTE",
              payload: selectedAddress.geometry,
            });
            setmapaddress(selectedAddress);
            fetchQuoteStart(selectedAddress);
          } else {
            setLoading(false);
          }
        });
    } else if (slug.includes("custom")) {
      setLoading(true);
      db.collection("saved_projects")
        .doc(slug.slice(7, 61))
        .get()
        .then((doc) => {
          setProjectAsync(doc.data()?.project_details);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (slug.includes("saved")) {
      if (slug.length !== 5 && slug.length !== 60) {
        alert("Invalid URL 1");
        history.push("/application");
        return;
      }
      if (currentUser && currentUser !== "undefined") {
        if (slug.length === 5) {
          db.collection("projects")
            .where("uid", "==", currentUser.uid)
            .get()
            .then((snap) => {
              const all_projects = [];
              snap.forEach((doc) => {
                all_projects.push(doc.data());
              });
              if (all_projects.length === 0) {
                setAllProjects(all_projects);
                alert("You do not have any saved projects");
                history.push("/application");
              } else {
                setAllProjects(all_projects);
                setMultipleProjectsDilog(true);
              }
            })
            .catch((e) => history.push("/application"));
        }
        if (slug.length === 60)
          if (currentUser?.uid !== slug.slice(6, 34)) {
            alert("You do not have permission to view this page");
            history.push("/application");
            return;
          } else {
            setLoading(true);
            db.collection("saved_projects")
              .doc(slug.slice(6, 60))
              .get()
              .then((doc) => {
                setProjectAsync(doc.data()?.project_details);
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
              });
          }
      }
    } else {
      setQuoteStatus("default");
      addProjectAsync(quote);
    }
  }, [currentUser, setProjectAsync, addComponent, setConsumption, pathname]);

  //useEffect for autometic saving the project
  useEffect(() => {
    if (currentUser && currentUser !== "undefined") {
      if (slug?.includes("saved")) {
        if (
          isImageIsFeatched &&
          quote?.status === "matched" &&
          !isComingFromUserOverView
        ) {
          save(project);
        } else {
        }
      }
    }
  }, [project, isImageIsFeatched, quote?.status, isComingFromUserOverView]);

  function getSteps() {
    return [
      isEnglish ? "System Layout" : "Dachprofil",
      isEnglish ? "Configuration" : "Konfiguration",
      isEnglish ? "Upload pictures" : "Bilder hochladen",
      isEnglish ? "Call for Tender" : "Angebote einholen",
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <SystemLayout
            allProjets={allProjets}
            scrollToBottom={scrollToBottom}
            currentUser={currentUser}
            isEnglish={isEnglish}
            project={project}
            dispatchMethods={dispatchMethods}
            quote={quote}
            includedServicesDialog={includedServicesDialog}
            setIncludedServicesDialog={setIncludedServicesDialog}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            text={text}
            setText={setText}
            setYearlyConsumptionDialog={setYearlyConsumptionDialog}
          />
        );
      case 1:
        return (
          <Configuration
            currentUser={currentUser}
            isEnglish={isEnglish}
            project={project}
            dispatchMethods={dispatchMethods}
          />
        );
      case 2:
        return (
          <UploadPictures
            currentUser={currentUser}
            isEnglish={isEnglish}
            project={project}
            dispatchMethods={dispatchMethods}
          />
        );
      case 3:
        return (
          <Tenders
            currentUser={currentUser}
            isEnglish={isEnglish}
            project={project}
            dispatchMethods={dispatchMethods}
            quote={quote}
            includedServicesDialog={includedServicesDialog}
            setIncludedServicesDialog={setIncludedServicesDialog}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        );
      case 4:
        return <h1>Ausschreibung</h1>;
      case 5:
        return <h1>Umsetzung</h1>;
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    set_total_steps_completed_index();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (window.innerWidth < 500) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const set_total_steps_completed_index = () => {
    if (activeStep === project?.total_steps_completed_index) {
      setProject({ ...project, total_steps_completed_index: activeStep + 1 });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (window.innerWidth < 500) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    if (step === 2) {
      if (window.innerWidth < 500) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
  };

  const saveProject = () => {
    if (currentUser && currentUser !== "undefined") {
      save(project);
    } else {
      setSignUpDialog(true);
      setOnAutomateiclySave(true);
    }
  };

  const StepperIconCompleted = () => {
    return <img src={Count} alt="" />;
  };

  useEffect(() => {
    if (window.innerWidth < 550) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, []);

  const isStaging = useSelector((state) => state.global.isStagingUser);
  useEffect(() => {
    if (window.location.href.includes("staging") && !isStaging) {
      dispatch({ type: "SET_USER_PASSWORD", payload: true });
      if (!isStaging) {
        setIsThatStagingUser(true);
      }
    }
  }, []);

  const [financing_details, set_financing_details] = useState({});
  const [energy_details, set_energy_details] = useState({});
  const [IsThatStagingUser, setIsThatStagingUser] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [welcomeUserNoAddressSelected, setWelcomeUserNoAddressSelected] =
    useState(false);
  const [mouseHover, setMouseHover] = useState(false);
  const [costs_and_savings, set_costs_and_savings] = useState({});
  const userAdressStatus = useSelector(
    (state) => state.user.isUserAdressEnterd
  );
  const [closeIframeTrigger, setcloseIframeTrigger] = useState(false);
  const [text, setText] = useState("");
  const [fullScreen, setFullScreen] = useState(false);

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 12px)",
      right: "calc(50% + 12px)",
    },
    lineVertical: {
      height: 20,
    },
    active: {
      "& $line": {
        borderColor: "rgba(48, 72, 100, 1)",
      },
    },
    completed: {
      "& $line": {
        borderColor: "rgba(48, 72, 100, 1)",
      },
    },
    line: {
      mouse: "pointer",
      borderTopWidth: 5,
      "@media (max-width: 500px)": {
        borderTopWidth: 3,
      },
      borderRadius: 1,
    },
  })(StepConnector);

  useEffect(() => {
    set_financing_details(financingDetails(project));
    set_energy_details(energyDetails(project));
    set_costs_and_savings(costsAndSavings(project));
  }, [project]);

  useEffect(() => {
    const getStep = localStorage.getItem("activeStep");
    console.log(typeof getStep, "getStep");
    if (getStep) {
      setActiveStep(parseInt(getStep));
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("saved")) {
      return;
    }
    if (userAdressStatus) {
      setWelcomeUserNoAddressSelected(true);
    }
  }, [userAdressStatus, location.pathname]);

  const closeYoutubeIframe = () => {
    setShowYoutubeLiveIframe(false);
    localStorage.removeItem("joinedWebinar");
    setcloseIframeTrigger((prev) => !prev);
  };

  const reDirectToCommunity = () => {
    if (
      (window.location.pathname.includes("default") ||
        window.location.pathname.includes("saved")) &&
      currentUser
    ) {
      localStorage.clear();
      console.log(searchResult1, "searchResult1");
      if (coordinates1 === undefined) {
        localStorage.setItem("coordinates1", JSON.stringify(coordinates));
      }
      localStorage.setItem("goBackUrl", window.location.pathname);
      localStorage.setItem("activeStep", activeStep);
      localStorage.setItem("searchResult1", JSON.stringify(searchResult1));
      localStorage.setItem("searchResult", JSON.stringify(searchResult));
      localStorage.setItem("coordinates", JSON.stringify(coordinates));
      history.push("/community/home");
    } else if (!currentUser) {
      setSignUpDialog(true);
    } else {
      setWelcomeUserNoAddressSelected(true);
    }
  };

  const minimizedHandler = (check, context) => {
    if (check == "close") {
      setminimized(false);
    } else {
      setminimized((prev) => !prev);
    }
    if (context?.item) {
      setminimizedUser(context.item?.uid);
    } else {
      setminimizedUser();
    }
  };

  return (
    <div className={classes.root}>
      <DashboardHeader
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        setshow_chat={setshow_chat}
        showYoutubeLiveIframe={showYoutubeLiveIframe}
        setShowYoutubeLiveIframe={setShowYoutubeLiveIframe}
        closeIframeTrigger={closeIframeTrigger}
        showWebinarDialog={showWebinarDialog}
      />
      <div className={classes.stepper_outer}>
        {project?.payment?.status === "succeeded" && (
          <PaymentDoneMessage
            costs_and_savings={costs_and_savings}
            isEnglish={isEnglish}
            financing_details={financing_details}
          />
        )}
        <MultipleProjects
          open={multipleProjectsDilog}
          allProjets={allProjets}
          setLoading={setLoading}
          setOpen={setMultipleProjectsDilog}
        />
        <div className={classes.stepper_inner}>
          <Stepper
            connector={<QontoConnector />}
            activeStep={activeStep}
            className={classes.removePadding}
            alternativeLabel
          >
            {steps.map((label, index) => (
              <Step key={label} className={classes.headersetup}>
                <StepLabel
                  // onMouseEnter={() => setMouseHover(true)}
                  // onMouseLeave={() => setMouseHover(false)}
                  // sx={{
                  //   borderTopWidth: "5px",
                  // }}
                  StepIconComponent={index === 0 && StepperIconCompleted}
                  StepIconProps={{
                    classes: {
                      root: classes.rootss,
                    },
                  }}
                  classes={{ label: classes.customLabelStyle }}
                  onClick={handleStep(index)}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Divider className={classes.needMargin} />
          <div className={classes.forLast}>
            {/* <Button
              onClick={() => save()}
              variant={"contained"}
              style={{
                backgroundColor: colors.ctaSolid,
                fontWeight: "bold",
                borderRadius: "25px",
                width: "100px",
                marginTop: "15px",
              }}
              startIcon={<img src={safe} alt="" />}
            >
              Save
            </Button> */}
          </div>
          <StepperHeader activeStep={activeStep} save={saveProject} />
          <div className={classes.stepper_container}>
            {activeStep === 0 ? (
              ""
            ) : (
              <div
                style={{
                  minHeight: activeStep === 0 ? "600px" : "600px",
                }}
                className={classes.big_step_button}
                disabled={activeStep === 0}
                onClick={() => activeStep !== 0 && handleBack()}
              >
                <img
                  src={PolygonLeft}
                  className={classes.big_step_button_icon}
                  alt=""
                />
              </div>
            )}
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            {activeStep === 3 ? (
              ""
            ) : (
              <div
                style={{
                  minHeight: activeStep === 0 ? "600px" : "600px",
                  marginTop: activeStep === 0 ? "44px" : "0px",
                }}
                className={classes.big_step_button}
                disabled={activeStep === steps.length - 1}
                onClick={() => activeStep !== steps.length - 1 && handleNext()}
              >
                <img
                  src={Polygon}
                  className={classes.big_step_button_icon}
                  alt=""
                />
              </div>
            )}

            <div className={classes.small_step_button} style={{}}>
              <div className={classes.buttonDiv}>
                <Button
                  disabled={activeStep === 0}
                  variant={"outlined"}
                  onClick={handleBack}
                  className={classes.BackButton}
                >
                  {isEnglish ? "Back" : "zurück"}
                </Button>
                <Button
                  disabled={activeStep === steps.length - 1}
                  variant="contained"
                  onClick={handleNext}
                  style={{
                    backgroundColor: colors.darkBlue,
                    color: "#fff",
                    borderRadius: "25px",
                    width: "100px",
                  }}
                >
                  {isEnglish ? "Next" : "weiter"}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "99%",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        ></div>
        {isExpanded ? (
          <div className={classes.bottomFixedIcons}>
            <CustomButton
              url="https://calendly.com/solarhub?hide_gdpr_banner=1"
              prefill={{
                email: currentUser?.email,
                firstName: currentUser?.displayName
                  ?.split(" ")
                  .slice(0, -1)
                  .join(" "),
                lastName: currentUser?.displayName
                  ?.split(" ")
                  .slice(-1)
                  .join(" "),
                name: currentUser?.displayName,
                date: new Date(Date.now() + 86400000),
              }}
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: "00a2ff",
                textColor: "4d5055",
              }}
              utm={{
                utmCampaign: "Spring Sale 2019",
                utmContent: "Shoe and Shirts",
                utmMedium: "Ad",
                utmSource: "Facebook",
                utmTerm: "Spring",
              }}
            />
            <div
              onClick={() => reDirectToCommunity()}
              className={classes.askTheCommunity}
            >
              <div>
                <img src={askCommunity} alt="askcommunity" />
              </div>
              <div className={classes.textSize}>
                {isEnglish ? "Ask the community" : "FRAGEN SIE DIE GEMEINDE"}
              </div>
            </div>
            {currentUser ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: 58,
                  minHeight: 51,
                  userSelect: "none",
                }}
                onClick={() => {
                  setshow_chat((prev) => !prev);
                  setminimized(false);
                }}
                className={classes.cometChat}
              >
                <div>
                  <img
                    src={show_chat ? closeIcon : cometChat}
                    alt="cometchat"
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          ""
        )}
        {window.innerWidth < 550 && !isExpanded ? (
          <div
            className={classes.bottomFixedIconsForMobile}
            onClick={() => {
              setIsExpanded(true);
              console.log("hey");
            }}
          >
            <Fab
              variant="extended"
              size="small"
              style={{ color: "#000", backgroundColor: "rgb(255, 215, 93)" }}
              aria-label="add"
            >
              <NavigationIcon sx={{ mr: 1 }} />
              {isEnglish ? "Contact" : "Kontakt"}
            </Fab>
          </div>
        ) : (
          ""
        )}
        {window.innerWidth < 550 && isExpanded ? (
          <div
            className={classes.bottomFixedIconsForMobile}
            onClick={() => {
              setIsExpanded(false);
              console.log("hey");
            }}
          >
            <Fab
              size="small"
              style={{ color: "#000", backgroundColor: "rgb(255, 215, 93)" }}
              aria-label="add"
            >
              <AddIcon style={{ transform: "rotate(45deg)" }} />
            </Fab>
          </div>
        ) : (
          ""
        )}
        {/*Pop Up */}
        <SignUpDialog
          signUpForWebinar={location.search.includes("signUpForWebinar")}
          open={signUpDialog}
          onAutomateiclySave={onAutomateiclySave}
          setOpen={setSignUpDialog}
          isEnglish={isEnglish}
          project={project}
        />
        <WelcomeUserNoAddressSelected
          open={welcomeUserNoAddressSelected}
          setOpen={setWelcomeUserNoAddressSelected}
          comingForCommunity={true}
        />
        <DetaildCalculation
          Visible={isModalVisible}
          project={project}
          setText={setText}
          text={text}
          setVisible={setIsModalVisible}
          energy_details={energy_details}
          costs_and_savings={costs_and_savings}
          financing_details={financing_details}
        />
        <IncludedServices
          open={includedServicesDialog}
          setOpen={setIncludedServicesDialog}
        />
        <IsStagingUser
          open={IsThatStagingUser}
          setOpen={setIsThatStagingUser}
        />
        <ProjectAnalyzedPopup
          open={projectAnalyzedPopup}
          setopen={setprojectAnalyzedPopup}
          address={mapaddress}
        />
        {project.project_type === "b2c" ? (
          <SelectYearlyConsumption
            open={yearlyConsumptionDialog}
            setOpen={setYearlyConsumptionDialog}
            consumption={consumption}
            consumption_details={project?.consumption_details}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
          />
        ) : (
          <YearlyConsumptionb2bDialog
            open={yearlyConsumptionDialog}
            setOpen={setYearlyConsumptionDialog}
            consumption={consumption}
            consumption_details={project?.consumption_details_b2b}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
          />
        )}
        <Loader loading={isLoading} color={colors.orangeSolid} />
        {/*--------------------------- */}
      </div>

      {showYoutubeLiveIframe ? (
        <div
          style={{
            backgroundColor: "#0000008f",
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            zIndex: 150,
            top: fullScreen ? 0 : 25,
            left: fullScreen ? 0 : 15,
            padding: fullScreen ? "1rem" : "0",
            width: fullScreen ? "100%" : "auto",
            height: fullScreen ? "100%" : "auto",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffd75d",
              borderRadius: 5,
              color: "#2d4764",
              display: "flex",
              fontSize: 16,
              fontWeight: 700,
              margin: ".5rem auto",
              padding: 10,
              width: "fit-content",
              overflow: "hidden",
              borderRadius: "10px",
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            }}
            onClick={closeYoutubeIframe}
          >
            Close webinar
          </div>
          <YoutubeLiveIframe
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            show={showYoutubeLiveIframe}
            setShow={setShowYoutubeLiveIframe}
            setcloseIframeTrigger={setcloseIframeTrigger}
          />
        </div>
      ) : null}

    </div>
  );
};

const cometChatstyle = (show_chat) => {
  let opacity = show_chat ? { opacity: 1 } : { opacity: 0 };
  let pointerEvent = show_chat ? {} : { pointerEvents: "none" };

  return {
    position: "fixed",
    right: 17,
    bottom: 100,
    zIndex: 100,
    transition: "all ease 0.5",
    ...opacity,
    ...pointerEvent,
  };
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isEnglish: state.global.isEnglish,
  isLoading: state.global.isLoading,
  project: state.project,
  quote: state.quote,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchMethods: {
    addComponent: (data) => dispatch({ type: "ADD_COMPONENT", payload: data }),
    removeComponent: (data) =>
      dispatch({ type: "REMOVE_COMPONENT", payload: data }),
    addInverter: (data) => dispatch({ type: "ADD_INVERTER", payload: data }),
    addBattery: (data) => dispatch({ type: "ADD_BATTERY", payload: data }),
    increaseSolarPanel: () => dispatch({ type: "INCREASE_SOLAR_PANEL" }),
    setRoofPitch: (data) => dispatch({ type: "SET_ROOF_PITCH", payload: data }),
    decreaseSolarPanel: (data) => dispatch({ type: "DECREASE_SOLAR_PANEL" }),
    toggleWarranty: (data) =>
      dispatch({ type: "TOGGLE_WARRANTY", payload: data }),
    setConsumption: (data) =>
      dispatch({ type: "SET_CONSUMPTION", payload: data }),
    setProjectImages: (data) =>
      dispatch({ type: "SET_PROJECT_IMAGES", payload: data }),
    setProjectAsync: (data) =>
      dispatch({ type: "SET_PROJECT_ASYNC", payload: data }),
    setProject: (data) => dispatch({ type: "SET_PROJECT", payload: data }),
    setProfile: (data) => dispatch({ type: "SET_PROFILE", payload: data }),
    setQRN: (data) => dispatch({ type: "SET_QRN", payload: data }),
    setVendor: (data) => dispatch({ type: "SET_VENDOR", payload: data }),
    setLoading: (data) => dispatch({ type: "SET_LOADING", payload: data }),
    fetchQuoteStart: (address) =>
      dispatch({ type: "FETCH_QUOTE_START", payload: address }),
    addProjectAsync: (address) =>
      dispatch({ type: "ADD_PROJECT_ASYNC", payload: address }),
    setVendor: (vendor) => dispatch({ type: "SET_VENDOR", payload: vendor }),
    setConsumptionDetails: (data) =>
      dispatch({ type: "SET_CONSUMPTION_DETAILS", payload: data }),
    setQuoteStatus: (data) =>
      dispatch({ type: "SET_QUOTE_STATUS", payload: data }),
    setLanguageAsEnglish: (data) =>
      dispatch({ type: "SET_LANGUAGE_AS_ENGLISH", payload: data }),
  },
});

const CustomButton = ({ url, prefill, pageSettings, utm }) => {
  const isEnglish = useSelector((state) => state?.global?.isEnglish);

  const useStyles = makeStyles((theme) =>
    createStyles({
      talkToOurExpert: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        width: "61px",
        overflow: "hidden",
        height: "54px",
        transition: "width 0.5s",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px 15px",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "#eee",
          cursor: "pointer",
          width: isEnglish ? "257px" : "391px",
        },
      },
      textSize: {
        fontWeight: 300,
        fontSize: 20,
        paddingLeft: "10px",
        color: "#2D4764",
      },
    })
  );
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
  const classes = useStyles();
  return (
    <div onClick={onClick} className={classes.talkToOurExpert}>
      <div>
        <img src={talktoExpert} alt="" />
      </div>
      <div className={classes.textSize}>
        {isEnglish
          ? "Talk to our experts"
          : "sprechen Sie mit unseren Experten"}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Application);
