import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core/";
import { DataGrid } from "@material-ui/data-grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { db } from "../../../firebase";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

//UserDetails section of Admin Dashboard
const Users = () => {
  const [userData, setUserData] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    //fetching details of all the users from database
    db.collection("users")
      .get()
      .then((snap) => {
        const data = [];
        snap.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
      });
  }, []);

  const columns = [
    { field: "fullName", width: 150 },
    { field: "email", width: 180 },
    { field: "phoneNumber", width: 150 },
    { field: "country", width: 150 },
    { field: "otherDetails", width: 150 },
  ];

  return (
    <>
      <Accordion expanded={true} style={{ flex: "1", width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>User Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
            }}
          >
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", minHeight: "65vh" }}
            >
              {userData.length > 0 ? (
                <DataGrid
                  rows={userData}
                  getRowId={(row) => {
                    console.log(row.uid);
                    return row.uid;
                  }}
                  columns={columns}
                  onCellEditCommit={(values, event) => {
                    handleChangeRow(values, event);
                  }}
                  pageSize={7}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Users;
