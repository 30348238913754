import React, { useEffect, useState } from "react";
import Forward1 from "Assets/community/Forward1.svg";
import styled from "styled-components";
import {
  Select,
  MenuItem,
  makeStyles,
  Box,
  CircularProgress,
} from "@material-ui/core";
import TopicList from "./partials/TopicList";
import Toback from "Assets/community/Toback.svg";
import InsideTopic from "./partials/InsideTopic";
import { getAllTopicsByCategoryId } from "api/discourse/get";
import { useSelector, useDispatch } from "react-redux";

const TopicWrapper = styled("div")`
  .middle_main {
    height: 615px;
    overflow-y: scroll;
    padding-left: 34px;
    overflow-x: hidden;
    padding-right: 34px;
    margin-top: 20px;
  }
  .middle_main::-webkit-scrollbar {
    width: 6px;
  }
  .middle_main::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .middle_main::-webkit-scrollbar-thumb {
    background: #888;
  }
  .middle_main::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .category_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #2d4764;
  }
  .header {
    margin-left: 34px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .dropdown {
    border: 1px solid #c0c0c0;
    box-sizing: border-box;
    border-radius: 16px;
    height: 27px;
    width: 111px;
  }
  @media (max-width: 600px) {
    .middle_main {
      padding: 0px;
      width: 100%;
      height: calc(100vh - 260px);
    }
    .header {
      margin: 0px;
      padding: 0px;
      width: 100%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: space-between;
      margin-top: 19px;
    }
    .category_text {
      padding: 0px !important;
      margin: 0px !important;
      font-size: 14px !important;
    }
    .flex {
      margin-left: 12px;
    }
    .lightbox-wrapper img {
      width: 77%;
    }
    .dropdown {
      display: flex;
    }
    .onebox {
      a {
        display: none;
      }
    }
  }
`;

const Topics = ({
  setIsTopicTrue,
  categoryId,
  directId,
  setOpen1,
  isOpen,
  categoryName,
  setIsHeadrThere,
}) => {
  const useStyles = makeStyles((theme) => ({
    rootFirstSelect: {
      padding: "3px 17px",
      fontSize: "14px",
    },
  }));
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [IsInsideTopicViewOn, setIsInsideTopicViewOn] = useState(false);
  const isTopicDeletedOrEdited = useSelector(
    (state) => state?.global?.isTopicDeletedOrEdited
  );
  const [topicData, setTopicData] = useState([]);
  const [dataForInsideTopic, setDataForInsideTopic] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [selected, setSelected] = useState("Newest");
  const dispatch = useDispatch();

  useEffect(() => {
    const func = async () => {
      if (categoryId) {
        const res = await getAllTopicsByCategoryId(categoryId);
        let arr = [...res.data];
        arr.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setTopicData(arr);
      }
    };

    func();
  }, [isTopicDeletedOrEdited]);

  useEffect(() => {
    if (directId !== null && topicData.length > 0) {
      setIsInsideTopicViewOn(true);
      setTopicId(directId);
    }
    setIsHeadrThere(true);
  }, [topicData]);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };
  useEffect(() => {
    if (selected === "Newest" || selected === "Newestt") {
      let arr = [...topicData];
      arr.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setTopicData(arr);
    } else {
      let arr = [...topicData];
      arr.sort(function (a, b) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setTopicData(arr);
    }
  }, [selected]);

  return (
    <TopicWrapper>
      <div>
        {IsInsideTopicViewOn ? (
          <InsideTopic
            setIsInsideTopicViewOn={setIsInsideTopicViewOn}
            categoryId={categoryId}
            data={dataForInsideTopic}
            setOpen1={setOpen1}
            isOpen={isOpen}
            topicData={topicData}
            setDataForInsideTopic={setDataForInsideTopic}
            topicId={topicId}
          />
        ) : (
          <>
            <div className="header">
              <div className="flex">
                <img
                  src={Toback}
                  onClick={() => {
                    setIsTopicTrue(false);
                    dispatch({
                      type: "SET_DIREACT_CATEGORY_SEARCH",
                      payload: {
                        category_id: null,
                        title: "",
                      },
                    });
                    const datass = {
                      flag: false,
                      category_id: null,
                      direactId: null,
                      title: "",
                    };
                    dispatch({ type: "SET_DIREACT_SEARCH", payload: datass });
                  }}
                  alt=""
                />
                <div className="category_text">
                  Topics - {categoryName !== null ? categoryName : ""}
                </div>
              </div>
              <div className="dropdown">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  className="select_class"
                  classes={{ root: classes.rootFirstSelect }}
                  IconComponent={() => (
                    <img
                      src={Forward1}
                      style={{ marginRight: "10px" }}
                      alt=""
                    />
                  )}
                  disableUnderline
                  defaultValue="Newest"
                  onChange={handleChange}
                >
                  <MenuItem value="Newest">
                    {isEnglish ? "Newest" : "Neueste"}
                  </MenuItem>
                  <MenuItem value="Oldest">
                    {isEnglish ? "Oldest" : "Älteste"}
                  </MenuItem>
                  <MenuItem value="Newestt">
                    {isEnglish ? "Trending" : "Im Trend"}
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div className="middle_main">
              {topicData.length > 0 ? (
                topicData.map((data) => {
                  return (
                    <TopicList
                      setIsInsideTopicViewOn={setIsInsideTopicViewOn}
                      setDataForInsideTopic={setDataForInsideTopic}
                      setTopicId={setTopicId}
                      data={data}
                      categoryId={categoryId}
                    />
                  );
                })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      color: "#fbda61",
                    }}
                  />
                </Box>
              )}
            </div>
          </>
        )}
      </div>
    </TopicWrapper>
  );
};

export default Topics;
