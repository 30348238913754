import { Dialog, Paper } from "@material-ui/core";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";

const ChnageLongText = ({ handleChange, value }) => {
  const [open, setOpen] = useState(true);
  const PaperStyle = {
    display: "flex",
    flexDirection: "column",
    width: 500,
    height: 300,
    padding: 40,
  };
  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <Paper style={PaperStyle} elevation={3}>
        <TextArea
          style={{ height: "100%", width: "100%" }}
          value={value}
          onChange={handleChange}
        />
      </Paper>
    </Dialog>
  );
};

export default ChnageLongText;
