import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

const colors = {
	darkRain: `linear-gradient(to left,#09152F,#1B4963)`,
	darkBlue: "#1B4963",
	greenHover: `linear-gradient(to left,#197061 ,#1B7A69)`,
	linear: `linear-gradient(to left,#FFE925 ,#FFAA39)`,
	sunny: `linear-gradient(to left,#FBDA61 ,#FFC371)`,
	sun: `linear-gradient(to left,#FBDA61 ,#F76B1C)`,
	sun_r: `linear-gradient(to right,#FBDA61 ,#F76B1C)`,
	fire: `linear-gradient(to left,#FF5F6D  ,#FFC371)`,
	orangeSolid: "#FFAA39",
	ctaSolid: "#FBDA61",
	lightYellow: "#FFF3CA",
};

const { ctaSolid } = colors;

const useStyles = makeStyles((theme) => ({
	c2_header: {
		display: "flex",
		width: "100%",
		borderRadius: "15px",
		height: "30px",
		alignItems: "center",
	},
	c2_header_button: {
		position: "relative",
		zIndex: "0", // changed due to comet chat issue
		flex: "1",
		width: "auto",
		textAlign: "center",
		background: "#FFF3CA",
		borderTopLeftRadius: "15px",
		borderTopRightRadius: "15px",
		height: "100%",
		paddingTop: "5px",
		cursor: "pointer",
		marginBottom: "-3px",
		[theme.breakpoints.down("xs")]: {
			paddingTop: "10px",
		},
		"&:hover": {
			fontWeight: "bold",
			backgroundColor: ctaSolid,
			transform: "scale(1.01)",
		},
	},
	c2_header_button_highlighted: {
		position: "relative",
		zIndex: "0", // changed due to comet chat issue
		flex: "1",
		width: "auto",
		textAlign: "center",
		backgroundColor: ctaSolid,
		borderTopLeftRadius: "15px",
		borderTopRightRadius: "15px",
		height: "100%",
		paddingTop: "5px",
		cursor: "pointer",
		[theme.breakpoints.down("xs")]: {
			paddingTop: "10px",
		},
		marginBottom: "-3px",
		"&:hover": {
			fontWeight: "bold",
			transform: "scale(1.01)",
		},
	},
}));

const TopHeader = () => {
	const classes = useStyles();
	const project = useSelector((state) => state.project);
	const isEnglish = useSelector((state) => state.global.isEnglish);
	const dispatch = useDispatch();
	const setIsFinancing = (data) => {
		dispatch({
			type: "SET_PROJECT",
			payload: { ...project, isFinancing: data },
		});
	};

	return (
		<div className={[classes.c2_header]}>
			<div
				onClick={() => {
					setIsFinancing(true);
				}}
				className={project.isFinancing ? classes.c2_header_button_highlighted : classes.c2_header_button}>
				{isEnglish ? "Finance" : "Finanzieren"}
			</div>
			<div
				onClick={() => {
					setIsFinancing(false);
				}}
				className={project.isFinancing ? classes.c2_header_button : classes.c2_header_button_highlighted}>
				{isEnglish ? "Buy" : "Kaufen"}
			</div>
		</div>
	);
};

export default TopHeader;
