import React, { useEffect, useRef, useState } from "react";
import OnlyImage from "../images/OnlyImage.png";
import { makeStyles, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import SelectYearlyConsumption from "../../../../Components/yearlyConsumption.dialog";
import Consumption_new_illustration from "Assets/Consumption_new_illustration.svg";
import Line1 from "../images/Line1.svg";
import LineMobile1 from "Assets/LineMobile1.svg";
import LineMobile2 from "Assets/LineMobile2.svg";
import LineMobile3 from "Assets/LineMobile3.svg";
import LineMobile4 from "Assets/LineMobile4.svg";
import Line2 from "../images/Line2.svg";
import gridtower from "../images/gridtower.png";
import { useDispatch, useSelector } from "react-redux";
import { energyDetails, costsAndSavings } from "../../../../utils/costs";
import { calculated_consumption } from "../../../../utils/consumption";
import { SelfSupplyInput } from "Components/consumptionProfile.dialog";
import { commaDotHandler } from "helperMethod";

const colors = {
  darkRain: `linear-gradient(to left,#09152F,#1B4963)`,
  darkBlue: "#1B4963",
  greenHover: `linear-gradient(to left,#197061 ,#1B7A69)`,
  linear: `linear-gradient(to left,#FFE925 ,#FFAA39)`,
  sunny: `linear-gradient(to left,#FBDA61 ,#FFC371)`,
  sun: `linear-gradient(to left,#FBDA61 ,#F76B1C)`,
  sun_r: `linear-gradient(to right,#FBDA61 ,#F76B1C)`,
  fire: `linear-gradient(to left,#FF5F6D  ,#FFC371)`,
  orangeSolid: "#FFAA39",
  ctaSolid: "#FBDA61",
  lightYellow: "#FFF3CA",
};

const useStyles = makeStyles((theme) => ({
  finace_container1: {
    width: "100%",
    margin: "10px 0px 10px 0px",
    display: "flex",
    height: "102px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    marginLeft: 50,
  },
  flexPro: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fontClass: {
    backgroundColor: "#C4C4C4",
    color: "black",
    borderRadius: "16px",
    width: "76px",
    textAlign: "center",
    fontSize: "15px",
    "@media (max-width: 560px)": {
      fontSize: "10px",
      width: "150px",
    },
    marginLeft: "25px",
    paddingTop: "3px",
    alignSelf: "center",
    height: "30px",
    "@media (max-width: 560px)": {
      fontSize: "14px",
    },
    "@media (max-width: 560px)": {
      fontSize: "14px",
    },
  },
  yourDiv: {
    backgroundColor: "#f8f8f8",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: "25px",
    width: "715px",
    height: "568px",
    margin: "auto",
    marginTop: "40px",
  },
  familyButton: {
    backgroundColor: "#fff",
    color: "black",
    borderRadius: "25px",
    fontSize: "15px",
    "@media (max-width: 560px)": {
      fontSize: "10px",
      width: "150px",
    },
    alignSelf: "center",
    height: "30px",
    border: `1px solid ${colors.darkBlue}`,
  },
  alignVertically: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  alignVerticallyMain: {
    display: "felx",
    marginRight: "150px",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  forH1: {
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "22px",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      marginBottom: "10px",
    },
    paddingTop: "30px",
  },
  alignVerticallyMain2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  mobileArrows: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "10px",
    alignItems: "center",
  },
  img1: {
    paddingLeft: "27px",
    height: "22px",
  },
  img2: {
    paddingRight: "32px",
    height: "22px",
  },
  makeSelfSuppluCenter: {
    width: "95%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "45px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  makeMobileGood: {
    width: "95%",
    height: "700px",
    background: "#F8F8F8",
    margin: "4px",
    borderRadius: "16px",
  },
}));

const DetailTab3 = ({ project, setYearlyConsumptionDialog }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const classes = useStyles();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerWidth > 500 ? "565px" : "700px",
      }}
    >
      <ElectricityFlows
        value1={9.0}
        value2={10.834}
        value3={6.503}
        value4={2.947}
        isEnglish={isEnglish}
        value5={4.424}
      />
    </div>
  );
};

const ElectricityFlows = ({
  value1,
  value2,
  value3,
  value4,
  value5,
  isEnglish,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const { total_production, total_self_supply, feed_into_grid } =
    energyDetails(project);
  const consumption = calculated_consumption(project?.consumption_details);
  const consumption_details = project?.consumption_details;
  const setConsumptionDetails = (data) =>
    dispatch({ type: "SET_CONSUMPTION_DETAILS", payload: data });

  return (
    <>
      {window.innerWidth > 500 ? (
        <div className={classes.yourDiv}>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "21px",
              marginTop: "24px",
              fontFamily: "Nunito",
              fontWeight: "600",
              fontSize: "22px",
              textTransform: "uppercase",
            }}
          >
            {isEnglish
              ? "Electricity flows in your building"
              : "Strom fließt in Ihrem Gebäude"}
          </h1>
          <div className={classes.alignVerticallyMain}>
            <h3>{isEnglish ? "Yearly consumption" : "Jahresverbrauch"}</h3>
            <h3 style={{ color: "#247D38" }}>
              {commaDotHandler(consumption, `${isEnglish ? "en" : "de"}`)}
              kWh
            </h3>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexPro}>
              <div className={classes.alignVertically}>
                <h3>{isEnglish ? "Generation" : "Generation"}</h3>
                <h3 style={{ color: "#FFAA39" }}>
                  {commaDotHandler(
                    total_production,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
              <div className={classes.alignVertically}>
                <h3>
                  {isEnglish ? "Self supply by Pv" : "Eigenversorgung durch Pv"}
                </h3>
                <h3 style={{ color: "#304864" }}>
                  {commaDotHandler(
                    total_self_supply,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
            </div>
            <img
              src={Consumption_new_illustration}
              style={{ marginRight: "15px", width: "247px" }}
              alt=""
            />
            <div className={classes.flexPro}>
              <div className={classes.alignVertically}>
                <h3>{isEnglish ? "Grid supply" : "Netzversorgung"}</h3>
                <h3 style={{ color: "#247D38" }}>
                  {commaDotHandler(
                    consumption - total_self_supply,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
              <img src={Line1} style={{ marginBottom: "10px" }} alt="" />
              <img src={Line2} alt="" />
              <div className={classes.alignVertically}>
                <h3>{isEnglish ? "Feed-in" : "Einspeisung ins Netz"}</h3>
                <h3 style={{ color: "#304864" }}>
                  {commaDotHandler(
                    feed_into_grid,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
            </div>
            <img style={{ marginLeft: "15px" }} src={gridtower} alt="" />
          </div>
          <SelfSupplyInput
            consumption_details={consumption_details}
            setConsumptionDetails={setConsumptionDetails}
          />
          <h3
            style={{
              margin: "20px",
              marginLeft: "45px",
              fontFamily: "Nunito",
              fontStyle: "normal",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {isEnglish
              ? "This is showing you the entire electricity flows in your building between the solar system, all consumers in the building and the grid with the respective sums for the year"
              : "Dies zeigt Ihnen die gesamten Stromflüsse in Ihrem Gebäude zwischen Solaranlage, allen Verbrauchern im Gebäude und dem Netz mit den jeweiligen Summen für das Jahr"}
          </h3>
        </div>
      ) : (
        <>
          <div className={classes.makeMobileGood}>
            <h1 className={classes.forH1}>
              {isEnglish
                ? "Electricity flows in your building"
                : "Strom fließt in Ihrem Gebäude"}
            </h1>
            <div className={classes.alignVerticallyMain2}>
              <h3 style={{ margin: "0px" }}>
                {isEnglish ? "Yearly consumption" : "Jahresverbrauch"}
              </h3>
              <h3 style={{ color: "#247D38" }}>
                {commaDotHandler(consumption, `${isEnglish ? "en" : "de"}`)}
                kWh
              </h3>
            </div>
            <div className={classes.makeSelfSuppluCenter}>
              <div className={classes.alignVertically}>
                <h3 style={{ margin: "0px" }}>
                  {isEnglish ? "Generation" : "Generation"}
                </h3>
                <h3 style={{ color: "#FFAA39" }}>
                  {commaDotHandler(
                    total_production,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
              <div className={classes.alignVertically}>
                <h3 style={{ margin: "0px" }}>
                  {isEnglish ? "Grid supply" : "Netzversorgung"}
                </h3>
                <h3 style={{ color: "#247D38" }}>
                  {commaDotHandler(
                    consumption - total_self_supply,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
            </div>
            <div className={classes.mobileArrows}>
              <img src={LineMobile1} className={classes.img1} alt="" />
              <img src={LineMobile2} className={classes.img2} alt="" />
            </div>
            <img
              style={{ marginLeft: "12%", marginTop: "9px" }}
              src={Consumption_new_illustration}
              alt=""
            />
            <div className={classes.mobileArrows}>
              <img src={LineMobile3} className={classes.img1} alt="" />
              <img src={LineMobile4} className={classes.img2} alt="" />
            </div>
            <div className={classes.makeSelfSuppluCenter}>
              <div className={classes.alignVertically}>
                <h3 style={{ margin: "0px" }}>
                  {isEnglish ? "Self supply by Pv" : "Eigenversorgung durch Pv"}
                </h3>
                <h3 style={{ color: "#304864" }}>
                  {commaDotHandler(
                    total_self_supply,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
              <div className={classes.alignVertically}>
                <h3 style={{ margin: "0px" }}>
                  {isEnglish ? "Feed-in" : "Einspeisung ins Netz"}
                </h3>
                <h3 style={{ color: "#304864" }}>
                  {commaDotHandler(
                    feed_into_grid,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  kWh
                </h3>
              </div>
            </div>
            <SelfSupplyInput
              center={true}
              consumption_details={consumption_details}
              setConsumptionDetails={setConsumptionDetails}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DetailTab3;
