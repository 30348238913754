import React, { useState, useEffect } from "react";
import { CometChat } from "@cometchat-pro/chat";

function useCometChat() {
  const [groupList, setGroupList] = useState([]);
  const [reloadSwitch, setReloadSwitch] = useState(false);
  useEffect(() => {
    fetchWebinarGroupList();
  }, [reloadSwitch]);

  const createWebinarGroup = (webinarId, webinarName) => {
    console.log("creating webinar group");
    if (!webinarName) {
      return alert("Please enter webinar name");
    }
    var GUID = webinarId;
    var groupName = webinarName;
    var groupType = CometChat.GROUP_TYPE.PUBLIC;
    var password = "";

    var group = new CometChat.Group(GUID, groupName, groupType, password);

    CometChat.createGroup(group).then(
      (group) => {
        console.log("Group created successfully:", group);
      },
      (error) => {
        console.log("Group creation failed with exception:", error);
      }
    );
  };

  const fetchWebinarGroupList = () => {
    let limit = 30;
    let groupsRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(limit)
      .build();

    groupsRequest.fetchNext().then(
      (groupList) => {
        console.log("Groups list fetched successfully", groupList);
        return groupList;
      },
      (error) => {
        console.log("Groups list fetching failed with error", error);
      }
    );
  };

  const deleteWebinarGroup = (groupId) => {
    var GUID = groupId;

    CometChat.deleteGroup(GUID).then(
      (response) => {
        console.log("Groups deleted successfully:", response);
      },
      (error) => {
        console.log("Group delete failed with exception:", error);
      }
    );
  };
  return { createWebinarGroup, fetchWebinarGroupList, deleteWebinarGroup };
}

export default useCometChat;
