import React, { useState } from "react";
import {
  Dialog,
  Button,
  makeStyles,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Hero from "../../Assets/Hero.svg";
import Close from "../../Assets/Close.svg";
import Group from "../SignUpSignIn/images/Group.svg";
import { colors } from "../../colors";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "666px",
    height: "279px",
    margin: "0px",
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  h1_helper: {
    fontFamily: "Nunito",
    fontWeight: "700",
    fontStyle: "Normal",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "297px",
    },
    fontSize: "20px",
    color: "rgba(45, 71, 100, 1)",
  },
  h3_helper: {
    fontFamily: "Nunito",
    fontWeight: "600",
    width: "269px",
    [theme.breakpoints.down("xs")]: {
      width: "297px",
      textAlign: "center",
    },
    fontStyle: "normal",
    fontSize: "16px",
    color: "rgba(17, 17, 17, 1)",
  },
  for_margin: {
    marginLeft: "30px",
    width: "320px",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "54px",
    },
    marginBottom: "20px",
  },
  for_margin1: {
    marginRight: "30px",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
    marginBottom: "20px",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
      background: "black",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: colors.ctaSolid,
      },
    },
  },
}));

const isStagingUser = ({ setOpen, open }) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const submitHandler = () => {
    if (values.password === "davidSolarhub24") {
      console.log("hey");
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} maxWidth={false} style={{ margin: "-15px" }}>
      <div className={classes.root}>
        <div>
          <InputLabel htmlFor="standard-adornment-password">
            Password
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <Button onClick={() => submitHandler()}>submit</Button>
      </div>
    </Dialog>
  );
};

export default isStagingUser;
