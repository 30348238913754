import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "pages/AdminPanel/Navbars/Navbar.js";
import "Assets/css/material-dashboard-react.css?v=1.10.0";
import "./DashBoard.css";
import Footer from "pages/AdminPanel/components/Footer/Footer.js";
import Sidebar from "pages/AdminPanel/Sidebar/Sidebar.js";
import FixedPlugin from "pages/AdminPanel/components/FixedPlugin/FixedPlugin.js";
import routes from "pages/userdashboard/components/templates/routes";
import styles from "Assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "Assets/img/sidebar-2.jpg";
import logo from "Assets/SolarHubLogo.png";
import { useSelector } from "react-redux";

let ps;
console.log(routes, "routes");
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/userdashboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/userdashboard" to="/userdashboard/listing" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function DashBoard({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const isEnglish = useSelector((state) => state.global.isEnglish);
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  useEffect(() => {
    if (window.location.pathname.includes("userdashboard")) {
      document.title = isEnglish
        ? "Solarhub - Project configurator"
        : "Solarhub - Projekt konfigurator";
    } else if (window.location.pathname.includes("admin")) {
      document.title = "Solarhub - Admin panel";
    }
  }, []);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={isEnglish ? "Project configurator" : "Projekt konfigurator"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div
        className={classes.mainPanel}
        style={{ overflow: "hidden" }}
        ref={mainPanel}
      >
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {getRoute() ? (
          <div
            className={classes.content}
            style={{ padding: 0, marginTop: "72px" }}
          >
            <div className={classes.container} style={{ marginRight: 0 }}>
              {switchRoutes}
            </div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
    </div>
  );
}
