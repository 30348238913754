import { createStore, applyMiddleware, compose } from "redux";
import { all } from "redux-saga/effects";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { fetchQuoteStart } from "./quote/quote.saga";
import { setProjectStart } from "./project/project.saga";
import rootReducer from "./root-reducer";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(...middlewares))
);

function* rootSaga() {
  yield all([fetchQuoteStart(), setProjectStart()]);
}

sagaMiddleware.run(rootSaga);

export default store;
