import React from "react";
import { Drawer } from "antd";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { makeStyles } from "@material-ui/core";
import DrawerWrapper from "antd/lib/drawer";
import { useLocation } from "react-router";

// style with material ui start
const useStyles = makeStyles((theme) => ({
  flex_x: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  pa10: {
    padding: "10px",
  },
}));
// style with material ui ends

const ShareButtonForMobile = ({
  sharedURL,
  currentUser,
  visibleShare,
  setvisibleShare,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  let slug = pathname.substring(13);
  //function for share url
  const url = () => {
    if (slug?.includes("default")) {
      return (
        window.location.origin + pathname.replace(/ /g, "%20").replace(",", "")
      );
    } else if (slug?.includes("saved")) {
      return window.location.href;
    } else {
      return window.location.origin + "/application";
    }
  };
  //over functiuon
  const msg = `${url()}`;
  return (
    <DrawerWrapper
      title="SHARE"
      placement="bottom"
      closable={false}
      onClose={() => setvisibleShare(false)}
      visible={visibleShare}
      key="bottom"
    >
      <div className={classes.flex_x}>
        <div className={classes.pa10}>
          <WhatsappShareButton url={msg}>
            <WhatsappIcon round size={30} />
            <div className="an-10 medium-text">WhatsApp</div>
          </WhatsappShareButton>
        </div>
        <div className={classes.pa10}>
          <FacebookShareButton url={msg}>
            <FacebookIcon round size={30} />
            <div className="an-10 medium-text">Facebook</div>
          </FacebookShareButton>
        </div>
        <div className={classes.pa10}>
          <TwitterShareButton url={msg}>
            <TwitterIcon round size={30} />
            <div className="an-10 medium-text">Twitter</div>
          </TwitterShareButton>
        </div>
        <div className={classes.pa10}>
          <LinkedinShareButton url={msg}>
            <LinkedinIcon round size={30} />
            <div className="an-10 medium-text">Linkedin</div>
          </LinkedinShareButton>
        </div>
        <div className={classes.pa10}>
          <TelegramShareButton url={msg}>
            <TelegramIcon round size={30} />
            <div className="an-10 medium-text">Telegram</div>
          </TelegramShareButton>
        </div>
        <div className={classes.pa10}>
          <EmailShareButton url={msg}>
            <EmailIcon round size={30} />
            <div className="an-10 medium-text">Gmail</div>
          </EmailShareButton>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default ShareButtonForMobile;
