import React from "react";
import styled from "styled-components";
const HomeWrapper = styled("div")`
  width: 673px;
  height: 307px;
  background: #ffffff;
  box-shadow: 0px 4px 4px #d8d8d8;
  border-radius: 10px;
  margin-top: 16px;
  position: relative;
  .UserPic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
const TextDot = styled("div")`
  position: absolute;
  width: 11px;
  height: 11px;
  left: 48px;
  top: 48px;
  border-radius: 50%;
  background: #75d58b;
`;
const Header = styled("div")`
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
`;
const HeaderText = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #2d4764;
`;
const MainContainer = styled("div")`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  width: 50%;
  border-right: 1px solid #d0d0d0; ;
`;
const TextBody = styled("div")`
  display: flex;
`;
const BodyText1 = styled("div")`
  width: 131px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #666666;
`;
const BodyText2 = styled("div")`
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #2d4764;
`;

const dataMaker = (date) => {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;
  return today;
};
const ProfileCard = ({ userData }) => {
  console.log("sar", userData);
  return (
    <HomeWrapper>
      <Header>
        <div style={{ display: "flex", gap: "16px" }}>
          <div>
            <img
              src={`http://community.solarhub24.de${userData.avatar_template.replace(
                "{size}",
                "50"
              )}`}
              alt="profile-img"
              className="UserPic"
            />
            <TextDot />
          </div>
          <HeaderText>{userData.name}</HeaderText>
        </div>
      </Header>
      <MainContainer>
        <TextBody>
          <BodyText1>username</BodyText1>
          <BodyText2>{userData.username}</BodyText2>
        </TextBody>
        <TextBody>
          <BodyText1>member since</BodyText1>
          <BodyText2>{dataMaker(userData.created_at)}</BodyText2>
        </TextBody>
        <TextBody>
          <BodyText1>number of likes</BodyText1>
          <BodyText2>{userData.like_count}</BodyText2>
        </TextBody>
        <TextBody>
          <BodyText1>topics created</BodyText1>
          <BodyText2>{userData.topic_count}</BodyText2>
        </TextBody>
        <TextBody>
          <BodyText1>posts created</BodyText1>
          <BodyText2>{userData.post_count}</BodyText2>
        </TextBody>
      </MainContainer>
    </HomeWrapper>
  );
};

export default ProfileCard;
