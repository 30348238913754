export const default_quote_data = {
  roof_structure_complexity_values: {
    mid_complex: 3,
    little_complex: 4,
    complex: 2,
    very_complex: 1,
    clean: 5,
  },
  state_of_roof: "",
  city: "Stegen",
  style_of_roof: "",
  roof_status_rating: 1,
  proposed_system_size_ac: "21.0",
  module_quantity: "71.0",
  current_annual_consumption: "4500.0",
  contact_url: "",
  inverter_quantity_2: "",
  is_solar_panel_already_installed: "No",
  type_of_solar_panel_if_already_installed: "",
  time: "12:6:49",
  total_inverter_capacity_22: "",
  production_ratio: "830.0",
  inverter_efficiency: "96.0",
  roof_material_rating: 2,
  roof_structure_complexity_rating: 3,
  "utility_bill_replacement_%": "87.0",
  quotes: "[]",
  project_size_rating: 3,
  estimated_consumption: "",
  qrn: "60ebee279eee013e34e64130",
  isVerified: "true",
  owner_type: "",
  annul_electric_bill: "1699.44",
  total_system_cost_: "34612.0",
  unit_system_cost: "1.26",
  bill_type: "annual",
  state: "Deutschland",
  roof_orientation_rating: 4,
  contact_website: "",
  inverter_id: "I1",
  utility_name: "Prio Energie",
  unit_electricity_bill_rate: "0.37766",
  addr1: "ringstraße 7",
  roof_status_values: {
    medium: 3,
    new: 5,
    good: 4,
    old: 2,
    very_old: 1,
  },
  geohash: "u0t8vnttm7",
  watts_per_module: "325.0",
  business_name: "",
  shadows_rating: 1,
  roof_area: "",
  long: "7.9618841",
  roof_orientation: "South orientation",
  number_of_floors: "",
  project_type: "R",
  lat: "47.9809618",
  inverter_model_2: "",
  zip_code: "79252",
  "utility_electric_rate_escalator_%": "5.0",
  annual_solar_generation: "19144.0",
  sl_no: "312.0",
  year_of_roof_construction: "",
  module_model_number: "JA Solar JAM60S21-MR 385 Wp",
  roof_material: "metal roof",
  roof_type: "Arched roof",
  number_of_inhabitants: "",
  module_id: "P1",
  total_inverter_capacity_1: "21.0",
  inverter_brand_2: "",
  date: "11.1.2021",
  total_inverter_capacity_2: "",
  roof_tilt: "45°",
  shadows_values: {
    strong: 1,
    middle_strong: 2,
    no: 5,
    light: 4,
    middle: 3,
  },
  inverter_quantity_1: "5.0",
  roof_tilt_values: {
    "0-90": 1,
    "97-180": 5,
    "91-93": 2,
    "93-95": 4,
    "95-97": 4,
  },
  rate_name: "Domestic",
  business_type: "",
  building_type: "Multi-Family Building",
  combined_img: "mozilla.png",
  dc_to__ac_ratio: "1.1",
  inverter_brand_1: "SMA",
  crn: "0EO-6F4-5EH",
  inverter_model_1: "SMA Sunny Tripower 10.0",
  panel_degradation: "0.5",
  new_annual_electric_bill: "223.0",
  module_brand: "JA Solar",
  customer_name: "Ringstraße 7",
  proposed_system_size_dc: "23.075",
  inverter_powerrating_1: "7.0",
  roof_types_list: [
    "Flatroof",
    "Tentroof",
    "Hiproof",
    "Trapezoidal Profiles",
    "Gable roof",
    "Singlepitch roof",
    "Archedroof",
    "Halfhipped roof",
    "Foldedplate roof",
    "Walldormer",
  ],
  img_url: [
    "https://firebasestorage.googleapis.com/v0/b/solar-app-229e7.appspot.com/o/defaultQuoteImages%2FSOLARHUB00361_1_combined.png?alt=media&token=4ea27963-5667-4145-8921-93214ab1e339",
  ],
  fit_type: "FIT_July_Resedential",
};
