import React, { useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  FormLabel,
  Icon,
  Input,
  TextField,
} from "@material-ui/core";
import vector from "../../Assets/Vector.png";
import twoperson from "../../Assets/twopersion.png";
import threeperson from "../../Assets/threepersion.png";
import fourperson from "../../Assets/fourperson.png";
import fiveperson from "../../Assets/fivepersons.png";
import { YearlyConsumptionInput } from "./Building";
import { calculated_consumption } from "utils/consumption";
import setCommaDot, { parseLocaleNumber, commaDotHandler } from "helperMethod";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    maxHeight: 345,
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  option_container: {
    marginTop: "30px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  option_container1: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  one_option: {
    marginTop: "10px",
    height: "50px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input_main: {
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  },
  button: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  font: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

const Image = (props) => {
  return (
    <>
      <img src={props.src} style={props.style} alt="" />
    </>
  );
};

const Household = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const classes = useStyles();

  const { people, isWaterHeating } = consumption_details;
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );
  const setPeople = (people) => {
    setConsumptionDetails({ ...consumption_details, people: people });
    setTemp1(
      calculated_consumption({ ...consumption_details, people: people })
    );
  };

  const setElectricWaterHeating = (isWaterHeating) => {
    setConsumptionDetails({
      ...consumption_details,
      isWaterHeating: isWaterHeating,
    });
    setTemp1(
      calculated_consumption({
        ...consumption_details,
        isWaterHeating: isWaterHeating,
      })
    );
  };
  return (
    <div style={{ marginTop: window.innerWidth < 500 ? "20px" : "50px" }}>
      <h4 className={classes.center}>
        {isEnglish
          ? "How many people live in your household?"
          : "Wie viele Personen leben in Ihrem Haushalt?"}
      </h4>
      <div className={classes.option_container}>
        <FormControl component="fieldset">
          <FormGroup
            aria-label="position"
            classes={{ root: classes.option_container1 }}
            row
          >
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  checked={people === 1 && true}
                  onChange={(checked) => checked && setPeople(1)}
                  color="primary"
                />
              }
              label={
                <Image
                  src={vector}
                  style={{
                    width: window.innerWidth < 500 ? "16px" : "",
                    height: window.innerWidth < 500 ? "40px" : "",
                  }}
                />
              }
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  checked={people === 2 && true}
                  onChange={(checked) => checked && setPeople(2)}
                  color="primary"
                />
              }
              label={
                <Image
                  src={twoperson}
                  style={{
                    width: window.innerWidth < 500 ? "40px" : "",
                    height: window.innerWidth < 500 ? "40px" : "",
                  }}
                />
              }
              labelPlacement="top"
            />{" "}
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  checked={people === 3 && true}
                  onChange={(checked) => checked && setPeople(3)}
                  color="primary"
                />
              }
              label={
                <Image
                  src={threeperson}
                  style={{
                    width: window.innerWidth < 500 ? "40px" : "",
                    height: window.innerWidth < 500 ? "40px" : "",
                  }}
                />
              }
              labelPlacement="top"
            />{" "}
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  checked={people === 4 && true}
                  onChange={(checked) => checked && setPeople(4)}
                  color="primary"
                />
              }
              label={
                <Image
                  src={fourperson}
                  style={{
                    width: window.innerWidth < 500 ? "40px" : "",
                    height: window.innerWidth < 500 ? "40px" : "",
                  }}
                />
              }
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  checked={people === 5 && true}
                  onChange={(checked) => checked && setPeople(5)}
                  color="primary"
                />
              }
              label={
                <Image
                  src={fiveperson}
                  style={{
                    width: window.innerWidth < 500 ? "40px" : "",
                    height: window.innerWidth < 500 ? "40px" : "",
                  }}
                />
              }
              labelPlacement="top"
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className={classes.one_option}>
        <FormControlLabel
          control={
            <Checkbox
              // checked={state.checkedB}
              // onChange={handleChange}
              checked={isWaterHeating}
              onChange={() => setElectricWaterHeating(!isWaterHeating)}
              name="checkedB"
              color="primary"
            />
          }
          style={{
            color: "#2D4764",
            fontSize: window.innerWidth < 500 ? "14px" : "",
          }}
          className={classes.font}
          label={
            <Typography className={classes.font}>
              {isEnglish
                ? "Electrical Water Heating"
                : "Elektrische Warmwasserbereitung"}
            </Typography>
          }
        />
      </div>
      <YearlyConsumptionInput
        consumption={consumption}
        setConsumption={setConsumption}
        temp1={temp1}
        setTemp1={setTemp1}
      />
      {/* <div className={classes.button}>
        <Button
          style={{
            color: "white",
            background:
              " linear-gradient(226.42deg, #28334A 8.93%, #09152F 110.98%)",
            borderRadius: "10px",
          }}
          variant="contained"
          onClick={() => setOpen(false)}
        >
          {isEnglish ? "Take Over" : "Übernehmen"}
        </Button>
      </div> */}
    </div>
  );
};

export default Household;
