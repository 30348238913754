import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonDropdown from "../../../../Components/atoms/buttons/button.dropdown";
import { Card, Button, Typography, Divider } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { colors } from "colors";
import { Rate } from "antd";
import Maps from "Assets/images/headerImages/Maps.png";
import Berner from "Assets/images/headerImages/Berner.png";
import { useSelector } from "react-redux";
import { financingDetails } from "utils/costs";
import commaDotHandler from "helperMethod";

const OfferIsSelectedWrapper = styled("div")`
  .main_root {
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    margin: 10px;
    display: flex;
    height: 526px;
    flex-direction: column;
    align-items: center;
    z-index: 0;
  }
  .divider {
    width: 90%;
    color: #ffd75d;
    margin: 5px;
  }
  .first_contai {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.005em;
    color: #000000;
  }
  .tow_conteirn {
    width: 740px;
    height: 244px;
    margin-top: 10px;
    margin-bottom: 19px;
    display: flex;
    align-items: center;
  }
  .first_container {
    display: flex;
    justify-content: center;
    gap: 11px;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
  .inside_first {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .secound {
    padding-left: 5px;
  }
  .secound_contaienr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: center;
  }
  .text_2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .world {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .text_price {
    font-weight: normal;
    font-size: 36px;
    line-height: 49px;
    color: #3e3637;
  }
  .text_desc {
    font-weight: normal;
    font-size: 14px;
    color: #3e3637;
  }
  .last_button {
    background: linear-gradient(226.42deg, #1b4963 8.93%, #09152f 110.98%);
    border-radius: 10px;
    height: 41px;
    width: 187px;
    color: #ffffff;
  }
`;

const OfferIsSelected = ({ offerAcceptedData }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);
  const vendors = useSelector((state) => state?.project?.vendor?.suggested);
  const project = useSelector((state) => state.project);
  const yealry_financ = financingDetails(project).yearly_financing_amount;
  console.log(offerAcceptedData, "offerAcceptedData");

  useEffect(() => {
    if (offerAcceptedData) {
      const result = vendors.filter(
        (data) => data.iid === offerAcceptedData.iid
      );
      if (result.length) {
        setData(result[0]);
      }
    }
  }, [offerAcceptedData]);

  console.log(data, "ddddat");

  return (
    <OfferIsSelectedWrapper>
      <Card elevation={"3"} className="main_root">
        <div className="border_important"></div>
        <div className="first_contai">
          {isEnglish ? "Congratulations!" : "Herzlichen Glückwunsch!"}
        </div>
        {data !== null && (
          <>
            <div className="first_contai">
              {isEnglish
                ? "Your project partner is"
                : "Ihr neuer Projektpartner ist"}
            </div>
            <img src={data.image_url} style={{ marginTop: "10px" }} alt="" />
            <div className="tow_conteirn">
              <div className="first_container">
                <Rate allowHalf value={4} disabled />
                <div className="world">
                  <div className="text_price">
                    {commaDotHandler(
                      Math.floor(yealry_financ),
                      `${isEnglish ? "en" : "de"}`
                    )}
                  </div>
                  <div className="text_desc">Gesamtpreis für die Umsetzung</div>
                </div>
                <div className="inside_first">
                  <div className="text">Umsetzungszeitraum</div>
                  <div className="secound">
                    {offerAcceptedData?.realization_week_short}
                  </div>
                </div>
                <Divider
                  className="divider"
                  style={{
                    background:
                      "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
                  }}
                />
                <ButtonDropdown
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                  style={{ margin: "10px" }}
                >
                  {isEnglish ? "Scope of work" : "Leistungsumfang"}
                </ButtonDropdown>
              </div>
              <div className="secound_contaienr">
                <div className="text_2">
                  {Math.round(data?.distance)}km Entfernung zu ihrem Dach
                </div>
                <img src={Maps} alt="" />
                <div className="text_2">{data?.address}</div>
              </div>
            </div>
            <Button className="last_button">Zur Konfiguration</Button>
          </>
        )}
      </Card>
    </OfferIsSelectedWrapper>
  );
};

export default OfferIsSelected;
