import { Card, makeStyles, Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { Input } from "antd";
import DashboardHeader from "Components/dashboardHeader";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { KonfComponent } from "../partials/application.step2";
import { useDispatch, useSelector } from "react-redux";
import { Form, Rate } from "antd";
import { db } from "../../../firebase";
import { useHistory, useLocation } from "react-router";
import { colors } from "colors";
import { toast } from "react-toastify";
import Group from "Assets/Group.svg";
const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "955px",
    height: "535px",
    marginTop: "20px",
    borderRadius: "10px",
    border: "none",
    margin: "auto",
  },
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  aboveLine: {
    width: "100%",
    height: "8px",
    background:
      "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  rating: {
    display: "flex",
    height: "32x",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  paragrapph: {
    marginLeft: "20px",
    margin: "0px",
    fontSize: "18px",
    color: "#09152F",
    // marginTop: "-5px",
  },
  btn3: {
    width: "165px",
    marginTop: "30x",
    marginBottom: "30px",
    color: "#fff",
    backgroundColor: colors.darkBlue,
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "bold",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: colors.darkBlue,
    },
    "@media (max-width: 560px)": {
      width: "80%",
    },
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "50px",
  },
  lastContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  h2: {
    marginTop: "10px",
  },
  insideFirstContainer: {
    marginBottom: "10px",
  },
  textarea: {
    width: "510px",
    marginTop: "10px",
    marginBottom: "22px",
    borderRadius: "10px",
    border: "1px solid #666666",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
}));

const FeedBack = () => {
  const classes = useStyles();
  const project = useSelector((state) => state.project);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const qrn = useSelector((state) => state.quote.quote.qrn);
  const uid = useSelector((state) => state.user?.currentUser?.uid);
  const email = useSelector((state) => state.user?.currentUser?.email);
  const solar_panel = project?.components.items.solar_panel;
  const isProjectSet = useSelector((state) => state.project.isProjectSet);
  const inverter = project?.components.items.inverter;
  const battery = project?.components.items.battery;
  const wallbox = project?.components.items.wallbox;
  const heatpump = project?.components.items.heatpump;
  const { state } = useLocation();
  const history = useHistory();
  const { id } = state;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const setLoading = (data) => dispatch({ type: "SET_LOADING", payload: data });
  const setProjectAsync = (data) =>
    dispatch({ type: "SET_PROJECT_ASYNC", payload: data });
  //   const {state} = useLocation();
  //   const {id}=state;

  useEffect(() => {
    //   console.log(uid,"uid")
    //   console.log(id,"qrn")
    if (id) {
      setLoading(true);
      db.collection("saved_projects")
        .doc(id)
        .get()
        .then((doc) => {
          setProjectAsync(doc.data()?.project_details);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [uid, qrn]);

  const returnFeatchData = async (number) => {
    const select = [
      project?.components?.items?.inverter?.item?.objectId,
      project?.components?.items?.battery?.item?.objectId,
      project?.components?.items?.wallbox?.item?.objectId,
      project?.components?.items?.heatpump?.item?.objectId,
      project?.components?.items?.solar_panel?.item?.objectId,
    ];
    const news = await db
      .collection("feedbacks")
      .where("uid", "==", uid)
      .where("pid", "==", project.pid)
      .where("product_id", "==", select[number])
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        return data;
      })
      .catch((error) => {
        return false;
      });
    return news;
  };

  useEffect(() => {
    const func = async () => {
      if (uid && isProjectSet) {
        const data0 = await returnFeatchData(0);
        if (data0.length) {
          form.setFieldsValue({
            inverterMessage: data0[0].comment,
            inverterRate: data0[0].rating,
          });
        }
        const data4 = await returnFeatchData(4);
        if (data4.length) {
          form.setFieldsValue({
            solarPanelMessage: data4[0].comment,
            solarPanelRate: data4[0].rating,
          });
        }
        if (battery.item) {
          const data1 = await returnFeatchData(1);
          if (data1.length) {
            form.setFieldsValue({
              batteryRate: data1[0].rating,
              batteryMessage: data1[0].comment,
            });
          }
        }
        if (wallbox.item) {
          const data2 = await returnFeatchData(2);
          if (data2.length) {
            form.setFieldsValue({
              wallboxMessage: data2[0].comment,
              wallboxRate: data2[0].rating,
            });
          }
        }
        if (heatpump.item) {
          const data3 = await returnFeatchData(3);
          if (data3.length) {
            form.setFieldsValue({
              heatPumpMessage: data3[0].comment,
              heatPumpRate: data3[0].rating,
            });
          }
        }
      }
    };

    func();
  }, [uid, isProjectSet]);

  const toggleWarranty = (component) => {
    dispatch({ type: "TOGGLE_WARRANTY", payload: component });
  };
  const onFinish = (value) => {
    for (const key in value) {
      if (key.includes("Rate")) {
        if (value[key] === undefined) {
          toast.error("please give rating to all the produts");
          return;
        }
      }
    }
    for (const key in value) {
      if (key.includes("solarPanelRate")) {
        db.collection("feedbacks")
          .where("uid", "==", uid)
          .where("pid", "==", project.pid)
          .where(
            "product_id",
            "==",
            project.components.items.solar_panel.item.objectId
          )
          .get()
          .then((snapshots) => {
            const data = [];
            snapshots.forEach((doc) => {
              data.push({ ...doc.data(), id: doc.id });
            });
            if (data.length) {
              db.collection("feedbacks")
                .doc(data[0].id)
                .set({
                  uid: uid,
                  comment: value?.solarPanelMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id:
                    project.components.items.solar_panel.item.objectId,
                  email: email,
                });
            } else {
              db.collection("feedbacks")
                .doc(uuidv4())
                .set({
                  uid: uid,
                  comment: value?.solarPanelMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id:
                    project.components.items.solar_panel.item.objectId,
                  email: email,
                });
            }
          });
      }
      if (key.includes("inverterRate")) {
        db.collection("feedbacks")
          .where("uid", "==", uid)
          .where("pid", "==", project.pid)
          .where(
            "product_id",
            "==",
            project.components.items.inverter.item.objectId
          )
          .get()
          .then((snapshots) => {
            const data = [];
            snapshots.forEach((doc) => {
              data.push({ ...doc.data(), id: doc.id });
            });
            if (data.length) {
              db.collection("feedbacks")
                .doc(data[0].id)
                .set({
                  uid: uid,
                  comment: value?.inverterMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.inverter.item.objectId,
                  email: email,
                });
            } else {
              db.collection("feedbacks")
                .doc(uuidv4())
                .set({
                  uid: uid,
                  comment: value?.inverterMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.inverter.item.objectId,
                  email: email,
                });
            }
          });
      }
      if (key.includes("batteryRate")) {
        db.collection("feedbacks")
          .where("uid", "==", uid)
          .where("pid", "==", project.pid)
          .where(
            "product_id",
            "==",
            project.components.items.battery.item.objectId
          )
          .get()
          .then((snapshots) => {
            const data = [];
            snapshots.forEach((doc) => {
              data.push({ ...doc.data(), id: doc.id });
            });
            if (data.length) {
              db.collection("feedbacks")
                .doc(data[0].id)
                .set({
                  uid: uid,
                  comment: value?.batteryMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.battery.item.objectId,
                  email: email,
                });
            } else {
              db.collection("feedbacks")
                .doc(uuidv4())
                .set({
                  uid: uid,
                  comment: value?.batteryMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.battery.item.objectId,
                  email: email,
                });
            }
          });
      }
      if (key.includes("wallboxRate")) {
        db.collection("feedbacks")
          .where("uid", "==", uid)
          .where("pid", "==", project.pid)
          .where(
            "product_id",
            "==",
            project.components.items.wallbox.item.objectId
          )
          .get()
          .then((snapshots) => {
            const data = [];
            snapshots.forEach((doc) => {
              data.push({ ...doc.data(), id: doc.id });
            });
            if (data.length) {
              db.collection("feedbacks")
                .doc(data[0].id)
                .set({
                  uid: uid,
                  comment: value?.wallboxMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.wallbox.item.objectId,
                  email: email,
                });
            } else {
              db.collection("feedbacks")
                .doc(uuidv4())
                .set({
                  uid: uid,
                  comment: value?.wallboxMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.wallbox.item.objectId,
                  email: email,
                });
            }
          });
      }
      if (key.includes("heatPumpRate")) {
        db.collection("feedbacks")
          .where("uid", "==", uid)
          .where("pid", "==", project.pid)
          .where(
            "product_id",
            "==",
            project.components.items.heatpump.item.objectId
          )
          .get()
          .then((snapshots) => {
            const data = [];
            snapshots.forEach((doc) => {
              data.push({ ...doc.data(), id: doc.id });
            });
            if (data.length) {
              db.collection("feedbacks")
                .doc(data[0].id)
                .set({
                  uid: uid,
                  comment: value?.heatPumpMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.heatpump.item.objectId,
                  email: email,
                });
            } else {
              db.collection("feedbacks")
                .doc(uuidv4())
                .set({
                  uid: uid,
                  comment: value?.heatPumpMessage || "",
                  rating: value[key],
                  pid: project.pid,
                  product_id: project.components.items.heatpump.item.objectId,
                  email: email,
                });
            }
          });
      }
    }
    toast.success("Rating successfully submited");
    history.push(`/application/saved-${project?.pid}`);
    console.log(value);
  };

  return (
    <>
      <DashboardHeader />
      <div style={{ width: "100%", height: "100%" }}>
        <Form
          name="validate_other"
          // {...formItemLayout}
          onFinish={onFinish}
          form={form}
          // initialValues={{ ...userFeatchRatings }}
        >
          <Card className={classes.root} elevation={"3"}>
            <div className={classes.aboveLine}></div>
            <div className={classes.mainContainer}>
              <div className={classes.FirstContainer}>
                <div className={classes.insideFirstContainer}>
                  <h2 className={classes.h2}>
                    {isEnglish
                      ? "Bewerten Sie ihr Solarpanel"
                      : "Bewerten Sie ihr Solarpanels"}
                  </h2>
                  <div className={classes.rating}>
                    <Form.Item
                      name="solarPanelRate"
                      style={{ marginBottom: "0px" }}
                    >
                      <Rate allowHalf />
                    </Form.Item>
                    <p className={classes.paragrapph}>Leistungserbringung</p>
                  </div>
                </div>
              </div>
              <KonfComponent
                solarPanel
                isEnglish={isEnglish}
                component={solar_panel}
                project={project}
                toggleWarranty={toggleWarranty}
              />
              <Form.Item name="solarPanelMessage">
                <TextArea
                  className={classes.textarea}
                  placeholder="Enter your message"
                  rows={4}
                />
              </Form.Item>
            </div>
          </Card>
          <Card className={classes.root} elevation={"3"}>
            <div className={classes.aboveLine}></div>
            <div className={classes.mainContainer}>
              <div className={classes.FirstContainer}>
                <div className={classes.insideFirstContainer}>
                  <h2 className={classes.h2}>
                    {isEnglish
                      ? "Bewerten Sie ihr Inverter"
                      : "Bewerten Sie ihr Wechselrichter"}
                  </h2>
                  <div className={classes.rating}>
                    <Form.Item
                      name="inverterRate"
                      style={{ marginBottom: "0px" }}
                    >
                      <Rate allowHalf />
                    </Form.Item>
                    <p className={classes.paragrapph}>Leistungserbringung</p>
                  </div>
                </div>
              </div>
              {inverter.item !== null && (
                <KonfComponent
                  isEnglish={isEnglish}
                  component={inverter}
                  project={project}
                  toggleWarranty={toggleWarranty}
                />
              )}
              <Form.Item name="inverterMessage">
                <TextArea
                  className={classes.textarea}
                  placeholder="Enter your message"
                  rows={4}
                />
              </Form.Item>
            </div>
          </Card>
          {battery.item && (
            <Card className={classes.root} elevation={"3"}>
              <div className={classes.aboveLine}></div>
              <div className={classes.mainContainer}>
                <div className={classes.FirstContainer}>
                  <div className={classes.insideFirstContainer}>
                    <h2 className={classes.h2}>
                      {isEnglish
                        ? "Bewerten Sie ihr Battery"
                        : "Bewerten Sie ihr Batterie"}
                    </h2>
                    <div className={classes.rating}>
                      <Form.Item
                        name="batteryRate"
                        style={{ marginBottom: "0px" }}
                      >
                        <Rate allowHalf />
                      </Form.Item>
                      <p className={classes.paragrapph}>Leistungserbringung</p>
                    </div>
                  </div>
                </div>
                {battery.item && (
                  <KonfComponent
                    isEnglish={isEnglish}
                    component={battery}
                    toggleWarranty={toggleWarranty}
                  />
                )}
                <Form.Item name="batteryMessage">
                  <TextArea
                    className={classes.textarea}
                    placeholder="Enter your message"
                    rows={4}
                  />
                </Form.Item>
              </div>
            </Card>
          )}
          {wallbox.item && (
            <Card className={classes.root} elevation={"3"}>
              <div className={classes.aboveLine}></div>
              <div className={classes.mainContainer}>
                <div className={classes.FirstContainer}>
                  <div className={classes.insideFirstContainer}>
                    <h2 className={classes.h2}>
                      {isEnglish
                        ? "Bewerten Sie ihr Wallbox"
                        : "Bewerten Sie ihr Wallbox"}
                    </h2>
                    <div className={classes.rating}>
                      <Form.Item
                        name="wallboxRate"
                        style={{ marginBottom: "0px" }}
                      >
                        <Rate allowHalf />
                      </Form.Item>
                      <p className={classes.paragrapph}>Leistungserbringung</p>
                    </div>
                  </div>
                </div>
                {wallbox.item && (
                  <KonfComponent
                    isEnglish={isEnglish}
                    component={wallbox}
                    toggleWarranty={toggleWarranty}
                  />
                )}
                <Form.Item name="wallboxMessage">
                  <TextArea
                    className={classes.textarea}
                    placeholder="Enter your message"
                    rows={4}
                  />
                </Form.Item>
              </div>
            </Card>
          )}
          {heatpump.item && (
            <Card className={classes.root} elevation={"3"}>
              <div className={classes.aboveLine}></div>
              <div className={classes.mainContainer}>
                <div className={classes.FirstContainer}>
                  <div className={classes.insideFirstContainer}>
                    <h2 className={classes.h2}>
                      {isEnglish
                        ? "Bewerten Sie ihr Heat Pump"
                        : "Bewerten Sie ihr Wärmepumpe"}
                    </h2>
                    <div className={classes.rating}>
                      <Form.Item
                        name="heatPumpRate"
                        style={{ marginBottom: "0px" }}
                      >
                        <Rate allowHalf />
                      </Form.Item>
                      <p className={classes.paragrapph}>Leistungserbringung</p>
                    </div>
                  </div>
                </div>
                {heatpump.item && (
                  <KonfComponent
                    isEnglish={isEnglish}
                    component={heatpump}
                    toggleWarranty={toggleWarranty}
                  />
                )}
                <Form.Item name="heatPumpMessage">
                  <TextArea
                    className={classes.textarea}
                    placeholder="Enter your message"
                    rows={4}
                  />
                </Form.Item>
              </div>
            </Card>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "96px",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              variant="contained"
              className={classes.btn3}
              startIcon={<img src={Group} alt="send icon" />}
            >
              {isEnglish ? "Submit" : "Absenden"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default FeedBack;
