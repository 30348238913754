import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Dropdown from "./LoginDropdown";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import finalText from "Assets/finalText.svg";
import LanguageDropdown from "./languageDropdown";
import { useSelector } from "react-redux";
import finalLogo from "Assets/finalLogo.svg";
import How from "Assets/community/How.svg";
import CallIcon from "@material-ui/icons/Call";
import { useDispatch } from "react-redux";
import { openPopupWidget } from "react-calendly";
import { useHistory, useLocation } from "react-router-dom";
import VersionMenu from "./VersionMenu";
import { db } from "../firebase";
import WebinarPopup from "pages/Application/partials/webinarPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "66px",
    boxShadow: "none",
    width: "100vw",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoResponsive: {
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      width: "57px",
      position: "absolute",
      left: "21px",
      bottom: "1px",
      height: "47px",
    },
  },
  logoResponsive2: {
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      width: "57px",
      position: "absolute",
      left: "21px",
      bottom: "10px",
      height: "47px",
    },
  },
  hideOnMobile: {
    borderRadius: "5px",
    fontWeight: "bold",
    marginTop: "0px",
    borderRadius: "10px",
    marginBottom: "13px",
    height: "44px",
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "&:hover": {
      backgroundColor: "rgba(45, 71, 100, 1)",
      color: "#fff",
    },
  },
  finalLogos: {
    fontFamily: "Comfortaa",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "30px",
    textAlign: "center",
    letterSpacing: "0.1em",
    color: "#FFBF1B",
  },
  searchInput: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 36px rgba(251, 218, 97, 0.34)",
    borderRadius: "336px",
    // border: "1px solid #FFE925",
    width: "362px",

    "&.MuiFormControl-root div.MuiOutlinedInput-root": {
      borderRadius: "336px",
      border: "1px solid #FFE925",
      paddingRight: 0,
    },
    "&.MuiFormControl-root div.MuiOutlinedInput-root .MuiInputAdornment-root svg":
      {
        background:
          "linear-gradient(148.54deg, #FFE925 20.91%, #FFAA39 105.47%)",
        borderRadius: "336px",
        width: "50px",
        height: "30px",
      },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      marginTop: "0px",
    },
  },
  yourStyle: {
    marginRight: "3px",
    color: "rgba(0, 0, 0, 1)",
  },
  needMedia: {
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      justifyContent: "space-around",
      margin: "0px",
      width: "73%",
    },
    marginBottom: "-6px",
  },
}));

const DashboardHeader = (props) => {
  const { currentUser, isEnglish, project, closeIframeTrigger } = props;
  const { showYoutubeLiveIframe, setShowYoutubeLiveIframe } = props;
  const classes = useStyles();
  const [forIconChange, setForIconChange] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLiveWebinar, setisLiveWebinar] = useState(false);
  const [webinarPopup, setWebinarPopup] = useState(false);

  useEffect(() => {
    setWebinarPopup(props.showWebinarDialog);
  }, [props.showWebinarDialog]);

  useEffect(() => {
    db.collection("webinar").onSnapshot((snapshots) => {
      const data = [];
      snapshots.forEach((doc) => {
        data.push(doc.data());
      });
      let flag = false;
      if (data.length) {
        data.forEach((web) => {
          if (web.live) {
            flag = true;
          }
        });
      }
      console.log(
        "local storage",
        data.length,
        !localStorage.getItem("joinedWebinar"),
        flag
      );
      if (data.length && !localStorage.getItem("joinedWebinar") && flag) {
        setisLiveWebinar(true);
      } else {
        setisLiveWebinar(false);
      }
    });
  }, [closeIframeTrigger]);
  const onClickHandler = () => {
    dispatch({ type: "SET_COMING_OVERVIEW", payload: true });
    history.push(localStorage.getItem("goBackUrl"));
  };

  const joinWebinar = () => {
    setWebinarPopup(true);
  };

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        sx={{ borderBottom: "1px solid #C0C0C0" }}
        position="static"
        color="inherit"
      >
        <Toolbar
          disableGutters={true}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            style={{
              display: "flex",
              paddingLeft: 20,
              // width: window.innerWidth < 500 ? "" : "303px",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {window.innerWidth < 500 ? (
              <img
                className={
                  currentUser ? classes.logoResponsive2 : classes.logoResponsive
                }
                src={finalLogo}
                alt="img"
              />
            ) : (
              <>
                <div>
                  <img
                    className={classes.logoResponsive}
                    src={finalLogo}
                    alt="img"
                  />
                  <img
                    src={finalText}
                    className={classes.logoResponsive}
                    style={{ marginLeft: "15px" }}
                    alt=""
                  />
                </div>
                {isLiveWebinar && !showYoutubeLiveIframe ? (
                  <div
                    onClick={joinWebinar}
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#2D4764",
                      cursor: "pointer",
                    }}
                  >
                    Join Live Webinar
                  </div>
                ) : null}
              </>
            )}
          </div>

          {(location.pathname.includes("home") ||
            location.pathname.includes("popular") ||
            location.pathname.includes("notification") ||
            location.pathname.includes("faq") ||
            location.pathname.includes("useroverview") ||
            location.pathname.includes("knowledgehub") ||
            location.pathname.includes("community/saved") ||
            location.pathname.includes("profile")) &&
          window.innerWidth > 500 ? (
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={onClickHandler}
            >
              <img style={{ marginRight: "6px" }} src={How} alt="" />
              <div style={{ fontSize: "15px" }}>
                {isEnglish ? "back to the project" : "zurück zum Projekt"}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={classes.needMedia}>
            {window.location.pathname.includes("saved") ? <VersionMenu /> : ""}
            <LanguageDropdown />
            <CustomButton
              url="https://calendly.com/solarhub?hide_gdpr_banner=1"
              setForIconChange={setForIconChange}
              forIconChange={forIconChange}
              prefill={{
                email: currentUser?.email,
                firstName: currentUser?.displayName
                  ?.split(" ")
                  .slice(0, -1)
                  .join(" "),
                lastName: currentUser?.displayName
                  ?.split(" ")
                  .slice(-1)
                  .join(" "),
                name: currentUser?.displayName,
                date: new Date(Date.now() + 86400000),
              }}
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: "00a2ff",
                textColor: "4d5055",
              }}
              utm={{
                utmCampaign: "Spring Sale 2019",
                utmContent: "Shoe and Shirts",
                utmMedium: "Ad",
                utmSource: "Facebook",
                utmTerm: "Spring",
              }}
            />
            <div></div>
            <Dropdown
              currentUser={currentUser}
              isEnglish={isEnglish}
              project={project}
            />
          </div>
        </Toolbar>
      </AppBar>
      {isLiveWebinar && !showYoutubeLiveIframe ? (
        <WebinarPopup
          showYoutubeLiveIframe={showYoutubeLiveIframe}
          setShowYoutubeLiveIframe={setShowYoutubeLiveIframe}
          open={webinarPopup}
          setOpen={setWebinarPopup}
        />
      ) : null}
    </div>
  );
};

const CustomButton = ({
  url,
  prefill,
  pageSettings,
  utm,
  setForIconChange,
  forIconChange,
}) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <Button
      className={classes.hideOnMobile}
      onMouseEnter={() => setForIconChange(true)}
      onClick={onClick}
      onMouseLeave={() => setForIconChange(false)}
    >
      <CallIcon
        className={classes.yourStyle}
        style={{ color: forIconChange ? "#fff" : "black" }}
      />
      {isEnglish ? "Book a call" : "Kontakt"}
    </Button>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isEnglish: state.user.isEnglish,
  project: state.project,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchMethods: {
    setProjectType: (data) =>
      dispatch({ type: "SET_PROJECTTYPE", payload: data }),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
