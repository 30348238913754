import React from "react";

const Maps = () => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: "3px" }}
    >
      <path
        d="M3.85938 14.8145C4.43984 15.5516 4.96814 16.3281 5.44045 17.1383C5.84371 17.9002 6.01182 18.4169 6.30653 19.335C6.48727 19.8423 6.65062 19.9937 7.00183 19.9937C7.38453 19.9937 7.55816 19.7361 7.69231 19.3378C7.97089 18.4708 8.18948 17.8091 8.53434 17.1838C9.21104 15.9768 10.0519 14.9041 10.8781 13.8732C11.1018 13.5814 12.548 11.8817 13.199 10.5405C13.199 10.5405 13.9992 9.06717 13.9992 7.00956C13.9992 5.08488 13.2101 3.75 13.2101 3.75L10.9381 4.35632L9.55831 7.97763L9.21695 8.47698L9.1487 8.56744L9.05791 8.68068L8.89855 8.86155L8.67084 9.08842L7.44217 10.0859L4.37038 11.8533L3.85938 14.8145Z"
        fill="#34A853"
      />
      <path
        d="M0.6875 10.294C1.43721 12.0005 2.88293 13.5005 3.86107 14.8169L9.05644 8.68428C9.05644 8.68428 8.3245 9.63818 6.9968 9.63818C5.51788 9.63818 4.32313 8.46134 4.32313 6.97742C4.32313 5.95982 4.93748 5.26074 4.93748 5.26074L1.41074 6.20243L0.6875 10.294V10.294Z"
        fill="#FBBC04"
      />
      <path
        d="M9.11641 0.315484C10.8419 0.869871 12.3188 2.03374 13.2121 3.74998L9.05998 8.68066C9.05998 8.68066 9.67434 7.96894 9.67434 6.95766C9.67434 5.43919 8.3912 4.3048 7.00504 4.3048C5.69423 4.3048 4.94141 5.25712 4.94141 5.25712V2.15085L9.11641 0.31543V0.315484Z"
        fill="#4285F4"
      />
      <path
        d="M1.64062 2.49401C2.67121 1.26529 4.48465 0 6.98474 0C8.19771 0 9.11154 0.31733 9.11154 0.31733L4.93691 5.25974H1.97914L1.64062 2.49406V2.49401Z"
        fill="#1A73E8"
      />
      <path
        d="M0.689719 10.293C0.689719 10.293 0 8.94705 0 6.99719C0 5.1543 0.718922 3.54341 1.64145 2.49316L4.94009 5.25932L0.689719 10.293Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default Maps;
