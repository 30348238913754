import React, { useState, useEffect } from "react";
import { TextField, Checkbox } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { db } from "../../../../firebase";
import { keys } from "@mui/system";

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Properties = ({ params, propertiesList, setpropertiesList }) => {
  const [paramvalue, setparamvalue] = useState([]);
  useEffect(() => {
    db.collection("products")
      .doc(params.id)
      .get()
      .then((doc) => {
        const product = doc.data();
        //product.properties
        let pushArray = [];
        for (const key in product.properties) {
          pushArray.push({ title: key });
        }
        if (product.properties) {
          setparamvalue(pushArray);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  const savePropertiesToFirestore = (values) => {
    let obj = {};
    for (let i = 0; i < values.length; i++) {
      if (!values[i].hasOwnProperty("title")) {
        obj[values[i]] = true;
      } else {
        obj[values[i].title] = true;
      }
    }
    console.log("obj", obj);
    db.collection("products")
      .doc(params.id)
      .set({ ...params.row, properties: obj })
      .then(() => {
        console.log("saved");
      })
      .catch((err) => console.log("errr", err));
  };

  const addNewPropertytoFirestore = (property) => {
    const docRef = db.collection("constants").doc("product");
    docRef.get().then((doc) => {
      const properties = doc.data().productPropertiesList;
      const propertiesArray = properties.map((opt) => {
        return { title: opt };
      });
      setpropertiesList([...propertiesArray, { title: property }]);
      docRef.set({
        productPropertiesList: [...properties, property],
      });
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableCloseOnSelect
        autoHighlight={true}
        value={paramvalue}
        onChange={(event, newValue) => {
          let newArray = [];
          setparamvalue((prev) => {
            if (prev.length < newValue.length) {
              const obj = newValue[newValue.length - 1];
              if (obj?.inputValue) {
                newArray = prev.map((opt) => opt);
                newArray.push(obj?.inputValue);
                addNewPropertytoFirestore(obj?.inputValue);
                savePropertiesToFirestore(newArray);
                return newArray;
              }
            }
            newArray = newValue.map((opt) => opt.title);
            savePropertiesToFirestore(newValue);
            return newValue;
          });
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={propertiesList}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }

          if (option?.inputValue) {
            return option.inputValue;
          }

          return option?.title;
        }}
        renderOption={(option) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={paramvalue.some(
                (data) =>
                  data.title.toLowerCase().trim() ===
                  option.title.toLowerCase().trim()
              )}
            />
            {option.title}
          </React.Fragment>
        )}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    </div>
  );
};

export default Properties;
