import { useGridApiContext } from "@mui/x-data-grid-pro";
import ChnageLongText from "../ChnageLongText";

const RatingEditInputCell = (props) => {
  const apiRef = useGridApiContext();
  console.log(props, "props");
  const { id, value, field } = props;
  const handleChange = (event) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return <ChnageLongText value={value} handleChange={handleChange} />;
};

export const renderRatingEditInputCell = (params) => {
  return <RatingEditInputCell {...params} />;
};
