import React, { useRef, useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@material-ui/core/colors";
import { IconButton, Checkbox } from "@material-ui/core";
import ShowImage from "./topics/partials/ShowImage";
import UploadPic from "./topics/UploadPic";
import axios from "axios";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { toast } from "react-toastify";
import UserList from "./topics/partials/UserList";
import MultipleProjects from "popups/multipleProjects/MultipleProjects";

const { Option } = Select;

import { getError } from "helperMethod";
const MainContainer = styled("div")`
  width: 560px;
  min-height: 632px;
  max-height: 749px;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 30px;
  @media (max-width: 600px) {
    width: 97%;
    max-height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .submit {
    width: 182px;
    height: 46px;
    background: #ffd75d;
    border-radius: 42px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    color: #2d4764;
    margin-top: 24px;
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    max-height: 280px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    background-color: white;
    transform: translate(100px, 245px);
    z-index: 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  .dropdown-body {
    max-height: 280px;
    width: 100%;
    border-top: 1px solid #e5e8ec;
    display: none;
    overflow: auto;
    overflow-x: hidden;
  }

  .dropdown-body.open {
    display: flex;
    flex-direction: column;
  }
  .dropdown-item {
    width: 100%;
    border-bottom: 1px solid #f9f9f9;
    padding: 0px !important;
  }

  .dropdown-item:hover {
    cursor: pointer;
    background: #f9f9f9;
  }
`;
const Header = styled("div")`
  width: 100%;
  padding: 12px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c0c0c0;
`;
const HeaderText = styled("div")`
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #2d4764;
`;
const SubContainer = styled("div")`
  position: relative;
  padding-left: 28px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  padding-top: 24px;
  padding-right: 36px;
`;
const SubContainer1 = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Text1 = styled("div")`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #2d4764;
`;
const TopicInput = styled("input")`
  margin-top: 14px;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 34px 12px 30px;
  width: 472px;
  height: 46px;
  @media (max-width: 600px) {
    width: auto;
  }
  background: #f9f9f9;
  border-radius: 10px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
`;
const TopicDes = styled("textarea")`
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  width: 472px;
  @media (max-width: 600px) {
    width: auto;
  }
  min-height: 112px;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 30px;
  padding-top: 12px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #2d4764;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;
const TopicCheck = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 14px;
`;
const TopicCheckText = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
`;
const Upload = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 472px;
  @media (max-width: 600px) {
    width: auto;
  }
  height: 38px;
  background: #f9f9f9;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 12px;
  padding-left: 40px;
  padding-bottom: 30px;
`;
const UploadImage11 = styled("div")`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #2d4764;
  cursor: pointer;
`;
const initialData = {
  title: "",
  raw: "",
  category: "",
  username: "",
  tags: [],
};
const CreateTopicModal = ({ setOpen, open }) => {
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userName, selectedCategoryID] = useSelector((state) => [
    state.user.username,
    state.savedCategory.category,
  ]);
  const [allCategory, setAllCategory] = useState([]);
  const [alltag, setAllTag] = useState([]);
  const image_element = useRef(null);
  const [image, setimage] = useState(null);
  const [preview, setPreview] = useState("");
  const [open2, setOpen2] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [savedProjectUrl, setSavedProjectUrl] = useState("");
  const [isOpen, setOpen1] = useState(false);
  const [count, setCount] = useState(0);
  const [checked, setChecked] = React.useState(true);
  const [topicData, setTopicData] = useState(initialData);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  const handleChangeForProject = (event) => {
    setChecked(event.target.checked);
    setOpen2(event.target.checked);
  };
  useEffect(() => {
    console.log(savedProjectUrl);
    setTopicData((prev) => {
      let temp = {
        ...prev,
      };
      temp.raw = temp.raw + savedProjectUrl;
      return temp;
    });
    setSavedProjectUrl("");
  }, [savedProjectUrl]);
  useEffect(() => {
    if (open2 === false && !isSelected) {
      setChecked(false);
    }
  }, [open2]);
  useEffect(() => {
    console.log("sar", topicData.raw);
  }, [topicData]);
  const handleClose = () => {
    setLoader(false);
    setTopicData(initialData);
    setimage(null);
    setCount(0);
    setChecked(false);
    setOpen1(false);
    setOpen(false);
  };
  useEffect(() => {
    var config = {
      method: "get",
      url: "https://us-central1-solarhub-44450.cloudfunctions.net/api/categories/list-with-topic",
      headers: {},
    };
    axios(config)
      .then(function (response) {
        const res = response.data;
        let res1 = [];
        for (let i = 0; i < res.length; i++) {
          let t = {};
          t.id = res[i].id;
          t.title = res[i].title;
          res1.push(t);
        }
        setAllCategory(res1);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [selectedCategoryID, open]);
  useEffect(() => {
    var config = {
      method: "get",
      url: "https://us-central1-solarhub-44450.cloudfunctions.net/api/tags",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        const res = response.data;
        let res1 = [];
        for (let i = 0; i < res.length; i++) {
          res1.push(res[i].name);
        }
        setAllTag(res1);
      })
      .catch(function (err) {
        if (err?.response?.data?.errors?.length) {
          toast.error(getError(err?.response?.data?.errors));
        }
      });
  }, []);
  useEffect(() => {
    var config = {
      method: "get",
      url: "https://us-central1-solarhub-44450.cloudfunctions.net/api/users/list",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setUserList(response.data);
      })
      .catch(function (err) {
        if (err?.response?.data?.errors?.length) {
          toast.error(getError(err?.response?.data?.errors));
        }
      });
  }, []);
  useEffect(() => {
    if (!image) {
      setPreview("");
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);
  useEffect(() => {
    setTopicData((prev) => {
      let temp = {
        ...prev,
      };
      temp.username = userName;
      console.log(temp, "temp");
      return temp;
    });
  }, [userName, open]);
  const onSubmit = async () => {
    if (topicData.title === "") {
      toast.warning("Enter the Title");
      return;
    }

    if (topicData.category === "") {
      toast.warning("Select the Category");
      return;
    }
    if (topicData.raw === "") {
      toast.warning("Enter the Description");
      return;
    }

    setLoader(true);
    if (image) {
      let data = new FormData();
      data.append("media", image);
      console.log("sar", data);
      var config = {
        method: "post",
        url: "https://us-central1-solarhub-44450.cloudfunctions.net/api/general/file-upload",
        data: data,
      };
      let response = await axios.post(
        "https://us-central1-solarhub-44450.cloudfunctions.net/api/general/file-upload",
        data
      );
      const path = `![${response.data.data.original_filename}|${response.data.data.thumbnail_width}x${response.data.data.thumbnail_height}](${response.data.data.short_url})`;
      let newTopicData = {
        ...topicData,
      };
      newTopicData.raw = newTopicData.raw + path;
      var config = {
        method: "post",
        url: "https://us-central1-solarhub-44450.cloudfunctions.net/api/topics",
        headers: {},
        data: newTopicData,
      };
      axios(config)
        .then(function (response) {
          console.log("sar", response.data);
          setimage(null);
          handleClose();
          toast.success("Topic created successfully");
        })
        .catch(function (err) {
          if (err?.response?.data?.errors?.length) {
            toast.error(getError(err?.response?.data?.errors));
          }
          setLoader(false);
        });
    } else {
      var config = {
        method: "post",
        url: "https://us-central1-solarhub-44450.cloudfunctions.net/api/topics",
        headers: {},
        data: topicData,
      };
      axios(config)
        .then(function (response) {
          console.log("sar", response.data);
          handleClose();
          toast.success("Topic created successfully");
        })
        .catch(function (err) {
          if (err?.response?.data?.errors?.length) {
            toast.error(getError(err?.response?.data?.errors));
          } else {
            toast.error(err?.response?.data?.username);
          }
          setLoader(false);
        });
    }
  };
  const UploadImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setimage(null);
      return;
    }
    setimage(e.target.files[0]);
  };
  function handleChange(value) {
    setTopicData((prev) => {
      let temp = {
        ...prev,
      };
      temp.category = value;
      return temp;
    });
  }
  function handleChange1(value) {
    setTopicData((prev) => {
      let temp = {
        ...prev,
      };
      temp.tags = value;
      return temp;
    });
  }
  const handleItemClick = (e, id) => {
    e.preventDefault();
    console.log("sar", id);
    let t = topicData.raw;
    t += id;
    setTopicData((prev) => {
      let temp = {
        ...prev,
      };
      temp.raw = t;
      return temp;
    });
    setCount((prev) => {
      return prev + 1;
    });
    setOpen1(false);
  };
  useEffect(() => {
    if (allCategory.length > 0) {
      if (selectedCategoryID) {
        for (let i = 0; i < allCategory.length; i++) {
          if (allCategory[i].id === selectedCategoryID.id) {
            setTopicData((prev) => {
              let temp = {
                ...prev,
              };
              temp.category = selectedCategoryID.id;
              return temp;
            });
          }
        }
      }
    }
  }, [allCategory, selectedCategoryID]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MainContainer>
          <Header>
            <HeaderText>
              {isEnglish ? "Add new topic" : "Neues Thema hinzufügen"}
            </HeaderText>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Header>
          <SubContainer>
            <SubContainer1>
              <Text1> {isEnglish ? "Topic title" : "Thementitel"}</Text1>
              <Select
                style={{ width: 160 }}
                onChange={handleChange}
                placeholder="Select a Category"
                defaultValue={
                  selectedCategoryID ? selectedCategoryID.title : null
                }
              >
                {allCategory.map((data, index) => {
                  return (
                    <Option key={index} value={data.id}>
                      {data.title}
                    </Option>
                  );
                })}
              </Select>
            </SubContainer1>
            <TopicInput
              onChange={(e) => {
                setTopicData((prev) => {
                  let temp = {
                    ...prev,
                  };
                  temp.title = e.target.value;
                  return temp;
                });
              }}
            />
            <input
              type="file"
              accept="image/*"
              ref={image_element}
              onChange={UploadImage}
              disabled={image ? true : false}
              hidden
            />
            <Text1 style={{ marginTop: "18px", marginBottom: "10px" }}>
              {isEnglish ? "Description" : "Beschreibung"}
            </Text1>
            <TopicDes
              onChange={(e) => {
                let data = e.target.value;
                let c = 0;
                for (let i = 0; i < data.length; i++) {
                  if (data[i] === "@") {
                    c++;
                  }
                }
                setTopicData((prev) => {
                  let temp = {
                    ...prev,
                  };
                  temp.raw = e.target.value;
                  return temp;
                });
                if (c > count) setOpen1(true);
                if (c < count) setCount(c);
              }}
              disabled={isOpen}
              value={topicData.raw}
            />
            {image ? (
              <ShowImage image={image} setimage={setimage} preview={preview} />
            ) : null}
            {image ? null : (
              <Upload>
                <UploadPic image_element={image_element} />
                <UploadImage11
                  onClick={() => {
                    image_element.current.click();
                  }}
                >
                  {isEnglish ? "Add image" : "Bild hinzufügen"}
                </UploadImage11>
              </Upload>
            )}
            {isOpen && (
              <div className="dropdown">
                <div className={`dropdown-body ${isOpen && "open"}`}>
                  {userList.map((item) => (
                    <UserList item={item} handleItemClick={handleItemClick} />
                  ))}
                </div>
              </div>
            )}

            <Text1 style={{ marginTop: "18px", marginBottom: "10px" }}>
              {isEnglish ? "Add tag" : "Tag hinzufügen"}
            </Text1>
            <Select
              mode="multiple"
              size={"large"}
              placeholder={
                isEnglish ? "Please select a Tag" : "ergänzen Sie ein tag"
              }
              onChange={handleChange1}
              style={{ width: "95%" }}
            >
              {alltag.map((data, index) => {
                return (
                  <Option key={index} value={data}>
                    {data}
                  </Option>
                );
              })}
            </Select>
            <TopicCheck style={{ marginTop: "8px" }}>
              <Checkbox
                color="rgba(255, 215, 93, 1)"
                checked={checked}
                onChange={handleChangeForProject}
              />
              <TopicCheckText>
                {isEnglish
                  ? "Add project Link for your saved configurations to the post"
                  : "Fügen Sie dem Beitrag einen Projektlink für Ihre gespeicherte Konfiguration hinzu"}
              </TopicCheckText>
            </TopicCheck>
            <Button className="submit" onClick={onSubmit} loading={loader}>
              {isEnglish ? "Create Topic" : "Thema erstellen"}
            </Button>
          </SubContainer>
          <MultipleProjects
            open={open2}
            setOpen={setOpen2}
            setSavedProjectUrl={setSavedProjectUrl}
            setIsSelected={setIsSelected}
          />
        </MainContainer>
      </Modal>
    </div>
  );
};

export default CreateTopicModal;
