import { db } from "../../../firebase";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import WebinarGroupChat from "Components/organisms/webinarChat/WebinarGroupChat";
import { useSelector } from "react-redux";
const IconContainer = styled.div`
  position: absolute;
  top: ${(props) => (props.fullScreen ? "83px" : "73px")};
  right: ${(props) => (props.fullScreen ? "20px" : "5px")};
  color: white;
  &:hover {
    cursor: pointer;
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  color: white;
  padding: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const YoutubeLiveIframe = ({
  show,
  setShow,
  setcloseIframeTrigger,
  fullScreen,
  setFullScreen,
}) => {
  const [webinarLinks, setWebinarLinks] = useState([]);
  // const [fullScreen, setFullScreen] = useState(false);
  const [height, setheight] = useState(100);
  const [width, setwidth] = useState(200);
  const isLoggedInComet = useSelector((state) => state.chat?.isLoggedIn);
  //const cometChatUser = useSelector((state) => state.chat?.user);

  useEffect(() => {
    db.collection("webinar").onSnapshot((snapshots) => {
      const data = [];
      snapshots.forEach((doc) => {
        data.push(doc.data());
      });
      console.log("webinar data", data);
      setWebinarLinks(data);
    });
  }, []);

  const closeYoutubeIframe = () => {
    setShow(false);
    localStorage.removeItem("joinedWebinar");
    setcloseIframeTrigger((prev) => !prev);
  };

  return (
    <Fragment>
      {webinarLinks.map((iframe) => {
        if (iframe.live) {
          return (
            <>
              <CloseIconContainer onClick={closeYoutubeIframe}>
                <CloseIcon />
              </CloseIconContainer>
              <iframe
                width={width}
                height={height}
                src={iframe.embedLink}
                frameborder="0"
                allowFullScreen
              ></iframe>
              <IconContainer
                fullScreen={fullScreen}
                onClick={() => {
                  if (fullScreen) {
                    setwidth(250);
                    setheight(150);
                  } else {
                    setwidth("100%");
                    setheight("100%");
                  }
                  setFullScreen((prev) => !prev);
                }}
              >
                {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconContainer>
              {isLoggedInComet && (
                <WebinarGroupChat id={iframe.id} name={iframe.id} />
              )}
            </>
          );
        }
        return;
      })}
    </Fragment>
  );
};

export default YoutubeLiveIframe;
