import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import ProfileCard from "./ProfileCard";
import { UserMetadata } from "firebase-admin/lib/auth/user-record";
import axios from "axios";
const HomeWrapper = styled("div")`
  .root {
    border: 2px solid #dfdfdf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 94vh;
    width: 100%;
    background: #f9f9f9;
  }
`;
const HeaderText = styled("div")`
  display: flex;
  width: 100%;
  font-family: Nunito;
  font-weight: bold;
  font-size: 24px;
  color: #2d4764;
  margin-top: 28px;
  margin-left: 34px;
`;
const HeaderLodaer = styled("div")`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Profile = () => {
  const [isEnglish, userName] = useSelector((state) => [
    state.global.isEnglish,
    state.user.username,
  ]);
  const [UserData, setUserData] = useState(null);
  useEffect(() => {
    const func = async () => {
      try {
        const res = await axios.get(
          `https://us-central1-solarhub-44450.cloudfunctions.net/api/users/${userName}`
        );
        console.log("sar", res.data.user);
        setUserData(res.data.user);
      } catch (err) {
        console.log("err", err);
      }
    };

    func();
  }, []);
  return (
    <HomeWrapper>
      {!UserData ? (
        <HeaderLodaer>
          <CircularProgress
            style={{
              color: "#fbda61",
            }}
          />
        </HeaderLodaer>
      ) : (
        <div className="root">
          <HeaderText>{isEnglish ? "Profile" : "Profil"}</HeaderText>
          <ProfileCard userData={UserData} />
        </div>
      )}
    </HomeWrapper>
  );
};

export default Profile;
