export const vendors = [
  {
    company_url:
      "https://de.enfsolar.com/zize-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=634&utm_campaign=profiles_installer",
    company_name: "Zize Elektro",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ff69fb9a67f3.png?v=1",
    address: "Dorfstr. 72, 24242, Felde",
    telephone: "+49 4340 1526",
    website: "http://www.zize.homepage.t-online.de",
    email: "ZIZE.Elektrotechnik@t-online.de",
    "Installaliert seit": 1997,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-ressler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81800&utm_campaign=profiles_installer",
    company_name: "Zimmerei Ressler",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58957a784b067.jpg?v=1",
    address: "Schongauer Str. 58, 82380 Peißenberg",
    telephone: "+49 8803 63730",
    website: "http://www.zimmerei-ressler.de",
    email: "zimmerei.ressler@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-gottlinger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116895&utm_campaign=profiles_installer",
    company_name: "Zimmerei Göttlinger",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ea1fd0889c.jpg?v=1",
    address: "Ginnerting 18, 83112 Frasdorf",
    telephone: "+49 8052 9395",
    website: "https://www.zimmerei-goettlinger.de",
    email: "zimmerei-goettlinger@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/baumann-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106248&utm_campaign=profiles_installer",
    company_name: "Fernmeldebau Baumann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50366737f35.png?v=1",
    address: "Schleusenstraße 20, 46282 Dorsten",
    telephone: "+49 2362 91980",
    website: "https://www.baumann-fernmeldebau.de",
    email: "Zentrale@fb-baumann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94247&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdde07e4060.png?v=1",
    address: "Eisenbahnstraße 3, 48231 Warendorf-Freckenhorst",
    telephone: "+49 2581 44523",
    website: "http://www.franznilles-bedachungen.de",
    email: "wolff@franznilles.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-wolf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93740&utm_campaign=profiles_installer",
    company_name: "Elektro Wolf GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c36b48befa7.jpg?v=1",
    address: "Gleimershäuser Straße 24, 98617 Meiningen, Dreißigacker",
    telephone: "+49 3693 44780",
    website: "http://www.derelektrowolf.de",
    email: "wir@derelektrowolf.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wind-westerwald?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11179&utm_campaign=profiles_installer",
    company_name: "Wind-Westerwald",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56c044360b646.jpg?v=1",
    address: "Im Bangert 1, 65606, Villmar-Weyer",
    telephone: "+49 6483 911047",
    website: "http://www.wind-westerwald.de",
    email: "wind@wind-westerwald.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Siemens Energy,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/parabel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=569&utm_campaign=profiles_installer",
    company_name: "Parabel Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/569n.gif?v=1",
    address: "Holzhauser Straße 177, 13509 Berlin",
    telephone: "+49 30 214802275",
    website: "http://www.parabel-solar.de",
    email: "will@parabel-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/heine-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94220&utm_campaign=profiles_installer",
    company_name: "Heine Bedachungen e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc123950e4.png?v=1",
    address: "Im Bulloh 22, 29331 Lachendorf",
    telephone: "+49 5145 93636",
    website: "http://www.heine-bedachungen.de",
    email: "wilkens@heine-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wiese?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102360&utm_campaign=profiles_installer",
    company_name: "Wiese Installationstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b5ba969f8.png?v=1",
    address: "Blumenstraße 3, 32758, Detmold",
    telephone: "+49 5231 91580",
    website: "http://www.wiese-heizung.de",
    email: "Wiese-Sanitaer@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wiedenhoft-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93294&utm_campaign=profiles_installer",
    company_name: "Wiedenhöft Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba24173f56f.jpg?v=1",
    address: "Driesch 70b, 52525, Heinsberg",
    telephone: "+49 2453 3839501",
    website: "http://wiedenhoeft-bedachungen.de/",
    email: "wiedenhoeft-bedachungen@hotmail.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/werner-schneider?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124871&utm_campaign=profiles_installer",
    company_name: "Photovoltaik & Elektrotechnik Werner Schneider",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecfa65e5c45a.png?v=1",
    address: "Hauptstr. 27, 55608 Becherbach",
    telephone: "+49 6757 474",
    website: "http://ws-elektromeister.net/",
    email: "werner-schneider@gmx.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/welker-dachtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11038&utm_campaign=profiles_installer",
    company_name: "Welker Dachtechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fec92b17f324.png?v=1",
    address: "Am Köppel 19, 35683, Dillenburg",
    telephone: "+49 2771 34314",
    website: "http://www.welker-dachtechnik.de",
    email: "welker-dachtechnik@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/photovoltaikanlagen-weitershaus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93298&utm_campaign=profiles_installer",
    company_name: "Photovoltaikanlagen Weitershaus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba275c40fdb.jpg?v=1",
    address: "Alte Hütte 2, 57537, Wissen",
    telephone: "+49 2742 9698061",
    website: "http://weitershaus.de/",
    email: "weitershaus@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-weigelt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10628&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Gregor Weigelt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/587c3f864a6dc.jpg?v=1",
    address: "Am Winkelstück 120, 58239, Schwerte",
    telephone: "+49 2304 73579",
    website: "http://dachdecker-weigelt.de/",
    email: "weigelt-bedachungen@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/steinhauser-electronic?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75573&utm_campaign=profiles_installer",
    company_name: "Steinhauser Electronic GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae3df33bf13.PNG?v=1",
    address: "Holzgasse 2, 88361 Altshausen",
    telephone: "+49 7584 920770",
    website: "http://www.steinhauser-gmbh.de",
    email: "webmaster@steinhauser-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lausitzsonne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83151&utm_campaign=profiles_installer",
    company_name: "PV-Anlage Lausitzsonne",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58bcf91fa19f3.jpg?v=1",
    address: "Lange Straße 2b, 02957 Weißkeißel",
    telephone: "",
    website: "http://lausitzsonne.info",
    email: "webmaster@lausitzsonne.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/planungsburos-weber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81793&utm_campaign=profiles_installer",
    company_name: "Planungsbüros Weber",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589577c7e3258.jpg?v=1",
    address: "Fichtenstr. 14, D-90574 Roßtal",
    telephone: "+49 9127 1624",
    website: "http://www.weber-rosstal.de",
    email: "weber-rosstal@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/krk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76663&utm_campaign=profiles_installer",
    company_name: "Kreie & Kreie GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6082ee9676a64.PNG?v=1",
    address: "Egelsberg 16, 34434 Borgentreich",
    telephone: "+49 5645 2083208",
    website: "https://www.krk-elektrotechnik.de",
    email: "web@krk-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gsm-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10335&utm_campaign=profiles_installer",
    company_name: "Gesellschaft für Solar-Management mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b10ad1cad95e.png?v=1",
    address: "Hof Lethaus 32, 59192 Bergkamen",
    telephone: "+49 2307 9640231",
    website: "http://www.gsmsolar.de",
    email: "web@gsmsolar.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gernot-berner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102028&utm_campaign=profiles_installer",
    company_name: "Gernot Berner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab70f99fc68.jpg?v=1",
    address: "Stützeläckerweg 11, 60489 Frankfurt/M",
    telephone: "+49 69 9788220",
    website: "https://www.berner-dach.de",
    email: "web@berner-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-vollmer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81959&utm_campaign=profiles_installer",
    company_name: "Elektro Vollmer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bdbce71a78.jpg?v=1",
    address: "Zillhauserstr.9, 72459 Albstadt-Pfeffingen",
    telephone: "+49 7432 8727",
    website: "http://www.elektro-vollmer.de",
    email: "waltervollmer@freenet.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Siemens Energy,,,Eaton Corporation,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/albrechtip?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91928&utm_campaign=profiles_installer",
    company_name: "AlbrechtIP - Erneuerbare Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599bd9e267876.jpg?v=1",
    address:
      "Hinter der Mühle 6, 39164 Wanzleben-Börde OT Klein Rodensleben Sachsen,Anhalt",
    telephone: "+49 39204 66224",
    website: "http://www.albrechtip.de",
    email: "w.albrecht@albrechtip.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Luxor Solar GmbH,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunprofit-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=126773&utm_campaign=profiles_installer",
    company_name: "SunProfit GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f4358788d2c5.jpg?v=1",
    address: "Breithauptstr. 1, 64404 Bickenbach",
    telephone: "+49 6257 936890",
    website: "http://www.sunprofit.de",
    email: "viktor.braun@sunprofit.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,SunPower Corp.,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: ",Enerplex Gruppe,",
    "Last Update": 8092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/deg-dach-fassade-holz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94483&utm_campaign=profiles_installer",
    company_name: "DEG Dach-Fassade-Holz eG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc5f7e66fc8.jpg?v=1",
    address: "Oberster Kamp 6, 59069 Hamm-Rhynern",
    telephone: "+49 2385 9330",
    website: "https://www.dde.de",
    email: "vetrieb@dde.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/westkusten-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76007&utm_campaign=profiles_installer",
    company_name: "Westküsten-Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d61a084c253.jpg?v=1",
    address: "Rosenburger Weg 24a, 25821 Bredstedt",
    telephone: "+49 4671 4049360",
    website: "http://www.westkuesten-solar.de",
    email: "verwaltung@westkuesten-solar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarvertrieb-ringena?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93988&utm_campaign=profiles_installer",
    company_name: "Solarvertrieb Ringena",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b037dd082.jpg?v=1",
    address: "Taubenstraße 11, 60313 Frankfurt am Main",
    telephone: "+49 4921 6372",
    website: "http://www.solarvertrieb-ringena.de",
    email: "vertrieb@solarvertrieb-ringena.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Kyocera Corporation,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mitgas?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107912&utm_campaign=profiles_installer",
    company_name: "Mitgas Mitteldeutsche Gasversorgung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88b59339d50.jpg?v=1",
    address: "Industriestr. 10, 06184 Kabelsketal",
    telephone: "+49 3452160",
    website: "https://www.mitgas.de",
    email: "vertrieb@mitgas.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/beg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=89640&utm_campaign=profiles_installer",
    company_name: "Bayerische Energiegesellschaft - Sunface GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/18765963a09828f74.gif?v=1",
    address: "Albert-Einstein Str. 6, 86899 Landsberg am Lech",
    telephone: "+49 8191 9729190",
    website: "http://www.bayrische-energiegesellschaft.de",
    email: "vertrieb@b-eg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/1a-solar-projekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10058&utm_campaign=profiles_installer",
    company_name: "1A-Solar-Projekt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10058n.gif?v=1",
    address: "Nussbergstr. 65, 97422, Schweinfurt",
    telephone: "+49 9721 32812",
    website: "http://www.1a-solar.com",
    email: "vertrieb@1a-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/uwe-gudella-sanitar-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92475&utm_campaign=profiles_installer",
    company_name: "Uwe Gudella Sanitär - Heizung e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a900075457a.jpg?v=1",
    address: "Halterner Straße 261, 46284 Dorsten",
    telephone: "+49 2362 71312",
    website: "http://www.heizung-gudella.de",
    email: "uwegudella@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-jakobs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92149&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Jakobs",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3c00273c5f.jpg?v=1",
    address: "Selbecker Str.165, DE-58091 Hagen",
    telephone: "+49 2331 7397348",
    website: "http://www.dachdecker-jakobs.de",
    email: "info@dachdecker-jakobs.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-elektro-kapell?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93768&utm_campaign=profiles_installer",
    company_name: "Solar & Elektro Kapell",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c464082ab61.jpg?v=1",
    address: "Ricarda-Huch-Weg 24, 07743 Jena",
    telephone: "+49 178 9197940",
    website: "http://www.solarstrom-jena.de",
    email: "ukapell@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bergander-u-broich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94456&utm_campaign=profiles_installer",
    company_name: "Bergander u. Broich GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc3a9e26487.jpg?v=1",
    address: "Eschweg 1, 32457 Porta-Westfalica",
    telephone: "+49 5731 763175",
    website: "http://www.bergander-dach.de",
    email: "u.bergander@rubo.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tps-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99191&utm_campaign=profiles_installer",
    company_name: "Thüringer Photovoltaik Service UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c2be288a45.png?v=1",
    address: "Gallenberg 4d, 07356 Bad Lobenstein",
    telephone: "+49 36649 790282",
    website: "http://www.tps-energie.de",
    email: "tps2010@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thurow-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1766&utm_campaign=profiles_installer",
    company_name: "Thurow Gas-u. Ölfeuerungs-Service",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5167a98b28c87.bmp?v=1",
    address: "Rudolf-Marek-Str. 9, 09112 Chemnitz",
    telephone: "+49 371 360733",
    website: "http://www.thurow-service.de",
    email: "thurowservice@aol.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroanlagen-rudolph?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81558&utm_campaign=profiles_installer",
    company_name: "Elektro Rudolph GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5febb54792130.png?v=1",
    address: "Hauptstraße 31, 99887 Gräfenhain",
    telephone: "+49 3624 311599",
    website: "http://www.elektroanlagen-rudolph.de",
    email: "thomas.rudolph@me-ru.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-conclusion?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112665&utm_campaign=profiles_installer",
    company_name: "Solar Conclusion GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c7cc2973d9b1.jpg?v=1",
    address: "Zur Aussicht 9, 74906 Bad Rappenau",
    telephone: "+49 172 7131735",
    website: "http://www.solar-conclusion.com",
    email: "thomas.rostock@roofsolutions.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heick-bedachungsunternehmen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81968&utm_campaign=profiles_installer",
    company_name: "Heick Bedachungsunternehmen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589be2f6daed6.jpg?v=1",
    address: "Mühlenweg 1, D-18442 Steinhagen",
    telephone: "+49 38327 60628",
    website: "http://www.heick-bedachungsunternehmen.de",
    email: "thomas.heick@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-adelmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94662&utm_campaign=profiles_installer",
    company_name: "Elektro-Adelmann GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddbcf998509.png?v=1",
    address: "Marburger Straße 13, 64289 Darmstadt",
    telephone: "+49 6151 73000",
    website: "http://www.elektro-adelmann.de",
    email: "technik@elektro-adelmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/michael-storch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99157&utm_campaign=profiles_installer",
    company_name: "Michael Storch Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c0f914ff3e.png?v=1",
    address: "Tegelheide 24a, 49186 Bad Iburg",
    telephone: "+49 5403 794141",
    website: "http://www.storch-elektro.de",
    email: "team@storch-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/planungsburo-poppl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10563&utm_campaign=profiles_installer",
    company_name: "Planungsbüro Pöppl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ce3bd29d75d4.png?v=1",
    address: "Industriestr. 71, 92345 Dietfurt",
    telephone: "+49 8464 6019090",
    website: "http://www.solarstrom-poeppl.de",
    email: "team@solarstrom-poeppl.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-rohe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92265&utm_campaign=profiles_installer",
    company_name: "Elektro Röhe GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a51be3664cd.jpg?v=1",
    address: "Stake 6, 25813 Schwesing",
    telephone: "+49 4841 773434",
    website: "http://www.elektrotechnik-roehe.de",
    email: "t.roehe@elektro-roehe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/suntec-energiesysteme-hessen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1571&utm_campaign=profiles_installer",
    company_name: "Suntec Hessen Solarsysteme GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/522ed62a5e70c.gif?v=1",
    address: "Hohe Str. 6, 35625 Hüttenberg",
    telephone: "+49 6403 774984",
    website: "http://www.suntec-hessen.de",
    email: "suntecsolarsysteme@.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunshinesolar-systemmontage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75847&utm_campaign=profiles_installer",
    company_name: "Sunshinesolar-Systemmontage GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdcff4197237.png?v=1",
    address: "Bergstraße 2, 06467 Hoym",
    telephone: "+49 176 32446999",
    website: "http://www.sunshinesolar-gmbh.de",
    email: "sunshinesolar@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/phlegonsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=16782&utm_campaign=profiles_installer",
    company_name: "PHLEGONsolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/16782n.gif?v=1",
    address: "Ornatsöd 1, 94107, Untergriesbach",
    telephone: "+49 8593 913990",
    website: "http://www.phlegon-solar.com",
    email: "sun@phlegon-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ms-construction-managment?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93030&utm_campaign=profiles_installer",
    company_name: "MS Construction Managment GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5f86135789.jpg?v=1",
    address: "Gewerbepark 5, 66989 Höhfröschen",
    telephone: "+49 172 2921389",
    website: "http://www.ms-constructionmanagement.de",
    email: "stretz@ms-global.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Mastervolt International BV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/klaus-streib?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1631&utm_campaign=profiles_installer",
    company_name: "Klaus Streib GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1631n.gif?v=1",
    address: "Horrenbergerstraße 6/1, 74939, Zuzenhausen",
    telephone: "+49 6226 8931",
    website: "http://www.streib.de",
    email: "streib@streib.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/komsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74491&utm_campaign=profiles_installer",
    company_name: "KomSolar Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ad46226ffa8.jpg?v=1",
    address: "Schwerborner Straße 30, 99087 Erfurt",
    telephone: "+49 361 6522828",
    website: "http://www.komsolar.de",
    email: "stiftung@komsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",TEAG Thüringer Energie AG,",
    "Last Update": 5112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stevens-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103533&utm_campaign=profiles_installer",
    company_name: "Stevens Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebc03bd85b0.jpg?v=1",
    address: "Werner-von-Siemens-Str. 8a, 33334 Gütersloh",
    telephone: "+49 5241 701308",
    website: "http://www.stevens-elektro.de",
    email: "stevenselektro@icloud.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-sterr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10787&utm_campaign=profiles_installer",
    company_name: "Zimmerei Michael Sterr e. K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dc287ce83b2e.png?v=1",
    address: "Guggenberg 1, 82405, Wessobrunn",
    telephone: "+49 8809 291",
    website: "http://www.zimmerei-sterr.de",
    email: "sterr-wessobrunn@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/avr-umweltservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93350&utm_campaign=profiles_installer",
    company_name: "AVR UmweltService GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb41f29f164.jpg?v=1",
    address: "Dietmar-Hopp-Str. 8, D- 74889, Sinsheim",
    telephone: "+49 7261 9310",
    website: "http://avr-umweltservice.de/",
    email: "stephan.grittmann@avr-umweltservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/steins-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99124&utm_campaign=profiles_installer",
    company_name: "Steins Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a585cff4ccd3.png?v=1",
    address: "Kronenstr. 26, D-42697, Solingen",
    telephone: "+49 212 222604586",
    website: "http://www.steins-solar.de",
    email: "steins-solar@ online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bremer-baudienst?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106247&utm_campaign=profiles_installer",
    company_name: "Bremer Baudienst",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50350fc815d.png?v=1",
    address: "Moorkuhlenweg 4, 28357 Bremen",
    telephone: "+49 421 2760328",
    website: "http://www.bremer-baudienst.de",
    email: "Steinmair@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sms-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82015&utm_campaign=profiles_installer",
    company_name: "SMS GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d47f230203f6.jpg?v=1",
    address: "Am Schornacker 121, 46485 Wesel",
    telephone: "+49 281 14799840",
    website: "http://www.maass-solar.de",
    email: "stefan.maass@maass.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/egm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112667&utm_campaign=profiles_installer",
    company_name: "EGM GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c7cc3a42f883.jpg?v=1",
    address: "Theaterstraße 24, 53111 Bonn",
    telephone: "+49 228 7112718",
    website: "https://www.egm-bonn.de",
    email: "Stefan.Baginski@stadtwerke-bonn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-gottingen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107908&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Göttingen AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88aeeadcf0b.jpg?v=1",
    address: "Hildebrandstraße 1, 37081 Göttingen",
    telephone: "+49 551 3010",
    website: "https://www.stadtwerke-goettingen.de",
    email: "stadtwerke@swgoe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sus-treat?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122758&utm_campaign=profiles_installer",
    company_name: "Sus Treat",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e65e00de3cff.jpg?v=1",
    address: "Bahnhofstraße 47, D-56068, Koblenz",
    telephone: "+49 261 1293602",
    website: "http://sustreat.eu",
    email: "stadtentwaesserung@stadt.koblenz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-e-h?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81980&utm_campaign=profiles_installer",
    company_name: "SEH GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589c04d33aadb.jpg?v=1",
    address: "Im oberen Tal 13, 74858 Aglasterhausen",
    telephone: "+49 6262 915571",
    website: "http://www.seh-aglasterhausen.de",
    email: "sonnenenergie-s.e.h@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/adsoba?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9989&utm_campaign=profiles_installer",
    company_name: "Adsoba GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50593958e5cf6.PNG?v=1",
    address: "Bahnhofstraße 24, 77749 Hohberg-Niederschopfheim",
    telephone: "+49 7808 914250",
    website: "http://www.adsoba.de",
    email: "solutions@adsoba.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Frankreich,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solide?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102349&utm_campaign=profiles_installer",
    company_name: "SoLiDE GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9adcd4f107.png?v=1",
    address: "Ohmstr. 2, 50677 Köln",
    telephone: "+49 221 861313",
    website: "http://www.solide-gmbh.de",
    email: "solide-gmbh@netcologne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kulschewski-solaranlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1847&utm_campaign=profiles_installer",
    company_name: "Kulschewski Solaranlagen KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5876fd4598f5b.jpg?v=1",
    address: "Münkerstraße 53, 47798 Krefeld",
    telephone: "+49 2151 772483",
    website: "http://www.solarzone.de",
    email: "solarzone@solarzone.de",
    "Installaliert seit": 1993,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Spanien,,Europa",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-winkler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119862&utm_campaign=profiles_installer",
    company_name: "Solartechnik Winkler",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dddd6c63a9d8.jpg?v=1",
    address: "Rundweg 1 D-34549 Edertal-Anraff",
    telephone: "+49 5621 72883",
    website: "http://www.solarwinkler.com",
    email: "solarwin@online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-schierl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1709&utm_campaign=profiles_installer",
    company_name: "Solartechnik Schierl GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/538d665bce40a.gif?v=1",
    address: "Industriestrasse 1, 33397 Rietberg",
    telephone: "+49 5244 8061",
    website: "http://www.schierl.info",
    email: "solartechnik@schierl.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-neckar-enz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1979&utm_campaign=profiles_installer",
    company_name: "Solartechnik-Neckar-Enz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/518c9efe6d377.gif?v=1",
    address: "Rosenbergstraße 5, 74613 Öhringen-Möglingen",
    telephone: "+49 7948 942742",
    website: "http://www.solartechnik-neckar-enz.de",
    email: "solartechnik.neckar.enz@gmail.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-brinkmeier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8681&utm_campaign=profiles_installer",
    company_name: "Solartechnik Brinkmeier",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5756934916248.jpg?v=1",
    address: "Bachwiesenstr. 13, 69469 Weinheim",
    telephone: "+49 6201 592095",
    website: "http://www.solartechnik-brinkmeier.de",
    email: "solartechnik-brinkmeier@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarstromanlagen-grimm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11974&utm_campaign=profiles_installer",
    company_name: "Solarstromanlagen Alfred Grimm",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57688c883e5c6.jpg?v=1",
    address: "Münchenerstraße 66, 91154, Roth",
    telephone: "+49 9171 5591",
    website: "http://www.solarstrom-grimm.de",
    email: "solarstrom-grimm@t-online.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solar Fabrik GmbH,,,Kyocera Corporation,,,Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarsta?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93770&utm_campaign=profiles_installer",
    company_name: "Solarsta",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4650b3681a.jpg?v=1",
    address: "Hornungweg 50, 12623 Berlin",
    telephone: "+49 176 48038464",
    website: "http://solarsta.de",
    email: "solarsta@aol.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",asola Technologies GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solmotion?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=55671&utm_campaign=profiles_installer",
    company_name: "Solmotion Project GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c785dc1e82c.jpg?v=1",
    address: "Karlstraße 8, 88212 Ravensburg",
    telephone: "+49 751 29509611",
    website: "http://www.solmotion.de",
    email: "solarkraftwerk@solmotion.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-rm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74613&utm_campaign=profiles_installer",
    company_name: "Solarzentrum-RM",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a52f9a7cb35.PNG?v=1",
    address: "Aachenerstr.19, 65428 Rüsselsheim",
    telephone: "+49 175 1547262",
    website: "http://www.solarzentrum-rm.de",
    email: "Solarinfo@voltix.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,ReneSola Ltd.,,,Viessmann Werke GmbH & Co. KG,,,Upsolar Global Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarart?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1649&utm_campaign=profiles_installer",
    company_name: "SolarArt Services GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1649n.gif?v=1",
    address: "Waldstraße 2, 97922, Lauda-Königshofen",
    telephone: "+49 9343 627690",
    website: "http://www.solarart.de",
    email: "solarart@solarart.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiezentrum-waldeck?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92376&utm_campaign=profiles_installer",
    company_name: "Energiezentrum Waldeck",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a75c675e0bb.jpg?v=1",
    address: "Auf der Schanze 2, 34454, Bad Arolsen",
    telephone: "+49 5691 5262",
    website: "http://www.energiezentrum-waldeck.de",
    email: "solaranlagen-figge@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomae-kollegen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=71963&utm_campaign=profiles_installer",
    company_name: "Thomae & Kollegen KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6092bbceb3ce4.PNG?v=1",
    address: "Dorfstraße 50, 07318 Saalfeld/Crösten",
    telephone: "+49 3671 510053",
    website: "http://www.thomae.tk",
    email: "solar@thomae.tk",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rst-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119908&utm_campaign=profiles_installer",
    company_name: "Reinhard Solartechnik Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ddf21d017871.jpg?v=1",
    address: "Brückenstraße 2 D- 28857 Syke",
    telephone: "+49 4242 80106",
    website: "http://www.reinhard-solartechnik.de",
    email: "solar@reinhard-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/quadrex?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102348&utm_campaign=profiles_installer",
    company_name: "QuadreX Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9ac8335d8f.png?v=1",
    address: "Hagenring 6, 72119 Ammerbuch",
    telephone: "+49 7032 7880821",
    website: "http://quadrex-solar.de",
    email: "solar@quadrex.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",QuadreX Group,",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hans-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76079&utm_campaign=profiles_installer",
    company_name: "Hans Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d7772ba28ab.jpg?v=1",
    address: "Blumenstrasse 80, 01307 Dresden",
    telephone: "+49 351 4569060",
    website: "http://hanssolar.de",
    email: "solar@hansgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Italien, Slovakei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bader-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64045&utm_campaign=profiles_installer",
    company_name: "Bäder Solar GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/604f91fc74762.png?v=1",
    address: "Otto-Lilienthal-Straße 42A, 46539 Dinslaken",
    telephone: "+49 2064 4789877",
    website: "http://www.baedersolar.de",
    email: "solar@baedersolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/alvalog-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91957&utm_campaign=profiles_installer",
    company_name: "Alvalog GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e3e5a222e4.jpg?v=1",
    address: "Vor den Wiesen 18, 49163 Bohmte-Hunteburg",
    telephone: "+49 5475 9579800",
    website: "http://www.alvalog.com",
    email: "solar@alvalog.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,Solar Fabrik GmbH,,,Bauer Solartechnik GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,Samil Power Co., Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/fa-uwe-reichel-alternative-energien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11173&utm_campaign=profiles_installer",
    company_name: "Firma Reichel - Alternative Energien UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aced3d734708.jpg?v=1",
    address: "Mittelgasse 18, 04703 Leisnig",
    telephone: "+49 34321 12207",
    website: "http://www.uwereichel.de",
    email: "Solar.Reichel@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-kontor-peter-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93572&utm_campaign=profiles_installer",
    company_name: "Solar-Kontor Peter Müller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c08e6b0cc41.jpg?v=1",
    address: "Robinienweg 5, 14547 Fichtenwalde",
    telephone: "+49 33206 20978",
    website: "http://www.solar-kontor-fichtenwalde.de",
    email: "solar-kontor@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",EGing Photovoltaic Technology Co., Ltd.,,,Canadian Solar Inc.,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",KACO New Energy GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/blechnerei-singler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91980&utm_campaign=profiles_installer",
    company_name: "Blechnerei Singler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e6ef062c6f.jpg?v=1",
    address: "Zum Riesenberg 2b, 78476 Allensbach",
    telephone: "+49 7533 3103",
    website: "http://www.blechnerei-singler.de",
    email: "singler.allensbach@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sila-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81974&utm_campaign=profiles_installer",
    company_name: "SILA Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589be84db7594.jpg?v=1",
    address: "Justus-von-Liebig-Straße 6-8, 26160 Bad Zwischenahn",
    telephone: "+49 4403 602030",
    website: "https://www.silaservice.de",
    email: "sila@silaservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/esomatic?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1997&utm_campaign=profiles_installer",
    company_name: "Esomatic Elektronik Solar Automation",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1997n.gif?v=1",
    address: "Südring 164, 55128 Mainz",
    telephone: "+49 6131 337707",
    website: "http://www.esomatic.de",
    email: "shop@esomatic.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 6022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wilhelm-kampe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77809&utm_campaign=profiles_installer",
    company_name: "Wilhelm Kampe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6082d8ac1758b.PNG?v=1",
    address: "Neidenburger Straße 4, 28207 Bremen",
    telephone: "+49 421 71487",
    website: "https://www.wilhkampe.de",
    email: "service@wilhkampe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/weniger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75741&utm_campaign=profiles_installer",
    company_name: "Norbert Weniger, Dach und- Fassadenbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ce1b0d4bac8.jpg?v=1",
    address: "Konrad-Adenauer-Str. 29, 27239 Twistringen",
    telephone: "+49 4243 8350",
    website: "http://weniger-bedachungen.de",
    email: "service@weniger-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wee-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10326&utm_campaign=profiles_installer",
    company_name: "WeE Solar- und Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10326n.gif?v=1",
    address: "Hoffenheimer Strasse 26a, 74889 Sinsheim-Eschelbach",
    telephone: "+49 7265 7609",
    website: "http://www.wee-solar.de",
    email: "service@wee-solar.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-erkrath?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107911&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Erkrath GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88b154bab29.jpg?v=1",
    address: "Gruitener Straße 27, 40699 Erkrath",
    telephone: "+49 2104 9436070",
    website: "https://www.stadtwerke-erkrath.de",
    email: "service@stadtwerke-erkrath.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-wiebe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93970&utm_campaign=profiles_installer",
    company_name: "Solar Wiebe GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9a34a62704.jpg?v=1",
    address: "Fraunhofer Str. 3, 51647 Gummersbach-Windhagen",
    telephone: "+49 2261 5909980",
    website: "http://solarwiebe.de",
    email: "service@solarwiebe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroanlagenbau-vergin?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1685&utm_campaign=profiles_installer",
    company_name: "Elektroanlagenbau Vergin GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c9294991da6.gif?v=1",
    address: "Ribnitzer-Str. 3, 18181 Graal-Müritz",
    telephone: "+49 38206 79874",
    website: "http://www.solarenergie-mv.de",
    email: "service@solarenergie-mv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-scheldt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101824&utm_campaign=profiles_installer",
    company_name: "Elektro Scheldt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa731cb8921f.jpg?v=1",
    address: "Bensberger Straße 272, 51503 Rösrath-Forsbach",
    telephone: "+49 2205 900960",
    website: "https://www.scheldt.de",
    email: "service@scheldt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lichtenstrom-weser-ems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93571&utm_campaign=profiles_installer",
    company_name: "Lichtstrom Service Weser-Ems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c08d1893eee.jpg?v=1",
    address: "Lilienthalstr. 16, 49716 Meppen",
    telephone: "+49 5931 8480010",
    website: "http://www.l-w-e.de",
    email: "service@l-w-e.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kleser-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94486&utm_campaign=profiles_installer",
    company_name: "Kleser Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc619a0c439.jpg?v=1",
    address: "Marienthaler Str. 2, 53501 Grafschaft-Esch",
    telephone: "+49 2641 901200",
    website: "http://www.kleser-bedachungen.de",
    email: "service@kleser-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/infinity-solar-6?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=128611&utm_campaign=profiles_installer",
    company_name: "Infinity Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fa95cbd3f481.png?v=1",
    address: "Panoramaweg, 33-4, Stiege 1 Tür 1, 74594 Kreßberg",
    telephone: "+49 7957 6499988",
    website: "http://infinity-solar.eu/",
    email: "service@infinity-solar.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/matthias-hoke-sanitartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93199&utm_campaign=profiles_installer",
    company_name: "Matthias Hoke Sanitärtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b78955d87a8.jpg?v=1",
    address: "Rothenburger Str. 27h, 02828 Görlitz",
    telephone: "+49 3581 314611",
    website: "http://hoke-sanitaertechnik.de",
    email: "service@hoke-sanitaertechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lothar-rohwedder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82429&utm_campaign=profiles_installer",
    company_name: "Lothar Rohwedder",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abeae6e65de.jpg?v=1",
    address: "Bismarckallee 1, 48151 Münster",
    telephone: "+49 209 62510",
    website: "http://www.haustechnik-rohwedder.de",
    email: "service@haustechnik-rohwedder.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/evios-energy-systems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13736&utm_campaign=profiles_installer",
    company_name: "Evia Verkehrstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fffc6bc00ec7.png?v=1",
    address: "Georg-Wimmer-Ring 15, 85604, Zorneding",
    telephone: "+49 8106 39296600",
    website: "http://evia-verkehrstechnik.de/",
    email: "service@evia-verkehrstechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/evg-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93634&utm_campaign=profiles_installer",
    company_name: "EVG Energieversorgung Gaildorf",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1e6e231b21.jpg?v=1",
    address: "Burg 2, 74405 Gaildorf",
    telephone: "+49 7971 260920",
    website: "http://www.ev-gaildorf.de",
    email: "Service@EV-Gaildorf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-elektromeister-engelhardt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10633&utm_campaign=profiles_installer",
    company_name: "Engelhardt Dach- und Solartechnik UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/587c46dec8b5a.jpg?v=1",
    address: "Arndtstraße 21, 08451, Crimmitschau",
    telephone: "+49 3762 4313",
    website: "http://www.engelhardt-solartechnik.info",
    email: "service@engelhardt-solartechnik.info",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-von-bode?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=111681&utm_campaign=profiles_installer",
    company_name: "Elektro von Bode",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c3d4f873d2f3.png?v=1",
    address: "Werkssiedlung 15, 17321 Löcknitz",
    telephone: "+49 1523 4094578",
    website: "http://www.elektrovonbode.de",
    email: "service@elektrovonbode.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-ecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105972&utm_campaign=profiles_installer",
    company_name: "Elektro Ecker GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f03e818afbe1.png?v=1",
    address: "Salzdorf 5, 84036 Landshut",
    telephone: "+49 871 43019041",
    website: "http://www.elektroecker.de",
    email: "service@elektroecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ecm-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81653&utm_campaign=profiles_installer",
    company_name: "Energy Consulting Mai GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588825f8ba0c5.jpg?v=1",
    address: "Sonnenallee 1, 31636 Linsburg",
    telephone: "+49 502 79495980",
    website: "http://www.ecm-solar.de",
    email: "service@ecm-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/d-spanier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101825&utm_campaign=profiles_installer",
    company_name: "D. Spanier GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa7347116b10.jpg?v=1",
    address: "Am Vorend 47, 51467 Bergisch Gladbach",
    telephone: "+49 2202 98750",
    website: "http://www.spanier-gmbh.de",
    email: "service@dspanier.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/breischaft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10697&utm_campaign=profiles_installer",
    company_name: "Breischaft GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10697n.gif?v=1",
    address: "Bahnhofstraße 117, 74348, Lauffen am Neckar",
    telephone: "+49 7133 16518",
    website: "http://www.breischaft.de",
    email: "service@breischaft.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/antignum-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93983&utm_campaign=profiles_installer",
    company_name: "Antignum Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ad5a21a8f.jpg?v=1",
    address: "Dittelstedter Grenze 3, 99099 Erfurt",
    telephone: "+49 36203 728211",
    website: "http://www.antignum.com",
    email: "service@antignum.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aenders-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11112&utm_campaign=profiles_installer",
    company_name: "Aenders Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11112n.gif?v=1",
    address: "Pfortengartenweg 51, 65931, Frankfurt am Main",
    telephone: "+49 69 33091900",
    website: "http://www.aenders.com",
    email: "service@aenders.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Canadian Solar Inc.,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ace-schneider?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1812&utm_campaign=profiles_installer",
    company_name: "ACE-Schneider GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1812n.gif?v=1",
    address: "Steinstr. 15, 81667 München",
    telephone: "+49 89 48953306",
    website: "http://www.ace-schneider.de",
    email: "service@ACE-Schneider.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/enso-energie-sachsen-ost?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94814&utm_campaign=profiles_installer",
    company_name: "SachsenEnergie AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6012cffd077b6.png?v=1",
    address: "Friedrich-List-Platz 2, 01069 Dresden",
    telephone: "+49 800 6686868",
    website: "https://enso.de",
    email: "service-enso@sachsenenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bonergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=79099&utm_campaign=profiles_installer",
    company_name: "Bonergie GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58462918abe8e.jpg?v=1",
    address: "Am Lochholz 28, 80999 München",
    telephone: "+49 89 21777912",
    website: "http://bonergie.com",
    email: "senegal@bonergie.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Senegal,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Training",
    Muttergesellschaft: "",
    "Last Update": 25122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/selzer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93354&utm_campaign=profiles_installer",
    company_name: "Selzer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb458111fce.jpg?v=1",
    address: "Hochwaldstraße 7, 66709 Weiskirchen",
    telephone: "+49 6872 91294",
    website: "http://selzer-gmbh.de/",
    email: "selzer.gmbh@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wpd?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=21879&utm_campaign=profiles_installer",
    company_name: "Wpd AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576b41b3ba902.jpg?v=1",
    address: "Stephanitorsbollwerk 3 (Haus LUV), 28217 Bremen",
    telephone: "+49 421 1686610",
    website: "http://www.wpd.de",
    email: "sekretariat@wpe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wpe-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77609&utm_campaign=profiles_installer",
    company_name: "WPE Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58243c3cdcc13.jpg?v=1",
    address: "Stellenbachstraße 52a, 44536 Lünen-Brambauer",
    telephone: "+49 231 9873000",
    website: "http://www.wpe.de",
    email: "sekretariat@wpe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-schulz-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10654&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Schulz GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10654n.gif?v=1",
    address: "Am Bauhof 4, 25761 Büsum",
    telephone: "+49 4834 9050",
    website: "http://www.dachdeckerei-schulz-buesum.de",
    email: "schulz@kueste.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sse-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95157&utm_campaign=profiles_installer",
    company_name: "SSE GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee978948569.jpg?v=1",
    address: "Maximilianstr. 28b, 85399 Hallbergmoos",
    telephone: "+49 811 1422",
    website: "http://sse-eu.de",
    email: "schu@sse-eu.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bks-dachdecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10510&utm_campaign=profiles_installer",
    company_name: "BKS Dachdecker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10510n.gif?v=1",
    address: "Völklingerstraße 101, 66333, Völklingen",
    telephone: "+49 6898 943062",
    website: "http://www.bks-dachdecker.de",
    email: "schmitz@bks-dachdecker.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-dachdeckerei-schmitz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93974&utm_campaign=profiles_installer",
    company_name: "Zimmerei - Dachdeckerei Schmitz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9a6a52a755.jpg?v=1",
    address: "Industriestr. 36, 66914 Waldmohr",
    telephone: "+49 6373 6928",
    website: "http://www.schmitz-waldmohr.de",
    email: "schmitz-waldmohr@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",ET Solar New Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92249&utm_campaign=profiles_installer",
    company_name: "Elektro-Schmidt GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a5110c2a3a6.jpg?v=1",
    address: "Wupperstraße 4, 45701 Herten",
    telephone: "+49 209 357700",
    website: "http://www.elektro-schmidt-herten.de",
    email: "schmidtelTR@aol.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": ",Siemens Energy,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenberg-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99186&utm_campaign=profiles_installer",
    company_name: "Sonnenberg Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c246679986.png?v=1",
    address: "Dillstr. 9a, 79356 Eichstetten",
    telephone: "+49 7663 9148397",
    website: "http://www.sonnenberg-solar.de",
    email: "schmidt@sonnenberg-solar.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Suntech Power Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,,,LONGi Solar Technology Co., Ltd.,,,GCL System Integration Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmidt-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10431&utm_campaign=profiles_installer",
    company_name: "Solar Schmidt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/563ad8c2d1ec2.jpg?v=1",
    address: "Am Filzbach 4, 08304, Schönheide",
    telephone: "+49 37755 59305",
    website: "http://www.solar-schmidt.de",
    email: "Schmidt-Solarschmidt@web.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,Kyocera Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schlomp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75709&utm_campaign=profiles_installer",
    company_name: "Schlömp GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cd0653b366d.jpg?v=1",
    address: "Am Neuen Weg 8, 77815 Bühl",
    telephone: "+49 7223 6247",
    website: "https://www.schloemp-hs.de",
    email: "schloemp-hs@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schleeszelmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93529&utm_campaign=profiles_installer",
    company_name: "Schleeßelmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0770eb6630.jpg?v=1",
    address: "Vahlder Weg 23, 27383 Scheeßel",
    telephone: "+49 4263 1454",
    website: "http://schleesselmann-haustechnik.de",
    email: "schleesselmann@ewetel.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schandog-hk-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93249&utm_campaign=profiles_installer",
    company_name: "Schandog HK-Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f612eefee.jpg?v=1",
    address: "Kolkwitzer Str. 64, 03046 Cottbus",
    telephone: "+49 355 791928",
    website: "http://www.waermepumpenfuchs.de",
    email: "schandog.technik@lausitz.net",
    "Installaliert seit": 1990,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sbu-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10274&utm_campaign=profiles_installer",
    company_name: "SBU Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589be6dc96de0.jpg?v=1",
    address: "Kaufweg 3, 16303 Schwedt/Oder",
    telephone: "+49 3332 581044",
    website: "http://www.sbu-pv.de",
    email: "sbm@sbu-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/santel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75601&utm_campaign=profiles_installer",
    company_name: "Santel GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8e4bf2b8a3.jpg?v=1",
    address: "Hauptstr. 15, D-49584 Fürstenau-Schwagstorf",
    telephone: "+49 5901 93940",
    website: "http://www.santel.de",
    email: "santel@santel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sea-sun-technology?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=625&utm_campaign=profiles_installer",
    company_name: "Sea & Sun Technology GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52297d8d91e51.gif?v=1",
    address: "Arndtstr. 9-13, 24610 Trappenkamp",
    telephone: "+49 4323 910913",
    website: "http://www.sea-sun-tech.com",
    email: "sales@sea-sun-tech.com",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/glasscon?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=61257&utm_campaign=profiles_installer",
    company_name: "Glasscon GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/603683585a82c.png?v=1",
    address: "Mergenthalerallee 77, 65760 Eschborn, Frankfurt am Main",
    telephone: "+49 61 967889560",
    website: "http://www.glasscon.com",
    email: "sales@glasscon.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": ",Naher Osten, Afrika, Asien-Pazifik, Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Studer Innotec,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/clenergy-xiamen-technology?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4507&utm_campaign=profiles_installer",
    company_name: "Clenergy (Xiamen) Technology Co., Ltd",
    logo_url: "https://cdn.enfsolar.com/ID/logo/539112bcd7f88.gif?v=1",
    address:
      "No. 999-1009, Min'an Rd., Huoju Hi-tech Ind. Dev. Zone, Xiang'an District, Xiamen, Fujian",
    telephone: "+86 592 3110088",
    website: "http://www.clenergy.com",
    email: "sales@clenergy.com.cn",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in":
      "Australien, China, Großbritannien, Japan, Malaysia, USA, Thailand, Philippinen,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/centroplan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=5270&utm_campaign=profiles_installer",
    company_name: "Centroplan GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5270n.gif?v=1",
    address: "Am Pannhaus 2-10, D-52511 Geilenkirchen",
    telephone: "+49 2451 911300",
    website: "https://centroplan.com",
    email: "SALES@CENTROPLAN.DE",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/m-s-b-facility-management-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13765&utm_campaign=profiles_installer",
    company_name: "M.S.B. Facility Management",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5063b2c8bae58.PNG?v=1",
    address: "Frankenwinheimer Straße 8, 97447 Gerolzhofen",
    telephone: "+49 9382 310676",
    website: "http://www.ms-bautenschutz.de",
    email: "sabasch@ms-bautenschutz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Victron Energy BV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kraus-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119860&utm_campaign=profiles_installer",
    company_name: "Kraus Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dddcf2348e4e.jpg?v=1",
    address: "Scheffelstraße 76, 78176 Blumberg",
    telephone: "+49 7702 477752",
    website: "http://www.krauselektrotechnik.de",
    email: "s.kraus@krauselektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/skyworker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94233&utm_campaign=profiles_installer",
    company_name: "SkyWorker Seil & Dachtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc6ce426d7.png?v=1",
    address: "Wasserkunststraße 98, 39124 Magdeburg",
    telephone: "+49 391 5630731",
    website: "http://www.skyworker-md.de",
    email: "s.kluge@skyworker-md.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Roto Frank Professional Service GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haushandwerker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10372&utm_campaign=profiles_installer",
    company_name: "Haushandwerker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d27f9b795975.jpg?v=1",
    address: "Hegelstraße 29, 15517 Fürstenwalde",
    telephone: "+49 3361 3310000",
    website: "https://www.haushandwerker.org",
    email: "rp@haushandwerker.org",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",GermanPV GmbH,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rosenbaum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10453&utm_campaign=profiles_installer",
    company_name: "Hubert Rosenbaum GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10453n.gif?v=1",
    address: "Broichstraße 44, 51109, Köln",
    telephone: "+49 221 841394",
    website: "http://www.solar-dach.com",
    email: "rosenbaum@bauklempner.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rw-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93492&utm_campaign=profiles_installer",
    company_name: "RW-Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf6fdecab19.jpg?v=1",
    address: "Im Grünen Winkel 5, 32805 Horn Bad Meinberg",
    telephone: "+49 5233 951538",
    website: "http://rw-elektrosolar.de",
    email: "rolf.winand@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gageur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112265&utm_campaign=profiles_installer",
    company_name: "Roland Gageur Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c621c9f3f379.jpg?v=1",
    address: "Poppenbütteler Bogen 90, 22399 Hamburg",
    telephone: "+49 40 6439319",
    website: "http://www.gageur-elektrotechnik.de",
    email: "Roland@gageur-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-service-lehmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94669&utm_campaign=profiles_installer",
    company_name: "Elektro Service Lehmann e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddbf083d163.png?v=1",
    address: "In den Brachfeldern 4, 77756 Hausach",
    telephone: "+49 7831 966320",
    website: "http://www.es-lehmann.de",
    email: "robby@ES-Lehmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ritzler-freitag?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1587&utm_campaign=profiles_installer",
    company_name: "Ritzler Freitag KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e26964b18016.jpg?v=1",
    address: "Rauer Werth 33, 42275, Wuppertal, Nordrhein-Westfalen",
    telephone: "+49 202 4600807",
    website: "http://www.ritzlerfreitagkg.de",
    email: "Ritzler.FreitagKG@arcor.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 6042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/steffen-richter-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106009&utm_campaign=profiles_installer",
    company_name: "Steffen Richter Elektroanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4584661f76f.png?v=1",
    address: "Wiesenstraße 16, 04509 Krostitz",
    telephone: "+49 34295 72037",
    website: "https://www.richter-elektroanlagen.de",
    email: "richter.elektroanlagen@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-solarservice-parchim?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102584&utm_campaign=profiles_installer",
    company_name: "Elektro & Solarservice Parchim GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac44a0412f5b.png?v=1",
    address: "Meyenburger Straße 18, 19370 Parchim",
    telephone: "+49 3871 423414",
    website: "http://www.essp-gmbh.de",
    email: "reik.hoffmann@essp-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/steffens-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76071&utm_campaign=profiles_installer",
    company_name: "Steffens Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d76ecaed678.jpg?v=1",
    address: "Lessingstr. 14﻿, 56288 Kastellaun",
    telephone: "+49 6762 9048075",
    website: "http://www.steffens-solar.de",
    email: "ralf.steffens@steffens-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rainbows-end-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=627&utm_campaign=profiles_installer",
    company_name: "Rainbows End Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11076.gif?v=1",
    address: "Kiebitzheide 39, 49084, Osnabrück",
    telephone: "+49 541 5690965",
    website: "http://www.rainbows-solar.de",
    email: "rainbows-solar@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,Solvis d.o.o.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bauer-solarstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7259&utm_campaign=profiles_installer",
    company_name: "Bauer Solarstrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5811bd5d61c30.jpg?v=1",
    address: "Gartenstraße 25, 76287, Rheinstetten",
    telephone: "+49 7242 934134",
    website: "http://www.bauersolarstrom.de",
    email: "raimund.bauer@bauersolarstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ek-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93840&utm_campaign=profiles_installer",
    company_name: "EK-Elektro",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b91195489.jpg?v=1",
    address: "Deepe Delle 22, 26607 Aurich Langefeld",
    telephone: "+49 4947 917220",
    website: "http://www.xn--klting-xxa.de",
    email: "pvmontage@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieheld?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82726&utm_campaign=profiles_installer",
    company_name: "Energieheld GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b393c09dac0.jpg?v=1",
    address: "Heidenkampsweg 51-57, 20097 Hamburg",
    telephone: "+49 800 7776670",
    website: "https://www.energieheld.de",
    email: "presse@energieheld.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vikram-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6491&utm_campaign=profiles_installer",
    company_name: "Vikram Solar Limited",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5381878028.gif?v=1",
    address:
      "The Chambers, 8th Floor, 1865 Rajdanga Main Road, Kolkata, 700107, West Bengal",
    telephone: "+91 33 24427299",
    website: "http://www.vikramsolar.com",
    email: "press@vikramsolar.com",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "China, Deutschland, Indien, Japan, USA, Singapur,,Asien, Afrika, Europa, Nordamerika",
    "Lieferanten von Solarmodulen": ",Vikram Solar Limited,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: ",Vikram Group,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/abo-wind?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=104727&utm_campaign=profiles_installer",
    company_name: "ABO Wind AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b14aa582d960.png?v=1",
    address: "Unter den Eichen 7, 65195 Wiesbaden",
    telephone: "+49 611 267650",
    website: "https://www.abo-wind.com",
    email: "press@abo-wind.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Spanien, Iran,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/hanwha-q-cells?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=772&utm_campaign=profiles_installer",
    company_name: "Hanwha Q CELLS GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ccfdd43c2ad9.jpg?v=1",
    address: "Sonnenallee 17-21, 06766 Bitterfeld-Wolfen",
    telephone: "+49 3494 66990",
    website: "https://www.q-cells.com",
    email: "press.release@qcells.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in":
      "Australien, Kanada, Schweiz, Chili, China, Deutschland, Spanien, Frankreich, Großbritannien, Indien, Italien, Japan, Südkorea, Malaysia, USA, Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: ",Hanwha Q CELLS Corp.,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/gt-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75839&utm_campaign=profiles_installer",
    company_name: "GT-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf8892bd7bb.jpg?v=1",
    address: "Nikolaus-Otto-Straße 18, D-33335 Gütersloh, Avenwedde",
    telephone: "+49 5241 505140",
    website: "http://www.gt-solar.de",
    email: "postfach@gt-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Canadian Solar Inc.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,Shenzhen Growatt New Energy Technology Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/das-syndikat-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117019&utm_campaign=profiles_installer",
    company_name: "SYNdikat Zimmerei AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d400bd110c71.png?v=1",
    address: "Täleswiesenstraße 16, 72770 Reutlingen",
    telephone: "+49 7121 1449350",
    website: "https://syndikat-ag.de",
    email: "post@syndikat-ag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/swb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1591&utm_campaign=profiles_installer",
    company_name: "SWB Sonnen- und Windenergieanlagenbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c7f42205066.jpg?v=1",
    address: "Karl-Hermann-Straße 14, 45701 Herten",
    telephone: "+49 23 6643965",
    website: "http://www.swb-herten.de",
    email: "post@swb-herten.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/paulus-straub?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119819&utm_campaign=profiles_installer",
    company_name: "Paulus Straub GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ddc8bf42a173.jpg?v=1",
    address: "Deutz-Mülheimer Str. 227, 51063 Cologne",
    telephone: "+49 221 1689105",
    website: "http://www.straub-partner.eu",
    email: "post@straub-partner.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Solon International,,,ReneSola Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 29042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/spreesun-de?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99127&utm_campaign=profiles_installer",
    company_name: "SpreeSun.de",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5863e4bbe6b.png?v=1",
    address: "Lindenstrasse 68, 15517 Furstenwalde/Spree",
    telephone: "+49 3361 6930304",
    website: "http://www.spreesun.de",
    email: "post@SpreeSun.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/smu?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64523&utm_campaign=profiles_installer",
    company_name: "SMU GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6054abc5942ee.PNG?v=1",
    address: "Gewerbepark 6a, 85250 Altomünster",
    telephone: "+49 8254 9978973",
    website: "http://www.smu-solar.de",
    email: "post@smu-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/fristd-bau-zub?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=109463&utm_campaign=profiles_installer",
    company_name: "Fristd-Bau Zub GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bd12f2bacb4f.jpg?v=1",
    address: "Haldesdorferstr. 44, 22179 Hamburg",
    telephone: "+49 40 38674565",
    website: "https://www.fristd-bau.com/kontakt.html",
    email: "post@rastd-bau.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-kreft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102037&utm_campaign=profiles_installer",
    company_name: "Peter Kreft GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab7638ce1c3.jpg?v=1",
    address: "Bornrand 1, 99326, Stadtilm /OT, Griesheim",
    telephone: "+49 3629 800020",
    website: "http://www.ilmtal-solar.de",
    email: "post@peterkreftgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/novatecc?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10505&utm_campaign=profiles_installer",
    company_name: "Novatecc GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b10be012958e.png?v=1",
    address: "Kunkeldanweg 11 B, 18055, Rostock",
    telephone: "+49 381 1216028",
    website: "http://www.novatecc.de",
    email: "post@novatecc.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Aleo Solar GmbH,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-lillich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103508&utm_campaign=profiles_installer",
    company_name: "Elektro Lillich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebb01f82d03.jpg?v=1",
    address: "Zeppelinstraße 5, 71 679 Asperg",
    telephone: "+49 7141 4889960",
    website: "http://www.lillich.de",
    email: "post@lillich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kaplick?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92855&utm_campaign=profiles_installer",
    company_name: "Kaplick GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0c2e3e87b1.jpg?v=1",
    address: "Max-Eyth-Str. 60, 72622 Nürtingen",
    telephone: "+49 7022 95600",
    website: "http://www.kaplick.de",
    email: "post@kaplick.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",First Solar, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/herbst-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94916&utm_campaign=profiles_installer",
    company_name: "Herbst Bedachungen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e9895c9b3842.gif?v=1",
    address: "Tillmanns Kamp 13, 59757 Arnsberg-Müschede",
    telephone: "+49 2932 54120",
    website: "http://www.herbst-bedachungen.de",
    email: "post@herbst-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerald-lange-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10109&utm_campaign=profiles_installer",
    company_name: "Gerald Lange Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10109n.gif?v=1",
    address: "Sternstraße 3, 31618 Liebenau",
    telephone: "+49 5023 981616",
    website: "http://www.gerald-lange.de",
    email: "post@gerald-lange.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elotec-gruner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94210&utm_campaign=profiles_installer",
    company_name: "Elotec Gruner GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb9a9d78dc.jpg?v=1",
    address: "Am Markt 3, 21279 Hollenstedt",
    telephone: "+49 4165 2216890",
    website: "https://www.elotec-gruner.de",
    email: "post@elotec-gruner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AxSun Solar GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-nimtz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103505&utm_campaign=profiles_installer",
    company_name: "Elektro Nimtz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebada53f93e.jpg?v=1",
    address: "Backofenstr. 1, 15913 Märkische Heide OT Wittmannsdorf",
    telephone: "+49 35476 650290",
    website: "http://www.elektro-nimtz.de",
    email: "post@elektro-nimtz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dps-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84822&utm_campaign=profiles_installer",
    company_name: "DPS",
    logo_url: "",
    address: "Dr.-Reuber-Str. 45, 46485 Wesel",
    telephone: "+49 151 41906777",
    website: "http://www.dp-solar.de",
    email: "post@dp-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mederer-keucher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10394&utm_campaign=profiles_installer",
    company_name: "Dachart Mederer & Keucher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd0a8e9380a5.png?v=1",
    address: "Schulstrasse 38, Europark, 09125, Chemnitz",
    telephone: "+49 371 510840",
    website: "http://www.dachart.de",
    email: "post@dachart.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Schweiz, Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bruckner-solartechnik-heizung-sanitar-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92066&utm_campaign=profiles_installer",
    company_name: "Brückner Solartechnik Heizung-Sanitär-Elektro",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fbd31a8377.jpg?v=1",
    address: "Wriezener Strasse 68, 15324 Letschin",
    telephone: "+49 33475 576084",
    website: "http://www.bsthse.de",
    email: "post@bsthse.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-kirsch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10478&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Andreas Kirsch",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10478n.gif?v=1",
    address: "Eisenberger Straße 18, 07613, Heideland, OT Königshofen",
    telephone: "+49 36691 51702",
    website: "http://www.dach-kirsch.de",
    email: "post @ dach-kirsch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/planungsburo-fur-photovoltaikanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93218&utm_campaign=profiles_installer",
    company_name: "Planungsbüro für Photovoltaikanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b79c5288741.jpg?v=1",
    address: "Brühlstr. 21, 30169, Hannover",
    telephone: "+49 511 70039700",
    website: "http://photovoltaikanlagen-hannover.de/",
    email: "planungsbuero-photovoltaik@online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-piske?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10356&utm_campaign=profiles_installer",
    company_name: "Elektro-Piske GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10356n.gif?v=1",
    address: "Eschachstraße 2, 72459, Albstadt",
    telephone: "+49 7431 95980",
    website: "http://www.elektro-piske.de",
    email: "piske@elektro-piske.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stewei-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75841&utm_campaign=profiles_installer",
    company_name: "B&V Stewei Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf89990b77b.jpg?v=1",
    address: "Halberstädter Straße 115, 39112 Magdeburg",
    telephone: "+49 3918 1959223",
    website: "http://www.stewei-solar.de",
    email: "photovoltaik@stewei-solar.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-deischl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116897&utm_campaign=profiles_installer",
    company_name: "Zimmerei Deischl",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f80b8779034f.PNG?v=1",
    address: "Tainger Straße 21, 85669 Zeilern – Pastetten",
    telephone: "+49 8124 9680",
    website: "https://zimmerei-deischl.de",
    email: "peter@zimmerei-deischl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-thomas-paul?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75355&utm_campaign=profiles_installer",
    company_name: "Solartechnik Thomas Paul",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c67113552da.jpg?v=1",
    address: "Heidelbergstrasse 31, 02736 Oppach",
    telephone: "+49 35872 32948",
    website: "http://www.solartechnik-paul.de",
    email: "paul-oppach@t-online.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gaszner-elektro-und-umwelttechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11900&utm_campaign=profiles_installer",
    company_name: "Gaßner Elektro- und Umwelttechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5937afe335952.jpg?v=1",
    address: "Sonnenhaldestraße 19, 72525, Münsingen",
    telephone: "+49 7381 4940",
    website: "http://www.hg-solar.de",
    email: "pagari@hg-solar.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiequelle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7572&utm_campaign=profiles_installer",
    company_name: "Energiequelle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7572n.gif?v=1",
    address: "Hauptstraße 44, D-15806 Zossen OT Kallinchen",
    telephone: "+49 33769 8710",
    website: "http://www.energiequelle.de",
    email: "pader@energiequelle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Spanien, Frankreich,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/p-solarenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93460&utm_campaign=profiles_installer",
    company_name: "P-Solarenergie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf4449b2e35.jpg?v=1",
    address: "Kösterhof 6, 26919 Brake",
    telephone: "+49 4401 705385",
    website: "http://p-solarenergie.de/",
    email: "p-solarenergie@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/olaf-mayer-heizungsbau-solartech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1861&utm_campaign=profiles_installer",
    company_name: "Olaf Mayer Heizungsbau-Solartech",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575f534d9dbf9.jpg?v=1",
    address: "Görlitzer Str.7, 69502 Hemsbach",
    telephone: "+49 62 0144984",
    website: "http://www.olaf-mayer-solar.de",
    email: "olaf.mayer@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gottschall?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93960&utm_campaign=profiles_installer",
    company_name: "Helmut Gottschall Zimmerei Dachdeckerei Fachwerkhaus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8c68f1d58f.jpg?v=1",
    address: "Gewerbegebiet Ost 3c, 66987 Thaleischweiler-Fröschen",
    telephone: "+49 6334 449780",
    website: "http://www.zimmerei-gottschall.de",
    email: "office@zimmerei-gottschall.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/vario-green-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=17423&utm_campaign=profiles_installer",
    company_name: "Vario Green Energy Concept GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/17423n.gif?v=1",
    address: "Helmuth Bächle Straße 40, 72135, Dettenhausen",
    telephone: "+49 7157 9898280",
    website: "http://www.vario-greenenergy.de",
    email: "office@vario-greenenergy.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Solon International,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,Mitsubishi Heavy Industries Ltd.,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,,,ET Solar New Energy Co., Ltd.,,,Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solwest?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8337&utm_campaign=profiles_installer",
    company_name: "SOLwest GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/544f52182eefd.jpg?v=1",
    address: "Aachener Str. 315, D-50931 Köln",
    telephone: "+49 221 9957070",
    website: "http://www.solwest.de",
    email: "office@solwest.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Hanwha Q CELLS GmbH,,,Trina Solar Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarx?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=132465&utm_campaign=profiles_installer",
    company_name: "S.C. SolarX Smart Energy S.R.L.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/609d2d2a07c9c.png?v=1",
    address: "",
    telephone: "+40 726 741101",
    website: "http://www.solarx.ro",
    email: "office@solarx.ro",
    "Installaliert seit": 2020,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Rumänien,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Trina Solar Limited,,,LONGi Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/meyer-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102567&utm_campaign=profiles_installer",
    company_name: "MEYER Elektro-Heizung-Sanitär-Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4398e4eefa.png?v=1",
    address: "Nordring 8, 49751 Sögel",
    telephone: "+49 5951 4141",
    website: "http://www.meyer-ehs.de",
    email: "office@meyer-ehs.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/leipziger-solargesellschaft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=52251&utm_campaign=profiles_installer",
    company_name: "Leipziger Solargesellschaft mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6087dc2794156.PNG?v=1",
    address: "Käthe-Kollwitz-Straße 68, 04109 Leipzig",
    telephone: "+49 3 4168679460",
    website: "http://www.lesolar.de",
    email: "office@lesolar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lambda-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11469&utm_campaign=profiles_installer",
    company_name: "Lambda GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11469n.gif?v=1",
    address: "Am Pfaffenkogel 10, 83483 Bischofswiesen",
    telephone: "+49 8652 9793330",
    website: "http://www.lambda-gmbh.de",
    email: "office@lambda-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Österreich, Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarpark-ks-mv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76033&utm_campaign=profiles_installer",
    company_name: "Solarpark KS-MV GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d62dd90758a.jpg?v=1",
    address: "Krischanweg 8b, 18069 Rostock",
    telephone: "+49 381 8083426",
    website: "http://www.ks-mv.de",
    email: "office@ks-mv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ka?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1596&utm_campaign=profiles_installer",
    company_name: "KA Konrad Architekten",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54447d4b41849.jpg?v=1",
    address: "Limburger Str.39, 65558, Holzheim",
    telephone: "+49 64 32910011",
    website: "https://www.konrad-architekten.de",
    email: "office@konrad-architekten.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-ebert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10713&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Heiko Ebert",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55f14a49ec53f.jpg?v=1",
    address: "Hirschwechsel 2, 14532 Kleinmachnow",
    telephone: "+49 3329 691712",
    website: "http://www.heiko-ebert.de",
    email: "office@heiko-ebert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-kraft-sud?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92347&utm_campaign=profiles_installer",
    company_name: "Energie-Kraft Süd GmbH & Co. KG.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a67adea717d.jpg?v=1",
    address: "Gewerbestraße 12, 83404 Ainring",
    telephone: "+49 8654 771610",
    website: "http://energie-kraft.de",
    email: "office@energie-kraft.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/e4p?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94598&utm_campaign=profiles_installer",
    company_name: "Energy for People GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7c6a053b6.png?v=1",
    address: "Robert-Bosch-Str. 10, 56410 Montabaur",
    telephone: "+49 2602 919550",
    website: "http://e4p.net",
    email: "office@e4p.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/c-s-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94395&utm_campaign=profiles_installer",
    company_name: "C&S Solarsystems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db393961f26.png?v=1",
    address: "Bahnhofstrasse 66, 16359 Biesenthal",
    telephone: "+49 3337 4308868",
    website: "http://www.cs-solarsystems.com",
    email: "office@cs-solarsystems.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bodmer-bad-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1633&utm_campaign=profiles_installer",
    company_name: "Bodmer Bad & Heizung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51cce618db4d6.jpg?v=1",
    address: "Eckenerstraße 4, 78532 Tuttlingen",
    telephone: "+49 7461 93660",
    website: "http://www.bodmer-bad-heizung.de",
    email: "office@bodmer-bad-heizung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/berliner-energieagentur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102361&utm_campaign=profiles_installer",
    company_name: "Berliner Energieagentur GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b67acd709.png?v=1",
    address: "Französische Str. 23, 10117 Berlin",
    telephone: "+49 30 2933300",
    website: "http://www.berliner-e-agentur.de",
    email: "office@berliner-e-agentur.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solon International,,,Centrosolar America Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alpin-sun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=52665&utm_campaign=profiles_installer",
    company_name: "Alpin Sun GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e575d1f22a74.png?v=1",
    address: "Zum Wasserwerk 12, D-15537, Erkner, bei Berlin",
    telephone: "+49 40 730119911",
    website: "http://www.alpin-sun.de",
    email: "office@alpin-sun.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Kanada, Deutschland, Spanien, Frankreich, Niederlande, USA,,Südamerika, Europa, Nordamerika",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 29052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/oelkrug-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81956&utm_campaign=profiles_installer",
    company_name: "Oelkrug Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bd754edf8e.jpg?v=1",
    address: "Haldenstraße 2, 73266 Bissingen Teck",
    telephone: "+49 7023 743000",
    website: "http://www.oelkrug-energietechnik.com",
    email: "oelkrug@email.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-hinze?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93515&utm_campaign=profiles_installer",
    company_name: "Haustechnik Hinze",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c06d4fbd4ad.jpg?v=1",
    address: "Lange Wiese 11, OT Altranft 16259 Bad Freienwalde",
    telephone: "+49 3344 5576",
    website: "http://www.solarhinze.de",
    email: "norman-hinzehaustechnik@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nickel-spezialmontage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93473&utm_campaign=profiles_installer",
    company_name: "Nickel Spezialmontage GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf5b3230260.jpg?v=1",
    address: "Fasanenstr. 10, 90587 Tuchenbach",
    telephone: "+49 911 755596",
    website: "http://www.nickel-spezialmontage.de",
    email: "nickel.rwa@gmail.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rewo-zeitarbeit?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94023&utm_campaign=profiles_installer",
    company_name: "ReWo Zeitarbeit GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9d1f25e104.jpg?v=1",
    address: "Alfred-Mozer-Str. 32, 48527 Nordhorn",
    telephone: "+49 5921 819200",
    website: "http://www.rewo-zeitarbeit.de",
    email: "NFO@REWO-ZEITARBEIT.DE",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ziemann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1775&utm_campaign=profiles_installer",
    company_name: "Ziemann Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1775n.gif?v=1",
    address: "Pierbusch 24-26, 44536, Lünen",
    telephone: "+49 231 9872492",
    website: "http://www.solararchitektur.de",
    email: "mz@solararchitektur.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Kyocera Corporation,,,Sharp Energy Solutions Europe,,,Photowatt,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mvs-bau-gbr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75783&utm_campaign=profiles_installer",
    company_name: "MVS Bau GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ce646a75581.jpg?v=1",
    address: "Knüllbergstraße 1a, 21702 Ahlerstedt",
    telephone: "+49 4166 8483065",
    website: "http://www.mvs-bau.de",
    email: "mvs-bau@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/murdaca-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95194&utm_campaign=profiles_installer",
    company_name: "Murdaca Energy UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b73e0ee1cfaf.png?v=1",
    address: "Siedlerstrasse 18, 92521 Schwarzenfeld",
    telephone: "+49 9439 9590354",
    website: "http://www.murdacaenergy.com",
    email: "murdacaenergy@gmail.com",
    "Installaliert seit": 2013,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/muller-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75351&utm_campaign=profiles_installer",
    company_name: "Müller Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6088289385c97.PNG?v=1",
    address: "Bahnhofstrasse 18, 74906 Bad Rappenau",
    telephone: "+49 72 68919557",
    website: "http://www.mueller-solar-technik.de",
    email: "mueller.oeko@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mtec-elektro-und-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131762&utm_campaign=profiles_installer",
    company_name: "MTEC Elektro und Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6073f3ae40c42.jpg?v=1",
    address: "Up'n Kamp 6, 28790 Schwanewede",
    telephone: "+49 4209 914375",
    website: "http://www.mtec-elektro.de",
    email: "mtec@mtec-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mm-electronics?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84791&utm_campaign=profiles_installer",
    company_name: "MM Electronics",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e5e149bde1e.jpg?v=1",
    address: "Neue Straße 8, 37627 Stadtoldendorf",
    telephone: "+49 5532 97085",
    website: "http://www.mm-elektro.com",
    email: "mm@mm-elektro.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ml-elektroservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94688&utm_campaign=profiles_installer",
    company_name: "ML-Elektroservice GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddcd9927f57.png?v=1",
    address: "Rosenkamp 15, 49733 Haren-Tinnen",
    telephone: "+49 5932 7334848",
    website: "https://www.ml-elektroservice.de",
    email: "ml-elektroservice@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-thees?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=12042&utm_campaign=profiles_installer",
    company_name: "Solar Thees GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52940256ae7d7.gif?v=1",
    address: "Parchimer Strasse 11a, 19374 Damm",
    telephone: "+49 3871 5055",
    website: "http://www.solarthees.de",
    email: "michael@solarthees.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/michael-langenberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81755&utm_campaign=profiles_installer",
    company_name: "Michael Langenberg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58954e5a65922.jpg?v=1",
    address: "Herringscher Weg 7, 47589 Uedem",
    telephone: "+49 174 6986056",
    website: "http://langenberg-solar.de",
    email: "michael@langenberg-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-augst?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10469&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Michael Augst",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd112c68da49.png?v=1",
    address: "Lessingstraße 29 b, 02689, Wehrsdorf",
    telephone: "+49 3 593633343",
    website: "http://www.dachdecker-augst.de",
    email: "michael@dachdecker-augst.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/menzel-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95060&utm_campaign=profiles_installer",
    company_name: "Menzel Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e848140f280.jpg?v=1",
    address: "Oberer Weinberg 37, 91522 Ansbach",
    telephone: "+49 0981 9724387",
    website: "http://www.menzel-bedachungen.de",
    email: "menzelbedachungengmbh@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/men-at-work?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95158&utm_campaign=profiles_installer",
    company_name: "Men at work GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee981498b0d.jpg?v=1",
    address: "Martinstr. 97, 64285 Darmstadt",
    telephone: "+49 171 8336526",
    website: "http://www.work-crew.de",
    email: "men@work-crew.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Yingli Green Energy Holding Co., Ltd.,,,EGing Photovoltaic Technology Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,,,ReneSola Ltd.,,,Perfectenergy (Shanghai) Co., Ltd.,,,NexPower Technology Corporation,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-und-fach-strunz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92160&utm_campaign=profiles_installer",
    company_name: "Dach und Fach Strunz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3cc679dfb7.jpg?v=1",
    address: "Kirchstraße 3, 38329 Wittmar",
    telephone: "+49 5337 926866",
    website: "https://www.dachundfach-strunz.com",
    email: "meister@dachundfach-strunz.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zeo-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58277&utm_campaign=profiles_installer",
    company_name: "Zeo Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602298611e557.png?v=1",
    address: "Robert-Bosch-Str. 3, 84539 Ampfing",
    telephone: "+49 8636 2398790",
    website: "http://www.zeo-solar.de",
    email: "meinstrom@zeo-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Canadian Solar Inc.,,,AU Optronics Corporation,,,Bauer Energiekonzepte GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-meier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93015&utm_campaign=profiles_installer",
    company_name: "H. Meier Bedachungen + Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5e30dc7c8d.jpg?v=1",
    address: "Am Bach 42, 50181, Bedburg",
    telephone: "+49 2272 808144",
    website: "http://meier-bedachungen-solartechnik.de/",
    email: "meier-bedachungen-solartechnik@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wun-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84812&utm_campaign=profiles_installer",
    company_name: "WUN Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e70999b4191.jpg?v=1",
    address: "Rot-Kreuz-Str. 8, 95632 Wunsiedel",
    telephone: "+49 9232 887195",
    website: "http://www.wun-solar.de",
    email: "matthias.wagner@wun-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,Solar Fabrik GmbH,,,Shenzhen MegaSolar Technologies Co., Ltd,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmidtlein-solartechnik-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75171&utm_campaign=profiles_installer",
    company_name: "Schmidtlein Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdbbb28ab208.png?v=1",
    address: "An der Eisenbahn 39, 27299 Langwedel",
    telephone: "+49 4235 957930",
    website: "http://wp.schmidtlein-solartechnik.de",
    email: "martinhillen@schmidtleinweb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pudlo-solar-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93013&utm_campaign=profiles_installer",
    company_name: "Pudlo Solar Technik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5dffcd9820.jpg?v=1",
    address: "Hordelerstr. 72 E, 44651, Herne",
    telephone: "+49 2325 924588",
    website: "http://martin-pudlo-solar-technik.de/",
    email: "martin-pudlo-solar-technik@online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Solon International,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,Anhui Schutten Solar Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eas-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93705&utm_campaign=profiles_installer",
    company_name: "Energie Agentur Salzinger",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c30da157a3d.jpg?v=1",
    address: "Untere Ringstr. 5f, 86899 Landsberg am Lech",
    telephone: "+49 8191 4299093",
    website: "http://www.energie-agentur-salzinger.de",
    email: "markus.salzinger@eas-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,LG Electronics, Inc.,,,Wiosun For Renewable Energy Ltd,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/vsun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119097&utm_campaign=profiles_installer",
    company_name: "VSUN SOLAR",
    logo_url: "https://cdn.enfsolar.com/z/s/logos/Kg5e5c830ddce5a/.png?v=1",
    address:
      "Lot III, Dong Vang Area, Dinh Tram Industrial Zone,Viet Yen District, Bac Giang",
    telephone: "+84 204 3566688",
    website: "http://www.vsun-solar.com",
    email: "marketing@vsunsolar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Japan, Vietnam,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",FUJI SOLAR,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/geckler-electrical?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83071&utm_campaign=profiles_installer",
    company_name: "Geckler Electrical",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b8d256e75aa.jpg?v=1",
    address: "Sattlergasse 11, 72131 Ofterdingen",
    telephone: "+49 7473 272744",
    website: "http://www.geckler.de",
    email: "mario@geckler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SolarWorld Industries GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarbau-mang?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93752&utm_campaign=profiles_installer",
    company_name: "Solarbau Mang",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c37d5b22bde.jpg?v=1",
    address: "Imsbachermühle 1, 66976 Rodalben",
    telephone: "+49 6331 1410557",
    website: "http://www.solarbau-mang.de",
    email: "mang@solarbau-mang.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-walbrodt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106238&utm_campaign=profiles_installer",
    company_name: "Elektro Walbrodt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b502c37a1798.png?v=1",
    address: "Untere Walsumermarkstr. 2a, 46147 Oberhausen",
    telephone: "+49 208 9698970",
    website: "http://www.elektro-walbrodt.de",
    email: "mailtoinfo@elektro-walbrodt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ibeko-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7304&utm_campaign=profiles_installer",
    company_name: "Ibeko-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53d704b8c7374.jpg?v=1",
    address: "Hasslerstr. 3, 83059 Kolbermoor",
    telephone: "+49 8031 2213030",
    website: "http://ibeko-solar.de",
    email: "mailto@ibeko-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Viessmann Werke GmbH & Co. KG,,,AU Optronics Corporation,,,AxSun Solar GmbH & Co. KG,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 2102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-klaus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116909&utm_campaign=profiles_installer",
    company_name: "Zimmerei Klaus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3eb2c11bb3a.png?v=1",
    address: "Brodershof 1, 88260 Argenbühl-Eisenharz",
    telephone: "+49 7522 914112",
    website: "http://www.zimmerei-klaus.de",
    email: "mail@zimmerei-klaus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bucher-huber-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10797&utm_campaign=profiles_installer",
    company_name: "Bucher & Huber Zimmerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10797n.gif?v=1",
    address: "Eulenthal 15, 83075, Bad Feilnbach",
    telephone: "+49 8064 242",
    website: "http://www.zimmerei-bucherundhuber.de",
    email: "mail@zimmerei-bucherundhuber.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wolf-system?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22956&utm_campaign=profiles_installer",
    company_name: "Wolf System GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4ffa8c005a3c8.gif?v=1",
    address: "Am Stadtwald 20, D-94486 Osterhofen",
    telephone: "+49 9932 370",
    website: "http://www.wolfsystem.de",
    email: "mail@wolfsystem.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wolf-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94183&utm_campaign=profiles_installer",
    company_name: "Wolf-Energietechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/600f359309661.png?v=1",
    address: "Gildenstr. 4b, 48157 Münster",
    telephone: "+49 251 2766888",
    website: "http://www.wolf-energietechnik.info",
    email: "mail@wolf-energietechnik.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wohnen-mit-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64721&utm_campaign=profiles_installer",
    company_name: "Wohnen mit Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6058d4010f0a6.PNG?v=1",
    address: "Fürkerstraße 14a-b, 42697 Solingen",
    telephone: "+49 212 64235678",
    website: "http://www.wohnen-mit-energie.de",
    email: "mail@wohnen-mit-Energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Aleo Solar GmbH,,,Solar Fabrik GmbH,,,SunPower Corp.,,,JA Solar Holdings Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/weiss-blau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107903&utm_campaign=profiles_installer",
    company_name: "Weiss-Blau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88a4950a4f5.jpg?v=1",
    address: "Marienstraße 9, 85298 Scheyern",
    telephone: "+49 8441 7970280",
    website: "https://www.weiss-blau-gmbh.de",
    email: "mail@weiss-blau-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/weis?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31087&utm_campaign=profiles_installer",
    company_name: "Ing.-Büro Weis",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51eccd06d5460.jpg?v=1",
    address: "Wendelsgasse 6, D-56653, Wassenach",
    telephone: "+49 26 36800921",
    website: "http://www.weis-gruppe.eu",
    email: "mail@weis-gruppe.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hyundai Energy Solutions,,,Malaysian Solar Resources Sdn Bhd,,,HIP Solar GmbH,",
    "Wechselrichter-Lieferanten": ",DASS Tech Co., Ltd,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wammetsberger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105978&utm_campaign=profiles_installer",
    company_name: "Wammetsberger Elektrotechnik und Photovoltaik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4459d6afb92.png?v=1",
    address: "Gewerbering am Brand 10, 82549 Königsdorf",
    telephone: "+49 8179 8062",
    website: "http://wammetsberger.de",
    email: "mail@wammetsberger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/volta-sole?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75533&utm_campaign=profiles_installer",
    company_name: "Volta Sole GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ce2d4c5ebd7.jpg?v=1",
    address: "Mühlweg 2, 97294 Unterpleichfeld",
    telephone: "+49 9367 982578",
    website: "http://www.volta-sole.de",
    email: "mail@volta-sole.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/unomondo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94579&utm_campaign=profiles_installer",
    company_name: "Unomondo",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd704e6235c.png?v=1",
    address: "Kißlingweg 69, 75417 Mühlacker",
    telephone: "+49 7041 815420",
    website: "http://www.unomondo.de",
    email: "mail@unomondo.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tondl-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102670&utm_campaign=profiles_installer",
    company_name: "Tondl Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac984b9c6457.jpg?v=1",
    address: "Gewerbegebiet Bitzen 14, 53804 Much",
    telephone: "+49 2245 9140390",
    website: "http://tondl.de",
    email: "mail@tondl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/techmaster?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75065&utm_campaign=profiles_installer",
    company_name: "Techmaster GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bbe26c26cc9.jpg?v=1",
    address: "Linsenäcker 10, 72379 Hechingen",
    telephone: "+49 7471 9601980",
    website: "https://www.techmaster.de",
    email: "mail@techmaster.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sv-strasser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105997&utm_campaign=profiles_installer",
    company_name: "SV-Strasser",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4575bcdd1cd.png?v=1",
    address: "Mannheimer Str. 4, 76344 Eggenstein-Leopoldshafen",
    telephone: "+49 172 6335742",
    website: "http://www.sv-strasser.de",
    email: "mail@sv-strasser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunroof-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30945&utm_campaign=profiles_installer",
    company_name: "Sunroof GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ec9f2fed65c.jpg?v=1",
    address: "Oberer Brühl 42, 72336 Balingen-Roßwangen",
    telephone: "+49 7433 308108",
    website: "http://www.sunroof-gmbh.de",
    email: "mail@sunroof-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunnavoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30933&utm_campaign=profiles_installer",
    company_name: "SunnaVoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602fdab234288.png?v=1",
    address: "St.-Bartholomäus-Weg 6, 85457, Hörlkofen",
    telephone: "+49 176 84006060",
    website: "http://www.sunnavoltaik.com",
    email: "mail@sunnavoltaik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Hanwha Q CELLS GmbH,,,Trina Solar Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/struwe-dachtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95222&utm_campaign=profiles_installer",
    company_name: "Struwe Dachtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f00c11bb313.png?v=1",
    address: "Ludwig-Wagner-Str. 33, 69168 Wiesloch",
    telephone: "+49 6222 92590",
    website: "http://www.struwe-dachtechnik.de",
    email: "mail@struwe-dachtechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sontec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81982&utm_campaign=profiles_installer",
    company_name: "Sontec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589c0823e700f.jpg?v=1",
    address: "Benzstr. 15, 71332 Waiblingen",
    telephone: "+49 7151 5020367",
    website: "http://www.sontecgmbh.de",
    email: "mail@sontecgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnen-bartel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1836&utm_campaign=profiles_installer",
    company_name: "Sonnen Bartel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1836n.gif?v=1",
    address: "Nelkenstraße 8, 83101 Rohrdorf",
    telephone: "+49 8031 71148",
    website: "http://www.sonnenbartel.de",
    email: "mail@sonnenbartel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1708&utm_campaign=profiles_installer",
    company_name: "Solartekt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4173.gif?v=1",
    address: "Große Wiese 14, 3276 Detmold",
    telephone: "+49 5231 469515",
    website: "http://www.solartekt.de",
    email: "mail@solartekt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solare-technik-klinkenberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30859&utm_campaign=profiles_installer",
    company_name: "Solare Technik Klinkenberg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ec85319cca0.jpg?v=1",
    address: "Litzelbergstraße 11, 78315 RAdolfzell",
    telephone: "+49 7732 8235767",
    website: "http://www.solare-technik.com",
    email: "mail@solare-technik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solar Frontier K.K.,,,Astronergy Co., Ltd. (Chint Solar),,,Viessmann Werke GmbH & Co. KG,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-wend?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=624&utm_campaign=profiles_installer",
    company_name: "Solar Wend GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/624n.gif?v=1",
    address: "Beesem 12, 29487 Luckau, Wendland",
    telephone: "+49 5844 975905",
    website: "http://www.solar-wend.de",
    email: "mail@solar-wend.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Panasonic Corporation (Sanyo),,,Alfa Solar Energy Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solagent?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93559&utm_campaign=profiles_installer",
    company_name: "Solagent GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c08790b6169.jpg?v=1",
    address: "Weißenbach 9, 95339 Wirsberg",
    telephone: "+49 9227 346002",
    website: "http://www.solagent.eu",
    email: "mail@solagent.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sibora-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94132&utm_campaign=profiles_installer",
    company_name: "Sibora Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb6c9cd163c.jpg?v=1",
    address: "Dornburger Weg 36 b, 39114 Magdeburg",
    telephone: "+49 391 5045244",
    website: "https://sibora-elektrotechnik.de",
    email: "mail@sibora-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/scm-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11870&utm_campaign=profiles_installer",
    company_name: "Scm Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/518204c6a9c23.bmp?v=1",
    address: "Groß Chüdener Chaussee 3, 29410 Salzwedel, OT Pretzier",
    telephone: "+49 39037 956000",
    website: "http://scm-energy.de",
    email: "mail@scm-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rock-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=53663&utm_campaign=profiles_installer",
    company_name: "Röck-Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54fd47b4617b0.jpg?v=1",
    address: "Walpersdorfer Straße 4, 91126 Schwabach",
    telephone: "+49 9122 885770",
    website: "http://www.roeck-haustechnik.de",
    email: "mail@roeck-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/res-6?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102569&utm_campaign=profiles_installer",
    company_name: "RES Rüdersdorfer Elektromontage und Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac43adb60905.png?v=1",
    address: "Ernst-Thälmann-Str. 81-83, 15562 Rüdersdorf bei Berlin",
    telephone: "+49 33638 7420",
    website: "http://www.res-service.de",
    email: "mail@resgmbh.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/res-heinz-wolf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8662&utm_campaign=profiles_installer",
    company_name: "RES Heinz Wolf GmbH i.L.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57620ef024db7.jpg?v=1",
    address: "Im Buschfeld 6, 41515 Grevenbroich",
    telephone: "+49 21 81499807",
    website: "http://www.res-heinzwolf-gmbh.de",
    email: "mail@res-heinzwolf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/procon?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75893&utm_campaign=profiles_installer",
    company_name: "Procon Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d0c3b76c762.jpg?v=1",
    address: "Nordparkstraße 30, D-03044 Cottbus",
    telephone: "+49 355 485520",
    website: "http://www.procon-solar.de",
    email: "mail@procon-consult.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernhard-plasz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81977&utm_campaign=profiles_installer",
    company_name: "Bernhard Plaß GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fedd1e1f1597.png?v=1",
    address: "Stiegenkamp 3, 59368 Werne",
    telephone: "+49 2389 2938",
    website: "http://www.plassgmbh.de",
    email: "mail@plassgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pfeufer-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93467&utm_campaign=profiles_installer",
    company_name: "Pfeufer-Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60b0dc7d31d24.png?v=1",
    address: "Christanz 7a, 95491 Ahorntal",
    telephone: "+49 9202 9709707",
    website: "http://pfeufer-energie.de/",
    email: "mail@pfeufer-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pape-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101765&utm_campaign=profiles_installer",
    company_name: "Pape Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa5fd13ae206.jpg?v=1",
    address: "Industriestraße 1, 27446 Selsingen",
    telephone: "+49 4284 93090",
    website: "http://www.pape-haustechnik.de",
    email: "mail@pape-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/otto-leible?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93453&utm_campaign=profiles_installer",
    company_name: "Otto Leible GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf3dc12fcf8.jpg?v=1",
    address: "Hauptstr. 17a, D-23611 Bad Schwartau",
    telephone: "+49 451 301017",
    website: "http://www.otto-leible.de",
    email: "mail@otto-leible.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/okobau-harz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83166&utm_campaign=profiles_installer",
    company_name: "Ökobau Harz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58bd161cb63eb.jpg?v=1",
    address: "Gartenweg 26, 37539 Bad Grund (Harz)",
    telephone: "+49 5522 951663",
    website: "http://web.bb-oekobau.de",
    email: "mail@oekobau-harz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/krause-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103551&utm_campaign=profiles_installer",
    company_name: "Krause Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebce47e3edf.jpg?v=1",
    address: "Diepholzer Straße 26, 49393 Lohne",
    telephone: "+49 4442 802483",
    website: "http://www.krauseelektro.de",
    email: "mail@krauseelektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ein-stein-kompetenzzentrum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=89612&utm_campaign=profiles_installer",
    company_name: "Ein Stein Kompetenzzentrum GmbH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59632cc04768e.jpg?v=1",
    address: "Grabenstraße 1a, 67578 Gimbsheim",
    telephone: "+49 6249 8067520",
    website: "https://www.kompetenz-zentrum-energie.de",
    email: "mail@kompetenz-zentrum-energie.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kohrt-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93166&utm_campaign=profiles_installer",
    company_name: "Kohrt Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b75b32c6f5f.jpg?v=1",
    address: "Hechtskuhl 20, 23992 Neukloster",
    telephone: "+49 38422 45046",
    website: "http://kohrt-elektro.de/",
    email: "mail@kohrt-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/klarmodul?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81973&utm_campaign=profiles_installer",
    company_name: "KlarModul GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d47dede9b23a.jpg?v=1",
    address: "Wohlmuthäuser Str. 24, 74670 Forchtenberg",
    telephone: "+49 7947 9439330",
    website: "http://www.klarmodul.com",
    email: "mail@klarmodul.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,IBC Solar AG,,,GCL System Integration Technology Co., Ltd.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kistler-elektrotechnik-neue-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75603&utm_campaign=profiles_installer",
    company_name: "Kistler Elektrotechnik & Neue Energie GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae48090a658.PNG?v=1",
    address: "Gerda-Hasselfeldt-Ring 17, 85235 Wagenhofen",
    telephone: "+49 8134 5566550",
    website: "http://kistlerelektrotechnik.de",
    email: "mail@kistler-elektrotechnik.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Viessmann Werke GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kalteis-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92853&utm_campaign=profiles_installer",
    company_name: "Kalteis-Solar UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0c16e59ba7.jpg?v=1",
    address: "Grüner Weg 10a, 49401, Damme",
    telephone: "+49 173 6302736",
    website: "http://kalteis-solar.de/",
    email: "mail@kalteis-solar.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,EGing Photovoltaic Technology Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,First Solar, Inc.,,,AXITEC Energy GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/jungsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10513&utm_campaign=profiles_installer",
    company_name: "JungSolar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10513n.gif?v=1",
    address: "Kurze Baunastraße 23, 34270, Schauenburg, Elgershausen",
    telephone: "+49 5601 9617240",
    website: "http://www.jungsolar.de",
    email: "mail@jungsolar.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,EGing Photovoltaic Technology Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-huber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124874&utm_campaign=profiles_installer",
    company_name: "Elektro Huber",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecfb1304e7e1.png?v=1",
    address: "Dietlinger Str., 14, 79809 Weilheim",
    telephone: "+49 7741 2217",
    website: "http://huber-weilheim.de/",
    email: "mail@huber-weilheim.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gutmaier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94356&utm_campaign=profiles_installer",
    company_name: "Gutmaier GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e967fefdd136.png?v=1",
    address: "Brunsbütteler Damm 128 A, 13581 Berlin",
    telephone: "+49 30 3314919",
    website: "http://gutmaier.de",
    email: "mail@gutmaier.berlin",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/geba?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84795&utm_campaign=profiles_installer",
    company_name: "GeBa GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e5e555dbbca.jpg?v=1",
    address: "Am Bach 4, D-79736, Rickenbach",
    telephone: "+49 7765 918375",
    website: "http://www.geba-gmbh.com",
    email: "mail@geba-gmbh.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heinz-ewald?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11871&utm_campaign=profiles_installer",
    company_name: "Heinz Ewald GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/516765e32b103.gif?v=1",
    address: "Im Lämpchen 19, 30459 Hannover",
    telephone: "+49 511 421652",
    website: "http://www.ewaldbedachungen.de",
    email: "mail@ewaldbedachungen.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eurosol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1620&utm_campaign=profiles_installer",
    company_name: "Eurosol GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51779867858ed.gif?v=1",
    address: "Am Herrschaftsweiher 45, 67071 Ludwigshafen",
    telephone: "+49 621 5957070",
    website: "http://www.eurosol.eu",
    email: "mail@eurosol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,EGing Photovoltaic Technology Co., Ltd.,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,,,ReneSola Ltd.,,,BYD Company Limited,,,Phono Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Siemens Energy,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eq-tech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101807&utm_campaign=profiles_installer",
    company_name: "EQ-tech GmbH＆Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa7229fdb571.jpg?v=1",
    address: "Mühlweg 11, 88239, Wangen im Allgäu",
    telephone: "+49 7522 974590",
    website: "https://www.eq-tech.de",
    email: "mail@eq-tech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/enerparc?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7657&utm_campaign=profiles_installer",
    company_name: "Enerparc AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7657n.gif?v=1",
    address: "2, Astra Tower, 20359 Hamburg",
    telephone: "+49 40 75664490",
    website: "http://www.enerparc.de",
    email: "mail@enerparc.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Deutschland, Frankreich, Indien, Turkei, Russland,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-helmut-schwarzer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=611&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Helmut Schwarzer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/611n.gif?v=1",
    address: "Gildenstrasse 2D, D - 48157 Münster",
    telephone: "+49 251 141370",
    website: "http://www.elektroschwarzer.de",
    email: "mail@elektroschwarzer.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-service-haas?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10036&utm_campaign=profiles_installer",
    company_name: "Elektro - Service Haas",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10036n.gif?v=1",
    address: "Beim Rohrwald 2, 66780 Rehlingen-Siersburg",
    telephone: "+49 6835 9239780",
    website: "http://www.elektro-service-haas.de",
    email: "mail@elektro-service-haas.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-lother?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10544&utm_campaign=profiles_installer",
    company_name: "Elektro Löther GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10544n.gif?v=1",
    address: "Würzburger Straße 33, 97342 Obernbreit",
    telephone: "+49 9332 50460",
    website: "http://www.elektro-loether.de",
    email: "mail@elektro-loether.de",
    "Installaliert seit": 1997,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-guhring?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1928&utm_campaign=profiles_installer",
    company_name: "Elektro Gühring GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/572188b823468.jpg?v=1",
    address: "Freihofstraße 25, 70439 Stuttgart",
    telephone: "+49 711 802218",
    website: "http://www.elektro-guehring.de",
    email: "mail@elektro-guehring.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stephan-eickhoff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92234&utm_campaign=profiles_installer",
    company_name: "Stephan Eickhoff",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4ffbd20eea.jpg?v=1",
    address: "Seilfahrt 125, 44809 Bochum",
    telephone: "+49 234 4156080",
    website: "http://www.eickhoff-dach.de",
    email: "mail@eickhoff-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bumiller-neue-energien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=582&utm_campaign=profiles_installer",
    company_name: "Bumiller Neue Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/561b748c89330.jpg?v=1",
    address: "Großbeerenstraße 13 A, D-10963 Berlin-Kreuzberg",
    telephone: "+49 30 279072020",
    website: "http://www.e-berlin.info",
    email: "mail@e-roller.berlin",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dobler-heiztechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11985&utm_campaign=profiles_installer",
    company_name: "Dobler Heiztechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11985n.gif?v=1",
    address: "Stuttgarter Straße 38, 71384, Weinstadt-Beutelsbach",
    telephone: "+49 71 5168007",
    website: "http://www.dobler-heiztechnik.de",
    email: "mail@dobler-heiztechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmellenkamp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116908&utm_campaign=profiles_installer",
    company_name: "Schmellenkamp - Die Dachbaumeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3eb25599e48.png?v=1",
    address: "Kiesbert 9 - 58849 Herscheid",
    telephone: "+49 2357 601776",
    website: "https://www.diedachbaumeister.de",
    email: "mail@diedachbaumeister.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/derr-elektro-hausgerate?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103515&utm_campaign=profiles_installer",
    company_name: "Derr Elektro+Hausgeräte",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebb55b935b6.jpg?v=1",
    address: "Kirchgasse 4, 01734 Rabenau",
    telephone: "+49 351 4601187",
    website: "http://www.derr-elektro.de",
    email: "mail@derr-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dean-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=97540&utm_campaign=profiles_installer",
    company_name: "Dean Solar Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a2f917d05a23.png?v=1",
    address: "Alte Feldmühle 10, D-31535 Neustadt a. Rbge.",
    telephone: "+49 5034 959140",
    website: "http://deansolar.com",
    email: "mail@deanSolar.com",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: ",Dean Gruppe,",
    "Last Update": 8062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/friebe-klempnerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11068&utm_campaign=profiles_installer",
    company_name: "Friebe Klempnerei GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11068n.gif?v=1",
    address: "Elsässer Strasse 14a, 16548, Glienicke",
    telephone: "+49 33056 94562",
    website: "http://www.dachdeckerei-friebe.de",
    email: "mail@dachdeckerei-friebe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebr-lagoda?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94967&utm_campaign=profiles_installer",
    company_name: "Gebr. Lagoda GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e06b0d6dc35.jpg?v=1",
    address: "Walter-Welp-Str. 19, 44149 Dortmund",
    telephone: "+49 231 856526",
    website: "http://dachdecker-in-dortmund.de",
    email: "mail@dachdecker-in-dortmund.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/corona-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=639&utm_campaign=profiles_installer",
    company_name: "Corona Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/539684d11b684.jpg?v=1",
    address: "Zur Bettfedernfabrik 1, 30451, Hannover",
    telephone: "+49 511 9247950",
    website: "https://www.corona.solar",
    email: "mail@corona.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/buschmann-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94066&utm_campaign=profiles_installer",
    company_name: "Buschmann Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca0b5d50743.jpg?v=1",
    address: "Langenstraße 12, 27239 Twistringen",
    telephone: "+49 42 4395495",
    website: "http://buschmann-energietechnik.de",
    email: "mail@buschmann-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bode-christ?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10369&utm_campaign=profiles_installer",
    company_name: "Bode + Christ GmbH Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10369n.gif?v=1",
    address: "Max-Planck-Strasse 4, D-78052 Villingen-Schwenningen",
    telephone: "+49 7721 95230",
    website: "http://www.bodechrist.de",
    email: "mail@bodechrist.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/binkert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1635&utm_campaign=profiles_installer",
    company_name: "Binkert GmbH Heizung Lüftung Sanitär",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51cce7025cb71.jpg?v=1",
    address: "Am Riedbach 3, 79774 Albbruck-Birndorf",
    telephone: "+49 77 5392100",
    website: "http://www.binkert.de",
    email: "mail@binkert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AxSun Solar GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116890&utm_campaign=profiles_installer",
    company_name: "Zimmerei & Photovoltaik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3e9fc9ea8d8.png?v=1",
    address: "Crinitztalstraße 4a, 08147 CrinitzbergOT Lauterhofen",
    telephone: "+49 37602 690269",
    website: "https://baumann-falk.de",
    email: "mail@baumann-falk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/backhaus-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77811&utm_campaign=profiles_installer",
    company_name: "Backhaus Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/582ea1218e168.jpg?v=1",
    address: "Bergstedter Markt 1, 22395 Hamburg",
    telephone: "+49 40 60441291",
    website: "http://www.backhaussolartechnik.de",
    email: "mail@backhaussolartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aumann-haus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94352&utm_campaign=profiles_installer",
    company_name: "Aumann Haus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dac81ba0309.jpg?v=1",
    address: "Rudolf-Diesel-Str. 3, 86473 Ziemetshausen",
    telephone: "+49 8284 9989800",
    website: "https://www.aumann-haus.de",
    email: "mail@aumann-haus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haenes-dach-fassade?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92622&utm_campaign=profiles_installer",
    company_name: "Haenes Dach & Fassade GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae0a997b26f.jpg?v=1",
    address: "Tharandter Str. 37, 01159 Dresden",
    telephone: "+49 351 4818181",
    website: "http://www.haenes-dach.de",
    email: "mail@athaenes-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ares-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1502&utm_campaign=profiles_installer",
    company_name: "ARES Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/522ece84bfe65.gif?v=1",
    address: "SAP-Allee 5, 68789 St. Leon-Rot",
    telephone: "+49 6227 52355",
    website: "http://www.aresenergy.de",
    email: "mail@aresenergy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/b-a-u-bauausfuhrung-architektur-umbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81646&utm_campaign=profiles_installer",
    company_name: "Bauausführung Architektur Umbach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58881a64637d5.jpg?v=1",
    address: "Friedetal 1, 99759 Sollstedt",
    telephone: "+49 36338 63020",
    website: "http://www.architektur-bauausfuehrung.de",
    email: "mail@architektur-bauausfuehrung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/altertec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30537&utm_campaign=profiles_installer",
    company_name: "Altertec e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e8893db7a3a.jpg?v=1",
    address: "Einsteinstraße 17, 40589, Düsseldorf",
    telephone: "+49 211 82854880",
    website: "http://www.altertec.de",
    email: "mail@altertec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Phono Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ralf-kaltschmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94733&utm_campaign=profiles_installer",
    company_name: "Ralf Kaltschmidt Elektroanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dddd6dc66b7.png?v=1",
    address: "Gutsstraße 5, 03205 Calau",
    telephone: "+49 3541 802222",
    website: "http://www.alles-elektrisch.de",
    email: "mail@alles-elektrisch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,ABB Italy S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alcapi-solartwent-systems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11113&utm_campaign=profiles_installer",
    company_name: "Alcapi Solartwent Systems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57889bd29a398.jpg?v=1",
    address: "Klingelbergerstr. 13, D-77770 Durbach",
    telephone: "+49 781 9481810",
    website: "http://www.alcapi.de",
    email: "mail@alcapi.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Ukraine, Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/akut-solar-und-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=579&utm_campaign=profiles_installer",
    company_name: "AKUT Solar- und Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/543f2dfe759d1.jpg?v=1",
    address: "Wattstraße 10, D-13355, Berlin Mitte",
    telephone: "+49 30 72616697",
    website: "http://www.akut-solar.de",
    email: "mail@akut-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Kyocera Corporation,,,Panasonic Corporation (Sanyo),,,Hyundai Energy Solutions,,,LG Electronics, Inc.,,,Eternit Österreich GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Victron Energy BV,,,Siemens Energy,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/maucke-und-niegisch-dachdecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94218&utm_campaign=profiles_installer",
    company_name: "Maucke und Niegisch Dachdecker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc08129d31.png?v=1",
    address: "Untere Dorfstraße 29, 02791 Oderwitz",
    telephone: "+49 35842 24176",
    website: "http://www.dachdecker-maucke-niegisch.de",
    email: "maik.niegisch@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/maik-pilgram-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94934&utm_campaign=profiles_installer",
    company_name: "Maik Pilgram Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e052109714b.png?v=1",
    address: "Kölner Straße 336, 51515 Kürten",
    telephone: "+49 2207 705450",
    website: "https://www.pilgram-dach.de",
    email: "maik-pilgram@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-klima-kompetenzzentrum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93570&utm_campaign=profiles_installer",
    company_name:
      "Solar & Klima Kompetenzzentrum Kettmann & Maasch GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c08c00b9837.jpg?v=1",
    address: "Stadtring 8, 03042 Cottbus",
    telephone: "+49 355 2903350",
    website: "http://www.solar-klima-kompetenzzentrum.de",
    email: "maasch@solar-klima-kompetenzzentrum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/new-lighttec-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=57891&utm_campaign=profiles_installer",
    company_name: "New LightTec Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601bf20504127.png?v=1",
    address: "Am Liepengraben 7, 18147 Rostock",
    telephone: "+49 381 63744880",
    website: "http://www.lighttec-solar.de",
    email: "m.wassmann@lighttec-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/richardt-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106213&utm_campaign=profiles_installer",
    company_name: "Richardt Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4f0752b2516.png?v=1",
    address: "Markusstr. 10, 45968 Gladbeck",
    telephone: "+49 2043 319915",
    website: "http://www.richardtsolar.de",
    email: "m.richardt@richardtsolar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mack-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101870&utm_campaign=profiles_installer",
    company_name: "Mack Solar GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa87e6ccea0e.jpg?v=1",
    address: "Erlenweg 2, 17291 Prenzlau",
    telephone: "+49 3984 806863",
    website: "http://www.mack-solar.de",
    email: "m.mack@mack-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hermann-michelau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94467&utm_campaign=profiles_installer",
    company_name: "Hermann Michelau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc44680e099.jpg?v=1",
    address: "Aldermannweg 6, 23560 Lübeck",
    telephone: "+49 451 5808784",
    website: "http://www.michelau-dach.de",
    email: "luebeck@michelau-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lorenz-zimmerei-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10770&utm_campaign=profiles_installer",
    company_name: "Lorenz Zimmerei & Holzbau Andreas Lorenz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10770n.gif?v=1",
    address: "Griesheimerstraße 73, 77652 Offenburg-Griesheim",
    telephone: "+49 781 9399545",
    website: "http://www.lorenz-zimmerei-og.de",
    email: "lorenz@zimmerei-og.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/metzger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1303&utm_campaign=profiles_installer",
    company_name: "Logemann Sanitär Heizung Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ff6b7064beb2.png?v=1",
    address: "Sandgasse 25, 77731, Willstätt",
    telephone: "+49 78 5297852",
    website: "https://www.logemann-ml.de",
    email: "logemann@logemann-ml.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/siegfried-lochner-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1666&utm_campaign=profiles_installer",
    company_name: "Siegfried Lochner Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5167a81f41d68.bmp?v=1",
    address: "Langenburgstr. 13, 81245 München-Aubing",
    telephone: "+49 89 879304",
    website: "http://www.lochner-haustechnik.de",
    email: "lochner.haustechnik@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solar Fabrik GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lnl-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94212&utm_campaign=profiles_installer",
    company_name: "LNL Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdbaff5b65e.jpg?v=1",
    address: "Westerbachstraße 11, 37671 Höxter",
    telephone: "+49 5271 97760",
    website: "http://www.lnl-elektrotechnik.de",
    email: "LNL-Elektrotechnik@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-link?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11987&utm_campaign=profiles_installer",
    company_name: "Elektro Link GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11987n.gif?v=1",
    address: "Schorndorfer Straße 5, 73614 Schorndorf - Weiler",
    telephone: "+49 7181 977600",
    website: "http://www.elektrolink.de",
    email: "link@elektrolink.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/let?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102676&utm_campaign=profiles_installer",
    company_name: "LET Löwer & Co. GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9890ad0f3d.jpg?v=1",
    address: "Glockenhofsweg 9, 34277 Fuldabrück-Dörnhagen",
    telephone: "+49 5665 94670",
    website: "http://www.loewer-energietechnik.de",
    email: "let@loewer-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-collect-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93568&utm_campaign=profiles_installer",
    company_name: "Solar Collect GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c08a8449fcf.jpg?v=1",
    address: "Frühlingstraße 25, 97259 Greussenheim",
    telephone: "+49 9369 990499",
    website: "http://www.solar-collect.com",
    email: "lehrmann@solar-collect.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/esa-energieprojekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83191&utm_campaign=profiles_installer",
    company_name: "ESA Energieprojekt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d43ac8d18877.jpg?v=1",
    address: "Stockerpointstraße 4, 94560 Offenberg",
    telephone: "+49 991 9959711",
    website: "http://esa-energieprojekt.de",
    email: "LED@esa-energieprojekt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Risen Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/langhof-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94827&utm_campaign=profiles_installer",
    company_name: "Langhof Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df0f501b6bc.jpg?v=1",
    address: "Hauptstr. 62, 87637 Seeg/Allgäu",
    telephone: "+49 83 64418",
    website: "http://www.langhof-seeg.de",
    email: "langhof-seeg@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lackner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93194&utm_campaign=profiles_installer",
    company_name: "Lackner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b7847a9e60d.jpg?v=1",
    address: "Woogseestr. 13, 76437 Rastatt",
    telephone: "+49 7222 774427",
    website: "http://lackner-gmbh.com/",
    email: "lackner.gmbh@googlemail.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nova-energies?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=87895&utm_campaign=profiles_installer",
    company_name: "Nova Energies e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50585938ed6b8c1ee.gif?v=1",
    address: "Hermann-Reichelt-Str. 3, 01109 Dresden",
    telephone: "+49 9621 9039954",
    website: "http://www.novaenergies.de",
    email: "kurt.rose@novaenergies.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/egt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94660&utm_campaign=profiles_installer",
    company_name: "EGT AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c35727e96984.jpg?v=1",
    address: "Schonacher Str. 2, 78098 Triberg",
    telephone: "+49 7722 918100",
    website: "https://www.egt.de",
    email: "kundenservice@egt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kuhn-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=33413&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Kühn GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fc7c81b4af58.PNG?v=1",
    address: "Gerd-Heinssen-Straße 4, 21640 Horneburg",
    telephone: "+49 4163 81880",
    website: "http://www.kuehn-dach.de",
    email: "kuehn@kuehn-dach.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kub-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93180&utm_campaign=profiles_installer",
    company_name: "Kub-Solar Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b75f6cc1cbd.jpg?v=1",
    address: "Lerchenkoppel 7, 25704, Wolmersdorf",
    telephone: "+49 4832 979893",
    website: "http://kub-solar.de/",
    email: "kub-solar@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/k-k-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124490&utm_campaign=profiles_installer",
    company_name: "K&K Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb8cb255a28c.jpg?v=1",
    address: "Itzlinger Str. 10, 94469 Deggendorf",
    telephone: "+49 991 36172983",
    website: "http://www.strom-pv.eu",
    email: "ksolar@online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-norbert-krug?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93178&utm_campaign=profiles_installer",
    company_name: "Haustechnik Norbert Krug",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b75ec2353cd.jpg?v=1",
    address: "Maintalstraße 147, 95460, Bad Berneck",
    telephone: "+49 9273 1438",
    website: "http://krug-heizung-sanitär.com/",
    email: "krug-heizungsanitaer@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Viessmann Werke GmbH & Co. KG,,,Solvis d.o.o.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zirkelbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102039&utm_campaign=profiles_installer",
    company_name: "Zirkelbach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab76f55c08c.jpg?v=1",
    address: "Reuther Straße 18, 96272 Hochstadt",
    telephone: "+49 9574 652797",
    website: "http://zirkelbach-hochstadt-mde.de",
    email: "kontakt@zirkelbach-hochstadt-mde.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-holzbau-bedachungen-fabian-mauderer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=118160&utm_campaign=profiles_installer",
    company_name: "Zimmerei – Holzbau – Bedachungen • Fabian Mauderer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d6cdeb528fd3.png?v=1",
    address: "Hertzstraße 8, 92521 Schwarzenfeld",
    telephone: "+49 9435 3009553",
    website: "https://zimmerei-mauderer.de",
    email: "kontakt@zimmerei-mauderer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-holzbau-karl-kernl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93947&utm_campaign=profiles_installer",
    company_name: "Zimmerei - Holzbau Karl Kernl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8b5573e921.jpg?v=1",
    address: "Kelheimer Str. 26, 93336 Altmannstein",
    telephone: "+49 8468 657",
    website: "http://www.zimmerei-kernl.de",
    email: "kontakt@zimmerei-kernl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wiventa?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11878&utm_campaign=profiles_installer",
    company_name: "Winter Energietechnik GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fee019253c8e.png?v=1",
    address: "Engelblecker Str. 340, 41066, Mönchengladbach",
    telephone: "+49 2161 4764770",
    website: "https://www.winterenergie.com",
    email: "kontakt@winterenergie.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wiebach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=635&utm_campaign=profiles_installer",
    company_name: "Wiebach Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/635n.gif?v=1",
    address: "Elbinger Platz 3, 27570 Bremerhaven",
    telephone: "+49 471 21403",
    website: "http://www.wiebach.de",
    email: "kontakt@wiebach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wewers?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10577&utm_campaign=profiles_installer",
    company_name: "Wewers GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5877281f8e5be.jpg?v=1",
    address: "Daimlerstr. 21, 32791, Lage",
    telephone: "+49 5232 979590",
    website: "http://www.wewers-gmbh.de",
    email: "kontakt@wewers-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wemag?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=36593&utm_campaign=profiles_installer",
    company_name: "WEMAG AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52146b2ac4238.jpg?v=1",
    address: "Obotritenring 40, 19053 Schwerin",
    telephone: "+49 385 7552755",
    website: "http://www.wemag.com",
    email: "kontakt@wemag.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: ",WEMAG-Gruppe,",
    "Last Update": 28122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wekatech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74689&utm_campaign=profiles_installer",
    company_name: "wekaTech PV and more",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608bf05fba078.PNG?v=1",
    address: "Graslitzerstr. 24, 84478-Waldkraiburg",
    telephone: "+49 176 46625445",
    website: "http://www.wekatech.de",
    email: "kontakt@wekatech.de",
    "Installaliert seit": 2014,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-walzenbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10789&utm_campaign=profiles_installer",
    company_name: "Zimmerei Walzenbach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10789n.gif?v=1",
    address: "Jahnstrasse 12, 97953 Koenigheim",
    telephone: "+49 9341 5975",
    website: "http://www.zimmerei-walzenbach.de",
    email: "kontakt@webdesign-schmitt.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/unterholzner-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106010&utm_campaign=profiles_installer",
    company_name: "Unterholzner Photovoltaik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4584e32f01c.png?v=1",
    address: "Milchstr. 9, 84163 Marklkofen / Poxau",
    telephone: "+49 8732 92150",
    website: "http://www.unterholzner-photovoltaik.de",
    email: "kontakt@uh-service.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/twf-dach-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94812&utm_campaign=profiles_installer",
    company_name: "TWF Dach & Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df03a43730c.jpg?v=1",
    address: "Spergauer Strasse 2, 06237, Leuna",
    telephone: "+49 3461 4569847",
    website: "https://www.twf-gmbh.com",
    email: "kontakt@twf-gmbh.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tc-projekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101820&utm_campaign=profiles_installer",
    company_name: "Tc-projekt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa72e497e604.jpg?v=1",
    address: "Damaschkestr. 45, 06849 Dessau-Roßlau",
    telephone: "+49 340 5198093",
    website: "http://www.tc-haus.de",
    email: "kontakt@tc-haus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnett?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7300&utm_campaign=profiles_installer",
    company_name: "Sonnett Vertriebs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60799313ec9ad.png?v=1",
    address: "Am Kalkheck 5, 58313, Herdecke",
    telephone: "+49 2330 60660",
    website: "http://www.sonnett.de",
    email: "kontakt@sonnett.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solar Fabrik GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Jiangsu SunLink PV Technology Co., Ltd.,,,Eurener Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenweg-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84847&utm_campaign=profiles_installer",
    company_name: "Sonnenweg Solar KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e7339314d75.jpg?v=1",
    address: "Röhrsdorfer Allee 13, 09247 Chemnitz",
    telephone: "+49 3722 5051190",
    website: "http://www.sonnenweg-solar.de",
    email: "kontakt@sonnenwegsolar.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solidee?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10412&utm_campaign=profiles_installer",
    company_name: "SOLidee GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd0e89148281.png?v=1",
    address: "Klein Westerbeck 17, 27711 Osterholz-Scharmbeck",
    telephone: "+49 4791 1408690",
    website: "http://www.solidee.de",
    email: "kontakt@solidee.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",JA Solar Holdings Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-und-strom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93788&utm_campaign=profiles_installer",
    company_name: "Solar und Strom UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c475eecc480.jpg?v=1",
    address: "Daimlerring 43, 32289 Rödinghausen",
    telephone: "+49 5223 7927510",
    website: "http://solarundstrom.de",
    email: "kontakt@solarundstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-turpe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75217&utm_campaign=profiles_installer",
    company_name: "Solartechnik Türpe GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60882c93ad75c.PNG?v=1",
    address: "Obere Hauptstrasse 115, 09232 Hartmannsdorf",
    telephone: "+49 3722 95343",
    website: "https://www.solartechnik-tuerpe.de",
    email: "kontakt@solartechnik-tuerpe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Viessmann Werke GmbH & Co. KG,,,Vaillant SLU,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarinseln?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=12146&utm_campaign=profiles_installer",
    company_name: "Solarinseln - Systemhaus für Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/12146n.gif?v=1",
    address: "Am Fuchsengraben 5, 82319, Starnberg",
    telephone: "+49 8151 9716400",
    website: "http://www.solarinseln.de",
    email: "kontakt@solarinseln.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Suntech Power Co., Ltd.,,,Panasonic Corporation (Sanyo),,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarbonus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10293&utm_campaign=profiles_installer",
    company_name: "Solarbonus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10293n.gif?v=1",
    address: "Feserstr. 5, 80937, München",
    telephone: "+49 89 37067865",
    website: "http://www.solarbonus.de",
    email: "kontakt@solarbonus.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,BISOL Group, d.o.o.,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rieger-kraft-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76073&utm_campaign=profiles_installer",
    company_name: "Rieger & Kraft Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d7704a69635.jpg?v=1",
    address: "Holzinger Hauptstr. 32, 91781 Weißenburg/ Holzingen",
    telephone: "+49 9141 923239",
    website: "http://solar-rieger-kraft.de",
    email: "kontakt@solar-rieger-kraft.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",Steca Elektronik GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sk-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1807&utm_campaign=profiles_installer",
    company_name: "S+K GmbH Haus- und Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1807n.gif?v=1",
    address: "Robert-Bosch-Str1, 74585 Rot am See",
    telephone: "+49 79 55388800",
    website: "http://www.sk-haustechnik.de",
    email: "kontakt@sk-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sirius?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1753&utm_campaign=profiles_installer",
    company_name: "Sirius - Solar Energie Systeme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d27de63e102.jpg?v=1",
    address: "Zum Forst 100, 24145 Kiel",
    telephone: "+49 431 714121",
    website: "http://www.sirius-solar.de",
    email: "kontakt@sirius-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kayser-holding?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93620&utm_campaign=profiles_installer",
    company_name: "S. Kayser Holding GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1de67e36a5.jpg?v=1",
    address: "Kellnersbergstr. 2, 99438 Bad Berka",
    telephone: "+49 3 645832962",
    website: "http://www.silvio-kayser.de",
    email: "kontakt@silvio-kayser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sg-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75239&utm_campaign=profiles_installer",
    company_name: "SG Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be71f436010.jpg?v=1",
    address: "Aubachweg 12, 91338 Igensdorf",
    telephone: "+49 9192 998591",
    website: "http://www.sg-solar.de",
    email: "kontakt@sg-solar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,JA Solar Holdings Co., Ltd.,,,Trina Solar Limited,,,Astronergy Co., Ltd. (Chint Solar),,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schubert-kuhn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93542&utm_campaign=profiles_installer",
    company_name: "Schubert + Kühn GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c07f3281255.jpg?v=1",
    address: "Gewerbering 3, 91629 Weihenzell",
    telephone: "+49 9802 952580",
    website: "http://www.schubert-kuehn.de",
    email: "kontakt@schubert-kuehn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/otto-schatte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94830&utm_campaign=profiles_installer",
    company_name: "Otto Schatte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df10abd56ad.jpg?v=1",
    address: "Hochstraße 82, 23554 Lübeck",
    telephone: "+49 451 4088055",
    website: "http://www.schatte.de",
    email: "kontakt@schatte.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/burger-solarkraftwerke-rosengarten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92140&utm_campaign=profiles_installer",
    company_name: "Bürger-Solarkraftwerke Rosengarten eG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3b8932d2d9.jpg?v=1",
    address: "Postfach 1132, 21231 Buchholz",
    telephone: "",
    website: "http://www.buergersolarkraftwerke-rosengarten.de",
    email: "kontakt@rosengartenenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pusch-co-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=570&utm_campaign=profiles_installer",
    company_name: "Pusch & Co Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11014.gif?v=1",
    address: "Ludwigstr. 20, 06110, Halle an der Saale",
    telephone: "+49 345 133550",
    website: "http://www.pusch-halle.de",
    email: "kontakt@pusch-halle.de",
    "Installaliert seit": 1997,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pure-energien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93977&utm_campaign=profiles_installer",
    company_name: "Pure Energien",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9a8ec88ed5.jpg?v=1",
    address: "Nicolaus-Otto-Ring 9, 85098 Großmehring",
    telephone: "+49 8407 3189990",
    website: "https://www.pure-energien.de",
    email: "kontakt@pure-energien.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,AXITEC Energy GmbH & Co. KG,,,Jinko Solar Holding Co., Ltd.,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/oko-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1776&utm_campaign=profiles_installer",
    company_name: "ÖKO-Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1776n.gif?v=1",
    address: "Fasanenweg 7, 63694, Limeshain-Himbach",
    telephone: "+49 6048 950992",
    website: "http://www.oeko-energie.de",
    email: "kontakt@oeko-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,SunWare Solartechnik Produktions GmbH und Co. KG,,,IBC Solar AG,,,SunPower Corp.,,,Kyocera Corporation,,,Mitsubishi Heavy Industries Ltd.,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,S-Energy Co., Ltd.,,,Ascent Solar Technologies Inc.,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,,,Apollo New Energy Co.,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Studer Innotec,,,Fronius International GmbH,,,Victron Energy BV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nordic-sun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30593&utm_campaign=profiles_installer",
    company_name: "Nordic Sun GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e89d63742b9.jpg?v=1",
    address: "Gewerbestrasse 3a, 18230, Biendorf",
    telephone: "+49 38294 15882",
    website: "http://www.nordic-sun.eu",
    email: "kontakt@nordic-sun.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nienaber-bad-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93483&utm_campaign=profiles_installer",
    company_name: "Nienaber Bad+Heizung e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf64d987b9f.jpg?v=1",
    address: "Robert-Bosch-Straße 30, 46397 Bocholt",
    telephone: "+49 2871 225522",
    website: "http://www.nienaber-haustechnik.de",
    email: "kontakt@nienaber-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/niekrawietz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81569&utm_campaign=profiles_installer",
    company_name: "Niekrawietz Aufzugtechnik & Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5884790721020.jpg?v=1",
    address: "Jahnstraße 21, 92696 Flossenbürg",
    telephone: "+49 9603 9030020",
    website: "http://www.niekrawietz.de",
    email: "kontakt@niekrawietz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,Power-One Micro Systems Pvt. Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-neubert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10821&utm_campaign=profiles_installer",
    company_name: "Dachdecker Enrico Neubert",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576746da5d99a.jpg?v=1",
    address: "Babisnauer Straße 14, 01731 Kreischa-Bärenklause",
    telephone: "+49 35 20621224",
    website: "http://www.neubert-dach.de",
    email: "kontakt@neubert-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mercom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82742&utm_campaign=profiles_installer",
    company_name: "MerCom GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b3c6ecaa0ba.jpg?v=1",
    address: "06526 Sangerhausen",
    telephone: "+49 17 18546598",
    website: "http://mercom.de",
    email: "kontakt@mercom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mayer-schweig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93261&utm_campaign=profiles_installer",
    company_name: "Mayer & Schweig GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9ff9e594c6.jpg?v=1",
    address: "Große Heide 11, 66399 Mandelbachtal",
    telephone: "+49 6803 1627",
    website: "http://www.mayer-schweig.de",
    email: "kontakt@mayer-schweig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmitz-marc-heizung-sanitar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1609&utm_campaign=profiles_installer",
    company_name: "Schmitz Marc Heizung Sanitär Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c11450223a5.gif?v=1",
    address: "Von-Hünefeld-Str. 91, 50829 Köln",
    telephone: "+49 221 4759340",
    website: "http://www.marcschmitz.de",
    email: "kontakt@marcschmitz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/leifer-hamann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102229&utm_campaign=profiles_installer",
    company_name: "Leifer & Hamann GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1f2999c07a.jpg?v=1",
    address: "Zur Dringe 2, 33014 Bad Driburg-Dringenberg",
    telephone: "+49 5259 930080",
    website: "https://www.leifer-hamann.de",
    email: "kontakt@leifer-hamann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,Aleo Solar GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Sunways LLC,,,Photowatt,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/christian-johannes-kaupp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93883&utm_campaign=profiles_installer",
    company_name: "Christian + Johannes Kaupp GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c862ff55f12.jpg?v=1",
    address: "Dr. Georg Spohn Straße 27, 89143 Blaubeuren",
    telephone: "+49 7344 6490",
    website: "https://www.kaupp-zimmerei.de",
    email: "kontakt@kaupp-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jm-projektinvest?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93756&utm_campaign=profiles_installer",
    company_name: "JM ProjektInvest GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c381be797d3.jpg?v=1",
    address: "Niels-Bohr-Straße 10b, D-39106 Magdeburg",
    telephone: "+49 391 5556070",
    website: "http://www.jm-projektinvest.com",
    email: "kontakt@jm-projektinvest.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,REFU Elektronik GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 8042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-m-jansen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92827&utm_campaign=profiles_installer",
    company_name: "S+M Jansen GmbH Meister Der Elemente",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0b19e1ad12.jpg?v=1",
    address: "Lehmkuhlenweg 2, 41065 Mönchengladbach",
    telephone: "+49 2161 541822",
    website: "http://jansen-moenchengladbach-mde.de",
    email: "kontakt@jansen-moenchengladbach-mde.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kratos-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2011&utm_campaign=profiles_installer",
    company_name: "iKratos Solar und Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5188682f43126.gif?v=1",
    address: "91367 Weißenohe, Bahnhofstraße 1",
    telephone: "+49 9192 992800",
    website: "http://www.ikratos.de",
    email: "kontakt@ikratos.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-cambeis?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93961&utm_campaign=profiles_installer",
    company_name: "Holzbau Cambeis GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8c792735c7.jpg?v=1",
    address: "Kitzinger Straße 17, 97318 Kaltensondheim",
    telephone: "+49 9321 5567",
    website: "http://holzbau-cambeis.de",
    email: "kontakt@holzbau-cambeis.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-hass?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94825&utm_campaign=profiles_installer",
    company_name: "Haustechnik Hass GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df0ed76504d.jpg?v=1",
    address: "Bergstraße 19, 91338 Igensdorf-Pommer",
    telephone: "+49 9192 7800",
    website: "http://www.haustechnik-hass.de",
    email: "kontakt@haustechnik-hass.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gsg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102221&utm_campaign=profiles_installer",
    company_name: "GSG Ingenieurbüro und Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1d90855cff.jpg?v=1",
    address: "Bautzener Straße 40, D-02956 Rietschen",
    telephone: "+49 35772 4220",
    website: "http://www.gsg-rietschen.de",
    email: "kontakt@gsg-rietschen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Kyocera Corporation,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,,,Sunways LLC,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-h-solarprojekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75607&utm_campaign=profiles_installer",
    company_name: "H+H Solarprojekt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8ec68b5d62.jpg?v=1",
    address: "Pfarrstraße 29, 08233 Treuen",
    telephone: "+49 374 68769946",
    website: "http://www.go-solar.eu",
    email: "kontakt@go-solar.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gb-kohler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124367&utm_campaign=profiles_installer",
    company_name: "GB Köhler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb42bf13bcbe.png?v=1",
    address: "Gewerbepark 25, 87477 Sulzberg",
    telephone: "+49 831 52735077",
    website: "http://gbkoehler.de/",
    email: "kontakt@gbkoehler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energy-systems-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92377&utm_campaign=profiles_installer",
    company_name: "Energy Systems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a75dfdab0f5.jpg?v=1",
    address: "Magdeburger Straße 250, 39218, Schönebeck, Elbe",
    telephone: "+49 39 28464330",
    website: "https://www.es-sbk.de",
    email: "kontakt@es-sbk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/entecsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92401&utm_campaign=profiles_installer",
    company_name: "EntecSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a77fbc83ca0.jpg?v=1",
    address: "Schwerter Straße 6, 58730, Fröndenberg",
    telephone: "+49 2378 8908740",
    website: "http://www.entecsolar.de",
    email: "kontakt@entecsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enbw?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94593&utm_campaign=profiles_installer",
    company_name: "EnBW Energie Baden-Württemberg AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7942907b3.png?v=1",
    address: "Durlacher Allee 93, 76131 Karlsruhe",
    telephone: "+49 721 6300",
    website: "https://www.enbw.com",
    email: "kontakt@enbw.com",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-zobel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93645&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Zobel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1ec64b1ca8.jpg?v=1",
    address: "Kurzestr. 9, 30853 Langenhagen",
    telephone: "+49 511 98423223",
    website: "http://www.elektro-zobel.de",
    email: "kontakt@elektro-zobel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-stober?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93646&utm_campaign=profiles_installer",
    company_name: "Elektro Stöber",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1ed19aa052.jpg?v=1",
    address: "Hauptstraße 22, 84079 Gündlkofen",
    telephone: "+49 8765 920026",
    website: "http://www.elektro-stoeber.de",
    email: "kontakt@elektro-stoeber.de",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-pister?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1895&utm_campaign=profiles_installer",
    company_name: "Elektro Pister GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50d031a65a19d.gif?v=1",
    address: "Traminerweg 3, 76835 Rhodt",
    telephone: "+49 6323 937300",
    website: "http://www.elektro-pister.de",
    email: "kontakt@elektro-pister.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-ottmann-vertrieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102250&utm_campaign=profiles_installer",
    company_name: "Elektro Ottmann Vertrieb GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab218203d3fc.jpg?v=1",
    address: "Gewerbepark Hügelmühle 3, 91174 Spalt",
    telephone: "+49 9175 908090",
    website: "https://energiespeicher-ottmann.de",
    email: "kontakt@elektro-ottmann.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-jager?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29791&utm_campaign=profiles_installer",
    company_name: "Elektro Jäger",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bee5a5b52987.png?v=1",
    address: "Waldseer-Str.16, 88410 Bad Wurzach, Haidgau",
    telephone: "+49 7564 3606",
    website: "http://www.elektro-jaeger-haidgau.de",
    email: "kontakt@elektro-jaeger-haidgau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-heber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94695&utm_campaign=profiles_installer",
    company_name: "Elektro Heber",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddcf3aaec82.png?v=1",
    address: "Fürstenstr. 76, 09130 Chemnitz, Sachsen",
    telephone: "+49 371 301707",
    website: "http://elektro-heber.de",
    email: "kontakt@elektro-heber.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/johannes-espel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103521&utm_campaign=profiles_installer",
    company_name: "Johannes Espel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebbb252b182.jpg?v=1",
    address: "Vehrter Landstr. 29, 49134 Wallenhorst",
    telephone: "+49 5407 819043",
    website: "https://www.elektro-espel.de",
    email: "kontakt@elektro-espel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/einecke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81649&utm_campaign=profiles_installer",
    company_name: "Einecke Gas, Heating and Sanitary Installation GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58882079e0ff2.jpg?v=1",
    address: "Odernheimer Straße 7, 12559 Berlin",
    telephone: "+49 30 659 8352",
    website: "http://www.einecke-berlin.de",
    email: "kontakt@einecke-berlin.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachvermittlungszentrale?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=89856&utm_campaign=profiles_installer",
    company_name: "Dachvermittlungszentrale",
    logo_url: "",
    address: "Lürmannstraße 45, 49076 Osnabrück",
    telephone: "+49 5418601201",
    website: "http://www.dachvermittlungs-zentrale.de",
    email: "kontakt@dachvermittlungs-zentrale.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-michael-koster?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94450&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Michael Köster",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc380985427.jpg?v=1",
    address: "Holzkoppel 2, D-23560 Lübeck",
    telephone: "+49 451 8070164",
    website: "http://www.dachdeckereikoester.de",
    email: "kontakt@dachdeckereikoester.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/andreas-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93995&utm_campaign=profiles_installer",
    company_name: "Andreas Schmidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b4b35aec1.jpg?v=1",
    address: "Zur Harth 3-5, 04442 Zwenkau",
    telephone: "+49 34203 32291",
    website: "http://www.dachdeckerei-schmidt-zwenkau.de",
    email: "kontakt@dachdeckerei-schmidt-zwenkau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schaller-dachdecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94452&utm_campaign=profiles_installer",
    company_name: "Schaller Dachdecker GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc392be4f03.jpg?v=1",
    address: "Mainzer Straße 46a, 55291 Saulheim",
    telephone: "+49 6732 930499",
    website: "http://dachdecker-schaller.de",
    email: "kontakt@dachdecker-schaller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmitd-partner-dachbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10714&utm_campaign=profiles_installer",
    company_name: "Dachbau Schmidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe42760a4cde.png?v=1",
    address: "Tellstraße 1, 14532, Stahnsdorf",
    telephone: "+49 3329 60610",
    website: "http://www.dachbau-schmidt.de",
    email: "kontakt@dachbau-schmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernd-caspers?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92080&utm_campaign=profiles_installer",
    company_name: "Bernd Caspers GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fc81d21384.jpg?v=1",
    address: "Trompeterallee 92, 41189 Mönchengladbach",
    telephone: "+49 2166 958205",
    website: "http://www.caspers-gmbh.de",
    email: "kontakt@caspers-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bulter-meister-der-elemente?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92067&utm_campaign=profiles_installer",
    company_name: "Bülter - Meister Der Elemente",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fbdd6e56d0.jpg?v=1",
    address: "Alst 119, 41379 Brüggen",
    telephone: "+49 2157 871927",
    website: "http://buelter-brueggen-mde.de",
    email: "kontakt@buelter-brueggen-mde.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bohl-rothe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9964&utm_campaign=profiles_installer",
    company_name: "Meisterbetrieb Jörg Böhl & Roland Rothe GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/9964n.gif?v=1",
    address: "Kiefholzstr. 6, 12435 Berlin",
    telephone: "+49 30 6765019",
    website: "http://www.boehl-rothe.de",
    email: "kontakt@boehl-rothe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pichler-e-tech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106002&utm_campaign=profiles_installer",
    company_name: "Pichler E-Tech",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b457c1c13ac7.png?v=1",
    address: "Auf der Gröb 6, 83064 Raubling",
    telephone: "+49 8035 9637770",
    website: "http://www.pichler-etech.de",
    email: "kontakt@pichler-etech.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mikosa?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22253&utm_campaign=profiles_installer",
    company_name: "Mikosa Michael Konrad e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/593a0e4b00c77.jpg?v=1",
    address: "Im Mittleren Gottwill 26, 66117, Saarbrücken",
    telephone: "+49 0681 48160",
    website: "http://www.mikosa.de",
    email: "konrad@mikosa.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/spie-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102245&utm_campaign=profiles_installer",
    company_name: "SPIE Deutschland & Zentraleuropa GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/603819a279e60.png?v=1",
    address: "Balcke-Dürr-Allee 7, 40882 Ratingen",
    telephone: "+49 2102 37080",
    website: "https://spie.de",
    email: "kommunikation@spie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rhenag-rheinische-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107909&utm_campaign=profiles_installer",
    company_name: "Rhenag Rheinische Energie AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88b058497ea.jpg?v=1",
    address: "Postfach 51 09 20, 50945 Köln",
    telephone: "+49 221 937310",
    website: "https://www.rhenag.de",
    email: "koeln@rhenag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/martin-kniebel-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1561&utm_campaign=profiles_installer",
    company_name: "Martin Kniebel Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1561n.gif?v=1",
    address: "Enge Straße 1, 38272 Burgdorf OT Westerlinde",
    telephone: "+49 534 7941875",
    website: "https://salzgitter-solar.jimdo.com",
    email: "kniebel.elektro@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kluge-photovoltaikanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94833&utm_campaign=profiles_installer",
    company_name: "Kluge Photovoltaikanlagen GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df11e3d730c.jpg?v=1",
    address: "Liemer Str. 36, 32791 Lage",
    telephone: "+49 5232 8588845",
    website: "https://www.kluge-solar.de",
    email: "kluge-solar@arcor.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kleinschmitt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92227&utm_campaign=profiles_installer",
    company_name: "Elektro Kleinschmitt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4eaf5df48f.jpg?v=1",
    address: "Bahnhofstr. 60, 63743 Aschaffenburg",
    telephone: "+49 6028 400276",
    website: "http://elektro-kleinschmitt.de",
    email: "kleinschmitt.s@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ad-fontes-kiel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91914&utm_campaign=profiles_installer",
    company_name: "Ad Fontes Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ffe1199dce5f.png?v=1",
    address: "Koldingstraße 8, 24105 Kiel",
    telephone: "+49 431 8950088",
    website: "http://www.adfontes-kiel.de",
    email: "kiel@adfontes.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,IBC Solar AG,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kelvin-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1883&utm_campaign=profiles_installer",
    company_name: "Kelvin-Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6090770a4b171.png?v=1",
    address: "Waisenhausgasse 2-4, 50676 Köln",
    telephone: "+49 221 4062233",
    website: "http://www.kelvin-solartechnik.de",
    email: "Kelvin-Solartechnik@web.de",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/keller-tutzing?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92484&utm_campaign=profiles_installer",
    company_name: "Keller Heizung Sanitär und Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a904a42d775.jpg?v=1",
    address: "Greinwaldstraße 11, 82327 Tutzing",
    telephone: "+49 8158 1566",
    website: "http://www.heizung-sanitaer-solaranlagen.de",
    email: "KellerTutzing@aol.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-karwinski?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10476&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Konrad Karwinski von Karwin",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd11868a1e6b.png?v=1",
    address: "Im Winkel 27, 08294, Lößnitz",
    telephone: "+49 3771 32500",
    website: "http://www.dach-karwinski.de",
    email: "karwinski-von-karwin@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/karsten-herre?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83180&utm_campaign=profiles_installer",
    company_name: "Karsten Herre",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be1df8d91c1.jpg?v=1",
    address: "Karlsbader Weg 55, 74523 Schwäbisch Hall",
    telephone: "+49 791 95663436",
    website: "http://herre.solar",
    email: "k.herre@herre.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/raach-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81642&utm_campaign=profiles_installer",
    company_name: "Raach Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58881216b93d0.jpg?v=1",
    address: "Im Stellwinkel 1, 88451 Dettingen an der Iller",
    telephone: "+49 7354 9372783",
    website: "http://raachsolar.com",
    email: "juergen.raach@raachsolar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Phocos AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/seiler-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103523&utm_campaign=profiles_installer",
    company_name: "Seiler Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebbc27a0956.jpg?v=1",
    address: "Franz-Schubert-Str. 11, 84518, Garching-Wald, Alz",
    telephone: "+49 8634 8952",
    website: "http://www.elektro-seiler.de",
    email: "jseiler@elektro-seiler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jost-energie-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102685&utm_campaign=profiles_installer",
    company_name: "Jost Energie Technik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac98fb22a808.jpg?v=1",
    address: "Roggenkamp 9, 22941 Bargteheide",
    telephone: "+49 4532 2745244",
    website: "http://www.jost-energie-technik.de",
    email: "jost@jost-energie-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarnet-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124487&utm_campaign=profiles_installer",
    company_name: "Solarnet GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb8bbe65a3f7.jpg?v=1",
    address: "Goldbeckstrasse 7, 69493 Hirschberg a.d. Bergstrasse",
    telephone: "+49 6201 7103099",
    website: "http://solarnet.energy",
    email: "Joachim.goldbeck@solarnet.energy",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Chili, Deutschland, Großbritannien, Mexiko, Thailand,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Canadian Solar Inc.,,,S-Energy Co., Ltd.,,,ReneSola Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fimer S.p.A.,,,Schneider Electric SA,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/j-k-solarenergie-thuringen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75073&utm_campaign=profiles_installer",
    company_name: "J&K Solarenergie Thüringen UG & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bbeba692802.jpg?v=1",
    address: "Inselsbergstraße 95a, 99891 Bad Tabarz",
    telephone: "+49 36259 370002",
    website: "http://www.solarstrom24.net",
    email: "jacob@solarstrom24.net",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sperling-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10317&utm_campaign=profiles_installer",
    company_name: "Sperling Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/533a7e0b4c376.jpg?v=1",
    address: "Leikenberg 22, 66839, Schmelz",
    telephone: "+49 6887 304544",
    website: "http://www.sperling-solar.de",
    email: "j.sperling@sperling-solar.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/p-s-rode?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=628&utm_campaign=profiles_installer",
    company_name: "P.S. Rode GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11077.gif?v=1",
    address: "Hogrevestrasse 39, D-29223, Celle",
    telephone: "+49 5141 931400",
    website: "http://www.p-s-rode.de",
    email: "j.rode@ps-rode.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,First Solar, Inc.,,,NexPower Technology Corporation,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/giesecke-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102687&utm_campaign=profiles_installer",
    company_name: "Giesecke Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac992a2afbc1.jpg?v=1",
    address: "Wiesengrund 32, 49509 Recke",
    telephone: "+49 5453 3319944",
    website: "http://www.giesecke-energy.de",
    email: "j.giesecke@giesecke-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gune-pili?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6635&utm_campaign=profiles_installer",
    company_name: "Güneş Pili Ltd. Sti.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d89ad23bd08d.jpg?v=1",
    address: "Sakarya, Şenyurt Sk., 42090, Selçuklu, Konya",
    telephone: "+90 332 248046465",
    website: "http://www.gunes-pili.com",
    email: "irtibat@gunes-pili.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-latzel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81555&utm_campaign=profiles_installer",
    company_name: "Elektro Latzel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58846a08bcda8.jpg?v=1",
    address: "Leimitzer Straße 67, 95028 Hof",
    telephone: "+49 9281 40522",
    website: "http://www.elektro-latzel-hof.de",
    email: "installation@elektro-latzel-hof.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Eaton Corporation,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wegatech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64391&utm_campaign=profiles_installer",
    company_name: "Wegatech Greenergy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6053569499fdd.PNG?v=1",
    address: "Birketweg 31, 80639 München",
    telephone: "+49 89 122287170",
    website: "http://www.wegatech.de",
    email: "INQUIRY@WEGATECH.DE",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,AXITEC Energy GmbH & Co. KG,,,Hyundai Energy Solutions,,,Luxor Solar GmbH,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lamilux?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82488&utm_campaign=profiles_installer",
    company_name: "Lamilux Heinrich Strunz Holding GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58acef14bd97a.jpg?v=1",
    address: "Zehstraße 2, 95111 Rehau",
    telephone: "+49 9283 5950",
    website: "http://www.lamilux.com",
    email: "information@lamilux.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zwickau-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74695&utm_campaign=profiles_installer",
    company_name: "Zwickau Solar Gert Kulhanek",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a6790792127.PNG?v=1",
    address: "Walther Rathenau Str. 23, 08058 Zwickau",
    telephone: "+49 375 88366170",
    website: "http://zwickau-solar.eu",
    email: "info@zwickau-solar.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zwick-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93912&utm_campaign=profiles_installer",
    company_name: "Zwick-Holzbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c879e6a0be0.jpg?v=1",
    address: "Kirchbühlstraße 16+18, 78166 Donaueschingen",
    telephone: "+49 7705 5478",
    website: "http://www.zwick-holzbau.de",
    email: "info@zwick-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Day4 Europe Srl,,,Heckert Solar GmbH,,,Algatec Handels UG & Co. KG,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zsd?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7374&utm_campaign=profiles_installer",
    company_name: "ZSD Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f7731714b661.png?v=1",
    address: "Pliniusstraße 8, 48488 Emsbüren",
    telephone: "+49 5903 932020",
    website: "http://www.zsd.solar",
    email: "info@zsd.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,München Energieprodukte GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82023&utm_campaign=profiles_installer",
    company_name: "Zolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589d3face0a93.jpg?v=1",
    address: "Oranienstraße 185, 10999 Berlin",
    telephone: "+49 800 2300600",
    website: "http://www.zolar.de",
    email: "info@zolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,JA Solar Holdings Co., Ltd.,,,Trina Solar Limited,,,Jinko Solar Holding Co., Ltd.,,,LONGi Solar Technology Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zk-elektronik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81802&utm_campaign=profiles_installer",
    company_name: "ZK Elektronik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58957b465c69d.jpg?v=1",
    address: "Kaltenbrunnweg 8, 97657 Langenleiten",
    telephone: "+49 9701 8313",
    website: "http://www.zk-electronic-t.bplaced.net",
    email: "info@zk-electronic.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zipperlein-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11011&utm_campaign=profiles_installer",
    company_name: "Uwe Zipperlein Holzbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11011n.gif?v=1",
    address: "Alte Stadt 13, 74219, Möckmühl",
    telephone: "+49 6298 1827",
    website: "http://www.zipperlein-holzbau.de",
    email: "info@zipperlein-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-goldhagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93972&utm_campaign=profiles_installer",
    company_name: "Holzbau Goldhagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9a5b0e8ab0.jpg?v=1",
    address: "Bergblick 18 & Industriestraße 26, 37115 Duderstadt",
    telephone: "+49 5527 849389",
    website: "http://www.zimmereiteam.de",
    email: "info@zimmereiteam.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-braun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10793&utm_campaign=profiles_installer",
    company_name: "Zimmerei Nikolaus Braun",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10793n.gif?v=1",
    address: "Längentalstraße 20, 83646, Wackersberg",
    telephone: "+49 8042 9710",
    website: "http://www.zimmereibraun.de",
    email: "info@zimmereibraun.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-bottcher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117003&utm_campaign=profiles_installer",
    company_name: "Zimmerei Böttcher",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ff9da47288.png?v=1",
    address: "Hauptstraße 27, 07778 Lehesten",
    telephone: "+49 36425 22236",
    website: "http://www.zimmerei-boettcher.com",
    email: "info@zimmereiboettcher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-tofflinger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93631&utm_campaign=profiles_installer",
    company_name: "Zimmerei Töfflinger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f60ce09a1a60.PNG?v=1",
    address: "Itzehoer Straße 41c, 24594 Hohenwestedt",
    telephone: "+49 4871 761915",
    website: "https://www.zimmerei-toefflinger.de",
    email: "info@zimmerei-toefflinger. de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-schondienst?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10782&utm_campaign=profiles_installer",
    company_name: "Zimmerei Schöndienst",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe44721c870f.png?v=1",
    address: "Hegaustrasse 31, 78176 Blumberg-Kommingen",
    telephone: "+49 7736 92380",
    website: "http://www.zimmerei-schoendienst.de",
    email: "info@zimmerei-schoendienst.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-markus-schnitger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94966&utm_campaign=profiles_installer",
    company_name: "Zimmerei Markus Schnitger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e06a97bb8e5.jpg?v=1",
    address: "Saterlandstr. 26, 26689 Apen",
    telephone: "+49 4489 941160",
    website: "http://www.zimmerei-schnitger.de",
    email: "info@zimmerei-schnitger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-schnelle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116899&utm_campaign=profiles_installer",
    company_name: "Zimmerei Schnelle",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ea3f2e9c55.png?v=1",
    address: "Attelner Berg 5, 33165 Lichtenau",
    telephone: "+49 5295 997133",
    website: "http://www.zimmerei-schnelle.de",
    email: "info@zimmerei-schnelle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sauter-zimmerei-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94970&utm_campaign=profiles_installer",
    company_name: "Sauter Zimmerei-Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e06cd496933.jpg?v=1",
    address: "Streichener Straße 51, 72336 Balingen",
    telephone: "+49 7433 20843",
    website: "http://zimmerei-sauter.de",
    email: "info@zimmerei-sauter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-florian-meidhof?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117004&utm_campaign=profiles_installer",
    company_name: "Zimmerei Florian Meidhof",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ffa73cd0e1.png?v=1",
    address: "Schmelzweg 67a, 63599 Biebergemünd-Bieber",
    telephone: "+49 6050 912770",
    website: "https://zimmerei-meidhof.de",
    email: "info@zimmerei-meidhof.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-kaller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10785&utm_campaign=profiles_installer",
    company_name: "Zimmerei-Kaller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5767463c53e9b.jpg?v=1",
    address: "Maschweg 73, 49324, Melle",
    telephone: "+49 5422 703423",
    website: "http://www.zimmerei-kaller.de",
    email: "info@zimmerei-kaller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-jestadt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93951&utm_campaign=profiles_installer",
    company_name: "Zimmerei Jestädt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8bab133f09.jpg?v=1",
    address: "Birkenweg 3, 36093 Künzell",
    telephone: "+49 661 9339789",
    website: "http://www.zimmerei-jestaedt.de",
    email: "info@zimmerei-jestaedt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-hug?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94867&utm_campaign=profiles_installer",
    company_name: "Zimmerei Hug",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df30f422851.png?v=1",
    address: "In der Rohrmatt 15, 77955 Ettenheim-Münchweier",
    telephone: "+49 7822 447968",
    website: "http://www.zimmerei-hug.de",
    email: "info@zimmerei-hug.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hinkofer-wolf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93861&utm_campaign=profiles_installer",
    company_name: "Hinkofer & Wolf UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/600aef678375b.png?v=1",
    address: "Haidsberg 4, 94255 Böbrach",
    telephone: "+49 9923 3083",
    website: "http://www.zimmerei-hinkoferwolf.de",
    email: "info@zimmerei-hinkoferwolf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-haring?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10774&utm_campaign=profiles_installer",
    company_name: "Zimmerei Häring GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10774n.gif?v=1",
    address: "Rosenweg 13, 87634 Obergünzburg",
    telephone: "+49 8372 596",
    website: "http://www.zimmerei-haering.de",
    email: "info@zimmerei-haering.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-gutwin?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94973&utm_campaign=profiles_installer",
    company_name: "Zimmerei u. Dachdeckerei Gutwin",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601343695cdca.png?v=1",
    address: "Zum Westbach 3, 59494 Soest-Röllingsen",
    telephone: "+49 2928 9709687",
    website: "http://www.zimmerei-gutwin.de",
    email: "info@zimmerei-gutwin.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-christian-cordsen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93987&utm_campaign=profiles_installer",
    company_name: "Zimmerei Christian Cordsen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b00a09d21.jpg?v=1",
    address: "Sörupholzer Str. 5, 24966 Sörup",
    telephone: "+49 151 16702121",
    website: "https://zimmerei-cc.de",
    email: "info@zimmerei-ccpunktde",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/beneke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116892&utm_campaign=profiles_installer",
    company_name: "Beneke GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ea04e05917.png?v=1",
    address: "Hohe Luft 1a · 27404 Heeslingen",
    telephone: "+49 4281 7173710",
    website: "https://www.handwerk-beneke.de",
    email: "info@zimmerei-beneke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nils-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94963&utm_campaign=profiles_installer",
    company_name: "Nils Müller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e067f6631fe.jpg?v=1",
    address: "Obere Str. 31, 32839 Steinheim",
    telephone: "+49 5233 9537455",
    website: "http://zimmerei-bedachungen.de",
    email: "info@zimmerei-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-baur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116898&utm_campaign=profiles_installer",
    company_name: "Zimmerei Baur GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ea35605609.png?v=1",
    address: "Beim Scherling 3, 89182 Bernstadt",
    telephone: "+49 7348 95770",
    website: "https://www.zimmerei-baur.com",
    email: "info@zimmerei-baur.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-baumgartner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10803&utm_campaign=profiles_installer",
    company_name: "Zimmerei Baumgartner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10803n.gif?v=1",
    address: "Kleinherrischwand 13, D-79737 Herrischried",
    telephone: "+49 7764 9335959",
    website: "http://www.zimmerei-baumgartner.de",
    email: "info@zimmerei-baumgartner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-arnold?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93955&utm_campaign=profiles_installer",
    company_name: "Zimmerei Arnold",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8bff39fd22.jpg?v=1",
    address: "Bahnhofstr. 3, 71282 Hemmingen",
    telephone: "+49 7150 6161",
    website: "https://www.zimmerei-arnold.de",
    email: "info@zimmerei-arnold.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-arne-weisner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94961&utm_campaign=profiles_installer",
    company_name: "Zimmerei Arne Weisner GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e065eb6fcd0.jpg?v=1",
    address: "Haverkamp 22, 24238 Selent",
    telephone: "+49 4384 5928148",
    website: "http://zimmerei-arne-weisner.de",
    email: "info@zimmerei-arne-weisner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmer-weber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102369&utm_campaign=profiles_installer",
    company_name: "Zimmer & Weber GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9bee7315a8.png?v=1",
    address: "Talstraße 5-6, 66679 Losheim am See",
    telephone: "+49 6872 91858",
    website: "http://zimmer-weber-gmbh.de",
    email: "info@zimmer-weber-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",CREATON GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ziethmann-anlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93801&utm_campaign=profiles_installer",
    company_name: "Ziethmann Anlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c48b6d24873.jpg?v=1",
    address: "Fümmelser Straße 41, 38304 Wolfenbüttel",
    telephone: "+49 5331 949600",
    website: "http://www.ziethmann.de",
    email: "info@ziethmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zetinsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=115172&utm_campaign=profiles_installer",
    company_name: "Zetin Solar and Investment Company Limited",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5cf875d1115b8.png?v=1",
    address: "Airport Road, Juba",
    telephone: "+211 917 171714",
    website: "http://www.zetinsolar.com",
    email: "info@zetinsolar.com",
    "Installaliert seit": 2016,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Uganda, Südsudan,",
    "Lieferanten von Solarmodulen": ",Solon International,,,FuturaSun srl,",
    "Wechselrichter-Lieferanten": ",Steca Elektronik GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung, Training",
    Muttergesellschaft: "",
    "Last Update": 8102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zeller-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102241&utm_campaign=profiles_installer",
    company_name: "Zeller Gebäudetechnik GmbH & Co.KG.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab20df13763b.jpg?v=1",
    address: "Richard-Hirschmann Str. 47, 73728 Esslingen",
    telephone: "+49 711 3154600",
    website: "http://www.zeller-gebaeudetechnik.de",
    email: "info@zeller-gebaeudetechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/yokk-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=36531&utm_campaign=profiles_installer",
    company_name: "Yokk Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/521438e882ba9.jpg?v=1",
    address: "Grassistr. 20, 04107 Leipzig",
    telephone: "+49 341 392908680",
    website: "http://yokk-solar.com",
    email: "info@yokk-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/yandalux?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8426&utm_campaign=profiles_installer",
    company_name: "Yandalux Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c80934648470.jpg?v=1",
    address: "Schellerdamm 4, 21079, Hamburg",
    telephone: "+49 40 882159550",
    website: "http://www.yandalux.com",
    email: "info@yandalux.com",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Afrika,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/shp-europe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102779&utm_campaign=profiles_installer",
    company_name: "Sustainable Heat & Power Europe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5acb2c50561fb.jpg?v=1",
    address: "Dorfstr. 22, 18292 Krakow am See OT Groß Grabow",
    telephone: "+49 38457 519590",
    website: "http://www.shp-europe.com",
    email: "info@xn--selbst-stndig-wachsen-e2b.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/xetma-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=26453&utm_campaign=profiles_installer",
    company_name: "Xetma Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/518b232964194.jpg?v=1",
    address: "Basteiweg 6, DE-08280 Aue",
    telephone: "+49 3771 2930",
    website: "http://xetma-solar.com",
    email: "info@xetma-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,,,Danfoss A/S,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wws-wartung-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93259&utm_campaign=profiles_installer",
    company_name: "WWS Wartung & Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9fe9eafc20.jpg?v=1",
    address: "Chemnitzer Straße 24, 67433 Neustadt an der Weinstraße",
    telephone: "+49 6321 400780",
    website: "http://www.wws-neustadt.de",
    email: "info@wws-neustadt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Soluxtec GmbH,,,CS Wismar GmbH Sonnenstromfabrik,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wwf-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13807&utm_campaign=profiles_installer",
    company_name: "WWF Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/518c63dd1a4af.bmp?v=1",
    address: "Mühlenstraße, 16227 Eberswalde",
    telephone: "+49 3334 552900",
    website: "http://www.wwfsolar.de",
    email: "info@wwfsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wve?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1972&utm_campaign=profiles_installer",
    company_name: "WVE GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1972n.gif?v=1",
    address: "Blechhammerweg 50, 67659 Kaiserslautern",
    telephone: "+49 631 7500573",
    website: "http://www.wve-kl.de",
    email: "info@wve-kl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heinrich-wulfert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94408&utm_campaign=profiles_installer",
    company_name: "Heinrich Wulfert GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc180fe8a3e.png?v=1",
    address: "Hasselbach 44-50, D-58119 Hagen-Hohenlimburg",
    telephone: "+49 2334 54505",
    website: "http://www.wulfdach.de",
    email: "info@wulfdach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Antaris Solar GmbH & Co. KG,,,Alwitra GmbH & Co.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wurth-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=19735&utm_campaign=profiles_installer",
    company_name: "Adolf Würth GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/19735n.gif?v=1",
    address: "Reinhold-Würth-Strasse 12-17, Künzelsau, 74653",
    telephone: "+49 7940 151000",
    website: "http://wuerth.com/",
    email: "info@wuerth.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-wurstlein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94186&utm_campaign=profiles_installer",
    company_name: "Elektro-Würstlein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ccc00755e27.jpg?v=1",
    address: "Sudetenstr. 16, 96145 Seßlach",
    telephone: "+49 9569 272",
    website: "https://www.würstlein.de",
    email: "info@wuerstlein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-h-wuhrmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58179&utm_campaign=profiles_installer",
    company_name: "H & H Wührmann Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602123c11a592.png?v=1",
    address: "Atenser Allee 19, 26954 Nordenham",
    telephone: "+49 4731 363630",
    website: "http://www.wuehrmann.de",
    email: "info@wuehrmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wirsol-aufdach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4776&utm_campaign=profiles_installer",
    company_name: "WIRSOL Aufdach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fae01c017421.jpg?v=1",
    address: "Schwetzinger Str. 22-26, 68753 Waghäuse",
    telephone: "+49 7254 710880",
    website: "https://wrs.eco",
    email: "info@wrs.eco",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": ",Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/wrage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99181&utm_campaign=profiles_installer",
    company_name: "T. & H. Wrage Sanitärtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c20586b6af.png?v=1",
    address: "Achter de Höf 4a, 22559, Hamburg",
    telephone: "+49 40 812167",
    website: "http://www.wrage-gmbh.de",
    email: "info@wrage-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wptec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102564&utm_campaign=profiles_installer",
    company_name: "WPTEC Energietechnik UG & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4310d81bf3.png?v=1",
    address: "Ottostraße 23a, Industriegebiet Baal, 41836 Hückelhoven",
    telephone: "+49 2433 959420",
    website: "http://wptec.de",
    email: "info@wptec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/harald-meyer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95242&utm_campaign=profiles_installer",
    company_name: "Harald Meyer Brandschutz-Elektro GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f0263f91a70.png?v=1",
    address: "Zur Straßenmeisterei 2, 27777 Ganderkesee",
    telephone: "+49 4222 94340",
    website: "http://www.meyergruppe.de",
    email: "info@wp.eu",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Meyer Technik Group of Companies,",
    "Last Update": 5022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/reiner-wotruba?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102031&utm_campaign=profiles_installer",
    company_name: "Reiner Wotruba GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab71d5cada1.jpg?v=1",
    address: "Lödlaer Chausee 12, 04617 Lödla",
    telephone: "+49 3447 8630",
    website: "http://www.wotruba-gmbh.de",
    email: "info@wotruba-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/worring-dach-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10451&utm_campaign=profiles_installer",
    company_name: "Worring Dach + Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50753283ed5b9.PNG?v=1",
    address: "Löhdorfer Strasse 46-48, 42699, Solingen",
    telephone: "+49 212 2641515",
    website: "http://www.worring-dach-solar.de",
    email: "info@worring-dach-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wollnik-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75247&utm_campaign=profiles_installer",
    company_name: "Wollnik Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be84eb7bfc5.jpg?v=1",
    address: "Obere Waldstraße 45, 07381 Pößneck",
    telephone: "+49 3647 449781",
    website: "https://www.wollnik-solar.de",
    email: "info@wollnik-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alfred-wohlers?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95248&utm_campaign=profiles_installer",
    company_name: "Alfred Wohlers GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f02a2d57983.png?v=1",
    address: "Elbchaussee 525, 22587 Hamburg",
    telephone: "+49 40 862320",
    website: "http://www.wohlers-sanitaer.de",
    email: "info@wohlers-sanitaer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wostmann-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10017&utm_campaign=profiles_installer",
    company_name: "Wöstmann Solar- und Heiztechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53fbf8a27f421.jpg?v=1",
    address: "Waterstroate 30, 48231 Warendorf",
    telephone: "+49 25 813510",
    website: "http://www.woestmann-solar.de/start.html",
    email: "info@woestmann-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wnb-automation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93754&utm_campaign=profiles_installer",
    company_name: "WNB  Automation",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c3800ea3fce.jpg?v=1",
    address: "Borner Weg 11D, 14827 Wiesenburg",
    telephone: "+49 33849 50807",
    website: "http://www.wnb-automation.de",
    email: "info@wnb-automation.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wm-solar-wanner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81795&utm_campaign=profiles_installer",
    company_name: "WM Solar Wanner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589578681d67e.jpg?v=1",
    address: "Lechstraße 9, 86937 Scheuring",
    telephone: "+49 8195 9980976",
    website: "http://www.wmsolar.de",
    email: "info@wmsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Trina Solar Limited,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wks-global?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=127671&utm_campaign=profiles_installer",
    company_name: "WK Solarpower GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f6c1d5ebd6dc.jpg?v=1",
    address: "Im Wiesengrund 18, D-57537 Wissen",
    telephone: "+49 2742 9667564",
    website: "http://www.wk-solarpower.com",
    email: "info@wk-solarpower.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",EGing Photovoltaic Technology Co., Ltd.,,,Zhejiang Shuqimeng Photovoltaic Technology Co., Ltd.,,,Eurener Group,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-wisser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10954&utm_campaign=profiles_installer",
    company_name: "W. Wisser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5937ac819dbe3.jpg?v=1",
    address: "Friedrich-Ebert-Straße 14, 65556 Limburg-Staffel",
    telephone: "+49 6431 3104",
    website: "http://www.wisser-dach.de",
    email: "info@wisser-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wirth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=16060&utm_campaign=profiles_installer",
    company_name: "Wirth Gruppe",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5175f54bd07e6.gif?v=1",
    address: "Schwanenkirchner Str. 24, 94491, Hengersberg",
    telephone: "+49 9901 93140",
    website: "http://www.wirth-gruppe.de",
    email: "info@wirth-gruppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wirth-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=27945&utm_campaign=profiles_installer",
    company_name: "Wirth Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c21d72dc5361.jpg?v=1",
    address: "Dossenbergerstraße 40, 89561 Dischingen",
    telephone: "+49 7327 96040",
    website: "http://wirth-elektrotechnik.com",
    email: "info@wirth-elektrotechnik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/winterbauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75745&utm_campaign=profiles_installer",
    company_name: "Winterbauer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608817eaea566.PNG?v=1",
    address: "In der Gabel 20, 69123 Heidelberg",
    telephone: "+49 6221 84400",
    website: "http://www.winterbauer.de",
    email: "info@winterbauer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wolfgang-winnefeld?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106230&utm_campaign=profiles_installer",
    company_name: "Wolfgang Winnefeld GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50274981cc6.png?v=1",
    address: "Ammerseestr. 30, 83101 Rohrdorf",
    telephone: "+49 8032 1642",
    website: "http://www.winnefeld.com",
    email: "info@winnefeld.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/willer-sanitar-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82021&utm_campaign=profiles_installer",
    company_name: "Willer Sanitär & Heizung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589d3efcc47d7.jpg?v=1",
    address: "Oppauer Straße 81, 67069 Ludwigshafen",
    telephone: "+49 621 668890",
    website: "http://willergmbh.de",
    email: "info@willergmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wiesing-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1573&utm_campaign=profiles_installer",
    company_name: "Wiesing Solartechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5167a5fc75eca.bmp?v=1",
    address: "Josef-Förster-Straße 13, 33161, Hövelhof",
    telephone: "+49 5257 938510",
    website: "http://www.wiesing-solartechnik.de",
    email: "info@wiesing-solartechnik.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solar Fabrik GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wieser-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10580&utm_campaign=profiles_installer",
    company_name: "Wieser Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10580n.gif?v=1",
    address: "Kirchplatz 5, 83553, Frauenneuharting",
    telephone: "+49 8092 85550",
    website: "http://www.wieserelektro.de",
    email: "info@wieserelektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wieser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94134&utm_campaign=profiles_installer",
    company_name: "Wieser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb6dee22d0a.jpg?v=1",
    address: "Hauptstraße 33, 94363 Oberschneiding",
    telephone: "+49 9426 852400",
    website: "http://www.wieser-haustechnik.de",
    email: "info@wieser-haustechnik.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/widmann-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1989&utm_campaign=profiles_installer",
    company_name: "Widmann Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/564989aa1d7bb.jpg?v=1",
    address: "Wilhelm-Maybach-Str.5, 74196 Neuenstadt am Kocher",
    telephone: "+49 71 39937970",
    website: "http://www.widmann-solartechnik.de",
    email: "info@widmann-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wibatec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94850&utm_campaign=profiles_installer",
    company_name: "Wibatec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df22f0a6f96.png?v=1",
    address: "Lehmbach 13, 51503 Rösrath",
    telephone: "+49 2205 908433",
    website: "http://www.wibatec.de",
    email: "info@wibatec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wi-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10578&utm_campaign=profiles_installer",
    company_name: "Wi Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56d54fd4073f7.jpg?v=1",
    address: "Am Römerturm 4, 56759, Kaisersesch",
    telephone: "+49 2653 9115980",
    website: "http://www.wi-solar.de",
    email: "info@wi-solar.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wg-solar-concept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76259&utm_campaign=profiles_installer",
    company_name: "WG Solar Concept GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e0ae3a00ee4.jpg?v=1",
    address: "Gottlieb-Daimler-Straße 1, 71154 Nufringen",
    telephone: "+49 7032 7844438",
    website: "https://web.wg-solar-concept.de",
    email: "info@wg-solar-concept.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/weser-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94651&utm_campaign=profiles_installer",
    company_name: "Weser Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb94a23cce.png?v=1",
    address: "Weserstraße 18, D-28757 Bremen",
    telephone: "+49 421 6586788",
    website: "http://weser-energietechnik.de",
    email: "info@weser-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wep?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76091&utm_campaign=profiles_installer",
    company_name: "WEP GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d77cb8c031b.jpg?v=1",
    address: "Mühlberg 19, 39175 Menz",
    telephone: "+49 39292 699399",
    website: "http://www.wep-gmbh.de",
    email: "info@wep-gmbh.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Solar Fabrik GmbH,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,Viessmann Werke GmbH & Co. KG,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,RCT Power GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wenz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102232&utm_campaign=profiles_installer",
    company_name: "Wenz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1f8d056bc3.jpg?v=1",
    address: "Paimarer Straße 5, 97950 Großrinderfeld",
    telephone: "+49 9349 92030",
    website: "http://www.wenzgmbh.de",
    email: "info@wenzgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wenninger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94143&utm_campaign=profiles_installer",
    company_name: "Wenninger GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cc4b4174d99.jpg?v=1",
    address: "Ampereweg 1, 74864 Fahrenbach",
    telephone: "+49 6267 929900",
    website: "http://www.wenninger-elektrotechnik.de",
    email: "info@wenninger.cc",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/welling-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94630&utm_campaign=profiles_installer",
    company_name: "Welling-Bedachungen GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddae0a9e0ab.png?v=1",
    address: "Natingen, Dorfstrasse 25, 34434 Borgentreich",
    telephone: "+49 5645 9931",
    website: "http://www.welling-bedachungen.de",
    email: "info@welling-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/welcome-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94378&utm_campaign=profiles_installer",
    company_name: "Welcome Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db2a6e0289a.png?v=1",
    address: "Berti-Breuer-Weber-Str. 11, D-55543 Bad Kreuznach",
    telephone: "+49 671 79615230",
    website: "http://www.welcome-energy.de",
    email: "info@welcome-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Siemens Energy,,,Kostal Solar Electric GmbH,,,Samil Power Co., Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/weise-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93462&utm_campaign=profiles_installer",
    company_name: "Weise Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf46623fd21.jpg?v=1",
    address: "Siemensstr. 14, 63755 Alzenau",
    telephone: "+49 6023 947750",
    website: "http://peter-solar.de/",
    email: "Info@weise-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/weis-dachdeckermeisterbetrieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11005&utm_campaign=profiles_installer",
    company_name: "Ronald Weis Dachdeckermeisterbetrieb",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11005n.gif?v=1",
    address: "Bahnhofstrasse 46, 64584, Biebesheim am Rhein",
    telephone: "+49 6258 6123",
    website: "http://www.weis-dachdecker.de",
    email: "info@weis-dachdecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/weindich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99196&utm_campaign=profiles_installer",
    company_name: "Weindich Ökologische Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c402674a35.png?v=1",
    address: "Schulstraße 5, 27330 Asendorf",
    telephone: "+49 4253 801160",
    website: "http://www.weindich.de",
    email: "info@weindich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wegscheider-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99198&utm_campaign=profiles_installer",
    company_name: "Max Wegscheider GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c419eb62be.png?v=1",
    address: "Zenchinger Str. 5, 93473 Arnschwang",
    telephone: "+49 9977 253",
    website: "http://www.wegscheider-m.de",
    email: "info@wegscheider-m.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jorg-wedler-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8622&utm_campaign=profiles_installer",
    company_name: "Jörg Wedler Elektroanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8622n.gif?v=1",
    address: "Kornradenstr. 44, 12357 Berlin",
    telephone: "+49 30 66930085",
    website: "http://www.wedler-berlin.de",
    email: "info@wedler-berlin.de",
    "Installaliert seit": 1997,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-konzepte-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93356&utm_campaign=profiles_installer",
    company_name: "Energie Konzepte",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb48c5ae800.jpg?v=1",
    address: "Auf der Setz 4, 76356 Weingarten, Baden",
    telephone: "+49 7244 5599480",
    website: "http://www.weber.energy",
    email: "info@weber.energy",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-p-w?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117015&utm_campaign=profiles_installer",
    company_name: "Weber & Burkard Zimmerei & Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f80bc3de093d.PNG?v=1",
    address: "Dr.-Oetker-Straße 14, Wengerohr, 54516 Wittlich",
    telephone: "+49 65 719549777",
    website: "http://weber-zimmerei.com",
    email: "info@weber-zimmerei.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/martin-weber-haustechnik-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99199&utm_campaign=profiles_installer",
    company_name: "Martin Weber Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c429155379.png?v=1",
    address: "Merowingerstrasse 4, 97297 Waldbüttelbrunn",
    telephone: "+49 931 7840947",
    website: "https://weber-martin.de",
    email: "info@weber-martin.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/weber-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102679&utm_campaign=profiles_installer",
    company_name: "Weber Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac98b0fd663e.jpg?v=1",
    address: "An der Alten Schule 4, 27801 Brettorf",
    telephone: "+49 4432 1424",
    website: "http://weber-solar.de",
    email: "info@weber-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/walz-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1711&utm_campaign=profiles_installer",
    company_name: "Walz Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50f89d9730826.gif?v=1",
    address: "Hungener Str. 62, 35423 Lich",
    telephone: "+49 6404 91930",
    website: "http://www.walz-lich.de",
    email: "info@walz-lich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ludwig-walz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81990&utm_campaign=profiles_installer",
    company_name: "Ludwig Walz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fedd7c3d56d7.png?v=1",
    address: "Tunnelweg 2a, 74850 Schefflenz",
    telephone: "+49 6293 412",
    website: "http://www.walz-dachbau.de",
    email: "info@walz-dachbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/walliser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99204&utm_campaign=profiles_installer",
    company_name: "Walliser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c4832ed728.png?v=1",
    address: "Germanenstraße 14, 72474 Winterlingen",
    telephone: "+49 7577 926158",
    website: "http://www.walliser-gmbh.de",
    email: "info@walliser-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wagner-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1295&utm_campaign=profiles_installer",
    company_name: "Wagner Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5cd244516d5e0.png?v=1",
    address: "Sonnenallee 2, 35274, Kirchhain",
    telephone: "+49 6421 80070",
    website: "http://www.wagner-solar.com",
    email: "info@wagner-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,,Europa",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,LONGi Solar Technology Co., Ltd.,,,LG Electronics, Inc.,,,CS Wismar GmbH Sonnenstromfabrik,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,AEconversion GmbH & Co. KG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/werner-schmelmer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102249&utm_campaign=profiles_installer",
    company_name: "Werner Schmelmer GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60381b3e531d5.png?v=1",
    address: "Bogener Straße 11, D-94379 St. Englmar",
    telephone: "+49 9965 84080",
    website: "http://www.w-schmelmer.de",
    email: "info@w-schmelmer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/w-quadrat?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1301&utm_campaign=profiles_installer",
    company_name: "W-quadrat Westermann & Wörner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d2097b2c9aa.jpg?v=1",
    address: "Baccarat-Straße 37-39, 76593 Gernsbach",
    telephone: "+49 7224 991900",
    website: "http://www.w-quadrat.de",
    email: "info@w-quadrat.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vwew-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95149&utm_campaign=profiles_installer",
    company_name: "Vereinigte Wertach-Elektrizitätswerke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee947074027.jpg?v=1",
    address: "Neugablonzer Straße 21, 87600 Kaufbeuren",
    telephone: "+49 8341 8050",
    website: "http://www.vwew-energie.de",
    email: "info@vwew-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wsb-neue-energien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=38687&utm_campaign=profiles_installer",
    company_name: "VSB Holding GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fa549cde1a3c.PNG?v=1",
    address: "Schweizer Str. 3a, 01069 Dresden",
    telephone: "+49 351 21183400",
    website: "http://www.vsb.energy",
    email: "info@vsb.energy",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Deutschland, Finnland, Frankreich, Griechenland, Kroatien, Irland, Italien, Südkorea, Rumänien, Polen,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jurgen-voss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106429&utm_campaign=profiles_installer",
    company_name: "Jürgen Voss",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b569ecace9ea.png?v=1",
    address: "Lunser Dorfstraße 2, 27321 Thedinghausen",
    telephone: "+49 4204 7246",
    website: "https://www.voss-lunsen.de",
    email: "info@voss-lunsen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/voltaro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131155&utm_campaign=profiles_installer",
    company_name: "Voltaro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6049e04bcfc6c.jpg?v=1",
    address: "Karlstr. 14 80333 München",
    telephone: "+49 89 2153704205",
    website: "https://www.voltaro.de",
    email: "info@voltaro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/voltark?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119907&utm_campaign=profiles_installer",
    company_name: "Voltark GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ddf1c26b9db3.jpg?v=1",
    address: "Friedrich-Ebert Strasse 132, 48153 Münster",
    telephone: "+49 251 97447390",
    website: "http://www.voltark.de",
    email: "info@voltark.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solea-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4906&utm_campaign=profiles_installer",
    company_name: "Solea AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4906n.gif?v=1",
    address: "Gottlieb-Daimler-Str.10, 94447 Plattling",
    telephone: "+49 9931 8969970",
    website: "http://www.solea-ag.com",
    email: "info@voltaik-check.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Canadian Solar Inc.,,,ReneSola Ltd.,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,KACO New Energy GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/stromtiger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95243&utm_campaign=profiles_installer",
    company_name: "StromTiger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f027931fbf8.png?v=1",
    address: "Oberwihl 7, 79733 Görwihl",
    telephone: "+49 7754 929813",
    website: "http://www.stromtiger.de",
    email: "info@voltaik-check.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/viva-solar-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=781&utm_campaign=profiles_installer",
    company_name: "Viva Solar Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/2527.gif?v=1",
    address: "Otto-Wolff-Str 12, 56626 Andernach",
    telephone: "+49 2632 96630",
    website: "http://vivasolar.de",
    email: "info@voltaik-check.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/voltaik-check?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94005&utm_campaign=profiles_installer",
    company_name: "Voltaik Check GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9c1edabe5c.jpg?v=1",
    address: "Borgwardring 20, 26802 Moormerland",
    telephone: "+49 4954 3052788",
    website: "http://www.voltaik-check.de",
    email: "info@voltaik-check.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 30102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vogel-solar-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10586&utm_campaign=profiles_installer",
    company_name: "Vogel Solar- & Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53acc82c7783e.jpg?v=1",
    address: "Dieselstraße 3, 76307, Karlsbad",
    telephone: "+49 7202 9373105",
    website: "http://www.vogel-solar.de",
    email: "info@vogel-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vodasun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13802&utm_campaign=profiles_installer",
    company_name: "Vodasun Akquise und Vertriebs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5063bac4c9d60.PNG?v=1",
    address: "Hochbrückenstrasse 10, 80331 München",
    telephone: "+49 89 716801650",
    website: "http://www.vodasun.de",
    email: "info@vodasun.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/vivest-energias-renovables?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=86840&utm_campaign=profiles_installer",
    company_name: "Vivest Energías Renovables s.a.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c32b50c93fe.jpg?v=1",
    address: "3 Oriente 2240, 3461274 Talca",
    telephone: "+56 71 2343224",
    website: "https://www.vivest-energias-renovables.net",
    email: "info@vivest.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Chili, Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/vissoltec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31075&utm_campaign=profiles_installer",
    company_name: "Vissoltec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/577635adeca7d.jpg?v=1",
    address: "Auf der Gass 21, 56291, Wiebelsheim",
    telephone: "+49 6766 9889787",
    website: "http://www.vissoltec.de",
    email: "Info@vissoltec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/visar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95220&utm_campaign=profiles_installer",
    company_name: "Visar Dachtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6014a3b7be309.png?v=1",
    address: "Dachauer Str. 334a, 80993 München",
    telephone: "+49 89 55898121",
    website: "http://www.visar-dachtechnik.de",
    email: "info@visar-dachtechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/vis-sanitar-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99205&utm_campaign=profiles_installer",
    company_name: "Vis Sanitär Heizung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c48da18691.png?v=1",
    address: "Kleinenberger Straße 8, 42719 Solingen",
    telephone: "+49 212 10042",
    website: "http://www.vis-solingen.de",
    email: "info@vis-solingen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/verfers-elektromontagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94785&utm_campaign=profiles_installer",
    company_name: "Verfers Elektromontagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dee5bb5b6a4.png?v=1",
    address: "Klompstraße 42-44, 47551 Bedburg-Hau",
    telephone: "+49 2821 89850",
    website: "http://www.verfers.de",
    email: "info@verfers.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vendo-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75889&utm_campaign=profiles_installer",
    company_name: "Vendo Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d0c0d62aee5.jpg?v=1",
    address: "Haferkamp 3, 49586 Merzen",
    telephone: "+49 5466 936543",
    website: "http://vendo-solar.de",
    email: "info@vendo-solar.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/veith-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11908&utm_campaign=profiles_installer",
    company_name: "Veith Gebäudetechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/546add2574e0c.jpg?v=1",
    address: "Bußmatten 15, 77815, Bühl, Baden",
    telephone: "+49 7223 8010010",
    website: "http://www.veith-gebaeudetechnik.de",
    email: "info@veith-gt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/grubert-heizung-sanitar-installation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10378&utm_campaign=profiles_installer",
    company_name: "Uwe Grubert GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd08e0632b88.png?v=1",
    address: "Loitzer Str. 20, 17489 Greifswald",
    telephone: "+49 3834 594972",
    website: "http://www.uwegrubert-gmbh.de",
    email: "info@uwegrubertgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/unseld-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75169&utm_campaign=profiles_installer",
    company_name: "Unseld Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd38e8ac69c.jpg?v=1",
    address: "Hinterdenkental 17, 89198 Westerstetten",
    telephone: "+49 7336 921072",
    website: "http://www.unseld-solar.de",
    email: "info@unseld-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/uee?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81789&utm_campaign=profiles_installer",
    company_name: "Universal Energy Engineering GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5895749199334.jpg?v=1",
    address: "Neefestraße 82, 09119 Chemnitz",
    telephone: "+49 371 9098590",
    website: "http://www.universal-energy.de",
    email: "info@universal-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solar Fabrik GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Ekarat Solar Co., Ltd.,,,Solartron Public Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/umwelttechnik-bornemann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81988&utm_campaign=profiles_installer",
    company_name: "Umwelttechnik Bornemann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589c117e823e6.jpg?v=1",
    address: "Am Graben 12, D-18182 Bentwisch",
    telephone: "+49 381 6374990",
    website: "http://www.umwelttechnik-bornemann.de",
    email: "info@umwelttechnik-bornemann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ulltech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75371&utm_campaign=profiles_installer",
    company_name: "Ulltech AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c3f72b8219d.jpg?v=1",
    address: "Magnolienweg 25, D-63741 Aschaffenburg",
    telephone: "+49 6021 386530",
    website: "http://ulltech.de",
    email: "info@ulltech-ag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ulland-dachtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95224&utm_campaign=profiles_installer",
    company_name: "Ulland Dachtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f0101c9df42.png?v=1",
    address: "Bocholder Esch 16c, 48683 Ahaus-Alstätte",
    telephone: "+49 2567 936800",
    website: "http://www.ulland-dachtechnik.de",
    email: "info@ulland-dachtechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",CREATON GmbH,,,Dachziegelwerke Nelskamp GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/uhtenwoldt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101448&utm_campaign=profiles_installer",
    company_name: "Uhtenwoldt GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a9910cfa81f2.jpg?v=1",
    address: "Heidekamp 3, 23758 Jahnshof",
    telephone: "+49 4361 90890",
    website: "http://www.uhtenwoldt-gmbh.de",
    email: "info@uhtenwoldt-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/umweltfreundliche-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1572&utm_campaign=profiles_installer",
    company_name: "Umweltfreundliche Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/522ed6cb38d60.gif?v=1",
    address: "Schlagenweg 8, 37077 Göttingen",
    telephone: "+49 551 371002",
    website: "http://www.ufhgmbh.de",
    email: "info@ufhgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/uzw-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93783&utm_campaign=profiles_installer",
    company_name: "ÜZW Energie AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c46f3952afb.jpg?v=1",
    address: "Regensburger Straße 33, 84051 Altheim",
    telephone: "+49 8703 92550",
    website: "https://uezw-energie.de",
    email: "info@uezw-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/umst?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75353&utm_campaign=profiles_installer",
    company_name: "Uckermark Solartechnik GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c3df1fedaa6.jpg?v=1",
    address: "Carmzow 47a, 17291 Carmzow-Wallmow",
    telephone: "+49 39854 37733",
    website: "http://uckermark-solartechnik.de",
    email: "info@uckermark-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/twe-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76029&utm_campaign=profiles_installer",
    company_name: "TWE Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ef58b8bd6913.png?v=1",
    address: "Haarbachweg 12, 94474 Vilshofen",
    telephone: "+49 8549 9730020",
    website: "http://www.twe-solar.de",
    email: "info@twe-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Trina Solar Limited,,,AU Optronics Corporation,,,LG Electronics, Inc.,,,Sunny Call LLC,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,,,Fimer S.p.A.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tst-solarstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7367&utm_campaign=profiles_installer",
    company_name: "TST Solarstrom OHG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ef848a99b0df.jpg?v=1",
    address: "Baron-Riederer-Str. 48, 84337, Schönau",
    telephone: "+49 8726 910037",
    website: "https://www.tst-solarstrom.de",
    email: "info@tst-solarstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Shenzhen Global Solar Energy Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/triwatt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99210&utm_campaign=profiles_installer",
    company_name: "Triwatt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c4c6bdf7e4.png?v=1",
    address: "Rothenburger Straße 28, 74582 Gerabronn",
    telephone: "+49 7952 9219820",
    website: "http://www.triwatt.de",
    email: "info@triwatt.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tritec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=3792&utm_campaign=profiles_installer",
    company_name: "Tritec International AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/3792n.gif?v=1",
    address: "Im Klosteracker 35, CH – 4103 Bottmingen",
    telephone: "+41 61 2640020",
    website: "http://www.tritec-energy.com",
    email: "info@tritec-energy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Schweiz,",
    "Lieferanten von Solarmodulen":
      ",Canadian Solar Inc.,,,Jinko Solar Holding Co., Ltd.,,,LG Electronics, Inc.,,,AEG Solar Solutions GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,Guangzhou Sanjing Electric Co., Ltd.,,,AEG Solar Solutions GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/trinewa-elektro-und-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102234&utm_campaign=profiles_installer",
    company_name: "Trinewa Elektro- & Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab20171158fc.jpg?v=1",
    address: "Lamparter Straße 6, 72141 Walddorfhäslach",
    telephone: "+49 7127 9572060",
    website: "http://www.trinewa.de",
    email: "info@trinewa.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/trid-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99195&utm_campaign=profiles_installer",
    company_name: "Trid Solar Service",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f4c780cae107.jpg?v=1",
    address: "Zum Küppel 15, 36124 Eichenzell",
    telephone: "+49 6659 6291795",
    website: "http://www.trid-solar.com",
    email: "info@trid-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Solar Frontier K.K.,,,Galaxy Energy GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 31082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/trend-power?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119858&utm_campaign=profiles_installer",
    company_name: "Trend Power",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dddcacda1193.jpg?v=1",
    address: "Friedrich-Profit-Str. 2, 67346 Speyer",
    telephone: "+49 173 8997727",
    website: "http://trendpower.de",
    email: "info@trendpower.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/trend-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102384&utm_campaign=profiles_installer",
    company_name: "Trend Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9d8d39e151.png?v=1",
    address: "Dr.-Rudolf-Schieber-Str. 45, 73463 Westhausen",
    telephone: "+49 7363 9599929",
    website: "https://www.trend-energietechnik.de",
    email: "Info@Trend-Energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/top-exergy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124465&utm_campaign=profiles_installer",
    company_name: "Top Exergy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb659208bc92.jpg?v=1",
    address: "Neue Wietze 6c, 30657 Hanover",
    telephone: "+49 511 37388626",
    website: "http://www.top-exergy.de",
    email: "info@top-exergy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tittmann-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1767&utm_campaign=profiles_installer",
    company_name: "Tittmann Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/586f578556c48.jpg?v=1",
    address: "Schurwaldstraße 13, D-71332 Waiblingen",
    telephone: "+49 7151 976840",
    website: "http://www.tittmann-solar.de",
    email: "info@tittmann-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/titotec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107913&utm_campaign=profiles_installer",
    company_name: "Titotec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88ba2a30526.jpg?v=1",
    address: "Heinrich-Schacht-Weg 14, 32805 Horn-Bad Meinberg",
    telephone: "+49 5234 2622",
    website: "https://www.titotec-lippe.de",
    email: "info@titotec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tischlerei-montage-schuurmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93255&utm_campaign=profiles_installer",
    company_name: "Tischlerei & Montage Schüürmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9fb81b6198.jpg?v=1",
    address: "Wilsumer Str. 30, 49847, Itterbeck",
    telephone: "+49 5948 990165",
    website: "http://schuurmann-kozijnen.nl/",
    email: "info@tischlerei-schüürmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/thorwesten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82433&utm_campaign=profiles_installer",
    company_name: "Thorwesten GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abeccd6b787.jpg?v=1",
    address: "Geiststr. 24, 48151 Münster",
    telephone: "+49 251 38471958",
    website: "http://thorwesten-gmbh.de",
    email: "info@thorwesten-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-anweiler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99179&utm_campaign=profiles_installer",
    company_name: "Thomas Anweiler Heizungsbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c1f203d3a3.png?v=1",
    address: "In den Meckeswiesen 2, 69226 Nußloch",
    telephone: "+49 6224 16924",
    website: "http://thomasanweiler.de",
    email: "info@thomasanweiler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tholen-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94114&utm_campaign=profiles_installer",
    company_name: "Tholen Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb395e44ec8.jpg?v=1",
    address: "Johann-Conen-Straße 1, 52538 Gangelt",
    telephone: "+49 2454 989090",
    website: "http://tholen-elektrotechnik.de",
    email: "info@tholen-gt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tholen-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124530&utm_campaign=profiles_installer",
    company_name: "Tholen Gebäudetechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eba506d7f52c.jpg?v=1",
    address: "Johann-Conen-Straße 1, 52538 Gangelt",
    telephone: "+49 2454 989090",
    website: "https://tholen-gebaeudetechnik.de",
    email: "info@tholen-gt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/theurer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93594&utm_campaign=profiles_installer",
    company_name: "Theurer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c099d87ff6b.jpg?v=1",
    address: "Daimlerstraße 2, 77815 Bühl",
    telephone: "+49 7223 911245",
    website: "http://www.theurer-heizung.de",
    email: "info@theurer-heizung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/thermoglobe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=577&utm_campaign=profiles_installer",
    company_name: "ThermoGLOBE Hausbau & Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/595dab2af2051.jpg?v=1",
    address: "Berliner Straße 28 A, 13127 Berlin",
    telephone: "+49 30 81864677",
    website: "http://www.thermoglobe.de",
    email: "info@thermoglobe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tga-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81986&utm_campaign=profiles_installer",
    company_name: "Tga Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589c0f2ee22ff.jpg?v=1",
    address: "Nieder-Ramstädter-Straße 80, 64372 Ober-Ramstadt",
    telephone: "+49 6154 5890330",
    website: "http://www.tga-service.de",
    email: "info@tga-service.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/san-installation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99172&utm_campaign=profiles_installer",
    company_name:
      "San. Installation u. Heizungsbau Hermann Ternieden + Söhne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c1baa6d6e0.png?v=1",
    address: "Grimbergstr. 7, 45307 Essen",
    telephone: "+49 201 552579",
    website: "http://www.ternieden-soehne.de",
    email: "info@ternieden-soehne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/termasol-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64631&utm_campaign=profiles_installer",
    company_name: "Termasol Energie GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/605899e78512b.PNG?v=1",
    address: "Falltorstraße 21, 76676 Graben-Neudorf",
    telephone: "+49 7255 7247807",
    website: "http://www.termasol-energie.com",
    email: "info@termasol-energie.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",Termasol GmbH,",
    "Last Update": 22032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tellsolar-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99188&utm_campaign=profiles_installer",
    company_name: "TellSolar Technik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c28eb9ae0c.png?v=1",
    address: "Zum Hegesiefen 7, 51674 Wiehl",
    telephone: "+49 2261 978207",
    website: "http://tellsolar-technik.de",
    email: "info@tellsolar-technik.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tekloth-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75545&utm_campaign=profiles_installer",
    company_name: "Tekloth Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdc96d503abe.png?v=1",
    address: "Schlavenhorst 105, 46395 Bocholt",
    telephone: "+49 2871 2520600",
    website: "http://www.tekloth-solar.de",
    email: "info@tekloth-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/teck-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81985&utm_campaign=profiles_installer",
    company_name: "Teck Solar e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589c0d17dada9.jpg?v=1",
    address: "Röntgenstrasse 8, 73230 Kirchheim",
    telephone: "+49 7021 4052506",
    website: "http://www.teck-solar.de",
    email: "info@teck-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/technosol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76429&utm_campaign=profiles_installer",
    company_name: "Technosol",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e49942a2158.jpg?v=1",
    address: "Yachthafenstraße 17, D-21635 Jork",
    telephone: "+49 4162 942707",
    website: "http://www.technosol.de",
    email: "info@technosol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Mitsubishi Electric US, Inc.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/technik-service-bayern?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93744&utm_campaign=profiles_installer",
    company_name: "Technik Service Bayern GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c3712e82157.jpg?v=1",
    address: "Am Hartholz 29, 82239 Alling",
    telephone: "+49 8141 66657560",
    website: "https://www.technikservice-bayern.de",
    email: "info@technikservice-bayern.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/technikhaus-gundel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81565&utm_campaign=profiles_installer",
    company_name: "Technikhaus Gündel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588472b5f2779.jpg?v=1",
    address: "Johannes-Göderitz-Str.98, 39130 Magdeburg",
    telephone: "+49 391 7224305",
    website: "http://www.technikhaus-guendel.de",
    email: "info@technikhaus-guendel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/team-warmeservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1592&utm_campaign=profiles_installer",
    company_name: "Team Wärmeservice GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52035acd9f895.jpg?v=1",
    address: "Moselstr. 11, 45478, Mülheim an der Ruhr",
    telephone: "+49 208 9698812",
    website: "https://www.team-waermeservice.de",
    email: "info@teamwaermeservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/team-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75237&utm_campaign=profiles_installer",
    company_name: "Team Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be70f650508.jpg?v=1",
    address: "Rödertalstraße 70, 01454 Radeberg",
    telephone: "+49 3528 487579",
    website: "http://www.teamsolartechnik.de",
    email: "info@teamsolartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-zachert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10790&utm_campaign=profiles_installer",
    company_name: "Thomas Zachert GmbH Dachdeckermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ff7310468464.png?v=1",
    address: "Liebigstrasse 6, 32107, Bad Salzuflen",
    telephone: "+49 5222 998950",
    website: "https://team-zachert.de",
    email: "info@team-zachert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/herrmann-heizungsbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75713&utm_campaign=profiles_installer",
    company_name: "Herrmann Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cd13c86b25d.jpg?v=1",
    address: "Hauptstraße 1, 16818 Dabergotz",
    telephone: "+49 3391 82290",
    website: "http://www.team-herrmann.com",
    email: "info@team-herrmann.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tdz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75019&utm_campaign=profiles_installer",
    company_name: "TDZ Technische Dienstleistungen Zimmermann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bac2b97f74a.jpg?v=1",
    address: "Friedhofsstraße 10, 31249 Hohenhameln, Bierbergen",
    telephone: "+49 51 28400492",
    website: "http://tdz-online.de",
    email: "info@tdz-online.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/taunus-solarenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93209&utm_campaign=profiles_installer",
    company_name: "Taunus Solarenergie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b791c545a77.jpg?v=1",
    address: "Industriestr. 8a, 61449 Steinbach",
    telephone: "+49 6173 9892616",
    website: "http://taunus-solarenergie.de",
    email: "info@taunus-solarenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Jinko Solar Holding Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,,,Alpha Group,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tasa-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75259&utm_campaign=profiles_installer",
    company_name: "TASA Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60aba3d28da36.PNG?v=1",
    address: "Gartenhausweg 18, Gebäude 44, 74259 Widdern",
    telephone: "+49 6298 95610",
    website: "http://www.tasa-solartechnik.de",
    email: "info@tauber-solar.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tauber-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9390&utm_campaign=profiles_installer",
    company_name: "Tauber-Solar Gruppe",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c25cb107fe9.jpg?v=1",
    address: "Siemensstraße 7, 97941 Tauberbischofsheim",
    telephone: "+49 93 41895820",
    website: "http://www.tauber-solar.de",
    email: "info@tauber-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tas?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101813&utm_campaign=profiles_installer",
    company_name: "Technischer Anlagen Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa72a65daf85.jpg?v=1",
    address: "Walkmühlenweg 53, D-45661 Recklinghausen",
    telephone: "+49 2361 65050",
    website: "http://www.tas-recklinghausen.de",
    email: "info@tas-recklinghausen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tamsen-bau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94489&utm_campaign=profiles_installer",
    company_name: "Tamsen Bau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc632f6ed5e.jpg?v=1",
    address: "Mackenstedter Str. 4, 28816 Stuhr, Groß Mackenstedt",
    telephone: "+49 4206 3053770",
    website: "https://www.tamsenbau.de",
    email: "info@tamsenbau.de",
    "Installaliert seit": 2013,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/system-sonne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10322&utm_campaign=profiles_installer",
    company_name: "System Sonne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10322n.gif?v=1",
    address: "Grundlerstrasse 14, 89616 Rottenacker",
    telephone: "+49 7393 954940",
    website: "http://www.system-sonne.de",
    email: "info@system-sonne.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,AxSun Solar GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/swu-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101847&utm_campaign=profiles_installer",
    company_name: "SWU Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa765a809305.jpg?v=1",
    address: "Karlstraße 1-3, 89073 Ulm",
    telephone: "+49 731 1662677",
    website: "https://www.swu.de",
    email: "info@swu.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",SWU Group of Companies,",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-munchen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93624&utm_campaign=profiles_installer",
    company_name: "Stadtwerke München GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1e0d3969ad.jpg?v=1",
    address: "Stadtwerke München, 80287 Munich",
    telephone: "+49 800 7967960",
    website: "https://www.swm.de",
    email: "info@swm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/swiptec-engineering?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93773&utm_campaign=profiles_installer",
    company_name: "Swiptec Engineering GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c467e88800f.jpg?v=1",
    address: "Springstraße 24, 65604 Elz",
    telephone: "+49 6431 2172703",
    website: "http://www.swiptec-engineering.de",
    email: "info@swiptec-engineering.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",JA Solar Holdings Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/swat-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99171&utm_campaign=profiles_installer",
    company_name: "SWAT Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c1aa8ea0d6.png?v=1",
    address: "An der Zeil 38, D-74906, Bad Rappenau",
    telephone: "+49 7268 919810",
    website: "http://www.swat-energy.de",
    email: "info@swatsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-schifferstadt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95235&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Schifferstadt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f0236f47672.png?v=1",
    address: "Mühlstraße 18, 67105 Schifferstadt",
    telephone: "+49 6235 49010",
    website: "http://www.sw-schifferstadt.de",
    email: "info@sw-schifferstadt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarwind-projekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124952&utm_campaign=profiles_installer",
    company_name: "SolarWind Projekt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ed471ecbde66.png?v=1",
    address: "Bernstorffstr. 120, 22767, Hamburg",
    telephone: "+49 40 40196411",
    website: "https://sw-projekt.eu",
    email: "info@sw-projekt.eu",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sun-unlimited?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99163&utm_campaign=profiles_installer",
    company_name: "Sun Unlimited",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c170dbb501.png?v=1",
    address: "Percha 3, 83620 Feldkirchen-Westerham",
    telephone: "+49 8063 3483605",
    website: "http://www.sununlimited.de",
    email: "info@sununlimited.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,KACO New Energy GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/suntec-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10440&utm_campaign=profiles_installer",
    company_name: "SunTec Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10440n.gif?v=1",
    address: "Am Tiergarten 2, 97253, Wolkshausen",
    telephone: "+49 9337 980775",
    website: "http://www.suntec-energiesysteme.de",
    email: "info@suntec-energiesysteme.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunsys-energietechnik-friedhelm-schneider?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1842&utm_campaign=profiles_installer",
    company_name: "Sunsys Energietechnik Friedhelm Schneider e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d257c95c4db5.jpg?v=1",
    address: "Römerstraße 56, 41569 Rommerskirchen",
    telephone: "+49 172 6053035",
    website: "https://www.sunsys.de",
    email: "info@sunsys.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Galaxy Energy GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1308&utm_campaign=profiles_installer",
    company_name: "SunStrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4520.gif?v=1",
    address: "Moritzburger Weg 67, D-01109 Dresden",
    telephone: "+49 351 8838130",
    website: "http://www.sunstrom.de",
    email: "info@sunstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunstar-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10024&utm_campaign=profiles_installer",
    company_name: "SUNSTAR Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10024n.gif?v=1",
    address: "Im Gewerbepark D31, 93059 Regensburg",
    telephone: "+49 941 44527",
    website: "http://www.sunstar-solartechnik.de",
    email: "info@sunstar-solartechnik.de",
    "Installaliert seit": 1989,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunsibar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99160&utm_campaign=profiles_installer",
    company_name: "Sunsibar Sonnenenergie & Speichertechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c13b1ececc.png?v=1",
    address: "Buchenstrße 65, 59348 Lüdinghausen",
    telephone: "+49 2591 8912082",
    website: "http://www.sunsibar-solar.de",
    email: "info@sunsibar-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunrapidosolar-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99159&utm_campaign=profiles_installer",
    company_name: "SunRapidoSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c129370b05.png?v=1",
    address: "Karlstadter Strasse 114, 97737 Gemuenden am Main",
    telephone: "+49 9351 5004993",
    website: "http://www.sunrapidosolar.com",
    email: "info@sunrapidosolar.com",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sun-peak-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1779&utm_campaign=profiles_installer",
    company_name: "Sun Peak Unternehmensgruppe Ratio Data GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e8bbe9783be.jpg?v=1",
    address: "Auf den Besenäckern 17, 69502 Hemsbach",
    telephone: "+49 6201 6020721",
    website: "http://www.sunpeak-vertrieb.de",
    email: "info@sunpeak.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunny-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1653&utm_campaign=profiles_installer",
    company_name: "Sunny Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/518384db36b4d.gif?v=1",
    address: "Gustav-Schwab-Straße 14, 78467 Konstanz / Bodensee",
    telephone: "+49 7531 362850",
    website: "http://www.sunny-solartechnik.de",
    email: "info@sunny-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,JA Solar Holdings Co., Ltd.,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunmotion?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99158&utm_campaign=profiles_installer",
    company_name: "SunMotion GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c11532b6d4.png?v=1",
    address: "Am Fuhrenkamp 5, 29393, Groß Oesingen",
    telephone: "+49 5838 991400",
    website: "http://www.sunmotion-gmbh.com",
    email: "info@sunmotion-gmbh.com",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sungrade-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93652&utm_campaign=profiles_installer",
    company_name: "Sungrade Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60098d207b006.png?v=1",
    address: "Röntgenstr. 10, 89312 Günzburg",
    telephone: "+49 800 1165116",
    website: "http://www.sungrade.de",
    email: "info@sungrade.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,AXITEC Energy GmbH & Co. KG,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunforfree?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58199&utm_campaign=profiles_installer",
    company_name: "SunForFree GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6021292528524.png?v=1",
    address: "In der Kleinen Heide 4, 53842 Troisdorf",
    telephone: "+49 2241 9744972",
    website: "http://www.sunforfree.de",
    email: "info@sunforfree.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunfarming?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8314&utm_campaign=profiles_installer",
    company_name: "SUNfarming GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8314n.gif?v=1",
    address: "Zum Wasserwerk 12, D-15537, Erkner",
    telephone: "+49 3362 8859120",
    website: "http://www.sunfarming.de",
    email: "info@sunfarming.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",ALENSYS Group,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/sunera?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11893&utm_campaign=profiles_installer",
    company_name: "Sunera Erneuerbare Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ba095823851a.png?v=1",
    address: "Schlachthofstr. 11a, D-66280, Sulzbach",
    telephone: "+49 6897 9520920",
    website: "http://www.sunera.de",
    email: "info@sunera.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunenergy-europe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6795&utm_campaign=profiles_installer",
    company_name: "SunEnergy Europe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6795n.gif?v=1",
    address: "Glüsinger Str. 92, 21217 Seevetal",
    telephone: "+49 40 5201430",
    website: "http://www.sunenergy.eu",
    email: "info@sunenergy.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": ",Europa,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/semita?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6968&utm_campaign=profiles_installer",
    company_name: "Semita GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56e9136576f47.jpg?v=1",
    address: "Neheimer Str. 10, 59469, Ense",
    telephone: "+49 2938 9778636",
    website: "http://www.sundevice.de",
    email: "info@sundevice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunconcept-pluster?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11880&utm_campaign=profiles_installer",
    company_name: "SunConcept Plüster GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5865fa18750f5.jpg?v=1",
    address: "Buersche Str. 37, 49074 Osnabrück",
    telephone: "+49 541 88495",
    website: "http://www.sunconcept-ap.de",
    email: "info@sunconcept-ap.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunairgy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=118976&utm_campaign=profiles_installer",
    company_name: "SunAirgy Ingenieurgesellschaft mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d98436e4a867.jpg?v=1",
    address: "Weinbergstrasse 1,9 D-77971 Kippenheim",
    telephone: "+49 7825 8698865",
    website: "http://www.sunairgy.de",
    email: "info@sunairgy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,ReneSola Ltd.,,,Znshine PV-tech Co., Ltd.,,,Photowatt,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 30032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sulzle-kopf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102238&utm_campaign=profiles_installer",
    company_name: "Sülzle Kopf GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab20a808dec6.jpg?v=1",
    address: "Stützenstraße 6, 72172 Sulz a.N.",
    telephone: "+49 7454750",
    website: "http://suelzle-kopf.de",
    email: "info@suelzle-kopf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: ",Sülzle Group,",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-bad-tolz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94990&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Bad Tölz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e07e952362f.jpg?v=1",
    address: "An der Osterleite 2, 83646 Bad Tölz",
    telephone: "+49 8041 7970",
    website: "http://stw-toelz.de",
    email: "info@stw-toelz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-frankenthal?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107427&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Frankenthal GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b7bb919e5f50.png?v=1",
    address: "Wormser Straße 111, 67227 Frankenthal",
    telephone: "+49 6233 6020",
    website: "https://www.stw-frankenthal.de",
    email: "info@stw-frankenthal.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 23032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sturm-sanitar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7365&utm_campaign=profiles_installer",
    company_name: "Sturm Sanitär- und Heizungstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56947b1e33005.jpg?v=1",
    address: "Neckarwiesen 5, D-72172 Sulz",
    telephone: "+49 7454 976900",
    website: "http://www.sturm-sulz.de",
    email: "info@sturm-sulz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stucke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102692&utm_campaign=profiles_installer",
    company_name: "Stucke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac999dd277a3.jpg?v=1",
    address: "Hausmattweg 12, 76547 Sinzheim",
    telephone: "+49 7221 8842",
    website: "http://www.stucke.de",
    email: "info@stucke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stuber-energie-sonnen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93247&utm_campaign=profiles_installer",
    company_name: "Stuber Energie & Sonnen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f35d318be.jpg?v=1",
    address: "Auer Str. 15, 84048, Rudelzhausen-Tegernbach",
    telephone: "+49 8751 844680",
    website: "http://stuber-sonne.de/",
    email: "info@stuber-sonne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sts-solar-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10320&utm_campaign=profiles_installer",
    company_name: "STS Solar Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10320n.gif?v=1",
    address: "Laffenau 2, 91180 Heideck",
    telephone: "+49 9177 1855",
    website: "http://www.sts-solar.de",
    email: "info@sts-solar.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sts-alarm-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131729&utm_campaign=profiles_installer",
    company_name: "STS Alarm & Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/606d536892a68.png?v=1",
    address: "Klein Sarau 19, 23627, Groß Sarau",
    telephone: "+49 4509 712010",
    website: "http://sts-elektro.de/",
    email: "info@sts-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/strumberger-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1985&utm_campaign=profiles_installer",
    company_name: "Strumberger Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53e088e39d629.jpg?v=1",
    address: "Friedrich-Silcher-Str. 60, 72639 Neuffen",
    telephone: "+49 170 9570366",
    website: "http://www.strumberger-solartechnik.de",
    email: "info@strumberger-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/strasser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75689&utm_campaign=profiles_installer",
    company_name: "Strasser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cce2df39fe0.jpg?v=1",
    address: "Rebensdorf 12 ⅓, 84144 Geisenhausen",
    telephone: "+49 8743 830",
    website: "http://www.strasser-get.de",
    email: "info@strasser-get.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stoiber-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94127&utm_campaign=profiles_installer",
    company_name: "Stoiber Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb6732ca136.jpg?v=1",
    address: "Oberpöringermoos 69, 94562 Oberpöring",
    telephone: "+49 993 7617",
    website: "http://www.stoiber-photovoltaik.de",
    email: "info@stoiber-photovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Kyocera Corporation,,,Panasonic Corporation (Sanyo),,,Centrosolar America Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stix?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99123&utm_campaign=profiles_installer",
    company_name: "STIX Haustechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a585bf3893a8.png?v=1",
    address: "Spinning Island 1, 83059 Kolbermoor",
    telephone: "+49 8031 28370",
    website: "http://www.stix-haustechnik.de",
    email: "info@stix-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stiller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99190&utm_campaign=profiles_installer",
    company_name: "Stiller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c2aea5c4ae.png?v=1",
    address: "Feldstraße 208-210, D-45701 Herten",
    telephone: "+49 2366 505954",
    website: "https://www.stiller-shk.de",
    email: "info@stiller-shk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stiefenhofer-energie-und-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7364&utm_campaign=profiles_installer",
    company_name: "Stiefenhofer Energie- und Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/603f365dad5ee.png?v=1",
    address: "Hans-Liebherr-Straße 16, D-88161, Lindenberg",
    telephone: "+49 8381 92080",
    website: "http://stiefenhofer-lindenberg.de/",
    email: "info@stiefenhofer-lindenberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,AXITEC Energy GmbH & Co. KG,,,Viessmann Werke GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stemberg-solar-und-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1568&utm_campaign=profiles_installer",
    company_name: "Stemberg Solar- und Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58f8186ac2955.png?v=1",
    address: "Im Seelenkamp 7, 32791 Lage",
    telephone: "+49 5232 70270",
    website: "http://stemberg-solar.de",
    email: "Info@stemberg-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stechmann-dachtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95141&utm_campaign=profiles_installer",
    company_name: "Stechmann Dachtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee91040c021.jpg?v=1",
    address: "Nievenheimer Str. 9, 41469 Neuss-Norf",
    telephone: "+49 2137 9280124",
    website: "http://www.stechmann.de",
    email: "info@stechmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarteam-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74893&utm_campaign=profiles_installer",
    company_name: "Solarteam Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a7a4205670c.PNG?v=1",
    address: "Norderstraße 2, 24632 Lentföhrden",
    telephone: "+49 4191 9912840",
    website: "http://ste-solar.de",
    email: "info@ste-solar.de",
    "Installaliert seit": 2013,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,BMI Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stapel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82431&utm_campaign=profiles_installer",
    company_name: "Stapel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abeba1d9c33.jpg?v=1",
    address: "Tönnishäuschen 15, 59227 Ahlen",
    telephone: "+49 2528 8452",
    website: "http://www.stapel-gmbh.de",
    email: "info@stapel-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stallberg-sanitar-und-heizungstechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93657&utm_campaign=profiles_installer",
    company_name: "Stallberg Sanitär- und Heizungstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c201b6e8792.jpg?v=1",
    address: "Piccoloministr 30, 51063 Köln",
    telephone: "+49 221 9603560",
    website: "http://stallberg.com",
    email: "info@stallberg.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-wolfenbuttel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93643&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Wolfenbüttel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1eb6cf27c5.jpg?v=1",
    address: "Am Wasserwerk 2, 38304 Wolfenbüttel",
    telephone: "+49 5331 4080",
    website: "https://www.stadtwerke-wf.de",
    email: "info@stadtwerke-wf.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-rendsburg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93243&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Rendsburg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f25c61aac.jpg?v=1",
    address: "Am Eiland 12, 24768 Rendsburg",
    telephone: "+49 4331 2090",
    website: "https://www.stadtwerke-rendsburg.de",
    email: "info@stadtwerke-sh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-rodgau-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94808&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Rodgau Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c35b30debbf3.jpg?v=1",
    address: "Philipp-Reis-Strasse 7, 63110, Rodgau",
    telephone: "+49 6106 82960",
    website: "https://www.stadtwerke-rodgau.de",
    email: "info@stadtwerke-rodgau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-iserlohn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95251&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Iserlohn GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f02bcb3f1a8.png?v=1",
    address: "Stefanstraße 4-8, 58638 Iserlohn",
    telephone: "+49 2371 159078",
    website: "https://www.heimatversorger.de",
    email: "info@stadtwerke-iserlohn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-buchen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=47981&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Buchen Gmbh & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58edd1ff97249.png?v=1",
    address: "Am Hohen Markstein 3, 74722, Buchen",
    telephone: "+49 6281 5350",
    website: "http://www.stadtwerke-buchen.de",
    email: "info@stadtwerke-buchen.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-bayreuth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122757&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Bayreuth Holding GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e65de80473de.jpg?v=1",
    address: "Birkenstraße 2, 95447, Bayreuth",
    telephone: "+49 921 6000",
    website: "http://www.stadtwerke-bayreuth.de",
    email: "info@stadtwerke-bayreuth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-s-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4120&utm_campaign=profiles_installer",
    company_name: "S+S Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/593e34d7145c9.jpg?v=1",
    address: "Am Rott 1, 29439 Lüchow (Wendland) Grabow",
    telephone: "+49 5864 9878781",
    website: "http://www.sse-dan.de",
    email: "info@sse-dan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Trina Solar Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/srt-hausling?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99150&utm_campaign=profiles_installer",
    company_name: "SRT-Häusling",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c064422896.png?v=1",
    address: "Dunckerstr. 12, 60386, Frankfurt",
    telephone: "+49 69 425985",
    website: "http://www.srt-haeusling.de",
    email: "info@srt-haeusling.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/srp-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93260&utm_campaign=profiles_installer",
    company_name: "SRP Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9fe82b7ab7.jpg?v=1",
    address: "Zeppelinring 12, 26197 Ahlhorn",
    telephone: "+49 4435 933677",
    website: "http://www.srp-elektrotechnik.de",
    email: "info@srp-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/srs-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82493&utm_campaign=profiles_installer",
    company_name: "Solar Roof Systems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58acf18feb772.jpg?v=1",
    address: "Energiepark 10-14, 91732 Merkendorf",
    telephone: "+49 9826 658550",
    website: "http://sroof.de",
    email: "info@sroof.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,EGing Photovoltaic Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Fimer S.p.A.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/spreewald-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64599&utm_campaign=profiles_installer",
    company_name: "Spreewald Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/605880eb7f42b.PNG?v=1",
    address: "Waldstraße 5, 15913 Neu Zauche",
    telephone: "+49 35475 800692",
    website: "http://www.spreewald-energy.de",
    email: "info@spreewald-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,LG Electronics, Inc.,,,Tesla Energy,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/spr-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=5923&utm_campaign=profiles_installer",
    company_name: "SPR Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601c0d67eb987.png?v=1",
    address: "Norddeich 3, 25924, Rodenäs",
    telephone: "+49 46 68958630",
    website: "http://www.spr-energie.de",
    email: "info@spr-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/spot-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74997&utm_campaign=profiles_installer",
    company_name: "Spot Energy West GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a7b76493e38.PNG?v=1",
    address: "Gießerallee 19, 47877 Willich",
    telephone: "+49 2154 5024570",
    website: "http://www.spot-energy-west.de",
    email: "info@spot-energy-west.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerhard-spoler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76277&utm_campaign=profiles_installer",
    company_name: "Gerhard Spöler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e0cb2c9c3bd.jpg?v=1",
    address: "Borkener Straße 34, 46359 Heiden",
    telephone: "+49 2867 234",
    website: "http://www.spoeler.de",
    email: "info@spoeler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/spenglerei-wasensteiner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81785&utm_campaign=profiles_installer",
    company_name: "Martin Wasensteiner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58957191a79f2.jpg?v=1",
    address: "Hohenwiesen 3, 83661 Lenggries",
    telephone: "+49 8042 8725",
    website: "http://www.spenglerei-wasensteiner.de",
    email: "info@spenglerei-wasensteiner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bauspenglerei-hackner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94639&utm_campaign=profiles_installer",
    company_name: "Bauspenglerei Hackner GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb468814df.png?v=1",
    address: "Neuhartshöfe 6, 85080 Gaimersheim",
    telephone: "+49 8458 5680",
    website: "http://www.spenglerei-hackner.de",
    email: "info@spenglerei-hackner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Mitsubishi Electric US, Inc.,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/speck?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95086&utm_campaign=profiles_installer",
    company_name: "Speck GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85d42e66f8.jpg?v=1",
    address: "Rappenwörtstraße 16, 76189 Karlsruhe",
    telephone: "+49 721 572268",
    website: "http://speck-dach.de",
    email: "info@speck-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sparteam?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75055&utm_campaign=profiles_installer",
    company_name: "Sparteam Service und Vertrieb",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bbc2fcc528e.jpg?v=1",
    address: "Lärchenweg 15, 59581 Warstein-Hirschberg",
    telephone: "+49 2902 976923",
    website: "http://www.sparteam.com",
    email: "info@sparteam.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Perfectenergy (Shanghai) Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,,,SolarPark Korea Co., Ltd.,,,Csunpower Technology (Nanjing) Co., Ltd.,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/spalding-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1569&utm_campaign=profiles_installer",
    company_name: "Spalding Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/522ed5ab53621.gif?v=1",
    address: "Südstraße 7a, 35066 Frankenberg",
    telephone: "+49 6451 2302490",
    website: "http://www.spalding-elektro.de",
    email: "info@spalding-elektro.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sotech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1732&utm_campaign=profiles_installer",
    company_name: "Sotech GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ed4b10a1e6d2.jpg?v=1",
    address: "Am Birkenfeld 10, 52222 Stolberg",
    telephone: "+49 2402 7097620",
    website: "http://www.sotech.de",
    email: "info@sotech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnvolt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84775&utm_campaign=profiles_installer",
    company_name: "Sonnvolt GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60781fb233eb0.PNG?v=1",
    address: "Nuthedamm 14, 14974 Ludwigsfelde",
    telephone: "+49 3378 2066486",
    website: "http://www.sonnvolt.de",
    email: "info@sonnvolt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,Solar Fabrik GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,JA Solar Holdings Co., Ltd.,,,EGing Photovoltaic Technology Co., Ltd.,,,Trina Solar Limited,,,Solar Frontier K.K.,,,ReneSola Ltd.,,,Avancis GmbH & Co. KG,,,Solvis d.o.o.,,,AU Optronics Corporation,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,,,Sopray Solar Group Limited,",
    "Wechselrichter-Lieferanten":
      ",Steca Elektronik GmbH,,,Fronius International GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnergy-bavaria?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75555&utm_campaign=profiles_installer",
    company_name: "Sonnergy Bavaria Ltd.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c7dc641fbfb.jpg?v=1",
    address: "Kiefernstraße 5, 94336 Hunderdorf/Bogen",
    telephone: "+49 9422 4012965",
    website: "http://www.photovoltaik-bayern.com",
    email: "info@sonnergy-bavaria.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenzeit-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13788&utm_campaign=profiles_installer",
    company_name: "Sonnenzeit GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576899327abbd.jpg?v=1",
    address: "Am Bahnhof 15, 33397 Rietberg",
    telephone: "+49 52 44700050",
    website: "https://www.sonnenzeitpv.de",
    email: "info@sonnenzeitpv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenwerkstatt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99145&utm_campaign=profiles_installer",
    company_name: "SonnenWerkstatt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c0421580e7.png?v=1",
    address: "Oberstixner 1A, 87547, Missen-Wilhams",
    telephone: "+49 8320 9258833",
    website: "http://www.sonnenwerkstatt.org",
    email: "info@sonnenwerkstatt.org",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,SunPower Corp.,,,AXITEC Energy GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenwende2020?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99140&utm_campaign=profiles_installer",
    company_name: "Sonnenwende2020 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c01140267c.png?v=1",
    address: "Postleitenstraße 10, 91785 Pleinfeld",
    telephone: "+49 9144 608080",
    website: "http://sonnenwende2020.de",
    email: "info@sonnenwende2020.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnentaler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99113&utm_campaign=profiles_installer",
    company_name: "Sonnentaler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c00055c814.png?v=1",
    address: "Im Kampe 23, 31008 Elze",
    telephone: "+49 5068 92920",
    website: "http://www.sonnentaler.eu",
    email: "info@sonnentaler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Suntech Power Co., Ltd.,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenstrom-bauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10569&utm_campaign=profiles_installer",
    company_name: "Sonnenstrom Bauer GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10569n.gif?v=1",
    address: "Am Kastlacker 11, 93309, Kelheim",
    telephone: "+49 9441 1749770",
    website: "http://www.sonnenstrom-bauer.de",
    email: "info@sonnenstrom-bauer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenstraelen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4126&utm_campaign=profiles_installer",
    company_name: "Sonnenstraelen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/582146f5a9a2a.jpg?v=1",
    address: "Steinstr. 38, 47638 Straelen",
    telephone: "+49 2834 709794",
    website: "http://www.sonnenstraelen.de",
    email: "info@sonnenstraelen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,Trina Solar Limited,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenplaner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30891&utm_campaign=profiles_installer",
    company_name: "Sonnenplaner e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54c20953a48d3.bmp?v=1",
    address: "Engertstr. 13, 04177 Leipzig",
    telephone: "+49 341 52904569",
    website: "http://www.sonnenplaner.com",
    email: "info@sonnenplaner.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,JA Solar Holdings Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,Changzhou Almaden Co., Ltd.,,,Astronergy Co., Ltd. (Chint Solar),,,AXITEC Energy GmbH & Co. KG,,,Hyundai Energy Solutions,,,Jinko Solar Holding Co., Ltd.,,,LONGi Solar Technology Co., Ltd.,,,Luxor Solar GmbH,,,AU Optronics Corporation,,,GCL System Integration Technology Co., Ltd.,,,LG Electronics, Inc.,,,Suzhou Talesun Solar Technologies Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Sungrow Power Supply Co., Ltd.,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenplan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7345&utm_campaign=profiles_installer",
    company_name: "SonnenPlan GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58956d43efb5e.jpg?v=1",
    address: "Amerikastraße 37, D-66482 Zweibrücken",
    telephone: "+49 6332 566890",
    website: "http://www.sonnenplan.de",
    email: "info@sonnenplan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sks?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99129&utm_campaign=profiles_installer",
    company_name: "SKS Sonnenkraftspeicher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a586609596aa.png?v=1",
    address: "Am Lindachsgraben 37, 97353, Wiesentheid",
    telephone: "+49 9383 8699870",
    website: "https://www.sonnenkraftspeicher.de",
    email: "info@sonnenkraftspeicher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenkonzept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64597&utm_campaign=profiles_installer",
    company_name: "Sonnenkonzept GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56aacbf1afe96.png?v=1",
    address: "Wichernstraße 22, 14656 Brieselang",
    telephone: "+49 33 232223089",
    website: "http://www.sonnenkonzept.de",
    email: "info@sonnenkonzept.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,SunPower Corp.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenkonig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4563&utm_campaign=profiles_installer",
    company_name:
      "Sonnenkönig Spezialist für Solar & Energiespar-Technik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6341988904.gif?v=1",
    address: "Am Giener 9, Gewerbepark I, 55268, Nieder-Olm",
    telephone: "+49 6136 9234888",
    website: "http://www.sonnenkoenig.org",
    email: "info@sonnenkoenig.org",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenhaus-bad-segeberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99128&utm_campaign=profiles_installer",
    company_name: "Sonnenhaus Bad Segeberg",
    logo_url:
      "https://cdn.enfsolar.com/z/s/logos/DJ5db3f981551fb/Sonnehaus-Logo-positiv_gimp zuschnitt.png?v=1",
    address: "Bramstedter Chaussee 5 B, 23795 Högersdorf",
    telephone: "+49 4551 5393587",
    website: "http://www.sonnenhaus-se.de",
    email: "info@sonnenhaus-se.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenexpert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94894&utm_campaign=profiles_installer",
    company_name: "Sonnenexpert GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e032699d50a.jpg?v=1",
    address: "Konrad-Zuse-Str. 1A, 18184 Roggentin",
    telephone: "+49 381 80699210",
    website: "http://sonnenexpert.com",
    email: "info@sonnenexpert.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnendach-invest?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=575&utm_campaign=profiles_installer",
    company_name: "Sonnendach-Invest e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575e73a5cbf5b.jpg?v=1",
    address: "Ziegelbergsweg 98, 38855, Wernigerode",
    telephone: "+49 3943 905440",
    website: "http://www.sonnendach-invest.de",
    email: "info@sonnendach-invest.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,Centro Energy Co., Ltd.,,,HeFei Sunway Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,KACO New Energy GmbH,,,Sungrow Power Supply Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnen-pv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81784&utm_campaign=profiles_installer",
    company_name: "Sonnen-PV GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5895703752281.jpg?v=1",
    address: "Hannberger Weg 13, 91091 Grossenseebach",
    telephone: "+49 9135 7357775",
    website: "http://www.sonnen-pv.de",
    email: "info@sonnen-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonn-ja-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99185&utm_campaign=profiles_installer",
    company_name: "Sonn-ja Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c22c30ca0d.png?v=1",
    address: "Bergstr.1, 76547, Sinzheim",
    telephone: "+49 152 34019152",
    website: "http://www.sonn-ja.de",
    email: "info@sonn-ja.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Suntech Power Co., Ltd.,,,Sharp Energy Solutions Europe,,,Luxor Solar GmbH,,,Win Win Precision Technology Co., Ltd.,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonalis?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31225&utm_campaign=profiles_installer",
    company_name: "Sonalis GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/521dc1b736441.jpg?v=1",
    address: "Wellesweilerstr. 100, 66538, Neunkirchen",
    telephone: "+49 6821 9517940",
    website: "http://www.sonalis.de",
    email: "info@sonalis.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Luxemburgo,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solux-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1676&utm_campaign=profiles_installer",
    company_name: "Solux GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4121.gif?v=1",
    address: "An der Stiftsbleiche 1, 87439 Kempten",
    telephone: "+49 8 3164413",
    website: "http://www.solux.de",
    email: "info@solux.de",
    "Installaliert seit": 1993,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/soluwa?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7360&utm_campaign=profiles_installer",
    company_name: "Soluwa GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7360n.gif?v=1",
    address: "Haimendorfer Str. 54, 90571 Schwaig b. Nürnberg",
    telephone: "+49 911 3784090",
    website: "http://www.soluwa.de",
    email: "info@soluwa.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/s4e-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99151&utm_campaign=profiles_installer",
    company_name: "Solutions4energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c06e71377c.png?v=1",
    address: "Delsenbachweg 9B, D-90425, Nürnberg",
    telephone: "+49 911 13012306",
    website: "https://www.solutions4energy.de",
    email: "info@solutions4energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Sunways LLC,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solution-solarsysteme-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1630&utm_campaign=profiles_installer",
    company_name: "Solution Solarsysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4074.gif?v=1",
    address: "Wehlauer Str. 5, 76139 Karlsruhe",
    telephone: "+49 721 9613410",
    website: "http://www.solution-solar.de",
    email: "info@solution-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/soltech-rieser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81576&utm_campaign=profiles_installer",
    company_name: "Soltech Rieser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5885559f61119.jpg?v=1",
    address: "Tullastraße 6, 67346 Speyer",
    telephone: "+49 6232 36681",
    website: "http://www.soltech.de",
    email: "info@soltech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/soltec-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7359&utm_campaign=profiles_installer",
    company_name: "Soltec Reimann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7359n.gif?v=1",
    address: "Kölner Str. 239, Mülheim a.d. Ruhr, NRW, 45481",
    telephone: "+49 208 485416",
    website: "http://www.soltec-solar.com",
    email: "info@soltec-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Kyocera Corporation,,,Solvis d.o.o.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Studer Innotec,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/soltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8684&utm_campaign=profiles_installer",
    company_name: "Soltaik KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f7823fa8267c.png?v=1",
    address: "Crayenbühlstraße 6, 67295 Bolanden",
    telephone: "+49 179 7786933",
    website: "http://www.soltaik.de",
    email: "info@soltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,IBC Solar AG,,,ET Solar New Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solprime-power-systems-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=20585&utm_campaign=profiles_installer",
    company_name: "SOLprime Power Systems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/20585n.gif?v=1",
    address: "Philipp-Reis-Weg 6, D- 24148 Kiel",
    telephone: "+49 431 6674910",
    website: "http://solprime.de",
    email: "info@solprime.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Argentinien, Deutschland, Madagaskar,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 15052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solonic?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=14616&utm_campaign=profiles_installer",
    company_name: "Solonic International GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d43ec1750a32.png?v=1",
    address: "Am Schornacker 121, 46485 Wesel",
    telephone: "+49 281 405677960",
    website: "http://www.solonic.de",
    email: "info@solonic.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solmey?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74907&utm_campaign=profiles_installer",
    company_name: "Solmey GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b66e82c1afe.jpg?v=1",
    address: "Badstr. 12, 71546 Aspach",
    telephone: "+49 7191 933881",
    website: "http://www.solmey.de",
    email: "info@solmey.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solkraft-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94841&utm_campaign=profiles_installer",
    company_name: "Solkraft GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df1d9cc8238.png?v=1",
    address: "Luitpoldstraße 2, 63897 Miltenberg",
    telephone: "+49 9371 6500322",
    website: "http://www.solkraft.de",
    email: "INFO@SOLKRAFT.DE",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Trina Solar Limited,,,Astronergy Co., Ltd. (Chint Solar),,,Hyundai Energy Solutions,,,LONGi Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solicity-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99153&utm_campaign=profiles_installer",
    company_name: "Solicity Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c0ba7e3f73.png?v=1",
    address: "An den Brauwiesen 22, D-98631 Grabfeld OT Jüchsen",
    telephone: "+49 36947 526040",
    website: "http://www.solicityenergy.com",
    email: "info@solicityenergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solibra-system-montage-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56393&utm_campaign=profiles_installer",
    company_name: "Solibra System Montage GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/557f955f5a62f.jpg?v=1",
    address: "Ernst-Sachs-Straße 27, D-56070 Koblenz",
    telephone: "+49 261 9222070",
    website: "http://solibra-system-montage.eu",
    email: "info@solibra.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Großbritannien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/soli-sonne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124653&utm_campaign=profiles_installer",
    company_name: "Soli Sonne",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ec49c8f5ba71.png?v=1",
    address: "Gustav-Mahler-Str. 2, 17358 Torgelow",
    telephone: "+49 3976 2804423",
    website: "https://www.soli-sonne.de",
    email: "info@soli-sonne.de",
    "Installaliert seit": 2018,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solfang-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64587&utm_campaign=profiles_installer",
    company_name: "Solfang Photovoltaik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6058795397175.PNG?v=1",
    address: "Oberdießemer Str. 26, 47805 Krefeld",
    telephone: "+49 2151 9495829",
    website: "http://www.solfang.de",
    email: "info@solfang.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/soley-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75495&utm_campaign=profiles_installer",
    company_name: "Soley Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c6a26a0b9cd.jpg?v=1",
    address: "Hirschlach 30 b, 91732 Merkendorf",
    telephone: "+49 9826 6593220",
    website: "http://www.soley-solar.de",
    email: "info@soley-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Galaxy Energy GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solera-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64585&utm_campaign=profiles_installer",
    company_name: "Solera GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56aab833a267a.png?v=1",
    address: "Fuhrmannstraße 7, 72351 Geislingen-Binsdorf",
    telephone: "+49 7428 9418700",
    website: "http://solera.de",
    email: "info@solera.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",Thomas Preuhs Holding GmbH,",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solemio?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99216&utm_campaign=profiles_installer",
    company_name: "Solemio GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c5135d565e.png?v=1",
    address: "Fasanenweg 6, 79235 Vogtsburg-Achkarren",
    telephone: "+49 7662 935420",
    website: "http://www.solemio.de",
    email: "info@solemio.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11866&utm_campaign=profiles_installer",
    company_name: "Solektro Solarstrom & Solarwärme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51675e87a0efa.gif?v=1",
    address: "Grubenstieg 6, D-22549, Hamburg",
    telephone: "+49 40 84057070",
    website: "http://www.solektro.de",
    email: "info@solektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/soldeg-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1780&utm_campaign=profiles_installer",
    company_name: "Soldeg Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1780n.gif?v=1",
    address: "Im Rott 6, 69493 Hirschberg",
    telephone: "+49 6201 9868930",
    website: "http://www.soldeg.de",
    email: "Info@soldeg.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solcompany?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10312&utm_campaign=profiles_installer",
    company_name: "Solcompany",
    logo_url: "https://cdn.enfsolar.com/ID/logo/533a75d416310.jpg?v=1",
    address: "Heerenstr. 40, 45145 Essen, NRW",
    telephone: "+49 201 75960968",
    website: "http://www.solcompany.de",
    email: "info@solcompany.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Kyocera Corporation,,,Solar Frontier K.K.,,,Mitsubishi Electric US, Inc.,,,Panasonic Corporation (Sanyo),,,Hyundai Energy Solutions,,,LG Electronics, Inc.,,,Sunways LLC,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,,,Delta Energy Systems (Germany) GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaton?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64575&utm_campaign=profiles_installer",
    company_name: "Solaton GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56a9ccbb93a10.png?v=1",
    address: "Fritz-Klett-Straße 61-63, 71404 Korb",
    telephone: "+49 7151 2095730",
    website: "http://www.solaton.de",
    email: "info@solaton.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 21012020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solastrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10310&utm_campaign=profiles_installer",
    company_name: "SolaStrom GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10310n.gif?v=1",
    address: "Färberstraße 19a, 86157 Augsburg",
    telephone: "+49 821 523126",
    website: "http://www.solastrom.de",
    email: "info@solaservice.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-siegerland?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74461&utm_campaign=profiles_installer",
    company_name: "Solarzentrum Siegerland",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ad24c8caf6d.jpg?v=1",
    address: "Steinstraße 24, 57072 Siegen",
    telephone: "+49 271 2359673",
    website: "http://www.solarzentrum-siegerland.de",
    email: "info@solarzentrum-siegerland.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,AXITEC Energy GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-rheingau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74431&utm_campaign=profiles_installer",
    company_name: "Solarzentrum Rheingau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ac2ec0c77b1.jpg?v=1",
    address: "Adelheidstrasse 33, 65343 Eltville",
    telephone: "+49 6123 705809",
    website: "http://www.solarzentrum-rheingau.de",
    email: "info@solarzentrum-rheingau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-oberland?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1762&utm_campaign=profiles_installer",
    company_name: "Solarzentrum Oberland GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1762n.gif?v=1",
    address: "Am Weidenbach 1, 82362 Weilheim",
    telephone: "+49 881 64333",
    website: "http://www.solarzentrum-oberland.de",
    email: "info@solarzentrum-oberland.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-mittelhessen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93268&utm_campaign=profiles_installer",
    company_name: "Solarzentrum Mittelhessen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba0450f0fb3.jpg?v=1",
    address: "Im Süßacker 1-3, D-35236 Breidenbach-Oberdieten",
    telephone: "+49 6465 927680",
    website: "https://www.solarzentrum-mittelhessen.de",
    email: "info@solarzentrum-mittelhessen.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Luxor Solar GmbH,,,GCL System Integration Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-mirow?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11863&utm_campaign=profiles_installer",
    company_name: "Solarzentrum Mirow GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11863n.gif?v=1",
    address: "Fischergang 3, 17252, Mirow",
    telephone: "+49 39833 20497",
    website: "http://www.solarzentrum-mirow.de",
    email: "info@solarzentrum-mirow.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-bielefeld?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93973&utm_campaign=profiles_installer",
    company_name: "Solarzentrum Bielefeld Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9a6ae47c36.jpg?v=1",
    address: "Potsdamer Straße 12, 33719 Bielefeld",
    telephone: "+49 521 3906067",
    website: "http://www.solarzentrum-bielefeld.de",
    email: "info@solarzentrum-bielefeld.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarwerkstatt-lemgo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1300&utm_campaign=profiles_installer",
    company_name: "Solarwerkstatt Lemgo GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4510.gif?v=1",
    address: "Lagesche Straße 10-12, D-32657 Lemgo",
    telephone: "+49 5261 77553",
    website: "http://www.solarwerkstatt.de",
    email: "info@solarwerkstatt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarwerkstatt-wuppertal?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93985&utm_campaign=profiles_installer",
    company_name: "Solarwerkstatt Wuppertal",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ae222d239.jpg?v=1",
    address: "Langerfelder Str. 37, D-42389 Wuppertal",
    telephone: "+49 202 82964",
    website: "http://solarwerkstatt-wuppertal.de",
    email: "info@solarwerkstatt-wuppertal.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarwerkstatt-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11918&utm_campaign=profiles_installer",
    company_name: "Solarwerkstatt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11918n.gif?v=1",
    address: "Ravensburger Straße 41/1, 88069, Tettnang",
    telephone: "+49 7542 509951",
    website: "http://www.solarwerkstatt-online.de",
    email: "info@solarwerkstatt-online.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solar Fabrik GmbH,,,BISOL Group, d.o.o.,,,IBC Solar AG,,,Kyocera Corporation,,,First Solar, Inc.,,,Photowatt,,,Solara GmbH,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarvolt-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93994&utm_campaign=profiles_installer",
    company_name: "Solarvolt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b2b82bb18.jpg?v=1",
    address: "Herrenhaider Straße 41, D-09217 Burgstädt",
    telephone: "+49 3724 80106",
    website: "http://www.solarvolt.net",
    email: "info@solarvolt.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-und-energiespeicher-elektromeister?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105982&utm_campaign=profiles_installer",
    company_name: "Solar und Energiespeicher Elektromeister Pascal Prezzo",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4460b1b0756.png?v=1",
    address: "Im Kies 13, 79771, Klettgau",
    telephone: "+49 7742 9226966",
    website: "https://www.solarundenergiespeicher.de",
    email: "info@solarundenergiespeicher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/harperscheidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1604&utm_campaign=profiles_installer",
    company_name: "Elektro & Energie Harperscheidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54a352d3027d5.jpg?v=1",
    address: "Am Burgholz 2-4, 52372 Kreuzau",
    telephone: "+49 24 216934921",
    website: "http://www.solartiger.de",
    email: "info@solartiger.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-sud?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11698&utm_campaign=profiles_installer",
    company_name: "Solartechnik Süd GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11698n.gif?v=1",
    address: "Perlacher Str. 6, D-81539, München",
    telephone: "+49 89 69399393",
    website: "http://www.solartechniksued.de",
    email: "info@solartechniksud.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-tappeser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11888&utm_campaign=profiles_installer",
    company_name: "Solartechnik Tappeser GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11888n.gif?v=1",
    address: "Elsetalstraße 32, 58239 Schwerte",
    telephone: "+49 2304 200477",
    website: "http://solartechnik-tappeser.de/",
    email: "info@solartechnik-tappeser.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,LONGi Solar Technology Co., Ltd.,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-steffen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93780&utm_campaign=profiles_installer",
    company_name: "Solartechnik Steffen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c46dbd68038.jpg?v=1",
    address: "Bockelhornerweg 25, 24601 Wankendorf",
    telephone: "+49 4326 508436",
    website: "http://solartechnik-steffen.de",
    email: "info@solartechnik-steffen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Trina Solar Limited,,,Luxor Solar GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-janszen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75143&utm_campaign=profiles_installer",
    company_name: "Solartechnik Janßen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd18c9e7c7a.jpg?v=1",
    address: "Zetastraße 1, 65385 Rüdesheim am Rhein",
    telephone: "+49 6726 9732",
    website: "http://www.solartechnik-janssen.de",
    email: "info@solartechnik-janßen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-e-technik-hamm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75133&utm_campaign=profiles_installer",
    company_name: "Solar-E-Technik Hamm GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd0ffc83565.jpg?v=1",
    address: "Oberster Kamp 2, 59069 Hamm",
    telephone: "+49 2385 437487",
    website: "http://www.solartechnik-hamm.de",
    email: "info@solartechnik-hamm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-ebbes?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93777&utm_campaign=profiles_installer",
    company_name: "Solartechnik Ebbes",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c46c35597c7.jpg?v=1",
    address: "Ferdinand-Kortmann-Str. 5, 59394 Nordkirchen",
    telephone: "+49 2596 528700",
    website: "http://www.solartechnik-ebbes.de",
    email: "info@solartechnik-ebbes.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,Luxor Solar GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Sungrow Power Supply Co., Ltd.,,,Kostal Solar Electric GmbH,,,AEG Power Solutions B.V.,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-bergmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75147&utm_campaign=profiles_installer",
    company_name: "Solartechnik Bergmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd1a806aa5c.jpg?v=1",
    address: "Beinegrund 21, 63776 Mömbris",
    telephone: "+49 6029 992715",
    website: "http://www.solartechnik-bergmann.de",
    email: "info@solartechnik-bergmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-technik-hoffmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75251&utm_campaign=profiles_installer",
    company_name: "Solar Technik Hoffmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be86fc666a2.jpg?v=1",
    address: "Ludwigstraße 14, 85113 Böhmfeld",
    telephone: "+49 8406 2726768",
    website: "http://www.solartechnik-hoffmann.de",
    email: "info@solartechnik -hoffmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartec-roder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119700&utm_campaign=profiles_installer",
    company_name: "Solartec Röder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dd38db630f39.jpg?v=1",
    address: "Udenhainer Str. 5, 63607 Wächtersbach",
    telephone: "+49 6053 6081470",
    website: "http://www.solartec-roeder.de",
    email: "info@solartec-roeder.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartec-7?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30807&utm_campaign=profiles_installer",
    company_name: "SolarTec",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e8febe067e2.jpg?v=1",
    address: "Okerstraße 5, 16515 Oranienburg",
    telephone: "+49 3301 573081",
    website: "http://www.solartec-ohv.de",
    email: "info@solartec-ohv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Fimer S.p.A.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartec-energie-systeme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82007&utm_campaign=profiles_installer",
    company_name: "Solartec Energie-Systeme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589d25ef79126.jpg?v=1",
    address: "Heinrichsallee 44, 58636 Iserlohn",
    telephone: "+49 2371 778345",
    website: "https://www.solartec-mk.de",
    email: "info@solartec-energiesysteme.de",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarteam-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93775&utm_campaign=profiles_installer",
    company_name: "SolarTeam 3 - Ländereck",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c46a24b567d.jpg?v=1",
    address: "Würgasser Str. 2A, 37697 Lauenförde",
    telephone: "+49 5273 3677320",
    website: "http://www.solarteam3.de",
    email: "info@solarteam3.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarstromer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124872&utm_campaign=profiles_installer",
    company_name: "Solarstromer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecfa852d4983.png?v=1",
    address: "Schillerstraße, 49, D-72275 Alpirsbach",
    telephone: "+49 7444 917846",
    website: "http://solarstromer.de/",
    email: "info@solarstromer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,SunPower Corp.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarstrom-celle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=52435&utm_campaign=profiles_installer",
    company_name: "Solarstrom Celle",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54c59e117471c.bmp?v=1",
    address: "Witzlebenstraße 4a, 29223, Celle",
    telephone: "+49 5141 950196",
    website: "http://www.solarstromcelle.de",
    email: "info@solarstromcelle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarstrom-roth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102040&utm_campaign=profiles_installer",
    company_name: "Solarstrom Roth GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab773b2f515.jpg?v=1",
    address: "Gewerbering 7a, 91564 Neuendettelsau",
    telephone: "+49 9874 504500",
    website: "https://www.solarstrom-roth.de",
    email: "info@solarstrom-roth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarstrom-prignitz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93771&utm_campaign=profiles_installer",
    company_name: "Solarstrom Prignitz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4662a16686.jpg?v=1",
    address: "Meyenburger Tor 19a, 16928 Pritzwalk",
    telephone: "+49 3395 709385",
    website: "http://www.solarstrom-prignitz.de",
    email: "info@solarstrom-prignitz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarstep?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22829&utm_campaign=profiles_installer",
    company_name: "solarSTEP Unternehmensgruppe",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4ff25460de2fe.gif?v=1",
    address: "Stützeläckerweg 11, 60489, Frankfurt am Main",
    telephone: "+49 69 26913700",
    website: "http://www.solarstep.de",
    email: "info@solarstep.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Ningbo Qixin Solar Electrical Appliance Co., Ltd,,,Mitsubishi Heavy Industries Ltd.,,,Canadian Solar Inc.,,,First Solar, Inc.,,,ReneSola Ltd.,,,Phono Solar Technology Co., Ltd.,,,Europe Solar Production Sp. z o.o.,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Danfoss A/S,,,REFU Elektronik GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarsky-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=60055&utm_campaign=profiles_installer",
    company_name: "SolarSky GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/561c72641e844.jpg?v=1",
    address: "Max-Planck-Straße 4, 36179 Bebra",
    telephone: "",
    website: "http://www.solarsky-gmbh.de",
    email: "info@solarsky-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Rumänien,",
    "Lieferanten von Solarmodulen": ",Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarservice-norddeutschland-vertriebs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124471&utm_campaign=profiles_installer",
    company_name: "Solarservice Norddeutschland Vertriebs GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb65f2fc319c.jpg?v=1",
    address: "Lise-Meitner-Strasse 5, 31303 Burgdorf",
    telephone: "+49 5136 9701100",
    website: "http://www.solarservice-norddeutschland.de",
    email: "info@solarservice-norddeutschland.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarscout-eifel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93766&utm_campaign=profiles_installer",
    company_name: "Solarscout Eifel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4620d37fdd.jpg?v=1",
    address: "Kerperscheid 11, 53937 Schleiden",
    telephone: "+49 2445 8511800",
    website: "http://www.solarscout-eifel.de",
    email: "info@solarscout-eifel.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarschmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8335&utm_campaign=profiles_installer",
    company_name: "Solarschmidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e4df188acfa.jpg?v=1",
    address: "Heinrichsbergla 1, 95183, Münchenreuth",
    telephone: "+49 9295 1213",
    website: "http://www.solarschmidt.de",
    email: "info@solarschmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarpowerteam?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91070&utm_campaign=profiles_installer",
    company_name: "SolarPowerTeam GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/597e91ca854d0.jpg?v=1",
    address: "Gewerbepark Edelweiß 2, 88138 Weißensberg",
    telephone: "+49 8382 89953",
    website: "http://www.solarpowerteam.de",
    email: "info@solarpowerteam.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solon International,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarplus-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82446&utm_campaign=profiles_installer",
    company_name: "Solarplus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abf5e54ef53.jpg?v=1",
    address: "Zaunkönigweg 7, 44225 Dortmund",
    telephone: "+49 231 72548390",
    website: "http://solarplus-dortmund.de",
    email: "info@solarplus-dortmund.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarplatz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122766&utm_campaign=profiles_installer",
    company_name: "Solarplatz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e6605eebc503.jpg?v=1",
    address: "Im Wiesengrund 8-10, 56746, Spessart",
    telephone: "+49 2642 998894",
    website: "http://www.solarplatz.de",
    email: "info@solarplatz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/7c-solarparken?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=18532&utm_campaign=profiles_installer",
    company_name: "7C Solarparken AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5695ada7c2bb0.jpg?v=1",
    address: "An der Feuerwache 15, D-95445 Bayreuth",
    telephone: "+49 921 23055777",
    website: "http://www.solarparken.com",
    email: "info@solarparken.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Siemens Energy,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarparc?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13784&utm_campaign=profiles_installer",
    company_name: "Solarparc GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5063b4866fce7.PNG?v=1",
    address: "In den Dauen 6a, 53117, Bonn",
    telephone: "+49 228 4224050",
    website: "http://www.solarparc.de",
    email: "info@solarparc.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Siemens Energy,,,REFU Elektronik GmbH,,,Schneider Electric SA,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-outdoor-media?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=121244&utm_campaign=profiles_installer",
    company_name: "Solar Outdoor Media GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e1c32e88a9c7.jpg?v=1",
    address: "Kemper Platz 1 c/o WeWork, Berlin 10785",
    telephone: "",
    website: "http://solaroutdoormedia.de",
    email: "info@solaroutdoormedia.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarmontage-rostock?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93274&utm_campaign=profiles_installer",
    company_name: "Solarmontage Rostock GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba1674c8ed4.jpg?v=1",
    address: "Krischanweg 7, 18069, Rostock",
    telephone: "+49 381 21067900",
    website: "http://solarmontage-rostock.com/",
    email: "info@solarmontage-rostock.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=87287&utm_campaign=profiles_installer",
    company_name: "Energie- & Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/592782ee7307e.jpg?v=1",
    address: "Ansdorfer Straße 3, 93480 Hohenwarth",
    telephone: "+49 9946 9024353",
    website: "https://www.solarkroner.de",
    email: "info@solarkroner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarkraft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11917&utm_campaign=profiles_installer",
    company_name: "Solarkraft GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/518213aaeac63.bmp?v=1",
    address: "Hirschberger Weg 4, 87437, Kempten",
    telephone: "+49 831 565444",
    website: "http://www.solarkraft-umwelttechnik.de",
    email: "info@solarkraft-umwelttechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarkonig-services?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93210&utm_campaign=profiles_installer",
    company_name: "Solarkönig Services GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b7925f1b0a2.jpg?v=1",
    address: "Buschkamp 14, 48324 Sendenhorst",
    telephone: "+49 2535 931085",
    website: "http://www.solarkoenig.com",
    email: "info@solarkoenig.com",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaritec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93519&utm_campaign=profiles_installer",
    company_name: "Solaritec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6008abe00d435.png?v=1",
    address: "Ladestr. 7a, 15834 Rangsdorf",
    telephone: "+49 33708 938230",
    website: "http://www.solaritec.de",
    email: "info@solaritec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaris-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93516&utm_campaign=profiles_installer",
    company_name: "Solaris Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c06e03a5468.jpg?v=1",
    address: "Aschmattstraße 8, 76532 Baden-Baden",
    telephone: "+49 7221 3944630",
    website: "https://www.solaris-energie.net",
    email: "info@solaris-energie.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,ABB Italy S.p.A.,,,Solaris Green Energy Co., Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/herrmann-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11972&utm_campaign=profiles_installer",
    company_name: "Herrmann Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60795a61c15dc.png?v=1",
    address: "Schnieglinger Str. 192, 90427, Nürnberg",
    telephone: "+49 911 311612",
    website: "http://www.herrmannsolar.de",
    email: "info@solarherrmann.de",
    "Installaliert seit": 1992,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarhelden?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58279&utm_campaign=profiles_installer",
    company_name: "SolarHelden GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b99bfef937c5.png?v=1",
    address: "Geretsrieder Str. 2, 84478 Waldkraiburg",
    telephone: "+49 8638 9846640",
    website: "http://www.solarhelden.de",
    email: "info@solarhelden.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/jorg-schenke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93514&utm_campaign=profiles_installer",
    company_name: "SELL Heizung – Sanitär – Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c06c24926ba.jpg?v=1",
    address: "Stockholmring 1, 23560 Lübeck",
    telephone: "+49 451 77240",
    website: "http://www.solarheizung-sell.de",
    email: "info@solarheizung-sell.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/r-haring-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6647&utm_campaign=profiles_installer",
    company_name: "R. Häring Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5045a5866759d.jpg?v=1",
    address: "Elias-Holl-Straße 22, 86836 Obermeitingen",
    telephone: "+49 82 3279241",
    website: "http://www.solarhaering.de",
    email: "info@solarhaering.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",Xantrex Technology Inc,,,Steca Elektronik GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Q3 Energie GmbH & Co. KG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solargy-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124470&utm_campaign=profiles_installer",
    company_name: "Solargy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb65dbae7e25.jpg?v=1",
    address: "Im Büntefeld 9, 30966 Hemmingen",
    telephone: "+49 511 2348283",
    website: "https://solargy.de",
    email: "info@solargy.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarfuxx?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77807&utm_campaign=profiles_installer",
    company_name: "SolarfuxX GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/582e9dba2a427.jpg?v=1",
    address: "Hertzstraße 16, D-48653 Coesfeld",
    telephone: "+49 2541 9689788",
    website: "http://www.solarfuxx.de",
    email: "info@solarfuxx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/okologgia?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=620&utm_campaign=profiles_installer",
    company_name: "ÖkoLoggia Naturbaustoffe und Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5166830ac7e09.bmp?v=1",
    address: "Akazienhain 2, 31595 Steyerberg",
    telephone: "+49 5764 93050",
    website: "http://www.oekologgia.de",
    email: "info@solarfun-shop.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarfuchs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82006&utm_campaign=profiles_installer",
    company_name: "Solarfuchs",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589d25116cbfb.jpg?v=1",
    address: "Wachtelstr. 25, 22305 Hamburg",
    telephone: "+49 40 61189354",
    website: "http://solarfuchs-hamburg.de",
    email: "info@solarfuchs.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarfritze?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93512&utm_campaign=profiles_installer",
    company_name: "Solarfritze GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6008a917e63a3.png?v=1",
    address: "Kreuzäcker-Ring 7, 63814 Mainaschaff",
    telephone: "+49 6021 5830343",
    website: "http://www.solarfritze.de",
    email: "info@solarfritze.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarfields?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93511&utm_campaign=profiles_installer",
    company_name: "SF SolarFields Energiekonzepte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f60b2652d9ac.PNG?v=1",
    address: "Zeißigstraße 7, 09337 Hohenstein-Ernstthal",
    telephone: "+49 3723 665989",
    website: "http://www.solarfields.de",
    email: "info@solarfields.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-factor?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=66927&utm_campaign=profiles_installer",
    company_name: "Solar Factor GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56d90a7c64b69.jpg?v=1",
    address: "Strandstraße 4, 17192 Waren (Müritz)",
    telephone: "+49 3991 6734100",
    website: "http://www.solarfaktor.de",
    email: "info@solarfaktor.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122019,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarenner-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7350&utm_campaign=profiles_installer",
    company_name: "SolARenner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7350n.gif?v=1",
    address: "Gewerbering 3, D-86922 Eresing",
    telephone: "+49 8193 9960920",
    website: "https://www.shop-muenchner-solarmarkt.de",
    email: "info@solarenner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,SunWare Solartechnik Produktions GmbH und Co. KG,,,Solar Frontier K.K.,,,Panasonic Corporation (Sanyo),,,AxSun Solar GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Phocos AG,,,Studer Innotec,,,Fronius International GmbH,,,Victron Energy BV,,,KACO New Energy GmbH,,,Cotek Electronic Ind. Co., Ltd.,,,Kostal Solar Electric GmbH,,,Voltronic Power Technology Corp.,,,Altenergy Power System Inc.,,,AEconversion GmbH & Co. KG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarenergiezentrum-hochrhein-stefan-drayer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1650&utm_campaign=profiles_installer",
    company_name: "Solarenergiezentrum Hochrhein Stefan Drayer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ccef19e7c8f.jpg?v=1",
    address: "Küssnacher Str. 13, 79801 Hohentengen, Lienheim",
    telephone: "+49 7742 5324",
    website: "http://www.solarenergiezentrum-hochrhein.de",
    email: "info@solarenergiezentrum-hochrhein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarenergie21?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93524&utm_campaign=profiles_installer",
    company_name: "Solarenergie21 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c072abbb88d.jpg?v=1",
    address: "Zum Feldchen 8, 41836 Hückelhoven",
    telephone: "+49 2433 938149",
    website: "http://www.solarenergie21.de",
    email: "info@solarenergie21.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarenergie-netzwerk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75369&utm_campaign=profiles_installer",
    company_name: "SolarEnergieNetzwerk UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c3f4274472b.jpg?v=1",
    address: "Dr. Gottfried-Cremer-Allee 12, D-50226 Frechen",
    telephone: "+49 2234 4308155",
    website: "http://www.solarenergie-netzwerk.de",
    email: "info@solarenergie-netzwerk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,IBC Solar AG,,,SunPower Corp.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/strubich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93510&utm_campaign=profiles_installer",
    company_name: "Strübich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c068d44338f.jpg?v=1",
    address: "Denzlingerstrasse 9, D-79183 Waldkirch-Buchholz",
    telephone: "+49 7681 1047",
    website: "http://www.solarenergie-baden.de",
    email: "info@solarenergie-baden.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/effekt-montage-service-podlich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94796&utm_campaign=profiles_installer",
    company_name: "Effekt Montage Service Podlich",
    logo_url: "",
    address: "Juno Str.6, 93055 Regensburg",
    telephone: "+49 941 7845717",
    website: "https://www.solareffekt.de",
    email: "info@solareffekt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ebner-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81548&utm_campaign=profiles_installer",
    company_name: "Ebner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58845f13d572e.jpg?v=1",
    address: "Boschstrasse 12, 71287 Weissach",
    telephone: "+49 7044 33269",
    website: "https://heizungsbau-ebner.de",
    email: "info@solarebner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solare-werkstatten-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=45749&utm_campaign=profiles_installer",
    company_name: "Weber+Wimmer Solare Werkstätten GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/537471eaad87a.jpg?v=1",
    address: "Bad Höhenstadt 141, 94081 Fürstenzell",
    telephone: "+49 8506 1305",
    website: "http://www.solare-werkstaetten.de",
    email: "info@solare-werkstaetten.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Großbritannien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/klaus-anton-danner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93501&utm_campaign=profiles_installer",
    company_name: "Klaus Anton Danner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf7bef95f6a.jpg?v=1",
    address:
      "Dipl.-Ing. Klaus Danner, Johann-Helminger - Str. 10, 83339 Chieming",
    telephone: "+49 8664 9289427",
    website: "http://www.solardanner.de",
    email: "info@solardanner. de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,JA Solar Holdings Co., Ltd.,,,Jetion Solar (China) Co., Ltd.,,,S-Energy Co., Ltd.,,,Ningbo Huashun Solar Energy Technology Co., Ltd.,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,,,Danfoss A/S,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,Nedap Energy Systems,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solardach-altmark?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10304&utm_campaign=profiles_installer",
    company_name: "Solardach-Altmark",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5800407e90940.jpg?v=1",
    address: "Osterburger Straße 69, 39576 Stendal",
    telephone: "+49 3931 418916",
    website: "http://www.solardach-altmark.de",
    email: "info@solardach-altmark.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarconsult?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7349&utm_campaign=profiles_installer",
    company_name: "SolarConsult AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7349n.gif?v=1",
    address: "Steinbeisstr. 20, D-71691 Freiberg am Neckar",
    telephone: "+49 71 416962660",
    website: "http://www.solarconsult.de",
    email: "info@solarconsult.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarbauer-stemwede?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93499&utm_campaign=profiles_installer",
    company_name: "Solarbauer Stemwede GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf779cc2857.jpg?v=1",
    address: "Molkenstraße 21, 32351 Stemwede-Wehdem",
    telephone: "+49 5773 9919370",
    website: "http://www.solarbauer-stemwede.de",
    email: "info@solarbauer-stemwede.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarbau-chiemgau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7581&utm_campaign=profiles_installer",
    company_name: "Solarbau Chiemgau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7581n.gif?v=1",
    address: "Albert Meindl, Gigling 1, 84550 Feichten a d Alz",
    telephone: "+49 8623 290",
    website: "http://www.solarbau-chiemgau.de",
    email: "info@solarbau-chiemgau.de",
    "Installaliert seit": 1997,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaranlagen-pfanzelt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93497&utm_campaign=profiles_installer",
    company_name: "Solaranlagen Pfanzelt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf7555347ff.jpg?v=1",
    address: "Gewerbepark 4, 87675 Rettenbach",
    telephone: "+49 8860 921422",
    website: "http://www.solaranlagen-pfanzelt.de",
    email: "info@solaranlagen-pfanzelt.de/",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zipser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93650&utm_campaign=profiles_installer",
    company_name: "Zipser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1ee9d6f5e3.jpg?v=1",
    address: "Äußerer Möhnhof 2, 73566 Bartholomä",
    telephone: "+49 7173 7163093",
    website: "http://www.solar-zipser.de",
    email: "info@solar-zipser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ws-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102041&utm_campaign=profiles_installer",
    company_name: "Ws-Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab77b00a0fe.jpg?v=1",
    address: "Langgasse 29, 55234 Albig",
    telephone: "+49 6731 9980999",
    website: "http://www.solar-ws.de",
    email: "info@solar-ws.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/vogel-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105980&utm_campaign=profiles_installer",
    company_name: "Vogel Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b445bb126c65.png?v=1",
    address: "Waldfischbacher Str. 15-19, D-66978 Leimen",
    telephone: "+49 6397 1290400",
    website: "https://vogel-energie.de",
    email: "info@solar-vogel.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jaap?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93488&utm_campaign=profiles_installer",
    company_name: "Jaap GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf6e6d4156a.jpg?v=1",
    address: "Bahnhofstraße 49, 21255 Tostedt",
    telephone: "+49 4182 8457",
    website: "http://www.solar-solar.de",
    email: "info@solar-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heizungsbau-wolfgang-schiemann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93487&utm_campaign=profiles_installer",
    company_name: "Heizungsbau Wolfgang Schiemann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf6d0e5060b.jpg?v=1",
    address: "Gerhart-Hauptmann-Str. 2, 16816 Neuruppin",
    telephone: "+49 3391 2709",
    website: "http://www.solar-schiemann.de",
    email: "info@solar-schiemann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-heiztechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11913&utm_campaign=profiles_installer",
    company_name: "Solar & Heiztechnik Reisinger GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11913n.gif?v=1",
    address: "Am Entbach 11, 83131, Nußdorf",
    telephone: "+49 8034 1687",
    website: "http://www.solar-reisinger.de",
    email: "info@solar-reisinger.de",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-pur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11976&utm_campaign=profiles_installer",
    company_name: "Solar-Pur AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11976n.gif?v=1",
    address: "Am Schlagerfelsen 2, 94163 Saldenburg",
    telephone: "+49 85 049579970",
    website: "http://www.solar-pur.de",
    email: "info@solar-pur.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,SunPower Corp.,,,BYD Company Limited,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-projekt-11?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93583&utm_campaign=profiles_installer",
    company_name: "Solar Projekt 11 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f60c328d3bb8.PNG?v=1",
    address: "Rosmarinweg 5, 86156 Augsburg",
    telephone: "+49 821 2170815",
    website: "https://www.solar-projekt-11.de",
    email: "info@solar-projekt-11.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-power-7?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=48093&utm_campaign=profiles_installer",
    company_name: "Solar Power GmbH & Co. Managing KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53f1813f171d2.jpg?v=1",
    address: "Südfeld 13, D-26907 Walchum",
    telephone: "+49 4963 919220",
    website: "http://www.solar-power-gmbh.de",
    email: "info@solar-power-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarenergie-piller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10565&utm_campaign=profiles_installer",
    company_name: "SolarEnergie Piller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5767445544323.jpg?v=1",
    address: "Maulendorf 3, 94371 Rattenberg",
    telephone: "+49 9963 910620",
    website: "http://www.solar-piller.de",
    email: "info@solar-piller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-nowack?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1755&utm_campaign=profiles_installer",
    company_name: "Solar Nowack",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e269ecca0396.jpg?v=1",
    address: "Lange Straße 2A, 19399, Techentin",
    telephone: "+49 385 3269892",
    website: "http://www.solar-nowack.de",
    email: "info@solar-nowack.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Aleo Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",Steca Elektronik GmbH,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-netzker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81059&utm_campaign=profiles_installer",
    company_name: "Solar Netzker",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6081622a6d1ce.PNG?v=1",
    address: "Alte Bautzener Straße 59, 02999 Lohsa OT Steinitz",
    telephone: "+49 3 572455291",
    website: "http://Www.solar-Netzker.de",
    email: "info@solar-netzker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Canadian Solar Inc.,,,First Solar, Inc.,,,Sunrise Energy Co., Ltd.,,,Risen Energy Co., Ltd.,,,Calyxo GmbH,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,Enertronica Santerno S.p.A.,,,SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Sungrow Power Supply Co., Ltd.,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,,,Fimer S.p.A.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-network-partners?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=23593&utm_campaign=profiles_installer",
    company_name: "Solar Network & Partners",
    logo_url: "https://cdn.enfsolar.com/ID/logo/527caa0c859b9.gif?v=1",
    address: "Corneliusstraße 90, 52511 Geilenkirchen",
    telephone: "+49 2451 4094179",
    website: "http://www.solar-network-partners.de",
    email: "info@solar-network-partners.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mw-photovoltaik-engineering?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93576&utm_campaign=profiles_installer",
    company_name: "MW Photovoltaik Engineering GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c08f1922031.jpg?v=1",
    address: "Antonienstraße 18-20, D-13403 Berlin",
    telephone: "+49 30345 550710",
    website: "http://www.solar-mw.com",
    email: "info@solar-mw.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/andre-thamelt-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10407&utm_campaign=profiles_installer",
    company_name: "André Thämelt Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5343bd576f4a4.jpg?v=1",
    address: "Rockauer Ring 42, 01328, Dresden",
    telephone: "+49 173 5870109",
    website: "http://www.solar-montagen.de",
    email: "info@solar-montagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Sunset Energietechnik GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-melle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10376&utm_campaign=profiles_installer",
    company_name: "Solartechnik Melle GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576742b95864e.jpg?v=1",
    address: "Alte Dorfstraße 13, 27239 Twistringen-Heiligenloh",
    telephone: "+49 4246 964972",
    website: "http://www.solar-melle.de",
    email: "info@solar-melle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Griechenland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-lamprecht?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58299&utm_campaign=profiles_installer",
    company_name: "Solar-Lamprecht",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c9557a52609.jpg?v=1",
    address: "Kronstädter Straße 14a, 90765 Fürth",
    telephone: "+49 911 97905450",
    website: "http://www.solar-lamprecht.de",
    email: "info@solar-lamprecht.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,S-Energy Co., Ltd.,,,Astronergy Co., Ltd. (Chint Solar),,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: ",EDV-Systeme Doris Lamprecht,",
    "Last Update": 12032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-konzept-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=90951&utm_campaign=profiles_installer",
    company_name: "Solar-Konzept Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/597987601d720.jpg?v=1",
    address: "Isekai 1, 20249 Hamburg",
    telephone: "+49 40 39895910",
    website: "http://www.solar-konzept.de",
    email: "info@solar-konzept.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-kittel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75131&utm_campaign=profiles_installer",
    company_name: "Solartechnik Kittel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ab920caf7a9.PNG?v=1",
    address: "Franz-Larcher-Straße 8, 83088 Kiefersfelden",
    telephone: "+49 8033 3023281",
    website: "http://www.solar-kittel.de",
    email: "info@solar-kittel.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Aleo Solar GmbH,,,Kyocera Corporation,,,Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-kaiser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93569&utm_campaign=profiles_installer",
    company_name: "Solar Kaiser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c08b456d036.jpg?v=1",
    address: "Reicheltstraße 3, 03238 Finsterwalde",
    telephone: "+49 3531 8337",
    website: "http://www.solar-kaiser.de",
    email: "info@solar-kaiser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,SunPower Corp.,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-heisse?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1672&utm_campaign=profiles_installer",
    company_name: "Solar Heisse GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55fbaf1139488.jpg?v=1",
    address: "Kelvinstraße 3, 86899 Landsberg am Lech",
    telephone: "+49 8191 944301",
    website: "http://www.solar-heisse.de",
    email: "info@solar-heisse.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarstrom-harnischfeger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84827&utm_campaign=profiles_installer",
    company_name: "Solarstrom Harnischfeger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e72a614307b.jpg?v=1",
    address: "Im Gewerbegebiet 6a, 63637 Jossgrund",
    telephone: "+49 60 599069420",
    website: "http://www.solar-harnischfeger.de",
    email: "info@solar-harnischfeger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4658&utm_campaign=profiles_installer",
    company_name: "SoLaR GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e39b1f99c8c.jpg?v=1",
    address: "Am Rathaus 7, 84095 Furth",
    telephone: "+49 8704 929200",
    website: "http://www.solar-gmbh.de",
    email: "info@solar-gmbh.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",JA Solar Holdings Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-energy-center-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84815&utm_campaign=profiles_installer",
    company_name: "Solar Energy Center GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e70bba426fa.jpg?v=1",
    address: "On the stones 26, 06485 Quedlinburg / OT Gernrode",
    telephone: "+49 39485 65390",
    website: "http://www.sem-thale.de",
    email: "info@solar-energy-mitte.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sec-solar-energy-consult?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64471&utm_campaign=profiles_installer",
    company_name: "SEC Solar Energy Consult Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6054973057347.PNG?v=1",
    address: "Berliner Chaussee 11, 39307 Genthin",
    telephone: "+49 3933 822160",
    website: "http://www.solar-energy-consult.de",
    email: "info@solar-energy-consult.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,Viessmann Werke GmbH & Co. KG,,,Solar Power Technology Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-h-solar-energiekonzepte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1294&utm_campaign=profiles_installer",
    company_name: "S&H Solar-energiekonzepte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59375636c9b22.jpg?v=1",
    address: "Eichgasse 39, D-72379, Hechingen-Boll",
    telephone: "+49 7471 91384",
    website: "http://www.solar-energiekonzepte.de",
    email: "info@solar-energiekonzepte.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-energiedach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1973&utm_campaign=profiles_installer",
    company_name: "Solar Energiedach GmbH PV",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51886546554c3.gif?v=1",
    address: "Messerschmittstr. 2-3, 67681, Sembach",
    telephone: "+49 6303 99960",
    website: "http://www.solar-energiedach.de",
    email: "info@solar-energiedach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-energie-worms?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81773&utm_campaign=profiles_installer",
    company_name: "Solar-Energie-Worms",
    logo_url: "https://cdn.enfsolar.com/ID/logo/sc/5895588d3e062.jpeg?v=1",
    address: "Wormser Landstr. 24, 67551 Worms",
    telephone: "+49 6241 9099186",
    website: "http://solar-energie-worms.de",
    email: "info@solar-energie-worms.de",
    "Installaliert seit": 2015,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-energie-saager?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75597&utm_campaign=profiles_installer",
    company_name: "Solar & Energie Saager GmbH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8de4b55b4a.jpg?v=1",
    address: "Büssinghook 52, 46395 Bocholt",
    telephone: "+49 2871 241180",
    website: "http://www.solar-energie-saager.de",
    email: "info@solar-energie-saager.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarsysteme-boden?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=571&utm_campaign=profiles_installer",
    company_name: "Solar- und Energiesysteme Matthias Boden",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58f02c31beafd.png?v=1",
    address: "St. Michelner Hauptstr. 45, 08132, Mülsen",
    telephone: "+49 3760 12880",
    website: "http://www.solar-energie-boden.de",
    email: "info@solar-energie-boden.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-direct-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=15692&utm_campaign=profiles_installer",
    company_name: "Solar Direct Group",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5768c0c134653.jpg?v=1",
    address: "Gewerbegebiet Säuritzer Str. Ost 1, D-01906, Burkau",
    telephone: "+49 35953 29940",
    website: "http://www.solardirectgroup.com",
    email: "info@solar-direkt-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Sharp Energy Solutions Europe,,,NexPower Technology Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Siemens Energy,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-solartechnik-siemens?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10459&utm_campaign=profiles_installer",
    company_name: "Dach-Solartechnik & Energieberatung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd10e2f3372b.png?v=1",
    address: "Ewaldstraße 18, 45892, Gelsenkirchen",
    telephone: "+49 209 1490301",
    website: "http://www.solar-dachprofi.de",
    email: "info@solar-dachprofi.de .",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-conze?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58247&utm_campaign=profiles_installer",
    company_name: "Solar Conze GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5af260517092c.png?v=1",
    address: "Rother Strasse 5, 57539 Etzbach-Roth",
    telephone: "+49 2682 26290",
    website: "http://www.solar-conze.de",
    email: "info@solar-conze.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-center-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84782&utm_campaign=profiles_installer",
    company_name: "Solar Center",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e5dc0c13a59.jpg?v=1",
    address: "In den Zehn Morgen 33 - 55559 Bretzenheim",
    telephone: "+49 671 9208080",
    website: "http://solar-center.net",
    email: "info@solar-center.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-bau-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93489&utm_campaign=profiles_installer",
    company_name: "Solar-Bau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf6e7dda00c.jpg?v=1",
    address: "Alemannenstrasse 22, 85116 Egweil",
    telephone: "+49 8424 885066",
    website: "http://www.solar-bau.com",
    email: "info@solar-bau.com",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,JA Solar Holdings Co., Ltd.,,,Trina Solar Limited,,,Astronergy Co., Ltd. (Chint Solar),,,LONGi Solar Technology Co., Ltd.,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,,,GoodWe Power Supply Technology Co., Ltd.,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,,,Huawei Technologies Co., Ltd.,,,RCT Power GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-energie-andresen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93562&utm_campaign=profiles_installer",
    company_name: "Solar-Energie Andresen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c087f6b3c17.jpg?v=1",
    address: "Hauptstraße 32, 25917 Sprakebüll",
    telephone: "+49 4662 882660",
    website: "http://www.solar-andresen.com",
    email: "info@solar-andresen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/soladu-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122103&utm_campaign=profiles_installer",
    company_name: "SOLADÜ Energy GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e4a4a27e63df.png?v=1",
    address: "Bokemühlenfeld 30, 33334, Gütersloh",
    telephone: "+49 5241 2108360",
    website: "http://www.soladue-energy.de",
    email: "info@soladue-energy.de",
    "Installaliert seit": 2012,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,Solar Frontier K.K.,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sol-technics-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93557&utm_campaign=profiles_installer",
    company_name: "Sol-Technics-Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c086937e7a3.jpg?v=1",
    address: "Tauberhöhe 18, 97990 Weikersheim",
    telephone: "+49 7934 995358",
    website: "http://www.sol-technics-solutions.de",
    email: "info@sol-technics-solutions.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sol-tec-handelsagentur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93832&utm_campaign=profiles_installer",
    company_name: "Sol-Tec Handelsagentur GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b5a7ec67e.jpg?v=1",
    address: "Breiteweg 25, 88326 Aulendorf",
    telephone: "+49  7525 9456637",
    website: "http://sol-tec-ha.de",
    email: "info@sol-tec-ha.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sofsky-ing?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81769&utm_campaign=profiles_installer",
    company_name: "Sofsky Ing. GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5895558178fe8.jpg?v=1",
    address: "Pirminiusstraße 2, 66907 Glan-Münchweiler",
    telephone: "+49 6383 204",
    website: "http://www.sofskydaecher.de",
    email: "info@sofskydaecher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/soenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81647&utm_campaign=profiles_installer",
    company_name: "Soenergie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60801df3664f8.PNG?v=1",
    address: "Kirschstraße 1, 52441 Linnich",
    telephone: "+49 24 622060370",
    website: "http://www.soenergie.de",
    email: "info@soenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/smp-solar-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=57889&utm_campaign=profiles_installer",
    company_name: "SMP Solar Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e84160f5f436.jpg?v=1",
    address: "Lützner Straße 90-92, 04177 Leipzig",
    telephone: "+49 341 47847300",
    website: "http://www.smp-energy.de",
    email: "info@smp-energy.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Guangdong Fivestar Solar Energy Co., Ltd.,,,Solarwatt GmbH,,,IBC Solar AG,,,Trina Solar Limited,,,Jiangsu SunLink PV Technology Co., Ltd.,,,Panasonic Corporation (Sanyo),,,LONGi Solar Technology Co., Ltd.,,,GCL System Integration Technology Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,Sungrow Power Supply Co., Ltd.,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Envertech Corporation Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/smart-tec-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=129428&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Herne AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd35e9c380d1.png?v=1",
    address: "Grenzweg 18, 44623, Herne",
    telephone: "+49 2323 5921234",
    website: "http://smarttec-haustechnik.de/",
    email: "info@smarttec-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sm-energy-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93556&utm_campaign=profiles_installer",
    company_name: "SM-Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c085f1562a8.jpg?v=1",
    address: "Carl-Zeiss-Straße 4, 95666 Mitterteich",
    telephone: "+49 9633 4007670",
    website: "http://www.sm-energy.de",
    email: "info@sm-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sl-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116900&utm_campaign=profiles_installer",
    company_name: "SL-Holzbau GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ea48ad1eb3.png?v=1",
    address: "Mainau B 8, 97199 Ochsenfurt",
    telephone: "+49 9331 2491",
    website: "https://www.sl-holzbau-gbr.de",
    email: "info@sl-holzbau-gbr.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sky-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64513&utm_campaign=profiles_installer",
    company_name: "Sky Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6054a54a5f80c.PNG?v=1",
    address: "Hauptstraße 13, 01594 Stauchitz",
    telephone: "+49 35268 964001",
    website: "http://www.sky-energie.de",
    email: "info@sky-energie.de/",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sk-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10383&utm_campaign=profiles_installer",
    company_name: "SK Solar Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10383n.gif?v=1",
    address: "Lütke Feld 15, 48329, Havixbeck",
    telephone: "+49 2507 9855700",
    website: "http://www.sksolar.de",
    email: "info@sksolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,JA Solar Holdings Co., Ltd.,,,Canadian Solar Inc.,,,AXITEC Energy GmbH & Co. KG,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),,,LG Electronics, Inc.,,,Suzhou Talesun Solar Technologies Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,SolarEdge Technologies, Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sks-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31575&utm_campaign=profiles_installer",
    company_name: "SKS Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/572174f676d7d.jpg?v=1",
    address: "Dreifaltigkeitsstraße 15, 92224, Amberg",
    telephone: "+49 9621 9759008",
    website: "http://www.sks-energietechnik.de",
    email: "info@sks-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/skb-solartec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75071&utm_campaign=profiles_installer",
    company_name: "SKB SolarTec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bbe557a2bf0.jpg?v=1",
    address: "Christian-Balzer-Str. 10, 35216 Biedenkopf-Wallau",
    telephone: "+49 64 61758740",
    website: "http://www.skb-solartec.com",
    email: "info@skb-solartec.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schroerlucke-keuer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93551&utm_campaign=profiles_installer",
    company_name: "Schröerlücke & Keuer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c084e402521.jpg?v=1",
    address: "Zur Königsbrücke 20, 49549 Ladbergen",
    telephone: "+49 5485 93740",
    website: "http://www.sk-elektro.de",
    email: "info@sk-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sittig-rein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93990&utm_campaign=profiles_installer",
    company_name: "Sittig & Rein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b11f6ce66.jpg?v=1",
    address: "Im Kleinen Bruch 4, 69412 Eberbach",
    telephone: "+49 6271 72982",
    website: "http://www.sittig-rein.de",
    email: "info@sittig-rein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/simply-orange?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94603&utm_campaign=profiles_installer",
    company_name: "Simply Orange GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7f870f0fc.png?v=1",
    address: "Altenhofweg 51a, D-92318 Neumarkt i.d.Opf.",
    telephone: "+49 9181 5091318",
    website: "http://simplyorange.de",
    email: "info@simplyorange.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-osterloher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10760&utm_campaign=profiles_installer",
    company_name: "Simon Osterloher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10760n.gif?v=1",
    address: "Aichet 7, 83137 Schonstett",
    telephone: "+49 8055 1588",
    website: "http://www.simon-osterloher.de",
    email: "info@simon-osterloher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/silithium?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=46815&utm_campaign=profiles_installer",
    company_name: "Silithium Smart Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb16e7a81ee6.png?v=1",
    address: "In Der Warthütte 8, 69181 Leimen",
    telephone: "+49 6224 9263448",
    website: "http://silithium.de",
    email: "info@silithium.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Ertex Solartechnik GmbH,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,S-Energy Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,,,LG Electronics, Inc.,,,BMI Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sideka-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102220&utm_campaign=profiles_installer",
    company_name: "Sideka Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1d6675e1a7.jpg?v=1",
    address: "Talstraße 97, 49479 Ibbenbüren",
    telephone: "+49 5451 50270",
    website: "http://www.sideka-energietechnik.de",
    email: "info@sideka-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,,,First Solar, Inc.,,,Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/karsten-taubner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93550&utm_campaign=profiles_installer",
    company_name: "Karsten Täubner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c083d833040.jpg?v=1",
    address: "Zur Lessingschule 8a, 01705 Freital",
    telephone: "+49 351 6491360",
    website: "http://shk-taeubner.de",
    email: "info@shk-taeubner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/shf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101761&utm_campaign=profiles_installer",
    company_name: "Sanitär- und Heizungstechnik GmbH Frankenberg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa5fafc0e0d3.jpg?v=1",
    address: "Amalienstraße 9, 09669 Frankenberg",
    telephone: "+49 37206 60040",
    website: "http://www.shf-frankenberg.de",
    email: "info@shf-frankenberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/shc-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=26455&utm_campaign=profiles_installer",
    company_name: "SHC Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/518b241538029.jpg?v=1",
    address: "Im Petersfeld 5, 65624, Altendiez",
    telephone: "+49 6039 48910",
    website: "http://www.shc-solar.de",
    email: "info@shc-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solar Fabrik GmbH,,,SunPower Corp.,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Elesta Technology GmbH,",
    "Last Update": 3022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-international-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=129582&utm_campaign=profiles_installer",
    company_name: "Solar International Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe063c968806.jpg?v=1",
    address: "Osterbrooksweg 71, 22869 Schenefeld",
    telephone: "+49 40 88184180",
    website: "http://a1-solar.com",
    email: "info@shago.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,Astronergy Co., Ltd. (Chint Solar),,,Hyundai Energy Solutions,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-h-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76661&utm_campaign=profiles_installer",
    company_name: "S+H Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ecbc042c181.jpg?v=1",
    address: "Heinrich-Hertz-Str. 3F, 34123 Kassel",
    telephone: "+49 561 95380383",
    website: "http://sh-solarenergie.de",
    email: "info@sh-solarenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kappei-sft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1559&utm_campaign=profiles_installer",
    company_name: "Solar Future Technik Kappei GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1559n.gif?v=1",
    address: "Am Park 1, 38274 Elbe",
    telephone: "+49 5345 980015",
    website: "http://www.sft.de",
    email: "info@sft.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sf-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64507&utm_campaign=profiles_installer",
    company_name: "SF Elektro GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6054a251a4734.PNG?v=1",
    address: "Stelzacker 2, 88630 Pfullendorf",
    telephone: "+49 7552 936966",
    website: "http://www.sf-elektro.com",
    email: "info@sf-elektro.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Kyocera Corporation,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnen-energie-zentrum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=637&utm_campaign=profiles_installer",
    company_name: "Sonnen-Energie-Zentrum",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5108d1d64eb05.gif?v=1",
    address: "Gewerbestraße-Süd 2, 26842 Ostrhauderfehn",
    telephone: "+49 4952 826820",
    website: "http://sez-solar.de",
    email: "info@sez-solar.de",
    "Installaliert seit": 1990,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,IBC Solar AG,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,Eaton Corporation,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/set?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64559&utm_campaign=profiles_installer",
    company_name: "Solar- und EnergieTechnik Dr. Volker Bergmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60586aae5db20.PNG?v=1",
    address: "In den Folgen 23A, 98704 Ilmenau",
    telephone: "+49 36 774669977",
    website: "http://www.set-ilmenau.de",
    email: "info@set-ilmenau.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,Trina Solar Limited,,,Solar Frontier K.K.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotech-sepph2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93661&utm_campaign=profiles_installer",
    company_name: "ElektroTech Sepph2 GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c204c239311.jpg?v=1",
    address: "Franz-Ehrenthaler Str. 2, 84160 Frontenhausen",
    telephone: "+49 8732 2197",
    website: "http://www.sepph2.de",
    email: "info@sepph2.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/semrau-solar-mehr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93848&utm_campaign=profiles_installer",
    company_name: "Semrau Solar & Mehr",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4bea4a17b8.jpg?v=1",
    address: "Jettinger Str. 12, 89343 Jettingen-Scheppach",
    telephone: "+49 8225 308404",
    website: "http://www.semrau-solar-mehr.com",
    email: "info@semrau-solar-mehr.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sela-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84789&utm_campaign=profiles_installer",
    company_name: "Sela Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6078193e64a34.PNG?v=1",
    address: "Mackensen Straße 34, 63654 Büdingen",
    telephone: "+49 6042 9791077",
    website: "http://www.sela-photovoltaik.de",
    email: "info@sela-photovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Siemens Energy,,,Kostal Solar Electric GmbH,,,Fimer S.p.A.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/seimsystems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76251&utm_campaign=profiles_installer",
    company_name: "SeimSystems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e09a5eee002.jpg?v=1",
    address: "Marktstraße 24, 35279 Neustadt, Hessen",
    telephone: "+49 6692 919101",
    website: "http://www.seimsolar.de",
    email: "info@seimsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Trina Solar Limited,,,Viessmann Werke GmbH & Co. KG,,,LG Electronics, Inc.,,,CS Wismar GmbH Sonnenstromfabrik,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Sungrow Power Supply Co., Ltd.,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/seibel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101767&utm_campaign=profiles_installer",
    company_name: "Seibel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa5fd8c02ac7.jpg?v=1",
    address: "Prinz-Friedrich-Str. 22, 45257 Essen",
    telephone: "+49 201 8601230",
    website: "http://www.seibel-essen.de",
    email: "info@seibel-essen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/helmut-seger-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7339&utm_campaign=profiles_installer",
    company_name: "Helmut Seger Elektroanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/517b55f588276.bmp?v=1",
    address: "Gisibodenstrasse 17 - 19, 79674 Todtnau, Geschwend",
    telephone: "+49 7671 999690",
    website: "http://www.seger-elektro.de",
    email: "info@seger-elektro.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Schweiz,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/seb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76083&utm_campaign=profiles_installer",
    company_name: "SEB-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d7798abedcc.jpg?v=1",
    address: "Brauereistr. 8a, 77781 Biberach/ Baden",
    telephone: "+49 7835 631115",
    website: "http://www.seb-solar.de",
    email: "info@seb-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/seasolar-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=127579&utm_campaign=profiles_installer",
    company_name: "Seasolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f6959cfe54e3.jpg?v=1",
    address: "Kürfürstendamm 194, 10707 Berlin",
    telephone: "+49 800 513618",
    website: "https://seasolargroup.com",
    email: "info@seasolargroup.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Solon International,,,Aleo Solar GmbH,,,Solar Fabrik GmbH,,,BISOL Group, d.o.o.,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,,,JA Solar Holdings Co., Ltd.,,,Trina Solar Limited,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,Astronergy Co., Ltd. (Chint Solar),,,Jinko Solar Holding Co., Ltd.,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),,,AU Optronics Corporation,,,LG Electronics, Inc.,,,Eco Delta Power Co., Ltd.,,,Csunpower Technology (Nanjing) Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Sungrow Power Supply Co., Ltd.,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,,,GoodWe Power Supply Technology Co., Ltd.,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/scuv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94581&utm_campaign=profiles_installer",
    company_name: "Schmidt Consulting und Vertriebs GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601168afb5753.png?v=1",
    address: "Alleestrasse 35, D-56410 Montabaur",
    telephone: "+49 2602 999510",
    website: "http://www.scuv.de",
    email: "info@scuv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sct-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4898&utm_campaign=profiles_installer",
    company_name: "SCT Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/547fdff6a5a28.gif?v=1",
    address: "Am Wall 84, 14532 Kleinmachnow",
    telephone: "+49 33203 771371",
    website: "http://www.sct-energietechnik.de",
    email: "Info@SCT-Energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schweikart-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94175&utm_campaign=profiles_installer",
    company_name: "Schweikart Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cca8d570006.png?v=1",
    address: "Im Sonnenwinkel 3, 72290 Loßburg",
    telephone: "+49 7446 2530",
    website: "http://schweikart.de",
    email: "info@schweikart.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schwab-gmbh-solar-und-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1624&utm_campaign=profiles_installer",
    company_name: "Schwab GmbH Solar- und Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1624n.gif?v=1",
    address: "Wilhelm-Filchner-Str. 1-3, 68219, Mannheim",
    telephone: "+49 621 896826",
    website: "http://schwab-solartechnik.de",
    email: "info@schwabsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Solar Fabrik GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,EGing Photovoltaic Technology Co., Ltd.,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schwaab-elektrik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58201&utm_campaign=profiles_installer",
    company_name: "Schwaab Elektrik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60212bbadbd5d.png?v=1",
    address: "Am Ehrenmal 10, 54492 Erden",
    telephone: "+49 6532 93246",
    website: "http://www.schwaab-elektrik.de",
    email: "info@schwaab-elektrik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schulze?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102042&utm_campaign=profiles_installer",
    company_name: "Elektro Schulze GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab783e16a05.jpg?v=1",
    address: "Martin-Luther-Straße 5-7, 90542 Eckental",
    telephone: "+49 9126 2934902",
    website: "https://www.schulze-solar.de",
    email: "info@schulze-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schulz-systemtechnik-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=126940&utm_campaign=profiles_installer",
    company_name: "SCHULZ Systemtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f4dc54a03d74.jpg?v=1",
    address: "Schneiderkruger Straße 12, 49429 Visbek",
    telephone: "+49 4445 8970",
    website: "https://www.schulz.st",
    email: "info@schulz.st",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schulz-pv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=55793&utm_campaign=profiles_installer",
    company_name: "Schulz-PV",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6001e3f661b44.PNG?v=1",
    address: "Bahnhofstraße 153, 45770 Marl",
    telephone: "+49 2365 200111",
    website: "http://www.schulz-pv.de",
    email: "info@schulz-pv.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solar Frontier K.K.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroservice-schulz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94644&utm_campaign=profiles_installer",
    company_name: "Elektroservice Schulz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6011c7dab7985.png?v=1",
    address: "Marie-Curie-Straße 24, 73230 Kirchheim/Teck",
    telephone: "+49 7021 485500",
    website: "http://www.schulz-elektroservice.de",
    email: "info@schulz-elektroservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/georg-schutz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93547&utm_campaign=profiles_installer",
    company_name: "Georg Schütz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0806b2b40e.jpg?v=1",
    address: "Hauptstraße 1a, 96120 Bischberg, Tütschengereuth",
    telephone: "+49 9549 7320",
    website: "http://www.schuetz-energie.de",
    email: "info@schuetz-energie.de",
    "Installaliert seit": 1987,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schreyoggbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=114873&utm_campaign=profiles_installer",
    company_name: "SchreyöggBau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ce786b65e565.jpg?v=1",
    address: "Mühlenweg 33, 87724, Ottobeuren, Ollarzried",
    telephone: "+49 8332 925438",
    website: "https://www.schreyoeggbau.de",
    email: "info@schreyoeggbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schreckert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64467&utm_campaign=profiles_installer",
    company_name: "Schreckert GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56a869fe1f2ac.png?v=1",
    address: "Unterer Semmetweg 1, 34311 Naumburg-Altenstädt",
    telephone: "+49 56 25923950",
    website: "http://www.schreckert.de",
    email: "info@schreckert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/erich-schoneboom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93538&utm_campaign=profiles_installer",
    company_name: "Erich Schoneboom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c07c9c59a35.jpg?v=1",
    address: "Harburger Str. 17a, 29640 Schneverdingen",
    telephone: "+49 5193 98410",
    website: "https://www.schoneboom.de",
    email: "info@schoneboom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schneider-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76231&utm_campaign=profiles_installer",
    company_name: "Schneider GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57df97523a5eb.jpg?v=1",
    address: "Pointstraße 2, 97753 Karlstadt-Stetten bei Würzburg",
    telephone: "+49 9360 9939590",
    website: "http://schneider-solar.de",
    email: "info@schneider-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Aleo Solar GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schneider-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81766&utm_campaign=profiles_installer",
    company_name: "Schneider GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589553f94a4ad.jpg?v=1",
    address: "Sankt-Florian-Str. 5, 35041 Marburg, Elnhausen",
    telephone: "+49 6420 839255",
    website: "http://www.schneider-elnhausen.de",
    email: "info@schneider-elnhausen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmitz-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75599&utm_campaign=profiles_installer",
    company_name: "Schmitz Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8e0a94e792.jpg?v=1",
    address: "Waldstraße 5, 54576 Hillesheim-Bolsdorf",
    telephone: "+49 65 9398550",
    website: "http://www.schmitz-haustechnik.de",
    email: "info@schmitz-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-schmiedekamp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93533&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Schmiedekamp GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0794131928.jpg?v=1",
    address: "Postfach 1525, D-32589 Vlotho",
    telephone: "+49 5733 962003",
    website: "http://www.schmiedekamp.de",
    email: "info@schmiedekamp.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmidt-s-team?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93593&utm_campaign=profiles_installer",
    company_name: "Schmidt's Team GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c09872c7535.jpg?v=1",
    address: "Burgstraße 32, 38704 Liebenburg",
    telephone: "+49 5346 946920",
    website: "https://schmidts-team.com",
    email: "info@schmidts-team.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmidt-heizung-sanitar-solar-freiberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81570&utm_campaign=profiles_installer",
    company_name: "Schmidt Heizung, Sanitär, Solar Freiberg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58847a274c6d0.jpg?v=1",
    address: "Dorfstraße 17b, 09569 Kirchbach",
    telephone: "+49 37 29239 30",
    website: "http://www.schmidt-solartechnik.de",
    email: "info@schmidt-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/robert-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94819&utm_campaign=profiles_installer",
    company_name: "Robert Schmidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df080792262.jpg?v=1",
    address: "Stralsunder Str. 7, 49143 Bissendorf",
    telephone: "+49 5402 3101",
    website: "http://www.schmidt-bissendorf.de",
    email: "info@schmidt-bissendorf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schmees-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94715&utm_campaign=profiles_installer",
    company_name: "Schmees Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddd5d26f04b.png?v=1",
    address: "Dieselstraße 12, 26899 Rhede, Ems",
    telephone: "+49 4964 605580",
    website: "http://schmees-energietechnik.de",
    email: "info@schmees-gruppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schlappner-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93682&utm_campaign=profiles_installer",
    company_name: "Schlappner Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c219b6c6ec0.jpg?v=1",
    address: "Am Werrtor 46-48, 68647 Biblis",
    telephone: "+49 6245 90130",
    website: "http://schlappner-elektro.de",
    email: "info@schlappner-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schindler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93342&utm_campaign=profiles_installer",
    company_name: "Schindler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb3f2eabb42.jpg?v=1",
    address: "Rüskeweg 72, 26624 Moorhusen",
    telephone: "+49 4942 912822",
    website: "http://schindler-installationen.de/",
    email: "info@schindler-installationen.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schilloks-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75267&utm_campaign=profiles_installer",
    company_name: "Schilloks Solartechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60aba55bbf430.PNG?v=1",
    address: "Büchener Weg 94, 21481 Lauenburg",
    telephone: "+49 4153 8699700",
    website: "http://www.schilloks-solartechnik.de",
    email: "info@schilloks-solartechnik.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,LG Electronics, Inc.,,,Tesla Energy,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schieber-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=49297&utm_campaign=profiles_installer",
    company_name: "Schieber Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5434d1afe71c6.gif?v=1",
    address: "Weinstraße 2, 74374, Zaberfeld",
    telephone: "+49 7046 930390",
    website: "http://www.schieber-solar.de",
    email: "info@schieber-solar.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Siemens Energy,,,REFU Elektronik GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/alternative-technik-scherf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93527&utm_campaign=profiles_installer",
    company_name: "Alternative Technik Scherf GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0769024fa8.jpg?v=1",
    address: "Calauer Str.12, 03229 Altdöbern",
    telephone: "+49 35434 60450",
    website: "http://www.scherf-alternativ.de",
    email: "info@scherf-alternativ.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/scherer-solarstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8667&utm_campaign=profiles_installer",
    company_name: "Scherer Solarstrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8667n.gif?v=1",
    address: "Gottlieb-Daimler-Str. 26, 76703 Kraichtal",
    telephone: "+49 7250 929110",
    website: "http://www.scherer-solarstrom.de",
    email: "info@scherer-solarstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Jiangsu SunLink PV Technology Co., Ltd.,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten":
      ",Kostal Solar Electric GmbH,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schenk-lehr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93926&utm_campaign=profiles_installer",
    company_name: "Schenk & Lehr",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c881050a486.jpg?v=1",
    address: "Veitswend 1, 91550 Dinkelsbühl",
    telephone: "+49 98 57974620",
    website: "http://www.schenk-lehr.de",
    email: "info@schenk-lehr.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/service-technik-schenk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74427&utm_campaign=profiles_installer",
    company_name: "Service-Technik-Schenk GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ac2939b901e.jpg?v=1",
    address: "Industriestrasse 25, D-37115 Duderstadt",
    telephone: "+49 5527 9145767",
    website: "http://www.strom-sonne.de",
    email: "info@schenk-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schemmer-und-frank?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10539&utm_campaign=profiles_installer",
    company_name: "Schemmer und Frank GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10539n.gif?v=1",
    address: "Daimlerstraße 21, 32791 Lage",
    telephone: "+49 5232 9628130",
    website: "http://www.schemmer-und-frank.de",
    email: "info@schemmer-frank.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schemberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102576&utm_campaign=profiles_installer",
    company_name: "Schemberg GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4422265e54.png?v=1",
    address: "Ingewai 3, 25980 Sylt-Ost",
    telephone: "+49 4651 3611",
    website: "https://schemberg-sylt.com",
    email: "info@schemberg-sylt.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schelzel-bedachungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94409&utm_campaign=profiles_installer",
    company_name: "Schelzel Bedachungs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc1a2a6d974.png?v=1",
    address: "Am Lehmberg 54, 01157 Dresden",
    telephone: "+49 351 429930",
    website: "http://www.schelzel-dach.de",
    email: "info@schelzeldach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/schedetal-folien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119680&utm_campaign=profiles_installer",
    company_name: "Schedetal Folien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dd246a07392d.jpg?v=1",
    address: "Im Schedetal 22, 34346 Hann. Münden",
    telephone: "+49 5541 98350",
    website: "http://www.schedetal.de",
    email: "info@schedetal.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/manfred-schader?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94570&utm_campaign=profiles_installer",
    company_name: "Manfred Schäder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd6912bc45f.png?v=1",
    address: "Sebastian-Bauer-Straße 8, 81737 München",
    telephone: "+49 89 6374842",
    website: "http://www.schaeder-gmbh.de",
    email: "Info@Schaeder-GmbH.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",ABB Italy S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/saxony-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=89635&utm_campaign=profiles_installer",
    company_name: "Saxony Solar AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5963402455440.jpg?v=1",
    address: "Schubertstraße 37, 08058 Zwickau",
    telephone: "+49 375 21439147",
    website: "http://www.saxony-solar.eu",
    email: "info@saxony-solar.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Sharp Energy Solutions Europe,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sawida?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95586&utm_campaign=profiles_installer",
    company_name: "Firma Sawida Dachdeckermeisterbetrieb",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59fa6e0930a60.jpg?v=1",
    address: "Contrescarpe 63, 28195 Bremen",
    telephone: "+49 421 5720515",
    website: "http://www.sawida.de",
    email: "info@sawida.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sarauer-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93322&utm_campaign=profiles_installer",
    company_name: "Sarauer EnergieTechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb2ea66b108.jpg?v=1",
    address: "Am Galgenfeld 3, 86554 Pöttmes",
    telephone: "+49 8253 995880",
    website: "http://sarauer-energie.de",
    email: "info@sarauer-energietechnik.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sao?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75137&utm_campaign=profiles_installer",
    company_name: "Solartechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd164d52c2b.jpg?v=1",
    address: "Friedrich-List Straße 26, 88353 Kißlegg-Zaisenhofen",
    telephone: "+49 75 63911240",
    website: "http://www.sao-solar.de",
    email: "info@sao-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wulff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93526&utm_campaign=profiles_installer",
    company_name: "Wulff GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c075a96de90.jpg?v=1",
    address: "Lauenburger Str. 37, 21514 Büchen",
    telephone: "+49 4155 2048",
    website: "http://www.wulff-buechen.de",
    email: "info@sanitaerwulff.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sandmeyer-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102236&utm_campaign=profiles_installer",
    company_name: "Sandmeyer Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab207c0aaae1.jpg?v=1",
    address: "Schmiedestraße 6, 21781 Cadenberge",
    telephone: "+49 4777 800120",
    website: "https://cux-solar.de",
    email: "info@sandmeyer-elektro.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Solar Frontier K.K.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sames-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75887&utm_campaign=profiles_installer",
    company_name: "Sames Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d0bfb849755.jpg?v=1",
    address: "Grüner Weg 11, 35041 Marburg",
    telephone: "+49 6421 31611",
    website: "http://www.sames-solar.de",
    email: "info@sames-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,Nedap Energy Systems,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sailerenergy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93272&utm_campaign=profiles_installer",
    company_name: "Sailer Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba15e91879a.jpg?v=1",
    address: "Zementwerkstraße 17, D-89584 Ehingen/Donau",
    telephone: "+49 7391 500250",
    website: "http://www.sailerenergy.de",
    email: "info@sailerenergy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/saam?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101815&utm_campaign=profiles_installer",
    company_name: "Saam GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa72b3b182a7.jpg?v=1",
    address: "Lise-Meitner-Str. 6, 53332 Bornheim",
    telephone: "+49 2227 925050",
    website: "http://www.saamgmbh.de",
    email: "info@saamgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-c-sanitarservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102340&utm_campaign=profiles_installer",
    company_name: "S+C Sanitärservice GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab99b3f91aa4.jpg?v=1",
    address: "Birkenweg 2, 91058 Erlangen",
    telephone: "+49 9131 9341361",
    website: "http://www.s-plus-c.de",
    email: "info@s-plus-c.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-info-zentrum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1971&utm_campaign=profiles_installer",
    company_name: "Solar-Info-Zentrum GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/577c6cda7e215.jpg?v=1",
    address: "Solarparkstr. 1, 67435 Neustadt/W",
    telephone: "+49 6327 978680",
    website: "http://www.s-i-z.de",
    email: "info@s-i-z.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rw-solar-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82450&utm_campaign=profiles_installer",
    company_name: "RW Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abfaa55e65d.jpg?v=1",
    address: "Kalthofer Feld 23, 58640 Iserlohn",
    telephone: "+49 2371 9558850",
    website: "https://www.rw-solar.de",
    email: "info@rw-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ruoff-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1984&utm_campaign=profiles_installer",
    company_name: "Ruoff Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/538e9890b1e89.gif?v=1",
    address: "Rudolf-Diesel-Strasse 5, 72585 Riederich",
    telephone: "+49 7123 934190",
    website: "http://www.ruoff-solar.de",
    email: "info@ruoff-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-bernatzki?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75167&utm_campaign=profiles_installer",
    company_name: "Solartechnik Dietmar Bernatzki",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd368f5b20d.jpg?v=1",
    address: "Thingstraße 27, 45527 Hattingen",
    telephone: "+49 2324 3897140",
    website: "https://www.ruhrsolar.de",
    email: "info@ruhrsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ruttger-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101832&utm_campaign=profiles_installer",
    company_name: "Rüttger Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa73f1b9e6b4.jpg?v=1",
    address: "Am Kirschberg 14, 97218 Gerbrunn",
    telephone: "+49 931 404150",
    website: "https://www.ruettger-dach.de",
    email: "info@ruettger-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ruthlein-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94037&utm_campaign=profiles_installer",
    company_name: "Rüthlein Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ee42b2d21.jpg?v=1",
    address: "Untere Hauptstraße 23, 97241 Bergtheim",
    telephone: "+49 9367 984395",
    website: "http://www.ruethlein-elektrotechnik.de",
    email: "info@ruethlein-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/markus-ruckert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94407&utm_campaign=profiles_installer",
    company_name: "Markus Rückert Dachdecker und Spenglermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc17366dc22.png?v=1",
    address: "Am Kirschbaum 6, 63856 Bessenbach",
    telephone: "+49 6095 9976730",
    website: "http://www.rueckert-dach.de",
    email: "info@rueckert-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rosenhovel-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74473&utm_campaign=profiles_installer",
    company_name: "Rosenhövel Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ad3b34e9700.jpg?v=1",
    address: "Braamer Straße 134, 59071 Hamm",
    telephone: "+49 23 8181788",
    website: "http://www.rosenhoevel-elektrotechnik.de",
    email: "info@rosenhoevel-elektrotechnik.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rolf-preis?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75701&utm_campaign=profiles_installer",
    company_name: "Rolf Preis Lüften und Heizen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ccefc48cb02.jpg?v=1",
    address: "Weidenweg 4a, 79790 Küssaberg-Kadelburg",
    telephone: "+49 7741 65693",
    website: "http://www.rolf-preis.de",
    email: "info@rolf-preis.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rower?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1588&utm_campaign=profiles_installer",
    company_name: "Röwer Sicherheits- und Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5719b9b243b8a.jpg?v=1",
    address: "Im Felde 2, 49076 Osnabrück",
    telephone: "+49 541 13970",
    website: "http://www.roewer.de",
    email: "info@roewer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rottinger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102226&utm_campaign=profiles_installer",
    company_name: "Röttinger Daten@Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1f0fa91240.jpg?v=1",
    address: "Fritz-Hopf-Straße 6b, 86720 Nördlingen",
    telephone: "+49 9081 799443",
    website: "http://www.roettinger.de",
    email: "info@roettinger.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ronne-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10535&utm_campaign=profiles_installer",
    company_name: "Rönne Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53b4b2a37aae1.jpg?v=1",
    address: "Textilstraße 16, 48465, Schüttorf",
    telephone: "+49 5923 902270",
    website: "http://www.roenne-technik.de",
    email: "info@roenne-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/roder-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10409&utm_campaign=profiles_installer",
    company_name: "Röder Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/542901a88c5a3.jpg?v=1",
    address: "Magdeburger Chaussee 60, 06118, Halle",
    telephone: "+49 345 2396700",
    website: "http://solaranlagen-halle.de",
    email: "info@roeder-solar.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ritter-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74485&utm_campaign=profiles_installer",
    company_name: "Ritter Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ad436a6f0bf.jpg?v=1",
    address: "Lise-Meitner-Str. 12, D - 79100 Freiburg",
    telephone: "+49 761 21417754",
    website: "http://www.ritter-elektrotechnik.com",
    email: "info@ritter-elektrotechnik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/riester-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102242&utm_campaign=profiles_installer",
    company_name: "Riester Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab20e974d279.jpg?v=1",
    address: "Wolfbühl 32, 88637 Leibertingen",
    telephone: "+49 7466 296",
    website: "http://www.riester-holzbau.de",
    email: "info@riester-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/riera-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29787&utm_campaign=profiles_installer",
    company_name: "Riera Elektrotechnik OHG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602e4f3c3ac41.png?v=1",
    address: "Im Bodendeichsfeld 4, 31275, Lehrte, Ahlten",
    telephone: "+49 51 327047",
    website: "http://riera-elektrotechnik.com/",
    email: "info@riera-elektrotechnik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarwerkstatt-berlin?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=602&utm_campaign=profiles_installer",
    company_name: "Solarwerkstatt Berlin GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602n.gif?v=1",
    address: "Rohrbachstraße 13a, 12307 Berlin",
    telephone: "+49 30 74001193",
    website: "http://www.richtung-sonne.de",
    email: "info@richtung-sonne.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rheinland-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75015&utm_campaign=profiles_installer",
    company_name: "Rheinland Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a7bb644e146.PNG?v=1",
    address: "Welserstraße 8, 41468 Neuss",
    telephone: "+49 2131 595280",
    website: "http://www.rheinlandsolar.de",
    email: "info@rheinland-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,IBC Solar AG,,,Phono Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rfm-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94067&utm_campaign=profiles_installer",
    company_name: "Rfm Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca0e3b03b6c.jpg?v=1",
    address: "Hermann-Scheer-Str. 1, 26736 Krummhörn",
    telephone: "+49 23 8059952",
    website: "http://rfm-energie.de",
    email: "info@rfm-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 27032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/retec-merz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102222&utm_campaign=profiles_installer",
    company_name: "Retec Merz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1dc0462b49.jpg?v=1",
    address: "Eichendorffstr. 22, 78166, Donaueschingen",
    telephone: "+49 771 3042",
    website: "https://retec-merz.de",
    email: "info@retec-merz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rest-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95084&utm_campaign=profiles_installer",
    company_name: "Rest Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85c908ab9d.jpg?v=1",
    address: "Goethestraße 28, 63762 Großostheim",
    telephone: "+49 6026 8578",
    website: "http://www.rest-bedachungen.de",
    email: "info@rest-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/revotec-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10264&utm_campaign=profiles_installer",
    company_name: "Revotec Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c8204c5ee9d.jpg?v=1",
    address: "Im Bühl 10, 71287, Weissach-Flacht",
    telephone: "+49 7044 9056820",
    website: "http://www.revotec-energy.de",
    email: "info@rest-bedachungen.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rensch-haus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=113330&utm_campaign=profiles_installer",
    company_name: "Rensch-Haus Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c9d7f4b4fee3.jpg?v=1",
    address: "Mottener Straße 13, 36148, Kalbach-Uttrichshausen",
    telephone: "+49 9742 910",
    website: "https://www.rensch-haus.com",
    email: "info@rensch-haus.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/renos-solar-and-green-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82458&utm_campaign=profiles_installer",
    company_name: "RENOS Solar and Green Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58ac00b174f7b.jpg?v=1",
    address: "Gewerbepark 10, 49143 Bissendorf",
    telephone: "+49 5402 9790322",
    website: "http://renos-energy.de",
    email: "info@renos-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/renneberg-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93893&utm_campaign=profiles_installer",
    company_name: "Renneberg Bedachungen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c86a83ed8e5.jpg?v=1",
    address: "Westergrund 14, 32427, Minden",
    telephone: "+49 571 7797710",
    website: "http://www.renneberg-bedachungen.de",
    email: "info@renneberg-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/klaudia-kutscher-remy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124876&utm_campaign=profiles_installer",
    company_name: "Klaudia Kutscher-Remy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecfbf13e69de.png?v=1",
    address: "Sebastianusstraße, 6, 46487 Wesel-Büderich",
    telephone: "+49 2803 611",
    website: "http://remy-dach.de/",
    email: "info@remy-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/remember-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11881&utm_campaign=profiles_installer",
    company_name: "Remember Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576750cbc9074.jpg?v=1",
    address: "Lütticherstr. 321, 52074 Aachen",
    telephone: "+49 241 403249",
    website: "http://www.remember-solartechnik.de",
    email: "info@remember-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rela-elektroservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101811&utm_campaign=profiles_installer",
    company_name: "Rela Elektroservice GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa729cf61418.jpg?v=1",
    address: "Bischof-Wurm-Platz 2, 75417 Mühlacker",
    telephone: "+49 7041 5219",
    website: "http://rela-elektroservice.de",
    email: "info@rela-elektroservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-reiter-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10009&utm_campaign=profiles_installer",
    company_name: "Elektro Reiter GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10009n.gif?v=1",
    address: "Gewerbering 20, 85258 Weichs",
    telephone: "+49 8136 893290",
    website: "http://www.reiter-elektrotechnik.de",
    email: "info@reiter-elektrotechnik.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-reiter-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94723&utm_campaign=profiles_installer",
    company_name: "Elektro Reiter GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddd820b5455.png?v=1",
    address: "Herdweg 15, D-86647 Buttenwiesen",
    telephone: "+49 8274 310390",
    website: "http://www.reiter-elektro.de",
    email: "info@reiter-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Solon International,,,Galaxy Energy GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/reinergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93709&utm_campaign=profiles_installer",
    company_name: "Firma Reinergie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c311956dc8a.jpg?v=1",
    address: "​Reinachstrasse 27, 79112 Freiburg",
    telephone: "+49 157 87653475",
    website: "http://www.reinergie.de",
    email: "info@reinergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Schweiz, Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kurt-reiner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75539&utm_campaign=profiles_installer",
    company_name: "Reiner-Dach GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdc957347ce0.png?v=1",
    address: "Trinkbornstrasse 5, 56281 Dörth",
    telephone: "+49 6747 7666",
    website: "http://www.reiner-dach.de",
    email: "info@reiner-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/reine-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=48643&utm_campaign=profiles_installer",
    company_name: "Reine Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/603c72c6c565f.png?v=1",
    address: "Birkenallee 121, 48431, Rheine",
    telephone: "+49 5971 8081720",
    website: "http://reine-energie.de/",
    email: "info@reine-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanover Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/reichhard?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106436&utm_campaign=profiles_installer",
    company_name: "Reichhard",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b56a24ba755e.png?v=1",
    address: "Rudolf Diesel Straße 11, 97318 Kitzingen",
    telephone: "+49 9321 32970",
    website: "https://www.reichhard.de",
    email: "info@reichhard.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/reichel-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93345&utm_campaign=profiles_installer",
    company_name: "Reichel Elektroanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb4087cdfc0.jpg?v=1",
    address: "Carl-Poellath-Str. 29, 86529 Schrobenhausen",
    telephone: "+49 8252 2053",
    website: "http://reichel-elektro.de/",
    email: "info@reichel-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-reich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10795&utm_campaign=profiles_installer",
    company_name: "Zimmerei Reich GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10795n.gif?v=1",
    address: "Dorfstrasse 26, 86447 Aindling-Gaulzhofen",
    telephone: "+49 8207 468",
    website: "http://www.reich-holzbau.de",
    email: "info@reich-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rehl-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=114861&utm_campaign=profiles_installer",
    company_name: "Rehl Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ce765bfeb730.jpg?v=1",
    address: "Am Weißen Haus 9, 56626 Andernach",
    telephone: "+49 2632 9894050",
    website: "https://rehl-energy.de",
    email: "info@rehl-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,Solar Fabrik GmbH,,,Sharp Energy Solutions Europe,,,Astronergy Co., Ltd. (Chint Solar),,,LG Electronics, Inc.,,,CS Wismar GmbH Sonnenstromfabrik,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Schneider Electric SA,,,AEconversion GmbH & Co. KG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/reh-geil?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93915&utm_campaign=profiles_installer",
    company_name: "Thilo Reh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/600b34548db8d.png?v=1",
    address: "An der Hessel 9, 35713 Eschenburg",
    telephone: "+49 2774 921926",
    website: "http://www.reh-dachdecker.de",
    email: "info@reh-dachdecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/regetech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93221&utm_campaign=profiles_installer",
    company_name: "Regetech GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b79db08db81.jpg?v=1",
    address: "Hagenbusch 14, 59505 Bad Sassendorf",
    telephone: "+49 2927 9193700",
    website: "http://www.regetech.de",
    email: "info@regetech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Sungrow Power Supply Co., Ltd.,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/regensys?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4577&utm_campaign=profiles_installer",
    company_name: "RegEnSys - Regenerative Energie Systeme e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4577n.gif?v=1",
    address: "Offenbachstrasse 23, D- 67227 Frankenthal",
    telephone: "+49 6233 4593933",
    website: "http://www.regensys.de",
    email: "info@regensys.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Trina Solar Limited,,,Astronergy Co., Ltd. (Chint Solar),,,Viessmann Werke GmbH & Co. KG,,,Hyundai Energy Solutions,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/regensol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94026&utm_campaign=profiles_installer",
    company_name: "RegEnSol",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9d6189a12f.jpg?v=1",
    address: "Adlerweg 15, 82140 Olching",
    telephone: "+49 8142 445664",
    website: "http://www.regensol.de",
    email: "info@RegEnSol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Astronergy Co., Ltd. (Chint Solar),,,LG Electronics, Inc.,,,Soluxtec GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rega-dach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=100403&utm_campaign=profiles_installer",
    company_name: "Rega Dach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a77b6326af74.jpg?v=1",
    address: "Würmtalstraße 12 b, 75181, Pforzheim",
    telephone: "+49 7231 468319",
    website: "https://www.regadach.de",
    email: "info@regadach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/reeg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94797&utm_campaign=profiles_installer",
    company_name: "REEG - Regenerative Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deebb593d6d.png?v=1",
    address: "Pröda 1, 01665 Käbschütztal OT Pröda",
    telephone: "+49 171 4716742",
    website: "http://www.reeg-solar.de",
    email: "info@reeg-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/reckonergy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58121&utm_campaign=profiles_installer",
    company_name: "Reckonergy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c181020daf8.jpg?v=1",
    address: "DeineEnergie Nord UG, Grunewaldstraße 21, 10823 Berlin",
    telephone: "+49 152 23279034",
    website: "http://www.reckonergy.de",
    email: "info@reckonergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11122019,
  },
  {
    company_url:
      "https://de.enfsolar.com/rebele?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81764&utm_campaign=profiles_installer",
    company_name: "Rebele GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589552dfdd70d.jpg?v=1",
    address: "Römerweg 3, 86675 Buchdorf",
    telephone: "+49 9099 1380",
    website: "http://www.rebele-buchdorf.de",
    email: "info@rebele-buchdorf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rebatec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93918&utm_campaign=profiles_installer",
    company_name: "ReBaTec UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87d4c5056a.jpg?v=1",
    address: "Dorfstr. 2, 39326 Samswegen, Niedere Börde",
    telephone: "+49 157 88160506",
    website: "http://rebatec-ug.de",
    email: "info@rebatec-ug.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rawe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82405&utm_campaign=profiles_installer",
    company_name: "Rawe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abc0c44dd87.jpg?v=1",
    address: "Halterner Str. 83, 45657 Recklinghausen",
    telephone: "+49 2361 406780",
    website: "http://www.rawe-gmbh.com",
    email: "info@rawe-gmbh.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ravolta?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94129&utm_campaign=profiles_installer",
    company_name: "Ravolta GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb6a180cfd8.jpg?v=1",
    address: "Pfarrweg 58, 72379 Hechingen",
    telephone: "+49 7477 928250",
    website: "http://www.ravolta.de",
    email: "info@ravolta.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ing-d-raubald?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93314&utm_campaign=profiles_installer",
    company_name: "Ing. D. Raubald GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb269068ea7.jpg?v=1",
    address: "Weinbergstr. 23, 38855, Wernigerode",
    telephone: "+49 3943 92330",
    website: "http://raubald.de/",
    email: "info@raubald.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroservice-rath?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94652&utm_campaign=profiles_installer",
    company_name: "Elektroservice Rath GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb9c7145ac.png?v=1",
    address: "Wimmersweg 28, D-47807 Krefeld",
    telephone: "+49 2151 300576",
    website: "http://www.rath-elektro.de",
    email: "info@rath-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rasolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10585&utm_campaign=profiles_installer",
    company_name: "Rasolar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10585n.gif?v=1",
    address: "Ausbau 9, 18299, Dolgen am See OT Sabel",
    telephone: "+49 38454 21188",
    website: "http://www.rasolar.de",
    email: "info@rasolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rameil-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94231&utm_campaign=profiles_installer",
    company_name: "Rameil Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc64c1e1c1.png?v=1",
    address: "Auf der Jenseite 19, 57368 Lennestadt Saalhausen",
    telephone: "+49 2723 8200",
    website: "https://www.dachdecker-rameil.de",
    email: "info@rameil-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ra-lux?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93981&utm_campaign=profiles_installer",
    company_name: "Ra Lux AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ac0b228a4.jpg?v=1",
    address: "Ziegeleistr. 9, 08233 Treuen OT Eich",
    telephone: "+49 37468 68670",
    website: "http://www.ralux-ag.de",
    email: "info@ralux-ag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ralph-schweitzer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93980&utm_campaign=profiles_installer",
    company_name: "Ralph Schweitzer GmbH Heizung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ab5019aba.jpg?v=1",
    address: "Friedrichstraße 48, 68723 Schwetzingen",
    telephone: "+49 6202 9786730",
    website: "https://www.ralph-schweitzer-gmbh.de",
    email: "info@ralph-schweitzer-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rainbow-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74257&utm_campaign=profiles_installer",
    company_name: "Rainbow Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57a7f2566efea.jpg?v=1",
    address: "Rankestraße 12, 99310 Arnstadt",
    telephone: "+49 3628 5180278",
    website: "http://www.rainbowenergy.de",
    email: "info@rainbowenergy.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/raichle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1671&utm_campaign=profiles_installer",
    company_name: "Raichle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4116.gif?v=1",
    address: "Hauptstr 2, 88525 Dürmentingen",
    telephone: "+49 7371 950980",
    website: "http://www.raichle-gmbh.de",
    email: "info@raichle-gmbh.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Nedap Energy Systems,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/raible-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84819&utm_campaign=profiles_installer",
    company_name: "Raible Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/607721c532090.PNG?v=1",
    address: "Dieselstraße 6, 71277 Rutesheim",
    telephone: "+49 7152 3199957",
    website: "https://raible.solar",
    email: "info@raible.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/raebiger-solar-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82749&utm_campaign=profiles_installer",
    company_name: "Raebiger Solar Heizung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b3d2205fe51.jpg?v=1",
    address: "Hechenwanger Str.7, 86949 Windach",
    telephone: "+49 8193 9979850",
    website: "http://raebiger-solar-heizung.de",
    email: "info@raebiger-solar-heizung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/radotronic?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1567&utm_campaign=profiles_installer",
    company_name: "Radotronic Elektrotechnische Systeme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/586f5451d9d6f.jpg?v=1",
    address: "Über der Landwehr 19, 37520 Osterode am Harz, OT Lasfelde",
    telephone: "+49 5522 31050",
    website: "http://www.radotronic.de",
    email: "info@radotronic.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ralf-arndt-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103553&utm_campaign=profiles_installer",
    company_name: "Ralf Arndt Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebcf72cda01.jpg?v=1",
    address: "Hagenerstraße 199, 44229 Dortmund-Kirchhörde",
    telephone: "+49 231 9761124",
    website: "http://www.ra-elektro.de",
    email: "info@ra-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/qg-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76253&utm_campaign=profiles_installer",
    company_name: "QG Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e09b4dbc881.jpg?v=1",
    address: "Friesenweg 5, Entrance 5.2, 22763 Hamburg",
    telephone: "+49 40 20948390",
    website: "http://qg-solar.info",
    email: "info@qg-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-construction?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94851&utm_campaign=profiles_installer",
    company_name: "PV Construction Germany GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c36f2253c815.jpg?v=1",
    address: "Unnerweg 68, DE-49688 Lastrup",
    telephone: "+49 4472 950590",
    website: "http://www.pvconstruction.de",
    email: "info@pvconstruction.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93598&utm_campaign=profiles_installer",
    company_name: "PV-Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0aecd612ee.jpg?v=1",
    address: "Industriestraße 2B, 47623 Kevelaer",
    telephone: "+49 2832 9752801",
    website: "http://www.pvass.com",
    email: "info@pvass.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-technical-services?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93641&utm_campaign=profiles_installer",
    company_name: "PV-Technical Services GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1eb38b3a47.jpg?v=1",
    address: "Sarreiterweg 5, 85560 Ebersberg",
    telephone: "+49 8092 8604820",
    website: "http://www.pv-technical-services.de",
    email: "info@pv-ts.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-solartechnik-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10230&utm_campaign=profiles_installer",
    company_name: "PV Solartechnik Gmbh & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10230n.gif?v=1",
    address: "Butzbacherstrasse 52, 60389 Frankfurt am Main",
    telephone: "+49 69 46994828",
    website: "http://www.pv-solartechnik.de",
    email: "info@pv-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-korten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58191&utm_campaign=profiles_installer",
    company_name: "PV-Korten GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c42316b8ef5.jpg?v=1",
    address: "Industriestr.11, 48739 Legden",
    telephone: "+49 2566 9331630",
    website: "http://www.pv-korten.de",
    email: "info@pv-korten.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Trina Solar Limited,,,Canadian Solar Inc.,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/viessmann-bottger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64697&utm_campaign=profiles_installer",
    company_name: "Viessmann & Böttger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e8c5fcc8add5.png?v=1",
    address: "Gottlieb-Daimler-Straße 8, 31552 Rodenberg",
    telephone: "+49 57 239865670",
    website: "https://www.sparemitsolar.de",
    email: "info@pv-fachbetrieb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-experte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=118301&utm_campaign=profiles_installer",
    company_name: "PV Experte",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d75ed929b79a.png?v=1",
    address: "Haarbachweg 12, 94474 Pleinting",
    telephone: "+49 175 4493059",
    website: "http://www.pv-experte.de",
    email: "info@pv-experte.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-conception?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94720&utm_campaign=profiles_installer",
    company_name: "PV Conception GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddd7764578c.png?v=1",
    address: "Heideweg 6, 26160 Bad Zwischenahn OT Ofen",
    telephone: "+49 441 4806610",
    website: "http://www.pv-conception.de",
    email: "info@pv-conception.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/p-s-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=629&utm_campaign=profiles_installer",
    company_name: "P & S-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11078.gif?v=1",
    address: "Färberstraße 8, 26340 Zetel",
    telephone: "+49 4453 938199",
    website: "http://www.solarhandwerk.de",
    email: "info@punds-zetel.de",
    "Installaliert seit": 1992,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/psg-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75947&utm_campaign=profiles_installer",
    company_name: "PSG Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d20cd327e48.jpg?v=1",
    address: "Schulstraße 7, 06386 Osternienburg",
    telephone: "+49 34973 291773",
    website: "http://solar-köthen.de",
    email: "info@psg-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/psg-reinigung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30657&utm_campaign=profiles_installer",
    company_name: "PSG-Reinigung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be2d63d17d6.jpg?v=1",
    address: "Lutz Birnstein, OT Barnitz 24, 01665, Käbschützeal",
    telephone: "+49 35244 41324",
    website: "http://psg-reinigung.de",
    email: "info@psg-reinigung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/protea-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22796&utm_campaign=profiles_installer",
    company_name: "Protea Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/22796n.gif?v=1",
    address: "Sielminger Hauptstr. 15, 70794, Filderstadt",
    telephone: "+49 711 508863600",
    website: "http://www.proteaenergy.com",
    email: "info@proteaenergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/prohelios?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11887&utm_campaign=profiles_installer",
    company_name: "ProHelios Regenerative Energiesysteme GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5160dfe67d9ab.bmp?v=1",
    address: "Am Hohlweg 1a, 56368, Katzenelnbogen",
    telephone: "+49 6486 900400",
    website: "http://www.prohelios.de",
    email: "info@prohelios.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pro-energy-solutions-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74901&utm_campaign=profiles_installer",
    company_name: "Pro Energy Solutions",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b66baced6fd.jpg?v=1",
    address: "Alfred-Lippmann-Str. 4, 66386 St. Ingbert",
    telephone: "+49 6894 9906923",
    website: "http://www.pro-energy-solutions.de",
    email: "info@pro-energy-solutions.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/prisma?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102579&utm_campaign=profiles_installer",
    company_name: "Prisma Neue Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac444b78dd4f.png?v=1",
    address: "Rockwinkeler Landstrasse 117A, 28325 Bremen",
    telephone: "+49 421 95700100",
    website: "http://www.prisma-neue-energie.de",
    email: "info@prisma-neue-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/priogo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10532&utm_campaign=profiles_installer",
    company_name: "Priogo AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10532n.gif?v=1",
    address: "Römerallee 80, 53909, Zulpich",
    telephone: "+49 2252 835210",
    website: "http://www.priogo.com",
    email: "info@priogo.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/prima-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22767&utm_campaign=profiles_installer",
    company_name: "Prima Gruppe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fc8a79ece983.png?v=1",
    address: "Junckerstr. 10, 16816 Neuruppin",
    telephone: "+49 3391 400200",
    website: "http://www.prima-gruppe.com",
    email: "info@prima-gruppe.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/preissinger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95151&utm_campaign=profiles_installer",
    company_name: "Preissinger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee94e4ee265.jpg?v=1",
    address: "Hohenfelsstraße 46, 90455 Nürnberg",
    telephone: "+49 9129 908645",
    website: "http://preissinger-dach.de",
    email: "info@preissinger-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/praml?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10571&utm_campaign=profiles_installer",
    company_name: "Praml GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5768d68f468f3.jpg?v=1",
    address: "Passauer Str. 36, 94161 Ruderting",
    telephone: "+49 8509 90060",
    website: "http://www.praml.de",
    email: "info@praml.de",
    "Installaliert seit": 1983,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energetik-solartechnologie-vertriebs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8370&utm_campaign=profiles_installer",
    company_name: "Energetik Solartechnologie Vertrieb GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f777739cc0cc.png?v=1",
    address: "Nikolaus-Otto-Str. 18, 33335 Gütersloh",
    telephone: "+49 5241 505110",
    website: "http://powershop.online/",
    email: "info@powershop.online",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 3102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/potthast-sagel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93352&utm_campaign=profiles_installer",
    company_name: "Potthast & Sagel GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb44045dd86.jpg?v=1",
    address: "Löwendorf 22, 37696 Marienmünster",
    telephone: "+49 5277 952014",
    website: "http://www.ps-schaltanlagen.de",
    email: "info@potthast-sagel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,IBC Solar AG,,,EGing Photovoltaic Technology Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Sharp Energy Solutions Europe,,,Hyundai Energy Solutions,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,Future Smart Technologies Pvt. Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/popiolek-fassaden?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102019&utm_campaign=profiles_installer",
    company_name: "Popiolek Fassaden GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6c7a29848.jpg?v=1",
    address: "Daimlerstraße 6, 61352 Bad Homburg vor der Höhe",
    telephone: "+49 6172 6818890",
    website: "https://www.popiolek-fassaden.net",
    email: "info@popiolek-fassaden.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pohlen-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22480&utm_campaign=profiles_installer",
    company_name: "Pohlen Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a292fe4656e.png?v=1",
    address: "Am Pannhaus 2-10, D-52511 Geilenkirchen",
    telephone: "+49 2451 62030",
    website: "http://www.pohlen-dach.de",
    email: "info@pohlen-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: ",Pohlen Bedachungen GmbH & Co. KG,",
    "Last Update": 18052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pletzinger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75007&utm_campaign=profiles_installer",
    company_name: "Pletzinger Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bab98c45150.jpg?v=1",
    address: "Stakelbrauk 8, 59889 Eslohe",
    telephone: "+49 2973 809448",
    website: "https://pletzinger.de",
    email: "info@pletzinger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/planet-pv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22758&utm_campaign=profiles_installer",
    company_name: "Planet PV GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4ffa85c77474a.gif?v=1",
    address: "Kniebisstr. 1, 77704 Oberkirch, Zusenhofen",
    telephone: "+49 7805 9185990",
    website: "http://www.planet-pv.de",
    email: "info@planet-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Italien,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/planer-consulting?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=35017&utm_campaign=profiles_installer",
    company_name: "Planer Consulting UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52084112b0aa4.jpg?v=1",
    address: "Fliederweg 4, 34233 Fuldatal",
    telephone: "+49 5607 2159990",
    website: "http://www.planer-consulting.de",
    email: "info@planer-consulting.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Siemens Energy,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/plan-b-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93647&utm_campaign=profiles_installer",
    company_name: "Plan B Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f60d0e07a5ac.PNG?v=1",
    address: "Landerdamm 24b, 33758 Schloß Holte, Stukenbrock",
    telephone: "+49 5207 9551510",
    website: "https://www.planb-pv.de",
    email: "info@planb-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Risen Energy Co., Ltd.,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/plan-b?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58259&utm_campaign=profiles_installer",
    company_name: "Plan B Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60212f4e20904.png?v=1",
    address: "Kavalleriestraße 24, 66740 Saarlouis",
    telephone: "+49 6831 7063716",
    website: "http://www.plan-b-energie.de",
    email: "info@plan-b-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-kirchner-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93954&utm_campaign=profiles_installer",
    company_name: "Peter Kirchner Zimmerei GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8bf61da980.jpg?v=1",
    address: "Kirchplatz 20, 97618 Hollstadt OT Wargolshausen",
    telephone: "+49 9762 6608",
    website: "http://www.pk-zimmerei.de",
    email: "info@pk-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/photovoltaikanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81797&utm_campaign=profiles_installer",
    company_name: "Photovoltaikanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5895797b7fdc0.jpg?v=1",
    address: "Rudi-Schmid-Weg 38, 85221 Dachau",
    telephone: "+49 81 31273199",
    website: "http://www.photovoltaikfachmann.de",
    email: "info@photovoltaikfachmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Canadian Solar Inc.,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-haitz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94412&utm_campaign=profiles_installer",
    company_name: "Elektro Haitz, Elektro Meisterbetrieb",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc1b1acb2f4.png?v=1",
    address: "Engelstraße 13, 76448 Durmersheim",
    telephone: "+49 7245 937734",
    website: "http://www.elektro-haitz.de",
    email: "info@photovoltaikbau.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/photovoltaikanlagen-stenger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102573&utm_campaign=profiles_installer",
    company_name: "Photovoltaikanlagen Stenger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac43dfb84158.png?v=1",
    address: "Forststraße 5, 97859 Wiesthal",
    telephone: "+49 6020 970097",
    website: "https://www.photovoltaikanlagen-stenger.de",
    email: "info@photovoltaikanlagen-stenger.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,EGing Photovoltaic Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/photovoltaik4all-de?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=14290&utm_campaign=profiles_installer",
    company_name: "Photovoltaik4all.de",
    logo_url: "https://cdn.enfsolar.com/ID/logo/503f0ed27a7ba.gif?v=1",
    address: "Berliner Chaussee 11, D-39307, Genthin",
    telephone: "+49 3933 8221616",
    website: "https://www.photovoltaik4all.de",
    email: "info@photovoltaik4all.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Astronergy Co., Ltd. (Chint Solar),,,AXITEC Energy GmbH & Co. KG,,,Viessmann Werke GmbH & Co. KG,,,Jinko Solar Holding Co., Ltd.,,,Luxor Solar GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Sungrow Power Supply Co., Ltd.,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Benning Elektrotechnik und Elektronik GmbH & Co.KG,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: ",SEC Solar Energy Consult Energiesysteme GmbH,",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunworks-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82017&utm_campaign=profiles_installer",
    company_name: "SunWorks GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589d3a9beed96.jpg?v=1",
    address: "Josef-Strobel-Str. 38, 88213 Ravensburg",
    telephone: "+49 751 35939950",
    website: "http://www.photovoltaik-ravensburg.de",
    email: "info@photovoltaik-ravensburg.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-kooperation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93675&utm_campaign=profiles_installer",
    company_name: "Energie Kooperation W. Buschbaum",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c2150ef24d7.jpg?v=1",
    address: "Herbert-Kühn-Str. 16, 55481 Kirchberg",
    telephone: "+49 6763 303436",
    website: "http://www.pv-profis.de",
    email: "info@photovoltaik-profis.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lorenz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94053&utm_campaign=profiles_installer",
    company_name: "Lorenz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca037f28a6d.jpg?v=1",
    address: "Damaschkestraße 2a, 49811 Lingen",
    telephone: "+49 591 47889",
    website: "http://www.photovoltaik-lorenz.de",
    email: "info@photovoltaik-lorenz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",EGing Photovoltaic Technology Co., Ltd.,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/Photovoltaik%20H%C3%A4rtel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=127711&utm_campaign=profiles_installer",
    company_name: "Photovoltaik Härtel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f6ef8eb49471.png?v=1",
    address: "Gartenstr. 5, 71106 Magstadt",
    telephone: "+49 179 6119445",
    website: "https://photovoltaik-haertel.de",
    email: "info@photovoltaik-haertel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Energetica Energy GmbH,,,SunPower Corp.,,,JA Solar Holdings Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Solar Frontier K.K.,,,AXITEC Energy GmbH & Co. KG,,,Jinko Solar Holding Co., Ltd.,,,LONGi Solar Technology Co., Ltd.,,,Luxor Solar GmbH,,,LG Electronics, Inc.,,,Viridian Concepts Ltd,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Sungrow Power Supply Co., Ltd.,,,Enphase Energy, Inc.,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,GoodWe Power Supply Technology Co., Ltd.,,,AEconversion GmbH & Co. KG,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/friese-rover?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1939&utm_campaign=profiles_installer",
    company_name: "Friese & Röver GmbH & Co.KG",
    logo_url:
      "https://cdn.enfsolar.com/z/s/logos/b604b4af616eb948552360a230f25d6bd.jpg?v=1",
    address: "Kommendestr. 13, 38173 Lucklum",
    telephone: "+49 5305 7653733",
    website: "http://www.photovoltaik-bs.de",
    email: "info@photovoltaik-bs.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,JA Solar Holdings Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,Astronergy Co., Ltd. (Chint Solar),,,AXITEC Energy GmbH & Co. KG,,,Hyundai Energy Solutions,,,BYD Company Limited,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/photovoltaic-connections?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64401&utm_campaign=profiles_installer",
    company_name: "PhotoVoltaic Connections GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56a732817cd43.png?v=1",
    address: "Augustaanlage 57, 68165 Mannheim",
    telephone: "+49 621 72493843",
    website: "http://photovoltaic-connections.de",
    email: "info@photovoltaic-connections.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,SunPower Corp.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,S-Energy Co., Ltd.,,,Astronergy Co., Ltd. (Chint Solar),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/photon-solar-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=12946&utm_campaign=profiles_installer",
    company_name: "Photon Solar Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/584e682b0e434.jpg?v=1",
    address: "Niermannsweg 11-15, 40699 Erkrath",
    telephone: "+49 211 2801250",
    website: "http://www.photon-solar.eu",
    email: "info@photon-solar.shop",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Photon Solar Energy GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Victron Energy BV,,,Danfoss A/S,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Photon Solar Energy GmbH,,,Huawei Technologies Co., Ltd.,,,RCT Power GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/philipps?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102371&utm_campaign=profiles_installer",
    company_name: "Philipps GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9c18457481.png?v=1",
    address: "Rombacher Hütte 2, 44795 Bochum",
    telephone: "+49 234 9437710",
    website: "http://www.philipps-bochum.de",
    email: "info@philipps-bochum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/philipp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93471&utm_campaign=profiles_installer",
    company_name: "Philipp GmbH + Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf4b3f60a5d.jpg?v=1",
    address: "Schelpmilser Weg 16a, 33609 Bielefeld",
    telephone: "+49 521 5577600",
    website: "http://www.philipp-haustechnik.de",
    email: "info@philipp-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/philipp-gretschel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95573&utm_campaign=profiles_installer",
    company_name: "Philipp Gretschel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f992f3651d7.jpg?v=1",
    address: "Zur Ley 8a, 53809 Ruppichteroth",
    telephone: "+49 160 7556496",
    website: "http://www.philipp-gretschel.de",
    email: "info@philipp-gretschel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/phasenwerk-ingenieurgesellschaft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131764&utm_campaign=profiles_installer",
    company_name: "Phasenwerk Ingenieurgesellschaft mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/607409f12eee4.jpg?v=1",
    address: "Bambergerstrasse 12, 91413, Neustadt an der Aisch",
    telephone: "+49 9161 8922040",
    website: "http://www.phasenwerk.de",
    email: "Info@phasenwerk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pfi-planungsgemeinschaft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93470&utm_campaign=profiles_installer",
    company_name: "PFI Planungsgemeinschaft GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf4934b48b1.jpg?v=1",
    address: "Karl-Imhoff-Weg 4, 30165 Hannover",
    telephone: "+49 511 358510",
    website: "http://www.pfi.de",
    email: "info@pfi.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/p-f-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=110032&utm_campaign=profiles_installer",
    company_name: "P & F Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bf212dd15831.jpg?v=1",
    address: "Kleinherbeder Str. 10, 58455 Witten",
    telephone: "+49 23 0227326",
    website: "https://pf-elektro.de",
    email: "info@pf-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/klaus-peter-sporleder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93465&utm_campaign=profiles_installer",
    company_name: "Klaus-Peter Sporleder",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf4702d33a6.jpg?v=1",
    address: "Silbergrund 37, 32805 Horn-Bad Meinberg",
    telephone: "+49 5234 1771",
    website: "http://petersporleder.de/",
    email: "info@petersporleder.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-schutte-photovoltaikanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58185&utm_campaign=profiles_installer",
    company_name: "Peter Schütte Photovoltaikanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602125b431218.png?v=1",
    address: "Lange Kamp 2, 32584 Löhne",
    telephone: "+49 5732 891801",
    website: "http://www.peterschuette.de",
    email: "info@peterschuette.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/peters-dachprofi?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82436&utm_campaign=profiles_installer",
    company_name: "Peters Dachprofi GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abed7bb4e80.jpg?v=1",
    address: "Angeraue 88, 40489 Düsseldorf",
    telephone: "+49 203 742306",
    website: "http://www.peters-dachprofi.de",
    email: "info@peters-dachprofi.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-stein-gerustbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94962&utm_campaign=profiles_installer",
    company_name: "Peter Stein Gerüstbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e067897735e.jpg?v=1",
    address: "Rosengartenstraße 18/2, 72108 Rottenburg-Hailfingen",
    telephone: "+49 7457 5377",
    website: "http://peter-stein-geruestbau.de",
    email: "info@peter-stein-geruestbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/perlutec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81960&utm_campaign=profiles_installer",
    company_name: "Perlutec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bde2b95a40.jpg?v=1",
    address: "Rischenweg 5b, 37124 Rosdorf, Kr. Göttingen",
    telephone: "+49 551 3067300",
    website: "http://www.perlutec.de",
    email: "info@perlutec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-pecher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94983&utm_campaign=profiles_installer",
    company_name: "Elektro Pecher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e07ab26d386.jpg?v=1",
    address: "Thomas-Dölle-Strasse 5, 86316 Friedberg",
    telephone: "+49 821 267560",
    website: "http://www.pecher-technik.de",
    email: "info@pecher-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dieter-paul?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93476&utm_campaign=profiles_installer",
    company_name: "Dieter Paul e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf5e266db55.jpg?v=1",
    address: "Westerfeldstraße 142, 33613 Bielefeld",
    telephone: "+49 521 882464",
    website: "http://www.paul-sanitaer.de",
    email: "info@paul-sanitaer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/paul-asbeck?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102359&utm_campaign=profiles_installer",
    company_name: "Paul Asbeck GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b4ba520e3.png?v=1",
    address: "Westerbauerstraße 25, 58135 Hagen",
    telephone: "+49 2331 40845",
    website: "http://www.paul-asbeck.de",
    email: "info@paul-asbeck.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/patt-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102570&utm_campaign=profiles_installer",
    company_name: "Patt Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60384d9b42d97.png?v=1",
    address: "Talstraße 70, 57399 Kirchhundem-Heinsberg",
    telephone: "+49 2723 97660",
    website: "https://patt-online.de",
    email: "info@patt-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/parockinger-zimmerei-dachdeckerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93896&utm_campaign=profiles_installer",
    company_name: "Parockinger GmbH & Co. KG Zimmerei - Dachdeckerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c86d5eb4967.jpg?v=1",
    address: "Ensmannsreut 1-2, 94065 Waldkirchen",
    telephone: "+49 8581 776",
    website: "http://parockinger.de",
    email: "info@parockinger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/palme-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=66861&utm_campaign=profiles_installer",
    company_name: "Palme Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/71122827.gif?v=1",
    address: "Heidenheimerstraße 80, 89542 Herbrechtingen-Bolheim",
    telephone: "+49 7324 9896433",
    website: "http://www.palme-solar.de",
    email: "info@palme-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",First Solar, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Delta Energy Systems (Germany) GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/palluth-dachbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1721&utm_campaign=profiles_installer",
    company_name: "Palluth- Dachbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/538d677519402.gif?v=1",
    address: "Vierwaldstätter Weg 13 , 13407 Berlin",
    telephone: "+49 30 4554051",
    website: "http://www.palluth-dachbau.de",
    email: "info@palluth-dachbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/paa-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1682&utm_campaign=profiles_installer",
    company_name: "Paa Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4127.gif?v=1",
    address: "Fichtenweg 5, 92548 Wölsendorf",
    telephone: "+49 9435 3017700",
    website: "http://www.paa-haustechnik.de",
    email: "info@paa-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pzm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7327&utm_campaign=profiles_installer",
    company_name: "PZM Photovoltaikzentrum Mauder e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7327n.gif?v=1",
    address: "Reuboldstraße 4, D-63937 Weilbach",
    telephone: "+49 9373 206900",
    website: "http://www.p-z-m.com",
    email: "info@p-z-m.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/over-dach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93246&utm_campaign=profiles_installer",
    company_name: "Over Dach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f348d9283.jpg?v=1",
    address: "Ottostraße 6, 50170 Kerpen",
    telephone: "+49 2273 98530",
    website: "http://over-dach.com",
    email: "info@over-dach.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ovag-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93711&utm_campaign=profiles_installer",
    company_name: "Oberhessische Versorgungsbetriebe AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c31360ad9e0.jpg?v=1",
    address: "Hanauer Straße 9-13, 61169 Friedberg",
    telephone: "+49 6031 68480",
    website: "https://www.ovag.de",
    email: "info@ovag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",OVAG Group,",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-j-otto?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94399&utm_campaign=profiles_installer",
    company_name: "H.J. Otto GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db3c9a8b5d2.png?v=1",
    address: "Bienenweide 7, 58566 Kierspe",
    telephone: "+49 2359 7170",
    website: "http://www.otto-dachbau.de",
    email: "info@ottodachbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/otto-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95082&utm_campaign=profiles_installer",
    company_name: "Otto Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85b46597ee.jpg?v=1",
    address: "Aachener Str. 624, 50226 Frechen-Königsdorf",
    telephone: "+49 2234 64120",
    website: "http://ottobedachungen.de",
    email: "info@ottobedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-heinemann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=643&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Heinemann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/643n.gif?v=1",
    address: "Kefferhäuser Straße 57, 37351 Dingelstädt",
    telephone: "+49 360 7562449",
    website: "http://www.elektrotechnik-oheinemann.de",
    email: "info@ottmar-heinemann.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alwin-otten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1850&utm_campaign=profiles_installer",
    company_name: "Alwin Otten GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1850n.gif?v=1",
    address: "Industriestraße 22, 49716 Meppen-Nödike",
    telephone: "+49 5931 495950",
    website: "http://www.otten.de",
    email: "info@otten.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/fritz-ott?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93989&utm_campaign=profiles_installer",
    company_name: "Fritz Ott GmbH Holzbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b086bc52a.jpg?v=1",
    address: "Austraße 18, 71739 Oberriexingen",
    telephone: "+49 7042 9300932",
    website: "http://www.ott-holzbauservice.de",
    email: "info@ott-holzbauservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ostseebau-kiel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93452&utm_campaign=profiles_installer",
    company_name: "Ostseebau Kiel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf3cc926358.jpg?v=1",
    address: "Birkenweg 7, 24214 Gettorf",
    telephone: "+49 152 27553421",
    website: "http://ostseebau-kiel.de/",
    email: "info@ostseebau-kiel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ost-solar-warmetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=33881&utm_campaign=profiles_installer",
    company_name: "Ost-Solar- & Wärmetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51fb6f0fbf1bb.jpg?v=1",
    address: "An der Sportanlage 10, 63584 Gründau-Lieblos",
    telephone: "+49 6051 906728",
    website: "http://www.ost-solar.de",
    email: "info@ost-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,Alfa Solar Energy Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/os-solar-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75813&utm_campaign=profiles_installer",
    company_name: "OS - Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf6dd8201e5.jpg?v=1",
    address: "Wiesenstraße 1, 49191 Belm",
    telephone: "+49 5406 881144",
    website: "http://www.os-solar.de",
    email: "info@os-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebr-orth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93986&utm_campaign=profiles_installer",
    company_name: "Gebr. Orth GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9af85d2f85.jpg?v=1",
    address: "Wiesenweg 10, D-53474 Bad Neuenahr-Ahrweiler",
    telephone: "+49 2641 5191",
    website: "http://www.orth-dach.de",
    email: "info@orth-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sager-deus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93451&utm_campaign=profiles_installer",
    company_name: "Sager & Deus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf3c3f5e0d6.jpg?v=1",
    address: "Vierenkamp 4, 22453 Hamburg",
    telephone: "+49 40 6828030",
    website: "http://www.haustechnik.hamburg",
    email: "info@oplaender-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/onesolar-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75835&utm_campaign=profiles_installer",
    company_name: "OneSolar International GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf847c951d3.jpg?v=1",
    address: "Am Moos 9, 84174 Eching",
    telephone: "+49 8709 928880",
    website: "http://www.onesolar.de",
    email: "info@onesolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/o-l-nexentury?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103598&utm_campaign=profiles_installer",
    company_name: "O&L Nexentury GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/604a229168f3e.png?v=1",
    address: "Maximilianstraße 2a, 82319 Starnberg",
    telephone: "+49 7634 3500061",
    website: "https://olnexentury.com",
    email: "info@ol-nx.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in":
      "Botswana, Deutschland, Südafrika, Namibie,Afrika, Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: ",Ohlthaver & List Group,",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/olaf-nispel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124858&utm_campaign=profiles_installer",
    company_name: "Olaf Nispel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecf785f192e0.png?v=1",
    address:
      "Langenkamp 12, Gewerbepark Bardowick Wittorfer Heide, 21357 Bardowick",
    telephone: "+49 4131 7547990",
    website: "http://ok-nispel.de/",
    email: "info@ok-nispel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ralf-ogniwek-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93450&utm_campaign=profiles_installer",
    company_name: "Ralf Ogniwek Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf38a3bcf9c.jpg?v=1",
    address: "Klein Hansdorfer Strasse 18, 22941 Jersbek/Timmerhorn",
    telephone: "+49 4532 24996",
    website: "http://www.ogniwek-solartechnik.de",
    email: "info@ogniwek-solartechnik.de",
    "Installaliert seit": 1991,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/off-grid-europe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=110264&utm_campaign=profiles_installer",
    company_name: "Off-Grid Europe",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bfb870dbb8d7.jpg?v=1",
    address: "Vorhof 1, 88633 Heiligenberg DE",
    telephone: "+49 7554 9896935",
    website: "https://www.off-grid-europe.com",
    email: "info@off-grid-europe.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Großbritannien,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Panasonic Corporation (Sanyo),,,AXITEC Energy GmbH & Co. KG,,,LG Electronics, Inc.,,,BO POWER,",
    "Wechselrichter-Lieferanten":
      ",OutBack Power,,,SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Studer Innotec,,,RIPEnergy AG,,,Victron Energy BV,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/okotronik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10044&utm_campaign=profiles_installer",
    company_name: "ÖkoTronik Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6095522468459.png?v=1",
    address: "Sälzerstraße 3b, D-34587 Felsberg",
    telephone: "+49 5662 6191",
    website: "http://www.oekotronik.de",
    email: "info@oekotronik.de",
    "Installaliert seit": 1989,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/okotherm-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1607&utm_campaign=profiles_installer",
    company_name: "Ökotherm Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/574d442360999.jpg?v=1",
    address: "Dotzheimer Str. 28, 65185 Wiesbaden",
    telephone: "+49 611 89059651",
    website: "http://www.oekotherm-solartechnik.de",
    email: "info@oekotherm.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/okolectric-haus-ehmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10591&utm_campaign=profiles_installer",
    company_name: "Ökolectric-Haus Ehmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10591n.gif?v=1",
    address: "Reutlichweg 28, 95111 Rehau",
    telephone: "+49 9283 898190",
    website: "http://www.oekolectric-ehmann.de",
    email: "info@oekolectric-ehmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/oekokraft-m-v?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81958&utm_campaign=profiles_installer",
    company_name: "Oekokraft M-V GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bdb9b1f5c5.jpg?v=1",
    address: "Lübecker Str. 270, 19059 Schwerin",
    telephone: "+49 385 20060058",
    website: "http://www.oekokraft-mv.de",
    email: "info@oekokraft-mv.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Siemens Energy,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-schworer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7375&utm_campaign=profiles_installer",
    company_name: "Zimmerei Schwörer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5948d2050ceb2.jpg?v=1",
    address: "Tullastraße 32, 79369, Wyhl a. Kaiserstuhl",
    telephone: "+49 7642 1681",
    website: "http://www.oekobau-schwoerer.de",
    email: "info@oekobau-schwoerer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/oko-team-energie-und-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77033&utm_campaign=profiles_installer",
    company_name: "Öko-Team Energie- und Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5805d9dd00f7b.jpg?v=1",
    address: "Schiedangerweg 7, 90518 Altdorf b., Nürnberg",
    telephone: "+49 9187 9212005",
    website: "http://www.öko-team.com",
    email: "info@oeko-team.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/oko-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1794&utm_campaign=profiles_installer",
    company_name: "Öko-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/574e990862f87.jpg?v=1",
    address: "Beim Tivoli 2, 26441 Jever",
    telephone: "+49 4461 74231",
    website: "http://www.oeko-solar-jever.de",
    email: "info@oeko-solar-jever.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/oko-haus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10043&utm_campaign=profiles_installer",
    company_name: "ÖKO-HAUS GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57673f6f8bf91.jpg?v=1",
    address: "Pfarrer-Singer-Strasse 5, 87745 Eppishausen.OT Hamlet",
    telephone: "+49 8266 862200",
    website: "http://www.oeko-haus.com",
    email: "info@oeko-haus.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/oecoenergy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8717&utm_campaign=profiles_installer",
    company_name: "OecoEnergy Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54ab79d7c8610.jpg?v=1",
    address: "Schmiedestraße 15, 26629 Großefehn",
    telephone: "+49 4943 91170",
    website: "http://www.oecoenergy.net",
    email: "info@oecoenergy.net",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Trina Solar Limited,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/odwald?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82496&utm_campaign=profiles_installer",
    company_name: "Odwald GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58acf3792c167.jpg?v=1",
    address: "Eichkamp 3, 49152 Bad Essen-Barkhausen",
    telephone: "+49 5427 922555",
    website: "http://odwald-gmbh.de",
    email: "info@odwald-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/obo-bettermann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=20199&utm_campaign=profiles_installer",
    company_name: "OBO Bettermann Holding GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56b59aec5ae73.jpg?v=1",
    address: "Hüingser Ring 52, 58710, Menden",
    telephone: "+49 2373 890",
    website: "http://obo-bettermann.com",
    email: "info@obo.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/oberschwaben-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75999&utm_campaign=profiles_installer",
    company_name: "Oberschwaben Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a28d90a9dd9d.png?v=1",
    address: "Georgstraße 22, 88214 Ravensburg",
    telephone: "+49 751 97719770",
    website: "http://www.oberschwaben-solar.de",
    email: "info@oberschwaben-solar.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nussmann-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93979&utm_campaign=profiles_installer",
    company_name: "Nussmann Holzbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ab001ad4f.jpg?v=1",
    address: "Ochsenhauser Str. 21 A, 88416 Bellamont",
    telephone: "+49 7358 9616110",
    website: "http://www.nussmann-holzbau.de",
    email: "info@nussmann-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nti-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7326&utm_campaign=profiles_installer",
    company_name: "NTI AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7326n.gif?v=1",
    address: "Lindengärten 2, 79219, Staufen",
    telephone: "+49 7633 95340",
    website: "http://www.nti-ag.com",
    email: "info@nti-ag.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/novatech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1648&utm_campaign=profiles_installer",
    company_name: "Novatech GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/522edc00d8c24.gif?v=1",
    address: "Frankenstraße 6-8, D - 74549 Wolpertshausen",
    telephone: "+49 7904 9430",
    website: "http://www.novatechgmbh.com",
    email: "info@novatechgmbh.com",
    "Installaliert seit": 1992,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nordwest-gruppe-neue-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8733&utm_campaign=profiles_installer",
    company_name: "Nordwest Gruppe Neue Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5594ab98b5728.jpg?v=1",
    address: "Auf dem Sattel 6, 49757 Werlte",
    telephone: "+49 59 518949000",
    website: "http://www.nordwestgruppe.de",
    email: "info@nordwestgruppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nordic-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76081&utm_campaign=profiles_installer",
    company_name: "Nordic Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d777feb225a.jpg?v=1",
    address: "Deepenstöcken 1, 22529 Hamburg",
    telephone: "+49 40 30094360",
    website: "http://nordic-solar.de",
    email: "info@nordic-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nordeifel-solar-pv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10503&utm_campaign=profiles_installer",
    company_name: "Nordeifel Solar PV",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10503n.gif?v=1",
    address:
      "Gewerbegebiet Zingsheim Süd, Auf Der Heide 8a, 53947, Nettersheim",
    telephone: "+49 2486 801054",
    website: "http://www.nordeifel-solar-pv.de",
    email: "info@nordeifel-solar-pv.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nm-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93311&utm_campaign=profiles_installer",
    company_name: "Nm Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb221d40475.jpg?v=1",
    address: "Wackenberg str.90, 13156, Berlin",
    telephone: "+49 30 25562845",
    website: "http://nm-solar.de/",
    email: "info@nm-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft:
      ",NSE-Schaltanlagenbau Elektro- und Gebäudeservice GmbH,",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nlf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93250&utm_campaign=profiles_installer",
    company_name: "NLF GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f764a0909.jpg?v=1",
    address: "Hembergener Str. 10, 48369 Saerbeck",
    telephone: "+49 2574 939100",
    website: "http://www.nlf-gmbh.de",
    email: "info@nlf-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nk-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93795&utm_campaign=profiles_installer",
    company_name: "NK Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c483b05e7d9.jpg?v=1",
    address: "Marie-Curie-Straße 15, 55435 Gau-Algesheim",
    telephone: "+49 6725 9378380",
    website: "https://www.nk-solar.de",
    email: "info@nk-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solar Frontier K.K.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-nitz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10637&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Christian Nitz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10637n.gif?v=1",
    address: "Potsdamer Straße 21a, 14558 Nuthetal",
    telephone: "+49 33200 85633",
    website: "http://www.nitzdachdeckermeister.de",
    email: "info@nitzdachdeckermeister.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/newi-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10201&utm_campaign=profiles_installer",
    company_name: "Newi-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10201n.gif?v=1",
    address: "Riedstrasse 7, 88410, Bad Wurzach",
    telephone: "+49 7564 948660",
    website: "http://www.newi-solar.de",
    email: "info@newi-solar.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/neumann-bauelemente?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81568&utm_campaign=profiles_installer",
    company_name: "Neumann Bauelemente GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588474703a945.jpg?v=1",
    address: "Erfurter Str. 60, 99448 Kranichfeld",
    telephone: "+49 36450 3480",
    website: "http://www.neumann-bauelemente.de",
    email: "info@neumann-bauelemente.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/netzwerk-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124870&utm_campaign=profiles_installer",
    company_name: "Netzwerk Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecfa2c188412.png?v=1",
    address: "Am Kapellengraben, 11, D-66802 Überherrn",
    telephone: "+49 6836 9214875",
    website: "http://netzwerk-solar.de/",
    email: "info@Netzwerk-Solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/neohel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93441&utm_campaign=profiles_installer",
    company_name: "Neohel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf2ff62f276.jpg?v=1",
    address: "St.-Josef-Str. 4, 68642, Bürstadt",
    telephone: "+49 6245 997722",
    website: "http://neohel.de/",
    email: "info@neohel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Suntech Power Co., Ltd.,,,Canadian Solar Inc.,,,Panasonic Corporation (Sanyo),,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/necon?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93437&utm_campaign=profiles_installer",
    company_name: "necon GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf2a0423402.jpg?v=1",
    address: "Industriestraße 8-10, 56472, Hof",
    telephone: "+49 2661 9175668",
    website: "http://necon.org/",
    email: "info@necon.org",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/neag?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93059&utm_campaign=profiles_installer",
    company_name: "Neue Energie Anlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b60bde338d9.jpg?v=1",
    address: "Schlag 1, 85667 Oberpframmern",
    telephone: "+49 8093 902410",
    website: "http://www.neag.de",
    email: "info@neag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,AxSun Solar GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nds-solar-concept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58195&utm_campaign=profiles_installer",
    company_name: "NDS Solar Concept GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6021272c417bf.png?v=1",
    address: "Höhenweg 10, 53332 Bornheim",
    telephone: "+49 2227 923640",
    website: "http://nds-konzept.solarlog-web.de",
    email: "info@nds-solarconcept.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ndb-technische-systeme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1305&utm_campaign=profiles_installer",
    company_name: "NDB Technische Systeme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/522d81cada96d.gif?v=1",
    address: "Robert-Bosch-Straße 11, 21684 Stade",
    telephone: "+49 4141 52301",
    website: "http://www.ndb.de",
    email: "info@ndb.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/natur-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102677&utm_campaign=profiles_installer",
    company_name: "Natur-Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9898c1c6b4.jpg?v=1",
    address: "Nelkenstraße 6, 92685 Floß",
    telephone: "+49 9603 2798",
    website: "http://www.natur-energietechnik.de",
    email: "info@natur-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Siemens Energy,,,Delta Energy Systems (Germany) GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ing-buro-k-niszl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83134&utm_campaign=profiles_installer",
    company_name: "Ingenieurbüro für Photovoltaik Klaus Nißl",
    logo_url: "https://cdn.enfsolar.com/ID/logo/607d9ce65a5f4.PNG?v=1",
    address: "Gleichweg 21, 80999 München",
    telephone: "+49 89 8926360",
    website: "http://n-pv.de",
    email: "info@n-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,Shell Solar,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,First Solar, Inc.,,,Astronergy Co., Ltd. (Chint Solar),,,Hyundai Energy Solutions,,,BYD Company Limited,,,LG Electronics, Inc.,,,Suzhou Talesun Solar Technologies Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mywohnen-immobilienservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93606&utm_campaign=profiles_installer",
    company_name: "Mywohnen Immobilienservice GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0e11361671.jpg?v=1",
    address: "Berghoferweg 35, 15569 Woltersdorf",
    telephone: "+49 176 64229260",
    website: "http://www.md-montage.de",
    email: "info@mywohnen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/suntec-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8530&utm_campaign=profiles_installer",
    company_name: "Suntec Vertriebs- und Montage GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b3d9c7b30a6b.png?v=1",
    address: "Ohmstraße 10, 92521 Schwarzenfeld",
    telephone: "+49 9435 301760",
    website: "http://www.mysuntec.com",
    email: "info@mysuntec.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,SunPower Corp.,,,Jinko Solar Holding Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/m-w-group?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13424&utm_campaign=profiles_installer",
    company_name: "M+W Group GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/13424n.gif?v=1",
    address: "Loewentorbogen 9b, 70376, Stuttgart",
    telephone: "+49 711 88040",
    website: "http://www.mwgroup.net",
    email: "info@mwgroup.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: ",Stumpf Group,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/mur-dach-und-wand?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119698&utm_campaign=profiles_installer",
    company_name: "mur Dach und Wand GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dd38c40589ed.jpg?v=1",
    address: "Barloer Str. 106 46414 Rhede",
    telephone: "+49 28 72980810",
    website: "http://www.mur-dach.de",
    email: "info@mur-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/multicon-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=16725&utm_campaign=profiles_installer",
    company_name: "Multicon Faber GmbH",
    logo_url:
      "https://cdn.enfsolar.com/ID/logo/16725_81685a93d6044d356.jpg?v=1",
    address: "Am Nienhaushof 28, 47139 Duisburg",
    telephone: "+49 203 48867610",
    website: "http://www.multiconsolar.de",
    email: "info@multiconsolar.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "VAE, Deutschland, Frankreich, Italien, Mali, Niger,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Training",
    Muttergesellschaft: "",
    "Last Update": 31012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94040&utm_campaign=profiles_installer",
    company_name: "Müller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9f0704199b.jpg?v=1",
    address: "Nelly-Sachs-Str. 6, 79111 Freiburg",
    telephone: "+49 761 7676830",
    website: "https://www.muellergmbh.info",
    email: "info@muellergmbh.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-muller-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106007&utm_campaign=profiles_installer",
    company_name: "Elektro Müller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b458260b7ede.png?v=1",
    address: "Franzstraße 9, 99988 Heyerode",
    telephone: "+49 36024 89257",
    website: "http://www.muellerelektrik.de",
    email: "info@muellerelektrik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerald-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93038&utm_campaign=profiles_installer",
    company_name: "Gerald Müller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b6006f7055c.jpg?v=1",
    address: "Alter Kirchenweg 77, 24983, Handewitt",
    telephone: "+49 4608 970445",
    website: "http://mueller-handewitt.de/",
    email: "info@mueller-handewitt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mubra-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81759&utm_campaign=profiles_installer",
    company_name: "Mubra Energy UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589551360aa9e.jpg?v=1",
    address: "Peter-Nettekoven-Str. 9, 41569 Rommerskirchen",
    telephone: "+49 2182 5785262",
    website: "http://mubra.de",
    email: "info@mubra.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,ABB Italy S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mts-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75569&utm_campaign=profiles_installer",
    company_name: "MTS GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c7e6fc04ec3.jpg?v=1",
    address: "Wittenberger Str. 15A, 04129 Leipzig",
    telephone: "+49 341 9111234",
    website: "http://www.mts-gmbh.eu",
    email: "info@mts-gmbh.eu",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mssolar-regenerative-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=128750&utm_campaign=profiles_installer",
    company_name: "MS Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fb26eac6413f.png?v=1",
    address: "Breitscheidstr. 91a, D-07407, Rudolstadt",
    telephone: "+49 3672 350409",
    website: "http://mssolar.de/",
    email: "info@mssolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/msg-naturenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10192&utm_campaign=profiles_installer",
    company_name: "MSG Naturenergie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58772473bfebd.jpg?v=1",
    address: "Am Goldenen Steig 2, 94151 Mauth",
    telephone: "+49 8557 91150",
    website: "http://www.msg-naturenergie.de",
    email: "info@msg-naturenergie.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mseg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93033&utm_campaign=profiles_installer",
    company_name: "MSEG International GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5f89df2961.jpg?v=1",
    address: "Am Rohrbach 10, 89397, Waldstetten",
    telephone: "+49 8223 9623827",
    website: "http://mseg-international.de/",
    email: "info@mseg-international.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Polen,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mse-tec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94666&utm_campaign=profiles_installer",
    company_name: "Michael Schwanz ElektroTechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddbdea40977.png?v=1",
    address: "Am Berg 3, D-78359 Nenzingen",
    telephone: "+49 7771 5198",
    website: "http://www.mse-tec.de",
    email: "info@mse-tec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mse-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94074&utm_campaign=profiles_installer",
    company_name: "MSE Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca132f3c51f.jpg?v=1",
    address: "Mühlgasse 31, 74930 Ittlingen",
    telephone: "+49 7266 914194",
    website: "http://www.mse-elektrotechnik.de",
    email: "info@mse-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ms-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94063&utm_campaign=profiles_installer",
    company_name: "MS Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca097f6ede8.jpg?v=1",
    address: "Im Höning 20, 63820 Elsenfeld",
    telephone: "+49 6022 710100",
    website: "http://www.ms-elektro-technik.de",
    email: "info@ms-elektro-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": ",Siemens Energy,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ms-blitzschutz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112670&utm_campaign=profiles_installer",
    company_name: "MS Blitzschutz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c7cc5b59cf95.jpg?v=1",
    address: "Dieselstraße 8, 71546 Aspach-Großaspach",
    telephone: "+49 7191 92430",
    website: "http://www.blitzschutz-ms.de",
    email: "info@ms-blitzschutz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mrt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76257&utm_campaign=profiles_installer",
    company_name: "Meier Regelungs Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e0a77db763e.jpg?v=1",
    address: "Sülzer Chaussee 1, 18465 Tribsees",
    telephone: "+49 38320 47064",
    website: "http://www.mrt-solar.de",
    email: "info@mrt-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solon International,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/maschinenring-landkreis-ansbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102394&utm_campaign=profiles_installer",
    company_name: "MaschinenRing Landkreis Ansbach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9e11de2f69.png?v=1",
    address: "Dr.-Zumach-Ring 16, 91522 Ansbach",
    telephone: "+49 981 487870",
    website: "https://www.mr-ansbach.de",
    email: "info@mr-ansbach.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",MR Landkreis Ansbach e.V.,",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/moscheik-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30327&utm_campaign=profiles_installer",
    company_name: "Andreas Moscheik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e755d799023.jpg?v=1",
    address:
      "Alfred-Klanke-Straße 9, Gewerbegebiet, Villigst Süd, 58239 Schwerte, Villigst",
    telephone: "+49 2304 963466",
    website: "http://www.moscheik.de",
    email: "info@moscheik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/montagebau-richert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94013&utm_campaign=profiles_installer",
    company_name: "Montagebau Richert GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9c92e95922.jpg?v=1",
    address: "Turnhallenweg 13, 89143 Blaubeuren",
    telephone: "+49 178 4921818",
    website: "https://www.montagebau-richert.de",
    email: "info@montagebau-richert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/moin-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=132615&utm_campaign=profiles_installer",
    company_name: "Moin Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a667c72686b.png?v=1",
    address: "An der Biese 22, 26655 Westerstede",
    telephone: "+49 4488 5939190",
    website: "https://moin.solar",
    email: "info@moin.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",Sungrow Power Supply Co., Ltd.,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: ",JE Kapital GmbH,",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/m-p-e?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56405&utm_campaign=profiles_installer",
    company_name: "Moeller & Poeller Engineering GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/557fdbae186e1.jpg?v=1",
    address: "Europaplatz 5, 72072 Tübingen",
    telephone: "+49 7071 138790",
    website: "http://www.moellerpoeller.de",
    email: "info@moellerpoeller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11122019,
  },
  {
    company_url:
      "https://de.enfsolar.com/moller-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77361&utm_campaign=profiles_installer",
    company_name: "Möller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5816e053c8806.jpg?v=1",
    address: "Im Fange 1, D-49356 Diepholz",
    telephone: "+49 5441 59590",
    website: "http://www.moeller.eu",
    email: "info@moeller.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/moller-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93050&utm_campaign=profiles_installer",
    company_name: "Möller GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b606a76567b.jpg?v=1",
    address: "Talweg 12, 48231 Warendorf / Einen",
    telephone: "+49 2584 1227",
    website: "http://www.moeller-kg.de",
    email: "info@moeller-kg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/moderne-bader?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93023&utm_campaign=profiles_installer",
    company_name: "Moderne Bäder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5ed039663b.jpg?v=1",
    address: "Mitteltor 15, 22929 Köthel",
    telephone: "+49 4159 333",
    website: "http://www.modernebaeder.de",
    email: "info@modernebaeder.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/moddemann-kommunikation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74479&utm_campaign=profiles_installer",
    company_name: "Moddemann Neue Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fda7c6794f2e.png?v=1",
    address: "Ootmarsumer Weg 25, 48527 Nordhorn",
    telephone: "+49 5921 88690",
    website: "http://www.moddemann-pv.de",
    email: "info@moddemann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/m-m-pv-und-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94012&utm_campaign=profiles_installer",
    company_name: "M&M PV- und Elektrotechnik GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9c8c347b35.jpg?v=1",
    address: "Deutzstraße 5, 48488 Emsbüren",
    telephone: "+49 5903 7031040",
    website: "http://www.mm-pv-elektrotechnik.de",
    email: "info@mm-pv-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mm-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93022&utm_campaign=profiles_installer",
    company_name: "MM-Photovoltaik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5eb5828bbc.jpg?v=1",
    address: "Fichtenweg 3, 46569, Hünxe",
    telephone: "+49 2064 46065",
    website: "http://mm-photovoltaik.de/",
    email: "info@mm-photovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ml-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58175&utm_campaign=profiles_installer",
    company_name: "ML Solutions",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c408162622a.jpg?v=1",
    address: "Am Marinhof 18, 22880 Wedel",
    telephone: "+49 4103 7016856",
    website: "http://www.ml-solutions.de",
    email: "info@ml-solutions.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11122019,
  },
  {
    company_url:
      "https://de.enfsolar.com/mkg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22985&utm_campaign=profiles_installer",
    company_name: "Montagebau Karl Göbel e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4ffcf973d9d07.gif?v=1",
    address: "Pfaffenmühlweg 86, 74613, Öhringen, Baden-Württemberg",
    telephone: "+49 79 41649200",
    website: "http://www.mkg-goebel.de",
    email: "info@mkg-goebel.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Nicaragua, Rumänien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mitzner-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=130939&utm_campaign=profiles_installer",
    company_name: "Mitzner-Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/603e097b0718a.png?v=1",
    address: "Hofbrühl 20, 78554, Aldingen Aixheim",
    telephone: "+49 7424 7035118",
    website: "http://mitzner-energie.de/",
    email: "info@Mitzner-Energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,Shenzhen Growatt New Energy Technology Co., Ltd.,,,AEconversion GmbH & Co. KG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mhs-solarbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30551&utm_campaign=profiles_installer",
    company_name: "MHS Solarbau Boos",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e890178270e.jpg?v=1",
    address: "Ackerweg 8, 56729, Boos",
    telephone: "+49 2656 951014",
    website: "http://www.mhs-solarbau-boos.com",
    email: "info@mhs-solarbau-boos.com",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,IBC Solar AG,,,SunPower Corp.,,,Trina Solar Limited,,,Canadian Solar Inc.,,,Panasonic Corporation (Sanyo),,,Hyundai Energy Solutions,,,LG Electronics, Inc.,,,CS Wismar GmbH Sonnenstromfabrik,,,Dehui Solar,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/meyersolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81955&utm_campaign=profiles_installer",
    company_name: "Meyersolar – Alternative Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bd680859c3.jpg?v=1",
    address: "Herderstr.40, 99096 Erfurt",
    telephone: "+49 361 3457462",
    website: "http://www.meyersolar.de",
    email: "info@meyersolar.de",
    "Installaliert seit": 2014,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,Kaneka Corporation,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/meyer-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93214&utm_campaign=profiles_installer",
    company_name: "Meyer-Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b798d121509.jpg?v=1",
    address: "Osterriedestr. 24, 30851 Langenhagen",
    telephone: "+49 511 744056",
    website: "http://www.meyer-energiesysteme.de",
    email: "info@meyer-energiesysteme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/meusert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101821&utm_campaign=profiles_installer",
    company_name: "Meusert GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa72edb85523.jpg?v=1",
    address: "Philipp-Reis-Straße 4, 97437 Haßfurt",
    telephone: "+49 9521 94330",
    website: "http://www.meusert.com",
    email: "info@meusert.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Yingli Green Energy Holding Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rudi-metzler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93021&utm_campaign=profiles_installer",
    company_name: "Rudi Metzler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5ea66072b1.jpg?v=1",
    address: "Sportplatzweg 6, 79856 Hinterzarten",
    telephone: "+49 7652 91170",
    website: "http://www.metzler-daecher.de",
    email: "info@metzler-daecher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/metzger-heizung-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75845&utm_campaign=profiles_installer",
    company_name: "Metzger Heizung & Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf8c4807e01.jpg?v=1",
    address: "Martin-Luther Straße 17, 42781 Haan",
    telephone: "+49 2129 32956",
    website: "http://www.metzger-heizung-solar.de",
    email: "info@metzger-heizung-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/edgar-mesam?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93020&utm_campaign=profiles_installer",
    company_name: "Edgar Mesam GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5e98aae98f.jpg?v=1",
    address: "Aispergweg 2, 79809 Weilheim-Bannholz",
    telephone: "+49 7755 91033",
    website: "http://www.mesam.de",
    email: "info@mesam.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mes-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30557&utm_campaign=profiles_installer",
    company_name: "Moderne Energie Systeme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d2450049d4ed.png?v=1",
    address: "Putlitzer Straße 27, 19370, Parchim",
    telephone: "+49 3871 62600",
    website: "https://www.mes-solar.de",
    email: "info@mes-parchim.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/meridian-neue-energien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8908&utm_campaign=profiles_installer",
    company_name: "Meridian Neue Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8908n.gif?v=1",
    address: "Frau Schrumpf, Schützenstraße 2, D-98527 Suhl",
    telephone: "+49 36 8145565",
    website: "http://www.meridian-energy.de",
    email: "info@meridian-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Tschechische, Deutschland, Frankreich, Kroatien, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/merbold-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94219&utm_campaign=profiles_installer",
    company_name: "Merbold Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc0a8be57f.jpg?v=1",
    address: "Zum Förderturm 4a, 57562 Herdorf",
    telephone: "+49 2744 931780",
    website: "http://www.merbold-elektrotechnik.net",
    email: "info@merbold-elektrotechnik.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Mitsubishi Electric US, Inc.,",
    "Wechselrichter-Lieferanten": ",Siemens Energy,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/meisterbetrieb-kelm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93016&utm_campaign=profiles_installer",
    company_name: "Meisterbetrieb Kelm",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60060b42ce96c.png?v=1",
    address: "Alte Dorfstraße 10, 31234 Edemissen-Abbensen",
    telephone: "+49 5177 8354",
    website: "http://www.meisterbetrieb-kelm.de",
    email: "info@meisterbetrieb-kelm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/meiss-solartechnik-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9997&utm_campaign=profiles_installer",
    company_name: "Dreier & Meiss Bedachungsgesellschaft mbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa7363d08a81.png?v=1",
    address: "Grotfeldsweg 151, 47506 Neukirchen-Vluyn",
    telephone: "+49 2845 93715",
    website: "http://www.meiss-solar.de",
    email: "info@meiss-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-mechanik-meisl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22747&utm_campaign=profiles_installer",
    company_name: "Elektro-Mechanik Meisl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576379ffef34e.jpg?v=1",
    address: "Bergwerkstr. 2-4, 83471 Berchtesgaden",
    telephone: "+49 08652 964966",
    website: "http://www.meisl-elektromechanik.de",
    email: "info@meisl.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sybac-solar-berlin?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76855&utm_campaign=profiles_installer",
    company_name: "Sybac Solar Berlin GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1481818982.gif?v=1",
    address: "Normannenstr. 40, 10367 Berlin",
    telephone: "+49 30 55499350",
    website: "http://www.meinstrom.de",
    email: "info@meinstrom.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,Panasonic Corporation (Sanyo),,,AXITEC Energy GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/meine-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10470&utm_campaign=profiles_installer",
    company_name: "Meine Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5436307fe05c2.jpg?v=1",
    address: "Ausbau Wriezener Str. 11, 15320, Neutrebbin",
    telephone: "+49 33474 38116",
    website: "http://www.meine-solar.de",
    email: "info@meine-solar.de",
    "Installaliert seit": 2013,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieinsel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84993&utm_campaign=profiles_installer",
    company_name: "Energieinsel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58ec83b4b4b30.jpg?v=1",
    address: "Im Gewerbepark 24, 16727 Oberkrämer",
    telephone: "+49 3304 2472390",
    website: "http://www.meine-energieinsel.de",
    email: "info@meine-energieinsel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,SunPower Corp.,,,Panasonic Corporation (Sanyo),,,Luxor Solar GmbH,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/meikowe-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93291&utm_campaign=profiles_installer",
    company_name: "Meikowe Elektro und Teleservice GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba22b369026.jpg?v=1",
    address: "Gewerbegebiet 1, 54597 Fleringen",
    telephone: "+49 6558 92920",
    website: "http://meikowe.de/",
    email: "info@meikowe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mehag-eg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75699&utm_campaign=profiles_installer",
    company_name: "MEHAG eG Handelsgenossenschaft Metall und KfZ",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ccee01aa223.jpg?v=1",
    address: "Friedrich-Engels-Straße 9, 99974 Mühlhausen, Thüringen",
    telephone: "+49 3601 40146",
    website: "http://www.mehag-mhl.de",
    email: "info@mehag-mhl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mts-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75955&utm_campaign=profiles_installer",
    company_name: "Megatop Solutions",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5930c4074a653.jpg?v=1",
    address: "Schwielowseestr. 76, 14548 Schwielowsee",
    telephone: "+49 33209 20000",
    website: "http://www.megatopsolutions.de",
    email: "info@megatopsolutions.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solar Fabrik GmbH,,,Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektromontagen-bock?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94031&utm_campaign=profiles_installer",
    company_name: "Elektromontagen Bock GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9e9035dc6d.jpg?v=1",
    address: "Barnitz 21A in 01665 Käbschütztal, Sachsen.",
    telephone: "+49 35244 41039",
    website: "http://www.mediaelektrik.de",
    email: "info@mediaelektrik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mecklenburger-solarbetrieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10175&utm_campaign=profiles_installer",
    company_name: "Mecklenburger Solarbetrieb",
    logo_url: "https://cdn.enfsolar.com/ID/logo/563c247908901.jpg?v=1",
    address: "Auf der Horst 16a, 19079, Banzkow",
    telephone: "+49 3861 3020020",
    website: "http://www.mecklenburger-solarbetrieb.de",
    email: "info@mecklenburger-solarbetrieb.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/me-montagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93251&utm_campaign=profiles_installer",
    company_name: "ME-Montagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c10a51b30510.jpg?v=1",
    address: "Am Friedrichsberg 8, 71723, Großbottwar",
    telephone: "+49 7148 1638120",
    website: "http://me-montagen.de/",
    email: "info@me-montagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mccormick-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4564&utm_campaign=profiles_installer",
    company_name: "Mccormick Solar Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56b5b9d4d6ac2.jpg?v=1",
    address: "Siegener Fußweg 5, 88348 Bad Saulgau",
    telephone: "+49 7581 4873780",
    website: "http://www.mccormick-solar.de",
    email: "info@mccormick-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,ET Solar New Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mbt-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4570&utm_campaign=profiles_installer",
    company_name: "MBT Solar GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4570n.gif?v=1",
    address: "Ringstraße 8, 24806 Hohn",
    telephone: "+49 4335 922500",
    website: "http://mbt-solar.de",
    email: "info@mbt-solar.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Mitsubishi Electric US, Inc.,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mbe-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93281&utm_campaign=profiles_installer",
    company_name: "MBE Elektrotechnik Süd GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba1c7984c4b.jpg?v=1",
    address: "Felix-Wankel-Straße 4/1, 73760 Ostfildern",
    telephone: "+49 711 30090945",
    website: "http://www.mbe-gmbh.eu",
    email: "info@mbe-gmbh.eu",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-trier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112668&utm_campaign=profiles_installer",
    company_name: "Elektro Trier GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c7cc48c54bfa.jpg?v=1",
    address: "Kölner Str., 184-186, 50226 Frechen",
    telephone: "+49 2234 956003",
    website: "https://www.elektro-trier.de",
    email: "info@mayriesen.de",
    "Installaliert seit": 2017,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mayer-montagebau-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74609&utm_campaign=profiles_installer",
    company_name: "Mayer Montagebau- Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b1562984f1a.jpg?v=1",
    address: "Frohndhofstraße 2, 68623 Lampertheim, Hofheim",
    telephone: "+49 6241 498450",
    website: "http://www.mayer-solar.de",
    email: "info@mayer-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solon International,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 19052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/maxx-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6118&utm_campaign=profiles_installer",
    company_name: "Maxx-solar & Energie GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f76ce0443101.png?v=1",
    address: "Eisenacher Landstr. 26, 99880 Waltershausen",
    telephone: "+49 3622 40103210",
    website: "http://maxx-solar.de/",
    email: "info@maxx-solar.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Österreich, Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,IBC Solar AG,,,SunPower Corp.,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/maxsolar-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=40189&utm_campaign=profiles_installer",
    company_name: "MaxSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/528711d054ead.jpg?v=1",
    address: "Schmidhamer Strasse 22, 83278 Traunstein",
    telephone: "+49 861 2097090",
    website: "http://www.maxsolar.de",
    email: "info@maxsolar.de",
    "Installaliert seit": 2013,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/matsa?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124485&utm_campaign=profiles_installer",
    company_name: "Matsa GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb8b69a1f82f.jpg?v=1",
    address: "Berliner Allee 10, D-30855 Langenhagen, Hannover",
    telephone: "+49 511 47547611-13",
    website: "http://www.matsa-gmbh.com",
    email: "info@matsa-gmbh.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hofmeister-dachdeckermeister?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10636&utm_campaign=profiles_installer",
    company_name: "Volker Hofmeister GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10636n.gif?v=1",
    address: "Hackfeldstr. 20, 32457, Porta-Westfalica",
    telephone: "+49 571 71564",
    website: "http://www.master-dach.de",
    email: "info@master-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/maschinenring-erding?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93283&utm_campaign=profiles_installer",
    company_name: "Maschinenring Erding GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba1cf0121df.jpg?v=1",
    address: "Dr.-Ulrich-Weg 3, 85435 Erding",
    telephone: "+49 8122 959810",
    website: "http://www.maschinenring-erding-gmbh.de",
    email: "info@maschinenring-erding-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-martin?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117011&utm_campaign=profiles_installer",
    company_name: "Zimmerei Martin",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d40068418ab1.png?v=1",
    address: "Hinterhofen 1, 78253 Eigeltingen",
    telephone: "+49 7774 9392920",
    website: "https://www.martin-zimmerei.de",
    email: "info@martin-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/marssolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1562&utm_campaign=profiles_installer",
    company_name: "Marssolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54a8a63e97222.gif?v=1",
    address: "Mönchstr. 30, 34431 Marsberg",
    telephone: "+49 2992 700",
    website: "http://www.marssolar.de",
    email: "info@marssolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/markus-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74947&utm_campaign=profiles_installer",
    company_name: "Markus-Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b68ae373c2d.jpg?v=1",
    address: "Bahnhofstraße 23, 63579 Freigericht, Neuses",
    telephone: "+49 6055 939783",
    website: "http://www.markus-energie.de",
    email: "info@markus-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/marco-krames?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93012&utm_campaign=profiles_installer",
    company_name: "Krames Marco Sanitär Heizung Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5de7248c4e.jpg?v=1",
    address: "Rheydter Str. 121, 41352 Korschenbroich",
    telephone: "+49 2161 644536",
    website: "http://www.marco-krames.de",
    email: "info@marco-krames.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/maplandau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131767&utm_campaign=profiles_installer",
    company_name: "MAPlandau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60740f147b3ec.jpg?v=1",
    address: "Werner-Heisenberg-Str. 13, 76829 Landau",
    telephone: "+49 6341 510232",
    website: "http://www.map-solar.de",
    email: "info@map-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mangold?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=19738&utm_campaign=profiles_installer",
    company_name: "Mangold GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/19738n.gif?v=1",
    address: "Am Deutenbach 6, 73525 Schwäbisch Gmünd",
    telephone: "+49 7171 186566",
    website: "http://www.mangold-photovoltaik.de",
    email: "info@mangold-photovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10531&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Manfred Müller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10531n.gif?v=1",
    address: "Von-Trips-Straße 31, 52249, Eschweiler",
    telephone: "+49 2403 38700",
    website: "http://www.manfredmueller-dach.de",
    email: "info@manfredmueller-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mak-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64319&utm_campaign=profiles_installer",
    company_name: "MAK Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60533f16573e5.PNG?v=1",
    address: "Am Schneidacker 44, 86316 Friedberg",
    telephone: "+49 821 45033855",
    website: "http://www.mak-solar.de",
    email: "info@mak-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,SunPower Corp.,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mainvolt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64313&utm_campaign=profiles_installer",
    company_name: "MainVolt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56a5dc7939719.png?v=1",
    address: "Karl-Benz-Straße 8, 65428 Rüsselsheim",
    telephone: "+49 6142 7013291",
    website: "http://www.mainvolt.de",
    email: "info@mainvolt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,SunPower Corp.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mainsonnenstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131115&utm_campaign=profiles_installer",
    company_name: "Mainsonnenstrom GMBH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6048278adafe8.jpg?v=1",
    address: "Michelriether Str. 40, 97828, Marktheidenfeld",
    telephone: "+49 9391 8105899",
    website: "http://www.mainsonnenstrom.de",
    email: "info@mainsonnenstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mainfrankensolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10181&utm_campaign=profiles_installer",
    company_name: "MainFrankenSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10181n.gif?v=1",
    address: "Tiergartenstaße 4c, 97209 Veitshöchheim",
    telephone: "+49 931 41793898",
    website: "http://www.mainfrankensolar.de",
    email: "info@mainfrankensolar.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/main-spessart-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1858&utm_campaign=profiles_installer",
    company_name: "Main- Spessart-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1858n.gif?v=1",
    address: "Im Hahlenfeld 2, 63856 Bessenbach",
    telephone: "+49 6095 97303",
    website: "http://www.main-spessart-solar.de",
    email: "info@main-spessart-solar.de",
    "Installaliert seit": 1993,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/maifeld-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10170&utm_campaign=profiles_installer",
    company_name: "Maifeld Solar\tibh - Ingenieurbüro Hoffmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c906c92a8e48.gif?v=1",
    address: "Auf'm Wasem 25, Polch 56751",
    telephone: "+49 2654 9629085",
    website: "http://www.maifeld-solar.de",
    email: "Info@Maifeld.Solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/maier-sohn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10402&utm_campaign=profiles_installer",
    company_name: "Maier & Sohn GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10402n.gif?v=1",
    address: "Adolf- Kolping- Str. 32, 86381 Krumbach",
    telephone: "+49 8282 89740",
    website: "http://www.maierundsohn.onlineshk.de",
    email: "info@maierundsohn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/markl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95067&utm_campaign=profiles_installer",
    company_name: "Märkl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e84b32b431c.jpg?v=1",
    address: "Im Kessel 2a, 90579 Langenzenn",
    telephone: "+49 910 190050",
    website: "http://maerkl.de",
    email: "info@maerkl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/maassen-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10167&utm_campaign=profiles_installer",
    company_name: "Maassen Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10167n.gif?v=1",
    address: "Kronenstraße 44, 40217 Düsseldorf",
    telephone: "+49 211 2205030",
    website: "http://www.maassen-solar.de",
    email: "info@maassen-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/matthias-pfeiffer-heizung-u-sanitar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93200&utm_campaign=profiles_installer",
    company_name: "Matthias Pfeiffer Heizung u. Sanitär",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b789d359b80.jpg?v=1",
    address: "Im Kloster 5A, 25557 Hanerau-Hademarschen",
    telephone: "+49 4872 942684",
    website: "http://www.m-pfeiffer.net",
    email: "info@m-pfeiffer.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebr-luxenhofer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93198&utm_campaign=profiles_installer",
    company_name: "Gebr. Luxenhofer GmbH Gebäudetechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b787e21e43e.jpg?v=1",
    address: "Bismarckstraße 71/2 - 9, 86159 Augsburg",
    telephone: "+49 821 570430",
    website: "http://www.luxenhofer.de",
    email: "info@luxenhofer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enzinger-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95683&utm_campaign=profiles_installer",
    company_name: "Enzinger Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e672933d4723.jpg?v=1",
    address: "Drachenburgstr. 6, 53179 Bonn Mehlem",
    telephone: "+49 228 943800",
    website: "https://www.elektro-enzinger.de",
    email: "info@lustauflicht.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lupo-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10457&utm_campaign=profiles_installer",
    company_name: "Lupo Energietechnik oHG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55cc2f28731dd.jpg?v=1",
    address: "Hübschenberg 1/1, 88273, Fronreute",
    telephone: "+49 7505 92103",
    website: "http://www.lupo-energietechnik.de",
    email: "info@lupo-energietechnik.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/luftdicht-nord?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94008&utm_campaign=profiles_installer",
    company_name: "Luftdicht Nord GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9c6c0bc604.jpg?v=1",
    address: "Westerheide 14, 25917 Stadum",
    telephone: "+49 4662 8838876",
    website: "http://www.luftdichtnord.de",
    email: "info@luftdicht-nord.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ernst-lutzow?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107428&utm_campaign=profiles_installer",
    company_name: "Ernst Lützow GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b7bbac7c3fe5.png?v=1",
    address: "Vor dem Bruch 4, 21271 Hanstedt",
    telephone: "+49 4184 88200",
    website: "https://www.luetzow-heizung.de",
    email: "info@luetzow-heizung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Viessmann Werke GmbH & Co. KG,,,Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/luendo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=73011&utm_campaign=profiles_installer",
    company_name: "Luendo gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd8b810c46d3.png?v=1",
    address: "Häuschensweg 46, 50827 köln",
    telephone: "+49 221 5302047",
    website: "http://www.luendo.de",
    email: "info@luendo.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-lullmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94914&utm_campaign=profiles_installer",
    company_name: "Zimmerei Lüllmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e03d5c2dec0.png?v=1",
    address: "Ahrendvehn 2, 49624, Löningen",
    telephone: "+49 5432 9054610",
    website: "http://www.luellmann-dach.de",
    email: "info@luellmann-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/luke-elektro-technik-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76017&utm_campaign=profiles_installer",
    company_name: "Lüke Elektro-Technik-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d624f5294b7.jpg?v=1",
    address: "Werkstraße 7, 33142 Büren",
    telephone: "+49 2951 931010",
    website: "http://lueke-elektroanlagen.de",
    email: "info@lueke-gmbh.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ludwig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94795&utm_campaign=profiles_installer",
    company_name: "Ludwig GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deeaa49c9cc.jpg?v=1",
    address: "Lacheweg 1, D-35327 Ulrichstein",
    telephone: "+49 6645 320",
    website: "http://www.ludwig-haustechnik.de",
    email: "info@ludwig-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ludwig-elektro-und-netzwerktechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94147&utm_campaign=profiles_installer",
    company_name: "Ludwig Elektro- und Netzwerktechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cc55c6cc941.jpg?v=1",
    address: "Am Sportplatz 6, 96138 Burgebrach",
    telephone: "+49 9546 920920",
    website: "http://www.ludwig-elektrotechnik.de",
    email: "info@ludwig-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lucinski-heizung-sanitar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82133&utm_campaign=profiles_installer",
    company_name: "Lucinski Heizung + Sanitär GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58a3cb738ce80.jpg?v=1",
    address: "Forsterstr. 100, 90441 Nürnberg",
    telephone: "+49 911 425052",
    website: "http://lucinski.de",
    email: "info@lucinski.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ls-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10473&utm_campaign=profiles_installer",
    company_name: "LS-Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5354e0bb588e8.jpg?v=1",
    address: "Hochstrasse 37, 53879, Euskirchen",
    telephone: "+49 2251 7024808",
    website: "http://www.ls-solar.de",
    email: "info@LS-Solar.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,Solar Frontier K.K.,,,Sharp Energy Solutions Europe,,,BYD Company Limited,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lr-solarstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93549&utm_campaign=profiles_installer",
    company_name: "LR Solarstrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0837d6894d.jpg?v=1",
    address: "Martin-Luther-Str. 14, 78549, Spaichingen",
    telephone: "+49 7424 9463889",
    website: "http://lrsolarstrom.de/",
    email: "info@lr-solarstrom.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,KIOTO Solar,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lorsbach-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94122&utm_campaign=profiles_installer",
    company_name: "Lorsbach Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb61f76a3da.jpg?v=1",
    address: "Obere Hommeswiese 27, D-57258 Freudenberg",
    telephone: "+49 2734 437892",
    website: "http://www.lorsbach-elektrotechnik.de",
    email: "info@lorsbach-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energietechnik-lorenz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1966&utm_campaign=profiles_installer",
    company_name: "Umwelt- und Energietechnik Lorenz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1966n.gif?v=1",
    address: "Robert Bosch Straße 20, 63584 Gründau-Lieblos",
    telephone: "+49 6051 884450",
    website: "http://www.lorenzenergie.de",
    email: "info@lorenzenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/loma-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8256&utm_campaign=profiles_installer",
    company_name: "Loma-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51480aa9d1a43.gif?v=1",
    address: "Thonhausen 11, 92289, Ursensollen",
    telephone: "+49 9626 912291",
    website: "http://www.loma-solar.de",
    email: "info@loma-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-willy-a-low?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94936&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Willy A. Löw AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6013380553a0c.png?v=1",
    address: "Benzstraße 18, 61352 Bad Homburg v.d. Höhe",
    telephone: "+49 6172 1890",
    website: "http://www.loew.ag",
    email: "INFO@LOEW.AG",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lobach-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94058&utm_campaign=profiles_installer",
    company_name: "Löbach Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca072dd454d.jpg?v=1",
    address: "Frankfurter Straße 86, 53757 Sankt Augustin",
    telephone: "+49 2241 61396",
    website: "http://www.loebach.de",
    email: "info@loebach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/LMD-Newenergy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=128029&utm_campaign=profiles_installer",
    company_name: "LMD New Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f8cf67ef3576.jpg?v=1",
    address: "Schlehenweg 22, 33129 Delbrück",
    telephone: "+49 5250 5173239",
    website: "http://lmd-newenergy.de",
    email: "info@lmd-newenergy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/licht-und-kraftanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81757&utm_campaign=profiles_installer",
    company_name: "Licht- und Kraftanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5895509f0435c.jpg?v=1",
    address: "Weinstraße 7 - 11, 86956 Schongau",
    telephone: "+49 8861 23660",
    website: "http://www.lkg-elektro.de",
    email: "info@lkg-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lizergy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74523&utm_campaign=profiles_installer",
    company_name: "Lizergy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ad7074d1398.jpg?v=1",
    address: "Jaspisstraße 13, 72250 Freudenstadt",
    telephone: "+49 7441 9521900",
    website: "http://www.lizergy.com",
    email: "info@lizergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/limmer-sollner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102034&utm_campaign=profiles_installer",
    company_name: "Limmer & Söllner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab734d55b4f.jpg?v=1",
    address: "Zum Külmitz 10, 96264 Altenkunstadt",
    telephone: "+49 9572 38630",
    website: "http://limmer-soellner.de",
    email: "info@limmer-soellner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/liebig-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93041&utm_campaign=profiles_installer",
    company_name: "Liebig Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b6021730882.jpg?v=1",
    address: "Dieselstr. 21, 36041 Fulda",
    telephone: "+49 661 29199850",
    website: "http://www.liebighaustechnik.de",
    email: "info@liebighaustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lichthaus-menne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94806&utm_campaign=profiles_installer",
    company_name: "Lichthaus Menne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deef0b84c3a.jpg?v=1",
    address: "Ruhrstraße 10, 59872 Meschede",
    telephone: "+49 291 1630",
    website: "http://www.lichthaus-menne.de",
    email: "info@lichthaus-menne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lichtstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93196&utm_campaign=profiles_installer",
    company_name: "LichtStrom GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b7861e1ecd0.jpg?v=1",
    address: "Carl-Benz-Straße 6, 25813, Husum",
    telephone: "+49 4841 773326",
    website: "http://licht-strom.net/",
    email: "info@licht-strom.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/licht-kraft-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94794&utm_campaign=profiles_installer",
    company_name: "Licht & Kraft Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deea99d7a5f.png?v=1",
    address: "Lauterbacher Str. 1, 09496 Marienberg",
    telephone: "+49 3735 91210",
    website: "http://www.licht-kraft.de",
    email: "info@licht-kraft.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lhs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76175&utm_campaign=profiles_installer",
    company_name: "Lisek Heizung & Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae534e72853.PNG?v=1",
    address: "Fasanenweg 2, 14641 Nauen, Weinberg",
    telephone: "+49 3321 452550",
    website: "http://www.waermepumpen-heizungsbau.de",
    email: "info@lhs-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/leopoldseder-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75223&utm_campaign=profiles_installer",
    company_name: "Leopoldseder Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bfb2563b644.jpg?v=1",
    address: "Zellhauser Str. 41, 85356 Freising",
    telephone: "+49 8167 950131",
    website: "https://www.leopoldseder-solar.de",
    email: "info@leopoldseder-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/leny?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=96285&utm_campaign=profiles_installer",
    company_name: "Len Solar",
    logo_url: "",
    address: "Breite Straße 18, 55124 Mainz",
    telephone: "+49 6131 466859",
    website: "http://www.leny.de",
    email: "info@leny.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hg-lenkeit?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9959&utm_campaign=profiles_installer",
    company_name: "H.G. Lenkeit",
    logo_url: "https://cdn.enfsolar.com/ID/logo/9959n.gif?v=1",
    address: "Kulmbacher Strasse 53, 95460 Bad Berneck",
    telephone: "+49 9273 247",
    website: "http://www.lenkeit-dach.de",
    email: "info@lenkeit-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/leit-ramm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7675&utm_campaign=profiles_installer",
    company_name: "Leit-Ramm GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5721a1b503708.jpg?v=1",
    address: "Vaterstettener Straße 20, 85598 Baldham",
    telephone: "+49 8106 3700",
    website: "http://www.leit-ramm.de",
    email: "info@leit-ramm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/leiser-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117018&utm_campaign=profiles_installer",
    company_name: "LEISER Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d400b03f2306.png?v=1",
    address: "Industriestraße 50, 76698 Ubstadt-Weiher",
    telephone: "+49 7253 4620",
    website: "https://www.leiser-holzbau-gmbh.de",
    email: "info@leiser-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/leicht-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76013&utm_campaign=profiles_installer",
    company_name: "Leicht Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d620b9850dd.jpg?v=1",
    address: "Im Ochsenstall 32b, Gewerbegebiet K1, 76689 Karlsdorf-Neuthard",
    telephone: "+49 7251 94340",
    website: "http://leicht-gruppe.de/",
    email: "info@leicht-gruppe.de",
    "Installaliert seit": 2012,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Suntech Power Co., Ltd.,,,Panasonic Corporation (Sanyo),,,AXITEC Energy GmbH & Co. KG,,,Jinko Solar Holding Co., Ltd.,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Enphase Energy, Inc.,,,ABB Italy S.p.A.,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: ",Leicht Group,",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/leibfarth-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81954&utm_campaign=profiles_installer",
    company_name: "Leibfarth Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bd57a06550.jpg?v=1",
    address: "Vogelsangstraße 8, 72581 Dettingen",
    telephone: "+49 7123 972987",
    website: "http://leibfarth-elektrotechnik.de",
    email: "info@leibfarth-elektrotechnik.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lehmeier-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93921&utm_campaign=profiles_installer",
    company_name: "Lehmeier Zimmerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87e58b9b4e.jpg?v=1",
    address: "Postgasse 2a, 92283 Lauterhofen",
    telephone: "+49 9186 9077117",
    website: "https://www.lehmeier-zimmerei.de",
    email: "info@lehmeier-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ledlight-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116026&utm_campaign=profiles_installer",
    company_name: "Ledlight Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/76605d24485713e06.gif?v=1",
    address: "Schifffahrter Damm 28, 48145 Münster",
    telephone: "+49 251 39573025",
    website: "http://www.ledlight-solutions.de",
    email: "info@ledlight-solutions.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Österreich, Belgien, Deutschland, Frankreich, Niederlande, Turkei,",
    "Lieferanten von Solarmodulen":
      ",Solar Fabrik GmbH,,,Trina Solar Limited,,,Canadian Solar Inc.,,,Jinko Solar Holding Co., Ltd.,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung, Training",
    Muttergesellschaft: "",
    "Last Update": 6102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lecher-daecher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11042&utm_campaign=profiles_installer",
    company_name: "Lecher Daecher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f008b08f6f4.png?v=1",
    address: "Friedensweg 1A, 03099 Kolkwitz",
    telephone: "+49 355 7840860",
    website: "http://www.lecher-daecher.de",
    email: "info@lecher-daecher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lebherz-partner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10455&utm_campaign=profiles_installer",
    company_name: "Lebherz und Partner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be65a6695c0.jpg?v=1",
    address: "Jakobstr. 218, 52064, Aachen",
    telephone: "+49 241 477070",
    website: "http://www.lebherz-und-partner.de",
    email: "info@lebherz-und-partner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lebenswerte-energien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58177&utm_campaign=profiles_installer",
    company_name: "Lebenswerte Energien",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c409b2a4ce9.jpg?v=1",
    address: "Carlo-Schmid-Weg 5, 25337 Elmshorn",
    telephone: "+49 4121 794100",
    website: "http://lebenswerte-energien.de",
    email: "info@lebenswerte-energien.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,JA Solar Holdings Co., Ltd.,,,Astronergy Co., Ltd. (Chint Solar),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lb-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76219&utm_campaign=profiles_installer",
    company_name: "LB-EnergieTechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60af77fa5a7c4.PNG?v=1",
    address: "Marburger Straße 15, 10789 Berlin",
    telephone: "+49 3302 2039340",
    website: "http://www.lb-energietechnik.eu",
    email: "info@lb-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Solon International,,,SunPower Corp.,,,Viessmann Werke GmbH & Co. KG,,,AU Optronics Corporation,,,LG Electronics, Inc.,,,Tesla Energy,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 27052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/laumen-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116910&utm_campaign=profiles_installer",
    company_name: "Laumen Zimmerei GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3eb333ddd9c.png?v=1",
    address: "Nierstraßer Weg 15, 52511 Geilenkirchen",
    telephone: "+49 2451 1292",
    website: "http://www.laumen-zimmerei-gmbh.de",
    email: "info@laumen-zimmerei-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/laue-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1848&utm_campaign=profiles_installer",
    company_name: "Laue Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1848n.gif?v=1",
    address: "Höferhof 11, 42929, Wermelskirchen",
    telephone: "+49 2193 3031",
    website: "http://www.laue-elektrotechnik.de",
    email: "info@laue-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/laudenberg-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93039&utm_campaign=profiles_installer",
    company_name: "Laudenberg Bedachungen e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60adfa48842ff.png?v=1",
    address: "Eifgenstrasse 8a, 51519, Odenthal",
    telephone: "+49 2174 40792",
    website: "http://laudenberg-dach.de/",
    email: "info@laudenberg-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lassen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93191&utm_campaign=profiles_installer",
    company_name: "Lassen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b7743955dd6.jpg?v=1",
    address: "Wiesentalstraße 23, 79115 Freiburg",
    telephone: "+49 761 459030",
    website: "http://www.lassen-gmbh.de",
    email: "info@lassen-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/firma-langholz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10896&utm_campaign=profiles_installer",
    company_name: "Andreas Langholz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53d9ae8e057ca.jpg?v=1",
    address: "Industriegebiet 5, 66453, Gersheim",
    telephone: "+49 6843 262",
    website: "http://www.langholz-dach.de",
    email: "info@langholz-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/d-m-langfeldt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93189&utm_campaign=profiles_installer",
    company_name: "D.+M. Langfeldt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b772b4867b7.jpg?v=1",
    address: "Lütjenburger Str. 103, 23714, Bad Malente",
    telephone: "+49 4523 1718",
    website: "http://langfeldt-malente.de/",
    email: "info@langfeldt-malente.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lange-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1565&utm_campaign=profiles_installer",
    company_name: "Lange Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/609071e550814.png?v=1",
    address: "In der Rose 4a, 57339 Erndtebrück",
    telephone: "+49 2753 598800",
    website: "http://www.langeelektro.de",
    email: "info@Langeelektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lange-solar-datentechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93188&utm_campaign=profiles_installer",
    company_name: "Lange Solar & Datentechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b7623737d34.jpg?v=1",
    address: "Freystädter Strasse 16, DE - 92334 Berching, Erasbach",
    telephone: "+49 8462 942784",
    website: "http://www.lange-solar-technik.de",
    email: "info@lange-datentechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lamprecht-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10408&utm_campaign=profiles_installer",
    company_name: "Lamprecht Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5343be2fe3ac5.jpg?v=1",
    address: "Altnaundorf 27, 01445, Radebeul",
    telephone: "+49 351 837690",
    website: "http://www.lamprecht-haustechnik.de",
    email: "info@lamprecht-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/joachim-lahmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1306&utm_campaign=profiles_installer",
    company_name: "Joachim Lahmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/522d832f909d3.gif?v=1",
    address: "Ewige Weide 2, 22926 Ahrensburg",
    telephone: "+49 410 26669660",
    website: "http://www.lahmann-ahrensburg.de",
    email: "info@lahmann-ahrensburg.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lasko-lammle-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7274&utm_campaign=profiles_installer",
    company_name: "Läsko Lämmle Elektro GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7274n.gif?v=1",
    address: "Falkenstraße 26, 89269, Vöhringen",
    telephone: "+49 7306 6011",
    website: "http://www.laesko-elektro.de",
    email: "info@laesko.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/l-plan-montage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93270&utm_campaign=profiles_installer",
    company_name: "L-Plan Montage GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae3aa5a9efe.png?v=1",
    address: "Hermann-Hollerith Straße 7, 52249, Eschweiler",
    telephone: "+49 2403 80300",
    website: "http://l-plan.net/",
    email: "info@l-plan.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/k-winter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93185&utm_campaign=profiles_installer",
    company_name: "K. Winter GmbH Heizung Sanitär Elektro",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b760cc2c87a.jpg?v=1",
    address: "Buldernweg 32-36, 48163 Münster",
    telephone: "+49 251 871870",
    website: "http://www.kwinter.de",
    email: "info@kwinter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/werner-kuntter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93184&utm_campaign=profiles_installer",
    company_name: "Werner Kuntter e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b760742c6b5.jpg?v=1",
    address: "Arndtstraße 24a, 22085 Hamburg",
    telephone: "+49 40 292773",
    website: "http://www.kuntter.de",
    email: "info@kuntter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kunert-dacher-und-bau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94909&utm_campaign=profiles_installer",
    company_name: "Kunert Dächer und Bau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e03bb2d19d0.png?v=1",
    address: "Mühlenstr. 11-12, 17, 04451 Zweenfurth bei Leipzig",
    telephone: "+49 34291 4380",
    website: "http://www.kunert-daecher.de",
    email: "info@kunert-daecher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",CREATON GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kuhhorn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102574&utm_campaign=profiles_installer",
    company_name: "Kühhorn GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac43f066b7be.png?v=1",
    address: "Am Galgenbuck 10, 90613 Großhabersdorf",
    telephone: "+49 9105 990683",
    website: "http://www.kuehhorn.de",
    email: "info@kuehhorn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elcktro-solar-kubiak?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1707&utm_campaign=profiles_installer",
    company_name: "Elcktro- Solar- Kubiak",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4172.gif?v=1",
    address: "Am Bahnhof 5, 34434 Borgentreich",
    telephone: "+49 5231 878448",
    website: "http://www.kubiak-solar.de",
    email: "info@kubitec.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-solartechnik-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41599&utm_campaign=profiles_installer",
    company_name: "PV-Solartechnik GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52d8944625f87.jpg?v=1",
    address: "Rosenweg 8, 78655 Dunningen",
    telephone: "+49 7402 69010",
    website: "http://www.pv-solartechnik.net",
    email: "info@ks-regenerative-energie.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Japan,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/krumbein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1778&utm_campaign=profiles_installer",
    company_name: "Krumbein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c7ad2810ed0.gif?v=1",
    address: "Daimlerstr. 05, 64646 Heppenheim",
    telephone: "+49 62 5293100",
    website: "http://www.krumbein.de",
    email: "info@krumbein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kronemeyer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93192&utm_campaign=profiles_installer",
    company_name: "Kronemeyer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b774c4d5e47.jpg?v=1",
    address: "Ziegeleistr. 4, 49843 Uelsen",
    telephone: "+49 5942 93210",
    website: "http://www.kronemeyer.de",
    email: "info@kronemeyer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/werner-krolpfeifer-dachdeckermeister?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95061&utm_campaign=profiles_installer",
    company_name: "Werner Krolpfeifer Dachdeckermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e8487dd2be9.jpg?v=1",
    address: "Fürstenberger Straße 12, 33142 Büren",
    telephone: "+49 2951 2809",
    website: "http://www.krolpfeifer.de",
    email: "info@krolpfeifer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/krisch-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=78943&utm_campaign=profiles_installer",
    company_name: "Krisch Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5844cda09e46d.jpg?v=1",
    address: "Einsteinstraße 13, 95643 Tirschenreuth",
    telephone: "+49 9631 6000440",
    website: "http://www.krisch-energietechnik.de",
    email: "info@krisch-energietechnik.de",
    "Installaliert seit": 1976,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kremer-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76681&utm_campaign=profiles_installer",
    company_name: "Elektro Kremer Haustechnik e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ee27a48f9db.jpg?v=1",
    address: "Hauptstraße 17, 54533 Eisenschmitt",
    telephone: "+49 6567 339",
    website: "http://kremerhaustechnik.de",
    email: "info@kremerhaustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kreienbaum-montage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93241&utm_campaign=profiles_installer",
    company_name: "Kreienbaum Montage GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f159e6489.jpg?v=1",
    address: "Splieterstraße 47, 48231 Warendorf",
    telephone: "+49 2581 789430",
    website: "http://www.kreienbaum-montage.de",
    email: "info@kreienbaum-montage.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dieter-krauss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93172&utm_campaign=profiles_installer",
    company_name: "Dieter Krauss GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b75ce38cd7f.jpg?v=1",
    address: "Rostock-Koppel 11, 25365 Klein Offenseth-Sparrieshoop",
    telephone: "+49 4121 579920",
    website: "http://www.krauss-haustechnik.com",
    email: "info@krauss-haustechnik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kraftwerk-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82025&utm_campaign=profiles_installer",
    company_name: "Kraftwerk Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589d4133609be.jpg?v=1",
    address: "Lindenstraße 76, 53721 Siegburg",
    telephone: "+49 2241 2615090",
    website: "http://www.kraftwerk-solutions.com",
    email: "info@kraftwerk-solutions.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-kramer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94869&utm_campaign=profiles_installer",
    company_name: "Zimmerei Krämer GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e98844f7bbff.png?v=1",
    address: "Hülenbergstr. 35, 72622 Nürtingen",
    telephone: "+49 7022 32170",
    website: "http://www.kraemer-holzbau.de",
    email: "info@kraemer-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kramer-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119859&utm_campaign=profiles_installer",
    company_name: "Krämer Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dddcdf870879.jpg?v=1",
    address: "Einbacher Str. 43, 77756 Hausach",
    telephone: "+49 7831 7676",
    website: "http://www.kraemer-haustechnik-gmbh.de",
    email: "info@kraemer-haustechnik-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/korona-solarsysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1582&utm_campaign=profiles_installer",
    company_name: "Korona Solarsysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5761162275.gif?v=1",
    address: "Hauptstr. 379 a, 51465 Bergisch Gladbach",
    telephone: "+49 2202 42220",
    website: "http://www.korona-solar.de",
    email: "info@korona-ht.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-korn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106245&utm_campaign=profiles_installer",
    company_name: "Elektro Korn",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50330a679dd.png?v=1",
    address: "Untergasse 26, 86934 Reichling",
    telephone: "+49 8194 543",
    website: "http://www.korn-elektro.de",
    email: "info@korn-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/koop-brinkmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102030&utm_campaign=profiles_installer",
    company_name: "Koop-Brinkmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab715d1e5c0.jpg?v=1",
    address: "Alter Bahnhofsweg 2, 49457 Drebber",
    telephone: "+49 5445 98470",
    website: "http://www.koop-brinkmann.de",
    email: "info@koop-brinkmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kolb-und-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1946&utm_campaign=profiles_installer",
    company_name: "Kolb und Müller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1946n.gif?v=1",
    address: "Brückenstraße 44, 36381 Schlüchtern-Elm",
    telephone: "+49 6661 72575",
    website: "http://www.komue.de",
    email: "info@komue.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/firma-reimer-koll?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94974&utm_campaign=profiles_installer",
    company_name: "Reimer Koll GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e071c97ec2e.jpg?v=1",
    address: "Altentreptower Straße 3, 25704 Meldorf",
    telephone: "+49 4832 8585",
    website: "http://koll-meldorf.de",
    email: "info@koll-meldorf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kolk-heizungsbau-und-installationsbetrieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93169&utm_campaign=profiles_installer",
    company_name: "Kolk Heizungsbau- und Installationsbetrieb GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b75bd5f0d1a.jpg?v=1",
    address: "Wörthstr. 33, 45894 Gelsenkirchen",
    telephone: "+49 209 959840",
    website: "http://www.kolk-kapalla.de",
    email: "info@kolk-kapalla.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kohl-wasser-warme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1663&utm_campaign=profiles_installer",
    company_name: "Kohl Wasser+Wärme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54992225d4753.gif?v=1",
    address: "Gutenbergstraße 6, 86399 Bobingen",
    telephone: "+49 823 4959830",
    website: "http://www.kohl-online.de",
    email: "info@kohl-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rolf-konsen-bedachung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10399&utm_campaign=profiles_installer",
    company_name: "Rolf Könsen Bedachung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10399n.gif?v=1",
    address: "Reiterstr. 3, 28307, Bremen",
    telephone: "+49 421 480263",
    website: "http://www.koensen.de",
    email: "info@koensen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kolnsolar-futur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1857&utm_campaign=profiles_installer",
    company_name: "KölnSolar Futur GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1857n.gif?v=1",
    address: "Merlinweg 18, 50997, Köln",
    telephone: "+49 2233 923375",
    website: "http://www.koelnsolar.de",
    email: "info@KoelnSolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solon International,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kohne-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95148&utm_campaign=profiles_installer",
    company_name: "Köhne Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee94298c484.jpg?v=1",
    address: "Walter-Rathenau-Ring 10-12, 59581 Warstein-Belecke",
    telephone: "+49 2902 701321",
    website: "http://www.koehne-bedachungen.de",
    email: "info@koehne-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/koco-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=17242&utm_campaign=profiles_installer",
    company_name: "Koco Energy AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/609c4b9548e39.png?v=1",
    address: "Nordstraße 10, 83253 Rimsting",
    telephone: "+49 8051 9396930",
    website: "http://www.koco-ag.com",
    email: "info@koco-ag.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Trina Solar Limited,,,Canadian Solar Inc.,,,Hyundai Energy Solutions,,,CS Wismar GmbH Sonnenstromfabrik,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rk-elektrotechnik-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30671&utm_campaign=profiles_installer",
    company_name: "RK Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7681507993.gif?v=1",
    address: "Penzendorfer Straße 12, D-91126 Schwabach-Rednitzhembach",
    telephone: "+49 9122 1886290",
    website: "http://www.knorr-nbg-elektrotechnik.de",
    email: "info@knorr-nbg-elektrotechnik.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Turkei, Polen,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,First Solar, Inc.,,,Eoplly New Energy Technology Co., Ltd.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/knappmeier-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94182&utm_campaign=profiles_installer",
    company_name: "Knappmeier Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ccbe0a590a9.jpg?v=1",
    address: "Am Freibad 13, 49324 Melle",
    telephone: "+49 5422 8235",
    website: "http://www.knappmeier-elektrotechnik.de",
    email: "info@knappmeier-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kms-umwelttechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1710&utm_campaign=profiles_installer",
    company_name: "KMS - Neue Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1710n.gif?v=1",
    address: "Wiesenstraße 12, 33790, Halle Westfalen",
    telephone: "+49 52 0110848",
    website: "http://www.kms-neue-energie.de",
    email: "info@kms-neue-energie.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/arno-kluschat?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9988&utm_campaign=profiles_installer",
    company_name: "Arno Kluschat - Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d27f82715bc9.jpg?v=1",
    address: "Naheweinstraße 21, 55452 Guldental",
    telephone: "+49 6707 1017",
    website: "http://www.kluschat-elektrotechnik.de",
    email: "info@kluschat-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/klein-neue-energien-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11873&utm_campaign=profiles_installer",
    company_name: "Klein Neue Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11873n.gif?v=1",
    address: "Rauschfeld 1, 33129, Delbrück-Boke",
    telephone: "+49 5258 1570",
    website: "http://www.kleinneueenergien.de",
    email: "info@kleinneueenergien.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kleibolt-losing?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82402&utm_campaign=profiles_installer",
    company_name: "Kleibolt-Lösing",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abbd6cd474b.jpg?v=1",
    address: "Telgenkamp 29, 48249 Dülmen",
    telephone: "+49 2594 2256",
    website: "http://www.kleibolt-loesing-duelmen.de",
    email: "info@kleibolt-loesing.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kle-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=55717&utm_campaign=profiles_installer",
    company_name: "KLE Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8d535574b5.jpg?v=1",
    address: "Züscher Straße 22a, 54411 Hermeskeil",
    telephone: "+49 6503 414420",
    website: "http://www.kle-energie.de",
    email: "info@kle-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,JA Solar Holdings Co., Ltd.,,,Viessmann Werke GmbH & Co. KG,,,GCL System Integration Technology Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-klaas?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11505&utm_campaign=profiles_installer",
    company_name: "Elektro-Klaas Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57674dba6f3ef.jpg?v=1",
    address: "Albersdorferfeld 1, 25767, Albersdorf",
    telephone: "+49 4835 1330",
    website: "http://www.klaas-elektro.de",
    email: "info@klaas-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kl-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30243&utm_campaign=profiles_installer",
    company_name: "KL-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e654e50bfaf.jpg?v=1",
    address: "Mötzower Landstr. 36, 14776, Brandenburg",
    telephone: "+49 3381 668811",
    website: "http://www.kl-solar.de",
    email: "info@kl-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Canadian Solar Inc.,,,ReneSola Ltd.,,,NexPower Technology Corporation,,,Shunda Italia Srl,,,Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kkt-kratochwil?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92851&utm_campaign=profiles_installer",
    company_name: "KKT Kratochwil GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0c0813eb02.jpg?v=1",
    address: "Am Stadtwald 56, 99974 Mühlhausen",
    telephone: "+49 3601 838383",
    website: "http://kkt-cool.de/",
    email: "info@kkt-cool.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/k-k-solar-management?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83144&utm_campaign=profiles_installer",
    company_name: "K& K Solar Management GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/607d993f9de2a.PNG?v=1",
    address: "Mühlenstraße 50, 31191 Algermissen",
    telephone: "+49 5126 8022836",
    website: "http://www.kk-solar-management.de",
    email: "info@kk-solar-management.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kirchner-solar-group-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=16955&utm_campaign=profiles_installer",
    company_name: "Kirchner Solar Group GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/16955n.gif?v=1",
    address: "Auf der Welle 8, D-36211 Alheim",
    telephone: "+49 5664 939110",
    website: "http://www.kirchner-solar-group.de",
    email: "info@kirchner-solar-group.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-kirchhammer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93906&utm_campaign=profiles_installer",
    company_name: "Zimmerei Kirchhammer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c876decdead.jpg?v=1",
    address: "Langweid 2a, 84094 Elsendorf",
    telephone: "+49 8753 9679947",
    website: "http://www.kirchhammer.de",
    email: "info@kirchhammer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kipp-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83145&utm_campaign=profiles_installer",
    company_name: "Kipp-Solar, Inh.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58bce3c6b5b50.jpg?v=1",
    address: "Buhlstrasse 25th, 72348 Rosenfeld",
    telephone: "+49 74 289410921",
    website: "http://kipp-solar.de",
    email: "info@kipp-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kilotherm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93158&utm_campaign=profiles_installer",
    company_name: "Kilotherm GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b7587b0dc32.jpg?v=1",
    address: "Rheinstraße 52, 79639, Grenzach-Wyhlen",
    telephone: "+49 7624 909685",
    website: "http://kilotherm.de/",
    email: "info@kilotherm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kili-solar-systeme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30231&utm_campaign=profiles_installer",
    company_name: "Kili Solar Systeme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5760ec80cdb93.jpg?v=1",
    address: "Maximilian Strasse 56, 85399, Hallbergmoos",
    telephone: "+49 811 1288704",
    website: "http://www.kili-solar.de",
    email: "info@kili-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Suntech Power Co., Ltd.,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kienzler-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22757&utm_campaign=profiles_installer",
    company_name: "Kienzler Energie Ltd.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52019c15b79a8.gif?v=1",
    address: "Schützenstraße 28, D-78147 Vöhrenbach",
    telephone: "+49 7727 919401",
    website: "https://energie-kienzler.de",
    email: "info@kienzler-voehrenbach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-balkone-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117016&utm_campaign=profiles_installer",
    company_name: "Kienzler Vöhrenbach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d4009bf7ac04.jpg?v=1",
    address: "Schützenstraße 28, 78147 Vöhrenbach",
    telephone: "+49 7727 919401",
    website: "https://www.kienzler-vöhrenbach.de",
    email: "info@kienzler-voehrenbach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/khs-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101999&utm_campaign=profiles_installer",
    company_name: "KHS Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab5c9e95c61.jpg?v=1",
    address: "Maybachstrasse 7, 71563 Affalterbach",
    telephone: "+49 7144 83490",
    website: "https://www.khs-affalterbach.de",
    email: "info@khs-affalterbach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kruger-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6094&utm_campaign=profiles_installer",
    company_name: "Krüger Elektrotechnik GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6094n.gif?v=1",
    address: "Auricher Landstraße 21. D - 26629 Großefehn",
    telephone: "+49 4943 91010",
    website: "http://www.ketg.de",
    email: "info@ketg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kerngesund-energiekonzepte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8247&utm_campaign=profiles_installer",
    company_name: "Kerngesund Energiekonzepte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60931fd6688e7.png?v=1",
    address: "Kuhwasen 3, 72355 Schömberg",
    telephone: "+49 7427 92270",
    website: "https://www.kerngesund-energiekonzepte.de",
    email: "info@kerngesund-energiekonzepte.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kemper-automation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1585&utm_campaign=profiles_installer",
    company_name: "Kemper Automation GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c7f176b7733.jpg?v=1",
    address: "Estern 79, 48712, Gescher",
    telephone: "+49 2542 7433",
    website: "http://cms.kemper-automation.de",
    email: "Info@Kemper-Automation.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/keitel-house?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=114870&utm_campaign=profiles_installer",
    company_name: "Keitel-Haus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ce784516edf4.jpg?v=1",
    address: "Reubacher Straße 23, 74585, Rot am See, Brettheim",
    telephone: "+49 7958 98050",
    website: "https://www.fertighaus-keitel.de",
    email: "info@keitel-haus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-und-natur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93824&utm_campaign=profiles_installer",
    company_name: "Energie und Natur",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4afe5465ae.jpg?v=1",
    address: "Stockwäldchen 5, 66450 Bexbach",
    telephone: "+49 151 22319080",
    website: "https://energie-und-natur.de",
    email: "info@keilenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Tschechische, Deutschland, Italien, Vietnam,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/keepgreen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75059&utm_campaign=profiles_installer",
    company_name: "KeepGreen - Grüne Energiekonzepte",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ab8ef910eae.PNG?v=1",
    address: "Hochstrasse 111, 56070 Koblenz",
    telephone: "+49 261 98366660",
    website: "http://keepgreen.de",
    email: "info@keepgreen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kaufmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93360&utm_campaign=profiles_installer",
    company_name: "Kaufmann GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb4e3d79643.jpg?v=1",
    address: "Im Brühl 9-11, 67365 Schwegenheim",
    telephone: "+49 6344 94450",
    website: "http://www.kaufmann-ems.com",
    email: "info@kaufmann-ems.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kastell-solar-verwaltungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76659&utm_campaign=profiles_installer",
    company_name: "Kastell Solar Verwaltungs-GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ecba196c4a6.jpg?v=1",
    address: "Eschebergstr. 85b, D-34128 Kassel",
    telephone: "+49 561 66382",
    website: "http://www.kastell-solar.de",
    email: "info@kastell-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/karstens?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10756&utm_campaign=profiles_installer",
    company_name: "Karstens GmbH Dachdeckerei & Zimmerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/516ba379bafeb.bmp?v=1",
    address: "Hauptstraße 13, 29365 Sprakensehl",
    telephone: "+49 5837 140880",
    website: "http://www.karstens-gmbh.de",
    email: "info@karstens-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/karl-scharpf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102351&utm_campaign=profiles_installer",
    company_name: "Karl Scharpf GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9aec0e2e0e.png?v=1",
    address: "Fritz-Müller-Strasse 136, 73730 Esslingen",
    telephone: "+49 711 939380",
    website: "http://www.karl-scharpf.de",
    email: "info@karl-scharpf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-karl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81762&utm_campaign=profiles_installer",
    company_name: "Peter Karl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5895522013da6.jpg?v=1",
    address: "Kapell-Leite 7, 90579 Langenzenn",
    telephone: "+49 9101 2492",
    website: "http://www.karl-langenzenn.de",
    email: "info@karl-langenzenn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kardell-sothmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95063&utm_campaign=profiles_installer",
    company_name: "Kardell Sothmann Bedachungen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e849720ed23.jpg?v=1",
    address: "Am Kleinbahnhof 19-21, 25746 Heide",
    telephone: "+49 481 684050",
    website: "http://www.kardell-sothmann.de",
    email: "info@kardell-sothmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/erwin-kaiser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92849&utm_campaign=profiles_installer",
    company_name:
      "Firma Erwin Kaiser Sanitär- und Heizungsinstallation, Ralf Schimanski e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0bfd9da68c.jpg?v=1",
    address: "Velauer Straße 284, 45472 Mülheim/Ruhr",
    telephone: "+49 208 492069",
    website: "http://www.kaiser-shk.de",
    email: "info@kaiser-shk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/karl-kaiser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92846&utm_campaign=profiles_installer",
    company_name: "Karl Kaiser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0beea95af2.jpg?v=1",
    address: "Weststraße 7, 40822 Mettmann",
    telephone: "+49 2104 7858",
    website: "http://www.kaiser-haustechnik.de",
    email: "info@kaiser-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kampf-co-innovative-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92843&utm_campaign=profiles_installer",
    company_name: "Kämpf + Co. Innovative Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0bd66d01e5.jpg?v=1",
    address: "August-Schanz-Straße 31, 60433 Frankfurt/Main",
    telephone: "+49 69 795800",
    website: "http://www.kaempf.de",
    email: "info@kaempf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/k-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92837&utm_campaign=profiles_installer",
    company_name: "K-Solar Photovoltaik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e9334545f3a8.png?v=1",
    address: "Plankenwiese 8, 56589 Niederbreitbach",
    telephone: "+49 2638 947050",
    website: "http://k-solar-breitscheid.de/",
    email: "info@k-solar-breitscheid.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kse-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92833&utm_campaign=profiles_installer",
    company_name: "Klaus Schleicher Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0b5afa4927.jpg?v=1",
    address: "Am Märzrasen 7, 36124 Eichenzell",
    telephone: "+49 6659 9150820",
    website: "http://www.k-s-e.com",
    email: "info@k-s-e.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/j-w-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=46611&utm_campaign=profiles_installer",
    company_name: "J&W Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53a2a02a210e3.jpg?v=1",
    address: "Ferdinand Porsche Straße 13, 76275 Ettlingen",
    telephone: "+49 7243 520712",
    website: "http://www.jundw.de",
    email: "info@jw-h.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",J&W Group,",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/j-v-g-thoma?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8441&utm_campaign=profiles_installer",
    company_name: "J. v. G. Technology GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f74614ae8ff3.png?v=1",
    address: "Möningerberg 1a, 92342 Freystadt",
    telephone: "+49 9179 963806",
    website: "http://www.jvg-thoma.de",
    email: "info@jvgthoma.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/juwi?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1366&utm_campaign=profiles_installer",
    company_name: "Juwi AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1366n.gif?v=1",
    address: "Energie-Allee 1, 55286 Wörrstadt",
    telephone: "+49 6732 96570",
    website: "http://www.juwi.de",
    email: "info@juwi.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Asien-Pazifik, Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",MVV Energia AG,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/jungmann-sichelschmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93156&utm_campaign=profiles_installer",
    company_name: "Jungmann & Sichelschmidt GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b757f494c36.jpg?v=1",
    address: "Breite Straße 57, 47169 Duisburg",
    telephone: "+49 203 5193294",
    website: "http://jungmann-sichelschmidt.de",
    email: "info@jungmann-sichelschmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/w-jung-sohne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92831&utm_campaign=profiles_installer",
    company_name: "W. Jung & Söhne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0b48a24753.jpg?v=1",
    address: "Klusriede 18, 30851 Langenhagen",
    telephone: "+49 511 737564",
    website: "http://www.jung-langenhagen.de",
    email: "info@jung-langenhagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-jung-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95066&utm_campaign=profiles_installer",
    company_name: "Thomas Jung Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e84acd110a5.jpg?v=1",
    address: "Bichtenhahn 5, 56459 Stockum–Püschen",
    telephone: "+49 2661 206020",
    website: "http://www.jung-dachdeckermeister.de",
    email: "info@jung-dachdeckermeister.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/julist?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102556&utm_campaign=profiles_installer",
    company_name: "Julist GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac42e3f5615e.png?v=1",
    address: "Sorauer Straße 22, 03149 Forst (Lausitz)",
    telephone: "+49 30 5056595940",
    website: "http://www.julist.de",
    email: "info@julist.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",First Solar, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/JT%20Solarbau%20GmbH?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=129794&utm_campaign=profiles_installer",
    company_name: "JT Solarbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fee3f9559bfb.jpg?v=1",
    address: "Kölnerstr 193, 50226 Frechen",
    telephone: "+49 2234 6868354",
    website: "http://www.jt-solarbau.de",
    email: "info@jt-solarbau.de",
    "Installaliert seit": 2018,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,Europa,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": ",Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/josef-bauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10695&utm_campaign=profiles_installer",
    company_name: "Josef Bauer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10695n.gif?v=1",
    address: "Brechdarrenweg 5, 97980 Bad Mergentheim-Lustbronn",
    telephone: "+49 7931 46470",
    website: "http://josefbauergmbh.de",
    email: "info@josef-bauer-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/jochheim-dachdecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94248&utm_campaign=profiles_installer",
    company_name: "Jochheim Dachdecker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cddea224d06.png?v=1",
    address: "Gröbziger Straße 63B, 06406 Bernburg, Saale",
    telephone: "+49 3471 312435",
    website: "http://www.jochheim-dachdecker.de",
    email: "info@jochheim-dachdecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-und-fassadenbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101833&utm_campaign=profiles_installer",
    company_name: "Dach- und Fassadenbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa745ee93b63.jpg?v=1",
    address: "Dorfstrasse 5, 06682 Nessa",
    telephone: "+49 34443 6060",
    website: "http://www.jnschmidt.de",
    email: "info@jnschmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jf-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=42119&utm_campaign=profiles_installer",
    company_name: "JF Solar & Baumaschinenverleih GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d61baff20eb.jpg?v=1",
    address: "Unter'm Bucharts 29, 1/2, 87435 Kempten (Allgäu)",
    telephone: "+49 176 64966533",
    website: "http://www.jf-solar.de",
    email: "info@jf-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Trina Solar Limited,,,AXITEC Energy GmbH & Co. KG,,,Phono Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Delta Energy Systems (Germany) GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mons-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75255&utm_campaign=profiles_installer",
    company_name: "Mons Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be886651ba6.jpg?v=1",
    address: "Walpershofer Str. 9, 66292 Riegelsberg",
    telephone: "+49 6806 860555",
    website: "http://www.jetzt-photovoltaik.de",
    email: "Info@Jetzt-Photovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/jes-tec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94779&utm_campaign=profiles_installer",
    company_name: "Jes-Tec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dedfd35fac7.png?v=1",
    address: "Löhdorfer Str. 140, 42699 Solingen",
    telephone: "+49 212 650045800",
    website: "http://www.jes-tec.de",
    email: "info@jes-tec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jenergy-business?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83140&utm_campaign=profiles_installer",
    company_name: "JEnergy Business Limited",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58bcdc8038e7c.jpg?v=1",
    address: "Leyer Straße 37, 49076 Osnabrück",
    telephone: "+49 5407 8129910",
    website: "http://www.jenergy-business.de",
    email: "info@jenergy-business.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-jahn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92826&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Jahn GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0b130171a0.jpg?v=1",
    address: "Schulstraße 17, 08428, Langenbernsdorf",
    telephone: "+49 3761 3463",
    website: "http://jahn-dachdeckerei.de/",
    email: "info@jahn-dachdeckerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/jada-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93836&utm_campaign=profiles_installer",
    company_name: "Jada Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b776eacc0.jpg?v=1",
    address: "Hinter Gärten 1, D-78176 Blumberg",
    telephone: "+49 7702 477800",
    website: "http://www.jada-elektrotechnik.de",
    email: "info@jada-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/jacobi-und-flother?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94252&utm_campaign=profiles_installer",
    company_name: "Jacobi u. Flother Dächer und Gerüste GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cde0eaa4f82.png?v=1",
    address: "Uhlandstr. 45, D-13156 Berlin",
    telephone: "+49 30 4247668",
    website: "http://www.jacobi-und-flother.de",
    email: "info@jacobi-und-flother.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energietechnik-packmohr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92824&utm_campaign=profiles_installer",
    company_name: "Packmohr Energie- & Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0afd782c5f.jpg?v=1",
    address: "Wiesenweg 2, 29559 Wrestedt",
    telephone: "+49 5802 9701289",
    website: "http://www.j-packmohr.de",
    email: "info@j-packmohr.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-bauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10524&utm_campaign=profiles_installer",
    company_name: "Dachdecker Jürgen Bauer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10524n.gif?v=1",
    address: "Kurfürst-Eppstein-Ring 20, 63877, Sailauf",
    telephone: "+49 6093 995883",
    website: "http://www.dachdecker-jbauer.de",
    email: "info@j-bauer.org",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Österreich,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ivr-energieverteilungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13754&utm_campaign=profiles_installer",
    company_name: "IVR Energieverteilungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576a385748950.jpg?v=1",
    address: "Mainaer Str. 2, 99441, Ottstedt, Magdala",
    telephone: "+49 36454 50621",
    website: "http://ivrenergy.de",
    email: "info@ivrenergy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ist-innovative-solar-technologie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1644&utm_campaign=profiles_installer",
    company_name: "IST Innovative Solar Technologie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575e7b61c087e.jpg?v=1",
    address: "Kreuzwiesenstr. 1, D-75181 Pforzheim",
    telephone: "+49 72344763",
    website: "http://www.ist-solar.de",
    email: "info@ist-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Mastervolt International BV,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/issler-gmbh-heizungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1643&utm_campaign=profiles_installer",
    company_name: "Issler GmbH- Heizungs- und Sanitärtechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51b4231717b49.gif?v=1",
    address: "Waldemar-Hellmich-Strasse 2, 79639 Grenzach-Wyhlen",
    telephone: "+49 7624 50500",
    website: "http://www.issler.de",
    email: "info@issler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ism-baugesellschaft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56587&utm_campaign=profiles_installer",
    company_name: "ISM Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60095d57a7cf6.png?v=1",
    address: "Röhrenstraße 75, 06749 Bitterfeld",
    telephone: "+49 3493 600559",
    website: "http://www.ism-energy.de",
    email: "info@ism-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/iqma-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10138&utm_campaign=profiles_installer",
    company_name: "iQma-energy GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10138n.gif?v=1",
    address: "Hammer 7, 59889 Eslohe",
    telephone: "+49 2973 9791590",
    website: "http://solaranlagen-hsk.de/",
    email: "info@iqma-energy.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ips-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4823&utm_campaign=profiles_installer",
    company_name: "IPS-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/565e5cdac658e.jpg?v=1",
    address: "Poststr. 1, 08459, Neukirchen",
    telephone: "+49 3762 7593170",
    website: "http://www.ips-sol.de",
    email: "info@ips-sol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Schweiz, Deutschland, Frankreich,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Eurener Group,,,Solvis d.o.o.,,,Calyxo GmbH,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ipdad?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83136&utm_campaign=profiles_installer",
    company_name: "Integrierte Photovoltaik DemonstrationsAnlage Dimbach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/607d9ab383256.PNG?v=1",
    address: "Marienstraße 13, 97332 Volkach-Dimbach",
    telephone: "+49 9381 9191",
    website: "http://ipdad.de",
    email: "info@ipdad.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ip-engineers-with-partner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93672&utm_campaign=profiles_installer",
    company_name: "ip³ | Engineers with Partner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c2126f1c8d4.jpg?v=1",
    address: "Dr.-Pfleger-Str. 34, 92637 Weiden",
    telephone: "+49 961 40191360",
    website: "http://www.ip3-energie.de",
    email: "info@ip3-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/iozzo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81971&utm_campaign=profiles_installer",
    company_name: "Firma Iozzo e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d47db9f2d0ea.jpg?v=1",
    address: "Hebelsteinstraße 8, 78247 Hilzingen",
    telephone: "+49 7731 9764700",
    website: "http://www.iozzo.de",
    email: "info@iozzo.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Sharp Energy Solutions Europe,,,LG Electronics, Inc.,,,Sunways LLC,,,Wiosun For Renewable Energy Ltd,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/inutec-solarcenter-international?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=55437&utm_campaign=profiles_installer",
    company_name: "Inutec Solarcenter International GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55543d550d86a.jpg?v=1",
    address: "Im Gewerbegebiet 17, 38315 Schladen",
    telephone: "+49 5335 8089960",
    website: "http://inutec-int.com",
    email: "info@inutec-int.com",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Indonesien, Philippinen,",
    "Lieferanten von Solarmodulen": ",AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Imeon Energy,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung, Training",
    Muttergesellschaft: "",
    "Last Update": 28062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/intakt-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92822&utm_campaign=profiles_installer",
    company_name: "Intakt Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0ad1e0bc9a.jpg?v=1",
    address: "Aurlfing 5a, 85461, Bockhorn",
    telephone: "+49 8122 955145",
    website: "http://intakt-solar.de/",
    email: "info@intakt-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Energetica Energy GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/instatech-meyer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93799&utm_campaign=profiles_installer",
    company_name: "Instatech Meyer Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c488d77c3fb.jpg?v=1",
    address: "Rudolf-Diesel-Str. 18, 49479 Ibbenbüren",
    telephone: "+49 5451 949320",
    website: "http://www.instatech.de",
    email: "info@instatech.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/inoval?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30381&utm_campaign=profiles_installer",
    company_name: "Inoval Dienstleistungen Unternehmergesellschaft",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e76e9bca804.jpg?v=1",
    address: "Im Steingarten 10, 74722, Buchen",
    telephone: "+49 6281 5579881",
    website: "http://www.inoval.de",
    email: "info@inoval.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solar Frontier K.K.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/innoheiz-hannover?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124466&utm_campaign=profiles_installer",
    company_name: "InnoHeiz Hannover GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb65ae37e6ef.jpg?v=1",
    address: "Ernst-Grote-Str. 13, 30916 Isernhagen",
    telephone: "+49 511 54435477",
    website: "http://www.innoheiz.de",
    email: "info@innoheiz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/intec-energy-solutions-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92450&utm_campaign=profiles_installer",
    company_name: "INTEC Energy Solutions",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4ff41ed2404.png?v=1",
    address: "Frauentorgraben 5, 90443 Nürnberg",
    telephone: "+49 911 284788489",
    website: "http://www.in-tecenergy.com",
    email: "info@in-tecenergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Niederlande, Turkei, Polen,Global,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,Fimer S.p.A.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/iliotec-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2024&utm_campaign=profiles_installer",
    company_name: "Iliotec Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/512ae6e8473fe.gif?v=1",
    address: "Eschenbacherstraße 1, 93057, Regensburg",
    telephone: "+49 941 29770180",
    website: "http://www.iliotec.de",
    email: "info@iliotec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ikavau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92819&utm_campaign=profiles_installer",
    company_name: "iKaVau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0aa9db2098.jpg?v=1",
    address: "Isarstraße 42, 84100, Niederaichbach",
    telephone: "+49 8702 9474324",
    website: "http://www.ikavau.de",
    email: "info@ikavau.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/thiel-alff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94207&utm_campaign=profiles_installer",
    company_name: "Thiel & Alff GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb84be8a7a.png?v=1",
    address: "Danielsberg 7, D-54614 Schönecken",
    telephone: "+49 6553 2332",
    website: "http://www.ihr-dach.de",
    email: "info@ihr-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/igl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94601&utm_campaign=profiles_installer",
    company_name: "IGL Umwelt und Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7e506e921.png?v=1",
    address: "Horchlerstraße 15,92536 Pfreimd",
    telephone: "+49 9606 9230300",
    website: "http://www.igl-gerhard.de",
    email: "info@igl-gerhard.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/craenmehr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92145&utm_campaign=profiles_installer",
    company_name: "Craenmehr GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fff4e7fe2760.png?v=1",
    address: "Zeppelinstraße 44, 47608 Geldern",
    telephone: "+49 2831 86477",
    website: "http://www.craenmehr.de",
    email: "info@igetec-gruppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ifm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94984&utm_campaign=profiles_installer",
    company_name: "IFM Industrie Facility Management GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e07b1f7bd7b.jpg?v=1",
    address: "Spitalwaldstraße 10, 91126 Schwabach",
    telephone: "+49 9122 9999020",
    website: "http://www.ifm-industrie.de",
    email: "info@ifm-industrie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/interfon-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93155&utm_campaign=profiles_installer",
    company_name: "Interfon GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b75770ba769.jpg?v=1",
    address: "Gundelfinger Str. 21, 89567, Sontheim",
    telephone: "+49 7325 9528721",
    website: "https://www.if-solar.de",
    email: "info@if-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ietm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84845&utm_campaign=profiles_installer",
    company_name: "Industrie- und Elektrotechnik Müller e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e732ed18ecb.jpg?v=1",
    address: "Von-Humboldt-Straße 128, 52511 Geilenkirchen",
    telephone: "+49 2451 9401776",
    website: "http://ietm.de",
    email: "info@ietm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroservice-kirsammer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93554&utm_campaign=profiles_installer",
    company_name: "Elektroservice Kirsammer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c085de54d52.jpg?v=1",
    address: "Bei der Rayse 3, 72525 Münsingen",
    telephone: "+49 7381 9386820",
    website: "http://energievonsonne.de",
    email: "info@iek-muensingen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,SunPower Corp.,,,EGing Photovoltaic Technology Co., Ltd.,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ideematec-deutschland?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4110&utm_campaign=profiles_installer",
    company_name: "Ideematec Deutschland GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5af160819a5b0.png?v=1",
    address: "Neusling 9c, 94574, Wallerfing",
    telephone: "+49 9936 9510900",
    website: "http://www.ideematec.de",
    email: "info@ideematec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/idealstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81661&utm_campaign=profiles_installer",
    company_name: "Idealstrom GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588843f3e0859.jpg?v=1",
    address: "Peutestraße 53a, 20539 Hamburg",
    telephone: "+49 40 21085573",
    website: "http://www.idealstrom.de",
    email: "info@idealstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ide-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103541&utm_campaign=profiles_installer",
    company_name: "IDE-Elektro",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebc3614e550.jpg?v=1",
    address: "Oberdorf 3, 99765 Urbach",
    telephone: "+49 36333 70833",
    website: "https://www.ide-elektro.de",
    email: "info@ide-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernd-ickert-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92864&utm_campaign=profiles_installer",
    company_name: "Bernd Ickert Elektroanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0cb467599f.jpg?v=1",
    address: "Spreestraße 1-3, 24539, Neumünster",
    telephone: "+49 4321 99090",
    website: "http://ickert-elt.de/",
    email: "info@ickert-elt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/icarus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=60817&utm_campaign=profiles_installer",
    company_name: "Icarus Photovoltaik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58d4dab46c218.jpg?v=1",
    address: "Ziegelhofstraße 232, 79110 Freiburg im Breisgau",
    telephone: "+49 761 61254444",
    website: "http://www.icarusmontage.com",
    email: "info@icarusMontage.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Schweiz, Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ib-vogt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8081&utm_campaign=profiles_installer",
    company_name: "ib vogt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/587701dc7ddae.jpg?v=1",
    address: "Helmholtzstr. 2-9, 10587 Berlin",
    telephone: "+49 30 3974400",
    website: "http://www.ibvogt.com",
    email: "info@ibvogt.com",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in":
      "Deutschland, Großbritannien, Indien, Singapur,Asien-Pazifik, Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/ibs-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93825&utm_campaign=profiles_installer",
    company_name: "IBS Gruppe",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4adefe9d17.jpg?v=1",
    address: "Hauptstasse 135, 76344 Eggenstein",
    telephone: "+49 721 970570",
    website: "https://www.ibs-gruppe.de",
    email: "info@ibs-gruppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Tesla PV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ibg-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93790&utm_campaign=profiles_installer",
    company_name: "IBG Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c476e64846a.jpg?v=1",
    address: "St. Osdag Straße 17, 31535 Neustadt a. Rbge",
    telephone: "+49 5072 2589810",
    website: "http://www.ibg-corp.de",
    email: "info@ibg-corp.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,SunPower Corp.,,,Luxor Solar GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ibe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124873&utm_campaign=profiles_installer",
    company_name: "IBE Ingenieurbüro Für Erneuerbare Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecfadf7d4aeb.gif?v=1",
    address: "Stotzarder Str., 22, D-86447 Aindling",
    telephone: "+49 821 41903099",
    website: "http://ibe-solar.de/",
    email: "info@ibe-solar.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ibc-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1501&utm_campaign=profiles_installer",
    company_name: "IBC Solar AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d27c8e452cb.jpg?v=1",
    address: "Am Hochgericht 10, 96231, Bad Staffelstein",
    telephone: "+49 9573 92240",
    website: "http://www.ibc-solar.de",
    email: "info@ibc-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": ",Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/zeitgeist?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58293&utm_campaign=profiles_installer",
    company_name: "Zeitgeist Engineering GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6022a00c04b18.png?v=1",
    address: "Äußere Sulzbacher Str. 29, 90491 Nürnberg",
    telephone: "+49 911 21707400",
    website: "http://www.zeitgeist-engineering.de",
    email: "info@ib-zeitgeist.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 9022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/iaccess?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=125508&utm_campaign=profiles_installer",
    company_name: "iAccess Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ef98171891db.jpg?v=1",
    address: "Oltmannsstrasse 26, 79100 Freiburg",
    telephone: "+49 761 88862810",
    website: "http://i-access.online",
    email: "info@iaccess.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Australien, Deutschland,,Asien, Europa",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-w-energieprojekte-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=23302&utm_campaign=profiles_installer",
    company_name: "H&W Energieprojekte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5acda667972f7.jpg?v=1",
    address: "Hebelstraße 11, 79400 Kandern",
    telephone: "+49 7626 2619848",
    website: "http://www.hw-energie.de",
    email: "info@hw-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-w-dach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94379&utm_campaign=profiles_installer",
    company_name: "H&W Dach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db2c3276fcb.png?v=1",
    address: "Industriestraße 1, 66583 Spiesen-Elversberg",
    telephone: "+49 6821 7427840",
    website: "http://www.h-w-dachgmbh.de",
    email: "info@hw-dachgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hummel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92813&utm_campaign=profiles_installer",
    company_name: "Elektro Hummel GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0a729668a2.jpg?v=1",
    address: "Dreikönigstraße 4, 79102 Freiburg im Breisgau",
    telephone: "+49 761 72912",
    website: "http://www.hummelelektro.de",
    email: "info@hummelelektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hummel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93717&utm_campaign=profiles_installer",
    company_name: "Hummel Systemhaus GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c317df3eb39.jpg?v=1",
    address: "In den Gernäckern 13, 72636 Frickenhausen-Linsenhofen",
    telephone: "+49 7025 912710",
    website: "https://hummel-systemhaus.de",
    email: "info@hummel-systemhaus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/christof-humm-dach-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75161&utm_campaign=profiles_installer",
    company_name: "Christof Humm Dach & Solartechnik Dachdeckermeisterbetrieb",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608be83e5bcee.PNG?v=1",
    address: "Eritkampsweg 5, 47625 Kevelaer",
    telephone: "+49 2832 9777272",
    website: "https://www.humm-solar.de",
    email: "info@humm-bedachung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/huka-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75881&utm_campaign=profiles_installer",
    company_name: "Huka Solar GmbH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608813218b574.PNG?v=1",
    address: "Fallenweg 5, 78126 Königsfeld",
    telephone: "+49 7725 914490",
    website: "http://www.huka-solar.de",
    email: "info@huka-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hug-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92811&utm_campaign=profiles_installer",
    company_name: "Hug Zimmerei GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0a6db74bf7.jpg?v=1",
    address: "Im Brühl 2, 79254 Oberried",
    telephone: "+49 7661 938381",
    website: "http://www.hug-zimmerei.de",
    email: "info@hug-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/karl-husing?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11869&utm_campaign=profiles_installer",
    company_name: "Karl Hüsing GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11869n.gif?v=1",
    address: "Rotenburger Straße 17, 27367 Sottrum",
    telephone: "+49 4264 83940",
    website: "https://www.huesing-sottrum.de",
    email: "info@huesing-sottrum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hubert-schmid-bauunternehmen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94924&utm_campaign=profiles_installer",
    company_name: "Hubert Schmid Bauunternehmen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e04112a8aae.png?v=1",
    address: "Iglauer Strasse 2, D-87616 Marktoberdorf",
    telephone: "+49 8342 961071",
    website: "http://www.hubert-schmid.de",
    email: "info@hubert-schmid.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Hubert Schmid Unternehmensgruppe,",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/huber-solar-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75953&utm_campaign=profiles_installer",
    company_name: "Huber Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d2189bd680f.jpg?v=1",
    address: "Blankenöd 1, 84140 Gangkofen-Kollbach",
    telephone: "+49 8735 314",
    website: "http://huber-solar.de",
    email: "info@huber-solar.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/fur-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75891&utm_campaign=profiles_installer",
    company_name: "Für Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d0c253a473e.jpg?v=1",
    address: "St.-Walburg-Straße 34, 91734 Mitteleschenbach",
    telephone: "+49 9871 657100",
    website: "http://www.fuersolar.de",
    email: "info@http://www.fuersolar.de/",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/htb-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92499&utm_campaign=profiles_installer",
    company_name: "HTB Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a90ab7d5a2a.jpg?v=1",
    address: "Gustav-Nachtigal-Straße 5, 14770 Brandenburg",
    telephone: "+49 3381 34950",
    website: "http://www.htb-haustechnik.de",
    email: "info@htb-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hsl-laibacher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=42191&utm_campaign=profiles_installer",
    company_name: "HSL Laibacher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fb243375ab68.PNG?v=1",
    address: "Im Gewerbegebiet 12, 63831 Wiesen",
    telephone: "+49 6096 9700700",
    website: "http://www.hsl-laibacher.de",
    email: "info@hsl-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,Delta Electronics, Inc.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hsi-steinfurt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92449&utm_campaign=profiles_installer",
    company_name: "HSI Steinfurt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8df9c6e7a1.jpg?v=1",
    address: "Hollich 29, 48565, Steinfurt",
    telephone: "+49 171 9569603",
    website: "http://www.hsi-steinfurt.de",
    email: "info@hsi-steinfurt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-hron?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10032&utm_campaign=profiles_installer",
    company_name: "H.Hron GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/505fb2d8eda83.PNG?v=1",
    address: "Gerstenstraße 30, 06542 Allstedt",
    telephone: "+49 34652 67290",
    website: "http://www.hron-solaranlagen.de",
    email: "info@hron-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hpv-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58189&utm_campaign=profiles_installer",
    company_name: "HPV-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c420f14f954.jpg?v=1",
    address: "Unterbruch 24-26, 47877 Willich",
    telephone: "+49 2154 5523",
    website: "http://www.hpv-solar-gmbh.de",
    email: "info@hpv-solar-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Solarnova Deutschland GmbH,",
    "Wechselrichter-Lieferanten":
      ",KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hahn-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102690&utm_campaign=profiles_installer",
    company_name: "Hahn Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac99685ea6f5.jpg?v=1",
    address: "Ötterichweg 5, 90411 Nürnberg",
    telephone: "+49 911 951159",
    website: "http://www.hahn-haustechnik.de",
    email: "info@hp-koestler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/howald-dachdeckermeister?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92488&utm_campaign=profiles_installer",
    company_name: "Howald Dachdeckermeister GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a90720e1365.jpg?v=1",
    address: "Rockwinkeler Landstraße 81, 28355 Bremen-Oberneuland",
    telephone: "+49 421 250816",
    website: "http://www.howald.de",
    email: "info@howald.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-horstkamp-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10538&utm_campaign=profiles_installer",
    company_name: "Siegfried Horstkamp Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10538n.gif?v=1",
    address: "Rottbruchstr. 4, 44625 Herne",
    telephone: "+49 2325 466252",
    website: "http://haustechnik-horstkamp.de",
    email: "info@horstkamp-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hoppesack?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30371&utm_campaign=profiles_installer",
    company_name: "Hoppesack Meß & Regeltechnik Vertriebs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e76c2685a1e.jpg?v=1",
    address: "Carl-Zeiss-Straße 12, 63456, Hanau",
    telephone: "+49 6181 964310",
    website: "http://www.hoppesack.de",
    email: "info@hoppesack.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hoppe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=605&utm_campaign=profiles_installer",
    company_name: "Hoppe GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/595dac0c8df98.jpg?v=1",
    address: "Hindenburgstr. 40, 27442 Gnarrenburg",
    telephone: "+49 4763 8046",
    website: "http://www.hoppegmbh.de",
    email: "info@hoppegmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/homann-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=113332&utm_campaign=profiles_installer",
    company_name: "Homann Solar GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c9d84a593e37.jpg?v=1",
    address: "Lilienthalstraße 28, 49716 Meppen",
    telephone: "+49 5931 8885108",
    website: "http://www.homann-solarbau.de",
    email: "info@homann-solarbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-hans-peter-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93900&utm_campaign=profiles_installer",
    company_name: "Zimmerei Hans Peter Müller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8705512944.jpg?v=1",
    address: "Johannes-Keßler Ring 10, 88487 Mietingen-Baltringen",
    telephone: "+49 7356 3641",
    website: "http://www.holzhausbau-mueller.de",
    email: "info@holzhausbau-mueller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/weiss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93978&utm_campaign=profiles_installer",
    company_name: "Weiss GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9a9f031df9.jpg?v=1",
    address: "Jurabergstr. 6, 91171 Greding, Röckenhofen",
    telephone: "+49 8463 305",
    website: "http://www.holzhaus-holzbau-weiss.de",
    email: "info@holzhaus-holzbau-weiss.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/helmut-dorr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95155&utm_campaign=profiles_installer",
    company_name: "Helmut Dörr GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60147f0822a59.png?v=1",
    address: "Bergstraße 21a, 66346 Köllerbach",
    telephone: "+49 6806 4259",
    website: "http://www.dachdeckerei-dörr.de",
    email: "info@holzhaeuser-im-saarland.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-steeger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93757&utm_campaign=profiles_installer",
    company_name: "Holzbau Steeger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c382c2c3df9.jpg?v=1",
    address: "Wüstenstein70, 91346 Wiesenttal",
    telephone: "+49 9196 463",
    website: "http://www.holzbau-steeger.de",
    email: "info@holzbau-steeger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-schmid?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=118156&utm_campaign=profiles_installer",
    company_name: "Zimmerei Schmid",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d6cd2be750dd.png?v=1",
    address: "Mittelstraße 18, 89568 Hermaringen",
    telephone: "+49 7322 23605",
    website: "https://holzbau-schmid.de",
    email: "info@holzbau-schmid.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/saur-holzbau-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116880&utm_campaign=profiles_installer",
    company_name: "Saur Holzbau & Zimmerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3e99df8ac1e.png?v=1",
    address: "Robert-Bosch-Straße 10, 72116 Mössingen",
    telephone: "+49 175 9161609",
    website: "https://www.holzbau-saur.de",
    email: "info@holzbau-saur.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/roszmoller-und-steinhoff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94373&utm_campaign=profiles_installer",
    company_name: "Roßmöller und Steinhoff GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db2751e8e5e.png?v=1",
    address: "Oststraße 14, 48301 Nottuln",
    telephone: "+49 2502 24170",
    website: "https://holzbau-rs.de",
    email: "info@holzbau-rs.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-pfander?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93930&utm_campaign=profiles_installer",
    company_name: "Holzbau Pfänder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8847bc05f6.jpg?v=1",
    address: "Kolpingstr. 20, 88416 Ochsenhausen / Längenmoos",
    telephone: "+49 7352 9798960",
    website: "http://www.holzbau-pfaender.de",
    email: "info@holzbau-pfaender.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/handwerksmeister-im-zimmererhandwerk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10702&utm_campaign=profiles_installer",
    company_name: "Handwerksmeister im Zimmererhandwerk",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55f148ad2549a.jpg?v=1",
    address: "Hauweg 36-38, 72768 Reutlingen-Oferdingen",
    telephone: "+49 7121 3017889",
    website: "http://www.holzbau-linhart.de",
    email: "info@holzbau-linhart.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/andreas-kunzel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93895&utm_campaign=profiles_installer",
    company_name: "Andreas Künzel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c86ba32ac0f.jpg?v=1",
    address: "Am Erlenbach 12, D-77736 Zell am Harmersbach",
    telephone: "+49 7835 426568",
    website: "http://www.holzbau-kuenzel.de",
    email: "info@holzbau-kuenzel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-hans-konig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93924&utm_campaign=profiles_installer",
    company_name: "Holzbau Hans König",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8808b76f7a.jpg?v=1",
    address: "Windischhausen 69, 91757 Windischhausen",
    telephone: "+49 9142 8929",
    website: "http://www.holzbau-koenig.com",
    email: "info@holzbau-koenig.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-solarsysteme-hellmuth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94303&utm_campaign=profiles_installer",
    company_name: "Holzbau Hellmuth GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fb90976a1680.png?v=1",
    address: "Stettiner Straße 39, 34225 Baunatal Großenritte",
    telephone: "+49 5601 965030",
    website: "https://www.holzbau-hellmuth.de",
    email: "info@holzbau-hellmuth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heidler-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10810&utm_campaign=profiles_installer",
    company_name: "Peter Heidler Zimmerei - Holzbau - Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10810n.gif?v=1",
    address: "Beuchaerstraße 14, 74392 Freudental",
    telephone: "+49 71 43402224",
    website: "http://www.holzbau-heidler.de",
    email: "info@holzbau-heidler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-eberlein-rappsilber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81751&utm_campaign=profiles_installer",
    company_name: "Holzbau Eberlein & Rappsilber GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58954b77a73c2.jpg?v=1",
    address: "Schoemperlenstrasse 11 a, 76185 Karlsruhe",
    telephone: "+49 721 95139481",
    website: "http://eberlein-rappsilber.de",
    email: "info@holzbau-er.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-bunz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101839&utm_campaign=profiles_installer",
    company_name: "Holzbau Bunz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa749fe048f8.jpg?v=1",
    address: "Langenauer Weg 17, 89189 Neenstetten",
    telephone: "+49 7340 9194120",
    website: "http://www.holzbau-bunz.de",
    email: "info@holzbau-bunz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-anselm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93846&utm_campaign=profiles_installer",
    company_name: "Holzbau Anselm e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4bc1b3854e.jpg?v=1",
    address: "Laubertsweg 19, 77743 Altenheim",
    telephone: "+49 7807 2135",
    website: "http://www.holzbau-anselm.de",
    email: "info@holzbau-anselm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-albiez?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94296&utm_campaign=profiles_installer",
    company_name: "Holzbau Albiez GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cf55b1c0121.jpg?v=1",
    address: "Daimlerstraße 29b, 79761 Waldshut-Tiengen",
    telephone: "+49 7741 5711",
    website: "http://www.holzbau-albiez.de",
    email: "info@holzbau-albiez.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/holz-solar-haus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83082&utm_campaign=profiles_installer",
    company_name: "Holz-Solar-Haus",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5af92e1e3301b.png?v=1",
    address: "Morgenröther Str. 7a, 08262 Muldenhammer",
    telephone: "+49 37465 41177",
    website: "http://www.holz-solar-haus.de",
    email: "info@holz-solar-haus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Wiosun For Renewable Energy Ltd,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holintec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=121250&utm_campaign=profiles_installer",
    company_name: "Holintec",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e1d19c1094a0.jpg?v=1",
    address: "Poststrasse 1, D-21509 Glinde/Hamburg",
    telephone: "+49 40 64226413",
    website: "http://www.holintec.com",
    email: "info@holintec.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16012020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holger-braaf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94654&utm_campaign=profiles_installer",
    company_name: "Holger Braaf GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddba1702719.png?v=1",
    address: "Krumackerfeld 10, 24997 Wanderup",
    telephone: "+49 4606 965900",
    website: "https://holger-braaf.de",
    email: "info@holger-braaf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,IBC Solar AG,,,Trina Solar Limited,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hoffmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94085&utm_campaign=profiles_installer",
    company_name: "Hoffmann Elektro & Energiesysteme GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca189cb42ff.jpg?v=1",
    address: "Osterlange 12a, 99189 Elxleben",
    telephone: "+49 361 2117893",
    website: "https://www.hoffmann-elektro-energiesysteme.de",
    email: "info@hoffmann-ees.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,Tesla PV,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hoffmann-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82723&utm_campaign=profiles_installer",
    company_name: "Hoffmann Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b38f2de5643.jpg?v=1",
    address: "Bendenweg 62, 53902 Bad Münstereifel",
    telephone: "+49 2253 544538",
    website: "http://hoffmann-bedachungen.eu",
    email: "info@hoffmann-bedachungen.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hormann-kommunikation-netze?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77585&utm_campaign=profiles_installer",
    company_name: "Hörmann Kommunikation & Netze GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5823ea7e5c31e.jpg?v=1",
    address: "Hauptstr. 45-47, 85614 Kirchseeon",
    telephone: "+49 8091 5630200",
    website: "http://hoermann-kn.de",
    email: "info@hoermann-kn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rudolf-hormann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7671&utm_campaign=profiles_installer",
    company_name: "Rudolf Hörmann GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e603076736d.png?v=1",
    address: "Rudolf-Hörmann-Straße 1, D-86807 Buchloe",
    telephone: "+49 8241 96820",
    website: "https://hoermann-info.com",
    email: "info@hoermann-info.com",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hormann-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102239&utm_campaign=profiles_installer",
    company_name: "Hörmann GmbH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab20b150b2e1.jpg?v=1",
    address: "Otto-Lilienthal-Straße 30, 88046 Friedrichshafen",
    telephone: "+49 7541 959900",
    website: "https://www.hoermann-fn.de",
    email: "info@hoermann-fn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aic-projects-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=18368&utm_campaign=profiles_installer",
    company_name: "AIC Projects GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/18368n.gif?v=1",
    address: "Brückenstraße 8, D-09111 Chemnitz",
    telephone: "+49 371 6666201",
    website: "http://www.aic-projects.com",
    email: "info@hoermann-bauplan.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": ",Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",Hörmann Group,",
    "Last Update": 4082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hormann-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102223&utm_campaign=profiles_installer",
    company_name: "Hörmann GmbH Energie- und Gebäudetechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1df3766b5e.jpg?v=1",
    address: "Im Farchet 2 1/2, 83646, Bad Tölz",
    telephone: "+49 8041 78550",
    website: "http://www.hoermann-badtoelz.de",
    email: "info@hoermann-badtoelz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hollerer-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93285&utm_campaign=profiles_installer",
    company_name: "Höllerer Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae3e72d2b02.png?v=1",
    address: "Neustädter Str. 10, 92685 Floß",
    telephone: "+49 9603 903250",
    website: "http://hoe-tec.de/",
    email: "info@hoe-tec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hochwind-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2008&utm_campaign=profiles_installer",
    company_name: "Hochwind-Solar Energietechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5108c9f06146c.gif?v=1",
    address: "Siebnacherstr. 33-35, 86833 Ettringen",
    telephone: "+49 82 49969440",
    website: "http://www.hochwind-solar.de",
    email: "Info@hochwind-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Sharp Energy Solutions Europe,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-heiko-oehlert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10649&utm_campaign=profiles_installer",
    company_name: "Heiko Oehlert GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/516cf15adee03.gif?v=1",
    address: "Gewerbehof 3, 24955, Harrislee",
    telephone: "+49 461 43010230",
    website: "http://www.dachdeckerei-oehlert.de",
    email: "info@ho-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hammann-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2885&utm_campaign=profiles_installer",
    company_name: "Hammann Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/2885n.gif?v=1",
    address: "Müllergässle 2, D-75365 Calw",
    telephone: "+49 7053 920696",
    website: "http://www.hmsolar.de",
    email: "info@hmsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hms-obermeier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92444&utm_campaign=profiles_installer",
    company_name: "HMS Obermeier",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8d8a82f060.jpg?v=1",
    address: "Hoch 9a, 94336 Hunderdorf",
    telephone: "+49 170 8394073",
    website: "http://www.hms-obermeier.de",
    email: "info@hms-obermeier.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hitzler-sohne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8679&utm_campaign=profiles_installer",
    company_name: "Hitzler & Söhne UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58004c652fe80.jpg?v=1",
    address: "Gunningerstr. 11, 78606 Seitingen",
    telephone: "+49 7464 3693000",
    website: "http://xn--hitzlerundshne-5pb.de",
    email: "info@hitzlerundsöhne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hhl-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94126&utm_campaign=profiles_installer",
    company_name: "HHL Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb666e3c7e0.jpg?v=1",
    address: "In der Strutt 8+10, 63599 Biebergemünd",
    telephone: "+49 6050 9086740",
    website: "http://www.hhl-elektro-service.de",
    email: "info@hhl-service.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hetho-technic-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=613&utm_campaign=profiles_installer",
    company_name: "Hetho Technic Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/613n.gif?v=1",
    address: "Lerchenweg 3, 29557, Wrestedt",
    telephone: "+49 580 2970304",
    website: "http://hetho-technic.de",
    email: "info@hetho-technic.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hep-global?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124488&utm_campaign=profiles_installer",
    company_name: "Hep Global GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb8bebe76116.jpg?v=1",
    address: "Römerstraße 3, 74363 Güglingen",
    telephone: "+49 7135 934460",
    website: "https://hep.global",
    email: "info@hep.global",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Großbritannien, Japan, USA, Taiwan,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heos-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92652&utm_campaign=profiles_installer",
    company_name: "HEOS Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae2002942b4.jpg?v=1",
    address: "Carl-von-Bach-Straße 8, 09116 Chemnitz",
    telephone: "+49 371 4004220",
    website: "http://www.heos-energy.de",
    email: "info@heos-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/henrich-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103509&utm_campaign=profiles_installer",
    company_name: "Henrich Elektroanlagen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebb11b89c33.jpg?v=1",
    address: "Bertha-von-Suttner-Straße 31-33, D-64846, Groß-Zimmern",
    telephone: "+49 6071 73331",
    website: "http://www.henrich-elektroanlagen.de",
    email: "info@henrich-elektroanlagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hennig-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92649&utm_campaign=profiles_installer",
    company_name: "Hennig Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae1eb543234.jpg?v=1",
    address: "Splau 17, 06905, Bad Schmiedeberg",
    telephone: "+49 163 2855511",
    website: "http://hennig-energy.de/",
    email: "info@hennig-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,ReneSola Ltd.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heinrich-henke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10634&utm_campaign=profiles_installer",
    company_name: "Heinrich Henke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/587c478053369.jpg?v=1",
    address: "Gelldorfer Weg 42, 31683, Obernkirchen",
    telephone: "+49 5724 95960",
    website: "http://www.henke-dachbau.de",
    email: "info@henke-dachbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/helsti-massivbau-immobilien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92485&utm_campaign=profiles_installer",
    company_name: "Helsti Massivbau & Immobilien GMBH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6000cbb8ba211.png?v=1",
    address: "Werner Str. 32, D-59368 Werne",
    telephone: "+49 2389 95030",
    website: "http://www.helsti.de",
    email: "info@helsti.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Viessmann Werke GmbH & Co. KG,,,Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/soren-hellweg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93963&utm_campaign=profiles_installer",
    company_name: "Sören Hellweg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8c8c629949.jpg?v=1",
    address: "",
    telephone: "+49 160 98719784",
    website: "http://hellweg-dach.de",
    email: "info@hellweg-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/helios-solar-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75111&utm_campaign=profiles_installer",
    company_name: "Helios Solar Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bcfa93d9fb8.jpg?v=1",
    address: "Großblittersdorfer Str. 261, 66119 Saarbrücken",
    telephone: "+49 681 59535000",
    website: "http://www.heliosgmbh.de",
    email: "info@heliosgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heliosolar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75117&utm_campaign=profiles_installer",
    company_name: "HelioSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bcfc9dac590.jpg?v=1",
    address: "Gottenheimer Straße 30, 79224 Umkirch",
    telephone: "+49 7665 9323111",
    website: "https://www.innovative-photovoltaik-loesungen.de",
    email: "info@helio-solar.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/held?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95237&utm_campaign=profiles_installer",
    company_name: "HELD Dach + Wand Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c3d9fd616e14.jpg?v=1",
    address: "Lise-Meitner-Straße 14, D-48161 Münster",
    telephone: "+49 2534 7022",
    website: "http://www.held-bedachungen.de",
    email: "info@held-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Roto Frank Professional Service GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nowak-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92477&utm_campaign=profiles_installer",
    company_name: "Nowak Heizung Sanitär Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a901694b68f.jpg?v=1",
    address: "Magnus-Hackl-Straße 15, 86929, Penzing",
    telephone: "+49 8191 9159776",
    website: "http://www.heizung-sanitaer-landsberg.de",
    email: "info@heizung-sanitaer-landsberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/j-kurt-e-k-heizung-solar-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92638&utm_campaign=profiles_installer",
    company_name: "J. Kurt e.K. Heizung Solar Service",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae18862fe09.jpg?v=1",
    address: "Großfeldstraße 12, 79618, Rheinfelden",
    telephone: "+49 7623 7418010",
    website: "http://heizung-kurt.de/",
    email: "info@heizung-kurt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/norbert-albrecht?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92471&utm_campaign=profiles_installer",
    company_name: "Norbert Albrecht",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8fefe83f67.jpg?v=1",
    address: "Bremer Straße 168, 28832, Achim",
    telephone: "+49 4202 81286",
    website: "https://www.heizung-albrecht.de",
    email: "info@heizung-albrecht.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heiztechnik-schmitt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124916&utm_campaign=profiles_installer",
    company_name: "Heiztechnik Schmitt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ed08ed0286a6.png?v=1",
    address: "Am Döllenpfad, 1, 76889 Vorderweidenthal",
    telephone: "+49 6398 993099",
    website: "http://heiztechnik-schmitt.de/",
    email: "info@heiztechnik-schmitt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heitz-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92470&utm_campaign=profiles_installer",
    company_name: "Heitz Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8fe53cca7b.jpg?v=1",
    address: "Albert-Walter-Straße 1a, 77694, Kehl-Querbach",
    telephone: "+49 7853 92890",
    website: "http://www.heitz-haustechnik.de",
    email: "info@Heitz-Haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heitstumman-mittrop?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92478&utm_campaign=profiles_installer",
    company_name: "Bernhard Heitstummann Malermeister GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6000c8449e178.png?v=1",
    address: "An den Loddenbüschen 85, 48155 Münster",
    telephone: "+49 251 65907",
    website: "http://www.heitstummann.de",
    email: "info@heitstummann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heitkotter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93733&utm_campaign=profiles_installer",
    company_name: "Heitkötter GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c335a66e9d8.jpg?v=1",
    address: "Hansaring 154, 48268 Greven",
    telephone: "+49 2571 95370",
    website: "http://www.heitkoetter.de",
    email: "info@heitkoetter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heisterkamp-dachbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75715&utm_campaign=profiles_installer",
    company_name: "Heisterkamp Dachbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cd15360612d.jpg?v=1",
    address: "Einsteinstraße 1, 59423 Unna",
    telephone: "+49 2303 41414",
    website: "http://www.heisterkamp-dachbau.de",
    email: "info@heisterkamp-dachbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heise-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74435&utm_campaign=profiles_installer",
    company_name: "Heise Elektrotechnik & IT- Service",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ac3801e8c65.jpg?v=1",
    address: "Unterdorf 16, 37199 Wulften",
    telephone: "+49 5556 99090",
    website: "http://www.heise-itservice.de",
    email: "info@heise-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heinle-energie-und-automationstechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93735&utm_campaign=profiles_installer",
    company_name: "Heinle Energie- und Automationstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c3601f2040e.jpg?v=1",
    address: "Fritz-Hopf-Straße 13, 86720 Nördlingen",
    telephone: "+49 9081 805060",
    website: "http://www.heinle-gmbh.de",
    email: "info@heinle-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heim-jorges?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93238&utm_campaign=profiles_installer",
    company_name: "Heim & Jörges GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae33f2b9256.png?v=1",
    address: "Kronenstraße 13, 34128 Kassel-Harleshausen, Hessen",
    telephone: "+49 561 988760",
    website: "http://heimundjoerges.de/",
    email: "info@heimundjoerges.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heil-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10496&utm_campaign=profiles_installer",
    company_name: "Heil Bedachungs-GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10496n.gif?v=1",
    address: "Ingelheimer Straße 6, 65385, Rüdesheim am Rhein",
    telephone: "+49 6722 90550",
    website: "http://heil-dach.com",
    email: "info@heil-dach.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heiko-hennig-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102228&utm_campaign=profiles_installer",
    company_name: "Heiko Hennig Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1f22cbaa91.jpg?v=1",
    address: "Seestraße 60, 24245 Klein Barkau",
    telephone: "+49 4302 1825",
    website: "https://www.heiko-hennig.de",
    email: "info@heiko-hennig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heidt-urschl-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58275&utm_campaign=profiles_installer",
    company_name: "Heidt & Urschl Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6022973298d09.png?v=1",
    address: "Humboldstraße 29, 75334 Straubenhardt",
    telephone: "+49 7082 8001",
    website: "http://www.heidt-urschl.de",
    email: "info@heidt-urschl.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heidrich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81969&utm_campaign=profiles_installer",
    company_name: "Heidrich Dach und Fassade GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589be38eaf670.jpg?v=1",
    address: "Langenberger Str. 777a, 45257 Essen",
    telephone: "+49 201 48961",
    website: "https://heidrich-dachdecker.de",
    email: "info@heidrich-dachdecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heger-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30357&utm_campaign=profiles_installer",
    company_name: "Heger Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e767f5db58b.jpg?v=1",
    address: "Babenhäuser Straße 55, 63762, Großostheim",
    telephone: "+49 6026 97670",
    website: "http://www.heger-energietechnik.de",
    email: "info@heger-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hbge?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1886&utm_campaign=profiles_installer",
    company_name: "HBGE GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a24e7f430d3a.png?v=1",
    address: "Eiserntalstraße 492, 57080 Siegen",
    telephone: "+49 271 31843250",
    website: "http://hbge.de",
    email: "info@hbge.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haven-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92467&utm_campaign=profiles_installer",
    company_name: "Haven Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8fc385563d.jpg?v=1",
    address: "Siemensstrasse 25a, 47533 Kleve",
    telephone: "+49 2821 22832",
    website: "http://www.haven-haustechnik.de",
    email: "info@haven-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zeller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92635&utm_campaign=profiles_installer",
    company_name: "Zeller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae13065bc3b.jpg?v=1",
    address: "Am Autohof 4, 73037 Göppingen",
    telephone: "+49 7161 97500",
    website: "http://www.haustechnik-zeller.de",
    email: "info@haustechnik-zeller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Danfoss A/S,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-will?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119861&utm_campaign=profiles_installer",
    company_name: "Haustechnik Will",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dddd2433e2a3.jpg?v=1",
    address: "Mühlenkampsweg 1, 21406 Barnstedt",
    telephone: "+49 4134 8935",
    website: "http://www.haustechnik-will.de",
    email: "info@haustechnik-will.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-steinbacher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75707&utm_campaign=profiles_installer",
    company_name: "Haustechnik Steinbacher",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cd04f1b0cbe.jpg?v=1",
    address: "Adalbert-Brunner-Straße 4b, 92421 Schwandorf",
    telephone: "+49 9431 37242",
    website: "http://www.haustechnik-steinbacher.de",
    email: "info@haustechnik-steinbacher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mr-heizung-sanitar-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92633&utm_campaign=profiles_installer",
    company_name: "MR Heizung, Sanitär und Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6002019a0140a.png?v=1",
    address: "Vanikumer Str. 64, Dyxmannshof, 41569 Rommerskirchen",
    telephone: "+49 2183 8063758",
    website: "http://www.haustechnik-rommerskirchen.de",
    email: "info@haustechnik-rommerskirchen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nowak?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75179&utm_campaign=profiles_installer",
    company_name: "NOWAK GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5df1f5cd2ae3c.jpg?v=1",
    address: "Straßen 26, 51429 Bergisch Gladbach",
    telephone: "+49 2204 9999111",
    website: "https://www.haustechnik-nowak.de",
    email: "info@haustechnik-nowak.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ludemann-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92464&utm_campaign=profiles_installer",
    company_name: "Lüdemann Haustechnik e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8fadb7cc2b.jpg?v=1",
    address: "Hohenzollernring 115a, 22763 Hamburg",
    telephone: "+49 40 94771707",
    website: "http://www.haustechnik-luedemann.de",
    email: "info@haustechnik-luedemann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/detlef-bich-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1977&utm_campaign=profiles_installer",
    company_name: "Detlef Bich Elektro & Haustechnik GmbH und Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58046490aff4b.jpg?v=1",
    address: "Gleiwitzer Str. 47, 71229 Leonberg",
    telephone: "+49 7152 398270",
    website: "http://www.haustechnik-leonberg.de",
    email: "info@haustechnik-leonberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-kraska?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94596&utm_campaign=profiles_installer",
    company_name: "Haustechnik Kraska GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7b91832aa.png?v=1",
    address: "Waldenburger Straße 25, 09353 Oberlungswitz",
    telephone: "+49 3723 42302",
    website: "https://www.haustechnik-kraska.de",
    email: "info@haustechnik-kraska.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hausgeratekundendienst-hts?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75357&utm_campaign=profiles_installer",
    company_name: "HTS Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdbd02ad89bf.png?v=1",
    address: "Kalkreiße 2, 99085 Erfurt",
    telephone: "+49 361 2124226",
    website: "http://haustechnik-hts.de",
    email: "info@haustechnik-hts.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-gruber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92459&utm_campaign=profiles_installer",
    company_name: "Haustechnik Gruber GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8f87a72f7e.jpg?v=1",
    address: "Kaikenrieder Str. 42, 94244 Teisnach",
    telephone: "+49 9923 4398975",
    website: "http://haustechnik-gruber.de",
    email: "info@haustechnik-gruber.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-grass?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11902&utm_campaign=profiles_installer",
    company_name: "Elektro Grass GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11902n.gif?v=1",
    address: "Neumühleweg 27, 73660, Urbach",
    telephone: "+49 7181 85411",
    website: "http://www.haustechnik-grass.de",
    email: "info@haustechnik-grass.de",
    "Installaliert seit": 1990,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/johann-fruhbeis?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119942&utm_campaign=profiles_installer",
    company_name: "Johann Fruhbeis Gmbh & Co. Kg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ddf8d709adc7.jpg?v=1",
    address: "Biberger Strasse 11, 82008 Unterhaching",
    telephone: "+49 89 6251640",
    website: "http://www.haustechnik-fruehbeis.de",
    email: "info@haustechnik-fruehbeis.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-feldmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92631&utm_campaign=profiles_installer",
    company_name: "Haustechnik-Feldmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae11993e5e2.jpg?v=1",
    address: "Mühlenstr. 20, 31812 Bad Pyrmont",
    telephone: "+49 5281 17746",
    website: "http://www.haustechnik-feldmann.de",
    email: "info@haustechnik-feldmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/georg-draack-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122774&utm_campaign=profiles_installer",
    company_name: "Georg Draack Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e66ec336c625.jpg?v=1",
    address: "Vennheide 41, 47877, Willich",
    telephone: "+49 2156 9159906",
    website: "https://elektrotechnik-draack.de",
    email: "info@haustechnik-draack.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-gebaudetechnik-melnyk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10093&utm_campaign=profiles_installer",
    company_name: "Elektro & Gebäudetechnik Melnyk",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c90c1ab75dd.gif?v=1",
    address: "Max-Fürst-Strasse 9, 83355 Grabenstätt-Winkl",
    telephone: "+49 8661 1427",
    website: "http://www.hausgeist.info",
    email: "info@hausgeist.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hauser-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11910&utm_campaign=profiles_installer",
    company_name: "Hauser Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11910n.gif?v=1",
    address: "Ganghoferstr. 79, 81373, München",
    telephone: "+49 89 60036532",
    website: "http://www.hauser-solartechnik.de",
    email: "info@hauser-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hauck-solarenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92629&utm_campaign=profiles_installer",
    company_name: "Hauck Solarenergie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae0f99a4cee.jpg?v=1",
    address: "Sandgasse 20, 67454, Haßloch",
    telephone: "+49 6324 925529",
    website: "http://www.hauck-solar.de",
    email: "info@hauck-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/armin-benedikt-hasler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94968&utm_campaign=profiles_installer",
    company_name: "Armin & Benedikt Hasler",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e992f63ee242.png?v=1",
    address: "Türkheimer Straße 1, 79110 Freiburg",
    telephone: "+49 761 8969400",
    website: "https://www.hasler-holztreppen.de",
    email: "info@hasler-holztreppen.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/karsten-hartnack?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92628&utm_campaign=profiles_installer",
    company_name: "Karsten Hartnack",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae0ec410b55.jpg?v=1",
    address: "Rathenaustr. 53, 42277, Wuppertal",
    telephone: "+49 202 503258",
    website: "http://hartnack-bedachungen.de/",
    email: "info@hartnack-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hartmann-dacheindeckungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94941&utm_campaign=profiles_installer",
    company_name: "Hartmann-Dacheindeckungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e055be64191.png?v=1",
    address: "Bergstraße 21, 34266 Niestetal",
    telephone: "+49 561 952270",
    website: "http://www.hartmanndach.de",
    email: "info@hartmanndach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",CREATON GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hartmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103516&utm_campaign=profiles_installer",
    company_name: "Elektro Hartmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebb5e76c5ef.jpg?v=1",
    address: "Dieselstraße 3, 21365 Adendorf",
    telephone: "+49 4131 18490",
    website: "http://www.hartmann-elektro.de",
    email: "info@hartmann-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": ",Siemens Energy,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hartmann-dachdeckerbetrieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95085&utm_campaign=profiles_installer",
    company_name: "Hartmann Dachdeckerbetrieb GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85ce333a61.jpg?v=1",
    address: "Barkhauser Straße 267, 33106 Paderborn",
    telephone: "+49 5251 91159",
    website: "http://www.hartmann-dachdeckerbetrieb.de",
    email: "info@hartmann-dachdeckerbetrieb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/oliver-dennis-hartmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94921&utm_campaign=profiles_installer",
    company_name: "O+D Hartmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c37042e6849e.jpg?v=1",
    address: "Gehrenbreite 12, 31542 Bad Nenndorf",
    telephone: "+49 5723 75954",
    website: "http://www.hartmann-bedachung.de",
    email: "info@hartmann-bedachung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",CREATON GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/harth-und-sohne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94301&utm_campaign=profiles_installer",
    company_name: "Harth und Söhne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cf58821841b.jpg?v=1",
    address: "Mühlenteich 4, 66679 Losheim am See",
    telephone: "+49 6872 7841",
    website: "http://www.harth-u-soehne.de",
    email: "info@harth-soehne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hnm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76653&utm_campaign=profiles_installer",
    company_name: "Hans Nielsen Medientechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ecab172223a.jpg?v=1",
    address: "Am Teich 11, D-25926 Westre",
    telephone: "+49 4666 746",
    website: "http://www.hans-nielsen.de",
    email: "info@hans-nielsen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Luxor Solar GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,AISWEI New Energy Technology (Jiangsu) Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hans-lanz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93793&utm_campaign=profiles_installer",
    company_name: "Hans Lanz Gebäude- und Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c47d9a0567d.jpg?v=1",
    address: "Engetsweiler Straße 22, D-88239 Wangen im Allgäu",
    telephone: "+49 7528 95170",
    website: "http://www.hans-lanz.de",
    email: "info@hans-lanz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hans-klein-heizung-sanitar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92625&utm_campaign=profiles_installer",
    company_name: "Hans Klein Heizung - Sanitär GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae0c3baedaf.jpg?v=1",
    address: "Auchtertstraße 31, 73278 Schlierbach",
    telephone: "+49 7021 970070",
    website: "http://www.hans-klein.de",
    email: "info@hans-klein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hanni-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=14480&utm_campaign=profiles_installer",
    company_name: "Hanni Sustainable Investments GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52f87e503ee54.jpg?v=1",
    address: "Am Heideberg 36, 06886, Lutherstadt Wittenberg",
    telephone: "+49 3491 4595160",
    website: "http://www.hanni-solar.de",
    email: "info@hanni-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hanebutt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93804&utm_campaign=profiles_installer",
    company_name: "Hanebutt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c49026eaf61.jpg?v=1",
    address: "Justus-von-Liebig-Straße 16, 31535 Neustadt am Rubenberge",
    telephone: "+49 5032 952140",
    website: "http://www.hanebutt.de",
    email: "info@hanebutt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hamacher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=85379&utm_campaign=profiles_installer",
    company_name: "Hamacher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5af28de1b237a.jpg?v=1",
    address: "Montanusstraße 78, 51789 Lindlar",
    telephone: "+49 22 664799177",
    website: "http://www.hamacher.co",
    email: "info@hamacher.co",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haidle-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102683&utm_campaign=profiles_installer",
    company_name: "Haidle-Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac98f62f0cf3.jpg?v=1",
    address: "Alfons-Gerl-Str. 8A, 93077 Bad Abbach",
    telephone: "+49 9405 9574125",
    website: "http://www.haidle-energietechnik.de",
    email: "info@haidle-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hahn-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=109466&utm_campaign=profiles_installer",
    company_name: "Hahn Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bd14206622df.jpg?v=1",
    address: "85757 München",
    telephone: "+49 8131 2975975",
    website: "http://hahn-solar.com",
    email: "info@hahn-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-haussler-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7307&utm_campaign=profiles_installer",
    company_name: "Elektro Häussler Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7307n.gif?v=1",
    address: "Hauptstraße 24, 89171, Illerkirchberg",
    telephone: "+49 7346 3747",
    website: "http://www.haeussler-energietechnik.de",
    email: "info@haeussler-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/haarlander?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81577&utm_campaign=profiles_installer",
    company_name: "Heizungs & Lüftungsbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588556826e8c1.jpg?v=1",
    address: "Petersgmünder Str. 11, 91166 Georgensgmünd",
    telephone: "+49 9172 969",
    website: "http://www.haarlaender.de",
    email: "info@haarlaender.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bedachungen-haag?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10818&utm_campaign=profiles_installer",
    company_name: "Zimmerei Bedachungen Alexander Haag",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10818n.gif?v=1",
    address: "Unterholz 16, 88263 Horgenzell",
    telephone: "+49 7504 970970",
    website: "http://www.haag-zimmerei.de",
    email: "info@haag-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-kathmann-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93482&utm_campaign=profiles_installer",
    company_name: "H. Kathmann Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf65cf9a433.jpg?v=1",
    address: "Lindenstraße 8, 29553 Bienenbüttel",
    telephone: "+49 5823 98070",
    website: "https://www.h-kathmann.de",
    email: "info@h-kathmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-k-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93340&utm_campaign=profiles_installer",
    company_name: "H&K Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb3e4d48474.jpg?v=1",
    address: "Nieverner Str. 46, 56130 Bad Ems",
    telephone: "+49 2603 507807",
    website: "http://h-k-solarbedachungen.de/",
    email: "info@h-k-badachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gwinner-bedachungen-fassaden?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10114&utm_campaign=profiles_installer",
    company_name: "Gwinner Bedachungen & Fassaden GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/584f6aed39df6.jpg?v=1",
    address: "Werner-von-Siemens Str.16, D-78224 Singen",
    telephone: "+49 7731 9759814",
    website: "http://www.gwinner.com",
    email: "info@gwinner.com",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gwh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93673&utm_campaign=profiles_installer",
    company_name: "Gemeindewerke Haßloch GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c212e8aa920.jpg?v=1",
    address: "Gottlieb-Duttenhöfer-Straße 27, 67454 Haßloch",
    telephone: "+49 6324 59940",
    website: "https://www.gwhassloch.de",
    email: "info@gwhassloch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/g-w-energytec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74437&utm_campaign=profiles_installer",
    company_name: "G+W EnergyTec GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ac3b3723450.jpg?v=1",
    address: "Im Böge 26, 31226 Peine",
    telephone: "+49 170 3479674",
    website: "http://gw-energytec.com",
    email: "info@gw-energytec",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gutjahr?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95203&utm_campaign=profiles_installer",
    company_name: "Gutjahr Dachdeckermeister GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60149d00bed6b.png?v=1",
    address: "Lange Weihe 26, 30880 Laatzen",
    telephone: "+49 511 98230890",
    website: "http://www.gutjahr-dachdeckermeister.de",
    email: "info@gutjahr-dachtechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gunektra?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11988&utm_campaign=profiles_installer",
    company_name: "Gunektra GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11988n.gif?v=1",
    address: "Hintere Walke 3, D-78333, Stockach",
    telephone: "+49 7771 921358",
    website: "http://gunektra-gmbh.de",
    email: "info@gunektra-gmbh.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/guggemos-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94813&utm_campaign=profiles_installer",
    company_name: "Guggemos Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df04b21240c.png?v=1",
    address: "Vornholzstraße 86, D-94036 Passau",
    telephone: "+49 851 959640",
    website: "http://www.guggemos.de",
    email: "info@guggemos.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/guth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94203&utm_campaign=profiles_installer",
    company_name: "Güth GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb5bec9642.png?v=1",
    address: "Die Lach 4, 66121 Saarbrücken",
    telephone: "+49 681 967610",
    website: "http://www.gueth.de",
    email: "info@gueth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gurtler-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94236&utm_campaign=profiles_installer",
    company_name: "Gürtler Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c08e60d2e38d.jpg?v=1",
    address: "Waldemeinestraße 45a, D-32108 Bad Salzuflen",
    telephone: "+49 5222 929810",
    website: "https://guertler-elektrotechnik.com",
    email: "info@guertler-elektrotechnik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gullich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94583&utm_campaign=profiles_installer",
    company_name: "Güllich GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd73105dba6.png?v=1",
    address: "Unterfeldbrecht 28, 90616 Neuhof an der Zenn",
    telephone: "+49 9107 9249781",
    website: "http://www.guellich-gebaeudetechnik.de",
    email: "info@guellich-gebaeudetechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/g-tec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91026&utm_campaign=profiles_installer",
    company_name: "G-Tec",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f4fb26b1e75b.png?v=1",
    address: "Undorfer Str. 12, 93180, Deuerling",
    telephone: "+49 941 2804875",
    website: "https://www.gtec.bayern",
    email: "info@gtec.bayern",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gt-eco?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81564&utm_campaign=profiles_installer",
    company_name: "GT-eco GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588471f24f22c.jpg?v=1",
    address: "Kulmbacher Straße 5, D– 93057 Regensburg",
    telephone: "+49 941 8984780",
    website: "http://gt-eco.de",
    email: "info@gt-eco.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gottler-schmid?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107923&utm_campaign=profiles_installer",
    company_name: "Göttler & Schmid GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88d97cd9c45.jpg?v=1",
    address: "Wöhrer Straße 2, 85229 Markt Indersdorf",
    telephone: "+49 8136 893217",
    website: "https://gs-photovoltaik.de",
    email: "info@gs-photovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gs-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94083&utm_campaign=profiles_installer",
    company_name: "GS Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca1746bc2da.jpg?v=1",
    address: "Moerser Straße 51, 47239 Duisburg",
    telephone: "+49 2151 1537210",
    website: "http://www.gs-et.de",
    email: "info@gs-et.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/roland-grunwald?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93803&utm_campaign=profiles_installer",
    company_name: "Roland Grünwald",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c48f655099d.jpg?v=1",
    address: "Mecklenburger Str. 18a, 18184 Pastow",
    telephone: "+49 38204 15466",
    website: "http://www.bad-heizung-profi.de",
    email: "info@gruenwald-online.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/grotepass?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92619&utm_campaign=profiles_installer",
    company_name: "Grotepass GmbH Meister Der Elemente",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6001f2eba34c9.png?v=1",
    address: "Im Mühlenwinkel 5, 47506 Neukirchen-Vluyn",
    telephone: "+49 2845 28845",
    website: "http://grotepass-neukirchen-vluyn-mde.de",
    email: "info@grotepass.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gross-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81965&utm_campaign=profiles_installer",
    company_name: "Gross Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589be137e3655.jpg?v=1",
    address: "In den Hofgärten 8, 74360 Ilsfeld-Auenstein",
    telephone: "+49 7062 914770",
    website: "http://www.gross-energiesysteme.de",
    email: "info@gross-energiesysteme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-gross-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92618&utm_campaign=profiles_installer",
    company_name: "H. Gross Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae05ea5dd64.jpg?v=1",
    address: "Scheffelstr. 47, 40470, Düsseldorf",
    telephone: "+49 211 551132",
    website: "http://gross-dach.de/",
    email: "info@gross-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gronover-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103520&utm_campaign=profiles_installer",
    company_name: "Gronover Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebb6bd0b151.jpg?v=1",
    address: "Römerstraße 1, 74363 Güglingen",
    telephone: "+49 7135 974110",
    website: "http://www.gronover.de",
    email: "info@gronover.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/griesohn-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=647&utm_campaign=profiles_installer",
    company_name: "Griesohn-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11096.gif?v=1",
    address: "Brunnenhäuser Str 20, D-59425 Unna",
    telephone: "+49 2303 660829",
    website: "http://www.griesohn-solar.de",
    email: "info@griesohn-solar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/greiner-dach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=100402&utm_campaign=profiles_installer",
    company_name: "Greiner Dach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a77b534b75c7.jpg?v=1",
    address: "Merowingerstraße 13, D-70736 Fellbach",
    telephone: "+49 711 3055530",
    website: "http://www.greiner-dach.de",
    email: "info@greiner-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/greenvest-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76011&utm_campaign=profiles_installer",
    company_name: "Greenvest Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d768b714b9e.jpg?v=1",
    address: "Münchener Str. 15a, D-82319 Starnberg",
    telephone: "+49 8151 9970750",
    website: "http://www.greenvest-solar.de",
    email: "info@greenvest-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Suzhou Talesun Solar Technologies Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Danfoss A/S,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/greentech-power-by-sun-and-wind?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=120134&utm_campaign=profiles_installer",
    company_name: "Greentech Power by Sun and Wind Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5de9b39d0d8a8.jpg?v=1",
    address: "Burgstraße 13, 4869 Vreden",
    telephone: "+49 25 643975858",
    website: "http://greentech-power.com",
    email: "info@greentech-power.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Belgien, Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Antec Solar GmbH,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Kaneka Corporation,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/greenovative?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74839&utm_campaign=profiles_installer",
    company_name: "Greenovative GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b529c42373d.jpg?v=1",
    address: "Fürther Str. 252, 90429 Nürnberg",
    telephone: "+49 911 13137470",
    website: "http://www.greenovative.de",
    email: "info@greenovative.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/green-city-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13425&utm_campaign=profiles_installer",
    company_name: "Green City AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ffea26b783f2.png?v=1",
    address: "Zirkus-Krone-Str.10, 80335, München",
    telephone: "+49 89 890668",
    website: "https://www.greencity.de",
    email: "info@greencity.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Frankreich, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/greencells?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22879&utm_campaign=profiles_installer",
    company_name: "Greencells GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5722d6bb78b22.jpg?v=1",
    address: "Bahnhofstraße 28, 66111 Saarbrücken",
    telephone: "+49 681 9926690",
    website: "http://www.greencells.com",
    email: "info@greencells.com",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Italien, Rumänien,",
    "Lieferanten von Solarmodulen":
      ",JA Solar Holdings Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,,,LONGi Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/graule-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102225&utm_campaign=profiles_installer",
    company_name: "Graule Gebäudetechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60380cc5e364d.png?v=1",
    address: "Gustav-Freytag-Straße 3, 86720 Nördlingen",
    telephone: "+49 9081 80180",
    website: "http://www.graule-technik.de",
    email: "info@graule-technik.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-gratschek?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102592&utm_campaign=profiles_installer",
    company_name: "Elektro Gratschek GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac450cbe6648.png?v=1",
    address: "Singerhoffstraße 6, 44225 Dortmund",
    telephone: "+49 231 711960",
    website: "http://www.gratschek.de",
    email: "info@gratschek.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/grammer-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1782&utm_campaign=profiles_installer",
    company_name: "Grammer Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1782n.gif?v=1",
    address: "Oskar-von-Miller Str. 8, D - 92224 Amberg",
    telephone: "+49 9621 308570",
    website: "http://cms.grammer-solar.com",
    email: "info@grammer-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Spanien, Frankreich, Italien,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,Solarnova Deutschland GmbH,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gp-photovoltaik-handel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102575&utm_campaign=profiles_installer",
    company_name: "GP Photovoltaik & Handel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4400a251df.png?v=1",
    address: "Nikolaus-unruh-Str. 15, 95659 Arzberg",
    telephone: "+49 8781 2005280",
    website: "http://www.gpphotovoltaik.de",
    email: "info@gpphotovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,Europa,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Shanghai Aerospace Automobile Electromechanical Co.,Ltd.(HT-SAAE),,,Canadian Solar Inc.,,,Sunrise Energy Co., Ltd.,,,ReneSola Ltd.,,,Zytech Solar,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/goldbeck-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4829&utm_campaign=profiles_installer",
    company_name: "Goldbeck Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ff70c7320bdc.png?v=1",
    address: "Goldbeckstrasse 7, 69493, Hirschberg a.d. Bergstrasse",
    telephone: "+49 6201 7103300",
    website: "https://goldbecksolar.com",
    email: "info@goldbecksolar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,Solar Fabrik GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Canadian Solar Inc.,,,S-Energy Co., Ltd.,,,ReneSola Ltd.,,,Astronergy Co., Ltd. (Chint Solar),,,Risen Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,REFU Elektronik GmbH,,,Fimer S.p.A.,,,Schneider Electric SA,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Goldbeck Group,",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gsw?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10553&utm_campaign=profiles_installer",
    company_name: "GSW Gold Solar Wind Management GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/546ee3482c54b.jpg?v=1",
    address: "Otto-Hiendl-Straße 15, 94356 Kirchroth",
    telephone: "+49 9428 947900",
    website: "http://www.gold-solarwind.de",
    email: "info@gold-solarwind.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/gorsch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93278&utm_campaign=profiles_installer",
    company_name: "Gorsch GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba19e12b321.jpg?v=1",
    address: "Regattastraße 170, 12527, Berlin",
    telephone: "+49 30 67895630",
    website: "http://goersch.berlin/",
    email: "info@goerschgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gode-sohn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92452&utm_campaign=profiles_installer",
    company_name: "Göde & Sohn GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8f384add8d.jpg?v=1",
    address: "Schillerstrasse 5, 14547, Beelitz",
    telephone: "+49 33204 35560",
    website: "https://www.goede-beelitz.de",
    email: "info@goede-beelitz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gobel-solar-international?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1605&utm_campaign=profiles_installer",
    company_name: "Göbel Solar International",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53e04b2dd8774.jpg?v=1",
    address: "Kölnstrasse 95a, 52351 Düren",
    telephone: "+49 2421 6949336",
    website: "http://www.goebelsolar.com",
    email: "info@goebelsolar.de",
    "Installaliert seit": 1992,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Belgien, Kongo, Deutschland, Spanien, Frankreich, Sambia, Mexiko, Ruanda, Karibische Niederlande,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/klempner-installateure-gluckauf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92451&utm_campaign=profiles_installer",
    company_name: "Klempner & Installateure Glückauf",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8f1720183b.jpg?v=1",
    address: "An der Alten Schule 15, 09376, Oelsnitz Erzgeb",
    telephone: "+49 3 72983220",
    website: "https://glueckauf-oelsnitz.de",
    email: "info@glueckauf-oelsnitz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/holger-gloede-bedachungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102025&utm_campaign=profiles_installer",
    company_name: "Holger Gloede Bedachungs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6f8571b33.jpg?v=1",
    address: "Dannenberger Straße 47, 28879 Grasberg",
    telephone: "+49 4208 2113",
    website: "http://www.gloede-bedachungen.de",
    email: "info@gloede-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/global-3000?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92614&utm_campaign=profiles_installer",
    company_name: "Global 3000 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae0306ee7b2.jpg?v=1",
    address: "Alterdorf 2, 93499 Zandt",
    telephone: "+49 9944 3075540",
    website: "http://global3000.de",
    email: "info@global3000.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Suntech Power Co., Ltd.,,,Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/global-entwicklung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102566&utm_campaign=profiles_installer",
    company_name: "Global-Entwicklung GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4368f207f7.png?v=1",
    address: "Industriestraße 1, 19348 Perleberg",
    telephone: "+49 3876 300172",
    website: "https://www.global-entwicklung.de",
    email: "info@global-entwicklung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gleichstrom-plus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102379&utm_campaign=profiles_installer",
    company_name: "Gleichstrom Plus GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9c6848145e.png?v=1",
    address: "Lortzingstraße 19, 46282 Dorsten",
    telephone: "+49 2362 9999877",
    website: "http://www.gleichstromplus.de",
    email: "info@gleichstromplus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/glatter-schurz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94297&utm_campaign=profiles_installer",
    company_name: "Glatter & Schurz GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cf564d39033.jpg?v=1",
    address: "Rötzing 1a, 94113 Tiefenbach",
    telephone: "+49 8546 2597",
    website: "http://www.glatter-schurz.de",
    email: "info@glatter-schurz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gille-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94306&utm_campaign=profiles_installer",
    company_name: "Gille Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cf5fb246dd9.jpg?v=1",
    address: "In den Lissen 4, Gewerbegebiet, 76547 Sinzheim-Kartung",
    telephone: "+49 7221 8989",
    website: "http://gille-holzbau.de",
    email: "info@gille-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/g-h-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=53881&utm_campaign=profiles_installer",
    company_name: "G+H Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ff5f406c7b21.png?v=1",
    address: "Otto-von-Guericke-Straße 13, 53757 Sankt Augustin",
    telephone: "+49 2241 4931551",
    website: "http://www.ghsolar.de",
    email: "info@ghsolar.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ggew?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93580&utm_campaign=profiles_installer",
    company_name: "GGEW AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e5f64a8bd67e.jpg?v=1",
    address: "Dammstraße 68, 64625 Bensheim",
    telephone: "+49 6251 13010",
    website: "https://dein-ggew-sonnendach.de",
    email: "info@ggew.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gexx-aerosol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81658&utm_campaign=profiles_installer",
    company_name: "Gexx aeroSol GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58883eec78613.jpg?v=1",
    address: "Schmiedestraße 2A, 15745 Wildau",
    telephone: "+49 3375 5226252",
    website: "http://www.gexx-aerosol.com",
    email: "info@gexx-aerosol.com",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,SunPower Corp.,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung, Training",
    Muttergesellschaft: ",Gexx Group,",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/geuppert-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94730&utm_campaign=profiles_installer",
    company_name: "Geuppert Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c35a935bd16c.jpg?v=1",
    address: "Ringstraße 2, 97461 Hofheim",
    telephone: "+49 9523 950115",
    website: "http://www.geuppert-elektrotechnik.de",
    email: "info@geuppert-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/get-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1699&utm_campaign=profiles_installer",
    company_name: "GET GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d27d7562abe.jpg?v=1",
    address: "Kellerberg 1-5, D-09326 Geringswalde",
    telephone: "+49 37382 8430",
    website: "http://www.get-elektro.de",
    email: "info@get-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gespa?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119850&utm_campaign=profiles_installer",
    company_name: "GESPA GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ddcd7919a027.jpg?v=1",
    address: "Heinrich-Lersch-Straße 3, D-65428 Rüsselsheim",
    telephone: "+49 6142 3009400",
    website: "http://www.gespa-energy.de",
    email: "info@gespa-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Delta Energy Systems (Germany) GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerwens-schiballa?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105974&utm_campaign=profiles_installer",
    company_name: "Gerwens & Schiballa GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4454193786a.png?v=1",
    address: "Gronauer Str. 176, 48599 Gronau",
    telephone: "+49 2565 9071100",
    website: "https://www.gerwens-schiballa.de",
    email: "info@gerwens-schiballa.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerster?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116904&utm_campaign=profiles_installer",
    company_name: "Gerster GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ea8a53ab68.png?v=1",
    address: "Haid 28, 88299 Leutkirch im Allgäu",
    telephone: "+49 7561 915450",
    website: "http://www.gerster-montagebau.de",
    email: "info@gerster-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/germansol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=23035&utm_campaign=profiles_installer",
    company_name: "GermanSol GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/606e8dca8d883.png?v=1",
    address: "Kurt-Schumacher-Ring 5, 63329, Egelsbach",
    telephone: "+49 6103 4400010",
    website: "http://www.germansol.de",
    email: "info@germansol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Österreich, Schweiz, Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,IBC Solar AG,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 8042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerhard-schmitt-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103546&utm_campaign=profiles_installer",
    company_name: "Gerhard Schmitt-Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebc8859944f.jpg?v=1",
    address: "Buchklinger Str. 2, 69488 Birkenau",
    telephone: "+49 6201 22299",
    website: "https://www.gerhard-schmitt-elektro.de",
    email: "info@gerhard-schmitt-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Soluxtec GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/geoplex-pv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93211&utm_campaign=profiles_installer",
    company_name: "Geoplex Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b79482b49c4.jpg?v=1",
    address: "Osnabrücker Straße 77a, 33790, Halle Westfalen",
    telephone: "+49 5201 849432",
    website: "http://geoplex-pv.de/",
    email: "info@geoplex-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,Fimer S.p.A.,,,New Energy Technology Co., Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/geonima?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10108&utm_campaign=profiles_installer",
    company_name: "Geonima",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10108n.gif?v=1",
    address: "Gerolsteiner Str. 3, D-50997 Köln",
    telephone: "+49 2236 3316020",
    website: "http://www.geonima.de",
    email: "info@geonima.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/genzwurker-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7291&utm_campaign=profiles_installer",
    company_name: "Werner Genzwürker Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/12943n.gif?v=1",
    address: "Im Seelach 17, 74706 Osterburken, Schlierstadt",
    telephone: "+49 6292 92100",
    website: "http://www.genzwuerker.com",
    email: "info@genzwuerker.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Suntech Power Co., Ltd.,,,Canadian Solar Inc.,,,AXITEC Energy GmbH & Co. KG,,,Emmvee Photovoltaic Power Pvt Ltd,,,Luxor Solar GmbH,,,Daqo Group Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 2102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gemeinhardt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1680&utm_campaign=profiles_installer",
    company_name: "Gemeinhardt AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4125.gif?v=1",
    address: "Kautendorfer Str. 37, D-95145 Oberkotzau",
    telephone: "+49 92 869890",
    website: "http://www.gemeinhardt.ag",
    email: "info@gemeinhardt.ag",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gedea-ingelheim?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81660&utm_campaign=profiles_installer",
    company_name: "Gedea-Ingelheim GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fec91b0c16f1.png?v=1",
    address: "Bahnhofstr. 21, 55218 Ingelheim",
    telephone: "+49 6132 7100120",
    website: "http://www.gedea-ingelheim.com",
    email: "info@gedea-ingelheim.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebruder-prinz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94582&utm_campaign=profiles_installer",
    company_name: "Gebrüder Prinz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7261c2fd8.png?v=1",
    address: "Kieler Str. 20, 24790 Ostenfeld",
    telephone: "+49 4331 70870",
    website: "http://www.gebrueder-prinz.de",
    email: "info@gebrueder-prinz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeisterbetrieb-gebr-vollbrecht?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10660&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeisterbetrieb Gebr. Vollbrecht GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/587f1b35c232e.jpg?v=1",
    address: "Gravenstein 1, 24852, Eggebek",
    telephone: "+49 4609 454",
    website: "http://www.gebr-vollbrecht.de",
    email: "info@gebr-vollbrecht.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebaudetechnik-hermann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102233&utm_campaign=profiles_installer",
    company_name: "Gebäudetechnik Hermann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1faaf99219.jpg?v=1",
    address: "Raiffeisenstraße 12, 88400 Biberach Ringschnait",
    telephone: "+49 7352 51662",
    website: "http://www.gebaeudetechnik-hermann.de",
    email: "info@gebaeudetechnik-hermann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebaudetechnik-berger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102230&utm_campaign=profiles_installer",
    company_name: "Gebäudetechnik Berger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1f4056b243.jpg?v=1",
    address: "Ginsterweg 12, 49757 Lahn",
    telephone: "+49 5951 994070",
    website: "https://www.gebaeudetechnik-berger.de",
    email: "info@gebaeudetechnik-berger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/green-energy-3000?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=113335&utm_campaign=profiles_installer",
    company_name: "Green Energy 3000 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c9d898fdc67d.jpg?v=1",
    address: "Torgauer Straße 231, 04347 Leipzig",
    telephone: "+49 341 35560410",
    website: "https://www.ge3000.de",
    email: "info@ge3000.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/graf-demmerle-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94217&utm_campaign=profiles_installer",
    company_name: "Graf & Demmerle Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc036f0cd5.jpg?v=1",
    address: "Alsenzstr. 11, 67722 Winnweiler",
    telephone: "+49 6302 924078",
    website: "http://www.gd-elektrotechnik.de",
    email: "info@gd-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerald-becker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94059&utm_campaign=profiles_installer",
    company_name: "Gerald Becker",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca0806ac26c.jpg?v=1",
    address: "Sandkaule 29, 53757 Sankt Augustin",
    telephone: "+49 2241 391397",
    website: "http://www.gb-elektrotechnik.de",
    email: "info@gb-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gausling?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93253&utm_campaign=profiles_installer",
    company_name: "Gausling GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f70e3fc5e.jpg?v=1",
    address: "Stroot 18, 48619 Heek",
    telephone: "+49 2568 93420",
    website: "http://gausling-gmbh.de",
    email: "info@gausling-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gat-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76055&utm_campaign=profiles_installer",
    company_name: "GAT-Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d74e6e35b03.jpg?v=1",
    address: "Deisterstraße 10, 38122 Braunschweig",
    telephone: "+49 531 287001",
    website: "http://www.gat-solar.de",
    email: "info@gat-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gast-partner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10015&utm_campaign=profiles_installer",
    company_name: "Gast & Partner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10015n.gif?v=1",
    address: "Pillmannstraße 21, 38112 Braunschweig",
    telephone: "+49 531 2906150",
    website: "http://www.gast-partner.de",
    email: "info@gast-partner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/garten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1809&utm_campaign=profiles_installer",
    company_name: "Firma Garten Inh. Karl-Heinz Garten",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5768b6f5c7070.jpg?v=1",
    address: "Mittelbacher Strasse 1, 01896, Lichtenberg b.pulsnitz",
    telephone: "+49 35 95543848",
    website: "http://www.garten-lichtenberg.de",
    email: "info@garten-lichtenberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/galler-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105996&utm_campaign=profiles_installer",
    company_name: "Galler Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b457496691e2.png?v=1",
    address: "Hofmarkstr. 3, 85290 Geisenfeld",
    telephone: "+49 8452 731148",
    website: "http://www.galler-elektrotechnik.de",
    email: "info@galler-elektrotechnik.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gall-technology?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107914&utm_campaign=profiles_installer",
    company_name: "Gall Technology GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88bbc794730.jpg?v=1",
    address: "Industriestraße 3, 31020 Salzhemmendorf",
    telephone: "+49 5153 96045",
    website: "http://www.gall-technology.de",
    email: "info@gall-technology. com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/galaxy-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6922&utm_campaign=profiles_installer",
    company_name: "Galaxy Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6922_324559df39325b26d.jpg?v=1",
    address: "Sonnenstr. 2, 89180 Berghülen",
    telephone: "+49 7344 924500",
    website: "http://www.galaxy-energy.com",
    email: "info@galaxy-energy.com",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Tschechische, Deutschland, Indien,",
    "Lieferanten von Solarmodulen": ",Galaxy Energy GmbH,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/fullsol-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92413&utm_campaign=profiles_installer",
    company_name: "FullSol Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a7892e59630.jpg?v=1",
    address: "Großer Steinweg 4, 35390, Giessen",
    telephone: "+49 641 35099899",
    website: "https://fullsol.de",
    email: "info@fullsol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Victron Energy BV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/fuller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92409&utm_campaign=profiles_installer",
    company_name: "Fuller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a78576ace97.jpg?v=1",
    address: "Veilchenstraße 33, 76131 Karlsruhe",
    telephone: "+49 721 613033",
    website: "http://www.fuller.de",
    email: "info@fuller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-e-t-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74851&utm_campaign=profiles_installer",
    company_name: "Fürth & Co. GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdb8f2b4b40b.png?v=1",
    address: "Mülheimer Straße 39, 47058 Duisburg",
    telephone: "+49 203 34878331",
    website: "https://www.fuerth-elektrotechnik.de",
    email: "info@fuerth-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ers-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106232&utm_campaign=profiles_installer",
    company_name: "ERS GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b5028d5d8c62.png?v=1",
    address: "Roonstr. 2, 45476 Mulheim an der Ruhr",
    telephone: "+49 208 69639600",
    website: "http://ers-elektro.de",
    email: "info@fsd-mh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernhard-frondorf-systemtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92611&utm_campaign=profiles_installer",
    company_name: "Bernhard Frondorf Systemtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae00a226282.jpg?v=1",
    address: "Schafhäuser Str. 13-15, 55232 Alzey",
    telephone: "+49 6731 96880",
    website: "http://www.frondorf.de",
    email: "info@frondorf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/fromberger-zimmerei-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10876&utm_campaign=profiles_installer",
    company_name: "Fromberger Zimmerei-Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10876n.gif?v=1",
    address: "Attenham 6, 84339 Unterdietfurt",
    telephone: "+49 8721 5560",
    website: "http://www.fromberger-zimmerei.de",
    email: "info@fromberger-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/fritz-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10316&utm_campaign=profiles_installer",
    company_name: "Fritz Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5822d97a53183.jpg?v=1",
    address: "Pfarrwiese 16-18, 69168 Wiesloch, Schatthausen",
    telephone: "+49 6222 3053970",
    website: "http://www.fritzsolar.de",
    email: "info@fritzsolar.de",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/fritzsche-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94575&utm_campaign=profiles_installer",
    company_name: "Fritzsche-Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd6ecfabb6e.png?v=1",
    address: "Gewerbegebiet Morgensonne 10, 07580 Braunichswalde",
    telephone: "+49 36608 9650",
    website: "https://www.fritzsche.de",
    email: "info@fritzsche.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/friisk-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74863&utm_campaign=profiles_installer",
    company_name: "Friisk Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a79e7a70e75.PNG?v=1",
    address: "Längsweg 1a, 25845 Nordstrand",
    telephone: "+49 4842 901690",
    website: "http://friisk-energie.de",
    email: "info@friisk-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Guangdong Fivestar Solar Energy Co., Ltd.,,,Aleo Solar GmbH,,,Trina Solar Limited,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,REFU Elektronik GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/friedrich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81563&utm_campaign=profiles_installer",
    company_name: "Friedrich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588471494f1d8.jpg?v=1",
    address: "Goldbacher Straße 106, 63741 Aschaffenburg",
    telephone: "+49 6021 41640",
    website: "http://www.friedrich-ab.de",
    email: "info@friedrich-ab.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Brochier Group,",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/freund-bielefeld?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93680&utm_campaign=profiles_installer",
    company_name: "Freund & Bielefeld Bedachungen & Hallenbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c218c7478ba.jpg?v=1",
    address: "Hoher Hagen Strasse 18, 37127 Dransfeld",
    telephone: "+49 5502 4020",
    website: "http://www.freund-bielefeld.de",
    email: "info@freund-bielefeld.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/freiszmann-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10495&utm_campaign=profiles_installer",
    company_name: "Freißmann Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10495n.gif?v=1",
    address: "Leverdingen 6, 29643, Neuenkirchen",
    telephone: "+49 51 95672",
    website: "http://www.freissmann-haustechnik.de",
    email: "info@freissmann-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pilz-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10724&utm_campaign=profiles_installer",
    company_name: "Franz Pilz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10724n.gif?v=1",
    address: "Im Ramstal 41, 97922 Lauda-Königshofen",
    telephone: "+49 9343 5708",
    website: "http://www.franz-pilz.de",
    email: "info@franz-pilz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/fritz-monikheim?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7288&utm_campaign=profiles_installer",
    company_name: "Fritz Mönikheim GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d12997400b3.jpg?v=1",
    address: "Deutschordenstraße 4, 97990 Weikersheim-Elpersheim",
    telephone: "+49 7934 91100",
    website: "http://fm-energiezentrum.de",
    email: "info@fm-energiezentrum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rolf-fischer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75787&utm_campaign=profiles_installer",
    company_name: "Rolf Fischer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608813f8d0970.PNG?v=1",
    address: "Landstraße 18, 21698 Bargstedt",
    telephone: "+49 4164 88520",
    website: "http://fischer-bargstedt.de",
    email: "info@fischer-bargstedt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Viessmann Werke GmbH & Co. KG,,,Solvis d.o.o.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dehm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=44317&utm_campaign=profiles_installer",
    company_name: "DEHM GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fb804fc38531.PNG?v=1",
    address: "Eichenmühleweg 16/5, 88048 Friedrichshafen",
    telephone: "+49 7541 9505307",
    website: "http://www.firma-dehm.de",
    email: "info@firmadehm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebr-hoxtermann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102665&utm_campaign=profiles_installer",
    company_name: "Gebr. Höxtermann GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac981af8eaaf.jpg?v=1",
    address: "An der Mühle 5, D-34434 Borgentreich - Bühne",
    telephone: "+49 5643 683",
    website: "http://www.hoextermann-buehne.de",
    email: "info@firma-hoextermann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/helmut-feurer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95240&utm_campaign=profiles_installer",
    company_name: "Helmut Feurer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f025abb635c.png?v=1",
    address: "Kastanienallee 38, D-88499 Riedlingen",
    telephone: "+49 7371 93420",
    website: "http://www.feurer-hkl.de",
    email: "info@feurer-hkl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heise-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94180&utm_campaign=profiles_installer",
    company_name: "Heise GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ccadf949fe3.png?v=1",
    address: "Luthardtstr. 80, 37586 Dassel",
    telephone: "+49 5562 91226",
    website: "https://www.fa-heise.de",
    email: "info@fa-heise.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-cooperation-allgau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76369&utm_campaign=profiles_installer",
    company_name: "Solar Cooperation Allgäu Ltd. & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe0f21e3ce05.png?v=1",
    address: "Papiermühlenweg 4, 87448 Waltenhofen",
    telephone: "+49 831 52733458",
    website: "http://experts4energy.com",
    email: "info@experts4energy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/evi-solarmeile-hildesheim?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30781&utm_campaign=profiles_installer",
    company_name: "EVI Solarmeile Hildesheim GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a5707e90406.png?v=1",
    address: "Eimser Weg 75, 31061 Alfeld, Leine",
    telephone: "+49 5181 8066691",
    website: "https://www.evi-solarmeile.de",
    email: "info@evi-solarmeile.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieversorgung-beckum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95234&utm_campaign=profiles_installer",
    company_name: "Energieversorgung Beckum GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f022f0b970e.png?v=1",
    address: "Sternstraße 22, 59269 Beckum",
    telephone: "+49 2521 85060",
    website: "https://www.evb-beckum.de",
    email: "info@evb-beckum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/a-e-s-europe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13974&utm_campaign=profiles_installer",
    company_name: "A-E-S Europe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5160c5ca6cff1.jpg?v=1",
    address: "Hansastrasse 68, 30952 Ronnenberg",
    telephone: "+49 5069 4803670",
    website: "http://www.europe-solar.de",
    email: "info@europe-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,Global,Europa",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eurolflamm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93807&utm_campaign=profiles_installer",
    company_name: "Eurolflamm OHG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4a2486e0e8.jpg?v=1",
    address: "Robert-Bosch-Str. 5b, D-63477 Maintal",
    telephone: "+49 6181 43990",
    website: "http://www.eurolflamm.de",
    email: "info@eurolflamm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/euregio-solarzentrum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84991&utm_campaign=profiles_installer",
    company_name: "Euregio-solarzentrum GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58ec7bef9b3b1.jpg?v=1",
    address: "Carl-von-Ossietzky-Str. 1, 52477 Alsdorf",
    telephone: "+49 2404 9034535",
    website: "http://www.euregio-solarzentrum.de",
    email: "info@euregio-solarzentrum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eulektra?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22763&utm_campaign=profiles_installer",
    company_name: "Eulektra GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/22763n.gif?v=1",
    address: "Am Schornacker 67, 46485 Wesel",
    telephone: "+49 281 206260",
    website: "http://www.eulektra.de",
    email: "info@eulektra.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/etsun-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74589&utm_campaign=profiles_installer",
    company_name: "ETSun Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b13b99aebae.jpg?v=1",
    address: "Weidenbergweg 3, 95697 Nagel",
    telephone: "+49 9236 9682995",
    website: "http://www.etsun.de",
    email: "info@etsun.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,,,Trina Solar Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ets-5?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92384&utm_campaign=profiles_installer",
    company_name: "ETS-Elektro-Heizung-Sanitär-GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a764e8ce7de.jpg?v=1",
    address: "Johann-Gottlieb-Schoch-Straße 3, 39108, Magdeburg",
    telephone: "+49 391 63104041",
    website: "http://www.ets-magdeburg.de",
    email: "info@ets-magdeburg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/etm-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75551&utm_campaign=profiles_installer",
    company_name: "ETM Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ad01e82b150.PNG?v=1",
    address: "Ludgerusring 26, 41472 Neuss",
    telephone: "+49 2131 1760850",
    website: "http://www.etm-solar.de",
    email: "info@etm-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-liebhard?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93787&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Liebhard GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c473d96160a.jpg?v=1",
    address: "Ruppertswies 32, 85092 Kösching",
    telephone: "+49 8456 278020",
    website: "https://www.elektrotechnik-liebhard.de",
    email: "info@etl-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-kuhler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93753&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Kühler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c37f4f77fd6.jpg?v=1",
    address: "Freiherr vom Stein Straße 2, 04895 Falkenberg",
    telephone: "+49 35365 185530",
    website: "https://solarprofi-ee.de",
    email: "info@etk-ee.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-t-g?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75265&utm_campaign=profiles_installer",
    company_name: "ETG Solartechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be963eed04f.jpg?v=1",
    address: "Pfaffenreuth 8, 92690 Pressath",
    telephone: "+49 9644 68982-34",
    website: "http://www.etg-solar.de",
    email: "info@etg-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ete-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=130286&utm_campaign=profiles_installer",
    company_name: "ETE ElektroTechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601141bdb2515.png?v=1",
    address: "Kreuzstraße 4-6, 78098, Triberg",
    telephone: "+49 7722 919133",
    website: "http://ete-triberg.de/",
    email: "info@ete-triberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",Siemens Energy,,,AEG Power Solutions B.V.,,,Eaton Corporation,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/becker-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94224&utm_campaign=profiles_installer",
    company_name: "Becker Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc29430bd1.jpg?v=1",
    address: "Hollefeldstraße 29, 48282 Emsdetten",
    telephone: "+49 2572 7135",
    website: "https://www.et-becker.de",
    email: "info@et-becker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/essers-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10477&utm_campaign=profiles_installer",
    company_name: "Kurt Essers Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50766acceb445.PNG?v=1",
    address: "Elbestraße 27, 45478, Mülheim an der Ruhr",
    telephone: "+49 208 740370",
    website: "http://www.essersbedachungen.de",
    email: "info@essersbedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/essenwanger-solarenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81655&utm_campaign=profiles_installer",
    company_name: "Essenwanger Solarenergie UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58882730f0cb8.jpg?v=1",
    address: "Max-Remmele-Straße 18, 89358 Kammeltal-Goldbach",
    telephone: "+49 82 259587384",
    website: "http://www.essenwanger-solarenergie.de",
    email: "info@essenwanger-solarenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,Suntech Power Co., Ltd.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ess-kempfle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92383&utm_campaign=profiles_installer",
    company_name: "ESS Kempfle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ac7b26a8b46.png?v=1",
    address: "Max-Eyth-Str. 6, 89340, Leipheim",
    telephone: "+49 8221 2003220",
    website: "https://www.ess-kempfle.de",
    email: "info@ess-kempfle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/espv-tec-verwaltungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101842&utm_campaign=profiles_installer",
    company_name: "ESPV-Tec GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f5e225b3ed2c.png?v=1",
    address: "Dorfstraße 88, 89143 Blaubeuren",
    telephone: "+49 7344 952660",
    website: "http://www.espv-tec.de",
    email: "info@espv-tec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiesysteme-grosz-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92361&utm_campaign=profiles_installer",
    company_name: "Energiesysteme Groß GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a681ba26e84.jpg?v=1",
    address: "Schwarze Breite 2, 34260, Kaufungen",
    telephone: "+49 561 3105980",
    website: "https://www.esg-solar.de",
    email: "info@esg-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/escad-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76409&utm_campaign=profiles_installer",
    company_name: "ESCAD AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe0f9b4a3dbf.png?v=1",
    address: "Escad-Straße 1, D-88630 Pfullendorf",
    telephone: "+49 7552 9360",
    website: "https://www.escad-group.com",
    email: "info@escad.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/esam?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101834&utm_campaign=profiles_installer",
    company_name: "Esam GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa746496d749.jpg?v=1",
    address: "Alter Pfarrweg 1b, 01587 Riesa",
    telephone: "+49 3525 659034",
    website: "http://www.esam-energie.de",
    email: "info@esam-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-solutions-stephan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124869&utm_campaign=profiles_installer",
    company_name: "e-Solutions Stephan GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecf9f1fbc10b.png?v=1",
    address: "Kopenhagener Ring, 13, 66482 Zweibrücken",
    telephone: "+49 6332 206530",
    website: "http://e-s-stephan.de/",
    email: "info@es-stephan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/es-power-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94038&utm_campaign=profiles_installer",
    company_name: "Es Power GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9eef487e91.jpg?v=1",
    address: "Rachelstraße 15, 93413 Cham",
    telephone: "+49 9971 9966471",
    website: "http://es-power.de",
    email: "info@es-power.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,JA Solar Holdings Co., Ltd.,,,First Solar, Inc.,,,ET Solar New Energy Co., Ltd.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schraven-service-und-dienstleistungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74905&utm_campaign=profiles_installer",
    company_name: "ESN Energie-Systeme-Niederrhein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdb938b6127a.png?v=1",
    address: "Alte Wettener Straße 10, 47623 Kevelaer",
    telephone: "+49 2832 3414",
    website: "http://es-niederrhein.de",
    email: "info@es-niederrhein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,IBC Solar AG,,,SunPower Corp.,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ernst-neger-bedachungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10098&utm_campaign=profiles_installer",
    company_name: "Ernst Neger Bedachungs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51bfc7a26a9fd.gif?v=1",
    address: "An der Brunnenstube 20, 55120 Mainz",
    telephone: "+49 6131 99670",
    website: "http://www.neger.de",
    email: "info@ernstneger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/erene-green-technologies?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29373&utm_campaign=profiles_installer",
    company_name: "ErEne Green Technologies GmbH",
    logo_url:
      "https://cdn.enfsolar.com/ID/logo/29373_85875cc167dace65b.jpg?v=1",
    address: "Industriestr. 9, 40822, Mettmann",
    telephone: "+49 2104 175770",
    website: "http://www.erene.de",
    email: "info@erene.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,SunPower Corp.,,,Trina Solar Limited,,,Canadian Solar Inc.,,,Worldwide Energy and Manufacturing USA Co., Ltd (Amerisolar),,,Soluxtec GmbH,,,Suzhou Talesun Solar Technologies Co., Ltd.,,,VSUN SOLAR,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,Huawei Technologies Co., Ltd.,,,Envertech Corporation Ltd.,,,RCT Power GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/erding-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75843&utm_campaign=profiles_installer",
    company_name: "Erding Solar Projekt GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf8aaadf09b.jpg?v=1",
    address: "Pretzener Weg 25, D-85435 Erding",
    telephone: "+49 8122 9998440",
    website: "http://www.erding-solar.de",
    email: "info@erding-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: ",Bauma Elektro GmbH,",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/er-mounting?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29943&utm_campaign=profiles_installer",
    company_name: "ER Mounting GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e4a0acdc930.jpg?v=1",
    address: "Hauptstr. 7, 49757, Werlte",
    telephone: "+49 5951 9951103",
    website: "http://www.er-mounting.de",
    email: "info@er-mounting.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eps-solarstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74709&utm_campaign=profiles_installer",
    company_name: "EPS-Solarstrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b28cd10c475.jpg?v=1",
    address: "Lahnsteiner Straße 7, D-07629 Hermsdorf",
    telephone: "+49 36601 209523",
    website: "http://www.eps-solarstrom.de",
    email: "info@eps-solarstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eprdv-engineering?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=113892&utm_campaign=profiles_installer",
    company_name: "EPRDV-Engineering",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5cb6bd2c69e04.jpg?v=1",
    address: "Rosenheimer Str., 126 B, D-81669, Munich",
    telephone: "+49 176 54696651",
    website: "http://eprdv-engineering.com",
    email: "info@eprdv-engineering.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in":
      "Deutschland, Irak, Nigeria, Oman, USA, Saudi-Arabien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/epple-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75829&utm_campaign=profiles_installer",
    company_name: "Epple Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf804ec3850.jpg?v=1",
    address: "Kirchhalde 32, 88145 Opfenbach",
    telephone: "+49 8385 9246241",
    website: "http://epplesolar.de",
    email: "info@epplesolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AxSun Solar GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/epple?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1660&utm_campaign=profiles_installer",
    company_name: "Epple GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ccef9ec8f18.jpg?v=1",
    address: "Hammerschmiede 14, D-87616 Wald, Ostallgäu",
    telephone: "+49 8302 9228220",
    website: "http://www.epple-energie.de",
    email: "info@epple-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/epping-green-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93596&utm_campaign=profiles_installer",
    company_name: "Epping Green Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c09c3269636.jpg?v=1",
    address: "Matthäusweg 12A, 33332 Gütersloh",
    telephone: "+49 5241 470751",
    website: "https://epping-green-energy.de",
    email: "info@epping-green-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/epp-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11912&utm_campaign=profiles_installer",
    company_name: "Epp-Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11912n.gif?v=1",
    address: "Erlweg 10, 82547 Beuerberg",
    telephone: "+49 8179 1730",
    website: "http://www.epp-solar.de",
    email: "info@epp-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eos-technologies?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92382&utm_campaign=profiles_installer",
    company_name: "EOS Technologies GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a763383402e.jpg?v=1",
    address: "Notkestr. 99, 22607 Hamburg",
    telephone: "+49 40 30710497",
    website: "https://www.eos-technologies.de",
    email: "info@eos-technologies.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",ABB Italy S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-on-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=14201&utm_campaign=profiles_installer",
    company_name: "E.ON SE",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51904c604b2a3.bmp?v=1",
    address: "Brüsseler Platz 1, 45131 Essen",
    telephone: "+49 2 0118400",
    website: "http://www.eon.com",
    email: "info@eon.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/envaris?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29933&utm_campaign=profiles_installer",
    company_name: "Envaris GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57637caf56643.jpg?v=1",
    address: "Friedrich-Olbricht-Damm 62, 13627, Berlin",
    telephone: "+49 30 288849311",
    website: "http://www.envaris.de",
    email: "info@envaris.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Österreich, Belgien, Schweiz, Tschechische, Deutschland, Dänemark, Spanien, Frankreich, Großbritannien, Italien, Niederlande, Turkei, Portugal, Polen,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/envalue?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9414&utm_campaign=profiles_installer",
    company_name: "Envalue GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57621403c1993.jpg?v=1",
    address: "Gewerbepark Garham 6, 94544 Hofkirchen",
    telephone: "+49 8541 915410",
    website: "http://www.envalue.de",
    email: "info@envalue.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/entegro-photovoltaik-systeme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=25763&utm_campaign=profiles_installer",
    company_name: "Entegro Photovoltaik-Systeme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/514129fd2fe1c.jpg?v=1",
    address: "In den Telgen 44, 58730, Fröndenberg",
    telephone: "+49 2373 395870",
    website: "http://entegro.eu/",
    email: "info@entegro.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enpla?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101844&utm_campaign=profiles_installer",
    company_name: "Enpla GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa7634802c70.jpg?v=1",
    address: "St.-Martin-Straße 15, 88630 Pfullendorf",
    telephone: "+49 7552 935239",
    website: "http://www.enpla-photovoltaik.de",
    email: "info@enpla.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",GCL System Integration Technology Co., Ltd.,,,Wiosun For Renewable Energy Ltd,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/enpal?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=111321&utm_campaign=profiles_installer",
    company_name: "Enpal GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c2dc7884bf77.jpg?v=1",
    address: "Boxhagener Straße 80, 10245 Berlin",
    telephone: "+49 30 30808052",
    website: "https://www.enpal.de",
    email: "info@enpal.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enotech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29917&utm_campaign=profiles_installer",
    company_name: "Enotech GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e3bc7780d93.jpg?v=1",
    address: "Dieselstraße 14, 64807, Dieburg",
    telephone: "+49 6071 499310",
    website: "http://www.enotech.de",
    email: "info@enotech.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enles?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1728&utm_campaign=profiles_installer",
    company_name: "Enles GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5719bf86441fc.jpg?v=1",
    address: "Thyssenstr. 15, 48703, Stadtlohn",
    telephone: "+49 25 63206780",
    website: "http://www.enles.de",
    email: "info@enles.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/engynious?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91927&utm_campaign=profiles_installer",
    company_name: "Engynious GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599bd9fce5742.jpg?v=1",
    address: "An den Kiefern 19, OT Mühlbeck, 06774 Muldestausee",
    telephone: "+49 1761 7091550",
    website: "https://www.engynious.com",
    email: "info@engynious.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in":
      "Österreich, Kanada, Schweiz, Deutschland, Großbritannien, Griechenland, Italien, Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieteam-sud?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29879&utm_campaign=profiles_installer",
    company_name: "Energieteam Süd GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602e633a802ad.png?v=1",
    address: "Paradiesstr. 23-25, 73230, Kirchheim",
    telephone: "+49 7021 8799858",
    website: "http://www.energieteam-sued.de",
    email: "info@engt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Aleo Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Kaneka Corporation,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Danfoss A/S,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/engesaar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8244&utm_campaign=profiles_installer",
    company_name: "Engesaar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6041e24c74bba.png?v=1",
    address: "Angela-Braun-Str. 21, 66115, Saarbrücken",
    telephone: "+49 681 6865122",
    website: "http://www.engesaar.de",
    email: "info@engesaar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/engelmann-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81961&utm_campaign=profiles_installer",
    company_name: "Engelmann Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bdf3ede6ca.jpg?v=1",
    address: "Zum Kyrbach 14, 55481 Ober Kostenz",
    telephone: "+49 6763 960633",
    website: "http://www.engelmann-haustechnik.de",
    email: "info@engelmann-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Canadian Solar Inc.,,,First Solar, Inc.,,,ReneSola Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-engelhardt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7273&utm_campaign=profiles_installer",
    company_name: "Elektro Engelhardt GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5174b88ed7d3c.jpg?v=1",
    address: "Rothenburger Strasse 35, D-97285, Röttingen",
    telephone: "+49 9338 1728",
    website: "http://www.engelhardtelektro.de",
    email: "info@engelhardtelektro.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,KIOTO Solar,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,First Solar, Inc.,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,Win Win Precision Technology Co., Ltd.,,,Anhui Schutten Solar Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/w-engelhardt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95056&utm_campaign=profiles_installer",
    company_name: "W. Engelhardt GmbH and Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e845f5bd945.jpg?v=1",
    address: "Drosselweg 6, 36272 Niederaula",
    telephone: "+49 6625 431",
    website: "http://www.engelhardt-dach-fassade.de",
    email: "info@engelhardt-dach-fassade.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enerquinn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22213&utm_campaign=profiles_installer",
    company_name: "Enerquinn GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a0663fca937.png?v=1",
    address: "Birkenweg 12/1, 88250 Weingarten",
    telephone: "+49 751 18970570",
    website: "https://www.enerquinn.de",
    email: "info@enerquinn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enerkraft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92379&utm_campaign=profiles_installer",
    company_name: "Enerkraft GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a7610098095.jpg?v=1",
    address: "Kirchgasse 7, 74235, Erlenbach",
    telephone: "+49 7132 4884420",
    website: "http://www.enerkraft.de",
    email: "info@enerkraft.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enerix-alternative-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8557&utm_campaign=profiles_installer",
    company_name: "Enerix Alternative Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8557_87005991b8abbef4e.jpg?v=1",
    address: "Am Europakanal 31, D-93059 Regensburg",
    telephone: "+49 941 89054310",
    website: "http://enerix.de",
    email: "info@enerix.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energy-solar-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76171&utm_campaign=profiles_installer",
    company_name: "Energy Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57de072c39b90.jpg?v=1",
    address: "Volkmaroder Straße 8a, 38104 Braunschweig",
    telephone: "+49 531 1297620",
    website: "http://www.energysolar-gmbh.de",
    email: "info@energysolar-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Triacon Group,",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energypoint-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81561&utm_campaign=profiles_installer",
    company_name: "Energypoint GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58846fb854915.jpg?v=1",
    address: "Heckenweg 9, 97456 Holzhausen",
    telephone: "+49 9725 709118",
    website: "http://www.energypoint.de",
    email: "info@energypoint.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieversum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74545&utm_campaign=profiles_installer",
    company_name: "Energieversum GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b1142ad222a.jpg?v=1",
    address: "Ferdinand-Porsche-Str. 12-14, 33334 Gütersloh",
    telephone: "+49 5241 2249960",
    website: "https://www.energieversum.net",
    email: "info@energieversum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energietechnik-unger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29873&utm_campaign=profiles_installer",
    company_name: "Energietechnik Unger",
    logo_url: "https://cdn.enfsolar.com/ID/logo/520317bed9578.jpg?v=1",
    address: "Thalheimer Straße 6, 09387, Jahnsdorf",
    telephone: "+49 3721 23698",
    website: "http://www.energietechnik-unger.de",
    email: "info@energietechnik-unger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energietechnik-schermuly?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74443&utm_campaign=profiles_installer",
    company_name: "Energietechnik Schermuly GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608bf5ec2781e.PNG?v=1",
    address: "Gutenbergring 6, 35463 Fernwald",
    telephone: "+49 6404 9285130",
    website: "http://energietechnik-schermuly.de",
    email: "info@energietechnik-schermuly.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energietechnik-bohler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58267&utm_campaign=profiles_installer",
    company_name: "Energietechnik Böhler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602171e5abe95.png?v=1",
    address: "Kleiststraße 4, 69514 Laudenbach",
    telephone: "+49 6201 9803385",
    website: "http://energietechnik-boehler.de",
    email: "info@energietechnik-boehler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energietechnik-jung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11419&utm_campaign=profiles_installer",
    company_name: "Energietechnik Jung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b14dc6c743de.jpg?v=1",
    address: "Schulstraße 9, 65599, Dornburg",
    telephone: "+49 6436 6089990",
    website: "http://www.energietechnik-jung.de",
    email: "info@energietechnik-boehler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiesysteme-schimpf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94647&utm_campaign=profiles_installer",
    company_name: "Energiesysteme Schimpf GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb7cd7242c.png?v=1",
    address: "Buckelgarten 2, 64823 Groß-Umstadt",
    telephone: "+49 6078 9306879",
    website: "http://www.energiesystemeschimpf.de",
    email: "info@energiesystemeschimpf.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energy-tec-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92181&utm_campaign=profiles_installer",
    company_name: "Energy Tec",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4bb887a0f6.jpg?v=1",
    address: "Rudolf-Diesel-Str. 7, 19061 Schwerin",
    telephone: "+49 385 64109797",
    website: "https://www.energiespartechnik-mv.de",
    email: "info@energiespartechnik-mv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiering?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75563&utm_campaign=profiles_installer",
    company_name: "EnergieRing GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae3c3381118.PNG?v=1",
    address: "Sonnenschein 27, 42719 Solingen",
    telephone: "+49 212 2266333",
    website: "http://www.energiering.de",
    email: "info@energiering.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieprofi-barnim?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=590&utm_campaign=profiles_installer",
    company_name: "Energieprofi-Barnim GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e250041b154f.jpg?v=1",
    address: "An der Kirche 7, 16321, Bernau OT Ladeburg",
    telephone: "+49 3338 753366",
    website: "http://www.energieprofi-barnim.de",
    email: "info@energieprofi-barnim.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiepark-brandenburg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92352&utm_campaign=profiles_installer",
    company_name: "Energiepark Brandenburg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a67cf87bee5.jpg?v=1",
    address: "Schwartzkopffstr.17, 14612 Falkensee",
    telephone: "+49 3322 2796550",
    website: "http://energiepark-brandenburg.de",
    email: "info@energiepark-brandenburg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,,,JA Solar Holdings Co., Ltd.,,,Trina Solar Limited,,,First Solar, Inc.,,,Astronergy Co., Ltd. (Chint Solar),,,AU Optronics Corporation,,,Shunda Italia Srl,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,ABB Italy S.p.A.,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ebs-energiekonzepte-nrw?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29733&utm_campaign=profiles_installer",
    company_name: "EBS Energiekonzepte NRW",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e34a0f5af16.jpg?v=1",
    address: "Marktstrasse 5, 42653 Solingen",
    telephone: "+49 212 5996965",
    website: "http://www.energiekonzepte-nrw.de",
    email: "info@energiekonzepte-nrw.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiehaus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112860&utm_campaign=profiles_installer",
    company_name: "EnergieHaus Germany GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/21065c853220dff8a.gif?v=1",
    address: "Königsallee 60F, 40212 Düsseldorf",
    telephone: "+49 211 88284392",
    website: "http://www.energiehaus-germany.de",
    email: "info@energiehaus-germany.de",
    "Installaliert seit": 2014,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung, Training",
    Muttergesellschaft: "",
    "Last Update": 13102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieberatung-lamberty?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93635&utm_campaign=profiles_installer",
    company_name: "Energieberatung Lamberty",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1e7dcdc4bb.jpg?v=1",
    address: "Im Höhngesgarten 6, 51491 Overath",
    telephone: "+49 2206 8525417",
    website: "http://www.energieberater-lamberty.de",
    email: "info@energieberater-lamberty.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-technik-center-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81560&utm_campaign=profiles_installer",
    company_name: "Energie-Technik-Center GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58846e6d5da3d.jpg?v=1",
    address: "Am Gewerbepark 4, 91735 Muhr Am See",
    telephone: "+49 9831 880960",
    website: "http://www.energie-technik-center.de",
    email: "info@energie-technik-center.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Blueleaf Energy,,,Yingli Green Energy Holding Co., Ltd.,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-saarlorlux?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93714&utm_campaign=profiles_installer",
    company_name: "Energie SaarLorLux AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c3156a3aafd.jpg?v=1",
    address: "Richard-Wagner-Straße 14-16, 66111 Saarbrücken",
    telephone: "+49 681 5874777",
    website: "https://www.energie-saarlorlux.com",
    email: "info@energie-saarlorlux.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,JA Solar Holdings Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energietechnik-stuhlenmiller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74673&utm_campaign=profiles_installer",
    company_name: "Energietechnik Stuhlenmiller GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b27f2178ec9.jpg?v=1",
    address: "Am Forstbach 2, 86450 Altenmünster-Hennhofen",
    telephone: "+49 8295 669390",
    website: "https://www.energie-est.de",
    email: "info@energie-est.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-concept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11975&utm_campaign=profiles_installer",
    company_name: "Energie Concept Müller und Mühlbauer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fee0c941f11f.png?v=1",
    address: "Im Gässlein 2, 91230, Happurg",
    telephone: "+49 9151 81440",
    website: "http://www.energie-concept.de",
    email: "info@energie-concept.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Trina Solar Limited,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energeticum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7283&utm_campaign=profiles_installer",
    company_name: "Energeticum Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5747f2311c1fd.jpg?v=1",
    address: "St. Leonhard Str. 26, 86483 Balzhausen",
    telephone: "+49 8281 7993030",
    website: "http://www.energeticum.info",
    email: "info@energeticum.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eneotec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81654&utm_campaign=profiles_installer",
    company_name: "Eneotec GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58882696a9cd3.jpg?v=1",
    address: "Fichtelstraße 10, D-97656 Sondernau",
    telephone: "+49 9774 8585835",
    website: "http://www.eneotec.de",
    email: "info@eneotec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/enco?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75005&utm_campaign=profiles_installer",
    company_name: "Enco GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bab6ccb1a0d.jpg?v=1",
    address: "Mackstraße 17, 88348 Bad Saulgau",
    telephone: "+49 7581 4809888",
    website: "http://www.enco-energie.de",
    email: "info@enco-energie.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/enbawo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92275&utm_campaign=profiles_installer",
    company_name: "EnBaWo GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a522769df48.jpg?v=1",
    address: "Heidelberger Straße 32, 68723 Schwetzingen",
    telephone: "+49 6202 4095670",
    website: "http://www.enbawo.de",
    email: "info@enbawo.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/enatek?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92273&utm_campaign=profiles_installer",
    company_name: "Enatek GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a521aa1d925.jpg?v=1",
    address: "Bornstraße 10, 65589 Hadamar-Steinbach",
    telephone: "+49 6433 8169800",
    website: "http://www.enatek.de",
    email: "info@enatek.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,SunPower Corp.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/en-elektrotechnische-anlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93265&utm_campaign=profiles_installer",
    company_name: "En Elektrotechnische Anlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba0230dded1.jpg?v=1",
    address: "Philipp-Reis-Str. 1, 66793 Saarwellingen",
    telephone: "+49 6838 864790",
    website: "http://www.en-anlagen.de",
    email: "info@en-anlagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/emt-elektromontage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94155&utm_campaign=profiles_installer",
    company_name: "EMT Elektromontage GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cc98286dc28.jpg?v=1",
    address: "Tückelhäuserstr. 47, 97199 Ochsenfurt",
    telephone: "+49 9331 802500",
    website: "https://www.emt-elektromontage.de",
    email: "info@emt-elektromontage.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-m-s-sun-control?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7652&utm_campaign=profiles_installer",
    company_name: "E.M.S. Sun Control GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/542900ab235db.jpg?v=1",
    address: "Thyssenstr. 17, 49744 Geeste",
    telephone: "+49 5937 971990",
    website: "http://www.ems-suncontrol.com",
    email: "info@ems-suncontrol.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/emmler-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94588&utm_campaign=profiles_installer",
    company_name: "Emmler Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7589dad3e.png?v=1",
    address: "Auf dem Liethberge 32b, 38690 Vienenburg",
    telephone: "+49 5324 1022",
    website: "http://www.emmler.net",
    email: "info@emmler.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/em-energy-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102561&utm_campaign=profiles_installer",
    company_name: "EM Energy Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4311ad5bb3.png?v=1",
    address: "Zwoller Straße 30, 49716 Meppen",
    telephone: "+49 5935 9988220",
    website: "http://www.em-energysolutions.de",
    email: "info@em-energysolutions.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernd-elvert-heizung-sanitar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92272&utm_campaign=profiles_installer",
    company_name: "Bernd Elvert - Heizung-Sanitär-Elektro",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a52028ad582.jpg?v=1",
    address: "Mercatorstrasse 23, 21502 Geesthacht",
    telephone: "+49 4152 9249400",
    website: "http://www.elvertheizung.de",
    email: "info@elvert-heizung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Viessmann Werke GmbH & Co. KG,,,Solvis d.o.o.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1896&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Schmidt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c8fea054e59.gif?v=1",
    address: "Althaidhof 89, 95473 Creussen",
    telephone: "+49 9270 914325",
    website: "http://www.elektrotechnik-creussen.de",
    email: "info@elts.biz",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-armin-hoppmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94055&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Armin Hoppmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca050a6bcae.jpg?v=1",
    address: "Am Foret 1, 86456 Gablingen",
    telephone: "+49 8230 8536336",
    website: "http://www.elektrotechnik-hoppmann.de",
    email: "info@elteho.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elotherm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=120940&utm_campaign=profiles_installer",
    company_name: "Elotherm Anderson GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e0b08ff38fdb.jpg?v=1",
    address: "Henighuser Str. 18, 31311 Hänigsen",
    telephone: "+49 51 4798040",
    website: "http://www.elotherm-gmbh.de",
    email: "info@elotherm-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-anlagen-olbernhau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94980&utm_campaign=profiles_installer",
    company_name: "Elektro-Anlagen Olbernhau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e0795a6d3ee.jpg?v=1",
    address: "Grünthaler Straße 206, 09526 Olbernhau",
    telephone: "+49 37360 7440",
    website: "http://www.elol.de",
    email: "info@elol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elokom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92268&utm_campaign=profiles_installer",
    company_name: "Elokom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a51d14182d4.jpg?v=1",
    address: "Magirusstraße 3, 89547 Gerstetten-Gussenstadt",
    telephone: "+49 7323 953690",
    website: "https://www.elokom.de",
    email: "info@elokom-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elmak-elektroanlagenbau-heizung-u-sanitar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103510&utm_campaign=profiles_installer",
    company_name: "Elmak Elektroanlagenbau Heizung u. Sanitär GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebb18175007.jpg?v=1",
    address: "Dammzollstraße 65, 03185 Peitz",
    telephone: "+49 35601 897280",
    website: "http://www.elmak-peitz.de",
    email: "info@elmak-peitz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elk-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93621&utm_campaign=profiles_installer",
    company_name: "ELK Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1de58b8c1c.jpg?v=1",
    address: "Am Schulfang 9, D-84172 Buch a. Erlbach",
    telephone: "+49 8709 92390",
    website: "http://www.elk-energy.com",
    email: "info@elk-energy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elitec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93702&utm_campaign=profiles_installer",
    company_name: "Elitec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6009e1d113585.png?v=1",
    address: "Industriestraße 2, D-64653 Lorsch",
    telephone: "+49 6251 943936",
    website: "http://www.elitec-gmbh.de",
    email: "info@elitecgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eletec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105983&utm_campaign=profiles_installer",
    company_name: "Eletec",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4461bf902b3.png?v=1",
    address: "Konrad-Zuse-Ring 28, 48691 Vreden",
    telephone: "+49 2564 3949939",
    website: "https://www.eletec.de",
    email: "info@eletec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",LG Electronics, Inc.,,,Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Siemens Energy,,,Eaton Corporation,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/martin-walz-elektro-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7279&utm_campaign=profiles_installer",
    company_name: "Martin Walz Elektro + Solartechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52044a9fbb56f.jpg?v=1",
    address: "Im Mönchgraben 37, 75397 Simmozheim",
    telephone: "+49 7033 4067830",
    website: "http://www.elektrowalz.de",
    email: "info@elektrowalz.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-vogt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75057&utm_campaign=profiles_installer",
    company_name: "Elektro Vogt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ab8c400ba10.PNG?v=1",
    address: "Sattelbach 333, 88263 Horgenzell",
    telephone: "+49 7504 1632",
    website: "http://www.elektrovogt.org",
    email: "info@elektrovogt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-gruter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94039&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Grüter GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9efaa71a96.jpg?v=1",
    address: "Uhlenbrock 15, 49586 Neuenkirchen",
    telephone: "+49 5465 312250",
    website: "http://www.grueter-elektrotechnik.de",
    email: "info@elektrotechnikgrueter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-zabler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64141&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Zabler .e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6050bf9c4fd6d.png?v=1",
    address: "Eichendorffstraße 30, 76669 Bad Schönborn",
    telephone: "+49 7253 4640",
    website: "http://www.elektrotechnik-zabler.de",
    email: "info@elektrotechnik-zabler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-will?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112686&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Will",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c7dd6159670b.jpg?v=1",
    address: "Waldböckelheimer Straße 21, 55595 Bockenau",
    telephone: "+49 6758 9691280",
    website: "https://elektrotechnik-will.de",
    email: "info@elektrotechnik-will.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 12062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-und-solartechnik-wenner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1845&utm_campaign=profiles_installer",
    company_name: "Elektro und Solartechnik Wenner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e269f5e7b2d5.jpg?v=1",
    address: "Am Stuten 20, 45529, Hattingen",
    telephone: "+49 232 4971680",
    website: "https://www.elektro-und-solartechnik-wenner.de",
    email: "info@elektrotechnik-wenner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-segeth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94036&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Segeth GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/600b6a75b2219.png?v=1",
    address: "Mösleinstr. 15, 86697 Oberhausen/Kreut",
    telephone: "+49 8431 43286",
    website: "http://www.photovoltaik-neuburg.de",
    email: "info@elektrotechnik-segeth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schraut?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92267&utm_campaign=profiles_installer",
    company_name: "Elektro Schraut GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fff81b110ad5.png?v=1",
    address: "Raiffeisenstraße 2, 97440 Essleben",
    telephone: "+49 9722 1788",
    website: "http://www.schraut-elektrotechnik.de",
    email: "info@elektrotechnik-schraut.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-renz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1930&utm_campaign=profiles_installer",
    company_name: "Elektro-Renz GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1930n.gif?v=1",
    address: "Mercedesstr. 1, 71334 Waiblingen",
    telephone: "+49 7151 959160",
    website: "http://www.elektrotechnik-renz.de",
    email: "info@elektrotechnik-renz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-rambach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82374&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Rambach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58aba5c0b5e07.jpg?v=1",
    address: "Kleine Horionstraße 11, 47551 Bedburg-Hau",
    telephone: "+49 2821 66352",
    website: "http://elektrotechnik-rambach.de",
    email: "info@elektrotechnik-rambach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-magherusan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94185&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Magherusan GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e93d73860767.jpg?v=1",
    address: "Gaußring 16, 86415 Mering",
    telephone: "+49 8233 7932002",
    website: "http://www.elektrotechnik-magherusan.de",
    email: "info@elektrotechnik-magherusan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dennis-luft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106241&utm_campaign=profiles_installer",
    company_name: "Dennis Luft Elektrotechnikermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b502e8f4f6ac.png?v=1",
    address: "An der Lanterbeck 19, 45357 Essen",
    telephone: "+49 201 80669240",
    website: "http://www.elektrotechnik-luft.de",
    email: "info@elektrotechnik-luft.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-liepold?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106243&utm_campaign=profiles_installer",
    company_name: "Elektro Liepold",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b5031d9bc037.png?v=1",
    address: "Anwanderstraße 10, 85049 Ingolstadt-Irgertsheim",
    telephone: "+49 8424 88820",
    website: "http://www.elektro-klaus-liepold.de",
    email: "info@elektrotechnik-liepold.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/markus-lenzen-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105979&utm_campaign=profiles_installer",
    company_name: "Markus Lenzen Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b445ade72564.png?v=1",
    address: "Schiefbahnerstraße 21, 41352 Korschenbroich",
    telephone: "+49 2161 671009",
    website: "https://www.elektrotechnik-lenzen.de",
    email: "info@elektrotechnik-lenzen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",ABB Italy S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/klasen-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106233&utm_campaign=profiles_installer",
    company_name: "Klasen Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50298f70685.png?v=1",
    address: "Pappelweg 45, 27308 Kirchlinteln",
    telephone: "+49 4237 408",
    website: "https://www.elektrotechnik-klasen.de",
    email: "info@elektrotechnik-klasen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eth-elektrotechnik-hoppe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94131&utm_campaign=profiles_installer",
    company_name: "ETH Elektrotechnik Hoppe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb6b8345325.jpg?v=1",
    address: "Wülknitzer Str.7, 01609 Röderaue OT Koselitz",
    telephone: "+49 35 26331516",
    website: "http://www.elektrotechnik-hoppe.de",
    email: "info@elektrotechnik-hoppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-hafner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94216&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Hafner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdbf580200b.jpg?v=1",
    address: "Ringeisenstr. 3, D-86470 Thannhausen",
    telephone: "+49 8281 79000",
    website: "https://elektrotechnik-hafner.jimdo.com",
    email: "info@elektrotechnik-hafner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-gaiszer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102589&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Gaißer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac44efb026bb.png?v=1",
    address: "Bangertweg 5, 72070 Tübingen-Hageloch",
    telephone: "+49 70701 640960",
    website: "http://elektrotechnik-gaisser.de",
    email: "info@elektrotechnik-gaisser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-und-solartechnik-friebe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92264&utm_campaign=profiles_installer",
    company_name: "Elektro- und Solartechnik Friebe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a51a7b39465.jpg?v=1",
    address: "Gewerbestr. 5, 86981 Kinsau",
    telephone: "+49 8869 9129990",
    website: "http://www.elektrotechnik-friebe.de",
    email: "info@elektrotechnik-friebe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Trina Solar Limited,,,LG Electronics, Inc.,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-forstner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93561&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Forstner GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c087db344d6.jpg?v=1",
    address: "Berg 23, D-83139 Söchtenau",
    telephone: "+49 8038 699262",
    website: "http://www.elektrotechnik-forstner.de",
    email: "info@elektrotechnik-forstner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/michael-drexl-elektrotechnikermeister-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92261&utm_campaign=profiles_installer",
    company_name: "Michael Drexl Elektrotechnikermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a517ff8f229.jpg?v=1",
    address: "Hoheneggstraße 41, 86978, Hohenfurch",
    telephone: "+49 8861 2542207",
    website: "https://www.elektrotechnik-drexl.de",
    email: "info@elektrotechnik-drexl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnische-anlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102240&utm_campaign=profiles_installer",
    company_name: "Elektrotechnische Anlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab20c7bbd260.jpg?v=1",
    address: "Auf'm Angst 2, 42553 Velbert",
    telephone: "+49 2053 9700",
    website: "http://etecgmbh.de",
    email: "info@elektrotechnik-budde.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/reiner-klingebiel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93781&utm_campaign=profiles_installer",
    company_name: "Reiner Klingebiel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c46e05980f1.jpg?v=1",
    address: "Fontainengraben 74a, 53123 Bonn",
    telephone: "+49 228 8508185",
    website: "http://www.elektrotechnik-bonn.de",
    email: "info@elektrotechnik-bonn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-springer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81556&utm_campaign=profiles_installer",
    company_name: "Elektro Springer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58846b7cbfab8.jpg?v=1",
    address: "Bottwarbahnstraße 64, 74081 Heilbronn",
    telephone: "+49 7131 6449783",
    website: "http://www.elektrospringer.de",
    email: "info@elektrospringer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/handwerksmeister-nestler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10442&utm_campaign=profiles_installer",
    company_name: "Handwerksmeister Gabriel Nestler",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57674371cde5c.jpg?v=1",
    address: "Pappelallee 5, 17235 Neustrelitz",
    telephone: "+49 3981 205048",
    website: "http://www.elektroservice-nestler.de",
    email: "info@elektroservice-nestler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroservice-kunst?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10362&utm_campaign=profiles_installer",
    company_name: "ElektroService Kunst GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd089daeb372.png?v=1",
    address: "Rötelstr. 8/1, 74172 Neckarsulm",
    telephone: "+49 7132 982830",
    website: "http://www.elektroservice-kunst.de",
    email: "info@elektroService-kunst.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",LG Electronics, Inc.,,,CS Wismar GmbH Sonnenstromfabrik,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroservice-allgau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94641&utm_campaign=profiles_installer",
    company_name: "Elektroservice Allgäu GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb550c4111.png?v=1",
    address: "Am Moosberg 8, 87666 Pforzen",
    telephone: "+49 8346 9822055",
    website: "http://elektroservice-allgaeu.de",
    email: "info@elektroservice-allgaeu.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-bernhard-rehme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=132142&utm_campaign=profiles_installer",
    company_name: "Elektro Bernhard Rehme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608bcbc1befba.png?v=1",
    address: "Raiffeisenstraße 10, 83677, Reichersbeuern",
    telephone: "+49 8041 7958798",
    website: "http://elektrorehme.de/",
    email: "info@elektrorehme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebaude-und-anlagentechnik-haina?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93625&utm_campaign=profiles_installer",
    company_name: "Gebäude - und Anlagentechnik Haina GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1e13f094af.jpg?v=1",
    address: "Am Kesselrasen 11, 98630 Römhild",
    telephone: "+49 36948 22680",
    website: "http://www.elektroprofis-haina.de",
    email: "info@elektroprofis-haina.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,Solar Frontier K.K.,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-prinz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94081&utm_campaign=profiles_installer",
    company_name: "Elektro Prinz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca15eec1214.jpg?v=1",
    address: "Lindenstraße 11-13, 49596 Gehrde",
    telephone: "+49 5439 1562",
    website: "http://www.elektroprinz.de",
    email: "info@elektroprinz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-palm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119697&utm_campaign=profiles_installer",
    company_name: "Elektro Palm GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dd389f395312.jpg?v=1",
    address: "Elektro-Palm, Winterwerberstr. 19, 56357 Gemmerich",
    telephone: "+49 6776 1095",
    website: "http://www.elektropalm.de",
    email: "info@elektropalm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",Eaton Corporation,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mesch-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102375&utm_campaign=profiles_installer",
    company_name: "Mesch Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9c447e368c.png?v=1",
    address: "Bahnhofstr. 47, 22946 Trittau",
    telephone: "+49 4154 2034",
    website: "http://www.elektromesch.de",
    email: "info@elektromesch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,Kyocera Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-maul?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122772&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Maul",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e66e79f050fe.jpg?v=1",
    address: "Hauptstraße 12, 91249, Weigendorf",
    telephone: "+49 9154 4897",
    website: "http://www.elektromaul.de",
    email: "info@elektromaul.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroma?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74837&utm_campaign=profiles_installer",
    company_name: "Elektroma GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b5286bb24a5.jpg?v=1",
    address: "Reimerdeskamp 51, 31787 Hameln",
    telephone: "+49 5151 40140",
    website: "http://www.elektroma.de",
    email: "info@elektroma.de",
    "Installaliert seit": 1993,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-konig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10543&utm_campaign=profiles_installer",
    company_name: "Elektro König GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe2ef32b262c.png?v=1",
    address: "Weinbergstraße 11, 91483, Oberscheinfeld",
    telephone: "+49 9167 403",
    website: "http://www.elektrokoenig.com",
    email: "info@elektrokoenig.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hanisch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93838&utm_campaign=profiles_installer",
    company_name: "Elektro Hanisch",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b82ed8494.jpg?v=1",
    address: "Fischbecker Straße 30, 31785 Hameln",
    telephone: "+49 5151 24132",
    website: "http://www.elektrohanisch.de",
    email: "info@elektrohanisch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-halbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94121&utm_campaign=profiles_installer",
    company_name: "Elektro Halbach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c08c2a245890.jpg?v=1",
    address: "Friedenshort 4, 42369 Wuppertal",
    telephone: "+49 202 464041",
    website: "https://elektrohalbach.de",
    email: "info@elektrohalbach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-anton-hartl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81745&utm_campaign=profiles_installer",
    company_name: "Elektro Anton Härtl",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58953d380a0e3.jpg?v=1",
    address: "Danielstrasse 3, 82467 Garmisch-Partenkirchen",
    telephone: "+49 8821 74245",
    website: "http://www.elektrohaertl.de",
    email: "info@elektrohaertl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Sharp Energy Solutions Europe,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gieraths-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92400&utm_campaign=profiles_installer",
    company_name: "Elektro Gieraths GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6000a6ba23526.png?v=1",
    address: "Saaler Str. 72, 51429 Bergisch Gladbach",
    telephone: "+49 2204 52974",
    website: "http://www.elektrogieraths.de",
    email: "info@elektrogieraths.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-essmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92258&utm_campaign=profiles_installer",
    company_name: "Elektro Essmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a516fc1594f.jpg?v=1",
    address: "Von-Liebig-Str. 2, D-48432 Rheine",
    telephone: "+49 5971 911220",
    website: "http://www.elektroessmann.de",
    email: "info@elektroessmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hans-eisele?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93338&utm_campaign=profiles_installer",
    company_name: "Hans Eisele GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb3a59aba38.jpg?v=1",
    address: "Lombacherstr. 67, 72293 Glatten",
    telephone: "+49 7443 96080",
    website: "http://www.elektroeisele.de",
    email: "info@elektroeisele.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-dietz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119701&utm_campaign=profiles_installer",
    company_name: "Elektro Dietz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dd38f5b243b9.jpg?v=1",
    address: "Danzigerstr 20, 67685 Weilerbach",
    telephone: "+49 63 74914000",
    website: "http://www.elektrodietzgmbh.de",
    email: "info@elektrodietzgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-bruderle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93358&utm_campaign=profiles_installer",
    company_name: "Elektro Brüderle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb4c829b3a2.jpg?v=1",
    address: "Am Kümmerling 17, 55294 Bodenheim",
    telephone: "+49 6135 2641",
    website: "http://elektrobruederle.de/",
    email: "info@elektrobruederle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-blitz-coburg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102580&utm_campaign=profiles_installer",
    company_name: "Elektro Blitz Coburg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4469f67fe0.png?v=1",
    address: "Bärenholzweg 2a, 96450 Coburg",
    telephone: "+49 9561 200906",
    website: "http://www.elektroblitzcoburg.de",
    email: "info@elektroblitzcoburg.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrobau-sud?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94976&utm_campaign=profiles_installer",
    company_name: "Elektrobau Süd GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e073624b333.jpg?v=1",
    address: "Zülowstraße 16, 15827 Dahlewitz (Blankenfeld-Mahlow)",
    telephone: "+49 30 7238899",
    website: "https://www.elektrobau-sued.de",
    email: "info@elektrobau-sued.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrobau-karl-peter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1713&utm_campaign=profiles_installer",
    company_name: "Elektrobau Karl Peter GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5719bd5f94006.jpg?v=1",
    address: "Auf der Insel 2, 37247 Großalmerode",
    telephone: "+49 560 4915054",
    website: "http://www.elektrobau-peter.de",
    email: "info@elektrobau-peter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrobau-meffert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94975&utm_campaign=profiles_installer",
    company_name: "Elektrobau Meffert GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e072ea8618f.jpg?v=1",
    address: "Bärstadter Straße 4a, 65388 Schlangenbad-Wambach",
    telephone: "+49 6129 9750",
    website: "http://www.elektrobau-meffert.de",
    email: "info@elektrobau-meffert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-bartel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1927&utm_campaign=profiles_installer",
    company_name: "Elektro-Bartel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1927n.gif?v=1",
    address: "Dörntener Str. 18, 38644 Goslar, Deutschland",
    telephone: "+49 5321 37900",
    website: "http://www.elektrobartels.de",
    email: "info@elektrobartels.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-bachmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106240&utm_campaign=profiles_installer",
    company_name: "Elektro Bachmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b502dc8787c2.png?v=1",
    address: "Memmerter Straße 10, 26757 Borkum",
    telephone: "+49 4922 4453",
    website: "http://www.bachmann-elektrotechnik.de",
    email: "info@elektrobachmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wolfgang-herz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93279&utm_campaign=profiles_installer",
    company_name: "Wolfgang Herz Elektroanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba1b0fca9ac.jpg?v=1",
    address: "Fünfers 4, 88239, Wangen im Allgäu",
    telephone: "+49 7522 21851",
    website: "http://elektroanlagen-herz.de/",
    email: "info@elektroanlagen-herz.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroanlagen-hager?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11130&utm_campaign=profiles_installer",
    company_name: "Elektroanlagen Hager",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5acc533299fb9.jpg?v=1",
    address: "August-Bebel-Straße 14a, 08289, Schneeberg",
    telephone: "+49 3772 28923",
    website: "http://www.elektroanlagen-hager.de",
    email: "info@elektroanlagen-hager.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-zocher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=564&utm_campaign=profiles_installer",
    company_name: "Elektro-Zocher GmbH & Co.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11007.gif?v=1",
    address: "Leipziger Str. 17a, 01665 Diera-Zehren OT. Zehren",
    telephone: "+49 35247 50110",
    website: "http://www.elektro-zocher.de",
    email: "info@elektro-zocher.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-wurkner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=560&utm_campaign=profiles_installer",
    company_name: "Elektro Würkner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/560n.gif?v=1",
    address: "Eislebener Straße 1A, 06279, Farnstädt",
    telephone: "+49 347 7691910",
    website: "http://www.elektro-wuerkner.de",
    email: "info@elektro-wuerkner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/witsch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94803&utm_campaign=profiles_installer",
    company_name: "Elektro Witsch GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deeebeafef0.png?v=1",
    address: "Carl-Bosch-Straße 10, 53501 Grafschaft",
    telephone: "+49 2641 26733",
    website: "http://www.elektro-witsch.de",
    email: "info@elektro-witsch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-wilhelm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74441&utm_campaign=profiles_installer",
    company_name: "Elektro-Wilhelm GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ac3e5238d45.jpg?v=1",
    address: "Am Bockshorn 18, 38173 Sickte",
    telephone: "+49 5305 91030",
    website: "http://www.elektro-wilhelm.com",
    email: "info@elektro-wilhelm.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,SunPower Corp.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-wester?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94078&utm_campaign=profiles_installer",
    company_name: "Elektro Wester GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca149ba5a21.jpg?v=1",
    address: "Lilienthalstr. 12, 53424 Remagen",
    telephone: "+49 2642 22096",
    website: "http://www.elektro-wester.de",
    email: "info@elektro-wester.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-walz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74515&utm_campaign=profiles_installer",
    company_name: "Elektro Walz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ad66756d463.jpg?v=1",
    address: "Finkenweg 3, 75392 Deckenpfronn",
    telephone: "+49 7056 8485",
    website: "http://www.elektro-walz.de",
    email: "info@elektro-walz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-ulm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101841&utm_campaign=profiles_installer",
    company_name: "Elektro Ulm GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa74b5925536.jpg?v=1",
    address: "Bogenweg 4, 92283 Traunfeld",
    telephone: "+49 9189 44070",
    website: "http://www.elektro-ulm.de",
    email: "info@elektro-ulm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Kyocera Corporation,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-udo-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94120&utm_campaign=profiles_installer",
    company_name: "Elektro Udo Schmidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb3d30cc026.jpg?v=1",
    address: "Im großen Gunterstal 4, 66440 Blieskastel-Webenheim",
    telephone: "+49 6842 1234",
    website: "http://www.elektro-udo-schmidt.de",
    email: "info@elektro-udo-schmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-trobs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92256&utm_campaign=profiles_installer",
    company_name: "Elektro Tröbs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a5150bc6878.jpg?v=1",
    address: "Hansastraße 16, 49205 Hasbergen-Gaste",
    telephone: "+49 5405 92070",
    website: "http://www.elektro-troebs.de",
    email: "info@elektro-troebs.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-thum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93313&utm_campaign=profiles_installer",
    company_name: "Elektro Thum GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb2610c697a.jpg?v=1",
    address: "Am Bühl 5, 86735 Forheim",
    telephone: "+49 9089 969388",
    website: "http://elektro-thum.de",
    email: "info@elektro-thum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-thanner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74691&utm_campaign=profiles_installer",
    company_name: "Elektro Thanner GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b2860e06c85.jpg?v=1",
    address: "Dirnaich 15, 84140 Gangkofen",
    telephone: "+49 8722 259",
    website: "http://www.elektro-thanner.de",
    email: "info@Elektro-Thanner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-turke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94133&utm_campaign=profiles_installer",
    company_name: "Elektro Türke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb6d170a18c.jpg?v=1",
    address: "Boschstr. 6, 64347 Griesheim",
    telephone: "+49 6155 2285",
    website: "http://www.elektro-tech.de",
    email: "info@elektro-tech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/electric-team-metz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81747&utm_campaign=profiles_installer",
    company_name: "Electric Team Metz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589545e4a30a1.jpg?v=1",
    address: "Hauptstr. 11, 37133 Friedland, OT Niedernjesa",
    telephone: "+49 5509 1357",
    website: "http://www.elektro-team-metz.de",
    email: "info@elektro-team-metz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-sturz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94857&utm_campaign=profiles_installer",
    company_name: "Elektro Stürz Elektrische Anlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df28a10fdbc.png?v=1",
    address: "Benzstraße 9, 71149 Bondorf",
    telephone: "+49 7457 3988",
    website: "http://www.elektro-stuerz.de",
    email: "info@elektro-stuerz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-strehle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94047&utm_campaign=profiles_installer",
    company_name: "Elektro Strehle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9fc6e623f7.jpg?v=1",
    address: "Lochfelbenstraße 11, 89312 Günzburg",
    telephone: "+49 82 21367370",
    website: "https://elektro-strehle.de",
    email: "info@elektro-strehle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-strasskewitz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122770&utm_campaign=profiles_installer",
    company_name: "Elektro Strasskewitz GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e66e0ffd0e94.jpg?v=1",
    address: "Rotter Viehtrift 3, 53842, Troisdorf-Oberlar",
    telephone: "+49 2241 403666",
    website: "http://www.elektro-strasskewitz.de",
    email: "info@elektro-strasskewitz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroservice-stavenhagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74615&utm_campaign=profiles_installer",
    company_name: "Elektroservice Jörg Stavenhagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a530eaaa02b.PNG?v=1",
    address: "Bahnhofstraße 6, 18573 Rambin, Rügen",
    telephone: "+49 38306 7118",
    website: "http://www.elektro-stavenhagen.de",
    email: "info@elektro-stavenhagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-arnd-sonnenschein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92252&utm_campaign=profiles_installer",
    company_name: "Elektro Arnd Sonnenschein",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a5126a78d6c.jpg?v=1",
    address: "Brunnsiepen 1, 45259 Essen",
    telephone: "+49 201 462267",
    website: "https://www.elektro-sonnenschein.de",
    email: "info@elektro-sonnenschein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-und-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75165&utm_campaign=profiles_installer",
    company_name: "Elektro- und Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd35950bced.jpg?v=1",
    address: "Greuel 22, D-42897 Remscheid",
    telephone: "+49 2191 949171",
    website: "http://www.elektro-solartechnik.com",
    email: "info@elektro-solartechnik.com",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-solter-elektrofachgeschaft-und-kuchenstudio?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92250&utm_campaign=profiles_installer",
    company_name: "Elektro Sölter Elektrofachgeschäft und Küchenstudio",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a511915694d.jpg?v=1",
    address: "Bergkamp 2, 32683 Barntrup",
    telephone: "+49 5263 8594",
    website: "http://www.elektro-soelter.de",
    email: "info@elektro-soelter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-service-frank?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94650&utm_campaign=profiles_installer",
    company_name: "Elektro-Service Frank GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb8c547a7d.png?v=1",
    address: "Herrenwiesenstraße 37, 97980 Bad Mergentheim",
    telephone: "+49 7931 98900",
    website: "http://www.elektro-service-frank.de",
    email: "info@elektro-service-frank.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ets-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124529&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Thomas Schwartz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eba4c976e3d2.jpg?v=1",
    address: "Talstraße 17, 01731 Kreischa",
    telephone: "+49 35206 21841",
    website: "https://elektro-schwartz.de",
    email: "info@elektro-schwartz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten": ",Solarwatt GmbH,,,SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroinstallation-lothar-schroder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92388&utm_campaign=profiles_installer",
    company_name: "Elektroinstallation Lothar Schröder",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a77a4202d72.jpg?v=1",
    address: "Breite Straße 132, 17121 Loitz",
    telephone: "+49 39998 10228",
    website: "http://www.elektro-schroeder-loitz.de",
    email: "info@elektro-schroeder-loitz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schrey?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93779&utm_campaign=profiles_installer",
    company_name: "Elektro Schrey GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c46d7660d15.jpg?v=1",
    address: "Gabelsbergerstraße 17, 46238 Bottrop",
    telephone: "+49 2041 62081",
    website: "http://www.elektro-schrey.de",
    email: "info@elektro-schrey.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/erich-und-arnold-schonenberger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94029&utm_campaign=profiles_installer",
    company_name: "Erich und Arnold Schönenberger GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9e7f937157.jpg?v=1",
    address: "Talblick 30, 72160 Horb a.N. - Isenburg",
    telephone: "+49 7451 2808",
    website: "http://www.elektro-schoenenberger.de",
    email: "info@elektro-schoenenberger.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schober-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=55881&utm_campaign=profiles_installer",
    company_name: "Elektro-Schober",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60055c7048c97.PNG?v=1",
    address: "Neugasse 28, 55599 Stein-Bockenheim",
    telephone: "+49 6703 941968",
    website: "http://www.elektro-schober.de",
    email: "info@elektro-schober.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,Kaneka Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schneider?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94791&utm_campaign=profiles_installer",
    company_name: "Elektro Schneider GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dee8d58c735.jpg?v=1",
    address: "Mozartstraße 6, 91489 Wilhelmsdorf",
    telephone: "+49 91 04779",
    website: "http://www.elektro-schneider.net",
    email: "info@elektro-schneider.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schmitt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93809&utm_campaign=profiles_installer",
    company_name: "Elektro Schmitt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4a38b874d2.jpg?v=1",
    address: "Zum Dollberg 42, 66620 Otzenhausen",
    telephone: "+49 6873 359",
    website: "http://www.elektro-schmitt.com",
    email: "info@elektro-schmitt.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-heinl-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76183&utm_campaign=profiles_installer",
    company_name: "Elektro Schmidt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdd283427b00.png?v=1",
    address: "Tiefenbachweg 3d, 34295 Edermünde",
    telephone: "+49 5603 910990",
    website: "https://www.elektro-schmidt-besse.de",
    email: "info@elektro-schmidt-besse.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroinstallationen-horst-schmidke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94659&utm_campaign=profiles_installer",
    company_name: "Elektroinstallationen Horst Schmidke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddbbbfe310b.png?v=1",
    address: "Am Gänsebrink 3, 17166 Teterow",
    telephone: "+49 3996 129210",
    website: "http://www.elektro-schmidke.de",
    email: "info@elektro-schmidke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schillinger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10443&utm_campaign=profiles_installer",
    company_name: "Elektro Schillinger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10443n.gif?v=1",
    address: "Wiesentalstraße 46, 79115, Freiburg",
    telephone: "+49 761 401090",
    website: "http://www.elektro-schillinger.de",
    email: "info@elektro-schillinger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schandert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105989&utm_campaign=profiles_installer",
    company_name: "Elektro-Schandert",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b456cc903283.png?v=1",
    address: "Friedrich-Engels-Straße 56, 06895 Zahna-Elster",
    telephone: "+49 34924 20417",
    website: "https://www.elektro-schandert.de",
    email: "info@elektro-schandert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-schader?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77351&utm_campaign=profiles_installer",
    company_name: "Elektro Schader GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6082e5a570865.PNG?v=1",
    address: "Bahnhofstraße 16, 83661 Lenggries",
    telephone: "+49 8042 8736",
    website: "https://www.elektro-schader.de",
    email: "info@elektro-schader.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",Siemens Energy,,,AEG Power Solutions B.V.,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-schachtschober?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7276&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Marc Schachtschober",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51b14df87c5e7.jpg?v=1",
    address: "Am Rodaugraben 4, 76744, Wörth",
    telephone: "+49 7271 2776",
    website: "http://www.elektro-schachtschober.de",
    email: "info@elektro-schachtschober.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-sauerland?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93829&utm_campaign=profiles_installer",
    company_name: "Elektro Sauerland GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b400d2637.jpg?v=1",
    address: "Unterstraße 62, 34414 Warburg",
    telephone: "+49 5641 2354",
    website: "https://www.elektro-sauerland.de",
    email: "info@elektro-sauerland.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-sa-sohne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75609&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Sá & Söhne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8edd355349.jpg?v=1",
    address: "Otto-Lilienthal-Str. 12, 53879 Euskirchen",
    telephone: "+49 2251 772480",
    website: "http://www.elektro-sa.de",
    email: "info@elektro-sa.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-ruthmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93738&utm_campaign=profiles_installer",
    company_name: "Elektro Ruthmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c368599b180.jpg?v=1",
    address: "Jahnstraße 20, 33442 Herzebrock-Clarholz",
    telephone: "+49 5245 2636",
    website: "http://www.elektro-ruthmann.de",
    email: "info@elektro-ruthmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-rumpel-sohn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102227&utm_campaign=profiles_installer",
    company_name: "Elektro Rümpel & Sohn GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5cf6119712f60.jpg?v=1",
    address: "Feldstraße 22, 15806, Zossen OT, Horstfelde",
    telephone: "+49 3377 301015",
    website: "http://www.elektro-ruempel.de",
    email: "info@elektro-ruempel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",KIOTO Solar,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroinstallation-rummler-schellerhau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=562&utm_campaign=profiles_installer",
    company_name: "Elektroinstallation Rümmler Schellerhau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51667dd650166.bmp?v=1",
    address: "Hauptstraße 80 A, 01773 Altenberg, OT Schellerhau",
    telephone: "+49 35052 67763",
    website: "http://www.elektro-ruemmler.de",
    email: "info@elektro-ruemmler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-roszwein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10347&utm_campaign=profiles_installer",
    company_name: "Elektro Roßwein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576742317cf74.jpg?v=1",
    address: "Mühlstraße 26, 04741, Roßwein",
    telephone: "+49 34322 4740",
    website: "http://www.elektro-rosswein.de",
    email: "info@elektro-rosswein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-roman-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10404&utm_campaign=profiles_installer",
    company_name: "Elektro Roman Müller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10404n.gif?v=1",
    address: "Bahnhofstraße 11, 87637 Seeg",
    telephone: "+49 8364 318",
    website: "http://www.elektro-roman-mueller.de",
    email: "info@elektro-roman-mueller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rohrmoser-energieberatung-solar-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82455&utm_campaign=profiles_installer",
    company_name: "Rohrmoser Energieberatung • Solar • Elektro",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58abff0d8e32b.jpg?v=1",
    address: "Kalthofer Feld 23, 58640 Iserlohn",
    telephone: "+49 176 20912172",
    website: "http://elektro-rohrmoser.de",
    email: "info@elektro-rohrmoser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-roggenkamper?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106004&utm_campaign=profiles_installer",
    company_name: "Elektro Roggenkämper GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b458001f1d00.png?v=1",
    address: "Stockumerstraße 440, 44227 Dortmund",
    telephone: "+49 231 3178338",
    website: "http://www.elektro-roggenkaemper.de",
    email: "info@elektro-roggenkaemper.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-romer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92248&utm_campaign=profiles_installer",
    company_name: "Elektro Römer GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a510929e17f.jpg?v=1",
    address: "Rosenweg 4-6, 34628 Willingshausen",
    telephone: "+49 6697 1595",
    website: "http://www.elektro-roemer.de",
    email: "info@elektro-roemer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-riedmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94142&utm_campaign=profiles_installer",
    company_name: "Elektro Riedmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cc47aa5330a.jpg?v=1",
    address: "Johann Schöner Straße 43, 97753 Karlstadt",
    telephone: "+49 9353 97100",
    website: "http://www.elektro-riedmann.com",
    email: "info@elektro-riedmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-reiss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112664&utm_campaign=profiles_installer",
    company_name: "Elektro Reiss GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c7cc14061c07.jpg?v=1",
    address: "Am Zankwald 12, 66583 Spiesen-Elversberg",
    telephone: "+49 6821 71110",
    website: "http://www.elektro-reiss.de",
    email: "info@elektro-reiss.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/reimer-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94080&utm_campaign=profiles_installer",
    company_name: "Reimer Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca15305b9d2.jpg?v=1",
    address: "Zeppelinstraße 12, 89231 Neu-Ulm",
    telephone: "+49 731 37842310",
    website: "http://www.elektro-reimer.com",
    email: "info@elektro-reimer.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-rathenow?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94054&utm_campaign=profiles_installer",
    company_name: "Elektro Rathenow GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca03f20e598.jpg?v=1",
    address: "Wilhelm-Külz-Straße 10, 14712 Rathenow",
    telephone: "+49 3385 54400",
    website: "http://www.elektro-rathenow.com",
    email: "info@elektro-rathenow.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-prost?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94897&utm_campaign=profiles_installer",
    company_name: "Elektro Prost GmbH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e0351546483.png?v=1",
    address: "Mainbacherstraße 2a, 84323 Massing",
    telephone: "+49 8724 1870",
    website: "http://www.elektro-prost.de",
    email: "info@elektro-prost.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hermann-popko?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76683&utm_campaign=profiles_installer",
    company_name: "Hermann Popko GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ee2924b181f.jpg?v=1",
    address: "Springbarg 10, 29482 Küsten, Lübeln",
    telephone: "+49 5841 4355",
    website: "http://www.elektro-popko.de",
    email: "info@elektro-popko.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-service-pollich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74911&utm_campaign=profiles_installer",
    company_name: "Elektro-Service Pollich",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a7a6bf72bda.PNG?v=1",
    address: "Herrieder Str. 8a, 91522 Ansbach",
    telephone: "+49 981 63755",
    website: "http://www.hws-pollich.de",
    email: "info@elektro-pollich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-persch-fliehmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94115&utm_campaign=profiles_installer",
    company_name: "Elektro Persch Fliehmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb39dd995b6.jpg?v=1",
    address: "Am Weidensatz 38, 76756 Bellheim",
    telephone: "+49 7272 9725920",
    website: "http://www.elektro-persch.com",
    email: "info@elektro-persch.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,Kyocera Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Siemens Energy,,,Fimer S.p.A.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-panne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102689&utm_campaign=profiles_installer",
    company_name: "Elektro Panne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9955ed5bef.jpg?v=1",
    address: "Kirchstraße 5, 58553 Halver",
    telephone: "+49 2353 5011",
    website: "https://www.elektro-panne.de",
    email: "info@elektro-panne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",Kostal Solar Electric GmbH,,,Eaton Corporation,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-otterbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94208&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Otterbach GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e67038bae958.jpg?v=1",
    address: "Laßbacher Str. 23, 74595 Langenburg - Nesselbach",
    telephone: "+49 7905 941880",
    website: "http://www.elektro-otterbach.de",
    email: "info@elektro-otterbach.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-oberauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74669&utm_campaign=profiles_installer",
    company_name: "Elektro Oberauer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b27cbc885e7.jpg?v=1",
    address: "Auenstraße 13, 84419 Schwindegg",
    telephone: "+49 8082 2712971",
    website: "http://www.elektro-oberauer.de",
    email: "info@elektro-oberauer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Tesla PV,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-nothwang?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81650&utm_campaign=profiles_installer",
    company_name: "Elektro Nothwang GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5888217186a57.jpg?v=1",
    address: "Kirchheimer Str. 104, 73277 Owen, Teck",
    telephone: "+49 7021 59045",
    website: "http://www.elektro-nothwang.de",
    email: "info@elektro-nothwang.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-neuber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10345&utm_campaign=profiles_installer",
    company_name: "Elektro Neuber GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10345n.gif?v=1",
    address: "Stanglmühle 2, 85283, Wolnzach",
    telephone: "+49 8442 968200",
    website: "http://www.elektro-neuber.de",
    email: "info@elektro-neuber.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/muffler-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1647&utm_campaign=profiles_installer",
    company_name: "Muffler Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50f7c57beaca2.gif?v=1",
    address: "Schneckengasse 16, 88605 Sauldorf-Krumbach",
    telephone: "+49 7777 930490",
    website: "http://www.elektro-muffler.de",
    email: "info@elektro-muffler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-murle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94082&utm_campaign=profiles_installer",
    company_name: "Elektro-Mürle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca168b612da.jpg?v=1",
    address: "Oberer Hardweg 8, 75181 Pforzheim",
    telephone: "+49 7231 979881",
    website: "http://www.elektro-muerle.de",
    email: "info@elektro-muerle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-muller-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102363&utm_campaign=profiles_installer",
    company_name: "Elektro Müller Weselberg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b7e84fb0b.png?v=1",
    address: "Zeisselstraße 14, 66919 Weselberg",
    telephone: "+49 6333 276738",
    website: "http://elektro-mueller-weselberg.de",
    email: "info@elektro-mueller-weselberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Solon International,,,Aleo Solar GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",Mastervolt International BV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102571&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Müller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac43c6780e86.png?v=1",
    address: "Leitzachtalstr. 134, 83730 Fischbachau / Elbach",
    telephone: "+49 8028 1365",
    website: "http://elektro-mueller-fischbachau.de",
    email: "info@elektro-mueller-fischbachau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/leo-mohr-elektroinstallation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92236&utm_campaign=profiles_installer",
    company_name: "Elektro Mohr Inh. Markus Schaettgen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a50157d653c.jpg?v=1",
    address: "Glottertalstraße 7, 79108 Freiburg im Breisgau",
    telephone: "+49 761 555771",
    website: "http://www.elektro-mohr.de",
    email: "info@elektro-mohr.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-monnich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92235&utm_campaign=profiles_installer",
    company_name: "Elektro Mönnich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5be4dd2e118e2.png?v=1",
    address: "Reichardstr. 4, 31789 Hameln",
    telephone: "+49 5151 95520",
    website: "https://www.elektro-moennich.de",
    email: "info@elektro-moennich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-mohring?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93710&utm_campaign=profiles_installer",
    company_name: "Elektro Möhring",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c312d1c3dc0.jpg?v=1",
    address: "Wirtsgrund 1, 91086 Aurachtal",
    telephone: "+49 9132 60833",
    website: "http://www.elektro-moehring.de",
    email: "info@elektro-moehring.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-und-solartechnik-mittermeier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75121&utm_campaign=profiles_installer",
    company_name: "Elektro- und Solartechnik Mittermeier GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd00ce72a83.jpg?v=1",
    address: "Zugspitzstraße 101, 82467 Garmisch-Partenkirchen",
    telephone: "+49 8821 7306300",
    website: "http://www.elektro-mittermeier.de",
    email: "info@elektro-mittermeier.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-mihm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1579&utm_campaign=profiles_installer",
    company_name: "Elektro Mihm",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1579n.gif?v=1",
    address: "Rahserstraße 261, 41748 Viersen",
    telephone: "+49 21 6215196",
    website: "http://www.elektro-mihm.de",
    email: "info@elektro-mihm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-menzel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93810&utm_campaign=profiles_installer",
    company_name: "Elektro Menzel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4a43e672d6.jpg?v=1",
    address: "Gerhart-Hauptmann-Str. 18, 02689 Sohland a. d. Spree",
    telephone: "+49 35936 32207",
    website: "http://www.elektro-menzel.de",
    email: "info@elektro-menzel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,Solon International,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-meister-service-schmitt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1619&utm_campaign=profiles_installer",
    company_name: "Elektro-Meister-Service Schmitt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1619n.gif?v=1",
    address: "Lindenstraße 1, 97753 Karlstadt",
    telephone: "+49 9353 981192",
    website: "http://www.elektro-meister-schmitt.de",
    email: "info@elektro-meister-schmitt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-loresch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7275&utm_campaign=profiles_installer",
    company_name: "Elektro Loresch",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6092b9577c483.png?v=1",
    address: "Dorfstraße 13, 89250 Senden",
    telephone: "+49 7307 956671",
    website: "http://www.elektro-loresch.de",
    email: "info@elektro-loresch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-loffler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105991&utm_campaign=profiles_installer",
    company_name: "Elektro-Löffler e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f03ea445a0c5.png?v=1",
    address: "Lindemannstr. 1, 08523 Plauen",
    telephone: "+49 3741 135190",
    website: "http://www.elektro-loeffler.de",
    email: "info@elektro-loeffler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Sun Power Systems,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-lingscheidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1638&utm_campaign=profiles_installer",
    company_name: "Elektro Lingscheidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1638n.gif?v=1",
    address: "Blätteräcker 25, 74523 Schwäbisch Hall",
    telephone: "+49 7907 8096",
    website: "http://www.elektro-lingscheidt.de",
    email: "info@elektro-lingscheidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-electronic-lembcke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92238&utm_campaign=profiles_installer",
    company_name: "Elektro & Electronic Lembcke GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a502741e4c7.jpg?v=1",
    address: "Teinsiek 4/6, D-24568 Kaltenkirchen",
    telephone: "+49 4191 80080",
    website: "http://www.elektro-lembcke.de",
    email: "info@elektro-lembcke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-lauxmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106246&utm_campaign=profiles_installer",
    company_name: "Elektro Lauxmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50344524638.png?v=1",
    address: "Rosmarinweg 37, 70374 Stuttgart",
    telephone: "+49 711 5301005",
    website: "https://www.elektro-lauxmann.de",
    email: "info@elektro-lauxmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-laudenberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93637&utm_campaign=profiles_installer",
    company_name: "Elektro Laudenberg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1e90111cae.jpg?v=1",
    address: "Voiswinkeler Straße 21, 51467 Bergisch Gladbach",
    telephone: "+49 2202 81152",
    website: "http://www.elektro-laudenberg.de",
    email: "info@elektro-laudenberg.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-lange?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94048&utm_campaign=profiles_installer",
    company_name: "Elektro Lange",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ff222e937.jpg?v=1",
    address: "Heidestr. 1a, 59379 Selm-Bork",
    telephone: "+49 2592 240120",
    website: "http://www.elektro-lange.de",
    email: "info@elektro-lange.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kunisch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106228&utm_campaign=profiles_installer",
    company_name: "Elektro Kunisch GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50252c29823.png?v=1",
    address: "Römerstraße 37, 63785 Obernburg",
    telephone: "+49 6022 8554",
    website: "http://www.elektro-kunisch.de",
    email: "info@elektro-kunisch.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kummer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94608&utm_campaign=profiles_installer",
    company_name: "Kummer GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd8250af0e8.png?v=1",
    address: "Friedensstraße 40, 08468 Reichenbach",
    telephone: "+49 3765 55370",
    website: "http://www.elektro-kummer.de",
    email: "info@elektro-kummer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kurg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93546&utm_campaign=profiles_installer",
    company_name: "Elektro-Kurg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0828290d36.jpg?v=1",
    address: "Gefeller Straße 10, 96515 Sonneberg",
    telephone: "+49 3675 745351",
    website: "http://www.elektro-krug-sonneberg.de",
    email: "info@elektro-krug-sonneberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kranen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94118&utm_campaign=profiles_installer",
    company_name: "Elektro Kranen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb3b4d42f92.jpg?v=1",
    address: "Bellstraße 45, 47906 Kempen",
    telephone: "+49 2152 910530",
    website: "http://www.elektro-kranen.de",
    email: "info@elektro-kranen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,Trina Solar Limited,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kollmeder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102583&utm_campaign=profiles_installer",
    company_name: "Elektro Kollmeder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac448fc349ea.png?v=1",
    address: "Albert-Einstein-Straße 3, 84030 Ergolding",
    telephone: "+49 871 73857",
    website: "http://www.elektro-kollmeder.de",
    email: "info@elektro-kollmeder.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/klan-wagner-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103550&utm_campaign=profiles_installer",
    company_name: "Klan & Wagner Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebcd7dbf1d6.jpg?v=1",
    address: "Sankt-Ägidius-Straße 4, 51147 Köln",
    telephone: "+49 2203 599182",
    website: "http://www.elektro-klan.de",
    email: "info@elektro-klan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-kempkes?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94221&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Kempkes GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc1396129e.jpg?v=1",
    address: "Rennekoven 27, 41334 Nettetal",
    telephone: "+49 2153 9507299",
    website: "http://www.elektro-kempkes.de",
    email: "info@elektro-kempkes.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-jeutter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92233&utm_campaign=profiles_installer",
    company_name: "Elektro Jeutter GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4ff1fe74a4.jpg?v=1",
    address: "Liebigstraße 10, 71229 Leonberg Höfingen",
    telephone: "+49 7152 25367",
    website: "http://elektro-jeutter.de",
    email: "info@elektro-jeutter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-helmerichs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92226&utm_campaign=profiles_installer",
    company_name: "Elektro Helmerichs",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e914acabb094.png?v=1",
    address: "Zur Alten Mühle 1, 26969 Butjadingen, Burhave",
    telephone: "+49 4733 920060",
    website: "http://www.elektro-helmerichs.de",
    email: "info@elektro-helmerichs.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarnova Deutschland GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/helmer-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81957&utm_campaign=profiles_installer",
    company_name: "Helmer Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bd744d1d6d.jpg?v=1",
    address: "Friedrich-Bauer-Straße 50, 73642 Welzheim",
    telephone: "+49 7182 2357",
    website: "http://www.elektro-helmer.de",
    email: "info@elektro-helmer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": ",Siemens Energy,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-haug?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10352&utm_campaign=profiles_installer",
    company_name: "Haug Solar- & Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/533cb30c75379.jpg?v=1",
    address: "Ringstraße 1, 74226 Nordheim",
    telephone: "+49 7133 6668",
    website: "http://www.elektro-haug.com",
    email: "info@elektro-haug.com",
    "Installaliert seit": 1990,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hammerl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81553&utm_campaign=profiles_installer",
    company_name: "Clemens Hammerl Elektroinstallations GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5884687f389af.jpg?v=1",
    address: "Kaminskistrasse 15, D-83671 Benediktbeuern",
    telephone: "+49 8857 69160",
    website: "http://www.elektro-hammerl.de",
    email: "info@elektro-hammerl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/friedrich-hamel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94732&utm_campaign=profiles_installer",
    company_name: "Friedrich Hamel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dddcc790c6a.png?v=1",
    address: "Rajen 236, 26817 Rhauderfehn",
    telephone: "+49 4952 92920",
    website: "http://www.elektro-hamel.de",
    email: "info@elektro-hamel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hadwiger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1894&utm_campaign=profiles_installer",
    company_name: "Elektro Hadwiger GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1894n.gif?v=1",
    address: "Egerländerstr.10, 86368, Gersthofen/Augsburg",
    telephone: "+49 821 472751",
    website: "http://www.elektro-hadwiger.de",
    email: "info@elektro-hadwiger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Vaillant SLU,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-gottschalk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81552&utm_campaign=profiles_installer",
    company_name: "Elektro-Gottschalk GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588466341a314.jpg?v=1",
    address: "Freyschmidtstraße 1, 17291 Prenzlau",
    telephone: "+49 3984 874130",
    website: "http://www.elektro-gottschalk.de",
    email: "info@elektro-gottschalk.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-gorich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94708&utm_campaign=profiles_installer",
    company_name: "Elektro Görich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddd39a51047.png?v=1",
    address: "Bahnhofstr. 44, 64331 Weiterstadt",
    telephone: "+49 6150 2515",
    website: "http://elektro-goerich.de",
    email: "info@elektro-goerich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gopfert-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105969&utm_campaign=profiles_installer",
    company_name: "Göpfert Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dad1a52816a9.jpg?v=1",
    address: "Allacher Straße 233, 80997 München",
    telephone: "+49 89 89224936",
    website: "https://www.elektro-goepfert.de",
    email: "info@elektro-goepfert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-gocke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93721&utm_campaign=profiles_installer",
    company_name: "Elektro Göcke GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c3199298973.jpg?v=1",
    address: "Kirchstraße 22, 49509 Recke",
    telephone: "+49 5453 8513",
    website: "http://www.elektro-goecke.de",
    email: "info@elektro-goecke.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-glenk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106000&utm_campaign=profiles_installer",
    company_name: "Elektro Glenk GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b45792d4eb35.png?v=1",
    address: "Kirchstraße 11, 74572 Blaufelden - Gammesfeld",
    telephone: "+49 7958 926910",
    website: "https://www.elektro-glenk.de",
    email: "info@elektro-glenk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/freitag?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94729&utm_campaign=profiles_installer",
    company_name: "Ing. Ludwig Freitag Elektro GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dddac9b2ddb.png?v=1",
    address: "Industriestraße 3, 92331 Parsberg",
    telephone: "+49 9492 6040",
    website: "https://www.elektro-freitag.de",
    email: "info@elektro-freitag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-feller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103529&utm_campaign=profiles_installer",
    company_name: "Elektro Feller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebbeccda467.jpg?v=1",
    address: "Alte Weberei 4, 73266 Bissingen",
    telephone: "+49 7023 742268",
    website: "https://www.elektro-feller.de",
    email: "info@elektro-feller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-falter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94112&utm_campaign=profiles_installer",
    company_name: "Elektro Falter GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb268eb760a.jpg?v=1",
    address: "Ohmstraße 4, 94405 Landau a. d. Isar",
    telephone: "+49 9951 599075",
    website: "http://www.elektro-falter.de",
    email: "info@elektro-falter.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-elser-inh-gerhard-scharpf?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1636&utm_campaign=profiles_installer",
    company_name: "Elser Elektro+Haustechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1636n.gif?v=1",
    address: "Hauptstraße 105, 73104 Börtlingen",
    telephone: "+49 7161 504680",
    website: "http://www.elektro-elser.de",
    email: "info@elektro-elser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-elsen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64133&utm_campaign=profiles_installer",
    company_name: "Elektro Elsen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6050bcec62fdc.png?v=1",
    address: "Kirchstraße 11, 54662 Speicher",
    telephone: "+49 6562 96111",
    website: "http://www.eifel-solarstrom.de",
    email: "info@elektro-elsen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,,,Shunda Italia Srl,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Mastervolt International BV,,,Delta Electronics, Inc.,,,AROS Solar Technology (RPS SpA),",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-eickhoff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106234&utm_campaign=profiles_installer",
    company_name: "Elektro Eickhoff",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b502a2d1d350.png?v=1",
    address: "Speicherstraße 23A, 18273 Güstrow",
    telephone: "+49 3843 683088",
    website: "http://www.elektro-eickhoff.de",
    email: "info@elektro-eickhoff.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-egger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81551&utm_campaign=profiles_installer",
    company_name: "Anton Egger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58846461904bc.jpg?v=1",
    address: "Salzburger Straße 51, 83071 Stephanskirchen",
    telephone: "+49 8031 7420",
    website: "http://elektro-egger.de",
    email: "info@elektro-egger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: ",OPTIMA Packaging Group,",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-ege?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=96033&utm_campaign=profiles_installer",
    company_name: "Elektro Ege GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a051f49920f6.jpg?v=1",
    address: "Steffensweg 34, D-28217 Bremen",
    telephone: "+49 178 7232385",
    website: "http://elektro-ege.de",
    email: "info@elektro-ege.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-dramer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94818&utm_campaign=profiles_installer",
    company_name: "Elektro Drämer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df067967362.jpg?v=1",
    address: "Tiroler Strasse 8, 45659 Recklinghausen",
    telephone: "+49 2361 404640",
    website: "https://elektrodraemer.de",
    email: "info@elektro-draemer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-ayhan-dogan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105973&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Ayhan Dogan",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b445173a140c.png?v=1",
    address: "Emsdettener Landstr. 66, 48268 Greven",
    telephone: "+49 2575 971768",
    website: "https://www.elektro-dogan.de",
    email: "info@elektro-dogan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-dittmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93616&utm_campaign=profiles_installer",
    company_name: "Elektro-Dittmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1db0a38183.jpg?v=1",
    address: "Nordschleswigstraße 23, 25524 Itzehoe",
    telephone: "+49 4821 93670",
    website: "http://www.elektro-dittmann.de",
    email: "info@elektro-dittmann.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,ABB Italy S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-dessecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10428&utm_campaign=profiles_installer",
    company_name: "Elektro Dessecker Verwaltungs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd0eef6d5b16.png?v=1",
    address: "Zeppelinstraße 21, 72119, Ammerbuch-Entringen",
    telephone: "+49 7073 916745",
    website: "http://www.elektro-dessecker.de",
    email: "info@elektro-dessecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-caspersen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92229&utm_campaign=profiles_installer",
    company_name: "elektro caspersen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4fc84b81ca.jpg?v=1",
    address: "Hauptstr. 34, 25885 Wester-Ohrstedt",
    telephone: "+49 4847 410",
    website: "https://www.elektro-caspersen.de",
    email: "info@elektro-caspersen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrohaus-brenner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102593&utm_campaign=profiles_installer",
    company_name: "Elektrohaus Brenner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac4515c31245.png?v=1",
    address: "Oberjesinger Straße 53, 71083 Herrenberg-Kuppingen",
    telephone: "+49 7032 31446",
    website: "http://www.elektro-brenner.de",
    email: "info@elektro-brenner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-breitbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76573&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Breitbach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eba51ff9b655.jpg?v=1",
    address: "Winterstraße 1a, 50354 Hürth",
    telephone: "+49 22338 08750",
    website: "http://www.elektrotechnik-breitbach.com",
    email: "info@elektro-breitbach.de",
    "Installaliert seit": 2014,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bornewasser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92241&utm_campaign=profiles_installer",
    company_name: "Bornewasser e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a504c05570c.jpg?v=1",
    address: "Olper Höhe 4, 42899 Remscheid",
    telephone: "+49 2191 52526",
    website: "https://elektro-bornewasser.de",
    email: "info@elektro-bornewasser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-blitz-stangengrun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=558&utm_campaign=profiles_installer",
    company_name: "Elektro-Blitz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11001.gif?v=1",
    address: "Am Eisenberg 26, 08107 Stangengrün",
    telephone: "+49 3 760634472",
    website: "http://www.elektro-blitz.de",
    email: "info@elektro-blitz.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-birk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102237&utm_campaign=profiles_installer",
    company_name: "Elektro Birk",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab2098f3731f.jpg?v=1",
    address: "Hammermatt 3, 77704 Oberkirch",
    telephone: "+49 7802 93570",
    website: "http://www.elektro-birk.de",
    email: "info@elektro-birk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-beckhoff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93751&utm_campaign=profiles_installer",
    company_name: "Elektro Beckhoff GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c37c9b9066e.jpg?v=1",
    address: "Westweg 6, 33415 Verl",
    telephone: "+49 5246 70070",
    website: "https://www.elektro-beckhoff.de",
    email: "info@elektro-beckhoff.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/beckhauser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102572&utm_campaign=profiles_installer",
    company_name: "Heinz Beckhäuser & Sohn Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac43cfd7a70c.png?v=1",
    address: "Friedrichsfehner Straße 24, 26188 Edewecht",
    telephone: "+49 4486 92800",
    website: "http://www.elektro-beckhaeuser.de",
    email: "info@elektro-beckhaeuser.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-barth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94667&utm_campaign=profiles_installer",
    company_name: "Elektro Barth GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddbe4f15b33.png?v=1",
    address: "Heideweg 4, 01619 Röderau",
    telephone: "+49 3525 510464",
    website: "https://www.elektro-barth.de",
    email: "info@elektro-barth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93630&utm_campaign=profiles_installer",
    company_name: "Ballensiefen & Tilsch Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1e4bc4399d.jpg?v=1",
    address: "Sebastianstr. 7, 53859 Niederkassel",
    telephone: "+49 2208 913682",
    website: "https://www.elektro-ballensiefen-tilsch.de",
    email: "info@elektro-ballensiefen-tilsch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-arens?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=123286&utm_campaign=profiles_installer",
    company_name: "Elektro Arens GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e859b95935b0.png?v=1",
    address: "Markstraße 19, D-56332, Niederfell",
    telephone: "+49 26 079743213",
    website: "http://www.elektro-arens.com",
    email: "info@elektro-arens.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-anlagen-gran?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74825&utm_campaign=profiles_installer",
    company_name: "Elektro-Anlagen Grän",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b51cc48c117.jpg?v=1",
    address: "Olbernhauer Straße 7A, 09619 Sayda/OT Ullersdorf",
    telephone: "+49 37365 7244",
    website: "http://www.elektroanlagen-graen.de",
    email: "info@elektro-anlagengraen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-anlagen-bar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93687&utm_campaign=profiles_installer",
    company_name: "Elektro-Anlagen Bär GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c21e3943e24.jpg?v=1",
    address: "Palleskestrasse 34, 65929 Frankfurt am Main",
    telephone: "+49 69 344811",
    website: "http://www.elektro-anlagen-baer.com",
    email: "info@elektro-anlagen-baer.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-abend?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10365&utm_campaign=profiles_installer",
    company_name: "Elektro-Abend GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58291db2bd34b.jpg?v=1",
    address: "Hohentwielstr. 1A, D-79798, Jestetten",
    telephone: "+49 7745 8822",
    website: "http://www.elektro-abend.de",
    email: "info@elektro-abend.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elbatec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82719&utm_campaign=profiles_installer",
    company_name: "Elbatec e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b3810ea86cc.jpg?v=1",
    address: "Walter-Frese-Str. 26, 42799 Leichlingen",
    telephone: "+49 2175 9904221",
    website: "http://elbatec-solar.de",
    email: "info@elbatec-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 10112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elbag-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22744&utm_campaign=profiles_installer",
    company_name: "Elbag Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/22744n.gif?v=1",
    address: "Brückenstraße 28, 56348 Weisel",
    telephone: "+49 6774 180",
    website: "http://www.elbag.de",
    email: "info@elbag.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-wagner-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81557&utm_campaign=profiles_installer",
    company_name: "Elektro Wagner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58846cd121a68.jpg?v=1",
    address: "Mitterweg 2, 93090 Bach-Demling",
    telephone: "+49 9403 3357",
    website: "http://www.el-wagner.de",
    email: "info@el-wagner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eks-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75181&utm_campaign=profiles_installer",
    company_name: "EKS Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd3f42f0ba8.jpg?v=1",
    address: "Hohenstädter Grund 2, 74736 Hardheim – Gerichtstetten",
    telephone: "+49 6296 249",
    website: "http://www.eks-solar.de",
    email: "info@eks-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eks-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94116&utm_campaign=profiles_installer",
    company_name: "EKS Elektroanlagen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb3a87c6129.jpg?v=1",
    address: "Werftstraße 20, 31789 Hameln",
    telephone: "+49 5151 1073430",
    website: "http://www.eks-elektroanlagen.de",
    email: "info@eks-elektroanlagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eke-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10096&utm_campaign=profiles_installer",
    company_name: "EKE-Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/595c62e15d8ec.jpg?v=1",
    address: "Liptinger Straße 5, 78576 Emmingen",
    telephone: "+49 7465 92900",
    website: "http://www.eke-elektrotechnik.de",
    email: "info@eke-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eiszing-rohling?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=73917&utm_campaign=profiles_installer",
    company_name: "Eißing & Rohling GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/579ad6b61fa9b.png?v=1",
    address: "Kirchweg 12a, 46286 Dorsten",
    telephone: "+49 2369 202875",
    website: "http://www.eissing-rohling.de",
    email: "info@eissing-rohling.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/einenkel-emsr-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94979&utm_campaign=profiles_installer",
    company_name: "Einenkel EMSR-Technik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e078c204520.jpg?v=1",
    address: "Zechenweg 5b, D-09456 Annaberg-Buchholz",
    telephone: "+49 3733 596930",
    website: "http://www.einenkel-emsr.de",
    email: "info@einenkel-emsr.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eilers-jacobs-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8639&utm_campaign=profiles_installer",
    company_name: "Eilers-Jacobs Photovoltaik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5329489e8b94e.jpg?v=1",
    address: "Baumstrasse 27, 26506 Norden",
    telephone: "+49 4931 5223",
    website: "http://www.eilers-jacobs-solar.de",
    email: "info@eilers-jacobs-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eichenmuller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94205&utm_campaign=profiles_installer",
    company_name: "Eichenmüller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb70ed8995.png?v=1",
    address: "Bärenleite 3, 91356 Kirchehrenbach",
    telephone: "+49 9191 797997",
    website: "http://www.eichenmueller-dach.de",
    email: "info@eichenmueller-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-gebaudetechnik-tribian?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75123&utm_campaign=profiles_installer",
    company_name: "Elektro- & Gebäudetechnik Tribian GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608be96958af0.PNG?v=1",
    address: "Dorfstraße 54, 29303 Bergen",
    telephone: "+49 5054 9874523",
    website: "https://www.egt-tribian.de",
    email: "info@egt-tribian.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Astronergy Co., Ltd. (Chint Solar),,,Viessmann Werke GmbH & Co. KG,,,Bauer Energiekonzepte GmbH,,,LG Electronics, Inc.,,,Vaillant SLU,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/egg-tech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22927&utm_campaign=profiles_installer",
    company_name: "Egg-tech GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a2d014d2cbc.png?v=1",
    address: "Burkhard+Weber-Straße 59, 72760 Reutlingen",
    telephone: "+49 7121 1385010",
    website: "http://www.egg-tech.de",
    email: "info@egg-tech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,EGing Photovoltaic Technology Co., Ltd.,,,Canadian Solar Inc.,,,Risen Energy Co., Ltd.,,,Luxor Solar GmbH,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/efu-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11872&utm_campaign=profiles_installer",
    company_name: "EFU Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5de5d38be4a34.png?v=1",
    address: "Elbingerstraße 20, 31515, Wunstorf",
    telephone: "+49 5031 909380",
    website: "http://www.efu-solar.de",
    email: "info@efu-solar.com",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,CS Wismar GmbH Sonnenstromfabrik,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/efa-leipzig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83183&utm_campaign=profiles_installer",
    company_name: "Efa Leipzig GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be248123d3b.jpg?v=1",
    address: "Bucksdorffstr. 43, 04159 Leipzig",
    telephone: "+49 341 46368630",
    website: "http://www.efa-leipzig.com",
    email: "info@efa-leipzig.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eeservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124472&utm_campaign=profiles_installer",
    company_name: "EEservice GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb661acc174f.jpg?v=1",
    address: "Eilveser Hauptstr. 56, 31535 Neustadt am Rübenberge",
    telephone: "+49 5034 87940",
    website: "http://www.eeservice.de",
    email: "info@eeservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AU Optronics Corporation,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-e-mielke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91954&utm_campaign=profiles_installer",
    company_name: "E&E Mielke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e3ab197fd1.jpg?v=1",
    address: "Gewerbestr. 1-1a, 25358 Horst, Holstein",
    telephone: "+49 4126 2241",
    website: "https://www.eem.de",
    email: "info@eem.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Photon Energy Systems Ltd,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",Enertronica Santerno S.p.A.,,,SMA Solar Technology AG,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eeb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102364&utm_campaign=profiles_installer",
    company_name: "Erneuerbare Energien Betriebsgesellschaft mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b9246b7a8.png?v=1",
    address: "Grunewald 1a, 42477 Radevormwald",
    telephone: "+49 2191 8905215",
    website: "http://www.eebgmbh.de",
    email: "info@eebgmbh.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eder-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75711&utm_campaign=profiles_installer",
    company_name: "EDER Handel & Dienstleistungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cd0865cb662.jpg?v=1",
    address: "Hoflacher Str. 8, 82223 Eichenau",
    telephone: "+49 81 41818235",
    website: "http://www.eder-solartechnik.de",
    email: "info@eder-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/edeg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29685&utm_campaign=profiles_installer",
    company_name: "ED-Energy Germany GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51dfc7df327f6.jpg?v=1",
    address: "Tempelhofer Weg, 44 10829, Berlin-Schöneberg",
    telephone: "+49 30 521352060",
    website: "http://www.ed-energy.de",
    email: "info@ed-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eco-tech-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=5921&utm_campaign=profiles_installer",
    company_name: "Eco.Tech neue Energien & Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5921n.gif?v=1",
    address: "Bernecker Straße 15, 95448 Bayreuth",
    telephone: "+49 921 1512540",
    website: "http://www.ecotech-energy.de",
    email: "info@ecotech-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Eco.Tech neue Energien & Technik GmbH,",
    "Last Update": 16112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ecosolar-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1955&utm_campaign=profiles_installer",
    company_name: "Ecosolar e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1955n.gif?v=1",
    address: "Am Handwerkshof 17, 47269 Duisburg",
    telephone: "+49 203 7135330",
    website: "http://www.ecosolar.de",
    email: "info@ecosolar.de",
    "Installaliert seit": 1990,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/econ-solarwind?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1880&utm_campaign=profiles_installer",
    company_name: "Econ SolarWind GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1880n.gif?v=1",
    address: "Gewerbestraße Süd 63, 41812 Erkelenz",
    telephone: "+49 2431 97239130",
    website: "http://www.econsolarwind.de",
    email: "info@econsolarwind.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eco-systec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93667&utm_campaign=profiles_installer",
    company_name: "Eco-Systec Renner Wolfgang & Herko Michael GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c20e800840e.jpg?v=1",
    address: "Hochfeldstraße 22, 86853 Langerringen",
    telephone: "+49 8203 959154",
    website: "http://eco-systec.de",
    email: "info@eco-systec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ecosolar-6?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75831&utm_campaign=profiles_installer",
    company_name: "EcoSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf81c223862.jpg?v=1",
    address: "Loher Straße 33, 22149 Hamburg",
    telephone: "+49 4041 929341",
    website: "http://www.eco-solar24.de",
    email: "info@eco-solar24.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eckstein-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93660&utm_campaign=profiles_installer",
    company_name: "Eckstein Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c2041075a94.jpg?v=1",
    address: "Kipfenberger Straße 9, 85110 Kipfenberg-Pfahldorf",
    telephone: "+49 8465 94070",
    website: "http://www.eckstein-bedachungen.de",
    email: "info@eckstein-bedachungen.de.",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/echter-kuchen-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81549&utm_campaign=profiles_installer",
    company_name: "Echter Küchen & Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588461a65f0d8.jpg?v=1",
    address: "Augsburger Strasse 49, 86529 Schrobenhausen",
    telephone: "+49 8252 89760",
    website: "http://www.echter-kuechen-elektro.de",
    email: "info@echter-kuechen-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energy-competence-centre-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56045&utm_campaign=profiles_installer",
    company_name: "Energy Competence Centre GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/556d52ea0632b.jpg?v=1",
    address: "Kurfürstendamm 119, 10711 Berlin",
    telephone: "+49 30 86390280",
    website: "http://ecc-renewables.com",
    email: "info@ecc-renewables.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 27072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ebt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94788&utm_campaign=profiles_installer",
    company_name: "EBT Elektrobau GmbH Thüringen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dee7df5c5dc.png?v=1",
    address: "August-Röbling-Straße 18, 99091 Erfurt",
    telephone: "+49 361 778050",
    website: "http://www.ebt-erfurt.de",
    email: "info@ebt-erfurt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ebs-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94792&utm_campaign=profiles_installer",
    company_name: "Elektroinstallation & Blitzschutz-Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deea132c814.png?v=1",
    address: "Dorfstraße 61, 02827 Görlitz",
    telephone: "+49 3581 74220",
    website: "http://www.ebs-elektro.de",
    email: "info@ebs-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/oskar-ebner-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92206&utm_campaign=profiles_installer",
    company_name: "Oskar Ebner Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4da8dd7ac0.jpg?v=1",
    address: "Wolpadingen, Dorfstr. 7, 79875 Dachsberg",
    telephone: "+49 7755 8452",
    website: "http://www.ebner-haustechnik.de",
    email: "info@ebner-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ebitsch-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1893&utm_campaign=profiles_installer",
    company_name: "Ebitsch Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575f53fd0de4a.jpg?v=1",
    address: "Bamberger Str. 50, 96199 Zapfendorf",
    telephone: "+49 9547 87050",
    website: "http://www.ebitsch-energietechnik.de",
    email: "info@ebitsch-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Jinko Solar Holding Co., Ltd.,,,LONGi Solar Technology Co., Ltd.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eber-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93591&utm_campaign=profiles_installer",
    company_name: "Eber Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0969f71a9f.jpg?v=1",
    address: "Nürnberger Straße 28, 95448 Bayreuth",
    telephone: "+49 921 65507",
    website: "http://www.eber-elektro.de",
    email: "info@eber-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eb-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76015&utm_campaign=profiles_installer",
    company_name: "EB Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d622aba9415.jpg?v=1",
    address: "Kneheimer Weg 29, 49696 Molbergen",
    telephone: "+49 4475 929007",
    website: "http://eb-solar.de",
    email: "info@eb-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SolarWorld Industries GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/easysolar-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29657&utm_campaign=profiles_installer",
    company_name: "Easysolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54fd32881ac55.jpg?v=1",
    address: "Friedrich-Wilhelm-Platz 2, 12161, Berlin",
    telephone: "+49 30 32520022",
    website: "http://www.easy-solar.de",
    email: "info@easy-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Trina Solar Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eai?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2073&utm_campaign=profiles_installer",
    company_name: "Elektro- und Automatisierungstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50372206c4fc5.gif?v=1",
    address: "Bakenröder Strasse 11, D-38871, Ilsenburg",
    telephone: "+49 39452 9640",
    website: "http://www.eai-net.de",
    email: "info@eai-net.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/e3-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93296&utm_campaign=profiles_installer",
    company_name: "E3 Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba2521226b7.jpg?v=1",
    address: "August-Bebel-Damm 12, 39126, Magdeburg",
    telephone: "+49 391 54457910",
    website: "http://e3-haustechnik.de/",
    email: "info@e3-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106001&utm_campaign=profiles_installer",
    company_name: "E-technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b457ac6bf7bc.png?v=1",
    address: "Reitelbauerstrasse 1, 84137 Vilsbiburg",
    telephone: "+49 8741 6813",
    website: "http://www.e-technik-vib.de",
    email: "info@e-technik-vib.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eso?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94848&utm_campaign=profiles_installer",
    company_name: "ESO Anlagenbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df22425dfb5.png?v=1",
    address: "Bahnhofstraße 16A, D-04779, Wermsdorf, OT Mahlis",
    telephone: "+49 34364 888927",
    website: "http://www.e-s-o.de",
    email: "info@e-s-o.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroanlagen-meyer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10359&utm_campaign=profiles_installer",
    company_name: "Elektroanlagen Meyer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5350e533ba06a.jpg?v=1",
    address: "Lilienthaler Straße 36, 28870, Ottersberg",
    telephone: "+49 4293 919014",
    website: "http://www.elektroanlagen-meyer.de",
    email: "info@e-meyer.eu",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,,,AEG Power Solutions B.V.,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroservice-brugmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92199&utm_campaign=profiles_installer",
    company_name: "Elektroservice Brügmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4d04d43359.jpg?v=1",
    address: "Zu den Hufen 6, 17034 Neubrandenburg, Mecklenburg",
    telephone: "+49 395 57078078",
    website: "http://e-installation.com",
    email: "info@e-installation.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hofmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101840&utm_campaign=profiles_installer",
    company_name: "Elektro Hofmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa74a4f3291a.jpg?v=1",
    address: "Lautertalweg 4, 89134 Blaustein",
    telephone: "+49 7304 96040",
    website: "http://www.e-hofmann.de",
    email: "info@e-hofmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-concept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74447&utm_campaign=profiles_installer",
    company_name: "E-Concept GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ac43a9983f4.jpg?v=1",
    address: "Mannheimer Landstraße 37, 64395 Brensbach",
    telephone: "+49 61 61877720",
    website: "http://www.e-concept.de",
    email: "info@e-concept.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Viessmann Werke GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-biss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=54543&utm_campaign=profiles_installer",
    company_name: "e-biss GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c89c2753605c.jpg?v=1",
    address: "Königshorsterstr. 1, 29462 Wustrow",
    telephone: "+49 171 6566333",
    website: "http://www.e-biss.de",
    email: "info@e-biss.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,S-Energy Co., Ltd.,,,Znshine PV-tech Co., Ltd.,,,LG Electronics, Inc.,,,BO POWER,",
    "Wechselrichter-Lieferanten":
      ",Solarwatt GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-zimmerei-meier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10083&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei & Zimmerei Meier  GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10083n.gif?v=1",
    address: "Marie-Curie-Strasse 6, 38268 Lengede",
    telephone: "+49 5344 2626290",
    website: "http://www.dz-meier.de",
    email: "info@dz-meier.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/durion?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106775&utm_campaign=profiles_installer",
    company_name: "Durion GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b617cf53c6a4.png?v=1",
    address: "Libnower Landstrasse 1, 17390 Murchin",
    telephone: "+49 3971 876900",
    website: "https://durionenergy.com",
    email: "info@durionenergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dunkel-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11857&utm_campaign=profiles_installer",
    company_name: "Dunkel Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11857n.gif?v=1",
    address: "Theodor-Heuss-Straße 31, 04435, Schkeuditz",
    telephone: "+49 34204 68722",
    website: "http://www.dunkelelt.de",
    email: "info@dunkelelt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/w-d-dullmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1594&utm_campaign=profiles_installer",
    company_name: "W.D.Düllmann GmbH + Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4037.gif?v=1",
    address: "Feldstr. 44, 44141 Dortmund",
    telephone: "+49 231 5569330",
    website: "http://www.duellmann.de",
    email: "info@duellmann.de",
    "Installaliert seit": 1993,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ds-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94676&utm_campaign=profiles_installer",
    company_name: "DS-Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddc26fb5d27.png?v=1",
    address: "Hildesheimer Str. 38, 31226 Peine",
    telephone: "+49 5171 5409086",
    website: "http://d-s-elektrotechnik.de",
    email: "info@ds-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/droxner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102367&utm_campaign=profiles_installer",
    company_name: "Droxner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9be14f1214.png?v=1",
    address: "Unter der Linde 8, 88605 Ringgenbach-Meßkirch",
    telephone: "+49 7575 93124",
    website: "http://droxner.de",
    email: "info@droxner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-drewes-solar-und-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92188&utm_campaign=profiles_installer",
    company_name: "Thomas Drewes Solar und Heizung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4c3c3b176b.jpg?v=1",
    address: "Mühltalstraße 202, 64625 Bensheim",
    telephone: "+49 6251 63119",
    website: "http://www.drewes-solar.de",
    email: "info@drewes-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/drewes-datenkommunikations?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94801&utm_campaign=profiles_installer",
    company_name: "Drewes Datenkommunikations GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deed5e2bf25.jpg?v=1",
    address: "Gehrenbreite 2, 31542 Bad Nenndorf",
    telephone: "+49 5723 74164",
    website: "http://www.drewes-gmbh.de",
    email: "info@drewes-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heizungsbau-dreidoppel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92187&utm_campaign=profiles_installer",
    company_name: "Heizungsbau Dreidoppel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4c1e56aedf.jpg?v=1",
    address: "Rahmerweg 16, 56316 Raubach",
    telephone: "+49 2684 959123",
    website: "http://www.dreidoppel-heizungsbau.de",
    email: "info@dreidoppel-heizungsbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/holger-dreher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10514&utm_campaign=profiles_installer",
    company_name: "Holger Dreher - Holzbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53576c2d24e4e.jpg?v=1",
    address: "Stettener Straße 8, 72820, Sonnenbühl",
    telephone: "+49 7128 927166",
    website: "http://www.dreher-holzbau.de",
    email: "info@dreher-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dott-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81546&utm_campaign=profiles_installer",
    company_name: "Dott Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58845abe883d4.jpg?v=1",
    address: "Wölmerser Str. 11, 57635 Oberirsen",
    telephone: "+49 26 86989666",
    website: "http://www.dott-gmbh.de",
    email: "info@dott-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dosol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92169&utm_campaign=profiles_installer",
    company_name: "DOSOL GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3d821134e8.jpg?v=1",
    address: "An der Irler Höhe 3a, D-93055 Regensburg",
    telephone: "+49 941 20911190",
    website: "http://www.dosol.de",
    email: "info@dosol.de",
    "Installaliert seit": 1991,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schaaf-dornhofer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10460&utm_campaign=profiles_installer",
    company_name: "Schaaf & Dornhöfer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10460n.gif?v=1",
    address: "Schurzelter Strasse 47, 52074, Aachen",
    telephone: "+49 241 9039193",
    website: "http://www.dornhoefer-ac.de",
    email: "info@dornhoefer-ac.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/domilux-leuchten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=47355&utm_campaign=profiles_installer",
    company_name: "Domilux Leuchten GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53c61659e757b.jpg?v=1",
    address: "Nederlandstr. 1, 32825, Blomberg",
    telephone: "+49 5235 961712",
    website: "http://www.domilux-photovoltaik.de",
    email: "info@domilux.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 18032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/domhofer-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94599&utm_campaign=profiles_installer",
    company_name: "Eugen Domhöfer Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7d6acdd11.png?v=1",
    address: "Im Grund 2, 36145 Hofbieber",
    telephone: "+49 6657 919222",
    website: "http://domhoefer.de",
    email: "info@domhoefer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Trina Solar Limited,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dobler-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94241&utm_campaign=profiles_installer",
    company_name: "Dobler Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdca4c71dda.png?v=1",
    address: "Ketteler Straße 24, 63776 Mömbris",
    telephone: "+49 6029 999427",
    website: "http://www.dobler-bedachungen.de",
    email: "info@dobler-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dks?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103341&utm_campaign=profiles_installer",
    company_name: "DKS GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ae3d5265e5fb.jpg?v=1",
    address: "Schwürbitzer Str. 25, 96275 Marktzeuln",
    telephone: "+49 160 5344677",
    website: "http://dks-solar.de",
    email: "info@dks-wohnen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,,,First Solar, Inc.,,,ReneSola Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Danfoss A/S,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dirk-reuber-installationsmeisterberieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92168&utm_campaign=profiles_installer",
    company_name: "Dirk Reuber Installations-Meisterbetrieb",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3d608447e8.jpg?v=1",
    address: "Im Dahler Feld 33, 51674 Wiehl",
    telephone: "+49 2262 691973",
    website: "http://www.dirkreuber.de",
    email: "info@dirkreuber.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dirk-lappe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92468&utm_campaign=profiles_installer",
    company_name: "Dirk Lappe Heizungs- & Sanitäranlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8fd2b2d8c3.jpg?v=1",
    address: "Am Vorderflöß 25b, 33175 Bad Lippspringe",
    telephone: "+49 5252 930317",
    website: "https://www.dirk-lappe.de",
    email: "info@dirk-lappe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dinter-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94728&utm_campaign=profiles_installer",
    company_name: "Dinter Elektro- und Industriemontagen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddda0c1589f.png?v=1",
    address: "Marie-Curie-Str. 3, 04420 Markranstädt",
    telephone: "+49 34205 99080",
    website: "http://dinter-elektromontagen.de",
    email: "info@dinter-gruppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dinklage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94940&utm_campaign=profiles_installer",
    company_name: "Dinklage Dachdeckermeister GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e054e36bbb9.png?v=1",
    address: "Bielefelder Str. 48, 32130 Enger",
    telephone: "+49 5224 979147",
    website: "http://www.dinklage-dach.de",
    email: "info@dinklage-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alfons-diekmann-gmbh-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=130279&utm_campaign=profiles_installer",
    company_name: "Alfons Diekmann GmbH Elektroanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60110d1f8d5a8.png?v=1",
    address: "Holdorfer Straße 12, 49401, Damme",
    telephone: "+49 54 9197070",
    website: "http://diekmann.tv/",
    email: "info@diekmann.tv",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/michael-spengler-die-dachdecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94485&utm_campaign=profiles_installer",
    company_name: "Michael Spengler - Die Dachdecker - GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc612abf1e4.jpg?v=1",
    address: "Mühlwiese 14, 65779 Kelkheim",
    telephone: "+49 6195 73493",
    website: "https://www.diedachdeckergmbh.de",
    email: "info@diedachdeckergmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dicke-sohn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81953&utm_campaign=profiles_installer",
    company_name: "Wilh. Dicke + Sohn e. K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bd427e4bab.jpg?v=1",
    address: "Klophausstraße 15, 42119 Wuppertal",
    telephone: "+49 202 313355",
    website: "http://dicke-und-sohn.de",
    email: "info@dickeundsohn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dibu-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56671&utm_campaign=profiles_installer",
    company_name: "Dibu-Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/558b78f721b92.jpg?v=1",
    address: "Vadersdorf No. 1, 23769 Stadt Fehmarn",
    telephone: "+49 4371 8887700",
    website: "http://www.dibu-energie.de",
    email: "info@dibu-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,SolarWorld Industries GmbH,,,Hanwha Q CELLS GmbH,,,Hyundai Energy Solutions,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11122019,
  },
  {
    company_url:
      "https://de.enfsolar.com/dhb-solarsysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93357&utm_campaign=profiles_installer",
    company_name: "dHb Gruppe",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6008245374571.png?v=1",
    address: "Holzbachweg 8, 87437 Kempten",
    telephone: "+49 831 70497888",
    website: "https://www.dhb-gruppe.de",
    email: "info@dhb-gruppe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/depar-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=23260&utm_campaign=profiles_installer",
    company_name: "Depar Solar",
    logo_url:
      "https://cdn.enfsolar.com/ID/logo/23260_32215b5c3628ad478.jpg?v=1",
    address:
      "Anadolu Bulvarı 145. Sk. Petek İş Merkezi, No:10/33-47-48 Macunköy, 06200, Ankara",
    telephone: "+90 312 3977236",
    website: "http://www.deparsolar.com",
    email: "info@deparsolar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Depar Group,",
    "Last Update": 25092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/denne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75613&utm_campaign=profiles_installer",
    company_name: "Denne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8f1ed35e85.jpg?v=1",
    address: "Alsenstraße 17, 42719 Solingen",
    telephone: "+49 2 12310457",
    website: "http://www.denne-solar.de",
    email: "info@denne-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dein-handler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92165&utm_campaign=profiles_installer",
    company_name: "Dein Händler",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3d1db556db.jpg?v=1",
    address: "Am Raubfang 3-5, 14469 Postdam",
    telephone: "+49 331 5507090",
    website: "http://www.dein-haendler.de",
    email: "info@dein-haendler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/delimes-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56669&utm_campaign=profiles_installer",
    company_name: "DeLimes Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/558b765e93663.jpg?v=1",
    address: "Mengener Strasse 37, 88630 Pfullendorf",
    telephone: "+49 7552 3241214",
    website: "http://www.delimes.de",
    email: "info@dein-haendler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AxSun Solar GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/deimling-solar-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6882&utm_campaign=profiles_installer",
    company_name: "Deimling Solar Technik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58fdc9fd726e0.jpg?v=1",
    address: "Birkenweg 18, 67227, Frankenthal",
    telephone: "+49 6233 36018",
    website: "https://deimling-solar.de",
    email: "info@deimling-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/deichmann-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92164&utm_campaign=profiles_installer",
    company_name: "Deichmann Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3d0a767960.jpg?v=1",
    address: "Opladener Str. 73, 42699 Solingen",
    telephone: "+49 212 60386",
    website: "http://www.deichmann-dach.de",
    email: "info@deichmann-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dehne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94843&utm_campaign=profiles_installer",
    company_name: "Dehne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df1f451a373.png?v=1",
    address: "Liegnitzer Straße 13, 58454 Witten",
    telephone: "+49 2302 179080",
    website: "http://www.dehne-gmbh.de",
    email: "info@dehne-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Viessmann Werke GmbH & Co. KG,,,Stiebel Eltron GmbH & Co. KG,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-bernhard?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10992&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Roland Bernhard",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10992n.gif?v=1",
    address: "Alter Bahnhofsweg 13 - 15, 35764 Sinn-Fleisbach",
    telephone: "+49 2772 54924",
    website: "http://www.dachdecker-bernhard.de",
    email: "info@ddm-bernhard.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dch-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7598&utm_campaign=profiles_installer",
    company_name: "DCH Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7598n.gif?v=1",
    address: "In der Wehbach 17, DE 57080, Siegen",
    telephone: "+49 271 3878100",
    website: "http://www.dch-group.de",
    email: "info@dch-group.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "China, Deutschland, Dominica, Norwegen, Turkei, Schweden, Pakistan, Barbados, Belize,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",REFU Elektronik GmbH,,,Shenzhen SOFARSOLAR Co., Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mdb-mark-dausmann-bautechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94359&utm_campaign=profiles_installer",
    company_name: "MDB Mark Dausmann Bautechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dad31af268c.jpg?v=1",
    address: "Waagestr. 15, 33729 Bielefeld",
    telephone: "+49 171 3261823",
    website: "https://www.dausmann-dachdecker-bielefeld.de",
    email: "info@dausmann-dachdecker-bielefeld.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/daschner-sanitar-und-heizungsgesellschaft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92163&utm_campaign=profiles_installer",
    company_name: "Daschner Sanitär- und Heizungsgesellschaft mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3cf42de3c0.jpg?v=1",
    address: "Waterkampstr. 11-15, 47169 Duisburg",
    telephone: "+49 203 88008",
    website: "http://www.daschner-gmbh.de",
    email: "info@daschner-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dannemann-bedachungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94305&utm_campaign=profiles_installer",
    company_name: "Dannemann Bedachungs-GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cf5eef72377.jpg?v=1",
    address: "Colonie 12, 28857 Syke-Wachendorf",
    telephone: "+49 4240 93090",
    website: "http://www.dannemann-bedachung.de",
    email: "info@dannemann-bedachung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dammann-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95080&utm_campaign=profiles_installer",
    company_name: "Dammann Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e857dab5a06.jpg?v=1",
    address: "Kleeblattstr. 14, 31535 Neustadt",
    telephone: "+49 5034 959530",
    website: "https://www.dammann-dach.de",
    email: "info@dammann-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/damm-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64005&utm_campaign=profiles_installer",
    company_name: "Damm-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/604f7aed09af5.png?v=1",
    address: "Clara-Immerwahr-Straße 3, 67661 Kaiserslautern",
    telephone: "+49 6301 320320",
    website: "http://www.damm-solar.de",
    email: "info@damm-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/damm-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92161&utm_campaign=profiles_installer",
    company_name: "Damm Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3cd139dff4.jpg?v=1",
    address: "Kreuzstraße 12 a, 35075 Gladenbach",
    telephone: "+49 6462 6556",
    website: "https://www.damm-bedachungen.de",
    email: "info@damm-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/daily-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30503&utm_campaign=profiles_installer",
    company_name: "Daily-Energy GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e7b02ef0cd3.jpg?v=1",
    address: "Engelsbergstraße 47, D-67591, Offstein",
    telephone: "+49 6243 9077110",
    website: "http://daily-energy.de",
    email: "info@daily-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Canadian Solar Inc.,,,Suzhou Talesun Solar Technologies Co., Ltd.,,,Upsolar Global Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dahlmann-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75877&utm_campaign=profiles_installer",
    company_name: "Dahlmann  Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d0b65e0859e.jpg?v=1",
    address: "Rudolf-Diesel-Str. 94, 46485 Wesel",
    telephone: "+49 281 20618290",
    website: "http://www.dahlmann-solar.de",
    email: "info@dahlmann-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/daheim?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93601&utm_campaign=profiles_installer",
    company_name: "Daheim GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d9c227cc68e2.jpg?v=1",
    address: "Frankenthaler Straße 20, 81539 München",
    telephone: "+49 89 231416666",
    website: "http://www.daheim-solar.de",
    email: "info@daheim-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dahne-lucke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1920&utm_campaign=profiles_installer",
    company_name: "Dähne & Lucke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/515a3b922ec77.gif?v=1",
    address: "Wittenberger Str. 7, 14823 Niemegk",
    telephone: "+49 3 38436490",
    website: "http://www.daehne-lucke.de",
    email: "info@daehne-lucke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",CREATON GmbH,,,Dachziegelwerke Nelskamp GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dacher-von-hunold?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95583&utm_campaign=profiles_installer",
    company_name: "Dächer von Hunold GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59fa6d20d7452.jpg?v=1",
    address: "Am Vietberg 1, 32758 Detmold",
    telephone: "+49 5231 69954",
    website: "https://www.daecher-von-hunold.de",
    email: "Info@Daecher-von-Hunold.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rust-dachwerk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93258&utm_campaign=profiles_installer",
    company_name: "Rust Dachwerk GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9fcafd5ee4.jpg?v=1",
    address: "Berliner Str. 12, 21509 Glinde",
    telephone: "+49 40 71007220",
    website: "http://www.dachwerk.com",
    email: "info@dachwerk.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-und-wand-c-ch-weiss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102023&utm_campaign=profiles_installer",
    company_name: "Dach und Wand C. CH. Weiss GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6eba3d686.jpg?v=1",
    address: "Auf dem Anger 1, 37130 Gleichen",
    telephone: "+49 551 795375",
    website: "http://www.dachweiss.de",
    email: "info@dachweiss.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachtechnik-nord?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94202&utm_campaign=profiles_installer",
    company_name: "Dachtechnik Nord GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb5155797f.png?v=1",
    address: "Edisonstr. 13, 24145 Kiel",
    telephone: "+49 431 38580440",
    website: "http://dachtechnik-nord.de",
    email: "info@dachtechnik-nord.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachtechnik-hisker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95216&utm_campaign=profiles_installer",
    company_name: "Dachtechnik Hisker",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f009f7b4373.png?v=1",
    address: "Padies 28, 48683 Ahaus-Ottenstein",
    telephone: "+49 2567 1308",
    website: "http://www.dachtechnik-hisker.de",
    email: "Info@Dachtechnik-Hisker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-henry-huber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93999&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Henry Huber",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ba2b79d13.jpg?v=1",
    address: "Sandstraße 1c, 19306 Neustadt-Glewe",
    telephone: "+49 38757 54666",
    website: "http://www.dachdeckermeister-huber.de",
    email: "info@dachdeckermeister-huber.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/martin-henze?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94927&utm_campaign=profiles_installer",
    company_name: "Martin Henze GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c3705ad160ab.jpg?v=1",
    address: "Windelsbleicher Straße 60, 33647 Bielefeld",
    telephone: "+49 521 411860",
    website: "http://www.dachdeckermeister-henze.de",
    email: "info@dachdeckermeister-henze.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Alwitra GmbH & Co.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-innung-bremen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77873&utm_campaign=profiles_installer",
    company_name: "Dachdecker-Innung Bremen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58327209ef699.jpg?v=1",
    address: "Bayernstr. 166, 28219 Bremen",
    telephone: "+49 421 87750477",
    website: "http://www.dachdeckerinnung.de",
    email: "info@dachdeckerinnung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-zachmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92158&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Ch. Zachmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3ca6a423d7.jpg?v=1",
    address: "Gewerbehof 3, 01896 Pulsnitz, OT Oberlichtenau",
    telephone: "+49 35955 45031",
    website: "http://www.dachdeckerei-zachmann.de",
    email: "info@dachdeckerei-zachmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-siewert-u-sohn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10657&utm_campaign=profiles_installer",
    company_name: "Otto Siewert u. Sohn Dachdecker- und Bauklempnerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10657n.gif?v=1",
    address: "Stampmöhlenkamp 2, 25866 Rosendahl",
    telephone: "+49 4841 72472",
    website: "http://www.dachdeckerei-siewert.de",
    email: "info@dachdeckerei-siewert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/geschaftsfuhrer-mark-pillich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94363&utm_campaign=profiles_installer",
    company_name: "Geschäftsführer: Mark Pillich",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dad881464fa.jpg?v=1",
    address: "Braunschweiger Straße 11A, 38179 Schwülper",
    telephone: "+49 5303 990729",
    website: "http://www.dachdeckerei-pillich.de",
    email: "info@dachdeckerei-pillich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-langhojer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94481&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Langhojer GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc5e2bce1aa.jpg?v=1",
    address: "Au 18, OT. Altenbanz, D - 96231, Bad Staffelstein",
    telephone: "+49 9573 9687542",
    website: "http://www.dachdeckerei-langhojer.info",
    email: "info@dachdeckerei-langhojer.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-hannig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82463&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Hannig GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58ac031cb842f.jpg?v=1",
    address: "Unter dem Thie 8, 31848 Bad Münder, OT Eimbeckhausen",
    telephone: "+49 5042 8622",
    website: "http://www.dachdeckerei-hannig.de",
    email: "info@dachdeckerei-hannig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/grunder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94465&utm_campaign=profiles_installer",
    company_name: "Grunder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc4357563c4.jpg?v=1",
    address: "Fabrikstraße 13, 66917 Wallhalben",
    telephone: "+49 6375 268",
    website: "http://www.dachdeckerei-grunder.de",
    email: "info@dachdeckerei-grunder.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-engels?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94204&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Engels",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb6735440e.png?v=1",
    address: "Werdener Str. 172, 42549 Velbert",
    telephone: "+49 2051 417320",
    website: "http://www.dachdeckerei-engels-velbert.de",
    email: "info@dachdeckerei-engels.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/blank-dachdeckerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10712&utm_campaign=profiles_installer",
    company_name: "Blank Dachdeckerei GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10712n.gif?v=1",
    address: "Schmerberger Weg 16, 14548 Schwielowsee, Caputh",
    telephone: "+49 33209 21770",
    website: "http://www.dachdeckerei-blank.de",
    email: "info@dachdeckerei-blank.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-asmussen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10640&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Michael Asmussen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10640n.gif?v=1",
    address: "Steinberggaard 10, 24972, Steinberg",
    telephone: "+49 4632 7777",
    website: "http://www.dachdeckerei-asmussen.de",
    email: "info@dachdeckerei-asmussen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94403&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Mario Albrecht GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db3ed0ab5ee.png?v=1",
    address: "Dorfstraße 11, 01609 Wülknitz",
    telephone: "+49 35263 65674",
    website: "https://www.dachdeckerei-albrecht.de",
    email: "info@dachdeckerei-albrecht.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zetzmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92174&utm_campaign=profiles_installer",
    company_name: "Zetzmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3e19bc4545.jpg?v=1",
    address: "Gaiser Straße 20 , 96450 Coburg",
    telephone: "+49 9561 18751",
    website: "http://www.dachdecker-zetzmann.de",
    email: "info@dachdecker-zetzmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-wollmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117001&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Wollmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ff852ec8d3.png?v=1",
    address: "Straße des Friedens 8, 01723 Wilsdruff OT Kesselsdorf",
    telephone: "+49 35204 60572",
    website: "https://dachdecker-wollmann.de",
    email: "info@dachdecker-wollmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-maik-werner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95079&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Maik Werner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e854eda3140.jpg?v=1",
    address: "Johann-Gottlob-Pfaff-Straße 5a, 09405 Zschopau",
    telephone: "+49 3725 343660",
    website: "http://www.dachdecker-werner.de",
    email: "info@dachdecker-werner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/voss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94932&utm_campaign=profiles_installer",
    company_name: "Voss GmbH Dachdeckermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e05193b38fe.png?v=1",
    address: "Gewerbepark 26, 59069 Hamm Rhynern",
    telephone: "+49 2385 2549",
    website: "http://www.dachdecker-voss.de",
    email: "info@dachdecker-voss.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ronczka-pfanty?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94387&utm_campaign=profiles_installer",
    company_name: "Ronczka & Pfanty GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db31c3aaf8e.png?v=1",
    address: "Ickerottweg 4b, 45665 Recklinghausen-Suderwich",
    telephone: "+49 2361 87581",
    website: "http://www.dachdecker-suderwich.de",
    email: "info@dachdecker-suderwich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/paskal-both?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93949&utm_campaign=profiles_installer",
    company_name: "Paskal Both GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8b70b9c97a.jpg?v=1",
    address: "Merziger Str. 174, 66763 Dillingen/Saar",
    telephone: "+49 6831 4879581",
    website: "http://www.dachdecker-saarlouis.de",
    email: "info@dachdecker-saarlouis.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-riedel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10632&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Riedel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10632n.gif?v=1",
    address: "Wiesenstrasse 10, 38486, Klötze",
    telephone: "+49 3909 3365",
    website: "http://www.dachdecker-riedel.de",
    email: "info@dachdecker-riedel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-meisterbetrieb-markus-hoffmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95582&utm_campaign=profiles_installer",
    company_name: "Dachdecker-Meisterbetrieb Markus Hoffmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59fa6c3204e6b.jpg?v=1",
    address: "Villmarer Straße 27, 65611 Brechen",
    telephone: "+49 6438 923626",
    website: "https://www.dachdecker-brechen.de",
    email: "info@dachdecker-rechen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-dieter-opel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95018&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Dieter Opel GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e41a0e1a516.jpg?v=1",
    address: "Tiefengrüner Straße 19, 95189 Köditz",
    telephone: "+49 9293 7147",
    website: "http://www.dachdecker-opel.de",
    email: "info@dachdecker-opel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/frederik-oltrogge?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94250&utm_campaign=profiles_installer",
    company_name: "Dachdecker Oltrogge GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/600f57cd5f94a.png?v=1",
    address: "Am Sportplatz 3, 31555 Suthfeld",
    telephone: "+49 5723 6372",
    website: "http://www.dachdecker-oltrogge.de",
    email: "info@dachdecker-oltrogge.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-kaulartz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10082&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeisterbetrieb Pascal Kaulartz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10082n.gif?v=1",
    address: "Am Windrad 5, 52152 Monschau",
    telephone: "+49 2472 8025320",
    website: "http://www.dachdecker-monschau.de",
    email: "info@dachdecker-monschau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-liebscher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92151&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Liebscher",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3c34e8a0e8.jpg?v=1",
    address: "Solbrigstraße 6, 09120 Chemnitz",
    telephone: "+49 371 5905311",
    website: "http://dachdecker-liebscher.de",
    email: "info@dachdecker-liebscher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-lewandowski?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94930&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Lewandowski GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e0511a91dba.png?v=1",
    address: "Rotdornstr. 23, 34323 Malsfeld",
    telephone: "+49 5661 9290920",
    website: "https://www.dachdecker-lewandowski.de",
    email: "info@dachdecker-lewandowski.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-alfred-lehmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=114878&utm_campaign=profiles_installer",
    company_name: "Dachdecker Alfred Lehmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ce78b8695cb2.jpg?v=1",
    address: "Scharnhorststr. 11, 56564 Neuwied",
    telephone: "+49 2631 26401",
    website: "https://dachdecker-lehmann.de",
    email: "info@dachdecker-lehmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/latsch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95059&utm_campaign=profiles_installer",
    company_name: "Latsch GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e847bb0beef.jpg?v=1",
    address: "Freigründer Straße 118, D-57080 Siegen",
    telephone: "+49 271 356149",
    website: "http://www.dachdecker-latsch.de",
    email: "info@dachdecker-latsch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",CREATON GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mario-lassig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94476&utm_campaign=profiles_installer",
    company_name: "Mario Lässig",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc4949d5750.jpg?v=1",
    address: "An der Alten Oder 3a, 16259 Bad Freienwalde",
    telephone: "+49 3344 150355",
    website: "http://www.dachdecker-laessig.de",
    email: "info@dachdecker-laessig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/paul-krammes?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10699&utm_campaign=profiles_installer",
    company_name: "Paul Krammes GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10699n.gif?v=1",
    address: "Gewerbepark Klinkenthal 4b, 66578 Schiffweiler",
    telephone: "+49 6821 963600",
    website: "http://www.dachdecker-krammes.de",
    email: "info@dachdecker-krammes.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kettner-dachdecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95139&utm_campaign=profiles_installer",
    company_name: "Kettner Dachdecker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee8f85f2060.jpg?v=1",
    address: "Rückersberg 41, 98574 Schmalkalden",
    telephone: "+49 3683 4090900",
    website: "https://www.dachdecker-kettner.de",
    email: "info@dachdecker-kettner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lars?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94228&utm_campaign=profiles_installer",
    company_name: "Lars Kammann Dachdecker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc4b3322d1.png?v=1",
    address: "Oberwallweg 9, 31675 Bückeburg",
    telephone: "+49 5722 952514",
    website: "http://www.dachdecker-kammann.de",
    email: "info@dachdecker-kammann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/jorg-jansen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95206&utm_campaign=profiles_installer",
    company_name: "Jörg Jansen - Dachdeckermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f002fd1b5ad.png?v=1",
    address: "Büschen 1C, 41334 Nettetal",
    telephone: "+49 2153 730164",
    website: "http://www.dachdecker-jansen-nettetal.de",
    email: "info@dachdecker-jansen-nettetal.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-hitschler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95019&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Hitschler eK",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e41ab132d14.jpg?v=1",
    address: "Wallstraße 42, 66482 Zweibrücken/Rheinland-Pfalz",
    telephone: "+49 6332 77411",
    website: "http://www.dachdeckerhitschler.de",
    email: "info@dachdecker-hitschler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeisterbetrieb-gumprich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10507&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeisterbetrieb Egon Gumprich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5767440489bdb.jpg?v=1",
    address: "Bahnhofstraße 11 d, 02692, Singwitz",
    telephone: "+49 3591 31020",
    website: "http://www.dachdecker-gumprich.de",
    email: "info@dachdecker-gumprich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-ewald?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=114885&utm_campaign=profiles_installer",
    company_name: "Hermann Ewald GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f889ab1e5d8e.png?v=1",
    address: "Kiemstraße 4, Gewerbegebiet 54311 Trierweiler-Sirzenich",
    telephone: "+49 651 85435",
    website: "http://dachdecker-ewald.de",
    email: "info@dachdecker-ewald.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 16102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dorr-dach-wand?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102022&utm_campaign=profiles_installer",
    company_name: "Dörr Dach+Wand GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6e241b37f.jpg?v=1",
    address: "Emcostr. 1, 35274 Kirchhain, Hessen",
    telephone: "+49 6422 1018",
    website: "http://www.dachdecker-doerr.de",
    email: "info@dachdecker-doerr.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-braasch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10643&utm_campaign=profiles_installer",
    company_name: "Karl Heinz Braasch Dachdeckerei GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10643n.gif?v=1",
    address: "Schenefelder Chaussee 81, 25524 Itzehoe",
    telephone: "+49 4821 41174",
    website: "http://www.dachdecker-braasch.de",
    email: "info@dachdecker-braasch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-spenglerei-donath?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95022&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei & Spenglerei Donath",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60135fa7d5732.png?v=1",
    address: "Ahornstr. 5, 82194 Gröbenzell",
    telephone: "+49 8142 597940",
    website: "http://www.dach-donath.de",
    email: "info@dachdecker-blankenberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-klempnerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94451&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei - Klempnerei Thomas Reich",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc38a476a54.jpg?v=1",
    address: "Flurweg 1, 07366 Blankenberg",
    telephone: "+49 36642 29577",
    website: "https://www.dachdecker-blankenberg.de",
    email: "info@dachdecker-blankenberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-bente?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10642&utm_campaign=profiles_installer",
    company_name: "Dachdecker Bente GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10642n.gif?v=1",
    address: "Lüttparten 4, D-24582, Bordesholm",
    telephone: "+49 4322 3020",
    website: "http://www.dachdecker-bente.de",
    email: "info@dachdecker-bente.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Alfa Solar Energy Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ahrens-solartechnik-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10013&utm_campaign=profiles_installer",
    company_name: "Ahrens Dachtechnik e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/505963a1a3269.PNG?v=1",
    address: "Kreuzbreite 55, 31675 Bückeburg",
    telephone: "+49 5722 892920",
    website: "http://www.dachdecker-ahrens.de",
    email: "info@dachdecker-ahrens.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stickan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93913&utm_campaign=profiles_installer",
    company_name: "Stickan",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87aac547fa.jpg?v=1",
    address: "Stedefreunder Str. 51, 33729 Bielefeld",
    telephone: "+49 521 8972809",
    website: "https://www.dachbau-stickan.de",
    email: "info@dachbau-stickan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-weber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10726&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Hermann & Weber GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10726n.gif?v=1",
    address: "Paradiesstraße 35, 15370 Petershagen",
    telephone: "+49 33439 18504",
    website: "http://www.dach-weber.de",
    email: "info@dach-weber.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-thullesen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10659&utm_campaign=profiles_installer",
    company_name: "Niels Arne Thullesen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d5cb62ed2216.jpg?v=1",
    address: "Looper Weg 27, 24536 Neumünster",
    telephone: "+49 4321 95190",
    website: "http://www.dach-thullesen.de",
    email: "info@dach-thullesen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-und-sonne-agrar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=46603&utm_campaign=profiles_installer",
    company_name: "Dach und Sonne Agrar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fc62266837ad.png?v=1",
    address: "Kiefernweg 35, 04910 Elsterwerda",
    telephone: "+49 3533 4890606",
    website: "http://dach-sonne.de",
    email: "info@dach-sonne.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-solartechnik-wuppertal?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75135&utm_campaign=profiles_installer",
    company_name: "Dach & Solartechnik Wuppertal GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd11266c9d6.jpg?v=1",
    address: "Buchenhofener Straße 21, 42329 Wuppertal",
    telephone: "+49 202 76971830",
    website: "http://www.dach-solar-wuppertal.de",
    email: "info@dach-solar-wuppertal.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-spenglerei-mititelu?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93948&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei & Spenglerei Mititelu",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8b5ea1348c.jpg?v=1",
    address: "Magmannstraße 4, 82166 Gräfelfing",
    telephone: "+49 176 32458872",
    website: "http://www.dach-mititelu.de",
    email: "info@dach-mititelu.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dacher-von-grusz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10422&utm_campaign=profiles_installer",
    company_name: "Dächer von Christoph Gruß GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd0ea13cf768.png?v=1",
    address: "Am Sand 1, 07768 Gumperda",
    telephone: "+49 36422 6460",
    website: "http://www.daecher-von-gruss.de",
    email: "info@dach-gruss.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-dachdecker-friedrich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94461&utm_campaign=profiles_installer",
    company_name: "Zimmerei-Dachdecker Friedrich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc4176f133d.jpg?v=1",
    address: "Zwickauer Straße 379, 09117 Chemnitz",
    telephone: "+49 371 850292",
    website: "http://www.dach-friedrich.de",
    email: "info@dach-friedrich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/engel-dachtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95136&utm_campaign=profiles_installer",
    company_name: "Engel Dachtechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee8e9411c52.jpg?v=1",
    address: "Heinrich-Hoerle-Straße 16, 50737 Köln",
    telephone: "+49 221 744343",
    website: "http://www.dach-engel.de",
    email: "info@dach-engel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stickelmann-heldt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10734&utm_campaign=profiles_installer",
    company_name: "Stickelmann & Heldt Dachdeckermeister GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10734n.gif?v=1",
    address: "Parallelweg 48, 28219 Bremen",
    telephone: "+49 421 612817",
    website: "http://www.dach-bremen.de",
    email: "info@dach-bremen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/crs-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76227&utm_campaign=profiles_installer",
    company_name: "CRS Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57df936ab1b7f.jpg?v=1",
    address: "Flossinger Strasse 6, 84559 Kraiburg",
    telephone: "+49 8638 73140",
    website: "http://www.crssolar.de",
    email: "info@crssolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 27052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/convers-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95737&utm_campaign=profiles_installer",
    company_name: "Convers Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ffbc23ab50c.png?v=1",
    address: "Coloniastrasse 30, 53332, Bornheim",
    telephone: "+49 2232 931669",
    website: "http://conversenergie.com",
    email: "info@convers-e.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Frankreich, Großbritannien,Nordamerika,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/conterra?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=72495&utm_campaign=profiles_installer",
    company_name: "Conterra Energie Projekt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/609401c1e1c4d.PNG?v=1",
    address: "Bergstraße 6, 31535 Neustadt am Rübenberge",
    telephone: "+49 5034 7893030",
    website: "http://www.conterraenergie.de",
    email: "info@conterraenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/consolaris-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8766&utm_campaign=profiles_installer",
    company_name: "ConSolaris Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8766n.gif?v=1",
    address: "Sonnenfeldstraße 2, 34549 Edertal-Giflitz",
    telephone: "+49 5623 9335010",
    website: "http://www.consolaris-energietechnik.de",
    email: "info@consolaris.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,EGing Photovoltaic Technology Co., Ltd.,,,Suntech Power Co., Ltd.,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/conecon?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7650&utm_campaign=profiles_installer",
    company_name: "Conecon GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52044e1d5f4ec.jpg?v=1",
    address: "Mainaschaffer Str. 99, 63741 Aschaffenburg",
    telephone: "+49 6021 456050",
    website: "http://www.conecon.com",
    email: "info@conecon.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Tschechische, Deutschland, Spanien, Frankreich, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/comenergy-systems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=46427&utm_campaign=profiles_installer",
    company_name: "Comenergy Systems GmbH & Co. KG",
    logo_url:
      "https://cdn.enfsolar.com/ID/logo/46427_80445a71dc77816b2.png?v=1",
    address: "Tungendorfer Str. 81A, 24536 Neumünster",
    telephone: "+49 4321 9639446",
    website: "http://www.comenergy-systems.de",
    email: "info@comenergy-systems.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Suntech Power Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 1122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/otto-cobobes?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102696&utm_campaign=profiles_installer",
    company_name: "Otto Cobobes GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9af7b84d28.jpg?v=1",
    address: "Lübecker Landstraße 32, 23701 Eutin",
    telephone: "+49 4521 70490",
    website: "https://www.cobobes.de",
    email: "info@cobobes.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/clickcon-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41253&utm_campaign=profiles_installer",
    company_name: "ClickCon GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bc409a10cbf9.jpg?v=1",
    address: "Walter-Benjamin-Strasse 10, 79111 Freiburg",
    telephone: "+49 761 70599931",
    website: "http://clickcon.eu",
    email: "info@clickcon.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/clen-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=50957&utm_campaign=profiles_installer",
    company_name: "Clen Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/3581698726.gif?v=1",
    address: "Wettinerstraße 49, 08280, Aue-Bad Schlema",
    telephone: "+49 800 0010210",
    website: "http://www.clen-solar.de",
    email: "info@clen-solar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Österreich, Schweiz, Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/clear-sky-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92084&utm_campaign=profiles_installer",
    company_name: "Clear Sky Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fc954a5931.jpg?v=1",
    address: "Paul-Ehrlich-Straße 1, 69181 Leimen, Heidelberg",
    telephone: "+49 6221 9986990",
    website: "https://clearsky-energie.de",
    email: "info@clearsky-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,IBC Solar AG,,,JA Solar Holdings Co., Ltd.,,,Trina Solar Limited,,,GCL System Integration Technology Co., Ltd.,,,Suzhou Talesun Solar Technologies Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Sungrow Power Supply Co., Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/industrial-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75815&utm_campaign=profiles_installer",
    company_name: "Industrial Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf6f0dcb5b3.jpg?v=1",
    address: "Basler Strasse 115, D-79115 Freiburg",
    telephone: "+49 761 7671110",
    website: "http://www.industrial-solar.de",
    email: "info@cleanindustrysolutions.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/christ-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102231&utm_campaign=profiles_installer",
    company_name: "Christ Gebäudetechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1f75f2b106.jpg?v=1",
    address: "Neustädter Straße 8, 36320 Kirtorf",
    telephone: "+49 6635 373",
    website: "http://www.christ-gebaeudetechnik.de",
    email: "info@christ-gebaeudetechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/chalupa-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75149&utm_campaign=profiles_installer",
    company_name: "Chalupa Solartechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd1b4b76c48.jpg?v=1",
    address: "Poststraße 11, 74214 Schöntal Bieringen",
    telephone: "+49 7943 944980",
    website: "http://www.chalupa-solartechnik.de",
    email: "info@chalupa-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/cet-technology?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=25187&utm_campaign=profiles_installer",
    company_name: "CET Technology GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fc849d984a71.png?v=1",
    address: "Höchstadter Straße 5, 91475 Lonnerstadt",
    telephone: "+49 9193 6281204",
    website: "http://www.cet-technology.de",
    email: "info@cet-technology.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/ce-energieconzepte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81648&utm_campaign=profiles_installer",
    company_name: "CE - EnergieConzepte e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58881f5f619a1.jpg?v=1",
    address: "Lütticher Str. 4, 53842 Troisdorf",
    telephone: "+49 2266 4792166",
    website: "http://www.ce-energieconzepte.de",
    email: "info@ce-energieconzepte.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/cbe-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1875&utm_campaign=profiles_installer",
    company_name: "Cbe Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d27e3a201e1.jpg?v=1",
    address: "Bierstraße 50, 31246 Lahstedt",
    telephone: "+49 5174 922345",
    website: "http://www.cbesolar.de",
    email: "info@cbeSOLAR.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/carportunion?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83178&utm_campaign=profiles_installer",
    company_name: "CarportUnion",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be1a01e1869.jpg?v=1",
    address: "Am Flugplatz 18a, D-15344 Strausberg",
    telephone: "+49 3341 306464",
    website: "http://www.carport-aus-aluminium.de",
    email: "info@carportunion.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/carpevigo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11099&utm_campaign=profiles_installer",
    company_name: "Carpevigo Holding AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11099n.gif?v=1",
    address: "Marktplatz 20, 83607 Holzkirchen",
    telephone: "+49 8024 6083830",
    website: "http://www.carpevigo.de",
    email: "info@carpevigo.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Deutschland, Spanien, Italien, USA,Nordamerika, Europa,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Siemens Energy,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/carpe-calor?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92075&utm_campaign=profiles_installer",
    company_name: "Carpe Calor GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fc3f905261.jpg?v=1",
    address: "Malthusstr. 5, 28307 Bremen",
    telephone: "+49 421 4844760",
    website: "http://www.carpe-calor.de",
    email: "info@carpe-calor.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",JA Solar Holdings Co., Ltd.,,,Trina Solar Limited,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/capptuller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94130&utm_campaign=profiles_installer",
    company_name: "Capptuller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb6b10f1c9f.jpg?v=1",
    address: "Pinneberger Chaussee 105c, 25436 Moorrege",
    telephone: "+49 4122 4029000",
    website: "http://www.capptuller.de",
    email: "info@capptuller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/c2-umwelttechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10078&utm_campaign=profiles_installer",
    company_name: "C2-Umwelttechnik Michael Trauth e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10078n.gif?v=1",
    address: "Bruchgasse 16, 76863, Herxheim",
    telephone: "+49 7276 502210",
    website: "http://www.c2-umwelttechnik.de",
    email: "info@c2-umwelttechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ca-sa-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=85060&utm_campaign=profiles_installer",
    company_name: "Ca.sa Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58edc714e700c.jpg?v=1",
    address: "Hördenstr. 71 - 58135 Hagen",
    telephone: "+49 23 316244202",
    website: "http://casa-solar.de",
    email: "info@c2-umwelttechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/caa-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=98895&utm_campaign=profiles_installer",
    company_name: "CAA Int. GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5caaa0ef68aae.png?v=1",
    address: "P.O. Box 101401, 86004 Augsburg",
    telephone: "+49 821 94079",
    website: "https://caaint.com",
    email: "info@c2-umwelttechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Lorentz,",
    "Wechselrichter-Lieferanten": ",Steca Elektronik GmbH,,,Studer Innotec,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 29072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/photovoltaik-montage-zimmermann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83176&utm_campaign=profiles_installer",
    company_name: "Photovoltaik Montage Zimmermann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/607d930614d4b.PNG?v=1",
    address: "Azaleenring 19, 49733 Haren",
    telephone: "+49 160 94103869",
    website: "http://b-z-photovoltaikmontage.com",
    email: "info@bz-photovoltaik Montage.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bwg-solarkonzept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92077&utm_campaign=profiles_installer",
    company_name: "BWG Solarkonzept Rhein-Ahr-Eifel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fc5cba6386.jpg?v=1",
    address: "Ober dem Wasserborn 2, 56767 Uersfeld",
    telephone: "+49 2657 9415228",
    website: "http://bwg-solarkonzept.de",
    email: "info@bwg-solarkonzept.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/b-w-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124867&utm_campaign=profiles_installer",
    company_name: "B&W Energy GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecf90f24da06.png?v=1",
    address: "Leblicher Str., 27, 46359 Heiden",
    telephone: "+49 2867 909090",
    website: "http://bw-energy.de/",
    email: "info@bw-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Niederlande,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bvt-bellmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76393&utm_campaign=profiles_installer",
    company_name: "BVT Bellmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e37b1cabdf3.jpg?v=1",
    address: "Röntgenstr. 6, 66763 Dillingen",
    telephone: "+49 6831 98390",
    website:
      "https://www.bvt-bellmann.eu/geschaeftsbereiche/leistungsspektrum/regenerative-energien.html",
    email: "info@bvt-bellmann.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/butz-flachdachtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10698&utm_campaign=profiles_installer",
    company_name: "Butz Flachdachtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10698n.gif?v=1",
    address: "Sägewerkstraße 6, 75181 Pforzheim",
    telephone: "+49 7231 95280",
    website: "http://www.butz-dach.de",
    email: "info@butz-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/busies-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92072&utm_campaign=profiles_installer",
    company_name: "Busies Haustechnik e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fc1c48db14.jpg?v=1",
    address: "Tullastraße 5, 79331 Teningen",
    telephone: "+49 7641 43063",
    website: "http://www.busies.de",
    email: "info@busies.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-busch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93834&utm_campaign=profiles_installer",
    company_name: "Elektroinstallation Walter Busch e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b665c19ad.jpg?v=1",
    address: "Oststraße 48, 58540 Meinerzhagen",
    telephone: "+49 2354 3680",
    website: "http://buschelektro.de",
    email: "info@buschelektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",ZSD Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/burbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94736&utm_campaign=profiles_installer",
    company_name: "Burbach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dde314bb05e.png?v=1",
    address: "Grubstrasse 31, 35686 Dillenburg",
    telephone: "+49 2771 81550",
    website: "http://www.burbach-gmbh.de",
    email: "info@burbach-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bulter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75895&utm_campaign=profiles_installer",
    company_name: "Bulter GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d0c527e34a8.jpg?v=1",
    address: "Marktgasse 6, 48249 Dülmen",
    telephone: "+49 2594 5736",
    website: "http://www.bulter-solar.de",
    email: "info@bulter-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/buttner-elektrotechnik-kabelbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=125689&utm_campaign=profiles_installer",
    company_name: "Büttner Elektrotechnik & Kabelbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f07d75018d78.jpg?v=1",
    address: "Am Mühlenberg 42, 39619 Arendsee",
    telephone: "+49 39399 81204",
    website: "http://www.buettner-elektro-technik.de",
    email: "info@buettner-elektro-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/burgel-schulze-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102697&utm_campaign=profiles_installer",
    company_name: "Bürgel & Schulze Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9b034d721f.jpg?v=1",
    address: "Oldenburger Ring 4, D-02829 Markersdorf",
    telephone: "+49 35829 6240",
    website: "http://www.buergel-schulze.de",
    email: "info@buergel-schulze.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/budropol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=50991&utm_campaign=profiles_installer",
    company_name: "Budropol GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1121164066.gif?v=1",
    address: "Beethovenstraße 4, 90571 Schwaig b. Nürnberg",
    telephone: "+49 911 5903853",
    website: "http://www.budropol.de",
    email: "info@budropol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Jiangxi LDK Solar High-Tech Co., Ltd,,,Jiangsu SunLink PV Technology Co., Ltd.,,,Mitsubishi Electric US, Inc.,,,First Solar, Inc.,,,Bangkok Solar Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 2042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bss-solar-systeme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58253&utm_campaign=profiles_installer",
    company_name: "BSS Solar Systeme GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c803e0af6f1.jpg?v=1",
    address: "Bremmenstraße 20a, 58285 Gevelsberg",
    telephone: "+49 2332 6647371",
    website: "http://www.bss-solarsysteme.de",
    email: "info@bss-solarsysteme.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 23102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bss-dach-und-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76009&utm_campaign=profiles_installer",
    company_name: "BSS Dach- und Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d61cb1c2bca.jpg?v=1",
    address: "Reckenziner Dorfstr. 67, 19357 Karstädt",
    telephone: "+49 38788 901090",
    website: "http://www.bss-dachdecker.de",
    email: "info@bss-dachdecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,Kyocera Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bsk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75363&utm_campaign=profiles_installer",
    company_name: "Bedachung Solartechnik Klempnerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c3e5ffa2f9d.jpg?v=1",
    address: "Mönkediekstraße 2, 49088 Osnabrück",
    telephone: "+49 541 91198211",
    website: "http://www.bsk-os.de",
    email: "info@bsk-os.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/burger-speichern-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=115986&utm_campaign=profiles_installer",
    company_name: "Bürger Speichern Energie eG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d2562393faf1.png?v=1",
    address: "Goldener Steig 40, 94116 Hutthurm",
    telephone: "+49 8505 9162546",
    website: "http://www.bseeg.de",
    email: "info@bseeg.de",
    "Installaliert seit": 2014,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Österreich, Schweiz, Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",REFU Elektronik GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bsa-bedachung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10644&utm_campaign=profiles_installer",
    company_name: "BSA Bedachung Sanierung Abdichtungstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/587c4a6fed0e2.jpg?v=1",
    address: "Redderallee 12, 21483, Lütau",
    telephone: "+49 4153 559393",
    website: "http://www.bsa-bedachung.de",
    email: "info@bsa-bedachung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bs-tankanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74677&utm_campaign=profiles_installer",
    company_name: "BS Tankanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b280168bbc9.jpg?v=1",
    address: "Max-Planck-Straße 25, 72800 Eningen u. Achalm",
    telephone: "+49 7121 88733",
    website: "http://www.bs-tankanlagen.de",
    email: "info@bs-tankanlagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/beratungsservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74857&utm_campaign=profiles_installer",
    company_name: "Beratungsservice",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b535ebc3f53.jpg?v=1",
    address: "Anemonenbogen 2, 24963 Tarp",
    telephone: "+49 4638 3076598",
    website: "http://www.bs-scheel.de",
    email: "info@bs-scheel.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Waris S.r.l.,",
    "Wechselrichter-Lieferanten": ",ABB Italy S.p.A.,,,Danfoss A/S,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/brugger-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105977&utm_campaign=profiles_installer",
    company_name: "Brugger Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4458f0bf832.png?v=1",
    address: "Zollhäusleweg 9, 78052 Villingen-Schwenningen",
    telephone: "+49 7721 993675",
    website: "https://brugger-elektrotechnik.de",
    email: "info@brugger-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bruhmann-und-nolte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10729&utm_campaign=profiles_installer",
    company_name: "Firma Brühmann & Nolte GmbH Dachbauunternehmen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10729n.gif?v=1",
    address: "Am Mühlwege 1, 34497, Korbach",
    telephone: "+49 5631 913200",
    website: "http://www.nolte-daecher.de",
    email: "info@brugger-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-bruder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93958&utm_campaign=profiles_installer",
    company_name: "Holzbau Bruder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8c480164b8.jpg?v=1",
    address: "Altwingertweg 15, 68766 Hockenheim",
    telephone: "+49 6205 6460",
    website: "https://bruder-holzbau.de",
    email: "info@bruder-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/brockmann-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1696&utm_campaign=profiles_installer",
    company_name: "Brockmann Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1696n.gif?v=1",
    address: "Heidelberger Straße 4, 01189 Dresden",
    telephone: "+49 351 420950",
    website: "http://www.brockmann-solar.de",
    email: "info@brockmann-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/brocker-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94965&utm_campaign=profiles_installer",
    company_name: "Brocker Holzbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e06a308f899.jpg?v=1",
    address: "Kümmerner Weg 1, 54441 Mannebach",
    telephone: "+49 6581 5614",
    website: "http://www.brocker-holzbau.de",
    email: "info@brocker-holzbau.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Luxemburgo,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jakob-fuhr-inh-ernst-brendel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101835&utm_campaign=profiles_installer",
    company_name: "Jakob Fuhr Inh. Ernst Brendel GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa746b077d9c.jpg?v=1",
    address: "Buchbrunnenweg 18, 89081 Ulm",
    telephone: "+49 731 610038",
    website: "http://brendel-ulm.de",
    email: "info@brendel-ulm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebr-brehl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92064&utm_campaign=profiles_installer",
    company_name: "Brehl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fbbb8f3306.jpg?v=1",
    address: "Weichselstr. 20, 36043 Fulda",
    telephone: "+49 661 94890",
    website: "http://www.brehl.de",
    email: "info@brehl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/werner-bracht?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10701&utm_campaign=profiles_installer",
    company_name: "Werner Bracht Dachdeckermeisterbetrieb GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55f147f08a396.jpg?v=1",
    address: "Herlestraße 19, 33100 Paderborn",
    telephone: "+49 5251 22571",
    website: "http://www.bracht-bedachungen.de",
    email: "info@bracht-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/b-q-dachbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=607&utm_campaign=profiles_installer",
    company_name: "B & Q Dachbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575e73eb2f79f.jpg?v=1",
    address: "Moorfleeter Deich 112, 22113, Hamburg",
    telephone: "+49 40 7804060",
    website: "http://www.bq-dachbau.de",
    email: "info@bq-dachbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/boysen-zimmerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93992&utm_campaign=profiles_installer",
    company_name: "Boysen Zimmerei GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b2149e139.jpg?v=1",
    address: "Klaus-Groth-Straße 7, 21465 Reinbek",
    telephone: "+49 40 7277830",
    website: "http://www.boysen-zimmerei.de",
    email: "info@boysen-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bosse-dach-wand?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102027&utm_campaign=profiles_installer",
    company_name: "Bosse Dach + Wand GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab709f9798f.jpg?v=1",
    address: "Rethobergstr. 9, 37154 Northeim",
    telephone: "+49 5551 952030",
    website: "http://www.bosse-dach-wand.de",
    email: "info@bosse-dach-wand.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bos-ten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84821&utm_campaign=profiles_installer",
    company_name: "Bos.ten AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e7144e2d6c1.jpg?v=1",
    address: "Franz-von-Taxis-Ring 30-32, 93049 Regensburg",
    telephone: "+49 941 396470",
    website: "http://www.bos-ten.net",
    email: "info@bos-ten.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/borowski?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8211&utm_campaign=profiles_installer",
    company_name: "Borowski GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8211n.gif?v=1",
    address: "Heinz-Bäcker-Str. 38, 45356 Essen-Dellwig",
    telephone: "+49 201 1852780",
    website: "http://www.borowski.de",
    email: "info@borowski.de",
    "Installaliert seit": 1986,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Training",
    Muttergesellschaft: "",
    "Last Update": 3102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/borngraber-solartechnik-warmepumpen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92061&utm_campaign=profiles_installer",
    company_name: "Borngräber GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fba5c86fd5.jpg?v=1",
    address: "Kiekebuscher Straße 30, 03042 Cottbus",
    telephone: "+49 355 722675",
    website: "http://www.borngraeber.com",
    email: "info@borngraeber.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bollmann-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=604&utm_campaign=profiles_installer",
    company_name: "Bollmann Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54a37b58d6a2f.gif?v=1",
    address: "Insterburgstr. 8, 38122, Braunschweig-Broitzen",
    telephone: "+49 531 872959",
    website: "http://www.bollmann-elektrotechnik.de",
    email: "info@bollmann-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunenergy-boehne?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1570&utm_campaign=profiles_installer",
    company_name: "Sunenergy Boehne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/521dbc04b94ee.jpg?v=1",
    address: "Aminghauser Str. 5, D-32423 Minden",
    telephone: "+49 571 97304050",
    website: "http://www.boehne.de",
    email: "info@boehne.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Spanien, Kroatien,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 1092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bodtlander?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102013&utm_campaign=profiles_installer",
    company_name: "Bodtländer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6649de837.jpg?v=1",
    address: "Saarlouiser Str. 64, 66773 Schwalbach",
    telephone: "+49 6834 52520",
    website: "http://www.bodtlaender-gmbh.de",
    email: "info@bodtlaender-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bode-energie-und-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92056&utm_campaign=profiles_installer",
    company_name: "Bode Energie - und Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fa5ca32416.jpg?v=1",
    address: "Kastanienstraße 25, 38268 Lengede",
    telephone: "+49 5344 999350",
    website: "http://www.bode-solarstrom.de",
    email: "info@bode-solarstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,Panasonic Corporation (Sanyo),,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/b-o-b-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94003&utm_campaign=profiles_installer",
    company_name: "B.O.B. Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9bdaf8e743.jpg?v=1",
    address: "Otto-Wels-Str. 28, 33102 Paderborn",
    telephone: "+49 5251 8769405",
    website: "https://www.dachdeckerei-paderborn.de",
    email: "info@bob-pb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/blue-group?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7262&utm_campaign=profiles_installer",
    company_name: "Blue Group GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7262n.gif?v=1",
    address: "Gewerbepark Lindach A2, D-84489 Burghausen",
    telephone: "+49 8677 876702",
    website: "http://www.bluesolargroup.com",
    email: "info@blue.bayern",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bielefelder-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=641&utm_campaign=profiles_installer",
    company_name: "Bielefelder Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e25028cce217.jpg?v=1",
    address: "Otto-Brenner-Straße 150, D-33604 Bielefeld",
    telephone: "+49 521 297918",
    website: "http://www.blomeelektrotechnik.de",
    email: "Info@BlomeElektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/blomacher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92046&utm_campaign=profiles_installer",
    company_name: "Blömacher GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f9c384a74a.jpg?v=1",
    address: "Venloer Str. 61, 41462 Neuss",
    telephone: "+49 2131 545856",
    website: "http://www.bloemacher.de",
    email: "info@bloemacher-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kohler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92045&utm_campaign=profiles_installer",
    company_name: "Elektro-Köhler",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f9b216ede1.jpg?v=1",
    address: "Am Hellmannsgrund 2a, 08396 Waldenburg",
    telephone: "+49 37608 3737",
    website: "http://www.blitzschutz-solar.de",
    email: "info@blitzschutz-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/blitz-hartmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92044&utm_campaign=profiles_installer",
    company_name: "Blitz-Hartmann GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f98ad2d02d.jpg?v=1",
    address: "Zehnthofstraße 37, 71083 Herrenberg",
    telephone: "+49 7032 915760",
    website: "http://www.blitz-hartmann.de",
    email: "info@blitz-hartmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/blis-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75943&utm_campaign=profiles_installer",
    company_name: "Blis Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d208478e2a0.jpg?v=1",
    address: "Münzstr. 3-4, 30159 Hannover",
    telephone: "+49 511 30034490",
    website: "http://www.blis-solar.de",
    email: "info@blis-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,SunPower Corp.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/blg-project?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93312&utm_campaign=profiles_installer",
    company_name: "BLG Project GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb23d50d411.jpg?v=1",
    address: "Brückenstraße 15 a, 34466 Wolfhagen-Istha",
    telephone: "+49 5692 9960745",
    website: "http://www.blg.eu",
    email: "info@blg.eu",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/blank-projektentwicklung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11905&utm_campaign=profiles_installer",
    company_name: "Blank Projektentwicklung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11905n.gif?v=1",
    address: "Ringstraße 28, 74214, Schöntal",
    telephone: "+49 7943 943809",
    website: "http://www.blankeenergie.de",
    email: "info@blankeenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bk-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93280&utm_campaign=profiles_installer",
    company_name: "Block & Kirchhoff Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba19b5d1852.jpg?v=1",
    address: "Dunlopweg 2, 32130 Enger",
    telephone: "+49 5224 9374553",
    website: "http://www.bkelektrotechnik.de",
    email: "info@bkelektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bk-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75837&utm_campaign=profiles_installer",
    company_name: "BK Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf85b70bf7c.jpg?v=1",
    address: "Sonnenstraße 2, 86860 Jengen",
    telephone: "+49 8241 997060",
    website: "http://www.bk-solar.de",
    email: "info@bk-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-bitzer-schneider?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102560&utm_campaign=profiles_installer",
    company_name: "Elektro Bitzer Schneider GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac430345c36c.png?v=1",
    address: "Gewerbering 2, DE-84416 Taufkirchen",
    telephone: "+49 8084 413040",
    website: "http://www.bitzer-schneider.de",
    email: "info@bitzer-schneider.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bisolar-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92004&utm_campaign=profiles_installer",
    company_name: "BiSolar Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e9624085ad.jpg?v=1",
    address: "St. -Antonius-Straße 1, 55430 Urbar",
    telephone: "+49 6741 980537",
    website: "https://www.bisolar.de",
    email: "info@bisolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/birke-elektroanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=109467&utm_campaign=profiles_installer",
    company_name: "Birke Elektroanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bd15628ae372.jpg?v=1",
    address: "Rot-Kreuz-Straße 8, 95632 Wunsiedel",
    telephone: "+49 9232 9158250",
    website: "https://firmengruppe-birke.de",
    email: "info@birke-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/big-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75823&utm_campaign=profiles_installer",
    company_name: "Big Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cf7696210ed.jpg?v=1",
    address: "Hochstrasse 5-7, 35781 Weilburg",
    telephone: "+49 6471 492260",
    website: "http://www.bigsolar.de",
    email: "info@bigsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/biber-solarkonzept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=130965&utm_campaign=profiles_installer",
    company_name: "BIBER Solarkonzept UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/603f64058c099.jpg?v=1",
    address: "Schücostr. 11, 68649 Groß-Rohrheim",
    telephone: "+49 163 2712184",
    website: "http://www.biber-solarkonzept.de",
    email: "info@biber-solarkonzept.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Trina Solar Limited,,,Sharp Energy Solutions Europe,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/biber-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76265&utm_campaign=profiles_installer",
    company_name: "Biber Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e0b1d29d218.jpg?v=1",
    address: "Otto-Hahn-Str. 2, 36179 Bebra",
    telephone: "+49 6622 6039510",
    website: "http://biber-solar.de",
    email: "info@biber-pv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bewo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93826&utm_campaign=profiles_installer",
    company_name: "BEWO Energietechnik e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b1b0d8082.jpg?v=1",
    address: "Schwanthalerstr. 102, 80336 München",
    telephone: "+49 89 51619630",
    website: "http://www.bewo-energietechnik.de",
    email: "info@bewo-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,ABB Italy S.p.A.,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Nedap Energy Systems,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/herbert-betz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102247&utm_campaign=profiles_installer",
    company_name: "Herbert Betz GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5cf618d4ad3af.jpg?v=1",
    address: "Brunnenstraße 26, 63679 Schotten",
    telephone: "+49 6044 96090",
    website: "https://betz-technik.de",
    email: "info@betz-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/badische-energy-systems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41277&utm_campaign=profiles_installer",
    company_name: "Badische Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52b55602c98fc.jpg?v=1",
    address: "Brunnmattenstr. 4 a, 79238, Ehrenkirchen",
    telephone: "+49 7633 923693",
    website: "http://best-freiburg.de",
    email: "info@best-freiburg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,Canadian Solar Inc.,,,Risen Energy Co., Ltd.,,,Znshine PV-tech Co., Ltd.,,,SI Module GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,ABB Italy S.p.A.,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/beso-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93543&utm_campaign=profiles_installer",
    company_name: "BeSo Service GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0801b962ca.jpg?v=1",
    address: "Gewerbegebiet zum Wasserwerk 8 c, 15537 Erkner",
    telephone: "+49 33 62931180",
    website: "http://www.beso-service.de",
    email: "info@beso-service.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bertsch-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=111824&utm_campaign=profiles_installer",
    company_name: "Bertsch Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c467b02047cd.png?v=1",
    address: "Ortsstr. 52, 89155 Erbach-Ringingen",
    telephone: "+49 7344 1699721",
    website: "http://www.bertsch-energie.de",
    email: "info@bertsch-energie.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 12102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102558&utm_campaign=profiles_installer",
    company_name: "Elektro Luckow & Söhne GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac42f29b6047.png?v=1",
    address: "Bahnhofsallee 22, 40721 Hilden",
    telephone: "+49 2103 242040",
    website: "https://www.elektroluckow.de",
    email: "info@berresheim-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernhardt-bedachung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93962&utm_campaign=profiles_installer",
    company_name: "Bernhardt Bedachung GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c8c81157893.jpg?v=1",
    address: "Auf der Höhe 2a, D-35232 Dautphetal-Dautphe",
    telephone: "+49 6466 7949",
    website: "http://www.bernhardt-bedachung.de",
    email: "info@bernhardt-bedachung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/berner-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92007&utm_campaign=profiles_installer",
    company_name: "Berner Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e9896a1024.jpg?v=1",
    address: "Hafenbahnstraße 16, 70329 Stuttgart",
    telephone: "+49 711 9374560",
    website: "http://www.berner-gmbh.com",
    email: "info@berner-gmbh.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernd-floss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107249&utm_campaign=profiles_installer",
    company_name: "Bernd Floss Installateur- und Heizungsbaumeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b74e16adc62e.png?v=1",
    address: "Weststr. 32, 53913 Swisttal-Heimerzheim",
    telephone: "+49 2254 834348",
    website: "https://www.bernd-floss.de",
    email: "info@bernd-floss.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/berliner-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41279&utm_campaign=profiles_installer",
    company_name: "Berliner Energie GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52b55a8e6fbbb.jpg?v=1",
    address: "Nonnendamm 39, 13627, Berlin",
    telephone: "+49 30 34099132",
    website: "http://www.berliner-energie.de",
    email: "info@berliner-energie.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Aleo Solar GmbH,,,Sharp Energy Solutions Europe,,,Astronergy Co., Ltd. (Chint Solar),,,LG Electronics, Inc.,,,SolarMaxx Solar Energy Solutions,",
    "Wechselrichter-Lieferanten":
      ",Apollo Solar,,,SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/benzsolar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75365&utm_campaign=profiles_installer",
    company_name: "Benzsolar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c3e7730cc92.jpg?v=1",
    address: "Derenbach 3, 53773 Hennef",
    telephone: "+49 2248 917664",
    website: "http://www.solartechnik-hennef.de",
    email: "info@benzsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/benz-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10070&utm_campaign=profiles_installer",
    company_name: "Benz Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5191ab02f3746.gif?v=1",
    address: "Lerchenweg 36, 89174 Altheim, Alb",
    telephone: "+49 7340 918890",
    website: "https://www.benz-elektro.de",
    email: "info@benz-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bellen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92141&utm_campaign=profiles_installer",
    company_name: "Bellen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3bb38ae1a4.jpg?v=1",
    address: "Nordstraße 6, 41542 Dormagen",
    telephone: "+49 2133 90863",
    website: "http://www.bellen.de",
    email: "info@bellen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/belectric?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2026&utm_campaign=profiles_installer",
    company_name: "Belectric GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/2026n.gif?v=1",
    address: "Wadenbrunner Str.10, 97509 Kolitzheim",
    telephone: "+49 9385 5489000",
    website: "http://www.belectric.com",
    email: "info@belectric.com",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,JA Solar Holdings Co., Ltd.,,,Canadian Solar Inc.,,,First Solar, Inc.,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,GE Energy Power Conversion  GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/beisler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75553&utm_campaign=profiles_installer",
    company_name: "Beisler OHG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c7da523e9d0.jpg?v=1",
    address: "Frankfurter Straße 9+20, 63628 Bad Soden-Salmünster",
    telephone: "+49 6056 900300",
    website: "http://www.beisler-elektro.de",
    email: "info@beisler.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/egon-beetz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10525&utm_campaign=profiles_installer",
    company_name: "Egon Beetz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe2e5ab44cc6.png?v=1",
    address: "Segeberger Chaussee 329, 22851, Norderstedt",
    telephone: "+49 40 5244153",
    website: "http://www.beetz-gmbh.de",
    email: "info@beetz-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/josef-beermann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10149&utm_campaign=profiles_installer",
    company_name: "Josef Beermann GmbH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5656a05f7ddce.jpg?v=1",
    address: "Heinrich Niemeyer Strasse 50, 48477, Hörstel",
    telephone: "+49 5454 93050",
    website: "http://www.beermann.de",
    email: "info@beermann.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-und-wand-aus-einer-hand?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94939&utm_campaign=profiles_installer",
    company_name: "Dach und Wand aus einer Hand GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e053e7a0df3.png?v=1",
    address: "Am Mersheimer Graben 5, 52391 Vettweiß",
    telephone: "+49 2424 201020",
    website: "http://www.bedachungen-schoenecker.de",
    email: "INFO@BEDACHUNGEN-SCHOENECKER.DE",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Roto Frank Professional Service GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/meyer-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92003&utm_campaign=profiles_installer",
    company_name: "Meyer Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e950997d14.jpg?v=1",
    address: "Westkampstraße 4a, 59597 Erwitte",
    telephone: "+49 2943 97220",
    website: "http://bedachungen-meyer.de",
    email: "Info@Bedachungen-Meyer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/liepe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95073&utm_campaign=profiles_installer",
    company_name: "Liepe GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e84d30cd8dc.jpg?v=1",
    address: "Am Silberg 25, 59494 Soest",
    telephone: "+49 2921 77010",
    website: "http://www.bedachungen-liepe.de",
    email: "info@bedachungen-liepe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",CREATON GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/beckers-elektro-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102044&utm_campaign=profiles_installer",
    company_name: "Beckers Elektro- & Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab788eb8958.jpg?v=1",
    address: "Siemensstraße 4, D-47533 Kleve",
    telephone: "+49 2821 997870",
    website: "http://www.beckers-elektrotechnik.de",
    email: "info@beckers-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-beck?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10094&utm_campaign=profiles_installer",
    company_name: "Elektro Beck GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c7ec7227954.jpg?v=1",
    address: "Eichstätter Straße 15, 85132, Schernfeld",
    telephone: "+49 8422 746",
    website: "http://www.beck-elektro.de",
    email: "info@beck-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,Kyocera Corporation,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bechtold?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10056&utm_campaign=profiles_installer",
    company_name: "Bechtold GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c3f8960642c.jpg?v=1",
    address: "Heidigstraße 2, 76709 Kronau",
    telephone: "+49 7253 94030",
    website: "http://www.bechtoldsolar.de",
    email: "info@bechtoldsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/beba-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10055&utm_campaign=profiles_installer",
    company_name: "BeBa Energie GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5486a80af2a14.jpg?v=1",
    address: "25770 Hemmingstedt, Werner-von-Siemens-Straße 6",
    telephone: "+49 481 828660",
    website: "http://beba-gruppe.de",
    email: "info@beba-energie.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernd-becker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119703&utm_campaign=profiles_installer",
    company_name: "Bernd Becker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dd3923551294.jpg?v=1",
    address: "Holzer Straße 58, 66265 Heusweiler",
    telephone: "+49 68 0646230",
    website: "http://bb-dachdecker.de",
    email: "info@bb-dachdecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Alwitra GmbH & Co.,,,BMI Group,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bayerische-ray-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102674&utm_campaign=profiles_installer",
    company_name: "Bayerische Ray Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac986a252705.jpg?v=1",
    address: "Dirnismaning 34a, D-85748 Garching bei München",
    telephone: "+49 89 3290040",
    website: "http://www.bayray.de",
    email: "info@bayray.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bayer-raach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6611&utm_campaign=profiles_installer",
    company_name: "Bayer & Raach Neue Energien GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/563ad4e3e0d8e.jpg?v=1",
    address: "Hartwiesen 1, 72539 Pfronstetten-Aichelau",
    telephone: "+49 7388 993373",
    website: "http://www.bayer-raach.de",
    email: "info@bayer-raach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bausan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107247&utm_campaign=profiles_installer",
    company_name: "BauSAN GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b74df716e2ca.png?v=1",
    address: "Röntgenstraße 4, 89331 Burgau",
    telephone: "+49 82 22966560",
    website: "http://www.bausan-schwaben.de",
    email: "info@bausan-schwaben.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/baur-wagner-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102026&utm_campaign=profiles_installer",
    company_name: "Baur & Wagner Gmbh & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab700a6f044.jpg?v=1",
    address: "Angerstrasse 55, 86842 Türkheim",
    telephone: "+49 8245 1020",
    website: "http://www.baur-dach-wand.de",
    email: "info@baurwagner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/baumgartner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1872&utm_campaign=profiles_installer",
    company_name: "Baumgartner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5167ab7f1deb4.bmp?v=1",
    address: "Herrenweg 1, D-77971 Kippenheim",
    telephone: "+49 7825 877530",
    website: "http://www.baumgartner-gmbh.de",
    email: "info@baumgartner-gmbh.de",
    "Installaliert seit": 1990,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/baumann-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91994&utm_campaign=profiles_installer",
    company_name: "Baumann Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e81a16a2de.jpg?v=1",
    address: "Egerländer Straße 6-8, D-91541 Rothenburg",
    telephone: "+49 9861 9559860",
    website: "http://www.baumann-solartechnik.de",
    email: "info@baumann-solartechnik.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Solon International,,,IBC Solar AG,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",Baumann GmbH,",
    "Last Update": 21112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bauermade?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124489&utm_campaign=profiles_installer",
    company_name: "Bauermade GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb8c932995c0.jpg?v=1",
    address: "Sauerzapfstr. 13, D - 92507 Nabburg",
    telephone: "+49 9433 202025",
    website: "http://www.bauermade.info",
    email: "info@bauermade.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bauer-solarenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7642&utm_campaign=profiles_installer",
    company_name: "Bauer Energiekonzepte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b500f5b31f79.jpg?v=1",
    address: "Hinter der Mühl 2, 55278 Selzen",
    telephone: "+49 6737 80810",
    website: "http://bauer-energiekonzepte.de",
    email: "info@bauer-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Ungarn, Italien,",
    "Lieferanten von Solarmodulen": ",Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Bauer Group,",
    "Last Update": 2102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94807&utm_campaign=profiles_installer",
    company_name: "BAUER Elektroanlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df00278c48f.png?v=1",
    address: "Kaspar-Graf-Str. 2, 84428 Buchbach",
    telephone: "+49 8086 93000",
    website: "https://www.bauer-netz.de",
    email: "info@bauer-netz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bauer-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1656&utm_campaign=profiles_installer",
    company_name: "Bauer Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e763cf02f4.jpg?v=1",
    address: "Marie-Curie-Str. 3, 85055 Ingolstadt",
    telephone: "+49 841 480344",
    website: "http://www.solarcenter.de",
    email: "info@bauer-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bathow-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81542&utm_campaign=profiles_installer",
    company_name: "Bathow Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58845526c384b.jpg?v=1",
    address: "Weißiger Str. 3, 02994 Bernsdorf",
    telephone: "+49 35723 2340",
    website: "http://www.bathow.com",
    email: "info@bathow.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/barysol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13720&utm_campaign=profiles_installer",
    company_name: "Barysol GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5af4e9c77539f.jpg?v=1",
    address: "Aubweg 33, 97990 Weikersheim",
    telephone: "+49 7934 9957000",
    website: "http://www.barysol.de",
    email: "info@barysol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Griechenland, Italien, Turkei,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,,,Risen Energy Co., Ltd.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bartsch-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102009&utm_campaign=profiles_installer",
    company_name: "Jörg Bartsch Dachdecker- und Klempnermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab655d6316d.jpg?v=1",
    address: "Wittensteinstr. 248, 42283 Wuppertal",
    telephone: "+49 202 559806",
    website: "https://www.dachdecker-wuppertal.info",
    email: "info@bartsch-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/barth-kerner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93337&utm_campaign=profiles_installer",
    company_name: "Barth & Kerner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb39dd5f926.jpg?v=1",
    address: "Röntgenstraße 14/1, 73730, Esslingen am Neckar",
    telephone: "+49 711 9308150",
    website: "http://elektrobkv.de/",
    email: "info@barth-kerner.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/baro-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75963&utm_campaign=profiles_installer",
    company_name: "Baro Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d21dbf518d3.jpg?v=1",
    address: "Alte Flugplatzstrasse 5, 04838 Doberschuetz, OT Rote Jahne",
    telephone: "+49 3423 7066600",
    website: "http://www.baro-solar.de",
    email: "info@baro-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-barczus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94829&utm_campaign=profiles_installer",
    company_name: "Elektro Barczus",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df103ed480d.jpg?v=1",
    address: "Schloßstraße.42, 07338 Eichicht",
    telephone: "+49 36733 22216",
    website: "http://lichthaus-gmbh.de",
    email: "info@barczus.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,Trina Solar Limited,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/klaus-meyer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74521&utm_campaign=profiles_installer",
    company_name: "Klaus Meyer GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ad6eabc101a.jpg?v=1",
    address: "Nordring 10, 24850 Schuby",
    telephone: "+49 4621 41476",
    website: "http://www.baeder-waerme-solar.de",
    email: "info@baeder-waerme-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Solar Frontier K.K.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/backer-elektrobau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93564&utm_campaign=profiles_installer",
    company_name: "Bäcker Elektrobau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0890fd028c.jpg?v=1",
    address: "Mösberg 19, 23623 Ahrensbök",
    telephone: "+49 4525 496969",
    website: "https://www.baecker-elektrobau.de",
    email: "info@baecker-elektrobau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/az-energy-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77303&utm_campaign=profiles_installer",
    company_name: "az Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/581196087b16f.jpg?v=1",
    address: "Am Kupferrasen 11, 99310 Arnstadt",
    telephone: "+49 3628 5182040",
    website: "https://www.az-energie.de",
    email: "info@az-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/axma-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41275&utm_campaign=profiles_installer",
    company_name: "Axma Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52b54f6fd951c.jpg?v=1",
    address: "Nassauische Str. 8, 56470 Bad Marienberg",
    telephone: "+49 2661 931761",
    website: "http://www.axma-solar.de",
    email: "info@axma-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/aws-solar-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75899&utm_campaign=profiles_installer",
    company_name: "AWS Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d0c821afa9e.jpg?v=1",
    address: "Baldurstr. 80, 46284 Dorsten",
    telephone: "+49 2362 997574",
    website: "http://www.aws-solar.de",
    email: "info@aws-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/awi-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75951&utm_campaign=profiles_installer",
    company_name: "AWI Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d217c47281b.jpg?v=1",
    address: "Korbacher Str. 1c, 97353 Wiesentheid",
    telephone: "+49 9383 9039690",
    website: "http://www.awi-solar.de",
    email: "info@awi-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/awa-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30359&utm_campaign=profiles_installer",
    company_name: "AWA Solar- & Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e768b80af29.jpg?v=1",
    address: "Hochdorf 31, 94253 Bischofsmais",
    telephone: "+49 9920 902015",
    website: "http://awa-solar.de",
    email: "info@awa-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",JA Solar Holdings Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/aussem-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95144&utm_campaign=profiles_installer",
    company_name: "Aussem Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee922719c4b.jpg?v=1",
    address: "Erper Straße 14, 50374 Erftstadt",
    telephone: "+49 2235 78138",
    website: "https://www.aussem-bedachungen.de",
    email: "info@aussem-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aurea-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101846&utm_campaign=profiles_installer",
    company_name: "Aurea Energie GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa764749ba3f.jpg?v=1",
    address: "Falchenstrasse 106, 89233 Neu-Ulm",
    telephone: "+49 731 71101788",
    website: "http://www.aurea-energie.de",
    email: "info@aurea-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Italien, Rumänien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/athos-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75017&utm_campaign=profiles_installer",
    company_name: "Athos Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bac16c53e47.jpg?v=1",
    address: "Im Breitapiel 11, D-69126 Heidelberg",
    telephone: "+49 6221 3460885",
    website: "http://www.athos-solar.de",
    email: "info@athos-solar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/at-solarprof?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91979&utm_campaign=profiles_installer",
    company_name: "AT Solarprofi GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e6bee53a19.jpg?v=1",
    address: "Benzstr. 6 75334 Straubenhardt",
    telephone: "+49 7082 9412230",
    website: "http://www.at-solarprofi.de",
    email: "info@at-solarprofi.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/a-t-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=130628&utm_campaign=profiles_installer",
    company_name: "A&T Energiesysteme GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/602ddc2cb5905.jpg?v=1",
    address: "Steinstrasse 15, 15566 Schöneiche b, Berlin",
    telephone: "+49 179 7006667",
    website: "http://www.at-energiesysteme.de",
    email: "info@at-energiesysteme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/astenik-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=12989&utm_campaign=profiles_installer",
    company_name: "Astenik Solar Inc.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57636ef0b3012.jpg?v=1",
    address: "60 Spy Crt, Unit 4, Markham, ON, L3R 5H6",
    telephone: "+1 877 2783645",
    website: "http://www.asteniksolar.com",
    email: "info@asteniksolar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Kanada,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/asol-energy-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41273&utm_campaign=profiles_installer",
    company_name: "Asol Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/581c2158e48e5.jpg?v=1",
    address: "Von-Hofmann-Straße 2, 44579 Castrop-Rauxel",
    telephone: "+49 2305 7045455",
    website: "http://www.asol-energy.de",
    email: "info@asol-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Trina Solar Limited,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-matthias-asmussen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92017&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Matthias Asmussen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f75bda192c.jpg?v=1",
    address: "An der B5 Nr.15, 25920 Stedesand",
    telephone: "+49 4662 8928223",
    website: "http://www.asmussen-dachdeckerei.de",
    email: "info@asmussen-dachdeckerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/andreas-schmidt-dacher-und-fassaden?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94479&utm_campaign=profiles_installer",
    company_name: "Andreas Schmidt Dächer und Fassaden GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc4beae457f.jpg?v=1",
    address: "Lassalleweg 51, 06667 Weißenfels",
    telephone: "+49 3443 39300",
    website: "http://www.asdach.de",
    email: "info@asdach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/asa-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91975&utm_campaign=profiles_installer",
    company_name: "ASA Energy GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e550f41855.jpg?v=1",
    address: "Heimbacher Straße 35, 52385 Nideggen",
    telephone: "+49 2474 5099910",
    website: "http://asa-energy.com/",
    email: "info@asa-energy.com",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",ASA Group,",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/as-hausrenovierungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102015&utm_campaign=profiles_installer",
    company_name: "AS-Hausrenovierungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6863b99e0.jpg?v=1",
    address: "Ringstraße 180, 73432 Aalen",
    telephone: "+49 7367 2041",
    website: "http://www.as-hausrenovierungen.de",
    email: "info@as-hausrenovierungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/arthur-rothel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77365&utm_campaign=profiles_installer",
    company_name: "Arthur Röthel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5816e1ed00dd0.jpg?v=1",
    address: "Bahnhofstrasse 20, 04509 Krostitz",
    telephone: "+49 34295 7520",
    website: "http://www.arthur-roethel.de",
    email: "info@arthur-roethel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/art-kalz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=45031&utm_campaign=profiles_installer",
    company_name: "ART Kalz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/584657b4c8297.jpg?v=1",
    address: "Am Schießplatz 9, 01968, Hörlitz",
    telephone: "+49 35 733672771",
    website: "https://a-r-t-kalz.de",
    email: "info@art-kalz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Jiangxi LDK Solar High-Tech Co., Ltd,,,Csunpower Technology (Nanjing) Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/arnold-dach-und-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10034&utm_campaign=profiles_installer",
    company_name: "Arnold Dach und Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589c263291271.jpg?v=1",
    address: "Hüttenstraße 31a, Industriegebiet Süd, D-66839 Schmelz",
    telephone: "+49 6887 7028",
    website: "http://www.arnolddachundsolar.de",
    email: "info@arnolddachundsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/archimedes-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102243&utm_campaign=profiles_installer",
    company_name: "Archimedes Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab20ff81d8b8.jpg?v=1",
    address: "Engerstraße 3-5, D-32051 Herford",
    telephone: "+49 5221 1334311",
    website: "http://www.archimedes-technik.de",
    email: "info@archimedes-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Archimedes Group,",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/arausol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13719&utm_campaign=profiles_installer",
    company_name: "Arau Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/13719n.gif?v=1",
    address: "Hanfwiesenstraße 15, D-73614 Schorndorf",
    telephone: "+49 718 192200",
    website: "http://www.arausol.com",
    email: "info@arausol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/appel-werner-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10786&utm_campaign=profiles_installer",
    company_name: "Werner Appel Zimmerei-Holzbau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10786n.gif?v=1",
    address: "Gartenstrasse 8, 63785 Obernburg",
    telephone: "+49 6022 7109830",
    website: "http://www.appel-zimmerei.de",
    email: "info@appel-zimmerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/apex-energy-teterow?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=12041&utm_campaign=profiles_installer",
    company_name: "APEX Energy Teterow GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52940326c57a5.gif?v=1",
    address: "Am Kellerholz 4-6, 17166 Teterow",
    telephone: "+49 3996 1572260",
    website: "http://www.apex-energy.de",
    email: "info@apex-energy.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/aot-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83096&utm_campaign=profiles_installer",
    company_name: "AOT Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b90c389992e.jpg?v=1",
    address: "Ermlandstr. 57, D-28777 Bremen",
    telephone: "+49 421 65897012",
    website: "http://www.aot-technik.de",
    email: "info@aot-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/antlike-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76269&utm_campaign=profiles_installer",
    company_name: "Antlike Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60af7ed7b167f.PNG?v=1",
    address: "Heydeweg 5, 18182 Bentwisch",
    telephone: "+49 381 80113050",
    website: "http://www.antlike.de",
    email: "info@antlike-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/metz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91961&utm_campaign=profiles_installer",
    company_name: "Metz Anlagentechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e407b28cda.jpg?v=1",
    address: "Am Stützle 11, 97705 Burkardroth",
    telephone: "+49 9734 9310950",
    website: "https://www.anlagentechnik-metz.de",
    email: "info@anlagentechnik-metz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/am-photovoltaik-und-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102577&utm_campaign=profiles_installer",
    company_name: "AM Photovoltaik und Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac442fd3187f.png?v=1",
    address: "Ortsstr. 39, 73061 Ebersbach an der Fils",
    telephone: "+49 174 9964111",
    website: "https://www.amphotovoltaik.de",
    email: "info@AMPhotovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ampere-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=16667&utm_campaign=profiles_installer",
    company_name: "Ampere Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54a8d855a48d8.jpg?v=1",
    address: "Antonstraße 39, 01097 Dresden",
    telephone: "+49 351 4265244",
    website: "http://www.ampere-solar.de",
    email: "info@ampere-solar.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ama-technology-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=128754&utm_campaign=profiles_installer",
    company_name: "AMA Technology GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fb281fe52e57.png?v=1",
    address: "Leihbühl 21, 33165, Lichtenau",
    telephone: "+49 52 959984745",
    website: "http://ama-technology.de/press/",
    email: "info@ama-technology.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Libanon, Palästina,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/altmarker-solarstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1867&utm_campaign=profiles_installer",
    company_name: "Altmärker Solarstrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1867n.gif?v=1",
    address: "Klötzer Straße 34b, 38486 Kusey",
    telephone: "+49 39005 93080",
    website: "http://www.altmarksolar.de",
    email: "info@altmarksolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Kaneka Corporation,,,Sharp Energy Solutions Europe,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Siemens Energy,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/altintas?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91956&utm_campaign=profiles_installer",
    company_name: "Altintas GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ffe2381811fc.png?v=1",
    address: "Figline-Valdarno-Ring 13, 64319 Pfungstadt",
    telephone: "+49 6157 8085843",
    website: "http://www.altintas-shk.de",
    email: "info@altintas-shk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-a-t-sonnen-u-alternativtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=626&utm_campaign=profiles_installer",
    company_name: "S.A.T. Sonnen- u. Alternativtechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11075.gif?v=1",
    address: "Osterkoppel 1, 25821 Struckum",
    telephone: "+49 4671 60300",
    website: "http://www.alternativtechnik.de",
    email: "info@alternativtechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,IBC Solar AG,,,SunPower Corp.,,,Kyocera Corporation,,,Solar Frontier K.K.,,,Viessmann Werke GmbH & Co. KG,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alternative-energien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7255&utm_campaign=profiles_installer",
    company_name: "Alternative Energien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50874e122580a.gif?v=1",
    address: "Heidkamper Weg 1, D-27801 Neerstedt",
    telephone: "+49 44 32912812",
    website: "http://www.alternative-energien-gb.de",
    email: "info@alternative-energien-gb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alternative-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8687&utm_campaign=profiles_installer",
    company_name: "Alternative Energie - Solarstromanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8687n.gif?v=1",
    address: "Staudach 1, 82541, Ammerland",
    telephone: "+49 8177 998490",
    website: "http://www.alternative-energie.de",
    email: "info@alternative-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/alphasol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13716&utm_campaign=profiles_installer",
    company_name: "AlphaSol GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/506383c8336ba.PNG?v=1",
    address: "Auf dem Hohenstein 3a, 61231, Bad Nauheim",
    telephone: "+49 6032 926850",
    website: "http://www.alphasol.de",
    email: "info@alphasol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/alldach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10526&utm_campaign=profiles_installer",
    company_name: "Alldach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10526n.gif?v=1",
    address: "Leipziger Strasse 38, 06366 Köthen",
    telephone: "+49 3496 551343",
    website: "http://www.alldach.de",
    email: "info@alldach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alkasol?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7254&utm_campaign=profiles_installer",
    company_name: "alkaSOL GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c2a6580b359.gif?v=1",
    address: "Wallersdorfer Str. 4, 94522 Haidlfing",
    telephone: "+49 9933 9529556",
    website: "http://www.alkasol.de",
    email: "info@alkasol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Angola, Österreich, Australien, Bahamas, Kamerun, Tschechische, Deutschland, Spanien, Frankreich, Griechenland, Italien, Mauritius, Nigeria, Turkei,Afrika,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,LTI ReEnergy GmbH,,,GoodWe Power Supply Technology Co., Ltd.,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/algatec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=5550&utm_campaign=profiles_installer",
    company_name: "Algatec Handels UG & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5550_60985ca3475a5140a.png?v=1",
    address: "Schipkauer Strasse 8, 01987, Schwarzheide",
    telephone: "+49 172 6764285",
    website: "http://www.algatec.com",
    email: "info@algatec.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/albwerk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102377&utm_campaign=profiles_installer",
    company_name: "Albwerk GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9c5568ede2.png?v=1",
    address: "Postfach 1151, 73301 Geislingen",
    telephone: "+49 7331 2090",
    website: "https://www.albwerk.de",
    email: "info@albwerk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alb-elektric?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93592&utm_campaign=profiles_installer",
    company_name: "Alb-Elektric Huber GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0974d19fab.jpg?v=1",
    address: "Obere Stegwiesen 28, 88400 Biberach/Riß",
    telephone: "+49 7351 15880",
    website: "http://www.alb-elektric.de",
    email: "info@alb-elektric.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunwind-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10430&utm_campaign=profiles_installer",
    company_name: "SunWind GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/604612a0f139b.png?v=1",
    address: "Oberstraße 40, 65594, Runkel",
    telephone: "+49 6483 911047",
    website: "http://sun-wind.de",
    email: "info@aktiv-sunwind.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ahmann-wirth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10904&utm_campaign=profiles_installer",
    company_name: "Ahmann & Wirth GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fec35ff151c7.png?v=1",
    address: "Gutenbergstraße 8 a, 49479, Ibbenbüren",
    telephone: "+49 5451 9388291",
    website: "http://www.ahmann-wirth.de",
    email: "info@ahmann-wirth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/agroenergien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116586&utm_campaign=profiles_installer",
    company_name: "AgroEnergien",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d369e49720eb.jpg?v=1",
    address: "Brunner Straße 18, 26316 Varel",
    telephone: "+49 4453 985800",
    website: "https://agroenergien.de",
    email: "info@agroenergien.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen": ",First Solar, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/agrarsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7253&utm_campaign=profiles_installer",
    company_name: "AgrarSolar GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ca6d6dd1239.gif?v=1",
    address: "Am Seeacker 3, Dießen am Ammersee, Bayern 86911",
    telephone: "+49 8807 2140390",
    website: "http://www.agrarsolar.de",
    email: "info@agrarsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/agens-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8353&utm_campaign=profiles_installer",
    company_name: "Agens Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d12bb2749d1.jpg?v=1",
    address: "Am Kellerholz 4, 17166 Teterow",
    telephone: "+49 3996 152000",
    website: "http://www.agens-energie.de",
    email: "info@agens-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ageff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107426&utm_campaign=profiles_installer",
    company_name: "Ageff GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b7bb79267fc9.png?v=1",
    address: "Engelbergerstr. 19, 79106 Freiburg im Breisgau",
    telephone: "+49 761 88843180",
    website: "https://www.ageff.com",
    email: "info@ageff.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ageb-elektro-automatisierung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94355&utm_campaign=profiles_installer",
    company_name: "AGEB Elektro-Automatisierung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dacc1c5e836.jpg?v=1",
    address: "Wickersdorfer Ring 4, 35099 Burgwald",
    telephone: "+49 6451 717850",
    website: "http://www.ageb.de",
    email: "info@ageb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/a-f-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8513&utm_campaign=profiles_installer",
    company_name: "A+F Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/8513n.gif?v=1",
    address: "Adalbert-Stifter-Straße 12, 94110 Wegscheid",
    telephone: "+49 8592 1474",
    website: "http://www.af-solartechnik.de",
    email: "info@af-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aep-solar-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9991&utm_campaign=profiles_installer",
    company_name: "AEP Gesellschaft für Alternative Energien mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57673f4068e27.jpg?v=1",
    address: "Drögen-Hasen-Weg 9, 26129 Oldenburg",
    telephone: "+49 441 21713602",
    website: "http://www.aep-solar.de",
    email: "info@AEP-Solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=25741&utm_campaign=profiles_installer",
    company_name: "Alternative Energie Concepte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/513ffe00e3f4b.gif?v=1",
    address: "Maintalstraße 60, 95460, Bad Berneck",
    telephone: "+49 9273 501600",
    website: "http://aec-solar.com",
    email: "info@aec-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Zhejiang Sunflower Amass Ray New Energy Technology Co.,Ltd,,,Wiosun For Renewable Energy Ltd,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ae3000?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9872&utm_campaign=profiles_installer",
    company_name: "AE3000 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57cfa6c553f64.jpg?v=1",
    address: "Postweg, 3, 83209 Prien am Chiemsee",
    telephone: "+49 8051 9654322",
    website: "http://www.ae3000.com",
    email: "info@ae3000.com",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Spanien, Frankreich, Turkei,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ae-tec-alternative-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=63933&utm_campaign=profiles_installer",
    company_name: "AE-Tec Alternative Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/604f51d0d429f.png?v=1",
    address: "Hochstraße 17, 47228 Duisburg",
    telephone: "+49 2065 6786780",
    website: "http://www.ae-tec.solar",
    email: "info@ae-tec.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ae-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22783&utm_campaign=profiles_installer",
    company_name: "AE Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/22783_143459c124ca21636.png",
    address: "",
    telephone: "",
    website: "http://www.ae-solar.com",
    email: "info@ae-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/adrex?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91916&utm_campaign=profiles_installer",
    company_name: "ADREX Photovoltaikanlagen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bbc55714ce1a.jpg?v=1",
    address: "Haiterbacher Steige 82, 72160 Horb am Neckar",
    telephone: "+49 7486 963674",
    website: "http://www.adrex.de",
    email: "info@adrex.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Panasonic Corporation (Sanyo),,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/addisol-components?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91913&utm_campaign=profiles_installer",
    company_name: "Addisol  Components GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599bcd5d55e5d.jpg?v=1",
    address: "Im Kessel 3, 21629 Wulmstorf",
    telephone: "+49 40 41358260",
    website: "https://www.addisol.eu",
    email: "info@addisol.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/actensys?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41255&utm_campaign=profiles_installer",
    company_name: "Actensys GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb95aa85fbf0.png?v=1",
    address: "Zur Schönhalde 10, 89352 Ellzee",
    telephone: "+49 8283 999980",
    website: "http://www.actensys.de",
    email: "info@actensys.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",RenerVest GmbH,",
    "Last Update": 11052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ache-engineering?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102693&utm_campaign=profiles_installer",
    company_name: "ACHE Engineering GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac99a346cf79.jpg?v=1",
    address: "Roentgenweg 9, D-35638 Leun",
    telephone: "+49 6473 922540",
    website: "http://ache-engineering.de",
    email: "info@ache-engineering.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/chroscinski-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=49431&utm_campaign=profiles_installer",
    company_name: "Chroscinski Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59113d9331739.jpg?v=1",
    address: "Sankt-Georg-Str. 2, 87463, Dietmannsried-Reicholzried",
    telephone: "+49 8374 5895640",
    website: "http://www.ach-solar.de",
    email: "info@ach-solar.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,Suntech Power Co., Ltd.,,,ReneSola Ltd.,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ac-solar-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75139&utm_campaign=profiles_installer",
    company_name: "AC Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd177118ba6.jpg?v=1",
    address: "Thierkopfweg 36, D-82319 Starnberg",
    telephone: "+49 8151 9987714",
    website: "http://www.ac-solartechnik.de",
    email: "info@ac-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,SunPower Corp.,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,First Solar, Inc.,,,Jinko Solar Holding Co., Ltd.,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,SolarEdge Technologies, Ltd.,,,Tesla PV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/abel-retec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=18139&utm_campaign=profiles_installer",
    company_name: "ABEL ReTec GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f605a24a95d4.jpg?v=1",
    address: "Gießmühler Straße 3, D-84549 Engelsberg/Ortsteil Wiesmühl",
    telephone: "+49 8634 6265560",
    website: "http://www.abel-retec.de",
    email: "info@abel-retec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/abel-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11914&utm_campaign=profiles_installer",
    company_name: "Abel Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11914n.gif?v=1",
    address: "Gewerbering 15, 84549, Engelsberg",
    telephone: "+49 8634 62560",
    website: "http://www.abel-elektrotechnik.de",
    email: "info@abel-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: ",Abel Gruppe,",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wagner-haus-und-umwelttechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75575&utm_campaign=profiles_installer",
    company_name: "Wagner, Haus- und Umwelttechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c7efda52b82.jpg?v=1",
    address: "Bahnhofstrasse 12, 87789 Woringen",
    telephone: "+49 8331 990310",
    website: "http://www.abc-wagner.de",
    email: "info@abc-wagner.de",
    "Installaliert seit": 1997,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/a2-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81643&utm_campaign=profiles_installer",
    company_name: "a2 Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588813b285939.jpg?v=1",
    address: "Am Piperfenn 10, 14776 Brandenburg, Havel",
    telephone: "+49 3381 213220",
    website: "http://www.a2energie.de",
    email: "info@a2energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/7x7energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94854&utm_campaign=profiles_installer",
    company_name: "7x7energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fbbab6e661f9.png?v=1",
    address: "Wilhelmstraße 6, 35683 Dillenburg",
    telephone: "+49 2771 267320",
    website: "https://www.7x7.de",
    email: "info@7phia7.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",7x7 Group,",
    "Last Update": 23112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/30-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102353&utm_campaign=profiles_installer",
    company_name: "30°-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b10cbdba7.png?v=1",
    address: "Schwedter Straße 225, 10435 Berlin",
    telephone: "+49 30 44052303",
    website: "http://www.30grad-solar.de",
    email: "info@30grad-solar.com",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/1a-energiekonzepte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102366&utm_campaign=profiles_installer",
    company_name: "1A Energiekonzepte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9bcf95085c.png?v=1",
    address: "Stettiner Strasse 8 HH, 13357 Berlin",
    telephone: "+49 30 23882282",
    website: "http://1a-energiekonzepte.com",
    email: "info@1a-energiekonzepte.com",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Solon International,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rohm-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81967&utm_campaign=profiles_installer",
    company_name: "Röhm Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589be3104d6e2.jpg?v=1",
    address: "Bahnhofstraße 6, 89561 Ballmertshofen",
    telephone: "+49 7327 9222173",
    website: "http://www.roehm-elektrotechnik.de",
    email: "info@roehm-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ms-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76165&utm_campaign=profiles_installer",
    company_name: "Schwarz GmbH Elektro- und Sicherheitstechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdd22dc4bf72.png?v=1",
    address: "Untere Gewerbesraße 19, 77791 Berghaupten",
    telephone: "+49 7803 96640",
    website: "https://www.schwarz-elektro.de",
    email: "info(at)schwarz-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,Viessmann Werke GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Tesla PV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schaffer-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11970&utm_campaign=profiles_installer",
    company_name: "Schäffer Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11970n.gif?v=1",
    address: "Aufhausener Str. 39, 89520 Heidenheim",
    telephone: "+49 7321 961662",
    website: "http://schaeffer-expert.de",
    email: "info@schaeffer-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-sandmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92239&utm_campaign=profiles_installer",
    company_name: "Sandmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fff737ae06cb.png?v=1",
    address: "Am Stadion 67, 45659 Recklinghausen",
    telephone: "+49 2361 92410",
    website: "http://www.sandmanngmbh.de",
    email: "info@sandmanngmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/radlinger-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4119&utm_campaign=profiles_installer",
    company_name: "Rädlinger Energy GmbH (RWenergy GmbH)",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60742bf42220a.png?v=1",
    address: "Kammerdorfer Straße 16, 93413, Cham",
    telephone: "+49 9971 80880",
    website: "http://www.rw-energy.com",
    email: "info@raedlinger-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-hornig?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10551&utm_campaign=profiles_installer",
    company_name: "Photovoltaikzentrum Hornig GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10551n.gif?v=1",
    address: "Kellerfeld 1, 91604, Flachslanden",
    telephone: "+49 9829 9329290",
    website: "http://www.photovoltaik-hornig.de",
    email: "info@photovoltaik-hornig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/se-consulting?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6650&utm_campaign=profiles_installer",
    company_name: "SE Consulting GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6650n.gif?v=1",
    address: "Im Dörfle 11, D-79400 Kandern",
    telephone: "+49 7626 6844",
    website: "http://www.se-consulting.de",
    email: "info(a)se-consulting. de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/volthaus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11855&utm_campaign=profiles_installer",
    company_name: "Volthaus GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51667f96e7aec.gif?v=1",
    address: "Am Hang 2a, 84048 Mainburg",
    telephone: "+49 8751 70780",
    website: "http://www.volthaus.com",
    email: "info(@volthaus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 20072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/naturenergieladen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9957&utm_campaign=profiles_installer",
    company_name: "NaturEnergieLaden GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/548809db64d3f.jpg?v=1",
    address: "Von-Guericke-Strase 2a, 38350 Helmstedt",
    telephone: "+49 5351 399399",
    website: "http://www.naturenergieladen.de",
    email: "info.he@naturenergieladen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,IBC Solar AG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/oko-sun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75183&utm_campaign=profiles_installer",
    company_name: "ÖKO-SUN Solarfachhandel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bd40b0e2a8b.jpg?v=1",
    address: "Villa Baben 1, 39596 Eichstedt OT Baben",
    telephone: "+49 3 938828224",
    website: "http://www.oeko-sun.de",
    email: "info-oekosun@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/georg-fehl-sohn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1999&utm_campaign=profiles_installer",
    company_name: "Georg Fehl & Sohn GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58d1f4462a0ef.JPG?v=1",
    address: "Auf dem Brühl 4-6, 36399 Freiensteinau",
    telephone: "+49 6666 96010",
    website: "http://www.fehlundsohn.de",
    email: "info-freiensteinau@fehlundsohn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bysolar-hans-jurgen-brikey?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92073&utm_campaign=profiles_installer",
    company_name: "Bysolar Hans-Jürgen Brikey GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fc32f3a773.jpg?v=1",
    address: "Mörikestraße 7, 27478 Cuxhaven",
    telephone: "+49 4723 500282",
    website: "https://www.solarexperte.eu",
    email: "info@bysolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/adg-abdichtungs-und-dachtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81743&utm_campaign=profiles_installer",
    company_name: "ADG Abdichtungs- und Dachtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5895351e1f119.jpg?v=1",
    address: "Angerstraße 13-15, 04177 Leipzig",
    telephone: "+49 341 6994136",
    website: "http://www.info-adg.com",
    email: "info-adg@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-hanebut?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9966&utm_campaign=profiles_installer",
    company_name: "Solartechnik Hanebut",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51906dfa65f78.gif?v=1",
    address: "Kirchhofsallee 23, 27404 Zeven",
    telephone: "+49 4281 9518980",
    website: "https://www.solarmontagen.com",
    email: "info @ solarmontagen.com",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-confirm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93500&utm_campaign=profiles_installer",
    company_name: "Solar Confirm",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf7a67a8aad.jpg?v=1",
    address: "Grüner Grund 20, 49536 Lienen",
    telephone: "+49 5484 963348",
    website: "http://www.solarconfirm.de",
    email: "info @ solarconfirm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Solar Fabrik GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,AXITEC Energy GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ega-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92215&utm_campaign=profiles_installer",
    company_name: "EGA Dohma",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4e2c270b1e.jpg?v=1",
    address: "Maxim-Gorki-Str. 17, 01796 Pirna",
    telephone: "+49 3501 442296",
    website: "http://ega-dohma.de",
    email: "info @ ega-dohma.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bedachungen-burmann-weller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91988&utm_campaign=profiles_installer",
    company_name: "Bedachungen Burmann / Weller GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e7e7823428.jpg?v=1",
    address: "Germaniastr. 63, 44379 Dortmund",
    telephone: "+49 231 613073",
    website: "http://www.bedachungen-burmann.de",
    email: "info @ bedachungen-burmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/soflatech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124473&utm_campaign=profiles_installer",
    company_name: "Soflatech GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb662a441110.jpg?v=1",
    address: "Ikarusallee 16, 30179 Hanover",
    telephone: "+49 511 96496255",
    website: "https://soflatech.de",
    email: "info@soflatech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/schramm-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75885&utm_campaign=profiles_installer",
    company_name: "Schramm Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdd022c3188d.png?v=1",
    address: "Haardstr. 71c, 97720 Nüdlingen",
    telephone: "+49 971 64569",
    website: "http://www.schramm-solar.de",
    email: "info@schramm-solar.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/renoc-warme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93739&utm_campaign=profiles_installer",
    company_name: "Renoc Wärme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c36aa3b6482.jpg?v=1",
    address: "Am Spring 11, 03246 Crinitz",
    telephone: "+49 35324 789010",
    website: "https://renoc.de",
    email: "info@renoc.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/cms-green-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=5447&utm_campaign=profiles_installer",
    company_name: "CMS Green Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575e3504517c4.jpg?v=1",
    address: "Am Rennplatz 6a, 41334 Nettetal",
    telephone: "+49 2157 1244931",
    website: "http://www.cmsgreenenergy.com",
    email: "info@cmsgreenenergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Deutschland, Frankreich, Großbritannien, Niederlande,Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ses-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102219&utm_campaign=profiles_installer",
    company_name: "SES Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab1d30088e2e.jpg?v=1",
    address: "Bademer Straße 2, 54634 Metterich",
    telephone: "+49 6565 9566848",
    website: "http://www.ses-gebaeudetechnik.de",
    email: "info (@) ses-gebaeudetechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-tech-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75281&utm_campaign=profiles_installer",
    company_name: "S-Tech-Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bf94e007588.jpg?v=1",
    address: "Gewerbestraße 7, 84543 Winhöring",
    telephone: "+49 8671 886320",
    website: "http://www.s-tech-energie.de",
    email: "impressum@s-tech-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "",
    "Lieferanten von Solarmodulen":
      ",KIOTO Solar,,,Trina Solar Limited,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten":
      ",KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/marc-mathias-henle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=113333&utm_campaign=profiles_installer",
    company_name: "Marc Mathias Henle GmbH + Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c9d8611977ff.jpg?v=1",
    address: "Dietenheimer Straße 7, 89257 Illertissen",
    telephone: "+49 7303 96330",
    website: "https://henlebau.de",
    email: "immo@henlebau.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Trina Solar Limited,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/humer-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92807&utm_campaign=profiles_installer",
    company_name: "Hümer Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c3bfbbbcdf7c.png?v=1",
    address: "Geislinger Str. 6, 73485, Unterschneidheim",
    telephone: "+49 7966 316583",
    website: "http://huemersolar.de",
    email: "huemersolar@online.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eepro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=27863&utm_campaign=profiles_installer",
    company_name: "Erneuerbare Energie Projecktentwicklung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601ab43b6f307.png?v=1",
    address: "Marktplatz 41, 94436, Simbach b. Landau",
    telephone: "+49 9954 7001930",
    website: "http://www.eepro.de",
    email: "http://www.eepro.de",
    "Installaliert seit": 2007,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Bulgarien, Tschechische, Deutschland, Spanien, Italien, Südafrika, Slovakei, Rumänien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tbm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93267&utm_campaign=profiles_installer",
    company_name: "TBM GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ba02b59f694.jpg?v=1",
    address: "Dieselstraße 5, 74594 Kressberg-Bergbronn",
    telephone: "+49 7957 924900",
    website: "http://tbm-beck.de",
    email: "http://tbm-beck.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/holger-teegen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92446&utm_campaign=profiles_installer",
    company_name: "Holger Teegen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8dc9d6acf1.jpg?v=1",
    address: "Barkauer Straße 56-58, 24145, Kiel",
    telephone: "+49 431 2404580",
    website: "http://www.holgerteegen.de",
    email: "ht@holgerteegen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bader-heizungen-sasse?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1727&utm_campaign=profiles_installer",
    company_name: "Bäder & Heizungen Sasse GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5719bf3448c12.jpg?v=1",
    address: "Vogelsdorfer Straße 103, 15569 Woltersdorf",
    telephone: "+49 3362 500394",
    website: "http://www.sasse-bad.de",
    email: "homepage@sasse-bad.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/homburger-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11090&utm_campaign=profiles_installer",
    company_name: "Homburger Bedachungs GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11090n.gif?v=1",
    address: "Lessingstraße 4, 66424, Homburg",
    telephone: "+49 6841 67064",
    website: "http://www.homburger-bedachung.de",
    email: "homburger.bedachung@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/r-g-schmalzl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11065&utm_campaign=profiles_installer",
    company_name: "R & G Schmalzl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/587c6cffb0913.jpg?v=1",
    address: "Lindenhofstraße 6 1/2, 88145, Schwarzenberg",
    telephone: "+49 7522 6159",
    website: "http://www.holzbau-schmalzl.de",
    email: "holzbau.schmalzl@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-denz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116999&utm_campaign=profiles_installer",
    company_name: "Zimmerei Denz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3ff79b34ca3.png?v=1",
    address: "Oberwihl 24, D-79737 Görwihl",
    telephone: "+49 7754 7230",
    website: "https://www.zimmereidenz.de",
    email: "holz@zimmereidenz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-nordmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93911&utm_campaign=profiles_installer",
    company_name: "Zimmerei Nordmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87970e8a12.jpg?v=1",
    address: "Gewerbepark Pirgo I - 8, 26169 Friesoythe",
    telephone: "+49 4491 938178",
    website: "https://www.zimmerei-nordmann.de",
    email: "holz@zimmerei-nordmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-bischoff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11865&utm_campaign=profiles_installer",
    company_name: "Zimmerei Michael Bischoff und Michael Bischoff GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5434e1251c6db.jpg?v=1",
    address: "Am Zuschlag 4 - 6, 21769, Armstorf",
    telephone: "+49 4773 894057",
    website: "http://www.zimmerei-bischoff.de",
    email: "holz@zimmerei-bischoff.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/suntech-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81579&utm_campaign=profiles_installer",
    company_name: "SunTech-ElektroTeam Holger Schmidt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58855872a1412.jpg?v=1",
    address: "OT Kleinfahner, Ziehor 1, 99100 Gierstädt",
    telephone: "+49 36206 20550",
    website: "http://www.suntech-elektro.de",
    email: "holger.schmidt@suntech-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hls-tubbesing?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92443&utm_campaign=profiles_installer",
    company_name: "HLS Tubbesing GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8d7b3d9dc6.jpg?v=1",
    address: "Hansastr. 83, 44866, Bochum",
    telephone: "+49 2327 15356",
    website: "http://www.hls-tubbesing.de",
    email: "hls-tubbesing@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/alpenwatt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102358&utm_campaign=profiles_installer",
    company_name: "Alpenwatt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b2c810320.png?v=1",
    address: "Bruch 11a, D-83404 Ainring",
    telephone: "+49 8654 4803507",
    website: "http://www.alpenwatt.de",
    email: "hirner@alpenwatt.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hierner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92442&utm_campaign=profiles_installer",
    company_name: "Hierner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8d5c5e35c9.jpg?v=1",
    address: "Trausnitzstrasse 8, 81671, München",
    telephone: "+49 89 4506410",
    website: "http://www.hierner.de",
    email: "hierner@hierner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heyde-windtechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83159&utm_campaign=profiles_installer",
    company_name: "Heyde Windtechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/607d95534a761.PNG?v=1",
    address: "Glashütter Straße 60, 01744 Dippoldiswalde",
    telephone: "+49 3504 611280",
    website: "http://heyde-windtechnik.de",
    email: "heyde-windtechnik@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-heuer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10530&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Uwe Heuer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55cdad5170abd.jpg?v=1",
    address: "Am Rehmanger 11, 38304 Wolfenbüttel",
    telephone: "+49 5331 41261",
    website: "http://www.dachdecker-heuer.de",
    email: "heuer@dachdecker-heuer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/axel-hertwich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94466&utm_campaign=profiles_installer",
    company_name: "Axel Hertwich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc43dea448b.jpg?v=1",
    address: "Alte Dorfstr. 16, D-38448 Wolfsburg",
    telephone: "+49 5361 655771",
    website: "http://www.hertwich-dach.de",
    email: "hertwich@hertwich-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/henschel-bonig-dachdeckerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10647&utm_campaign=profiles_installer",
    company_name: "Henschel + Bönig Dachdeckerei GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10647n.gif?v=1",
    address: "Hamburger Chaussee 198, 24113, Kiel",
    telephone: "+49 431 8888885",
    website: "http://www.henschel-bönig.de",
    email: "henschel-boenig@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/helmut-kampmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75567&utm_campaign=profiles_installer",
    company_name: "Helmut Kampmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c91e2bc34c5.jpg?v=1",
    address: "Jagstheimer Hauptstr. 123, 74564 Crailsheim-Jagstheim",
    telephone: "+49 7951 6001",
    website: "http://www.helmutkampmann.de",
    email: "helmutkampmann@t-online.de",
    "Installaliert seit": 1994,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bau-solar-sud-west?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102694&utm_campaign=profiles_installer",
    company_name: "Bau Solar Süd-West GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9a99c3e17a.jpg?v=1",
    address: "Mühläcker 9, D-76768 Berg",
    telephone: "+49 7277 796910",
    website: "http://www.bau-solar.de",
    email: "helmut.rieger@bau-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,Suntech Power Co., Ltd.,,,Sharp Energy Solutions Europe,,,ReneSola Ltd.,,,AXITEC Energy GmbH & Co. KG,,,BYD Company Limited,,,Solar Swiss GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,Fronius International GmbH,,,Siemens Energy,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-system-berater-norbert-helm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1922&utm_campaign=profiles_installer",
    company_name: "Solar-System-Berater Norbert Helm",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c11bb53041e.gif?v=1",
    address: "Schwabenallee 43, 12683, Berlin-Biesdorf-Süd",
    telephone: "+49 30 5142502",
    website: "http://www.solar-systeme.de",
    email: "helm@solar-systeme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/redavia?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=79061&utm_campaign=profiles_installer",
    company_name: "Redavia GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58452227ca945.jpg?v=1",
    address: "Fürstenstraße 15, 80333 Munich",
    telephone: "+49 89 24268869",
    website: "http://www.redaviasolar.com",
    email: "hello@redaviasolar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Ghana, Kenia,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/galicium-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10319&utm_campaign=profiles_installer",
    company_name: "Galicium Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10319n.gif?v=1",
    address: "Belthlestrasse 11, 72070, Tübingen",
    telephone: "+49 7071 772484",
    website: "http://www.galicium.de",
    email: "hello@galicium.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Italien,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,SunPower Corp.,,,Trina Solar Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heiwe-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10348&utm_campaign=profiles_installer",
    company_name: "HEIWE Heizung Sanitär GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10348n.gif?v=1",
    address: "Norderstraße 4, 25855 Haselund",
    telephone: "+49 4843 2040080",
    website: "http://www.heiwe.de",
    email: "heizung@heiwe.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heizungsbau-nuszlein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107429&utm_campaign=profiles_installer",
    company_name: "Heizungsbau Nüßlein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b7bbcd91fc5c.png?v=1",
    address: "Fränkische-Schweiz-Str. 15, 96110 Scheßlitz",
    telephone: "+49 9542 921035",
    website: "https://www.heizung-nuesslein.de",
    email: "heizung-nuesslein@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-heiko-muuss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93917&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Heiko Muuss GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87ca7d0da8.jpg?v=1",
    address:
      "Albert-Einstein-Straße 16, 23617 Stockelsdorf, Schleswig-Holstein",
    telephone: "+49 451 4988588",
    website: "http://www.dachdeckerei-muuss.de",
    email: "heikomuuss@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-udo-vierath?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75235&utm_campaign=profiles_installer",
    company_name: "Haustechnik Udo Vierath",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be6f8ea57a0.jpg?v=1",
    address: "Parkstraße 13, OT Frankenfelde, 16269 Wriezen",
    telephone: "+49 33456 70789",
    website: "http://www.solartechnik-vierath.de",
    email: "Haustechnik-Vierath@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gussek-haus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=114872&utm_campaign=profiles_installer",
    company_name: "Gussek Haus Franz Gussek GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ce785999aef0.jpg?v=1",
    address: "Euregiostraße 7, 48527, Nordhorn",
    telephone: "+49 5921 1740",
    website: "https://www.gussek-haus.de",
    email: "hausinfo@gussek.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-schulz-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10655&utm_campaign=profiles_installer",
    company_name: "Schulz e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10655n.gif?v=1",
    address: "Bismarckstrasse 24, 24768 Rendsburg",
    telephone: "+49 4331 24907",
    website: "http://www.schulz-rd.de",
    email: "harms@schulz-rd.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Großbritannien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/etm-solarservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131779&utm_campaign=profiles_installer",
    company_name: "etm Solarservice",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6074f4aecc6fd.jpg?v=1",
    address: "Fuhrmannsgasse 3, 79108 Freiburg",
    telephone: "+49 7665 9739730",
    website: "http://www.etm-solarservice.de",
    email: "handel@etm-solarservice.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,Astronergy Co., Ltd. (Chint Solar),,,Hyundai Energy Solutions,,,LONGi Solar Technology Co., Ltd.,,,Phono Solar Technology Co., Ltd.,,,AU Optronics Corporation,,,GCL System Integration Technology Co., Ltd.,,,LG Electronics, Inc.,,,CS Wismar GmbH Sonnenstromfabrik,,,Sun Power Systems,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,Delta Energy Systems (Germany) GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,,,AEconversion GmbH & Co. KG,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hafner-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94731&utm_campaign=profiles_installer",
    company_name: "Häfner Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dddc204e725.png?v=1",
    address: "Industriestraße 1C, 97483 Eltmann",
    telephone: "+49 9522 3043770",
    website: "http://www.haefner-elektro.de",
    email: "HaefnerET@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hermann-semrau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92654&utm_campaign=profiles_installer",
    company_name: "Hermann Semrau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae222557726.jpg?v=1",
    address: "Kalscheurer Weg 31, 50969, Köln",
    telephone: "+49 221 362061",
    website: "http://hermann-semrau-gmbh.de/",
    email: "h.semrau-gmbh@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarbau-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31587&utm_campaign=profiles_installer",
    company_name: "SolarBau Mittelhessen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a595eb43968.png?v=1",
    address: "Alsfelderstraße 15a, 35279 Neustadt, Hessen",
    telephone: "+49 6428 605314",
    website: "https://solarbau-mh.de",
    email: "h.ortmann@solarbau-mh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gutjahr-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81567&utm_campaign=profiles_installer",
    company_name: "Gutjahr Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588473da69e33.jpg?v=1",
    address: "Marie Curie Str.23, 40764 Langenfeld",
    telephone: "+49 2173 978949",
    website: "http://www.gutjahrsolartechnik.de",
    email: "gutjahr.solartechnik@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solter-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75997&utm_campaign=profiles_installer",
    company_name: "SolTer-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d612bbdc4e7.jpg?v=1",
    address: "Platanenstraße 9, 01129 Dresden",
    telephone: "+49 172 7611728",
    website: "http://solter-solar.de",
    email: "gunter.karl@solter-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elekto-guttner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94021&utm_campaign=profiles_installer",
    company_name: "Elekto Güttner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e65fc165b504.jpg?v=1",
    address: "Turmweg 10, 04668 Grimma",
    telephone: "+49 3437 918025",
    website: "http://www.elektro-güttner.de",
    email: "guettner-hausgeraete@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/regiowatt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7331&utm_campaign=profiles_installer",
    company_name: "regioWatt GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7331n.gif?v=1",
    address: "Nelkenweg 26, 33378 Rheda-Wiedenbrück",
    telephone: "+49 5242 189158",
    website: "http://www.regiowatt.de",
    email: "groenke@regioWatt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hannes-hoppner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103542&utm_campaign=profiles_installer",
    company_name: "Hannes Höppner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebc40acb069.jpg?v=1",
    address: "Lupinenweg 23, 82194 Gröbenzell",
    telephone: "+49 171 6976984",
    website: "http://hoeppner-energie.de",
    email: "Groebenzell hallo@hoeppner-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/greiner-elektro-und-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105970&utm_campaign=profiles_installer",
    company_name: "Greiner Elektro- und Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b444d78497db.png?v=1",
    address: "Neuenreuther Str. 4, 95482 Gefrees",
    telephone: "+49 9254 9619725",
    website: "http://www.greiner-elektro-solar.de",
    email: "greiner.j@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tichai?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74867&utm_campaign=profiles_installer",
    company_name: "Tichai GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b54e95c752a.jpg?v=1",
    address: "Rödinghauser Straße 126, 32257 Bünde",
    telephone: "+49 5223 7926088",
    website: "http://www.tichai-gmbh.de",
    email: "gmbh@tichai.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gk-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75547&utm_campaign=profiles_installer",
    company_name: "GK Solar Photovoltaikanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c7cdba3d6c5.jpg?v=1",
    address: "Vahlhauser Höhe 3, 32760 Detmold",
    telephone: "+49 5231 3025630",
    website: "http://www.gksolar.de",
    email: "gksolar@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,,,JA Solar Holdings Co., Ltd.,,,Suntech Power Co., Ltd.,,,Trina Solar Limited,,,Jinko Solar Holding Co., Ltd.,,,AU Optronics Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-schwedt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102352&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Schwedt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9af690235b.png?v=1",
    address: "Heinersdorfer Damm 55-57, 16303 Schwedt",
    telephone: "+49 3332 4490",
    website: "https://www.stadtwerke-schwedt.de",
    email: "geschaeftsfuehrung@stadtwerke-schwedt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gaspar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82502&utm_campaign=profiles_installer",
    company_name: "Gaspar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58acfc9a09fec.jpg?v=1",
    address: "Postfach 51 09 20, 50945 Cologne",
    telephone: "+49 2 21937310",
    website: "http://gaspar-energie.de",
    email: "gaspar@rhenag. de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-zager?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10720&utm_campaign=profiles_installer",
    company_name: "Gerhard Zager GmbH, Dachdeckermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10720n.gif?v=1",
    address: "Alte Landstrasse 217-219, 51373 Leverkusen",
    telephone: "+49 214 62755",
    website: "http://www.zager-gmbh.de",
    email: "g.zager@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heg-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94817&utm_campaign=profiles_installer",
    company_name: "HEG Energie GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df05dd8149b.jpg?v=1",
    address: "Lauterbach 19, 91608 Geslau",
    telephone: "+49 9867 97892970",
    website: "https://www.heg-meinkraftwerk.de",
    email: "g.heidingsfelder@heg-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/abdichttechnik-bedachungen-friedrich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81963&utm_campaign=profiles_installer",
    company_name: "Z. Friedrich Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589be04529eba.jpg?v=1",
    address: "Königsberger Allee 91, 47058 Duisburg",
    telephone: "+49 203 770297",
    website: "http://www.friedrich-dach.de",
    email: "friedrichdach@googlemail.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bergmann-dach-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1938&utm_campaign=profiles_installer",
    company_name: "Bergmann Dach & Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575f54c08328e.jpg?v=1",
    address: "Altleubnitz 34, D-01219 Dresden",
    telephone: "+49 351 4706571",
    website: "http://www.bergmann-dach.de",
    email: "frank@bergmann-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Eoplly New Energy Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Nedap Energy Systems,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-und-solar-matthias-fischer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1906&utm_campaign=profiles_installer",
    company_name: "Elektro und Solar Matthias Fischer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575f7450e6cfd.jpg?v=1",
    address: "Veteranenstraße 3, 01139 Dresden",
    telephone: "+49 351 8488759",
    website: "http://www.elektro-solar.de",
    email: "fischer@elektro-solar.de",
    "Installaliert seit": 1992,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,SolarWorld Industries GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/korner-korner-bedachungsgesellschaft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10426&utm_campaign=profiles_installer",
    company_name: "Körner+Körner Bedachungsgesellschaft mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d27fb073b2a0.jpg?v=1",
    address: "Prinzenstraße 174, 53175, Bonn",
    telephone: "+49 228 315348",
    website: "https://www.koerner-koerner.de",
    email: "firma@koerner-koerner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/t-kleczka-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93046&utm_campaign=profiles_installer",
    company_name: "T. Kleczka Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b604325c68e.jpg?v=1",
    address: "Schütte-Lanz-Str. 10, 68782 Brühl",
    telephone: "+49 6202 4094334",
    website: "http://www.kleczka-haustechnik.de",
    email: "firma@kleczka-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hamann-elektromontage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94831&utm_campaign=profiles_installer",
    company_name: "Hamann Elektromontage GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c35b6b317fb5.jpg?v=1",
    address: "Zur feuerwache 3, 15890 Eisenhuttenstadt",
    telephone: "+49 3364 413204",
    website: "http://elektrohamann.de",
    email: "firma@elektrohamann.de",
    "Installaliert seit": 2012,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/techno-solar-solaranlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=600&utm_campaign=profiles_installer",
    company_name: "Techno Solar - Solaranlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/504d617f67593.png?v=1",
    address: "Am Studio 6, 12489 Berlin",
    telephone: "+49 30 678179900",
    website: "http://www.technosolar.de",
    email: "f.meletzki@technosolar.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,MaxxSun Solar Pvt., Ltd.,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solardach-24-de?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10297&utm_campaign=profiles_installer",
    company_name: "Solardach 24",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5594afc658462.jpg?v=1",
    address: "Dr.-Hans-Ehard-Str. 15, 96049, Bamberg",
    telephone: "+49 951 5090600",
    website: "http://www.solardach24.de",
    email: "f.griessl@solardach24.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Trina Solar Limited,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/maxmar-montagebau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102390&utm_campaign=profiles_installer",
    company_name: "Maxmar-montagebau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9ddac5d2c8.png?v=1",
    address: "Freihorstfeld 72, 30559 Hannover",
    telephone: "+49 511 30079357",
    website: "http://www.maxmar-montagebau.de",
    email: "eva-westmann@maxmar-montagebau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/etm-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105995&utm_campaign=profiles_installer",
    company_name: "ETM - Elektrotechnik Mayer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4573c3b0ed1.png?v=1",
    address: "Gewerbegebiet 5a, 83569 Vogtareuth",
    telephone: "+49 8038 699536",
    website: "http://s401006885.website-start.de",
    email: "etm@etm-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/epperlein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=565&utm_campaign=profiles_installer",
    company_name: "Epperlein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52281c675c1e8.gif?v=1",
    address: "Rostocker Str. 50, 01587, Riesa",
    telephone: "+49 3525 65920",
    website: "http://www.epperlein-gmbh.de",
    email: "Epperlein-GmbH@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sowitec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=118997&utm_campaign=profiles_installer",
    company_name: "Sowitec",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d9d3e0db2a76.jpg?v=1",
    address: "Löherstraße 24, 72820 Sonnenbühl",
    telephone: "+49 7128 38080",
    website: "http://www.sowitec.com",
    email: "enrique.gomez@bitbasegroup.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enprovesolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41987&utm_campaign=profiles_installer",
    company_name: "Enprovesolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f8812955b9e1.PNG?v=1",
    address: "Fiedlerstraße 4, 01307 Dresden",
    telephone: "+49 351 41885990",
    website: "http://www.enprovement.com",
    email: "enprove@en-pm.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/public-sunpower-systems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58187&utm_campaign=profiles_installer",
    company_name: "Public SunPower Systems",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c41a67842c6.jpg?v=1",
    address: "In der Elb 12, 40229 Düsseldorf",
    telephone: "+49 171 3653205",
    website: "http://www.public-sunpower-systems.de",
    email: "engel@public-sunpower-systems.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/engcotec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1412&utm_campaign=profiles_installer",
    company_name: "Engcotec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4653.gif?v=1",
    address: "Buechsenstrass 15, D-70173 Stuttgart",
    telephone: "+49 711 2229676",
    website: "http://www.engcotec.de",
    email: "engcotec@engcotec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vispiron-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7644&utm_campaign=profiles_installer",
    company_name: "Vispiron Energy GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6092cc06b5d2d.png?v=1",
    address: "Joseph-Dollinger-Bogen 28, 80807 München",
    telephone: "+49 89 452450000",
    website: "https://www.vispiron.solar",
    email: "energy@vispiron.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: ",Vispiron Group,",
    "Last Update": 6052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-celle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94572&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Celle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd6cbb6416a.png?v=1",
    address: "Prinzengasse 1, 29221 Celle",
    telephone: "+49 5141 7095110",
    website: "https://stadtwerke-celle.de",
    email: "energievertrieb@stadtwerke-celle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/karl-meyer-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94679&utm_campaign=profiles_installer",
    company_name: "Karl Meyer Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddc37cd16ce.png?v=1",
    address: "Stader Straße 55-63, 21737 Wischhafen",
    telephone: "+49 4770 801555",
    website: "http://karl-meyer-energie.de",
    email: "energiesysteme@karl-meyer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Siemens Energy,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93513&utm_campaign=profiles_installer",
    company_name: "Mohr & Zimmermann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c06b71ed84f.jpg?v=1",
    address: "Rechwies 10, 66557 Illingen-Welschbach",
    telephone: "+49 6825 952233",
    website: "http://www.mz-handwerk.de",
    email: "energie-umwelttechnik@schlau.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SolarWorld Industries GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/m-rudorfer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95233&utm_campaign=profiles_installer",
    company_name: "M. Rudorfer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e99b50fb178c.png?v=1",
    address: "Am Bild 45, 97941 Tauberbischofsheim",
    telephone: "+49 9341 2324",
    website: "https://www.dach-rudorfer.de",
    email: "email@dach-rudorfer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eltron-elektro-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1703&utm_campaign=profiles_installer",
    company_name: "Eltron-Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4168.gif?v=1",
    address: "Poststraße 3, 04626, Schmölln",
    telephone: "+49 34 49156670",
    website: "http://www.eltron-schmoelln.de",
    email: "eltron-schmoelln@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tk-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1654&utm_campaign=profiles_installer",
    company_name: "TK-Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5719bac638971.jpg?v=1",
    address: "Maybachstraße 3, 73095 Albershausen",
    telephone: "+49 7161 987800",
    website: "http://www.tk-energietechnik.de",
    email: "elke.taxis@tk-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-thomas-keil?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93805&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Thomas Keil",
    logo_url: "",
    address: "Finkenweg 8c, 65835 Liederbach",
    telephone: "+49 6196 7685693",
    website: "http://www.elektro-tk.de",
    email: "ElektroTKeil@aol.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-lindlacher?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93617&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Lindlacher",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c1dcba14d11.jpg?v=1",
    address: "Mietenkamer Strasse 43, 83224 Grassau",
    telephone: "+49 8641 699905",
    website: "https://www.elektrotechnik-lindlacher.de",
    email: "elektrotechnik-lindlacher@freenet.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-fischer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93587&utm_campaign=profiles_installer",
    company_name: "Peter Fischer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c09391e1cd0.jpg?v=1",
    address: "Dieselstrasse 20, D-66763 Dillingen",
    telephone: "+49 6831 76810",
    website: "https://www.fischerdillingen.de",
    email: "elektromotoren@fischerdillingen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-mai-linden?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10589&utm_campaign=profiles_installer",
    company_name: "Elektro Mai",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe320fb3c033.png?v=1",
    address: "Lindener Dorfstr. 17, 98646, Straufhain",
    telephone: "+49 36875 69526",
    website: "http://www.elektro-mai-linden.de",
    email: "elektromailinden@aol.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-krenz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10342&utm_campaign=profiles_installer",
    company_name: "Elektro Krenz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5767420d86ffc.jpg?v=1",
    address: "Handelsstrasse 24, 69214, Eppelheim",
    telephone: "+49 6221 760030",
    website: "http://www.elektro-krenz.de",
    email: "elektrokrenz@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-elfrich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93362&utm_campaign=profiles_installer",
    company_name: "Elektro Elfrich GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bb5615acc12.jpg?v=1",
    address: "Emsstrasse 22, 48282 Emsdetten",
    telephone: "+49 2572 953320",
    website: "http://www.elektro-elfrich.de",
    email: "elektroelfrich@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-rainer-wertke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1731&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Rainer Wertke",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c11a3dafea0.gif?v=1",
    address: "Transvaalstrasse 19, 13351 Berlin",
    telephone: "+49 304 942030",
    website: "http://www.wertke.de",
    email: "elektro@wertke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/se?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93293&utm_campaign=profiles_installer",
    company_name: "Schulzendorfer Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60075bb5d9bb5.png?v=1",
    address: "Karl-Marx-Straße 14-16, 15732 Schulzendorf",
    telephone: "+49 33762 470",
    website: "http://segmbh.de",
    email: "elektro@segmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rumo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94028&utm_campaign=profiles_installer",
    company_name: "Rumo GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9e71f26ae8.jpg?v=1",
    address: "Langgasse 36, 35510 Butzbach",
    telephone: "+49 6033 7442871",
    website: "http://www.rumo-butzbach.de",
    email: "elektro@r-schmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kruckemeier-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93534&utm_campaign=profiles_installer",
    company_name: "Krückemeier Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c07b55c884a.jpg?v=1",
    address: "Dankerser Straße 169, 32423 Minden",
    telephone: "+49 571 387030",
    website: "https://krueckemeier.de",
    email: "elektro@krueckemeier.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kreutzpointner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81554&utm_campaign=profiles_installer",
    company_name: "Kreutzpointner Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5884695b3edbe.jpg?v=1",
    address: "Industriesiedlung 22, D-84140 Gangkofen",
    telephone: "+49 8722 9675070",
    website: "http://www.kreutzpointner.de",
    email: "elektro@kreutzpointner.de",
    "Installaliert seit": 2015,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Kreutzpointner Group,",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-imgenberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93808&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Imgenberg GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4a30648aaa.jpg?v=1",
    address: "Oststraße 28, 59065 Hamm",
    telephone: "+49 2381 22555",
    website: "http://www.imgenberg.de",
    email: "elektro@imgenberg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Honeywell International,",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hvt-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93655&utm_campaign=profiles_installer",
    company_name: "Hvt Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c2010d26a15.jpg?v=1",
    address: "Großbeerenstraße 139, D-14482 Potsdam",
    telephone: "+49 331 55099582",
    website: "http://www.hvt-elektro.de",
    email: "elektro@hvt-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-solartechnik-holzner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81746&utm_campaign=profiles_installer",
    company_name: "Elektro - Solartechnik Holzner GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58953f6969ff6.jpg?v=1",
    address: "Georg-Wiesböck-Ring 5, 83115 Neubeuern",
    telephone: "+49 8035 3003",
    website: "http://www.elektro-holzner.de",
    email: "elektro@elektro-holzner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Kyocera Corporation,,,Solar Frontier K.K.,,,Canadian Solar Inc.,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten": ",Siemens Energy,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-wichtrey?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94816&utm_campaign=profiles_installer",
    company_name: "Elektro Wichtrey GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df05d9373f4.png?v=1",
    address: "Biederbacher Str. 7, 91732 Merkendorf",
    telephone: "+49 9826 454",
    website: "http://www.elektro-wichtrey.de",
    email: "elektro.wichtrey@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrofachbetrieb-rehse?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=98099&utm_campaign=profiles_installer",
    company_name: "Elektrofachbetrieb Burkhard Rehse",
    logo_url: "",
    address: "Schulstraße 22, 39264 Lübs",
    telephone: "+49 39242 274",
    website: "http://www.elektro-rehse.de",
    email: "elektro.rehse@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hista-ohg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13747&utm_campaign=profiles_installer",
    company_name: "Hista OHG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/13747n.gif?v=1",
    address: "Stettiner Straße 10, 93073, Neutraubling",
    telephone: "+49 9401 92020",
    website: "http://www.hista.de",
    email: "elektro.gmbh@hista.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-glockner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105998&utm_campaign=profiles_installer",
    company_name: "Elektro Glockner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4576e21ddb3.png?v=1",
    address: "Schlatter Straße 12, 79189 Bad Krozingen",
    telephone: "+49 7633 3370",
    website: "https://www.elektro-glockner.de",
    email: "Elektro.Glockner@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-manfred-geiss?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106211&utm_campaign=profiles_installer",
    company_name: "Elektro Manfred Geiss",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4f0635aa14c.png?v=1",
    address: "Badische Straße 3, 55765 Birkenfeld",
    telephone: "+49 6782 6171",
    website: "https://www.elektrogeiss.com",
    email: "elektro.geiss@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",ABB Italy S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-berroth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=109464&utm_campaign=profiles_installer",
    company_name: "Elektro Berroth",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bd1331c451f0.jpg?v=1",
    address: "Wilhelmstraße 10, 89547 Gerstetten",
    telephone: "+49 7323 6129",
    website: "http://www.elektro-berroth.de",
    email: "Elektro.Berroth.GmbH@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-stark?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11859&utm_campaign=profiles_installer",
    company_name: "Elektro Stark",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576750a0bf616.jpg?v=1",
    address: "Ringstraße 16, 08538 Dehles",
    telephone: "+49 3743 55355",
    website: "http://www.elektro-stark.de",
    email: "elektro-stark@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-nordholz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64839&utm_campaign=profiles_installer",
    company_name: "Elektro Nordholz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/378150101.gif?v=1",
    address: "Heerde 41, 27245 Kirchdorf",
    telephone: "+49 4273 962678",
    website: "http://www.elektronordholz.wordpress.com",
    email: "elektro-nordholz@t.online.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kuhberg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106432&utm_campaign=profiles_installer",
    company_name: "Elektro-Kuhberg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b569f924b5ed.png?v=1",
    address: "Lerchensteig 36, 14469 Potsdam",
    telephone: "+49 331 504632",
    website: "https://elektro-kuhberg.de",
    email: "elektro-kuhberg@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hantzsche?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94656&utm_campaign=profiles_installer",
    company_name: "Elektro-Hantzsche GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddbab9abf28.png?v=1",
    address: "Kurzer Weg 3, 01477 Arnsdorf b. Dresden",
    telephone: "+49 35200 24526",
    website: "http://www.elektro-hantzsche.de",
    email: "elektro-hantzsche@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kluge?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11860&utm_campaign=profiles_installer",
    company_name: "Elektro Kluge GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4c363149e44.png?v=1",
    address: "Dorfstraße 244, 09306, Königshain",
    telephone: "+49 37202 2479",
    website: "http://www.elektrokluge.de",
    email: "ekk@elektrokluge.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kramer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94726&utm_campaign=profiles_installer",
    company_name: "Elektro-Krämer Jürgen Krämer e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddd8e2716f2.png?v=1",
    address: "Seyfferstraße 78A, 70197 Stuttgart",
    telephone: "+49 711 636020",
    website: "https://elektrokraemer.de",
    email: "ek@elektrokraemer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/brune-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92214&utm_campaign=profiles_installer",
    company_name: "Brüne Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a51ba740c81.jpg?v=1",
    address: "Am Rathaus 8, 34513 Waldeck",
    telephone: "+49 5634 99440430",
    website: "http://www.ee-bruene.de",
    email: "ee-bruene@elektro-bruene.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eak?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105999&utm_campaign=profiles_installer",
    company_name: "Elektro-Anlagen Kadner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b4577e4a75a9.png?v=1",
    address: "Lohmener Str. 12B, 01796 Pirna",
    telephone: "+49 3501 523548",
    website: "https://www.eak-pirna.de",
    email: "eak-kadner@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-buchen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103531&utm_campaign=profiles_installer",
    company_name: "Elektro Buchen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebbfc688f3b.jpg?v=1",
    address: "Kettelerstraße 27, 57572 Niederfischbach",
    telephone: "+49 2734 60693",
    website: "https://www.elektro-buchen.de",
    email: "ea@elektro-adelt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/storkel-holzhaus-bedachungs?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93984&utm_campaign=profiles_installer",
    company_name: "Störkel Holzhaus & Bedachungs GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9adfa9a0a7.jpg?v=1",
    address: "Obernhainer Weg, 61273 Wehrheim",
    telephone: "+49 6081 59965",
    website: "http://www.stoerkel-holzhaus.de",
    email: "e.stoerkel@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/segmihler-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94174&utm_campaign=profiles_installer",
    company_name: "Segmihler Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cca762240f7.png?v=1",
    address: "Bahnhofstraße 82, 82383 Hohenpeißenberg",
    telephone: "+49 8805 8171",
    website: "http://www.segmihler-elektrotechnik.de",
    email: "email@segmihler-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dzillum?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92197&utm_campaign=profiles_installer",
    company_name: "Dzillum GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4ce28492d5.jpg?v=1",
    address: "Jahnstraße 90b, 59192 Bergkamen-Oberaden",
    telephone: "+49 2306 80276",
    website: "http://www.dzillum-gmbh.de",
    email: "dzillum-gmbh@gswcom.biz",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/baywa-r-e?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=26967&utm_campaign=profiles_installer",
    company_name: "BayWa r.e. Renewable Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51abf7b3a33a3.jpg?v=1",
    address: "Arabellastraße 4, 81925, Munich",
    telephone: "+49 89 3839320",
    website: "https://www.baywa-re.com",
    email: "dominic.rheinwald@baywa-re.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Trina Solar Limited,,,Panasonic Corporation (Sanyo),,,Phono Solar Technology Co., Ltd.,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Enphase Energy, Inc.,,,Delta Energy Systems (Germany) GmbH,,,SolarEdge Technologies, Ltd.,,,Shenzhen SOFARSOLAR Co., Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: ",BayWa AG,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/am-lichtstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102578&utm_campaign=profiles_installer",
    company_name: "AM lichtstrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac44373bd6fb.png?v=1",
    address: "Spechtstraße 13, 84130 Dingolfing",
    telephone: "+49 8731 8649078",
    website: "https://www.amlichtstrom.de",
    email: "info@amlichtstrom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/asw-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103278&utm_campaign=profiles_installer",
    company_name: "ASW - Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ae164f941078.png?v=1",
    address: "August-Bebel-Str. 27, 06526 Sangerhausen",
    telephone: "+49 176 20691907",
    website: "http://asw-solar.de",
    email: "info@asw-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dieter-pohl-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11080&utm_campaign=profiles_installer",
    company_name: "Dieter Pohl Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11080n.gif?v=1",
    address: "Carl-Benz-Straße 8, 66773 Schwalbach",
    telephone: "+49 6834 51687",
    website: "http://www.pohl-dieter.de",
    email: "dieter_pohl_gmbh@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dacher-von-schnell?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101764&utm_campaign=profiles_installer",
    company_name: "Dächer von Schnell GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa5fbf48c67f.jpg?v=1",
    address: "Stedinger Str. 26, 26723 Emden",
    telephone: "+49 4921 24838",
    website: "http://daecher-von-schnell.de",
    email: "dialog@daecher-von-schnell.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dgs-berlin-brandenburg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56297&utm_campaign=profiles_installer",
    company_name:
      "Deutsche Gesellschaft für Sonnenenergie Landesverband Berlin Brandenburg e.V.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60094a7eac31a.png?v=1",
    address: "Erich-Steinfurth-Str. 8, 10243 Berlin",
    telephone: "+49 30 29381260",
    website: "http://www.dgs-berlin.de",
    email: "dgs@​dgs-berlin.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rolf-wendelmuth-dachdecker?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94635&utm_campaign=profiles_installer",
    company_name: "Rolf Wendelmuth Dachdecker GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb23e82ffa.png?v=1",
    address: "Untere Gasse 61, 99448 Rittersdorf",
    telephone: "+49 36450 31125",
    website: "http://www.rolfwendelmuth-dachdeckergmbh.de",
    email: "ddmrw@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/albrecht-bedachung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92184&utm_campaign=profiles_installer",
    company_name: "Albrecht Bedachung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4bfc593e43.jpg?v=1",
    address: "Am Kuhlenkamp 44, 44795 Bochum",
    telephone: "+49 234 79466710",
    website: "http://www.ddmalbrecht.de",
    email: "ddmalbrecht@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-ingo-lorenz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95057&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Ingo Lorenz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601417b447b27.png?v=1",
    address: "Rackeler Dorfstraße 18, 02694 Malschwitz",
    telephone: "+49 35932 31263",
    website: "http://www.dachdeckermeister-lorenz.de",
    email: "ddm-lorenz@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaranlagenprofis-dankert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74425&utm_campaign=profiles_installer",
    company_name: "Solaranlagenprofis Dankert GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a50f916cb91.PNG?v=1",
    address: "Bahnhofstraße 1, 29386 Hankensbüttel",
    telephone: "+49 5832 970303",
    website: "http://www.solaranlagenprofis.de",
    email: "dd@solaranlagenprofis.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Yingli Green Energy Holding Co., Ltd.,,,Jiangxi LDK Solar High-Tech Co., Ltd,,,Trina Solar Limited,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,First Solar, Inc.,,,Zytech Solar,",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SMA Solar Technology AG,,,KACO New Energy GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachwerk-luneburg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92152&utm_campaign=profiles_installer",
    company_name: "Dachwerk Lüneburg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3c4a9d4269.jpg?v=1",
    address: "Nutzfelde 20, 21379 Scharnebeck, Nutzfelde",
    telephone: "+49 4136 343906",
    website: "http://www.dachdecker-lueneburg.de",
    email: "david.meier@dachwerk-lueneburg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-monch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94393&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Mönch GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db381298eec.png?v=1",
    address: "Rheinstraße 37, 64367 Mühltal",
    telephone: "+49 6151 5014265",
    website: "http://www.dachdeckerei-moench.de",
    email: "daniel.moench@dachdeckerei-moench.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachland?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=5661&utm_campaign=profiles_installer",
    company_name: "Dachland GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5661n.gif?v=1",
    address: "Athener Allee 2, 55129 Mainz",
    telephone: "+49 6131 959100",
    website: "http://www.dachland.de",
    email: "dachland@dachland.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-aljoscha-muller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94471&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Aljoscha Müller",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc45ec400cd.jpg?v=1",
    address: "Feldstraße 1a, 59269 Beckum",
    telephone: "+49 151 51728956",
    website: "https://www.dachdeckermeister-mueller.com",
    email: "dachdeckermeister-mueller@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/klaus-sydow?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92157&utm_campaign=profiles_installer",
    company_name: "Klaus Sydow",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3c90a6948d.jpg?v=1",
    address: "An der Kohlung 45, 09114 Chemnitz",
    telephone: "+49 371 428817",
    website: "http://www.dachdeckerei-sydow.de",
    email: "dachdeckerei.sydow@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-hartmut-fischer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95089&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Hartmut Fischer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85f154df6a.jpg?v=1",
    address: "Schulstraße 2a, 31789 Hameln",
    telephone: "+49 5151 406418",
    website: "http://www.dachdecker-fischer-hameln.de",
    email: "dachdeckerei-fischer@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-dorenhoff?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94360&utm_campaign=profiles_installer",
    company_name: "Dachdecker Dörenhoff",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dad4b59edbd.jpg?v=1",
    address: "Mersch 35, 48317 Drensteinfurt",
    telephone: "+49 2387 566",
    website: "http://www.dachdecker-doerenhoff.de",
    email: "dachdecker@doerenhoff.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-sell?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95214&utm_campaign=profiles_installer",
    company_name: "Dachdecker Sell GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f00675478f8.png?v=1",
    address: "Saarower Straße 32, 15526 Reichenwalde",
    telephone: "+49 33631 646987",
    website: "https://www.dachdecker-sell.de",
    email: "dachdecker.sell@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-klaus-wittenburg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94920&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Klaus Wittenburg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e03f4948ba2.png?v=1",
    address: "Gerberhof 7, 23936 Grevesmühlen",
    telephone: "+49 3881 2677",
    website: "http://www.dachdecker-wittenburg.de",
    email: "dachdecker-wittenburg@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-andreas-fuhrmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10662&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Andreas Fuhrmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10662n.gif?v=1",
    address: "Krögiser Weg 2, 01665, Triebischtal",
    telephone: "+49 35244 41344",
    website: "http://www.dachdecker-fuhrmann.de",
    email: "Dachdecker-Fuhrmann-Miltitz@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wierig-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64715&utm_campaign=profiles_installer",
    company_name: "Wierig Solar AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6058d2c74de82.PNG?v=1",
    address: "Lindenstraße 76, 53721 Siegburg",
    telephone: "+49 2241 255255",
    website: "http://www.wierig.de",
    email: "dach@wierig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: ",Wierig GmbH,",
    "Last Update": 23032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-solartechnik-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75589&utm_campaign=profiles_installer",
    company_name: "Dach & Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c8d54d78c6f.jpg?v=1",
    address: "Mittelweg 115, D-01844 Neustadt/Polenz",
    telephone: "+49 3596 509510",
    website: "http://www.dach-solartechnik.de",
    email: "dach.solartechnik@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/engelhardt-dach-wand?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102020&utm_campaign=profiles_installer",
    company_name: "Engelhardt Dach & Wand GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6dbc6c90f.jpg?v=1",
    address:
      "Am Bahndamm 2, Kalteneber, 37308, Heilbad Heiligenstadt, Ortsteil Kalteneber",
    telephone: "+49 36083 41193",
    website: "http://www.engelhardt-dach-wand.de",
    email: "dach-wand@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-detlef-petruck?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94492&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Detlef Petruck",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc6411a44c3.jpg?v=1",
    address: "Im Garten 5, 21354 Bleckede",
    telephone: "+49 5852 2572",
    website: "http://www.dachdeckerei-petruck.de",
    email: "d.petruck@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bode-stephan?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122759&utm_campaign=profiles_installer",
    company_name: "Bode & Stephan GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e65e2ccd73f0.jpg?v=1",
    address: "Herbert-Quandt-Str. 6, 37081, Göttingen Siekhöhe",
    telephone: "+49 551 508850",
    website: "https://photovoltaik.bode24.de",
    email: "d.hartung@bode-stephan.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Sungrow Power Supply Co., Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dew21?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107918&utm_campaign=profiles_installer",
    company_name: "Dortmunder Energie- und Wasserversorgung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88d426f29ba.jpg?v=1",
    address: "Günter-Samtlebe-Platz 1, 44135 Dortmund",
    telephone: "+49 231 5440",
    website: "https://www.dew21.de",
    email: "CUSTOMERS@DEW21.DE",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/cs-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81544&utm_campaign=profiles_installer",
    company_name: "CS-Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588458866b881.jpg?v=1",
    address: "Hofertalstraße 22, 66299 Friedrichsthal",
    telephone: "+49 170 2392417",
    website: "http://www.cs-bedachungen.de",
    email: "cs-bedachungen@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alternative-energieanlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1866&utm_campaign=profiles_installer",
    company_name: "Reinhard Hönig-Alternative Energieanlagen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1866n.gif?v=1",
    address: "Bergsiedlung 33a, 01108 Dresden-Weixdorf",
    telephone: "+49 351 8900298",
    website: "http://www.ra-waermepumpen.de",
    email: "cr.hoenig@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/xenia-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119818&utm_campaign=profiles_installer",
    company_name: "Xenia Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ddc8600a1fce.jpg?v=1",
    address: "Hitzestrasse 48, 46399 Bocholt",
    telephone: "+49 2871 2909507",
    website: "http://xenia-energy.com",
    email: "contact@xenia-energy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dannenberg-dach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95209&utm_campaign=profiles_installer",
    company_name: "Dannenberg Dach GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59f004a6bf715.png?v=1",
    address: "Hermann-Löns-Str. 25, 50181 Bedburg",
    telephone: "+49 2272 4155",
    website: "https://www.dannenberg-dach.de",
    email: "contact@wunsch-dach.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarsun24?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95725&utm_campaign=profiles_installer",
    company_name: "SolarSun24",
    logo_url: "https://cdn.enfsolar.com/ID/logo/467159fc9c1e5ae37.gif?v=1",
    address: "Segelfliegerdamm 89, Raum 416, D-12487 Berlin",
    telephone: "+49 170 7737141",
    website: "http://www.solarsun24.de",
    email: "contact@solarsun24.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernd-muhle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102691&utm_campaign=profiles_installer",
    company_name: "Bernd Muhle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9971f476e3.jpg?v=1",
    address: "Westerriede 1, 49424 Goldenstedt",
    telephone: "+49 4444 961220",
    website: "https://www.muhle.info",
    email: "contact@muhle.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/muller-dach-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93901&utm_campaign=profiles_installer",
    company_name: "Müller Dach- & Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c871835d47c.jpg?v=1",
    address: "Hohenstaufenstraße 2, D-69412 Eberbach",
    telephone: "+49 6271 2534",
    website: "http://www.muellerholzbau.de",
    email: "contact@muellerholzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kbms-consult?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81951&utm_campaign=profiles_installer",
    company_name: "KBMS Consult GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bd1bd14665.jpg?v=1",
    address: "Ober der Höhle 2, 37235 Hessisch Lichtenau",
    telephone: "+49 5602 9193063",
    website: "http://www.kbms-consult.de",
    email: "Contact@kbms-consult.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",SunPower Corp.,,,Kaneka Corporation,,,Sharp Energy Solutions Europe,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94977&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e0742f668fa.jpg?v=1",
    address: "Schillerstraße 40, 31812 Bad Pyrmont",
    telephone: "+49 5281 960092",
    website: "http://elektro-jungnik.de",
    email: "contact@jungnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektroanlagen-sperling?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1698&utm_campaign=profiles_installer",
    company_name: "Elektroanlagen Sperling",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5451d1979a3bb.jpg?v=1",
    address: "Dietrichsdorf, 18b, 06895, Zahna-Elster",
    telephone: "+49 34 92260887",
    website: "http://www.elektroanlagen-sperling.de",
    email: "contact@elektroanlagen-sperling.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Aleo Solar GmbH,,,Kyocera Corporation,,,Solar Frontier K.K.,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten":
      ",Ningbo Sunways Technologies Co., Ltd,,,SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dz-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92194&utm_campaign=profiles_installer",
    company_name: "DZ-4 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4cbf460d28.jpg?v=1",
    address: "Kühnehöfe 3, 22761 Hamburg",
    telephone: "+49 40 6505250110",
    website: "https://www.dz-4.de",
    email: "contact@dz-4.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dhybrid-power-systems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1658&utm_campaign=profiles_installer",
    company_name: "Dhybrid Power Systems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5865cd160f2b3.jpg?v=1",
    address: "Perchtinger Straße 1A, 82131 Gauting - Hausen",
    telephone: "+49 89 8994810",
    website: "http://www.dhybrid.de",
    email: "contact@dhybrid.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/deea?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9598&utm_campaign=profiles_installer",
    company_name: "Deea Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b0b50ed01c9f.png?v=1",
    address: "Kennedyallee 93, 60596 Frankfurt am Main",
    telephone: "+49 69 45000255",
    website: "http://www.deea-solutions.com",
    email: "contact@deea-solutions.com",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "VAE, Deutschland, Indonesien, Japan,Afrika,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/christian-butzbach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10722&utm_campaign=profiles_installer",
    company_name: "Christian Butzbach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe42aae459d4.png?v=1",
    address: "Dellbornerstraße 44, 66679, Losheim am See",
    telephone: "+49 6872 7640",
    website: "http://www.christian-butzbach.de",
    email: "christian_butzbach@web.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/chiemsee-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11118&utm_campaign=profiles_installer",
    company_name: "Chiemsee Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51cce97347b5b.gif?v=1",
    address: "Priener Straße 87, 83233, Bernau",
    telephone: "+49 8051 63877",
    website: "http://www.chiemsee-solar.de",
    email: "chiemsee-solar@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiekontor?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=109155&utm_campaign=profiles_installer",
    company_name: "Energiekontor AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bc842ca37a0a.jpg?v=1",
    address: "Mary-Somerville-Straße 5, 28359 Bremen",
    telephone: "+49 421 33040",
    website: "https://www.energiekontor.de",
    email: "cerstin.kratzsch@energiekontor.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/agro-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41259&utm_campaign=profiles_installer",
    company_name: "Agro Service GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52b52b5e9eccf.jpg?v=1",
    address: "Unter den Eichen 2, 59597 Erwitte Ebbinghausen",
    telephone: "+49 2945 2219",
    website: "http://www.sonne-nrw.de",
    email: "cd@sonne-nrw.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 2062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/krinner-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93855&utm_campaign=profiles_installer",
    company_name: "Krinner Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4c32cc0139.jpg?v=1",
    address: "Passauer Straße 55, D-94342 Straßkirchen",
    telephone: "+49 9424 940180",
    website: "http://www.krinner-solar.com",
    email: "carportsystems@krinner.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/c-reese?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95103&utm_campaign=profiles_installer",
    company_name: "C.Reese GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e9948340149.jpg?v=1",
    address: "Robert-Koch-Str. 7, 37603 Holzminden",
    telephone: "+49 5531 4532",
    website: "http://c-reese.de",
    email: "C.Reese-GmbH@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hth-haustechnik-hurtz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92462&utm_campaign=profiles_installer",
    company_name: "Haustechnik Hurtz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8f97f2a11c.jpg?v=1",
    address: "Bahnstr. 39-41, 41363 Jüchen",
    telephone: "+49 2165 871850",
    website: "http://www.haustechnik-hurtz.de",
    email: "buero@haustechnik-hurtz.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Viessmann Werke GmbH & Co. KG,,,Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-roland-friedrich?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122773&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Roland Friedrich",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e66e9bc006fe.jpg?v=1",
    address: "Am Dorfplatz 3a, 01705, Freital",
    telephone: "+49 351 6491225",
    website: "http://www.et-friedrich.de",
    email: "buero@et-friedrich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enwo?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94864&utm_campaign=profiles_installer",
    company_name: "EnWo GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df2c0ea6964.png?v=1",
    address: "Henkerberg 10, 88696 Owingen",
    telephone: "+49 7551 947120",
    website: "http://www.e-u-solar.eu",
    email: "buero@enwo.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-goll?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7295&utm_campaign=profiles_installer",
    company_name: "Elektro Goll GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7295n.gif?v=1",
    address: "Carl-Zeiss-Straße 2/ 1, 71229 Leonberg/Gebersheim",
    telephone: "+49 7152 997484",
    website: "http://www.elektro-goll.de",
    email: "buero@elektro-goll.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dragert-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102235&utm_campaign=profiles_installer",
    company_name: "Drägert Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab205bb1d30e.jpg?v=1",
    address: "Poppenbütteler Bogen 14-16, 22399 Hamburg",
    telephone: "+49 40 47110060",
    website: "https://draegert.de",
    email: "buero@draegert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bedachungen-jentges?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92172&utm_campaign=profiles_installer",
    company_name: "Bedachungen Jentges",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3dade91fa7.jpg?v=1",
    address: "Mühlenweg 67, 47839 Krefeld - Hüls",
    telephone: "+49 2151 734534",
    website: "http://www.bedachungen-jentges.de",
    email: "buero@bedachungen-jentges.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-gogolok?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94222&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Gogolok GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc1b5bec6d.jpg?v=1",
    address: "Dorfstraße 12, 93099 Schönach",
    telephone: "+49 9429 9499165",
    website: "http://www.elektrotechnik-gogolok.de",
    email: "buero @ elektrotechnik-gogolok .de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wilhelm-buermann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92070&utm_campaign=profiles_installer",
    company_name: "Wilhelm Buermann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5be3d6598a0ec.png?v=1",
    address: "Am Holunder 58a, 30459 Hannover",
    telephone: "+49 511 423833",
    website: "http://www.buermann-gmbh.de",
    email: "buermann@buermann-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/buck-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101837&utm_campaign=profiles_installer",
    company_name: "Buck GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa747ad05986.jpg?v=1",
    address: "Krautgartenweg 10, 89179 Beimerstetten",
    telephone: "+49 7348 5558",
    website: "http://www.buckheizung.de",
    email: "buckgmbh@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solar Fabrik GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarcomplex?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30375&utm_campaign=profiles_installer",
    company_name: "Solarcomplex AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e76d7108cfb.jpg?v=1",
    address: "Ekkehardstraße 10, 78224, Singen",
    telephone: "+49 7731 82740",
    website: "http://www.solarcomplex.de",
    email: "box@solarcomplex.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bomba-partner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10542&utm_campaign=profiles_installer",
    company_name: "B&P ökokapital Investment AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe2ee75b166d.png?v=1",
    address: "Prof. Arneth Str. 6, 96224, Burgkunstadt",
    telephone: "+49 9572 603936",
    website: "https://www.oekokapital-ag.de",
    email: "bomba@bomba-und-partner.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bottcher-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83175&utm_campaign=profiles_installer",
    company_name: "Böttcher-Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be140aef5af.jpg?v=1",
    address: "Zeppelinweg 3, 99734 Nordhausen",
    telephone: "+49 3631 971533",
    website: "http://haustechnik-boettcher.de",
    email: "boettcher-solar@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-borner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92058&utm_campaign=profiles_installer",
    company_name: "Thomas Börner Heizung – Sanitär",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fb7d3cb80d.jpg?v=1",
    address: "Marienberger Str. 11a, 09509 Pockau-Lengefeld",
    telephone: "+49 37367 9504",
    website: "http://www.boerner-pockau.de",
    email: "boerner-pockau@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrohausle?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94988&utm_campaign=profiles_installer",
    company_name: "Elektrohäusle GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e07d656f3bc.jpg?v=1",
    address: "Alte Talstr. 19, 70794 Filderstadt",
    telephone: "+49 711 3584926",
    website: "http://www.elektrohaeusle.de",
    email: "bn@elektrohaeusle.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sun-energy-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64609&utm_campaign=profiles_installer",
    company_name: "Sun Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5914180f6334d.jpg?v=1",
    address: "Obere Kirchstr. 16, D - 79395 Neuenburg am Rhein",
    telephone: "+49 7634 5219010",
    website: "http://www.sun-energy-br.de",
    email: "blersch@sun-energy-br.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21012020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bjorn-stolp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94225&utm_campaign=profiles_installer",
    company_name: "Björn Stolp",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc3059787f.png?v=1",
    address: "Heinrich-Röhm-Straße 15, 89567 Sontheim an der Brenz",
    telephone: "+49 7325 951173",
    website: "http://stolp-bedachungen.de",
    email: "bjorn.stolp@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/berger-erneuerbare-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1614&utm_campaign=profiles_installer",
    company_name: "Berger Erneuerbare Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5183818c15104.gif?v=1",
    address: "Kirchenweg 11, D-97292 Holzkirchen",
    telephone: "+49 9369 99272",
    website: "http://www.berger-energie.de",
    email: "berger-energie@web.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Kyocera Corporation,,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/behm-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92031&utm_campaign=profiles_installer",
    company_name: "Behm Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f88e6173d5.jpg?v=1",
    address: "Industriestr. 2, 24610 Trappenkamp",
    telephone: "+49 4323 802402",
    website: "http://www.behm-haustechnik.com",
    email: "behm@behm-haustechnik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mischke-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95090&utm_campaign=profiles_installer",
    company_name: "Mischke Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85f871e29a.jpg?v=1",
    address: "Am Ökotop 78, 40549 Düsseldorf",
    telephone: "+49 211 5961146",
    website: "http://www.mischke.de",
    email: "bedachungen@mischke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wohlk-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10582&utm_campaign=profiles_installer",
    company_name: "Wöhlk Baustoffzentrum GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fe31dc726953.png?v=1",
    address: "Gewerbepark 20, 02692, Großpostwitz",
    telephone: "+49 3591 37270",
    website: "http://woehlk-gmbh.de/",
    email: "bautzen@woehlk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ostermann-partner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93455&utm_campaign=profiles_installer",
    company_name: "Ostermann & Partner",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf3e565e0b5.jpg?v=1",
    address: "Kachletstraße 13, 90480 Nürnberg",
    telephone: "+49 911 243163",
    website: "https://www.oup-solar.de",
    email: "b.ostermann@oup-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/naturenergiedach-arnecke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93439&utm_campaign=profiles_installer",
    company_name: "Naturenergiedach Arnecke",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b6d2f86de84c.png?v=1",
    address: "Oppelnstr. 6, 38124 Braunschweig",
    telephone: "+49 531 6151428",
    website: "http://www.naturenergiedach.de",
    email: "arnecke@naturenergiedach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,SolarWorld Industries GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,Trina Solar Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/apur-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8642&utm_campaign=profiles_installer",
    company_name: "APUR GmbH Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ee23f9daab3.png?v=1",
    address: "Nordhäuser Str. 41, D-38723 Seesen, Herrhausen",
    telephone: "+49 5381 60156",
    website: "http://www.apur-gmbh-solartechnik.de",
    email: "apurgmbh@aol.com",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Fimer S.p.A.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/anton-plenkers?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81952&utm_campaign=profiles_installer",
    company_name: "Anton Plenkers Dachdecker und Klempnermeister",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589bd1fbe799e.jpg?v=1",
    address: "Necklenbroicher Str. 62a, 40667 Meerbusch",
    telephone: "+49 2132 2472",
    website: "http://www.antonplenkers.de",
    email: "anton.plenkers@t-online.de",
    "Installaliert seit": 1998,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-anton-lechner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93654&utm_campaign=profiles_installer",
    company_name: "Elektro Anton Lechner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60098eab992b5.png?v=1",
    address: "Großberghofenerstr. 3a, 85253 Erdweg",
    telephone: "+49 8138 99100",
    website: "http://www.elektrotechnik-lechner.de",
    email: "anton.lechner@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/spenglerei-marcel-mockel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94455&utm_campaign=profiles_installer",
    company_name: "Spenglerei Marcel Möckel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc3a37d2018.jpg?v=1",
    address: "Spartakusstr. 14, 08209 Auerbach / Vogtland",
    telephone: "+49 3744 183269",
    website: "http://www.spenglerteam.de",
    email: "anfrage@spenglerteam.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaris-and-more?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93518&utm_campaign=profiles_installer",
    company_name: "Solaris and More GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0703639a8d.jpg?v=1",
    address: "Reisertstrasse 10, 53773, Hennef",
    telephone: "+49 2242 9183633",
    website: "http://www.solarisandmore.de",
    email: "anfrage@solarisandmore.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,IBC Solar AG,,,Bauer Energiekonzepte GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaris-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83181&utm_campaign=profiles_installer",
    company_name: "Solaris GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be1de568a99.jpg?v=1",
    address: "Hochstr. 30, 51688 Wipperfürth",
    telephone: "+49 2267 880408",
    website: "http://solarisgbr.de",
    email: "anfrage@solaris.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/esatek?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30363&utm_campaign=profiles_installer",
    company_name: "Esatek GmbH Elektrofachbetrieb und Energiesysteme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5732cdb25eada.jpg?v=1",
    address: "Ferdinand-Porsche-Straße 3, 63500, Seligenstadt",
    telephone: "+49 6182 829047",
    website: "http://www.esatek.de",
    email: "anfrage@esatek.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/buchanelectric?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75749&utm_campaign=profiles_installer",
    company_name: "Buchanelectric",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ce1ffe5cc99.jpg?v=1",
    address: "Rettchensdorfer Straße 1, 03229 Altdöbern",
    telephone: "+49 35 43413203",
    website: "http://www.buchanelectric.de",
    email: "anfrage@buchanelectric.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bau-ko-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75107&utm_campaign=profiles_installer",
    company_name: "Bau-Ko Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bcf733e9004.jpg?v=1",
    address: "Buchenstraße 2, 56584 Anhausen",
    telephone: "+49 2639 962730",
    website: "http://bauko-solar.de",
    email: "anfrage@bauko-solar.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar38?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93496&utm_campaign=profiles_installer",
    company_name: "Solar38 Photovoltaik-Technik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf737fbd71c.jpg?v=1",
    address: "Dangersener Str. 18, 21244 Buchholz in der Nordheide",
    telephone: "+49 4181 380382",
    website: "http://www.solar38.com",
    email: "andreas.schenk@solar38.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Bauer Energiekonzepte GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-seibt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10468&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Andreas Seibt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd111ba2a680.png?v=1",
    address: "Liebesdörfel 3, 02708, Löbau, OT, Ebersdorf",
    telephone: "+49 3585 481125",
    website: "http://www.dachdecker-seibt.de",
    email: "andreas-seibt@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kw-projekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112210&utm_campaign=profiles_installer",
    company_name: "KW Projekt und Handel GmbH",
    logo_url:
      "https://cdn.enfsolar.com/z/s/logos/IX5ebe65de3bfaa/grafik kw.png?v=1",
    address: "Effnerstr. 119",
    telephone: "+49 8999 165641",
    website: "http://www.kw-ph.de",
    email: "alexander.kern@kw-ph.de",
    "Installaliert seit": 2012,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Österreich, Schweiz, Deutschland, Spanien, Frankreich, Italien, USA, Ukraine,",
    "Lieferanten von Solarmodulen": ",FuturaSun srl,",
    "Wechselrichter-Lieferanten":
      ",Shenzhen SOFARSOLAR Co., Ltd.,,,Zucchetti Centro Sistemi S.p.A,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/michael-fleischmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93493&utm_campaign=profiles_installer",
    company_name: "Michael Fleischmann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf707cd388c.jpg?v=1",
    address: "Farrenpointstraße 11, 83043 Bad Aibling",
    telephone: "+49 8061 9399850",
    website: "http://www.solar-suedbayern.de",
    email: "alexander.kern@kw-ph.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarporter?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124868&utm_campaign=profiles_installer",
    company_name: "Solarporter c/o B&J Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ecf9ce011f6b.png?v=1",
    address: "Brigittenweg, 14, 54578 Nohn",
    telephone: "+49 2696 665",
    website: "http://solarporter.de/",
    email: "albert.bauer@buj.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",B&J Holzbau GmbH,",
    "Last Update": 4092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/scheibe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93585&utm_campaign=profiles_installer",
    company_name: "Scheibe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c092c928dfa.jpg?v=1",
    address: "Vitelliusstraße 27, 54516 Wittlich",
    telephone: "+49 6571 954224",
    website: "http://www.heizung-scheibe.de",
    email: "advice @ heizung-scheibe.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar8-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102325&utm_campaign=profiles_installer",
    company_name: "Solar8 Energy AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab876a7e7e09.png?v=1",
    address: "Kaiserswerther Straße 115, 40880 Ratingen",
    telephone: "+49 2102 420695",
    website: "http://www.solar8.de",
    email: "abock@solar8.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gerustbau-dachdeckerei?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94243&utm_campaign=profiles_installer",
    company_name: "Gerüstbau Dachdeckerei",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdcb4e8ad4a.png?v=1",
    address: "Possenhain 21, 06618 Schönburg OT Possenhain",
    telephone: "+49 3445 792937",
    website: "http://www.geruestbau-dachdeckerei.de",
    email: "a.wiebicke@geruestbau-dachdeckerei.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/horst-walch-kaminbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93540&utm_campaign=profiles_installer",
    company_name: "Horst Walch Kaminbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c07e395e526.jpg?v=1",
    address: "Industriering 12, 66440 Blieskastel",
    telephone: "+49 6842 2385",
    website: "http://www.schornsteintechnik-walch.de",
    email: "a.walch@schornsteintechnik-walch.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/woydowski?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101823&utm_campaign=profiles_installer",
    company_name: "Woydowski GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa72fc92f729.jpg?v=1",
    address: "Zum Scheider Feld 23, 51467 Bergisch Gladbach",
    telephone: "+49 2202 8018",
    website: "https://www.woydowski.de",
    email: "%20info@woydowski.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/k-wrede?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94229&utm_campaign=profiles_installer",
    company_name: "K. Wrede GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdc572d2762.png?v=1",
    address: "Wasserbank 17, 58456 Witten",
    telephone: "+49 23 02760050",
    website: "http://www.dachdecker-wrede.de",
    email: "wrede.witten@gmail.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunzenit?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4127&utm_campaign=profiles_installer",
    company_name: "Sunzenit GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4127n.gif?v=1",
    address: "Feldweg 3, D-14715, Märkisch Luch, OT Buschow",
    telephone: "+49 33876 40214",
    website: "http://www.sunzenit.de",
    email: "sunzenit@sunzenit.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Europa,",
    "Lieferanten von Solarmodulen": ",Trina Solar Limited,,,Sunways LLC,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,,,Siemens Energy,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachtechnik-fluck?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95142&utm_campaign=profiles_installer",
    company_name: "Dachtechnik Flück GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ee916edff17.jpg?v=1",
    address: "Horstmanns Heide 17, 46325 Borken",
    telephone: "+49 2865 603646",
    website: "http://www.dachtechnik-flueck.de",
    email: "stefan@dachtechnik-flueck.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-rohrl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93595&utm_campaign=profiles_installer",
    company_name: "Elektro Röhrl GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c09b477070c.jpg?v=1",
    address: "Wasserburger Landstraße 2, 85604 Zorneding",
    telephone: "+49 8106 29061",
    website: "http://www.elektro-roehrl.de",
    email: "sales@elektro-roehrl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-rall?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95988&utm_campaign=profiles_installer",
    company_name: "Peter Rall GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a03ecfdab781.jpg?v=1",
    address: "Thulerweg 7, 30519 Hannover",
    telephone: "+49 511 831369",
    website: "http://peter-rall.de",
    email: "rall@peter-rall.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/junker-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94178&utm_campaign=profiles_installer",
    company_name: "Junker Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ccac0e6a31f.png?v=1",
    address: "Schutterstraße 20, D-77746 Schutterwald",
    telephone: "+49 781 6399692",
    website: "http://www.junker-energietechnik.de",
    email: "mail@junker-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kutschinski-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94209&utm_campaign=profiles_installer",
    company_name: "Kutschinski Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e6703f6f18f4.jpg?v=1",
    address: "Gildestr. 3, 32657 Lemgo",
    telephone: "+49 5261 88484",
    website: "https://www.kutschinski-bedachungen.de",
    email: "kutschinski.daecher@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/buhler-zimmerei-und-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116876&utm_campaign=profiles_installer",
    company_name: "Bühler Zimmerei & Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3e9826ac655.png?v=1",
    address: "Altrheinstraße 6, 79415 Bad Bellingen-Rheinweiler",
    telephone: "+49 7635 9841",
    website: "https://www.buehler-holzbau.de",
    email: "kontakt@buehler-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/daniel-hanke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93898&utm_campaign=profiles_installer",
    company_name: "Daniel Hanke Zimmerei-Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f60e656868d7.PNG?v=1",
    address: "Uelzener Straße 27, 29451 Dannenberg (Elbe)",
    telephone: "+49 5861 4090",
    website: "http://zimmerei-hanke.de",
    email: "INFO@ZIMMEREI-HANKE.DE",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zenosolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=14353&utm_campaign=profiles_installer",
    company_name: "Zenosolar GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/600037f7a7344.png?v=1",
    address: "Wiesenstraße 29, 57290, Netphen",
    telephone: "+49 2737 2265060",
    website: "http://www.zenosolar.de",
    email: "info@zenosolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/armin-zache?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82500&utm_campaign=profiles_installer",
    company_name: "Armin Zache GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58acfa3bb2044.jpg?v=1",
    address: "Platz 27, 42855 Remscheid",
    telephone: "+49 2191 882121",
    website: "http://zache.com",
    email: "info@zache.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/volker-pick?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75531&utm_campaign=profiles_installer",
    company_name: "Volker Pick GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57c7bf8b8b62f.jpg?v=1",
    address: "Grüner Weg 35, 53902 Bad Münstereifel",
    telephone: "+49 2253 932063",
    website: "http://www.volker-pick.de",
    email: "info@volker-pick.de",
    "Installaliert seit": 2006,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",JA Solar Holdings Co., Ltd.,,,Canadian Solar Inc.,,,Sharp Energy Solutions Europe,,,Jinko Solar Holding Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/veramex?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8632&utm_campaign=profiles_installer",
    company_name: "Veramex",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d5fe40d8be7.jpg?v=1",
    address: "Markenweg 10, 59399 Olfen",
    telephone: "+49 2595 3850143",
    website: "http://veramex.de",
    email: "info@veramex.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vencotec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91066&utm_campaign=profiles_installer",
    company_name: "VencoTec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/597e8a1be6025.jpg?v=1",
    address: "Johannisgrund 3, 86684 Holzheim",
    telephone: "+49 8276 51870",
    website: "http://www.vencotec.de",
    email: "info@vencotec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/uli-kaiser-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1612&utm_campaign=profiles_installer",
    company_name: "Uli Kaiser Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bf231155fbf4.png?v=1",
    address: "Friedrichstr. 12, 56422 Wirges",
    telephone: "+49 2602 934160",
    website: "http://www.ulikaiserelektrotechnik.de",
    email: "info@ulikaiserelektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/deppe-pollmann-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102669&utm_campaign=profiles_installer",
    company_name: "Udo Deppe Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac9837af3389.jpg?v=1",
    address: "Kugelbreite 32, 33154 Salzkotten",
    telephone: "+49 5258 9804490",
    website: "http://ud-energietechnik.de",
    email: "info@ud-energietechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/beck-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8693&utm_campaign=profiles_installer",
    company_name: "Team Elektro Beck GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b9b4fade0941.png?v=1",
    address: "Friedrich-Bergius-Ring 1, 97076 Würzburg",
    telephone: "+49 931 2300970",
    website: "http://www.team-elektro-beck.de",
    email: "info@teamsolartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/neue-energie-systeme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10379&utm_campaign=profiles_installer",
    company_name: "Neue Energie Systeme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57674302a1934.jpg?v=1",
    address: "Sonnensteg 27, 18586 Sellin",
    telephone: "+49 38303 85600",
    website: "http://www.system-energie.de",
    email: "info@system-energie.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Danfoss A/S,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sunline-solarstrom?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1428&utm_campaign=profiles_installer",
    company_name: "Sunline-Solarstrom GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bffd2021c63.jpg?v=1",
    address: "Flugplatzstraße 90, 90768 Fürth",
    telephone: "+49 911 80092950",
    website: "http://www.sunline-solarstrom.de",
    email: "info@sunline-solar.de",
    "Installaliert seit": 1986,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/stotzel-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94009&utm_campaign=profiles_installer",
    company_name: "Stötzel Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9c71eef567.jpg?v=1",
    address: "Heidenbachswald 32, 57234 Wilnsdorf-Obersdorf",
    telephone: "+49 271 77344956",
    website: "http://stoetzel-elektrotechnik.de",
    email: "info@stoetzel-elektrotechnik.de",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-technik-nord?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=630&utm_campaign=profiles_installer",
    company_name: "Solar-Technik Nord GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51675bbed2446.bmp?v=1",
    address: "Marie-Curie-Straße 5, 24837 Schleswig",
    telephone: "+49 4621 9518200",
    website: "http://www.stn.sh",
    email: "info@stn.sh",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/spitzenenergie-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84262&utm_campaign=profiles_installer",
    company_name: "Spitzenenergie Solutions e.K",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58d878bcbceee.jpg?v=1",
    address: "Weisendorferstr. 16, D - 91056 Erlangen",
    telephone: "+49 9135 7350777",
    website: "http://www.spitzenenergy.com",
    email: "info@spitzenenergy.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Indien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-spiegler?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10318&utm_campaign=profiles_installer",
    company_name: "Elektro-Spiegler GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57674181038c2.jpg?v=1",
    address: "Ludwig-Erhard-Str. 5, 89547, Gerstetten-Dettingen",
    telephone: "+49 7324 980202",
    website: "http://spiegler-solar.de",
    email: "info@spiegler-solar.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,SunPower Corp.,,,Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sonnenenergie-winnen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99142&utm_campaign=profiles_installer",
    company_name: "Sonnenenergie Winnen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c02a8290af.png?v=1",
    address: "Eisenzahnstr. 5, 10709, Berlin",
    telephone: "+49 30 89543970",
    website: "http://www.sonnenenergiewinnen.de",
    email: "info@sonnenenergiewinnen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 10082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarkonzept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=572&utm_campaign=profiles_installer",
    company_name: "Solarkonzept GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57a98246a3199.jpg?v=1",
    address: "Pasewalker Str. 76, 13127 Berlin",
    telephone: "+49 30 48626906",
    website: "http://www.solkonzept.de",
    email: "info@solkonzept.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Panasonic Corporation (Sanyo),,,Lorentz,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-zeller?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10403&utm_campaign=profiles_installer",
    company_name: "Solar Zeller Energiesysteme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd0aa4fe2520.png?v=1",
    address: "Rauhornweg 5, 87541, Bad Hindelang",
    telephone: "+49 8324 1238",
    website: "http://www.solarzeller.de",
    email: "info@solarzeller.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarxpert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=119901&utm_campaign=profiles_installer",
    company_name: "SolarXpert e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5dde3d6a0ac2f.jpg?v=1",
    address: "Kreuznacher Str. 54 B, 67806 Rockenhausen",
    telephone: "+49 6361 6439897",
    website: "http://www.solarxpert.de",
    email: "info@solarxpert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,SunPower Corp.,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-zeise?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8536&utm_campaign=profiles_installer",
    company_name: "Solartechnik Zeise GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/507fb0b624ec6.JPG?v=1",
    address: "Dorfstraße 32, D-06628 Obermöllern",
    telephone: "+49 34463 27197",
    website: "http://www.solartechnik-zeise.de",
    email: "info@solartechnik-zeise.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Trina Solar Limited,,,Astronergy Co., Ltd. (Chint Solar),,,BYD Company Limited,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarfalke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84780&utm_campaign=profiles_installer",
    company_name: "Solarfalke",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60781d3917ee1.PNG?v=1",
    address: "Sankt-Johannes-Straße 142, 41849 Wassenberg",
    telephone: "+49 176 31602680",
    website: "http://www.solarfalke-photovoltaik.de",
    email: "info@solarfalke-photovoltaik.de",
    "Installaliert seit": 2002,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-direct-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10021&utm_campaign=profiles_installer",
    company_name: "Solar Direct",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf7f641f121.jpg?v=1",
    address: "Industriestraße 44, 48629 Metelen",
    telephone: "+49 2556 997267",
    website: "http://www.solardirect.eu",
    email: "info@solardirect.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar23?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4367&utm_campaign=profiles_installer",
    company_name: "Solar23 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4367n.gif?v=1",
    address: "Neue Str. 22, D-89073, Ulm",
    telephone: "+49 731 7080990",
    website: "http://www.solar23.com",
    email: "info@solar23.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Afrika, Europa,",
    "Lieferanten von Solarmodulen": ",Kyocera Corporation,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Studer Innotec,,,Victron Energy BV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 12112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-power-hofmann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93579&utm_campaign=profiles_installer",
    company_name: "Solar-Power Hofmann GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0907e20724.jpg?v=1",
    address: "Waldstetten 4, D-86709 Wolferstadt",
    telephone: "+49 9092 1226",
    website: "http://www.solar-power-hofmann.de",
    email: "info@solar-power-hofmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-partner-sud?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1325&utm_campaign=profiles_installer",
    company_name: "Solar-Partner-Süd GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4541.gif?v=1",
    address: "Holzhauser Feld 9, D-83361, Kienberg",
    telephone: "+49 8628 987970",
    website: "http://solar-partner-sued.de",
    email: "info@solar-partner-sued.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Aleo Solar GmbH,,,Kyocera Corporation,,,Canadian Solar Inc.,,,Avancis GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heinz-christian-sartisohn-e-k?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=129429&utm_campaign=profiles_installer",
    company_name: "Heinz Christian Sartisohn e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd36348db4e2.png?v=1",
    address: "Gewerbestr.1, 77731, Willstätt-Eckartsweier",
    telephone: "+49 7854 1710",
    website: "http://sartisohn-haustechnik.de/",
    email: "info@sartisohn-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-rene-reinfeldt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93982&utm_campaign=profiles_installer",
    company_name: "Solartechnik Renè Reinfeldt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ad482cc03.jpg?v=1",
    address: "Fünfeichener Weg 26, 17033 Neubrandenburg",
    telephone: "+49 395 7768784",
    website: "http://www.reinfeldtsolar.de",
    email: "info@reinfeldtsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/brehm?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10515&utm_campaign=profiles_installer",
    company_name: "Brehm GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/538ebea862e4e.gif?v=1",
    address: "Schwefingerstr. 2, 49716, Meppen",
    telephone: "+49 5931 4989939",
    website: "http://www.photovoltaik-montage.eu",
    email: "info@photovoltaik- Montage.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/pfalzsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10214&utm_campaign=profiles_installer",
    company_name: "Pfalzsolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e437ed03aaaa.png?v=1",
    address: "Franz-Zang-Straße 2, 67059 Ludwigshafen",
    telephone: "+49 621 9504010",
    website: "https://pfalzsolar.de",
    email: "info@pfalzsolar.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: ",Pfalzwerke Aktiengesellschaft,",
    "Last Update": 7052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-solarstrom-owl-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=126341&utm_campaign=profiles_installer",
    company_name: "Peter Solarstrom OWL GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f2caa1fca274.jpg?v=1",
    address: "Großer Stein 77, 32657 Lemgo",
    telephone: "+49 5261 9712251",
    website: "https://www.peter.solar",
    email: "info@peter.solar",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",KIOTO Solar,,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolaX Power Network Technology(Zhejiang) Co.,Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/peter-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1750&utm_campaign=profiles_installer",
    company_name: "Peter Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1750n.gif?v=1",
    address: "Leibnizstr. 6, 32108 Bad Salzuflen",
    telephone: "+49 5222 94860",
    website: "http://www.peter-solartechnik.de",
    email: "info@peter-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/orange-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76003&utm_campaign=profiles_installer",
    company_name: "Orange Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d618bf41456.jpg?v=1",
    address: "Zeppelinstraße 4, 74357 Bönnigheim",
    telephone: "+49 7143 9680890",
    website: "http://www.orange-solar.de",
    email: "info@orange-solar.de",
    "Installaliert seit": 2003,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Astronergy Co., Ltd. (Chint Solar),",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ontour-solar-ug?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=131410&utm_campaign=profiles_installer",
    company_name: "OnTour Solar UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/605bdf2caf8e7.jpg?v=1",
    address: "Fischergasse 9, 64560 Riedstadt",
    telephone: "+49 6158 9177550",
    website: "https://www.ontoursolar.de",
    email: "info@ontoursolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ontour-solar-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=39895&utm_campaign=profiles_installer",
    company_name: "OnTour Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/241324279.gif?v=1",
    address: "Im Lucken 6, 64673 Zwingenberg",
    telephone: "+49 6251 9854380",
    website: "http://www.ontour-solar.com",
    email: "INFO@ONTOUR-SOLAR.COM",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,AXITEC Energy GmbH & Co. KG,,,Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/orangeblue-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94381&utm_campaign=profiles_installer",
    company_name: "OrangeBlue Solar und Dachsysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f61054caf7cc.PNG?v=1",
    address: "Alte Gießener Straße 15, 35415 Pohlheim",
    telephone: "+49 641 58777754",
    website: "http://www.ob-solar.de",
    email: "info@ob-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nsw-energiekonzepte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93447&utm_campaign=profiles_installer",
    company_name: "NSW Energiekonzepte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf36be0fa63.jpg?v=1",
    address: "In der Mühle 4, 34637 Schrecksbach-Röllshausen",
    telephone: "+49 6698 9118836",
    website: "http://nsw-ek.de/",
    email: "info@nsw-ek.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nova-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1747&utm_campaign=profiles_installer",
    company_name: "Nova Solartechnik Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/548a818ac56e0.jpg?v=1",
    address: "Am Bahnhof 20, 33397 Rietberg",
    telephone: "+49 52 44928656",
    website: "http://www.nova-solar.de",
    email: "info@nova-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nilssun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=21577&utm_campaign=profiles_installer",
    company_name: "Nilssun GmH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51468d84bc693.gif?v=1",
    address: "Frenkingstraße 25a, 44894, Bochum",
    telephone: "+49 234 7732358",
    website: "http://www.nilssun.de",
    email: "info@nilssun.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Nilssun Energie UG,",
    "Last Update": 2022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/neshtec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56455&utm_campaign=profiles_installer",
    company_name: "Neshtec UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55812e59ccc68.jpg?v=1",
    address: "Margeritenweg 2c, 21493 Schwarzenbek",
    telephone: "+49 4151 8794507",
    website: "http://www.neshtec.eu",
    email: "info@neshtec.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/naturconcept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10377&utm_campaign=profiles_installer",
    company_name: "Naturconcept",
    logo_url: "https://cdn.enfsolar.com/z/s/logos/xI5e724750d2804/Logo.jpg?v=1",
    address: "Chemnitztalstraße 229, DE-09114 Chemnitz",
    telephone: "+49 371 4586891",
    website: "http://www.naturconcept.com",
    email: "info@naturconcept.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 8052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/nabenhauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10427&utm_campaign=profiles_installer",
    company_name: "Nabenhauer GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10427n.gif?v=1",
    address: "Weidenäcker 4, 88605 Meßkirch",
    telephone: "+49 7575 92180",
    website: "http://www.nabenhauer.de",
    email: "info@nabenhauer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Kyocera Corporation,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/nanoq-home-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93670&utm_campaign=profiles_installer",
    company_name: "Nanoq Home Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c211733953a.jpg?v=1",
    address: "Am Geißler 39, 35578 Wetzlar",
    telephone: "+49 6441 2089000",
    website: "https://www.nanoq.de",
    email: "info@mynanoq.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/muller-systems?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84836&utm_campaign=profiles_installer",
    company_name: "Müller Systems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58e7304cc2618.jpg?v=1",
    address: "Dorfstr. 27, 97633 Breitensee",
    telephone: "+49 9761 721664",
    website: "http://www.mueller-systems.eu",
    email: "info@mueller-systems.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mste-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2057&utm_campaign=profiles_installer",
    company_name: "MSTE Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575132cd33ef2.jpg?v=1",
    address: "In Oberwiesen 16, DE-88682 Salem",
    telephone: "+49 7553 2194296",
    website: "https://www.mste-solar.de",
    email: "info@mste-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mr-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=71897&utm_campaign=profiles_installer",
    company_name: "Mr-SolarMontageTechnik UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6092b651e17df.PNG?v=1",
    address: "Am Warenberg 1/2, 78050 Villingen-Schwenningen",
    telephone: "+49 171 3871391",
    website: "http://www.mr-solar.net",
    email: "info@mr-solar.net",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": ",Südamerika, Europa",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/milk-the-sun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=23845&utm_campaign=profiles_installer",
    company_name: "Milk the Sun GmbH",
    logo_url:
      "https://cdn.enfsolar.com/ID/logo/23845_22315a9406deec688.png?v=1",
    address: "Elsenstraße 106, 12435 Berlin",
    telephone: "+49 30 469990330",
    website: "http://www.milkthesun.com",
    email: "info@milkthesun.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Österreich, Schweiz, Deutschland, Spanien, Frankreich, Großbritannien, Griechenland, Ungarn, Italien, Südafrika, Portugal, Polen,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/mhb-montage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93244&utm_campaign=profiles_installer",
    company_name: "MHB Montage GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f2399c610.jpg?v=1",
    address: "Wilhelmstr. 6, 91732 Merkendorf",
    telephone: "+49 9826 6592140",
    website: "http://mhb-montage.de/",
    email: "info@mhb- Montage.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Belgien, Deutschland, Spanien, Frankreich, Rumänien,Europa,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/merkert-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93019&utm_campaign=profiles_installer",
    company_name: "Merkert-Solar Heizung & Gebäudetechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b5e83fba503.jpg?v=1",
    address: "Beuchaer Str. 13, D-74392, Freudental",
    telephone: "+49 7143 4015880",
    website: "http://merkert-solar.de/",
    email: "info@merkert-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/mawo-solarteur?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64327&utm_campaign=profiles_installer",
    company_name: "Mawo Solarteur",
    logo_url: "https://cdn.enfsolar.com/ID/logo/605344277d56e.PNG?v=1",
    address: "Horneburg 33, 44869 Bochum",
    telephone: "+49 2327 9946098",
    website: "http://www.mawo-solarteur.de",
    email: "info@mawo-solarteur.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",X-disc S.A.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/lunaco?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=100921&utm_campaign=profiles_installer",
    company_name: "Lunaco Europe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a8e6b1c725d3.png?v=1",
    address: "Halberstädter Str. 2, 10711 Berlin",
    telephone: "+49 30 86008038",
    website: "http://lunaco-europe.de",
    email: "info@lunaco.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Luxra AG,",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ludwar-elektrobau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94986&utm_campaign=profiles_installer",
    company_name: "Ludwar Elektrobau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e07d0d4a10c.jpg?v=1",
    address: "Adam-Stegerwald-Straße 17, D-97447 Gerolzhofen",
    telephone: "+49 9382 97150",
    website: "http://www.ludwar-elektrobau.de",
    email: "info@ludwar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/leo-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10155&utm_campaign=profiles_installer",
    company_name: "Leo-Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5350de76a91b9.jpg?v=1",
    address: "Sonnenrain 2, 89079 Ulm",
    telephone: "+49 1525 8539872",
    website: "http://www.leo-solar.de",
    email: "info@leo-solar.de",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 30092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lemke-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82722&utm_campaign=profiles_installer",
    company_name: "Lemke Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b389c7de3d3.jpg?v=1",
    address: "Staubenthaler Str. 18, 42369 Wuppertal",
    telephone: "+49 202 4603000",
    website: "http://lemke-solartechnik.de",
    email: "INFO@LEMKE-SOLARTECHNIK.DE",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kobernik-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81753&utm_campaign=profiles_installer",
    company_name: "Köbernik Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58954c8309f6a.jpg?v=1",
    address: "Ganghoferstraße 5, 93087 Alteglofsheim",
    telephone: "+49 9453 9999-317",
    website: "http://www.koebernik.net",
    email: "info@koebernik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kiefer-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93257&utm_campaign=profiles_installer",
    company_name: "Kiefer GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9fcc141ae4.jpg?v=1",
    address: "Franz-Ziwey-Ring 1, Industriegebiet Hardt, 78333 Stockach",
    telephone: "+49 7771 93930",
    website: "http://www.kiefer-holz.de",
    email: "info@kiefer-holz.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,KACO New Energy GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kas-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10485&utm_campaign=profiles_installer",
    company_name: "KAS Elektrotechnik GmbH & Co KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fd11b43c319d.png?v=1",
    address: "Leipziger Straße 96a, 34123, Kassel",
    telephone: "+49 561 5898990",
    website: "http://www.kas-elektrotechnik.de",
    email: "info@kas-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachbau-karpe?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10101&utm_campaign=profiles_installer",
    company_name: "Dachbau Karpe GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10101n.gif?v=1",
    address: "Weinbergweg 2, 99441 Kromsdorf",
    telephone: "+49 3643 516412",
    website: "http://www.karpe-dach.de",
    email: "info@karpe-dach.de .",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/j-k?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94007&utm_campaign=profiles_installer",
    company_name: "J&K GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9c3d8293f6.jpg?v=1",
    address: "Schützenhofstraße 50, 26180 Rastede",
    telephone: "+49 4402 9156243",
    website: "https://www.jundk-gbr.de",
    email: "info@jundk-gbr.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/innowatt24?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13453&utm_campaign=profiles_installer",
    company_name: "Innowatt24 GmbH & Co. KG",
    logo_url:
      "https://cdn.enfsolar.com/ID/logo/13453_86015be2dbb657d4a.png?v=1",
    address: "Kurfürstendamm 234, D-10719 Berlin",
    telephone: "+49 30 520045172",
    website: "http://www.innowatt24.com",
    email: "info@innowatt24.com",
    "Installaliert seit": 1985,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten": ",RCT Power GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung, Training",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hurtzig-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92862&utm_campaign=profiles_installer",
    company_name: "Hurtzig Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0c93e0a17a.jpg?v=1",
    address: "Tannenbergstraße 9, 28211 Bremen",
    telephone: "+49 421 490036",
    website: "http://www.hurtzig.de",
    email: "info@hurtzig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Viessmann Werke GmbH & Co. KG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hovestadt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94414&utm_campaign=profiles_installer",
    company_name: "Bedachungstechnik Hovestadt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc1cabcf2f7.png?v=1",
    address: "Graf-Beust-Allee 27, 45141 Essen",
    telephone: "+49 201 322202",
    website: "http://www.hovestadt-essen.de",
    email: "info@hovestadt-essen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/holzbau-forster?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94293&utm_campaign=profiles_installer",
    company_name: "Holzbau Forster GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cf53724d9a4.jpg?v=1",
    address: "Säntisstraße 20a, 88145 Opfenbach",
    telephone: "+49 8385 600",
    website: "http://holzbauforster.de",
    email: "info@HolzbauForster.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/moser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=109462&utm_campaign=profiles_installer",
    company_name: "Moser",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e6afb0f476df.jpg?v=1",
    address: "Hauptstraße 72, 09634 Hirschfeld",
    telephone: "+49 35242 62349",
    website: "http://www.holzbau-moser.de",
    email: "info@holzbau-moser.de",
    "Installaliert seit": 2005,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hm-pv?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22759&utm_campaign=profiles_installer",
    company_name: "hm-pv GmbH (formerly as 3+Solar GmbH)",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f6bffc0d531f.jpg?v=1",
    address: "Schmitzdorfer Str. 2a, 93482 Pemfling-Grafenkirchen",
    telephone: "+49 9975 904330",
    website: "http://hm-pv.de",
    email: "info@hm-pv.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,IBC Solar AG,,,SunPower Corp.,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-dachdeckerei-hildebrandt-meier?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=116913&utm_campaign=profiles_installer",
    company_name: "Zimmerei Dachdeckerei Hildebrandt + Meier GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3eb44636f86.png?v=1",
    address: "Leinestraße 6, 24539 Neumünster",
    telephone: "+49 4321 77121",
    website: "https://www.hildebrandt-meier.de",
    email: "info@hildebrandt-meier.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-hellfeuer-fick?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92646&utm_campaign=profiles_installer",
    company_name: "Thomas Hellfeuer-Fick GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae1cdb5e2de.jpg?v=1",
    address: "Schusterring 15a, 25355, Barmstedt",
    telephone: "+49 4123 6034",
    website: "http://hellfeuer-fick.de/",
    email: "info@hellfeuer-fick.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/josef-schmitz-heizungsbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75687&utm_campaign=profiles_installer",
    company_name: "Josef Schmitz Heizungsbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fdcac8053383.png?v=1",
    address: "Antoniusstraße 88, 41564 Kaarst-Vorst",
    telephone: "+49 2131 62354",
    website: "http://www.heizung-sanitaer-kaarst.de",
    email: "info@heizung-sanitaer-kaarst.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/haubner-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10449&utm_campaign=profiles_installer",
    company_name: "Haubner Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10449n.gif?v=1",
    address: "Pleißaer Straße 4, 09224, Chemnitz/ OT, Grüna",
    telephone: "+49 371 8000096",
    website: "http://www.haubner-solar.de",
    email: "info@haubner-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gumennoi?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94922&utm_campaign=profiles_installer",
    company_name: "Gumennoi GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e040ab19267.png?v=1",
    address: "Hohenwiesenstraße 59, 76275 Ettlingen",
    telephone: "+49 7243 5149688",
    website: "http://www.gumennoi-bedachung.de",
    email: "info@gummenoi-bedachung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/grundenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92456&utm_campaign=profiles_installer",
    company_name: "GrundEnergie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a8f5bcb6bd8.jpg?v=1",
    address: "Am Römerlager 28, 41238 Mönchengladbach",
    telephone: "+49 1735 738498",
    website: "https://www.grundenergie.eu",
    email: "info@grundenergie.de",
    "Installaliert seit": 2014,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/groob-dohmen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93665&utm_campaign=profiles_installer",
    company_name: "Groob-Dohmen GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c209ae3a77e.jpg?v=1",
    address: "Weserstr. 8, 41836 Hückelhoven",
    telephone: "+49 2433 52470",
    website: "http://www.groob-dohmen.de",
    email: "info@groob-dohmen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Sharp Energy Solutions Europe,,,Astronergy Co., Ltd. (Chint Solar),,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/gridparity?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=121507&utm_campaign=profiles_installer",
    company_name: "GridParity AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e23c54dc634d.png?v=1",
    address: "Ohmstraße 7, 85757 Karlsfeld",
    telephone: "+49 8131 3307560",
    website: "https://www.gridparityag.com",
    email: "info@gridparity.ag",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",Afore New Energy Technology (Shanghai) Co., Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/greenindustree?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95781&utm_campaign=profiles_installer",
    company_name: "GreenIndusTree GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ffdfe0eddf5.jpg?v=1",
    address: "Salierring 32, 50667 Köln",
    telephone: "+49 221 9977465",
    website: "http://www.greenindustree.de",
    email: "info@goneo.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Pakistan,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-futterer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94484&utm_campaign=profiles_installer",
    company_name: "Dachdecker Fütterer UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc60bdeca7a.jpg?v=1",
    address: "Würmersheimer Str. 26, 76474 Au am Rhein",
    telephone: "+49 7245 9239820",
    website: "https://www.fuetterer-dachdecker.de",
    email: "info@fuetterer-dachdecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dieter-friedrichs-dach-u-wand?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10458&utm_campaign=profiles_installer",
    company_name: "Dieter Friedrichs Dach u. Wand GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10458n.gif?v=1",
    address: "Flasskamp 1, 48565, Steinfurt - Borghorst",
    telephone: "+49 2552 4427",
    website: "https://www.friedrichsdach.de",
    email: "info@friedrichs-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ets-6?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94786&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Schaltanlagenbau ETS GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dee67d82c73.png?v=1",
    address: "Fassbinderstraße 11, 94347 Ascha",
    telephone: "+49 9961 94040",
    website: "http://www.elektrotechnik-schaltanlagenbau-ascha.de",
    email: "info@etsgmbh.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Siemens Energy,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-systeme21?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101836&utm_campaign=profiles_installer",
    company_name: "E.systeme21 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa7471cda841.jpg?v=1",
    address: "Boschstraße 38, 89079 Ulm",
    telephone: "+49 731 2065380",
    website: "http://www.esysteme21.de",
    email: "info@esysteme21.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Astronergy Co., Ltd. (Chint Solar),,,AXITEC Energy GmbH & Co. KG,,,Luxor Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Delta Energy Systems (Germany) GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ess-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82747&utm_campaign=profiles_installer",
    company_name: "ESS Elektro Solar Service",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b3d00657848.jpg?v=1",
    address: "Gewerbering 2, 83624 Otterfing",
    telephone: "+49 8024 470100",
    website: "http://ess-solar.de",
    email: "info@ess-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Panasonic Corporation (Sanyo),",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ensolar-4?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1642&utm_campaign=profiles_installer",
    company_name: "EnSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1642n.gif?v=1",
    address: "Max-Planck-Straße 10, 70806 Kornwestheim",
    telephone: "+49 7154 806710",
    website: "http://www.ensolar.de",
    email: "info@ensolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energieexpert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82725&utm_campaign=profiles_installer",
    company_name: "EnergieExpert Rhein-Ruhr GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b3911f79cb6.jpg?v=1",
    address: "Steinhof 33, 40699 Erkrath",
    telephone: "+49 211 58588380",
    website: "http://www.solarexpert.de",
    email: "info@energieexpert.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energiebauern?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=23459&utm_campaign=profiles_installer",
    company_name: "Energiebauern GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/50921285dd0cc.jpg?v=1",
    address: "Maria-Birnbaum-Str. 20, 86577 Sielenbach",
    telephone: "+49 8251 204600",
    website: "http://energiebauern.com/",
    email: "info@energiebauern.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,,Europa",
    "Lieferanten von Solarmodulen":
      ",Solon International,,,Yingli Green Energy Holding Co., Ltd.,,,Suntech Power Co., Ltd.,,,First Solar, Inc.,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,REFU Elektronik GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-spar-centrum-altenwahlingen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93860&utm_campaign=profiles_installer",
    company_name: "Energie-Spar-Centrum Altenwahlingen e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4c6692f74c.jpg?v=1",
    address: "Altenwahlingen 19, 29693 Böhme",
    telephone: "+49 5165 913913",
    website: "http://www.energie-spar-centrum.de",
    email: "info@energie-spar-centrum.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/energent?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1678&utm_campaign=profiles_installer",
    company_name: "Energent AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c1165051958.gif?v=1",
    address: "Oberkonnersreuther Str. 6C, 95448, Bayreuth",
    telephone: "+49 92150 708450",
    website: "http://www.energent.de",
    email: "info@energent.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/enercity?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=132483&utm_campaign=profiles_installer",
    company_name: "Enercity AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/609ee4b553435.png?v=1",
    address: "Ihmeplatz 2, D-30449, Hannover",
    telephone: "+49 511 4300",
    website: "https://www.enercity.de",
    email: "info@enercity.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-wernicke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94124&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik Wernicke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb65ba07f19.jpg?v=1",
    address: "Döhler Kirchweg 20, 21272 Egestorf",
    telephone: "+49 4175 429",
    website: "http://www.elektrotechnik-wernicke.de",
    email: "info@elektrotechnik-wernicke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-sicheneder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11128&utm_campaign=profiles_installer",
    company_name: "Elektro Sicheneder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/505a85618acf2.PNG?v=1",
    address: "Habersbrunn 1, 94424, Arnstorf",
    telephone: "+49 8723 979706",
    website: "http://www.elektro-sicheneder.de",
    email: "info@elektro-sicheneder.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 31122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/e-line?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29691&utm_campaign=profiles_installer",
    company_name: "Elektro-Line GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51dfcbd1209ab.jpg?v=1",
    address: "Röntgenstrasse 84, D-50169 Kerpen-Türnich",
    telephone: "+49 2237 9799260",
    website: "http://www.elektro-line.de",
    email: "info@elektro-line.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-montagen-greb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94828&utm_campaign=profiles_installer",
    company_name: "Elektro-Montagen Greb GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df0ffaf39c8.png?v=1",
    address: "Hatzper Str. 218, 45149 Essen",
    telephone: "+49 201 871120",
    website: "http://www.elektro-greb.de",
    email: "info@elektro-greb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-gmbh-schonau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102356&utm_campaign=profiles_installer",
    company_name: "Elektro GmbH Schönau",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9b226c33d8.png?v=1",
    address: "Hauptstraße 9, 02899 Schönau-Berzdorf",
    telephone: "+49 35874 24241",
    website: "http://www.elektro-gmbh-schoenau.de",
    email: "info@elektro-gmbh-schoenau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-forster?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94123&utm_campaign=profiles_installer",
    company_name: "Elektro Förster GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cb63e1dec6a.jpg?v=1",
    address: "Siegstraße 93, 57250 Netphen",
    telephone: "+49 271 773100",
    website: "https://www.elektro-foerster-gmbh.de",
    email: "info@elektro-foerster-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-dicks?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82359&utm_campaign=profiles_installer",
    company_name: "Elektro Dicks GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58ab996568e94.jpg?v=1",
    address: "Siemensstraße 86, 47574 Goch",
    telephone: "+49 2823 9757580",
    website: "http://elektro-dicks.de",
    email: "info@elektro-dicks.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-burkart?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1712&utm_campaign=profiles_installer",
    company_name: "Elektro Burkart GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/49d4177.gif?v=1",
    address: "Kohlgrunder Str. 15, 36093 Künzell-Dirlos",
    telephone: "+49 661 32389",
    website: "http://www.elektro-burkart.com",
    email: "info@elektro-burkart.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eks?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92220&utm_campaign=profiles_installer",
    company_name: "Energiekonzepte Süd Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4e5262136d.jpg?v=1",
    address: "Kirchheimer Strasse 180, 73265 Dettingen/Teck",
    telephone: "+49 7021 8602660",
    website: "http://www.eks-energie.de",
    email: "info@eks-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ekonzept-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93802&utm_campaign=profiles_installer",
    company_name: "Ekonzept Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c48e5024109.jpg?v=1",
    address: "Pagenstecherstraße 5, 49090 Osnabrück",
    telephone: "+49 541 38055500",
    website: "http://www.ekonzept-energy.com",
    email: "info@ekonzept.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",Ekonzept GmbH & Co. KG,",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eba?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94804&utm_campaign=profiles_installer",
    company_name: "Elektro, Bau und Anlagentechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deef541f0dc.png?v=1",
    address: "Pittlerstraße 28, 04159 Leipzig",
    telephone: "+49 341 46278790",
    website: "http://www.eba-leipzig.de",
    email: "info@eba-leipzig.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/e3-energie-effizienz-experten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93736&utm_campaign=profiles_installer",
    company_name: "E3 - Energie Effizienz Experten GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c365b74c990.jpg?v=1",
    address: "Eugen-Bolz-Straße 5, 74523 Schwäbisch Hall",
    telephone: "+49 791 94600300",
    website: "http://www.e3-experten.de",
    email: "info@e3-experten.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-kiel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103536&utm_campaign=profiles_installer",
    company_name: "Elektro Kiel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/604966fd22958.png?v=1",
    address: "Zellaer Str. 3, 99976 Horsmar",
    telephone: "+49 36023 50253",
    website: "http://www.e-kiel.de",
    email: "info@e-kiel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/droscher-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92190&utm_campaign=profiles_installer",
    company_name: "Dröscher Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4c4a91f1c8.jpg?v=1",
    address: "Hanns-Hoerbiger-Str. 2, 29664 Walsrode",
    telephone: "+49 5161 98960",
    website: "http://www.droescher-haustechnik.de",
    email: "info@droescher.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dornbach-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=26459&utm_campaign=profiles_installer",
    company_name: "Dörnbach Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/533919b13d7da.jpg?v=1",
    address: "Siegstraße 1, 57250, Netphen",
    telephone: "+49 2 71770028377",
    website: "http://www.doernbach-energie.de",
    email: "info@doernbach-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/die-lichtschmiede?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1657&utm_campaign=profiles_installer",
    company_name: "Die Lichtschmiede Solar + Lights GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c3aa8ea8f9f.jpg?v=1",
    address: "Kronwinkler Str. 24, 81245 München",
    telephone: "+49 89 89329252",
    website: "http://www.dielichtschmiede.de",
    email: "info@dielichtschmiede.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alfred-dahlem?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94244&utm_campaign=profiles_installer",
    company_name: "Alfred Dahlem GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdcbc5e2bce.png?v=1",
    address: "Benzstraße 2, D-56414 Meudt",
    telephone: "+49 6435 921898",
    website: "http://www.der-dachprofi.de",
    email: "info@der-dachprofi.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/david-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101849&utm_campaign=profiles_installer",
    company_name: "David-Solar Deutschland GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa7662b2073d.jpg?v=1",
    address: "Ernst-Thälmann-Str. 11, 04758 Cavertitz, OT Bucha",
    telephone: "+49 52 07957930",
    website: "http://www.david-solar.de",
    email: "info@david-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,EGing Photovoltaic Technology Co., Ltd.,,,Canadian Solar Inc.,,,First Solar, Inc.,,,Calyxo GmbH,,,Sungreen New Energy Dongtai Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Delta Energy Systems (Germany) GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachwerk?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102016&utm_campaign=profiles_installer",
    company_name: "Dachwerk Gmbh & Co. Kg",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab6a3467f18.jpg?v=1",
    address: "Am Reinsgraben 3, 37085 Göttingen",
    telephone: "+49 551 3793014",
    website: "http://www.dachwerk.de",
    email: "info@dachwerk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-wiesemann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94362&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Wiesemann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dad6c91e703.jpg?v=1",
    address: "Mühlenstr. 15, 37434 Rüdershausen",
    telephone: "+49 5529 4319649",
    website: "http://www.dachdeckerei-wiesemann.de",
    email: "info@dachdeckerei-wiesemann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckerei-hantzsche?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10646&utm_campaign=profiles_installer",
    company_name: "Dachdeckerei Hantzsche",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10646n.gif?v=1",
    address: "Friedrich-Ebert-Straße 33, 24790 Schacht-Audorf",
    telephone: "+49 4331 91181",
    website: "http://dachdeckerei-hantzsche.de",
    email: "info@dachdeckerei-hantzsche.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeisterbetrieb-jorg-huth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94460&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeisterbetrieb Jörg Huth",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dc3fcb600ae.jpg?v=1",
    address: "Am Dorfplatz 5, 04838 Zschepplin OT Krippehna",
    telephone: "+49 3423 606477",
    website: "http://www.dachdecker-huth.de",
    email: "info@dachdecker-huth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/grond?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94396&utm_campaign=profiles_installer",
    company_name: "Grond GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db3a9c7a5d3.png?v=1",
    address: "Schlosserstraße 2, 48231 Warendorf",
    telephone: "+49 2581 4208",
    website: "http://www.dachdecker-grond.de",
    email: "info@dachdecker-grond.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/b-s-sicherheits-und-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92071&utm_campaign=profiles_installer",
    company_name: "B + S Sicherheits - und Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599fc0e769ef0.jpg?v=1",
    address: "Hammerschmiede 7 89281 Altenstadt-Herrenstetten",
    telephone: "+49 8337 9005370",
    website: "http://www.bus-sicherheit.de",
    email: "info@bus-sicherheit.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Sharp Energy Solutions Europe,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/b-k-solare-zukunft?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30361&utm_campaign=profiles_installer",
    company_name: "B & K Solare Zukunft GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eeb6ccbda389.png?v=1",
    address: "Teutoburger Weg 5, 33332 Gütersloh",
    telephone: "+49 5241 7082110",
    website: "http://bksolarezukunft.de",
    email: "info@bksolarezukunft.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bioconstruct?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94877&utm_campaign=profiles_installer",
    company_name: "Bioconstruct",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e00d07c6fe8.jpg?v=1",
    address: "Wellingstraße 66, 49328 Melle",
    telephone: "+49 5226 59320",
    website: "http://www.bioconstruct.com",
    email: "info@bioconstruct.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/benzina-kommunikation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94177&utm_campaign=profiles_installer",
    company_name: "Benzina Kommunikation GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ccab3d77785.png?v=1",
    address: "Sasbacher Strasse 10, 79111 Freiburg",
    telephone: "+49 761 3839666",
    website: "http://www.benzina-kommunikation.de",
    email: "info@benzina-kommunikation.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/becker-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10425&utm_campaign=profiles_installer",
    company_name: "Becker Bedachungen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53449ba7084c6.jpg?v=1",
    address: "Raiffeisenstr. 2, 37124, Rosdorf",
    telephone: "+49 551 782066",
    website: "http://www.becker-bedachungen.de",
    email: "info@becker-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bauer-umwelt-und-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11906&utm_campaign=profiles_installer",
    company_name: "Bauer Umwelt- und Energietechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11906n.gif?v=1",
    address: "Hauptstraße 13, 74925, Epfenbach",
    telephone: "+49 7263 4097658",
    website: "http://www.bauersolar.de",
    email: "info@bauersolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 1012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ata-werbetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=9050&utm_campaign=profiles_installer",
    company_name: "ATA Werbetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b9b5b5cdfe5c.png?v=1",
    address: "Weidenkultur 1e, 18437, Stralsund",
    telephone: "+49 3831 3567024",
    website: "https://www.nord.solar",
    email: "info@ata-werbetechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Hanwha Q CELLS GmbH,",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/asg-engineering?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=23004&utm_campaign=profiles_installer",
    company_name: "ASG Besitz- und Verwaltungsges. mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5fbfc5ff3ab0f.png?v=1",
    address: "Arensdorfer Weg 2, 06366 Köthen",
    telephone: "+49 3496 649930",
    website: "http://www.asg-solar.de",
    email: "info@asg-solar.de",
    "Installaliert seit": 2001,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ase-mark-gunther?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101275&utm_campaign=profiles_installer",
    company_name: "ASE Mark Günther",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a9610e7bd5e4.jpg?v=1",
    address: "Hauptstraße 45, 03246, Crinitz",
    telephone: "+49 3 532438524",
    website: "http://ase-pv-anlagen.de",
    email: "info@ase-pv-anlagen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 28082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ad-solem?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1864&utm_campaign=profiles_installer",
    company_name: "Ad Solem GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/593916cf3d29f.jpg?v=1",
    address: "Mittelweg 4, 01809 Dohna",
    telephone: "+49 3529 5746682",
    website: "http://adsolem.de",
    email: "info@adsolem.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-wunder?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93997&utm_campaign=profiles_installer",
    company_name: "H. Wunder GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9b78edcc6f.jpg?v=1",
    address: "Lippertsgrüner Str. 37, 95131 Schwarzenbach a.Wald, OT Straßdorf",
    telephone: "+49 8289 1482",
    website: "http://dachdeckerei-wunder.de",
    email: "info-coburg@dachdeckerei-wunder.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heizmarkt-peter-edenlehmoser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92637&utm_campaign=profiles_installer",
    company_name: "Heizmarkt  Peter Edenlehmoser",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae13d861439.jpg?v=1",
    address: "Faulborn 30, 99510 Apolda",
    telephone: "+49 3644 50700",
    website: "http://www.heizmarkt.de",
    email: "heizmarkt@t-online.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/die-solarfirma?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10085&utm_campaign=profiles_installer",
    company_name: "die solarfirma GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10085n.gif?v=1",
    address: "Auf dem Berg 75, 64673 Zwingenberg",
    telephone: "+49 6251 703980",
    website: "http://www.die-solarfirma.de",
    email: "harald.lang@die-solarfirma.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ds-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75973&utm_campaign=profiles_installer",
    company_name: "DS-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d25491e7210.jpg?v=1",
    address: "Sonnenweg 12, 86944 Unterdießen",
    telephone: "+49 8243 1681",
    website: "http://www.ds-solar.de",
    email: "energie@ds-solar.de",
    "Installaliert seit": 1997,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aschoff-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91978&utm_campaign=profiles_installer",
    company_name: "Aschoff Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e6a751dc8c.jpg?v=1",
    address: "Höhenweg 8, 91580 Petersaurach",
    telephone: "+49 9872 4862890",
    website: "http://www.aschoff-solar.com",
    email: "contact@aschoff-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in":
      "China, Deutschland, Ägypten, Italien, Kenia, Vietnam, Thailand, Philippinen,",
    "Lieferanten von Solarmodulen":
      ",Solar Frontier K.K.,,,Canadian Solar Inc.,,,Perlight Solar Co., Ltd.,,,Astronergy Co., Ltd. (Chint Solar),,,Risen Energy Co., Ltd.,,,Znshine PV-tech Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/asantys?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41269&utm_campaign=profiles_installer",
    company_name: "Asantys Systems GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/52b5452ad8f6e.jpg?v=1",
    address: "Stephansrieder Str. 4, 87724 Ottobeuren",
    telephone: "+49 8332 9254733",
    website: "http://www.asantys.com",
    email: "contact@asantys.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung, Training",
    Muttergesellschaft: "",
    "Last Update": 13112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hummer-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10406&utm_campaign=profiles_installer",
    company_name: "Hümmer Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5343bbd036027.jpg?v=1",
    address: "Gaußstrasse 158, 22765, Hamburg",
    telephone: "+49 40 39866610",
    website: "http://www.huemmer.com",
    email: "buero@huemmer.com",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/andre-biesold?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93905&utm_campaign=profiles_installer",
    company_name: "André Biesold",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87414d7e00.jpg?v=1",
    address: "Kiebitzweg. 2, 24211 Preetz",
    telephone: "+49 4342 799499",
    website: "http://www.biesold-holzbau.de",
    email: "biesold.montagebau@arcor.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dettelbacher-energiesysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76181&utm_campaign=profiles_installer",
    company_name: "Dettelbacher Energiesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57de13312bbd2.jpg?v=1",
    address: "Am Dreistock 17, 97318 Kitzingen",
    telephone: "+49 9321 3870317",
    website: "http://dettelbacher-energiesysteme.de",
    email: "a.dettelbacher@dettelbacher-energiesysteme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aco-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76205&utm_campaign=profiles_installer",
    company_name: "Aco Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae54d4c6d54.PNG?v=1",
    address: "Ostring 15, 63533 Mainhausen",
    telephone: "+49 6182 29966",
    website: "http://www.aco-solar.de",
    email: "polensky@aco-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,First Solar, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heiko-burkhardt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75103&utm_campaign=profiles_installer",
    company_name: "Heiko Burkhardt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57bcf4d30722a.jpg?v=1",
    address: "Hauptstraße 33, 06792 Sandersdorf",
    telephone: "+49 3493 88328",
    website: "http://www.solar-bitterfeld.de",
    email: "info@solar-bitterfeld.de",
    "Installaliert seit": 2000,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/fritz-schiffer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102004&utm_campaign=profiles_installer",
    company_name: "Fritz Schiffer GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab61dfdd0c1.jpg?v=1",
    address: "Van-der-Upwich-Str. 6, 41334 Nettetal",
    telephone: "+49 2153 91690",
    website: "http://www.schiffer-dach.de",
    email: "info@schiffer-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/media-home-schulz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93747&utm_campaign=profiles_installer",
    company_name: "media @ home Schulz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c062344976ef.jpg?v=1",
    address: "Berblingerstr. 1, 71254 Ditzingen",
    telephone: "+49 7156 9330",
    website: "https://www.mediaathome.de",
    email: "info@mediaathome.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kochkommt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13490&utm_campaign=profiles_installer",
    company_name: "Kochkommt Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/13490n.gif?v=1",
    address: "Lange Str. 71, 99947 Bad Langensalza",
    telephone: "+49 3603 846216",
    website: "http://kochkommt.de",
    email: "info@kochkom.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,,,SunPower Corp.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ja-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=598&utm_campaign=profiles_installer",
    company_name: "Ja-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56d9499a8b008.jpg?v=1",
    address: "Am Dorfteich 75, 19386 Kritzow OT Benzin",
    telephone: "+49 38 73156497",
    website: "http://www.jasolar.de",
    email: "info@jasolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Kyocera Corporation,,,Sharp Energy Solutions Europe,,,Viessmann Werke GmbH & Co. KG,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gebr-hett-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92495&utm_campaign=profiles_installer",
    company_name: "Gebr. Hett Haustechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a9094a56b24.jpg?v=1",
    address: "Kirdorfer Straße 60, 61350 Bad Homburg",
    telephone: "+49 6172 81014",
    website: "http://www.hett.de",
    email: "info@hett.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 15012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/helmut-fischer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92392&utm_campaign=profiles_installer",
    company_name: "Helmut Fischer HSU",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a77190e7824.jpg?v=1",
    address: "Kastanienstraße 29, 19057, Schwerin",
    telephone: "+49 385 4841105",
    website: "http://www.fischer-schwerin.de",
    email: "info@fischer-schwerin.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energy-insider?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93743&utm_campaign=profiles_installer",
    company_name: "Energy Insider GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c36ebc93e1c.jpg?v=1",
    address: "Weseler Str. 162, 45721 Haltern am See",
    telephone: "+49 2364 6089730",
    website: "http://www.energy-insider.info",
    email: "info@energy-insider.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Znshine PV-tech Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hoflich-inh-h-j-lieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103539&utm_campaign=profiles_installer",
    company_name: "Elektro Höflich Inh. H.-J. Lieb GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aebc2c17379c.jpg?v=1",
    address: "Kauschrübenstr. 8, 63762 Großostheim",
    telephone: "+49 6026 4749",
    website: "http://www.elektro-hoeflich.de",
    email: "info@elektro-hoeflich.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/anumar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102664&utm_campaign=profiles_installer",
    company_name: "Anumar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac981178e898.jpg?v=1",
    address: "Haunwöhrer Straße 21, 85051 Ingolstadt",
    telephone: "+49 8 419937380",
    website: "http://www.anumar.de",
    email: "info@anumar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Delta Energy Systems (Germany) GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/a-m-stein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94010&utm_campaign=profiles_installer",
    company_name: "A & M Stein GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9c84342350.jpg?v=1",
    address: "Industriestraße 11, 93192 Wald",
    telephone: "+49 9463 3719863",
    website: "http://www.ams-handwerk.de",
    email: "info@ams-handwerk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ab-dachbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11089&utm_campaign=profiles_installer",
    company_name: "AB Dachbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11089n.gif?v=1",
    address: "Alte Plantage 9, 15711, Königs Wusterhausen",
    telephone: "+49 3375 502427",
    website: "http://www.ab-dachbau.de",
    email: "info@ab-dachbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/absicherungs-und-aufsperrdienst?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93850&utm_campaign=profiles_installer",
    company_name: "Absicherungs- und Aufsperrdienst GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4bef5383fc.jpg?v=1",
    address: "Dietrich-Bonhoeffer-Straße 52, 59558 Lippstadt",
    telephone: "+49 2941 2970",
    website: "http://www.strom-vom-dach.info",
    email: "info@strom-vom-dach.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",Remus Group,",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ad-fontes-elbe-weser?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=608&utm_campaign=profiles_installer",
    company_name: "Ad fontes Elbe-Weser GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/608n.gif?v=1",
    address: "Drangstedter Str. 37, D-27624 Bad Bederkesa",
    telephone: "+49 4745 5162",
    website: "http://www.adfontes.de",
    email: "info@adfontes.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/adywip?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92015&utm_campaign=profiles_installer",
    company_name: "Adywip Ökologische Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f71d6145d2.jpg?v=1",
    address: "Schwenckestr, 16, 20255 Hamburg",
    telephone: "+49 4043 208131",
    website: "http://adywip.de",
    email: "info@adywip.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aet-heidel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91921&utm_campaign=profiles_installer",
    company_name: "AET-Heidel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599bd67ff1bda.jpg?v=1",
    address: "Lahnsteiner Straße 6, 07629 Hermsdorf / Thüringen",
    telephone: "+49 36601 91239",
    website: "http://www.aet-heidel.de",
    email: "info@aet-heidel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/afflerbach-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91919&utm_campaign=profiles_installer",
    company_name: "Afflerbach Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599bd544965b1.jpg?v=1",
    address: "Jägerkampweg 2, 83052 Bruckmühl",
    telephone: "+49 8062 7769423",
    website: "http://afflerbach-solar.de",
    email: "info@afflerbach-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ah-enertec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76179&utm_campaign=profiles_installer",
    company_name: "AH-EnerTec GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57de11d184e15.jpg?v=1",
    address: "Sommerfelder Weg 2, 04329 Leipzig",
    telephone: "+49 341 5909586",
    website: "http://www.ah-enertec.de",
    email: "info@ah-enertec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/aihtec-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=73905&utm_campaign=profiles_installer",
    company_name: "Aihtec Solar UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/579ac99d9c0e3.png?v=1",
    address: "Wolfstr. 16, 06110 Halle",
    telephone: "+49 345 13379080",
    website: "http://www.aihtec.de",
    email: "info@aihtec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/alpensolar-anlagenbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10051&utm_campaign=profiles_installer",
    company_name: "Alpensolar Anlagenbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/54992a4b16cdf.jpg?v=1",
    address: "Gewerbepark 25, 87477 Sulzberg-See",
    telephone: "+49 8376 928940",
    website: "http://www.alpensolar.de",
    email: "info@alpensolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/altech-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91931&utm_campaign=profiles_installer",
    company_name: "Altech Solar S.L.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599bdd4bf2d7e.jpg?v=1",
    address: "Flughafenstraße 21, 44309 Dortmund",
    telephone: "+49 2336 4087806",
    website: "http://www.altech-solar.com",
    email: "info@altech-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Spanien,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/anton-linzner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101808&utm_campaign=profiles_installer",
    company_name: "Anton Linzner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa723c5e8dc9.jpg?v=1",
    address: "Schulstraße 9, 75217 Birkenfeld",
    telephone: "+49 7082 920698",
    website: "http://www.linzner-bedachungen.de",
    email: "info@linzner-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/apuron-holding?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=97175&utm_campaign=profiles_installer",
    company_name: "Apuron Holding GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a24b0bf52696.jpg?v=1",
    address: "Gräsleinsgasse 1, 97509 Kolitzheim",
    telephone: "+49 9381 7108710",
    website: "http://www.apuron.com",
    email: "info@apuron.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Tschechische, Deutschland, Turkei, Rumänien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/aqs-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=41267&utm_campaign=profiles_installer",
    company_name: "AQS Solarenergie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57baab6b1d623.jpg?v=1",
    address: "Schubertstraße 9, 86836 Klosterlechfeld",
    telephone: "+49 8232 906778",
    website: "http://www.aqssolar.de",
    email: "info@aqssolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/as-projekte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124469&utm_campaign=profiles_installer",
    company_name: "AS Projekte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb65c770e3e4.jpg?v=1",
    address: "Göttinger Chaussee 12-14, 30453 Hannover",
    telephone: "+49 151 57121710",
    website: "http://www.asp-energie.de",
    email: "info@asp-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 28052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bahre-energie-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94824&utm_campaign=profiles_installer",
    company_name: "Bähre Energie- & Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df0e60f1e7f.jpg?v=1",
    address: "Bahnhofstr. 14, 27254 Siedenburg",
    telephone: "+49 4272 335",
    website: "http://www.baehre-haustechnik.de",
    email: "info@baehre-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bayernsolar-sud?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92049&utm_campaign=profiles_installer",
    company_name: "BayernSolar Süd GmbH & CO. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f9f371bc9c.jpg?v=1",
    address: "Mühldorfer Str. 14a, 84503 Altötting",
    telephone: "+49 8671 92850",
    website: "http://www.bayernsolar-sued.de",
    email: "info@bayernsolar-sued.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,ET Solar New Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bds-bedachungssysteme?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92171&utm_campaign=profiles_installer",
    company_name: "BDS Bedachungssysteme",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3d9d4c7cf0.jpg?v=1",
    address: "Ludwig-Mond-Straße 68, 34121 Kassel",
    telephone: "+49 561 5039375",
    website: "http://www.bds-bedachungssysteme.de",
    email: "info@bds-bedachungssysteme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/bernd-wienhold-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92133&utm_campaign=profiles_installer",
    company_name: "Bernd Wienhold e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3b58e37d6b.jpg?v=1",
    address: "Walgernweg 1a, 48231 Warendorf",
    telephone: "+49 2581 2854",
    website: "http://www.bernd-wienhold.de",
    email: "info@bernd-wienhold.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/berolina-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64055&utm_campaign=profiles_installer",
    company_name: "Berolina Solar GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/604f933b0acf7.png?v=1",
    address: "Gewerbegebiet zum Wasserwerk 8c, 15537 Erkner",
    telephone: "+49 3362 931180",
    website: "http://www.berolina-solar.de",
    email: "info@berolina-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 16032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bichtawi-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94358&utm_campaign=profiles_installer",
    company_name: "Bichtawi Bedachungen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6010689801323.png?v=1",
    address: "Sommerlandstrasse 68b, 47551 Bedburg Hau",
    telephone: "+49 2824 9753330",
    website: "http://www.bichtawi-bedachungen.de",
    email: "info@bichtawi-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/bio-solar-haus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=113334&utm_campaign=profiles_installer",
    company_name: "Bio-Solar-Haus® GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c9d87946834c.jpg?v=1",
    address: "Alban St., Sonnenpark, 67813",
    telephone: "+49 6362 92270",
    website: "https://www.bio-solar-haus.de",
    email: "info@bio-solar-haus.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/biosolar-energie-umwelttechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=581&utm_campaign=profiles_installer",
    company_name: "BioSolar- Energie- & Umwelttechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/581n.gif?v=1",
    address: "Glockengießerstraße 38/8, 23552 Lübeck",
    telephone: "+49 451 72924",
    website: "http://www.bio-solar.de",
    email: "info@bio-solar.de",
    "Installaliert seit": 1992,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/blu?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92047&utm_campaign=profiles_installer",
    company_name: "blu GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599f9e66a5ba7.jpg?v=1",
    address: "Münsterstraße 108, 44145 Dortmund",
    telephone: "+49 231 8297957",
    website: "http://www.bluenergie.de",
    email: "info@bluenergie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/boss-pro-tec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=114292&utm_campaign=profiles_installer",
    company_name: "Boss Pro-Tec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5cce42a0b466b.jpg?v=1",
    address: "Heinrich-Heine-Str. 45, Onstmettingen, 72461, Albstadt",
    telephone: "+49 7432 98380",
    website: "https://www.boss-pro-tec.de",
    email: "info@boss-pro-tec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Indien,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 16072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/braunstein-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75945&utm_campaign=profiles_installer",
    company_name: "Braunstein Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d20b7461a44.jpg?v=1",
    address: "Haardter Treppenweg 14, 67433 Neustadt",
    telephone: "+49 6321 482000",
    website: "http://www.braunstein-solar.de",
    email: "info@braunstein-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Aleo Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/brinker-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95087&utm_campaign=profiles_installer",
    company_name: "Brinker Bedachungen e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85da522390.jpg?v=1",
    address: "Gartenstraße 18a, 58256 Ennepetal",
    telephone: "+49 2333 860088",
    website: "http://www.brinker-bedachungen.de",
    email: "info@brinker-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/buch-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93537&utm_campaign=profiles_installer",
    company_name: "Buch Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c07d085307b.jpg?v=1",
    address: "Januarius-Zick-Str. 17, 54296 Trier",
    telephone: "+49 651 5611426",
    website: "http://buch-solar.de",
    email: "info@buch-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solar Frontier K.K.,,,Risen Energy Co., Ltd.,,,AXITEC Energy GmbH & Co. KG,,,Viessmann Werke GmbH & Co. KG,,,Znshine PV-tech Co., Ltd.,,,Tesla Energy,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/chr-schminke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95081&utm_campaign=profiles_installer",
    company_name: "Chr. Schminke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e85a54dc3b2.jpg?v=1",
    address: "Auf dem Krombach 2, 34308 Bad Emstal",
    telephone: "+49 5624 6186",
    website: "http://www.schminke-dach.de",
    email: "info@schminke-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-solaranlagen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92830&utm_campaign=profiles_installer",
    company_name: "Christian Jessel Elektro & Solaranlagen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0b3cb091f1.jpg?v=1",
    address: "Poststraße 17, 19230 Hagenow",
    telephone: "+49 3883 619140",
    website: "http://www.jessel-elektro.de",
    email: "info@jessel-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 18012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/cm-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92144&utm_campaign=profiles_installer",
    company_name: "CM-Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3bd0946ba6.jpg?v=1",
    address: "Ludwig-Thoma-Straße 13 B, D - 83233 Bernau am Chiemsee",
    telephone: "+49 8051 9654455",
    website: "http://www.cmsolar.de",
    email: "info@cmsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",Enphase Energy, Inc.,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/coldewey?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10080&utm_campaign=profiles_installer",
    company_name: "Coldewey GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5191b0473cdf9.gif?v=1",
    address: "Burgstraße 6, 26655 Westerstede",
    telephone: "+49 4488 83840",
    website: "http://www.coldewey.de",
    email: "info@coldewey.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/contech-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93828&utm_campaign=profiles_installer",
    company_name: "Contech Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b2e2293fb.jpg?v=1",
    address: "Kapfing 20, 94551 Lalling",
    telephone: "+49 9904 84000",
    website: "http://contech-info.com",
    email: "info@contech-info.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Danfoss A/S,,,Samil Power Co., Ltd.,,,Nedap Energy Systems,",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/css-3?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58285&utm_campaign=profiles_installer",
    company_name: "Customized Solar Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/55c94d37bafa9.jpg?v=1",
    address: "Friedrichshafener Str. 77, 88131 Lindau",
    telephone: "+49 8382 2733454",
    website: "http://www.css-solar.de",
    email: "info@css-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdecker-munchen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82737&utm_campaign=profiles_installer",
    company_name: "Dachdecker München",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b3c271dbf33.jpg?v=1",
    address: "Mangfallweg 3, 85653 Aying",
    telephone: "+49 89 41850722",
    website: "http://www.dachdecker-muenchen-bayern.de",
    email: "info@dachdecker-muenchen-bayern.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeister-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94375&utm_campaign=profiles_installer",
    company_name: "Bert Schmidt Dachdeckermeister GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59db28d9bb727.png?v=1",
    address: "Feldtmannstraße 26, 13088 Berlin",
    telephone: "+49 30 9258640",
    website: "http://www.dachdeckerei-berlin-brandenburg.de",
    email: "info@dachdeckerei-berlin-brandenburg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dachdeckermeisterin?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94200&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeisterin Arel Graumann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb37fb18fe.png?v=1",
    address: "Neptunallee 4, 18057 Rostock",
    telephone: "+49 172 9095824",
    website: "http://www.arel.de",
    email: "info@arel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dba-energie-technik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=86024&utm_campaign=profiles_installer",
    company_name: "DBA Energie Technik UG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bc3eb79ee1a0.png?v=1",
    address: "Altpoderschauerweg 1, 04610 Meuselwitz",
    telephone: "+49 177 7492233",
    website: "https://www.dba-energie-technik.de",
    email: "info@dba-energie-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Spanien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/delta-electronic-munchen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=6622&utm_campaign=profiles_installer",
    company_name: "Delta Electronic München GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59afbd290e9b1.jpg?v=1",
    address: "Quickborner Str. 79, 25494, Borstel-Hohenraden",
    telephone: "+49 16 21056185",
    website: "https://www.delta-electronic.de",
    email: "info@delta-electronic.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland, Frankreich,,Asien, Afrika",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,,,Solarwatt GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dexas-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124467&utm_campaign=profiles_installer",
    company_name: "Dexas GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5eb65bc1524bb.jpg?v=1",
    address: "Rutenbergstr. 41a, 30559 Hanover",
    telephone: "+49 511 30090030",
    website: "http://www.dexas.de",
    email: "info@dexas.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/dpi-solar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93786&utm_campaign=profiles_installer",
    company_name: "DPI-Solar Energiespar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c473fabc244.jpg?v=1",
    address: "Rudelsburgstr. 2, 13129 Berlin",
    telephone: "+49 30 53210758",
    website: "http://www.dpi-solar.de",
    email: "info@dpi-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 22012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dr-bauch?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1740&utm_campaign=profiles_installer",
    company_name: "Dr. Bauch GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d27da57d3e3.jpg?v=1",
    address: "Meeraner Str. 153a, 08371 Glauchau",
    telephone: "+49 3763 78180",
    website: "http://www.bauch-technik.de",
    email: "info@bauch-technik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/drebo-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83182&utm_campaign=profiles_installer",
    company_name: "DREBO Bauträger GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58be224abd117.jpg?v=1",
    address: "Bünaustraße 1, 01159 Dresden",
    telephone: "+49 351 4275233",
    website: "http://www.drebo-solar.de",
    email: "info@drebo-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7052020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ecap-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=108862&utm_campaign=profiles_installer",
    company_name: "ecap Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bb03ff22f73e.jpg?v=1",
    address: "Hans-Henny-Jahnn-Weg 53, 22085 Hamburg",
    telephone: "+49 4022 696690",
    website: "https://ecapsolutions.eu",
    email: "info@ecapsolutions.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/ecosteps-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92211&utm_campaign=profiles_installer",
    company_name: "Ecosteps GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4e06cdacad.jpg?v=1",
    address: "Siemensstraße 25, 50374 Erftstadt",
    telephone: "+49 2235 1703640",
    website: "http://www.ecosteps-solar.de",
    email: "info@ecosteps-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Aleo Solar GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/edv-und-elektrotechnik-hardy-barth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94353&utm_campaign=profiles_installer",
    company_name: "EDV- und Elektrotechnik Hardy Barth GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59daca2e508bd.jpg?v=1",
    address: "Leinbergstr. 16, D-92262 Birgland, Schwend",
    telephone: "+49 9666 951224",
    website: "http://www.hardy-barth.de",
    email: "info@hardy-barth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",IBC Solar AG,,,Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 27012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/effizienz-haus-plus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74699&utm_campaign=profiles_installer",
    company_name: "Effizienz-Haus-Plus Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b28a7abcc6e.jpg?v=1",
    address: "Dorfplatz 8, 93473 Arnschwang",
    telephone: "+49 9977 903582",
    website: "http://natural-energy-concepts.de",
    email: "info@natural-energy-concepts.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/eichert-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94034&utm_campaign=profiles_installer",
    company_name: "Eichert Elektrotechnik GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c9ec789d8e2.jpg?v=1",
    address: "Am Rittergut 1, 34393 Grebenstein/Schachten",
    telephone: "+49 5674 925262",
    website: "http://www.eichert-elektro.de",
    email: "info@eichert-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/eichsfeldsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81744&utm_campaign=profiles_installer",
    company_name: "Eichsfeldsolar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589538fd07e62.jpg?v=1",
    address: "Marienstraße 2, 37318 Uder",
    telephone: "+49 36 08354355",
    website: "http://www.eichsfeldsolar.de",
    email: "info@eichsfeldsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Aleo Solar GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/el-com?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106231&utm_campaign=profiles_installer",
    company_name: "EL-Com Elektroinstallation & Kommunikationstechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b5028038a2c8.png?v=1",
    address: "Berliner Straße 18-20, 04105 Leipzig",
    telephone: "+49 341 4771096",
    website: "http://www.el-com.info",
    email: "info@el-com.info",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/electric-service-lutz-knoll?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94657&utm_campaign=profiles_installer",
    company_name: "Electric-Service Lutz Knoll",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddbb1485df7.png?v=1",
    address: "Jüterboger Straße 36, 14929 Treuenbrietzen",
    telephone: "+49 33748 12697",
    website: "http://www.electric-service-knoll.de",
    email: "info@electric-service-knoll.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-anton-vogel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94811&utm_campaign=profiles_installer",
    company_name: "Elektro Anton Vogel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df023a83ba6.jpg?v=1",
    address: "Gartenstraße 10, 86944 Oberdießen",
    telephone: "+49 8243 1071",
    website: "http://www.elektro-antonvogel.de",
    email: "info@elektro-antonvogel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Stiebel Eltron GmbH & Co. KG,,,Bosch (XiaMen) New Energy Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Siemens Energy,,,Kostal Solar Electric GmbH,,,Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-brix?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92225&utm_campaign=profiles_installer",
    company_name: "Elektro Brix GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4e9af6a935.jpg?v=1",
    address: "Ratsteich 8, 24837 Schleswig",
    telephone: "+49 4621 51424",
    website: "http://www.elektro-brix.de",
    email: "info@elektro-brix.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-fein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94179&utm_campaign=profiles_installer",
    company_name: "Elektro Fein GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ccaced0365d.png?v=1",
    address: "Loggerstraße 15, 26386 Wilhelmshaven",
    telephone: "+49 4421 409090",
    website: "http://www.elektrofein.de",
    email: "info@elektrofein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fimer S.p.A.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-guggenmos?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=16850&utm_campaign=profiles_installer",
    company_name: "Elektro Guggenmos GmbH & Co.KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/16850n.gif?v=1",
    address: "Salzstraße 21, 87782, Warmisried",
    telephone: "+49 8341 69574",
    website: "http://elektro-guggenmos.de",
    email: "info@elektro-guggenmos.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,,,ABB Italy S.p.A.,,,Danfoss A/S,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-hardy-baura?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92224&utm_campaign=profiles_installer",
    company_name: "Elektro Hardy Baura GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a4e91e60728.jpg?v=1",
    address: "Georg-Wilhelm-Straße 265, 21107 Hamburg",
    telephone: "+49 40 75492729",
    website: "http://www.elektro-baura.de",
    email: "info@elektro-baura.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-haupenthal?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102582&utm_campaign=profiles_installer",
    company_name: "Elektro Haupenthal",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac448050d860.png?v=1",
    address: "Heidestraße 27, 55776 Rohrbach",
    telephone: "+49 6789 646",
    website: "http://www.elektro-haupenthal.de",
    email: "info@elektro-haupenthal.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,Stiebel Eltron GmbH & Co. KG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Schneider Electric SA,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 30082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-huhn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75737&utm_campaign=profiles_installer",
    company_name: "Elektro Hühn Ulrich Hühn",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ce17317c4f0.jpg?v=1",
    address: "Franziusallee 127, 24148 Kiel",
    telephone: "+49 431 722155",
    website: "http://www.elektro-huehn.de",
    email: "info@elektro-huehn.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",IBC Solar AG,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-lubberger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94790&utm_campaign=profiles_installer",
    company_name: "Elektro Lubberger",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6012006e9657f.png?v=1",
    address: "Gottlieb-Fecht-Str. 30, 77694 Kehl/Kork",
    telephone: "+49 7851 9947680",
    website: "http://www.elektro-lubberger.de",
    email: "info@elektro-lubberger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Kyocera Corporation,,,S-Energy Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-weissert?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7280&utm_campaign=profiles_installer",
    company_name: "Elektro Weissert",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c2b7f03e89b.gif?v=1",
    address: "Altensteiger Strasse 2, 72224, Ebhausen",
    telephone: "+49 745 8341",
    website: "http://www.elektro-weissert.de",
    email: "info@elektro-weissert.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektro-liebald?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93830&utm_campaign=profiles_installer",
    company_name: "Elektro-Liebald e.K.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c4b3f663188.jpg?v=1",
    address: "Marktplatz 2, 95158 Kirchenlamitz",
    telephone: "+49 170 6411522",
    website: "http://www.elektro-liebald.de",
    email: "info@elektro-liebald.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-zimmermann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92253&utm_campaign=profiles_installer",
    company_name: "elektrotechnik Zimmermann",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a5130d334b0.jpg?v=1",
    address: "W. Raiffeisenstrasse 8, 17192 Waren (Mueritz)",
    telephone: "+49 3991 64477",
    website: "http://www.elektro-technik-zimmermann.de",
    email: "info@elektro-technik-zimmermann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/elektrotechnik-budde?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94144&utm_campaign=profiles_installer",
    company_name: "Elektrotechnik-Budde",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cc4db70af90.jpg?v=1",
    address: "Hohe Str. 23, 24806 Hohn",
    telephone: "+49 4335 7319127",
    website: "http://www.elektrotechnik-budde.de",
    email: "info@elektrotechnik-budde.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/elinta?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94592&utm_campaign=profiles_installer",
    company_name: "Elinta Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd7820e4f0b.png?v=1",
    address: "Westendstraße 49, 63128 Dietzenbach",
    telephone: "+49 9800 86729",
    website: "https://elinta.de",
    email: "info@elinta.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 10032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/emt2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94057&utm_campaign=profiles_installer",
    company_name: "EMT2 GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ca066b082d4.jpg?v=1",
    address: "Gewerbegebiet 8, 48480, Lünne",
    telephone: "+49 5906 9330414",
    website: "https://www.emt2-energie.de",
    email: "info@emt2-energie.de",
    "Installaliert seit": 2012,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/energie-konzepte-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102370&utm_campaign=profiles_installer",
    company_name: "Energie Konzepte Matbec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9c025079e9.png?v=1",
    address: "Boschstr. 62-66, 50171 Kerpen",
    telephone: "+49 2237 6566850",
    website: "http://www.matbec-gmbh.de",
    email: "info@matbec-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Bauer Energiekonzepte GmbH,",
    "Wechselrichter-Lieferanten":
      ",Fronius International GmbH,,,Kostal Solar Electric GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/enviam?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107922&utm_campaign=profiles_installer",
    company_name: "Envia Mitteldeutsche Energie AG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f61e7b949f39.png?v=1",
    address: "Chemnitztalstraße 13, 09114 Chemnitz",
    telephone: "+49 371 4820",
    website: "https://www.solar.enviam.de",
    email: "info@solar.enviam.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",EnviaM,",
    "Last Update": 16092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/evo-deutschland-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=128400&utm_campaign=profiles_installer",
    company_name: "EVO Deutschland GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f9a21b44e4d5.png?v=1",
    address: "Robert-Bosch-Strasse 2-6, 88487, Mietingen",
    telephone: "+49 800 2505050",
    website: "https://www.e-v-o.de/",
    email: "info@e-v-o.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/ews-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=651&utm_campaign=profiles_installer",
    company_name: "EWS - Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/651n.gif?v=1",
    address: "Heidestr. 22, 59174 Kamen",
    telephone: "+49 2307 933190",
    website: "http://www.ews-dieckheuer.de/",
    email: "info@ews-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/ewug-services?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7258&utm_campaign=profiles_installer",
    company_name: "EWUG Services KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5761f756d2e80.jpg?v=1",
    address: "At Raumfabrik 31, 76227, Karlsruhe",
    telephone: "+49 721 5099930",
    website: "http://www.ewug-services.de/",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/ewv-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94852&utm_campaign=profiles_installer",
    company_name: "EWV energy and water supply GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df24a09f97f.png?v=1",
    address: "Willy-Brandt-Platz 2, D-52222 Stolberg",
    telephone: "+49 800 3981000",
    website: "https://www.ewv.de/privatkunden",
    email: "service@ewv.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/exyte?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122269&utm_campaign=profiles_installer",
    company_name: "Exyte GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e4cd83f18fe3.png?v=1",
    address: "Loewentorbogen 9b, 70376, Stuttgart",
    telephone: "+49 711 88040",
    website: "https://www.exyte.net/en",
    email: "info@exyte.net",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/f-s-solar-concept?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4276&utm_campaign=profiles_installer",
    company_name: "F & S Solar Concept GmbH",
    logo_url: "http://www.fs-sun.com/",
    address: "Otto-Lilienthal-Strasse 34, 53879 Euskirchen",
    telephone: "+49 2551 14820",
    website: "http://www.fs-sun.com/",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/f-ee?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=27949&utm_campaign=profiles_installer",
    company_name: "F.EE GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51bfe1c733ee9.jpg?v=1",
    address: "In the Seugn 10 and 20, D-92431, Neunburg vorm Wald",
    telephone: "+49 9672 5060",
    website: "https://www.fee.de/en.html",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/f-l-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94214&utm_campaign=profiles_installer",
    company_name: "F + L Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdbb96c2129.jpg?v=1",
    address: "Florianstrasse 5, 53639 Königswinter, Bockeroth",
    telephone: "+49 2244 8740944",
    website: "https://www.elektro-fl.de/",
    email: "info@elektro-fl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/faethon-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=112417&utm_campaign=profiles_installer",
    company_name: "Faethon Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5c6b82a582642.jpg?v=1",
    address: "Lohrbergstrasse 14, Maintal, 63477",
    telephone: "+49 6181 491344",
    website: "http://www.faethonsolar.com/",
    email: "info@faethonsolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/falk-janke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124060&utm_campaign=profiles_installer",
    company_name: "Master electrician Falk & Janke",
    logo_url: "http://www.solaranlage-in.de/",
    address: "Hoffmann-von-Fallersleben Strasse 10, Wolfenbüttel, 38304",
    telephone: "+49 5311 949933",
    website: "http://www.solaranlage-in.de/",
    email: "mail@falk-janke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/famex-farchoukh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56615&utm_campaign=profiles_installer",
    company_name: "Famex Farchoukh GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/601d2a7ad68b6.png?v=1",
    address: "Philipsstrasse 8, 52068 Aachen",
    telephone: "+49 241 88691342",
    website: "www.famex-germany.de",
    email: "info@famex-germany.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/feg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10103&utm_campaign=profiles_installer",
    company_name: "Manufacturing & Development Company mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10103n.gif?v=1",
    address: "Franz-Mehring Strasse 18, 99610 Sömmerda",
    telephone: "+49 3634 68580",
    website: "https://www.fegmbh.de/",
    email: "info@fegmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fellhauer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84813&utm_campaign=profiles_installer",
    company_name: "Fellhauer Solar & Roof",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60772472d4469.PNG?v=1",
    address: "Opelstrasse 19, 68789 St. Leon-Rot",
    telephone: "+49 06227 – 39 84 89 6",
    website: "http://www.fellhauer-solar-dach.de/",
    email: "info@febesol.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fenderl-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=29963&utm_campaign=profiles_installer",
    company_name: "Fenderl Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51f88fb424d2a.gif?v=1",
    address: "Amendestr. 92, 13409, Berlin",
    telephone: "+49 2571 586680",
    website: "http://www.fenderl.eu/",
    email: "info@fenderl.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/feneco?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8506&utm_campaign=profiles_installer",
    company_name: "Feneco GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/619cf0e040608.PNG?v=1",
    address: "Hochfeldstrasse 12, 94538 Fürstenstein",
    telephone: "+49 8504 918424",
    website: "https://www.feneco.de/",
    email: "karlsruhe@feneco.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/ferber?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95205&utm_campaign=profiles_installer",
    company_name: "Ferber GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59effef96c620.png?v=1",
    address: "To Hartwiese 43, 66679, Losheim am See",
    telephone: "+49 6872 3606",
    website: "https://www.ferber-dach.de/",
    email: "info@ferber-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/ferrara-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81578&utm_campaign=profiles_installer",
    company_name: "Ferrara Haustechnik GmbH &. Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/588558d62c922.jpg?v=1",
    address: "Limbacher Str. 1, 91126 Schwabach",
    telephone: "+49 9122 86701",
    website: "http://www.ferrara-haustechnik.com/",
    email: "info@ferrara-haustechnik.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fesl-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81562&utm_campaign=profiles_installer",
    company_name: "Fesl Gebäudetechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5884706b4916a.jpg?v=1",
    address: "Im Farchet 30, 83646 Bad Tölz",
    telephone: "+49 8041 76860",
    website: "http://www.fesl.onlineshk.de/",
    email: "info@fesl.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fg-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92403&utm_campaign=profiles_installer",
    company_name: "FG Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a781594f8f9.jpg?v=1",
    address: "Lärchenweg 5a, 97267, Himmelstadt",
    telephone: "+49 9364 810625",
    website: "http://www.fg-energy.de/",
    email: "info@fg-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fh-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93604&utm_campaign=profiles_installer",
    company_name: "FH Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c0dec45f898.jpg?v=1",
    address: "Bachstrasse 479346, Endingen am Kaiserstuhl",
    telephone: "+49 7642 9174026",
    website: "http://www.fh-photovoltaik.de/",
    email: "fischer@fh-photovoltaik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fi-da?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10313&utm_campaign=profiles_installer",
    company_name: "Fischer and Daumüller GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5767415034f03.jpg?v=1",
    address: "Stützenweg 11, 70794 Filderstadt, Bernhausen",
    telephone: "+49 711 701112",
    website: "https://www.fi-da.de/",
    email: "info@fi-da.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fink?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=105051&utm_campaign=profiles_installer",
    company_name: "finch",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b20c13bb5fc4.jpg?v=1",
    address: "Tristolzer Weg 28, 88416 Bellamont",
    telephone: "+49 7358 1313",
    website: "https://fink-bad-heizung.de/",
    email: "info@fink-bad-heizung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fink-bedachungen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10421&utm_campaign=profiles_installer",
    company_name: "Fink GmbH & Co. Bedachungen KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/583e49380f077.jpg?v=1",
    address: "Am Illinger Eck 17, 75428 Illingen",
    telephone: "+49 7042 82400",
    website: "https://finkdach.de/",
    email: "info@finkdach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/firma-gamper-holzbau-bauprojekt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94969&utm_campaign=profiles_installer",
    company_name: "Gamper Holzbau - Bauprojekt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e06c5a72b90.jpg?v=1",
    address: "Plattenäcker 9, 89287 Bellenberg",
    telephone: "+49 7306 929250",
    website: "https://www.holzbau-gamper.de/",
    email: "info@gamper-holzbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/firnas-shuman?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=84916&utm_campaign=profiles_installer",
    company_name: "Firnas Shuman LLC",
    logo_url: "https://cdn.enfsolar.com/z/s/logos/4985f3cf1a43ad8c.jpg?v=1",
    address:
      "2 Osman Towers, 24th floor, Diplomats street, Nile Corniche, Maadi, Cairo",
    telephone: "+20 2 25241799",
    website: "firnasshuman.com",
    email: "solar@firnasshuman.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fischer-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102032&utm_campaign=profiles_installer",
    company_name: "Fischer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab727ed9d89.jpg?v=1",
    address: "Dorfstrasse 5, 95339 Neuenmarkt",
    telephone: "+49 9227 73241",
    website: "https://www.fischer-neuenmarkt.de",
    email: "info@fischer-neuenmarkt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fladung-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11882&utm_campaign=profiles_installer",
    company_name: "Fladung Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/11882n.gif?v=1",
    address: "Im Johannistal 31a, 52064, Aachen",
    telephone: "+49 241 9903312",
    website: "http://aepvi.com/",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fleckenstein-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=124486&utm_campaign=profiles_installer",
    company_name: "Fleckenstein Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6123a3529c152.PNG?v=1",
    address: "Libnower Landstrasse 1, Murchin, 17390",
    telephone: "+49 3971 876900",
    website: "https://fleckenstein-solar.de/",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/flossmann-grunbeck?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92404&utm_campaign=profiles_installer",
    company_name: "Flossmann & Grünbeck GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a7824f1a3ac.jpg?v=1",
    address: "Maxfeldstrasse 25, 90409 Nuremberg",
    telephone: "+49 911 935350",
    website: "https://www.flossmann-gruenbeck.de/",
    email: "info@flossmann-gruenbeck.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fluck-holzbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94972&utm_campaign=profiles_installer",
    company_name: "Fluck Holzbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60f116013c16e.PNG?v=1",
    address: "Alemannenstrasse 48, D 78176, Blumberg",
    telephone: "+41 52640 2972",
    website: "https://fluck-holzbau.de/",
    email: "info@fluck-holzbau.ch",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fm-elektrotechnik-photovoltaik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93242&utm_campaign=profiles_installer",
    company_name: "FM Elektrotechnik & Photovoltaik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b9f17be4d74.jpg?v=1",
    address: "Ortsstrasse 7, 98744 Cursdorf",
    telephone: "+49 36705 21877",
    website: "http://www.fmet.de/",
    email: "info@fmet.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/focusenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102682&utm_campaign=profiles_installer",
    company_name: "FocusEnergie GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac98e241ae06.jpg?v=1",
    address: "Mozartstrasse 24, D-79104 Freiburg",
    telephone: "+49 761 388 408 0",
    website: "https://www.focus-energie.de/cms/",
    email: "anfrage@focus-energie.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/fokus?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2000&utm_campaign=profiles_installer",
    company_name: "Focus Energie-Systeme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/575f55195ac1f.jpg?v=1",
    address: "Rensingstrasse 11, D-44807 Bochum",
    telephone: "+49 234 54 09 210",
    website: "http://www.fokus-energie-systeme.de/",
    email: "info@fokus-energie-systeme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-ruth?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=81750&utm_campaign=profiles_installer",
    company_name: "Frank + Ruth GmbH & Co. Electrical Engineering",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589549af2f582.jpg?v=1",
    address: "Böckinger Strasse 35, 74078 Heilbronn",
    telephone: "+49 7131 21052",
    website: "https://www.frank-ruth.de/home.html",
    email: "info@frank-ruth.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-becker-sanitar-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82724&utm_campaign=profiles_installer",
    company_name:
      "Frank Becker Innovative Building Services Sanitary - Solar - Heating",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b39030a8718.jpg?v=1",
    address: "Kreuzstrasse 34, 52388 Nörvenich",
    telephone: "+49 2421 123652",
    website: "https://shk-fbecker.de/",
    email: "info@shk-fbecker.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-geils?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10105&utm_campaign=profiles_installer",
    company_name: "Frank Geils GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10105n.gif?v=1",
    address: "Nagelschmiedsweg 15-19, 27356 Rotenburg",
    telephone: "+49 4261 8488 01",
    website: "https://www.frank-geils.de/",
    email: "info@frank-geils.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-huttemann?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92808&utm_campaign=profiles_installer",
    company_name: "Frank Hüttemann roofing",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b0a4bf708bc.jpg?v=1",
    address: "Küllenhahner Strasse 242, 42349, Wuppertal",
    telephone: "(0202) 976 55 40",
    website: "http://www.huettemann-bedachungen.de/",
    email: "info@huettemann-bedachungen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-koch-dachdeckermeisterbetrieb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10707&utm_campaign=profiles_installer",
    company_name: "Frank Koch master roofer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5acc35302757b.jpg?v=1",
    address: "Feldsiefer Wiesen 32, 51381 Leverkusen",
    telephone: "(02171) 76 85 99",
    website: "https://dach-frankkoch.de/",
    email: "info@dach-frankkoch.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-linow-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77813&utm_campaign=profiles_installer",
    company_name: "",
    logo_url: "",
    address: "",
    telephone: "",
    website: "",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-rothgaenger-elektroservice-und-gebaudetechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94692&utm_campaign=profiles_installer",
    company_name:
      "Frank Rothgaenger Electrical Service and Building Technology GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e97d2654c5db.png?v=1",
    address: "Helmut-Nack-Str. 8, 21035, Hamburg",
    telephone: "+49 735 08 512",
    website: "http://www.frankrothgaenger.de/",
    email: "FrankRothgaenger@gmx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92154&utm_campaign=profiles_installer",
    company_name: "Frank Schmidt",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3c674d3c15.jpg?v=1",
    address: "Sasendorfer Str. 16, 29549 Bad Bevensen",
    telephone: "+49 5821 992190",
    website: "http://dachdeckerei-f-schmidt.de/",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-scholze-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11856&utm_campaign=profiles_installer",
    company_name: "Frank Scholze Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51cc026551474.gif?v=1",
    address: "",
    telephone: "03591 302041",
    website: "https://www.elektro-scholze-bautzen.de/",
    email: "info@elektro-scholze.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frank-wenzel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75249&utm_campaign=profiles_installer",
    company_name: "Frank Wenzel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57be8618be068.jpg?v=1",
    address: "Menckesallee 28, 22089 Hamburg, Germany",
    telephone: "040-20 77 44",
    website: "http://www.frank-wenzel-gmbh.de/",
    email: "mail@frank-wenzel-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/franke-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74617&utm_campaign=profiles_installer",
    company_name: "Franke Elektrotechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57b15a9cf295b.jpg?v=1",
    address: "To Salzleite 2, 91586 Lichtenau",
    telephone: "09827 - 92 89 600",
    website: "https://solar-technik.elektro-franke.de/home/",
    email: "info@elektro-franke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/frankfurt-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=14415&utm_campaign=profiles_installer",
    company_name: "Frankfurt Energy Holding GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/536066744b040.gif?v=1",
    address: "Ginnheimer Strasse 4, 65760 Eschborn, Frankfurt a. M.",
    telephone: "",
    website: "https://www.frankfurt-energy.de/",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/franz-alberty?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91967&utm_campaign=profiles_installer",
    company_name: "Franz Alberty Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e4be16e03d.jpg?v=1",
    address: "Oberrather Str.27A, 40472, Düsseldorf",
    telephone: "+49 211 652070",
    website: "https://www.alberty-haustechnik.de/",
    email: "service@alberty-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/freitag-s-elektrik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=74419&utm_campaign=profiles_installer",
    company_name: "Freitag's Elektrik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ac207ec68e5.jpg?v=1",
    address: "Neue Siedlung 4, 06313 Ahlsdorf",
    telephone: "+49 34772 27593",
    website: "http://www.freitags-elektrik.de",
    email: "info@freitags-elektrik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/fritz-kadletz-energiemanager?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99174&utm_campaign=profiles_installer",
    company_name: "Fritz Kadletz Energiemanager ( E.E.M. - IHK )",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c1d89a8c69.png?v=1",
    address: "Taubenweg 6, 94428 Eichendorf",
    telephone: "+49 871 206623610",
    website: "http://www.strahlenwaerme.de",
    email: "info@strahlenwaerme.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",KACO New Energy GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/glasmetall?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94590&utm_campaign=profiles_installer",
    company_name: "GlasMetall GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d6e03643c06b.jpg?v=1",
    address: "Lemförder Straße 90, 32369 Rahden",
    telephone: "+49 5771 97100",
    website: "https://www.glasmetall.de",
    email: "info@glasmetall.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 23112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/goehrke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75683&utm_campaign=profiles_installer",
    company_name: "Goehrke - Bedachung und Solartechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57ccdee92160c.jpg?v=1",
    address: "Nikolausstrasse 2a, 53894 Mechernich",
    telephone: "+49 177 8343903",
    website: "https://www.facebook.com/Goehrkedach/",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/grun-leben?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56043&utm_campaign=profiles_installer",
    company_name: "Grün leben GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5caaf3ceae8d1.png?v=1",
    address: "Obere Waiblingerstr. 164, 70374 Stuttgart",
    telephone: "+49 711 5041416",
    website: "http://www.abrosgreen.com",
    email: "info@abrosgreen.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Afrika,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/gungowski-elektrobau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=106244&utm_campaign=profiles_installer",
    company_name: "Gungowski Elektrobau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b50326d725b2.png?v=1",
    address: "Kleine Witschgasse 11, 50676 Köln",
    telephone: "+49 221 33779510",
    website: "http://www.gungowski-elektrobau.de",
    email: "info@gungowski-elektrobau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 25022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/h-barge?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91983&utm_campaign=profiles_installer",
    company_name: "H. Barge",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599e74d27dae1.jpg?v=1",
    address: "Hauptstraße 17, 29476 Gusborn/OT Quickborn",
    telephone: "+49 5865 1333",
    website: "http://barge-haustechnik.de",
    email: "info@barge-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/harjes-elektrotechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77321&utm_campaign=profiles_installer",
    company_name: "Harjes Elektrotechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6082e7417380a.PNG?v=1",
    address: "Am Hillernsen Hamm 39, 26441 Jever",
    telephone: "+49 4461 3315",
    website: "http://www.elektrotechnik-harjes.de",
    email: "info@elektrotechnik-harjes.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-fur-energiesparer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=616&utm_campaign=profiles_installer",
    company_name: "Haustechnik Für Energiesparer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e8455db40946.jpg?v=1",
    address: "Glockengießerstraße 77-5, 23552 Lübeck Schleswig-Holstein",
    telephone: "+49 800 7666555",
    website: "http://www.holstein-solar.de",
    email: "info@holstein-solar.de",
    "Installaliert seit": 1995,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/haustechnik-werwein?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56599&utm_campaign=profiles_installer",
    company_name: "Haustechnik Werwein",
    logo_url: "https://cdn.enfsolar.com/ID/logo/558a05f594923.jpg?v=1",
    address: "Kreuz 56, 95445 Bayreuth",
    telephone: "+49 176 64871400",
    website: "http://www.haustechnik-werwein.de",
    email: "info@haustechnik-werwein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11122019,
  },
  {
    company_url:
      "https://de.enfsolar.com/heat-warmesystem?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2005&utm_campaign=profiles_installer",
    company_name: "HEAT Wärmesysteme GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51c9049b89bbd.gif?v=1",
    address: "Eisenbahnstrasse 36, D-73235, Weilheim",
    telephone: "+49 7023 94980",
    website: "http://heat.de/",
    email: "info@heat.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",GermanSolar AG,,,GermanPV GmbH,",
    "Wechselrichter-Lieferanten":
      ",SolarEdge Technologies, Ltd.,,,Fimer S.p.A.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heiko-albers-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91926&utm_campaign=profiles_installer",
    company_name: "Heiko Albers Haustechnik",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599bd970cc8b5.jpg?v=1",
    address: "Bonnstraße 26, 45470 Mülheim an der Ruhr",
    telephone: "+49 208 3027648",
    website: "http://www.albershaustechnik.de",
    email: "info@albershaustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heiko-hittmeyer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94206&utm_campaign=profiles_installer",
    company_name: "Dachdeckermeister Heiko Hittmeyer",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cdb79c4de38.png?v=1",
    address: "Hesterberger Str. 1, 31621 Pennigsehl OT Mainsche",
    telephone: "+49 172 4228571",
    website: "http://www.deckt-mein-dach.de",
    email: "info@deckt-mein-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heizmann-system-elektronik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=654&utm_campaign=profiles_installer",
    company_name: "Heizmann-System-Elektronik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b3c708d64d7b.png?v=1",
    address: "Mainzenwies 1, 35753 Greifenstein",
    telephone: "+49 2779 91005",
    website: "http://www.heizmann.com",
    email: "info@heizmann.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/heizung-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11901&utm_campaign=profiles_installer",
    company_name: "Heizung & Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/577a0caad8c75.jpg?v=1",
    address: "Beurener Straße 4, 72636 Frickenhausen",
    telephone: "+49 7025 911599",
    website: "http://www.solar-fachgeschaeft.de",
    email: "info@solar-fachgeschaeft.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/heka-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92643&utm_campaign=profiles_installer",
    company_name: "HEKA Energy Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ae1afe38fcc.jpg?v=1",
    address: "Loppengasse 14, 69226, Nußloch",
    telephone: "+49 6224 96970",
    website: "http://hekaenergy.de/",
    email: "info@hekaenergy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 25052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/henry-schulze-elektro-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101445&utm_campaign=profiles_installer",
    company_name: "Henry Schulze Elektro + Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a990be067d7b.jpg?v=1",
    address: "Lindenberger Straße 21, 15848, Rietz-Neuendorf, OT, Buckow",
    telephone: "+49 33675 5112",
    website: "http://www.schulze-elektro-solar.de",
    email: "info@schulze-elektro-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 28082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/herold-heizungsbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2007&utm_campaign=profiles_installer",
    company_name: "Herold Heizungsbau GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/574d52a9b2811.jpg?v=1",
    address: "Eichstr. 7, 79362 Forchheim",
    telephone: "+49 7642 6296",
    website: "http://www.herold-heizungsbau.de",
    email: "info@herold-heizungsbau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 9112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sanitar-und-heizungsservice-budde?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93553&utm_campaign=profiles_installer",
    company_name: "Heymeier Sanitär- und Heizungs GmbH & Co. Haustechnik KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/6008b91f941f2.png?v=1",
    address: "Bronsartstraße 4, 30161 Hannover",
    telephone: "+49 511 33959895",
    website: "https://www.heymeier.de",
    email: "info@heymeier.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/hhc24?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7302&utm_campaign=profiles_installer",
    company_name: "HHC24",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5080ba020cd43.JPG?v=1",
    address: "Hauptstrasse 78, 93354 Biburg",
    telephone: "+49 9443 9911077",
    website: "http://www.hilz-elektrotechnik.de",
    email: "info@hilz-elektrotechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hilker-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76023&utm_campaign=profiles_installer",
    company_name: "Hilker Solar Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a28aca8950b7.png?v=1",
    address: "Carl-Zeiss-Straße 26, D-32369 Rahden",
    telephone: "+49 5771 914990",
    website: "http://www.hilker-solar.de",
    email: "info@hilker-solar.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: ",Elektrotechnik Hilker GmbH & Co. KG,",
    "Last Update": 4062020,
  },
  {
    company_url:
      "https://de.enfsolar.com/hip-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=12390&utm_campaign=profiles_installer",
    company_name: "HIP Solar GmbH",
    logo_url:
      "https://cdn.enfsolar.com/ID/logo/12390_139359ef24f94bfff.png?v=1",
    address: "Daimlerstraße 1, D-91161, Hilpoltstein",
    telephone: "+49 9174 977830413",
    website: "http://www.jurawatt.de",
    email: "info@jurawatt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/hirzinger-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75949&utm_campaign=profiles_installer",
    company_name: "Hirzinger Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60ae506b2ce92.PNG?v=1",
    address: "Gewerbepark 6, 82229 Seefeld",
    telephone: "+49 172 8382496",
    website: "http://www.hirzingersolar.de",
    email: "info@hirzingersolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 26052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/huber-holzbau-service?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=117010&utm_campaign=profiles_installer",
    company_name: "HUBER Holzbau-Service",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5d3fff4063fa6.png?v=1",
    address: "Spitzenbergstr. 13, D-77794 Lautenbach",
    telephone: "+49 7802 5844",
    website: "http://www.sageba.de",
    email: "info@sageba.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/i-tec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=56433&utm_campaign=profiles_installer",
    company_name: "i-tec GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5580f49144164.jpg?v=1",
    address: "Am Königsweg 13, 48599 Gronau",
    telephone: "+49 2565 97790",
    website: "http://www.isfort.de",
    email: "info@isfort.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 9092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/ideemasun?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7672&utm_campaign=profiles_installer",
    company_name: "IdeemaSun Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/539661c10de91.jpg?v=1",
    address: "Neusling 9c, 94574 Wallerfing",
    telephone: "+49 9936 95109080",
    website: "http://www.ideemasun.com",
    email: "info@ideemasun.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Yingli Green Energy Holding Co., Ltd.,,,Hanwha SolarOne Co., Ltd.,,,Tegola Canadese S.p.A.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,KACO New Energy GmbH,,,ABB Italy S.p.A.,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/johannes-merz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=77591&utm_campaign=profiles_installer",
    company_name: "Johannes Merz GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5823f6df1e4d7.jpg?v=1",
    address: "Tullastraße 51, 67346, Speyer",
    telephone: "+49 6232 67300",
    website: "http://test.merz.team",
    email: "info@test.merz.team",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/jvo-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=30207&utm_campaign=profiles_installer",
    company_name: "Jörg von Oeynhausen Solar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51e63ba0b487c.jpg?v=1",
    address: "Emil Ruschenbaum Weg 5a, 58239, Schwerte",
    telephone: "+49 2304 6978382",
    website: "http://www.jvo-solar.de",
    email: "info@jvo-solar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/karl-jansen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94870&utm_campaign=profiles_installer",
    company_name: "Karl Jansen GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df32cfe294e.png?v=1",
    address: "Hansemannstr. 6-10, 52511 Geilenkirchen-Niederheid",
    telephone: "+49 2451 2321",
    website: "http://www.dachdecker-jansen.de",
    email: "info@dachdecker-jansen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/kusten-energie-technik-gmbh?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=132618&utm_campaign=profiles_installer",
    company_name: "Küsten Energie Technik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a787a600c27.PNG?v=1",
    address: "Harmen-Grapengeter-Straße 4a, 25813 Husum",
    telephone: "+49 16 23259877",
    website: "https://www.facebook.com/kuestenenergietechnik/",
    email: "info@facebook.com",
    "Installaliert seit": 2021,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kraftwerk-renewable-power-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=91848&utm_campaign=profiles_installer",
    company_name: "Kraftwerk Renewable Power Solutions",
    logo_url: "https://cdn.enfsolar.com/ID/logo/599a940f18af8.jpg?v=1",
    address: "Deutschherrnufer 32, 60594 Frankfurt",
    telephone: "+49 69 87205502",
    website: "http://www.kraftwerk-rps.com",
    email: "info@kraftwerk-rps.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Chili, Deutschland, Ägypten, Tunesien, Peru,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 13012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kutro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93182&utm_campaign=profiles_installer",
    company_name: "Kütro GmbH & Co. KG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b7600215092.jpg?v=1",
    address: "Rudolf-Diesel-Straße 13a, 93326 Abensberg",
    telephone: "+49 9443 928300",
    website: "http://www.kuetro.de",
    email: "info@kuetro.de",
    "Installaliert seit": 1999,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,KIOTO Solar,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,IBC Solar AG,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Kaneka Corporation,,,Sharp Energy Solutions Europe,,,Hyundai Energy Solutions,",
    "Wechselrichter-Lieferanten": ",Fronius International GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/kw-reneg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22706&utm_campaign=profiles_installer",
    company_name: "KW Reneg Construction GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/22706n.gif?v=1",
    address: "Spandauer Strasse 89, 14612 Falkensee",
    telephone: "+49 3322 123346",
    website: "http://www.kw-reneg.de",
    email: "info@kw-reneg.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Österreich, Zypern, Tschechische, Deutschland, Frankreich, Griechenland, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",KW-Reneg Deutschland GmbH & Co. KG,",
    "Last Update": 23112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/lahmeyer-international?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=19047&utm_campaign=profiles_installer",
    company_name: "Lahmeyer International GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57e4898e1202d.jpg?v=1",
    address: "Friedberger Strasse 173, 61118, Bad Vilbel",
    telephone: "+49 6101 550",
    website: "http://www.lahmeyer.de",
    email: "info@lahmeyer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in":
      "Bulgarien, Chili, Deutschland, Spanien, Frankreich, Griechenland, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/langholz-dach-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=95053&utm_campaign=profiles_installer",
    company_name: "Langholz Dach & Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e844fdbf315.jpg?v=1",
    address: "Saarbrücker Str. 53, 66299 Friedrichsthal",
    telephone: "+49 6897 5009492",
    website: "http://www.dach-haustechnik.de",
    email: "info@dach-haustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/liboao?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=27668&utm_campaign=profiles_installer",
    company_name: "Liboao Adv. GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ba8029cbc41.jpg?v=1",
    address: "Bismarckstr. 3,10625, Berlin",
    telephone: "+49 30 79743536",
    website: "http://www.liboao.com",
    email: "info@liboao.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",EGing Photovoltaic Technology Co., Ltd.,,,Jinko Solar Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",Sungrow Power Supply Co., Ltd.,,,Beijing Kinglong New Energy Technology Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/luneborg-warme-und-solar-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10386&utm_campaign=profiles_installer",
    company_name: "Lüneborg Wärme und Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10386n.gif?v=1",
    address: "Silscheder Str. 89, 45549 Sprockhövel",
    telephone: "+49 2332 9178590",
    website: "http://www.oekowaerme.de",
    email: "info@oekowaerme.de",
    "Installaliert seit": 2011,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/markus-henkel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94300&utm_campaign=profiles_installer",
    company_name: "Markus Henkel",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59cf57f8f2bd2.jpg?v=1",
    address: "Kesseler Straße 76, 47574 Goch",
    telephone: "+49 2823 410077",
    website: "http://www.henkel-daecher.de",
    email: "info@henkel-daecher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/maucher-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=27947&utm_campaign=profiles_installer",
    company_name: "Maucher Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51bfe004232dd.jpg?v=1",
    address: "Hauptstraße 27/2, 88436, Eberhardzell",
    telephone: "+49 7355 934460",
    website: "http://www.maucher-elektro.de",
    email: "info@maucher-elektro.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",REC Group,,,Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 3022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/montagebau-ruppel?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102661&utm_campaign=profiles_installer",
    company_name: "Montagebau Rüppel GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac97f38d4d54.jpg?v=1",
    address: "Raingasse 51, 74542 Braunsbach, Baden-Würtemberg",
    telephone: "+49 7906 402",
    website: "http://www.montagebau-rueppel.de",
    email: "info@montagebau-rueppel.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/munch-utd?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93902&utm_campaign=profiles_installer",
    company_name: "Münch UTD",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c872e7523c4.jpg?v=1",
    address: "Steinäckerweg 13, 74722 Buchen (Odenwald)",
    telephone: "+49 6281 5649490",
    website: "http://www.muenchutd.de",
    email: "info@muenchutd.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/my-power-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=58135&utm_campaign=profiles_installer",
    company_name: "My Power Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ad421c6594c0.jpg?v=1",
    address: "Taubenstraße 82, 46539 Dinslaken",
    telephone: "+49 2064 4755801",
    website: "http://www.mypower-solar.de",
    email: "info@mypower-solar.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Zhejiang BLD Solar Technology Co., Ltd.,,,Vikram Solar Limited,",
    "Wechselrichter-Lieferanten":
      ",Solarwatt GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 7082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/naturalis?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10437&utm_campaign=profiles_installer",
    company_name: "Naturalis-Bauen-Wohnen-Energie",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5767438b51168.jpg?v=1",
    address: "Gasstrasse 8, 83278, Traunstein",
    telephone: "+49 861 15652",
    website: "http://www.naturalis-traunstein.de",
    email: "info@naturalis-traunstein.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 10122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/new-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=47317&utm_campaign=profiles_installer",
    company_name: "New Energy for the World GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53c4d13b8b6f8.jpg?v=1",
    address: "Wilhelm Strasse 3, 93049 Regensburg",
    telephone: "+49 941 56816711",
    website: "http://www.ne4tw.de",
    email: "info@ne4tw.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",SAR Electronic GmbH,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/photovoltaik-schrankl?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7336&utm_campaign=profiles_installer",
    company_name: "Photovoltaik Schrankl",
    logo_url: "https://cdn.enfsolar.com/ID/logo/7336n.gif?v=1",
    address: "Oberdorf 1, 84565 Oberneukirchen",
    telephone: "+49 8630 1223",
    website: "http://www.photovoltaik-schrankl.de",
    email: "info@photovoltaik-schrankl.de",
    "Installaliert seit": 2004,
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,Aleo Solar GmbH,,,Suntech Power Co., Ltd.,,,Sharp Energy Solutions Europe,,,BYD Company Limited,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/pv-enertec?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=86621&utm_campaign=profiles_installer",
    company_name: "PV Enertec",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5914163891d4a.jpg?v=1",
    address: "Bozenerstr. 30, 16341 Panketal",
    telephone: "+49 30 94399110",
    website: "http://pv-enertec.de",
    email: "info@pv-enertec.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Hanwha Q CELLS GmbH,,,Aleo Solar GmbH,,,AU Optronics Corporation,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,SolarEdge Technologies, Ltd.,",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 3032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/reglo-bedachung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10651&utm_campaign=profiles_installer",
    company_name: "REGLO Bedachung",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10651n.gif?v=1",
    address: "Dorfstraße 28, 23881, Alt-Mölln",
    telephone: "+49 4542 837171",
    website: "http://www.reglo-bedachung.de",
    email: "info@reglo-bedachung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/rmsolar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107389&utm_campaign=profiles_installer",
    company_name: "RMSolar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b7b7cc627d1e.jpg?v=1",
    address: "Seestraße 97, D-78479 Reichenau",
    telephone: "+49 7534 2711111",
    website: "https://rmsolar.eu",
    email: "info@rmsolar.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Hanwha Q CELLS GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Trina Solar Limited,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,First Solar, Inc.,,,Luxor Solar GmbH,,,LG Electronics, Inc.,,,Win Win Precision Technology Co., Ltd.,,,Suzhou Talesun Solar Technologies Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Kostal Solar Electric GmbH,,,Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 1022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rudolf-roller-elektroinstallationen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=122734&utm_campaign=profiles_installer",
    company_name: "Rudolf Roller Elektroinstallationen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/609a05ad4bd9f.png?v=1",
    address: "Gewerbestrasse 2, 78359, Orsingen-Nenzingen",
    telephone: "+49 179 2103103",
    website: "https://rudolf-roller-elektroinstallationen.business.site",
    email: "info@rudolf-roller-elektroinstallationen.business.site",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/rudolf-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94845&utm_campaign=profiles_installer",
    company_name: "Elektro Rudolf Schmidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5e987802eb9e7.png?v=1",
    address: "Daimlerstr. 8-10, 54634 Bitburg",
    telephone: "+49 6561 6977130",
    website: "http://www.r-schmidt.de",
    email: "info@r-schmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/s-schramm-1-2?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93485&utm_campaign=profiles_installer",
    company_name: "S. Schramm GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf6936e2543.jpg?v=1",
    address: "Möbecker Str. 8, 42327, Wuppertal",
    telephone: "+49 202 2740300",
    website: "http://www.schramm-dach.de",
    email: "info@schramm-dach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 4082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/saferay?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=20880&utm_campaign=profiles_installer",
    company_name: "Saferay GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/53e3294137a0b.jpg?v=1",
    address: "Rosenthaler Str. 34/35, 10178, Berlin",
    telephone: "+49 30 53606360",
    website: "http://www.safe-ray.com",
    email: "info@safe-ray.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Australien, Chili, Deutschland, Japan, Mexiko, USA, Singapur,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/schnekenburger-dach-wand?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102006&utm_campaign=profiles_installer",
    company_name: "",
    logo_url: "",
    address: "",
    telephone: "",
    website: "",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/schnell-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94032&utm_campaign=profiles_installer",
    company_name: "",
    logo_url: "",
    address: "",
    telephone: "",
    website: "",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/schober?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=64207&utm_campaign=profiles_installer",
    company_name: "",
    logo_url: "",
    address: "",
    telephone: "",
    website: "",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/schoenergie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=57881&utm_campaign=profiles_installer",
    company_name: "",
    logo_url: "",
    address: "",
    telephone: "",
    website: "",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/schrameyer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=8657&utm_campaign=profiles_installer",
    company_name: "",
    logo_url: "",
    address: "",
    telephone: "",
    website: "",
    email: "",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/sharp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=2548&utm_campaign=profiles_installer",
    company_name: "Sharp Energy Solutions Europe",
    logo_url: "https://cdn.enfsolar.com/ID/logo/2548n.gif?v=1",
    address: "Nagelsweg 33-35, 20097, Hamburg",
    telephone: "+49 40 23760",
    website: "http://www.sharp.co.uk/energysolutions",
    email: "info@sharp.co.uk",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": ",Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: ",Sharp Electronics GmbH,",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/siekmann-dach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94938&utm_campaign=profiles_installer",
    company_name: "Siekmann Dach und Wandtechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e0533983b9f.png?v=1",
    address: "Derfflingerstraße 60, 32425 Minden",
    telephone: "+49 571 44621",
    website: "http://www.dachdecker-siekmann.de",
    email: "info@dachdecker-siekmann.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/siemens-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=3187&utm_campaign=profiles_installer",
    company_name: "Siemens Energy",
    logo_url: "https://cdn.enfsolar.com/ID/logo/3187n.gif?v=1",
    address: "Freyeslebenstrasse 1, 91058 Erlangen",
    telephone: "+49 69 797932850",
    website: "https://new.siemens.com",
    email: "info@new.siemens.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in":
      "Belgien, Deutschland, Spanien, Frankreich, Griechenland, Italien, Niederlande,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/small-energy?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82736&utm_campaign=profiles_installer",
    company_name: "Small Energy GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58b3c0553f6b3.jpg?v=1",
    address: "Hauptstrasse 26, D-86495 Eurasburg",
    telephone: "+49 8208 2430351",
    website: "http://se-small-energy.de",
    email: "info@se-small-energy.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 7122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/smp-montage?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=90953&utm_campaign=profiles_installer",
    company_name: "SMP Solar Montage & Planungsgesellschaft mbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/597988cb4bb61.jpg?v=1",
    address: "Neuer Wall 18, 20354 Hamburg",
    telephone: "+49 30 408192284",
    website: "http://www.smp-montage.com",
    email: "info@smp-montage.com",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 8012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solar-baddesign?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93566&utm_campaign=profiles_installer",
    company_name: "Solar&Baddesign",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c089510ec34.jpg?v=1",
    address: "Scharnhölzstr. 159, 46238 Bottrop",
    telephone: "+49 2041 7618359",
    website: "http://www.solar-baddesign.de",
    email: "info@solar-baddesign.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 4032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solaranlagen-uelzen?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=107916&utm_campaign=profiles_installer",
    company_name: "Solaranlagen Uelzen",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5b88c01bf192b.jpg?v=1",
    address: "Holzhausen 1, 29413 Dähre",
    telephone: "+49 171 3117477",
    website: "https://solaranlagen-uelzen.de",
    email: "info@solaranlagen-uelzen.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Heckert Solar GmbH,,,Solarwatt GmbH,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 25032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solare-systeme-schmidt?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93509&utm_campaign=profiles_installer",
    company_name: "Solare Systeme Schmidt GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c06799d8826.jpg?v=1",
    address: "Rudolf-Virchow-Str. 9, 91710 Gunzenhausen",
    telephone: "+49 9831 612943",
    website: "http://www.solare-systeme-schmidt.de",
    email: "info@solare-systeme-schmidt.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 21022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarenergy-oran?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=128114&utm_campaign=profiles_installer",
    company_name: "Solarenergy Oran",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60137142ddb8c.jpg?v=1",
    address: "Lotissement 55 N° 40, Point du Jours, Orans, 31000",
    telephone: "+213 661 630422",
    website: "https://solarenergy-oran.business.site",
    email: "info@solarenergy-oran.business.site",
    "Installaliert seit": 2010,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Algerien,",
    "Lieferanten von Solarmodulen":
      ",Hangzhou Gene Solar Industries Co., Ltd.,,,China Blue Solar Co., Ltd.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 1042021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarexperten?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93484&utm_campaign=profiles_installer",
    company_name: "SolarExperten",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59bf6670a805e.jpg?v=1",
    address: "Vor den Secheln 18, 31693 Hespe",
    telephone: "+49 5724 902556",
    website: "http://www.solar-schaumburg.de",
    email: "info@solar-schaumburg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,IBC Solar AG,,,SunPower Corp.,,,Kyocera Corporation,,,Sharp Energy Solutions Europe,,,Panasonic Corporation (Sanyo),,,Moser Baer Solar Limited,,,Jinko Solar Holding Co., Ltd.,,,Antaris Solar GmbH & Co. KG,,,Upsolar Global Co., Ltd.,,,Alfa Solar Energy Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "Bewertung",
    Muttergesellschaft: "",
    "Last Update": 30102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-bartnitzek?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=1754&utm_campaign=profiles_installer",
    company_name: "Solar- & Heizungstechnik Bartnitzek",
    logo_url: "https://cdn.enfsolar.com/ID/logo/1754n.gif?v=1",
    address: "Kirchweg 5a, 01920 Oßling",
    telephone: "+49 35792 59347",
    website: "http://www.solar-undheizungstechnik.de",
    email: "info@solar-undheizungstechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Yingli Green Energy Holding Co., Ltd.,,,Panasonic Corporation (Sanyo),,,Hyundai Energy Solutions,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-franken?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31573&utm_campaign=profiles_installer",
    company_name: "Solartechnik Franken",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a5945fd491d.png?v=1",
    address: "Bahnhofstraße 5, 91245 Simmelsdorf",
    telephone: "+49 9155 314",
    website: "http://www.solartechnik-franken.de",
    email: "info@solartechnik-franken.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Heckert Solar GmbH,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solartechnik-und-telekommunikation?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=82009&utm_campaign=profiles_installer",
    company_name: "Solartechnik und Telekommunikation",
    logo_url: "https://cdn.enfsolar.com/ID/logo/589d27a94b7f9.jpg?v=1",
    address: "Berliner Chaussee 83, 15234 Frankfurt (Oder)",
    telephone: "+49 335 63933",
    website: "http://solartechnik-und-telekommunikation.de",
    email: "info@solartechnik-und-telekommunikation.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",BYD Company Limited,,,LG Electronics, Inc.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Fronius International GmbH,,,Kostal Solar Electric GmbH,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 29102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarteur-specialist?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=92166&utm_campaign=profiles_installer",
    company_name: "Solarteur  Specialist Ccompany",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59a3d3b721b82.jpg?v=1",
    address: "Schillerstrasse 8, 73269 Hochdorf",
    telephone: "+49 7153 958219",
    website: "http://www.derrotefaden-solartechnik.de",
    email: "info@derrotefaden-solartechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 17022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarverde?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=103283&utm_campaign=profiles_installer",
    company_name: "Solarverde Holding Ltd.",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ae16dc8d10a7.png?v=1",
    address: "Stolzingstraße 10/III, D-81927 München",
    telephone: "+49 160 97324354",
    website: "http://solarverde.eu",
    email: "info@solarverde.eu",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Australien, Deutschland, Italien,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solarzentrum-ostalb?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=75011&utm_campaign=profiles_installer",
    company_name: "Solarzentrum Ostalb GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/60a7b93a7030b.PNG?v=1",
    address: "Heerstraße 15/1, 73453 Abtsgmünd-Hohenstadt",
    telephone: "+49 7366 9230622",
    website: "http://www.solarzentrum-ostalb.de",
    email: "info@solarzentrum-ostalb.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Solarwatt GmbH,,,Solar Fabrik GmbH,,,Alfa Solar Energy Inc.,",
    "Wechselrichter-Lieferanten":
      ",SolarMax Sales and Service GmbH,,,SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 21052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solectra?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11937&utm_campaign=profiles_installer",
    company_name: "Solectra Gmbh",
    logo_url: "https://cdn.enfsolar.com/ID/logo/576751027d6fd.jpg?v=1",
    address: "Zusamstraße 17, 86165 Augsburg",
    telephone: "+49 821 3433690",
    website: "http://www.solectra.de",
    email: "info@solectra.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",REC Group,,,Day4 Europe Srl,,,Heckert Solar GmbH,,,Solarwatt GmbH,,,Yingli Green Energy Holding Co., Ltd.,,,Kyocera Corporation,,,Suntech Power Co., Ltd.,,,Panasonic Corporation (Sanyo),,,AXITEC Energy GmbH & Co. KG,,,Avancis GmbH & Co. KG,,,Luxor Solar GmbH,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Steca Elektronik GmbH,,,Fronius International GmbH,,,KACO New Energy GmbH,,,Danfoss A/S,,,Kostal Solar Electric GmbH,,,REFU Elektronik GmbH,",
    "Sonstiger Service": "Bewertung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 9052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/solifant?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=83593&utm_campaign=profiles_installer",
    company_name: "Solifant",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58c63b63aed6a.jpg?v=1",
    address: "Widdersteinstraße 104, 88400 Biberach an der Riß",
    telephone: "",
    website: "http://www.solifant.de",
    email: "info@solifant.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen":
      ",Kyocera Corporation,,,Trina Solar Limited,,,Hyundai Energy Solutions,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,Studer Innotec,,,RIPEnergy AG,,,Victron Energy BV,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/solverde-burgerkraftwerke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99182&utm_campaign=profiles_installer",
    company_name: "Solverde Bürgerkraftwerke Energiegenossenschaft eG",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c20fb3fc08.png?v=1",
    address: "Burgsdorfstraße 8, 13353, Berlin",
    telephone: "+49 30 28433104",
    website: "http://www.solverde-buergerkraftwerke.de",
    email: "info@solverde-buergerkraftwerke.de",
    "Installaliert seit": 2008,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Yingli Green Energy Holding Co., Ltd.,",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,REFU Elektronik GmbH,,,Woodward Ids Switzerland Gmbh,",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 11092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/soventix?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22838&utm_campaign=profiles_installer",
    company_name: "Soventix GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4ff28fb6c6d3e.gif?v=1",
    address: "Am Schornacker 121, 46485 Wesel",
    telephone: "+49 281 4056770",
    website: "http://www.soventix.com",
    email: "info@soventix.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,Global,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": "",
  },
  {
    company_url:
      "https://de.enfsolar.com/sss-energietechnik-und-netzservice?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102678&utm_campaign=profiles_installer",
    company_name: "SSS Energietechnik und Netzservice GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac98a81e6380.jpg?v=1",
    address: "Rosa-Luxemburg-Str. 72, 06773 Gräfenhainichen",
    telephone: "+49 34953 26820",
    website: "http://www.sss-ens.de",
    email: "info@sss-ens.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 27022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/stadtwerke-bad-driburg?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102035&utm_campaign=profiles_installer",
    company_name: "Stadtwerke Bad Driburg GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aab7463ebfa7.jpg?v=1",
    address: "Am Rathausplatz 2, 33014 Bad Driburg",
    telephone: "+49 5253 881800",
    website: "http://www.stadtwerke-bad-driburg.de",
    email: "info@stadtwerke-bad-driburg.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 14092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/steag-solar-energy-solutions?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=4105&utm_campaign=profiles_installer",
    company_name: "STEAG Solar Energy Solutions GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5df0b095c02a8.jpg?v=1",
    address: "Carl-Zeiss-Str. 4, 97076, Würzburg",
    telephone: "+49 931 25064100",
    website: "http://sens-energy.com/",
    email: "info@sens-energy.com/",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "1(M)Wp+ Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 2032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/steiger-foxx?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=85062&utm_campaign=profiles_installer",
    company_name: "Steiger & Foxx",
    logo_url: "https://cdn.enfsolar.com/ID/logo/58edc8bf17c80.jpg?v=1",
    address: "Heinsheimer Straße 51, 74906 Bad Rappenau",
    telephone: "+49 7264 960520",
    website: "http://steiger-foxx.de",
    email: "info@steiger-foxx.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung, Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/sun-value?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=13940&utm_campaign=profiles_installer",
    company_name: "Sun Value GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/584e6bd94cbfe.jpg?v=1",
    address: "Bürgermeister-Zimmermann-Str. 1, D-93413, Cham",
    telephone: "+49 6278 2029210",
    website: "http://www.sun-value.com",
    email: "info@sun-value.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Österreich,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten":
      ",SMA Solar Technology AG,,,AEG Power Solutions B.V.,,,Schneider Electric SA,",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/sybac-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=12027&utm_campaign=profiles_installer",
    company_name: "Sybac Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51622a3b132b8.bmp?v=1",
    address: "Rote Hohl 10, 56729 Kehrig",
    telephone: "+49 2654 881920",
    website: "http://www.sybac-solar.de",
    email: "info@sybac-solar.de",
    "Installaliert seit": 2009,
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Frankreich, USA,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Bewertung, Planung",
    Muttergesellschaft: "",
    "Last Update": 20072020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-alternativeergien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94826&utm_campaign=profiles_installer",
    company_name: "Thomas Alternativeergien GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59df0ed89ca2c.png?v=1",
    address: "Zum Kirschenhof 16 a/b, 19057 Schwerin",
    telephone: "+49 385 485230",
    website: "http://thomasgmbh.de",
    email: "info@thomasgmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: ",Thomas GmbH Group,",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/thomas-unger-sanitar-heizung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94798&utm_campaign=profiles_installer",
    company_name: "Thomas Unger Sanitär & Heizung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59deec015b399.jpg?v=1",
    address: "Euskirchener Straße 119, 50321 Brühl",
    telephone: "+49 2232 157702",
    website: "http://www.thomasunger.de",
    email: "info@thomasunger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 28012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/thuga-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93212&utm_campaign=profiles_installer",
    company_name: "Thüga Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59b795a04a199.jpg?v=1",
    address: "Industriestr. 9, 78224 Singen",
    telephone: "+49 7731 59000",
    website: "https://thuega-energie-gmbh.de",
    email: "info@thuega-energie-gmbh.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen, Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/tino-bottcher-energietechnik-und-modernisierung?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102681&utm_campaign=profiles_installer",
    company_name: "Tino Böttcher Energietechnik und Modernisierung GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac98bab99e7c.jpg?v=1",
    address: "Handwerkerhof 12, 28857 Syke",
    telephone: "+49 4242 937777",
    website: "https://www.tino-böttcher.de",
    email: "info@tino-böttcher.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/tischner?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94640&utm_campaign=profiles_installer",
    company_name: "Tischner GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59ddb4e8848b4.png?v=1",
    address: "Tassilostraße 4, 93336 Altmannstein",
    telephone: "+49 9446 2398",
    website: "http://www.tischner-spenglereiunddacheindeckung.de",
    email: "info@tischner-spenglereiunddacheindeckung.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 16042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/trendbau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=99193&utm_campaign=profiles_installer",
    company_name: "Trendbau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a5c2e2a71a10.png?v=1",
    address: "Rottmannsdorfer Str. 21A, D-08064 Zwickau",
    telephone: "+49 375 78819690",
    website: "http://www.trendbau-bauelemente.de",
    email: "info@trendbau-bauelemente.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11082020,
  },
  {
    company_url:
      "https://de.enfsolar.com/triosolar-1?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31003&utm_campaign=profiles_installer",
    company_name: "TrioSolar",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ecaca672af8.jpg?v=1",
    address: "Dresdener Str. 5, 27232, Sulingen",
    telephone: "+49 4271 955955",
    website: "http://triosolar.de/",
    email: "info@triosolar.de/",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",AU Optronics Corporation,",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/dach-und-haustechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=10613&utm_campaign=profiles_installer",
    company_name: "U. Schröder Dach- und Haustechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/10613n.gif?v=1",
    address: "Eckerkoppel 151, 22047 Hamburg",
    telephone: "+49 40 6969950",
    website: "http://www.dachundhaustechnik.de",
    email: "info@dachundhaustechnik.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/van-de-loo-solartechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=3364&utm_campaign=profiles_installer",
    company_name: "Van de Loo Solartechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/3364n.gif?v=1",
    address: "Gustav-Kunst-Sraße 2-16, 20539 Hamburg",
    telephone: "+49 40 2513141",
    website: "http://www.vandeloosolar.de",
    email: "info@vandeloosolar.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 11112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vereinigte-stadtwerke?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94569&utm_campaign=profiles_installer",
    company_name: "Vereinigte Stadtwerke GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59dd67f069160.png?v=1",
    address: "Schweriner Straße 90, 23909 Ratzeburg",
    telephone: "+49 4541 80701",
    website: "https://www.vereinigte-stadtwerke.de",
    email: "info@vereinigte-stadtwerke.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 15042020,
  },
  {
    company_url:
      "https://de.enfsolar.com/vistacon-energietechnik?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102672&utm_campaign=profiles_installer",
    company_name: "Vistacon Energietechnik GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ac985c0618d6.jpg?v=1",
    address: "Jellinghausstr. 102, 32139 Spenge",
    telephone: "+49 5225 8749098",
    website: "http://www.vistacon.de",
    email: "info@vistacon.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Überwachung",
    Muttergesellschaft: "",
    "Last Update": 19102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wanderer?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=7376&utm_campaign=profiles_installer",
    company_name: "Wanderer GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5bbeb6584697d.png?v=1",
    address: "Rudolf-Diesel-Strasse 15, DE-74343 Sachsenheim",
    telephone: "+49 7147 276240",
    website: "http://www.zoller-wanderer.de",
    email: "info@zoller-wanderer.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 5052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/watt-auf-s-dach?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=31187&utm_campaign=profiles_installer",
    company_name: "Watt Auf's Dach",
    logo_url: "https://cdn.enfsolar.com/ID/logo/51ecedb11a510.jpg?v=1",
    address: "Achtern Diek 3, 25917 Leck",
    telephone: "+49 4662 5192",
    website: "http://www.wattaufsdach.de",
    email: "info@wattaufsdach.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 20052021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wattkraft-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=38977&utm_campaign=profiles_installer",
    company_name: "Wattkraft Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5f85bac75d703.PNG?v=1",
    address: "Podbielskistraße 70, 30177 Hannover",
    telephone: "+49 511 72757600",
    website: "http://www.wattkraft.com",
    email: "info@wattkraft.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": ",Phono Solar Technology Co., Ltd.,",
    "Wechselrichter-Lieferanten": ",Huawei Technologies Co., Ltd.,",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13102020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wavitech?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101466&utm_campaign=profiles_installer",
    company_name: "WAVItech",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5a9c95310145e.jpg?v=1",
    address: "Im Sand 9, 53783 Eitorf",
    telephone: "+49 2243 899905",
    website: "http://www.wavitech.de",
    email: "info@wavitech.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/wen-solar?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=76089&utm_campaign=profiles_installer",
    company_name: "WEN-Solar GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/57d77c08b7a10.jpg?v=1",
    address: "An der Hansalinie 48-50, 59387 Ascheberg",
    telephone: "+49 2593 9589780",
    website: "http://wen-solar.com",
    email: "info@wen-solar.com",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "Inselanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 19122020,
  },
  {
    company_url:
      "https://de.enfsolar.com/werner-sukau?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=101817&utm_campaign=profiles_installer",
    company_name: "Werner & Sukau GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5aa72c0243fcc.jpg?v=1",
    address: "Alter Steinweg 34, 37269 Eschwege",
    telephone: "+49 5651 5516",
    website: "http://www.werner-sukau.de",
    email: "info@werner-sukau.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 13092020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wernz-thien?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94016&utm_campaign=profiles_installer",
    company_name: "Wernz und Thien GbR",
    logo_url: "https://cdn.enfsolar.com/ID/logo/600b624c20fb4.png?v=1",
    address: "Obercarsdorfer Straße 9, 78737 Fluorn-Winzeln",
    telephone: "+49 7402 904418",
    website: "http://www.wernz-thien.de",
    email: "info@wernz-thien.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 23012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/windwarts-energie?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=22951&utm_campaign=profiles_installer",
    company_name: "Windwärts Energie GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/4ffa75e37813a.gif?v=1",
    address: "Hanomaghof 1, 30449 Hannover",
    telephone: "+49 511 1235730",
    website: "http://www.windwaerts.de",
    email: "info@windwaerts.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in":
      "Kleine Installationen, 1(M)Wp+ Installationen",
    "Installationen in": "Deutschland, Frankreich, Griechenland, Italien,",
    "Lieferanten von Solarmodulen":
      ",Suntech Power Co., Ltd.,,,Canadian Solar Inc.,",
    "Wechselrichter-Lieferanten": ",SMA Solar Technology AG,",
    "Sonstiger Service": "",
    Muttergesellschaft: ",MVV Energie AG,",
    "Last Update": 26112020,
  },
  {
    company_url:
      "https://de.enfsolar.com/wolf-probst?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=11968&utm_campaign=profiles_installer",
    company_name: "Wolf Probst",
    logo_url: "https://cdn.enfsolar.com/ID/logo/56c0454d9ec3e.jpg?v=1",
    address: "Sterngasse 14, 89703, Ulm",
    telephone: "+49 170 8913692",
    website: "http://wolf-probst.de",
    email: "info@wolf-probst.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 8032021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zellhuber-elektro?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=102346&utm_campaign=profiles_installer",
    company_name: "Zellhuber Elektro GmbH",
    logo_url: "https://cdn.enfsolar.com/ID/logo/5ab9abdcbd3ca.png?v=1",
    address: "Boschstr. 3, 84144 Geisenhausen",
    telephone: "+49 8743 91363",
    website: "http://www.zellhuber-elektro.de",
    email: "info@zellhuber-elektro.de",
    "Installaliert seit": 1996,
    "Netzanlagen/Inselanlagen": "Netzanlagen",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 26022021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-gampp?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93908&utm_campaign=profiles_installer",
    company_name: "Zimmerei Gampp",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c877d744e66.jpg?v=1",
    address: "Am Waldrein 2, 79859 Schluchsee",
    telephone: "+49 7658 369",
    website: "http://www.zimmerei-gampp.de",
    email: "info@zimmerei-gampp.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-hellinger?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=94971&utm_campaign=profiles_installer",
    company_name: "Zimmerei Hellinger",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59e06dc29b2b4.jpg?v=1",
    address: "Holzfeldstr. 3, 85457 Hörlkofen",
    telephone: "+49 175 1901237",
    website: "http://zimmerei-hellinger.de",
    email: "info@zimmerei-hellinger.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "Planung",
    Muttergesellschaft: "",
    "Last Update": 29012021,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-stefan-kuhn?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93923&utm_campaign=profiles_installer",
    company_name: "Zimmerei Stefan Kuhn",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87fe041dbb.jpg?v=1",
    address: "Herdweg 6+8, 72147 Nehren",
    telephone: "+49 747 3921247",
    website: "http://zimmerei-kuhn-nehren.de",
    email: "info@zimmerei-kuhn-nehren.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 24022020,
  },
  {
    company_url:
      "https://de.enfsolar.com/zimmerei-torsten-krawczewicz?directory=installer&utm_source=ENF&utm_medium=Germany&utm_content=93922&utm_campaign=profiles_installer",
    company_name: "Zimmerei Torsten Krawczewicz",
    logo_url: "https://cdn.enfsolar.com/ID/logo/59c87f860a6dc.jpg?v=1",
    address: "Bielefelder Str. 96, 33803 Steinhagen",
    telephone: "+49 1715 257350",
    website: "http://www.zimmerei-tk.de",
    email: "info@zimmerei-tk.de",
    "Installaliert seit": "",
    "Netzanlagen/Inselanlagen": "",
    "Größe der Installation in": "Kleine Installationen",
    "Installationen in": "Deutschland,",
    "Lieferanten von Solarmodulen": "",
    "Wechselrichter-Lieferanten": "",
    "Sonstiger Service": "",
    Muttergesellschaft: "",
    "Last Update": 6032020,
  },
];
