import DashboardHeader from "Components/dashboardHeader";
import React, { useEffect, useState } from "react";
import { MainContainer } from "pages/Application/useroverview/overviewStyles";
import backgroundOverlay from "Assets/application_step_1/backgroundOverlay.svg";
import CloseIcon from "@material-ui/icons/Close";
import Share from "Assets/application_step_1/Share.svg";
import first from "Assets/overview/first.svg";
import secound from "Assets/overview/secound.svg";
import third from "Assets/overview/third.svg";
import four from "Assets/overview/four.svg";
import five from "Assets/overview/five.svg";
import deletess from "Assets/community/delete.svg";
import six from "Assets/overview/six.svg";
import { Button } from "@material-ui/core";
import { commaDotHandler } from "helperMethod";
import ProductPopup from "popups/productpopup/ProductPopup";
import { db } from "../../../../firebase";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ShareButtonForMobile from "Components/molecules/share.button.forMobile";
import { MixButton } from "Components/molecules/share.project.button";

const UserOverViewCard = ({
  data,
  key,
  selectOfferHandler,
  isSavedProject,
  isEnglish,
}) => {
  const [type, setType] = useState(null);
  const quote = useSelector((state) => state.quote);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [sharedURL, setSharedURL] = useState("");
  useEffect(() => {
    if (currentUser) {
      setSharedURL(
        window.location.origin +
          "/application/custom-" +
          currentUser.uid +
          "-" +
          quote.quote.addr1
      );
    } else {
      setSharedURL(
        window.location.origin + "/application/default-" + quote.quote.addr1
      );
    }
  }, []);

  // all component states
  const [hover, setHover] = useState();
  const [trackId, setTrackId] = useState(null);
  const [visibleShare, setvisibleShare] = useState(false);

  const [open, setOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const openProductHandler = (type) => {
    setType(type);
    setOpen(true);
  };
  const dispatch = useDispatch();

  console.log(data, "rus Data");

  const onDelteHandler = () => {
    db.collection("saved_projects")
      .doc(data?.pid)
      .delete()
      .then(() => {
        toast.success(
          isEnglish
            ? "configuration deleted successfully"
            : "Konfiguration erfolgreich gelöscht"
        );
        dispatch({ type: "DUMMYPROJECT_PROJECT_ADDED" });
      })
      .catch((error) => {
        toast.error("something went wrong");
      });
  };

  const removeProduct = (type) => {
    console.log(data?.pid, "pid");
    const docRef = db.collection("saved_projects").doc(data?.pid);
    docRef
      .get()
      .then((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          console.log("hjello");
          const project_details = {
            ...doc.data().project_details,
            components: {
              ...doc.data().project_details.components,
              items: {
                ...doc.data().project_details.components.items,
                [type]: {
                  item: null,
                  extended_warranty: false,
                  quantity: 0,
                },
              },
            },
          };
          const updatedData = {
            ...doc.data(),
            project_details,
          };
          docRef.set(updatedData);
          dispatch({ type: "DUMMYPROJECT_PROJECT_ADDED" });
          if (projectId === data?.pid) {
            setProjectAsync(updatedData?.project_details);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainContainer>
      <div className="nake_ite">
        <div className="card_main" key={key}>
          <div className="header_">
            {!isSavedProject ? (
              <div className={`text ${!isSavedProject ? "textSmall" : ""}`}>
                {data?.project_details?.address?.complete?.length > 28
                  ? `${data?.project_details?.address?.complete.substr(
                      0,
                      27
                    )}...`
                  : data?.project_details?.address?.complete}
              </div>
            ) : (
              <div className="text">
                Offer {data?.pid[data?.pid?.length - 1]} Ecologic
              </div>
            )}
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className={`center ${
                  window.innerWidth > 500 ? "buttonShare" : "newBtt"
                }`}
                onMouseEnter={() => {
                  const timer = () =>
                    setTimeout(() => {
                      setHover(true);
                    }, 200);
                  const timerId = timer();
                  setTrackId(timerId);
                }}
                onMouseLeave={() => {
                  if (!hover) {
                    clearTimeout(trackId);
                  }
                  setHover(false);
                }}
                onClick={() => {
                  if (window.innerWidth < 500) {
                    setvisibleShare(true);
                  } else {
                  }
                }}
              >
                <img src={Share} alt="" />
                {hover && window.innerWidth > 500 ? (
                  <MixButton currentUser={currentUser} sharedURL={sharedURL} />
                ) : (
                  ""
                )}
              </div>
              <CloseIcon
                onClick={() => onDelteHandler()}
                style={{ zIndex: "1", marginRight: "10px", cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="borders"></div>
          <div className="next_text">
            {isEnglish ? "valid until 23.12.2021" : "Gültig bis 23.12.2021"}
          </div>
          <div className="borders2"></div>
          <div className="continaer">
            <div className="same_part">
              <div className="heading">
                {isEnglish ? "Energy production" : "Energiegewinnung"}
              </div>
              <div className="info">
                <div className="first_thing">
                  <div className="again_same">
                    <img src={first} alt="" />
                    <p>
                      {isEnglish ? "Amount of panels" : "Anzahl Solarpanele"}
                    </p>
                  </div>
                  <div className="poew">
                    {
                      data?.project_details?.components?.items?.solar_panel
                        ?.quantity
                    }
                  </div>
                </div>
                <div className="first_thing">
                  <div
                    className="again_same"
                    onClick={() => openProductHandler("solar_panel")}
                  >
                    <img src={secound} alt="" />
                    <p>{isEnglish ? "Panel capacity" : "Leistung Panel"}</p>
                  </div>
                  <div
                    className="poew"
                    onClick={() => openProductHandler("solar_panel")}
                  >
                    {data?.project_details?.components?.items?.solar_panel?.item
                      ?.capacity +
                      " " +
                      "kwp"}
                  </div>
                </div>
                <div className="first_thing">
                  <div className="again_same">
                    <img src={third} alt="" />

                    <p>{isEnglish ? "System capacity" : "System Leistung"}</p>
                  </div>
                  <div className="poew">180</div>
                </div>
                <div className="first_thing">
                  <div
                    className="again_same"
                    onClick={() => openProductHandler("inverter")}
                  >
                    <img src={first} alt="" />
                    <p>{isEnglish ? "inverter" : "Wechselrichter"}</p>
                  </div>
                  <div
                    className="poew"
                    onClick={() => openProductHandler("inverter")}
                  >
                    {data?.project_details?.components?.items?.inverter?.item
                      ?.applicable_max_capacity /
                      1000 +
                      "Kw"}
                  </div>
                </div>
              </div>
            </div>
            <div className="borders1"></div>
            <div className="same_part">
              <div className="heading">
                {isEnglish
                  ? "Energy storage & consumption"
                  : "Energiespeicherung und -verbrauch"}
              </div>
              <div className="info">
                <div className="first_thing">
                  {data?.project_details?.components?.items?.battery?.item ? (
                    <>
                      <div
                        className="again_same"
                        onClick={() => openProductHandler("battery")}
                      >
                        <img src={four} alt="" />
                        <p>{isEnglish ? "Battery" : "Batterie"}</p>
                      </div>
                      <div className="delete_product">
                        <div
                          className="poew"
                          onClick={() => {
                            openProductHandler("battery");
                          }}
                        >
                          {data?.project_details?.components?.items?.battery
                            ? data?.project_details?.components?.items?.battery
                                ?.item?.applicable_max_capacity
                            : ""}
                        </div>
                        <img
                          src={deletess}
                          onClick={() => removeProduct("battery")}
                          style={{ cursor: "pointer" }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="again_same"
                      onClick={() => {
                        openProductHandler("battery");
                        setIsAdd(true);
                      }}
                    >
                      <img src={four} alt="" />
                      <p>{isEnglish ? "Add battery" : "Batterie hinzufügen"}</p>
                    </div>
                  )}
                </div>
                <div className="first_thing">
                  {data?.project_details?.components?.items?.wallbox?.item ? (
                    <>
                      <div
                        className="again_same"
                        onClick={() => openProductHandler("wallbox")}
                      >
                        <img src={five} alt="" />
                        <p>{isEnglish ? "Wallbox" : "Wallbox"}</p>
                      </div>
                      <div className="delete_product">
                        <div
                          className="poew"
                          onClick={() => openProductHandler("wallbox")}
                        >
                          {data?.project_details?.components?.items?.wallbox
                            ? data?.project_details?.components?.items?.wallbox
                                ?.item?.applicable_max_capacity
                            : ""}
                        </div>
                        <img
                          src={deletess}
                          onClick={() => removeProduct("wallbox")}
                          style={{ cursor: "pointer" }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="again_same"
                      onClick={() => {
                        openProductHandler("wallbox");
                        setIsAdd(true);
                      }}
                    >
                      <img src={five} alt="" />
                      <p>{isEnglish ? "Add Wallbox" : "Wallbox hinzufügen"}</p>
                    </div>
                  )}
                </div>
                <div className="first_thing">
                  {data?.project_details?.components?.items?.heatpump?.item ? (
                    <>
                      <div
                        className="again_same"
                        onClick={() => openProductHandler("heatpump")}
                      >
                        <img src={six} alt="" />
                        <p>{isEnglish ? "heat pump" : "Wärmepumpe"}</p>
                      </div>
                      <div className="delete_product">
                        <div
                          className="poew"
                          onClick={() => openProductHandler("heatpump")}
                        >
                          {data?.project_details?.components?.items?.heatpump
                            ? data?.project_details?.components?.items?.heatpump
                                ?.item?.applicable_max_capacity
                            : ""}
                        </div>
                        <img
                          src={deletess}
                          onClick={() => removeProduct("heatpump")}
                          style={{ cursor: "pointer" }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="again_same"
                      onClick={() => {
                        openProductHandler("heatpump");
                        setIsAdd(true);
                      }}
                    >
                      <img src={six} alt="" />
                      <p>
                        {isEnglish ? "Add heatpump" : "Wärmepumpe hinzufügen"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="borders1"></div>
            <div className="inside_container">
              <div className="new_same">
                <div className="text_same">
                  {isEnglish ? "Price total project" : "Gesamtprojekt Kosten"}
                </div>
                <div className="text_same">
                  {commaDotHandler(
                    Math.floor(data?.financialDetails?.total_project_cost),
                    `${isEnglish ? "en" : "de"}`
                  )}{" "}
                  €
                </div>
              </div>
              <div className="new_same">
                <div className="littel_diff">
                  {isEnglish ? "Amortisation time" : "Amortisierungszeit"}
                </div>
                <div className="littel_diff">
                  {data?.financialDetails?.duration_in_years} Years
                </div>
              </div>
              <div className="new_same">
                <div className="text_same">
                  {isEnglish ? "ROI after 20 Years" : "Gewinn nach 20 Jahren"}
                </div>
                <div className="text_same">
                  {commaDotHandler(
                    Math.floor(data?.returnOfInvestment),
                    `${isEnglish ? "en" : "de"}`
                  )}{" "}
                  €
                </div>
              </div>
            </div>
            <Button
              className={`myButton ${!isEnglish ? "myButtonGerman" : ""}`}
              onClick={() => {
                selectOfferHandler(data);
              }}
            >
              {isEnglish ? "Select offer" : "Angebot auswählen"}
            </Button>
          </div>
        </div>
        <ProductPopup
          open={open}
          setOpen={setOpen}
          isAdd={isAdd}
          type={type}
          setIsAdd={setIsAdd}
          setType={setType}
          data={data}
        />
      </div>
      <ShareButtonForMobile
        currentUser={currentUser}
        sharedURL={sharedURL}
        visibleShare={visibleShare}
        setvisibleShare={setvisibleShare}
      />
    </MainContainer>
  );
};

export default UserOverViewCard;
