import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import Button from "pages/AdminPanel/components/CustomButtons/Button.js";

//hooks
import { useRouteName } from "pages/AdminPanel/hooks";

import styles from "Assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const [address, setAddress] = useState("");
  const classes = useStyles();
  const routeName = useRouteName();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  useEffect(() => {
    if (window.location.href.indexOf("local") > -1) {
      setAddress("local");
    }
    if (window.location.href.indexOf("staging") > -1) {
      setAddress("staging");
    }
    if (window.location.href.indexOf("production") > -1) {
      setAddress("production");
    }
  }, []);
  return (
    <AppBar
      className={classes.appBar + appBarClasses}
      style={{ backgroundColor: "#fff" }}
    >
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {routeName}
          </Button>
          {address === "local" ? (
            <div className={classes.flex1}>
              You are on Local Sytem [Check for stag/prod Manually]
            </div>
          ) : address === "staging" ? (
            <div className={classes.flex1}>
              You are on Staging Admin Sytem Panel
            </div>
          ) : (
            <div className={classes.flex1}>
              You are on Production Admin Sytem Panel
            </div>
          )}
        </div>

        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
