import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TopicList from "./TopicList";
import Toback from "Assets/community/Toback.svg";
import { Box, CircularProgress } from "@material-ui/core";
import {
  createPostWithId,
  getPostsByTopicId,
  getUserByUserName,
  updatedPostById,
} from "api/discourse/get";
import InsideTopicPost from "pages/Application/community/pages/home/partials/topics/partials/insideTopicPost/InsideTopicPost";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getError } from "helperMethod";
import ReplyInput from "./insideTopicPost/replyInput/ReplyInput";

const InsideTopicWrapper = styled("div")`
  .root {
    height: 100%;
    width: 100%;
  }
  .all {
    margin-left: 34px;
    margin-top: 24px;
    max-height: 675px;
    position: relative;
    overflow-y: auto;
  }
  .header1 {
    margin-bottom: 24px;
    margin-right: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .category_text1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #2d4764;
  }
  .flex1 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .middle_main1 {
    height: auto;
    margin-top: 20px;
  }
  .middle_main::-webkit-scrollbar {
    width: 6px;
  }
  .middle_main::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .middle_main::-webkit-scrollbar-thumb {
    background: #888;
  }
  .middle_main::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .all::-webkit-scrollbar {
    width: 6px;
  }
  .all::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .all::-webkit-scrollbar-thumb {
    background: #888;
  }
  .all::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .replieCount {
    font-weight: bold;
    font-size: 18px;
    margin-left: 40px;
    line-height: 25px;
    color: #2d4764;
  }
  .replymain {
    display: flex;
    justify-content: flex-start;
    margin-left: 40px;
    margin-top: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
  .inside_replymain {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    width: 560px;
  }
  .left_side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 36px;
      width: 36px;
    }
  }
  .right_side {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 500px;
    flex-direction: column;
  }
  .allThings {
    display: flex;
    gap: 13px;
    align-items: center;
    img {
      cursor: pointer;
    }
    div {
      cursor: pointer;
    }
  }
  .imp {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .flex_text {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    color: #666666;
  }
  .sameText {
    font-weight: 600;
    font-size: 12px;
    color: #666666;
  }
  .other_same {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }
  .line {
    width: 1.7px;
    height: 36px;
    margin-left: -11px;
    margin-top: 2px;
    background-color: #c0c0c0;
  }
  .input_main {
    background: #f4f4f4;
    border-radius: 10px;
    /* position: relative; */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 623px;
    min-height: 52px;
  }
  .ant-btn:hover {
    color: black;
    /* padding: 0px !important; */
    border: 1px solid transparent;
  }
  .ant-btn:focus {
    color: none;
    border: none;
  }
  .howare {
    margin-right: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .emoji {
    height: 22px;
    width: 22px;
  }
  .lightbox-wrapper img {
    width: 500px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  .meta {
    display: none;
  }
  .replymain::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .replymain::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .replymain::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .replymain::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media (max-width: 600px) {
    .all {
      margin: 0px;
      overflow-x: hidden;
      width: 101%;
      max-height: 100vh;
      position: inherit;
    }
    .replymain {
      width: 90%;
      margin: auto;
    }
    .inside_replymain {
      width: 100%;
    }
    .right_side {
      width: 100%;
    }
    .header1 {
      margin-top: 11px;
      width: 100%;
      margin-left: 17px;
    }
    .replieCount {
      margin-bottom: 10px;
    }
    .lightbox-wrapper img {
      width: 77%;
    }
  }
`;

const InsideTopic = ({
  setIsInsideTopicViewOn,
  topicId,
  setDataForInsideTopic,
  categoryId,
  setOpen1,
  isOpen,
  topicData,
}) => {
  const dispatch = useDispatch();
  // state for edit post logic
  const [editedDecriptData, setEditedDecriptData] = useState({});
  const [imgUrl, setImgUrl] = useState(null);
  const [orignalImgUrl, setOrignalImgUrl] = useState(null);
  //comple edit states
  const [insideTopicData, setInsideTopicData] = useState([]);
  const [isloadingCreatePost, setIsLoadingCreatePost] = useState(false);
  const [postText, setPostText] = useState("");
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [detect, setDetect] = useState(false);
  const [postId, setPostId] = useState(null);
  const image_element = useRef(null);
  const [preview, setPreview] = useState("");
  const [isEditTrue, setIsEditTrue] = useState(false);
  const [image, setimage] = useState(null);
  const divRref = useRef(null);
  const [count, setCount] = useState(0);
  const [userDeatils, setUserDeatils] = useState(null);
  const [isReplyTrue, setIsReplyTrue] = useState(false);
  const [description, setDescritption] = useState("");
  const [data, setData] = useState(null);
  const userName = useSelector((state) => state?.user?.username);
  const isPostDeletedOrEditedOrCreated = useSelector(
    (state) => state?.global?.isPostDeletedOrEditedOrCreated
  );
  const isEnglish = useSelector((state) => state.global.isEnglish);

  useEffect(() => {
    if (detect) {
      setOpen1(false);
    }
  }, [detect]);

  useEffect(() => {
    const fun = async () => {
      const result = await getUserByUserName(userName);
      if (result) {
        setUserDeatils(result.data.user);
      }
    };
    fun();
  }, []);

  useEffect(() => {
    if (editedDecriptData?.updatedDes) {
      setDescritption(editedDecriptData?.updatedDes);
      setImgUrl(editedDecriptData.url);
      setOrignalImgUrl(editedDecriptData.link);
    }
    console.log(editedDecriptData, "new-work");
  }, [editedDecriptData]);

  useEffect(() => {
    const func = async () => {
      if (topicId) {
        const result = topicData.filter((data) => data.id === topicId);
        console.log(result, "result");
        setData(result[0]);
        const res = await getPostsByTopicId(topicId);
        if (res) {
          setInsideTopicData(res.data);
        }
      }
    };

    func();
  }, [isPostDeletedOrEditedOrCreated]);

  useEffect(() => {
    if (!image) {
      setPreview("");
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  useEffect(() => {
    console.log(description, "userDeatils");
    setPostText(description);
  }, [description]);

  const createPost = async () => {
    if (categoryId) {
      if (postText === "") {
        return toast.warn(
          isEnglish
            ? "please write something to post"
            : "bitte schreiben Sie etwas um zu antworten"
        );
      }
      setIsLoadingCreatePost(true);
      if (image) {
        let data = new FormData();
        data.append("media", image);
        try {
          let response = await axios.post(
            "https://us-central1-solarhub-44450.cloudfunctions.net/api/general/file-upload",
            data
          );
          if (response) {
            console.log(response, "rus");
            const path = `![${response.data.original_filename}|${response.data.thumbnail_width}x${response.data.thumbnail_height}](${response.data.short_url})`;
            const postData = {
              raw: postText + path,
              categoryId: categoryId,
              topicId: topicId,
              username: userName,
            };
            const restult = await createPostWithId(postData);
            if (restult) {
              toast.success("post created successfully");
              setIsReplyTrue(false);
              setIsLoadingCreatePost(false);
              dispatch({ type: "SET_DISCORUSE_STATE_FOR_POST" });
              setPostText("");
              setShowReplyBox(false);
              setimage(null);
            } else {
              setIsLoadingCreatePost(false);
            }
          }
        } catch (error) {
          if (error?.response?.data?.errors?.length) {
            toast.error(getError(error?.response?.data?.errors));
          } else {
            console.log(error);
          }
          setIsLoadingCreatePost(false);
        }
      } else {
        const postData = {
          raw: postText,
          categoryId: categoryId,
          topicId: topicId,
          username: userName,
        };
        const restult = await createPostWithId(postData);
        if (restult) {
          toast.success("post created successfully");
          setIsReplyTrue(false);
          setIsLoadingCreatePost(false);
          dispatch({ type: "SET_DISCORUSE_STATE_FOR_POST" });
          setPostText("");
          setShowReplyBox(false);
          setimage(null);
        } else {
          setIsLoadingCreatePost(false);
        }
      }
    } else {
      setIsLoadingCreatePost(false);
      setPostText("");
      setimage(null);
      setIsReplyTrue(false);
      toast.error("you are not inside any category");
    }
  };

  const updatePost = async () => {
    if (postText === "") {
      return toast.warn(
        isEnglish
          ? "please write something to post"
          : "bitte schreiben Sie etwas um zu antworten"
      );
    }
    if (postId === null) {
      return toast.error("something went wrong please contect admin");
    }
    setIsLoadingCreatePost(true);
    //new
    if (image) {
      let data = new FormData();
      data.append("media", image);
      console.log("sar", data);
      try {
        let response = await axios.post(
          "https://us-central1-solarhub-44450.cloudfunctions.net/api/general/file-upload",
          data
        );
        if (response) {
          console.log(response, "rus");
          const path = `![${response.data.original_filename}|${response.data.thumbnail_width}x${response.data.thumbnail_height}](${response.data.short_url})`;
          const updateData = {
            raw: postText + path,
            topicId: topicId,
            username: userName,
          };
          const restult = await updatedPostById(postId, updateData);
          if (restult) {
            toast.success("post updated successfully");
            setIsReplyTrue(false);
            setIsLoadingCreatePost(false);
            dispatch({ type: "SET_DISCORUSE_STATE_FOR_POST" });
            setPostText("");
            setShowReplyBox(false);
            setimage(null);
            setDescritption("");
            setPostId(null);
            setImgUrl(null);
            setIsEditTrue(false);
            setOrignalImgUrl(null);
          } else {
            setIsLoadingCreatePost(false);
          }
        }
      } catch (error) {
        if (error?.response?.data?.errors?.length) {
          toast.error(getError(error?.response?.data?.errors));
        } else {
          console.log(error);
        }
        setIsLoadingCreatePost(false);
      }
    } else {
      //before
      const updateData = {
        raw: postText + " " + (orignalImgUrl !== null ? orignalImgUrl : ""),
        topicId: topicId,
        username: userName,
      };
      console.log(updateData, "results");
      const restult = await updatedPostById(postId, updateData);
      if (restult) {
        toast.success("post updated successfully");
        setIsLoadingCreatePost(false);
        dispatch({ type: "SET_DISCORUSE_STATE_FOR_POST" });
        setIsEditTrue(false);
        setDescritption("");
        setDescritption("");
        setPostId(null);
        setimage(null);
        setImgUrl(null);
        setPostId(null);
        setPostText("");
      } else {
        setDescritption("");
        setIsLoadingCreatePost(false);
      }
    }
  };
  const UploadImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setimage(null);
      return;
    }
    setimage(e.target.files[0]);
  };

  const handleItemClick = (e, id) => {
    e.preventDefault();
    console.log("sar", id);
    let t = postText;
    t += id;
    const newString = t.split("@")[t.split("@").length - 1];
    console.log(`@${newString}`, "newString");
    console.log(t, "t");
    t = t.replace(newString, "");
    setPostText(t + id);
    setCount((prev) => {
      return prev + 1;
    });
    setOpen1(false);
  };

  return (
    <InsideTopicWrapper>
      <div
        className="all"
        onClick={() => {
          setOpen1(false);
        }}
      >
        <div className="header1">
          <div className="flex1">
            <img
              src={Toback}
              onClick={() => {
                setIsInsideTopicViewOn(false);
                dispatch({
                  type: "SET_DIREACT_CATEGORY_SEARCH",
                  payload: {
                    category_id: null,
                    title: "",
                  },
                });
                const datass = {
                  flag: false,
                  category_id: null,
                  direactId: null,
                  title: "",
                };
                dispatch({ type: "SET_DIREACT_SEARCH", payload: datass });
              }}
              alt=""
            />
            <div className="category_text1">{data?.title}</div>
          </div>
        </div>
        <div className="middle_main1">
          {data !== null && (
            <TopicList
              setIsInsideTopicViewOn={setIsInsideTopicViewOn}
              insideTopic={true}
              categoryId={categoryId}
              setShowReplyBox={setShowReplyBox}
              data={data}
              setDataForInsideTopic={setDataForInsideTopic}
            />
          )}
        </div>
        <div className="replieCount">{insideTopicData.length} Replies</div>
        <div className="replymain">
          {insideTopicData.length > 0 ? (
            insideTopicData.map((data, key) => {
              return (
                <InsideTopicPost
                  data={data}
                  divRref={divRref}
                  setDetect={setDetect}
                  setIsReplyTrue={setIsReplyTrue}
                  setShowReplyBox={setShowReplyBox}
                  setEditedDecriptData={setEditedDecriptData}
                  key={key}
                  setIsEditTrue={setIsEditTrue}
                  setDescritption={setDescritption}
                  setPostId={setPostId}
                />
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  color: "#fbda61",
                }}
              />
            </Box>
          )}
        </div>
        <ReplyInput
          userDeatils={userDeatils}
          isReplyTrue={isReplyTrue}
          divRref={divRref}
          detect={detect}
          isEditTrue={isEditTrue}
          setOpen1={setOpen1}
          setCount={setCount}
          isOpen={isOpen}
          setPostText={setPostText}
          postText={postText}
          handleItemClick={handleItemClick}
          image_element={image_element}
          setDetect={setDetect}
          UploadImage={UploadImage}
          image={image}
          setimage={setimage}
          preview={preview}
          imgUrl={imgUrl}
          isloadingCreatePost={isloadingCreatePost}
          count={count}
          updatePost={updatePost}
          createPost={createPost}
          showReplyBox={showReplyBox}
          setShowReplyBox={setShowReplyBox}
          setIsEditTrue={setIsEditTrue}
          setIsReplyTrue={setIsReplyTrue}
          setImgUrl={setImgUrl}
          setOrignalImgUrl={setOrignalImgUrl}
        />
      </div>
    </InsideTopicWrapper>
  );
};

export default InsideTopic;
