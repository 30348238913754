import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { db } from "../../../firebase";
import OBJECT__POPUP from "../components/popups/admin.project.popup";
import QuotesPopup from "../components/popups/admin.quote.popup";
import { renderRatingEditInputCell } from "./DataGridColumns/commanFunction";
import { toast } from "react-toastify";
import RoofTypeCol from "./DataGridColumns/roofType";
import BuildingTypeCol from "./DataGridColumns/buildingType";
import SelectInstallerName from "./DataGridColumns/SelectInstallerName";
const buttonStyle = {
  backgroundColor: "#3f51b5",
  color: "white",
};

const Projects = () => {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState("");
  const [rowData, setRowData] = useState("");
  const [objectDetails, setObjectDetails] = useState({});
  const [selected_items, setselected_items] = useState([]);
  const [buildingType, setBuildingType] = useState([]);
  const [roofType, setRoofType] = useState([]);
  const [installerNames, setInstallerNames] = useState([]);
  const getFormattedDate = (date) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${daysOfWeek[date.getDay()]} ${date.getDate()}.${month}.${year}`;
  };
  useEffect(() => {
    db.collection("quotes").onSnapshot((snap) => {
      let quotes = [];
      snap.forEach((doc) => {
        const quote = {
          ...doc.data(),
          date: getFormattedDate(new Date(doc.data()?.date.seconds * 1000)),
          production_increse: doc.data()?.production_increse
            ? doc.data()?.production_increse
            : 0,
        };

        if (quote?.qrn) {
          quotes.push(quote);
        }
      });
      quotes.sort((v1, v2) => {
        const final = v1?.date?.split(" ")[1].split(".");
        const fullFinal = `${final[1]}.${final[0]}.${final[2]}`;
        const date1 = new Date(fullFinal);
        const final2 = v2?.date?.split(" ")[1].split(".");
        const fullFinal2 = `${final2[1]}.${final2[0]}.${final2[2]}`;
        const date2 = new Date(fullFinal2);
        return date1 < date2 ? 1 : -1;
      });
      setRowData(quotes);
    });
  }, [open]);
  useEffect(() => {
    db.collection("constants")
      .doc("Building_type")
      .get()
      .then((doc) => {
        const properties = doc.data().Building_type_list;
        console.log(properties);
        setBuildingType(properties);
      });
    db.collection("constants")
      .doc("Roof_Type")
      .get()
      .then((doc) => {
        const properties = doc.data().Roof_Type_List;
        console.log(properties);
        setRoofType(properties);
      });
  }, []);
  useEffect(() => {
    db.collection("installers")
      .get()
      .then((snapshot) => {
        let names = [];
        snapshot.forEach((doc) => {
          names.push(doc.data()?.company_name);
        });
        setInstallerNames(names);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // enable sorting on 'name' and 'age' columns only
  const columns = [
    {
      field: "isVerified",
      width: 250,
      editable: true,
      type: "singleSelect",
      valueOptions: ["verified", "pending", "not verified"],
    },
    {
      field: "addr1",
      editable: true,
      width: 180,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "flagOfQuote",
      width: 250,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Green", "eco", "smart", "none"],
    },
    {
      field: "installer_partner",
      width: 400,
      renderCell: (params) => (
        <SelectInstallerName
          installerNames={installerNames}
          setInstallerNames={setInstallerNames}
          params={params}
        />
      ),
    },
    {
      field: "qrn",
      width: 350,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
          }}
        >
          <div style={{ width: "220px" }}>
            <strong>{params.value}</strong>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const result = rowData.filter((data) => data.qrn === params.id);
              setObjectDetails(result[0]);
              setId(params.id);
              setOpen(true);
            }}
            style={{ width: "50px" }}
          >
            Open
          </Button>
        </div>
      ),
    },
    {
      field: "sl_no",
      editable: true,
      width: 300,
      renderCell: (params) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            <div style={{ width: "70px" }}>
              <strong>{params.value}</strong>
            </div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                const result = rowData.filter(
                  (data, index) => data.qrn === params.id
                );
                window.open(
                  `https://app.solarhub24.de/application/default-${result[0]?.addr1} ${result[0]?.city} ${result[0]?.state} ${result[0]?.zip_code}`,
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
              style={{ width: "70px" }}
            >
              Redirect
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                const result = rowData.filter(
                  (data, index) => data.qrn === params.id
                );
                window.open(
                  `https://app.solarhub24.de/application/default-${result[0]?.addr1} ${result[0]?.city} ${result[0]?.state} ${result[0]?.zip_code}`,
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
              style={{ width: "120px" }}
            >
              Go To Redesign
            </Button>
          </div>
        </>
      ),
    },
    {
      field: "customer_name",
      editable: true,
      width: 170,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "production_increse", editable: true, width: 150 },
    {
      field: "date",
      editable: false,
      width: 300,
      sortComparator: (v1, v2) => {
        const final = v1.split(" ")[1].split(".");
        const fullFinal = `${final[1]}.${final[0]}.${final[2]}`;
        const date1 = new Date(fullFinal);
        const final2 = v2.split(" ")[1].split(".");
        const fullFinal2 = `${final2[1]}.${final2[0]}.${final2[2]}`;
        const date2 = new Date(fullFinal2);
        return date1 < date2 ? 1 : -1;
      },
    },
    { field: "time", editable: false, width: 170 },
    {
      field: "module_brand",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "module_model_number",
      editable: true,
      width: 180,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "watts_per_module", editable: true, width: 110 },
    { field: "module_quantity", editable: true, width: 110 },
    { field: "inverter_brand_1", editable: true, width: 180 },
    {
      field: "inverter_model_1",
      editable: true,
      width: 180,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "inverter_quantity_1", editable: true, width: 110 },
    {
      field: "city",
      editable: true,
      width: 110,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "state",
      editable: true,
      width: 110,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "zip_code",
      editable: true,
      width: 110,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "annual_solar_generation", editable: true, width: 110 },
    { field: "annul_electric_bill", editable: true, width: 110 },
    {
      field: "bill_type",
      editable: true,
      width: 110,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "building_type",
      editable: true,
      width: 220,
      renderCell: (params) => (
        <BuildingTypeCol
          buildingType={buildingType}
          setBuildingType={setBuildingType}
          params={params}
        />
      ),
    },
    { field: "business_name", editable: true, width: 110 },
    { field: "business_type", editable: true, width: 110 },
    { field: "contact_url", editable: true, width: 110 },
    { field: "contact_website", editable: true, width: 110 },
    { field: "crn", editable: true, width: 110 },
    { field: "current_annual_consumption", editable: true, width: 110 },
    { field: "dc_to__ac_ratio", editable: true, width: 110 },
    { field: "estimated_consumption", editable: true, width: 110 },
    { field: "fit_type", editable: true, width: 180 },
    { field: "geohash", editable: true, width: 110 },
    { field: "inverter_brand_2", editable: true, width: 180 },
    { field: "inverter_efficiency", editable: true, width: 110 },
    { field: "inverter_id", editable: true, width: 110 },
    { field: "inverter_model_2", editable: true, width: 180 },
    { field: "inverter_powerrating_1", editable: true, width: 110 },
    { field: "inverter_quantity_2", editable: true, width: 110 },
    { field: "is_solar_panel_already_installed", editable: true, width: 110 },
    { field: "lat", editable: true, width: 110 },
    { field: "long", editable: true, width: 110 },
    { field: "new_annual_electric_bill", editable: true, width: 110 },
    { field: "module_id", editable: true, width: 110 },
    { field: "number_of_floors", editable: true, width: 110 },
    { field: "number_of_inhabitants", editable: true, width: 110 },
    { field: "owner_type", editable: true, width: 110 },
    { field: "panel_degradation", editable: true, width: 110 },
    { field: "production_ratio", editable: true, width: 110 },
    { field: "project_size_rating", editable: true, width: 110 },
    { field: "project_type", editable: true, width: 110 },
    { field: "proposed_system_size_ac", editable: true, width: 110 },
    { field: "proposed_system_size_dc", editable: true, width: 110 },
    { field: "rate_name", editable: true, width: 110 },
    { field: "roof_area", editable: true, width: 110 },
    { field: "roof_material", editable: true, width: 110 },
    { field: "roof_material_rating", editable: true, width: 180 },
    { field: "roof_orientation", editable: true, width: 250 },
    { field: "roof_orientation_rating", editable: true, width: 110 },
    { field: "roof_status_rating", editable: true, width: 110 },
    { field: "roof_structure_complexity_rating", editable: true, width: 110 },
    { field: "roof_tilt", editable: true, width: 110 },
    {
      field: "roof_type",
      editable: true,
      width: 240,
      renderCell: (params) => (
        <RoofTypeCol
          roofType={roofType}
          setRoofType={setRoofType}
          params={params}
        />
      ),
    },
    { field: "shadows_rating", editable: true, width: 110 },
    { field: "state_of_roof", editable: true, width: 110 },
    { field: "total_inverter_capacity_1", editable: true, width: 110 },
    { field: "total_inverter_capacity_2", editable: true, width: 110 },
    { field: "total_inverter_capacity_22", editable: true, width: 110 },
    { field: "total_system_cost_", editable: true, width: 110 },
    {
      field: "type_of_solar_panel_if_already_installed",
      editable: true,
      width: 110,
    },
    { field: "unit_electricity_bill_rate", editable: true, width: 110 },
    { field: "unit_system_cost", editable: true, width: 110 },
    { field: "utility_bill_replacement_%", editable: true, width: 110 },
    { field: "utility_electric_rate_escalator_%", editable: true, width: 110 },
    { field: "utility_name", editable: true, width: 110 },
    { field: "year_of_roof_construction", editable: true, width: 110 },
  ];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  const [newOpen, setNewOpen] = useState(false);
  const handleClickOpen = () => {
    setNewOpen(true);
  };
  const handleChangeRow = (values, e) => {
    const result = rowData.filter((data, index) => data.qrn === values.id);
    const something = values.field;
    if (something === "flagOfQuote") {
      const docRef = db.collection("quotes");
      docRef
        .where("addr1", "==", result[0]?.addr1)
        .get()
        .then((snapshots) => {
          let types = {
            Green: false,
            smart: false,
            eco: false,
          };
          snapshots.forEach((doc) => {
            if (doc.data()?.flagOfQuote === "Green") {
              types["Green"] = true;
            } else if (doc.data()?.flagOfQuote === "smart") {
              types["smart"] = true;
            } else if (doc.data()?.flagOfQuote === "eco") {
              types["eco"] = true;
            }
          });
          if (types.Green === true && values.value === "Green") {
            let workingData = rowData;
            const newArr = workingData.map((element) => {
              if (element.qrn === result[0].qrn) {
                return { ...element, flagOfQuote: result[0]?.flagOfQuote };
              }
              return element;
            });
            setRowData([...newArr]);
            return toast.warn("Green flag alerady taken");
          } else if (types.smart === true && values.value === "smart") {
            let workingData = rowData;
            const newArr = workingData.map((element) => {
              if (element.qrn === result[0].qrn) {
                return { ...element, flagOfQuote: result[0]?.flagOfQuote };
              }
              return element;
            });
            setRowData([...newArr]);
            return toast.warn("smart flag alerady taken");
          } else if (types.eco === true && values.value === "eco") {
            let workingData = rowData;
            const newArr = workingData.map((element) => {
              if (element.qrn === result[0].qrn) {
                return { ...element, flagOfQuote: result[0]?.flagOfQuote };
              }
              return element;
            });
            setRowData([...newArr]);
            return toast.warn("eco flag alerady taken");
          } else {
            const updatedRow = {
              ...result[0],
              date: new Date(),
              [something]: values.value === undefined ? "" : values.value,
            };
            db.collection("quotes")
              .doc(values?.id)
              .set({
                ...updatedRow,
              })
              .then(() => {
                console.log("changed");
              })
              .catch((error) => {
                console.log(error);
              });
            return;
          }
        })
        .catch((err) => console.log(err));
    } else {
      const updatedRow = {
        ...result[0],
        date: new Date(),
        [something]: values.value === undefined ? "" : values.value,
      };
      db.collection("quotes")
        .doc(values?.id)
        .set({
          ...updatedRow,
        })
        .then(() => {
          console.log("changed");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteSelectedQuotes = () => {
    if (selected_items) {
      console.log(selected_items);
      selected_items.forEach((item) => {
        const getRightQuote = rowData.filter((data) => data.qrn === item);
        db.collection("quotes")
          .doc(item)
          .delete()
          .then(() => {
            toast.success("quote deleted sucessfully");
            db.collection("lastLogOfUser")
              .get()
              .then((snapshot) => {
                snapshot.forEach((doc) => {
                  if (!doc.data().lastVisitedAddress) {
                    return;
                  }
                  if (
                    doc
                      .data()
                      .lastVisitedAddress.toLowerCase()
                      .includes(getRightQuote[0].addr1)
                  ) {
                    db.collection("lastLogOfUser")
                      .doc(doc.id)
                      .set({
                        ...doc.data(),
                        lastVisitedAddress: null,
                      })
                      .then(() => {
                        console.log("deleted quote url from users");
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                });
              });
          })
          .catch((err) => console.log(err));
      });
    } else {
      alert("Select Items to Delete");
    }
  };

  const duplicateSelectedQuotes = () => {
    let finalSlected = [];
    if (selected_items.length > 1) {
      finalSlected.push(selected_items[0]);
    } else {
      finalSlected.push(selected_items[0]);
    }
    if (finalSlected.length > 0) {
      finalSlected.forEach((item) => {
        const selectedRowData = rowData.filter((x) => x.qrn == item);
        const docRef = db.collection("quotes");
        docRef
          .where("addr1", "==", selectedRowData[0]?.addr1)
          .get()
          .then((snapshots) => {
            let types = {
              Green: false,
              smart: false,
              eco: false,
            };
            snapshots.forEach((doc) => {
              if (doc.data()?.flagOfQuote === "Green") {
                types["Green"] = true;
              } else if (doc.data()?.flagOfQuote === "smart") {
                types["smart"] = true;
              } else if (doc.data()?.flagOfQuote === "eco") {
                types["eco"] = true;
              }
            });
            let finalPushStatus = "";
            if (types.Green === false) {
              finalPushStatus = "Green";
            } else if (types.smart === false) {
              finalPushStatus = "smart";
            } else if (types.eco === false) {
              finalPushStatus = "eco";
            } else {
              finalPushStatus = "none";
            }
            const id = generateString(24).replace(" ", "");
            docRef
              .doc(id)
              .set({
                ...selectedRowData[0],
                qrn: id,
                date: new Date(),
                flagOfQuote: finalPushStatus,
              })
              .then(() => console.log("duplicated successfully"))
              .catch((err) => console.log(err));
            // console.log(finalPushStatus, "finalPushStatus");
          })
          .catch((err) => console.log(err));
        console.log(selectedRowData, "selectedRowData");
      });
    } else {
      toast.warn("Select Quote to Duplicate");
    }
  };

  return (
    <Accordion expanded={true} style={{ flex: "1", margin: "0px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Details of Quotes</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "15px 30px",
              marginTop: "-10px",
              gap: "8px",
            }}
          >
            <Button
              variant="contained"
              style={{ background: "#3f51b5", color: "white" }}
              onClick={duplicateSelectedQuotes}
            >
              Duplicate
            </Button>
            <Button style={buttonStyle} onClick={handleClickOpen}>
              Add quotes
            </Button>
            <Button style={buttonStyle} onClick={deleteSelectedQuotes}>
              Delete quotes
            </Button>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              flex: "1",
              minHeight: "65vh",
            }}
          >
            {rowData.length > 0 ? (
              <DataGrid
                rows={rowData}
                getRowId={(row) => row.qrn}
                columns={columns}
                onCellEditCommit={(values, event) => {
                  handleChangeRow(values, event);
                }}
                pageSize={12}
                rowsPerPageOptions={[5]}
                selectionModel={selected_items}
                checkboxSelection
                onSelectionModelChange={(selection) => {
                  setselected_items(selection);
                }}
                disableSelectionOnClick
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {open ? (
          <OBJECT__POPUP
            open={open}
            objectDetails={objectDetails}
            openMethod={setOpen}
            id={id}
          />
        ) : null}
        <QuotesPopup open={newOpen} setopen={setNewOpen} />
      </AccordionDetails>
    </Accordion>
  );
};

export default Projects;
