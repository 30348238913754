import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import styled from "styled-components";
import InsideModal from "./InsideModal";

const AcceptInvition = ({
  isModalVisible1,
  setIsModalVisible1,
  data,
  name,
}) => {
  const handleCancel = () => {
    setIsModalVisible1(false);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalVisible1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleCancel}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InsideModal handleCancel={handleCancel} data={data} name={name} />
      </Modal>
    </div>
  );
};

export default AcceptInvition;
