import React, { useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core/";
import { DataGrid } from "@material-ui/data-grid";
import firebase, { db } from "../../../firebase";
import Dialog from "@material-ui/core/Dialog";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Upload, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const PaperStyle = {
  display: "flex",
  flexDirection: "column",
  width: 500,
  padding: 40,
};

const buttonStyle = {
  backgroundColor: "#3f51b5",
  color: "white",
};

//UserDetails section of Admin Dashboard
const QrnDetails = () => {
  const [userData, setUserData] = useState([]);
  const [open, setopen] = useState(false);
  const [amountof_panels, setamountof_panels] = useState(20);
  const [amountof_inverters, setamountof_inverters] = useState(1);
  const [all_inverters, setall_inverters] = useState(null);
  const [type_of_solar_panel, set_type_of_solar_panel] = useState(0);
  const [type_of_inverter, set_type_of_inverter] = useState(0);
  const [all_solar_panel, setall_solar_panel] = useState(null);
  const [param_id, setparam_id] = useState(null);
  const [isverified, setisverified] = useState("false");
  const [selected_items, setselected_items] = useState(null);
  const [profileImage, setProfileImage] = useState({
    file: null,
    url: null,
  });

  useEffect(() => {
    db.collection("crn_details").onSnapshot((snap) => {
      const data = [];
      snap.forEach((doc) => {
        data.push(doc.data());
      });
      setUserData(data);
    });

    db.collection("products")
      .where("type", "==", "inverter")
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        setall_inverters(data);
      });

    db.collection("products")
      .where("type", "==", "solar_panel")
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        setall_solar_panel(data);
      });
  }, []);

  const setQuote = (data, result) => {
    db.collection("quotes")
      .doc(param_id)
      .set(data)
      .then(() => {
        console.log("changed");
        toast.success("Your Quote has been generated");
        uploadImage(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const generateQuotewithSampleData = () => {
    const result = userData.filter((data, index) => data.id === param_id);
    const date = new Date().getTime();
    const new_date = `${
      date.getMonth() + 1
    }.${date.getDate()}.${date.getFullYear()}`;
    const new_time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const data = {
      ...result[0]?.quote,
      addr1: result[0]?.address
        .substr(0, result[0]?.address.indexOf(","))
        .toLowerCase(),
      customer_name: result[0]?.address
        .substr(0, result[0]?.address.indexOf(","))
        .toLowerCase(),
      city: result[0]?.city,
      country: result[0]?.country,
      crn: result[0]?.crn,
      qrn: result[0]?.id,
      lat: result[0]?.latitude,
      long: result[0]?.longitude,
      state: result[0]?.state,
      postcode: result[0]?.zip_code,
      zip_code: result[0]?.zip_code,
      date: new Date(),
      time: new_time,
      isVerified: isverified,
    };
    console.log(mainData, "mainData");
    setQuote(data, result);
    setopen(false);
  };

  const generateQuote = () => {
    const result = userData.filter((data, index) => data.id === param_id);
    console.log("resutl", result[0]);
    console.log(
      all_inverters[type_of_inverter],
      amountof_inverters,
      all_solar_panel[type_of_solar_panel],
      amountof_panels
    );
    const date = new Date();
    const new_date = `${
      date.getMonth() + 1
    }.${date.getDate()}.${date.getFullYear()}`;
    const new_time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const data = {
      ...result[0]?.quote,
      addr1: result[0]?.address
        .substr(0, result[0]?.address.indexOf(","))
        .toLowerCase(),
      customer_name: result[0]?.address
        .substr(0, result[0]?.address.indexOf(","))
        .toLowerCase(),
      inverter_id: all_inverters[type_of_inverter].objectId,
      inverter_quantity_1: amountof_inverters,
      module_id: all_solar_panel[type_of_solar_panel].objectId,
      module_quantity: amountof_panels,
      city: result[0]?.city,
      country: result[0]?.country,
      crn: result[0]?.crn,
      qrn: result[0]?.id,
      lat: result[0]?.latitude,
      long: result[0]?.longitude,
      state: result[0]?.state,
      postcode: result[0]?.zip_code,
      zip_code: result[0]?.zip_code,
      date: new Date(),
      time: new_time,
      isVerified: isverified,
    };
    setQuote(data, result);
    setopen(false);
  };

  const columns = [
    {
      field: "crn",
      width: 300,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <div style={{ width: "100px" }}>
            <strong>{params.value}</strong>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setparam_id(params.id);
              setopen(true);
            }}
            style={{ width: 150, height: 35 }}
          >
            Generate Quote
          </Button>
        </div>
      ),
    },
    {
      field: "date",
      width: 150,
      // renderCell: (params) => (
      //   <strong>
      //     {`${new Date(params.value).getDate()}/${
      //       new Date(params.value).getMonth() + 1
      //     }/${new Date(params.value).getFullYear()}`}
      //   </strong>
      // ),
    },
    { field: "time", width: 150 },
    { field: "state", width: 150, editable: true },
    { field: "city", width: 150 },
    { field: "country", width: 150 },
    { field: "zip_code", width: 150 },
    { field: "address", width: 440 },
    { field: "address1", width: 340 },
    { field: "latitude", width: 150 },
    { field: "longitude", width: 150 },
    { field: "id", width: 150 },
  ];

  function getBase64(file) {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    } catch (error) {}
  }

  const onChangeProfile = async (e) => {
    if (e.file.status !== "uploading") {
      const base64 = await getBase64(e.file);
      setProfileImage({
        file: e.file,
        url: base64,
      });
    }
  };

  const uploadImage = (result) => {
    if (profileImage?.file) {
      db.collection("quotes")
        .where("crn", "==", result[0].crn)
        .get()
        .then((snapshots) => {
          const data = [];
          snapshots.forEach((doc) => {
            data.push(doc.data());
          });
          const id = data[0].qrn;
          console.log("id", id);
          firebase
            .storage()
            .ref("quotes/" + id)
            .put(profileImage?.file)
            .then((snapshot) => {
              firebase
                .storage()
                .ref("quotes/" + id)
                .getDownloadURL()
                .then((url) => {
                  db.collection("quotes")
                    .doc(id)
                    .set({
                      ...data[0],
                      img_url: [url],
                      combined_img: profileImage?.file.name,
                    });
                  toast.success("image uploaded");
                })
                .catch((error) => {
                  alert("error");
                });
            });
        });
    }
  };

  const deleteSelected = () => {
    if (selected_items) {
      selected_items.forEach((item) => {
        db.collection("crn_details")
          .doc(item)
          .delete()
          .then(() => console.log("deleted"))
          .catch((err) => console.log(err));
      });
    } else {
      alert("Select Items to Delete");
    }
  };

  return (
    <>
      <Paper elevation={3} style={{ flex: "1", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
          }}
        >
          <Typography style={{ textAlign: "center" }}>CRN Details</Typography>
          <Button onClick={deleteSelected} style={buttonStyle}>
            Delete
          </Button>
        </div>
        <div style={{ width: "100%", height: 800, padding: 20 }}>
          {userData.length > 0 ? (
            <DataGrid
              rows={userData}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={12}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(e) => setselected_items(e)}
            />
          ) : (
            ""
          )}
        </div>
      </Paper>
      <Dialog onClose={() => setopen(false)} open={open}>
        <Paper style={PaperStyle} elevation={3}>
          <TextField
            label="Total Solar Panels"
            value={amountof_panels}
            onChange={(e) => setamountof_panels(e.target.value)}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="solar-panel-label">Type of Solar Panel</InputLabel>
            <Select
              labelId="solar-panel-label"
              id="solar-panel"
              value={type_of_solar_panel}
              onChange={(e) => {
                set_type_of_solar_panel(e.target.value);
              }}
              label="Type of Solar Panel"
            >
              {all_solar_panel?.map((solar_panel, key) => {
                return (
                  <MenuItem key={key + 99} value={key}>
                    {solar_panel.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label="Amount of Inverter"
            value={amountof_inverters}
            onChange={(e) => setamountof_inverters(e.target.value)}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="inverter-label">Type of Inverter</InputLabel>
            <Select
              labelId="inverter-label"
              id="inverter"
              value={type_of_inverter}
              onChange={(e) => {
                set_type_of_inverter(e.target.value);
              }}
              label="Type of Inverter"
            >
              {all_inverters?.map((inverter, key) => {
                return (
                  <MenuItem key={key + 999} value={key}>
                    {inverter.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Checkbox
            checked={isverified === "true"}
            onChange={(e) => {
              if (e.target.checked) {
                setisverified("true");
              } else {
                setisverified("false");
              }
            }}
          >
            isVerified
          </Checkbox>
          <div style={{ marginTop: 10 }}>
            <Upload
              accept="image/*"
              onChange={onChangeProfile}
              beforeUpload={() => false}
              customRequest={() => false}
            >
              <Button style={buttonStyle} startIcon={<UploadOutlined />}>
                Click to Upload
              </Button>
            </Upload>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              gap: "10px",
            }}
          >
            <Button style={buttonStyle} onClick={generateQuote}>
              Generate Quote
            </Button>
            <Button style={buttonStyle} onClick={generateQuotewithSampleData}>
              Generate Quote with Sample Data
            </Button>
          </div>
        </Paper>
      </Dialog>
    </>
  );
};

export default QrnDetails;
