import React, { useState, useEffect } from "react";

function WebinarGroupChat({ id, name }) {
	const [minimized, setminimized] = useState(false);
	const closeButtonHandler = () => {
		toggle();
	};

	const minimizedHandler = () => {
		toggle();
	};

	const toggle = () => {
		setminimized(!minimized);
	};

	return (
		<>
		</>
	);
}

export default WebinarGroupChat;
