import React, { useEffect, useState } from "react";
import { Button, Input, Card, Form, Upload } from "antd";
import "./profile.css";
import userPlaceholder from "Assets/userPlaceholder.svg";
import camera from "Assets/camera.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "Components/loader";
// import { camera, userPlaceholder } from "helper/constant";
import firebase, { db } from "../../../firebase";
import { toast } from "react-toastify";
import { colors } from "@material-ui/core";
import { useHistory } from "react-router";
import { useStripe } from "@stripe/react-stripe-js";

function Profile() {
  const [profileImage, setProfileImage] = useState({
    file: null,
    url: null,
  });
  const [form] = Form.useForm();
  const stripe = useStripe();
  const [userDetail, setUserDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [savedProjects, setSavedProjects] = React.useState(null);
  const [loadComponent, setLoadComponent] = useState(false);
  const [status, setStatus] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.user?.currentUser);
  useEffect(() => {
    setLoadComponent(true);
    if (currentUser) {
      db.collection("users")
        .doc(currentUser?.uid)
        .get()
        .then((doc) => {
          setLoadComponent(false);
          setUserDetail(doc.data());
        })
        .catch((e) => {
          setLoadComponent(false);
        });
    }
  }, [currentUser, history.pathname]);

  useEffect(() => {
    form.setFieldsValue({
      Phone_number: userDetail?.phoneNumber || "",
      Email: userDetail?.email,
      first_Name: userDetail?.fullName?.split(" ").slice(0, -1).join(" "),
      Last_Name: userDetail?.fullName?.split(" ").slice(-1).join(" "),
    });
  }, [userDetail, history.pathname]);

  const { TextArea } = Input;
  const onFinish = (values) => {
    db.collection("users")
      .doc(currentUser?.uid)
      .set({
        ...userDetail,
        email: values?.Email,
        phoneNumber: values?.Phone_number || "",
        fullName: values?.first_Name + " " + values?.Last_Name,
        otherDetails: userDetail?.otherDetails || "other details",
        uid: currentUser?.uid,
        projectDetails: userDetail?.projectDetails || null,
      })
      .then(() => toast.success("details changed successfully"))
      .catch(() => toast.error("something went wrong"));
  };
  function getBase64(file) {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    } catch (error) {}
  }
  const onChangeProfile = async (e) => {
    if (e.file.status !== "uploading") {
      const base64 = await getBase64(e.file);
      setProfileImage({
        file: e.file,
        url: base64,
      });
    }
  };

  const onChange = (e) => {};
  const uploadImage = () => {
    setLoading(true);
    firebase
      .storage()
      .ref("user/" + currentUser?.uid)
      .put(profileImage?.file)
      .then((snapshot) => {
        firebase
          .storage()
          .ref("user/" + currentUser?.uid)
          .getDownloadURL()
          .then((url) => {
            db.collection("users")
              .doc(currentUser?.uid)
              .set({
                ...userDetail,
                img_url: url,
              })
              .then(() => {
                toast.success("image uploaded");
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
              });
          })
          .catch((error) => {
            alert("error");
          });
      });
  };

  const checkStatusHandler = (paymentIntent) => {
    // console.log(paymentIntent, "paymentIntent");
    stripe
      .retrievePaymentIntent(paymentIntent?.client_secret)
      .then(function (response) {
        console.log(response, "response");
        if (
          response.paymentIntent &&
          response.paymentIntent.status === "succeeded"
        ) {
          // Handle successful payment here
          console.log("wow successss");
          setStatus("wow payment is successfull");
        } else {
          // Handle unsuccessful, processing, or canceled payments and API errors here
          setStatus(`payment was ${response.paymentIntent.status}`);
        }
      });
  };

  useEffect(() => {
    if (currentUser?.uid) {
      db.collection("saved_projects")
        .where("uid", "==", currentUser?.uid)
        .get()
        .then((snap) => {
          const data = [];
          snap.forEach((doc) => {
            data.push(doc.data());
          });
          setSavedProjects(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      {!loadComponent ? (
        <div className="mainclass">
          <div
            className="mx25 my15"
            style={{
              width: "100%",
              marginLeft: "75px",
              height: "100%",
              marginTop: "30px",
            }}
          >
            <div className="flex-x mt10">
              <Card
                type="inner"
                className="an-20 semi-bold-text"
                style={{ width: "320px", height: "300px" }}
              >
                <div className="new-container">
                  <Upload
                    accept="image/*"
                    onChange={onChangeProfile}
                    showUploadList={false}
                    beforeUpload={() => false}
                    customRequest={() => false}
                  >
                    {profileImage.url || userDetail?.img_url ? (
                      <img
                        className="user"
                        src={
                          profileImage.url
                            ? profileImage.url
                            : userDetail?.img_url
                        }
                        alt="user"
                      />
                    ) : (
                      <img
                        className="user"
                        src={userPlaceholder}
                        alt="placeholder"
                      />
                    )}
                    <img className="camera" src={camera} alt="camera" />
                  </Upload>
                </div>
                <div className="inside-container">
                  <Button
                    htmlType="submit"
                    onClick={uploadImage}
                    loading={loading}
                    className="submit-button an-15 regular-text"
                  >
                    {userDetail?.img_url ? "Change Photo" : "Upload Image"}
                  </Button>
                </div>
              </Card>
              <Card
                type="inner"
                title="User Settings"
                className="an-24 semi-bold-text ml25"
                style={{ width: "60%", marginLeft: "25px" }}
              >
                <div className="pt10" style={{ paddingTop: "10px" }}>
                  <div>
                    <Form form={form} onFinish={onFinish}>
                      <div className="flex-x">
                        <div>
                          <div
                            className="an-16 bold-text mb5"
                            style={{ marginBottom: "5px" }}
                          >
                            Phone number
                          </div>
                          <Form.Item
                            name="Phone_number"
                            className="mb20 login-input"
                          >
                            <Input
                              type="text"
                              placeholder="Phone number"
                              style={{ width: "250px" }}
                            />
                          </Form.Item>
                        </div>
                        <div className="ml20" style={{ marginLeft: "20px" }}>
                          <div
                            className="an-16 bold-text mb5"
                            style={{ marginBottom: "5px" }}
                          >
                            Email Adress
                          </div>
                          <Form.Item
                            name="Email"
                            disabled
                            className="mb20 login-input"
                          >
                            <Input
                              type="text"
                              disabled={true}
                              placeholder="Email"
                              style={{ width: "250px" }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="flex-x">
                        <div>
                          <div
                            className="an-16 bold-text mb5"
                            style={{ marginBottom: "5px" }}
                          >
                            First Name
                          </div>
                          <Form.Item
                            name="first_Name"
                            className="mb20 login-input"
                            style={{ marginBottom: "20px" }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter first name",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="first Name"
                              style={{ width: "250px" }}
                            />
                          </Form.Item>
                        </div>
                        <div className="ml20" style={{ marginLeft: "20px" }}>
                          <div
                            className="an-16 bold-text mb5"
                            style={{ marginBottom: "5px" }}
                          >
                            Last Name
                          </div>
                          <Form.Item
                            name="Last_Name"
                            className="mb20 login-input"
                            rules={[
                              {
                                required: true,
                                message: "Please enter last name",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Last Name"
                              style={{ width: "250px" }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <Button
                        htmlType="submit"
                        className="submit-button an-15 regular-text"
                      >
                        Save Changes
                      </Button>
                    </Form>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        <Loader loading={loadComponent} color="#FFAA39" />
      )}
      <div className="worler">
        {savedProjects &&
          savedProjects.map((data, key) => {
            return (
              <div key={key}>
                {data?.project_details?.payment?.status !== "pending" ? (
                  <div>
                    {data.pid}
                    <button
                      onClick={() =>
                        checkStatusHandler(
                          data.project_details.payment.paymentIntent
                        )
                      }
                    >
                      check status
                    </button>
                    {status}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Profile;
