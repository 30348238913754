import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { db } from '../../../../firebase';
import { Autocomplete, TextField } from '@mui/material';
import { toast } from 'react-toastify';
export default function SelectNewQuote({ params, quotesNames }) {
	const [val, setVal] = React.useState('');
	React.useEffect(() => {
		setVal(params?.formattedValue);
	}, []);
	const handleChange = (newValue) => {
		if (newValue === null) {
			return;
		}
		let obj = {
			...params.row
		};
		console.log(obj, 'obj');

		setVal(newValue);
		let qrn = newValue.split(' ');
		qrn = qrn[qrn.length - 1];
		console.log(qrn, 'newValue');
		db.collection('quotes')
			.doc(qrn)
			.get()
			.then((doc) => {
        console.log(doc.data(),"doc");
        
				db.collection('saved_projects')
					.doc(obj.pid)
					.set({
						...obj,
						project_details: {
							...obj?.project_details,
							address: {
								...obj?.project_details?.address,
								address: doc.data()?.addr1,
								postCode: doc.data()?.zip_code
							}
						},
            qrn:doc.data()?.qrn
					})
					.then(() => {
						toast.success(`new ${newValue} quote added to selected project`);
					})
					.catch(() => {
						toast.error('some thing went wrong');
					});
			})
			.catch((err) => console.log('errr', err));
	};

	return (
		<div style={{ width: '100%' }}>
			<Autocomplete
				disablePortal
				value={val}
				onChange={(event, newValue) => {
					handleChange(newValue);
				}}
				id="combo-box-demo"
				options={quotesNames}
				renderInput={(params) => (
					<TextField {...params} label="Choose installer partner" />
				)}
			/>
		</div>
	);
}
