import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { db } from "./../../../../../../firebase";
import { useSelector } from "react-redux";

const MainContainer = styled("div")`
  width: 60%;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  padding-right: 28px;
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 13px;
  }
`;
const LeftContainer = styled("div")`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`;
const RightContainer = styled("div")`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  div {
    @media (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;
const Text1 = styled("span")`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #666666;
`;
const Text2 = styled("span")`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #2d4764;
  margin-right: 4px;
`;
const Text3 = styled("span")`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #2d4764;
  cursor: pointer;
`;
const Info = ({ data }) => {
  const [userData, setUserData] = useState(false);
  const [load, setLoad] = useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  useEffect(() => {
    if (data.is_accepted) {
      setLoad(true);
      db.collection("users")
        .doc(data.sender_uid)
        .get()
        .then((doc) => {
          setUserData(doc.data());
          setLoad(false);
        })
        .catch((error) => console.log(error));
    } else {
      setLoad(true);
      db.collection("users")
        .doc(data.receiver_uid)
        .get()
        .then((doc) => {
          setUserData(doc.data());
          setLoad(false);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <MainContainer>
      {load ? null : (
        <>
          <LeftContainer>
            {userData.img_url ? (
              <Avatar
                src={userData.img_url}
                style={{ width: 55, height: 55 }}
              />
            ) : (
              <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
                {userData?.fullName}
              </Avatar>
            )}
          </LeftContainer>
          <RightContainer>
            {!data.duration ? (
              data.is_accepted ? (
                <div>
                  <Text1>
                    {isEnglish ? "You have accepted" : "Sie haben akzeptiert"}
                  </Text1>
                  <Text2 style={{ marginLeft: "5px" }}>
                    {userData.fullName},
                  </Text2>
                  <Text1>
                    {isEnglish ? "chat invitation" : "Katze einladen"}
                  </Text1>
                </div>
              ) : (
                <>
                  <Text2> {userData.fullName},</Text2>
                  <Text1>
                    {isEnglish
                      ? "accepted your chat invitation"
                      : "deine Chat Einladung angenommen  "}
                  </Text1>
                </>
              )
            ) : data.is_accepted ? (
              <div>
                <Text1>You have accepted</Text1>
                <Text2 style={{ marginLeft: "5px" }}>
                  {userData.fullName},
                </Text2>
                <Text1>
                  {isEnglish ? "meet invitation" : "Einladung treffen"}
                </Text1>
              </div>
            ) : (
              <div>
                <Text2> {userData.fullName},</Text2>
                <Text1>
                  {isEnglish
                    ? "accepted your meet invitation"
                    : "Ihre Einladung zum Treffen angenommen"}{" "}
                </Text1>
              </div>
            )}
            <Text3>{isEnglish ? "View chat box" : "Chat öffnen"}</Text3>
          </RightContainer>
        </>
      )}
    </MainContainer>
  );
};

export default Info;
