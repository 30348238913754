import { put, call, takeLatest } from "redux-saga/effects";
import { getNumber } from "../../utils/costs";
import { db } from "../../firebase";

function fetchImageAsync(quote) {
  return new Promise((resolve, reject) => {
    //Task No 4
    db.collection("other_details/project_details/quote_images")
      .doc(quote?.qrn === null ? "something" : quote?.qrn)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          resolve(snapshot.data());
        } else {
          resolve(
            "https://firebasestorage.googleapis.com/v0/b/solar-app-229e7.appspot.com/o/defaultQuoteImages%2FSOLARHUB00450_2_combined.png?alt=media&token=e8ee5c78-7e4d-4409-822a-d48cfdfde09b"
          );
        }
      })
      .catch((e) => {
        resolve(
          "https://firebasestorage.googleapis.com/v0/b/solar-app-229e7.appspot.com/o/defaultQuoteImages%2FSOLARHUB00450_2_combined.png?alt=media&token=e8ee5c78-7e4d-4409-822a-d48cfdfde09b"
        );
      });
  });
}

// Code for fetching quote details of saved project & updating Redux
export function* setProjectAsync(action) {
  function fetchQuoteStartAsync() {
    return new Promise((resolve, reject) => {
      const data = [];
      db.collection("quotes")
        .where("qrn", "==", action.payload.qrn)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const temp = doc.data();
            if (temp) {
              resolve(temp);
            } else {
              reject(new Error("Quote does not exist"));
            }
          });
        });
    });
  }

  try {
    const data = yield call(fetchQuoteStartAsync);
    const imgUrl = yield call(fetchImageAsync, data);
    yield put({ type: "FETCH_QUOTE_SUCCESS", payload: data });
    yield put({ type: "SET_PROJECT", payload: action.payload });
    yield put({ type: "SET_COMING_OVERVIEW", payload: false });
    yield put({ type: "SET_IMG_URL", payload: imgUrl });
    yield put({ type: "SET_LOADING", payload: false });
  } catch (e) {
    yield put({ type: "FETCH_QUOTE_FAILURE", payload: e.message });
    yield put({ type: "SET_LOADING", payload: false });
  }
}

//Code for adding a new project to Redux which has not been saved before
//Get product details by product IDS (Module & Inverter)
export function* addProjectAsync(action) {
  const quote = action.payload;

  function fetchSoiarPanelAsync() {
    return new Promise((resolve, reject) => {
      db.collection("products")
        .doc(quote.quote.module_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject(new Error("Module not found"));
          }
        })
        .catch((e) => {
          reject(new Error(e.message));
        });
    });
  }
  function fetchInverterAsync() {
    return new Promise((resolve, reject) => {
      db.collection("products")
        .doc(quote.quote.inverter_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject(new Error("Installer not found"));
          }
        })
        .catch((e) => {
          reject(new Error(e.message));
        });
    });
  }

  try {
    const solarPanel = yield call(fetchSoiarPanelAsync);
    const inverter = yield call(fetchInverterAsync);
    const imgUrl = yield call(fetchImageAsync, quote.quote);

    yield put({
      type: "ADD_COMPONENT",
      payload: {
        item: solarPanel,
        quantity: getNumber(quote.quote.module_quantity),
        cost:
          getNumber(quote.quote.module_quantity) *
          getNumber(solarPanel.price_b2b),
      },
    });
    yield put({
      type: "ADD_COMPONENT",
      payload: { item: inverter, quantity: 1, cost: 350 },
    });

    yield put({
      type: "SET_QRN",
      payload: quote.quote.qrn,
    });
    yield put({ type: "SET_IMG_URL", payload: imgUrl });
    yield put({ type: "SET_LOADING", payload: false });
  } catch (e) {
    alert(e.message);
    console.error("error occured", e.message);
    yield put({ type: "SET_LOADING", payload: false });
  }
}

export function* setProjectStart() {
  yield takeLatest("SET_PROJECT_ASYNC", setProjectAsync);
  yield takeLatest("ADD_PROJECT_ASYNC", addProjectAsync);
}
