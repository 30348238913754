import React, { useState } from "react";
import styled from "styled-components";
import CreateTopicModal from "./CreateTopicModal";
import { useSelector } from "react-redux";
import SearchResult from "./SearchResult";
import axios from "axios";
import { Select } from "antd";

const { Option } = Select;

const MainContainer = styled("div")`
  width: 100%;
  padding: 23px 34px;
  border-bottom: 1px solid #dfdfdf;
  height: 96px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 10px;
    padding-top: 24px;
    padding-left: 9px;
    gap: 1px;
  }
  gap: 19px;
  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 20px;
    margin-right: 16px;
  }

  .dropdown-body {
    height: 100px;
    width: 100px;
    background-color: black;
    padding: 5px;
    border-top: 1px solid #e5e8ec;
    z-index: 100000;
    position: absolute;
    bottom: 0;
  }

  .dropdown-item {
    background-color: #f9f9f9;
    padding: 10px;
  }

  .dropdown-item:hover {
    cursor: pointer;
    background-color: #fcefc9;
  }

  .icon {
    font-size: 13px;
    color: #91a5be;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
  }

  .icon.open {
    transform: rotate(90deg);
  }
`;
const SubContainer = styled("div")`
  position: relative;
  width: 534px;
  height: 45px;
  background: #f9f9f9;
  border-radius: 59px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: ${(props) => {
    if (props.open == true) {
      return "0px";
    }
  }};
  border-bottom-right-radius: ${(props) => {
    if (props.open == true) {
      return "0px";
    }
  }};
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 0px;
  }
`;
const SubContainer1 = styled("input")`
  width: 60%;
  height: 45px;
  background: #f9f9f9;
  -webkit-appearance: none;
  border: none;
  :focus {
    outline: none;
    border: none;
  }
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2d4764;
`;
const SubContainer22 = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const SubContainer2 = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  @media (max-width: 600px) {
    display: none;
  }
  border: 1px solid #c0c0c0;
  transform: rotate(180deg);
`;
const TopicButton = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 131px;
  @media (max-width: 600px) {
    width: 154px;
  }
  height: 42px;
  background: #ffd75d;
  border-radius: 45px;
  font-weight: 500;
  font-size: 15px;
  color: #2d4764;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
const DropContainer = styled("div")`
  width: 31%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
`;
const DropContainerText = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #2d4764;
`;
const Search = ({ move }) => {
  const [data, setData] = useState([]);
  const [open1, setOpen1] = useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const [selectedItem, setSelectedItem] = useState("Categories");
  const [value, setValue] = useState("");
  const toggleDropdown = () => setOpen(!isOpen);

  function handleChange(value) {
    setSelectedItem(value);
  }

  const searchHadler = async (e) => {
    if (e.key === "Enter") {
      if (e.target.value === "") {
        return;
      }
      const res = await axios.get(
        `https://us-central1-solarhub-44450.cloudfunctions.net/api/general/search?q=${e.target.value}`
      );
      if (selectedItem === "Categories") {
        if ("categories" in res.data) {
          if (res.data.categories.length > 0) {
            setOpen(true);
          }
          setData(res.data.categories);
        }
      } else {
        if ("topics" in res.data) {
          if (res.data.topics.length > 0) {
            setOpen(true);
          }
          setData(res.data.topics);
        }
      }
    }
  };
  return (
    <>
      <MainContainer>
        <SubContainer open={isOpen}>
          <div className="search">
            <svg
              width="17"
              height="17"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 18L13.9862 13.9791L18 18ZM16.2105 8.60526C16.2105 10.6223 15.4093 12.5567 13.983 13.983C12.5567 15.4093 10.6223 16.2105 8.60526 16.2105C6.58822 16.2105 4.65379 15.4093 3.22753 13.983C1.80127 12.5567 1 10.6223 1 8.60526C1 6.58822 1.80127 4.65379 3.22753 3.22753C4.65379 1.80127 6.58822 1 8.60526 1C10.6223 1 12.5567 1.80127 13.983 3.22753C15.4093 4.65379 16.2105 6.58822 16.2105 8.60526V8.60526Z"
                stroke="#2D4764"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <SubContainer1
            placeholder={
              isEnglish
                ? "Search the community or ask a question"
                : "Durchsuche die Community oder stell eine Frage"
            }
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyPress={searchHadler}
            value={value}
          />
          <SubContainer22>
            <SubContainer2 />
          </SubContainer22>

          <Select
            defaultValue="Categories"
            style={{
              width: 140,
              display: window.innerWidth < 500 ? "none" : "",
            }}
            onChange={handleChange}
          >
            <Option value="Categories">
              {isEnglish ? "Categories" : "Kategorien"}
            </Option>
            <Option value="Topics">{isEnglish ? "Topics" : "Themen"}</Option>
          </Select>
        </SubContainer>
        <TopicButton
          onClick={() => {
            setOpen1(true);
          }}
        >
          {isEnglish ? "+ New Topic" : "Neues Thema"}
        </TopicButton>
        <CreateTopicModal setOpen={setOpen1} open={open1} />
        {isOpen && (
          <SearchResult
            isEnglish={isEnglish}
            data={data}
            move={move}
            selectedItem={selectedItem}
            setOpen={setOpen}
            setValue={setValue}
          />
        )}
      </MainContainer>
    </>
  );
};

export default Search;
