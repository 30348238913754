export const FaqsContent = [
  {
    title: "Warum sollte ich mir eine Photovoltaikanlage anschaffen?",
    content:
      "Photovoltaik bietet als einzige stromerzeugende Technologie die Möglichkeit, ein eigenes Kraftwerk zu Hause oder im Unternehmen zu betreiben. Damit erreicht man eine Preisgarantie von 25 bis 35 Jahren sowie eine Teil-Unabhängigkeit vom konventionellen Energieversorger. Außerdem gehört Photovoltaik heute zu den günstigsten Energieformen. Somit lassen sich leerstehende Dachflächen effizient nützen. Nach teilweise zwei Jahren produzieren Photovoltaikanlagen klimaneutralen Strom.",
  },
  {
    title: "Wie viele Kilowattstunden (kWh) produzieren 1 kWp?",
    content:
      "In Deutschland liegt der Durchschnittswert pro Jahr bei 1000 kWh/kWp. Je nach Aufständerung und Standort können es 900 - 1100 kWh pro Jahr sein.",
  },
  {
    title: "Was ist der Unterschied zwischen kWp und kWh?",
    content:
      "Ein kWp (Kilowatt peak) bezeichnet die Nennleistung eines Moduls. Wie viel Energie aus dem Modul herauskommt hängt davon ab, wie viele Stunden die Sonne darauf scheint. In Deutschland produziert ein Feld von 1 kWp ca. 1000 kWh (Kilowattstunden) sauberen Strom.",
  },
  {
    title: "Was ist der Unterschied zwischen kW und kWp?",
    content:
      "kW steht für Kilowatt und beschreibt die Leistung eines Gerätes. Es definiert, wie viel Energie (Einheit: 1000 Joule) in einem bestimmten Moment verbraucht oder erzeugt wird. kWp ist der Begriff für die Nennleistung einer Photovoltaikanlage und steht für Kilowatt peak. Diese Nennleistung hat aber nichts mit der Spitzenleistung zu tun, auch wenn der Begriff dies vermuten lässt. Es handelt sich um eine Leistung unter sogenannten “Standardtestbedingungen”: 25 Grad Umgebungstemperatur 1000 Watt Einstrahlung 90 Grad Einstrahlungswinkel Das bedeutet auch, dass unter “besseren” Bedingungen, wenn es zum Beispiel kälter ist und die Sonne mehr als 1000 Watt stark strahlt, bspw. auch im März mehr als die Nennleistung produziert werden kann. Wichtig: die entscheidende Kennzahl, ob eine Anlage gut läuft sind nicht die kWp, sondern die daraus produzierten Kilowattstunden über ein Jahr gesehen und hier ist es wichtig wenig Verschattung und viele Sonnenstunden am Dach zu haben.",
  },
  {
    title: "Wie viel Energie liefert die Sonne?",
    content:
      "Der Weltenergieverbrauch wird im Jahr 2020 auf etwa 600 Exajoule geschätzt. Die Sonne liefert pro Jahr ungefähr das 10.000 fache davon.",
  },
  {
    title: "Was bedeutet Netzparität?",
    content:
      "Das bedeutet, dass die Kosten von Strom aus erneuerbaren Energieträgern gleich viel betragen wie die Marktpreise von herkömmlicher elektrischer Energie. Dabei gibt es verschiedene Stufen, die erreicht werden können: auf der ersten Ebene richtet man den Blick auf die Gleichheit mit dem Haushaltsstrompreis, der in Österreich 18 - 20 ct/kWh beträgt. Diese Ebene wurde bereits erreicht! Auf der zweiten Stufe findet sich der Gewerbe- und Industriestrompreis (Ö: 5 - 15 ct/kWh). Im Gewerbe wurde ebenfalls schon eine Netzparität erreicht. Die dritte Stufe bedeutet Gleichheit mit dem Preis, der an der Börse gehandelt wird. Dieser bewegt sich derzeit (2019) zwischen 3 - 6 ct/kWh. Dazu lässt sich festhalten, dass neue Großkraftwerke bereits auch in diesem Bereich Netzparität erreicht haben.",
  },
  {
    title: "Wie hoch sind die Betriebskosten einer PV-Anlage?",
    content:
      "Als Faustformel gilt: die Betriebskosten entsprechen in etwa 1 - 2 % der Investitionskosten pro kWp. Ein Beispiel: Hat man 1300 €/kWp bezahlt, belaufen sich die Betriebskosten auf etwa 13 €/kWp. Bei einem durchschnittlichen 4-Personen-Haushalt mit einer 5 kWp Anlage wären dies insgesamt 65 €. Diese sind aber eher als Rücklage für den Austausch des Wechselrichters nach ca. 12 Jahren zu betrachten. Bei Anlagen in Unternehmen müssen außerdem je nach Betriebsanlage, Überprüfungsprotokolle von einem Elektriker zu erstellen. Die Frage der Betriebskosten ist sehr stark von der Anlage abhängig und je besser und störungsfreier die Anlage gebaut ist, desto geringer sind die Betriebskosten.Wenn Sie einen grundsätzlichen Überblick über die Kosten von Photovoltaikanlagen haben wollen, klicken Sie hier.",
  },
  {
    title:
      "Ist das Angebot ein Festpreisangebot und beinhaltet es alle Kosten?",
    content:
      "Neque tenetur sed dicta ullam voluptatum. Quod voluptatem occaecati quas consequuntur optio aperiam. Blandi",
  },
  {
    title: "Wie kann Solarhub so schnell ein Angebot erstellen?",
    content:
      "Quam animi vel maiores ducimus eveniet et consequuntur et aut. Veniam illo officia. Tempora non culpa mollitia ut sun",
  },
  {
    title:
      "Müssen Mitarbeiter von Solarhub bei mir zu Hause vorbeikommen, um ein Angebot zu erstellen?",
    content:
      "Sapiente porro perferendis voluptas qui aut. Tempore aut corporis quis. Ut reiciendis totam sint sapiente itaque dolorem totam. Eo",
  },
  {
    title: "Welche Fotos benötigt Solarhub von mir?",
    content: "Vero sapiente voluptatum ratione. Pariatur blanditiis r",
  },
  {
    title:
      "Warum erhalte ich als Angebot einen Link zum Solarhub Online-Konfigurator?",
    content:
      "Nobis inventore nisi accusantium quis culpa. Aut eos voluptatum perspiciatis. Facere optio",
  },
];

export const componentsContent = [
  {
    title: "Was kosten Photovoltaikanlagen?",
    content:
      "Eine Faustregel für größere Anlagen lautet 1000,- EUR pro kWp für eine grobe Kostenschätzung. Die Preise von Photovoltaikanlagen hängen aber vor allem mit der Anlagengröße zusammen. 2008 zahlte man pro kWp noch etwa 5000 €, heute beläuft sich dieser Wert bei kleineren Anlagen auf etwa 1600,- EUR pro kWp. Bei Großanlagen zahlt man zum Teil schon weniger als 1000,- EUR pro kWp. Einen ausführlichen Artikel finden Sie hier. Näheres zu Betriebskosten finden Sie hier.",
  },
  {
    title: "Wie setzen sich die Kosten einer Photovoltaikanlage zusammen?",
    content:
      "Die Kosten teilen sich grundsätzlich in fixe und variable Kosten, wobei die Fixkosten mit zunehmender Anlagengröße immer schwächer ins Gewicht fallen. Grundsätzlich rechnet man laut einer Studie der TU Wien aus 2018 pro Photovoltaikanlage mit Fixkosten von etwa 3500 EUR. Dazu gehören beispielsweise Kostenpunkte wie Genehmigungen oder die Projektentwicklung. Beispiel: Für einen 4-Personen-Haushalt benötigt man ca. eine 5 kWp leistungsstarke Anlage. Die Gesamtkosten betragen in etwa 9000 EUR, wobei die variablen Kosten 5500 EUR ausmachen. Für ein mittelständisches Unternehmen werden in etwa 50 kWp veranschlagt, dabei belaufen sich die Gesamtkosten auf ungefähr 55.000 EUR (3500 EUR fixe Kosten, der Rest variable Kosten). Den größten Kostenunterschied macht mittlerweile die Wahl der Unterkonstruktion und der Zugang aufs Dach aus. Am günstigsten ist der Bau auf Blechfalzdächern, am teuersten ein südlich aufgeständerte Flachdachkonstruktion. Hier zu unserem ausführlichen Kostenartikel.",
  },
  {
    title: "Ab wann rechnet sich eine Photovoltaikanlage?",
    content:
      "Ab dem ersten Tag der Produktion. Je nachdem wie viel Sie derzeit für Ihren Strom bezahlen, ist der “hauseigene” PV-Strom um 10 - 70 % günstiger als jener vom Netz. Eine Pauschalaussage lässt sich nicht treffen. Dieser Faktor hängt einzig und alleine vom derzeitigen Strompreis und der Verbrauchssituation ab. Wenn der Strom aus der Anlage um 50 % günstiger ist als jener vom Netz, sind es 10 - 12 Jahre. In den meisten Fällen in Österreich ist der Strom um 30 - 60 % günstiger. Wenn die Kosten für den Photovoltaikstrom, also die Gestehungskosten gleich hoch sind wie jene vom Netz, spricht man von Netzparität oder “Grid Parity”. In diesem Fall handelt es sich um eine Strompreisgarantie für die Dauer von ca. 25 Jahren. Dies ist meist bei kleinen Photovoltaikanlagen mit niedrigem Stromverbrauch der Fall. In der Dachgold Amortisationsmatrix sind diese Zusammenhänge nochmal bildlich verdeutlicht. Generell gilt: Je größer die Anlage und je höher der Strompreis, desto höher die Einsparung.",
  },
  {
    title:
      "Welche Potenziale gäbe es insgesamt im Bereich Photovoltaik? (energetisch & technisch)",
    content:
      "Betrachtet man das Potenzial, so muss man zwischen theoretischem und technischem Potenzial unterscheiden. Das theoretische Potenzial ergibt sich durch die zur Verfügung stehende Energiemenge. Grundsätzlich erreicht die Erde durch die Sonne pro Tag so viel Energie, dass wir unseren Energieverbrauch für 8 Jahre decken könnten. Das theoretische Potenzial ist mit einer 2850-fachen Menge (im Vergleich zum jetzigen Standpunkt) bei der Solarenergie mit Abstand am größten. Ergänzend dazu ist es allerdings unabdingbar, sich das technische Potenzial vor Augen zu führen. In dieses fließen sowohl strukturelle, technologische und ökologische Problematiken als auch räumliche Gegebenheitenmit ein. Das bedeutet, wie viel Fläche für einen Energieträger zur Verfügung steht. Bis 2050 könnte die Sonne das 19,4-fache an Energie zur Verfügung stellen, nächstes Jahr bereits das 10-fache.",
  },
  {
    title: "Welchen Nutzen erfüllen Wechselrichter bei PV-Anlagen?",
    content:
      "Eine Photovoltaikanlage erzeugt Gleichstrom. Das bedeutet, dass der Strom (Elektronen, Protonen, Ionen) konstant in eine Richtung fließen (vom (+) Pol zum (-) Pol . Ändert sich die Bewegungsrichtung hingegen periodisch, spricht man von Wechselstrom. Ein Wechselrichter wandelt den produzierten Gleichstrom in Wechselstrom um, um ihn im Alltag nutzen zu können. Man kann in diesem Zusammenhang von der Schnittstelle zwischen der Photovoltaikanlage und dem Netz sprechen. Der Wechselrichter ist für die ideale parametrische Ausrichtung beider Komponenten zuständig und steuert bzw. beeinflusst diese.",
  },
  {
    title: "Was ist die Lebensdauer einer Photovoltaikanlage?",
    content:
      "Grundsätzlich geht man bei Photovoltaikanlagen von einer Lebensdauer zwischen 20 bis 30 Jahren aus. Diesbezüglich spielt bei der Abnutzung vorwiegend die Witterung (Regen, Schnee etc.) eine Rolle. Außerdem produzieren Photovoltaikanlagen nach 1,5 bis 6 Jahren (je nach Art des Solarmoduls) die für die Herstellung benötigte Energie (siehe Amortisation). Das bedeutet, dass eine Solaranlage mehrfach jene Energie produziert, die man für die Herstellung benötigt.",
  },
  {
    title: "Wie groß muss meine Anlage für den optimalen Eigenverbrauch sein?",
    content:
      "Dies ist abhängig von Verbrauch und Größe des Haushalts. Ein Beispiel eines durchschnittlichen 4-Personen-Haushalts: Im Durchschnitt geht man dabei von einem jährlichen Stromverbrauch von etwa 3100 kWh bis zu 7300 kWh aus. Dabei spielen viele Faktoren eine wesentliche Rolle: bereitet man das Warmwasser auch mit Strom auf? Wie viele Personen sind wie oft zu Hause? Wann verbraucht man den Strom hauptsächlich (ganzer Tag oder nur abends)? Dies würde Leistungen zwischen 3 kWp bis 7 kWp entsprechen. Sollten Sie Interesse an einer Solaranlage haben, ist es ratsam, im Planungs- und Installationsprozess professionelle Hilfe hinzuzuziehen. Diese kann Ihnen dabei helfen, den jährlichen Stromverbrauch sowie die damit einhergehenden Stromkosten zu analysieren, eine potenzielle Anlage zu dimensionieren, Förderungen zu berücksichtigen und damit die Kosten gegenüberzustellen. ",
  },
  {
    title: "Kann eine Photovoltaikanlage ein E-Auto betreiben?",
    content:
      "Ja, das ist möglich und auch sinnvoll. Ein Beispiel: eine Photovoltaikanlage mit einer Leistung von 5 kWp produziert ungefähr 5000 kWh/Jahr. Nehmen wir an, dass Ihr E-Auto einen ungefähren Verbrauch von 18 kWh pro 100 km hat (was ein eher hoher Verbrauch ist, siehe hier). Somit könnten Sie pro Monat von 2300 - 2800 km emissionsfrei zurücklegen.",
  },
];

export const installationsContent = [
  {
    title: "In welche Richtung muss ich eine PV-Anlage ausrichten?",
    content:
      "Um ein Funktionieren der Photovoltaikanlage bestmöglich zu garantieren, sollte man die Anlage so gut als möglich nach Süden ausrichten. Der Neigungswinkel ist je nach Standort(-beschaffenheit) zu ermitteln. Grundsätzlich lässt sich festhalten, dass die Module den potenziell höchsten Ertrag dann erzielen, wenn die Sonne in einem 90°-Winkel auf diese trifft. Aus der Praxis ist jedoch bekannt, dass Abweichungen vom Ideal zu teilweise nur geringfügigen Änderungen führen. FacebookTwitterLinkedInTeilen",
  },
  {
    title: "Mit welcher Amortisationszeit ist zu rechnen?",
    content:
      "Man unterscheidet zwischen finanzieller und energetischer Amortisation. Von der finanziellen Amortisation spricht man, wenn sich die verursachten Investitionskosten mit den durch die Investitionen einhergehenden Ersparnissen ausgeglichen haben. Dies dauert meistens zwischen 8 und 10 Jahre. Ähnlich ist es bei der energetischen Amortisation. Eine Anlage hat sich dann energetisch amortisiert, wenn die Energie, die man zur Herstellung verwendete, die Anlage selbst wieder erzeugte. Diese beträgt, je nach Solarmodul (mono- bzw. polykristallin & Dünnschichtzellen) ca. 1,5 bis 6 Jahre.",
  },
  {
    title: "Was passiert, wenn die PV-Anlage ausgedient bzw. Schäden hat?",
    content:
      "Gleichzeitig mit der Geburtsstunde der Photovoltaik enstand auch der Abfallverband “PV-Cycle”. Prinzipiell existieren zwei Möglichkeiten: Bei kleineren Schäden beseitigt man diese und macht die Solarmodule wieder “fit” Man recycelt die Solarmodule, wobei man heute bereits über 80 % der verwendeten Stoffe wiederverwendet bzw. verwertet. Außerdem übersteigt der Wert der verwendeten Stoffe (Aluminium, Glas) die Kosten für den Abbau von neuen Anlagen.",
  },
];

export const promotionsContent = [
  {
    title:
      "Was sind die Vorteile von Photovoltaik gegenüber fossilen Energieträgern?",
    content:
      "Fossile Energieträger erschöpfen sich im Laufe der Zeit. Heute zehren wir von Ressourcen, die sich über die Jahrmillionen gebildet haben. Die Sonne steht als unerschöpflicher Energielieferant zur Verfügung, außerdem werden bei der Erzeugung von Sonnenstrom keine CO2 Emissionen ausgestoßen, wie dies bei Kohle, Gas oder Erdöl der Fall ist. Zudem ist hauseigener Sonnenstrom heute oftmals günstiger ist als der Strom vom Netz.",
  },
  {
    title: "Was sind Stromgestehungskosten?",
    content:
      "Als Stromgestehungskosten bezeichnet man jene Kosten, die notwendig sind, um Energie in elektrische Energie umzuwandeln. Diese sind je nach Energieträger unterschiedlich und werden üblicherweise in Cent/kWh oder Euro/MWh angegeben. Bei der Photovoltaik setzen sich Stromgestehungskosten aus Kapitalkosten, fixen und variablen Betriebskosten, Brennstoffkosten sowie der angestrebten Kapitalverzinsung über den Betriebszeitraum zusammen. Dachgold verfügt über den ersten kostenlosen online Gestehungskostenrechner, der Ihnen nach Eingabe einer potenziellen PV-Anlagengröße (in kWp) kurzerhand eine Grobberechnung der jeweiligen Stromgestehungskosten macht. Für Energieberater, Planer und Architekten bieten wir außerdem eine einmalig kostenpflichtige Variante des Gestehungskostenrechners an, welche beinahe punktgenaue Berechnungen zulässt. Zusätzlich hierzu bieten wir gelegentlich ein Ausbildungsformat, das dem Umgang mit dem Rechner gewidmet ist und die neueste Version des Gestehungskostenrechners beinhaltet.",
  },
  {
    title: "Welche Eigenverbrauchsquote ist bei PV-Anlagen privat möglich?",
    content:
      "Im Haushaltsbereich hängt der Grad der Eigennutzung vor allem davon ab welche Verbraucher es im Sommer gibt. Sonderfälle sind große Sommerverbraucher, wie eine Klimaanlage oder eine Schwimmbadpumpe. Bei diesen können die Werte bei einer gut dimensionierten Anlage auch Richtung 80 % Eigenverbrauch gehen. Klassische Haushaltsanlagen ohne Sommerverbraucher liegen jedoch eher bei 20 %, da untertags zu wenige Verbraucher laufen. Allerdings lohnt es sich in solchen Fällen, die PV-Anlage noch größer zu dimensionieren und über einen Einspeisetarif die überschüssige Energie über den Gestehungskosten zu bekommen. Bei Unternehmen können sich beispielsweise Werte von 20 bis 100 % ergeben. Für eine Berechnung melden Sie sich beim Quick-Check an.",
  },
  {
    title: "Kann man Strom speichern?",
    content:
      "Strom kann in Stromspeichern zwischengelagert werden. Den Vorgang kann man sich wie das Laden eines Akkus vorstellen. Dabei wird die zugeführte elektrische Energie in chemische Energie umgewandelt. Somit ist es möglich, Strom zu speichern. Will man den Strom wieder nutzen, wandelt sich die chemische wieder in elektrische Energie um. Eine weitere und in Österreich bevorzugte Variante der Stromspeicherung ist die Nutzung von Pumpspeicherkraftwerken. Dabei pumpt man Wasser mit überschüssigem Strom in ein höher gelegenes Becken. Wenn man Strom braucht, dann schickt man das Wasser von oben nach unten durch eine Turbine. Somit erzeugt man durch die kinetische Energie Strom.",
  },
];
