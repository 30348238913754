import { Drawer } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import RightSideOfPage from "pages/Application/community/partials/RightSideOfPage";
import styled from "styled-components";
import Close from "Assets/Close.svg";
import Group from "popups/SignUpSignIn/images/Group.svg";
import { Dialog, makeStyles } from "@material-ui/core";
import { colors } from "../../../../colors";

const NearbyWrapper = styled("div")`
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 103%;
  }
`;

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
      background: "black",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: colors.ctaSolid,
      },
    },
  },
}));

const NearbyProjectsDrawer = ({ open, setOpen }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const history = useHistory();
  const classes = useStyles();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <NearbyWrapper>
          <div style={{ display: "flex", width: "100%", position: "relative" }}>
            <img
              onMouseEnter={() => setWhichIconShouldBeVisible(true)}
              onMouseLeave={() => setWhichIconShouldBeVisible(false)}
              src={whichIconShouldBeVisible ? Close : Group}
              className={classes.icon}
              onClick={() => setOpen(false)}
            />
          </div>
          <RightSideOfPage />
        </NearbyWrapper>
      </Dialog>
    </div>
  );
};

export default NearbyProjectsDrawer;
