import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./partials/Header";
import Category from "./partials/Category";
import Search from "./partials/Search";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { db } from "../../../../../firebase";
const HomeWrapper = styled("div")`
  .root {
    position: relative;
    height: 100%;
    width: 100%;
    @media (max-width: 600px) {
      position: relative;
      height: calc(100vh - 70px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
`;
const Home = () => {
  const [isHeaderThere, setIsHeadrThere] = useState(false);
  const [isOpen, setOpen1] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser) {
      db.collection("users")
        .doc(currentUser.uid)
        .onSnapshot((doc) => {
          const data = {
            username: doc.data()?.username,
          };
          dispatch({ type: "SET_USERNAME_DISCOURSE_PASSWORD", payload: data });
        });
    }
  }, [currentUser]);
  return (
    <HomeWrapper>
      <div
        className="root"
        onClick={() => {
          setOpen1(false);
        }}
      >
        <Header
          setIsHeadrThere={setIsHeadrThere}
          isHeaderThere={isHeaderThere}
        />
        <Search move={setOpen1} />
        <Category
          isHeaderThere={isHeaderThere}
          setIsHeadrThere={setIsHeadrThere}
          setOpen1={setOpen1}
          isOpen={isOpen}
        />
      </div>
    </HomeWrapper>
  );
};

export default Home;
