import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
// core components
import GridItem from "pages/AdminPanel/components/Grid/GridItem.js";
import GridContainer from "pages/AdminPanel/components/Grid/GridContainer.js";
import Card from "pages/AdminPanel/components/Card/Card.js";
import CardHeader from "pages/AdminPanel/components/Card/CardHeader.js";
import CardIcon from "pages/AdminPanel/components/Card/CardIcon.js";
import CardBody from "pages/AdminPanel/components/Card/CardBody.js";
import CardFooter from "pages/AdminPanel/components/Card/CardFooter.js";
import { db } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "pages/AdminPanel/variables/charts.js";
import styles from "Assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [totalUsers, setTotalUsers] = useState("");
  const user = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (user?.currentUser) {
      if (
        user?.currentUser.email !== "daniel@solarhub24.de" &&
        user?.currentUser.email !== "david@solarhub24.de" &&
        user?.currentUser.email !== "vs.doce@gmail.com" &&
        user?.currentUser.email !== "shuklasarthak100@gmail.com" &&
        user?.currentUser.email !== "moritz@solarhub24.de" &&
        user?.currentUser.email !== "vincent@solarhub24.de" &&
        user?.currentUser.email !== "rushilpatel2311@gmail.com" &&
        user?.currentUser.email !== "rikinbhalodia9504@gmail.com" &&
        user?.currentUser.email !== "yashpatel45273@gmail.com" &&
        user?.currentUser.email !== "ruples568@gmail.com" &&
        user?.currentUser.email !== "lukas@solarhub24.de" &&
        user?.currentUser.email !== "johannes@solarhub24.de" &&
        user?.currentUser.email !== "felicitas@solarhub24.de" &&
        user?.currentUser.email !== "elisabeth@solarhub24.de" &&
        user?.currentUser.email !== "Johannes@solarhub24.de" &&
        user?.currentUser.email !== "solarhub24@gmail.com" &&
        user?.currentUser.email !== "ssaurabh8778@gmail.com"  
      ) {
        history.push("/application");
      }
    } else {
      history.push("/login");
    }
  }, [user]);

  useEffect(() => {
    db.collection("users")
      .get()
      .then((snap) => {
        let data = [];
        snap.forEach((doc) => {
          data.push(doc.data());
        });
        setTotalUsers(data.length);
      });
  }, []);

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Revenue</p>
              <h3 className={classes.cardTitle}>$34,245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Total users</p>
              <h3 className={classes.cardTitle}>{`+ ${totalUsers}`}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
