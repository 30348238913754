import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Hero from "../../Assets/Hero.svg";
import { Upload, message, Progress } from "antd";
import solarProject from "Assets/images/solarProject.png";
import Kontakt from "Assets/images/Kontakt.svg";
import Login from "Assets/images/Login.svg";
import popup from "Assets/Info/popup.png";
import Home from "Assets/Info/Home.svg";
import Roof from "Assets/Info/Roof.svg";
import Graph from "Assets/Info/Graph.svg";
import location from "Assets/Info/location.svg";
import Arrow1 from "Assets/Info/Arrow1.png";
import { useHistory } from "react-router-dom";
import Close from "../../Assets/Close.svg";
import project1 from "Assets/images/project1.svg";
import project2 from "Assets/images/project2.svg";
import project3 from "Assets/images/project3.svg";
import { db } from "../../firebase";
import Share from "Assets/images/Share.svg";
import Rating from "@material-ui/lab/Rating";
import Group from "../SignUpSignIn/images/Group.svg";
import { useDispatch, useSelector } from "react-redux";
import { commaDotHandler } from "helperMethod";
import { CloseOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1100px",
    // height:"650px",
    margin: "30px 0px 30px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexHalper: {
    display: "flex",
    justifyContent: "space-between",
    "& h1": {
      fontSize: "20px",
    },
    marginBottom: "10px",
  },
  flexHalper2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h1": {
      marginTop: "10px",
    },
  },
  flex_x: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  filterdiv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    height: "189px",
    width: "100%",
  },
  filterTags: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  mainProjectDiv: {
    display: "flex",
    width: "90%",
    margin: "auto",
    marginBottom: "10px",
    height: "220px",
    border: "1px solid #F22525",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#fff",
      transform: "scale(1.01)",
    },
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "333px",
    height: "220px",
  },
  otherContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
  },
  insideOtherContainerOne: {
    display: "flex",
    justifyContent: "space-around",
  },
  detail: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  insideDetail: {
    display: "flex",
    justifyContent: "space-between",
  },
  detail2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginTop: "20px",
    height: "110px",
  },
  otherContainer2: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mainFilter: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "7px",
      borderRadius: "20px",
      scrollbarColor: "blue",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D0D0D0",
      borderRadius: "20px",
      height: "180px",
    },
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  root2: {
    width: "65%",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#fff",
      transform: "scale(1.01)",
    },
  },
  leftContainer: {
    padding: "10px 35px",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "space-around",
    width: "517px",
    height: "250px",
    display: "flex",
  },
  rightContainer: {
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  rightContainerSubBody: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    margin: "auto",
    flexDirection: "row",
    gap: "16px",
  },
  rightContainerSubBodyImg: {
    position: "relative",
    height: "160px",
    width: "160px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainerSubBody1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  rightContainerSubBodyButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
  },
  rightContainerSubBodyButton1: {
    height: "30px",
    width: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#3E3637",
    borderRadius: "20px",
    color: "#FFFFFF",
    fontSize: "14px",
    cursor: "pointer",
  },
  rightContainerRatingValue: {
    position: "absolute",
    height: "100%",
    width: "100%",
    fontSize: "14px",
    color: "black",
    transform: "translate(15px,-58px)",
  },
  rightContainerName: {
    marginLeft: "8px",
    fontWeight: "400",
    fontSize: "18px",
    color: "#000000",
  },
  leftFooterLeft: {
    width: "30%",
    fontWeight: "600",
    color: "#718096",
    fontSize: "16px",
  },
  leftFooterRight: {
    fontWeight: "500",
    color: "#718096",
    fontSize: "16px",
  },
  leftSubFooter: {
    display: "flex",
  },
  projectdetails: {
    display: "flex",
    gap: "16px",
    cursor: "pointer",
  },
}));

const MultipleProjects = ({
  open,
  setOpen,
  setSavedProjectUrl,
  setIsSelected,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  let history = useHistory();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const [savedProjects, setSavedProjects] = React.useState(null);
  const currentUssser = useSelector((state) => state?.user?.currentUser?.uid);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  useEffect(() => {
    if (currentUssser) {
      db.collection("saved_projects")
        .where("uid", "==", currentUssser)
        .get()
        .then((snap) => {
          const data = [];
          snap.forEach((doc) => {
            data.push(doc.data());
          });
          setSavedProjects(data);
        })
        .catch(() => {});
    }
  }, [currentUssser]);

  const handleClose = () => {
    // if (savedProjects.length === 1) {
    //   history.push("/application/saved-" + savedProjects[0].pid);
    //   setOpen(false);
    // } else {
    //   setOpen(false);
    //   history.goBack();
    // }
    // dispatch({ type: "SET_LOADING", payload: false });
    setOpen(false);
  };
  const [value, setValue] = React.useState(2);
  const onClickHandler = (id) => {
    setSavedProjectUrl(`${window.location.origin}/application/custom-${id}`);
    setOpen(false);
    setIsSelected(true);
  };
  return (
    <Dialog onClose={handleClose} open={open} maxWidth={false}>
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          src={whichIconShouldBeVisible ? Close : Group}
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.root}>
        {savedProjects && savedProjects.length > 0
          ? savedProjects.map((item, index) => {
              return (
                <div
                  className={classes.root2}
                  onClick={() => {
                    onClickHandler(item.pid);
                  }}
                >
                  <div className={classes.rightContainer}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Progress percent={50} showInfo={false} />
                      <div className={classes.rightContainerSubBody}>
                        <div className={classes.rightContainerSubBodyImg}>
                          <img
                            src={item?.projectImgUrl}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className={classes.rightContainerSubBody1}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                gap: "16px",
                              }}
                            >
                              <div style={{ height: "20px", width: "20px" }}>
                                <img
                                  src={popup}
                                  alt=""
                                  style={{ height: "100%", width: "100%" }}
                                />
                              </div>
                              <div>{`${item?.project_details?.address?.complete}`}</div>
                              <img src={Login} alt="" />
                              <img src={Home} alt="" />
                            </div>
                            <div className={classes.projectdetails}>
                              <div style={{ height: "20px", width: "20px" }}>
                                <img
                                  src={Arrow1}
                                  alt=""
                                  style={{ height: "100%", width: "100%" }}
                                />
                              </div>
                              <div>Project Details</div>
                              <div>
                                {isEnglish
                                  ? `Solar Configuration version${item?.version[1]}`
                                  : `Konfiguration Variante hinzufügen${item?.version[1]}`}
                              </div>
                            </div>
                            <div style={{ display: "flex", gap: "16px" }}>
                              <div
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={Roof}
                                  alt=""
                                  style={{ height: "100%", width: "100%" }}
                                />
                              </div>
                              <div>{`${item?.updatedData?.roof_type} ${item?.updatedData?.roof_material} ${item?.updatedData?.roof_tilt} ${item?.updatedData?.roof_orientation}`}</div>
                            </div>
                            <div style={{ display: "flex", gap: "16px" }}>
                              <div
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={Graph}
                                  alt=""
                                  style={{
                                    align: "middle",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                />
                              </div>
                              <div>
                                {item?.financialDetails?.total_project_cost}€
                              </div>
                              <div>{`CRN Number :- ${item?.crn}`}</div>
                            </div>
                            <div
                              className={classes.rightContainerSubBodyButton}
                            >
                              <button
                                className={classes.rightContainerSubBodyButton1}
                              >
                                Make an appointment
                              </button>
                              <div>En Solar GmbH, Stuttgart</div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                marginBottom: "30px",
                              }}
                            >
                              <CloseOutlined
                                width={60}
                                height={60}
                                style={{ fontSize: "20px" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "14px",
                                marginRight: "14px",
                                height: "92px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "1.5rem",
                                  marginLeft: "9px",
                                }}
                              >
                                {item?.energysDetails?.total_production}kWp
                              </div>
                              <div
                                style={{ height: "64px", position: "relative" }}
                              >
                                {/* <Progress
                    style={{ height: "64px" }}
                    type="circle"
                    percent={76}
                    format={() => "4.3"}
                  />
                  <div className={classes.rightContainerRatingValue}>4.3</div> */}
                              </div>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={location} alt="" />
                                </div>
                                <div>ID 2231</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </Dialog>
  );
};

export default MultipleProjects;
