import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CardActive from "./CardActive";
import CardDull from "./CardDull";
import ChatCol from "../Assets/ChatCol";
import ScheduleBal from "../Assets/ScheduleBal";
import ScheduleCol from "../Assets/ScheduleCol";
import ChatBal from "../Assets/ChatBal";
import axios from "axios";
import { Button, Result } from "antd";
import { useSelector } from "react-redux";
const MainContainer = styled("div")`
  position: relative;
  width: 512px;
  height: 441px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 90%;
    height: 523px;
    margin-bottom: 10px;
  }
`;
const Text1 = styled("div")`
  font-weight: 600;
  font-size: 20px;
  color: #2d4764;
  margin-top: 22px;
  margin-left: 20px;
`;
const Text2 = styled("div")`
  font-weight: bold;
  font-size: 16px;
  color: #1b4963;
  margin-top: 16px;
  margin-left: 20px;
`;

const Text3 = styled("div")`
  margin-top: 28px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #2d4764;
`;
const Text4 = styled("textarea")`
  margin-top: 8px;
  margin-left: 20px;
  @media (max-width: 600px) {
    width: 88%;
  }
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  width: 448px;
  min-height: 130px;
  background: #f9f9f9;
  border-radius: 7.75424px;
  padding: 20px;
  padding-top: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #2d4764;
  line-height: 17px;
`;
const MainButton = styled("div")`
  position: absolute;
  bottom: 19px;
  right: 20px;
  display: flex;
  @media (max-width: 600px) {
    bottom: 12px;
    right: 23%;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 35px;
  background: #ffd75d;
  border-radius: 42px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #2d4764;
  cursor: pointer;
`;
const SubContainer = styled("div")`
  margin-top: 8px;
  margin-left: 20px;
  @media (max-width: 600px) {
    margin-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  display: flex;
  gap: 22px;
`;

const ContainerWork = styled("div")`
  .mainButton {
    position: absolute;
    bottom: 19px;
    right: 20px;
    @media (max-width: 600px) {
      bottom: 12px;
      right: 23%;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 167px;
    height: 35px;
    background: #ffd75d;
    border-radius: 42px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #2d4764;
    cursor: pointer;
  }
  .ant-btn {
    border: none;
  }
`;

const RightSide = ({
  setInviteState,
  setScheduleInviteState,
  selected,
  setSelected,
  discriptiion,
  setdiscriptiion,
  sender_uid,
  receiver_uid,
}) => {
  const [loading, setLoading] = useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const submitHandler = async () => {
    //just call api and use description props to get the des. value
    const data = {
      sender_uid,
      receiver_uid,
      description: discriptiion,
    };
    try {
      setLoading(true);
      const result = await axios.post(
        "https://solarhub24.herokuapp.com/SolarHub-Discourse/invitation/sendInvitation",
        data
      );
      if (result) {
        setLoading(false);
        setInviteState(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(true);
      setInviteState(false);
    }
  };

  const onChangeHandler = (e) => {
    setdiscriptiion(e.target.value);
  };

  return (
    <MainContainer>
      <Text1>
        {isEnglish
          ? "Invite Peter to discuss your pv project"
          : "Lade Peter ein um über dein Projekt zu sprechen"}
      </Text1>
      <Text2>
        {isEnglish
          ? "Choose a suitable method for invitation"
          : "Wähle deine bevorzugte Option aus"}
      </Text2>
      <SubContainer>
        {selected === "chat" ? (
          <CardActive
            text={isEnglish ? "Direct Chat" : "Direkter Chat"}
            active={<ChatCol />}
            setSelected={setSelected}
          />
        ) : (
          <CardDull
            text={isEnglish ? "Direct Chat" : "Direkter Chat"}
            active={<ChatBal />}
            setSelected={setSelected}
          />
        )}
        {selected === "schedule" ? (
          <CardActive
            text={isEnglish ? "Schedule Meet" : "Termin einplanen"}
            active={<ScheduleCol />}
            setSelected={setSelected}
          />
        ) : (
          <CardDull
            text={isEnglish ? "Schedule Meet" : "Termin einplanen"}
            active={<ScheduleBal />}
            setSelected={setSelected}
          />
        )}
      </SubContainer>
      <Text3>
        {isEnglish
          ? "Describe your problem briefly"
          : "Schreibe eine kurze Nachricht"}
      </Text3>
      <Text4 onChange={(e) => onChangeHandler(e)} />
      {selected === "chat" ? (
        <ContainerWork>
          <Button
            loading={loading}
            className="mainButton"
            onClick={submitHandler}
          >
            {isEnglish ? "Send invite" : "Einladung senden"}
          </Button>
        </ContainerWork>
      ) : (
        <MainButton
          onClick={() => {
            setScheduleInviteState(false);
          }}
        >
          {isEnglish ? "Continue" : "fortsetzen"}
        </MainButton>
      )}
    </MainContainer>
  );
};

export default RightSide;
