import React from "react";

//materialui components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  Paper,
  Divider,
  Avatar,
} from "@material-ui/core";
import { commaDotHandler } from "helperMethod";

//colors
import { colors } from "../../../colors";

const BuyCharts = (props) => {
  const { isEnglish, costs_and_savings, financing_details } = props;
  const calc_func = (
    electricity_cost_before,
    estimated_yearly_price_increase
  ) => {
    var temp = electricity_cost_before;
    var total_cost = electricity_cost_before;
    console.log("initial_temp", temp);
    console.log(estimated_yearly_price_increase);
    for (let i = 0; i < 24; i++) {
      const this_years_extra =
        (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100;
      temp = temp + this_years_extra;
      total_cost = total_cost + temp;
      console.log("temp" + i + "-" + temp);
      console.log("this years extra" + i + "-" + this_years_extra);
      console.log("total_cost" + i + "-" + total_cost);
    }
    return Math.round(total_cost);
  };

  const a = Math.round(financing_details.total_project_cost);
  const b = calc_func(
    parseFloat(costs_and_savings.electricity_cost_before),
    parseFloat(costs_and_savings.estimated_yearly_price_increase)
  );
  const c = Math.round(costs_and_savings.feedin_savings) * 25;
  const useStyles = makeStyles((theme) => ({
    top_text: {
      margin: "0px",
      marginLeft: "20px",
    },
    root: {
      width: "95%",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#f8f8f8",
      borderRadius: "25px",
      margin: "0px 15px 15px 15px",
    },
    c1: {
      width: "25%",
      margin: "15px 5px 15px 15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    c2: {
      width: "60%",
      margin: "15px 25px 15px 0px",
      "& h1": {
        color: colors.orangeSolid,
        margin: "0px",
        padding: "0px",
      },
      "@media (max-width: 430px)": {
        margin: "11px 9px 15px 0px",
      },
      "& h5": {
        margin: "0px",
        padding: "0px",
        fontSize: "15px",
        marginBottom: "15px",
      },
      "& p": {
        display: "flex",
        fontSize: "13px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "5px 0px",
        "@media (max-width: 430px)": {
          fontSize: "9px",
        },
        "& span": {
          display: "flex",
          alignItems: "center",
        },
        "& div": {
          width: "8px",
          height: "8px",

          border: "1px solid gray",
          margin: "0 10px 0 -15px",
          borderRadius: "5px",
        },
      },
    },
    chart_conatiner: {
      width: "100px",
      minHeight: "10px",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
      },
      marginTop: "35px",
    },
    chart_1: {
      width: "35px",
      background: "darkred",
      height: `${(a / (a + b + c)) * 200}px`,
      alignSelf: "flex-end",
      marginRight: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    chart_2: {
      width: "35px",
      background: "darkgreen",
      height: `${(c / (a + b + c)) * 200}px`,
      marginLeft: "8px",

      color: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    chart_3: {
      width: "35px",
      background: colors.sun_r,
      height: `${(b / (a + b + c)) * 200}px`,
      marginLeft: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    makeResponsive: {
      "@media (max-width: 430px)": {
        fontSize: "9px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.top_text}>
        {isEnglish
          ? "Cost Calculation & Investment"
          : "Kostenkalkulation & Investment"}
      </Typography>
      <div className={classes.root}>
        <div className={classes.c1}>
          <div className={classes.chart_conatiner}>
            <div className={classes.chart_1}></div>
            <div>
              <div className={classes.chart_2}></div>
              <div className={classes.chart_3}></div>
            </div>
          </div>
        </div>
        <div className={classes.c2}>
          <h1>{commaDotHandler(b + c - a, `${isEnglish ? "en" : "de"}`)} €</h1>
          <h5>
            {isEnglish
              ? "Profit after 25 years of operation"
              : "Profit nach 25 Jahren Betriebsdauer"}
          </h5>
          <Typography className={classes.makeResponsive}>
            <span>
              <div style={{ background: "darkred" }} />
              {isEnglish
                ? "Investment / building cost"
                : "Investment / Baukosten"}
            </span>
            <span>
              <strong>
                - {commaDotHandler(a, `${isEnglish ? "en" : "de"}`)} EUR
              </strong>
            </span>
          </Typography>
          <Typography className={classes.makeResponsive}>
            <span>
              <div style={{ background: colors.sun }} />
              {isEnglish
                ? "Savings electricity bill"
                : "Einsparungen Stromrechnung"}
            </span>
            <span>
              <strong>
                {commaDotHandler(b, `${isEnglish ? "en" : "de"}`)} EUR
              </strong>
            </span>
          </Typography>
          <Typography className={classes.makeResponsive}>
            <span>
              <div style={{ background: "darkgreen" }} />
              {isEnglish ? "Feed-in-tariff" : "Einspeisevergütung"}
            </span>
            <span>
              <strong>
                {commaDotHandler(c, `${isEnglish ? "en" : "de"}`)} EUR
              </strong>
            </span>
          </Typography>
          <Divider style={{ margin: "20px 0px" }} />
          <Typography>
            <span>
              <strong>
                {isEnglish
                  ? "Return on Investment after 25 years"
                  : "Rendite nach 25 Jahren"}
              </strong>
            </span>
            <span>
              <strong>
                {commaDotHandler(
                  Math.round(((b + c - a) / a) * 100),
                  `${isEnglish ? "en" : "de"}`
                )}{" "}
                %
              </strong>
            </span>
          </Typography>
          <Typography style={{ marginLeft: "50px" }}>
            <span>
              <strong>
                {commaDotHandler(
                  Math.round((((b + c - a) / a) * 100) / 25),
                  `${isEnglish ? "en" : "de"}`
                )}
                % Yearly Returns
              </strong>
            </span>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default BuyCharts;
