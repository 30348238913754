import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { db } from "../../../firebase";
import FAQsPopup from "../components/popups/admin.faqs.popup";

const buttonStyle = {
  backgroundColor: "#3f51b5",
  color: "white",
};

const customerInstaller = () => {
  const [rowData, setRowData] = useState("");
  const [selected_items, setselected_items] = useState(null);
  const [newOpen, setNewOpen] = useState(false);

  useEffect(() => {
    db.collection("faqs").onSnapshot((snap) => {
      let all_faqs = [];
      snap.forEach((doc) => {
        const quote = {
          ...doc.data(),
          id: doc.id,
        };
        all_faqs.push(quote);
      });
      setRowData(all_faqs);
    });
  }, []);

  const returnUserData = async (uid) => {
    const result = await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        return doc.data();
      })
      .catch((error) => {
        return null;
      });
    return result;
  };

  const returnInstallerData = async (pid) => {
    const result = await db
      .collection("installers")
      .doc(pid)
      .get()
      .then((doc) => {
        return doc.data();
      })
      .catch((error) => {
        return null;
      });
    return result;
  };

  const projectData = async (iid, pid) => {
    const result = await db
      .collection("saved_projects")
      .doc(pid)
      .get()
      .then(async (doc) => {
        const installerResult = await returnInstallerData(iid);
        const userResult = await returnUserData(doc.data().uid);
        if (
          installerResult === undefined ||
          installerResult === null ||
          userResult === null
        ) {
        } else {
          return {
            ...installerResult,
            ...userResult,
            id: iid + pid,
          };
        }
      })
      .catch(() => {
        return null;
      });
    return result;
  };

  //--> featching installer detials with users
  const matchedDoc = async () => {
    const result = await db
      .collection("matched")
      .where("status", "==", "one of selected")
      .get()
      .then(async (snap) => {
        let data = [];
        snap.forEach(async (doc) => {
          const projectResult = await projectData(
            doc.data().iid,
            doc.data().pid
          );
          if (projectResult) {
            data.push(projectResult);
          }
        });
        console.log(data, "rus");
        return data;
      })
      .catch((err) => console.log(err));
    return result;
  };
  useEffect(async () => {
    const result = await matchedDoc();
    console.log(result);
  }, []);

  const columns = [
    {
      field: "type",
      width: 250,
    },
    {
      field: "title",
      width: 500,
      editable: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              height: "100%",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "content",
      width: 500,
      editable: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              height: "100%",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  const handleClickOpen = () => {
    setNewOpen(true);
  };

  const handleChangeRow = (values, e) => {
    const result = rowData.filter((data, index) => data.id === values.id);
    const updatedField = values.field;
    const updatedRow = {
      ...result[0],
      [updatedField]: values.value === undefined ? "" : values.value,
    };
    console.log(updatedRow, "updatedRow");
    // db.collection("faqs")
    //   .doc(values?.id)
    //   .set({
    //     ...updatedRow,
    //   })
    //   .then(() => {
    //     console.log("value successfully updated changed");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const deleteSelectedQuotes = () => {
    if (selected_items) {
      console.log(selected_items);
      selected_items.forEach((item) => {
        db.collection("faqs")
          .doc(item)
          .delete()
          .then(() => console.log("deleted"))
          .catch((err) => console.log(err));
      });
    } else {
      alert("Select Items to Delete");
    }
  };

  return (
    <Accordion expanded={true} style={{ flex: "1", margin: "0px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>FAQs</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "15px 30px",
              marginTop: "-10px",
              gap: "8px",
            }}
          >
            <Button style={buttonStyle} onClick={handleClickOpen}>
              Add FAQs
            </Button>
            <Button style={buttonStyle} onClick={deleteSelectedQuotes}>
              Delete FAQs
            </Button>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              flex: "1",
              minHeight: "65vh",
            }}
          >
            {rowData.length > 0 ? (
              <DataGrid
                rows={rowData}
                getRowId={(row) => row.id}
                columns={columns}
                onCellEditCommit={(values, event) => {
                  handleChangeRow(values, event);
                }}
                pageSize={12}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(e) => setselected_items(e)}
                disableSelectionOnClick
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <FAQsPopup open={newOpen} setopen={setNewOpen} />
      </AccordionDetails>
    </Accordion>
  );
};

export default customerInstaller;
