import { put, call, takeLatest } from "redux-saga/effects";
import { default_quote_data } from "../../lib/default_quote";
import { db } from "../../firebase";

export function* fetchQuoteAsync(action) {
  function fetchQuoteStartAsync() {
    return new Promise((resolve, reject) => {
      //Task No 3 - done
      const docref = db
        .collection("quotes")
        .where("addr1", "==", action.payload.address)
        .where("zip_code", "==", action.payload.postcode);
      docref
        .get()
        .then((snap) => {
          const data = [];
          snap.forEach((doc) => {
            data.push(doc.data());
          });
          if (data.length) {
            resolve(data[0]);
          } else {
            const data = {
              first_name: "test",
              last_name: "name",
              email: "test1@gmail.com",
              phone: "(123) 123-1231",
              isLatlongAddress: "true",
              address: action.payload.complete,
              city: action.payload.city,
              state: action.payload.state,
              country: action.payload.country,
              zip_code: action.payload.postcode,
              type: "R",
              annual_electric_bill: "5000",
              project_owner_first_name: "random first name",
              project_owner_last_name: "random last name",
              project_owner_email: "david@solarhub24.de",
              account_id: "SOLARHUB",
              latitude: action.payload.geometry.coordinates[1].toString(),
              longitude: action.payload.geometry.coordinates[0].toString(),
            };
            fetch("https://envisionapi.enact-systems.com/lead", {
              method: "POST",
              headers: {
                "x-api-key": "DmxtC5gi7V6Wegpmaw8tr4ywv4xBBQR8THXXIJ9f",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data), // body data type must match "Content-Type" header
            })
              .then((res) => res.json())
              .then((data) => {
                console.log(data, "data");
                // alert(
                //   `Project Creation Request Sent. Your CRN is ${data.data.crn}.`
                // );
                console.log(data, "quote data");
                const date = new Date();
                const new_date = `${
                  date.getMonth() + 1
                }.${date.getDate()}.${date.getFullYear()}`;
                const new_time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                console.log("date", new_date);
                console.log("time", new_time);
                db.collection("crn_details")
                  .doc(data?.data?.id)
                  .set({
                    quote: default_quote_data,
                    address: data?.data?.address,
                    address1: data?.data?.address1,
                    city: data?.data?.city,
                    country: data?.data?.country,
                    crn: data?.data?.crn,
                    id: data?.data?.id,
                    latitude: data?.data?.latitude,
                    longitude: data?.data?.longitude,
                    zip_code: data?.data?.zip_code,
                    state: data?.data?.state,
                    date: new_date,
                    time: new_time,
                  })
                  .then(() => {
                    console.log("crn details are set", data.data.crn);
                  })
                  .catch((error) => {
                    console.log("failed to set crn details", error);
                  });
              })
              .catch((e) => console.log(e));
            reject(new Error("Quote does not exist"));
          }
        })
        .catch((err) => console.log(err));
    });
  }

  // working address kageneckstraße 18

  try {
    const data = yield call(fetchQuoteStartAsync);
    yield put({ type: "FETCH_QUOTE_SUCCESS", payload: data });
    yield put({ type: "SET_CRN_REQUEST", payload: data.crn });
    yield put({ type: "SET_QUOTE_STATUS", payload: "found" });
    yield put({
      type: "SET_INCRESE_CAPACITY_ADMIN_PANEL",
      payload: data?.production_increse ? data?.production_increse : 0,
    });
    yield put({ type: "SET_IMG_URL", payload: data.img_url });
    yield put({ type: "SET_PROJECT_ADDRESS", payload: action.payload });
    yield put({ type: "ADD_PROJECT_ASYNC", payload: { quote: data } });
    if (action.meta) {
      yield call(action.meta.redirect, action.meta.path);
    }
  } catch (e) {
    yield put({ type: "FETCH_QUOTE_FAILURE", payload: e.message });
    yield put({ type: "SET_QUOTE_STATUS", payload: "notFound" });
    yield put({
      type: "ADD_PROJECT_ASYNC",
      payload: { quote: default_quote_data },
    });
    yield put({ type: "SET_LOADING", payload: false });
    if (action.meta) {
      yield call(action.meta.redirect, action.meta.path);
    }
  }
}

export function* fetchQuoteStart() {
  yield takeLatest("FETCH_QUOTE_START", fetchQuoteAsync);
}
