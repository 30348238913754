import React, { useState, useEffect } from "react";
import { makeStyles, Button } from "@material-ui/core";
import share from "../../pages/Application/partials/images/share.svg";
import share2 from "Assets/share2.svg";
import Email from "Assets/Email.svg";
import ShareButtonForMobile from "Components/molecules/share.button.forMobile";
import Linkedin from "Assets/Linkedin.svg";
import Facebook1 from "Assets/Facebook1.svg";
import whatsapp from "Assets/whatsapp.png";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useSelector } from "react-redux";
import { colors } from "../../colors";
import { useLocation } from "react-router";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#fff",
    color: colors.darkBlue,
    borderRadius: "25px",
    fontSize: "12px",
    width: "160px",
    fontWeight: "bold",
    textAlign: "center",
    // marginLeft: "250px",
    height: "40px",
    transitionDelay: "1.3s",
    border: `1px solid ${colors.darkBlue}`,
    "@media (max-width: 900px)": {
      marginBottom: "30px",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
      width: "160px",
      marginLeft: "auto",
    },
    "&:hover": {
      [theme.breakpoints.down("xs")]: {
        width: "160px",
        marginLeft: "auto",
      },
      width: "310px",
      transition: "0.3s",
    },
    "&::after": {
      transition: "0.3s",
    },
  },
  logo: {
    "&:hover": {
      color: "#fff",
    },
  },
  mainMixButton: {
    display: "felx",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  comman: {
    marginLeft: "10px",
    marginRight: "-2px",
    height: "30px",
    width: "30px",
    marginBottom: "17px",
    marginTop: "-6px",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  shareTo: {
    marginTop: "7px",
    marginLeft: "3px",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  commanDiff: {
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "3px",
    height: "26px",
    "&:hover": {
      transform: "scale(1.02)",
    },
    width: "26px",
  },
}));

const ShareProjectButton = ({ shareProject, setShareProjectDialog }) => {
  const quote = useSelector((state) => state.quote);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [sharedURL, setSharedURL] = useState("");
  useEffect(() => {
    if (currentUser) {
      setSharedURL(
        window.location.origin +
          "/application/custom-" +
          currentUser.uid +
          "-" +
          quote.quote.addr1
      );
    } else {
      setSharedURL(
        window.location.origin + "/application/default-" + quote.quote.addr1
      );
    }
  }, []);

  const classes = useStyles();
  // all component states
  const [hover, setHover] = useState();
  const [trackId, setTrackId] = useState(null);
  const [visibleShare, setvisibleShare] = useState(false);

  return (
    <div>
      <Button
        variant={"outlined"}
        className={classes.button}
        onMouseEnter={() => {
          const timer = () =>
            setTimeout(() => {
              setHover(true);
            }, 200);
          const timerId = timer();
          setTrackId(timerId);
        }}
        onMouseLeave={() => {
          if (!hover) {
            clearTimeout(trackId);
          }
          setHover(false);
        }}
        onClick={() => {
          if (window.innerWidth < 500) {
            setvisibleShare(true);
          } else {
          }
        }}
        startIcon={<img src={share} />}
      >
        {isEnglish ? "Share Project" : "Projekt teilen"}
        {hover && window.innerWidth > 500 ? (
          <MixButton currentUser={currentUser} sharedURL={sharedURL} />
        ) : (
          ""
        )}
      </Button>
      <ShareButtonForMobile
        currentUser={currentUser}
        sharedURL={sharedURL}
        visibleShare={visibleShare}
        setvisibleShare={setvisibleShare}
      />
    </div>
  );
};

export const MixButton = ({ sharedURL, currentUser }) => {
  const classes = useStyles();
  const project = useSelector((state) => state.project);
  const location = useLocation();

  const { pathname } = location;
  let slug = pathname.substring(13);
  //function for share url
  const url = () => {
    if (slug?.includes("default")) {
      return window.location.href;
    } else if (slug?.includes("saved")) {
      return window.location.href;
    } else {
      return window.location.origin + "/application";
    }
  };
  //over functiuon
  const msg = `${url()}`;
  return (
    <div className={classes.mainMixButton}>
      <EmailShareButton url={msg}>
        <img src={Email} className={classes.comman} alt="" />
      </EmailShareButton>
      <LinkedinShareButton className={classes.shareTo} url={msg}>
        <LinkedinIcon className={classes.shareTo} round size={30} />
      </LinkedinShareButton>
      <FacebookShareButton className={classes.shareTo} url={msg}>
        <FacebookIcon round size={30} />
      </FacebookShareButton>
      <WhatsappShareButton className={classes.shareTo} url={msg}>
        <WhatsappIcon round size={30} />
      </WhatsappShareButton>
    </div>
  );
};

export default ShareProjectButton;
