import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Dialog } from "@material-ui/core/";
import { v4 as uuidv4 } from "uuid";
import { Button, Card, Upload, Progress } from "antd";
import { Image } from "antd";
import userPlaceholder from "Assets/userPlaceholder.svg";
import { InboxOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";

import firebase, { db } from "../../../../firebase";
import { toast } from "react-toastify";
import Dragger from "antd/lib/upload/Dragger";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    paper: { minWidth: "60%", height: "744px" },
  })
);

const ProjectPopup = ({ open, objectDetails, openMethod, id }) => {
  const classes = useStyles();
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [profileImage, setProfileImage] = useState({
    file: null,
    url: null,
  });
  const [getAllUrl, setGetAllUrl] = useState([]);
  const [loading, setLoading] = useState();
  const [loadingForMultiple, setloadingForMultiple] = useState(false);
  const [combineImgDetail, setCombineImgDetail] = useState(null);
  const [all_imgurls, setall_imgurls] = useState(null);

  useEffect(() => {
    if (id) {
      db.collection("quotes")
        .doc(id)
        .onSnapshot((doc) => {
          console.log(doc.data());
          const data = doc.data();
          setall_imgurls(data.img_url);
        });
    }
  }, [id]);

  const deleteHandler = (imgurl) => {
    const docRef = db.collection("quotes").doc(id);
    docRef
      .get()
      .then((doc) => {
        const data = doc.data();
        const img_urls = doc.data().img_url;
        console.log("img_urls", img_urls);
        const new_img_urls = img_urls.filter((x) => x != imgurl);
        console.log("new_img_urls", new_img_urls);
        docRef.set({ ...data, img_url: [...new_img_urls] });
        toast.success("image deleted successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadImage = (file) => {
    if (profileImage?.file !== null) {
      setLoading(true);
      firebase
        .storage()
        .ref("quotes/" + id)
        .put(profileImage?.file)
        .then((snapshot) => {
          firebase
            .storage()
            .ref("quotes/" + id)
            .getDownloadURL()
            .then((url) => {
              db.collection("quotes")
                .doc(id)
                .set({
                  ...objectDetails,
                  img_url: [url],
                  combined_img: profileImage?.file.name,
                });
            })
            .catch((error) => {
              alert("error");
            });
        });
    } else {
      setLoading(false);
      toast.error("please select image first");
    }
  };

  const uploadImageUrl = (img_urls) => {
    const docRef = db.collection("quotes").doc(id);
    docRef
      .get()
      .then((doc) => {
        const data = doc.data();
        console.log("data", data);
        const img_url = doc.data().img_url;
        docRef.set({
          ...data,
          img_url: [...img_url, ...img_urls],
        });
      })
      .catch((err) => console.log(err));
  };

  const getImgUrl = async (data) => {
    const newId = uuidv4();
    const img_url = await firebase
      .storage()
      .ref("otherImages/" + newId)
      .put(data?.originFileObj)
      .then(async () => {
        const url1 = await firebase
          .storage()
          .ref("otherImages/" + newId)
          .getDownloadURL()
          .then((url) => {
            return url;
          })
          .catch((error) => {
            alert("error");
            setloadingForMultiple(false);
          });
        return url1;
      });
    return img_url;
  };

  const getAllDownloadUrls = async () => {
    let img_urls = [];
    for (let i = 0; i < selectedFileList.length; i++) {
      const url = await getImgUrl(selectedFileList[i]);
      img_urls.push(url);
    }
    return img_urls;
  };

  const uploadFilesHandler = async () => {
    if (selectedFileList.length !== 0) {
      const img_urls = await getAllDownloadUrls();
      uploadImageUrl(img_urls);
    } else {
      toast.error("please select an image first");
    }
  };

  function getBase64(file) {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    } catch (error) {}
  }
  const onChangeProfile = async (e) => {
    if (e.file.status !== "uploading") {
      const base64 = await getBase64(e.file);
      setProfileImage({
        file: e.file,
        url: base64,
      });
    }
  };

  const onChangeFileHandler = async (info) => {
    let allFiles = [];
    if (info?.fileList?.length) {
      allFiles = await Promise.all(
        info.fileList.map(async (file) => {
          if (file?.type?.includes("image")) {
            return {
              originFileObj: file.originFileObj,
              preview: await getBase64(file.originFileObj),
              name: file.name,
              type: file.type,
              size: file.size,
              progress: 0,
              uid: file.uid,
            };
          }
          return {
            originFileObj: file.originFileObj,
            name: file.name,
            type: file.type,
            preview: null,
            size: file.size,
            progress: 0,
            uid: file.uid,
          };
        })
      );
    }
    setSelectedFileList(allFiles);
  };

  const removeFileHandler = (e, file) => {
    e.stopPropagation();
    setSelectedFileList(selectedFileList.filter((f) => f?.uid !== file?.uid));
  };

  const getFileSize = (size, decimals = 2) => {
    const k = 1024;
    const sizeInKb = size / k > 100 ? size / k / k : size / k;
    const format = size / k > 100 ? "MB" : "KB";
    return sizeInKb.toFixed(1) + " " + format;
  };

  return (
    <>
      <Dialog
        open={open}
        classes={{ paper: classes.paper }}
        style={{ zIndex: "0" }}
        onClose={() => {
          openMethod(false);
          setLoading(false);
          setloadingForMultiple(false);
          setGetAllUrl([]);
          setSelectedFileList([]);
          setProfileImage({
            file: null,
            url: null,
          });
          setCombineImgDetail(null);
        }}
      >
        <Card
          type="inner"
          className="an-20 semi-bold-text"
          style={{ width: "100%", height: "473px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div className="new-container">
                <Upload
                  accept="image/*"
                  onChange={onChangeProfile}
                  showUploadList={false}
                  beforeUpload={() => false}
                  customRequest={() => false}
                >
                  {profileImage.url || objectDetails?.actualImg_url ? (
                    <img
                      style={{ height: "298px", width: "298px" }}
                      src={
                        profileImage.url
                          ? profileImage.url
                          : objectDetails?.actualImg_url
                      }
                      alt="user"
                    />
                  ) : (
                    <img
                      className="user"
                      src={userPlaceholder}
                      alt="placeholder"
                    />
                  )}
                </Upload>
              </div>
              <div className="inside-container">
                <Button
                  htmlType="submit"
                  onClick={uploadImage}
                  loading={loading}
                  className="submit-button an-15 regular-text"
                >
                  Upload I
                </Button>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "300px",
                display: "flex",
                flexWrap: "wrap",
                overflow: "auto",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {all_imgurls
                ? all_imgurls?.map((data, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          style={{ zIndex: "10000" }}
                          width={200}
                          src={data}
                        />
                        <DeleteOutlined onClick={() => deleteHandler(data)} />
                      </div>
                    );
                  })
                : "don't have any multiple images you can upload them in below component by selecting or draging them also you can upload multiple iamges"}
            </div>
          </div>
        </Card>
        <div
          style={{
            height: "200px",
            width: "100%",
            margin: "auto",
            backgroundColor: "lightblue",
          }}
        >
          <Dragger
            style={{
              width: "100%",
            }}
            fileList={selectedFileList}
            multiple={true}
            showUploadList={false}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess("ok");
              }, 0);
            }}
            onChange={onChangeFileHandler}
            accept=".png,.jpg,.zip,.xls,.xlsx,.csv,.doc,.docx,.pdf,.txt,.html,.svg"
          >
            {selectedFileList && selectedFileList.length ? (
              // Container for Document preview
              <div
                className="flex items-center flex-wrap"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {selectedFileList.map((files) => {
                  return (
                    // You have to design here
                    <div
                      className="mr10 mb10"
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {files.preview ? (
                        <div
                          className="file-upload-image-preview flex items-center justify-center"
                          style={{
                            width: "140px",
                          }}
                        >
                          <img
                            width="100"
                            src={files.preview}
                            alt="file-preview"
                          />
                          <div className="image-detail">
                            <div className="an-16 line-normal image-size">
                              {getFileSize(files.size)}
                            </div>
                            <span className="an-13 image-name">
                              {files.name}
                            </span>
                          </div>

                          <Progress
                            percent={files?.progress}
                            showInfo={false}
                            className="file-progress-bar"
                          />
                        </div>
                      ) : (
                        <div className="file-upload-preview flex items-center justify-center">
                          <div className="file-upload-preview-text line-normal">
                            <div className="an-16 file-size">
                              {getFileSize(files.size)}
                            </div>
                            <span className="an-13 file-name">
                              {files.name}
                            </span>
                          </div>
                          <Progress
                            percent={files?.progress}
                            showInfo={false}
                            className="file-progress-bar"
                          />
                        </div>
                      )}
                      <Button
                        type="button"
                        className="file-upload-remove-btn mlr-auto px10 py3"
                        onClick={(e) => removeFileHandler(e, files)}
                      >
                        Remove file
                      </Button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text" style={{ fontSize: "15px" }}>
                  Drag and drop files here or click to <br /> select files from
                  your device.
                </p>
              </>
            )}
          </Dragger>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={uploadFilesHandler}
              isGreyOutlinedBtn
              loading={loadingForMultiple}
              className="submit-button an-15 regular-text"
              style={{ width: "200px", marginTop: "5px" }}
            >
              start upload
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ProjectPopup;
