import React, { useEffect } from "react";
import firebase from "../../firebase";
import { default_quotes } from "../../lib/projects_db2";
import { vendors } from "../../lib/vendors_db.js";
import { quotes } from "lib/quotes_data";
import { db } from "../../firebase";
import { products } from "../../lib/products/products_db";
import * as geofire from "geofire-common";
import { productsList } from "lib/products/productsList";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "antd";

function getTechnicalDetailsArr(details) {
  console.log("details", details);
  const arr = details.split("\n");
  const trimmedArr = arr
    .map((item) => (item != "" ? item.trim() : undefined))
    .filter((x) => x != "");
  return trimmedArr;
}

// const resetQuotes = () => {
//   const batch = db.batch();

//   db.collection("installers")
//     .get()
//     .then((snapshots) => {
//       let quotes = [];
//       snapshots.forEach((doc) => {
//         quotes.push(doc.data());
//       });
//       console.log("quotes fetched successfully", quotes);
//       quotes.map(async (quote) => {
//         const docRef = db.collection("installers").doc(quote.iid);
//         const res = await docRef.update({ admin_rating: 5 });
//         console.log(res);
//       });
//     });
// };

const deleteDuplicateCRN = () => {
  db.collection("crn_details")
    .get()
    .then((snapshots) => {
      const data = [];
      snapshots.forEach((doc) => {
        data.push(doc.data());
      });
      const arr = [];
      const set = new Set();
      data.forEach((doc) => {
        arr.push(doc.id);
        set.add(doc.id);
      });
      console.log("arr", arr);
      console.log("set", set);
      // console.log("arr", arr);
    })
    .catch((err) => console.log("err", err));
};

const resetCrn = () => {
  const batch = db.batch();
  db.collection("crn_details")
    .get()
    .then((snapshots) => {
      let quotes = [];
      snapshots.forEach((doc) => {
        quotes.push(doc.data());
      });
      console.log("quotes fetched successfully", quotes);
      let c = 1;
      quotes.map((quote) => {
        const docRef = db.collection("crn_details").doc(quote.id);

        batch.set(docRef, {
          ...quote,
          date: `11.${c}.2021`,
          time: "12:6:49",
        });
        c = c + 1;
        if (c === 30) {
          c %= 30;
          c = c + 1;
        }
      });
      batch.commit().then(() => console.log("committed successfully"));
    });
};

const all_col = [
  "crn_details",
  "installers",
  "matched",
  "products",
  "quotes",
  "saved_projects",
  "users",
];

const fetchallCollection = () => {
  all_col.map((col) => {
    db.collection(col)
      .get()
      .then((snapshots) => {
        let data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        console.log("col", data);
      });
  });
};

const findNullQRN = () => {
  db.collection("quotes")
    .get()
    .then((snapshots) => {
      let null_qrn = [];
      snapshots.forEach((doc) => {
        const data = doc.data();
        if (!data?.qrn) {
          null_qrn.push(doc.id);
        }
      });
      console.log(null_qrn);
    });
};

const addAllTheProductName = () => {
  db.collection("products")
    .get()
    .then((snapshot) => {
      let prodcutArray = [];
      snapshot.forEach((doc) => {
        prodcutArray.push(doc.data().name);
      });
      db.collection("constants")
        .doc("productsName")
        .set({ prodcutArray })
        .then(() => {
          // console.log("uploaded");
          toast.success("product Name uploaded sucessfully");
        })
        .catch((err) => {
          console.log(err, "err");
        });
    })
    .catch((err) => {
      console.log(err, "err");
    });
};
const addAllTheProductNameWithType = () => {
  db.collection("products")
    .get()
    .then((snapshot) => {
      let prodcutArray = [];
      snapshot.forEach((doc) => {
        prodcutArray.push({ name: doc.data().name, type: doc.data().type });
      });
      db.collection("constants")
        .doc("productsNameWithType")
        .set({ prodcutArray })
        .then(() => {
          toast.success("product Name with type uploaded sucessfully");
        })
        .catch((err) => {
          console.log(err, "err");
        });
    })
    .catch((err) => {
      console.log(err, "err");
    });
};
const deleteCompatibleProduct = () => {
  const docRef = db.collection("products");
  docRef
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        let obj = doc.data();
        if (obj.hasOwnProperty("compatible_products")) {
          delete obj.compatible_products;
        }
        db.collection("products")
          .doc(obj.objectId)
          .set({
            ...obj,
            compatible_products: [],
          });
      });
    })
    .catch((err) => {
      console.log(err, "err");
    });
};
const copyImageUrltoQuotesfromOtherDetails = () => {
  db.collection("other_details/project_details/quote_images")
    .get()
    .then((snapshots) => {
      let image_urls = [];
      snapshots.forEach((doc) => {
        image_urls.push({ id: doc.id, url: doc.data().img_url });
      });
      let image_urls1 = {};
      image_urls.forEach((img) => {
        image_urls1[img.id] = img.url;
      });
      Object.entries(image_urls1).map(([key, value]) => {
        console.log(key, value);
        db.collection("quotes")
          .doc(key)
          .get()
          .then((doc) => {
            const quote = doc.data();
            console.log(quote);
            db.collection("quotes")
              .doc(key)
              .set({ ...quote, img_url: [value] });
          });
      });
    });
};

const fetchImageURL = () => {
  const all_quotes = default_quotes.slice(0, 10);

  firebase
    .database()
    .ref("other_details/quote_images")
    .get()
    .then((snapshot) => {
      const all_quotes_obj = snapshot.val();
    });
};

const setImgURLObj = () => {
  const all_quotes = default_quotes;

  const all_quotes_obj = {};
  all_quotes.map((quote) => {
    all_quotes_obj[quote.qrn] = {
      combined_img: quote.combined_img,
      img_url: "",
    };
  });
  firebase
    .database()
    .ref("other_details/quote_images")
    .set(all_quotes_obj, (error) => {
      if (error) {
        alert("Error Occured");
      } else {
        alert("added");
      }
    });
};

function pad(num) {
  var s = "000000000" + num;
  return s.substr(s.length - 10);
}

const addInstallers = async () => {
  const batch = db.batch();
  for (
    let i = 498 + 498 + 498 + 498 + 498;
    i < Math.min(498 + 498 + 498 + 498 + 498 + 498, vendors.length);
    i++
  ) {
    const vendor = vendors[i];
    const res = await fetch(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        vendor.Address +
        ".json?access_token=pk.eyJ1IjoiY2hlbHVkbyIsImEiOiJjazRhMWY0eG8wMHFiM2ttdXk2cnEwMXNoIn0.qGONrbtgEqaTGD5beE7KBw"
    );
    const data = await res.json();
    if (data.features !== undefined) {
      const objectId = "installer" + pad(i + 1);
      console.log("objectId", objectId);
      const docRef = db.collection("installers").doc(objectId);
      const lng = data?.features[0]?.geometry.coordinates[0];
      const lat = data?.features[0]?.geometry.coordinates[1];
      const hash = geofire.geohashForLocation([lat, lng]);
      batch.set(docRef, {
        ...vendor,
        geometry: data?.features[0]?.geometry,
        geohash: hash,
        lat: lat,
        lng: lng,
        admin_rating: 5.0,
        user_rating: 5.0,
        iid: objectId,
        installer_uid: null,
        is_registered: false,
        self_leads: [],
      });
    }
  }
  batch.commit().then(() => console.log("committed successfully"));
};

const addProducts = () => {
  const batch = db.batch();

  productsList.map((prod) => {
    const docRef = db.collection("products").doc(prod.ID);
    if (prod?.ID) {
      const keys = Object.keys(prod);
      const updatedProd = {};
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === "capacity (kwp)") {
          updatedProd["capacity"] = prod[keys[i]];
        } else if (keys[i] == "Technische Produktdaten") {
          updatedProd[keys[i]] = getTechnicalDetailsArr(prod[keys[i]]);
        } else if (keys[i] === "ID") {
          updatedProd["objectId"] = prod[keys[i]];
        } else if (keys[i] === "bullet_point_list" && prod[keys[i]]) {
          updatedProd[keys[i]] = JSON.parse(prod[keys[i]]);
        } else updatedProd[keys[i]] = prod[keys[i]];
      }
      batch.set(docRef, updatedProd);
    }
  });
  batch
    .commit()
    .then((result) => console.log(result))
    .catch((err) => console.log(err));
};

const updateProductFieldData = () => {
  const batch = db.batch();
  const updateField = "Technische Produktdaten";

  productsList.map((product) => {
    const docRef = db.collection("products").doc(product.ID);
    docRef.get().then((doc) => {
      const prod = doc.data();
      console.log("id", prod.objectId);
      delete prod.Available;
      const updateProd = {
        ...prod,
      };
      // console.log("technical", getTechnicalDetailsArr(product[updateField]));
      console.log("updateProd", updateProd);
      docRef.set({ ...updateProd });
      // batch.set(docRef, updateProd);
    });
  });

  // batch
  //   .commit()
  //   .then((result) => console.log(result))
  //   .catch((err) => console.log(err));
};

const addVersion = () => {
  db.collection("saved_projects")
    .get()
    .then((snapshots) => {
      snapshots.forEach((doc) => {
        db.collection("saved_projects")
          .doc(doc?.data?.qrn + doc?.data?.uid)
          .set({ ...doc?.data, version: "v1" })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      });
    });
};

const fetchAllInstallersAddress = () => {
  db.collection("installers")
    .get()
    .then(async (snapshots) => {
      const all_addr = [];
      snapshots.forEach((doc) => {
        const data = doc.data();
        all_addr.push(data.address);
      });
      console.log("all_addr", all_addr);
      const new_strarr = [];
      for (let i = 0; i < all_addr.length; i++) {
        const url_string = all_addr[i].replaceAll(",", "");
        const new_url = url_string.replaceAll(" ", "%20");
        new_strarr.push(new_url);
        const mod_url = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${new_url}&inputtype=textquery&fields=formatted_address%2Cname%2Crating&key=AIzaSyDcpXcMlNZtuIZs3cBZ_mLX50xmw_D4EmY`;
        const all_ratings = [];
        await axios
          .get(mod_url)
          .then(function (response) {
            const json_res = JSON.stringify(response.data);
            if (json_res?.candidates) {
              if (
                json_res?.candidates.length > 0 &&
                json_res?.candidates[0].rating
              )
                all_ratings.push(json_res?.candidates[0]?.rating);
            } else {
              console.log("candidate not found", json_res);
            }
            console.log(json_res);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      console.log("response");
      console.log("new", new_strarr);
    });
};

const successMessage = () => {
  toast.success("hey you are");
};

const changeQuotesModuleIds = () => {
  const batch = db.batch();

  db.collection("quotes")
    .get()
    .then((snapshots) => {
      const data = [];
      snapshots.forEach((doc) => {
        data.push(doc.data());
      });
      data.forEach((quote) => {
        const docRef = db.collection("quotes").doc(quote.qrn);
        batch.set(docRef, {
          ...quote,
          module_id: "P1",
          module_brand: "JA Solar",
          module_model_number: "JA Solar JAM60S21-MR 385 Wp",
          inverter_id: "I1",
          inverter_brand_1: "SMA",
          inverter_model_1: "SMA Sunny Tripower 10.0",
        });
      });
      batch
        .commit()
        .then(() => console.log("module id changed successfully"))
        .catch((err) => console.log("some error", err));
    });
};
function generateRandomInteger(min, max) {
  return Math.floor(min + Math.random() * (max - min + 1));
}
const addCarbuyingPrice = () => {
  db.collection("products")
    .get()
    .then((snapshots) => {
      const data = [];
      snapshots.forEach((doc) => {
        data.push(doc.data());
      });
      data.forEach((product) => {
        const docRef = db.collection("products").doc(product.objectId);
        docRef
          .set({
            ...product,
            buying_car_price:
              product.type === "car" ? generateRandomInteger(30000, 31000) : 0,
          })
          .then(() => {
            console.log("added sucessfully");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });
};
function Utilities() {
  useEffect(() => {
    // db.collection("quotes")
    //   .doc("installer0000000002")
    //   .set({})
    //   .then((doc) => console.log("quote data", doc.data()));
  }, []);

  const fetchallQuotes = () => {
    db.collection("quotes")
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          const doc_data = doc.data();
          if (doc_data.city == "Schwarzenbek") data.push(doc.data());
        });
        console.log("all quotes", data);
      });
  };
  const featchProducts = () => {
    db.collection("products")
      .get()
      .then((snap) => {
        let data = [];
        snap.forEach((doc, key) => {
          data.push(doc.data());
        });
        console.log(data, "data");
      })
      .catch((err) => console.log(err));
  };
  const returnCrnNumber = () => {
    let r = (Math.random() + 1).toString(36).substring(2);
    return r;
  };
  const addQuotesHandler = () => {
    const date = new Date();
    const new_date = `${
      date.getMonth() + 1
    }.${date.getDate()}.${date.getFullYear()}`;
    for (let i = 0; i < quotes.length; i++) {
      const finalSubmitData = {
        ...quotes[i],
        module_id: "P1",
        inverter_id: "I1",
        addr1: quotes[i].addr1.trim(),
        zip_code: quotes[i].zip,
        img_url: [quotes[i].img_url],
        roof_types_list: [
          "Flatroof",
          "Tentroof",
          "Hiproof",
          "Trapezoidal Profiles",
          "Gable roof",
          "Singlepitch roof",
          "Archedroof",
          "Halfhipped roof",
          "Foldedplate roof",
          "Walldormer",
        ],
        isVerified: "true",
        isOnJun02: "true",
        customer_name: quotes[i].addr1.trim(),
        date: new_date,
      };
      db.collection("quotes")
        .doc(quotes[i].qrn)
        .set(finalSubmitData)
        .then((doc) => console.log("sucessfully uploaded"))
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const addInstallerNameToQuote = () => {
    const docRef = db.collection("quotes");
    docRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          db.collection("quotes")
            .doc(doc.data()?.qrn)
            .set({
              ...doc.data(),
              installer_partner: "",
            })
            .then(() => {
              console.log("filed added sucessfully");
            });
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const deleteQuotesHandler = () => {
    db.collection("quotes")
      .where("isOnMAy26", "==", "true")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          db.collection("quotes")
            .doc(doc.data().qrn)
            .delete()
            .then(() => console.log("deleted successfully"))
            .catch((err) => console.log(err));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateQuoteImagesBucketNames = () => {
    const docRef = db.collection("quotes");
    docRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let obj = doc.data();
          let img_urls_list = obj.img_url;
          for (let i = 0; i < img_urls_list.length; i++) {
            if (img_urls_list[i].includes("solar-app-229e7.appspot.com")) {
              img_urls_list[i].replace(
                "solar-app-229e7.appspot",
                "solarhub-44450.appspot.com"
              );
            }
          }
          let finalObj = {
            ...obj,
            img_url: [...img_urls_list],
          };
          db.collection("quotes")
            .doc(doc.data()?.qrn)
            .set(finalObj)
            .then(() => {
              console.log("filed added sucessfully");
            });
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  return (
    <div>
      <button onClick={() => addAllTheProductName()}>set prodcuts names</button>
      <button onClick={() => addAllTheProductNameWithType()}>
        set prodcuts names with type
      </button>
      <button onClick={() => addCarbuyingPrice()}>Add car buying price</button>
      <button onClick={() => updateQuoteImagesBucketNames()}>
        update quote images bucket names
      </button>
      <button onClick={() => addInstallerNameToQuote()}>
        add installer name filed to quote
      </button>
      <button onClick={() => deleteCompatibleProduct()}>
        delete competible properties from product
      </button>
      <button disable onClick={() => fetchallCollection()}>
        fetchallCollection
      </button>
      <button disable onClick={() => fetchAllInstallersAddress()}>
        fetch all installer address
      </button>
      <button disable onClick={() => changeQuotesModuleIds()}>
        Change Quotes ModulesIds
      </button>
      <button onClick={() => fetchallQuotes()}>fetch all Quotes</button>
      <button onClick={() => findNullQRN()}>Find null qrn</button>
      <button disable onClick={() => resetCrn()}>
        Reset CRN
      </button>
      <button disable onClick={() => deleteDuplicateCRN()}>
        deleteDuplicateCRN
      </button>
      <button disable onClick={() => copyImageUrltoQuotesfromOtherDetails()}>
        Copy Image Url to Quotes
      </button>
      <button onClick={() => updateProductFieldData()}>
        updateProductFieldData
      </button>
      <button onClick={() => fetchImageURL()}>Fetch Image URL</button>
      <button disabled onClick={() => setImgURLObj()}>
        Set Image URL Obj
      </button>
      <Button onClick={() => addQuotesHandler()}>
        Add new quotes to database
      </Button>
      <button onClick={() => successMessage()}>Success Message</button>
      <button onClick={() => addInstallers()}>Add Installers</button>
      <button onClick={addProducts}>add Products</button>
      <button onClick={addVersion}>add version</button>
      <Button onClick={() => featchProducts()}>Featch Pdocuts</Button>
      <Button onClick={() => deleteQuotesHandler()}>
        Delete quotes new added data
      </Button>
    </div>
  );
}

export default Utilities;
