import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #f9f9f9;
  border: 1px solid #dfdfdf;

  .title {
    font-size: 26px;
    font-weight: 600;
    color: #28334a;
    @media (max-width: 600px) {
      font-size: 22px;
      text-align: center;
    }
  }

  .search-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 32px;
    @media (max-width: 600px) {
      gap: 8px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .search-bar {
      position: relative;
      width: 500px;
      @media (max-width: 600px) {
        width: 100%;
      }
      height: 45px;
      .search-icon {
        position: absolute;
        top: 45%;
        left: 20px;
        transform: translateY(-50%);
        @media (max-width: 600px) {
          top: 53%;
        }
      }
      input {
        border: 1px solid #2d4764;
        border-radius: 59px;
        padding-left: 50px;
        @media (max-width: 600px) {
          font-size: 15px;
          padding-left: 44px;
        }
        background-color: transparent;
        resize: none;
        outline: none;
        height: 45px;
        width: 100%;
      }
    }

    .new-topic {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 131px;
      height: 42px;
      background: #ffd75d;
      border-radius: 45px;
      font-weight: 500;
      font-size: 15px;
      color: #2d4764;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
`;

export const StyledTabs = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 90%;
  .selected {
    background: #ffffff;
    /* border: 1px solid rgba(0, 0, 0, 0.3); */
    border-bottom: none;
    border-radius: 9px 9px 0px 0px;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1px;
      border: 1px solid white;
      transform: translateY(1px);
    }
  }
`;

export const Tab = styled.div`
  position: relative;
  padding: 12px 32px;
  font-weight: bold;
  @media (max-width: 600px) {
    padding: 12px 9px;
  }
  font-size: 14px;
  color: #28334a;
  border-bottom: none;
  border-radius: 9px 9px 0px 0px;
  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  border-radius: ${(props) =>
    props.value == "FAQs" ? "0px 10px 10px 10px" : "10px 10px 10px 0px"};
  overflow-y: auto;
  overflow-x: hidden;
`;
