import axios from "axios";
import React from "react";

const Accept = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8784 0.769888C14.2289 -1.43274 6.8931 1.24385 2.86265 6.84633C-1.20226 12.4969 -0.857314 20.4426 3.38373 25.8776C7.63544 31.3259 15.1544 33.4313 21.6248 30.996C27.8289 28.6606 31.9549 22.6181 32 16.0149C31.9526 9.08336 27.4511 2.9468 20.8788 0.769634L20.8784 0.769888ZM18.3447 29.5924C11.8508 30.8059 5.24067 26.8374 3.01656 20.6869C0.736323 14.3813 3.47638 7.29312 9.25733 3.99278C15.1075 0.652236 22.5544 2.30673 26.7677 7.42709C28.7396 9.82318 29.7441 12.9361 29.7654 16.0151C29.7188 22.6996 24.8975 28.3676 18.3447 29.5924ZM24.1071 12.2265C23.6085 12.7245 23.11 13.2228 22.612 13.7208C20.3742 15.9576 18.1364 18.1946 15.8984 20.4313C15.3473 20.9822 14.7965 21.5328 14.2454 22.0836C14.1127 22.2163 13.9659 22.3086 13.8126 22.3688C12.8154 22.8406 11.9133 21.6109 11.2744 20.9722C10.1946 19.8931 9.11521 18.814 8.0356 17.7349C7.61646 17.3165 7.05421 16.7713 7.3238 16.1089C7.73451 15.0987 8.82455 14.8787 9.59261 15.6466C10.5839 16.6372 11.5745 17.6278 12.5658 18.6184L13.3156 19.3682C15.1119 17.573 16.908 15.7773 18.7039 13.9822C19.7036 12.9827 20.7036 11.9832 21.7036 10.9836C22.367 10.3202 23.2485 9.15276 24.2877 9.94566C24.3402 9.98584 24.3886 10.0307 24.4328 10.0776C24.4804 10.1217 24.5245 10.1701 24.5649 10.2229C25.1391 10.9747 24.6482 11.6855 24.1071 12.2265Z"
          fill="#FDC51C"
        />
      </svg>
    </div>
  );
};

export default Accept;
