import React, { useState } from "react";
import { Dialog, Button, makeStyles } from "@material-ui/core";
import Hero from "../../Assets/Hero.svg";
import Close from "../../Assets/Close.svg";
import Group from "../SignUpSignIn/images/Group.svg";
import { colors } from "../../colors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "666px",
    height: "279px",
    margin: "0px",
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  h1_helper: {
    fontFamily: "Nunito",
    fontWeight: "700",
    fontStyle: "Normal",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "297px",
    },
    fontSize: "20px",
    color: "rgba(45, 71, 100, 1)",
  },
  h3_helper: {
    fontFamily: "Nunito",
    fontWeight: "600",
    width: "269px",
    [theme.breakpoints.down("xs")]: {
      width: "297px",
      textAlign: "center",
    },
    fontStyle: "normal",
    fontSize: "16px",
    color: "rgba(17, 17, 17, 1)",
  },
  for_margin: {
    marginLeft: "30px",
    width: "320px",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "54px",
    },
    marginBottom: "20px",
  },
  for_margin1: {
    marginRight: "30px",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
    marginBottom: "20px",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
      background: "black",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: colors.ctaSolid,
      },
    },
  },
}));

const RealizationTime = ({ setOpen, open }) => {
  const classes = useStyles();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div
        className={classes.root}
        style={{
          height: window.innerWidth < 500 ? "" : isEnglish ? "279px" : "320px",
        }}
      >
        <div className={classes.for_margin}>
          <h1 className={classes.h1_helper}>
            {isEnglish
              ? "We´re requesting possible installation times for your PV project."
              : "Wir fragen nach möglichen Montagezeiten für Ihr PV-Projekt."}
          </h1>
          <h3 className={classes.h3_helper}>
            {isEnglish
              ? "We will let you know as soon as we have received possible realization dates."
              : "Wir melden uns bei Ihnen sobald wir die möglichen Umsetzungszeiträume erhalten haben. In der Zwischenzeit können Sie weiterhin Änderungen vornehmen."}
            <div style={{ marginBottom: "20px" }}></div>
            {isEnglish
              ? "In the meantime feel free to change your setup and browse your project"
              : "In der Zwischenzeit können Sie Ihr Setup ändern und Ihr Projekt durchsuchen"}
          </h3>
        </div>
        <div className={classes.for_margin1}>
          <img
            style={{ width: "222px" }}
            src={Hero}
            style={{ height: "208px" }}
            alt=""
          />
        </div>
      </div>
    </Dialog>
  );
};

export default RealizationTime;
