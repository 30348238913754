import styled from "styled-components";

export const MainContainer = styled(`div`)`
  .mainDIv {
    height: 93vh;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    padding-top: 7%;
  }
  .delete_product {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .buttonShare {
    background-color: #fff;
    color: #1b4963;
    border-radius: 25px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    transition-delay: 1.3s;
    cursor: pointer;
    border: 1px solid #1b4963;
    :hover {
      width: 180px;
      transition: 0.3s;
      height: 40px !important;
    }
    ::after {
      transition: 0.3s;
    }
  }
  .newBtt {
    border-radius: 25px;
  }
  .overlayColor {
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 40%;
    background: linear-gradient(148.54deg, #ffe925 20.91%, #ffaa39 105.47%);
    clip-path: polygon(0 0, 0 100%, 100% 0);
  }
  .card_main {
    height: 563px;
    width: 337px;
    position: relative;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
  }
  .header_ {
    display: flex;
    justify-content: space-between;
    height: 32.02px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-left: 14px;
    padding-right: 10px;
    align-items: center;
  }
  .text {
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #2d4764;
    opacity: 0.8;
  }
  .borders {
    background: #e2e2e2;
    height: 2px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 7px;
  }
  .next_text {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #666666;
  }
  .same_part {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .heading {
    font-family: Nunito;
    font-size: 18px;
    color: #2d4764;
    margin-bottom: 8px;
  }
  .info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 274px;
    align-items: flex-start;
  }
  .first_thing {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }
  .textSmall {
    font-size: 17px;
  }
  .poew {
    color: rgba(48, 72, 100, 1);
    cursor: pointer;
  }
  .poew2 {
    color: rgba(48, 72, 100, 1);
    cursor: pointer;
    text-decoration-line: underline;
  }
  .again_same {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;
    p {
      padding: 0px;
      color: #2d4764;
      margin: 0px;
    }
  }
  .continaer {
    width: 90%;
    margin: auto;
  }
  .borders1 {
    background: #e2e2e2;
    height: 2px;
    width: 100%;
    margin-bottom: 7px;
    border-radius: 4px;
    margin-top: 7px;
  }
  .borders2 {
    background: #e2e2e2;
    height: 2px;
    width: 89%;
    margin: auto;
    border-radius: 4px;
    margin-bottom: 7px;
    margin-top: 7px;
  }
  .inside_container {
    display: flex;
    justify-content: space-between;
    padding-left: 28px;
    align-items: center;
    width: 274px;
    gap: 7px;
    margin-bottom: 10px;
    padding-top: 13px;
    flex-direction: column;
  }
  .new_same {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .text_same {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #304864;
  }
  .myButton {
    position: absolute;
    left: 92px;
    bottom: 19px;
    :hover {
      background: #ffd75d;
    }
    width: 154px;
    height: 43px;
    background: #ffd75d;
    border-radius: 36px;
  }
  .myButtonGerman {
    position: absolute;
    left: 77px;
    bottom: 10px;
    width: 179px;
    height: 43px;
    background: #ffd75d;
    border-radius: 36px;
  }
  .littel_diff {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .cardsContainer {
    display: flex;
    justify-content: center;
    margin-left: auto;
    align-items: center;
    gap: 16px;
    width: 1066px;
    margin-right: auto;
  }
  .cardsContainer2 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    align-items: center;
    gap: 16px;
    width: 1066px;
    margin-right: auto;
  }
  .text_to_shown {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    padding-bottom: 19px;
    color: #2d4764;
    width: 100%;
  }
  .button_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  .add_config {
    background: #ffffff;
    border: 1px solid #2d4764;
    border-radius: 36px;
    height: 40px;
    color: #2d4764;
    width: 257px;
  }
  .adjest_width {
    width: 100% !important;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .map_view {
    height: 40px;
    width: 132px;
    background: #ffffff;
    border-radius: 36px;
    border: 1px solid #2d4764;
    color: #2d4764;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2d4764;
    color: #2d4764;
    padding: 8px;
  }
  arrow_icon: {
    fontsize: 50px;
    hover: {
      cursor: pointer;
      padding-left: 10px;
      transition: 0.3s;
    }
  }
  .under_main {
    width: 103%;
  }
  .slick-slider .slick-initialized {
    width: 68%;
  }
  .map_view1 {
    display: flex;
    padding: 10px;
  }
  .img_and_all {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    img {
      border-radius: 20px;
      height: 185px;
      width: 218px;
    }
  }
  .img_hide {
    display: none;
  }
  .myButton2 {
    :hover {
      background: #ffd75d;
    }
    width: 154px;
    height: 43px;
    background: #ffd75d;
    border-radius: 36px;
  }
  .myButton2German {
    width: 170px;
    height: 43px;
    background: #ffd75d;
    border-radius: 36px;
  }
  .card_main2 {
    height: 499px;
    width: 531px;
    border-radius: 22px;
    position: relative;
    margin-bottom: 10px;
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 1164px) {
    .cardsContainer2 {
      flex-direction: column;
      width: auto !important;
    }
    .cardsContainer {
      width: 357px;
    }
    .inside_container {
      padding-left: 0px;
    }
    .nake_ite {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 1183px) {
    .cardsContainer {
      width: 787px;
    }
  }
  @media (max-width: 500px) {
    .cardsContainer {
      width: 357px;
    }
    .mainDIv {
      height: 100%;
      margin-bottom: 41px;
    }
    .map_view1 {
      padding: 0px;
    }
    .img_and_all {
      display: none;
    }
    .adjest_width {
      width: 89% !important;
      margin-bottom: 0px;
      margin-top: 10px;
    }
    .card_main2 {
      width: 344px;
      margin: auto;
      height: 701px;
    }
    .img_hide {
      display: block;
      height: 200px;
      width: 67%;
      margin: auto;
      border-radius: 20px;
      height: 153px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .heading {
      font-size: 15px;
    }
    .our_main {
      flex-direction: column;
    }
  }
  @media (max-width: 800px) {
    .card_main {
      width: 306px;
    }
    .card_main2 {
      margin: auto;
      margin-bottom: 10px;
    }
    .adjest_width {
      width: 100% !important;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
  @media (max-width: 365px) {
    .card_main {
      width: 306px;
    }
    .inside_container {
      padding-left: 0px;
    }
    .myButton {
      left: 76px;
    }
    .card_main2 {
      width: 320px;
      margin: auto;
    }
    .adjest_width {
      width: 89% !important;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
  @media (max-width: 340px) {
    .card_main2 {
      width: 303px;
      margin: auto;
    }
  }
  @media (max-width: 320px) {
    .inside_container {
      width: 244px;
    }
    .info {
      width: 244px;
    }
    .cardsContainer {
      width: 305px;
    }
    .card_main {
      width: 271px;
      height: 568px;
    }
    .card_main2 {
      width: 283px;
      margin: auto;
    }
    .myButton {
      left: 48px;
    }
  }
`;
