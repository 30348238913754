import React, { useEffect, useRef, useState } from "react";
// import { Modal, Button } from "antd";
import { Box, Dialog, Tab, Tabs, Typography, Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Select } from "antd";
import DetailTab1 from "./detailCalculationPartils/DetailTab1";
import DetailTab2 from "./detailCalculationPartils/DetailTab2";
import DetailTab3 from "./detailCalculationPartils/DetailTab3";
import "antd/dist/antd.css";
import DetailTab4 from "./detailCalculationPartils/DetailTab4";
import Close from "../../../Assets/Close.svg";
import Group from "../../../popups/SignUpSignIn/images/Group.svg";
import { useDispatch, useSelector } from "react-redux";
import { commaDotHandler } from "helperMethod";
import EditIcon from "@material-ui/icons/Edit";
import { calculated_consumption } from "../../../utils/consumption";
import { energyDetails } from "../../../utils/costs";
import SelectYearlyConsumption from "Components/yearlyConsumption.dialog";
import ConsumptionProfileDialog from "Components/consumptionProfile.dialog";
import ElectricityPriceDialog from "Components/electricityPrice.dialog";
import EstimatedYearlyIncreaseDialog from "Components/estimatedYearlyIncrease.dialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    cahnge: {
      ".&MuiTab-textColorInherit ": {
        color: "white",
      },
    },
    paper: {
      ".&MuiPaper-root div.MuiDialog-paper div.MuiDialog-paper": {
        borderRadius: 15,
      },
    },
    icon: {
      position: "fixed",
      zIndex: "1",
      fontSize: "30px",
      "&:hover": {
        cursor: "pointer",
        border: "1px solid black",
        background: "linear-gradient(315deg, #F9EEFE 0%, #E8F5FF 100%)",
        borderRadius: "50%",
      },
    },
    icon: {
      width: "30px",
      justifySelf: "flex-end",
      position: "absolute",
      right: "0px",
      top: "10px",
      color: "#2D4764",
      marginRight: "10px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    c2_finance_outer_container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    TabsContainer: {
      width: "872px",
      height: "914px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      marginTop: "30px",
      overflow: "auto",
      overflowX: "hidden",
    },
    c2_finace_container1: {
      width: "100%",
      alignSelf: "center",
      maxWidth: "600px",
      "& div": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 25px",
      },
      "&.MuiTypography-body1": {
        "@media (max-width: 430px)": {
          fontSize: "10px",
        },
      },
    },
    fontClass: {
      "@media (max-width: 560px)": {
        fontSize: "14px",
      },
      "&.MuiSelect-select.MuiSelect-select": {
        padding: "0px",
      },
    },
    familyButton: {
      backgroundColor: "#fff",
      color: "black",
      borderRadius: "25px",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "14px",
        width: "100px",
      },
      "&:hover": {
        background:
          "linear-gradient(180deg, rgba(244, 223, 115, 0.1) 0%, rgba(255, 184, 3, 0.1) 100%)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
      },
      alignSelf: "center",
      height: "30px",
      border: `1px solid #E2E2E2`,
    },
    familyIcon: {
      color: "#304864",
      margin: "0 5px",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "12px",
      },
    },
    selectorContainer: {
      width: "93%",
      marginLeft: "13px",
      height: "43px",
      border: "none",
      background: "none",
      borderBottom: "3px solid #FFD75D",
    },
    tab: {
      "& .MuiBox-root": {
        padding: "0px !important",
      },
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const { Option } = Select;

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetaildCalculation = ({
  Visible,
  setVisible,
  project,
  costs_and_savings,
  financing_details,
  setText,
  text,
}) => {
  const [value, setValue] = React.useState(0);
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const [text1, setText1] = useState(value);
  const isB2B = useSelector((state) => state.project.isB2B);

  const [consumptionProfileDialog, setConsumptionProfileDialog] =
    useState(false);
  const [yearlyConsumptionDialog, setYearlyConsumptionDialog] = useState(false);
  const [electricity_price, setelectricity_price] = useState(2);
  const [estimated_price, setestimated_price] = useState(
    project.costs_and_savings.estimated_yearly_price_increase
  );
  const [electricityPriceDialog, setelectricityPriceDialog] = useState(false);
  const [estimatedPriceIncreaseDialog, setestimatedPriceIncreaseDialog] =
    useState(false);
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const energy_details = energyDetails(project);
  const wayToGo = [
    "Yearly Savings in €",
    "Show all included services",
    "Self Supply",
    "Yearly Savings in TCO2",
  ];

  const wayToGoInGermany = [
    "Jährliche Einsparungen in €",
    "Show all included services",
    "Eigenverbrauch",
    "Jährliche Einsparungen in TCO2",
  ];
  const consumption = Math.round(
    calculated_consumption(project?.consumption_details)
  );

  useEffect(() => {
    setestimated_price(
      project?.costs_and_savings?.estimated_yearly_price_increase
    );
  }, [project]);

  useEffect(() => {
    if (isB2B) {
      setelectricity_price(project.consumption_details_b2b.price_per_kWH);
    } else {
      setelectricity_price(project.consumption_details.price_per_kWH);
    }
  }, [isB2B, project]);
  useEffect(() => {
    if (text) {
      if (!isEnglish) {
        const result = wayToGoInGermany.indexOf(text);
        setValue(result);
        setText1(result);
      } else {
        const result = wayToGo.indexOf(text);
        setValue(result);
        setText1(result);
      }
    }
  }, [text]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlerChange1 = (value) => {
    setText1(value);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const dispatch = useDispatch();
  const setConsumption = (data) => {
    dispatch({ type: "SET_CONSUMPTION", payload: data });
  };
  const setConsumptionDetails = (data) => {
    dispatch({ type: "SET_CONSUMPTION_DETAILS", payload: data });
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [value, text1]);

  return (
    <>
      <Dialog
        open={Visible}
        className={classes.paper}
        onClose={handleClose}
        maxWidth={false}
        style={{ margin: "-15px" }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            position: "relative",
            height: "30px",
            top: "7px",
            right: "10px",
          }}
          className={classes.removeScrrolvar}
        >
          <img
            src={whichIconShouldBeVisible ? Close : Group}
            onMouseEnter={() => setWhichIconShouldBeVisible(true)}
            onMouseLeave={() => setWhichIconShouldBeVisible(false)}
            className={classes.icon}
            onClick={() => {
              console.log(Visible, "visible");
              setVisible(false);
            }}
          />
        </div>
        <div className={classes.TabsContainer}>
          {window.innerWidth > 500 ? (
            <Box sx={{ width: "100%", p: 0 }} p={0}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", p: 0 }} p={0}>
                <Tabs
                  value={value}
                  style={{
                    marginLeft: "80px",
                    borderBottom: "1px solid #E2E2E2",
                    padding: "0x",
                    width: "725px",
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FFD75D",
                      padding: "0px",
                      textTransform: "capitalize",
                    },
                  }}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={isEnglish ? "Investment" : "Renditen"}
                    style={{
                      color: value === 0 ? "#FFD75D" : "#2D4764",
                      textTransform: "capitalize",
                      fontStyle: "normal",
                      fontWeight: "800",
                      padding: "0px",
                      fontSize: "24px",
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{
                      color: value === 1 ? "#FFD75D" : "#2D4764",
                      textTransform: "capitalize",
                      fontStyle: "normal",
                      fontWeight: "800",
                      fontSize: "24px",
                    }}
                    label={isEnglish ? "Electricity cost" : "Stromkosten"}
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{
                      color: value === 2 ? "#FFD75D" : "#2D4764",
                      textTransform: "capitalize",
                      fontStyle: "normal",
                      fontWeight: "800",
                      fontSize: "24px",
                    }}
                    label={isEnglish ? "self-sufficiency" : "Autarkie"}
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{
                      color: value === 3 ? "#FFD75D" : "#2D4764",
                      textTransform: "capitalize",
                      fontStyle: "normal",
                      fontWeight: "800",
                      fontSize: "24px",
                    }}
                    label={isEnglish ? "CO2-Savings" : "CO2-Einsparungen"}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} classes={{ root: classes.tab }}>
                <DetailTab1
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              </TabPanel>
              <TabPanel value={value} index={1} classes={{ root: classes.tab }}>
                <DetailTab2
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              </TabPanel>
              <TabPanel value={value} index={2} classes={{ root: classes.tab }}>
                <DetailTab3
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              </TabPanel>
              <TabPanel value={value} index={3} classes={{ root: classes.tab }}>
                <DetailTab4
                  costs_and_savings={costs_and_savings}
                  energy_details={energy_details}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              </TabPanel>
            </Box>
          ) : (
            <>
              <Select
                style={{ width: "90%", zIndex: 5, marginLeft: "13px" }}
                placeholder="Select a person"
                bordered={false}
                onChange={handlerChange1}
                defaultValue={text1}
                className={classes.selectorContainer}
                getPopupContainer={(node) => node.parentNode}
              >
                <Option value={0}>
                  {isEnglish ? "Investment" : "Renditen"}
                </Option>
                <Option value={1}>
                  {isEnglish ? "Electricity cost" : "Stromkosten"}
                </Option>
                <Option value={2}>
                  {isEnglish ? "self-sufficiency" : "Autarkie"}
                </Option>
                <Option value={3}>
                  {isEnglish ? "CO2-Savings" : "CO2-Einsparungen"}
                </Option>
              </Select>
              {text1 === 0 ? (
                <DetailTab1
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              ) : (
                ""
              )}
              {text1 === 1 ? (
                <DetailTab2
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              ) : (
                ""
              )}
              {text1 === 2 ? (
                <DetailTab3
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              ) : (
                ""
              )}
              {text1 === 3 ? (
                <DetailTab4
                  costs_and_savings={costs_and_savings}
                  energy_details={energy_details}
                  financing_details={financing_details}
                  setYearlyConsumptionDialog={setYearlyConsumptionDialog}
                />
              ) : (
                ""
              )}
            </>
          )}
          <div className={classes.c2_finance_outer_container}>
            <div className={classes.c2_finace_container1}>
              <div>
                <Typography className={classes.fontClass}>
                  {isEnglish
                    ? "Production in KWh/year......."
                    : "Production in KWh/year........"}
                </Typography>
                <Typography
                  className={classes.fontClass}
                  style={{ marginRight: "15px" }}
                >
                  {commaDotHandler(
                    energy_details?.total_production,
                    `${isEnglish ? "en" : "de"}`
                  )}
                </Typography>
              </div>
              <div>
                <Typography className={classes.fontClass}>
                  {isEnglish
                    ? "Yearly consumption in kWh......."
                    : "Jahresverbrauch in kWh........"}
                </Typography>
                <Button
                  onClick={() => setYearlyConsumptionDialog(true)}
                  className={classes.familyButton}
                >
                  <EditIcon
                    style={{
                      color: "#304864",
                      margin: "0 5px",
                      fontSize: "15px",
                    }}
                  />
                  {commaDotHandler(consumption, `${isEnglish ? "en" : "de"}`)}
                </Button>
              </div>
              <div>
                <Typography className={classes.fontClass}>
                  {isEnglish
                    ? "Electricity Price in €/kWh......."
                    : "Strompreis in €/kWh......."}
                </Typography>
                <Button
                  onClick={() => setelectricityPriceDialog(true)}
                  className={classes.familyButton}
                >
                  <EditIcon
                    style={{
                      color: "#304864",
                      margin: "0 5px",
                      fontSize: "15px",
                    }}
                  />
                  {commaDotHandler(
                    electricity_price,
                    `${isEnglish ? "en" : "de"}`
                  )}
                </Button>
              </div>
              <div>
                <Typography className={classes.fontClass}>
                  {isEnglish
                    ? "Estimated Yearly Electricity Price Increase %......"
                    : "Geschätzte jährliche Strompreiserhöhung %......."}
                </Typography>
                <Button
                  onClick={() => setestimatedPriceIncreaseDialog(true)}
                  className={classes.familyButton}
                >
                  <EditIcon
                    style={{
                      color: "#304864",
                      margin: "0 5px",
                      fontSize: "15px",
                    }}
                  />
                  {commaDotHandler(
                    estimated_price,
                    `${isEnglish ? "en" : "de"}`
                  )}
                  %
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div ref={messagesEndRef}></div>
        <SelectYearlyConsumption
          open={yearlyConsumptionDialog}
          setOpen={setYearlyConsumptionDialog}
          consumption={consumption}
          consumption_details={project?.consumption_details}
          setConsumption={setConsumption}
          setConsumptionDetails={setConsumptionDetails}
        />
        <ConsumptionProfileDialog
          open={consumptionProfileDialog}
          setOpen={setConsumptionProfileDialog}
        />
        <ElectricityPriceDialog
          open={electricityPriceDialog}
          setOpen={setelectricityPriceDialog}
          electricity_price={electricity_price}
          setelectricity_price={setelectricity_price}
        />
        <EstimatedYearlyIncreaseDialog
          open={estimatedPriceIncreaseDialog}
          setOpen={setestimatedPriceIncreaseDialog}
          estimated_price={estimated_price}
          setestimated_price={setestimated_price}
        />
      </Dialog>
    </>
  );
};

export default DetaildCalculation;
