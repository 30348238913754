import { getNumber } from "utils/costs";

const INITIAL_STATE = {
  pid: "project123",
  qrn: null,
  address: null,
  roofPitch: 15.0,
  isB2B: true,
  isNewUser: true,
  isProjectSet: false,
  project_type: "b2c",
  components: {
    items: {
      solar_panel: {
        item: null,
        quantity: 0,
        extended_warranty: false,
      },
      inverter: {
        item: null,
        quantity: 0,
        extended_warranty: false,
      },
      battery: {
        item: null,
        quantity: 0,
        extended_warranty: false,
      },
      wallbox: {
        item: null,
        quantity: 0,
        extended_warranty: false,
      },
      heatpump: {
        item: null,
        quantity: 0,
        extended_warranty: false,
      },
    },
    additional_componenents: {
      mounting_system: { item: null, priced_per_kwp: true },
      lightning_protecter: { item: null, priced_per_kwp: false },
      data_visualizer: { item: null, priced_per_kwp: false },
      energy_meter: { item: null, priced_per_kwp: false },
      construction_stage: { item: null, priced_per_kwp: false },
    },
    additional_chargee: {
      installation: { item: null, priced_per_kwp: true },
      electro_installation: { item: null, priced_per_kwp: true },
      on_site_visit: { item: null, priced_per_kwp: false },
      documentation: { item: null, priced_per_kwp: false },
    },
    costs: {
      total_costs: 0,
    },
  },
  profile: "Producer",
  project_images: {
    house_top: {
      type: "house_top",
      total: [],
      title: {
        english: "1. House/roof area - detail images & description ",
        german: "1. Haus / Dachfläche - Detailbilder & Beschreibung",
      },
      options: [
        { english: "Before 1980", german: "Vor 1998" },
        { english: "After 1980", german: "nach 1980" },
        { english: "After 2010", german: "nach 2010" },
      ],
      selectedOption: "Before 1980",
      description: "",
    },
    house_bottom: {
      type: "house_bottom",
      total: [],
      title: {
        english: "2. Roof from below - detail images & description",
        german: "2. Dach von unten - Detailbilder & Beschreibung",
      },
      options: [
        { english: "Before 1980", german: "Vor 1980" },
        { english: "After 1980", german: "nach 1980" },
        { english: "After 2010", german: "nach 2010" },
      ],
      selectedOption: "Before 1980",
      description: "",
    },
    electric_meter: {
      type: "electric_meter",
      total: [],
      title: {
        english: "3. Electric meter - detail images & description",
        german: "3. Stromzähler - Detailbilder & Beschreibung ",
      },
      options: [
        { english: "Digital meter", german: "digitales Messgerät" },
        { english: "Analog meter", german: "analoges Messgerät" },
      ],
      selectedOption: "Digital meter",
      description: "",
    },
  },
  total_steps_completed_index: 0,
  consumption: 0,
  energy_details: {
    total_production: "",
    consumption: "",
    feed_into_grid: "",
  },
  consumption_details: {
    entered_consumption: null,
    building_type: "single",
    people: 1,
    yearly_kms: 10000,
    isHeatPump: false,
    isWaterHeating: false,
    isCarChanrgeDuringSunshine: false,
    electroMobilityType: "type 3",
    consumption_profile: 1,
    price_per_kWH: 0.3,
    self_supply: 0.6,
  },
  consumption_details_b2b: {
    entered_consumption: null,
    business_type: "Office",
    isCarChanrgeDuringSunshine: false,
    no_of_employees: 0,
    kwa_per_employee: 0,
    electroMobilityType: "type 3",
    yearly_kms: 10000,
    no_of_cars: 18,
    kwa_per_100km: 180,
    heatPumpType: "type 3",
    surface_area: 1000,
    kwa_per_surface_area: 5,
    consumption_profile: 1,
    price_per_kWH: 0.3,
    self_supply: 0.8,
  },
  costs_and_savings: {
    electricity_price: "0.3",
    estimated_yearly_price_increase: "5",
    electricity_cost_before: "",
    feedin_savings: "",
    total_savings: "",
  },
  finacing_datails: {
    total_loan_amount: "",
    duration_in_years: "",
    total_interest: "",
    total_financing_amount: "",
    yearly_financing_amount: "",
  },
  vendor: { suggested: [], selected: [] },
  payment: {
    status: "pending", //pending, inProcess, done
    email: "",
    name: "",
    paymentIntent: null,
  },
  quoteStatus: "default",
  isFinancing: true,
  increseCapacityFromAdminPanle: 0,
  webConstants: {
    country: "germany",
    constants: [],
  },
};

/*
Quote Status
default- No address selected
found- Address selected & found in database
notFound- Addrees selected & not found in database
saved- Project already saved

*/
const addComponent = (state, data) => {
  let x = {};
  x[data.item.type] = {
    ...data,
    quantity: data.quantity
      ? data.quantity
      : state.components.items[data.item.type].quantity,
    extended_warranty: data.extended_warranty
      ? data.extended_warranty
      : state.components.items[data.item.type].extended_warranty,
  };
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        ...x,
      },
    },
  };
  return newState;
};

const increaseSolarPanelQuantity = (state) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: parseInt(state.components.items.solar_panel.quantity) + 1,
        },
      },
    },
  };
  return newState;
};

const setSolarPanelQuantity = (state, data) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: data,
        },
      },
    },
  };
  return newState;
};

const setRoofPitch = (state, data) => {
  const newState = {
    ...state,
    roofPitch: data,
  };
  return newState;
};
const decreaseSolarPanelQuantity = (state, data) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: state.components.items.solar_panel.quantity - 1,
        },
      },
    },
  };
  return newState;
};

const toggleWarranty = (state, data) => {
  let x = {};
  x[data.item.type] = { ...data, extended_warranty: !data.extended_warranty };
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        ...x,
      },
    },
  };
  return newState;
};

const removeComponent = (state, data) => {
  let x = {};
  x[data] = { item: null, quantity: 0, extended_warranty: false };
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        ...x,
      },
    },
  };
  return newState;
};
const setConsumption = (state, data) => {
  if (state.project_type == "b2b") {
    const newState = {
      ...state,
      consumption_details_b2b: {
        ...state.consumption_details_b2b,
        entered_consumption: data,
      },
    };
    return newState;
  } else {
    const newState = {
      ...state,
      consumption_details: {
        ...state.consumption_details,
        entered_consumption: data,
      },
    };
    return newState;
  }
};

const setProjectImages = (state, data) => {
  const newState = {
    ...state,
    project_images: data,
  };
  return newState;
};

const setProject = (state, data) => {
  const newState = {
    ...state,
    ...data,
    isProjectSet: true,
  };
  return newState;
};

const setProfile = (state, data) => {
  const factor_for_profile = {
    Starter: 0.25,
    Consumer: 0.5,
    Producer: 1,
  };
  const { profile, total_solar_modules } = data;
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: Math.round(
            total_solar_modules * factor_for_profile[profile]
          ),
        },
      },
    },
    profile: profile,
  };
  return newState;
};
const setProjectAddress = (state, data) => {
  const newState = {
    ...state,
    address: data,
  };
  return newState;
};

const setQRN = (state, data) => {
  const newState = {
    ...state,
    qrn: data,
  };
  return newState;
};
const setVendor = (state, data) => {
  const newState = {
    ...state,
    vendor: data,
  };
  return newState;
};

const setPayment = (state, data) => {
  const newState = {
    ...state,
    payment: data,
  };
  return newState;
};

const setConsumptionDetails = (state, data) => {
  if (state.project_type == "b2b") {
    const newState = {
      ...state,
      consumption_details_b2b: { ...data, entered_consumption: null },
    };
    return newState;
  } else {
    const newState = {
      ...state,
      consumption_details: { ...data, entered_consumption: null },
    };
    return newState;
  }
};

const newUserModal = (state) => {
  const newState = {
    ...state,
    isNewUser: false,
  };
  return newState;
};

const setQuoteStatus = (state, data) => {
  const newState = { ...state, quoteStatus: data };
  return newState;
};

const setFonancong = () => {
  const newState = {
    ...state,
    isFinancing: false,
  };
  return newState;
};

const setProjectType = (state, data) => {
  if (data === "b2b") {
    return { ...state, project_type: data, isB2B: true };
  } else {
    return { ...state, project_type: data, isB2B: false };
  }
};

const setIncreseCapacity = (state, data) => {
  const newState = {
    ...state,
    increseCapacityFromAdminPanle: data,
  };
  return newState;
};

const projectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_COMPONENT":
      return addComponent(state, action.payload);
    case "REMOVE_COMPONENT":
      return removeComponent(state, action.payload);
    case "INCREASE_SOLAR_PANEL":
      return increaseSolarPanelQuantity(state, action.payload);
    case "DECREASE_SOLAR_PANEL":
      return decreaseSolarPanelQuantity(state, action.payload);
    case "SET_SOLAR_PANEL_QUANTITY":
      return setSolarPanelQuantity(state, action.payload);
    case "SET_ROOF_PITCH":
      return setRoofPitch(state, action.payload);
    case "TOGGLE_WARRANTY":
      return toggleWarranty(state, action.payload);
    case "SET_CONSUMPTION":
      return setConsumption(state, action.payload);
    case "SET_PROJECT_IMAGES":
      return setProjectImages(state, action.payload);
    case "SET_PROJECT":
      return setProject(state, action.payload);
    case "SET_PROFILE":
      return setProfile(state, action.payload);
    case "SET_PROJECT_ADDRESS":
      return setProjectAddress(state, action.payload);
    case "SET_VENDOR":
      return setVendor(state, action.payload);
    case "SET_PAYMENT":
      return setPayment(state, action.payload);
    case "SET_QRN":
      return setQRN(state, action.payload);
    case "SET_CONSUMPTION_DETAILS":
      return setConsumptionDetails(state, action.payload);
    case "IS_NEW_USER_MODAL_OPEN":
      return newUserModal(state);
    case "SET_QUOTE_STATUS":
      return setQuoteStatus(state, action.payload);
    case "SET_FINANCING":
      return setFonancong(state, action.payload);
    case "SET_PROJECTTYPE":
      return setProjectType(state, action.payload);
    case "SET_INCRESE_CAPACITY_ADMIN_PANEL":
      return setIncreseCapacity(state, action.payload);
    case "SET_ELECTRICITY_PRICE":
      const isB2B = action.payload.isB2B;
      if (isB2B) {
        return {
          ...state,
          consumption_details_b2b: {
            ...state.consumption_details_b2b,
            price_per_kWH: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
          consumption_details: {
            ...state.consumption_details,
            price_per_kWH: action.payload.value,
          },
        };
      }
    case "SET_ESTIMATED_PRICE_INCREASE":
      return {
        ...state,
        costs_and_savings: {
          ...state.costs_and_savings,
          estimated_yearly_price_increase: action.payload,
        },
      };
    case "UPDATE_INVERTER":
      return {
        ...state,
        components: {
          ...state.components,
          items: {
            ...state.components.items,
            inverter: {
              ...state.components.items.inverter,
              item: action.payload,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default projectReducer;
