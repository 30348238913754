import React, { useState, useEffect } from "react";
import { TextField, Checkbox } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { db } from "../../../../firebase";
import { keys } from "@mui/system";

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CompatibleProducts = ({
  params,
  propertiesList,
  setpropertiesList,
  prodcutNamesWithTypes,
}) => {
  const [paramvalue, setparamvalue] = useState([]);
  useEffect(() => {
    db.collection("products")
      .doc(params.id)
      .get()
      .then((doc) => {
        const product = doc.data();
        //product.properties
        let pushArray = [];
        for (let i = 0; i < product?.compatible_products?.length; i++) {
          if (
            product.compatible_products[i].name ===
            "Test Battery (should work with SolarEdge)"
          ) {
            console.log("[checking competiable]", product.compatible_products);
          }
          pushArray.push({ title: product.compatible_products[i].name });
        }
        if (product?.compatible_products?.length) {
          setparamvalue(pushArray);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  const savePropertiesToFirestore = (values) => {
    let obj = [];
    for (let i = 0; i < values.length; i++) {
      const filterType = prodcutNamesWithTypes.filter(
        (data) => data.name === values[i].title
      );
      obj.push(filterType[0]);
    }
    const ids = obj.map((o) => o.name);
    const filtered = obj.filter(
      ({ name }, index) => !ids.includes(name, index + 1)
    );
    filtered.forEach((data) => {
      if (data.name === params.row.name) {
        return;
      }
      db.collection("products")
        .where("name", "==", data.name)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const docRef = db.collection("products").doc(doc.data().objectId);
            docRef
              .get()
              .then((result) => {
                if (result.data().hasOwnProperty("compatible_products")) {
                  if (result.data().compatible_products.length > 0) {
                    result.data().compatible_products.forEach((d) => {
                      if (d.name === params.row.name) {
                        return;
                      } else {
                        const ids = result
                          .data()
                          .compatible_products.map((o) => o.name);
                        const filtered = result
                          .data()
                          .compatible_products.filter(
                            ({ name }, index) => !ids.includes(name, index + 1)
                          );
                        docRef
                          .set({
                            ...result.data(),
                            compatible_products: [
                              ...filtered,
                              {
                                name: params.row.name,
                                type: params.row.type,
                              },
                            ],
                          })
                          .then(() => console.log("saved sucess"));
                      }
                    });
                  } else {
                    const ids = result
                      .data()
                      .compatible_products.map((o) => o.name);
                    const filtered = result
                      .data()
                      .compatible_products.filter(
                        ({ name }, index) => !ids.includes(name, index + 1)
                      );
                    docRef
                      .set({
                        ...result.data(),
                        compatible_products: [
                          ...filtered,
                          {
                            name: params.row.name,
                            type: params.row.type,
                          },
                        ],
                      })
                      .then(() => console.log("saved sucess"));
                  }
                } else {
                  const ids = result
                    .data()
                    .compatible_products.map((o) => o.name);
                  const filtered = result
                    .data()
                    .compatible_products.filter(
                      ({ name }, index) => !ids.includes(name, index + 1)
                    );
                  docRef
                    .set({
                      ...result.data(),
                      compatible_products: [
                        ...filtered,
                        {
                          name: params.row.name,
                          type: params.row.type,
                        },
                      ],
                    })
                    .then(() => console.log("saved sucess"));
                }
              })
              .catch((err) => console.log("err", err));
          });
        })
        .catch((err) => console.log("errr", err));
    });
    db.collection("products")
      .doc(params.id)
      .set({ ...params.row, compatible_products: filtered })
      .then(() => {
        console.log("saved");
      })
      .catch((err) => console.log("errr", err));
  };

  const addNewPropertytoFirestore = (property) => {
    const docRef = db.collection("constants").doc("productsName");
    docRef.get().then((doc) => {
      const properties = doc.data().productPropertiesList;
      const propertiesArray = properties.map((opt) => {
        return { title: opt };
      });
      setpropertiesList([...propertiesArray, { title: property }]);
      docRef.set({
        productPropertiesList: [...properties, property],
      });
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableCloseOnSelect
        autoHighlight={true}
        value={paramvalue}
        onChange={(event, newValue) => {
          let newArray = [];
          setparamvalue((prev) => {
            if (prev.length < newValue.length) {
              const obj = newValue[newValue.length - 1];
              if (obj?.inputValue) {
                newArray = prev.map((opt) => opt);
                newArray.push(obj?.inputValue);
                addNewPropertytoFirestore(obj?.inputValue);
                savePropertiesToFirestore(newArray);
                return newArray;
              }
            } else {
              let mainResult = prev.map((doc) => {
                let result = newValue.filter(
                  (data) => data.title === doc.title
                );
                if (result.length) {
                  return true;
                }
                return false;
              });
              for (let i = 0; i < mainResult.length; i++) {
                if (mainResult[i] === false) {
                  db.collection("products")
                    .where("name", "==", prev[i].title)
                    .get()
                    .then((snapshot) => {
                      snapshot.forEach((doc) => {
                        const docRef = db
                          .collection("products")
                          .doc(doc.data().objectId);
                        docRef
                          .get()
                          .then((result) => {
                            let compatible_products = [
                              ...result.data().compatible_products,
                            ];
                            const filterdProducts = compatible_products.filter(
                              (currDoc) => currDoc.name !== params.row.name
                            );
                            let resultObj = {
                              ...result.data(),
                              compatible_products: filterdProducts,
                            };
                            docRef
                              .set(resultObj)
                              .then(() => console.log("saved sucess"));
                          })
                          .catch((err) => console.log("err", err));
                      });
                    })
                    .catch((err) => console.log("errr", err));
                }
              }
            }
            newArray = newValue.map((opt) => opt.title);
            savePropertiesToFirestore(newValue);
            return newValue;
          });
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={propertiesList}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }

          if (option?.inputValue) {
            return option.inputValue;
          }

          return option?.title;
        }}
        renderOption={(option) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={paramvalue.some(
                (data) =>
                  data.title.toLowerCase().trim() ===
                  option.title.toLowerCase().trim()
              )}
            />
            {option.title}
          </React.Fragment>
        )}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    </div>
  );
};

export default CompatibleProducts;
