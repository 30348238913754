import React, { useState, useEffect } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Group from "./../../../Assets/Group.png";
import House from "./../../../Assets/House.png";
import { Button, Input, TextField } from "@material-ui/core";
import { Height, Label } from "@material-ui/icons";
import { Typography } from "antd";
import SingleImg from "./../../../Assets/application_step_1/consumption.building.1.png";
import MultiImg from "./../../../Assets/application_step_1/consumption.building.2.png";
import { colors } from "../../../colors";
import { consumption_profiles } from "../../../utils/consumption";
import setCommaDot, { commaDotHandler, parseLocaleNumber } from "helperMethod";
import { useSelector } from "react-redux";
import { calculated_consumption_b2b } from "utils/consumption";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 912,
    maxHeight: 778,
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  center1: {
    textAlign: "center",
    marginTop: "57px !important",
    color: "#2D4764",
  },
  ps_stepper: {
    display: "flex",
    flexDirection: "row",
    marginTop: "17px",
    alignItems: "center",
    justifyContent: "center",
  },
  ps_stepper_plus: {
    width: "35px",
    height: "35px",
    background: "#DFDFDF",
    border: "1px solid #DFDFDF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: "600",
  },
  ps_stepper_value: {
    width: "80px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1px solid #DFDFDF",
  },
  input_main: {
    height: "121px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  },
  button: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputStyle: {
    outline: "none",
    boxShadow: "none",
    height: "35px",
    width: "73px",
    textAlign: "center",
    border: "1px solid lightgray",
  },
}));
const Company = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption_b2b(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );
  const classes = useStyles();
  const [peopleCount, setPeopeCount] = useState(
    consumption_details.no_of_employees
  );
  const [employee, setEmployee] = useState(peopleCount);
  const [energyCounsumption, setEnergyCounsumption] = useState(
    consumption_details.kwa_per_employee
  );
  const [kwaPerEmployee, setKwaPerEmployee] = useState(energyCounsumption);
  const handleChange = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g, ""));
    setEmployee(value);
    setPeopeCount(value);
    setConsumptionDetails({
      ...consumption_details,
      no_of_employees: value,
    });
    setTemp1(
      calculated_consumption_b2b({
        ...consumption_details,
        no_of_employees: value,
      })
    );
  };
  const handleChange1 = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g, ""));
    setKwaPerEmployee(value);
    setEnergyCounsumption(value);
    setConsumptionDetails({
      ...consumption_details,
      kwa_per_employee: value,
    });
    setTemp1(
      calculated_consumption_b2b({
        ...consumption_details,
        kwa_per_employee: value,
      })
    );
  };
  return (
    <div>
      <h4 className={classes.center}>
        {isEnglish
          ? "How many people do you employ in your business?"
          : "Wie viele Mitarbeiter beschäftigen Sie in Ihrem Unternehmen?"}
      </h4>
      <div className={classes.ps_stepper}>
        <div
          className={classes.ps_stepper_plus}
          onClick={() => {
            setPeopeCount((prevstate) => {
              setConsumptionDetails({
                ...consumption_details,
                no_of_employees: prevstate - 1,
              });
              setTemp1(
                calculated_consumption_b2b({
                  ...consumption_details,
                  no_of_employees: prevstate - 1,
                })
              );
              return prevstate - 1;
            });
            setEmployee((employee) => employee - 1);
          }}
        >
          -
        </div>
        <input
          className={classes.inputStyle}
          value={employee}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <div
          className={classes.ps_stepper_plus}
          onClick={() => {
            setPeopeCount((prevstate) => {
              setConsumptionDetails({
                ...consumption_details,
                no_of_employees: prevstate + 1,
              });
              setTemp1(
                calculated_consumption_b2b({
                  ...consumption_details,
                  no_of_employees: prevstate + 1,
                })
              );
              setEmployee((employee) => employee + 1);
              return prevstate + 1;
            });
          }}
        >
          +
        </div>
      </div>
      <h4 className={classes.center1}>
        {isEnglish
          ? "  Energy consumption per employee per year - the medium estimate is preselected"
          : "Energieverbrauch pro Mitarbeiter und Jahr - die mittlere Schätzung ist vorausgewählt"}
      </h4>
      <div className={classes.ps_stepper}>
        <div
          className={classes.ps_stepper_plus}
          onClick={() => {
            setEnergyCounsumption((prevstate) => {
              setConsumptionDetails({
                ...consumption_details,
                kwa_per_employee: prevstate - 1,
              });
              setTemp1(
                calculated_consumption_b2b({
                  ...consumption_details,
                  kwa_per_employee: prevstate - 1,
                })
              );
              return prevstate - 1;
            });
            setKwaPerEmployee((c) => c - 1);
          }}
        >
          -
        </div>
        <input
          className={classes.inputStyle}
          value={kwaPerEmployee}
          onChange={(e) => {
            handleChange1(e);
          }}
        />
        <div
          className={classes.ps_stepper_plus}
          onClick={() => {
            setEnergyCounsumption((prevstate) => {
              setConsumptionDetails({
                ...consumption_details,
                kwa_per_employee: prevstate + 1,
              });
              setTemp1(
                calculated_consumption_b2b({
                  ...consumption_details,
                  kwa_per_employee: prevstate + 1,
                })
              );
              return prevstate + 1;
            });
            setKwaPerEmployee((c) => c + 1);
          }}
        >
          +
        </div>
      </div>
      <YearlyConsumptionInput
        consumption={consumption}
        setConsumption={setConsumption}
        temp1={temp1}
        setTemp1={setTemp1}
      />
    </div>
  );
};

export default Company;
export const YearlyConsumptionInput = ({
  consumption,
  setConsumption,
  temp1,
  setTemp1,
}) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <div className={classes.input_main}>
      <div className={classes.inside_inputMain}>
        <Typography>
          {isEnglish
            ? "Yearly consumption in kWh"
            : "Jährlicher Energieverbrauch in kWh"}
        </Typography>
        <Input
          placeholder={
            isEnglish
              ? "Yearly consumption in kWh"
              : "Jährlicher Energieverbrauch in kWh"
          }
          inputProps={{ style: { textAlign: "center" } }}
          style={{ textAlign: "center", paddingLeft: "5px", width: "100px" }}
          value={temp1}
          onChange={(e) => {
            let value = e.target.value;
            if (isEnglish) {
              value = parseLocaleNumber(value, "en");
              setTemp1(e.target.value);
            } else {
              value = parseLocaleNumber(value, "de");
              setTemp1(e.target.value);
            }
            setConsumption(value);
          }}
        />
      </div>
    </div>
  );
};
