import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import popup from "Assets/Info/popup.png";
import Login from "Assets/Info/Login.svg";
import Home from "Assets/Info/Home.svg";
import Roof from "Assets/Info/Roof.svg";
import Graph from "Assets/Info/Graph.svg";
import location from "Assets/Info/location.svg";
import Arrow1 from "Assets/Info/Arrow1.png";
import { Progress } from "antd";
import firebase, { db } from "../../../../firebase";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";
import { Tooltip, withStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "678px",
    },
    leftContainer: {
      padding: "10px 35px",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "space-around",
      width: "517px",
      height: "250px",
      display: "flex",
    },
    rightContainer: {
      padding: "15px",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    rightContainerSubBody: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      gap: "16px",
    },
    rightContainerSubBodyImg: {
      position: "relative",
      height: "160px",
      width: "160px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    rightContainerSubBody1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    rightContainerSubBodyButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
    },
    rightContainerSubBodyButton1: {
      height: "30px",
      width: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#3E3637",
      borderRadius: "20px",
      color: "#FFFFFF",
      fontSize: "14px",
      cursor: "pointer",
    },
    rightContainerRatingValue: {
      position: "absolute",
      height: "100%",
      width: "100%",
      fontSize: "14px",
      color: "black",
      transform: "translate(15px,-58px)",
    },
    rightContainerName: {
      marginLeft: "8px",
      fontWeight: "400",
      fontSize: "18px",
      color: "#000000",
    },
    leftFooterLeft: {
      width: "30%",
      fontWeight: "600",
      color: "#718096",
      fontSize: "16px",
    },
    leftFooterRight: {
      fontWeight: "500",
      color: "#718096",
      fontSize: "16px",
    },
    leftSubFooter: {
      display: "flex",
    },
    projectdetails: {
      display: "flex",
      gap: "16px",
      cursor: "pointer",
    },
  })
);
const ListingCard = ({
  projectData,
  setMakeUseEffectRun,
  makeUseEffectRun,
}) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);

  const onclickHandler = () => {
    window.open(
      `${window.location.origin}/application/saved-${projectData?.pid}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  // function for remvoving the saved project
  const removeSaveProjectHandler = (pid) => {
    console.log(pid, "pid");
    db.collection("saved_projects")
      .doc(pid)
      .delete()
      .then(() => {
        toast.success(
          isEnglish
            ? "project deleted successfully"
            : "Projekt erfolgreich gelöscht"
        );
        setMakeUseEffectRun(!makeUseEffectRun);
      })
      .catch((error) => {
        toast.error("something went wrong");
        setMakeUseEffectRun(!makeUseEffectRun);
      });
  };

  const BlueOnGreenTooltip = withStyles({
    tooltip: {
      color: "#000",
      fontWeight: "600",
      fontSize: "14px",
      maxWidth: "500px",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
      backgroundColor: "#fff",
    },
  })(Tooltip);

  return (
    <div className={classes.root}>
      <div className={classes.rightContainer}>
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Progress percent={50} showInfo={false} />
          <div className={classes.rightContainerSubBody}>
            <div className={classes.rightContainerSubBodyImg}>
              <img
                src={projectData?.projectImgUrl}
                style={{ height: "100%", width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div className={classes.rightContainerSubBody1}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "16px",
                  }}
                >
                  <div style={{ height: "20px", width: "20px" }}>
                    <img
                      src={popup}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>{`${projectData?.project_details?.address?.complete}`}</div>
                  <img src={Login} alt="" />
                  <img src={Home} alt="" />
                </div>
                <div
                  className={classes.projectdetails}
                  onClick={() => {
                    onclickHandler();
                  }}
                >
                  <div style={{ height: "20px", width: "20px" }}>
                    <img
                      src={Arrow1}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>Project Details</div>
                  <div>
                    {isEnglish
                      ? `Solar Configuration version${projectData?.version[1]}`
                      : `Konfiguration Variante hinzufügen${projectData?.version[1]}`}
                  </div>
                </div>
                <div style={{ display: "flex", gap: "16px" }}>
                  <div
                    style={{ height: "20px", width: "20px", cursor: "pointer" }}
                  >
                    <img
                      src={Roof}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>{`${projectData?.updatedData?.roof_type} ${projectData?.updatedData?.roof_material} ${projectData?.updatedData?.roof_tilt} ${projectData?.updatedData?.roof_orientation}`}</div>
                </div>
                <div style={{ display: "flex", gap: "16px" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={Graph}
                      alt=""
                      style={{ align: "middle", height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>
                    {projectData?.financialDetails?.total_project_cost}€
                  </div>
                  <div>{`CRN Number :- ${projectData?.crn}`}</div>
                </div>
                <div className={classes.rightContainerSubBodyButton}>
                  <button className={classes.rightContainerSubBodyButton1}>
                    Make an appointment
                  </button>
                  <div>En Solar GmbH, Stuttgart</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ cursor: "pointer", marginBottom: "30px" }}
                  onClick={() => removeSaveProjectHandler(projectData?.pid)}
                >
                  <BlueOnGreenTooltip
                    placement="top"
                    title={
                      isEnglish ? "delete your project" : "lösche dein Projekt"
                    }
                  >
                    <CloseOutlined
                      width={60}
                      height={60}
                      style={{ fontSize: "20px" }}
                    />
                  </BlueOnGreenTooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                    marginRight: "14px",
                    height: "92px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: "1.5rem", marginLeft: "9px" }}>
                    {projectData?.energysDetails?.total_production}kWp
                  </div>
                  <div style={{ height: "64px", position: "relative" }}>
                    {/* <Progress
                    style={{ height: "64px" }}
                    type="circle"
                    percent={76}
                    format={() => "4.3"}
                  />
                  <div className={classes.rightContainerRatingValue}>4.3</div> */}
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={location} alt="" />
                    </div>
                    <div>ID 2231</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
