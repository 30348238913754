import React, { useEffect, useState } from "react";
import { makeStyles, Select, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import firebase, { db } from "../firebase";
import { useProjectTools } from "hooks/useProjectTools";

const useStyles = makeStyles((theme) => ({
  mySignButton: {
    width: "120px",
    height: "45px",
    fontSize: "18px",
    backgroundColor: "#FBDA61",
    fontWeight: "bold",
    borderRadius: "5px",
    "@media (max-width: 430px)": {
      width: "80px",
      fontSize: "10px",
    },
  },

  butoon: {
    borderRadius: "5px",
    fontWeight: "bold",
    padding: "8px 20px",
    margin: "15px",
    borderRadius: "10px",
    border: `1px solid #000000`,
    backgroundColor: "transparent",
    marginTop: "0px",
    "&:hover": {
      backgroundColor: "rgba(45, 71, 100, 1)",
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
      fontSize: "14px",
    },
    "@media (max-width: 321px)": {
      padding: "7px 12px",
      margin: "3px",
      fontSize: "10px",
    },
  },

  personIcon: {
    marginRight: "3px",
    marginBottom: "2px",
  },

  forLogInUserResponsive: {
    width: "40px",
    [theme.breakpoints.down("xs")]: {
      width: "30px",
      marginRight: "6px",
    },
  },

  h3Responsive: {
    marginTop: "10px",
    marginLeft: "3px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },

  buttonResponsive: {
    marginBottom: "14px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
}));
export default function VersionMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [signUpSignInPopup, setSignUpSignInPopup] = useState(false);
  const [savedprojectsVersion, setSavedProjectsVersion] = useState([]);
  const isEnglish = useSelector((state) => state?.global?.isEnglish);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dummyProjectCount = useSelector(
    (state) => state.global.dummyProjectCount
  );
  const qrn = useSelector((state) => state.project.qrn);
  const quote = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  const history = useHistory();
  //
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentUser && qrn) {
      db.collection("saved_projects")
        .where("qrn", "==", qrn)
        .where("uid", "==", currentUser.uid)
        .get()
        .then((snapshots) => {
          const data = [];
          snapshots.forEach((doc) => {
            data.push(doc.data());
          });
          setSavedProjectsVersion(data);
          dispatch({ type: "SET_PROJECT_CONFIGURATION", payload: data.length });
        })
        .catch((e) => console.log(e));
    }
  }, [currentUser, qrn, dummyProjectCount, history.pathname, isEnglish]);

  const [age, setAge] = React.useState("");
  useEffect(() => {
    if (window.location.pathname.includes("saved")) {
      const currentVersion = window.location.pathname.substr(-2);
      setAge(
        isEnglish
          ? `Solar Configuration version ${currentVersion[1]}`
          : `Konfiguration Variante ${currentVersion[1]}`
      );
    }
  }, [savedprojectsVersion, isEnglish]);

  const handleChange = (event) => {
    setAge(event.target.value);
    const versionFromUrl = event.target.value.substr(-1);
    history.push(
      "/application/saved-" +
        currentUser?.uid +
        quote?.quote.qrn +
        `v${versionFromUrl}`
    );
  };

  return (
    <div>
      {savedprojectsVersion.length > 1 && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disableUnderline
          style={{
            border: "none",
            borderRadius: "0px",
            marginTop: "0px",
          }}
          value={age}
          label="Age"
          onChange={handleChange}
        >
          {savedprojectsVersion?.map((data, index) => {
            return (
              <MenuItem
                key={index}
                value={
                  isEnglish
                    ? `Solar Configuration version ${data?.version[1]}`
                    : `Konfiguration Variante ${data?.version[1]}`
                }
              >
                {window.innerWidth > 500
                  ? isEnglish
                    ? `Solar Configuration version ${data?.version[1]}`
                    : `Konfiguration Variante ${data?.version[1]}`
                  : isEnglish
                  ? `v${data?.version[1]}`
                  : `v${data?.version[1]}`}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </div>
  );
}
