import React, { useState, useEffect } from "react";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setLanguage } from "../redux/language/language.action";

const useStyles = makeStyles((theme) => ({
  selectHalper: {
    height: "45px",
    width: "150px",
    zIndex: "0",
    paddingLeft: "15px",
    margin: "15px",
    "@media (max-width: 560px)": {
      width: "auto",
      margin: "0px",
    },
    border: "1px solid lightgray",
  },
}));

function LanguageDropdown({ isEnglish, setLanguage }) {
  const classes = useStyles();
  const [selectedLanguage, setSelectedLanguage] = useState();
  useEffect(() => {
    setSelectedLanguage(isEnglish ? "English" : "German");
  }, [isEnglish]);
  const changeLanguage = (e) => {
    setSelectedLanguage(e.target.value);
    e.target.value === "English" ? setLanguage(true) : setLanguage(false);
  };

  return (
    <Select
      style={{
        width: "100px",
        border: "none",
        borderRadius: "0px",
        marginTop: "0px",
      }}
      margin="dense"
      className={classes.selectHalper}
      displayEmpty={true}
      renderValue={() => {
        return (
          <img
            src={`/images/${selectedLanguage}.png`}
            style={{ width: "50px", height: "auto", marginTop: "6px" }}
          />
        );
      }}
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      disableUnderline
      value={selectedLanguage}
      onChange={(e) => changeLanguage(e)}
    >
      <MenuItem value={"English"}>English</MenuItem>
      <MenuItem value={"German"}>{isEnglish ? "German" : "Deutsch"}</MenuItem>
    </Select>
  );
}

const mapStateToProps = (state) => ({
  isEnglish: state.global.isEnglish,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (isEnglish) => dispatch(setLanguage(isEnglish)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
