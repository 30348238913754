import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core/";
import { Button, Input, Card, Form, Upload } from "antd";
import userPlaceholder from "Assets/userPlaceholder.svg";
import camera from "Assets/camera.svg";

import firebase, { db } from "../../../../firebase";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const typeList = [
  { name: "Solar Panel" },
  { name: "Mounting System" },
  { name: "Inverter" },
  { name: "Additional Equipment" },
  { name: "Batteries" },
  { name: "Wallbox" },
  { name: "Heatpump" },
];
const ProductPopup = ({
  open,
  objectDetails,
  id,
  setId,
  openMethod,
  setObjectDetails,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const [profileImage, setProfileImage] = useState({
    file: null,
    url: null,
  });

  const uploadImage = (file) => {
    if (profileImage?.file !== null) {
      setLoading(true);
      firebase
        .storage()
        .ref("products/" + id)
        .put(profileImage?.file)
        .then((snapshot) => {
          firebase
            .storage()
            .ref("products/" + id)
            .getDownloadURL()
            .then((url) => {
              db.collection("products")
                .doc(id)
                .set({
                  ...objectDetails,
                  imgurl1: url,
                })
                .then(() => {
                  toast.success("image uploaded");
                  openMethod(false);
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                });
            })
            .catch((error) => {
              alert("error");
              setLoading(false);
            });
        });
    } else {
      toast.error("please select an image first");
    }
  };

  function getBase64(file) {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    } catch (error) {}
  }
  const onChangeProfile = async (e) => {
    if (e.file.status !== "uploading") {
      const base64 = await getBase64(e.file);
      setProfileImage({
        file: e.file,
        url: base64,
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          openMethod(false);
          setLoading(false);
          setProfileImage({
            file: null,
            url: null,
          });
        }}
      >
        <Card
          type="inner"
          className="an-20 semi-bold-text"
          style={{ width: "600px", height: "473px" }}
        >
          <div className="new-container">
            <Upload
              accept="image/*"
              onChange={onChangeProfile}
              showUploadList={false}
              beforeUpload={() => false}
              customRequest={() => false}
            >
              {profileImage.url || objectDetails?.imgurl1 ? (
                <img
                  style={{ height: "300px", width: "400px" }}
                  src={
                    profileImage.url ? profileImage.url : objectDetails?.imgurl1
                  }
                  alt="user"
                />
              ) : (
                <img className="user" src={userPlaceholder} alt="placeholder" />
              )}
              <img
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  width: "46.79px",
                  height: "46.79px",
                  top: "245px",
                  right: "96px",
                }}
                src={camera}
                alt="camera"
              />
            </Upload>
          </div>
          <div className="inside-container">
            <Button
              htmlType="submit"
              onClick={uploadImage}
              loading={loading}
              className="submit-button an-15 regular-text"
            >
              {"Upload Image"}
            </Button>
          </div>
        </Card>
      </Dialog>
    </>
  );
};

export default ProductPopup;
