import React, { useEffect, useState } from "react";
import Info from "./Info";
import styled from "styled-components";
const MainContainer = styled("div")`
  width: 100%;
  min-height: 96px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  background: #ffffff;
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const MainContainerContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;
const MainContainerDivide = styled("div")`
  width: 2px;
  background-color: #d0d0d0;
  height: 80%;
`;
const SubContainer = styled("div")`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: 72%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto;
    padding-top: 7px;
  }
`;
const Text = styled("div")`
  margin-left: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #2d4764;
`;
const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, "0");
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
const getDay = (date) => {
  var A = date;
  var weekdays = new Array(7);
  weekdays[0] = "Sunday";
  weekdays[1] = "Monday";
  weekdays[2] = "Tuesday";
  weekdays[3] = "Wednesday";
  weekdays[4] = "Thursday";
  weekdays[5] = "Friday";
  weekdays[6] = "Saturday";
  var r = weekdays[A.getDay()];
  return r;
};
const getDate = (today) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var date =
    today.getFullYear() +
    " , " +
    monthNames[today.getMonth()] +
    " , " +
    today.getDate();
  return date;
};
const AllNotification = ({ data }) => {
  console.log("sar", data);
  return (
    <MainContainer>
      <Info data={data} />
      {!data.duration ? null : (
        <>
          <MainContainerContainer>
            <MainContainerDivide />
          </MainContainerContainer>
          <SubContainer>
            <Text>Duration- {data.duration} min</Text>
            <Text>
              {formatAMPM(new Date(data.meeting_time.seconds * 1000))} -{" "}
              {formatAMPM(
                new Date(
                  data.meeting_time.seconds * 1000 + data.duration * 60 * 1000
                )
              )}
            </Text>
            <Text>
              {`${getDay(
                new Date(data.meeting_time.seconds * 1000)
              )}, ${getDate(new Date(data.meeting_time.seconds * 1000))}`}
            </Text>
          </SubContainer>
        </>
      )}
    </MainContainer>
  );
};

export default AllNotification;
