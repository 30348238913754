import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Process from "Assets/Process.svg";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "react-image-gallery/styles/css/image-gallery.css";
import Close from "../../Assets/Close.svg";
import { colors } from "../../colors";
import Projektablauf from "Assets/Projektablauf.svg";
import play from "../../Assets/play.png";
import benefit1 from "../../Assets/benefit1.png";
import { useSelector } from "react-redux";
import ProjektablaufEnglish from "Assets/ProjektablaufEnglish.svg";
import close from "../../Assets/close.png";
import { Divider } from "antd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Details from "./Deatils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90vw",
    maxWidth: "900px",
    padding: "25px",
    "@media (max-width: 560px)": {
      paddingLeft: "0px",
    },
    overflow: "auto",
    overflowX: "hidden",
    paddingLeft: "50px",
    display: "flex",
    flexDirection: "column",
  },
  makeer: {
    "@media (max-width: 560px)": {
      margin: "0px",
      marginRight: "10px",
      width: "111%",
    },
  },
  adsetWidth: {
    "@media (max-width: 560px)": {
      width: "100%",
      marginLeft: "30px",
    },
  },
  close_container: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  close_icon: {
    width: "30px",
    height: "30px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  img1: {
    width: "80%",
    height: "auto",
  },
  benefit_container: {
    width: "100%",
    display: "flex",
    "@media (max-width: 560px)": {
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "10px",
      flexDirection: "column",
    },
    flexWrap: "wrap",
  },
  steps_after_order: {
    width: "80%",
    margin: "15px",
    marginBottom: "35px",
  },
}));

function IncludedServices({ isEnglish, open, setOpen }) {
  const classes = useStyles();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <div className={classes.root}>
        <div className={classes.close_container}>
          <img
            onMouseEnter={() => setWhichIconShouldBeVisible(true)}
            onMouseLeave={() => setWhichIconShouldBeVisible(false)}
            src={whichIconShouldBeVisible ? Close : close}
            className={classes.close_icon}
            onClick={() => setOpen(false)}
          />
        </div>
        <div className={classes.adsetWidth}>
          <TopHeader
            text1={
              isEnglish
                ? "SAMPLE INSTALLATION SOLAR ARRAY"
                : "Beispiel Installation Photovoltaik"
            }
            text2={
              isEnglish
                ? "LOOK HOW EASY IT IS TO REALIZE A PV PROJECT"
                : "SEHEN SIE, WIE EINFACH ES IST, EIN PV-PROJEKT ZU REALISIEREN"
            }
          />
          <iframe
            width="100%"
            height="500"
            src="https://youtube.com/embed/amU4k6-CNDE"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <TopHeader
            text1={
              isEnglish
                ? "INCLUDED BENEFITS AT A GLANCE"
                : "INKLUSIVE VORTEILE AUF EINEN BLICK"
            }
            text2={
              isEnglish
                ? "WE TAKE CARE OF ALL NECESSARY STEPS IN THE PROCESS"
                : "WIR KÜMMERN SICH UM ALLE NOTWENDIGEN SCHRITTE IM PROZESS"
            }
          />
        </div>
        <div className={classes.benefit_container}>
          <Details />
        </div>
        <Divider
          style={{ borderTop: "3px solid gray" }}
          className={classes.makeer}
        />
        {window.innerWidth < 500 ? (
          <img src={Process} style={{ marginLeft: "31px" }} alt="" />
        ) : isEnglish ? (
          <img
            src={ProjektablaufEnglish}
            className={classes.steps_after_order}
          />
        ) : (
          <img src={Projektablauf} className={classes.steps_after_order} />
        )}
      </div>
    </Dialog>
  );
}

const TopHeader = ({ text1, text2 }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    count__container: {
      fontSize: "50px",
      color: colors.darkBlue,
    },
    h2Helper: {
      fontSize: "10px",
      "@media (max-width: 922px)": {
        fontSize: "5px",
      },
    },
    detail__container: {
      margin: "25px 0px",
      "& h1": {
        margin: "0px",
        fontSize: "25px",
        color: colors.darkBlue,
        "@media (max-width: 450px)": {
          fontSize: "15px",
          fontWeight: "bold",
        },
      },
      "& p": {
        margin: "0px",
        color: colors.darkBlue,
        "@media (max-width: 430px)": {
          fontSize: "12px",
        },
      },
    },
    savings_container: {
      display: "flex",
      alignItems: "center",
      justifySelf: "flex-end",
      marginLeft: "100px",
      borderBottom: "2px solid #E7E7E7",
      padding: "2px",
      "@media (max-width: 922px)": {
        flexDirection: "column",
        padding: "0",
        height: "auto",
      },
      "@media (max-width: 477px)": {
        height: "auto",
        marginLeft: "0px",
        display: "none",
      },
      height: "35px",
      borderRadius: "5px",
      "& h2": {
        color: colors.orangeSolid,
        fontSize: "25px",
        "@media (max-width: 922px)": {
          fontSize: "20px",
        },
      },
      "& p": {
        fontSize: "14px",
        marginLeft: "10px",
      },
      myclass: {
        marginLeft: "20px",
        "@media (max-width: 430px)": {
          marginLeft: "5px",
        },
      },
      adsetWidth: {
        "@media (max-width: 560px)": {
          width: "100%",
          marginLeft: "30px",
        },
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.detail__container}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <h1>{text1}</h1>
        </div>
        <p>{text2}</p>
      </div>
    </div>
  );
};

const Benefit = ({ src, text, german, includedContent }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [expended, setExpended] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "40%",
      // minWidth: "350px",
      border: "none",
      margin: "10px",
      "@media (max-width: 560px)": {
        width: "100%",
      },
      "& .MuiAccordion-root:before": {
        height: "0px",
      },
      "&:hover": {
        width: "40%",
        "@media (max-width: 560px)": {
          width: "100%",
        },
        backgroundColor: "lightyellow",
        borderRadius: "10px",
        boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        cursor: "pointer",
      },
    },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "white",
      },
    },
    img: {
      width: "50px",
      height: "50px",
      borderRadius: "25px",
      backgroundColor: colors.ctaSolid,
    },
    text: {
      display: "flex",
      marginLeft: "19px",
      alignItems: "center",
      flex: "1",
      marginLeft: "15px",
    },
    makeCenter: {
      margin: "6px 0px",
      "@media (max-width: 560px)": {
        width: "100%",
        padding: "0px",
        margin: "0px",
      },
    },
    adsetWidth: {
      "@media (max-width: 560px)": {
        width: "100%",
        marginLeft: "30px",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Accordion
      className={classes.root}
      onClick={() => setExpended(!expended)}
      classes={{
        root: classes.MuiAccordionroot,
      }}
      elevation={0}
    >
      <AccordionSummary
        className={classes.makeCenter}
        expandIcon={
          expended ? (
            <ArrowDropDownIcon className={classes.helperIcon} />
          ) : (
            <ArrowRightIcon className={classes.helperIcon} />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <img src={benefit1} className={classes.img} />
        <Typography className={classes.text}>
          {isEnglish ? text : german}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {includedContent
            ? includedContent
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default IncludedServices;
