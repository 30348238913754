const { makeStyles } = require("@material-ui/core");
const { colors } = require("colors");

export const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `11px ​solid #FFD75`,
    overflow: "hidden",
    minWidth: "305px",
    height: "406px",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      minWidth: "205px",
    },
    alignItems: "center",
    zIndex: "0",
    backgroundColor: "#FFFFFF",
    border: " 1px solid #DFDFDF",
    boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "22px",
    position: "relative",
  },
  root2: {
    width: "296.47px",
    display: "flex",
    flexDirection: "column",
    height: "64%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "0",
  },
  close_header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: "0",
  },
  logoImage: {
    height: "50px",
    width: "150px",
    objectFit: "cover",
    margin: "25px",
  },
  divider: { width: "90%", color: colors.orangeSolid, margin: "5px" },
  buttons: {
    width: "100%",
    display: "flex",
    "@media (max-width: 430px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  button1: {
    backgroundColor: colors.darkBlue,
    color: "#fff",
    borderRadius: "3px",
    width: "50%",
    margin: "5px",
    "@media (max-width: 430px)": {
      width: "70%",
    },
  },
  button2: {
    backgroundColor: "#fff",
    color: colors.darkBlue,
    borderRadius: "3px",
    "@media (max-width: 430px)": {
      width: "70%",
    },
    width: "50%",
    border: `1px solid ${colors.darkBlue}`,
    margin: "5px",
  },
  closeIcom: {
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.5)",
      transition: "0.3s",
    },
  },
  vendorTopLine: {
    width: "100%",
    height: "41px",
    background:
      "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
  },
  middleWay: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
    flexDirection: "column",
    marginRight: "30px",
  },
  divider: {
    height: "2px",
    width: "90%",
    background:
      "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
    margin: "5px",
  },
  first_part: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontWeight: "600",
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#2D4764",
  },
  imgeStyle: {
    height: "30px !important",
    paddingRight: "10px !important",
    width: "99px !important",
    objectFit: "contain !important",
  },
  header_main: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    width: "93%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    border: "1px solid #2D4764",
  },
  celender: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    border: "1px solid #666666",
    borderRadius: "9px",
    width: "176px",
    height: "32px",
  },
  bigClass: {
    color: "#287E97",
    marginBottom: "5px",
  },
  buttonb: {
    background: "#FFD75D",
    borderRadius: "36px",
    width: "88%",
    height: "43px",
    color: "#2D4764",
    marginTop: "7px",
    fontSize: "16px",
    position: "absolute",
    bottom: "13px",
    "&:hover": {
      background: "#FFD75D",
    },
  },
  button2: {
    borderRadius: "36px",
    width: "88%",
    height: "43px",
    color: "#2D4764",
    marginTop: "7px",
    border: "1px solid #2D4764",
    fontSize: "16px",
    position: "absolute",
    bottom: "13px",
  },
  text1: {
    fontWeight: "600",
    fontSize: "16px",
    paddingRight: "14px",
    color: "#2D4764",
  },
}));
