import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import { Form, Input, Button, Checkbox, InputNumber } from "antd";
import { Dialog, AppBar, Toolbar, Typography } from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { db } from "../../../../firebase";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const typeList = [
  { name: "Solar Panel" },
  { name: "Mounting System" },
  { name: "Inverter" },
  { name: "Additional Equipment" },
  { name: "Batteries" },
  { name: "Wallbox" },
  { name: "Heatpump" },
];
const QuotesPopup = ({ open, setopen }) => {
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  const [imgurl1, setImgurl1] = useState("");
  const [combineImgName, setCombineImageName] = useState(null);
  const [objectId, setObjectId] = useState("");

  const onFinish = (values) => {
    console.log(values, "values");
    const uniqId = uuidv4();
    const newId = `quotes-` + uniqId;
    const output = {
      ...values,
      roof_types_list: [
        "Flatroof",
        "Tentroof",
        "Hiproof",
        "Trapezoidal Profiles",
        "Gable roof",
        "Singlepitch roof",
        "Archedroof",
        "Halfhipped roof",
        "Foldedplate roof",
        "Walldormer",
      ],
      roof_tilt_values: {
        "0-90": 1,
        "91-93": 2,
        "93-95": 4,
        "95-97": 4,
        "97-180": 5,
      },
      roof_structure_complexity_values: {
        clean: values?.clean,
        complex: values?.complex,
        little_complex: values?.little_complex,
        mid_complex: values?.mid_complex,
        very_complex: values?.very_complex,
      },
      roof_status_values: {
        good: values?.good,
        medium: values?.medium,
        new: values?.new,
        old: values?.old,
        very_old: values?.very_old,
      },
      shadows_values: {
        light: values?.light,
        middle: values?.middle,
        middle_strong: values?.middle_strong,
        no: values?.no,
        strong: values?.strong,
      },
      qrn: newId,
    };
    console.log(newId, "newId");
    console.log(output, "output");
    db.collection("quotes")
      .doc(newId)
      .set(output)
      .then(() => {
        toast.success("Saved");
        setopen(false);
      })
      .catch((error) => {
        toast.error("Error Occured");
        setopen(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Dialog fullScreen open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setopen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add Key quotes
            </Typography>
          </Toolbar>
        </AppBar>
        <Form
          name="basic"
          style={{
            margin: "auto",
            fontSize: "26px",
            width: "80%",
          }}
          initialValues={{
            addr1: "",
            annual_solar_generation: "",
            annul_electric_bill: "",
            bill_type: "",
            building_type: "",
            crn: "",
            business_name: "",
            business_type: "",
            city: "",
            combined_img: "",
            contact_url: "",
            contact_website: "",
            current_annual_consumption: "",
            customer_name: "",
            dc_to__ac_ratio: "",
            estimated_consumption: "",
            fit_type: "",
            inverter_brand_1: "",
            inverter_brand_2: "",
            inverter_efficiency: "",
            inverter_model_1: "",
            inverter_model_2: "",
            inverter_powerrating_1: "",
            inverter_quantity_1: "",
            inverter_quantity_2: "",
            is_solar_panel_already_installed: "",
            lat: "",
            long: "",
            module_brand: "",
            module_id: "",
            module_model_number: "",
            module_quantity: "",
            new_annual_electric_bill: "",
            number_of_floors: "",
            number_of_inhabitants: "",
            owner_type: "",
            project_size_rating: 5,
            panel_degradation: "",
            production_ratio: "",
            project_type: "",
            proposed_system_size_ac: "",
            proposed_system_size_dc: "",
            rate_name: "",
            roof_area: "",
            roof_material: "",
            roof_material_rating: 0,
            roof_orientation: 0,
            roof_orientation_rating: 0,
            roof_status_rating: 0,
            roof_structure_complexity_rating: 0,
            shadows_rating: 0,
            roof_type: "",
            state: "",
            state_of_roof: "",
            style_of_roof: "",
            total_inverter_capacity_1: "",
            total_inverter_capacity_2: "",
            total_inverter_capacity_22: "",
            total_system_cost_: "",
            type_of_solar_panel_if_already_installed: "",
            unit_electricity_bill_rate: "",
            unit_system_cost: "",
            roof_tilt: "",
            "utility_bill_replacement_%": "",
            "utility_electric_rate_escalator_%": "",
            utility_name: "",
            watts_per_module: "",
            year_of_roof_construction: "",
            zip_code: "",
            inverter_id: "",
            sl_no: "",
            img_url: "",
            good: 0,
            medium: 0,
            new: 0,
            very_old: 0,
            old: 0,
            light: 0,
            middle: 0,
            middle_strong: 0,
            no: 0,
            strong: 0,
            clean: 0,
            complex: 0,
            mid_complex: 0,
            little_complex: 0,
            very_complex: 0,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="addr1" name="addr1">
            <Input />
          </Form.Item>

          <Form.Item
            label="annual_solar_generation"
            name="annual_solar_generation"
          >
            <Input />
          </Form.Item>
          <Form.Item label="annul_electric_bill" name="annul_electric_bill">
            <Input />
          </Form.Item>
          <Form.Item label="bill_type" name="bill_type">
            <Input />
          </Form.Item>
          <Form.Item label="building_type" name="building_type">
            <Input />
          </Form.Item>
          <Form.Item label="business_name" name="business_name">
            <Input />
          </Form.Item>
          <Form.Item label="business_type" name="business_type">
            <Input />
          </Form.Item>
          <Form.Item label="city" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="combined_img" name="combined_img">
            <Input />
          </Form.Item>
          <Form.Item label="contact_url" name="contact_url">
            <Input />
          </Form.Item>
          <Form.Item label="roof_material" name="roof_material">
            <Input />
          </Form.Item>
          <Form.Item label="roof_material_rating" name="roof_material_rating">
            <InputNumber />
          </Form.Item>
          <Form.Item label="roof_orientation" name="roof_orientation">
            <Input />
          </Form.Item>
          <Form.Item
            label="roof_orientation_rating"
            name="roof_orientation_rating"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="roof_status_rating" name="roof_status_rating">
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="roof_structure_complexity_rating"
            name="roof_structure_complexity_rating"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="shadows_rating" name="shadows_rating">
            <InputNumber />
          </Form.Item>
          <Form.Item label="crn" name="crn">
            <Input />
          </Form.Item>
          <Form.Item label="project_size_rating" name="project_size_rating">
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="current_annual_consumption"
            name="current_annual_consumption"
          >
            <Input />
          </Form.Item>
          <Form.Item label="customer_name" name="customer_name">
            <Input />
          </Form.Item>
          <Form.Item label="dc_to__ac_ratio" name="dc_to__ac_ratio">
            <Input />
          </Form.Item>
          <Form.Item label="estimated_consumption" name="estimated_consumption">
            <Input />
          </Form.Item>
          <Form.Item label="fit_type" name="fit_type">
            <Input />
          </Form.Item>
          <Form.Item label="inverter_brand_1" name="inverter_brand_1">
            <Input />
          </Form.Item>
          <Form.Item label="inverter_brand_2" name="inverter_brand_2">
            <Input />
          </Form.Item>
          <Form.Item label="inverter_efficiency" name="inverter_efficiency">
            <Input />
          </Form.Item>
          <Form.Item label="inverter_model_1" name="inverter_model_1">
            <Input />
          </Form.Item>
          <Form.Item label="inverter_model_2" name="inverter_model_2">
            <Input />
          </Form.Item>
          <Form.Item
            label="inverter_powerrating_1"
            name="inverter_powerrating_1"
          >
            <Input />
          </Form.Item>
          <Form.Item label="inverter_quantity_1" name="inverter_quantity_1">
            <Input />
          </Form.Item>
          <Form.Item label="inverter_quantity_2" name="inverter_quantity_2">
            <Input />
          </Form.Item>
          <Form.Item
            label="is_solar_panel_already_installed"
            name="is_solar_panel_already_installed"
          >
            <Input />
          </Form.Item>
          <Form.Item label="lat" name="lat">
            <Input />
          </Form.Item>
          <Form.Item label="long" name="long">
            <Input />
          </Form.Item>
          <Form.Item label="module_brand" name="module_brand">
            <Input />
          </Form.Item>
          <Form.Item label="module_id" name="module_id">
            <Input />
          </Form.Item>
          <Form.Item label="module_quantity" name="module_quantity">
            <Input />
          </Form.Item>
          <Form.Item
            label="new_annual_electric_bill"
            name="new_annual_electric_bill"
          >
            <Input />
          </Form.Item>
          <Form.Item label="number_of_floors" name="number_of_floors">
            <Input />
          </Form.Item>
          <Form.Item label="number_of_inhabitants" name="number_of_inhabitants">
            <Input />
          </Form.Item>
          <Form.Item label="owner_type" name="owner_type">
            <Input />
          </Form.Item>
          <Form.Item label="panel_degradation" name="panel_degradation">
            <Input />
          </Form.Item>
          <Form.Item label="production_ratio" name="production_ratio">
            <Input />
          </Form.Item>
          <Form.Item label="project_type" name="project_type">
            <Input />
          </Form.Item>
          <Form.Item label="rate_name" name="rate_name">
            <Input />
          </Form.Item>
          <Form.Item label="roof_area" name="roof_area">
            <Input />
          </Form.Item>
          <Form.Item label="roof_type" name="roof_type">
            <Input />
          </Form.Item>
          <Form.Item label="state" name="state">
            <Input />
          </Form.Item>
          <Form.Item label="state_of_roof" name="state_of_roof">
            <Input />
          </Form.Item>
          <Form.Item label="style_of_roof" name="style_of_roof">
            <Input />
          </Form.Item>
          <Form.Item
            label="total_inverter_capacity_1"
            name="total_inverter_capacity_1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="total_inverter_capacity_2"
            name="total_inverter_capacity_2"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="total_inverter_capacity_22"
            name="total_inverter_capacity_22"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="type_of_solar_panel_if_already_installed"
            name="type_of_solar_panel_if_already_installed"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="unit_electricity_bill_rate"
            name="unit_electricity_bill_rate"
          >
            <Input />
          </Form.Item>
          <Form.Item label="unit_system_cost" name="unit_system_cost">
            <Input />
          </Form.Item>
          <Form.Item
            label="utility_bill_replacement_%"
            name="utility_bill_replacement_%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="utility_electric_rate_escalator_%"
            name="utility_electric_rate_escalator_%"
          >
            <Input />
          </Form.Item>
          <Form.Item label="utility_name" name="utility_name">
            <Input />
          </Form.Item>
          <Form.Item label="watts_per_module" name="watts_per_module">
            <Input />
          </Form.Item>
          <Form.Item
            label="year_of_roof_construction"
            name="year_of_roof_construction"
          >
            <Input />
          </Form.Item>
          <Form.Item label="zip_code" name="zip_code">
            <Input />
          </Form.Item>
          <Form.Item label="inverter_id" name="inverter_id">
            <Input />
          </Form.Item>
          <Form.Item label="sl_no" name="sl_no">
            <Input />
          </Form.Item>
          <Form.Item label="zip_code" name="zip_code">
            <Input />
          </Form.Item>
          <Form.Item label="img_url" name="img_url">
            <Input />
          </Form.Item>
          <Form.Item label="roof_tilt" name="roof_tilt">
            <Input />
          </Form.Item>
          <div>roof_status_values</div>
          <Form.Item label="good" name="good">
            <InputNumber />
          </Form.Item>
          <Form.Item label="medium" name="medium">
            <InputNumber />
          </Form.Item>
          <Form.Item label="new" name="new">
            <InputNumber />
          </Form.Item>
          <Form.Item label="old" name="old">
            <InputNumber />
          </Form.Item>
          <Form.Item label="very_old" name="very_old">
            <InputNumber />
          </Form.Item>
          <div>shadows_values</div>
          <Form.Item label="light" name="light">
            <InputNumber />
          </Form.Item>
          <Form.Item label="middle" name="middle">
            <InputNumber />
          </Form.Item>
          <Form.Item label="middle_strong" name="middle_strong">
            <InputNumber />
          </Form.Item>
          <Form.Item label="no" name="no">
            <InputNumber />
          </Form.Item>
          <Form.Item label="strong" name="strong">
            <InputNumber />
          </Form.Item>
          <div>roof_structure_complexity_values</div>
          <Form.Item label="clean" name="clean">
            <InputNumber />
          </Form.Item>
          <Form.Item label="complex" name="complex">
            <InputNumber />
          </Form.Item>
          <Form.Item label="mid_complex" name="mid_complex">
            <InputNumber />
          </Form.Item>
          <Form.Item label="little_complex" name="little_complex">
            <InputNumber />
          </Form.Item>
          <Form.Item label="very_complex" name="very_complex">
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: "300px", height: "50px" }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Dialog>
    </>
  );
};

export default QuotesPopup;
