import React, { useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  TextField,
} from "@material-ui/core";
import evCar1 from "../../Assets/evCar1.png";
import evCar2 from "../../Assets/evCar2.png";
import evCar3 from "../../Assets/evCar3.png";
import { YearlyConsumptionInput } from "./Building";
import center from "@turf/center";
import { calculated_consumption } from "utils/consumption";
import setCommaDot, { parseLocaleNumber, commaDotHandler } from "helperMethod";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    maxHeight: 345,
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  option_container: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 530px)": {
      flexwrap: "wrap",
    },
  },
  input_kelometer: {
    display: "flex",
    color: "#2D4764",
    width: "100%",
    marginBottom: "20px",
    justifyContent: "space-between",
    "@media (max-width: 530px)": {
      marginBottom: "0px",
    },
    flexDirection: "column",
    alignItems: "center",
  },
  input_main: {
    height: "130px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  },
  button: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img_classes: {
    height: "30px",
    width: "30px",
  },
  useElectricity: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "& h6": {
      fontSize: "18px",
      textAlign: "left",
      color: "#2D4764",
    },
  },
}));

const Image = (props) => {
  return (
    <>
      <img src={props.src} style={props.style} alt="" />
      <div
        style={{
          marginTop: "2px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#2D4764",
          fontSize: "14px",
        }}
      >
        {props.text}
      </div>
    </>
  );
};

const ElectroMobility = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const classes = useStyles();
  const { electroMobilityType } = consumption_details;
  const [showOption, setShowOption] = useState(
    electroMobilityType === "type 1" ? true : false
  );
  const [isCheked, setisCheked] = useState(false);
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );
  const setElectroMobilityType = (electroMobilityType) => {
    setConsumptionDetails({ ...consumption_details, electroMobilityType });
    setTemp1(
      calculated_consumption({ ...consumption_details, electroMobilityType })
    );
  };
  return (
    <div style={{ marginTop: window.innerWidth > 500 ? "8px" : "20px" }}>
      <h4 className={classes.center}>
        {isEnglish
          ? "Are you planning to use electromobility in your company?"
          : "Sie planen den Einsatz von Elektromobilität in Ihrem Unternehmen?"}
      </h4>
      <div className={classes.option_container}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={electroMobilityType === "type 1"}
                  onChange={(checked) => {
                    if (checked) {
                      setElectroMobilityType("type 1");
                      setShowOption(!showOption);
                    }
                  }}
                />
              }
              label={
                <Image
                  src={evCar1}
                  style={{ width: window.innerWidth < 500 ? "70px" : "" }}
                  text={isEnglish ? "In use" : "vorhanden"}
                />
              }
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={electroMobilityType === "type 2"}
                  onChange={(checked) =>
                    checked && setElectroMobilityType("type 2")
                  }
                />
              }
              label={
                <Image
                  src={evCar2}
                  style={{ width: window.innerWidth < 500 ? "68px" : "" }}
                  text={isEnglish ? "Planned" : "Geplant"}
                />
              }
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              style={{
                marginLeft: window.innerWidth < 500 ? "3px" : "",
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={electroMobilityType === "type 3"}
                  onChange={(checked) =>
                    checked && setElectroMobilityType("type 3")
                  }
                />
              }
              label={
                <Image
                  src={evCar3}
                  style={{ width: window.innerWidth < 500 ? "59px" : "" }}
                  text={isEnglish ? "Not Planned" : "Nicht geplant"}
                />
              }
              labelPlacement="top"
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className={classes.input_kelometer}>
        <h4>
          {isEnglish
            ? "Expected yearly kilometers travelled"
            : "Erwartete jährliche Kilometer pro Auto"}
        </h4>
        <TextField
          inputProps={{
            min: 0,
            style: { textAlign: "center", width: "100px" },
          }}
          placeholder="10,000 kms"
          value={`${consumption_details?.yearly_kms}`}
          onChange={(e) => {
            setConsumptionDetails({
              ...consumption_details,
              yearly_kms: e.target.value,
            });
            setTemp1(
              calculated_consumption({
                ...consumption_details,
                yearly_kms: e.target.value,
              })
            );
          }}
        />
      </div>
      {electroMobilityType === "type 1" ? (
        <div className={classes.useElectricity}>
          <Checkbox
            color="primary"
            checked={consumption_details?.isCarChanrgeDuringSunshine}
            onChange={(e) => {
              console.log(e.target.checked, "checked");
              const checked = e.target.checked;
              if (checked) {
                if (consumption_details.self_supply + 0.2 >= 1) {
                  setConsumptionDetails({
                    ...consumption_details,
                    isCarChanrgeDuringSunshine: true,
                  });
                } else {
                  setConsumptionDetails({
                    ...consumption_details,
                    isCarChanrgeDuringSunshine: true,
                    self_supply: consumption_details.self_supply + 0.2,
                  });
                }
              } else {
                if (consumption_details?.isCarChanrgeDuringSunshine) {
                  if (consumption_details.self_supply + 0.2 >= 1) {
                    setConsumptionDetails({
                      ...consumption_details,
                      isCarChanrgeDuringSunshine: false,
                    });
                  } else {
                    setConsumptionDetails({
                      ...consumption_details,
                      isCarChanrgeDuringSunshine: false,
                      self_supply: consumption_details.self_supply - 0.2,
                    });
                  }
                } else {
                  setConsumptionDetails({
                    ...consumption_details,
                    isCarChanrgeDuringSunshine: false,
                  });
                }
              }
            }}
          />
          <h6>
            {isEnglish
              ? "Yes I can charge the cars mostly during sunshine hours (increase self supply by 20%)"
              : "Ja ich kann das Auto hauptsächlich während der Sonnenstunden laden (Eigenverbrauch um 20% erhöhen)"}
          </h6>
        </div>
      ) : (
        ""
      )}
      <YearlyConsumptionInput
        consumption={consumption}
        setConsumption={setConsumption}
        temp1={temp1}
        setTemp1={setTemp1}
      />
      {/* <div className={classes.button}>
        <Button
          style={{
            color: "white",
            background:
              " linear-gradient(226.42deg, #28334A 8.93%, #09152F 110.98%)",
            borderRadius: "10px",
          }}
          variant="contained"
          onClick={() => setOpen(false)}
        >
          {isEnglish ? "Take Over" : "Übernehmen"}
        </Button>
      </div> */}
    </div>
  );
};

export default ElectroMobility;
