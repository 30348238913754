import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Button,
  CssBaseline,
  TextField,
  Grid,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import SolarHubLogo from "Assets/SolarHubLogo.png";
import { EmailShareButton } from "react-share";
import { Link } from "react-router-dom";

import firebase from "../../firebase";
import { colors } from "../../colors";
import { styles } from "../../popups/SignUpSignIn/signup.popup.styles";
import linkdin from "./images/linkdin.svg";
import Apple from "./images/Apple.svg";
import Google from "./images/Google.svg";
import Microsoft from "./images/Microsoft.svg";
import { useProjectTools } from "hooks/useProjectTools";
import { useHistory } from "react-router-dom";

const ProjectPopup = () => {
  const classes = styles();
  const [isRegistered, setIsRegistered] = useState(true);
  const [userLikeToSave, setuserLikeToSave] = useState(true);
  const [onAutomateiclySave, setonAutomateiclySave] = useState(true);
  const project = useSelector((state) => state.project);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  return (
    <Fragment>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <Grid container component="main" style={{ minHeight: "100vh" }}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={6} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            elevation={6}
            square
            style={{ margin: "auto" }}
          >
            <div className={classes.removeWidth}>
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div />
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={SolarHubLogo} className={classes.img} alt="" />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "73px",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h1 className={classes.h1Class}>
                  {isEnglish
                    ? "Save your Solarproject"
                    : "Speichern Sie ihr Solarprojekt"}
                </h1>
                <h5 className={classes.h5Class}>
                  {isEnglish
                    ? "Your path to your own energy"
                    : "Ihr Weg zu ihrer eigenen Energie"}
                </h5>
              </div>
            </div>
            <div className={classes.paper}>
              {isRegistered ? (
                <>
                  <UserLoginForm
                    isEnglish={isEnglish}
                    onAutomateiclySave={onAutomateiclySave}
                    userLikeToSave={userLikeToSave}
                    project={project}
                    // setOpen={setOpen}
                  />{" "}
                  <h3 className={classes.accountH1}>
                    <span>
                      {isEnglish
                        ? "You don´t have an account yet?"
                        : "Sie haben noch keinen Account"}
                    </span>{" "}
                    <Link
                      onClick={() => setIsRegistered(false)}
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          color: colors.ctaSolid,
                          textDecoration: "none",
                        }}
                      >
                        {isEnglish
                          ? "Register now"
                          : "- Registrieren Sie sich jetzt"}
                      </span>
                    </Link>
                  </h3>
                  <h3 className={classes.accountH1}>
                    <span>
                      {isEnglish
                        ? "Do you have any questions?"
                        : "Sie haben Fragen?"}
                    </span>{" "}
                    <Link style={{ textDecoration: "none" }}>
                      <EmailShareButton url="">
                        <a
                          style={{
                            color: colors.ctaSolid,
                            textDecoration: "none",
                          }}
                          href="mailto:info@solarhub24.de?subject=SendMail&body=Description"
                        >
                          {isEnglish
                            ? "Contact us now!"
                            : "Jetzt Kontakt mit uns aufnehmen"}
                        </a>
                      </EmailShareButton>
                    </Link>
                  </h3>
                </>
              ) : (
                <>
                  <UserSignUpForm
                    isEnglish={isEnglish}
                    onAutomateiclySave={onAutomateiclySave}
                    userLikeToSave={userLikeToSave}
                    project={project}
                    // setOpen={setOpen}
                  />
                  <h3 style={{ margin: "15px 0px 0px -25px" }}>
                    <span>Already have an account?</span>{" "}
                    <Link
                      onClick={() => setIsRegistered(true)}
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          color: colors.ctaSolid,
                          textDecoration: "none",
                        }}
                      >
                        Login now
                      </span>
                    </Link>
                  </h3>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default ProjectPopup;

export const UserSignUpForm = ({ isEnglish, project, onAutomateiclySave }) => {
  const classes = styles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = React.useState("");
  const { save } = useProjectTools();

  const handleChange = (event) => {
    setRememberMe(event.target.value);
  };

  const signUpWithEmailPassword = (e) => {
    e.preventDefault();

    if (firstName === "" || email === "") {
      alert("Please enter required details");
      return;
    }
    if (password === "") {
      alert("Please choose a password");
      return;
    }

    const updateUserInfo = (userDetails, info) => {
      if (info == "login") {
        const docref = db.collection("users").doc(userDetails.uid);
        docref.get().then((doc) => {
          if (doc.exists) {
            docref.set({ ...userDetails });
          }
        });
      }
      if (info == "register") {
        db.collection("users")
          .doc(userDetails.uid)
          .set({ ...userDetails });
      }
    };

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        var user = userCredential.user;
        user
          .updateProfile({
            displayName: firstName + " " + lastName,
          })
          .then(function () {
            const userDetails = {
              fullName: firstName + " " + lastName,
              email,
              otherDetails: "other details",
              uid: userCredential.user.uid,
            };
            updateUserInfo(userDetails, "register");
            if (onAutomateiclySave) {
              save(project, userCredential.user.uid);
            }
          })
          .catch((error) => {
            alert(error.message);
          });
      })
      .catch(function (error) {
        alert(error.message);
      });
  };

  const continueWithGoogle = (provider) => {
    if (provider === "google.com") {
      provider = new firebase.auth.GoogleAuthProvider();
    } else {
      provider = new firebase.auth.OAuthProvider(provider);
    }

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        let user = result.user;

        const userDetails = {
          fullName: user?.displayName,
          email: user?.email,
          otherDetails: "other details",
          uid: user.uid,
        };
        // setOpen(false);
        if (onAutomateiclySave) {
          save(project, user.uid);
        }
        updateUserInfo(userDetails, "register");
      })
      .catch((error) => {});
  };
  return (
    <Container maxWidth="xs">
      <div style={{ marginTop: "0px", color: "#fff" }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder={isEnglish ? "Enter First Name" : "Geben Sie ihre name"}
          autoComplete="email"
          autoFocus
          style={{
            marginBottom: "10px",
          }}
          className={classes.removeMargin}
          InputProps={{
            className: classes.multilineColor,
          }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder={
            isEnglish ? "Enter Last Name" : "Geben Sie Ihren Nachnamen ein"
          }
          autoFocus
          style={{
            marginBottom: "10px",
            color: "#fff",
            border: "1px solid white",
          }}
          InputProps={{
            className: classes.multilineColor,
          }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder={isEnglish ? "Enter Email" : "Geben Sie ihre E-Mail ein"}
          name="email"
          autoComplete="email"
          autoFocus
          style={{
            marginBottom: "10px",
            color: "#fff",
            border: "1px solid white",
          }}
          InputProps={{
            className: classes.multilineColor,
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder={
            isEnglish ? "Choose Password" : "Wählen Sie ihr Passwort"
          }
          name="password"
          type="password"
          id="password"
          color={"#fff"}
          autoComplete="current-password"
          style={{
            marginBottom: "10px",
            color: "#fff",
            border: "1px solid white",
          }}
          InputProps={{
            className: classes.multilineColor,
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div style={{ marginTop: "10px" }}>
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                value={rememberMe}
                control={<Radio style={{ color: "#2D4764" }} />}
                onChange={handleChange}
                label={
                  isEnglish ? "Remember Password" : "Passwort merken in Login"
                }
                className={classes.radioLabel}
              />
            </Grid>
            <Grid item style={{ marginTop: "10px" }}>
              <div className={classes.links}>
                {isEnglish ? "Forgot Password" : "Passwort vergessen"}
              </div>
            </Grid>
          </Grid>
        </div>
        <Button
          fullWidth
          variant="contained"
          className={classes.loginBtn}
          onClick={(e) => signUpWithEmailPassword(e)}
        >
          Jetzt Registrieren
        </Button>

        <div className={classes.btnContainer}>
          <Button
            fullWidth
            variant="contained"
            startIcon={
              <img style={{ marginRight: "10px" }} src={Google} alt="" />
            }
            className={classes.whiteButton}
            onClick={(e) => continueWithGoogle("google.com")}
          >
            {isEnglish ? "Login with Google" : "Einloggen mit Google"}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export const UserLoginForm = ({ isEnglish, project, onAutomateiclySave }) => {
  const classes = styles();
  const [email, setEmail] = useState();
  const history = useHistory();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = React.useState("");
  const { save } = useProjectTools();
  const user = useSelector((state) => state.user);

  const handleChange = (event) => {
    setRememberMe(event.target.value);
  };

  const logInWithEmailPassword = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        if (onAutomateiclySave) {
          save(project, userCredential.user.uid);
        }
      })
      .catch((error) => alert(error.message));
  };

  const continueWithGoogle = (provider) => {
    if (provider === "google.com") {
      provider = new firebase.auth.GoogleAuthProvider();
    } else {
      provider = new firebase.auth.OAuthProvider(provider);
    }

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        let user = result.user;
        const userDetails = {
          fullName: user?.displayName,
          email: user?.email,
          otherDetails: "other details",
          uid: user.uid,
        };
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (user?.currentUser) {
      history.push("/admin");
    }
  }, [user]);

  return (
    <Container maxWidth="xs">
      <div style={{ marginTop: "0px", color: "#fff" }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder="Email"
          name="email"
          autoComplete="email"
          autoFocus
          style={{
            marginBottom: "10px",
            color: "#fff",
            border: "1px solid white",
          }}
          InputProps={{
            className: classes.multilineColor,
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="Password"
          name="password"
          type="password"
          id="password"
          color={"#fff"}
          autoComplete="current-password"
          style={{
            marginBottom: "10px",
            color: "#fff",
            border: "1px solid white",
          }}
          InputProps={{
            className: classes.multilineColor,
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div style={{ marginTop: "10px" }}>
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                value={rememberMe}
                control={<Radio style={{ color: "#2D4764" }} />}
                onChange={handleChange}
                label={
                  isEnglish ? "Remember Password" : "Passwort merken in Login"
                }
                className={classes.radioLabel}
              />
            </Grid>
            <Grid item style={{ marginTop: "10px" }}>
              <div className={classes.links}>
                {isEnglish ? "Forgot Password" : "Passwort vergessen"}
              </div>
            </Grid>
          </Grid>
        </div>
        <Button
          fullWidth
          variant="contained"
          className={classes.loginBtn}
          onClick={(e) => logInWithEmailPassword(e)}
        >
          Jetzt anmelden
        </Button>
        <div className={classes.btnContainer}>
          <Button
            fullWidth
            variant="contained"
            startIcon={
              <img style={{ marginRight: "10px" }} src={Google} alt="" />
            }
            className={classes.whiteButton}
            onClick={(e) => continueWithGoogle("google.com")}
          >
            {isEnglish ? "Login with Google" : "Einloggen mit Google"}
          </Button>
        </div>
      </div>
    </Container>
  );
};
