import React, { useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  TextField,
} from "@material-ui/core";
import Heater from "../../../Assets/Heater.png";
import man from "../../../Assets/man.png";
import calendar from "Assets/calendar.svg";
import { YearlyConsumptionInput } from "./Company";
import setCommaDot, { commaDotHandler, parseLocaleNumber } from "helperMethod";
import { calculated_consumption_b2b } from "utils/consumption";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    maxHeight: 345,
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  option_container: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input_main: {
    height: "130px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  },
  button: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input_kelometer: {
    display: "flex",
    color: "#2D4764",
    width: "100%",
    marginBottom: "20px",
    marginTop: "20px",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
}));
const Image = (props) => {
  return (
    <>
      <img src={props.src} alt="" />
      <div
        style={{
          marginTop: "2px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#2D4764",
          fontSize: "14px",
        }}
      >
        {props.text}
      </div>
    </>
  );
};

const HeatPump = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption_b2b(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );
  const classes = useStyles();
  return (
    <div>
      <h4 className={classes.center} style={{ marginTop: "31px" }}>
        {isEnglish
          ? "Are you planning to use an electric heatpump to optimize your energy usage?"
          : "Planst du eine Wärmepumpe in deinem Haus einzusetzen um den PV Strom auch für die Heizung nutzbar zu machen?"}
      </h4>
      <div className={classes.option_container}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={consumption_details.heatPumpType === "type 1"}
                  onChange={(checked) => {
                    setConsumptionDetails({
                      ...consumption_details,
                      heatPumpType: "type 1",
                    });
                    setTemp1(
                      calculated_consumption_b2b({
                        ...consumption_details,
                        heatPumpType: "type 1",
                      })
                    );
                  }}
                />
              }
              label={
                <Image
                  src={Heater}
                  text={isEnglish ? "Installed" : "Eingerichtet"}
                />
              }
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={consumption_details.heatPumpType === "type 2"}
                  onChange={(checked) => {
                    setConsumptionDetails({
                      ...consumption_details,
                      heatPumpType: "type 2",
                    });
                    setTemp1(
                      calculated_consumption_b2b({
                        ...consumption_details,
                        heatPumpType: "type 2",
                      })
                    );
                  }}
                />
              }
              label={
                <Image
                  src={Heater}
                  style={{ marginLeft: "12px" }}
                  text={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "21px",
                        marginTop: "4px",
                      }}
                    >
                      <img src={calendar} />
                      <p
                        style={{
                          marginTop: "8px",
                          marginLeft: "3px",
                        }}
                      >
                        planned
                      </p>
                    </div>
                  }
                />
              }
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={consumption_details.heatPumpType === "type 3"}
                  onChange={(checked) => {
                    setConsumptionDetails({
                      ...consumption_details,
                      heatPumpType: "type 3",
                    });
                    setTemp1(
                      calculated_consumption_b2b({
                        ...consumption_details,
                        heatPumpType: "type 3",
                      })
                    );
                  }}
                />
              }
              label={
                <Image
                  src={man}
                  text={isEnglish ? "Not Planned" : "Nicht geplant"}
                />
              }
              labelPlacement="top"
            />{" "}
          </FormGroup>
        </FormControl>
      </div>
      <div className={classes.input_kelometer}>
        <h4 className={classes.center}>
          {isEnglish
            ? "Square meters of heated surface"
            : "Quadratmeter beheizte Fläche"}
        </h4>
        <TextField
          inputProps={{
            min: 0,
            style: { textAlign: "center", width: "100px" },
          }}
          placeholder="10,000 kms"
          value={`${consumption_details?.surface_area}`}
          onChange={(e) => {
            setConsumptionDetails({
              ...consumption_details,
              surface_area: e.target.value,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                surface_area: e.target.value,
              })
            );
          }}
        />
      </div>
      <YearlyConsumptionInput
        consumption={consumption}
        setConsumption={setConsumption}
        temp1={temp1}
        setTemp1={setTemp1}
      />
    </div>
  );
};

export default HeatPump;
