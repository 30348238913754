import React, { useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../colors";
import { ClipLoader } from "react-spinners";
import WelcomeUserAddreesSelectedAndFound from "../../../popups/welcomUser/WelcomeUser";
import { useDispatch, useSelector } from "react-redux";
import WelcomeUserAddressSelectedButNotFound from "popups/WelcomeUserNoData/WelcomeUserNoData";
import WelcomeUserNoAddressSelected from "popups/welcomeUserNoAddress/WecomeUserNoAddress";
import { useHistory } from "react-router-dom";
import CountDown from "./CountDown";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.lightYellow,
    margin: "-10px 0px 20px 0px",
    padding: "5px 25px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "96%",
      margin: "auto",
      marginTop: "0px",
      marginBottom: "18px",
    },
  },
  select_an_address: {
    color: "#4D7E6D",
    fontWeight: "bold",
    "&:hover": {
      color: colors.darkBlue,
    },
  },
  moveToLeft: {
    paddingLeft: "3px",
  },
}));

let prevTimeLeft = 20;
function Status({ isEnglish, quote, allProjets, project, isComingFromAdmin }) {
  const classes = useStyle();
  const [timeLeft, setTimeLeft] = useState(20);

  const [
    welcomeUserAddressSelectedAndFound,
    setWelcomeUserAddressSelectedAndFound,
  ] = useState(false);
  const [
    welcomeUserAddressSelectedButNotFound,
    setWelcomeUserAddressSelectedButNotFound,
  ] = useState(false);
  const [welcomeUserNoAddressSelected, setWelcomeUserNoAddressSelected] =
    useState(false);
  const quoteStatus = useSelector((state) => state.project.quoteStatus);
  const isNewUser = useSelector((state) => state.project.isNewUser);
  const currentUser = useSelector((state) => state.user.currentUser);
  const history = useHistory();

  useEffect(() => {
    console.log(quoteStatus, "quoteStatus");
    if (currentUser !== null) {
      //current user is there
      switch (quoteStatus) {
        case "default":
          if (
            isNewUser &&
            !history.location.pathname.includes("default") &&
            !history.location.pathname.includes("saved") &&
            !history.location.pathname.includes("custom")
          ) {
            return setWelcomeUserNoAddressSelected(true);
          } else {
            return setWelcomeUserNoAddressSelected(false);
          }
        case "found":
          if (
            history.location.pathname.includes("saved") ||
            history.location.pathname.includes("custom")
          ) {
            return setWelcomeUserAddressSelectedAndFound(false);
          } else {
            if (isNewUser) {
              console.log("yes this is true");
              return setWelcomeUserAddressSelectedAndFound(true);
            }
          }
        case "notFound":
          return setWelcomeUserAddressSelectedButNotFound(false);
        default:
          return 0;
      }
    } else {
      //current user is not there
      if (isNewUser) {
        switch (quoteStatus) {
          case "default":
            if (
              history.location.pathname.includes("default") ||
              history.location.pathname.includes("saved") ||
              history.location.pathname.includes("custom")
            ) {
              return setWelcomeUserNoAddressSelected(false);
            } else {
              return setWelcomeUserNoAddressSelected(true);
            }
          case "found":
            return setWelcomeUserAddressSelectedAndFound(true);
          case "notFound":
            return setWelcomeUserAddressSelectedButNotFound(true);
          default:
            return 0;
        }
      }
    }
  }, [quoteStatus, currentUser]);

  // useEffect(() => {
  //   setInterval(() => {
  //     prevTimeLeft =
  //       20 -
  //       Math.round(
  //         ((Date.now() - (quote.projectRequestTime % 86400000)) % 3600000) /
  //           60000
  //       );
  //     setTimeLeft(
  //       20 -
  //         Math.round(
  //           ((Date.now() - (quote.projectRequestTime % 86400000)) % 3600000) /
  //             60000
  //         )
  //     );
  //   }, 3000);
  // }, [quote]);

  const onClickHandler = () => {
    setWelcomeUserNoAddressSelected(true);
  };

  return (
    <div>
      <Card
        className={classes.root}
        style={{
          margin:
            window.innerWidth <= 500
              ? " "
              : isEnglish
              ? "-10px 0px 20px 0px"
              : "-10px 0px 20px 56px",
          width: window.innerWidth <= 500 ? "" : isEnglish ? " " : "95%",
        }}
      >
        {quoteStatus === "default" && (
          <>
            {!isEnglish ? (
              <span>
                Sie haben noch keine Adresse ausgewählt.{" "}
                <a
                  className={classes.select_an_address}
                  onClick={() => onClickHandler()}
                >
                  Klicken Sie hier und wählen Sie eine Adresse{" "}
                </a>
                oder probieren Sie es mit den Beispieldaten.
              </span>
            ) : (
              <span>
                You haven't selected any address yet.{" "}
                <a
                  className={classes.select_an_address}
                  onClick={() => onClickHandler()}
                >
                  Click here to select an address{" "}
                </a>
                or play around with sample data
              </span>
            )}
          </>
        )}
        {quoteStatus === null && (
          <>
            {!isEnglish ? (
              <span>
                Sie haben noch keine Adresse ausgewählt.{" "}
                <a
                  className={classes.select_an_address}
                  onClick={() => onClickHandler()}
                >
                  Klicken Sie hier und wählen Sie eine Adresse{" "}
                </a>
                oder probieren Sie es mit den Beispieldaten.
              </span>
            ) : (
              <span>
                You haven't selected any address yet.{" "}
                <a
                  className={classes.select_an_address}
                  onClick={() => onClickHandler()}
                >
                  Click here to select an address{" "}
                </a>
                or play around with sample data
              </span>
            )}
          </>
        )}
        {quoteStatus === "notFound" && (
          <>
            <span style={{ margin: "10px", paddingTop: "5px" }}>
              <ClipLoader color={colors.orangeSolid} loading={true} size={15} />
            </span>
            {isEnglish ? (
              <>
                We are caluclating your project - in about{" "}
                <strong>
                  <CountDown />
                </strong>{" "}
                you will receive detailed plan - play around with this sample
                data
              </>
            ) : (
              <>
                Wir kalkulieren ihr Projekt - in ca.{" "}
                <strong>
                  <CountDown />
                </strong>{" "}
                Minuten werden sie einen detaillierten Plan empfangen - spielen
                Sie mit den Beispieldaten
              </>
            )}
          </>
        )}
        {quoteStatus === "found" && (
          <>
            {isEnglish ? (
              <>
                {" "}
                Showing project for{" "}
                <strong className={classes.moveToLeft}>
                  {project?.address?.complete}
                </strong>
              </>
            ) : (
              <>
                Zeigt das Projekt für{" "}
                <strong className={classes.moveToLeft}>
                  {project?.address?.complete}
                </strong>
              </>
            )}
          </>
        )}
        {quoteStatus === "matched" && (
          <>
            {isEnglish ? (
              <>
                {" "}
                Showing project for{" "}
                <strong className={classes.moveToLeft}>
                  {project?.address?.complete}
                </strong>
              </>
            ) : (
              <>
                Zeigt das Projekt für{" "}
                <strong className={classes.moveToLeft}>
                  {project?.address?.complete}
                </strong>
              </>
            )}
          </>
        )}
      </Card>
      {isComingFromAdmin ? (
        " "
      ) : (
        <>
          <WelcomeUserAddreesSelectedAndFound
            open={welcomeUserAddressSelectedAndFound}
            setOpen={setWelcomeUserAddressSelectedAndFound}
          />
          <WelcomeUserAddressSelectedButNotFound
            open={welcomeUserAddressSelectedButNotFound}
            setOpen={setWelcomeUserAddressSelectedButNotFound}
          />
          <WelcomeUserNoAddressSelected
            open={welcomeUserNoAddressSelected}
            setOpen={setWelcomeUserNoAddressSelected}
          />
        </>
      )}
    </div>
  );
}

export default Status;
