/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import Dashboards from "pages/AdminPanel/partials/admin.dashboard";
import Products from "pages/AdminPanel/partials/admin.products";
import SavedProject from "pages/AdminPanel/partials/admin.savedProject";
import Projects from "pages/AdminPanel/partials/admin.projects";
import Constant from "pages/AdminPanel/partials/admin.constant";
import Vendors from "pages/AdminPanel/partials/admin.vendors";
import Hubspot from "pages/AdminPanel/partials/admin.hubspot";
import Users from "pages/AdminPanel/partials/admin.users";
import QrnDetails from "pages/AdminPanel/partials/admin.crndetails";
import KnowledgeHub from "pages/AdminPanel/partials/admin.knowledgehub";
import FAQs from "./partials/admin.faqs";
import customerInstaller from "./partials/admin.userInstaller";
import webinar from "./partials/admin.webinars";
import WebinarDetails from "./partials/admin.webinarDetails";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    germanName: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Dashboards,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Profile",
    germanName: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "products",
    germanName: "products",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/SavedProject",
    name: "saved projects",
    germanName: "saved projects",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: SavedProject,
    layout: "/admin",
  },
  {
    path: "/Projects",
    name: "Quotes",
    germanName: "Quotes",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Projects,
    layout: "/admin",
  },
  {
    path: "/Constant",
    name: "constants",
    rtlName: "خرائط",
    germanName: "constants",
    icon: LocationOn,
    component: Constant,
    layout: "/admin",
  },
  {
    path: "/Vendors",
    name: "installers",
    germanName: "installers",
    rtlName: "إخطارات",
    icon: Notifications,
    component: Vendors,
    layout: "/admin",
  },
  {
    path: "/Hubspot",
    name: "Hubspot",
    germanName: "Hubspot",
    rtlName: "إخطارات",
    icon: Notifications,
    component: Hubspot,
    layout: "/admin",
  },
  {
    path: "/webinarDetails",
    name: "WebinarDetails",
    germanName: "WebinarDetails",
    rtlName: "إخطارات",
    icon: Notifications,
    component: WebinarDetails,
    layout: "/admin",
  },
  {
    path: "/CrnDetails",
    name: "crn details",
    rtlName: "إخطارات",
    germanName: "crn details",
    icon: Notifications,
    component: QrnDetails,
    layout: "/admin",
  },
  {
    path: "/KnowledgeHub",
    name: "knowledge hub",
    rtlName: "إخطارات",
    germanName: "knowledge hub",
    icon: Notifications,
    component: KnowledgeHub,
    layout: "/admin",
  },
  {
    path: "/faqs",
    name: "FAQs",
    rtlName: "إخطارات",
    icon: Notifications,
    germanName: "FAQs",
    component: FAQs,
    layout: "/admin",
  },
  {
    path: "/customerInstaller",
    name: "customerInstaller",
    rtlName: "إخطارات",
    icon: Notifications,
    germanName: "customerInstaller",
    component: customerInstaller,
    layout: "/admin",
  },
  {
    path: "/webinar",
    name: "webinar",
    rtlName: "إخطارات",
    icon: Notifications,
    germanName: "webinar",
    component: webinar,
    layout: "/admin",
  },
];

export default dashboardRoutes;
