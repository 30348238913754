import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Rate } from "antd";
import { useSelector } from "react-redux";

const Payment = () => {
  const [star, setStar] = useState(4.5);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        height: "200px",
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
          width: "83%",
          height: "auto",
          justifyContent: "flex-start",
        },
        margin: "auto",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      rool: {
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
      h1: {
        fontFamily: "Nunito",
        fontWeight: "700",
        marginBottom: "3px",
        [theme.breakpoints.down("xs")]: {
          fontSize: isEnglish ? "20px" : "20px",
          textAlign: "center",
        },
        fontSize: "36px",
        color: "#304864",
      },
      h3: {
        fontFamily: "Nunito",
        fontWeight: "600",
        fontSize: "25px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "15px",
          textAlign: "center",
        },
        marginBottom: "7px",
        color: "#304864",
      },
      roof: {
        fontFamily: "Nunito",
        fontWeight: "400",
        marginBottom: "7px",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          fontSize: "14px",
        },
        fontSize: "22px",
        color: "#4D7E6D",
      },
      toCenter: {
        margin: "auto",
        [theme.breakpoints.down("xs")]: {
          width: "auto",
          textAlign: "center",
        },
      },
      mainRoof: {
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
    })
  );
  const classes = useStyles();
  const handleOnChage = (value) => {
    setStar(value);
  };
  return (
    <div className={classes.root}>
      <h1 className={classes.h1}>
        {isEnglish
          ? "Welcome to your new energy concept"
          : "Willkommen bei Ihrem Solarenergiekonzept"}
      </h1>
      <h3 className={classes.h3}>
        {isEnglish
          ? "We created a preliminary design for you - together we can make it happen!"
          : "Wir haben ein erstes PV Design für sie erstellt - zusammen können wir das Projekt umsetzen!"}
      </h3>
      <div className={classes.roof}>
        <div className={classes.mainRoof}>
          {isEnglish
            ? "Your roof is very qualified for PV! Configure your solar project now"
            : "Ihr Dach ist sehr gut geeignet für Solarenergie! Konfigurieren Sie nun ihr Solarprojekt weiter"}
        </div>
        <div
          className={classes.toCenter}
          style={{ marginLeft: window.innerWidth < 500 ? "auto" : "195px" }}
        >
          {isEnglish
            ? "- independently or with the help of our experts."
            : "- selbst oder mit der Hilfe eines unserer Experten."}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4
          style={{
            marginRight: "6px",
            fontFamily: "Nunito",
            fontWeight: "700",
            fontSize: "20px",
            marginTop: "13px",
          }}
        >
          {star}
        </h4>
        <Rate
          allowHalf
          defaultValue={4.5}
          style={{ color: "linear-gradient(180deg, #F4DF73 0%, #FFB803 100%)" }}
          value={star}
          onChange={handleOnChage}
        />
      </div>
    </div>
  );
};

export default Payment;
