import React, { useState, useEffect } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core/";
import { Checkbox } from "antd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import firebase, { db } from "../../../firebase";
import OBJECT__POPUP from "../components/popups/admin.installer.popup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "Components/loader";
import { colors } from "colors";
import { renderRatingEditInputCell } from "./DataGridColumns/commanFunction";
import { toast } from "react-toastify";

const Vendors = () => {
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState("");
  const [run, setRun] = useState(false);
  const [objectDetails, setObjectDetails] = useState({});
  const [selected_items, setselected_items] = useState([]);
  const [isNewObject, setIsNewObject] = useState(true);
  const isLoading = useSelector((state) => state.global.isLoading);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    db.collection("installers").onSnapshot((snap) => {
      const data = [];
      snap.forEach((doc) => {
        if (
          doc.data()?.installer_status === true ||
          doc.data()?.installer_status === false
        ) {
          data.push(doc.data());
          console.log(doc.data(), "data");
          console.log("hey");
        } else {
          data.push({ ...doc.data(), installer_status: false });
        }
      });
      dispatch({ type: "SET_LOADING", payload: false });
      setRowData(data);
    });
  }, [open, run]);
  console.log(rowData, "rowData");

  const BtnCellRenderer = (props) => {
    return (
      <DeleteIcon
        color="secondary"
        onClick={() => {
          firebase
            .database()
            .ref("websiteContent/objects/" + props.data.objectId)
            .remove();
        }}
      />
    );
  };
  const CompanyLogo = (props) => {
    return (
      <div
        style={{
          padding: "5px",
        }}
      >
        <img
          alt=""
          style={{
            width: "50px",
            height: "50px",

            objectFit: "cover",
          }}
          src={props?.data?.logo_url}
        />
      </div>
    );
  };

  const columns = [
    { field: "iid", width: 150 },
    {
      field: "installer_status",
      width: 100,
      renderCell: (params) => (
        <strong>
          {params.value}
          <Checkbox
            color="primary"
            size="small"
            checked={params.value}
            onChange={(e) => {
              const docRef = db.collection("installers").doc(params.id);
              docRef
                .get()
                .then((doc) => {
                  console.log(doc.data());
                  if (doc.exists) {
                    const updatedData = {
                      ...doc.data(),
                      installer_status: e.target.checked,
                    };
                    docRef.set(updatedData);
                    setRun(!run);
                    dispatch({ type: "SET_LOADING", payload: true });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              console.log(e.target.checked);
            }}
            style={{ marginLeft: 16 }}
          />
        </strong>
      ),
    },
    {
      field: "lat",
      editable: true,
      width: 110,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "lng",
      editable: true,
      width: 110,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "address",
      editable: true,
      width: 370,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "company_name",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "admin_rating", editable: true, width: 150 },
    { field: "geohash", editable: true, width: 150 },
    {
      field: "email",
      editable: true,
      width: 240,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "installer_uid", editable: true, width: 150 },
    { field: "is_registered", editable: true, width: 150 },
    { field: "last_update", editable: true, width: 150 },
    {
      field: "company_url",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "logo_url",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "telephone",
      editable: true,
      width: 240,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "user_rating", editable: true, width: 150 },
    {
      field: "website",
      editable: true,
      width: 160,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Installaliert seit",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Installationen_in",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Muttergesellschaft",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Lieferanten von Solarmodulen",
      editable: true,
      width: 500,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Netzanlagen/Inselanlagen",
      editable: true,
      width: 380,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Sonstiger Service",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Wechselrichter-Lieferanten",
      editable: true,
      width: 350,
      renderEditCell: renderRatingEditInputCell,
    },
  ];

  // other grid options ...

  const handleChangeRow = (values, e) => {
    const result = rowData.filter((data, index) => data.iid === values.id);
    const something = values.field;
    let updatedRow;
    if (something === "lat" || something === "lng") {
      let lat = result[0]?.geometry?.coordinates[0];
      let lng = result[0]?.geometry?.coordinates[1];
      if (something === "lat") {
        lat = values.value === undefined ? "" : parseFloat(values.value);
      } else if (something == "lng") {
        lng = values.value === undefined ? "" : parseFloat(values.value);
      }
      updatedRow = {
        ...result[0],
        geometry: {
          coordinates: [lat, lng],
        },
        [something]: values.value === undefined ? "" : parseFloat(values.value),
      };
    } else {
      updatedRow = {
        ...result[0],
        [something]: values.value === undefined ? "" : values.value,
      };
    }
    console.log(updatedRow, "updatedRow");
    //delete id--> newinstaller47e19e6d,newinstallerfecabe1b
    db.collection("installers")
      .doc(values?.id)
      .set({
        ...updatedRow,
      })
      .then(() => {
        console.log("changed");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChange = (value) => {
    setDataLimitCurrentPage(value);
  };

  const deleteSelectedInstallers = () => {
    if (selected_items.length) {
      selected_items.forEach((item) => {
        db.collection("installers")
          .doc(item)
          .delete()
          .then(() => {
            toast.success("Installer deleted sucessfully");
          })
          .catch((err) => console.log(err));
      });
    } else {
      alert("Select Items to Delete");
    }
  };

  return (
    <>
      <Accordion expanded={true} style={{ flex: "1", width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>installer Management</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flex: "1",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1",
                justifyContent: "flex-end",
                alignItems: "center",
                margin: "15px 30px",
                marginTop: "-10px",
              }}
            >
              <Button variant="contained" onClick={deleteSelectedInstallers}>
                Delete installer
              </Button>
              <Button variant="contained" onClick={handleClickOpen}>
                + Add installer
              </Button>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ minHeight: "65vh", width: "100%" }}
            >
              {rowData.length > 0 ? (
                <DataGrid
                  rows={rowData}
                  getRowId={(row) => row.iid}
                  columns={columns}
                  onCellEditCommit={(values, event) => {
                    handleChangeRow(values, event);
                  }}
                  pageSize={7}
                  onSelectionModelChange={(selection) => {
                    setselected_items(selection);
                  }}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <OBJECT__POPUP
            open={open}
            objectDetails={objectDetails}
            setObjectDetails={setObjectDetails}
            openMethod={setOpen}
            newObject={isNewObject}
            setIsNewObject={setIsNewObject}
          />
        </AccordionDetails>
        {isLoading ? (
          <Loader loading={isLoading} color={colors.orangeSolid} />
        ) : (
          ""
        )}
      </Accordion>
    </>
  );
};

export default Vendors;
