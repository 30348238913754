import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { db } from "../../../../firebase";
import { Autocomplete, TextField } from "@mui/material";
export default function SelectInstallerName({
  params,
  installerNames,
  setInstallerNames,
}) {
  const [val, setVal] = React.useState("");
  React.useEffect(() => {
    setVal(params?.formattedValue);
  }, []);
  const handleChange = (newValue) => {
    if (newValue === null) {
      return;
    }
    let obj = {
      ...params.row,
    };
    setVal(newValue);
    console.log(newValue, "newValue");
    db.collection("quotes")
      .doc(params.id)
      .set({ ...obj, installer_partner: newValue })
      .then(() => {
        console.log("saved");
      })
      .catch((err) => console.log("errr", err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        disablePortal
        value={val}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        id="combo-box-demo"
        options={installerNames}
        renderInput={(params) => (
          <TextField {...params} label="Choose installer partner" />
        )}
      />
    </div>
  );
}
