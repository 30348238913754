import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
const DateWrapper = styled("div")`
  .same_class {
    width: 370px;
    height: 40px;
    border: 1px solid #ffd75d;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    justify-content: center;
    cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
  }
  .hover_contaienr {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .new_class {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    border: 1px solid #2d4764;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .new_button {
    width: 180px;
    height: 40px;
    background: #ffd75d;
    border: 1px solid #ffd75d;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    :hover {
      background: #ffd75d;
    }
    text-transform: uppercase;
    color: #2d4764;
    border-radius: 4px;
  }
  .ant-btn {
    border: none;
  }
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87%;
    margin: auto;
  }
`;

const DateSide = ({ data, onSubmitHandler, loading }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <DateWrapper>
      {isHover ? (
        <div className="hover_contaienr" onMouseLeave={() => setIsHover(false)}>
          <div className="new_class">{data}</div>
          <Button
            loading={loading}
            className="new_button"
            onClick={() => onSubmitHandler(data)}
          >
            Confirm
          </Button>
        </div>
      ) : (
        <div
          className="same_class"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {data}
        </div>
      )}
    </DateWrapper>
  );
};

export default DateSide;
