import React from "react";

const ScheduleBal = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.86729 2.13402H10.1338C10.3244 2.13402 10.5005 2.03235 10.5957 1.86736C10.691 1.70236 10.691 1.49903 10.5957 1.33404C10.5005 1.16905 10.3244 1.06738 10.1338 1.06738H5.86729C5.6767 1.06738 5.50065 1.16905 5.40541 1.33404C5.31018 1.49904 5.31018 1.70237 5.40541 1.86736C5.50065 2.03235 5.67671 2.13402 5.86729 2.13402Z"
        fill="#666666"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.26574 3.1999C4.40716 3.1999 4.54288 3.14371 4.64287 3.04371C4.74287 2.94371 4.79906 2.808 4.79906 2.66658V0.53331C4.79906 0.34272 4.69739 0.166662 4.5324 0.0714264C4.3674 -0.0238088 4.16407 -0.0238088 3.99908 0.0714264C3.83409 0.166662 3.73242 0.342726 3.73242 0.53331V2.66658C3.73242 2.808 3.78861 2.94371 3.88861 3.04371C3.9886 3.14371 4.12432 3.1999 4.26574 3.1999Z"
        fill="#666666"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7325 3.1999C11.874 3.1999 12.0097 3.14371 12.1097 3.04371C12.2097 2.94371 12.2659 2.808 12.2659 2.66658V0.53331C12.2659 0.34272 12.1642 0.166662 11.9992 0.0714264C11.8342 -0.0238088 11.6309 -0.0238088 11.4659 0.0714264C11.3009 0.166662 11.1992 0.342726 11.1992 0.53331V2.66658C11.1992 2.808 11.2554 2.94371 11.3554 3.04371C11.4554 3.14371 11.5911 3.1999 11.7325 3.1999Z"
        fill="#666666"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5307 1.53618C15.2307 1.23607 14.8238 1.0675 14.3996 1.06738H13.3329C13.1423 1.06738 12.9663 1.16905 12.871 1.33404C12.7758 1.49904 12.7758 1.70237 12.871 1.86736C12.9663 2.03235 13.1423 2.13402 13.3329 2.13402H14.3996C14.541 2.13402 14.6767 2.19021 14.7767 2.2902C14.8767 2.3902 14.9329 2.52591 14.9329 2.66733V4.26729H12.7996C12.609 4.26729 12.433 4.36895 12.3377 4.53394C12.2425 4.69894 12.2425 4.90227 12.3377 5.06726C12.433 5.23225 12.609 5.33392 12.7996 5.33392H14.9329V14.4003C14.9329 14.5417 14.8767 14.6774 14.7767 14.7774C14.6767 14.8774 14.541 14.9336 14.3996 14.9336H1.59995C1.45853 14.9336 1.32282 14.8774 1.22282 14.7774C1.12282 14.6774 1.06663 14.5417 1.06663 14.4003V5.33392H10.6663C10.8569 5.33392 11.033 5.23226 11.1282 5.06726C11.2235 4.90227 11.2235 4.69894 11.1282 4.53394C11.033 4.36895 10.8569 4.26729 10.6663 4.26729H1.06663V2.66733C1.06663 2.52591 1.12282 2.3902 1.22282 2.2902C1.32282 2.19021 1.45853 2.13402 1.59995 2.13402H2.66659C2.85718 2.13402 3.03323 2.03235 3.12847 1.86736C3.2237 1.70236 3.2237 1.49903 3.12847 1.33404C3.03323 1.16905 2.85717 1.06738 2.66659 1.06738H1.59995C1.17555 1.06738 0.768678 1.23595 0.468557 1.53594C0.168565 1.83605 0 2.24294 0 2.66733V14.4003C0 14.8247 0.168565 15.2316 0.468557 15.5317C0.768669 15.8317 1.17555 16.0003 1.59995 16.0003H14.3996C14.824 16.0003 15.2308 15.8317 15.531 15.5317C15.831 15.2316 15.9995 14.8247 15.9995 14.4003V2.66733C15.9994 2.24306 15.8308 1.83618 15.5307 1.53618Z"
        fill="#666666"
      />
      <path
        d="M4.85593 7.40447C4.85593 7.79885 4.5363 8.11862 4.14189 8.11862C3.74748 8.11862 3.42773 7.79888 3.42773 7.40447C3.42773 7.01005 3.74748 6.69043 4.14189 6.69043C4.5363 6.69043 4.85593 7.01005 4.85593 7.40447Z"
        fill="#666666"
      />
      <path
        d="M4.85593 9.90459C4.85593 10.299 4.5363 10.6187 4.14189 10.6187C3.74748 10.6187 3.42773 10.299 3.42773 9.90459C3.42773 9.51021 3.74748 9.19043 4.14189 9.19043C4.5363 9.19043 4.85593 9.51018 4.85593 9.90459Z"
        fill="#666666"
      />
      <path
        d="M4.85593 12.4045C4.85593 12.7988 4.5363 13.1186 4.14189 13.1186C3.74748 13.1186 3.42773 12.7989 3.42773 12.4045C3.42773 12.0101 3.74748 11.6904 4.14189 11.6904C4.5363 11.6904 4.85593 12.0101 4.85593 12.4045Z"
        fill="#666666"
      />
      <path
        d="M7.42819 7.40447C7.42819 7.79885 7.10857 8.11862 6.71416 8.11862C6.31975 8.11862 6 7.79888 6 7.40447C6 7.01005 6.31975 6.69043 6.71416 6.69043C7.10857 6.69043 7.42819 7.01005 7.42819 7.40447Z"
        fill="#666666"
      />
      <path
        d="M7.42923 9.90407C7.42923 10.2988 7.10924 10.6187 6.71461 10.6187C6.31987 10.6187 6 10.2988 6 9.90407C6 9.50944 6.31987 9.18945 6.71461 9.18945C7.10924 9.18945 7.42923 9.50944 7.42923 9.90407Z"
        fill="#666666"
      />
      <path
        d="M7.42819 12.4045C7.42819 12.7988 7.10857 13.1186 6.71416 13.1186C6.31975 13.1186 6 12.7989 6 12.4045C6 12.0101 6.31975 11.6904 6.71416 11.6904C7.10857 11.6904 7.42819 12.0101 7.42819 12.4045Z"
        fill="#666666"
      />
      <path
        d="M9.9985 7.40447C9.9985 7.79885 9.67876 8.11862 9.28435 8.11862C8.88994 8.11862 8.57031 7.79888 8.57031 7.40447C8.57031 7.01005 8.88994 6.69043 9.28435 6.69043C9.67876 6.69043 9.9985 7.01005 9.9985 7.40447Z"
        fill="#666666"
      />
      <path
        d="M12.5708 7.40447C12.5708 7.79885 12.251 8.11862 11.8566 8.11862C11.4622 8.11862 11.1426 7.79888 11.1426 7.40447C11.1426 7.01005 11.4622 6.69043 11.8566 6.69043C12.251 6.69043 12.5708 7.01005 12.5708 7.40447Z"
        fill="#666666"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.81859 12.9379H11.3231C11.606 12.9379 11.8773 12.8255 12.0773 12.6255C12.2773 12.4255 12.3897 12.1542 12.3897 11.8713V10.4377C12.3897 10.1549 12.2773 9.88357 12.0773 9.68347C11.8773 9.48347 11.606 9.37109 11.3231 9.37109H9.81859C9.53574 9.37109 9.26443 9.48347 9.06445 9.68347C8.86433 9.88358 8.75195 10.1549 8.75195 10.4377V11.8713C8.75195 12.1542 8.86433 12.4255 9.06445 12.6255C9.26444 12.8255 9.53575 12.9379 9.81859 12.9379ZM9.81859 10.4377H11.3231V11.8713H9.81859V10.4377Z"
        fill="#666666"
      />
    </svg>
  );
};

export default ScheduleBal;
