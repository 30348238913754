import {
  Select,
  MenuItem,
  Button,
  Box,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import filters from "Assets/community/filters.svg";
import { db } from "../../../../../../firebase";
import SwaiperCard from "pages/Application/community/pages/home/partials/SwiperCard";
import { useSelector } from "react-redux";
import * as geofire from "geofire-common";
import * as turf from "@turf/turf";
import Forward1 from "Assets/community/Forward1.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./NearByProjects.css";
import SwiperCore, { Navigation } from "swiper";
import { Empty } from "antd";

SwiperCore.use([Navigation]);

const NearByProjectsWrapper = styled("div")`
  .filterConteinr {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    gap: 6px;
    margin-bottom: 18px;
    align-items: center;
  }
  .button_1 {
    background: #ffffff;
    height: 27px;
    width: 91px;
    border-radius: 16px;
    border: 1.4px solid #2d4764;
    cursor: pointer;
    border-radius: 16px;
  }
  .button_2 {
    background: #ffffff;
    height: 27px;
    width: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1.4px solid #2d4764;
    cursor: pointer;
    border-radius: 16px;
  }
  .select_class {
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #f9f9f9;
    height: 27px;
    width: 123px;
  }
  .circle_contaienr {
    background: #2d4764;
    height: 17px;
    width: 17px;
    top: -6px;
    position: absolute;
    right: -3px;
    font-family: Nunito;
    font-style: normal;
    justify-content: center;
    font-weight: bold;
    font-size: 10px;
    display: flex;
    align-items: center;
    color: #ffffff;
    border-radius: 50%;
  }
`;

const NearByProjects = () => {
  //selectors
  // const coordinates1 = useSelector(
  //   (state) => state.user?.geometry?.coordinates
  // );
  // const searchResult1 = useSelector((state) => state.user?.geometry);
  // const coordinates = useSelector(
  //   (state) => state.project?.address?.geometry?.coordinates
  // );
  // const searchResult = useSelector(
  //   (state) => state?.project?.address?.geometry
  // );
  // console.log(coordinates1, "coordinates1");
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const coordinates1 = JSON.parse(localStorage.getItem("coordinates1"));
  const searchResult1 = JSON.parse(localStorage.getItem("searchResult1"));
  const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  const searchResult = JSON.parse(localStorage.getItem("searchResult"));
  const [sliderData, setSliderData] = useState([]);
  const [filterSliderData, setFilterSliderData] = useState([]);
  const [noDataAvalible, setNoDataAvalible] = useState(false);
  const [filterValue, setFilterValue] = useState("Arched roof");
  const [foutPlusRating, setFoutPlusRating] = useState(false || null);
  const [isRoofTypeOn, setIsRoofTypeOne] = useState(false);
  console.log(isRoofTypeOn, "isRoofTypeOn");
  console.log(foutPlusRating, "foutPlusRating");
  // function for sorting data
  function compare(a, b) {
    if (a.distance < b.distance) {
      return -1;
    }
    if (a.distance > b.distance) {
      return 1;
    }
    return 0;
  }
  //helper functions
  const returnMatchedRealizesProjects = async () => {
    const result = await db
      .collection("matched")
      .where("is_project_realized", "==", true)
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data().pid);
        });
        return data;
      })
      .catch((error) => {
        return null;
      });
    return result;
  };

  const returnProjectComment = async (pid) => {
    const result = await db
      .collection("feedbacks")
      .where("pid", "==", pid)
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        return data;
      })
      .catch((error) => {
        return null;
      });
    return result;
  };

  const returnUserData = async (uid) => {
    const result = await db
      .collection("users")
      .where("uid", "==", uid)
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        return data;
      })
      .catch((error) => {
        return null;
      });
    return result;
  };

  const productName = (comingData, id) => {
    let news = [];
    for (const key in comingData) {
      for (const key1 in comingData[key].item) {
        if (key1 === "objectId") {
          if (comingData[key].item[key1] === id) {
            news.push(comingData[key].item);
          }
        }
      }
    }
    return news[0]?.name;
  };

  //useEffect for collecting near by project data
  useEffect(() => {
    const func = async () => {
      var options = { units: "kilometers" };
      let finalCoordinates;
      let finalSearchResult;
      if (coordinates) {
        finalCoordinates = coordinates;
        finalSearchResult = searchResult;
      } else {
        finalCoordinates = coordinates1;
        finalSearchResult = searchResult1;
      }
      if (finalCoordinates) {
        // const geohash = geofire.geohashForLocation([coordinates[0],coordinates[1]]);
        const center = [finalCoordinates[0], finalCoordinates[1]];
        const radiusInM = 336 * 100000;
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
          const q = db.collection("saved_projects");
          const q_ref = await q.orderBy("geohash").startAt(b[0]).endAt(b[1]);
          promises.push(q_ref.get());
        }
        const snapshots = await Promise.all(promises);
        const matchingDocs = [];
        const distance = [];
        for (const snap of snapshots) {
          //@ts-ignore
          for (const doc of snap.docs) {
            const lat = doc.get("lat");
            const lng = doc.get("long");
            if (lat && lng) {
              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const distanceInKm = geofire.distanceBetween(
                [parseFloat(lat), parseFloat(lng)],
                center
              );
              distance.push([distanceInKm * 1000, radiusInM]);
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radiusInM) {
                matchingDocs.push(doc);
              }
            }
          }
        }
        const data = [];
        matchingDocs.map((matchedDoc) => {
          data.push({
            ...matchedDoc.data(),
            distance: turf.distance(
              finalSearchResult,
              matchedDoc.data().project_details.address.geometry,
              options
            ),
          });
        });
        console.log(data, "datas");
        data.sort(compare);
        const result = await returnMatchedRealizesProjects();
        const finalResult = [];
        for (let i = 0; i < data.length; i++) {
          if (result.includes(data[i].pid)) {
            const commentResult = await returnProjectComment(data[i].pid);
            const userResult = await returnUserData(data[i].uid);
            // console.log(commentResult);
            if (
              commentResult === undefined ||
              commentResult === null ||
              commentResult.length === 0
            ) {
            } else {
              finalResult.push({
                projectData: data[i],
                userData: userResult[0],
                actualRatingData: commentResult[0],
              });
            }
          }
        }
        setSliderData(finalResult);
        setFilterSliderData(finalResult);
      }
    };

    func();
  }, []);

  const filterAcouridngToRoofType = (value) => {
    if (foutPlusRating) {
      const result = filterSliderData.filter((data, id) => {
        return data.projectData.updatedData.roof_type
          .toLowerCase()
          .replace(/ /g, "")
          .includes(value.toLowerCase().replace(/ /g, ""));
      });
      if (result.length) {
        setFilterSliderData(result);
        setNoDataAvalible(false);
      } else {
        setNoDataAvalible(true);
      }
    } else {
      const result = sliderData.filter((data, id) => {
        return data.projectData.updatedData.roof_type
          .toLowerCase()
          .replace(/ /g, "")
          .includes(value.toLowerCase().replace(/ /g, ""));
      });
      if (result.length) {
        setFilterSliderData(result);
        setNoDataAvalible(false);
      } else {
        setFilterSliderData(result);
        setNoDataAvalible(true);
      }
    }
  };

  const fourPlusRatingHandler = (value) => {
    if (value && isRoofTypeOn) {
      const result = filterSliderData.filter((data, id) => {
        return data.actualRatingData.rating > 4;
      });
      if (result.length) {
        setFilterSliderData(result);
        setNoDataAvalible(false);
      } else {
        setNoDataAvalible(true);
      }
    } else if (!value && isRoofTypeOn) {
      const result = sliderData.filter((data, id) => {
        return data.projectData.updatedData.roof_type
          .toLowerCase()
          .replace(/ /g, "")
          .includes(filterValue.toLowerCase().replace(/ /g, ""));
      });
      if (result.length) {
        setFilterSliderData(result);
        setNoDataAvalible(false);
      } else {
        setNoDataAvalible(true);
      }
    } else if (value && !isRoofTypeOn) {
      const result = sliderData.filter((data, id) => {
        return data.actualRatingData.rating > 4;
      });
      if (result.length) {
        setFilterSliderData(result);
        setNoDataAvalible(false);
      } else {
        setNoDataAvalible(true);
      }
    } else {
      setFilterSliderData(sliderData);
    }
  };

  const useStyles = makeStyles((theme) => ({
    rootFirstSelect: {
      padding: "0px 15px",
      fontSize: "14px",
    },
  }));

  console.log(filterSliderData, "let's see slider data");

  const classes = useStyles();

  return (
    <NearByProjectsWrapper>
      <div className="filterConteinr" style={{ width: "335px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          className="select_class"
          classes={{ root: classes.rootFirstSelect }}
          onChange={(e) => {
            setFilterValue(e.target.value);
            filterAcouridngToRoofType(e.target.value);
          }}
          IconComponent={() => (
            <img src={Forward1} style={{ marginRight: "10px" }} alt="" />
          )}
          disableUnderline
          value={filterValue}
          defaultValue="Arched roof"
          onClick={() => {
            setIsRoofTypeOne(true);
          }}
        >
          <MenuItem value="Folded plate roof">Folded plate roof</MenuItem>
          <MenuItem value="Trapezoidal Profiles">Trapezoidal Profiles</MenuItem>
          <MenuItem value="Gable roof">Gable roof</MenuItem>
          <MenuItem value="Hip roof">Hip roof</MenuItem>
          <MenuItem value="Tent roof">Tent roof</MenuItem>
          <MenuItem value="Arched roof">Arched roof</MenuItem>
          <MenuItem value="Flatroof">Flat roof</MenuItem>
          <MenuItem value="Single pitch roof">Single pitch roof</MenuItem>
          <MenuItem value="Half hipped roof">Half hipped roof</MenuItem>
          <MenuItem value="Wall dormer">Wall dormer</MenuItem>
        </Select>
        <button
          className="button_1"
          style={{
            border: foutPlusRating
              ? "1.4px solid #2d4764"
              : "1.4px solid #DFDFDF",
            width: isEnglish ? "91px" : "102px",
          }}
          onClick={() => {
            setFoutPlusRating(!foutPlusRating);
            fourPlusRatingHandler(!foutPlusRating);
          }}
        >
          {isEnglish ? "4+ rating" : "4+ "}
        </button>
        <div style={{ position: "relative" }}>
          <button className="button_2">
            <p style={{ marginBottom: "0px", marginRight: "10px" }}>Filters</p>
            <img src={filters} alt="" />
          </button>
          {filterSliderData.length > 0 ? (
            <div className="circle_contaienr">{filterSliderData.length}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      {filterSliderData.length > 0 && !noDataAvalible ? (
        <Swiper navigation={true} className="mySwiper">
          {filterSliderData.length > 0 &&
            filterSliderData.map((data, key) => {
              return (
                <SwiperSlide>
                  <SwaiperCard data={data} productName={productName} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      ) : noDataAvalible ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Box
          sx={{
            display: "flex",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "#fbda61",
            }}
          />
        </Box>
      )}
    </NearByProjectsWrapper>
  );
};

export default React.memo(NearByProjects);
