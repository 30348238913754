import React, { useState, useEffect } from "react";

import { CaretLeftOutlined } from "@ant-design/icons";
import { CaretRightOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  Paper,
  Tooltip,
  Divider,
  Avatar,
  withStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Transition } from "react-transition-group";
import CircularProgress from "@material-ui/core/CircularProgress";
//colors
import { colors } from "../../../colors";
import Question from "../../Application/partials/images/Question.svg";
import blackInfo from "Assets/blackInfo.svg";
//common-components
import ConsumptionProfileDialog from "../../../Components/consumptionProfile.dialog";
import ConsumptionProfileDialogB2B from "./../../../Components/consumptionProfileDialogb2b";

//pagewise-components
import Status from "../components/application.step1.status";
import ShareProjectDialog from "../components/application.step1.share";
import FinanceCharts from "../components/application.step1.financeCharts";
import BuyCharts from "../components/application.step1.buyCharts";

//methods
import { financingDetails } from "../../../utils/costs";
import { energyDetails } from "../../../utils/costs";
import { costsAndSavings } from "../../../utils/costs";
import { getNumber } from "../../../utils/costs";
import {
  calculated_consumption,
  consumption_profiles,
  calculated_consumption_b2b,
  consumption_profiles_b2b,
} from "../../../utils/consumption";

//data
import { roofPitchFactor } from "../../../lib/roofPitchFactor_db";

//images
import satellite from "./images/satellite.svg";
import threeD from "./images/threeD.svg";
import IncludedServices from "../../../popups/includedServices/IncludeServices";
import { commaDotHandler } from "helperMethod";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateInverterComponent } from "redux/project/project.actions";

const { ctaSolid } = colors;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90vw",
    maxWidth: "1200px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    "@media (max-width: 560px)": {
      fontSize: "14px",
    },
  },
  root1: {
    width: "90vw",
    maxWidth: "1200px",
    backgroundColor: "#fff",
    display: "flex",
    borderRadius: "10px",
    justifyContent: "space-around",
    "@media (max-width: 560px)": {
      flexDirection: "column",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    background:
      "linear-gradient(90deg, #FFC531 6.25%, rgba(255, 243, 202, 0) 100%)",
    borderRadius: "36px",
  },
  card1TopButton: {
    width: "33%",
    height: "30px",
    border: "1px solid #fbda61",
    borderRadius: "10px 10px 0 0",
    textAlign: "center",
    backgroundColor: "#fff3ca",
  },
  card1Image: {
    width: "100%",
    height: "300px",
    objectFit: "cover",
  },
  card2Loader: {
    width: "100%",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  c2_container: {
    width: "600px",
    minHeight: "564px",
    display: "flex",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
    flexDirection: "column",
    backgroundColor: "white",
    margin: "5px 0",
    marginBottom: "0px",
    borderRadius: "15px",
    "@media (max-width: 900px)": {
      width: "430px",
      margin: "auto",
    },
    "@media (max-width: 430px)": {
      width: "97%",
      minHeight: "521px",
      flexDirection: "column",
    },
  },
  mainClass: {
    width: "400px",
    margin: "15px 15px",
    marginBottom: "0px",
    "@media (max-width: 900px)": {
      margin: "auto",
      width: "430px",
      marginBottom: "30px",
    },
    "@media (max-width: 430px)": {
      width: "97%",
      flexDirection: "column",
    },
  },
  c2_header: {
    display: "flex",
    width: "100%",
    borderRadius: "15px",
    height: "30px",
    alignItems: "center",
  },
  c2_header_button: {
    position: "relative",
    zIndex: "0",
    flex: "1",
    width: "auto",
    textAlign: "center",
    background: "#FFF3CA",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    height: "100%",
    paddingTop: "5px",
    cursor: "pointer",
    marginTop: "-18px",
    marginBottom: "-3px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
    "&:hover": {
      fontWeight: "bold",
      backgroundColor: ctaSolid,
      transform: "scale(1.01)",
    },
  },
  c2_header_button_highlighted: {
    position: "relative",
    zIndex: "0",
    flex: "1",
    width: "auto",
    textAlign: "center",
    backgroundColor: ctaSolid,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    height: "100%",
    paddingTop: "5px",
    cursor: "pointer",
    marginTop: "-18px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
    marginBottom: "-3px",
    "&:hover": {
      fontWeight: "bold",
      transform: "scale(1.01)",
    },
  },
  c2_finace_container: {
    width: "100%",
  },
  c2_finace_container1: {
    width: "100%",
    "& div": {
      display: "flex",
      justifyContent: "space-between",
      margin: "30px 25px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        margin: "14px 25px",
      },
    },
    "&.MuiTypography-body1": {
      "@media (max-width: 430px)": {
        fontSize: "10px",
      },
    },
  },
  c2_finace_container2: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    "@media (max-width: 900px)": {
      justifyContent: "space-between",
    },
    "@media (max-width: 430px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "7px",
      marginLeft: "0px",
      color: "rgba(77, 126, 109, 1)",
    },
  },

  c2_benefits: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    height: "87px",
    boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      background:
        "linear-gradient(180deg, rgba(244, 223, 115, 0.1) 0%, rgba(255, 184, 3, 0.1) 100%)",
    },
    "@media (max-width: 430px)": {
      width: "88%",
      marginBottom: "5px",
      height: "60px",
      justifyContent: "flex-start",
      padding: "11px",
    },
  },

  c2_benefits_avatar: {
    alignSelf: "center",
    color: "#247D38",
    ".&MuiAvatar-img": {
      color: "#247D38",
    },
    "@media (max-width: 560px)": {
      ".&MuiAvatar-img": {
        color: "rgba(77, 126, 109, 1)",
      },
    },
  },
  c2_benefits_text: {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 430px)": {
      flexDirection: "row",
      justifyContent: "center",
      marginLeft: "8px",
      alignItems: "center",
    },
    marginLeft: "15px",
  },
  c2_benefits_text1: {
    color: colors.orangeSolid,
    fontWeight: "800",
    fontSize: "22px",
    "@media (max-width: 560px)": {
      color: "rgba(77, 126, 109, 1)",
      fontWeight: "800",
      fontSize: "20px",
    },
  },
  familyButton: {
    backgroundColor: "#fff",
    color: "black",
    borderRadius: "25px",
    fontSize: "15px",
    "@media (max-width: 560px)": {
      fontSize: "10px",
      width: "auto",
    },
    "&:hover": {
      background:
        "linear-gradient(180deg, rgba(244, 223, 115, 0.1) 0%, rgba(255, 184, 3, 0.1) 100%)",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
    },
    alignSelf: "center",
    height: "30px",
    border: `1px solid #E2E2E2`,
  },
  familyButton1: {
    backgroundColor: "#fff",
    color: "black",
    borderRadius: "25px",
    fontSize: "15px",
    "@media (max-width: 560px)": {
      width: "auto",
      fontSize: "14px",
      paddingLeft: "14px",
      paddingRight: "20px",
    },
    "&:hover": {
      background:
        "linear-gradient(180deg, rgba(244, 223, 115, 0.1) 0%, rgba(255, 184, 3, 0.1) 100%)",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
    },
    alignSelf: "center",
    height: "30px",
    border: `1px solid #E2E2E2`,
  },
  familyIcon: {
    color: "#304864",
    margin: "0 5px",
    fontSize: "15px",
    "@media (max-width: 560px)": {
      fontSize: "12px",
    },
  },
  addedNewButtons: {
    height: "119px",
    width: "100%",
    display: "flex",
    marginTop: "6px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  makeButton1: {
    display: "none",
    "@media (max-width: 430px)": {
      display: "block",
      width: "274px",
      height: "30px",
      fontSize: "12px",
      borderRadius: "36px",
      backgroundColor: "rgba(255, 194, 32, 1)",
    },
  },
  makeButton2: {
    display: "none",
    "@media (max-width: 430px)": {
      width: "274px",
      height: "30px",
      fontSize: "12px",
      borderRadius: "36px",
      backgroundColor: "rgba(255, 194, 32, 1)",
      display: "block",
    },
  },
  c2_benefits_text2: {
    fontSize: "12px",
    textTransform: "uppercase",
    width: "102px",
    "@media (max-width: 430px)": {
      marginLeft: "15px",
      fontSize: "14px",
      height: "100%",
      width: "100%",
      color: "#111111",
      fontWeight: "100",
    },
  },
  helperButton1: {
    backgroundColor: "rgba(255, 215, 93, 1)",
    color: `${colors.darkBlue}`,
    borderRadius: "25px",
    alignSelf: "center",
    fontWeight: "bold",
    height: "40px",
    border: "none",
    width: "450px",
    margin: "25px 25px 0px 150px",
    "@media (max-width: 560px)": {
      margin: "5px 12px",
      fontSize: "10px",
      width: "350px",
      height: "38px",
    },
    "&:hover": {
      transform: "scale(1.01)",
      backgroundColor: "rgba(255, 215, 93, 1)",
    },
  },
  helperButton2: {
    backgroundColor: "#fff",
    color: colors.darkBlue,
    borderRadius: "25px",
    fontWeight: "bold",
    alignSelf: "center",
    height: "40px",
    margin: "0px 25px 15px 150px",
    width: "450px",
    "@media (max-width: 560px)": {
      margin: "5px 12px",
      fontSize: "10px",
      width: "350px",
      height: "38px",
    },
    "&:hover": {
      backgroundColor: colors.darkBlue,
      color: "#fff",
    },
    border: `1px solid ${colors.darkBlue}`,
  },
  c2_calculation_button: {
    border: `1px solid ${colors.darkBlue}`,
    width: "350px",
    height: "35px",
    textAlign: "center",
    paddingTop: "10px",
    borderRadius: "50px",
    cursor: "pointer",
    alignSelf: "center",
    marginLeft: "120px",
    marginTop: "35px",
  },
  helperContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    marginBottom: "10px",
    "@media (max-width: 560px)": {
      width: "90%",
      margin: "auto",
    },
  },
  helperIcon: {
    fontSize: "25px",
    marginLeft: "15px",
    "@media (max-width: 560px)": {
      marginLeft: "0px",
    },
  },
  hoverClass: {
    "&:hover": {
      backgroundColor: "lightyellow",
      transform: "scale(1.01)",
    },
  },
  fontClass: {
    "@media (max-width: 560px)": {
      fontSize: "14px",
    },
    "&.MuiSelect-select.MuiSelect-select": {
      padding: "0px",
    },
  },
  fontClass3: {
    "@media (max-width: 560px)": {
      fontSize: "14px",
      paddingTop: "14px",
    },
  },
  plus_icon: {
    height: "100%",
    width: "20px",
    color: `${colors.darkBlue}`,
    backgroundColor: "#DFDFDF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 560px)": {
      width: "28px",
      height: "110%",
    },
    textAlign: "center",
    border: "1px solid lightgray",
    borderRadius: "4px 0px 0px 4px",
    mouse: "pointer",
    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${colors.darkBlue}`,
    },
    userSelect: "none",
  },
  plus_icon1: {
    height: "100%",
    width: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: `${colors.darkBlue}`,
    backgroundColor: "#DFDFDF",
    "@media (max-width: 560px)": {
      width: "28px",
      height: "110%",
    },
    textAlign: "center",
    border: "1px solid lightgray",
    borderRadius: "0px 4px 4px 0px",
    mouse: "pointer",
    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${colors.darkBlue}`,
    },
    userSelect: "none",
  },
  c1_container: {
    width: "100%",
    height: "564px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    "@media (max-width: 560px)": {
      marginTop: "10px",
    },
  },
  c1_container_header: { width: "100%", display: "flex", border: "none" },
  ThirdHoverClass: {
    width: "95%",
    margin: "auto",
    display: "flex",
    border: "1px solid #E2E2E2",
    fontWeight: "bold",
    transition: "all 250ms linear 2s",
    marginTop: "10px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "90px",
  },
  newCladd: {
    "&.MuiSvgIcon-root MuiSelect-icon": {
      color: "blue",
    },
  },
  roofPitch: {
    width: "50%",
    textAlign: "right",
    paddingRight: "26px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Solarmodules: {
    width: "50%",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    paddingRight: "3px",
  },
  panelCapacity: {
    width: "50%",
    textAlign: "right",
    paddingRight: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  totalCapacity: {
    width: "50%",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    paddingRight: "21px",
    paddingLeft: "20px",
  },
  forMakingSmallRoofPitch: {
    width: "91px",
    "@media (max-width: 560px)": {
      width: "108px",
    },
    textAlign: "center",
    height: "28px",
    paddingLeft: "20px",
    borderRadius: "4px",
    border: "1px solid #2D4764",
  },
  roofSelector: {
    height: "35px",
    marginLeft: "25px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      height: "27px",
    },
  },
  inputStyle: {
    outline: "none",
    "@media (max-width: 560px)": {
      height: "110%",
    },
    boxShadow: "none",
    height: "100%",
    width: "50px",
    textAlign: "center",
    border: "1px solid lightgray",
  },
  left_button: {
    color: "white",
    zIndex: 2,
    fontSize: 28,
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  right_button: {
    color: "white",
    zIndex: 2,
    fontSize: 28,
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  forIphone: {
    width: "auto",
    "@media (max-width: 326px)": {
      width: "284px",
    },
  },
  // className="text-white z-2 text-2xl absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer"
  // className="text-white z-2 text-2xl absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer"
}));

function Beneficiaries(props) {
  const {
    includedServicesDialog,
    setIncludedServicesDialog,
    setYearlyConsumptionDialog,
    isModalVisible,
    setIsModalVisible,
    scrollToBottom,
    isComingFromAdmin,
    currentUser,
    text,
    setText,
    isEnglish,
    project,
    dispatchMethods,
    allProjets,
    quote,
  } = props;

  const {
    increaseSolarPanel,
    decreaseSolarPanel,
    setConsumption,
    setLoading,
    setConsumptionDetails,
    setRoofPitch,
    setProfile,
  } = dispatchMethods;

  const classes = useStyles();

  const [showChart, setShowChart] = useState(false);
  const [consumptionProfileDialog, setConsumptionProfileDialog] =
    useState(false);
  const [financing_details, set_financing_details] = useState({});
  const [energy_details, set_energy_details] = useState({});
  const [costs_and_savings, set_costs_and_savings] = useState({});
  const [shareProjectDialog, setShareProjectDialog] = useState(false);
  let solar_panel_quantity = project?.components?.items?.solar_panel?.quantity;
  const [solarpanel, setSolarPanel] = useState(solar_panel_quantity);
  const [sharedURL, setSharedURL] = useState("");
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const isInverterDone = useSelector((state) => state.global.isInverterDone);
  const setIsFinancing = (data) =>
    dispatch({
      type: "SET_PROJECT",
      payload: { ...project, isFinancing: data },
    });

  const solar_panel_capacity =
    project?.components?.items?.solar_panel?.item?.capacity;
  const [consumption, setConsumption1] = useState("");
  const [current_image, setcurrent_image] = useState(0);

  const profile = project?.profile;
  const isFInancing = project?.isFinancing;
  const res = String(window.location.href);
  const isUserSelectingAdress = useSelector(
    (state) => state.global.isUserSelectingAdress
  );
  const history = useHistory();

  useEffect(() => {
    setSolarPanel(solar_panel_quantity);
  }, [solar_panel_quantity]);

  useEffect(() => {
    if (!window.location.pathname.includes("saved") && isInverterDone) {
      dispatch(updateInverterComponent(project));
    }
  }, [solar_panel_capacity, solar_panel_quantity]);

  useEffect(() => {
    if (res.includes("saved") || res.includes("default")) {
      if (
        project?.quoteStatus === "found" ||
        project?.quoteStatus === "matched" ||
        project?.quoteStatus === "notFound"
      )
        setLoader(false);
      dispatch({ type: "SET_USER_SELECTING_ADDRESS", payload: false });
    } else {
      setLoader(false);
    }
  }, [project, history.pathname]);

  useEffect(() => {
    if (isUserSelectingAdress) {
      setLoader(true);
    }
  }, [isUserSelectingAdress]);

  useEffect(() => {
    if (project?.project_type === "b2b") {
      setConsumption1(
        Math.round(calculated_consumption_b2b(project?.consumption_details_b2b))
      );
    } else {
      setConsumption1(
        Math.round(calculated_consumption(project?.consumption_details))
      );
    }
  }, [project]);

  useEffect(() => {
    set_financing_details(financingDetails(project));
    set_energy_details(energyDetails(project));
    set_costs_and_savings(costsAndSavings(project));
  }, [project]);

  //method to change consumption profile
  const modifyProfile = (data) => {
    setProfile({
      profile: data,
      total_solar_modules: quote?.quote?.module_quantity,
    });
  };

  //methods for sharing project url
  const shareProject = () => {
    if (currentUser) {
      setSharedURL(
        window.location.origin +
          "/application/custom-" +
          currentUser.uid +
          "-" +
          quote.quote.addr1
      );
    } else {
      setSharedURL(
        window.location.origin + "/application/default-" + quote.quote.addr1
      );
    }
    setShareProjectDialog(true);
  };

  const BlueOnGreenTooltipForHoverPart = withStyles({
    tooltip: {
      color: "#000",
      fontWeight: "600",
      fontSize: "17px",
      height: "120px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "570px",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
      backgroundColor: "#fff",
    },
  })(Tooltip);
  //benefit cards components
  const BenefitsCard = (props) => {
    const { imgUrl, text1, text2, number } = props;

    const classes = useStyles();
    const newHack = ["bottom-start", "bottom", "bottom-end"];
    const [trackId, setTrackId] = useState(null);
    const [preventOnMouseLeave, setPreventOnMouseLeave] = useState(false);
    const onClickHandler = () => {
      setIsModalVisible(true);
      setText(text2);
    };
    return (
      <BlueOnGreenTooltipForHoverPart
        placement={newHack[number]}
        enterNextDelay={600}
        // mouseLeaveDelay={0.5}
        // mouseEnterDelay={0.5}
        title={
          isEnglish ? (
            <div style={{ marginLeft: "15px" }}>
              we have made all calculations for you.Check your details now to
              understand all details regarding the calcutaion
            </div>
          ) : (
            <div style={{ marginLeft: "15px" }}>
              wir haben alle Berechnungen für Sie durchgeführt.Überprüfen Sie
              jetzt Ihre Daten um alle Details bezüglich der Berechnung zu
              verstehen
            </div>
          )
        }
      >
        <div
          className={classes.c2_benefits}
          onClick={() => {
            setWhichIconShouldBeVisible(false);
            onClickHandler();
          }}
        >
          <Avatar className={classes.c2_benefits_avatar} src={imgUrl} />
          <div
            className={classes.c2_benefits_text}
            onMouseEnter={() => {
              setIsVisible(true);
            }}
          >
            <Typography className={classes.c2_benefits_text1}>
              {number === 0
                ? commaDotHandler(`${text1}`, `${isEnglish ? "en" : "de"}`) +
                  "€"
                : number === 2
                ? `${commaDotHandler(
                    `${text1}`,
                    `${isEnglish ? "en" : "de"}`
                  )}%`
                : commaDotHandler(
                    `${text1}`,
                    `${isEnglish ? "en" : "de"}` + "%"
                  )}
            </Typography>
            {window.innerWidth <= 500 ? (
              <Typography
                style={{ fontSize: isEnglish ? "12px" : "10px" }}
                className={classes.c2_benefits_text2}
              >
                {text2}
              </Typography>
            ) : text2 === "Yearly Savings in TCO2" ||
              text2 === "Jährliche Einsparungen in TCO2" ? (
              <>
                {text2 === "Jährliche Einsparungen in TCO2" ? (
                  <Typography
                    className={classes.c2_benefits_text2}
                    style={{
                      fontSize: "10px",
                      height: isEnglish ? "36px" : "42px",
                    }}
                  >
                    Jährliche Einsparungen in TCO2
                  </Typography>
                ) : (
                  <Typography
                    className={classes.c2_benefits_text2}
                    style={{
                      height: isEnglish ? "36px" : "42px",
                    }}
                  >
                    Yearly Savings in TCO2
                  </Typography>
                )}
              </>
            ) : text2 === "Yearly Savings in €" ? (
              <Typography
                className={classes.c2_benefits_text2}
                style={{
                  height: isEnglish ? "36px" : "42px",
                }}
              >
                Yearly Savings
                <br />
                in €
              </Typography>
            ) : (
              <Typography
                style={{ fontSize: isEnglish ? "12px" : "10px" }}
                className={classes.c2_benefits_text2}
              >
                {text2}
              </Typography>
            )}
          </div>
        </div>
      </BlueOnGreenTooltipForHoverPart>
    );
  };

  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const [whichIconShouldBeVisibles, setWhichIconShouldBeVisibles] =
    useState(false);
  const [whichIconShouldBeVisibles2, setWhichIconShouldBeVisibles2] =
    useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const quantity = useSelector(
    (state) => state?.project?.components?.items?.solar_panel?.quantity
  );

  const BlueOnGreenTooltip = withStyles({
    tooltip: {
      color: "#000",
      fontWeight: "600",
      fontSize: "14px",
      maxWidth: "300px",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
      backgroundColor: "#fff",
    },
  })(Tooltip);

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^\d,.]/g, "");
    var hasNumber = /\d/;
    if (!hasNumber.test(e.target.value) && e.target.value !== "") {
      return toast.warning("String is not allowed please enter a number");
    }
    console.log(quantity, "quantity");
    if (getNumber(value) > quote.quote.module_quantity) {
      value = parseInt(quote.quote.module_quantity);
      dispatch({ type: "SET_SOLAR_PANEL_QUANTITY", payload: value });
      return toast.warning(
        `Maximun panels for this quote is ${parseInt(
          quote.quote.module_quantity
        )} more than ${parseInt(quote.quote.module_quantity)} is not allowed`
      );
    }
    dispatch({ type: "SET_SOLAR_PANEL_QUANTITY", payload: value });
  };

  return (
    <div className={classes.root}>
      <ShareProjectDialog
        open={shareProjectDialog}
        setOpen={setShareProjectDialog}
        url={sharedURL}
      />
      <Status
        isComingFromAdmin={isComingFromAdmin}
        isEnglish={isEnglish}
        allProjets={allProjets}
        quote={quote}
        project={project}
      />
      <div className={classes.root1}>
        <div className={classes.mainClass}>
          <Paper elevation={5} className={classes.c1_container}>
            <div className={classes.c1_container_header}>
              <div
                className={
                  profile === "Starter"
                    ? classes.c2_header_button_highlighted
                    : classes.c2_header_button
                }
                onClick={() => modifyProfile("Starter")}
              >
                {isEnglish ? "eco" : "eco"}
              </div>
              <div
                className={
                  profile === "Consumer"
                    ? classes.c2_header_button_highlighted
                    : classes.c2_header_button
                }
                onClick={() => modifyProfile("Consumer")}
              >
                {isEnglish ? "smart" : "smart"}
              </div>
              <div
                className={
                  profile === "Producer"
                    ? classes.c2_header_button_highlighted
                    : classes.c2_header_button
                }
                onClick={() => modifyProfile("Producer")}
              >
                {isEnglish ? "green" : "green"}
              </div>
            </div>
            {loader ? (
              <div className={classes.card2Loader}>
                <CircularProgress />
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <img
                  alt=""
                  src={
                    project?.quoteStatus === "found" ||
                    project?.quoteStatus === "matched"
                      ? quote.quote.img_url[current_image]
                      : "/images/application/application_step_1/default_project_image.png"
                  }
                  className={classes.card1Image}
                />
                {current_image > 0 ? (
                  <div
                    className={classes.left_button}
                    onClick={() => setcurrent_image((prev) => prev - 1)}
                  >
                    <CaretLeftOutlined />
                  </div>
                ) : null}
                {current_image < quote.quote.img_url.length - 1 ? (
                  <div
                    className={classes.right_button}
                    onClick={() => setcurrent_image((prev) => prev + 1)}
                  >
                    <CaretRightOutlined />
                  </div>
                ) : null}
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant={"contained"}
                style={{
                  backgroundColor: "#FFD75D",
                  width: "50%",
                  height: "35px",
                  borderRadius: "unset",
                  forntSize: "13px",
                  textAlign: "center",
                  marginTop: "-15px",
                }}
                startIcon={<img src={satellite} alt="" />}
                className={classes.fontClass}
              >
                {isEnglish ? "Satellite" : "Satellit"}
              </Button>
              <Button
                variant={"contained"}
                style={{
                  backgroundColor: "#f8f8f8",
                  width: "50%",
                  height: "35px",
                  borderRadius: "unset",
                  textAlign: "center",
                  forntSize: "13px",
                  marginTop: "-15px",
                }}
                startIcon={<img src={threeD} alt="3D-view" />}
                className={classes.fontClass}
              >
                {isEnglish
                  ? "3D-View"
                  : window.innerWidth > 500
                  ? "3D-Ansicht(bald)"
                  : "3D-Ansicht"}
              </Button>
            </div>
            <div className={classes.forIphone}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0",
                  alignItems: "center",
                }}
              >
                <Typography
                  className={classes.roofPitch}
                  style={{ paddingRight: isEnglish ? "28px" : "30px" }}
                >
                  {isEnglish ? "Roof Pitch" : "Dachneigung"}
                </Typography>
                <BlueOnGreenTooltip
                  placement="right-start"
                  title={
                    isEnglish
                      ? "for a first sample we assumed your roof pitch to be around 15° please adjust if you have more detailed information here -it will affect your calculation."
                      : "Für ein erstes Muster haben wir angenommen, dass Ihre Dachneigung ca. 15° beträgt. Bitte passen Sie an, wenn Sie hier genauere Informationen haben, wird di -es Ihre Berechnung beeinflussen."
                  }
                >
                  <img
                    style={{ marginLeft: "20px" }}
                    onMouseEnter={() => setWhichIconShouldBeVisibles(true)}
                    onMouseLeave={() => setWhichIconShouldBeVisibles(false)}
                    src={whichIconShouldBeVisibles ? blackInfo : Question}
                    alt=""
                  />
                </BlueOnGreenTooltip>
                <div className={classes.roofSelector}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={
                      classes.newCladd && classes.forMakingSmallRoofPitch
                    }
                    value={project?.roofPitch}
                    onChange={(e) => setRoofPitch(e.target.value)}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {roofPitchFactor.map((item) => (
                      <MenuItem value={getNumber(item.angle)}>
                        {`${getNumber(item.angle)}` + `\u00b0`}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <Typography
                  className={classes.Solarmodules}
                  style={{ paddingRight: isEnglish ? "0px" : "31px" }}
                >
                  {isEnglish ? "Solarmodules" : "Solar Module"}
                </Typography>
                <BlueOnGreenTooltip
                  placement="right-start"
                  title={
                    isEnglish
                      ? "as a start we've tried to put as many modules on your roof as possible. Due to constantly increasing energy consumption for example by using Electric vehicles we highly recommend to use the full solar potential on your roof."
                      : "Vorkonfiguriert ist hier die maximale Anzahl an Modulen für die Ihr Dach Platz bietet. E-Mobilität und Wärmepumpen werden den zukünftigen Bedarf an Strom steigen lassen. Daher Empfehlen wir von SolarHub die Fläche Ihres Dachs voll auszunutzen. Steigern Sie Ihre Energieautarkie."
                  }
                >
                  <img
                    style={{
                      marginLeft:
                        window.innerWidth < 500 && isEnglish ? "29px" : "20px",
                    }}
                    onMouseEnter={() => setWhichIconShouldBeVisibles2(true)}
                    onMouseLeave={() => setWhichIconShouldBeVisibles2(false)}
                    src={whichIconShouldBeVisibles2 ? blackInfo : Question}
                    alt=""
                  />
                </BlueOnGreenTooltip>
                <div
                  style={{
                    height: "25px",
                    marginLeft: "25px",
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <div
                    onClick={() => {
                      if (quantity >= 2) {
                        decreaseSolarPanel();
                      }
                    }}
                    className={classes.fontClass && classes.plus_icon}
                  >
                    -
                  </div>
                  <input
                    className={classes.inputStyle}
                    value={solarpanel}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={() => {
                      if (isNaN(quantity)) {
                        setSolarPanel(parseInt(quote.quote.module_quantity));
                        dispatch({
                          type: "SET_SOLAR_PANEL_QUANTITY",
                          payload: parseInt(quote.quote.module_quantity),
                        });
                      } else if (quantity === null) {
                        setSolarPanel(parseInt(quote.quote.module_quantity));
                        dispatch({
                          type: "SET_SOLAR_PANEL_QUANTITY",
                          payload: parseInt(quote.quote.module_quantity),
                        });
                      } else if (quantity === "") {
                        setSolarPanel(parseInt(quote.quote.module_quantity));
                        dispatch({
                          type: "SET_SOLAR_PANEL_QUANTITY",
                          payload: parseInt(quote.quote.module_quantity),
                        });
                      }
                    }}
                  />
                  {project?.components?.items?.solar_panel?.quantity <
                    quote?.quote?.module_quantity && (
                    <div
                      onClick={() => {
                        if (
                          project?.components?.items?.solar_panel?.quantity <
                          quote?.quote?.module_quantity
                        ) {
                          increaseSolarPanel();
                        }
                      }}
                      className={classes.plus_icon1}
                    >
                      +
                    </div>
                  )}
                </div>
              </div>
              <Divider
                style={{
                  width: "75%",
                  margin: "auto",
                  height: "1px",
                  background: "#2D4764",
                  opacity: "0.3",
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <Typography className={classes.panelCapacity}>
                  {isEnglish ? "Panel Capacity" : "Kapazität je Panel"}
                </Typography>
                <Typography
                  style={{
                    height: "20px",
                    marginLeft: "25px",
                    width: "50%",
                    paddingLeft: "20px",
                    fontWeight: "600",
                    color: "rgba(102, 102, 102, 1)",
                  }}
                  className={classes.fontClass}
                >
                  {commaDotHandler(
                    Math.floor(solar_panel_capacity),
                    `${isEnglish ? "en" : "de"}`
                  )}{" "}
                  Watt
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <Typography className={classes.totalCapacity}>
                  {isEnglish ? "Total Capacity" : "Gesamtkapazität"}
                </Typography>
                <Typography
                  style={{
                    height: "20px",
                    marginLeft: "25px",
                    width: "50%",
                    paddingLeft: "20px",
                    fontWeight: "600",
                  }}
                  className={classes.fontClass}
                >
                  {commaDotHandler(
                    Math.round(
                      (solar_panel_capacity * solar_panel_quantity) / 1000
                    ),
                    `${isEnglish ? "en" : "de"}`
                  )}{" "}
                  kWatt
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
        <Paper elevation={5} className={classes.c2_container}>
          <div className={[classes.c2_header]}>
            <div
              onClick={() => {
                setTimeout(() => {
                  scrollToBottom();
                }, 100);
                setShowChart(true);
                setIsFinancing(true);
              }}
              className={
                isFInancing
                  ? classes.c2_header_button_highlighted
                  : classes.c2_header_button
              }
            >
              {isEnglish ? "Finance" : "Finanzieren"}
            </div>
            <div
              onClick={() => {
                setTimeout(() => {
                  scrollToBottom();
                }, 100);
                setShowChart(true);
                setIsFinancing(false);
              }}
              className={
                isFInancing
                  ? classes.c2_header_button
                  : classes.c2_header_button_highlighted
              }
            >
              {isEnglish ? "Buy" : "Kaufen"}
            </div>
          </div>

          <div className={classes.c2_finace_container}>
            <div className={classes.c2_finace_container1}>
              <div>
                {window.innerWidth > 500 ? (
                  <Typography className={classes.fontClass}>
                    {isEnglish
                      ? "Production in kWh/year ......................"
                      : "Produktion in kWh / Jahr ......................."}
                  </Typography>
                ) : (
                  <Typography className={classes.fontClass}>
                    {isEnglish
                      ? "Production in kWh/year"
                      : "Produktion in kWh / Jahr"}
                  </Typography>
                )}
                <Typography className={classes.fontClass}>
                  {commaDotHandler(
                    energy_details?.total_production,
                    `${isEnglish ? "en" : "de"}`
                  )}
                </Typography>
              </div>
              <div>
                {window.innerWidth > 500 ? (
                  <Typography className={classes.fontClass}>
                    {isEnglish
                      ? "Yearly consumption in kWh ......................"
                      : "Jahresverbrauch in kWh ......................"}
                  </Typography>
                ) : (
                  <Typography className={classes.fontClass3}>
                    {isEnglish
                      ? "Yearly consumption in kWh"
                      : "Jahresverbrauch in kWh ....."}
                  </Typography>
                )}
                <Button
                  onClick={() => setYearlyConsumptionDialog(true)}
                  className={classes.familyButton1}
                >
                  <EditIcon
                    style={{
                      color: "#304864",
                      margin: "0 5px",
                      fontSize: "15px",
                    }}
                  />
                  {commaDotHandler(consumption, `${isEnglish ? "en" : "de"}`)}
                </Button>
              </div>
              <div>
                {window.innerWidth > 500 ? (
                  <Typography className={classes.fontClass}>
                    {isEnglish
                      ? `Consumption profile ..................`
                      : "Lastprofil ................."}
                  </Typography>
                ) : (
                  <Typography className={classes.fontClass3}>
                    {isEnglish ? `Consumption profile` : "Lastprofil ........"}
                  </Typography>
                )}
                <Button
                  onClick={() => setConsumptionProfileDialog(true)}
                  className={classes.familyButton}
                >
                  <EditIcon
                    style={{
                      color: "#304864",
                      margin: "0 5px",
                      fontSize: "15px",
                    }}
                    className={classes.familyIcon}
                  />

                  {project?.project_type === "b2b"
                    ? isEnglish
                      ? consumption_profiles_b2b[
                          project?.consumption_details_b2b?.consumption_profile
                        ]?.short_title[0]
                      : consumption_profiles_b2b[
                          project?.consumption_details_b2b?.consumption_profile
                        ]?.short_title[1]
                    : isEnglish
                    ? consumption_profiles[
                        project?.consumption_details?.consumption_profile
                      ]?.short_title[0]
                    : consumption_profiles[
                        project?.consumption_details?.consumption_profile
                      ]?.short_title[1]}
                </Button>
              </div>
            </div>
            <Divider style={{ margin: "25px" }} />
            <div className={classes.c2_finace_container2}>
              <BenefitsCard
                imgUrl={"/images/application/application_step_1/step1_c1.svg"}
                text1={`${Math.round(
                  costs_and_savings.electricity_cost_before +
                    costs_and_savings.feedin_savings -
                    financing_details.yearly_financing_amount
                )}`}
                text2={
                  isEnglish
                    ? "Yearly Savings in €"
                    : "Jährliche Einsparungen in €"
                }
                number={0}
              />
              <BenefitsCard
                imgUrl={"/images/application/application_step_1/step1_c2.svg"}
                text1={`${Math.round(
                  energy_details?.total_production * 0.0008
                )}`}
                text2={
                  isEnglish
                    ? "Yearly Savings in TCO2"
                    : "Jährliche Einsparungen in TCO2"
                }
                number={1}
              />
              <BenefitsCard
                imgUrl={"/images/application/application_step_1/step1_c3.svg"}
                text1={
                  project?.project_type === "b2b"
                    ? `${Math.round(
                        project?.consumption_details_b2b?.self_supply * 100
                      )}`
                    : `${Math.round(
                        project?.consumption_details?.self_supply * 100
                      )}`
                }
                text2={isEnglish ? "Self Supply" : "Eigenverbrauch"}
                number={2}
              />
            </div>
            {whichIconShouldBeVisible ? (
              <Paper elevation={0} className={classes.ThirdHoverClass}>
                {isEnglish ? (
                  <h3>
                    we have made all calculations for you.Check your details now
                    to understand all details regarding the calcutaion
                  </h3>
                ) : (
                  <h3>
                    wir haben alle Berechnungen für Sie durchgeführt.Überprüfen
                    Sie jetzt Ihre Daten um alle Details bezüglich der
                    Berechnung zu verstehen
                  </h3>
                )}
              </Paper>
            ) : (
              <>
                <div className={classes.helperContainer}>
                  <Button
                    variant={"outlined"}
                    onClick={() => setIncludedServicesDialog(true)}
                    className={classes.helperButton1}
                  >
                    {isEnglish
                      ? "Show all included services"
                      : "ZEIGE ALLE BEINHALTETEN LEISTUNGEN"}
                  </Button>
                </div>
                <div className={classes.helperContainer}>
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      setShowChart(!showChart);
                      setTimeout(() => {
                        scrollToBottom();
                      }, 100);
                    }}
                    className={classes.helperButton2}
                  >
                    {isEnglish
                      ? "Show detailed calculation"
                      : "Detailkalkulation anzeigen"}

                    {showChart ? (
                      <ArrowDropDownIcon className={classes.helperIcon} />
                    ) : (
                      <ArrowRightIcon className={classes.helperIcon} />
                    )}
                  </Button>
                </div>
              </>
            )}
            <Divider style={{ margin: "15px" }} />
            {isFInancing && showChart && (
              <>
                <FinanceCharts
                  isEnglish={isEnglish}
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                />
              </>
            )}
            {!isFInancing && showChart && (
              <>
                <BuyCharts
                  isEnglish={isEnglish}
                  costs_and_savings={costs_and_savings}
                  financing_details={financing_details}
                />
              </>
            )}
          </div>
        </Paper>

        {project?.project_type === "b2b" ? (
          <ConsumptionProfileDialogB2B
            open={consumptionProfileDialog}
            setOpen={setConsumptionProfileDialog}
            consumption={consumption}
            consumption_details={project?.consumption_details_b2b}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
          />
        ) : (
          <ConsumptionProfileDialog
            open={consumptionProfileDialog}
            setOpen={setConsumptionProfileDialog}
            consumption={consumption}
            consumption_details={project?.consumption_details}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
          />
        )}
        <IncludedServices
          open={includedServicesDialog}
          setOpen={setIncludedServicesDialog}
        />
      </div>
    </div>
  );
}

export default Beneficiaries;
