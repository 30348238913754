import * as actionTypes from "./chat.actionTypes";

import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "comet_const";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    user: user,
    isLoggedIn: true,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const logout = () => {
  return (dispatch) => {
    CometChat.logout().then(
      () => {
        console.log("Logout completed successfully");
        dispatch(logoutSuccess());
      },
      (error) => {
        console.log("Logout failed with exception:", { error });
      }
    );
  };
};

export const auth = (uid, authKey) => {
  console.log(uid, "uid");
  return (dispatch) => {
    dispatch(authStart());

    CometChat.login(uid, authKey)
      .then((user) => {
        console.log("user", user);
        if (user) {
          dispatch(authSuccess(user));
        } else {
          dispatch(authFail(user));
        }
      })
      .catch((error) => {
        console.log("CometChatLogin Failed", error);
        dispatch(authFail(error));
      });
  };
};

export const createUser = (uid, name) => {};

export const authCheckState = () => {
  return (dispatch) => {
    CometChat.getLoggedinUser()
      .then((user) => {
        if (user) {
          dispatch(authSuccess(user));
        } else {
          dispatch(authFail(user));
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};

export const myCometChat = (uid, name, avatar) => {
  console.log("hello");
  console.log("uid", uid, name);
  return (dispatch) => {
    CometChat.getLoggedinUser()
      .then((user) => {
        console.log("user", user);
        if (!user) {
          CometChat.login(uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
            (user) => {
              console.log("Login Successful:", { user });
              dispatch(authSuccess(user));
            },
            (error) => {
              console.log("Login failed with exception:", { error });
              console.log("creating new user");
              const new_user = new CometChat.User(uid);
              new_user.setUid(uid);
              new_user.setName(name);
              new_user.setAvatar(avatar);
              CometChat.createUser(new_user, COMETCHAT_CONSTANTS.AUTH_KEY).then(
                (user) => {
                  console.log("new user created", user);
                  dispatch(auth(uid, COMETCHAT_CONSTANTS.AUTH_KEY));
                },
                (error) => {
                  console.log("error", error);
                }
              );
            }
          );
        } else {
          console.log("user founded");
          dispatch(authSuccess(user));
          // window.location.assign("/application");
        }
      })
      .catch((error) => {
        console.log("lol", error);
        dispatch(authFail(error));
      });
  };
};

/* export const myCometChat1 = (uid, name) => {
  return (dispatch) => {
    CometChat.getLoggedinUser()
      .then((user) => {
        console.log("user", user);
        if (user) {
          console.log("comet logout");
          dispatch(logout());
        } else {
        }
      })
      .catch((error) => {
        console.log("lol", error);
        dispatch(authFail(error));
      });
  };
}; */

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};
