import React, { useEffect, useState } from "react";
import styled from "styled-components";
import flash from "Assets/community/flash.svg";
import ladki from "Assets/community/ladki.svg";
import bookmark from "Assets/community/bookmark.svg";
import BookmarkFilled from "Assets/community/BookmarkFilled.svg";
import heart from "Assets/community/heart.svg";
import share from "Assets/community/share.svg";
import menu from "Assets/community/menu.svg";
import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Button } from "antd";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import {
  deleteTopicById,
  getUserByUserName,
  updateTopicById,
} from "api/discourse/get";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { db } from "./../../../../../../../../firebase";
const TopicListWrapper = styled("div")`
  .listMain {
    min-height: 197px;
    width: 644px;
    margin-bottom: 28px;
    padding-top: 24px;
    padding-bottom: 10px;
    border-radius: 10px;
    :hover {
      background: #f9f9f9;
      box-shadow: 2px 2px 10px #d3d3d3;
      border-radius: 10px;
    }
  }
  .first_container {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    margin-left: 28px;
    align-items: flex-start;
  }
  .howare {
    margin-right: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .makeItFlex {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  .makeItFlex2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text_imp {
    font-weight: bold;
    font-size: 18px;
    color: #2d4764;
    margin-bottom: 3px;
  }
  .text_1 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #2d4764;
  }
  .text_2 {
    font-weight: 600;
    margin-left: 8px;
    font-size: 10px;
    line-height: 14px;
    color: #666666;
  }
  .secound_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 387px;
    padding-left: 91px;
    height: 22px;
  }
  .same_clase {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 78px;
    cursor: pointer;
    height: 22px;
    div {
      font-weight: 600;
      font-size: 10px;
      margin-left: 6px;
      line-height: 14px;
      color: #666666;
    }
  }
  .same_clasee {
    :hover {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: #edf5ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .descriotion {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 21px;
    font-size: 16px;
    margin-top: 10px;
    padding-right: 10px;
    line-height: 22px;
    color: #666666;
  }
  .withMenu {
    display: flex;
    justify-content: space-between;
    width: 505.21px;
    align-items: flex-start;
    min-height: 30px;
  }
  .emoji {
    height: 22px;
    width: 22px;
  }
  .lightbox img {
    width: 500px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  .meta {
    display: none;
  }
  .replyButton {
    width: 65px;
    height: 27px;
    background: #ffd75d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-right: 12px;
    :hover {
      background: #ffd75d;
    }
  }
  .input_main {
    background: #f4f4f4;
    border-radius: 10px;
    width: 98%;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    img {
      height: 36px;
      width: 36px;
      margin-right: 10px;
    }
  }
  .last_class {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  }
  .tags_contanier {
    display: flex;
    justify-content: flex-start;
    padding-right: 14px;
    gap: 7px;
    align-items: flex-start;
  }
  .tag {
    background: #f9f9f9;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #304864;
    border-radius: 16.9412px;
    height: 18px;
    width: 55.913055419921875px;
  }
  .ant-btn:hover {
    color: black;
    border: none;
  }
  .ant-btn:focus {
    color: none;
    border: none;
  }
  .new_update {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (max-width: 600px) {
    .listMain {
      width: 93%;
      margin: auto;
      background: rgba(249, 249, 249, 1);
      margin-bottom: 10px;
    }
    .first_container {
      margin: 0px;
      margin-left: 8px;
    }
    .descriotion {
      font-size: 15px;
    }
    .withMenu {
      width: auto;
    }
    .lightbox-wrapper img {
      width: 77%;
    }
    .secound_container {
      width: 65%;
      margin: auto;
      padding-left: 11px;
    }
  }
`;
const ITEM_HEIGHT = 48;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F4F4F4",
  },
}));
const TopicList = ({
  setIsInsideTopicViewOn,
  insideTopic,
  setShowReplyBox,
  data,
  setTopicId,
  categoryId,
  setDataForInsideTopic,
}) => {
  console.log("sar", data);
  const [isBookmarked, setisBookmarked] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEditTrue, setIsEditTrue] = useState(false);
  const [updateTitle, setUpdateTitle] = useState(data?.title);
  const [isEditngThePost, setIsEditngThePost] = useState(false);
  const [userName, uid, topicId] = useSelector((state) => {
    return [
      state?.user?.username,
      state?.user.currentUser.uid,
      state?.savedTopic.topicId,
    ];
  });
  useEffect(() => {
    for (let i = 0; i < topicId.length; i++) {
      if (topicId[i].id === data.id) {
        setisBookmarked(true);
        break;
      }
    }
  }, []);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onclickHandler = () => {
    console.log("clicked edit");
    setIsEditTrue(true);
    handleClose();
  };
  const likeHandler = () => {
    console.log("sarthak");
  };

  const bookMarkHandler = async (flag) => {
    let t = [...topicId];
    if (isBookmarked) {
      let t1 = [];
      for (let i = 0; i < t.length; i++) {
        if (t[i].id != data.id) t1.push(t[i]);
      }
      t = t1;
    } else {
      t.push({ id: data.id, categoryId });
    }
    const data1 = {
      topicIdList: t,
    };
    const res = await db.collection("savedTopics").doc(uid).set(data1);
    dispatch({ type: "SET_TopicId", payload: t });
    if (isBookmarked) {
      toast.success("Topic Removed Successfully");
    } else {
      toast.success("Topic Added Successfully");
    }
    setisBookmarked((prev) => {
      return !prev;
    });
  };

  const onDeleteHandler = async () => {
    console.log("on delete clicked");
    handleClose();
    if (data?.posters[0]?.username !== userName) {
      setIsEditTrue(false);
      return toast.error(
        "you are not created this post you are not allowed to delete this post"
      );
    }
    const data1 = {
      username: data?.posters[0]?.username,
    };
    console.log(data1, "data1");
    const res = await deleteTopicById(data.id, data1);
    if (res) {
      dispatch({ type: "SET_DISCORUSE_STATE" });
      toast.success("topic deleted successfully");
    } else {
      setIsEditTrue(false);
    }
  };

  const onSubmitHandler = async () => {
    if (updateTitle === "") {
      return toast.warn(
        isEnglish
          ? "please write something to post"
          : "bitte schreiben Sie etwas um zu antworten"
      );
    }
    if (data?.posters[0]?.username !== userName) {
      setIsEditTrue(false);
      return toast.error(
        "you are not created this post you are not allowed to edit this post"
      );
    }
    const data1 = {
      title: updateTitle,
      username: data?.posters[0]?.username,
    };
    setIsEditngThePost(true);
    const res = await updateTopicById(data.id, data1);
    if (res) {
      setIsEditTrue(false);
      dispatch({ type: "SET_DISCORUSE_STATE" });
      setIsEditngThePost(false);
      toast.success("topic updated successfully");
    } else {
      setIsEditTrue(false);
      setIsEditngThePost(false);
    }
  };
  return (
    <TopicListWrapper>
      <div
        className="listMain"
        style={{ background: insideTopic ? "#f9f9f9" : "" }}
      >
        <div className="new_update">
          <div
            className="first_container"
            onClick={() => {
              isEditTrue
                ? console.log("disable")
                : setIsInsideTopicViewOn(true);
              if (insideTopic) {
                return;
              } else {
                setTopicId(data.id);
              }
            }}
          >
            {data?.posters?.length > 0 && data?.posters[0]?.avatar_template ? (
              <img
                src={`http://community.solarhub24.de${data.posters[0].avatar_template.replace(
                  "{size}",
                  "50"
                )}`}
                className="howare"
                alt=""
              />
            ) : (
              <img src={ladki} alt="" />
            )}
            <div className="makeItFlex">
              <div className="withMenu">
                {isEditTrue ? (
                  <div className="input_main">
                    <TextField
                      id="demo-helper-text-aligned"
                      style={{ width: "100%" }}
                      InputProps={{
                        disableUnderline: true, // <== added this
                        classes: { input: classes.input1 },
                      }}
                      rows={3}
                      value={updateTitle}
                      onChange={(e) => setUpdateTitle(e.target.value)}
                      className={classes.root}
                    />
                    <Button
                      className="replyButton"
                      loading={isEditngThePost}
                      onClick={() => onSubmitHandler()}
                    >
                      {isEnglish ? "save" : " speichern"}
                    </Button>
                  </div>
                ) : (
                  <div className="text_imp">{data.title}</div>
                )}
              </div>
              <div className="makeItFlex2">
                <div className="text_1">{data?.posters[0]?.username}</div>
                <img src={flash} style={{ marginLeft: "4px" }} alt="" />
                <div className="text_2">
                  {moment(new Date(data.createdAt)).startOf("hour").fromNow()}
                </div>
              </div>
              <div className="descriotion">{parse(data.description)}</div>
            </div>
          </div>
          {data?.posters[0]?.username !== userName ? (
            ""
          ) : (
            <>
              <div style={{ paddingRight: "10px" }}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{ padding: "0px" }}
                >
                  <img src={menu} alt="" />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  <MenuItem onClick={onclickHandler}>
                    {isEnglish ? "Edit" : "Bearbeiten"}
                  </MenuItem>
                  <MenuItem onClick={onDeleteHandler}>
                    {isEnglish ? "delete" : "delete"}
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </div>
        <div className="last_class">
          <div className="secound_container">
            <div
              className="same_clase"
              onClick={() => {
                likeHandler();
              }}
            >
              <img src={heart} alt="" />
              <div>{data.likeCount} Likes</div>
            </div>
            <div
              className="same_clase"
              onClick={() => {
                if (insideTopic) {
                  setShowReplyBox(true);
                }
              }}
            >
              <img src={share} alt="" />
              <div>
                {data.replyCount} {isEnglish ? "Replies" : "Antworten"}
              </div>
            </div>
            <div className="same_clase">
              {isBookmarked ? (
                <img
                  src={BookmarkFilled}
                  alt=""
                  onClick={() => bookMarkHandler(true)}
                  height={"17px"}
                  width={"17px"}
                />
              ) : (
                <img
                  src={bookmark}
                  alt=""
                  onClick={() => bookMarkHandler(false)}
                  height={"17px"}
                  width={"17px"}
                />
              )}
            </div>
          </div>
          <div className="tags_contanier">
            {data?.tags?.length > 0
              ? data?.tags?.map((value) => {
                  return <div className="tag">@{value}</div>;
                })
              : ""}
          </div>
        </div>
      </div>
    </TopicListWrapper>
  );
};

export default TopicList;
