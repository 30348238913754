import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import SignUpSignInPopUp from "../popups/SignUpSignIn/signup.popup";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import firebase, { db } from "../firebase";
import { costsAndSavings, energyDetails, financingDetails } from "utils/costs";
import { Avatar, Image } from "antd";
import * as geofire from "geofire-common";
import * as actions from "redux/chat/chat.action";
import { CometChat } from "@cometchat-pro/chat";
import { useProjectTools } from "hooks/useProjectTools";

const useStyles = makeStyles((theme) => ({
  mySignButton: {
    width: "120px",
    height: "45px",
    fontSize: "18px",
    backgroundColor: "#FBDA61",
    fontWeight: "bold",
    borderRadius: "5px",
    "@media (max-width: 430px)": {
      width: "80px",
      fontSize: "10px",
    },
  },
  butoon: {
    borderRadius: "5px",
    fontWeight: "bold",
    padding: "8px 20px",
    margin: "15px",
    borderRadius: "10px",
    border: `1px solid #000000`,
    backgroundColor: "transparent",
    marginTop: "0px",
    "&:hover": {
      backgroundColor: "rgba(45, 71, 100, 1)",
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
      fontSize: "14px",
    },
    "@media (max-width: 321px)": {
      padding: "7px 12px",
      margin: "3px",
      fontSize: "10px",
    },
  },
  personIcon: {
    marginRight: "3px",
    marginBottom: "2px",
  },
  forLogInUserResponsive: {
    width: "40px",
    [theme.breakpoints.down("xs")]: {
      width: "30px",
      marginRight: "6px",
    },
  },
  h3Responsive: {
    marginTop: "10px",
    marginLeft: "3px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  buttonResponsive: {
    marginBottom: "14px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
}));

export default function SimpleMenu({ project }) {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [signUpSignInPopup, setSignUpSignInPopup] = useState(false);
  const [forIconChange, setForIconChange] = useState(false);
  const [userLikeToSave, setUserLikeToSave] = useState(false);
  const [savedprojectsVersion, setSavedProjectsVersion] = useState([]);
  const [userDetail, setUserDetail] = useState();
  const { save } = useProjectTools();
  const projectConfiguration = useSelector(
    (state) => state.global.projectConfiguration
  );
  const qrn = useSelector((state) => state.project.qrn);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const quote = useSelector((state) => state.quote);
  const dummyProjectCount = useSelector(
    (state) => state?.global?.dummyProjectCount
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profileSendHandler = () => {
    if (currentUser) {
      history.push({
        pathname: "/userdashboard/Profile",
        state: { currentUser: currentUser.uid },
      });
    } else {
      toast.error("for view your profile please login first");
    }
  };

  const projectSendHanlder = () => {
    if (currentUser) {
      history.push({
        pathname: "/userdashboard",
        state: { currentUser: currentUser.uid },
      });
    } else {
      toast.error("for view your profile please login first");
    }
  };

  useEffect(() => {
    if (currentUser) {
      db.collection("users")
        .doc(currentUser.uid)
        .onSnapshot((doc) => {
          setUserDetail(doc.data());
          const data = {
            username: doc.data()?.username,
          };
          const notificationData = {
            waiting_invitaion: doc.data()?.waiting_invitaion
              ? doc.data()?.waiting_invitaion
              : [],
            pending_invitaion: doc.data()?.pending_invitaion
              ? doc.data()?.pending_invitaion
              : [],
            accepted_invitaion: doc.data()?.accepted_invitaion
              ? doc.data()?.accepted_invitaion
              : [],
          };
          dispatch({ type: "SET_USERNAME_DISCOURSE_PASSWORD", payload: data });
          dispatch({ type: "SET_ALL_INVITAION", payload: notificationData });
        });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && qrn) {
      db.collection("saved_projects")
        .where("qrn", "==", qrn)
        .where("uid", "==", currentUser.uid)
        .get()
        .then((snapshots) => {
          const data = [];
          snapshots.forEach((doc) => {
            data.push(doc.data());
          });
          setSavedProjectsVersion(data);
        })
        .catch((e) => console.log(e));
    }
  }, [currentUser, qrn, dummyProjectCount, history.pathname]);

  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        handleClose();
        dispatch(actions.logout());
        history.push("/application");
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
  };

  const logIn = () => {
    setSignUpSignInPopup(true);
  };

  const save2 = () => {
    let slug = pathname.substring(13);
    if (project?.quoteStatus === "default") {
      toast.error(<OpenInSave />);
      return;
    }
    if (project?.quoteStatus === "notFound") {
      toast.error(
        isEnglish
          ? "Please wait for sometime. We are creating the project for your address -Please check back after some time"
          : "Wir erstellen gerade deinen Projektplan für dein Gebäude - schau dich gerne etwas um. Wir melden uns sobald die Planung fertig ist."
      );
      return;
    }
    if (slug?.includes("saved")) {
    } else {
      setLoading(true);
    }
    const financialDetails = financingDetails(project);
    const energysDetails = energyDetails(project);
    const costSaving = costsAndSavings(project);
    if (currentUser) {
      const uid = currentUser?.uid;
      let versionLength;
      if (savedprojectsVersion?.length === 0) {
        versionLength = 2;
      } else {
        versionLength = savedprojectsVersion?.length + 1;
      }
      db.collection("saved_projects")
        .doc(`${uid}${quote?.quote.qrn}v${versionLength}`)
        .set({
          project_details: {
            ...project,
            pid: uid + quote?.quote.qrn + `v${versionLength}`,
          },
          uid: uid,
          qrn: quote?.quote.qrn,
          crn: quote?.quote?.crn,
          pid: uid + quote?.quote.qrn + `v${versionLength}`,
          projectImgUrl: quote?.quote?.img_url,
          financialDetails,
          energysDetails,
          costSaving,
          updatedData: {
            roof_type: quote?.quote?.roof_type || "Flat roof",
            roof_material: quote?.quote?.roof_material || "metal roof",
            roof_tilt: quote?.quote?.roof_tilt || "45°",
            roof_orientation:
              quote?.quote?.roof_orientation || "South orientation",
          },
          version: `v${versionLength}`,
          geohash: geofire.geohashForLocation([
            parseFloat(quote?.quote?.lat),
            parseFloat(quote?.quote.long),
          ]),
          lat: quote?.quote?.lat,
          long: quote?.quote?.long,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          toast.success(
            isEnglish
              ? "Solar Configuration new version created successfully"
              : "Weitere Konfiguration neu hinzufügen erfolgreich erstellt"
          );
          if (slug?.includes("saved")) {
          } else {
            toast.success(
              isEnglish
                ? "Project succesfully saved"
                : "Projekt erfolgreich gespeichert"
            );
            setLoading(false);
          }
        })
        .catch((e) => console.error(e.message));
    } else {
      setLoading(false);
    }
  };
  const addDummyProject = () => {
    save2();
    handleClose();
    dispatch({ type: "DUMMYPROJECT_PROJECT_ADDED" });
  };

  const offersHandler = () => {
    const uid = currentUser?.uid;
    if (qrn && uid && qrn !== "60ebee279eee013e34e64130") {
      const url = `${uid}${qrn}v1`;
      const decRef = db.collection("saved_projects").doc(url);
      decRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            localStorage.clear();
            localStorage.setItem("goBackUrl", window.location.pathname);
            dispatch({ type: "SET_BAK_TO_STEP_FOUR", payload: false });
            history.push({
              pathname: `/useroverview/${doc.data()?.pid}`,
              state: {
                qrn: qrn,
                projectId: doc.data()?.pid,
              },
            });
            handleClose();
          } else {
            save(project, null, true);
          }
        })
        .catch((err) => {
          console.log(err);
          handleClose();
        });
    } else {
      localStorage.clear();
      localStorage.setItem("goBackUrl", window.location.pathname);
      dispatch({ type: "SET_BAK_TO_STEP_FOUR", payload: false });
      history.push({
        pathname: "/useroverview/60ebee279eee013e34e64130Project123",
        state: {
          qrn: "60ebee279eee013e34e64130",
          projectId: "60ebee279eee013e34e64130Project123",
        },
      });
      handleClose();
    }
  };

  return (
    <div>
      {currentUser ? (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.buttonResponsive}
        >
          {!userDetail?.img_url && !currentUser?.photoURL ? (
            <Avatar
              style={{ backgroundColor: "#00a2ae", verticalAlign: "middle" }}
              size="large"
            >
              {currentUser?.email[0]}
            </Avatar>
          ) : (
            <Avatar
              src={
                userDetail?.img_url
                  ? userDetail?.img_url
                  : currentUser?.photoURL
              }
              size={35}
            />
          )}
          <h3 className={classes.h3Responsive}>
            {userDetail?.fullName
              ? userDetail?.fullName
              : currentUser.displayName}
          </h3>
        </Button>
      ) : (
        <Link style={{ textDecoration: "none" }}>
          <Button
            className={classes.butoon}
            onMouseEnter={() => setForIconChange(true)}
            onMouseLeave={() => setForIconChange(false)}
            onClick={logIn}
          >
            <PersonIcon
              className={classes.personIcon}
              style={{
                color: forIconChange ? "#fff" : "black",
              }}
            />
            Login
          </Button>
        </Link>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          transform: "translateX(1%) translateY(5%)",
        }}
      >
        <MenuItem
          onClick={() => {
            profileSendHandler();
          }}
        >
          {isEnglish ? "Profile" : "Profil"}
        </MenuItem>
        {currentUser && window.location.pathname.includes("saved") ? (
          <MenuItem onClick={addDummyProject}>
            {isEnglish
              ? "Add aditional configruation"
              : "Weitere Konfiguration hinzufügen"}
          </MenuItem>
        ) : (
          " "
        )}
        <MenuItem onClick={offersHandler}>
          {isEnglish ? "Saved offers" : "Gespeicherte Angebote"}
        </MenuItem>
        <MenuItem onClick={logOut}>
          {isEnglish ? "Logout" : "Abmelden"}
        </MenuItem>
      </Menu>
      <SignUpSignInPopUp
        open={signUpSignInPopup}
        setOpen={setSignUpSignInPopup}
        userLikeToSave={userLikeToSave}
        registerStatus={true}
        isEnglish={isEnglish}
        project={project}
      />
    </div>
  );
}
