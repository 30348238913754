import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Input } from "@material-ui/core";
import { useSelector } from "react-redux";
import { parseLocaleNumber, commaDotHandler } from "helperMethod";
import { useDispatch } from "react-redux";
import swapCommaDot from "helperMethod.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      maxHeight: 345,
    },
    center: {
      textAlign: "center",
      color: "#2D4764",
    },
    main_container: {
      marginTop: "30px",
      height: "150px",
      width: "471px",
      display: "flex",
      "@media (max-width: 530px)": {
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        height: "223px",
        alignItems: "center",
      },
      flexDirection: "row",
      justifyContent: "space-between",
    },
    input_main: {
      height: "121px",
      "@media (max-width: 530px)": {
        marginBottom: "14px",
      },
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inside_inputMain: {
      backgroundColor: " #EAEAEA",
      width: "289px",
      height: "80px",
      padding: "15px 20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 530px)": {
        width: "260px",
      },
      borderRadius: "20px",
    },
    inside_main: {
      width: "208px",
      height: "140px",
      border: `1px solid #2D4764`,
      borderRadius: "19px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      "@media (max-width: 530px)": {
        marginBottom: "10px",
      },
      "& img": {
        height: "64px",
        marginTop: "20px",
        width: "76px",
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgba(253, 184, 0, 0.1)",
        width: "214px",
        height: "145px",
        tansition: "0.3s",
      },
    },
    button: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    Button_color: {
      backgroundColor:
        " linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
    },
  })
);

const electricityPriceDialog = (props) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const isB2B = useSelector((state) => state.project.isB2B);
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(
    commaDotHandler(props.electricity_price, `${isEnglish ? "en" : "de"}`)
  );
  const setElectricityPrice = (data) => {
    const payload = {
      isB2B: isB2B,
      value: data,
    };
    dispatch({ type: "SET_ELECTRICITY_PRICE", payload: payload });
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <Dialog onClose={handleClose} open={props.open} maxWidth={false}>
      <div className={classes.inside_inputMain} style={{ height: "100%" }}>
        <Typography>
          {isEnglish
            ? "Electricity Price in €/kWh......."
            : "Strompreis in €/kWh......."}
        </Typography>
        <Input
          placeholder={
            window.innerWidth < 500
              ? ""
              : isEnglish
              ? "Electricity Price in €/kWh......."
              : "Strompreis in €/kWh......."
          }
          inputProps={{ style: { textAlign: "center" } }}
          style={{ textAlign: "center", paddingLeft: "5px", width: "100px" }}
          value={temp}
          // onChange={(e) => {
          //   props.setelectricity_price(e.target.value);
          //   let value = e.target.value;
          //   if (isEnglish) {
          //     value = parseLocaleNumber(value, "en");
          //   } else {
          //     value = parseLocaleNumber(value, "de");
          //   }
          //   setElectricityPrice(value);
          // }}

          onChange={(e) => {
            let value = e.target.value;
            if (isEnglish) {
              value = parseLocaleNumber(value, "en");
              setTemp(e.target.value);
            } else {
              value = parseLocaleNumber(value, "de");
              setTemp(e.target.value);
            }
            setElectricityPrice(value);
          }}
          onBlur={() => {
            if (isNaN(props.electricity_price)) {
              setTemp(0.3);
              setElectricityPrice(0.3);
            }
          }}
        />
      </div>
    </Dialog>
  );
};
export default electricityPriceDialog;
