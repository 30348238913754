import React from "react";
import styled from "styled-components";
const MainContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 8px;
  :hover {
    cursor: pointer;
    background: #f9f9f9;
  }
`;
const UserList = ({ item, handleItemClick }) => {
  return (
    <MainContainer onClick={(e) => handleItemClick(e, item.username)}>
      <img
        style={{
          marginRight: "10px",
          width: "35px",
          height: "35px",
          borderRadius: "50%",
        }}
        src={`http://community.solarhub24.de/${item.avatar.replace(
          "{size}",
          "50"
        )}`}
        alt=""
      />
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: window.innerWidth > 500 ? "" : "18px",
          }}
        >
          {item?.name ? item?.name : item.username}
        </div>
        <div
          className="dropdown-item"
          style={{
            fontSize: window.innerWidth > 500 ? "" : "18px",
          }}
        >{`@ ${item.username}`}</div>
      </div>
    </MainContainer>
  );
};

export default UserList;
