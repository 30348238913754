import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Button } from "@mui/material";
import { Backdrop } from "@material-ui/core";
import finalLogo from "Assets/finalLogo.svg";
import finalText from "Assets/finalText.svg";
import { useSelector } from "react-redux";
import Google from "Assets/vectors/Google.svg";
import SignUpSignInPopUp from "popups/SignUpSignIn/signup.popup";
import { v4 as uuidv4 } from "uuid";
import firebase, { db } from "../../../firebase";
import moment from "moment";
import styled from "styled-components";
import { timeBreaker } from "utils/utilsfunctions";

const Container = styled.div`
	display: flex;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 60px 160px;
	border-radius: 20px;
	box-shadow: 24;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.title {
		font-size: 18px;
		font-weight: 700;
		color: #2d4764;
		margin-bottom: 28px;
		margin-top: 20px;
	}
	.webinar-time {
		font-weight: 700;
		font-size: 18px;
		color: #2d4764;
	}
	.timer-countdown {
		display: flex;
		justify-content: center;
		gap: 10px;
		margin-top: 28px;
		margin-bottom: 20px;
		div {
			text-align: center;
			color: #2d4764;
			font-weight: 500;
			font-size: 18px;
		}
	}
	.google-login {
		font-weight: 600;
		box-shadow: none;
		border: 1px solid black;
		background: white;
		color: #2d3748;
		margin-top: 32px;
	}

	.register-button {
		color: #2d4764;
		font-size: 16px;
		font-weight: 700;
		background: #ffd75d;
		border-radius: 5px;
		padding: 10px 102px;
		margin-top: 32px;
		margin-bottom: 20px;

		&:hover {
			cursor: pointer;
		}
	}

	.webinar-button {
		color: #2d4764;
		font-size: 16px;
		font-weight: 700;
		background: #ffd75d;
		border-radius: 5px;
		padding: 10px 102px;
		margin-top: 32px;
		margin-bottom: 20px;

		&:hover {
			cursor: pointer;
		}
	}

	.login {
		font-weight: 400;
		font-size: 14px;

		.yellow {
			color: #ffbf1b;
		}

		&:hover {
			cursor: pointer;
		}
	}
`;

const WebinarPopup = ({ showYoutubeLiveIframe, setShowYoutubeLiveIframe, open, setOpen }) => {
	const [timerCountdown, settimerCountdown] = useState({
		days: 0,
		hours: 3,
		minutes: 5,
		seconds: 57,
	});
	const [flag, setFlag] = useState(false);
	const currentUser = useSelector((state) => state.user.currentUser);
	const [isEnglish, project] = useSelector((state) => [state.global.isEnglish, state.project]);
	const [signUpSignInPopup, setSignUpSignInPopup] = useState(false);
	const [userLikeToSave, setUserLikeToSave] = useState(false);
	const [registerStatus, setRegisterStatus] = useState(true);

	const [webinar_time, setwebinar_time] = useState("Monday, 1st April 2022 - 18:00 (CET)");

	useEffect(() => {
		db.collection("webinar").onSnapshot((snapshots) => {
			const data = [];
			snapshots.forEach((doc) => {
				data.push(doc.data());
			});
			if (data.length) {
				let webinarDate = null;
				data.forEach((webinar) => {
					if (webinar.live) {
						webinarDate = new Date(webinar.date.toDate());
					}
				});

				console.log("sfads");
				const diff = moment().diff(webinarDate);
				setwebinar_time(moment(webinarDate).format("dddd, MMMM Do YYYY, h:mm a"));
				if (diff > 0) {
					settimerCountdown({
						days: 0,
						hours: 0,
						minutes: 0,
						seconds: 0,
					});
					// clearAllIntervals();
				} else {
					setInterval(() => {
						countDownHandler(webinarDate);
					}, 1000);
				}
			}
		});
	}, [flag]);

	const clearAllIntervals = () => {
		// Get a reference to the last interval + 1
		const interval_id = window.setInterval(function () {}, Number.MAX_SAFE_INTEGER);

		// Clear any timeout/interval up to that id
		for (let i = 1; i < interval_id; i++) {
			window.clearInterval(i);
		}
	};

	const countDownHandler = (webinarDate) => {
		const diff = moment().diff(webinarDate);

		const { seconds, minutes, hours, days } = timeBreaker(Math.abs(diff / 1000).toFixed(0));

		if (seconds == 0 && minutes == 0 && hours == 0 && days == 0) {
			clearAllIntervals();
		}

		settimerCountdown({
			days: days,
			hours: hours,
			minutes: minutes,
			seconds: seconds,
		});
	};

	const updateUserInfo = (userDetails, info) => {
		if (info == "register") {
			const docref = db.collection("users").doc(userDetails.uid);
			docref.get().then((doc) => {
				if (doc.exists) {
					if (!doc.data().username) {
						registerUserInDiscourse(userDetails);
					} else {
						delete userDetails.disPassword;
						delete userDetails.username;
					}
					if (doc.data().fullName) {
						delete userDetails.fullName;
					}
					docref.set({
						...doc.data(),
						...userDetails,
					});
				} else {
					registerUserInDiscourse(userDetails);
					db.collection("users")
						.doc(userDetails.uid)
						.set({ ...userDetails })
						.then((data) => {
							console.log(data, "datas");
						})
						.catch((error) => alert(error));
				}
			});
		}
	};

	const genrateRandomUserName = (email) => {
		const result = uuidv4().split("-");
		let username = email + result[1];
		if (username.length >= 20) {
			let result = username.length - 20;
			username.slice(0, -result);
		}
		return username;
	};

	const genrateRandomPassword = (email) => {
		const password = email + uuidv4();
		return password;
	};

	const continueWithGoogle = (provider) => {
		provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				let user = result.user;
				const ind = user?.email.indexOf("@");
				const userDetails = {
					fullName: user?.displayName,
					email: user?.email,
					username: genrateRandomUserName(user?.email.slice(0, ind)),
					disPassword: genrateRandomPassword(user?.email.slice(0, ind)),
					otherDetails: "other details",
					uid: user.uid,
				};
				updateUserInfo(userDetails, "register");
				setOpen(false);
			})
			.catch((error) => {
				alert(error);
			});
	};

	const joinWebinarHandler = () => {
		localStorage.setItem("joinedWebinar", true);
		setShowYoutubeLiveIframe(true);
		setOpen(false);
	};

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={open}
			onClose={() => setOpen(false)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}>
			<Container>
				<div className='logo'>
					<div>
						<img src={finalLogo} alt='img' />
						<img src={finalText} style={{ marginLeft: "15px" }} alt='' />
					</div>
				</div>
				<div className='title'>SolarHub Solar Planungsevent</div>
				<div className='webinar-time'>{webinar_time}</div>
				<div className='timer-countdown'>
					<div className='days'>
						<div>{isEnglish ? "Days" : "Tage"}</div>
						<div>{timerCountdown.days}</div>
					</div>
					<div className='hours'>
						<div>{isEnglish ? "Hours" : "Stunden"}</div>
						<div>{timerCountdown.hours}</div>
					</div>
					<div className='minutes'>
						<div>{isEnglish ? "Minutes" : "Minuten"}</div>
						<div>{timerCountdown.minutes}</div>
					</div>
					<div className='seconds'>
						<div>{isEnglish ? "Seconds" : "Sekunden"}</div>
						<div>{timerCountdown.seconds}</div>
					</div>
				</div>
				{!currentUser ? (
					<Fragment>
						<div>
							<Button
								fullWidth
								variant='contained'
								startIcon={<img style={{ marginRight: "10px" }} src={Google} alt='' />}
								className='google-login'
								onClick={(e) => continueWithGoogle()}>
								{isEnglish ? "Login with Google" : "Einloggen mit Google"}
							</Button>
						</div>
						<div className='register-button' onClick={() => setSignUpSignInPopup(true)}>
							Register
						</div>
						<div
							className='login'
							onClick={() => {
								setRegisterStatus(false);
								setSignUpSignInPopup(true);
							}}>
							<span className='yellow'>Schon registriert? </span>
							<u>Login</u>
						</div>
						<SignUpSignInPopUp
							open={signUpSignInPopup}
							setOpen={setSignUpSignInPopup}
							userLikeToSave={userLikeToSave}
							registerStatus={registerStatus}
							project={project}
							signUpForWebinar={true}
						/>
					</Fragment>
				) : (
					<div className='webinar-button' onClick={joinWebinarHandler}>
						{isEnglish ? "Join Live Webinar" : "Am Webinar teilnehmen"}
					</div>
				)}
			</Container>
		</Modal>
	);
};

export default WebinarPopup;
