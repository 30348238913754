import React, { useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Count from "Assets/Count.svg";

import "react-image-gallery/styles/css/image-gallery.css";
import { StepConnector } from "@material-ui/core";
import Building from "./partials/Building";
import Household from "./partials/Household";
import Close from "../Assets/Close.svg";
import Group from "../popups/SignUpSignIn/images/Group.svg";
import ElectroMobility from "./partials/ElectroMobility";
import Heatpump from "./partials/Heatpump";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => createStyles({});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "900px",
    padding: "0px 0px 18px",
    margin: "0px",
    height: "598px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
    [theme.breakpoints.up("md ")]: {
      width: "900px",
    },
    alignItems: "center",
  },
  space: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "494px",
    [theme.breakpoints.down("xs")]: {
      width: "94%",
      marginTop: "20px",
      marginBottom: "17px",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  row1: {
    display: "flex",
    margin: "15px 0px",
  },

  row1_img: {
    border: "2px solid white",
    margin: "5px 10px",
  },

  row1_img_highlighted: {
    border: "2px solid gray",
    margin: "5px 10px",
  },
  row2_img: {
    border: "2px solid white",
    margin: "5px 15px",
  },

  row2_img_highlighted: {
    border: "2px solid gray",
    margin: "5px 15px",
  },
  row3_img: {
    border: "2px solid white",
    margin: "5px 15px",
    padding: "0px 10px",
  },

  row3_img_highlighted: {
    border: "2px solid gray",
    margin: "5px 15px",
    padding: "0px 10px",
  },
  closeIcon: {
    cursor: "pointer",
    fontSize: "35px",
    alignSelf: "flex-end",
    margin: "15px",
    marginBottom: "-25px",
  },
  instructions: {
    height: "430px",
    display: "flex",
    width: "504px",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
    marginBottom: theme.spacing(1),
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  icon: {
    width: "30px",
    marginRight: "20px",
    marginTop: "20px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  active: {
    color: "rgba(45, 71, 100, 1) !important",
  },
  icon: {
    color: "rgba(45, 71, 100, 1) !important",
  },
  rootss: {
    // color: "rgba(45, 71, 100, 1) !important",
  },
  text: {
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },
  stepperActive: {
    borderColor: "rgba(48, 72, 100, 1) !important",
    borderTopWidth: "5px",
  },
  stepperComplete: {
    borderColor: "rgba(48, 72, 100, 1) !important",
    borderTopWidth: "5px",
  },
  completed: {
    color: "rgba(45, 71, 100, 1) !important",
  },
  customLabelStyle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  connectorLine: {
    // borderColor: 'rgba(48, 72, 100, 1)',
    borderTopWidth: "5px",
  },
  removePadding: {
    padding: "0px",
    paddingBottom: "9px",
    paddingTop: "20px",
  },
}));

// making a 4 functional commponents

//complating a four functional commponent

export default function SelectYearlyConsumption({
  open,
  setOpen,
  consumption,
  consumption_details,
  setConsumption,
  setConsumptionDetails,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [activeStep, setActiveStep] = React.useState(0);
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const [row1_seleceted_img, set_row1_selected_img] = useState(1);
  const [row2_seleceted_img, set_row2_selected_img] = useState(1);
  const [row3_seleceted_img, set_row3_selected_img] = useState(1);

  const handleClose = () => {
    setOpen(false);
  };

  const setRow1 = (index) => {
    set_row1_selected_img(index);
  };

  const setRow2 = (index) => {
    set_row2_selected_img(index);
  };

  const setRow3 = (index) => {
    set_row3_selected_img(index);
  };

  function getSteps() {
    if (isEnglish)
      return ["Building", "Household", "Electro mobility", "Heat Pump"];
    else return ["Gebäude", "Haushalt", "Elektromobilität", "Wärmepumpe"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Building
            isEnglish={isEnglish}
            setOpen={setOpen}
            consumption={consumption}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
            consumption_details={consumption_details}
          />
        );
      case 1:
        return (
          <Household
            isEnglish={isEnglish}
            setOpen={setOpen}
            consumption={consumption}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
            consumption_details={consumption_details}
          />
        );
      case 2:
        return (
          <ElectroMobility
            isEnglish={isEnglish}
            setOpen={setOpen}
            consumption={consumption}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
            consumption_details={consumption_details}
          />
        );
      default:
        return (
          <Heatpump
            isEnglish={isEnglish}
            setOpen={setOpen}
            consumption={consumption}
            setConsumption={setConsumption}
            setConsumptionDetails={setConsumptionDetails}
            consumption_details={consumption_details}
          />
        );
    }
  }
  const StepperIconCompleted = () => {
    return <img src={Count} alt="" />;
  };

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 12px)",
      right: "calc(50% + 12px)",
    },
    lineVertical: {
      height: 20,
    },
    active: {
      "& $line": {
        borderColor: "rgba(48, 72, 100, 1)",
      },
    },
    completed: {
      "& $line": {
        borderColor: "rgba(48, 72, 100, 1)",
      },
    },
    line: {
      mouse: "pointer",
      borderTopWidth: 5,
      borderRadius: 1,
    },
  })(StepConnector);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <div
        style={{
          display: "flex",
          width: "98%",
          height: "1%",
          marginTop: "10px",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <img
            onMouseEnter={() => setWhichIconShouldBeVisible(true)}
            onMouseLeave={() => setWhichIconShouldBeVisible(false)}
            src={whichIconShouldBeVisible ? Close : Group}
            className={classes.icon}
            style={{
              height: "30px",
            }}
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
      <div className={classes.root}>
        {/*
        <div className={classes.header}>
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        */}
        <Stepper
          connector={<QontoConnector />}
          style={{ width: "100%" }}
          activeStep={activeStep}
          className={classes.removePadding}
          alternativeLabel
        >
          {steps.map((label, index) => (
            <Step key={label} style={{ padding: "0px" }}>
              <StepLabel
                StepIconComponent={index === 0 && StepperIconCompleted}
                style={{ cursor: "pointer", padding: "0px" }}
                StepIconProps={{
                  classes: {
                    root: classes.rootss,
                    icon: classes.icon,
                    completed: classes.completed,
                    active: classes.active,
                    text: classes.text,
                  },
                }}
                classes={{ label: classes.customLabelStyle }}
                onClick={handleStep(index)}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div className={classes.space}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  {isEnglish ? "Back" : "zurück"}
                </Button>
                {activeStep !== 3 ? (
                  <Button
                    style={{
                      color: "white",
                      background:
                        "linear-gradient(226.42deg, #28334A 8.93%, #09152F 110.98%)",
                      borderRadius: "10px",
                    }}
                    disabled={activeStep === steps.length - 1}
                    variant="contained"
                    onClick={handleNext}
                  >
                    {isEnglish ? "Next" : "weiter"}
                  </Button>
                ) : (
                  <Button
                    onClick={() => setOpen(false)}
                    style={{
                      color: "white",
                      background:
                        "linear-gradient(226.42deg, #28334A 8.93%, #09152F 110.98%)",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                  >
                    {isEnglish ? "Take Over" : "Übernehmen"}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
