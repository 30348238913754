import { Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { useEffect, useState, Fragment } from "react";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import styled from "styled-components";

const { Option } = Select;

const singleConstants = [
  "feedInTarrif",
  "JAZ",
  "heat spring_share",
  "heat pump_share",
];

const SingleConstatnsContainer = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  gap: 28px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  .label {
    font-size: 14px;
    font-weight: 500;
  }
`;

const Constant = () => {
  const [rowData, setRowData] = useState([]);
  const [constants, setConstants] = useState({ feedInTarrif: "" });

  useEffect(() => {
    db.collection("constants")
      .doc("webConstants")
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data?.feedInTarrif) {
          setConstants({ ...data });
        }
      });
  }, []);

  useEffect(() => {
    db.collection("other_details/webConstants/germany")
      .get()
      .then((snap) => {
        let data = [];
        snap.forEach((doc) => {
          data.push(doc.data());
        });
        data.sort(compare);
        setRowData(data);
      });
  }, []);

  const saveConstants = () => {
    let isConstantNull = false;
    Object.keys(constants).map((key) => {
      if (constants[key] === "") {
        isConstantNull = true;
      }
    });

    if (!isConstantNull) {
      db.collection("constants")
        .doc("webConstants")
        .set({ ...constants })
        .then(() => console.log("updated constants"));
    } else {
      toast.warning("Constant cannot be empty");
    }
  };

  const compare = (a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  };

  const initiaValue = {
    users: [...rowData],
  };

  const onFinish = (values) => {
    db.collection("other_details/webConstants/germany")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
        const newData = [...values.users];
        const result = newData.map((item, index) => {
          db.collection("other_details/webConstants/germany/")
            .doc()
            .set(item)
            .then((data) => {})
            .catch((error) => {});
          return { ...item };
        });
        toast.success("Constant saved succesfully");
      });
  };

  return (
    <Fragment>
      {rowData.length > 0 ? (
        <Form
          name="dynamic_form_nest_item"
          style={{ width: "90%", margin: "auto" }}
          initialValues={initiaValue}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => {
                  return (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "title"]}
                        label="Name of constant"
                        labelCol={{ span: 24 }}
                        fieldKey={[fieldKey, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Name of Constant",
                          },
                        ]}
                      >
                        <Input placeholder="Name of Constant" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "price_b2b"]}
                        label="price for B2b"
                        labelCol={{ span: 24 }}
                        fieldKey={[fieldKey, "price_b2b"]}
                        rules={[
                          { required: true, message: "Missing price for B2b" },
                        ]}
                      >
                        <Input placeholder="price for B2b" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "price_b2c"]}
                        label="price for B2c"
                        labelCol={{ span: 24 }}
                        fieldKey={[fieldKey, "price_b2c"]}
                        rules={[
                          { required: true, message: "Missing price for B2c" },
                        ]}
                      >
                        <Input placeholder="price for B2c" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "Type"]}
                        labelCol={{ span: 24 }}
                        style={{ width: "208px" }}
                        label="please select any one type"
                        fieldKey={[fieldKey, "Type"]}
                        rules={[
                          {
                            required: true,
                            message: "please select any one type",
                          },
                        ]}
                      >
                        <Select style={{ width: 220 }} allowClear>
                          <Option value="Fix for project">
                            Fix for project
                          </Option>
                          <Option value="Depending on kWh">
                            Depending on kWh
                          </Option>
                          <Option value="Fix per panel">Fix per panel</Option>
                          <Option value="percentage of project cost">
                            percentage of project cost
                          </Option>
                        </Select>
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => {
                          if (fields.length === 1) {
                            toast.error(
                              "don't have to delete this last field you can simply edit it"
                            );
                          } else {
                            remove(name);
                          }
                        }}
                      />
                    </Space>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        "Wait Data is comming"
      )}
      <SingleConstatnsContainer>
        {singleConstants.map((item) => {
          return (
            <SubContainer>
              <div className="label">{item}</div>
              <InputBase
                item={item}
                value={constants[item]}
                setValue={setConstants}
              />
            </SubContainer>
          );
        })}
      </SingleConstatnsContainer>
      <Button style={buttonStyle} onClick={saveConstants} type="primary">
        Save Constants
      </Button>
    </Fragment>
  );
};
const buttonStyle = {
  margin: "auto",
  marginLeft: 60,
  marginTop: 20,
  marginBottom: 20,
};

export const InputBase = ({ item, value, setValue }) => {
  return (
    <Input
      value={value}
      onChange={(e) =>
        setValue((prev) => {
          return { ...prev, [item]: e.target.value };
        })
      }
    />
  );
};
export default Constant;
