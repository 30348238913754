import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
} from "@material-ui/core";
import React, { useState } from "react";
import { ProductPopupWrapper } from "../ProductPopupStyles";

const BatteryHeader = ({
  needToFilterList,
  isListOpen,
  setNeedToFilterList,
  setFilterData,
  list,
}) => {
  const [sliderValue, setSliderValue] = useState(1);
  function valuetext(value) {
    return `${value}kwh`;
  }
  const handleChange = (event, value) => {
    setSliderValue(value);
    const result = list.filter((data) => {
      for (let i = 0; i < data?.item?.properties?.length; i++) {
        console.log(data?.item?.applicable_max_capacity, value * 1000);
        return data?.item?.applicable_max_capacity >= value * 1000;
      }
    });
    setFilterData(result);
  };

  return (
    <ProductPopupWrapper>
      <div className="first_container">
        <div
          className="heading"
          style={{ paddingBottom: !isListOpen ? "13px" : "" }}
        >
          Battery <span>wählen sie ihre Präferenzen</span>
        </div>
        {isListOpen ? (
          <FormGroup className="check_box_contaienr">
            <div className="slider_div">
              <Slider
                aria-label="Temperature"
                defaultValue={1}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                onChange={handleChange}
                value={sliderValue}
                step={2}
                marks
                style={{ color: "#2D4764" }}
                min={0}
                max={7.7}
              />
              <p
                className="lable_text"
                style={{ padding: "0px", margin: "0px" }}
              >
                kwh
              </p>
              <div className="lable_battery">Battery Capacity</div>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Load management",
                      ]);
                    } else {
                      if (needToFilterList.includes("Load management")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Load management"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              label="Load management"
              className="changeText"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needToFilterList.includes("Data Visualisation")}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Data Visualisation",
                      ]);
                    } else {
                      if (needToFilterList.includes("Data Visualisation")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Data Visualisation"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              label="Data Visualisation"
              className="changeText"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "emergency power",
                      ]);
                    } else {
                      if (needToFilterList.includes("emergency power")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "emergency power"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              label="emergency power"
              className="changeText"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Made in Germany",
                      ]);
                    } else {
                      if (needToFilterList.includes("Made in Germany")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Made in Germany"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              label="Made in Germany"
              className="changeText"
            />
          </FormGroup>
        ) : (
          ""
        )}
      </div>
    </ProductPopupWrapper>
  );
};

export default BatteryHeader;
