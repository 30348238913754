import styled from "styled-components";

export const MapViewWrapper = styled("div")`
  .mapView_main {
    width: 1280px;
    margin: auto;
  }
  .our_main {
    width: 100%;
    display: flex;
    margin-top: 23px;
    height: 81vh;
  }
  .left_side_map {
    width: 58%;
  }
  .right_side_map {
    width: 44%;
    overflow-y: auto;
    padding: 7px;
  }
  @media (max-width: 700px) {
    .our_main {
      flex-direction: column;
    }
    .mapView_main {
      width: auto;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .right_side_map {
      width: 100%;
      height: 741px;
    }
  }
  @media (max-width: 600px) {
    .right_side_map {
      width: 100%;
      overflow: hidden;
      height: 741px;
    }
  }
  @media (max-width: 800px) {
    .our_main {
      flex-direction: column;
      height: auto;
    }
    .mapView_main {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .left_side_map {
      width: 100%;
    }
    .right_side_map {
      width: 100%;
      height: 741px;
    }
  }
  .map-outer-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .map-inside-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
`;
