import React from "react";

const Location = () => {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9_299)" filter="url(#filter0_d_9_299)">
        <path
          d="M7.92578 13.333C8.46478 13.9964 8.95535 14.6953 9.39392 15.4245C9.76838 16.1102 9.92448 16.5752 10.1981 17.4015C10.366 17.858 10.5177 17.9943 10.8438 17.9943C11.1991 17.9943 11.3604 17.7625 11.4849 17.404C11.7436 16.6237 11.9466 16.0282 12.2668 15.4655C12.8952 14.3791 13.676 13.4137 14.4432 12.4859C14.6509 12.2233 15.9938 10.6935 16.5983 9.48642C16.5983 9.48642 17.3413 8.16045 17.3413 6.30861C17.3413 4.57639 16.6086 3.375 16.6086 3.375L14.4989 3.92069L13.2176 7.17987L12.9007 7.62928L12.8373 7.71069L12.753 7.81261L12.605 7.9754L12.3936 8.17958L11.2527 9.07732L8.40028 10.668L7.92578 13.333V13.333Z"
          fill="#FFC220"
        />
        <path
          d="M4.98047 9.26434C5.67663 10.8001 7.01908 12.1502 7.92736 13.3349L12.7516 7.81556C12.7516 7.81556 12.072 8.67407 10.8391 8.67407C9.46582 8.67407 8.35641 7.61491 8.35641 6.27938C8.35641 5.36354 8.92688 4.73438 8.92688 4.73438L5.65205 5.5819L4.98047 9.26429V9.26434Z"
          fill="#FFC220"
        />
        <path
          d="M12.8065 0.281299C14.4087 0.780247 15.7801 1.82773 16.6096 3.37235L12.7541 7.80996C12.7541 7.80996 13.3246 7.16941 13.3246 6.25926C13.3246 4.89263 12.1331 3.87169 10.8459 3.87169C9.62874 3.87169 8.92969 4.72877 8.92969 4.72877V1.93313L12.8065 0.28125V0.281299Z"
          fill="#FFC220"
        />
        <path
          d="M5.86328 2.24461C6.82025 1.13876 8.50416 0 10.8257 0C11.952 0 12.8006 0.285597 12.8006 0.285597L8.92412 4.73376H6.17762L5.86328 2.24465V2.24461Z"
          fill="#FFC220"
        />
        <path
          d="M4.9803 9.26595C4.9803 9.26595 4.33984 8.0546 4.33984 6.29972C4.33984 4.64112 5.00741 3.19131 5.86404 2.24609L8.92707 4.73564L4.9803 9.26595V9.26595Z"
          fill="#FFC220"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9_299"
          x="0.339844"
          y="0"
          width="21"
          height="26"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9_299"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9_299"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_9_299">
          <rect
            width="13"
            height="18"
            fill="white"
            transform="translate(4.33984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Location;
