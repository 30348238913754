import React, { useState, useEffect } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Dialog,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { db } from "../../../firebase";
import Properties from "./DataGridColumns/properties";
import OBJECT__POPUP from "../components/popups/admin.product.popup";
import ChnageLongText from "./ChnageLongText";
import TextArea from "antd/lib/input/TextArea";
import { DataGrid } from "@mui/x-data-grid";
import { renderRatingEditInputCell } from "./DataGridColumns/commanFunction";
import CompatibleProducts from "./DataGridColumns/CompatibleProducts";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
  },
})(DataGrid);

const Products = () => {
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState([]);
  const [openText, setOpenText] = React.useState(true);
  const [selected_items, setselected_items] = useState([]);
  const [propertiesList, setpropertiesList] = useState([]);
  const [productsNames, setProductNames] = useState([]);
  const [prodcutNamesWithTypes, setProductNamesWithType] = useState([]);
  const [id, setId] = useState("");
  const [objectDetails, setObjectDetails] = useState({});

  useEffect(() => {
    db.collection("products").onSnapshot((snap) => {
      let data = [];
      snap.forEach((doc) => {
        const newData = {
          ...doc.data(),
          recommand: false,
          electric_car_consumption: doc.data()?.electric_car_consumption
            ? doc.data()?.electric_car_consumption
            : 0,
          newcolor: doc.data().color[0] ? doc.data().color[0] : "",
        };
        data.push(newData);
      });
      setRowData(data);
    });
  }, [open]);
  const PaperStyle = {
    display: "flex",
    flexDirection: "column",
    width: 500,
    padding: 40,
  };
  useEffect(() => {
    db.collection("constants")
      .doc("product")
      .get()
      .then((doc) => {
        const properties = doc.data().productPropertiesList;
        const newProperties = properties.map((prop) => {
          return { title: prop };
        });
        setpropertiesList(newProperties);
      });
  }, []);
  useEffect(() => {
    db.collection("constants")
      .doc("productsName")
      .get()
      .then((doc) => {
        const properties = doc.data().prodcutArray;
        const newProperties = properties.map((prop) => {
          return { title: prop };
        });
        setProductNames(newProperties);
      });
  }, []);
  useEffect(() => {
    db.collection("constants")
      .doc("productsNameWithType")
      .get()
      .then((doc) => {
        const properties = doc.data().prodcutArray;
        setProductNamesWithType(properties);
      });
  }, []);
  const columns = [
    {
      field: "objectId",
      width: 150,
      renderCell: (params) => (
        <strong>
          {params.value}
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setOpen(true);
              const result = rowData?.filter(
                (data, index) => data.objectId === params.id
              );
              setObjectDetails(result[0]);
              setId(params.id);
            }}
            style={{ marginLeft: 16 }}
          >
            Open
          </Button>
        </strong>
      ),
    },
    {
      field: "type",
      editable: true,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    { field: "price_b2b", editable: true, width: 110 },
    { field: "price_b2c", editable: true, width: 110 },
    { field: "buying_car_price", editable: true, width: 110 },
    { field: "electric_car_consumption", editable: true, width: 200 },
    { field: "subtype", editable: true, width: 110 },
    {
      field: "Herstellerinformation",
      editable: true,
      width: 250,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Garantie",
      editable: true,
      width: 200,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Highlights",
      editable: true,
      width: 250,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "warranty_link",
      editable: true,
      width: 250,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "Technische Produktdaten",
      editable: true,
      width: 350,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "allowed inverter",
      editable: true,
      width: 250,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "applicable_max_capacity", editable: true, width: 110 },
    { field: "capacity", editable: true, width: 110 },
    {
      field: "name",
      editable: true,
      width: 250,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "compatibel product",
      width: 400,
      renderCell: (params) => (
        <CompatibleProducts
          propertiesList={productsNames}
          prodcutNamesWithTypes={prodcutNamesWithTypes}
          setpropertiesList={setProductNames}
          params={params}
        />
      ),
    },
    {
      field: "properties",
      width: 400,
      renderCell: (params) => (
        <Properties
          propertiesList={propertiesList}
          setpropertiesList={setpropertiesList}
          params={params}
        />
      ),
    },
    {
      field: "bullet_point_list",
      editable: true,
      width: 350,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "brand",
      editable: true,
      width: 120,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "color", editable: true, width: 150 },
    { field: "extended_warranty_cost", editable: true, width: 150 },
    {
      field: "imgurl1",
      editable: true,
      width: 150,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "instock", editable: true, width: 110 },
    {
      field: "long_description",
      editable: true,
      width: 250,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "pdf_url", editable: true, width: 110 },
    {
      field: "short_description",
      editable: true,
      width: 200,
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "video_link",
      editable: true,
      width: 110,
      renderEditCell: renderRatingEditInputCell,
    },
    { field: "wallbox_charging_capacity", editable: true, width: 150 },
    { field: "imgurl2", editable: true, width: 150 },
    { field: "imgurl3", editable: true, width: 150 },
    { field: "imgurl4", editable: true, width: 150 },
    { field: "imgurl5", editable: true, width: 150 },
  ];

  const duplicateSelectedProducts = () => {
    if (selected_items.length > 0) {
      selected_items.forEach((item) => {
        const selectedRowData = rowData.filter((x) => x.objectId == item);
        const type = selectedRowData[0].type;
        const objectId = selectedRowData[0].objectId;
        const docRef = db.collection("products");
        docRef
          .where("type", "==", type)
          .get()
          .then((snapshots) => {
            let ids = [];
            let numbers = [];
            snapshots.forEach((doc) => {
              ids.push(doc.data().objectId);
              numbers.push(parseInt(doc.data().objectId.slice(1)));
            });
            numbers.sort((a, b) => a - b);
            let string = ids[ids.length - 1].substr(0, 1);
            let number = numbers[numbers.length - 1] + 1;
            let id = string + number;
            if (ids.includes(id)) {
              id = id + Math.round(Math.random() * 100).toString();
            }
            docRef
              .doc(id)
              .set({ ...selectedRowData[0], objectId: id })
              .then(() => console.log("duplicated successfully"))
              .catch((err) => console.log(err));
          });
      });
    } else {
      alert("Select Items to Duplicate");
    }
  };

  const deleteSelected = () => {
    if (selected_items) {
      selected_items.forEach((item) => {
        db.collection("products")
          .doc(item)
          .delete()
          .then(() => console.log("deleted"))
          .catch((err) => console.log(err));
      });
    } else {
      alert("Select Items to Delete");
    }
  };

  const handleChangeRow = (values, e) => {
    console.log(values);
    const result = rowData.filter((data, index) => data.objectId === values.id);
    const field = values.field;
    console.log("field", field);
    console.log("values", values);
    if (field == "bullet_point_list" || field == "Technische Produktdaten") {
      if (!Array.isArray(values.value)) {
        const bulletPoints = values.value.split(",");
        console.log("list", bulletPoints);
        let updatedRow = {
          ...result[0],
          [field]: bulletPoints,
        };
        db.collection("products")
          .doc(values?.id)
          .set({
            ...updatedRow,
          })
          .then(() => {
            console.log("changed");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (field === "newcolor") {
      let updatedRow = {
        ...result[0],
        color: [values.value],
      };
      delete updatedRow.newcolor;
      db.collection("products")
        .doc(values?.id)
        .set({
          ...updatedRow,
        })
        .then(() => {
          console.log("changed");
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (field == "applicable_max_capacity" || field == "capacity") {
      let updatedRow = {
        ...result[0],
        [field]: values.value === undefined ? 1 : Number(values.value),
      };
      delete updatedRow.newcolor;
      db.collection("products")
        .doc(values?.id)
        .set({
          ...updatedRow,
        })
        .then(() => {
          console.log("changed");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let updatedRow = {
        ...result[0],
        [field]: values.value === undefined ? "" : values.value,
      };
      delete updatedRow.newcolor;
      db.collection("products")
        .doc(values?.id)
        .set({
          ...updatedRow,
        })
        .then(() => {
          console.log("changed");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Accordion expanded={true} style={{ flex: "1", width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Product Management</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flex: "1" }}>
          <div
            style={{
              display: "flex",
              flex: "1",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1",
                justifyContent: "flex-end",
                alignItems: "center",
                margin: "15px 30px",
                gap: "22px",
                marginTop: "-10px",
              }}
            >
              <Button
                variant="contained"
                style={{ background: "#3f51b5", color: "white" }}
                onClick={duplicateSelectedProducts}
              >
                Duplicate
              </Button>
              <Button
                variant="contained"
                style={{
                  background: "red",
                  color: "white",
                }}
                onClick={deleteSelected}
              >
                Delete
              </Button>
            </div>
            <div
              className="ag-theme-alpine"
              style={{
                flex: "1",
                minHeight: "65vh",
                width: "100%",
              }}
            >
              {rowData.length > 0 ? (
                <StyledDataGrid
                  rows={rowData}
                  getRowId={(row) => row.objectId}
                  columns={columns}
                  onCellEditCommit={(values, event) => {
                    handleChangeRow(values, event);
                  }}
                  pageSize={10}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                  onSelectionModelChange={(e) => setselected_items(e)}
                />
              ) : (
                ""
              )}
            </div>
            <OBJECT__POPUP
              open={open}
              objectDetails={objectDetails}
              setObjectDetails={setObjectDetails}
              openMethod={setOpen}
              id={id}
              setId={setId}
              // newObject={isNewObject}
              // setIsNewObject={setIsNewObject}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Products;
