import { makeStyles, Typography } from "@material-ui/core";
import { Switch } from "antd";
import { Card, Button, Divider, Checkbox } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import React, { useState } from "react";
import { useEffect } from "react";
import firebase, { db } from "../../../firebase";
import * as turf from "@turf/turf";
import { colors } from "../../../colors";
import "./applicationStep4.css";
import ButtonDropdown from "../../../Components/atoms/buttons/button.dropdown";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CloseIcon from "@material-ui/icons/Close";
import { ClipLoader } from "react-spinners";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { costsAndSavings, financingDetails } from "../../../utils/costs";
import { useHistory } from "react-router-dom";
import RealizationTime from "../../../popups/realizationTime/RealizationTime";
import { commaDotHandler } from "helperMethod";
import { useDispatch, useSelector } from "react-redux";
import * as geofire from "geofire-common";
import UserSignUpDialog from "../../../popups/SignUpSignIn/signup.popup";
import { toast } from "react-toastify";
import Slider from "react-slick";
import OfferIsSelected from "./installersoffers/OfferIsSelected";
import VendorCard from "./step4Partials/VendorCard";

function CallForTender({
  project,
  quote,
  currentUser,
  isComingFromAdmin,
  dispatchMethods,
  isEnglish,
  includedServicesDialog,
  setIncludedServicesDialog,
  isModalVisible,
  setIsModalVisible,
}) {
  const [status, setStatus] = useState("searching");
  const [userSignUpDialog, setUserSignUpDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setVendor, setLoading } = dispatchMethods;
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    vendors: {
      width: "810px",
      height: "350px",
      backgroundImage:
        "url('images/application/application_step_4/searching.png')",
      display: "flex",
      "@media (max-width: 430px)": {
        width: "98%",
        boxShadow: "0px 2px 10px rgb(0 0 0 / 15%)",
        borderRadius: "12px",
      },
      justifyContent: "center",
      alignItems: "center",
    },
    arrow_icon: {
      fontSize: "50px",
      "@media (min-width: 900PX)": {
        "&:hover": {
          cursor: "pointer",
          paddingLeft: "10px",
          transition: "0.3s",
        },
      },
      "@media (max-width: 560px)": {
        fontSize: "25px",
      },
    },
    important: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 560px)": {
        width: "80%",
      },
    },
    request_realization_time: {
      width: "975px",
      height: "45px",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 430px)": {
        width: "100%",
        textAlign: "center",
      },
      background: "rgba(253, 184, 0, 0.1)",
      border: `1px solid ${colors.ctaSolid}`,
      margin: "5px 0px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: colors.ctaSolid,
      },
    },
    needToMobile: {
      width: "80%",
      height: "60%",
      marginBottom: 20,
      "@media (max-width: 430px)": {
        width: "90%",
        height: "47%",
      },
    },
  }));

  useEffect(() => {
    var options = { units: "kilometers" };

    const address = {
      id: "address.7615046705140730",
      type: "Feature",
      place_type: ["address"],
      relevance: 1,
      properties: { accuracy: "street" },
      text: "Londoner Straße",
      place_name: "Londoner Straße, 13349 Berlin, Germany",
      center: [13.3402682, 52.5608222],
      geometry: { type: "Point", coordinates: [13.3402682, 52.5608222] },
      context: [
        { id: "postcode.9568114566714510", text: "13349" },
        { id: "locality.10507900114739120", text: "Wedding" },
        {
          id: "place.14094307404564380",
          wikidata: "Q64",
          short_code: "DE-BE",
          text: "Berlin",
        },
        {
          id: "country.11437281100480410",
          wikidata: "Q183",
          short_code: "de",
          text: "Germany",
        },
      ],
    };

    const searchResult = project?.address
      ? project?.address?.geometry
      : address.geometry;
    if (project?.vendor.suggested?.length === 0) {
      setTimeout(() => {
        const center = [
          searchResult.coordinates[1],
          searchResult.coordinates[0],
        ];
        const radiusInM = 1000 * 1000;
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        console.log(bounds, "bounds");
        console.log(center, "center");
        const promises = [];
        for (const b of bounds) {
          const q = db
            .collection("installers")
            .orderBy("geohash")
            .startAt(b[0])
            .endAt(b[1]);
          promises.push(q.get());
        }

        Promise.all(promises)
          .then((snapshots) => {
            const matchingDocs = [];

            for (const snap of snapshots) {
              for (const doc of snap.docs) {
                const lat = doc.get("lat");
                const lng = doc.get("lng");

                const distanceInKm = geofire.distanceBetween(
                  [lat, lng],
                  center
                );
                const distanceInM = distanceInKm * 1000;
                if (distanceInM <= radiusInM) {
                  matchingDocs.push(doc);
                }
              }
            }

            return matchingDocs;
          })
          .then((matchingDocs) => {
            let data = [];
            matchingDocs.map((matchedDoc) => {
              if (matchedDoc.data()?.installer_status === true) {
              } else {
                data.push({
                  ...matchedDoc.data(),
                  distance: turf.distance(
                    searchResult,
                    matchedDoc.data().geometry,
                    options
                  ),
                });
              }
            });
            console.log(data, "data");
            data.sort(compare);
            data.sort(compareSec);
            console.log("installers", data.slice(0, 15));
            setVendor({ ...project?.vendor, suggested: data.slice(0, 15) });
            setStatus("found");
          });
      }, 500);
    }
  }, project);

  function compare(a, b) {
    if (a.distance < b.distance) {
      return -1;
    }
    if (a.distance > b.distance) {
      return 1;
    }
    return 0;
  }

  function compareSec(a, b) {
    if (a.admin_rating > b.admin_rating) {
      return -1;
    }
    if (a.admin_rating < b.admin_rating) {
      return 1;
    }
    return 0;
  }

  const removeSuggestion = (vendor_r) => {
    const updatedVendorList = [];
    project?.vendor?.suggested.map((vendor_s) => {
      if (vendor_s.iid !== vendor_r.iid) {
        updatedVendorList.push(vendor_s);
      }
    });
    setVendor({ ...project?.vendor, suggested: updatedVendorList });
  };
  const removeSelected = (vendor_r) => {
    const updatedVendorList = [];
    project?.vendor?.selected.map((vendor_s) => {
      if (vendor_s.objectId !== vendor_r.objectId) {
        updatedVendorList.push(vendor_s);
      }
    });
    setVendor({ ...project?.vendor, selected: updatedVendorList });
  };

  const setSelected = (vendor) => {
    setVendor({
      ...project?.vendor,
      selected: [...project?.vendor?.selected, vendor],
    });
  };

  const checkSelectedVendors = () => {
    return new Promise((resolve, reject) => {
      db.collection("matched")
        .where("pid", "==", project?.pid)
        .get()
        .then((snap) => {
          let data = [];
          snap.forEach((doc, key) => {
            data.push(doc.data().iid);
          });
          if (data.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((e) => reject(e.message));
    });
  };

  const checkout = async () => {
    if (project?.quoteStatus === "default") {
      return dispatch({ type: "SET_USER_ADRESS_STATUS", payload: true });
    }
    if (project?.quoteStatus === "notFound") {
      toast.error(
        isEnglish
          ? "Please wait for sometime. We are creating the project for your address -Please check back after some time"
          : "Wir erstellen gerade deinen Projektplan für dein Gebäude - schau dich gerne etwas um. Wir melden uns sobald die Planung fertig ist."
      );
      return;
    }
    const isVemdorSelected = await checkSelectedVendors();

    if (!isVemdorSelected) {
      toast.error("Please select atleast one vendor to checkout");
      return;
    }
    setLoading(true);
    if (currentUser) {
      const docref = db.collection("users").doc(currentUser.uid);
      docref
        .get()
        .then((doc) => {
          docref.set({ ...doc.data(), projectDetails: project }).then(() => {
            history.push("/payment");
            setLoading(false);
          });
        })
        .catch((error) => {
          alert("Error Occured");
          setLoading(false);
        });
    } else {
      setUserSignUpDialog(true);
      setLoading(false);
    }
  };
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  const [isOfferAccepted, setIsOfferAccepted] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [offerAcceptedData, setOfferAcceptedData] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    db.collection("matched")
      .where("pid", "==", project?.pid)
      .get()
      .then((snap) => {
        let data = [];
        snap.forEach((doc, key) => {
          data.push(doc.data()?.iid);
        });
        setSelectedVendors(data);
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        dispatch({ type: "SET_LOADING", payload: false });
      });
  }, [reload]);

  useEffect(() => {
    if (project?.pid) {
      db.collection("matched")
        .where("pid", "==", project?.pid)
        .get()
        .then((snapshots) => {
          const data = [];
          snapshots.forEach((doc) => {
            data.push(doc.data());
            if (
              doc?.data()?.is_project_realized &&
              doc?.data()?.realization_week?.length > 0
            ) {
              setIsOfferAccepted(true);
              setOfferAcceptedData(doc.data());
            }
          });
        })
        .catch((e) => console.log(e));
    }
  }, [project?.pid, trigger]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className={classes.arrow_icon}
        style={{
          ...style,
          display: onClick === null ? "none" : "block",
          position: "absolute",
          right: window.innerWidth > 500 ? "-56px" : "-20px",
          top: "40%",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIos
        className={classes.arrow_icon}
        style={{
          ...style,
          display: onClick === null ? "none" : "block",
          position: "absolute",
          left: window.innerWidth > 500 ? "-56px" : "-20px",
          top: "40%",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: window.innerWidth > 500 ? 3 : 1,
    slidesToScroll: 1,
    // afterChange: function (currentSlide) {
    //   console.log(currentSlide, "currentSlide");
    // },
    beforeChange: function (prev, next) {
      console.log(prev, next, "currentSlide");
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        width: "90vw",
        maxWidth: "1200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {/* <TopHeader isEnglish={isEnglish} project={project} /> */}
      {project?.payment?.status === "done" ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HorizontalNewsScrollPaid
              pid={project?.pid}
              list={project?.vendor?.suggested}
              selectedVendor={project?.vendor?.selected}
              isEnglish={isEnglish}
            />
          </div>
        </>
      ) : (
        <>
          {project?.vendor?.suggested?.length === 0 && (
            <div className={classes.vendors}>
              <ClipLoader loading={true} size={20} />
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginLeft: "15px",
                }}
              >
                {isEnglish
                  ? "We are searching for your local installation partner"
                  : "Wir suchen Ihren lokalen Installateur"}
              </Typography>
            </div>
          )}
          {project?.vendor?.suggested?.length > 0 && !isOfferAccepted ? (
            <>
              <div className={classes.needToMobile}>
                <Slider {...settings}>
                  {project?.vendor?.suggested?.length !== 0 &&
                    project?.vendor?.suggested.map((listItem, key) => {
                      return (
                        <VendorCard
                          pid={project?.pid}
                          vendor={listItem}
                          setTrigger={setTrigger}
                          setReload={setReload}
                          isComingFromAdmin={isComingFromAdmin}
                          reload={reload}
                          setSelected={setSelected}
                          selectedVendors={selectedVendors}
                          removeSuggestion={removeSuggestion}
                          isEnglish={isEnglish}
                        />
                      );
                    })}
                </Slider>
              </div>
              {/* <InstallersOffers /> */}
            </>
          ) : (
            project?.vendor?.suggested?.length > 0 && (
              <OfferIsSelected offerAcceptedData={offerAcceptedData} />
            )
          )}
        </>
      )}
      <RealizationTime open={isModalOpen} setOpen={setIsModalOpen} />
      <CostDetails
        monthlyCost={Math.round(
          financingDetails(project).yearly_financing_amount / 12
        )}
        project={project}
        payment={project?.payment}
        isFinacing={project?.isFinancing}
        checkout={checkout}
        includedServicesDialog={includedServicesDialog}
        setIncludedServicesDialog={setIncludedServicesDialog}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <UserSignUpDialog
        open={userSignUpDialog}
        setOpen={setUserSignUpDialog}
        isEnglish={isEnglish}
        project={project}
      />
    </div>
  );
}

export default CallForTender;

const CostDetails = ({
  monthlyCost,
  project,
  payment,
  checkout,
  setIsModalVisible,
  setIncludedServicesDialog,
}) => {
  const [selected, setSelected] = useState("monthly");

  const isFinancing = project.isFinancing;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "975px",
      height: "200px",
      display: "flex",
      alignItems: "center",
      "@media (max-width: 600px)": {
        flexDirection: "column",
        width: "auto",
        height: "auto",
      },
      "@media (max-width: 900px)": {
        width: "auto",
        margin: 30,
      },
      "@media (max-width: 500px)": {
        padding: "10px",
        width: "auto",
        margin: 0,
      },
      justifyContent: "space-between",
    },
    count__container: {
      fontSize: "50px",
      color: colors.darkBlue,
    },
    btn1: {
      color: colors.darkBlue,
      backgroundColor: "#fff",
      fontSize: "14px",
      textAlign: "center",
      margin: "5px 0",
      fontWeight: "bold",
      borderRadius: "50px",
      border: `1px solid #2D4764`,
      "&:hover": {
        backgroundColor: colors.darkBlue,
        border: "none",
        cursor: "pointer",
        color: "#fff",
      },
    },
    btn2: {
      color: colors.darkBlue,
      backgroundColor: "#fff",
      fontSize: "14px",
      textAlign: "center",
      margin: "5px 0",
      fontWeight: "bold",
      borderRadius: "50px",
      border: `1px solid #2D4764`,
      "&:hover": {
        backgroundColor: colors.darkBlue,
        cursor: "pointer",
        border: "none",
        color: "#fff",
      },
    },
    btn3: {
      color: colors.darkBlue,
      backgroundColor: "#FFD75D",
      fontSize: "16px",
      borderRadius: "50px",
      margin: "5px 0",
      boxShadow: "none",
      fontWeight: "bold",
      "&:hover": {
        color: colors.darkBlue,
        backgroundColor: "#FFD75D",
      },
    },
    detail__container: {
      width: "65%",
      margin: "35px 15px",
      paddingLeft: "15px",
      "@media (max-width: 560px)": {
        width: "90%",
        paddingLeft: "0px",
        margin: "18px 15px",
      },
      "& h1": {
        margin: "0px",
        fontSize: "25px",
        fontWeight: "bold",
        color: colors.darkBlue,
        "@media (max-width: 560px)": {
          width: "92%",
          fontSize: "15px",
          paddingLeft: "0px",
        },
      },
      "& p": {
        margin: "0px",
        color: colors.darkBlue,
      },
    },
    row1: {
      width: "100%",
      margin: "10px 0px 0px 0px",
      display: "flex",
      justifyContent: "space-between",
      "& h2": {
        fontSize: "20px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "15px",
        },
        fontWeight: "bold",
      },
      "& h1": {
        width: "70%",
        [theme.breakpoints.down("xs")]: {
          fontSize: "15px",
        },
        fontSize: "20px",
      },
    },
    row2: {
      width: "100%",
      margin: "0px 0px 5px 0px",
      display: "flex",
      justifyContent: "space-between",
      "& h2": {
        color: colors.orangeSolid,
        fontSize: "15px",
        fontWeight: "bold",
      },
      "& h1": { width: "70%", fontSize: "15px" },
    },
    row3: {
      width: "100%",
      margin: "5px 0px 5px 0px",
      display: "flex",
      justifyContent: "space-between",
      "& h2": { fontSize: "14px", fontWeight: "bold" },
      "& h1": { width: "70%", fontSize: "14px" },
    },
    middleWay: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0px",
      },
      flexDirection: "column",
      marginRight: "30px",
    },
    h4_container: {
      color: "#1B4963",
      fontWeight: "bold",
      fontSize: "17px",
      marginTop: "5px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "8px",
      },
    },
    financeToBuy: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        width: "100%",
      },
    },
  }));
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const financing_details = financingDetails(project);
  const [trueLogic, setTrueLogic] = useState(false);
  const costs_and_savings = costsAndSavings(project);
  const dispatch = useDispatch();
  const setIsFinancing = (data) =>
    dispatch({
      type: "SET_PROJECT",
      payload: { ...project, isFinancing: data },
    });
  //Financing costs
  const x = Math.round(financing_details.yearly_financing_amount);
  const y = Math.round(costs_and_savings.electricity_cost_before);
  const z = Math.round(costs_and_savings.feedin_savings);

  //Buying costs
  const a = Math.round(financing_details.total_project_cost);
  const b = Math.round(costs_and_savings.electricity_cost_before) * 25;
  const c = Math.round(costs_and_savings.feedin_savings) * 25;

  const getCostDetails = () => {
    if (isFinancing) {
      return selected === "monthly"
        ? [x / 12, (y + z) / 12, (y + z - x) / 12]
        : [x, y + z, y + z - x];
    } else {
      return [a, b + c, b + c - a];
    }
  };
  const costDetails = getCostDetails();

  return (
    <Card className={classes.root} elevation={2}>
      <div className={classes.detail__container}>
        <div
          className={classes.financeToBuy}
          style={{
            width:
              window.innerWidth < 500
                ? "100%"
                : isFinancing
                ? "494px"
                : "281px",
          }}
        >
          {isFinancing ? (
            <BtnRow1
              isEnglish={isEnglish}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            ""
          )}
          <h4 className={classes.h4_container}>
            {isEnglish
              ? isFinancing
                ? "Switch From Finance to buy"
                : "Switch From Buy to Finance"
              : isFinancing
              ? "Zu Kaufoption wechseln"
              : "Zu Finanzierungsoption wechseln"}
          </h4>
          <Switch
            style={{
              background: isFinancing ? "#FFD75D" : "#1B4963",
            }}
            defaultChecked
            onChange={() => {
              setIsFinancing(trueLogic);
              setTrueLogic(!trueLogic);
            }}
          />
        </div>
        <div className={classes.row1}>
          <h2>
            {commaDotHandler(
              Math.floor(costDetails[0]),
              `${isEnglish ? "en" : "de"}`
            )}
            <span> €</span>
          </h2>
          <h1>
            {isFinancing
              ? isEnglish
                ? "FINANCING RATE"
                : "Finanzierungsrate"
              : isEnglish
              ? "PROJECT COST"
              : "Projektkosten"}
          </h1>
        </div>
        <div className={classes.row2}>
          <h2>
            {commaDotHandler(
              Math.floor(costDetails[1]),
              `${isEnglish ? "en" : "de"}`
            )}
            <span> €</span>
          </h2>
          <h1>
            {isEnglish
              ? "Estimated Returns And Savings"
              : "Erwartete Rückzahlungen und Einsparungen"}
          </h1>
        </div>
        <Divider
          style={{ color: colors.darkBlue, width: "80%", height: "2px" }}
        />
        <div className={classes.row3}>
          <h2>
            {commaDotHandler(
              Math.floor(costDetails[2]),
              `${isEnglish ? "en" : "de"}`
            )}
            <span> €</span>
          </h2>
          <h1>
            {isEnglish ? "Estimated Total Earnings" : "Erwarteter Gewinn"}
          </h1>
        </div>
      </div>
      <div className={classes.middleWay}>
        <Button
          variant="contained"
          className={classes.btn1}
          onClick={() => setIsModalVisible(true)}
        >
          {isEnglish ? "DETAILED CALCULATION" : "Detaillierte Kalkulationen"}
        </Button>
        <Button
          variant="contained"
          className={classes.btn2}
          onClick={() => setIncludedServicesDialog(true)}
        >
          {isEnglish ? "INCLUDED SERVICES" : "Beinhaltete Leistungen"}
        </Button>
        {project?.payment?.status === "succeeded" ? (
          ""
        ) : (
          <Button
            variant="contained"
            className={classes.btn3}
            onClick={() => checkout()}
          >
            {isEnglish ? "Proceed to checkout" : "Weiter zum Checkout"}
          </Button>
        )}
      </div>
    </Card>
  );
};

const BtnRow1 = ({ selected, setSelected, isEnglish }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "175px",
      height: "38px",
      padding: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "@media (max-width:560px)": {
        width: "100%",
      },
      border: `1px solid ${colors.darkBlue}`,
      borderRadius: "25px",
      backgroundColor: "#fff",
      mouse: "pointer",
    },
    btn1: {
      width: "50%",
      height: "100%",
      fontSize: "14px",
      color: "#fff",
      fontWeight: "medium",
      borderRadius: "50px",
      margin: "5px 0",
      color: selected === "monthly" ? "#fff" : "black",
      backgroundColor: selected === "monthly" && "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      mouse: "pointer",
      "&:hover": {
        color: "#fff",
        cursor: "pointer",
        backgroundColor: "black",
      },
    },
    btn2: {
      width: "50%",
      height: "100%",
      color: selected === "yearly" ? "#fff" : "black",
      backgroundColor: selected === "yearly" && "black",
      fontSize: "14px",
      borderRadius: "50px",
      margin: "5px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        color: "#fff",
        cursor: "pointer",
        backgroundColor: "black",
      },
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div onClick={() => setSelected("monthly")} className={classes.btn1}>
        {isEnglish ? "Monthly" : "Monatlich"}
      </div>
      <div onClick={() => setSelected("yearly")} className={classes.btn2}>
        {isEnglish ? "Yearly" : "Jährlich"}
      </div>
    </div>
  );
};

export const HorizontalNewsScrollPaid = ({
  pid,
  list,
  removeSuggestion,
  isEnglish,
}) => {
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    firebase
      .database()
      .ref("matched")
      .orderByChild("pid")
      .equalTo(pid)
      .on("value", (snapshot) => {
        let data = [];
        for (let item in snapshot.val()) {
          data.push(snapshot.val()[item]?.iid);
        }
        setSelectedVendors(data);
      });
  }, [reload]);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "70vw",
      maxWidth: "810px",
      "@media (max-width: 560px)": {
        width: "328px",
        marginLeft: "16px",
      },
    },
    scroll_container: {
      display: "flex",
      overflow: "hidden",
      width: "810px",
      minWidth: "270px",
      "@media (max-width: 560px)": {
        width: "270x",
      },
    },
    arrow_icon: {
      fontSize: "50px",
      "&:hover": {
        cursor: "pointer",
        paddingLeft: "10px",
        transition: "0.3s",
      },
      "@media (max-width: 560px)": {
        fontSize: "25px",
      },
    },
  }));
  const elemPrefix = "test";
  const getId = (index) => `${elemPrefix}${index}`;
  const getItems = () =>
    Array(20)
      .fill(0)
      .map((_, ind) => ({ id: getId(ind) }));
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const [items] = React.useState(getItems);

  const contentWrapper = React.useRef(null);
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "80vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ArrowBackIos
          className={classes.arrow_icon}
          onClick={() => {
            sideScroll(contentWrapper.current, 10, 305, -5);
          }}
        />
        <div className={classes.scroll_container} ref={contentWrapper}>
          {list.map((listItem) => {
            if (selectedVendors.includes(listItem.iid)) {
              return <VendorCardPaid vendor={listItem} isEnglish={isEnglish} />;
            }
          })}
        </div>

        <ArrowForwardIos
          className={classes.arrow_icon}
          onClick={() => {
            sideScroll(contentWrapper.current, 10, 415, 5);
          }}
        />
      </div>
    </>
  );
};

const VendorCardPaid = ({ vendor, isEnglish }) => {
  const { address, admin_rating, name, logo_url, distance } = vendor;
  const [fireWhenMouseIsEnterd, setFireWhenMouseIsEnterd] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      borderTop: colors.ctaSolid,
      backgroundColor: "#fff",
      minWidth: "250px",
      margin: "10px",
      display: "flex",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.03)",
      },
      flexDirection: "column",
      alignItems: "center",
      zIndex: "0",
    },
    root2: {
      width: "250px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: "0",
    },
    close_header: {
      width: "97%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: "0",
    },
    logoImage: {
      height: "50px",
      width: "150px",
      objectFit: "fill",
    },
    divider: {
      height: "2px",
      width: "90%",
      background:
        "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
      margin: "5px",
    },
    buttons: {
      width: "100%",
      display: "flex",
      "@media (max-width: 430px)": {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    button1: {
      backgroundColor: colors.darkBlue,
      color: "#fff",
      borderRadius: "3px",
      width: "50%",
      margin: "5px",
      "@media (max-width: 430px)": {
        width: "70%",
      },
    },
    button2: {
      backgroundColor: "#fff",
      color: colors.darkBlue,
      borderRadius: "3px",
      "@media (max-width: 430px)": {
        width: "70%",
      },
      width: "50%",
      border: `1px solid ${colors.darkBlue}`,
      margin: "5px",
    },
    closeIcom: {
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.5)",
        transition: "0.3s",
      },
    },
    vendorTopLine: {
      width: "100%",
      height: "41px",
      background:
        "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
    },
    text_nano: {
      display: "flex",
      fontSize: "14px",
      margin: "5px 15px",
      "@media (max-width: 430px)": {
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Card
      elevation={"3"}
      className={classes.root}
      onMouseEnter={() => setFireWhenMouseIsEnterd(!fireWhenMouseIsEnterd)}
      onMouseLeave={() => setFireWhenMouseIsEnterd(!fireWhenMouseIsEnterd)}
    >
      <div className={classes.close_header}>
        <Checkbox
          checked={true}
          disabled
          name="checkedB"
          style={{
            background: fireWhenMouseIsEnterd
              ? "linear-gradient(315deg, #F9EEFE 0%, #E8F5FF 100%)"
              : "",
          }}
          color="primary"
        />
        <CloseIcon style={{ zIndex: "1" }} className={classes.closeIcom} />
      </div>
      <img className={classes.logoImage} src={logo_url} />
      <div className={classes.root2}>
        <Rating name="half-rating-read" value={admin_rating} readOnly />
        <Divider className={classes.divider} />
        <ButtonDropdown style={{ margin: "15px" }}>
          {isEnglish ? "Scope of work" : "Leistungsumfand"}
        </ButtonDropdown>
        <Typography style={{ margin: "5px", height: "50px" }}>
          <strong>{name}</strong>
        </Typography>
        <div>
          <Typography className={classes.text_nano}>
            <LocationOnIcon style={{ color: colors.orangeSolid }} />
            {address}
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <Typography className={classes.text_nano}>
          {Math.round(distance)} km Entfernung zu ihrem Dach
        </Typography>
      </div>
    </Card>
  );
};
