import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
} from "@material-ui/core";
import React, { useState } from "react";
import { ProductPopupWrapper } from "../ProductPopupStyles";

const InverterHeader = ({
  needToFilterList,
  isListOpen,
  setNeedToFilterList,
  setFilterData,
  list,
}) => {
  function valuetext(value) {
    return `${value}`;
  }
  const [sliderValue, setSliderValue] = useState(1);

  const handleChange = (event, value) => {
    setSliderValue(value);
    const result = list.filter((data) => {
      console.log(data?.item?.applicable_max_capacity, value * 1000);
      return data?.item?.applicable_max_capacity >= value * 1000;
    });
    setFilterData(result);
  };
  return (
    <ProductPopupWrapper>
      <div className="first_container">
        <div
          className="heading"
          style={{ paddingBottom: !isListOpen ? "13px" : "" }}
        >
          inverter <span>Wählen sie ihre Präferenzen</span>
        </div>
        {isListOpen ? (
          <FormGroup className="check_box_contaienr">
            <div className="slider_div">
              <Slider
                aria-label="Temperature"
                defaultValue={1}
                getAriaValueText={valuetext}
                onChange={handleChange}
                value={sliderValue}
                valueLabelDisplay="auto"
                step={2}
                marks
                style={{ color: "#2D4764" }}
                min={0}
                max={7.7}
              />
              <div className="lable_battery">System Capacity</div>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Hybrid (combinable with battery)",
                      ]);
                    } else {
                      if (
                        needToFilterList.includes(
                          "Hybrid (combinable with battery)"
                        )
                      ) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Hybrid (combinable with battery)"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Hybrid (combinable with battery)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needToFilterList.includes("Data Visualisation")}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Data Visualisation",
                      ]);
                    } else {
                      if (needToFilterList.includes("Data Visualisation")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Data Visualisation"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Data Visualisation"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Load management",
                      ]);
                    } else {
                      if (needToFilterList.includes("Load management")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Load management"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Load management"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "made in europe",
                      ]);
                    } else {
                      if (needToFilterList.includes("made in europe")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "made in europe"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="made in europe"
            />
          </FormGroup>
        ) : (
          ""
        )}
      </div>
    </ProductPopupWrapper>
  );
};

export default InverterHeader;
