import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Heater from "../../Assets/Heater.png";
import man from "../../Assets/man.png";
import { YearlyConsumptionInput } from "./Building";
import { calculated_consumption } from "utils/consumption";
import setCommaDot, { parseLocaleNumber, commaDotHandler } from "helperMethod";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    maxHeight: 345,
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  option_container: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input_main: {
    height: "130px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  },
  button: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Image = (props) => {
  return (
    <>
      <img src={props.src} alt="" />
    </>
  );
};

const Heatpump = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );
  const classes = useStyles();
  const { isHeatPump } = consumption_details;
  const setHeatPump = (checked) => {
    let heat;
    if (checked === "top") {
      heat = true;
      setConsumptionDetails({ ...consumption_details, isHeatPump: heat });
      setTemp1(
        calculated_consumption({ ...consumption_details, isHeatPump: heat })
      );
    } else {
      setConsumptionDetails({ ...consumption_details, isHeatPump: checked });
      setTemp1(
        calculated_consumption({ ...consumption_details, isHeatPump: checked })
      );
    }
  };
  return (
    <div style={{ marginTop: "50px" }}>
      <h4 className={classes.center}>
        {isEnglish
          ? "Are you planning to use an electric heatpump to optimize your energy usage?"
          : "Planst du eine Wärmepumpe in deinem Haus einzusetzen um den PV Strom auch für die Heizung nutzbar zu machen?"}
      </h4>
      <div className={classes.option_container}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {/* <div> */}
            {/* <div style={{   }}>
                
              </div> */}
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={isHeatPump}
                  onChange={(e) => setHeatPump(e.target.value)}
                />
              }
              label={
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    gap: 8,
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {isEnglish ? "in use / planned" : "vorhanden/ geplant"}
                  </div>
                  <div>
                    <Image src={Heater} />
                  </div>
                </div>
              }
              labelPlacement="top"
            />
            {/* </div> */}
            {/* <div> */}
            {/* <div style={{  }}>
                
              </div> */}
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={!isHeatPump}
                  onChange={(checked) => setHeatPump(!checked)}
                />
              }
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>{isEnglish ? "not planned" : "nicht geplant"}</div>
                  <div>
                    <Image src={man} />
                  </div>
                </div>
              }
              labelPlacement="top"
            />
            {/* </div> */}
          </FormGroup>
        </FormControl>
      </div>
      <YearlyConsumptionInput
        consumption={consumption}
        setConsumption={setConsumption}
        temp1={temp1}
        setTemp1={setTemp1}
      />
    </div>
  );
};

export default Heatpump;
