export const setCurrentUser = (currentUser) => ({
	type: "SET_CURRENT_USER",
	payload: currentUser,
});

export const setCurrentUserLoading = (status) => {
	console.log("called setCurrentUserLoading in actions with : ", status);
	return {
		type: "SET_CURRENT_USER_LOADING",
		payload: status,
	};
};
