import React from "react";
import axios from "axios";

var hubspotAxios = axios.create({
  baseURL:
    "https://us-central1-solarhub-44450.cloudfunctions.net/api/hubspotapi/",
  timeout: 10000,
  headers: { "X-Custom-Header": "foobar" },
});

const fetchListHttp = (itemType) => {
  return new Promise((resolve, reject) => {
    hubspotAxios
      .get(itemType)
      .then((res) => {
        console.log(`fetched ${itemType} data`, res);
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const useHubspot = () => {
  return { fetchListHttp };
};
