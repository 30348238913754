import React, { useEffect, useState } from "react";
import styled from "styled-components";
import cross from "Assets/community/cross.svg";
import sun from "Assets/sun.svg";
import firstProfile from "Assets/community/firstProfile.svg";
import secoundContainer from "Assets/community/secoundContainer.svg";
import whiteSave from "Assets/community/whiteSave.svg";
import axios from "axios";
import { useSelector } from "react-redux";

const HeaderWrapper = styled("div")`
  .maindiv {
    height: 143px;
    width: 644px;
    margin-left: 34px;
    margin-right: 34px;
    margin-top: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #2d4764;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  .sun {
    position: absolute;
    left: 0px;
    top: 3px;
  }
  .cross {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 13px;
  }
  .seocund_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
  .first_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
    line-height: 30px;
    color: #ffffff;
  }
  .first_container {
    display: flex;
    flex-direction: column;
    margin-left: 37px;
    padding-right: 13px;
    justify-content: space-around;
    flex: 3;
    align-items: flex-start;
  }
  .horizontalLine {
    height: 123px;
    background-color: #ffffff;
    width: 0.848764px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .secound_container {
    display: flex;
    flex: 3;
    padding-right: 10px;
    justify-content: space-around;
    align-items: center;
  }
  .same_style {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 51px;
    height: 83px;
    img {
      padding-bottom: 10px;
    }
    div {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
    section {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
    }
  }
  @media (max-width: 600px) {
    .maindiv {
      display: flex;
      width: 95%;
      margin: auto;
      margin-top: 7px;
    }
    .secound_container {
      display: none;
    }
  }
`;

const Header = ({ setIsHeadrThere, isHeaderThere }) => {
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(true);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  const userName = useSelector(
    (state) => state?.user?.currentUser?.displayName
  );
  const firstName = userName?.split(" ");

  useEffect(() => {
    const func = async () => {
      try {
        const res = await axios.get(
          "https://us-central1-solarhub-44450.cloudfunctions.net/api/general/statistic"
        );

        setData(res.data);
        setVisible(true);
      } catch (error) {
        console.error(error, "error");
      }
    };

    func();
  }, []);
  return (
    <HeaderWrapper>
      <div
        className="maindiv"
        style={{ display: isHeaderThere ? "none" : visible ? "flex" : "none" }}
      >
        <img src={sun} className="sun" alt="" />
        <img
          src={cross}
          onClick={() => {
            setVisible(false);
            setIsHeadrThere(true);
          }}
          className="cross"
          alt=""
        />
        <div className="first_container">
          <div className="first_text">
            {isEnglish ? `Welcome${" "}` : `Willkommen${" "}`}
            {firstName ? (
              <span style={{ color: "#ffbf1b", textTransform: "capitalize" }}>
                {firstName[0]}
              </span>
            ) : (
              ""
            )}
            , {isEnglish ? "to the Solar Community" : "in der Solar Community"}
          </div>
          <div className="seocund_text">
            {isEnglish
              ? "We are here to help. Find solutions, share ideas, and follow discussions"
              : "Hier versuchen wir uns gegenseitig bei der Realisierung von Solarprojekten zu Helfen. Schön dass du dabei bist!"}
          </div>
        </div>
        {data !== null ? (
          <div className="secound_container">
            <div className="same_style">
              <img src={firstProfile} alt="" />
              <section>{data.userCount}+</section>
              <div>{isEnglish ? "Members" : "Mitglieder"}</div>
            </div>
            <div className="same_style">
              <img src={secoundContainer} alt="" />
              <section>{data.topicCount}+</section>
              <div>{isEnglish ? "Topics" : "Mitglieder"}</div>
            </div>
            <div className="same_style">
              <img src={whiteSave} alt="" />
              <section>{data.postCount}+</section>
              <div>{isEnglish ? "Solutions" : "Lösungen"}</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </HeaderWrapper>
  );
};

export default Header;
