import { combineReducers } from "redux";
import languageReducer from "./language/language.reducer";

import userReducer from "./user/user.reducer";

import projectReducer from "./project/project.reducer";

import quoteReducer from "./quote/quote.reducer";

import chatReducer from "./chat/chat.reducer";
import notificationReducer from "./notification/notification.reducer";
import savedTopicReducer from "./Community/SavedTopicReducer";
import categoryReducer from "./Community/SavedCategory";
export default combineReducers({
  user: userReducer,
  global: languageReducer,
  project: projectReducer,
  quote: quoteReducer,
  chat: chatReducer,
  notification: notificationReducer,
  savedTopic: savedTopicReducer,
  savedCategory: categoryReducer,
});
