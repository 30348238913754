import { Typography, Divider, Button } from "@material-ui/core";
import { db } from "../../../../firebase";
import { useProjectTools } from "hooks/useProjectTools";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "pages/AdminPanel/components/Card/Card";
import { Rate } from "antd";
import CloseIcon from "@material-ui/icons/Close";
import ProjectPopup from "popups/SignUpSignIn/signup.popup";
import { useStyles } from "./VendorCardStyles";
import emailjs from "emailjs-com";
import Share from "Assets/application_step_1/Share.svg";
import ButtonDropdown from "Components/atoms/buttons/button.dropdown";
import apiKey from "emailKey";
import chart from "Assets/application_step_1/chart.svg";
import { toast } from "react-toastify";
import Location from "./Location";

const VendorCard = ({
  vendor,
  setReload,
  reload,
  isComingFromAdmin,
  selectedVendors,
  setSelected,
  removeSuggestion,
  isEnglish,
  setTrigger,
}) => {
  const {
    address,
    company_name,
    logo_url,
    admin_rating,
    distance,
    telephone,
    email,
  } = vendor;
  const selected = selectedVendors.includes(vendor.iid);
  const pid = useSelector((state) => state.project.pid);
  const [fireWhenMouseIsEnterd, setFireWhenMouseIsEnterd] = useState(false);
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [isMatchedOffer, setIsMatchedOffer] = useState([]);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const { save } = useProjectTools();
  const project = useSelector((state) => state.project);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (pid && vendor.iid) {
      db.collection("matched")
        .where("pid", "==", pid)
        .where("iid", "==", vendor.iid)
        .get()
        .then((snapshots) => {
          const data = [];
          snapshots.forEach((doc) => {
            data.push(doc.data());
          });
          setIsMatchedOffer(data);
        })
        .catch((e) => console.log(e));
    }
  }, [pid, vendor.id]);

  const setOneOfSelected = (vendor) => {
    if (!currentUser) {
      return setOpen(true);
    }
    if (project?.quoteStatus === "default") {
      return dispatch({ type: "SET_USER_ADRESS_STATUS", payload: true });
    }
    if (project?.quoteStatus === "notFound") {
      toast.error(
        isEnglish
          ? "Please wait for sometime. We are creating the project for your address -Please check back after some time"
          : "Wir erstellen gerade deinen Projektplan für dein Gebäude - schau dich gerne etwas um. Wir melden uns sobald die Planung fertig ist."
      );
      return;
    }
    if (
      project?.quoteStatus === "found" &&
      !window?.location.pathname.includes("saved")
    ) {
      return save(project);
    }
    db.collection("matched")
      .doc(vendor.iid + pid)
      .set({
        iid: vendor.iid,
        pid: pid,
        status: "one of selected",
        is_payment_done: false,
        is_project_realized: false,
      })
      .then(() => {
        dispatch({ type: "SET_LOADING", payload: true });
        setReload(!reload);
        var templateParams = {
          from_name: "Solarhub",
          to_name: "david!",
          installerId: vendor.iid,
          userName: currentUser.displayName,
          userEmail: currentUser.email,
          installerAdress: address,
          installerName: company_name,
          installerPhonenumber: telephone,
          installerEmail: email,
          projectPid: pid,
          message:
            "This all are the user and installer details now you can contect them",
        };
        emailjs
          .send(
            "service_nci41fe",
            apiKey.TEMPLATE_ID,
            templateParams,
            apiKey.USER_ID
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
      })
      .catch((e) => {
        alert(e.message);
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };
  const removeOneOfSelected = (vendor) => {
    if (pid === "project123") {
      alert("Please save the project to select installers");
      return;
    }
    db.collection("matched")
      .doc(vendor.iid + pid)
      .delete()
      .then(() => {
        dispatch({ type: "SET_LOADING", payload: true });
        setReload(!reload);
      })
      .catch((e) => {
        dispatch({ type: "SET_LOADING", payload: true });
        alert(e.message);
      });
  };

  const acceptOffer = (somee) => {
    console.log("running");
    const docRef = db.collection("matched").doc(somee.iid + pid);
    docRef
      .get()
      .then((doc) => {
        const data = doc.data();
        const updatedData = {
          ...doc.data(),
          is_project_realized: true,
        };
        docRef.set(updatedData);
        setTrigger(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ width: "94%" }}>
      <Card
        elevation={"3"}
        className={classes.root}
        onMouseEnter={() => setFireWhenMouseIsEnterd(!fireWhenMouseIsEnterd)}
        onMouseLeave={() => setFireWhenMouseIsEnterd(!fireWhenMouseIsEnterd)}
      >
        <div className={classes.header_main}>
          <div className={classes.center}>
            <img src={Share} alt="" style={{ width: 20, height: 20 }} />
          </div>
          <img src={logo_url} alt="" className={classes.imgeStyle} />
          <CloseIcon
            className={classes.closeIcom}
            style={{ zIndex: "1", marginRight: "10px" }}
            onClick={() => removeSuggestion(vendor)}
          />
        </div>
        <div className="border_important"></div>
        <div className={classes.root2}>
          <div className={classes.first_part}>
            <div className={classes.text}>
              {company_name.length > 40
                ? `${company_name.substr(0, 40)}...`
                : company_name}
            </div>
          </div>
          <Rate allowHalf value={5} disabled />
          <Divider
            className={classes.divider}
            style={{
              background:
                "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
            }}
          />
          <ProjectPopup
            open={open}
            setOpen={setOpen}
            project={project}
            onAutomateiclySave={project?.quoteStatus === "found" ? true : false}
          />
          {isComingFromAdmin ? (
            ""
          ) : (
            <ButtonDropdown
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              style={{ margin: "10px" }}
            >
              {isEnglish ? "Scope of work" : "Leistungsumfang"}
            </ButtonDropdown>
          )}
          {isComingFromAdmin && selected ? (
            <>
              <div className={classes.text}>{telephone}</div>
              <div className={classes.text}>{email}</div>
            </>
          ) : (
            ""
          )}
          <div className={classes.text}>Referenzprojekte einsehen</div>
          <div>
            <Typography
              style={{ display: "flex", fontSize: "14px", margin: "5px 15px" }}
            >
              <Location />
              {address}
            </Typography>
          </div>
          <Divider
            className={classes.divider}
            style={{
              height: "2px",
              background:
                "linear-gradient(168.55deg, #FBDA61 68.9%, #FAD44C 84.53%, #EE7D3E 113.04%, #EE7D3E 129.72%)",
            }}
          />
          <Typography
            style={{ display: "flex", fontSize: "14px", margin: "5px 15px" }}
          >
            {Math.round(distance)} km Entfernung zu ihrem Dach
          </Typography>
          {isMatchedOffer?.length > 0 &&
          isMatchedOffer[0]?.realization_week_short ? (
            <>
              <div className={classes.bigClass}>
                Proposed realization in week
              </div>
              <div className={classes.celender}>
                <img src={chart} alt="" />
                <div className={classes.text1}>
                  {isMatchedOffer[0]?.realization_week_short}
                </div>
              </div>
            </>
          ) : (
            " "
          )}
          {isMatchedOffer?.length > 0 &&
          isMatchedOffer[0]?.realization_week_short ? (
            <Button
              className={classes.buttonb}
              onClick={() => acceptOffer(vendor)}
            >
              Accept offer
            </Button>
          ) : (
            <Button
              className={classes.button2}
              onClick={() => {
                if (selected) {
                  return;
                }
                setOneOfSelected(vendor);
                setSelected(vendor);
              }}
            >
              {selected
                ? isEnglish
                  ? "waiting for confirmation"
                  : "Warte auf Angebot"
                : isEnglish
                ? "Request realization time"
                : "Umsetzung anfragen"}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default VendorCard;
