import { useRef, useState, useEffect } from "react";
import MapboxPopup from "./popup";
import ReactDOM from "react-dom";
//@ts-ignore
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "./fullMap.css";
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import { useSelector } from "react-redux";

mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

mapboxgl.accessToken =
  "pk.eyJ1Ijoicmx4MDA3IiwiYSI6ImNrcjdvMnlraDJ0ajEycXM2eXZrdng0c3QifQ.YiawYqp5iXxltusxukhNNw";
const styles = {
  0: "mapbox://styles/rlx007/ckrathp1o6llb17mubti98v10",
  1: "mapbox://styles/mapbox/satellite-v9",
};
const FullMap = ({ savedProjects }) => {
  const mapContainer = useRef(null);
  const whichStyle = useSelector((state) => state?.global?.whichStyleForMap);
  const map = useRef(null);
  const [lng, setLng] = useState(7.9591355);
  const [lat, setLat] = useState(47.9814838);
  const [zoom, setZoom] = useState(9);
  const [geoJsonData, setgeoJsonData] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    const fire = savedProjects?.map((data) => {
      const news = {
        type: "Feature",
        properties: {
          electricity_cost_before: data?.costSaving?.electricity_cost_before,
          feedin_savings: data?.costSaving?.feedin_savings,
          total_savings: data?.costSaving?.total_savings,
          consumption: data?.energysDetails?.consumption,
          feed_into_grid: data?.energysDetails?.feed_into_grid,
          total_production: data?.energysDetails?.total_production,
          total_self_supply: data?.energysDetails?.total_self_supply,
          duration_in_years: data?.financialDetails?.duration_in_years,
          total_financing_amount:
            data?.financialDetails?.total_financing_amount,
          total_interest: data?.financialDetails?.total_interest,
          total_project_cost: data?.financialDetails?.total_project_cost,
          complate_address: data?.project_details?.address?.complete,
          projectImgUrl: data?.projectImgUrl,
          pid: data?.pid,
          qrn: data?.grn,
          roof_type: data?.updatedData?.roof_type,
          roof_material: data?.updatedData?.roof_material,
          roof_tilt: data?.updatedData?.roof_tilt,
          roof_material: data?.updatedData?.roof_material,
        },
        geometry: data?.project_details?.address?.geometry,
      };
      return news;
    });
    const realMapData = {
      type: "FeatureCollection",
      features: fire,
    };
    setgeoJsonData(realMapData);
    setIsLoad(!isLoad);
  }, [savedProjects]);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: whichStyle ? styles[0] : styles[1],
      center: [lng, lat],
      zoom: zoom,
    });
  }, [map, whichStyle]);

  useEffect(() => {
    if (!map.current) return;
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [map]);

  const newFunction = () => {
    if (!map.current) return;

    map.current.on("load", () => {
      const id = "some";
      if (map.current.getLayer(id)) {
        map.current.removeLayer(id);
      }
      if (map.current.getSource(id)) {
        map.current.removeSource(id);
      }
      map.current.addSource("some", {
        type: "geojson",
        data: geoJsonData,
      });

      map.current.addLayer({
        id: "some",
        type: "circle",
        source: "some",
        paint: {
          "circle-color": "#4264fb",
          "circle-radius": 10,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#ffffff",
        },
      });
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
    });

    map.current.on("mouseenter", "some", (e) => {
      map.current.getCanvas().style.cursor = "pointer";
      const placeholder = document.createElement("section");
      placeholder.classList.add("popup-card");
      ReactDOM.render(
        <MapboxPopup
          style={{ padding: "0px" }}
          properties={e.features[0].properties}
        />,
        placeholder
      );
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup
        // .setLngLat(coordinates)
        .setDOMContent(placeholder)
        .addTo(map.current);
    });
  };

  useEffect(() => {
    newFunction();
  }, [map, geoJsonData, whichStyle]);

  return (
    <>
      <div style={{ width: "40%", height: "calc(100vh - 84px)" }}>
        <div ref={mapContainer} style={{ height: "100%", width: "100%" }} />
      </div>
    </>
  );
};

export default FullMap;
