import React from "react";
import { Button } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { colors } from "../../../colors";

function ButtonDropdown({ children, showDetails, setShowDetails, style }) {
  const defaultStyle = {
    backgroundColor: "#fff",
    color: colors.darkBlue,
    fontWeight: "bold",
    alignSelf: "center",
    height: "32px",
    border: "1px solid #DFDFDF",
  };
  return (
    <Button
      variant={"outlined"}
      onClick={() => setShowDetails(!showDetails)}
      style={{ ...defaultStyle, ...style }}
    >
      {children}
      {showDetails ? (
        <ArrowDropDownIcon style={{ fontSize: "25px", marginLeft: "5px" }} />
      ) : (
        <ArrowRightIcon style={{ fontSize: "25px", marginLeft: "5px" }} />
      )}
    </Button>
  );
}

export default ButtonDropdown;
