import React, { useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Close from "Assets/Close.svg";
import Group from "../popups/SignUpSignIn/images/Group.svg";
import CloseIcon from "@material-ui/icons/Close";

import TextField from "@material-ui/core/TextField";

import "react-image-gallery/styles/css/image-gallery.css";
import { Card, CardMedia } from "@material-ui/core";
import Building from "./partials/Building";
import Household from "./partials/Household";
import ElectroMobility from "./partials/ElectroMobility";
import Heatpump from "./partials/Heatpump";
import { colors } from "../colors";
import SingleImg from "../Assets/application_step_1/consumption.building.1.png";
import MultiImg from "../Assets/application_step_1/consumption.building.2.png";
import { Input } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  calculated_consumption,
  consumption_profiles_b2b,
} from "utils/consumption";
import setCommaDot from "helperMethod";
const styles = (theme) => createStyles({});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "900px",
    padding: "25px 0px",
    overflow: "auto",
    overflowX: "hidden",
    margin: "0px",
    "@media (max-width: 560px)": {
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  main_container: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  inside_main: {
    minWidth: "250px",
    maxWidth: "250px",
    margin: "5px",
    height: "140px",
    border: `1px solid #2D4764`,
    borderRadius: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width: 530px)": {
      marginBottom: "10px",
    },
    "& img": {
      height: "64px",
      marginTop: "20px",
      width: "76px",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(253, 184, 0, 0.1)",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  headerText: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h1": {
      color: colors.darkBlue,
      "@media (max-width: 530px)": {
        fontSize: "22px",
      },
    },
    "& h3": {
      color: colors.darkBlue,
      fontSize: "18px",
      marginBottom: "15px",
      "@media (max-width: 530px)": {
        fontSize: "15px",
      },
    },
  },
  button: {
    backgroundColor: colors.darkBlue,
    color: "#fff",
    margin: "10px",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
    input_main: {
      height: "151px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px",
    },
    inside_inputMain: {
      backgroundColor: " #EAEAEA",
      width: "289px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "20px",
    },
  },
}));

// making a 4 functional commponents

//complating a four functional commponent

export default function SelectYearlyConsumption({
  open,
  setOpen,
  consumption,
  consumption_details,
  setConsumption,
  setConsumptionDetails,
}) {
  const classes = useStyles();
  const project = useSelector((state) => state.project);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const [consumption_profile, setConsumption_profile] = useState(
    consumption_details.consumption_profile
  );
  const handleClose = () => {
    setOpen(false);
  };
  const setConsumptionProfile = (i) => {
    setConsumption_profile(i);
    setConsumptionDetails({
      ...consumption_details,
      consumption_profile: i,
      self_supply: parseFloat(consumption_profiles_b2b[i].self_supply),
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.root}>
        <div className={classes.main_container}>
          <div className={classes.headerText}>
            <h1>
              {isEnglish
                ? "Energy consumption profile"
                : "Energie verbrauchs profil"}
            </h1>
            <h3>
              {isEnglish
                ? "What is your usual energy consumption?"
                : "Wie hoch ist Ihr üblicher Energieverbrauch?"}
            </h3>
          </div>
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <div
              onClick={() => setConsumptionProfile(i)}
              className={classes.inside_main}
              style={{
                backgroundColor:
                  consumption_profile === i && "rgba(253, 184, 0, 0.1)",
                border: consumption_profile === i && `2px solid #2D4764`,
              }}
            >
              <img src={i % 2 === 0 ? MultiImg : SingleImg} id={1} alt="Home" />
              <h5 className={classes.center}>
                {isEnglish
                  ? consumption_profiles_b2b[i].name[0]
                  : consumption_profiles_b2b[i].name[1]}
              </h5>
            </div>
          ))}
        </div>
        <SelfSupplyInput
          consumption_details={consumption_details}
          setConsumptionDetails={setConsumptionDetails}
        />
        <div>
          <Button
            className={classes.button}
            style={{
              color: "white",
              background:
                "linear-gradient(226.42deg, #28334A 8.93%, #09152F 110.98%)",
              borderRadius: "10px",
            }}
            onClick={() => setOpen(false)}
          >
            {isEnglish ? "Take Over" : "Übernehmen"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
export const SelfSupplyInput = ({
  consumption_details,
  setConsumptionDetails,
}) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <div
      style={{
        height: "80px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px",
        fontSize: "35px",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          height: "151px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px",
          backgroundColor: " #EAEAEA",
          width: "289px",
          position: "relative",
          height: "108%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
        }}
      >
        <Typography style={{ color: "rgba(45, 71, 100, 1)" }}>
          {isEnglish ? "Self Supply" : "selbstversorgung"}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            height: "37px",
          }}
        >
          <Input
            placeholder={isEnglish ? "Self Supply" : "selbstversorgung"}
            inputProps={{ style: { textAlign: "center" } }}
            style={{
              textAlign: "center",
              paddingLeft: "5px",
              width: "100px",
              color: "rgba(45, 71, 100, 1)",
              fontSize: "18px",
            }}
            value={consumption_details.self_supply * 100}
            onChange={(e) => {
              console.log(e.target.value);
              if (parseInt(e.target.value) <= 100) {
                setConsumptionDetails({
                  ...consumption_details,
                  self_supply: e.target.value / 100,
                });
              } else if (e.target.value !== 100) {
                setConsumptionDetails({
                  ...consumption_details,
                  self_supply: 0 / 100,
                });
              } else {
                setConsumptionDetails({
                  ...consumption_details,
                  self_supply: 100 / 100,
                });
              }
            }}
          />
          <p
            style={{
              marginBottom: "0px",
              position: "absolute",
              right: "108px",
              bottom: "18px",
              marginTop: "4px",
              color: "rgba(45, 71, 100, 1)",
              fontSize: "18px",
            }}
          >
            %
          </p>
        </div>
      </div>
    </div>
  );
};
