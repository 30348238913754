import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { db } from "./../../../../firebase";
export default function BuildingType({
  params,
  buildingType,
  setBuildingType,
}) {
  const [val, setVal] = React.useState("");
  React.useEffect(() => {
    setVal(params?.formattedValue);
  }, []);
  const handleChange = (event) => {
    let obj = {
      ...params.row,
    };
    setVal(event.target.value);
    db.collection("quotes")
      .doc(params.id)
      .set({ ...obj, building_type: event.target.value })
      .then(() => {
        console.log("saved");
      })
      .catch((err) => console.log("errr", err));
  };

  return (
    <Box sx={{ minWidth: 220 }}>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={val}
          label=""
          onChange={handleChange}
        >
          {buildingType.map((val) => {
            return <MenuItem value={val}>{val}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
