import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Forward from "Assets/community/Forward.svg";
import { AvatarGroup } from "@material-ui/lab";
import parse from "html-react-parser";
import {
  Avatar,
  Box,
  CircularProgress,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import axios from "axios";
import Topics from "pages/Application/community/pages/home/partials/topics/Topics";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";
import { fetchCategoryHook } from "pages/Application/community/CustomHooks/CategoryHook";
const CategoryWrapper = styled("div")`
  height: 823px;
  .mainDiv {
    background: #ffffff;
    box-shadow: 0px 4px 4px #d8d8d8;
    border-radius: 10px;
    min-height: 211px;
    width: 644px;
    margin: auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    margin-bottom: 7px;
    :hover {
      background: #f9f9f9;
      box-shadow: 2px 2px 10px #d3d3d3;
      border-radius: 10px;
    }
  }
  .fist_part {
    display: flex;
    flex-direction: column;
    margin-right: 35px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 235px;
    margin-top: 19px;
    cursor: pointer;
  }
  .first_text {
    font-family: Nunito;
    font-style: normal;
    margin-bottom: 9px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #2d4764;
  }
  .secound_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #666666;
    margin-bottom: 17px;
  }
  .first_last {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
  .same_classs {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    div {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 7px;
      color: #666666;
    }
    section {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #2d4764;
    }
  }
  .horizonmal {
    height: 157px;
    background-color: #d0d0d0;
    width: 0.848764px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 13px;
    color: #666666;
  }
  .secound_part {
    display: flex;
    /* position: relative; */
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
  }
  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }
  .inside_same {
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    width: 253px;
    margin-bottom: 8px;
    align-items: flex-start;
    padding: 10px;
    @media (max-width: 600px) {
      padding: 3px;
    }
    :hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 10px;
    }
    img {
      margin-right: 6px;
      height: 29px;
      width: 29px;
    }
  }
  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
  }

  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .topic {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    justify-content: center;
    align-items: flex-start;
  }
  .topic_text {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .minitue {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #666666;
  }
  .middle_main {
    overflow-y: scroll;
    padding-top: 10px;
  }
  .category_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    margin-left: 34px;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 27px;
    color: #2d4764;
  }
  .middle_main::-webkit-scrollbar {
    width: 6px;
  }
  .make_it_flex {
    display: flex;
    justify-content: flex-start;
    min-height: 177px;
    margin-top: 19px;
    margin-left: 35px;
    align-items: flex-start;
    flex-direction: column;
  }
  /* Track */
  .middle_main::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .middle_main::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .middle_main::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media (max-width: 600px) {
    .mainDiv {
      width: 94%;
      flex-direction: column;
      align-items: center;
      background: rgba(249, 249, 249, 1);
    }
    .horizonmal {
      display: none;
    }
    .make_it_flex {
      margin: 0px;
    }
    .img {
      display: none;
    }
    .fist_part {
      margin-right: 0px;
    }
    .middle_main {
      height: auto !important;
      overflow: visible;
    }
  }
`;

const Category = ({ isHeaderThere, setIsHeadrThere, setOpen1, isOpen }) => {
  const dispatch = useDispatch();
  const [cateGroryData, setCateGoryData] = useState([]);
  const [isTopicTrue, setIsTopicTrue] = useState(false);
  const [open, setOpen] = useState(false);
  const [directId, setDireactId] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const isDireactSearch = useSelector((state) => state.global.isDireactSearch);
  const isDireactCategorySearch = useSelector(
    (state) => state.global.isDireactCategorySearch
  );

  const resData = fetchCategoryHook(setCateGoryData);
  const BlueOnGreenTooltip = withStyles({
    tooltip: {
      color: "#000",
      fontWeight: "600",
      fontSize: "14px",
      maxWidth: "500px",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
      backgroundColor: "#fff",
    },
  })(Tooltip);

  useEffect(() => {
    if (
      isDireactSearch.category_id !== null &&
      isDireactSearch.direactId !== null
    ) {
      setDireactId(isDireactSearch.direactId);
      setIsTopicTrue(true);
      setCategoryName(isDireactSearch.title);
      setCategoryId(isDireactSearch.category_id);
      setTimeout(() => {
        const datass = {
          flag: !isDireactSearch.flag,
          category_id: null,
          direactId: null,
          title: "",
        };
        dispatch({ type: "SET_DIREACT_SEARCH", payload: datass });
      }, 500);
    }
  }, [isDireactSearch]);

  useEffect(() => {
    if (
      isDireactCategorySearch.category_id !== null &&
      isDireactCategorySearch.direactId !== null
    ) {
      setDireactId(null);
      setIsTopicTrue(true);
      setCategoryName(isDireactCategorySearch.title);
      setCategoryId(isDireactCategorySearch.category_id);
      dispatch({
        type: "SET_CATEGORY",
        payload: {
          id: isDireactCategorySearch.category_id,
          title: isDireactCategorySearch.title,
        },
      });
    }
  }, [isDireactCategorySearch]);

  return (
    <CategoryWrapper>
      {!isTopicTrue ? (
        <>
          <div className="category_text">
            {isEnglish ? "Category" : "Kategorie"}
          </div>
          <div
            className="middle_main"
            style={{ height: isHeaderThere ? "611px" : "500px" }}
          >
            {cateGroryData.length > 0 ? (
              cateGroryData.map((data, id) => {
                return (
                  <div className="mainDiv">
                    <div
                      className="fist_part"
                      onClick={() => {
                        setIsTopicTrue(true);
                        setDireactId(null);
                        setCategoryId(data.id);
                        setCategoryName(data.title);
                        dispatch({
                          type: "SET_CATEGORY",
                          payload: {
                            id: data.id,
                            title: data.title,
                          },
                        });
                      }}
                    >
                      <div className="first_text">{data.title}</div>
                      <div className="secound_text">
                        {data.description === null
                          ? "In this Category no content is provided by the creator"
                          : parse(data.description)}
                      </div>
                      <div className="first_last">
                        <div className="same_classs">
                          <div>{isEnglish ? "Topics" : "Themen"}</div>
                          <setction>{data?.totalTopics}</setction>
                        </div>
                        <div className="same_classs">
                          <div>{isEnglish ? "Users" : "Benutzer"}</div>
                          <AvatarGroup max={4}>
                            {data?.users?.length > 0
                              ? data.users.map((data, id) => {
                                  return (
                                    <BlueOnGreenTooltip
                                      placement="center"
                                      title={data.username}
                                    >
                                      <Avatar
                                        alt="Remy Sharp"
                                        style={{
                                          height: "38px",
                                          width: "38px",
                                          borderRadius: "50%",
                                        }}
                                        src={`http://community.solarhub24.de${data.avatar_template.replace(
                                          "{size}",
                                          "50"
                                        )}`}
                                      />
                                    </BlueOnGreenTooltip>
                                  );
                                })
                              : ""}
                          </AvatarGroup>
                        </div>
                        <div className="same_classs">
                          <div>Posts</div>
                          <setction>{data.totalPosts}</setction>
                        </div>
                      </div>
                    </div>
                    <div className="horizonmal"></div>
                    <div className="make_it_flex">
                      <div className="text">
                        {isEnglish ? "Latest topics" : "neueste Themen"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className="secound_part">
                          {data.topics.length
                            ? data.topics.map((datas, id) => {
                                return (
                                  <div
                                    className="inside_same"
                                    onClick={() => {
                                      setDireactId(datas.id);
                                      setIsTopicTrue(true);
                                      setCategoryName(data.title);
                                      setCategoryId(data.id);
                                      dispatch({
                                        type: "SET_CATEGORY",
                                        payload: {
                                          id: data.id,
                                          title: data.title,
                                        },
                                      });
                                    }}
                                  >
                                    <Avatar
                                      style={{ backgroundColor: "#ffbf1b" }}
                                    >
                                      {datas.title[0]}
                                    </Avatar>
                                    <div className="topic">
                                      <div className="topic_text">
                                        {datas.title}
                                      </div>
                                      <div className="minitue">
                                        {datas.lastUpdateAt
                                          ? moment(new Date(datas.lastUpdateAt))
                                              .startOf("hour")
                                              .fromNow()
                                          : moment(new Date(datas.createdAt))
                                              .startOf("hour")
                                              .fromNow()}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="img">
                      <img
                        onClick={() => {
                          setIsTopicTrue(true);
                          setDireactId(null);
                          setCategoryName(data.title);
                          setCategoryId(data.id);
                        }}
                        style={{ cursor: "pointer", paddingLeft: "10px" }}
                        src={Forward}
                        alt=""
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  style={{
                    color: "#fbda61",
                  }}
                />
              </Box>
            )}
          </div>
        </>
      ) : (
        <Topics
          setIsTopicTrue={setIsTopicTrue}
          categoryId={categoryId}
          setOpen1={setOpen1}
          isOpen={isOpen}
          setIsHeadrThere={setIsHeadrThere}
          categoryName={categoryName}
          directId={directId}
        />
      )}
    </CategoryWrapper>
  );
};

export default Category;
