import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ProductPopupWrapper } from "../ProductPopupStyles";

const HeatPumpHeader = ({
  needToFilterList,
  isListOpen,
  setNeedToFilterList,
}) => {
  function valuetext(value) {
    return `${value}°C`;
  }
  const isEnglish = useSelector((state) => state.global.isEnglish);

  return (
    <ProductPopupWrapper>
      <div className="first_container">
        <div
          className="heading"
          style={{ paddingBottom: !isListOpen ? "13px" : "" }}
        >
          Heatpump <span>Wählen Sie ihre Präferenzen</span>
        </div>
        {isListOpen ? (
          <FormGroup className="check_box_contaienr">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Made in Germany",
                      ]);
                    } else {
                      if (needToFilterList.includes("Made in Germany")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Made in Germany"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Made in Germany"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needToFilterList.includes("Luft/Wasser-Wärmepumpe")}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Luft/Wasser-Wärmepumpe",
                      ]);
                    } else {
                      if (needToFilterList.includes("Luft/Wasser-Wärmepumpe")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Luft/Wasser-Wärmepumpe"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Luft/Wasser-Wärmepumpe"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "App zur Wärmeverwaltung",
                      ]);
                    } else {
                      if (
                        needToFilterList.includes("App zur Wärmeverwaltung")
                      ) {
                        const result = needToFilterList.filter(
                          (e) => e !== "App zur Wärmeverwaltung"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="App zur Wärmeverwaltung"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Warmwasseraufbereitung",
                      ]);
                    } else {
                      if (needToFilterList.includes("Warmwasseraufbereitung")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Warmwasseraufbereitung"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Warmwasseraufbereitung"
            />
          </FormGroup>
        ) : (
          ""
        )}
      </div>
      {isListOpen ? (
        <div className="secound_continaer">
          <div className="same_two">
            <div className="first_one">
              <div className="lable_text">
                {isEnglish
                  ? "Total area to be heated"
                  : "Gesamte zu beheizende Fläche"}
              </div>
              <Slider
                aria-label="Temperature"
                defaultValue={0}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={2}
                marks
                style={{ color: "#2D4764", width: "37%" }}
                min={0}
                max={7.7}
              />
              <div className="lable_text">20 m²</div>
            </div>
            <div className="first_one">
              <div className="lable_text">
                {isEnglish
                  ? "current oil / gas bill"
                  : "Bisherige Öl/ Gasrechnung"}
              </div>
              <Slider
                aria-label="Temperature"
                defaultValue={0}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={2}
                marks
                style={{ color: "#2D4764", width: "37%" }}
                min={0}
                max={7.7}
              />
              <div className="lable_text">18 €</div>
            </div>
          </div>
          <div className="same_two">
            <div className="first_one">
              <div className="lable_text">
                {isEnglish ? "Heating capacity" : "Heizleistung"}
              </div>
              <Slider
                aria-label="Temperature"
                defaultValue={0}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={2}
                marks
                style={{ color: "#2D4764", width: "37%" }}
                min={0}
                max={7.7}
              />
              <div className="lable_text">3.500 kW</div>
            </div>
            <div className="first_one">
              <div className="lable_text">
                {isEnglish
                  ? "current oil consumption"
                  : "Bisher verbrauchte Ölmenge"}
              </div>
              <Slider
                aria-label="Temperature"
                defaultValue={0}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={2}
                marks
                style={{ color: "#2D4764", width: "37%" }}
                min={0}
                max={7.7}
              />
              <div className="lable_text">10.000l</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </ProductPopupWrapper>
  );
};

export default HeatPumpHeader;
