import React from "react";
import { Button } from "@material-ui/core";
import { colors } from "../../../colors";
import { makeStyles } from "@material-ui/core/styles";

function ButtonBlueOverWhite({
  children,
  onClick,
  fullWidth,
  sharpCorners,
  fontSize,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#fff",
      color: colors.darkBlue,
      width: fullWidth && "100%",
      borderRadius: !sharpCorners && "25px",
      padding: "5px 15px",
      fontWeight: "bold",
      fontSize: "15px",
      border: `1px solid ${colors.darkBlue}`,
      "&:hover": {
        backgroundColor: colors.darkBlue,
        color: "#fff",
        cursor:"pointer"
      },
    },
  }));
  const classes = useStyles();
  return (
    <button variant={"outlined"} onClick={onClick} className={classes.root}>
      {children}
    </button>
  );
}

export default ButtonBlueOverWhite;
