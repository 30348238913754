import React, { useState, useEffect } from "react";
import Layout from "../../partials/Layout";
import styled from "styled-components";
import { useSelector } from "react-redux";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import TopicCard from "./TopicCard";
import { toast } from "react-toastify";
import Post from "../Saved/Post";
import { getError } from "helperMethod";
const HomeWrapper = styled("div")`
  .root {
    border: 2px solid #dfdfdf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background: #f9f9f9;
  }
`;
const HeaderText = styled("div")`
  display: flex;
  width: 100%;
  font-family: Nunito;
  font-weight: bold;
  font-size: 24px;
  color: #2d4764;
  margin-top: 28px;
  margin-left: 34px;
`;
const HeaderLodaer = styled("div")`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TopicCards = styled("div")`
  width: 100%;
  height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 50px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const Popular = () => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [topicData, setTopicData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [showPost, setShowPost] = useState(false);
  const [postTopic, setPostTopic] = useState(null);
  useEffect(() => {
    const func = async () => {
      setFlag(true);
      try {
        let res = await axios.get(
          `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/top/topics`
        );
        if (res) {
          setTopicData(res.data);
          setFlag(false);
        }
      } catch (error) {
        if (error?.response?.data?.errors?.length) {
          toast.error(getError(error?.response?.data?.errors));
          setFlag(false);
        } else {
          console.log(error);
          setFlag(false);
        }
      }
    };

    func();
  }, []);
  return (
    <HomeWrapper>
      {showPost ? (
        <Post
          setShowPost={setShowPost}
          postTopic={postTopic}
          categoryId={postTopic.categoryId}
        />
      ) : flag ? (
        <HeaderLodaer>
          <CircularProgress
            style={{
              color: "#fbda61",
            }}
          />
        </HeaderLodaer>
      ) : (
        <div className="root">
          <HeaderText>
            {isEnglish ? "Popular Topics" : "Beliebte Themen"}
          </HeaderText>
          <TopicCards className="sarthak">
            {topicData.map((data) => {
              return (
                <>
                  <TopicCard
                    data={data}
                    topicData={topicData}
                    hoverFlag={true}
                    setShowPost={setShowPost}
                    setPostTopic={setPostTopic}
                  />
                </>
              );
            })}
          </TopicCards>
        </div>
      )}
    </HomeWrapper>
  );
};

export default Popular;
