import React, { useState, useEffect } from "react";
import Layout from "../../partials/Layout";
import styled from "styled-components";
import { useSelector } from "react-redux";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import TopicCard from "./TopicCard";
import { toast } from "react-toastify";
import Post from "./Post";
import { getError } from "helperMethod";
const HomeWrapper = styled("div")`
  .root {
    border: 2px solid #dfdfdf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background: #f9f9f9;
  }
`;
const HeaderText = styled("div")`
  display: flex;
  width: 100%;
  font-family: Nunito;
  font-weight: bold;
  font-size: 24px;
  color: #2d4764;
  margin-top: 28px;
  margin-left: 34px;
`;
const BodyText1 = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Nunito;
  font-weight: 200;
  font-size: 24px;
  color: #2d4764;
`;
const HeaderLodaer = styled("div")`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TopicCards = styled("div")`
  width: 100%;
  height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 50px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const Saved = () => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [topicData, setTopicData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [showPost, setShowPost] = useState(false);
  const [postTopic, setPostTopic] = useState(null);
  const [categoryId, setcategoryId] = useState(0);
  const savedTopics = useSelector((state) => {
    return state.savedTopic.topicId;
  });
  useEffect( () => {
    const func = async () => {
      setFlag(true);
      let temp = [];
      for (let i = 0; i < savedTopics.length; i++) {
        try {
          let res = await axios.get(
            `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/${savedTopics[i].id}/topic`
          );
          if (res) {
            temp.push(res.data);
          }
        } catch (error) {
          if (error?.response?.data?.errors?.length) {
            toast.error(getError(error?.response?.data?.errors));
          } else {
            console.log(error);
          }
        }
      }
      console.log("sar", temp);
      setTopicData(temp);
      setFlag(false);
    };

    func();
  }, [showPost]);

  return (
    <HomeWrapper>
      {showPost ? (
        <Post
          setShowPost={setShowPost}
          postTopic={postTopic}
          categoryId={categoryId}
        />
      ) : flag ? (
        <HeaderLodaer>
          <CircularProgress
            style={{
              color: "#fbda61",
            }}
          />
        </HeaderLodaer>
      ) : (
        <div className="root">
          <HeaderText>
            {isEnglish ? "Saved Topics" : "Gespeicherte Themen"}
          </HeaderText>
          {topicData.length ? (
            <TopicCards className="sarthak">
              {topicData.map((data) => {
                return (
                  <>
                    <TopicCard
                      data={data}
                      setTopicData={setTopicData}
                      setcategoryId={setcategoryId}
                      topicData={topicData}
                      setPostTopic={setPostTopic}
                      setShowPost={setShowPost}
                    />
                  </>
                );
              })}
            </TopicCards>
          ) : (
            <TopicCards className="sarthak">
              <BodyText1>
                {isEnglish ? "No Topics Available" : "Keine Themen verfügbar"}
              </BodyText1>
            </TopicCards>
          )}
        </div>
      )}
    </HomeWrapper>
  );
};

export default Saved;
