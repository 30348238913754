import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { commaDotHandler } from "helperMethod";

const colors = {
  darkRain: `linear-gradient(to left,#09152F,#1B4963)`,
  darkBlue: "#1B4963",
  greenHover: `linear-gradient(to left,#197061 ,#1B7A69)`,
  linear: `linear-gradient(to left,#FFE925 ,#FFAA39)`,
  sunny: `linear-gradient(to left,#FBDA61 ,#FFC371)`,
  sun: `linear-gradient(to left,#FBDA61 ,#F76B1C)`,
  sun_r: `linear-gradient(to right,#FBDA61 ,#F76B1C)`,
  fire: `linear-gradient(to left,#FF5F6D  ,#FFC371)`,
  orangeSolid: "#FFAA39",
  ctaSolid: "#FBDA61",
  lightYellow: "#FFF3CA",
};

const BuyCharts = ({
  isEnglish,
  height,
  width,
  margin,
  costs_and_savings,
  financing_details,
}) => {
  const calc_func = (
    electricity_cost_before,
    estimated_yearly_price_increase
  ) => {
    var temp = electricity_cost_before;
    var total_cost = electricity_cost_before;
    for (let i = 0; i < 25; i++) {
      const this_years_extra =
        (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100;
      temp = temp + this_years_extra;
      total_cost = total_cost + temp;
    }
    return Math.round(total_cost);
  };

  const a = Math.round(financing_details.total_project_cost);
  const b = calc_func(
    parseFloat(costs_and_savings.electricity_cost_before),
    parseFloat(costs_and_savings.estimated_yearly_price_increase)
  );
  const c = Math.round(costs_and_savings.feedin_savings) * 25;

  const useStyles = makeStyles((theme) => ({
    top_text: {
      margin: "0px",
      marginLeft: "20px",
    },
    root: {
      width: width ? width : "95%",
      height: height ? height : "",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#f8f8f8",
      borderRadius: "25px",
      margin: margin ? margin : "0px 15px 15px 15px",
    },
    c1: {
      width: "25%",
      margin: "15px 5px 15px 15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    c2: {
      width: "60%",

      margin: "15px 25px 15px 0px",
      "& h1": {
        color: colors.orangeSolid,
        margin: "0px",
        padding: "0px",
      },
      "& h5": {
        margin: "0px",
        padding: "0px",
        fontSize: "15px",
        marginBottom: "15px",
      },
      "& p": {
        fontSize: "13px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "5px 0px",
        "& span": {
          display: "flex",
          alignItems: "center",
        },
        "& div": {
          width: "8px",
          height: "8px",

          border: "1px solid gray",
          margin: "0 10px 0 -15px",
          borderRadius: "5px",
        },
      },
    },
    chart_conatiner: {
      width: "100px",
      minHeight: "10px",
      display: "flex",
      marginTop: "35px",
    },
    chart_1: {
      width: "35px",
      background: "darkred",
      height: `${(a / (a + b + c)) * 200}px`,
      alignSelf: "flex-end",
      marginRight: "8px",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    chart_2: {
      width: "35px",
      background: "darkgreen",
      height: `${(c / (a + b + c)) * 200}px`,
      marginLeft: "8px",

      color: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    chart_3: {
      width: "35px",
      background: colors.sun_r,
      height: `${(b / (a + b + c)) * 200}px`,
      marginLeft: "8px",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.c1}>
          <div className={classes.chart_conatiner}>
            <div className={classes.chart_1}></div>
            <div>
              <div className={classes.chart_2}></div>
              <div className={classes.chart_3}></div>
            </div>
          </div>
        </div>
        <div className={classes.c2}>
          <h1>{commaDotHandler(b + c - a, `${isEnglish ? "en" : "de"}`)} €</h1>
          <h5>
            {isEnglish
              ? "Profit after 25 years of operation"
              : "Profit nach 25 Jahren Betriebsdauer"}
          </h5>
          <Typography>
            <span>
              <div style={{ background: "darkred" }} />
              {isEnglish
                ? "Investment / building cost"
                : "Investment / Baukosten"}
            </span>
            <span>
              <strong>
                - {commaDotHandler(a, `${isEnglish ? "en" : "de"}`)} EUR
              </strong>
            </span>
          </Typography>
          <Typography>
            <span>
              <div style={{ background: colors.sun }} />
              {isEnglish
                ? "Savings electricity bill"
                : "Einsparungen Stromrechnung"}
            </span>
            <span>
              <strong>
                {commaDotHandler(b, `${isEnglish ? "en" : "de"}`)} EUR
              </strong>
            </span>
          </Typography>
          <Typography>
            <span>
              <div style={{ background: "darkgreen" }} />
              {isEnglish ? "Feed-in-tariff" : "Einspeisevergütung"}
            </span>
            <span>
              <strong>
                {commaDotHandler(c, `${isEnglish ? "en" : "de"}`)} EUR
              </strong>
            </span>
          </Typography>
          <Divider style={{ margin: "20px 0px" }} />
          <Typography>
            <span>
              <strong>
                {isEnglish
                  ? "Return on Investment after 25 years"
                  : "Rendite nach 25 Jahren"}
              </strong>
            </span>
            <span>
              <strong>
                {commaDotHandler(
                  Math.round(((b + c - a) / a) * 100),
                  `${isEnglish ? "en" : "de"}`
                )}{" "}
                %
              </strong>
            </span>
          </Typography>
          <Typography style={{ marginLeft: "50px" }}>
            <span>
              <strong>
                {commaDotHandler(
                  Math.round((((b + c - a) / a) * 100) / 25),
                  `${isEnglish ? "en" : "de"}`
                )}
                % {isEnglish ? "Yearly Returns" : "Jahresrendite"}
              </strong>
            </span>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default BuyCharts;
