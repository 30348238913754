import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { useStyles } from "@material-ui/pickers/views/Calendar/Day";
import { commaDotHandler } from "helperMethod";
import { useDispatch, useSelector } from "react-redux";
import TopHeader from "./TopHeader";

const colors = {
  darkRain: `linear-gradient(to left,#09152F,#1B4963)`,
  darkBlue: "#1B4963",
  greenHover: `linear-gradient(to left,#197061 ,#1B7A69)`,
  linear: `linear-gradient(to left,#FFE925 ,#FFAA39)`,
  sunny: `linear-gradient(to left,#FBDA61 ,#FFC371)`,
  sun: `linear-gradient(to left,#FBDA61 ,#F76B1C)`,
  sun_r: `linear-gradient(to right,#FBDA61 ,#F76B1C)`,
  fire: `linear-gradient(to left,#FF5F6D  ,#FFC371)`,
  orangeSolid: "#FFAA39",
  ctaSolid: "#FBDA61",
  lightYellow: "#FFF3CA",
};

const { ctaSolid } = colors;

const calc_func = (
  electricity_cost_before,
  estimated_yearly_price_increase
) => {
  var temp = electricity_cost_before;
  var total_cost = electricity_cost_before;
  console.log("initial_temp", temp);
  console.log(estimated_yearly_price_increase);
  for (let i = 0; i < 25; i++) {
    const this_years_extra =
      (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100;
    temp = temp + this_years_extra;
    total_cost = total_cost + temp;
    console.log("temp" + i + "-" + temp);
    console.log("this years extra" + i + "-" + this_years_extra);
    console.log("total_cost" + i + "-" + total_cost);
  }
  return Math.round(total_cost);
};

const BuyChartsForNew = ({
  isEnglish,
  costs_and_savings,
  financing_details,
  setYearlyConsumptionDialog,
}) => {
  const isFinancing = useSelector((state) => state.project.isFinancing);
  const a = !isFinancing
    ? Math.round(financing_details.total_project_cost)
    : Math.round(financing_details.total_financing_amount);
  console.log(costs_and_savings);
  const b = calc_func(
    parseFloat(costs_and_savings.electricity_cost_before),
    parseFloat(costs_and_savings.estimated_yearly_price_increase)
  );
  const c = Math.round(costs_and_savings.feedin_savings) * 25;

  const useStyles = makeStyles((theme) => ({
    top_text: {
      margin: "0px",
      marginLeft: "20px",
    },
    root: {
      width: "100%",
      display: "flex",
      height: "285px",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#f8f8f8",
      borderRadius: "25px",
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        height: "438px",
        flexDirection: "column",
      },
      // margin: "10px 15px 15px 15px",
    },
    c1: {
      width: "25%",
      margin: "15px 5px 15px 15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    yourClass: {
      width: "715px",
      margin: "auto",
      transform: "translateY(-40px)",
      [theme.breakpoints.down("xs")]: {
        width: "95%",
        margin: "10px",
      },
    },
    centers: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    c2: {
      width: "60%",
      margin: "15px 25px 15px 0px",
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        width: "82%",
      },
      "& h1": {
        color: colors.orangeSolid,
        margin: "0px",
        padding: "0px",
      },
      "& h5": {
        margin: "0px",
        padding: "0px",
        fontSize: "14px",
        marginBottom: "15px",
      },
      "& p": {
        fontSize: "14px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "5px 0px",
        "@media (max-width: 356px)": {
          flexDirection: "column",
          alignItems: "start",
          marginLeft: "14px",
        },
        "& span": {
          display: "flex",
          alignItems: "center",
          "& strong": {
            "@media (max-width: 356px)": {
              marginTop: "5px",
            },
          },
        },
        "& div": {
          width: "8px",
          height: "8px",

          border: "1px solid gray",
          margin: "0 10px 0 -15px",
          borderRadius: "5px",
        },
      },
    },
    c2_header: {
      display: "flex",
      width: "100%",
      borderRadius: "15px",
      height: "30px",
      alignItems: "center",
    },
    c2_header_button: {
      position: "relative",
      zIndex: "1",
      flex: "1",
      width: "auto",
      textAlign: "center",
      background: "#FFF3CA",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      height: "100%",
      paddingTop: "5px",
      cursor: "pointer",
      marginBottom: "-3px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "10px",
      },
      "&:hover": {
        fontWeight: "bold",
        backgroundColor: ctaSolid,
        transform: "scale(1.01)",
      },
    },
    c2_header_button_highlighted: {
      position: "relative",
      zIndex: "1",
      flex: "1",
      width: "auto",
      textAlign: "center",
      backgroundColor: ctaSolid,
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      height: "100%",
      paddingTop: "5px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "10px",
      },
      marginBottom: "-3px",
      "&:hover": {
        fontWeight: "bold",
        transform: "scale(1.01)",
      },
    },
    chart_conatiner: {
      width: "100px",
      minHeight: "10px",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
      },
      marginTop: "35px",
    },
    thisResponsive: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    chart_1: {
      width: "35px",
      background: "darkred",
      height: `${(a / (a + b + c)) * 200}px`,
      alignSelf: "flex-end",
      marginRight: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    innerThing: {
      fontFamily: "Nunito",
      fontWeight: "400",
      fontSize: "16px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        marginTop: "22px",
        fontSize: "14px",
      },
      "@media (max-width: 356px)": {
        marginTop: "14px",
      },
    },
    chart_2: {
      width: "35px",
      background: "darkgreen",
      height: `${(c / (a + b + c)) * 200}px`,
      marginLeft: "8px",

      color: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    chart_3: {
      width: "35px",
      background: colors.sun_r,
      height: `${(b / (a + b + c)) * 200}px`,
      marginLeft: "8px",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    fontClass: {
      backgroundColor: "#C4C4C4",
      color: "black",
      borderRadius: "16px",
      width: "76px",
      textAlign: "center",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "10px",
        width: "150px",
      },
      marginLeft: "25px",
      paddingTop: "3px",
      alignSelf: "center",
      height: "30px",
      "@media (max-width: 560px)": {
        fontSize: "14px",
      },
    },
    familyButton: {
      backgroundColor: "#fff",
      color: "black",
      borderRadius: "25px",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "15px",
        width: "100px",
      },
      alignSelf: "center",
      height: "30px",
      border: `1px solid ${colors.darkBlue}`,
    },
    finace_container1: {
      width: "100%",
      margin: "10px 0px 10px 0px",
      display: "flex",
      height: "102px",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
    mainDiv: {
      backgroundColor: "#f8f8f8",
      borderBottomLeftRadius: "25px",
      borderBottomRightRadius: "25px",
      display: "flex",
      [theme.breakpoints.down("xs")]: {},
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
    forH1: {
      textAlign: "center",
      textTransform: "uppercase",
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontSize: "22px",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
      paddingTop: "30px",
    },
    important: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  const project = useSelector((state) => state.project);

  return (
    <div style={{}}>
      <TopHeader />
      <div className={classes.mainDiv}>
        <h1 className={classes.forH1}>
          {isEnglish
            ? "calculate your return on investment"
            : "Berechnen Sie Ihren Return on Investment"}
        </h1>
        <div className={classes.root}>
          <div className={classes.c1}>
            <div className={classes.chart_conatiner}>
              <div className={classes.chart_1}></div>
              <div>
                <div className={classes.chart_2}></div>
                <div className={classes.chart_3}></div>
              </div>
            </div>
          </div>
          <div className={classes.c2}>
            <h1 className={classes.centers}>
              {commaDotHandler(b + c - a, `${isEnglish ? "en" : "de"}`)} €
            </h1>
            <h5>
              {isEnglish
                ? "Profit after 25 years of operation"
                : "Profit nach 25 Jahren Betriebsdauer"}
            </h5>
            <Typography>
              <span>
                <div style={{ background: "darkred" }} />
                {isEnglish
                  ? !isFinancing
                    ? "Investment / building cost"
                    : "Financing / building cost"
                  : "Investment / Baukosten"}
              </span>
              <span>
                <strong>
                  - {commaDotHandler(a, `${isEnglish ? "en" : "de"}`)}{" "}
                  {window.innerWidth > 500 ? "EUR" : "€"}
                </strong>
              </span>
            </Typography>
            <Typography>
              <span>
                <div style={{ background: colors.sun }} />
                {isEnglish
                  ? "Savings electricity bill"
                  : "Einsparungen Stromrechnung"}
              </span>
              <span>
                <strong>
                  {commaDotHandler(b, `${isEnglish ? "en" : "de"}`)}{" "}
                  {window.innerWidth > 500 ? "EUR" : "€"}
                </strong>
              </span>
            </Typography>
            <Typography>
              <span>
                <div style={{ background: "darkgreen" }} />
                {isEnglish ? "Feed-in-tariff" : "Einspeisevergütung"}
              </span>
              <span>
                <strong>
                  {commaDotHandler(c, `${isEnglish ? "en" : "de"}`)}{" "}
                  {window.innerWidth > 500 ? "EUR" : "€"}
                </strong>
              </span>
            </Typography>
            <Divider style={{ margin: "20px 0px" }} />
            {window.innerWidth < 600 ? (
              ""
            ) : (
              <>
                <Typography>
                  <span>
                    <strong className={classes.thisResponsive}>
                      {isEnglish
                        ? "Return on Investment after 25 years"
                        : "Rendite nach 25 Jahren"}
                    </strong>
                  </span>
                  <span>
                    <strong>
                      {commaDotHandler(
                        Math.round(((b + c - a) / a) * 100),
                        `${isEnglish ? "en" : "de"}`
                      )}{" "}
                      %
                    </strong>
                  </span>
                </Typography>
                <Typography style={{ marginLeft: "50px" }}>
                  <span>
                    <strong>
                      {commaDotHandler(
                        Math.round((((b + c - a) / a) * 100) / 25),
                        `${isEnglish ? "en" : "de"}`
                      )}
                      % Yearly Returns
                    </strong>
                  </span>
                </Typography>
              </>
            )}
          </div>
        </div>
        {window.innerWidth > 600 ? (
          ""
        ) : (
          <>
            <Typography className={classes.important}>
              <span>
                <strong className={classes.thisResponsive}>
                  {isEnglish
                    ? "Return on Investment after 25 years"
                    : "Rendite nach 25 Jahren"}
                </strong>
              </span>
              <span>
                <strong>
                  {commaDotHandler(
                    Math.round(((b + c - a) / a) * 100),
                    `${isEnglish ? "en" : "de"}`
                  )}{" "}
                  %
                </strong>
              </span>
            </Typography>
            <Typography style={{ marginTop: "9px" }}>
              <span>
                <strong>
                  {commaDotHandler(
                    Math.round((((b + c - a) / a) * 100) / 25),
                    `${isEnglish ? "en" : "de"}`
                  )}
                  % Yearly Returns
                </strong>
              </span>
            </Typography>
          </>
        )}
        <h5 className={classes.innerThing}>
          {isEnglish
            ? "Here you can review your investment - this is depending on your selections in"
            : "Hier können Sie Ihre Investition überprüfen - dies ist abhängig von Ihrer Auswahl in"}{" "}
          <br />
          {isEnglish ? "the previous steps" : "die vorherigen Schritte"}
        </h5>
      </div>
    </div>
  );
};

const DetailTab1 = ({
  costs_and_savings,
  financing_details,
  setYearlyConsumptionDialog,
}) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <div
      className={classes.yourClass}
      style={{
        width: window.innerWidth < 500 ? "95%" : "725px",
        margin: window.innerWidth < 500 ? "10px" : "auto",
        marginTop: window.innerWidth < 500 ? "10px" : "20px",
      }}
    >
      <BuyChartsForNew
        isEnglish={isEnglish}
        costs_and_savings={costs_and_savings}
        financing_details={financing_details}
        setYearlyConsumptionDialog={setYearlyConsumptionDialog}
      />
    </div>
  );
};

export default DetailTab1;
