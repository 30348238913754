import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";
import "firebase/remote-config";

if (!firebase.apps.length) {
  firebase.initializeApp({
    // apiKey: "AIzaSyCzKo9f7eVSZg1eWyZ-nsrJ3ASNqgnDxd4",
    // authDomain: "solarhub-44450.firebaseapp.com",
    // projectId: "solarhub-44450",
    // storageBucket: "solarhub-44450.appspot.com",
    // messagingSenderId: "217083964770",
    // appId: "1:217083964770:web:9d012b55c4f86172f3d03b",
    // measurementId: "${config.measurementId}",
    apiKey: "AIzaSyB4NpPwTSm3pChudxdAky_J2qBdGMlR0G8",
    authDomain: "solarhub-production.firebaseapp.com",
    projectId: "solarhub-production",
    storageBucket: "solarhub-production.appspot.com",
    messagingSenderId: "29847521309",
    appId: "1:29847521309:web:1469755bfff17226ba1ef4",
    measurementId: "G-KHM792Y595",
  });
} else {
  firebase.app();
}

// const remoteConfig = firebase.remoteConfig();
// remoteConfig.settings.minimumFetchIntervalMillis = 1000;

// export { remoteConfig };
export var db = firebase.firestore();
export default firebase;
