import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Rate } from "antd";
import Checkout from "./images/Checkout.png";
import BuyCharts from "../../../Components/paymentDone.buyCharts";
import { Button } from "@material-ui/core";
import PaymentStatusModal from "./PaymentStatusModal";

const useStyles = makeStyles(() =>
  createStyles({
    rightSide: {
      height: "300px",
      display: "flex",
      paddingTop: "35px",
      paddingBottom: "25px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    root: {
      display: "flex",
      width: "80%",
      maxWidth: "1200px",
      height: "280px",
      justifyContent: "space-around",
      alignItems: "center",
    },
    flexing: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "54px",
    },
    h1: {
      fontFamily: "Nunito",
      fontWeight: "700",
      "@media (max-width: 1100px)": {
        fontSize: "28px",
      },
      fontSize: "24px",
      color: "#304864",
      alignSelf: "flex-start",
    },
    h3: {
      fontFamily: "Nunito",
      fontWeight: "600",
      "@media (max-width: 1100px)": {
        fontSize: "23px",
      },
      fontSize: "20px",
      color: "#304864",
      alignSelf: "flex-start",
    },
    roof: {
      fontFamily: "Nunito",
      fontWeight: "400",
      "@media (max-width: 1100px)": {
        fontSize: "23px",
      },
      fontSize: "20px",
      color: "#4D7E6D",
    },
    buttonClass: {
      color: "#2D4764",
      border: "1px solid #2D4764",
      fontSize: "16px",
      marginTop: "7px",
      borderRadius: "36px",
    },
  })
);

const PaymentCheckout = ({
  costs_and_savings,
  isEnglish,
  financing_details,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  return (
    <>
      <div className={classes.root}>
        <img
          src={Checkout}
          style={{ marginLeft: "30px", height: "308px", marginRight: "30px" }}
          alt=""
        />
        <div className={classes.rightSide}>
          <h1 className={classes.h1}>
            {isEnglish
              ? "Congratulations - your PV project will be realized soon!"
              : "Herzlichen Glückwunsch - Ihr PV-Projekt wird bald realisiert!"}
          </h1>
          <h3 className={classes.h3}>
            {isEnglish
              ? "Together with your help all relevant details have been settled"
              : "Mit Ihrer Hilfe sind alle relevanten Details geklärt"}
          </h3>
          <h3 className={classes.roof}>
            {isEnglish
              ? "Your Project is now ready to be realized - if you want to discuss some details feel free to reach out to us whenever you want to.We´ve send you all details via E-Mail as well"
              : "Ihr Projekt ist nun bereit zur Realisierung - wenn Sie diskutieren möchten einige details zögern sie nicht uns zu kontaktieren, wann immer sie möchten.Alle Details haben wir dir auch per E-Mail zugesendet"}
          </h3>
        </div>
      </div>
      <div className={classes.flexing}>
        <h1
          style={{
            margin: "0px",
            padding: "0px",
            textAlign: "center",
            textTransform: "uppercase",
            marginRight: "16px",
            marginTop: "9px",
            color: "#2D4764",
            fontSize: "20px",
          }}
        >
          {isEnglish
            ? "Check your Project Overview now!"
            : "Prüfen Sie jetzt Ihre Projektübersicht!"}
        </h1>
        <Button onClick={showModal} className={classes.buttonClass}>
          Check Your Payment Status
        </Button>
      </div>
      <div
        style={{
          width: "50%",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <BuyCharts
          isEnglish={isEnglish}
          height="250px"
          margin="auto"
          width="509px"
          costs_and_savings={costs_and_savings}
          financing_details={financing_details}
        />
      </div>
      <PaymentStatusModal open={open} setOpen={setOpen} />
    </>
  );
};

export default PaymentCheckout;
