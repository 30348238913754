import { Fragment, useState, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FaqsContent } from "../content";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  margin: 5%;
  margin-top: 0;
  background: white;
  height: max-content;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  border-radius: 0px 10px 10px 10px;
`;

const AccordionContainer = styled(Accordion)`
  /* background: rgba(245, 196, 65, 0.05); */
`;

const FAQs = ({ searchText, faqs }) => {
  const [content, setcontent] = useState(FaqsContent);
  console.log("fa");

  useEffect(() => {
    console.log("search", searchText);
  }, [searchText]);

  return (
    <Container>
      {faqs.map((item, key) => {
        return (
          <AccordionContainer key={item.title}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${key}a-content`}
              id={`panel${key}a-header`}
            >
              <Typography style={{ color: "rgba(40, 51, 74, 1)" }}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.content}</Typography>
            </AccordionDetails>
          </AccordionContainer>
        );
      })}
    </Container>
  );
};

export default FAQs;
