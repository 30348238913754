const INITIAL_STATE = {
	isCurrentUserLoading: true,
	currentUser: null,
	isUserAdressEnterd: false,
	geometry: null,
	username: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
	console.log("action : ", action);
	switch (action.type) {
		case "SET_CURRENT_USER":
			return {
				...state,
				currentUser: action.payload,
			};
		case "SET_CURRENT_USER_LOADING":
			return {
				...state,
				isCurrentUserLoading: action.payload,
			};
		case "SET_USER_ADRESS_STATUS":
			return {
				...state,
				isUserAdressEnterd: action.payload,
			};
		case "SET_USERNAME_DISCOURSE_PASSWORD":
			return {
				...state,
				username: action.payload.username,
			};
		case "SET_GEO_CORDINENTS_IF_NO_QUOTE":
			return {
				...state,
				geometry: action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
