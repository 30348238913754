import React from "react";
import Home from "../pages/home/Home";
import Popular from "../pages/Popular/Popular";
import Notification from "../pages/notification/notification";
import FAQsMain from "../pages/faqs/faqs";
import Saved from "./../pages/Saved/Saved";
import Profile from "../pages/Profile/Profile";
import { Route, Switch } from "react-router-dom";
import Layout from "./Layout";
import KnowledgeHub from "../pages/knowledgehub";

const Routes = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/community/home" component={Home} />
        <Route exact path="/community/popular" component={Popular} />
        <Route exact path="/community/notification" component={Notification} />
        <Route exact path="/community/faq" component={FAQsMain} />
        <Route exact path="/community/saved" component={Saved} />
        <Route exact path="/community/profile" component={Profile} />
        <Route exact path="/community/knowledgehub" component={KnowledgeHub} />
      </Switch>
    </Layout>
  );
};

export default Routes;
