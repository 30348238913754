import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SelectYearlyConsumption from "../../../../Components/yearlyConsumption.dialog";
import { useSelector } from "react-redux";
import { commaDotHandler } from "helperMethod";
import { useStyles } from "@material-ui/pickers/views/Calendar/Day";
import TopHeader from "./TopHeader";

const colors = {
  darkRain: `linear-gradient(to left,#09152F,#1B4963)`,
  darkBlue: "#1B4963",
  greenHover: `linear-gradient(to left,#197061 ,#1B7A69)`,
  linear: `linear-gradient(to left,#FFE925 ,#FFAA39)`,
  sunny: `linear-gradient(to left,#FBDA61 ,#FFC371)`,
  sun: `linear-gradient(to left,#FBDA61 ,#F76B1C)`,
  sun_r: `linear-gradient(to right,#FBDA61 ,#F76B1C)`,
  fire: `linear-gradient(to left,#FF5F6D  ,#FFC371)`,
  orangeSolid: "#FFAA39",
  ctaSolid: "#FBDA61",
  lightYellow: "#FFF3CA",
};

const calc_func = (
  electricity_cost_before,
  estimated_yearly_price_increase
) => {
  let temp = electricity_cost_before;
  console.log(estimated_yearly_price_increase);
  for (let i = 0; i < 25; i++) {
    temp =
      (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100 +
      temp;
  }
  console.log("final_temp", temp);
  return Math.round(temp);
};

const BuyChartsForNew = ({
  isEnglish,
  costs_and_savings,
  financing_details,
  setYearlyConsumptionDialog,
}) => {
  let monthly_plus;
  const isFinancing = useSelector((state) => state.project.isFinancing);
  if (!isFinancing) {
    monthly_plus =
      costs_and_savings.electricity_cost_before +
      costs_and_savings.feedin_savings;
  } else {
    monthly_plus =
      costs_and_savings.electricity_cost_before +
      costs_and_savings.feedin_savings -
      financing_details.yearly_financing_amount;
  }
  const a = Math.round(monthly_plus / 12);
  const project = useSelector((state) => state.project);
  const b = Math.round(costs_and_savings.feedin_savings / 12);
  const c = Math.round(costs_and_savings.electricity_cost_before / 12);

  const acutal_rate = !isFinancing
    ? Math.round(financing_details.total_project_cost)
    : Math.round(financing_details.total_financing_amount);
  console.log(costs_and_savings);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "@media (max-width: 560px)": {
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      },
    },
    forH1: {
      textAlign: "center",
      textTransform: "uppercase",
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontSize: "22px",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
        margin: "4px",
      },
      paddingTop: "30px",
    },
    mainDiv: {
      backgroundColor: "#f8f8f8",
      [theme.breakpoints.down("xs")]: {
        margin: "9px",
      },
      borderBottomLeftRadius: "25px",
      borderBottomRightRadius: "25px",
      height: "100%",
    },
    yourClass: {
      width: "715px",
      margin: "auto",
      marginTop: "40px",
      [theme.breakpoints.down("xs")]: {
        width: "95%",
        margin: "10px",
      },
    },
    c1: {
      width: "40%",
      minWidth: "250px",
      margin: "15px 5px 15px 15px",
      display: "flex",
      fontWeight: "bold",
      "@media (max-width: 430px)": {
        margin: "0px",
      },
      position: "relative",
      flexDirection: "column",
      alignItems: "center",
    },
    c2: {
      width: "47%",
      minWidth: "250px",
      backgroundColor: "#f8f8f8",
      borderRadius: "25px",
      padding: "15px",
      margin: "15px 25px 15px 0px",
      "@media (max-width: 430px)": {
        margin: "0px 0px 0px 0px",
      },
      "& h1": {
        color: colors.orangeSolid,
        margin: "0px",
        padding: "0px",
      },
      "& h5": {
        margin: "0px",
        padding: "0px",
        fontSize: "15px",
        marginBottom: "15px",
      },
      "& p": {
        fontSize: "14px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        margin: "5px 0px",
        "& span": {
          display: "flex",
          alignItems: "center",
          "& strong": {
            "@media (max-width: 430px)": {
              width: "35px",
            },
          },
        },
        "& div": {
          width: "8px",
          height: "8px",

          border: "1px solid gray",
          margin: "0 10px 0 -15px",
          borderRadius: "5px",
        },
      },
    },
    chart_conatiner: {
      position: "relative",
      width: "300px",
      minHeight: "10px",
      display: "flex",
      "@media (max-width: 430px)": {
        width: "100%",
        marginTop: "10px",
      },
      flexDirection: "column",
      marginTop: "35px",
    },
    chart_1: {
      width: "92px",
      background: colors.sun_r,
      height: `${(a / (a + c)) * 100}px`,
      maxHeight: `${(a / (a + c)) * 100}px`,
      alignSelf: "flex-end",
      marginRight: "16px",
      borderRadius: "8px 8px 0px 0px",
      fontSize: "20px",
      display: "flex",
      "@media (max-width: 430px)": {
        height: `${(a / (a + c)) * 100}px`,
        width: "71px",
        fontSize: "14px",
        borderRadius: "4px 4px 0px 0px",
      },
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    helperp1: {
      fontSize: "9px",
      fontSize: "15px",
      display: "flex",
      alignSelf: "flex-end",
      marginLeft: "10px",
      "@media (max-width: 430px)": {
        display: "flex",
        alignSelf: "flex-end",
        fontSize: "12px",
        marginRight: "0px",
      },
      "@media (min-width: 900px)": {
        display: "flex",
        alignSelf: "flex-end",
        fontSize: "15px",
        marginRight: "10px",
      },
    },
    marignLrft: {
      marginLeft: "20px",
    },
    helperp2: {
      fontSize: "15px",
      marginLeft: "10px",
      width: "100px",
      marginTop: "10px",
      "@media (max-width: 430px)": {
        fontSize: "12px",
        marginLeft: "0px",
      },
      "@media (min-width: 900px)": {
        fontSize: "15px",
        marginLeft: "10px",
      },
    },
    typo_responsive: {
      "@media (max-width: 430px)": {
        fontSize: "9px",
        display: "none",
        position: "absolute",
      },
    },
    logic: {
      display: "none",
      "@media (max-width: 430px)": {
        display: "block",
        textAlign: "center",
        margin: "0px",
        padding: "0px",
      },
    },
    chart_2: {
      width: "92px",
      background: "darkred",
      height: `${(c / (a + c)) * 100}px`,
      maxHeight: `70.137255px`,
      borderRadius: "8px 8px 0px 0px",
      fontSize: "20px",
      color: "#fff",
      display: "flex",
      "@media (max-width: 530px)": {
        height: `${(c / (a + c)) * 100}px`,
        borderRadius: "0px 0px 4px 4px",
        fontSize: "14px",
        width: "70px",
      },
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    chart_3: {
      width: "92px",
      background: "#219653",
      // right: "108px",
      // top: "80px",
      // position: "absolute",
      height: `${(b / (a + b)) * 100}px`,
      maxHeight: "70.045455px",
      marginLeft: "106px",
      borderRadius: "0px 0px 8px 8px",
      fontSize: "20px",
      color: "#fff",
      display: "flex",
      "@media (max-width: 530px)": {
        height: `${(b / (a + b)) * 100}px`,
        borderRadius: "0px 0px 4px 4px",
        marignLeft: "90px",
        fontSize: "14px",
        width: "71px",
      },
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    h5Tag: {
      fontFamily: "Nunito",
      fontWeight: "400",
      "@media (max-width: 530px)": {
        fontSize: "14px",
      },
      width: "90%",
      margin: "auto",
      fontSize: "16px",
      textAlign: "center",
      paddingBottom: "22px",
    },
    h2_helpers: {
      fontFamily: "Nunito",
      fontWeight: "600",
      fontSize: "16px",
    },
    finace_container1: {
      width: "100%",
      margin: "10px 0px 10px 0px",
      display: "flex",
      height: "102px",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
    fontClass: {
      backgroundColor: "#C4C4C4",
      color: "black",
      borderRadius: "16px",
      width: "76px",
      textAlign: "center",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "10px",
        width: "150px",
      },
      marginLeft: "25px",
      paddingTop: "3px",
      alignSelf: "center",
      height: "30px",
      "@media (max-width: 560px)": {
        fontSize: "14px",
      },
      "@media (max-width: 560px)": {
        fontSize: "14px",
      },
    },
    familyButton: {
      backgroundColor: "#fff",
      color: "black",
      borderRadius: "25px",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "10px",
        width: "150px",
      },
      alignSelf: "center",
      height: "30px",
      border: `1px solid ${colors.darkBlue}`,
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <TopHeader />
      <div className={classes.mainDiv}>
        <h1 className={classes.forH1}>
          {isEnglish
            ? "Review your monthly Electricity cost"
            : "Überprüfen Sie Ihre monatlichen Stromkosten"}
        </h1>
        <div className={classes.root}>
          <div className={classes.c1}>
            <Typography className={classes.typo_responsive}>
              {isEnglish
                ? "Monthly Electricity Costs"
                : "Monatliche Elektrizitätskosten"}
            </Typography>
            <div className={classes.chart_conatiner}>
              <div
                style={{
                  marginLeft: window.innerWidth < 500 ? "162px" : "196px",
                }}
              >
                <p className={classes.helperp1}>
                  {isEnglish ? `with solar system` : `mit Solar System`}
                </p>
                <div className={classes.chart_1}>
                  {commaDotHandler(a, `${isEnglish ? "en" : "de"}`)} €
                </div>
              </div>
              <div
                className={classes.chart_3}
                style={{
                  marginLeft: window.innerWidth < 500 ? "90px" : "106x",
                }}
              >
                {commaDotHandler(b, `${isEnglish ? "en" : "de"}`)} €
              </div>
              <div className={classes.marignLrft}>
                <div className={classes.chart_2}>
                  {commaDotHandler(c, `${isEnglish ? "en" : "de"}`)} €
                </div>
                <p className={classes.helperp2}>
                  {isEnglish ? "without solar system" : "ohne Solar"}
                </p>
              </div>
            </div>
          </div>
          <div className={classes.c2}>
            <h1>
              {commaDotHandler(
                Math.round(monthly_plus / 12),
                `${isEnglish ? "en" : "de"}`
              )}
              €
            </h1>
            <h5>{isEnglish ? "Monthly cost" : "Monatliches cost"}</h5>
            <Typography>
              <span>
                <div style={{ background: "darkred" }} />
                {isEnglish
                  ? "Electricity cost before PV"
                  : "Ersparnis durch Eigenverbrauch"}
              </span>
              <span>
                <strong>
                  {commaDotHandler(
                    Math.round(costs_and_savings.electricity_cost_before / 12),
                    `${isEnglish ? "en" : "de"}`
                  )}{" "}
                  €
                </strong>
              </span>
            </Typography>
            <Typography>
              <span>
                <div style={{ background: "darkgreen" }} />
                {isEnglish ? "Feed-in-tariff" : "Einspeisevergütung"}
              </span>
              <span>
                <strong>
                  {commaDotHandler(
                    Math.round(costs_and_savings.feedin_savings / 12),
                    `${isEnglish ? "en" : "de"}`
                  )}{" "}
                  €
                </strong>
              </span>
            </Typography>
            {project.isFinancing ? (
              <Typography>
                <span>
                  <div style={{ background: colors.sun }} />
                  {isEnglish ? "Financing rate" : "Finanzierungsrate"}
                </span>
                <span>
                  <strong>
                    {commaDotHandler(
                      Math.round(acutal_rate / 10 / 12),
                      `${isEnglish ? "en" : "de"}`
                    )}{" "}
                    €
                  </strong>
                </span>
              </Typography>
            ) : (
              ""
            )}
            <Divider style={{ margin: "20px 0px" }} />
            <Typography>
              <span>
                <strong>
                  {isEnglish ? "Monthly cost" : "Monatliches cost"}
                </strong>
              </span>
              <span>
                <strong>
                  {monthly_plus > 0
                    ? `${commaDotHandler(
                        Math.round(monthly_plus / 12),
                        `${isEnglish ? "en" : "de"}`
                      )}€`
                    : `- ${commaDotHandler(
                        Math.round(monthly_plus / 12),
                        `${isEnglish ? "en" : "de"}`
                      )}€`}
                </strong>
              </span>
            </Typography>
          </div>
        </div>
        <h5 className={classes.h5Tag}>
          {isEnglish
            ? "This is showing your expected monthly electricity cost - with your PV system in the long term you´re well prepared for increasingelectricity prices or increasing demand."
            : "Hier sehen Sie Ihre voraussichtlichen monatlichen Stromkosten – mit Ihrer PV-Anlage inLangfristig ist man gut gerüstet für steigenden Strom Preise oder steigende Nachfrage."}
        </h5>
      </div>
    </div>
  );
};

const DetailTab2 = ({
  costs_and_savings,
  financing_details,
  setYearlyConsumptionDialog,
}) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const classes = useStyles();
  return (
    <div
      className={classes.yourClass}
      style={{
        width: window.innerWidth < 500 ? "95%" : "725px",
        margin: window.innerWidth < 500 ? "10px" : "auto",
        marginTop: window.innerWidth < 500 ? "10px" : "20px",
      }}
    >
      <BuyChartsForNew
        isEnglish={isEnglish}
        costs_and_savings={costs_and_savings}
        financing_details={financing_details}
        setYearlyConsumptionDialog={setYearlyConsumptionDialog}
      />
    </div>
  );
};

export default DetailTab2;
