import { Rate } from "antd";
import React, { useEffect, useState } from "react";
import { ProductCardWrapper } from "./ProductCardStyles";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import commaDotHandler from "helperMethod";
import { db } from "../../../firebase";
import { getNumber } from "utils/costs";
import Forward from "Assets/application_step_1/Forward.svg";
import backword from "Assets/application_step_1/backword.svg";
import { useDispatch } from "react-redux";
import Germany from "Assets/images/Germany.svg";
import { useParams } from "react-router";

const ProductCard = ({
  details,
  mainData,
  list,
  type,
  setList,
  isSelectTrue,
  setIsListOpen,
  setOpen,
  needToFilterList,
  isListOpen,
  setNeedToFilterList,
  setFilterData,
}) => {
  const isEnglish = useSelector((state) => state?.global?.isEnglish);
  const [ratingsData, setRatingsData] = useState([]);
  const [state, setState] = useState(false);
  const dispatch = useDispatch();
  let { projectId } = useParams();
  console.log(needToFilterList);
  // function for caculating average reviews
  const giveAverageRatings = (data) => {
    let rate = 0;
    for (let i = 0; i < data.length; i++) {
      rate = rate + data[i].rating;
    }
    const finalRate = rate / data.length;
    return finalRate;
  };
  //calling useEffect for collecting average reviews
  useEffect(() => {
    if (details?.item) {
      db.collection("feedbacks")
        .where("product_id", "==", "c1")
        .get()
        .then((snapshots) => {
          let data = [];
          snapshots.forEach((doc) => {
            data.push(doc.data());
          });
          setRatingsData(data);
        });
    }
  }, []);

  useEffect(() => {
    db.collection("saved_projects")
      .doc("XjwY0OfQvpRpnouf1iBowtpLOg5360ffd3ae8a3044201b305f464v4")
      .get()
      .then((doc) => console.log(doc.data(), "s"))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (details?.item?.properties?.length) {
      for (let i = 0; i < details?.item?.properties?.length; i++) {
        if (details?.item?.properties[i]?.title?.includes("Made in Germany")) {
          setState(true);
        }
      }
    }
  }, [details]);

  const setProjectAsync = (data) =>
    dispatch({ type: "SET_PROJECT_ASYNC", payload: data });

  const costDetails = (component, isEnglish, isB2B) => {
    if (component?.item) {
      const component_cost = getNumber(
        component?.item[isB2B ? "price_b2b" : "price_b2c"]
      );
      const warranty_cost = getNumber(
        component?.item?.extended_warranty_cost || component_cost * 0.01
      );

      const total_cost = component.extended_warranty
        ? component_cost + warranty_cost
        : component_cost;
      return `${component?.quantity}  x €${commaDotHandler(
        total_cost,
        `${isEnglish ? "en" : "de"}`
      )}`;
    } else {
      return 0;
    }
  };

  const costDetails1 = (component, isEnglish, isB2B) => {
    if (component?.item) {
      const component_cost = getNumber(
        component?.item[isB2B ? "price_b2b" : "price_b2c"]
      );
      const warranty_cost = getNumber(
        component?.item?.extended_warranty_cost || component_cost * 0.01
      );

      const total_cost = component.extended_warranty
        ? component_cost + warranty_cost
        : component_cost;
      return `€${commaDotHandler(
        component?.quantity * total_cost,
        `${isEnglish ? "en" : "de"}`
      )} `;
    } else {
      return 0;
    }
  };

  const onProductChange = () => {
    if (details?.item) {
      setIsListOpen(!isListOpen);
      if (isListOpen) {
        setList([]);
        setFilterData([]);
      } else {
        db.collection("products")
          .where("type", "==", details?.item?.type)
          .get()
          .then((snap) => {
            let data = [];
            let newsItem = {};
            snap.forEach((doc, key) => {
              newsItem = { ...doc.data(), newsId: doc.data().objectId };
              const datas = {
                item: newsItem,
                extended_warranty: false,
                quantity: details?.quantity,
              };
              data.push(datas);
            });
            setList(data);
            setFilterData(data);
          });
      }
    }
  };

  const onSelectHandler = () => {
    console.log(mainData?.pid, "pid");
    const docRef = db.collection("saved_projects").doc(mainData?.pid);
    docRef
      .get()
      .then((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          console.log("hjello");
          const project_details = {
            ...doc.data().project_details,
            components: {
              ...doc.data().project_details.components,
              items: {
                ...doc.data().project_details.components.items,
                [type]: details,
              },
            },
          };
          const updatedData = {
            ...doc.data(),
            project_details,
          };
          docRef.set(updatedData);
          dispatch({ type: "DUMMYPROJECT_PROJECT_ADDED" });
          setOpen(false);
          setList([]);
          setFilterData([]);
          setNeedToFilterList([]);
          if (projectId === mainData?.pid) {
            setProjectAsync(updatedData?.project_details);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ProductCardWrapper>
      <div className="procut_container">
        <div className="mekeItCenter">
          <div className="upper_image">
            <img src={details?.item?.imgurl1} alt="" />
            {details?.item?.properties?.length &&
            needToFilterList?.includes("Made in Germany") ? (
              state ||
              details?.item?.properties?.includes("Made in Germany") ? (
                <img src={Germany} className="helper_class" alt="" />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div className="secount_part">
            <div>
              <div className="texts">{details?.item?.name}</div>
              <div className="texts">{details?.item?.capacity}</div>
            </div>
            <div className="bullet_points">
              <ul>
                {Array.isArray(details?.item?.bullet_point_list) &&
                details?.item?.bullet_point_list?.length
                  ? details?.item?.bullet_point_list?.map((data, index) => {
                      return <li key={index}>{data}</li>;
                    })
                  : null}
              </ul>
            </div>
          </div>
          <div className="third_part">
            <div>
              <div className="texts1">
                {costDetails1(details, isEnglish, mainData?.isB2B)}
              </div>
              <div className="down_text">
                {costDetails(details, isEnglish, mainData?.isB2B)}
              </div>
            </div>
            <div className="important">
              {ratingsData.length && (
                <Rate
                  disabled={true}
                  defaultValue={giveAverageRatings(ratingsData)}
                />
              )}
              <div className="reviews">
                {" "}
                {isEnglish
                  ? `${ratingsData?.length} reviews`
                  : `${ratingsData?.length} Bewertungen`}
              </div>
            </div>
            {isSelectTrue ? (
              <Button
                className="selet_prdocut"
                onClick={() => onSelectHandler()}
              >
                {isEnglish ? "Select Product" : "Produkt auswählen"}
              </Button>
            ) : (
              <Button
                className="change_product"
                endIcon={
                  isListOpen ? (
                    <img src={backword} alt="" />
                  ) : (
                    <img src={Forward} alt="" />
                  )
                }
                onClick={() => onProductChange()}
              >
                Change Product
              </Button>
            )}
          </div>
        </div>
      </div>
    </ProductCardWrapper>
  );
};

export default ProductCard;
