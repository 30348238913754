export function parseReverseGeo(geoData) {
  // debugger;
  let locality, city, state, country, postcode, returnStr;
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text;
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text;
      }
      if (v.id.indexOf("place") >= 0) {
        city = v.text;
      }
      if (v.id.indexOf("region") >= 0) {
        state = v.text;
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text;
      }
    });
  }
  returnStr = geoData.place_name;

  return {
    number: geoData.address || "",
    locality: locality || "",
    address: (
      geoData.text +
      (geoData.address ? " " : "") +
      (geoData.address ? geoData.address : "")
    ).toLowerCase(),
    city,
    state,
    postcode,
    country,
    complete: geoData.place_name,
    geometry: geoData.geometry,
  };
}
