import React from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";

const MainContainer = styled("div")`
  width: 72%;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  padding-right: 28px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const LeftContainer = styled("div")`
  width: 19%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 31%;
  }
`;
const RightContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  .mobile {
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      width: 103%;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;
const Text1 = styled("span")`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #666666;
`;
const Text3 = styled("span")`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #666666;
`;
const Text2 = styled("span")`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #2d4764;
  margin-right: 4px;
`;
const SubContainer = styled("div")`
  margin-top: 4px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
`;
const SubContainer12 = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 25px;
  border: 1.05105px solid #2d4764;
  border-radius: 12.012px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  color: #304864;
`;
const SubContainer13 = styled("div")`
  margin-left: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #2d4764;
  cursor: pointer;
  margin-top: 5px;
`;
const Profile = ({ data, userData, chatState, setChatState }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);

  return (
    <MainContainer>
      {userData !== null ? (
        <>
          <LeftContainer>
            {userData.img_url ? (
              <Avatar
                src={userData.img_url}
                style={{ width: 55, height: 55 }}
              />
            ) : (
              <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
                {userData?.fullName}
              </Avatar>
            )}
          </LeftContainer>
          <RightContainer>
            <div className="mobile">
              <Text2>{userData.fullName},</Text2>
              <Text3>
                {isEnglish
                  ? "wants to start a conversation with you"
                  : "möchte ein Gespräch mit Ihnen beginnen"}{" "}
              </Text3>
            </div>
            {chatState ? (
              <Text3> {isEnglish ? "via direct chat" : "per Direktchat"}</Text3>
            ) : (
              <Text3>Duration: {data.duration} min</Text3>
            )}
            <Text1 style={{ marginTop: "12px", marginBottom: "8px" }}>
              {data.description}
            </Text1>
            <SubContainer>
              <SubContainer12>
                {isEnglish ? "View Profile" : "Profil anzeigen"}
              </SubContainer12>
              <SubContainer13>
                {" "}
                {isEnglish ? "Project details" : "Projekt Details"}
              </SubContainer13>
            </SubContainer>
          </RightContainer>
        </>
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default Profile;
