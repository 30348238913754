import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { db } from "../../../firebase";
import OBJECT__POPUP from "../components/popups/admin.project.popup";
import KnowledgehubPopup from "../components/popups/admin.knowledgehub.popup";
import { renderRatingEditInputCell } from "./DataGridColumns/commanFunction";

const buttonStyle = {
  backgroundColor: "#3f51b5",
  color: "white",
};

const KnowledgeHub = () => {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState("");
  const [rowData, setRowData] = useState("");
  const [objectDetails, setObjectDetails] = useState({});
  const [selected_items, setselected_items] = useState(null);

  useEffect(() => {
    db.collection("knowledgehub").onSnapshot((snap) => {
      let embed_links = [];
      snap.forEach((doc) => {
        const quote = {
          ...doc.data(),
        };
        embed_links.push(quote);
      });
      console.log("embed", embed_links);
      setRowData(embed_links);
    });
  }, [open]);

  const columns = [
    {
      field: "title",
      width: 200,
      renderEditCell: renderRatingEditInputCell,
      editable: true,
    },
    {
      field: "tags",
      width: 300,
      editable: true,
      renderCell: (params) => {
        console.log("params", params.value);
        return (
          <div style={{ display: "flex", gap: "4px" }}>
            {params.value.map((tag) => (
              <span>#{tag}</span>
            ))}
          </div>
        );
      },
      renderEditCell: renderRatingEditInputCell,
    },
    {
      field: "link",
      width: 250,
      editable: true,
      renderEditCell: renderRatingEditInputCell,
    },
  ];

  const [newOpen, setNewOpen] = useState(false);
  const handleClickOpen = () => {
    setNewOpen(true);
  };

  const deleteSelectedQuotes = () => {
    if (selected_items) {
      console.log(selected_items);
      selected_items.forEach((item) => {
        db.collection("knowledgehub")
          .doc(item)
          .delete()
          .then(() => console.log("deleted"))
          .catch((err) => console.log(err));
      });
    } else {
      alert("Select Items to Delete");
    }
  };

  return (
    <Accordion expanded={true} style={{ flex: "1", margin: "0px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>KnowledgeHub Youtube Links</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "15px 30px",
              marginTop: "-10px",
              gap: "8px",
            }}
          >
            <Button style={buttonStyle} onClick={handleClickOpen}>
              Add Link
            </Button>
            <Button style={buttonStyle} onClick={deleteSelectedQuotes}>
              Delete Link
            </Button>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              flex: "1",
              minHeight: "65vh",
            }}
          >
            {rowData.length > 0 ? (
              <DataGrid
                rows={rowData}
                getRowId={(row) => row.id}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(e) => setselected_items(e)}
                disableSelectionOnClick
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <OBJECT__POPUP
          open={open}
          objectDetails={objectDetails}
          openMethod={setOpen}
          id={id}
        />
        <KnowledgehubPopup open={newOpen} setopen={setNewOpen} />
      </AccordionDetails>
    </Accordion>
  );
};

export default KnowledgeHub;
