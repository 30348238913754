/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import SettingsIcon from "@material-ui/icons/Settings";
import MessageIcon from "@material-ui/icons/Message";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import ContactManagement from "pages/AdminPanel/partials/admin.contact";
import Listing from "pages/userdashboard/listing/Listing";
import Profile from "pages/userdashboard/profile/Profile";

const dashboardRoutes = [
  {
    path: "/Profile",
    name: "User Profile",
    germanName: "Benutzerprofil",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Profile,
    layout: "/userdashboard",
  },
];

export default dashboardRoutes;
