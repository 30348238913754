import React, { useState } from "react";
import { Dialog, Button, makeStyles } from "@material-ui/core";
import Payment from "../../pages/Application/partials/Payment";
import Hero from "../../Assets/Hero.svg";
import Close from "../../Assets/Close.svg";
import Group from "../SignUpSignIn/images/Group.svg";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1124px",
    overflow: "auto",
    overflowX: "hidden",
    height: "634px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      justifyContent: "flex-start",
    },
    alignItems: "center",
  },
  button_group: {
    backgroundColor: "#FFD75D",
    border: "none",
    borderRadius: "16px",
    height: "41px",
    width: "268px",
    color: "#2D4764",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      width: "224px",
      height: "34px",
      padding: "9px",
      fontSize: "13px",
    },
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
    marginBottom: "15px",
    textAlign: "center",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  centerThings: {
    marginTop: "4px",
    [theme.breakpoints.down("xs")]: {
      width: "101%",
    },
  },
}));

const WelcomeUser = ({ open, setOpen }) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const handleClose = () => {
    dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
    setOpen(false);
  };
  const dispatch = useDispatch();
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => {
            dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
            setOpen(false);
          }}
        />
      </div>
      <div className={classes.root}>
        <div className={classes.centerThings}>
          <Payment />
        </div>
        <div>
          <img src={Hero} style={{ height: "153px" }} alt="" />
        </div>
        <button
          className={classes.button_group}
          onClick={() => {
            setOpen(false);
            dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
          }}
          variant="contained"
        >
          {isEnglish ? "Discover your project" : "Entdecke dein Projekt"}
        </button>
      </div>
    </Dialog>
  );
};

export default WelcomeUser;
