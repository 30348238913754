import styled from "styled-components";

export const ProductPopupWrapper = styled("div")`
  .product_main {
    height: auto;
    min-width: 1124px;
    border-radius: 22px;
    position: relative;
  }
  .img_set {
    position: absolute;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    top: 20px;
  }
  .heading {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    padding-left: 10px;
    color: #304864;
    width: 80%;
    margin: auto;
    span {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #304864;
    }
  }
  .first_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin: auto;
    gap: 15px;
    padding-top: 20px;
  }
  .check_box_contaienr {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .product_list_container {
    height: auto;
    margin-top: 22px;
    width: 100%;
    border-radius: 22px;
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    padding-left: 50px;
    padding-right: 50px;
  }
  .changeText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2d4764;
  }
  .linne {
    width: 100%;
    height: 0px;
    border: 1px solid #c0c0c0;
  }
  .slider_div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 5px;
    div {
      font-style: normal;
      width: 224px;
      font-weight: 400;
      font-size: 14px;
      color: #2d4764;
    }
  }
  .secound_continaer {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .same_two {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .first_one {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .lable_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  @media (max-width: 500px) {
    .product_main {
      min-width: 95% !important;
      width: 323px;
    }
    .product_list_container {
      box-shadow: none;
      padding-left: 0px;
      height: 467px;
      padding-right: 0px;
    }
    .first_container {
      margin-bottom: 6px;
    }
    .check_box_contaienr {
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 30px;
    }
    .heading {
      font-size: 16px;
    }
    .heading span {
      font-size: 16px;
    }
    .secound_continaer {
      flex-direction: column;
      width: 100%;
    }
    .same_two {
      flex-direction: column;
      width: 100%;
    }
    .lable_text {
      font-size: 12px;
    }
  }
`;
