import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Form, Input, Button } from "antd";
import { Dialog, AppBar, Toolbar, Typography } from "@material-ui/core/";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { db } from "../../../../firebase";
import { toast } from "react-toastify";
import { convertToEmbedYoutubeLink } from "utils/utilsfunctions";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const KnowledgehubPopup = ({ open, setopen }) => {
  const classes = useStyles();

  const onFinish = (values) => {
    const title = values.title;
    const tags = values.tags.split(" ");
    const link = values.link;
    const embed_link = convertToEmbedYoutubeLink(values.link);
    let id = uuidv4();
    const obj = {
      id,
      title: title,
      tags: tags,
      link: link,
      embed_link: embed_link,
    };
    db.collection("knowledgehub")
      .doc(id)
      .set(obj)
      .then(() => {
        toast.success("Successfully saved Youtube Video");
        setopen(false);
      })
      .catch((error) => {
        toast.error("Error Occured");
        setopen(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Dialog open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setopen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add Youtube Video Information
            </Typography>
          </Toolbar>
        </AppBar>
        <Form
          name="basic"
          style={{
            margin: "auto",
            fontSize: "26px",
            width: "80%",
            marginTop: 20,
          }}
          initialValues={{
            title: "",
            tag: "",
            link: "",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="title">
            <Input placeholder="title" />
          </Form.Item>
          <Form.Item name="tags">
            <Input placeholder="tags (space seperated)" />
          </Form.Item>
          <Form.Item name="link">
            <Input placeholder="link" />
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "200px" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Dialog>
    </>
  );
};

export default KnowledgehubPopup;
