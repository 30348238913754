import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const MainContainer = styled("div")`
  position: absolute;
  /* min-height: 156px; */
  height: 244px;
  width: 487px;
  background: #ffffff;
  border-radius: 25px;
  transform: translate(34px, 46px);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  left: 0;
  background: #f9f9f9;
  border-top: 0.7px solid #c0c0c0;
  z-index: 1000;
`;
const SubContainer = styled("div")`
  width: 100%;
  /* min-height: 72px; */
  height: 180px;
  overflow-y: scroll;
`;
const BottomContainer = styled("div")`
  width: 100%;
  position: absolute;
  bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding-right: 24px;
`;
const SubContainer1 = styled("div")`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2d4764;
  padding-left: 48px;
  cursor: pointer;
  &:hover {
    background: #fefaf0;
  }
`;
const TopicButton = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 27px;
  border: 1px solid #2d4764;
  box-sizing: border-box;
  border-radius: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #304864;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
const SearchResult = ({
  isEnglish,
  data,
  selectedItem,
  move,
  setOpen,
  setValue,
}) => {
  const isDireactSearch = useSelector((state) => state.global.isDireactSearch);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <MainContainer>
      <SubContainer>
        {selectedItem === "Categories"
          ? data.map((item) => {
              return (
                <SubContainer1
                  onClick={() => {
                    const datass = {
                      category_id: item.id,
                      title: item.name,
                    };
                    dispatch({
                      type: "SET_DIREACT_CATEGORY_SEARCH",
                      payload: datass,
                    });
                    move(true);
                    setOpen(false);
                    setValue("");
                  }}
                >
                  {item.name}
                </SubContainer1>
              );
            })
          : data.map((item) => {
              return (
                <SubContainer1
                  onClick={() => {
                    const datass = {
                      flag: !isDireactSearch.flag,
                      category_id: item.category_id,
                      direactId: item.id,
                      title: item.title,
                    };
                    dispatch({ type: "SET_DIREACT_SEARCH", payload: datass });
                    move(true);
                  }}
                >
                  {item.title}
                </SubContainer1>
              );
            })}
      </SubContainer>
      <BottomContainer>
        <div>haven't found yet</div>
        <TopicButton onClick={() => {}}>
          {isEnglish ? "+ New Topic" : "Neues Thema"}
        </TopicButton>
      </BottomContainer>
    </MainContainer>
  );
};
export default SearchResult;
