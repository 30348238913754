import React, { useState, useEffect, useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core/";
import { DataGrid } from "@material-ui/data-grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { db } from "../../../firebase";
import { Button } from "antd";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

//UserDetails section of Admin Dashboard
const WebinarDetails = () => {
  const [userData, setUserData] = useState([]);
  const [webinarData,setWebinarData] = useState([]);

  const featchUserDetail = async (doc) => {
   const result = await db.collection("users").doc(doc.data()?.uid).get();
   return result.data()
  }
  const getFormattedDate = (date) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${daysOfWeek[date.getDay()]} ${date.getDate()}.${month}.${year}`;
  };
  useEffect(async() => {
    //fetching details of all the users from database
    
    const snap = await (await db.collection("joinedWebinars").get()).docs;
        const data = [];
        for(let doc of snap ) {
          const userDoc = await db.collection("users").doc(doc.data()?.uid).get()
            data.push({...doc.data(),...userDoc.data(),...doc.data()?.lastJoinedWebinar,date: getFormattedDate(new Date(doc.data()?.lastJoinedWebinar?.date.seconds * 1000))})
        }
        setUserData(data);
  }, []);

  console.log(userData,"userData");

  const columns = [
    { field: "fullName", width: 150 },
    { field: "email", width: 180 },
    { field: "address", width: 380 },
    { field: "id", width: 150 },
    { field: "date", width: 150 },
    { field: "city", width: 150 },
    { field: "meetingNumber", width: 150 },
  ];

  const getData = useMemo(() => {
    return userData?.map((data)=> {
      return {
        fullName: data?.fullName,
        email: data?.email,
        address: data?.address,
        id: data?.id,
        date: data?.date,
        city: data?.city,
        meetingNumber: data?.meetingNumber,
      }
    })
  },[userData])

  return (
    <>
      <Accordion expanded={true} style={{ flex: "1", width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Webinar Details</Typography>
          <Button style={{marginLeft:"10px"}}>
          {
            userData?.length > 0 && 
          <CSVLink data={getData}>Export in csv</CSVLink>
          }
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
            }}
          >
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", minHeight: "65vh" }}
            >
              {userData.length > 0 ? (
                <DataGrid
                  rows={userData}
                  getRowId={(row) => {
                    return row.uid;
                  }}
                  columns={columns}
                  onCellEditCommit={(values, event) => {
                    handleChangeRow(values, event);
                  }}
                  pageSize={7}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default WebinarDetails;
