import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cross from "../Invitaion/Assets/Cross";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import DateSide from "./DateSide";
import { useDispatch } from "react-redux";

const ModalWrapper = styled("div")`
  .mainModal {
    position: relative;
    width: 1008px;
    height: 636px;
    padding-top: 69px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    @media (max-width: 600px) {
      width: 96%;
      height: 94vh;
      overflow-y: auto;
      padding-top: 40px;
      overflow-x: hidden;
      margin: auto;
    }
  }
  .main_side {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 23px;
    @media (max-width: 600px) {
      flex-direction: column;
      width: 93%;
      margin: auto;
    }
  }
  .left_side {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 536px;
    width: 412px;
    @media (max-width: 600px) {
      width: 99%;
      height: 454px;
    }
  }
  .first_part {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #2d4764;
  }
  .secound_part {
    height: 419px;
    width: 100%;
    padding-top: 27px;
    padding-left: 27px;
    padding-right: 10px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    @media (max-width: 600px) {
      padding-left: 10px;
      height: 363px;
    }
  }
  .text_calender {
    font-style: normal;
    font-weight: bold;
    margin-bottom: 38px;
    font-size: 20px;
    line-height: 27px;
    text-align: start;
    color: #2d4764;
    @media (max-width: 600px) {
      margin-bottom: 16px;
    }
  }
  .rmdp-shadow {
    box-shadow: none;
  }
  .right_side {
    height: 536px;
    width: 412px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    @media (max-width: 600px) {
      width: 99%;
      height: auto;
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }
  .same_class {
    width: 370px;
    height: 40px;
    border: 1px solid #ffd75d;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    justify-content: center;
    cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
  }
  .hover_contaienr {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .new_class {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    border: 1px solid #2d4764;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .new_button {
    width: 180px;
    height: 40px;
    background: #ffd75d;
    border: 1px solid #ffd75d;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    :hover {
      background: #ffd75d;
    }
    text-transform: uppercase;
    color: #2d4764;
    border-radius: 4px;
  }
  .rmdp-week {
    width: 344px;
    @media (max-width: 600px) {
      width: 276px;
    }
  }
  .rmdp-week-day {
    font-weight: bold;
    font-size: 20px;
    color: #2d4764;
  }
  .rmdp-day span {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2d4764;
  }
  .rmdp-day {
    height: 37px;
  }
  .rmdp-header-values span {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #2d4764;
  }
  .rmdp-arrow {
    border-top-color: rgba(255, 215, 93, 1);
    border-top-style: solid;
    border-top-width: 0px;
    border-right-color: rgba(255, 215, 93, 1);
    border-right-style: solid;
    border-right-width: 2px;
    border-bottom-color: rgba(255, 215, 93, 1);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-left-color: rgba(255, 215, 93, 1);
    border-left-style: solid;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    height: 11px;
    width: 11px;
  }
  .rmdp-arrow-container:hover {
    background-color: rgba(255, 215, 93, 1);
  }
  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #ffd75d;
  }
  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #ffd75d;
    box-shadow: none;
  }
  .rmdp-day:not(.rmdp-deactive) .highlight-green {
    color: #ffd75d;
  }
  .rmdp-day.rmdp-selected .highlight-green {
    color: #ffffff;
    background-color: #ffd75d;
  }
`;
const CrossContainer = styled("div")`
  position: absolute;
  right: 46px;
  top: 36px;
  cursor: pointer;
  @media (max-width: 600px) {
    right: 18px;
    top: 14px;
  }
`;
const InsideModal = ({ handleCancel, data, name }) => {
  const [value, setValue] = useState(new Date());
  const [newDate, setNewDate] = useState(null);
  const [thatManyDays, setThatManyDays] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [slots, setSlots] = useState([]);
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
  const currentUser_uid = useSelector((state) => state.user.currentUser.uid);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const returnDatysDates = () => {
    let newObje = {};
    for (const key in data.avalabilty_slots) {
      if (data.avalabilty_slots[key].length > 0) {
        const value = key;
        newObje = {
          ...newObje,
          [value]: [],
        };
      }
    }
    for (const key in newObje) {
      console.log(`${key}: ${newObje[key]}`);
      var monday = moment().startOf("month").day(`${key}`);
      if (monday.date() > 7) monday.add(7, "d");
      var month = monday.month();
      while (month === monday.month()) {
        newObje[key].push(parseInt(monday.toString().split(" ")[2]));
        monday.add(7, "d");
      }
    }
    return newObje;
  };

  useEffect(() => {
    setThatManyDays(returnDatysDates());
  }, []);

  useEffect(() => {
    if (newDate) {
      for (const key in data.avalabilty_slots) {
        if (data.avalabilty_slots[key].length > 0) {
          if (key.includes(newDate.split(" ")[0])) {
            console.log("hey");
            return setSlots(data.avalabilty_slots[key]);
          } else {
            setSlots([]);
          }
        }
      }
    }
  }, [newDate]);

  const onChange = (date) => {
    setNewDate(date?.format?.("dddd DD MMMM YYYY"));
    console.log(date?.format?.("dddd DD MMMM YYYY"));
  };

  const onSubmitHandler = async (datas) => {
    try {
      const submitData = {
        date: newDate,
        bookedSlot: datas,
        sender_uid: data.sender_uid,
        receiver_uid: currentUser_uid,
        duration: data.duration,
        description: data.description,
      };
      setLoading(true);
      console.log(submitData, "submitData");
      const result = await axios.post(
        "https://solarhub24.herokuapp.com/SolarHub-Discourse/invitation/acceptInvitationwithSchedule",
        submitData
      );
      if (result) {
        setLoading(false);
        dispatch({ type: "OPEN_MODAL" });
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      handleCancel();
      console.log(error);
    }
  };

  return (
    <ModalWrapper>
      <div className="mainModal">
        <CrossContainer
          onClick={() => {
            handleCancel();
          }}
        >
          <Cross />
        </CrossContainer>
        <div className="main_side">
          <div className="left_side">
            <div className="first_part">
              {isEnglish
                ? `Pick a date and time to meet with ${name}`
                : `Wähle ein Datum und eine Uhrzeit um mit ${name} zu sprechen`}
            </div>
            <div className="secound_part">
              <div className="text_calender">
                {data.duration}{" "}
                {isEnglish ? "Minute Meeting" : "Minuten Meeting"}
              </div>
              <Calendar
                value={value}
                onChange={(date) => {
                  onChange(date?.isValid ? date : "");
                  setValue;
                }}
                minDate={new Date()}
                weekDays={weekDays}
                mapDays={({ date }) => {
                  let color;
                  for (const key in thatManyDays) {
                    if (thatManyDays[key].includes(date.day)) color = "green";
                    if (color)
                      return { className: "highlight highlight-" + color };
                  }
                }}
              />
            </div>
          </div>
          <div className="right_side">
            {slots.length > 0
              ? slots.map((data) => {
                  return (
                    <div>
                      <DateSide
                        loading={loading}
                        data={data}
                        onSubmitHandler={onSubmitHandler}
                      />
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default InsideModal;
