const INITIAL_STATE = {
  waiting_invitaion: [],
  accepted_invitaion: [],
  pending_invitaion: [],
  flag: false,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_WAITING_INVITAION":
      return {
        ...state,
        waiting_invitaion: action.payload,
      };
    case "SET_ALL_INVITAION":
      return {
        ...state,
        waiting_invitaion: action.payload.waiting_invitaion,
        accepted_invitaion: action.payload.accepted_invitaion,
        pending_invitaion: action.payload.pending_invitaion,
      };
    case "SET_ACCEDPTED_INVITAION":
      return {
        ...state,
        accepted_invitaion: action.payload,
      };
    case "SET_PENDING_INVITAION":
      return {
        ...state,
        pending_invitaion: action.payload,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        flag: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        flag: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;
