export const convertToEmbedYoutubeLink = (youtubeLink) => {
  let id = youtubeLink.substr(youtubeLink.lastIndexOf("/") + 1);
  if (id.includes("watch")) {
    id = youtubeLink.substr(youtubeLink.lastIndexOf("=") + 1);
  }
  return `https://youtube.com/embed/${id}`;
};

export const timeBreaker = (totalSeconds) => {
  const seconds = totalSeconds % 60;
  const minutesWM = (totalSeconds - seconds) / 60;
  const minutes = minutesWM % 60;
  const hoursWM = (minutesWM - minutes) / 60;
  const hours = hoursWM % 24;
  const days = (hoursWM - hours) / 24;

  return { seconds, minutes, hours, days };
};
