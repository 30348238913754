import React, { useState, useEffect, Fragment } from "react";
import { Button } from "antd";
import styled from "styled-components";
import { convertToEmbedYoutubeLink } from "utils/utilsfunctions";
import { db } from "../../../firebase";
import { Checkbox, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import useCometChat from "hooks/useCometChat";
import { toast } from "react-toastify";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";

const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const WebinarsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-top: 28px;
`;

const WebinarTitle = styled.div`
  font-size: 28px;
`;

const Webinar = () => {
  const { createWebinarGroup, fetchWebinarGroupList, deleteWebinarGroup } =
    useCometChat();
  const [webinarlinkinput, setwebinarlinkinput] = useState("");
  const [webinarCity, setWebinarCity] = useState("offen");
  const [webinars, setWebinars] = useState([]);
  const [value, setValue] = React.useState(new Date());

  useEffect(() => {
    db.collection("webinar").onSnapshot((snapshots) => {
      const data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          date: new Date(doc.data().date.seconds * 1000),
          meetingNumber: doc.data()?.meetingNumber
            ? doc.data()?.meetingNumber
            : "",
          password: doc.data()?.password ? doc.data()?.password : "",
        });
      });
      setWebinars(data);
    });
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChangeRow = (values, e) => {
    const result = webinars.filter((data, index) => data.id === values.id);
    db.collection("webinar")
      .doc(values?.id)
      .set({
        ...result[0],
        [values.field]: values.value,
      })
      .then(() => {
        console.log("changed");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveWebinarLink = () => {
    let id = webinarlinkinput.substring(webinarlinkinput.lastIndexOf("/") + 1);
    if (id.includes("watch")) {
      id = webinarlinkinput.substring(webinarlinkinput.lastIndexOf("=") + 1);
    }
    if (id === "") {
      toast.warn("Please include webinar link");
    }
    const embedLink = convertToEmbedYoutubeLink(webinarlinkinput);
    console.log("id", id, embedLink);

    if (id !== webinarlinkinput) {
      createWebinarGroup(id, "Webinar Chat");
      db.collection("webinar")
        .doc(id)
        .set({
          id: id,
          shareLink: webinarlinkinput,
          city: webinarCity,
          embedLink: embedLink,
          date: value,
          live: false,
        })
        .then(() => {
          setwebinarlinkinput("");
        });
    }
  };
  const deleteHandler = (id) => {
    const docRef = db.collection("webinar").doc(id);
    deleteWebinarGroup(id);
    docRef
      .delete()
      .then((res) => console.log("succss deleted"))
      .catch((err) => console.log("err"));
  };
  const columns = [
    {
      field: "id",
      width: 70,
      renderCell: (params) => (
        <DeleteIcon
          color="secondary"
          style={{ cursor: "pointer" }}
          onClick={() => deleteHandler(params.id)}
        />
      ),
    },
    {
      field: "meetingNumber",
      width: 210,
      editable: true,
    },
    {
      field: "password",
      width: 210,
      editable: true,
    },
    {
      field: "live",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <strong>
          {params.value}
          <Checkbox
            color="primary"
            size="small"
            checked={params.value}
            onChange={(e) => {
              const docRef = db.collection("webinar").doc(params.id);
              docRef
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    const updatedData = {
                      ...doc.data(),
                      live: !e.target.checked,
                    };
                    docRef.set(updatedData).then(() => {
                      console.log("data updated sucessfully");
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            style={{ marginLeft: 16 }}
          />
        </strong>
      ),
    },
    { field: "city", width: 150, editable: true },
    {
      field: "date",
      width: 250,
      type: "dateTime",
      editable: true,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    { field: "embedLink", width: 350, editable: true },
    { field: "shareLink", width: 350, editable: true },
  ];
  console.log(webinars, "webinars");
  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container>
          <TextField
            value={webinarlinkinput}
            label="Webinar Link"
            onChange={(e) => setwebinarlinkinput(e.target.value)}
          />
          <TextField
            value={webinarCity}
            label="Webinar City"
            onChange={(e) => setWebinarCity(e.target.value)}
          />
          <DateTimePicker
            label="Webinar Date & Time"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button onClick={saveWebinarLink}>Save</Button>
        </Container>
        <WebinarsContainer>
          <WebinarTitle>All Webinars</WebinarTitle>
          <div
            className="ag-theme-alpine"
            style={{
              flex: "1",
              minHeight: "65vh",
            }}
          >
            {webinars.length > 0 ? (
              <DataGrid
                rows={webinars}
                getRowId={(row) => {
                  console.log(row);
                  return row.id;
                }}
                style={{ height: "700px" }}
                columns={columns}
                onCellEditCommit={(values, event) => {
                  handleChangeRow(values, event);
                }}
                pageSize={7}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            ) : (
              ""
            )}
          </div>
        </WebinarsContainer>
      </LocalizationProvider>
    </Fragment>
  );
};

export default Webinar;
