import React from "react";

const Cross = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.312149 0.312149C0.410846 0.213202 0.528094 0.134698 0.657177 0.0811343C0.786261 0.0275706 0.924643 0 1.0644 0C1.20415 0 1.34254 0.0275706 1.47162 0.0811343C1.6007 0.134698 1.71795 0.213202 1.81665 0.312149L7.4394 5.93702L13.0621 0.312149C13.1609 0.213362 13.2782 0.135 13.4073 0.0815365C13.5364 0.0280735 13.6747 0.00055629 13.8144 0.00055629C13.9541 0.00055629 14.0924 0.0280735 14.2215 0.0815365C14.3506 0.135 14.4679 0.213362 14.5666 0.312149C14.6654 0.410936 14.7438 0.528212 14.7973 0.657284C14.8507 0.786355 14.8782 0.924693 14.8782 1.0644C14.8782 1.2041 14.8507 1.34244 14.7973 1.47151C14.7438 1.60059 14.6654 1.71786 14.5666 1.81665L8.94177 7.4394L14.5666 13.0621C14.6654 13.1609 14.7438 13.2782 14.7973 13.4073C14.8507 13.5364 14.8782 13.6747 14.8782 13.8144C14.8782 13.9541 14.8507 14.0924 14.7973 14.2215C14.7438 14.3506 14.6654 14.4679 14.5666 14.5666C14.4679 14.6654 14.3506 14.7438 14.2215 14.7973C14.0924 14.8507 13.9541 14.8782 13.8144 14.8782C13.6747 14.8782 13.5364 14.8507 13.4073 14.7973C13.2782 14.7438 13.1609 14.6654 13.0621 14.5666L7.4394 8.94177L1.81665 14.5666C1.71786 14.6654 1.60059 14.7438 1.47151 14.7973C1.34244 14.8507 1.2041 14.8782 1.0644 14.8782C0.924693 14.8782 0.786355 14.8507 0.657284 14.7973C0.528212 14.7438 0.410936 14.6654 0.312149 14.5666C0.213362 14.4679 0.135 14.3506 0.0815365 14.2215C0.0280735 14.0924 0.00055629 13.9541 0.00055629 13.8144C0.00055629 13.6747 0.0280735 13.5364 0.0815365 13.4073C0.135 13.2782 0.213362 13.1609 0.312149 13.0621L5.93702 7.4394L0.312149 1.81665C0.213202 1.71795 0.134698 1.6007 0.0811343 1.47162C0.0275706 1.34254 0 1.20415 0 1.0644C0 0.924643 0.0275706 0.786261 0.0811343 0.657177C0.134698 0.528094 0.213202 0.410846 0.312149 0.312149Z"
        fill="#373737"
        fill-opacity="0.82"
      />
    </svg>
  );
};

export default Cross;
