import { useEffect } from "react";
import { useQuery } from "react-query";
import axios from "axios";

export const fetchCategoryHook = (setCateGoryData) => {
  const fetchPosts = async () => {
    try {
      const res = await axios.get(
        "https://us-central1-solarhub-44450.cloudfunctions.net/api/categories/list-with-topic"
      );
      if (res) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { data, error, status } = useQuery("category", fetchPosts, {
    keepPreviousData: true,
    staleTime: 60000,
    // enabled: isLongEnough,
  });

  useEffect(() => {
    if (data === undefined) return;
    if (status === "success") {
      setCateGoryData(data);
    }
  }, [data, status]);
};
