import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Input, Tooltip, withStyles } from "@material-ui/core";
import { Typography } from "antd";
import SingleImg from "../../Assets/application_step_1/consumption.building.1.png";
import MultiImg from "../../Assets/application_step_1/consumption.building.2.png";
import blackInfo from "Assets/blackInfo.svg";
import Question from "../../pages/Application/partials/images/Question.svg";
import { colors } from "../../colors";
import { consumption_profiles } from "../../utils/consumption";
import setCommaDot, { parseLocaleNumber, commaDotHandler } from "helperMethod";
import { useSelector } from "react-redux";
import swapCommaDot from "helperMethod";
import { calculated_consumption } from "../../utils/consumption";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    maxHeight: 345,
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  main_container: {
    marginTop: "30px",
    height: "150px",
    width: "471px",
    display: "flex",
    "@media (max-width: 530px)": {
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      height: "223px",
      alignItems: "center",
    },
    flexDirection: "row",
    justifyContent: "space-between",
  },
  input_main: {
    height: "121px",
    "@media (max-width: 530px)": {
      marginBottom: "14px",
      marginTop: "19px",
    },
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "80px",
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 530px)": {
      width: "260px",
    },
    borderRadius: "20px",
  },
  inside_main: {
    width: "208px",
    height: "140px",
    border: `1px solid #2D4764`,
    borderRadius: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width: 530px)": {
      marginBottom: "10px",
    },
    "& img": {
      height: "64px",
      marginTop: "20px",
      width: "76px",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(253, 184, 0, 0.1)",
      width: "214px",
      height: "145px",
      tansition: "0.3s",
    },
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Button_color: {
    backgroundColor:
      " linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
  },
  tooltipDesign: {
    marginLeft: "20px",
    position: "absolute",
    right: "82px",
    bottom: "74px",
    "@media (max-width: 530px)": {
      display: "none",
    },
  },
}));
const Building = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const classes = useStyles();
  const [temp, setTemp] = useState(
    commaDotHandler(
      consumption_details?.price_per_kWH,
      `${isEnglish ? "en" : "de"}`
    )
  );
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );

  function addCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }
  function addCommasWithGerman(nStr) {
    nStr += "";
    var x = nStr.split(",");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  }
  const BlueOnGreenTooltip = withStyles({
    tooltip: {
      color: "#000",
      fontWeight: "600",
      fontSize: "14px",
      maxWidth: "250px",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
      backgroundColor: "#fff",
    },
  })(Tooltip);

  return (
    <div
      style={{
        marginTop: window.innerWidth > 500 ? "50px" : "20px",
        width: window.innerWidth < 500 ? "305px" : "100%",
        width: window.innerWidth < 500 ? "305px" : "100%",
      }}
    >
      <h4 className={classes.center}>
        {isEnglish
          ? "In what building type do you live?"
          : "In welchem ​​Gebäudetyp wohnen Sie?"}
      </h4>
      <div className={classes.main_container}>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              building_type: "single",
            });
            setTemp1(
              calculated_consumption({
                ...consumption_details,
                building_type: "single",
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details?.building_type === "single" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details?.building_type === "single" &&
              `2px solid #2D4764`,
            width: consumption_details?.building_type === "single" && "208px",
            height: consumption_details?.building_type === "single" && "140px",
          }}
        >
          <img src={SingleImg} alt="Home" />
          <h5 className={classes.center}>
            {isEnglish ? " Single-family-building" : "Einfamilienhaus"}
          </h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              building_type: "multi",
            });
            setTemp1(
              calculated_consumption({
                ...consumption_details,
                building_type: "multi",
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details?.building_type === "multi" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details?.building_type === "multi" &&
              `2px solid #2D4764`,
            width: consumption_details?.building_type === "multi" && "208px",
            height: consumption_details?.building_type === "multi" && "140px",
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>
            {isEnglish ? "Multi-family-building" : "Mehrfamilienhaus"}
          </h5>
        </div>
      </div>
      <div className={classes.input_main}>
        <BlueOnGreenTooltip
          placement="right-start"
          title={
            isEnglish
              ? "You can also find the current price per kWh and your previous electricity consumption on the bill from your energy provider"
              : "Den aktuellen Preis pro kWh sowie dein bisherigen Stromverbrauch findest du auch auf der Rechnung deines Energieversorgers"
          }
        >
          <img
            onMouseEnter={() => setWhichIconShouldBeVisible(true)}
            onMouseLeave={() => setWhichIconShouldBeVisible(false)}
            className={classes.tooltipDesign}
            src={whichIconShouldBeVisible ? blackInfo : Question}
            alt=""
          />
        </BlueOnGreenTooltip>
        <div className={classes.inside_inputMain} style={{ height: "100%" }}>
          <Typography>
            {isEnglish ? "Price per kWh €" : "Preis pro kWh €"}
          </Typography>
          <Input
            style={{ textAlign: "center", paddingLeft: "5px", width: "100px" }}
            placeholder={isEnglish ? "Price per kWh €" : "Preis pro kWh €"}
            value={temp}
            inputProps={{ style: { textAlign: "center" } }}
            onChange={(e) => {
              let value = e.target.value;
              if (isEnglish) {
                value = parseLocaleNumber(value, "en");
                setTemp(e.target.value);
              } else {
                value = parseLocaleNumber(value, "de");
                setTemp(e.target.value);
              }
              setConsumptionDetails({
                ...consumption_details,
                price_per_kWH: value,
              });
            }}
            onBlur={() => {
              if (isNaN(consumption_details.price_per_kWH)) {
                setTemp(0.3);
                setConsumptionDetails({
                  ...consumption_details,
                  price_per_kWH: 0.3,
                });
              }
            }}
          />
          <Typography>
            {isEnglish
              ? "Yearly consumption in kWh"
              : "Jährlicher Energieverbrauch in kWh"}
          </Typography>
          <Input
            placeholder={
              isEnglish
                ? "Yearly consumption in kWh"
                : "Jährlicher Energieverbrauch in kWh"
            }
            inputProps={{ style: { textAlign: "center" } }}
            style={{ textAlign: "center", paddingLeft: "5px", width: "100px" }}
            value={temp1}
            onChange={(e) => {
              let value = e.target.value;
              if (isEnglish) {
                value = parseLocaleNumber(value, "en");
                setTemp1(e.target.value);
              } else {
                value = parseLocaleNumber(value, "de");
                setTemp1(e.target.value);
              }
              setConsumption(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Building;

export const YearlyConsumptionInput = ({
  temp1,
  setTemp1,
  consumption,
  consumption_details,
  setConsumptionDetails,
  setConsumption,
}) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <div className={classes.input_main}>
      <div className={classes.inside_inputMain}>
        <Typography>
          {isEnglish
            ? "Yearly consumption in kWh"
            : "Jährlicher Energieverbrauch in kWh"}
        </Typography>
        <Input
          placeholder={
            isEnglish
              ? "Yearly consumption in kWh"
              : "Jährlicher Energieverbrauch in kWh"
          }
          inputProps={{ style: { textAlign: "center" } }}
          style={{ textAlign: "center", paddingLeft: "5px", width: "100px" }}
          value={temp1}
          onChange={(e) => {
            let value = e.target.value;
            if (isEnglish) {
              value = parseLocaleNumber(value, "en");
              setTemp1(e.target.value);
            } else {
              value = parseLocaleNumber(value, "de");
              setTemp1(e.target.value);
            }
            setConsumption(value);
          }}
        />
      </div>
    </div>
  );
};
