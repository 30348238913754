import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Close from "Assets/Close.svg";
import Group from "../popups/SignUpSignIn/images/Group.svg";
import "react-image-gallery/styles/css/image-gallery.css";
import { colors } from "../colors";
import SingleImg from "../Assets/application_step_1/consumption.building.1.png";
import MultiImg from "../Assets/application_step_1/consumption.building.2.png";
import { useSelector, useDispatch } from "react-redux";
import { calculated_consumption } from "utils/consumption";
import { Input } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "900px",
    padding: "25px 0px",
    overflow: "auto",
    overflowX: "hidden",
    margin: "0px",
    display: "flex",
    "@media (max-width: 560px)": {
      width: "100%",
    },
    flexDirection: "column",
    alignItems: "center",
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  main_container: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  inside_main: {
    minWidth: "250px",
    maxWidth: "250px",
    margin: "5px",
    height: "140px",
    border: `1px solid #2D4764`,
    borderRadius: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width: 530px)": {
      marginBottom: "10px",
    },
    "& img": {
      height: "64px",
      marginTop: "20px",
      width: "76px",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(253, 184, 0, 0.1)",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  headerText: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h1": {
      color: colors.darkBlue,
      "@media (max-width: 530px)": {
        fontSize: "20px",
      },
    },
    "& h3": {
      color: colors.darkBlue,
      fontSize: "18px",
      "@media (max-width: 530px)": {
        fontSize: "13px",
      },
      marginBottom: "15px",
    },
  },
  button: {
    background: colors.darkBlue,
    color: "#fff",
    "&:hover": {
      background: "linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
    },
    margin: "10px",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

// making a 4 functional components

//complating a four functional component

export default function SelectYearlyConsumption({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const consumption = Math.round(
    calculated_consumption(project?.consumption_details)
  );
  const consumption_details = project?.consumption_details;
  const setConsumption = (data) =>
    dispatch({ type: "SET_CONSUMPTION", payload: data });
  const setConsumptionDetails = (data) =>
    dispatch({ type: "SET_CONSUMPTION_DETAILS", payload: data });
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { consumption_profile } = consumption_details;

  const setConsumptionProfile = (i) => {
    setConsumptionDetails({
      ...consumption_details,
      consumption_profile: i,
      self_supply: consumption_profiles[i].self_supply,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.root}>
        <div className={classes.main_container}>
          <div className={classes.headerText}>
            <h1>
              {isEnglish
                ? "Which of the following building types matches you?"
                : "Welches der folgenden Verbrauchsprofile trifft am besten auf Sie zu?"}
            </h1>
          </div>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
            <div
              onClick={() => setConsumptionProfile(i)}
              className={classes.inside_main}
              style={{
                backgroundColor:
                  consumption_profile === i && "rgba(253, 184, 0, 0.1)",
                border: consumption_profile === i && `2px solid #2D4764`,
              }}
            >
              <img src={i % 2 === 0 ? MultiImg : SingleImg} id={1} alt="Home" />
              <h5 className={classes.center}>
                {isEnglish
                  ? consumption_profiles[i].name[0]
                  : consumption_profiles[i].name[1]}
              </h5>
            </div>
          ))}
        </div>
        <SelfSupplyInput
          consumption_details={consumption_details}
          setConsumptionDetails={setConsumptionDetails}
        />
        <div>
          <Button
            className={classes.button}
            style={{
              color: "white",
              background:
                "linear-gradient(226.42deg, #28334A 8.93%, #09152F 110.98%)",
              borderRadius: "10px",
            }}
            onClick={() => setOpen(false)}
          >
            {isEnglish ? "Take Over" : "Übernehmen"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const consumption_profiles = {
  1: {
    name: ["Home Office - all day high consumption", "ganztägiger Verbrauch"],
    self_supply: 0.6,
  },
  2: {
    name: ["consumption in the morning and evening", "eher morgens und abends"],
    self_supply: 0.3,
  },
  3: { name: ["only in the evening", "nur abends"], self_supply: 0.25 },
  4: { name: ["Multi family house", "Mehrfamilienhaus"], self_supply: 0.6 },
  5: {
    name: [
      "Family with children in schooling age",
      "Familie mit Kindern im schulpflichtigen Alter",
    ],
    self_supply: 0.5,
  },
  6: {
    name: [
      "Family with small children",
      "Familie mit Kleinkindern oder Kindern im Vorschulalter",
    ],
    self_supply: 0.55,
  },
  7: {
    name: [
      "Single or two person household",
      "ein- oder zwei-Personen-Haushalt",
    ],
    self_supply: 0.55,
  },
  8: {
    name: [
      "Home Office / Retired home",
      "Personen im Ruhestand oder aus dem Homeoffice arbeitende Personen",
    ],
    self_supply: 0.55,
  },
  9: {
    name: [
      "High energy consumption at night",
      "Verbrauch konzentriert sich auf die Nacht",
    ],
    self_supply: 0.55,
  },
};

export const SelfSupplyInput = ({
  consumption_details,
  setConsumptionDetails,
  center,
}) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  console.log(consumption_details, "consumption_details");
  return (
    <div
      style={{
        height: "80px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        fontSize: "35px",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          height: "151px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px",
          backgroundColor: " #EAEAEA",
          width: "289px",
          position: "relative",
          height: "108%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
        }}
      >
        <Typography style={{ color: "rgba(45, 71, 100, 1)" }}>
          {isEnglish ? "Self Supply" : "selbstversorgung"}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            height: "37px",
          }}
        >
          <Input
            placeholder={isEnglish ? "Self Supply" : "selbstversorgung"}
            inputProps={{ style: { textAlign: "center" } }}
            style={{
              textAlign: "center",
              paddingLeft: "5px",
              width: "100px",
              color: "rgba(45, 71, 100, 1)",
              fontSize: "18px",
            }}
            value={Math.round(consumption_details.self_supply * 100)}
            onChange={(e) => {
              console.log(e.target.value);
              if (parseInt(e.target.value) <= 100) {
                setConsumptionDetails({
                  ...consumption_details,
                  self_supply: e.target.value / 100,
                });
              } else if (e.target.value !== 100) {
                setConsumptionDetails({
                  ...consumption_details,
                  self_supply: 0 / 100,
                });
              } else {
                setConsumptionDetails({
                  ...consumption_details,
                  self_supply: 100 / 100,
                });
              }
            }}
          />{" "}
          <p
            style={{
              marginBottom: "0px",
              position: "absolute",
              right:
                window.innerWidth < 500 ? "109px" : center ? "116px" : "112px",
              bottom: center ? "18px" : "19px",
              marginTop: "4px",
              color: "rgba(45, 71, 100, 1)",
              fontSize: "18px",
            }}
          >
            %
          </p>
        </div>
      </div>
    </div>
  );
};
