import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import React from "react";
import { ProductPopupWrapper } from "../ProductPopupStyles";

const FirstHeader = ({ needToFilterList, isListOpen, setNeedToFilterList }) => {
  return (
    <ProductPopupWrapper>
      <div className="first_container">
        <div
          className="heading"
          style={{ paddingBottom: !isListOpen ? "13px" : "" }}
        >
          PV Module Wählen <span>Sie ihre Präferenzen</span>
        </div>
        {isListOpen ? (
          <FormGroup className="check_box_contaienr">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "High performance module >370 Wp",
                      ]);
                    } else {
                      if (
                        needToFilterList.includes(
                          "High performance module >370 Wp"
                        )
                      ) {
                        const result = needToFilterList.filter(
                          (e) => e !== "High performance module >370 Wp"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="High performance module >370 Wp"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needToFilterList.includes("Made in Germany")}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Made in Germany",
                      ]);
                    } else {
                      if (needToFilterList.includes("Made in Germany")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Made in Germany"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Made in Germany"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([
                        ...needToFilterList,
                        "Glas/ Glas (higher quality)",
                      ]);
                    } else {
                      if (
                        needToFilterList.includes("Glas/ Glas (higher quality)")
                      ) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Glas/ Glas (higher quality)"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Glas/ Glas (higher quality)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNeedToFilterList([...needToFilterList, "Full Black"]);
                    } else {
                      if (needToFilterList.includes("Full Black")) {
                        const result = needToFilterList.filter(
                          (e) => e !== "Full Black"
                        );
                        setNeedToFilterList(result);
                      }
                    }
                  }}
                />
              }
              className="changeText"
              label="Full Black"
            />
          </FormGroup>
        ) : (
          ""
        )}
      </div>
    </ProductPopupWrapper>
  );
};

export default FirstHeader;
