import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  CardElement,
  useStripe,
  useElements,
  IbanElement,
} from "@stripe/react-stripe-js";
import axios from "../../axios";
import {
  Container,
  Button,
  TextField,
  FormLabel,
  Typography,
  Paper,
  Select,
  MenuItem,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import IbanForm from "./IbanForm";
import { makeStyles } from "@material-ui/core/styles";
import ButtonDropdown from "../../Components/atoms/buttons/button.dropdown";
import finalLogo from "Assets/finalLogo.svg";
import finalText from "Assets/finalText.svg";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LoginImage from "../../Assets/heroImage.jpg";
import UpdatedCompanyLogo from "../../Assets/UpdatedCompanyLogo.svg";
import AppleIcon from "@material-ui/icons/Apple";
import { connect } from "react-redux";
import { colors } from "../../colors";
import { financingDetails } from "../../utils/costs";
import { useDispatch, useSelector } from "react-redux";
import { countryList } from "../../lib/countries_db";
import firebase, { db } from "../../firebase";
import DashboardHeader from "Components/dashboardHeader";
import { commaDotHandler } from "helperMethod";
import { useProjectTools } from "hooks/useProjectTools";
import { Switch } from "antd";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
  root1: {
    width: "90vw",
    maxWidth: "1200px",
    minHeight: "100vh",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  image: {
    backgroundImage: `url(${LoginImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper1: {
    width: "500px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "500px",
    minWidth: "450px",
    padding: "25px 0px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  loginBtn: {
    background:
      "linear-gradient(90.01deg, #FFC531 6.25%, #FFCA39 289.13%, #FFDD81 367.84%, rgba(255, 243, 202, 0) 478.53%)",
    borderRadius: "5px",
    marginTop: "10px",
    fontFamily: "Nunito",
    fontSize: "16px",
    textTransform: "none",
    fontWeight: "bold",
  },
  labels: {
    color: "#111111",
    fontSize: "16px",
    fontFamily: "Nunito",
    margin: "0px",
  },
  links: {
    textDecoration: "none",
    color: "#111111",
    fontSize: "15px",
    fontFamily: "Nunito",
  },
  heading: {
    fontFamily: "Nunito",
    fontSize: "29px",
    fontWeight: 600,
    color: "#111111",
  },
  radioLabel: {
    cursor: "default",
    "&.MuiFormControlLabel-root .MuiTypography-root": {
      color: "#111111",
      fontSize: "15px",
      fontFamily: "Nunito",
    },
  },
  text_field: { marginBottom: "20px", marginTop: "5px" },
}));
//import { db } from "./firebase";

function Payment({ project, quote, currentUser, dispatchMethods }) {
  const history = useHistory();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState("");
  const [error, setError] = useState(null);
  const [isPaymentWithBankAcc, setIsPaymentWithBankAcc] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientsecret] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("Germany");
  const [zipCode, setZipCode] = useState("");
  const { setPayment, setLoading } = dispatchMethods;
  const monthlyCost = Math.round(
    financingDetails(project).yearly_financing_amount / 12
  );
  const yearlyCost = Math.round(
    financingDetails(project).yearly_financing_amount
  );

  const { save } = useProjectTools();

  // calling uesEffect for getting client intent for our backend server
  useEffect(() => {
    if (isPaymentWithBankAcc) {
      const getClientSecret = async () => {
        const needThings = {
          name: currentUser?.displayName,
          address: {
            line1: project.address.complete,
            postal_code: project.address.postcode,
            city: project.address.city,
            state: project.address.state,
            country: project.address.country,
          },
        };
        const response = await axios({
          method: "POST",
          url: `https://us-central1-solarhub-44450.cloudfunctions.net/payments/create?total=${
            yearlyCost < 500 ? yearlyCost : 500
          }`,
          data: needThings,
          headers: { "Content-Type": "application/json" },
        });
        setClientsecret(response.data.clientSecret);
      };
      getClientSecret();
    } else {
      const getClientSecret1 = async () => {
        const needThings = {
          name: currentUser?.displayName,
          address: {
            line1: project.address.complete,
            postal_code: project.address.postcode,
            city: project.address.city,
            state: project.address.state,
            country: project.address.country,
          },
          id: currentUser?.uid,
          description: project.pid,
          email: currentUser?.email,
        };
        const response = await axios({
          method: "POST",
          url: `https://us-central1-solarhub-44450.cloudfunctions.net/payments/create-payment-intent?total=${
            yearlyCost < 500 ? yearlyCost : 500
          }`,
          data: needThings,
          headers: { "Content-Type": "application/json" },
        });
        setClientsecret(response.data.clientSecret);
      };
      getClientSecret1();
    }
  }, [isPaymentWithBankAcc]);

  // calling useEffect for getting user details form out database
  useEffect(() => {
    if (currentUser) {
      const docref = db.collection("users").doc(currentUser?.uid);
      docref.get().then((doc) => {
        if (doc.exists) {
          const { userDetails } = doc.data();
          setEmail(userDetails?.email);
          setName(userDetails?.fullName);
        } else {
          alert("Error Occured");
          setLoading(false);
        }
      });
    } else {
      history.push("/");
    }
  }, [currentUser]);

  //calling useEffect for checking is applepay,google pay or microsofe age pay is available or not
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        console.log("result", result);
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  //submit hangler for card payments
  const submitHandler = async (event) => {
    event.preventDefault();
    setProcessing(true);
    if (monthlyCost === 0) {
      alert("Monthly cost can not be zero");
      return;
    }

    const payload = await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      .then((res) => {
        const { paymentIntent } = res;
        alert("Payment done!" + JSON.stringify(res));

        setPayment({ status: "succeeded", paymentIntent: paymentIntent });
        save({
          ...project,
          payment: { status: "succeeded", paymentIntent: paymentIntent },
        });
        history.goBack();
      });
  };

  //submit hangler for sepa direact debit bank account
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const iban = elements.getElement(IbanElement);

    // For brevity, this example is using uncontrolled components for
    // the accountholder's name and email. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    const accountholderName = event.target["accountholder-name"];
    const email = event.target.email;

    const result = await stripe.confirmSepaDebitPayment(clientSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountholderName.value,
          email: email.value,
        },
      },
    });
    if (result.error) {
      // Show error to your customer.
      toast.error(result.error.message);
    } else {
      // Show a confirmation message to your customer.
      const { paymentIntent } = result;
      if (paymentIntent.status === "processing") {
        toast.success(
          "your payment is processing you check payment status after some time in you dashboard profile"
        );
        setPayment({ status: "processing", paymentIntent: paymentIntent });
        save({
          ...project,
          payment: { status: "processing", paymentIntent: paymentIntent },
        });
      }
      // The PaymentIntent is in the 'processing' state.
      // SEPA Direct Debit payments are asynchronous,
      // so funds are not immediately available.
    }
  };

  const handleChange = (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  function onChange(checked) {
    setIsPaymentWithBankAcc(!checked);
  }

  console.log(isPaymentWithBankAcc, "isPaymentWithBankAcc");
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <>
      <DashboardHeader />
      <div className={classes.root}>
        <Paper className={classes.root1}>
          <div className={classes.paper1}>
            <VendorCard
              monthlyCost={monthlyCost}
              isEnglish={isEnglish}
              yearlyCost={yearlyCost}
            />
          </div>
          <div elevation={"10"} className={classes.paper2}>
            <Container maxWidth="xs">
              <div style={{ marginTop: "30px" }}>
                {paymentRequest && (
                  <PaymentRequestButtonElement options={{ paymentRequest }} />
                )}
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    margin: "21px 0px 20px 0px",
                  }}
                >
                  {isPaymentWithBankAcc
                    ? "----- or Pay with BankAccount -----"
                    : "----- or Pay with Card -----"}
                  <Switch
                    style={{
                      background: isPaymentWithBankAcc ? "#FFD75D" : "#1B4963",
                      marginLeft: "20px",
                    }}
                    value={isPaymentWithBankAcc}
                    onChange={onChange}
                  />
                </Typography>
                {isPaymentWithBankAcc ? (
                  <form onSubmit={submitHandler}>
                    <FormLabel component="legend" className={classes.labels}>
                      Email
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      placeholder="Email"
                      autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={classes.text_field}
                    />
                    <FormLabel component="legend" className={classes.labels}>
                      Card Details
                    </FormLabel>
                    <div
                      style={{
                        height: "60px",
                        paddingTop: "20px",
                        paddingLeft: "10px",
                        border: "1px solid lightgray",
                        marginTop: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      <CardElement
                        style={{ height: "150px" }}
                        onChange={handleChange}
                      />
                    </div>
                    <FormLabel component="legend" className={classes.labels}>
                      Name on Card
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                      className={classes.text_field}
                    />
                    <FormLabel component="legend" className={classes.labels}>
                      Country or Region
                    </FormLabel>
                    <Select
                      style={{
                        height: "55px",
                        width: "100%",
                        border: "1px solid lightgray",
                        marginTop: "5px",
                        paddingLeft: "15px",
                      }}
                      margin="dense"
                      displayEmpty={true}
                      renderValue={() => {
                        return country;
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      autoWidth
                    >
                      {countryList.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      placeholder="Zip Code"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      autoFocus
                      style={{ marginTop: "5px" }}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.loginBtn}
                      onClick={submitHandler}
                      disabled={processing || disabled || succeeded}
                    >
                      <span>{processing ? <p> Processing</p> : "Pay Now"}</span>
                    </Button>

                    {error && <div>{error}</div>}
                  </form>
                ) : (
                  <IbanForm
                    onSubmit={handleSubmit}
                    disabled={!stripe}
                    monthlyCost={monthlyCost}
                  />
                )}
              </div>
            </Container>
          </div>
        </Paper>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isEnglish: state.global.isEnglish,
  isLoading: state.global.isLoading,
  project: state.project,
  quote: state.quote,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchMethods: {
    setPayment: (vendor) => dispatch({ type: "SET_PAYMENT", payload: vendor }),
    setLoading: (data) => dispatch({ type: "SET_LOADING", payload: data }),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

const VendorCard = ({ monthlyCost, isEnglish, yearlyCost }) => {
  const dispatch = useDispatch();
  const imgUrl = useSelector((state) => state.quote.quote.img_url[0]);
  const useStyles = makeStyles((theme) => ({
    root: {
      borderTop: `10px solid ${colors.ctaSolid}`,
      width: "250px",
      margin: "15px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logoImage: {
      height: "50px",
      width: "150px",
      objectFit: "cover",
      margin: "25px",
    },
    divider: { width: "90%", color: colors.orangeSolid, margin: "5px" },
    price: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      height: "90px",
      // margin: "150px",
      color: colors.darkBlue,
      borderRadius: "5px",
    },
    button1: {
      backgroundColor: colors.darkBlue,
      color: "#fff",
      borderRadius: "3px",
      width: "50%",
      margin: "5px",
    },
    button2: {
      backgroundColor: "#fff",
      color: colors.darkBlue,
      borderRadius: "3px",
      width: "50%",
      border: `1px solid ${colors.darkBlue}`,
      margin: "5px",
    },
    ProjectImage: {
      height: "320px",
      borderRadius: "5px",
      width: "387px",
    },
    paymentLeftSide: {
      height: "700px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "flex-start",
    },
    flexThing: {
      display: "flex",
      width: "150px",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "20px",
    },
    mainclass: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
    },
    mainclass_h3: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "28px",
    },
    mainclass_h3_last: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "22px",
      color: "#666666",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.paymentLeftSide}>
      <div
        style={{
          display: "flex",
          width: window.innerWidth < 500 ? "" : "303px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {window.innerWidth < 500 ? (
          <img
            className={classes.logoResponsive}
            src={finalLogo}
            // onClick={() => setActiveStep(0)}
            alt="img"
          />
        ) : (
          <>
            <img
              className={classes.logoResponsive}
              src={finalLogo}
              // onClick={() => setActiveStep(0)}
              alt="img"
            />
            <img
              src={finalText}
              className={classes.logoResponsive}
              style={{ marginLeft: "15px" }}
              alt=""
            />
          </>
        )}
      </div>
      <div className={classes.price}>
        <h3 className={classes.mainclass}>Sichern Sie sich jetzt Ihr System</h3>
        <h3>
          <strong className={classes.mainclass_h3}>
            {yearlyCost < 500
              ? `€${commaDotHandler(yearlyCost, `${isEnglish ? "en" : "de"}`)}`
              : "€500"}
          </strong>
        </h3>
        <h3 className={classes.mainclass_h3_last}>Einmalige Anzalung</h3>
      </div>
      <img className={classes.ProjectImage} src={imgUrl} alt="" />
    </div>
  );
};
