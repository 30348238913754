import React, { useEffect, useState } from "react";
import WecomeUserAddress from "popups/welcomeUserNoAddress/WecomeUserNoAddress";
import { Button, Divider, Icon, makeStyles, Paper } from "@material-ui/core";
import ListingCard from "./Listingcard";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    height: "100%",
  },
  imageHadler: {
    display: "flex",
    width: "130px",
    height: "44px",
    backgroundColor: "#F3F3F3",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: "10px",
    "& img": {
      width: "24.05px",
      height: "21px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  divHandler: {
    display: "flex",
    width: "100%",
    height: "100px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  divider_next_step: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: "120px",
  },
  inside_divider_helper: {
    display: "flex",
    flexDirection: "column",
  },
  button_helper: {
    backgroundColor: "#FFD75D",
  },
  project_listing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  paper_div1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
    "& img": {
      width: "212px",
      height: "100px",
    },
  },
  paper_div2: {
    display: "flex",
    flexDirection: "column",
  },
  paper_div3: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  tag: {
    backgroundColor: "#5F5F5F",
    width: "94.65px",
    height: "23px",
    borderRadius: "5px",
    textAlign: "center",
    color: "white",
  },
  inside_div2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    "& h2": {
      fontSize: "10px",
    },
  },
  inside_div2_last: {
    display: "flex",
    justifyContent: "space-around",
  },
  button_helper2: {
    backgroundColor: "#FFD75D",
    width: "150px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
  button_helper3: {
    backgroundColor: "#3E3637",
    width: "150px",
    borderRadius: "10px",
    color: "white",
    fontSize: "13px",
  },
  mainFilter: {
    height: "90vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));
const InsideListing = ({
  savedProjects,
  setMakeUseEffectRun,
  makeUseEffectRun,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.root}>
      <div className={classes.divHandler}>
        <Button
          style={{
            backgroundColor: "rgb(255, 215, 93)",
            fontWeight: "bold",
            borderRadius: "10px",
            width: "177px",
            marginBottom: "3px",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          Add New Project
        </Button>
      </div>
      <Divider variant="middle" />
      <div className={classes.mainFilter}>
        {savedProjects && savedProjects.length > 0
          ? savedProjects.map((datass, index) => {
              return (
                <ListingCard
                  projectData={datass}
                  makeUseEffectRun={makeUseEffectRun}
                  setMakeUseEffectRun={setMakeUseEffectRun}
                />
              );
            })
          : "YOU DON'T HAVE ANY SAVED PROJECTS"}
      </div>
      <WecomeUserAddress open={open} isBlank={true} setOpen={setOpen} />
    </div>
  );
};

export default InsideListing;
