import React, { useEffect, useState } from "react";
import { Button } from "antd";
import styled from "styled-components";
import UserList from "../../UserList";
import ShowImageInUpload from "../ShowImgeInUpload";
import imageUpload from "Assets/community/imageUpload.svg";
import myImage from "Assets/community/myImage.svg";
import crosss from "Assets/community/crosss.svg";
import ladki from "Assets/community/ladki.svg";
import { useSelector } from "react-redux";
import { getError } from "helperMethod";
import { toast } from "react-toastify";
import axios from "axios";

const ReplyInputWrapper = styled("div")`
  .fixed_wrapper {
    position: absolute;
    bottom: 0px;
    right: 111px;
  }
  .wrapper {
    position: absolute;
    bottom: -11px;
    right: 40px;
  }
  .make_it {
    position: fixed;
    height: 29px;
    cursor: pointer;
  }
  .fixed_wrapper2 {
    position: absolute;
    bottom: 0px;
    left: -5px;
  }
  .myImage {
    width: 92px;
    height: 36px;
    background: #fcefc9;
    border-radius: 16px;
    border: none;
    font-family: Nunito;
    font-style: normal;
    position: fixed;
    gap: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #95740f;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input_main {
    background: #f4f4f4;
    border-radius: 10px;
    /* position: relative; */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 623px;
    min-height: 52px;
  }
  .img {
    height: 36px;
    width: 36px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 60%;
  }
  .replyButton {
    width: 80px;
    height: 27px;
    background: #ffd75d;
    border-radius: 16px;
    height: 27px;
    margin-right: 10px;
    background: #ffd75d;
    border-radius: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background: #ffd75d;
    }
  }
  .ant-btn:hover {
    color: black;
    /* padding: 0px !important; */
    border: 1px solid transparent;
  }
  .ant-btn:focus {
    color: none;
    border: none;
  }
  .howare {
    margin-right: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .emoji {
    height: 22px;
    width: 22px;
  }
  .lightbox-wrapper img {
    width: 500px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  .meta {
    display: none;
  }
  .replymain::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .replymain::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .replymain::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .replymain::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .image-uplod {
    height: 28px;
    width: 43px;
    margin-right: 10px;
    cursor: pointer;
  }
  .to_copy {
    display: flex;
    flex-direction: column;
  }
  .dropdown {
    position: absolute;
    max-height: 280px;
    top: 0px;
    width: 300px !important;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    background-color: white;
    transform: translate(10px, -280px);
    z-index: 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  .dropdown-body {
    position: absolute;
    max-height: 241px;
    width: 300px !important;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    background-color: white;
    /* transform: translate(10px, -170px); */
    bottom: 53px;
    z-index: 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
    width: 100%;
    background-color: #f4f4f4;
    border-top: 1px solid #e5e8ec;
    display: none;
    overflow: auto;
    overflow-x: hidden;
  }
  .dropdown-body.open {
    display: flex;
    flex-direction: column;
  }
  .dropdown-item {
    width: 100%;
    border-bottom: 1px solid #f9f9f9;
  }

  .dropdown-item:hover {
    cursor: pointer;
    background: #f9f9f9;
  }
  .wrapper_input_main {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 600px) {
    .myImage {
      bottom: 24px;
    }
    .fixed_wrapper2 {
      bottom: 54px;
      width: 100%;
      left: 6px;
    }
    .input_main {
      align-items: center;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      width: 97% !important;
    }
    .wrapper {
      position: absolute;
      bottom: 40px;
      right: 33px;
    }
    .make_it {
      position: fixed;
      height: 26px;
      cursor: pointer;
    }
    .image-uplod {
      width: 24px;
    }
    .secound_for_mobile {
      bottom: 176px;
      width: 100%;
      left: 6px;
    }
  }
`;
const TopicDes = styled("textarea")`
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  height: 52px;
  background: #f4f4f4;
  border-radius: 10px;
  padding: 0px;
  padding-top: 12px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #2d4764;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  @media (max-width: 600px) {
    width: 100% !important;
  }
`;

const ReplyInput = ({
  userDeatils,
  isReplyTrue,
  divRref,
  isEditTrue,
  setOpen1,
  setCount,
  setPostText,
  postText,
  detect,
  handleItemClick,
  image_element,
  UploadImage,
  image,
  count,
  setimage,
  setDetect,
  preview,
  imgUrl,
  isloadingCreatePost,
  isOpen,
  updatePost,
  createPost,
  showReplyBox,
  setShowReplyBox,
  setIsEditTrue,
  setIsReplyTrue,
  setImgUrl,
  setOrignalImgUrl,
}) => {
  const isEnglish = useSelector((state) => state?.global?.isEnglish);
  const [userList, setUserList] = useState([]);
  const [filterdUserList, setFilterdUserList] = useState([]);
  useEffect(() => {
    var config = {
      method: "get",
      url: "https://us-central1-solarhub-44450.cloudfunctions.net/api/users/list",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setUserList(response.data);
        setFilterdUserList(response.data);
      })
      .catch(function (err) {
        if (err?.response?.data?.errors?.length) {
          toast.error(getError(err?.response?.data?.errors));
        }
      });
  }, []);

  const filterUserList = (value) => {
    console.log(value, "value");
    if (value === "") {
      setFilterdUserList(userList);
    } else {
      const result = userList.filter(
        (data) =>
          `@${data.username.toLowerCase()}`.includes(
            `@${value.split("@")[value.split("@").length - 1]}`.toLowerCase()
          ) ||
          `@${data?.name?.toLowerCase()}`.includes(
            `@${value.split("@")[value.split("@").length - 1]}`.toLowerCase()
          )
      );
      setFilterdUserList(result);
    }
  };

  return (
    <ReplyInputWrapper>
      {showReplyBox ? (
        <div
          className={`fixed_wrapper2 ${
            (image || imgUrl !== null) && window.innerWidth < 500
              ? "secound_for_mobile"
              : ""
          }`}
        >
          <div
            className="input_main"
            style={{
              border: isReplyTrue ? "1px solid black" : "",
              alignItems: image ? "flex-start" : "center",
              paddingTop: image ? "10px" : "0px",
              paddingLeft: image ? "8px" : "0px",
              paddingRight: image ? "8px" : "0px",
            }}
            ref={divRref}
          >
            <img
              src={
                userDeatils?.avatar_template
                  ? `http://community.solarhub24.de${userDeatils?.avatar_template.replace(
                      "{size}",
                      "50"
                    )}`
                  : ladki
              }
              alt=""
              className="img"
            />
            <div className="to_copy">
              <TopicDes
                style={{
                  width: isEnglish ? "443px" : isEditTrue ? "413px" : "443px",
                }}
                onChange={(e) => {
                  let data = e.target.value;
                  let c = 0;
                  if (isReplyTrue) {
                    data = data.substring(1);
                  }
                  for (let i = 0; i < data.length; i++) {
                    if (detect) {
                      data = data.replace("@", "");
                      console.log(data, "e.target");
                    }
                    if (data[i] === "@") {
                      c++;
                    }
                  }
                  console.log(c, "c");
                  // if (detect) {
                  //   if (c > count) setOpen1(false);
                  // } else {
                  // }
                  setDetect(false);
                  if (c > count) {
                    setOpen1(true);
                    filterUserList(e.target.value);
                  }
                  if (c < count) setCount(c);
                  setPostText(e.target.value);
                }}
                // disabled={isOpen}
                value={postText}
              />
              {isOpen && (
                <div className={`dropdown-body ${isOpen && "open"}`}>
                  {filterdUserList.length &&
                    filterdUserList.map((item) => (
                      <UserList item={item} handleItemClick={handleItemClick} />
                    ))}
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                ref={image_element}
                onChange={UploadImage}
                disabled={image ? true : false}
                hidden
              />
              {image || imgUrl !== null ? (
                <ShowImageInUpload
                  image={image}
                  setimage={setimage}
                  preview={preview}
                  imgUrl={imgUrl}
                  setImgUrl={setImgUrl}
                  setOrignalImgUrl={setOrignalImgUrl}
                />
              ) : null}
            </div>
            {image || imgUrl !== null ? null : (
              <img
                src={imageUpload}
                onClick={() => {
                  image_element.current.click();
                }}
                className="image-uplod"
                alt=""
              />
            )}
            {isEditTrue ? (
              <Button
                className="replyButton"
                style={{ width: isEnglish ? "80px" : "106px" }}
                loading={isloadingCreatePost}
                onClick={() => updatePost()}
              >
                {isEnglish ? "update" : "aktualisieren"}
              </Button>
            ) : (
              <Button
                className="replyButton"
                loading={isloadingCreatePost}
                onClick={() => createPost()}
              >
                {isEnglish ? "reply" : "Antwort"}
              </Button>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {showReplyBox ? (
        <div className="wrapper">
          <img
            className="make_it"
            src={crosss}
            alt=""
            onClick={() => {
              setShowReplyBox(false);
              setIsEditTrue(false);
              setIsReplyTrue(false);
              setDetect(false);
              setImgUrl(null);
              setOrignalImgUrl(null);
            }}
          />
        </div>
      ) : (
        ""
      )}
      {!showReplyBox ? (
        <div className="fixed_wrapper">
          <button
            className="myImage"
            onClick={() => {
              setShowReplyBox(true);
            }}
          >
            <img src={myImage} alt="" />
            <span>{isEnglish ? "Reply" : "Antwort"}</span>
          </button>
        </div>
      ) : (
        ""
      )}
    </ReplyInputWrapper>
  );
};

export default ReplyInput;
