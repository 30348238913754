export const default_quotes = [
  {
    sl_no: "1.0",
    qrn: "60ffcd968bf23f04c02c2403",
    crn: "QUO-FRG-VWL",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Burger Str. 4",
    addr1: "Burger Str. 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9794522",
    long: "7.9737842",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "21.125",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16538.0",
    production_ratio: "783.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "65.0",
    new_annual_electric_bill: "380.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "26624.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00353_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "2.0",
    qrn: "61006c718bf23f04c02c27ee",
    crn: "52S-GP9-X95",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 1",
    addr1: "Am Schloßpark 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9814838",
    long: "7.9591355",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15124.0",
    production_ratio: "950.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c26",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 4.2",
    total_inverter_capacity_2: "4.2",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "4.2",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "235.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00031_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "3.0",
    qrn: "610025ad8a3044201b3061de",
    crn: "RI7-GRM-XM0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 12",
    addr1: "Am Schloßpark 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98209",
    long: "7.95873",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "12.675",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11782.0",
    production_ratio: "930.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "39.0",
    new_annual_electric_bill: "309.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15977.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00040_4_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "4.0",
    qrn: "610066a68a3044201b3062ec",
    crn: "P1N-41Q-6MR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 13",
    addr1: "Am Schloßpark 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98209",
    long: "7.95885",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14810.0",
    production_ratio: "950.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 4.2",
    total_inverter_capacity_2: "4.2",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "4.2",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "238.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00039_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "5.0",
    qrn: "610068f68bf23f04c02c27e3",
    crn: "Q1I-HTY-3ED",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 14",
    addr1: "Am Schloßpark 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98172",
    long: "7.95889",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "13.325",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12641.0",
    production_ratio: "949.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "41.0",
    new_annual_electric_bill: "275.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "16783.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00035_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "6.0",
    qrn: "610067ae8bf23f04c02c27dc",
    crn: "HR7-DI6-TAC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 14A",
    addr1: "Am Schloßpark 14A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98182",
    long: "7.95874",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10806.0",
    production_ratio: "950.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "321.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00037_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "7.0",
    qrn: "61006e848bf23f04c02c27f1",
    crn: "HJ1-5JO-OL5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 16",
    addr1: "Am Schloßpark 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9816304",
    long: "7.9583333",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "21.775",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21190.0",
    production_ratio: "974.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "67.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "27430.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00028_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "8.0",
    qrn: "610067408a3044201b3062ef",
    crn: "X51-FNO-EEN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 17",
    addr1: "Am Schloßpark 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9818114",
    long: "7.958421",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11120.0",
    production_ratio: "951.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "313.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00038_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "9.0",
    qrn: "60ffe9a88bf23f04c02c24f7",
    crn: "Y1E-SQW-DTS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 19",
    addr1: "Am Schloßpark 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9823439",
    long: "7.9585441",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "17.225",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16265.0",
    production_ratio: "945.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "53.0",
    new_annual_electric_bill: "224.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "21710.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00379_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "10.0",
    qrn: "61006986e1e1df7221e1b5a0",
    crn: "A7X-N8N-RHG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 2",
    addr1: "Am Schloßpark 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98165",
    long: "7.95903",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "13.65",
    proposed_system_size_ac: "13.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12684.0",
    production_ratio: "930.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "42.0",
    new_annual_electric_bill: "288.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "17199.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00034_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "11.0",
    qrn: "60ffe9408a3044201b306021",
    crn: "JIB-87G-1WH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 20",
    addr1: "Am Schloßpark 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9825752",
    long: "7.9586606",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "49.075",
    proposed_system_size_ac: "46.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "45367.0",
    production_ratio: "925.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "11.0",
    total_inverter_capacity_1: "46.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "151.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "61841.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00383_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "12.0",
    qrn: "60ffe7638a3044201b306008",
    crn: "8UL-IFL-FHN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 21",
    addr1: "Am Schloßpark 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9828225",
    long: "7.9587769",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14127.0",
    production_ratio: "925.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "421.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00392_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "13.0",
    qrn: "60ffe5358bf23f04c02c24bd",
    crn: "JPY-NDR-DLB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 23",
    addr1: "Am Schloßpark 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9830467",
    long: "7.9590454",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "27.3",
    proposed_system_size_ac: "25.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "25246.0",
    production_ratio: "925.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "25.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "84.0",
    new_annual_electric_bill: "226.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "34398.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00406_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "14.0",
    qrn: "60ffe4a88a3044201b305fe3",
    crn: "KYV-L5L-E9U",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 26",
    addr1: "Am Schloßpark 26",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9818614",
    long: "7.9581108",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10383.0",
    production_ratio: "940.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "563.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00407_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "15.0",
    qrn: "60ffe2b7e1e1df7221e1b27d",
    crn: "A0V-9JU-PQ8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 27",
    addr1: "Am Schloßpark 27",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9822131",
    long: "7.9581164",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.325",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24686.0",
    production_ratio: "938.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "81.0",
    new_annual_electric_bill: "232.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "33163.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00410_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "16.0",
    qrn: "60ffe21f8bf23f04c02c24a2",
    crn: "6LR-WFL-UXT",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 28",
    addr1: "Am Schloßpark 28",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9825849",
    long: "7.9582917",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "51.35",
    proposed_system_size_ac: "44.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "40534.0",
    production_ratio: "790.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c20",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "8.0",
    total_inverter_capacity_1: "44.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "158.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "64701.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00411_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "17.0",
    qrn: "60ffdea88bf23f04c02c2488",
    crn: "7DD-NAV-9RE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 29",
    addr1: "Am Schloßpark 29",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9828762",
    long: "7.9584681",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "25.35",
    proposed_system_size_ac: "25.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "23691.0",
    production_ratio: "935.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "25.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "78.0",
    new_annual_electric_bill: "240.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "31941.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00430_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "18.0",
    qrn: "60ffd20e8a3044201b305f36",
    crn: "S5O-6FJ-DGM",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 30",
    addr1: "Am Schloßpark 30",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9832381",
    long: "7.9588328",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "22.75",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21310.0",
    production_ratio: "937.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "70.0",
    new_annual_electric_bill: "260.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "28665.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00469_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "Flat Roof",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "19.0",
    qrn: "60fff5628a3044201b30608e",
    crn: "XAF-9W5-8SH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 4",
    addr1: "Am Schloßpark 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98202",
    long: "7.95917",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14815.0",
    production_ratio: "950.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "238.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "23400.0",
    unit_system_cost: "1.5",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00041_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "20.0",
    qrn: "60ffee1e8a3044201b30604b",
    crn: "3FO-VUM-01B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 5",
    addr1: "Am Schloßpark 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98212",
    long: "7.95919",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "17.875",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16871.0",
    production_ratio: "944.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "55.0",
    new_annual_electric_bill: "227.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "22529.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00368_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "21.0",
    qrn: "60ffed548bf23f04c02c251c",
    crn: "SKM-EHE-V6A",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 6",
    addr1: "Am Schloßpark 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98237",
    long: "7.95923",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "21.125",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19941.0",
    production_ratio: "944.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "65.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "26624.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00369_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "22.0",
    qrn: "60ffe5b78bf23f04c02c24c5",
    crn: "6GI-380-1AK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 8",
    addr1: "Am Schloßpark 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98282",
    long: "7.95931",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11943.0",
    production_ratio: "968.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "440.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00399_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "23.0",
    qrn: "60ffe6688bf23f04c02c24d1",
    crn: "WYG-5MV-5G7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Am Schloßpark 9",
    addr1: "Am Schloßpark 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9828",
    long: "7.95915",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.25",
    proposed_system_size_ac: "14.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15045.0",
    production_ratio: "926.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 4.2",
    total_inverter_capacity_2: "4.2",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "4.2",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "50.0",
    new_annual_electric_bill: "398.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20475.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00397_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "24.0",
    qrn: "610000b48a3044201b3060df",
    crn: "7NA-EJU-4FY",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 10",
    addr1: "Andreasstraße 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9829708",
    long: "7.9653079",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6120.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "520.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00284_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "25.0",
    qrn: "61002e4a8bf23f04c02c26d2",
    crn: "3V0-CAO-6PW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 11",
    addr1: "Andreasstraße 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9826811",
    long: "7.9652238",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.8",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6454.0",
    production_ratio: "828.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "24.0",
    new_annual_electric_bill: "790.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9828.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00262_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "26.0",
    qrn: "61000046e1e1df7221e1b390",
    crn: "65X-LQ9-0G9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 12",
    addr1: "Andreasstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98303",
    long: "7.96528",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "6.5",
    proposed_system_size_ac: "6.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5310.0",
    production_ratio: "817.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "6.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "20.0",
    new_annual_electric_bill: "562.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "8190.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00285_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "27.0",
    qrn: "61002c328bf23f04c02c26c4",
    crn: "EJ2-2G9-65K",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 13",
    addr1: "Andreasstraße 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9827111",
    long: "7.9655063",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11432.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "518.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00266_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "28.0",
    qrn: "60ffffe58bf23f04c02c25a6",
    crn: "TPA-2VX-M2R",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 14",
    addr1: "Andreasstraße 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98309",
    long: "7.96527",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7889.0",
    production_ratio: "838.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "433.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00286_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "29.0",
    qrn: "61002b758a3044201b3061fc",
    crn: "Y0R-4XQ-7QF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 15",
    addr1: "Andreasstraße 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9827124",
    long: "7.9655972",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9824.0",
    production_ratio: "864.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "589.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00267_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "30.0",
    qrn: "6100245ce1e1df7221e1b47e",
    crn: "F7O-B8O-2X1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 16",
    addr1: "Andreasstraße 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9829722",
    long: "7.9657288",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8003.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "429.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00279_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "31.0",
    qrn: "61002b23e1e1df7221e1b4ad",
    crn: "E8G-MOM-42V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 17",
    addr1: "Andreasstraße 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9827323",
    long: "7.9658501",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6366.0",
    production_ratio: "852.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "794.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00268_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "32.0",
    qrn: "610023fc8a3044201b3061d8",
    crn: "KTP-JRW-S11",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 18",
    addr1: "Andreasstraße 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98304",
    long: "7.96576",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "7.8",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6409.0",
    production_ratio: "822.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "24.0",
    new_annual_electric_bill: "504.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9828.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00280_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "33.0",
    qrn: "61002ac08a3044201b3061f9",
    crn: "76Y-SN6-F7F",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 19",
    addr1: "Andreasstraße 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9827325",
    long: "7.9659714",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8558.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "391.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00269_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "34.0",
    qrn: "60fffed1e1e1df7221e1b38a",
    crn: "RBG-67T-KRG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 2",
    addr1: "Andreasstraße 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9829771",
    long: "7.9642999",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "20.15",
    proposed_system_size_ac: "18.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17154.0",
    production_ratio: "852.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "62.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "25389.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00288_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "35.0",
    qrn: "610002bee1e1df7221e1b39e",
    crn: "1F1-6BW-PNW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 20",
    addr1: "Andreasstraße 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9831",
    long: "7.96573",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7721.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "438.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00281_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "36.0",
    qrn: "610040d08a3044201b30622d",
    crn: "0QS-GJ6-QJC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 21",
    addr1: "Andreasstraße 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9825204",
    long: "7.9664246",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "29.25",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22610.0",
    production_ratio: "773.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "90.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "36855.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00234_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "37.0",
    qrn: "6100017ce1e1df7221e1b396",
    crn: "IMO-U6W-O50",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 22",
    addr1: "Andreasstraße 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98316",
    long: "7.96573",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "7.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6651.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "493.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00282_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "38.0",
    qrn: "61002a5c8bf23f04c02c26bc",
    crn: "NSC-N2Y-BPD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 23",
    addr1: "Andreasstraße 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9827427",
    long: "7.9665014",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "40.95",
    proposed_system_size_ac: "37.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "32732.0",
    production_ratio: "800.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "126.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "51597.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00270_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "39.0",
    qrn: "610027d8e1e1df7221e1b48f",
    crn: "LFP-W5E-M5A",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 24",
    addr1: "Andreasstraße 24",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9829805",
    long: "7.9661614",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.325",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10922.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "41.0",
    new_annual_electric_bill: "558.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "16783.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00274_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "40.0",
    qrn: "610029e08bf23f04c02c26b7",
    crn: "50Y-QAM-BXD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 25",
    addr1: "Andreasstraße 25",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9830892",
    long: "7.9664546",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "36.075",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "29795.0",
    production_ratio: "826.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "111.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "45461.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00271_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "41.0",
    qrn: "61002768e1e1df7221e1b48c",
    crn: "9SH-GOC-L2P",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 26",
    addr1: "Andreasstraße 26",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9830612",
    long: "7.9661649",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.2",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4345.0",
    production_ratio: "836.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "16.0",
    new_annual_electric_bill: "1048.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "57.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "6552.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00275_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "42.0",
    qrn: "610028a4e1e1df7221e1b494",
    crn: "MUO-Q4Q-LF8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 27",
    addr1: "Andreasstraße 27",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9833384",
    long: "7.9665109",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "18.525",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12508.0",
    production_ratio: "676.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "57.0",
    new_annual_electric_bill: "574.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "23335.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00272_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "43.0",
    qrn: "610027128bf23f04c02c26aa",
    crn: "H0N-6DV-JOK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 28",
    addr1: "Andreasstraße 28",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9831283",
    long: "7.9661352",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.175",
    proposed_system_size_ac: "6.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5161.0",
    production_ratio: "836.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "6.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "19.0",
    new_annual_electric_bill: "932.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "62.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "7774.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00276_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "44.0",
    qrn: "6100412ce1e1df7221e1b4e0",
    crn: "4Q5-DEO-FMU",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 3",
    addr1: "Andreasstraße 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9826993",
    long: "7.9643015",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "25.35",
    proposed_system_size_ac: "23.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21693.0",
    production_ratio: "856.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "3.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "Piko 10",
    total_inverter_capacity_2: "10.0",
    inverter_quantity_2: "2.0",
    total_inverter_capacity_22: "20.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "78.0",
    new_annual_electric_bill: "246.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "31941.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00230_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "45.0",
    qrn: "610026b58bf23f04c02c26a6",
    crn: "SYI-J9I-VIG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 30",
    addr1: "Andreasstraße 30",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9831857",
    long: "7.9661352",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.85",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4791.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "18.0",
    new_annual_electric_bill: "984.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "59.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "7371.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00277_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "46.0",
    qrn: "610026318bf23f04c02c26a1",
    crn: "TDN-06N-PO2",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 32",
    addr1: "Andreasstraße 32",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9832338",
    long: "7.9661353",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "4.55",
    proposed_system_size_ac: "4.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3723.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "14.0",
    new_annual_electric_bill: "679.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "61.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "5733.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00278_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "47.0",
    qrn: "60fffe4d8a3044201b3060d4",
    crn: "G1I-2PK-S8B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 4",
    addr1: "Andreasstraße 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.983",
    long: "7.9644191",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "16.25",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13028.0",
    production_ratio: "802.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 7.0",
    total_inverter_capacity_2: "7.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "7.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "50.0",
    new_annual_electric_bill: "279.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20475.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00289_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "48.0",
    qrn: "60ffff5d8a3044201b3060d9",
    crn: "0CY-UJ5-087",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 6",
    addr1: "Andreasstraße 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98299",
    long: "7.96481",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "20.8",
    proposed_system_size_ac: "18.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17210.0",
    production_ratio: "828.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "64.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "26208.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00287_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "49.0",
    qrn: "61002eaae1e1df7221e1b4bb",
    crn: "403-URI-QE4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 7",
    addr1: "Andreasstraße 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9826789",
    long: "7.9651124",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6965.0",
    production_ratio: "825.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "755.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00261_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "50.0",
    qrn: "6100010c8a3044201b3060e1",
    crn: "58V-BTM-JTA",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Andreasstraße 8",
    addr1: "Andreasstraße 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9829319",
    long: "7.9652638",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "5.2",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4336.0",
    production_ratio: "834.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "16.0",
    new_annual_electric_bill: "620.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "64.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "6552.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00283_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "51.0",
    qrn: "6100610a8a3044201b3062d0",
    crn: "UV7-GR1-0V6",
    utility_name: "Spalte2",
    rate_name: "Commercial",
    customer_name: "Autohaus Rombach GmbH",
    addr1: "Reckenweg 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.985776",
    long: "7.9721424",
    annul_electric_bill: "1398.24",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.310733333",
    bill_type: "annual",
    proposed_system_size_dc: "13.65",
    proposed_system_size_ac: "13.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12687.0",
    production_ratio: "930.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "42.0",
    new_annual_electric_bill: "268.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "C",
    total_system_cost_: "14333.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Commercial",
    combined_img: "SOLARHUB00079_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "Autohaus Rombach GmbH",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "52.0",
    qrn: "60ffd1a8e1e1df7221e1b1e8",
    crn: "8SO-LAT-N1X",
    utility_name: "Prio Energie",
    rate_name: "Other",
    customer_name: "Birkenweg 1",
    addr1: "Birkenweg 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97908",
    long: "7.97192",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "12631.57895",
    unit_electricity_bill_rate: "0.19",
    bill_type: "annual",
    proposed_system_size_dc: "17.55",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14279.0",
    production_ratio: "814.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "54.0",
    new_annual_electric_bill: "0.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "100.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "C",
    total_system_cost_: "18428.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Commercial",
    combined_img: "SOLARHUB00464_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "Birkenweg 1",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "53.0",
    qrn: "60ffceac8a3044201b305f29",
    crn: "FXI-GBY-F9E",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 10",
    addr1: "Birkenweg 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9784994",
    long: "7.9727691",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "23.075",
    proposed_system_size_ac: "23.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20447.0",
    production_ratio: "887.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "71.0",
    new_annual_electric_bill: "248.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29068.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00360_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "54.0",
    qrn: "60ffcf108bf23f04c02c2409",
    crn: "3PI-03N-I71",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 11",
    addr1: "Birkenweg 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9791456",
    long: "7.9725969",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8475.0",
    production_ratio: "815.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "684.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00326_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "55.0",
    qrn: "60f6a2b89eee013e34e64eae",
    crn: "5AC-V9H-BT3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 12",
    addr1: "Birkenweg 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9784602",
    long: "7.9729938",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.4",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7218.0",
    production_ratio: "860.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "745.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10584.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00361_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "56.0",
    qrn: "60fff9608bf23f04c02c257b",
    crn: "OBK-J77-5NL",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 13",
    addr1: "Birkenweg 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979165",
    long: "7.9724885",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8548.0",
    production_ratio: "822.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "677.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00325_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "57.0",
    qrn: "60fff9a58bf23f04c02c257f",
    crn: "K8E-J4E-3VF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 15",
    addr1: "Birkenweg 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979325",
    long: "7.9723962",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6874.0",
    production_ratio: "847.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "756.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00324_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "58.0",
    qrn: "60ffea3ce1e1df7221e1b2ee",
    crn: "08B-JTY-RLB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 17",
    addr1: "Birkenweg 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97810468",
    long: "7.972838556",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "4.875",
    proposed_system_size_ac: "4.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3263.0",
    production_ratio: "670.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "15.0",
    new_annual_electric_bill: "1330.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "45.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "6149.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00363_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "59.0",
    qrn: "60fff6e98bf23f04c02c256d",
    crn: "C56-MIN-EGP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 1A",
    addr1: "Birkenweg 1A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9787983",
    long: "7.9723887",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.95",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9955.0",
    production_ratio: "666.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "46.0",
    new_annual_electric_bill: "687.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18837.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00333_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "60.0",
    qrn: "60fff578e1e1df7221e1b342",
    crn: "JL6-9MP-CAG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 1B",
    addr1: "Birkenweg 1B",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9787391",
    long: "7.9726625",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.675",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10270.0",
    production_ratio: "811.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "39.0",
    new_annual_electric_bill: "586.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15977.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00472_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "61.0",
    qrn: "60ffeba98bf23f04c02c2511",
    crn: "8LR-3I3-L7D",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 2",
    addr1: "Birkenweg 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97867565",
    long: "7.971752195",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.0",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22209.0",
    production_ratio: "855.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "80.0",
    new_annual_electric_bill: "240.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "32760.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00356_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "62.0",
    qrn: "60ffebe8e1e1df7221e1b303",
    crn: "UHN-F12-HQS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 2A",
    addr1: "Birkenweg 2A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9786661",
    long: "7.9714178",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.15",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16481.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "62.0",
    new_annual_electric_bill: "366.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "25389.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00355_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "63.0",
    qrn: "60fff7b6e1e1df7221e1b34b",
    crn: "N4L-MEW-1Q9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 3",
    addr1: "Birkenweg 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9786515",
    long: "7.9729843",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "31.525",
    proposed_system_size_ac: "28.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "26129.0",
    production_ratio: "829.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "97.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "39715.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00331_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "64.0",
    qrn: "60ffeac98a3044201b306037",
    crn: "TG8-YF4-26L",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 4",
    addr1: "Birkenweg 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9786376",
    long: "7.9720247",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7978.0",
    production_ratio: "768.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "723.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00357_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "65.0",
    qrn: "60fff7f78a3044201b3060a4",
    crn: "W11-H5S-0SJ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 5",
    addr1: "Birkenweg 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97883178",
    long: "7.972935954",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8927.0",
    production_ratio: "808.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "665.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00330_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "66.0",
    qrn: "60fff8348a3044201b3060a9",
    crn: "CFY-IEJ-4G9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 5A",
    addr1: "Birkenweg 5A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9789018",
    long: "7.9728461",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5707.0",
    production_ratio: "799.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "881.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "64.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00329_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "67.0",
    qrn: "60ffeb698a3044201b30603c",
    crn: "QAH-LOW-EHD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 6",
    addr1: "Birkenweg 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9785884",
    long: "7.972289",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.9",
    proposed_system_size_ac: "16.8",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14421.0",
    production_ratio: "854.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "16.8",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "52.0",
    new_annual_electric_bill: "403.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21294.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00358_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "68.0",
    qrn: "60fff87c8a3044201b3060ac",
    crn: "Y5W-HKI-T0W",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 7",
    addr1: "Birkenweg 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97898416",
    long: "7.972756273",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9028.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "654.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00328_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "69.0",
    qrn: "60ffeb15e1e1df7221e1b2f4",
    crn: "IKP-K1V-5X0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 8",
    addr1: "Birkenweg 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9785386",
    long: "7.9725339",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.9",
    proposed_system_size_ac: "15.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12632.0",
    production_ratio: "795.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "15.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "53.0",
    new_annual_electric_bill: "487.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20034.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00359_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "70.0",
    qrn: "60ffea888bf23f04c02c2504",
    crn: "V0W-6T9-5B8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 8A",
    addr1: "Birkenweg 8A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9784",
    long: "7.97249",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13030.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "459.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00362_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "71.0",
    qrn: "60fff8c28a3044201b3060af",
    crn: "FTB-QGN-W0N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Birkenweg 9",
    addr1: "Birkenweg 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97903",
    long: "7.9726811",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8514.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "681.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00327_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "72.0",
    qrn: "60ffe96de1e1df7221e1b2e0",
    crn: "JKR-1I5-NW4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Burger Str. 1",
    addr1: "Burger Str. 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9792133",
    long: "7.9746983",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "65.0",
    proposed_system_size_ac: "59.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "48952.0",
    production_ratio: "754.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "7.0",
    total_inverter_capacity_1: "59.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "200.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "81900.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00366_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "73.0",
    qrn: "60ffe9ab8bf23f04c02c24f9",
    crn: "A9E-U0J-NX9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Burger Str. 3",
    addr1: "Burger Str. 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97886864",
    long: "7.974447475",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "79.625",
    proposed_system_size_ac: "80.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "63844.0",
    production_ratio: "802.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "8.0",
    total_inverter_capacity_1: "80.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "245.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "100334.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00365_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "74.0",
    qrn: "60ffe9fce1e1df7221e1b2ea",
    crn: "USE-RQL-63X",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Burger Str. 5",
    addr1: "Burger Str. 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9786087",
    long: "7.9744186",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8506.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "689.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00364_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "75.0",
    qrn: "60ffee598bf23f04c02c2520",
    crn: "8RJ-65P-6PN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Burger Str. 6",
    addr1: "Burger Str. 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9792722",
    long: "7.9738532",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "4.225",
    proposed_system_size_ac: "4.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "2785.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "13.0",
    new_annual_electric_bill: "1487.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "39.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "5317.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00354_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "76.0",
    qrn: "60ffea038bf23f04c02c24fc",
    crn: "IMI-1R0-NGR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Capitano Dreisamtalus, Weilerstraße 15",
    addr1: "Capitano Dreisamtalus Weilerstraße 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.981667",
    long: "7.9613827",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "16.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12688.0",
    production_ratio: "814.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "16.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "502.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00373_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "77.0",
    qrn: "6100e9058a3044201b306467",
    crn: "BRJ-FR9-8CT",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Dorfpl. 12",
    addr1: "Dorfpl. 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806653",
    long: "7.9639607",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12477.0",
    production_ratio: "800.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "503.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00482_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "78.0",
    qrn: "6100eaec8a3044201b306470",
    crn: "KXP-PNF-BUG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Dorfpl. 6",
    addr1: "Dorfpl. 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806806",
    long: "7.9645407",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "23.075",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18539.0",
    production_ratio: "804.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "71.0",
    new_annual_electric_bill: "343.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29068.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00484_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "79.0",
    qrn: "6100ea05e1e1df7221e1b72a",
    crn: "C7P-USC-GTP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Dorfpl. 9",
    addr1: "Dorfpl. 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98064",
    long: "7.9643",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.55",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13469.0",
    production_ratio: "768.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "54.0",
    new_annual_electric_bill: "470.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "22113.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00483_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "80.0",
    qrn: "610047328a3044201b30625e",
    crn: "815-G0V-15V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Erwin-Kern-Straße 1",
    addr1: "Erwin-Kern-Straße 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9844008",
    long: "7.9518026",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "32.175",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "29757.0",
    production_ratio: "925.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "99.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "40534.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00207_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "81.0",
    qrn: "6100478d8bf23f04c02c272f",
    crn: "KGX-J0H-W5Y",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Erwin-Kern-Straße 2",
    addr1: "Erwin-Kern-Straße 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98505",
    long: "7.9587",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "22.1",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18463.0",
    production_ratio: "836.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "68.0",
    new_annual_electric_bill: "389.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "27846.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00204_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "82.0",
    qrn: "610046d78bf23f04c02c272c",
    crn: "G96-B53-48I",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Erwin-Kern-Straße 3",
    addr1: "Erwin-Kern-Straße 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9852651",
    long: "7.9548621",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10130.0",
    production_ratio: "945.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "344.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00208_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "83.0",
    qrn: "6108e69ae228ce427178bec7",
    crn: "C8N-ORG-J15",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "FKZ Elektrotechnik",
    addr1: "Kageneckstraße 24",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98127",
    long: "7.95715",
    annul_electric_bill: "10000.0",
    current_annual_consumption: "29784.0",
    unit_electricity_bill_rate: "0.335757695",
    bill_type: "annual",
    proposed_system_size_dc: "30.875",
    proposed_system_size_ac: "28.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27814.0",
    production_ratio: "901.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "95.0",
    new_annual_electric_bill: "2872.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "38909.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00492_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "29400 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: " 294.05 mÂ²",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "84.0",
    qrn: "60fffa42e1e1df7221e1b360",
    crn: "T2C-9H6-G8N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Gasthaus Sonne",
    addr1: "Oberbirken 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9794554",
    long: "7.9720619",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "24.375",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21852.0",
    production_ratio: "897.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "75.0",
    new_annual_electric_bill: "241.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "30719.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00323_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "85.0",
    qrn: "60fff12f8bf23f04c02c252f",
    crn: "KPP-9C7-SY7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 1",
    addr1: "Großmatte 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98435",
    long: "7.95931",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "25.025",
    proposed_system_size_ac: "24.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21727.0",
    production_ratio: "869.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 7.0",
    total_inverter_capacity_2: "7.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "7.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "77.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "31525.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00192_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "86.0",
    qrn: "610048fe8a3044201b306271",
    crn: "6V2-Y2O-E8V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 10",
    addr1: "Großmatte 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98467",
    long: "7.96006",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "30.55",
    proposed_system_size_ac: "28.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "25541.0",
    production_ratio: "837.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "94.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "38493.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00188_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "87.0",
    qrn: "610049708bf23f04c02c2740",
    crn: "T42-JOU-MOI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 2",
    addr1: "Großmatte 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9844561",
    long: "7.9598092",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "21.125",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17197.0",
    production_ratio: "815.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "65.0",
    new_annual_electric_bill: "226.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "26624.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00187_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "88.0",
    qrn: "610047efe1e1df7221e1b50c",
    crn: "4KF-UGL-F02",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 3",
    addr1: "Großmatte 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98459",
    long: "7.95924",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13038.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 7.0",
    total_inverter_capacity_2: "7.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "7.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "301.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00191_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "89.0",
    qrn: "610049f98a3044201b30627c",
    crn: "92N-IPT-96G",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 4",
    addr1: "Großmatte 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.984389",
    long: "7.9599045",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9437.0",
    production_ratio: "807.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "370.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00186_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "90.0",
    qrn: "610048448a3044201b306267",
    crn: "701-Q1I-AI3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 5",
    addr1: "Großmatte 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9847",
    long: "7.95925",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "20.475",
    proposed_system_size_ac: "18.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16797.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "63.0",
    new_annual_electric_bill: "239.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "25805.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00190_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "91.0",
    qrn: "60fff0bf8bf23f04c02c252b",
    crn: "YBH-2QQ-OOW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 6",
    addr1: "Großmatte 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9844",
    long: "7.96008",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6775.0",
    production_ratio: "907.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "453.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00185_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "92.0",
    qrn: "6100489d8bf23f04c02c2737",
    crn: "O6F-YPV-00I",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Großmatte 7",
    addr1: "Großmatte 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9849453",
    long: "7.9594626",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "29.25",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22309.0",
    production_ratio: "763.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "90.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "36855.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00189_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "93.0",
    qrn: "6100d63b8bf23f04c02c28b8",
    crn: "5RE-E9Y-L23",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hä.tti A.",
    addr1: "Ringstraße 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806761",
    long: "7.9604124",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "35.75",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "34552.0",
    production_ratio: "967.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "110.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "45045.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00013_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "94.0",
    qrn: "61005c168bf23f04c02c27a0",
    crn: "38D-OP2-KP6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 10",
    addr1: "Hauptstraße 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9844382",
    long: "7.9689891",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "24.7",
    proposed_system_size_ac: "23.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20409.0",
    production_ratio: "827.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "76.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "31122.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00100_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "95.0",
    qrn: "610059f5e1e1df7221e1b560",
    crn: "8FU-DUC-R7U",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 12",
    addr1: "Hauptstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9842678",
    long: "7.968854",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "11.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10388.0",
    production_ratio: "842.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "362.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00104_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "96.0",
    qrn: "61005a508a3044201b3062b9",
    crn: "I6N-R15-3T7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 12A",
    addr1: "Hauptstraße 12A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9842322",
    long: "7.9690654",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "6.825",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6463.0",
    production_ratio: "947.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "21.0",
    new_annual_electric_bill: "481.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "8606.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00103_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "97.0",
    qrn: "61005b1b8bf23f04c02c2799",
    crn: "Y2N-AST-BGG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 14A",
    addr1: "Hauptstraße 14A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9842269",
    long: "7.9694043",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "24.7",
    proposed_system_size_ac: "23.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20538.0",
    production_ratio: "832.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "76.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "31122.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00102_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "98.0",
    qrn: "60ffeb74e1e1df7221e1b2fb",
    crn: "QWP-N43-0G4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 15",
    addr1: "Hauptstraße 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9845163",
    long: "7.9632546",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "23.725",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16690.0",
    production_ratio: "704.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "73.0",
    new_annual_electric_bill: "255.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "29900.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00372_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "99.0",
    qrn: "61005c778bf23f04c02c27a2",
    crn: "T9O-IJV-PJ5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 16",
    addr1: "Hauptstraße 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9844663",
    long: "7.9692839",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "6.825",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5763.0",
    production_ratio: "845.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "21.0",
    new_annual_electric_bill: "543.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "8606.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00099_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "100.0",
    qrn: "610052b78bf23f04c02c2770",
    crn: "41R-O4Q-26V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 17",
    addr1: "Hauptstraße 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98436",
    long: "7.96372",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "46.475",
    proposed_system_size_ac: "43.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "45919.0",
    production_ratio: "989.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "40.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "143.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "58565.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00119_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "101.0",
    qrn: "61005dbe8bf23f04c02c27ab",
    crn: "T5H-Q1Y-Y2W",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 18A",
    addr1: "Hauptstraße 18A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9847227",
    long: "7.9695684",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5645.0",
    production_ratio: "790.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "551.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "3.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00096_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "102.0",
    qrn: "610053438bf23f04c02c2775",
    crn: "5I4-P7L-1AX",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 19",
    addr1: "Hauptstraße 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98429",
    long: "7.96417",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13204.0",
    production_ratio: "830.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "270.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00118_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "103.0",
    qrn: "61005b878bf23f04c02c279d",
    crn: "CXP-0TE-GJL",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 20",
    addr1: "Hauptstraße 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9841901",
    long: "7.9696997",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "8.775",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7008.0",
    production_ratio: "799.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "27.0",
    new_annual_electric_bill: "502.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11063.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00101_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "104.0",
    qrn: "610053ad8a3044201b3062a0",
    crn: "J7U-5XH-AOO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 21",
    addr1: "Hauptstraße 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98431",
    long: "7.96446",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9731.0",
    production_ratio: "788.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c26",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "593.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00117_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "105.0",
    qrn: "61005ab5e1e1df7221e1b564",
    crn: "VBC-XY7-YD4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 22",
    addr1: "Hauptstraße 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9843028",
    long: "7.9700536",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9093.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "416.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00093_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "106.0",
    qrn: "6100545de1e1df7221e1b54b",
    crn: "EGP-5H9-GRR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 23",
    addr1: "Hauptstraße 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9843873",
    long: "7.9648264",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "14.95",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11097.0",
    production_ratio: "743.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "46.0",
    new_annual_electric_bill: "349.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "18837.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00115_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "107.0",
    qrn: "61005ed48bf23f04c02c27af",
    crn: "G73-KR7-3RS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 24",
    addr1: "Hauptstraße 24",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9845954",
    long: "7.9700865",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "19.175",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16463.0",
    production_ratio: "859.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "59.0",
    new_annual_electric_bill: "229.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "24167.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00094_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "108.0",
    qrn: "61005e2be1e1df7221e1b573",
    crn: "D82-GOT-QQI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 24A",
    addr1: "Hauptstraße 24A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9845799",
    long: "7.9698649",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "22.425",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19643.0",
    production_ratio: "876.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "69.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "3.0",
    project_type: "R",
    total_system_cost_: "28262.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00095_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "109.0",
    qrn: "6100540b8bf23f04c02c277d",
    crn: "3QC-QQJ-U40",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 25",
    addr1: "Hauptstraße 25",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98444",
    long: "7.96501",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "18.525",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15133.0",
    production_ratio: "817.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "57.0",
    new_annual_electric_bill: "237.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "23335.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00116_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "110.0",
    qrn: "61004f578a3044201b306293",
    crn: "7TL-WQ6-YWV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 26",
    addr1: "Hauptstraße 26",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98436",
    long: "7.97062",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "52.0",
    proposed_system_size_ac: "50.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "49245.0",
    production_ratio: "948.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "50.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "160.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "65520.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00080_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "111.0",
    qrn: "610054bce1e1df7221e1b54d",
    crn: "1MX-DBT-6E6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 27",
    addr1: "Hauptstraße 27",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9842284",
    long: "7.965292",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "31.525",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "28986.0",
    production_ratio: "920.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "97.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "39715.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00114_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "112.0",
    qrn: "61004ce48bf23f04c02c2753",
    crn: "ID8-UBD-V4B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 28",
    addr1: "Hauptstraße 28",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9840445",
    long: "7.9705303",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.55",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14780.0",
    production_ratio: "843.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "54.0",
    new_annual_electric_bill: "403.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "22113.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00158_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "113.0",
    qrn: "61005f288bf23f04c02c27b4",
    crn: "7OX-VO2-Q71",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 28A",
    addr1: "Hauptstraße 28A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.984083",
    long: "7.9701539",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "12.675",
    proposed_system_size_ac: "13.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8774.0",
    production_ratio: "693.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "39.0",
    new_annual_electric_bill: "450.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15977.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00092_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "114.0",
    qrn: "61006179e1e1df7221e1b582",
    crn: "N9U-PVD-BOO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 28B ",
    addr1: "Hauptstraße 28B",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9841",
    long: "7.97024",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9158.0",
    production_ratio: "829.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c19",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "381.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "3.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00078_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "115.0",
    qrn: "610051d7e1e1df7221e1b543",
    crn: "V3W-45I-XDU",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 29A",
    addr1: "Hauptstraße 29A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9843509",
    long: "7.9657925",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "24.05",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21185.0",
    production_ratio: "881.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "74.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "30303.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00113_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "116.0",
    qrn: "61005f9be1e1df7221e1b578",
    crn: "6KX-5WX-6DB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 30",
    addr1: "Hauptstraße 30",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9848158",
    long: "7.9705555",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "20.15",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17552.0",
    production_ratio: "872.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "62.0",
    new_annual_electric_bill: "234.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "3.0",
    project_type: "R",
    total_system_cost_: "25389.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00091_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "117.0",
    qrn: "610055298bf23f04c02c2784",
    crn: "YBH-WH6-IDM",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 31",
    addr1: "Hauptstraße 31",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9842525",
    long: "7.9660646",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "38.025",
    proposed_system_size_ac: "34.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "33262.0",
    production_ratio: "875.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 4.2",
    total_inverter_capacity_2: "4.2",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "4.2",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "117.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "47905.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00112_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "118.0",
    qrn: "6100556fe1e1df7221e1b551",
    crn: "KNL-QIF-AKW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 31A",
    addr1: "Hauptstraße 31A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98443",
    long: "7.96612",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6037.0",
    production_ratio: "808.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "528.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00111_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "119.0",
    qrn: "6100561ae1e1df7221e1b554",
    crn: "MJL-GKI-T3B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 31B",
    addr1: "Hauptstraße 31B",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98448",
    long: "7.96609",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "6.825",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5611.0",
    production_ratio: "823.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "21.0",
    new_annual_electric_bill: "551.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "8606.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00110_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "120.0",
    qrn: "610055ce8bf23f04c02c2789",
    crn: "CWD-TLN-G4Q",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 31C",
    addr1: "Hauptstraße 31C",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98452",
    long: "7.96608",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.5",
    proposed_system_size_ac: "6.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5370.0",
    production_ratio: "827.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "6.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "20.0",
    new_annual_electric_bill: "914.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "62.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "8190.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00109_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "121.0",
    qrn: "61005875e1e1df7221e1b558",
    crn: "C7L-BNV-BA0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 31D",
    addr1: "Hauptstraße 31D",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98463",
    long: "7.96601",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "12.025",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10058.0",
    production_ratio: "837.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "37.0",
    new_annual_electric_bill: "361.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00108_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "122.0",
    qrn: "6100606a8a3044201b3062cd",
    crn: "DS1-XYK-6WI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 32",
    addr1: "Hauptstraße 32",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9849108",
    long: "7.9708695",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9334.0",
    production_ratio: "927.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "353.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "3.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00089_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "123.0",
    qrn: "61005cf7e1e1df7221e1b56d",
    crn: "Y90-U9P-6VF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 35",
    addr1: "Hauptstraße 35",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.984879",
    long: "7.9690892",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "14.625",
    proposed_system_size_ac: "13.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12906.0",
    production_ratio: "883.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "272.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "18434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00098_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "124.0",
    qrn: "61005d4e8a3044201b3062c3",
    crn: "GEK-979-DM5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 37",
    addr1: "Hauptstraße 37",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9849106",
    long: "7.9691939",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.1",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7631.0",
    production_ratio: "839.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "461.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11466.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00097_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "125.0",
    qrn: "61004b318a3044201b306282",
    crn: "33O-XEA-2JP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 3A",
    addr1: "Hauptstraße 3A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9843618",
    long: "7.9609339",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "61.425",
    proposed_system_size_ac: "55.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "51913.0",
    production_ratio: "846.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "50.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "189.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "77389.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00183_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "126.0",
    qrn: "61004a5d8bf23f04c02c2744",
    crn: "7HO-HV1-PK1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 7",
    addr1: "Hauptstraße 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9843348",
    long: "7.9618038",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.975",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22514.0",
    production_ratio: "835.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "83.0",
    new_annual_electric_bill: "274.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "33995.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00161_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "127.0",
    qrn: "610050d98a3044201b30629b",
    crn: "Q6C-05F-93N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 7a",
    addr1: "Hauptstraße 7a",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9842384",
    long: "7.9622374",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "6.5",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5179.0",
    production_ratio: "797.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "20.0",
    new_annual_electric_bill: "570.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "8190.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00136_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "128.0",
    qrn: "61004aca8bf23f04c02c274a",
    crn: "MCN-XOR-J29",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 7D",
    addr1: "Hauptstraße 7D",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98451",
    long: "7.96225",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.1",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7768.0",
    production_ratio: "854.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "417.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11466.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00160_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "129.0",
    qrn: "61004c678a3044201b306288",
    crn: "1XR-CGF-TO0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 7E",
    addr1: "Hauptstraße 7E",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98448",
    long: "7.96233",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.55",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14298.0",
    production_ratio: "815.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "54.0",
    new_annual_electric_bill: "424.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "22113.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00159_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "130.0",
    qrn: "610051648bf23f04c02c276a",
    crn: "OR1-A2D-XJD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hauptstraße 9A",
    addr1: "Hauptstraße 9A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9843295",
    long: "7.9625785",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "16.575",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14319.0",
    production_ratio: "864.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "51.0",
    new_annual_electric_bill: "261.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20878.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00135_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "131.0",
    qrn: "610059868a3044201b3062b5",
    crn: "DLB-4T7-4VS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 1",
    addr1: "Hirschenweg 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9838116",
    long: "7.9670886",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5934.0",
    production_ratio: "794.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "542.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00105_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "132.0",
    qrn: "6100041de1e1df7221e1b3a4",
    crn: "BYS-APV-NDI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 10",
    addr1: "Hirschenweg 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9838968",
    long: "7.9655816",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "17.875",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16002.0",
    production_ratio: "896.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "55.0",
    new_annual_electric_bill: "231.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "22529.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00298_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "133.0",
    qrn: "610008fa8a3044201b306113",
    crn: "ATD-U0G-UE4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 11",
    addr1: "Hirschenweg 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9835441",
    long: "7.9654191",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.1",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8479.0",
    production_ratio: "932.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "365.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11466.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00293_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "134.0",
    qrn: "6100036b8a3044201b3060e7",
    crn: "U1L-WVE-MRV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 12",
    addr1: "Hirschenweg 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9837778",
    long: "7.9653327",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "6.5",
    proposed_system_size_ac: "6.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5123.0",
    production_ratio: "789.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "6.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "20.0",
    new_annual_electric_bill: "581.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "66.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "8190.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00299_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "135.0",
    qrn: "610007108bf23f04c02c25d0",
    crn: "BJI-2DM-MP8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 13",
    addr1: "Hirschenweg 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9833709",
    long: "7.9650268",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9972.0",
    production_ratio: "930.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "334.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00294_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "136.0",
    qrn: "60fff6808bf23f04c02c2567",
    crn: "BM1-IRF-7YK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 14",
    addr1: "Hirschenweg 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98376",
    long: "7.96502",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "14.95",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12327.0",
    production_ratio: "825.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "46.0",
    new_annual_electric_bill: "296.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "18837.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00300_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "137.0",
    qrn: "6100068a8a3044201b3060fb",
    crn: "O50-0Y2-DE8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 15",
    addr1: "Hirschenweg 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9833887",
    long: "7.9648837",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "31.85",
    proposed_system_size_ac: "28.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27218.0",
    production_ratio: "855.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "98.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "40131.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00295_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "138.0",
    qrn: "60fffcdc8bf23f04c02c2598",
    crn: "V0O-NH7-JLO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 16",
    addr1: "Hirschenweg 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98362",
    long: "7.96471",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12905.0",
    production_ratio: "811.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "494.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00301_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "139.0",
    qrn: "61000590e1e1df7221e1b3b0",
    crn: "SQL-IR3-F2T",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 17",
    addr1: "Hirschenweg 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98331",
    long: "7.96456",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7756.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "437.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00296_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "140.0",
    qrn: "610058d8e1e1df7221e1b55a",
    crn: "IRV-IMW-H8I",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 2",
    addr1: "Hirschenweg 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98409",
    long: "7.96716",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "18.85",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15171.0",
    production_ratio: "805.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 7.0",
    total_inverter_capacity_2: "7.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "7.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "58.0",
    new_annual_electric_bill: "243.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "23751.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00107_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "141.0",
    qrn: "610028388bf23f04c02c26b3",
    crn: "7XR-3VE-WJS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 3",
    addr1: "Hirschenweg 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9835893",
    long: "7.9666439",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "33.15",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27720.0",
    production_ratio: "837.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "102.0",
    new_annual_electric_bill: "227.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "41769.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00273_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "142.0",
    qrn: "6100592f8a3044201b3062b1",
    crn: "NAB-4K8-KY2",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 4",
    addr1: "Hirschenweg 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98405",
    long: "7.96681",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7659.0",
    production_ratio: "761.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "500.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00106_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "143.0",
    qrn: "61002371e1e1df7221e1b479",
    crn: "D7U-W7I-AO0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 5",
    addr1: "Hirschenweg 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98367",
    long: "7.9661517",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.15",
    proposed_system_size_ac: "18.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16902.0",
    production_ratio: "839.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "62.0",
    new_annual_electric_bill: "380.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "25389.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00290_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "144.0",
    qrn: "610022e58a3044201b3061cd",
    crn: "1LV-GUA-DKQ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 7",
    addr1: "Hirschenweg 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9836371",
    long: "7.9660184",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13985.0",
    production_ratio: "897.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 4.2",
    total_inverter_capacity_2: "4.2",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "4.2",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "237.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00291_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "145.0",
    qrn: "610004c2e1e1df7221e1b3ad",
    crn: "DDL-R1I-IW9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 8",
    addr1: "Hirschenweg 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9838532",
    long: "7.9659283",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "30.55",
    proposed_system_size_ac: "28.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "25256.0",
    production_ratio: "827.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "94.0",
    new_annual_electric_bill: "245.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "38493.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00297_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "146.0",
    qrn: "61000ceb8bf23f04c02c260c",
    crn: "6U7-4KB-ILI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Hirschenweg 9",
    addr1: "Hirschenweg 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9835921",
    long: "7.9657166",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10959.0",
    production_ratio: "843.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "319.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "16380.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00292_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "147.0",
    qrn: "60fffc20e1e1df7221e1b375",
    crn: "AKW-TKN-VHO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 1",
    addr1: "Im Gäßle 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9796959",
    long: "7.9715272",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.225",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13449.0",
    production_ratio: "781.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "53.0",
    new_annual_electric_bill: "475.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21710.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00318_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "148.0",
    qrn: "61000ff38a3044201b306163",
    crn: "8QX-5KN-GWL",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 10",
    addr1: "Im Gäßle 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802891",
    long: "7.9713046",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "25.675",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20790.0",
    production_ratio: "810.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "79.0",
    new_annual_electric_bill: "275.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "32357.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00212_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "149.0",
    qrn: "60fff6a78bf23f04c02c256a",
    crn: "F5G-RRO-D6N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 12",
    addr1: "Im Gäßle 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98056",
    long: "7.97139",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "41.6",
    proposed_system_size_ac: "40.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "33827.0",
    production_ratio: "814.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "40.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "128.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "52416.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00199_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "150.0",
    qrn: "60fff9e9e1e1df7221e1b35c",
    crn: "395-5TD-VCF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 13",
    addr1: "Im Gäßle 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97999007",
    long: "7.971140979",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7090.0",
    production_ratio: "949.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "716.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00315_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "151.0",
    qrn: "610010318a3044201b306168",
    crn: "G2F-LPX-XFK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 14",
    addr1: "Im Gäßle 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9807142",
    long: "7.9710139",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.25",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13054.0",
    production_ratio: "804.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "50.0",
    new_annual_electric_bill: "469.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20475.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00210_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "152.0",
    qrn: "60ffd4ab8bf23f04c02c242e",
    crn: "L8W-MNV-HXY",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 15",
    addr1: "Im Gäßle 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9800565",
    long: "7.9710699",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4850.0",
    production_ratio: "679.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "1040.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "57.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00467_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "153.0",
    qrn: "610004798bf23f04c02c25b9",
    crn: "COO-830-D4U",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 17",
    addr1: "Im Gäßle 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802495",
    long: "7.9709431",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6490.0",
    production_ratio: "799.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "810.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00313_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "154.0",
    qrn: "610005e18a3044201b3060f5",
    crn: "XVL-PLJ-P39",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 19",
    addr1: "Im Gäßle 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9803206",
    long: "7.9708903",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.5",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8501.0",
    production_ratio: "810.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "693.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13230.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00312_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "155.0",
    qrn: "60fffb6de1e1df7221e1b36b",
    crn: "UPV-KGJ-H6J",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 1A",
    addr1: "Im Gäßle 1A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97954",
    long: "7.97128",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "23.4",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18979.0",
    production_ratio: "812.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "72.0",
    new_annual_electric_bill: "332.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29484.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00319_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "156.0",
    qrn: "610008f9e1e1df7221e1b3d4",
    crn: "OE2-3VY-CKE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 2",
    addr1: "Im Gäßle 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98013",
    long: "7.97267",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.8",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17114.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "64.0",
    new_annual_electric_bill: "347.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "26208.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00252_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "157.0",
    qrn: "610006228a3044201b3060f7",
    crn: "90G-GTB-818",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 21",
    addr1: "Im Gäßle 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9804991",
    long: "7.9707823",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6664.0",
    production_ratio: "789.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "793.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00311_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "158.0",
    qrn: "61000664e1e1df7221e1b3b6",
    crn: "3P6-TKC-1FM",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 23",
    addr1: "Im Gäßle 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98048",
    long: "7.9707",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "3.25",
    proposed_system_size_ac: "3.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "2645.0",
    production_ratio: "814.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "3.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "10.0",
    new_annual_electric_bill: "1533.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "37.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "4095.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00310_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "159.0",
    qrn: "61000796e1e1df7221e1b3c5",
    crn: "QB0-PTA-GI8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 25",
    addr1: "Im Gäßle 25",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806662",
    long: "7.9706852",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7435.0",
    production_ratio: "738.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "764.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00306_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "160.0",
    qrn: "60ffd3ae8a3044201b305f46",
    crn: "HSD-40H-JOE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 3",
    addr1: "Im Gäßle 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97980752",
    long: "7.971378875",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5747.0",
    production_ratio: "681.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "946.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "61.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00465_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "161.0",
    qrn: "610008b48a3044201b306111",
    crn: "AMM-5C1-PXL",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 4",
    addr1: "Im Gäßle 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98008",
    long: "7.97234",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "23.725",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20077.0",
    production_ratio: "847.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "73.0",
    new_annual_electric_bill: "265.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29900.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00254_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "162.0",
    qrn: "60fffc7ae1e1df7221e1b37b",
    crn: "QMA-1CD-IDK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 5",
    addr1: "Im Gäßle 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97988452",
    long: "7.971283651",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "3.25",
    proposed_system_size_ac: "3.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3075.0",
    production_ratio: "947.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "3.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "10.0",
    new_annual_electric_bill: "1392.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "42.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "4095.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00317_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "163.0",
    qrn: "610008768bf23f04c02c25e3",
    crn: "CPW-7W0-KL3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 6",
    addr1: "Im Gäßle 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97994578",
    long: "7.972170921",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7428.0",
    production_ratio: "789.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "742.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00255_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "164.0",
    qrn: "6100081fe1e1df7221e1b3c9",
    crn: "R85-IVY-V45",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 6A",
    addr1: "Im Gäßle 6A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9799193",
    long: "7.9720799",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6774.0",
    production_ratio: "834.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "761.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00257_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "165.0",
    qrn: "610003cc8bf23f04c02c25b2",
    crn: "OTX-1RW-YWJ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 7",
    addr1: "Im Gäßle 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97978552",
    long: "7.971059856",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6773.0",
    production_ratio: "948.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "735.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00316_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "166.0",
    qrn: "610007db8bf23f04c02c25d9",
    crn: "PB7-VS7-FGS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 8",
    addr1: "Im Gäßle 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9799563",
    long: "7.9717045",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.25",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13419.0",
    production_ratio: "826.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "50.0",
    new_annual_electric_bill: "450.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20475.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00258_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "167.0",
    qrn: "6100042b8bf23f04c02c25b6",
    crn: "UN0-JJQ-UQ4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Gäßle 9",
    addr1: "Im Gäßle 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97993",
    long: "7.97051",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.875",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14678.0",
    production_ratio: "822.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "55.0",
    new_annual_electric_bill: "431.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "22529.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00314_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "168.0",
    qrn: "60dae427e38dc240aa364739",
    crn: "FDM-7Q0-D6N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 1",
    addr1: "Im Großacker 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98162",
    long: "7.95867",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10180.0",
    production_ratio: "950.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "337.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "16087.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00006_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "169.0",
    qrn: "610066218a3044201b3062e9",
    crn: "J2O-L99-WX3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 10",
    addr1: "Im Großacker 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9815784",
    long: "7.9577742",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "19.825",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18322.0",
    production_ratio: "925.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "61.0",
    new_annual_electric_bill: "287.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "24973.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00050_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "130 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "170.0",
    qrn: "60ffe0ffe1e1df7221e1b26e",
    crn: "MDR-5P5-9LN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 11",
    addr1: "Im Großacker 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98293",
    long: "7.95788",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "31.85",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "29806.0",
    production_ratio: "936.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "98.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "40131.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00424_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "171.0",
    qrn: "60ffdc058bf23f04c02c2468",
    crn: "BJ8-7NE-GD5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 12",
    addr1: "Im Großacker 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98120987",
    long: "7.958423894",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.175",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4890.0",
    production_ratio: "792.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "19.0",
    new_annual_electric_bill: "986.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "59.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "7774.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00456_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "172.0",
    qrn: "60ffe01a8a3044201b305fbd",
    crn: "VAE-O7M-4C9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 13",
    addr1: "Im Großacker 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98283",
    long: "7.95807",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "21.45",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20084.0",
    production_ratio: "937.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "66.0",
    new_annual_electric_bill: "280.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "27027.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00425_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "173.0",
    qrn: "60ffdf2d8bf23f04c02c2491",
    crn: "G77-20A-BW8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 16",
    addr1: "Im Großacker 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9812179",
    long: "7.9580389",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.175",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5339.0",
    production_ratio: "865.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "19.0",
    new_annual_electric_bill: "927.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "62.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "7774.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00428_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "174.0",
    qrn: "60ffd068e1e1df7221e1b1e1",
    crn: "61B-J41-8CP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 19",
    addr1: "Im Großacker 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98321",
    long: "7.95828",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9110.0",
    production_ratio: "738.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "695.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00470_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "175 m2",
    roof_type: "Tent Roof",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "Solar PV Panels",
    year_of_roof_construction: "",
  },
  {
    sl_no: "175.0",
    qrn: "610050468a3044201b306298",
    crn: "KPG-29K-N9C",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 2",
    addr1: "Im Großacker 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98134564",
    long: "7.958550992",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "12.025",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9188.0",
    production_ratio: "765.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "37.0",
    new_annual_electric_bill: "393.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00054_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "176.0",
    qrn: "60ffdd64e1e1df7221e1b253",
    crn: "U09-693-MFT",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 20",
    addr1: "Im Großacker 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98127",
    long: "7.958",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12055.0",
    production_ratio: "757.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "527.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00449_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "177.0",
    qrn: "60ffe8de8a3044201b30601b",
    crn: "MJY-RAS-R9C",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 21",
    addr1: "Im Großacker 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98316",
    long: "7.95845",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6316.0",
    production_ratio: "778.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "827.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "66.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00386_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "178.0",
    qrn: "60ffe7cbe1e1df7221e1b2c5",
    crn: "P31-NAG-H1X",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 23",
    addr1: "Im Großacker 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98314",
    long: "7.95851",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "4.225",
    proposed_system_size_ac: "4.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3232.0",
    production_ratio: "765.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "13.0",
    new_annual_electric_bill: "1340.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "45.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "5317.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00389_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "179.0",
    qrn: "60ffd6148bf23f04c02c2438",
    crn: "WAI-L9Q-06K",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 24",
    addr1: "Im Großacker 24",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98135",
    long: "7.95771",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "19.5",
    proposed_system_size_ac: "18.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15883.0",
    production_ratio: "815.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "18.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "60.0",
    new_annual_electric_bill: "380.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "24570.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00448_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "180.0",
    qrn: "60ffea6b8bf23f04c02c2502",
    crn: "LH1-T22-KLC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 27",
    addr1: "Im Großacker 27",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98339",
    long: "7.95857",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "8.775",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6646.0",
    production_ratio: "758.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "27.0",
    new_annual_electric_bill: "507.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11063.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00370_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "181.0",
    qrn: "610a889841630e304eef5f97",
    crn: "U58-VPQ-X5F",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 28",
    addr1: "Im Großacker 28",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98171",
    long: "7.95732",
    annul_electric_bill: "36000.0",
    current_annual_consumption: "108984.0",
    unit_electricity_bill_rate: "0.330338097",
    bill_type: "annual",
    proposed_system_size_dc: "42.575",
    proposed_system_size_ac: "40.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "38256.0",
    production_ratio: "899.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "40.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "131.0",
    new_annual_electric_bill: "23443.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "35.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "53651.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00497_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "Multifamily",
    business_type: "N/A",
    contact_url: "N/A",
    contact_website: "N/A",
    estimated_consumption: "100600 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "4.0",
    number_of_inhabitants: "N/A",
    owner_type: "N/A",
    roof_area: "503.48 mÂ²",
    roof_type: "Flat roof",
    state_of_roof: "N/A",
    style_of_roof: "Dachbahn / Bitumen",
    type_of_solar_panel_if_already_installed: "N/A",
    year_of_roof_construction: "N/A",
  },
  {
    sl_no: "182.0",
    qrn: "60ffe3d2e1e1df7221e1b28d",
    crn: "8TK-S2V-MV8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 3",
    addr1: "Im Großacker 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98195",
    long: "7.95789",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "25.35",
    proposed_system_size_ac: "25.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "23774.0",
    production_ratio: "938.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "25.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "78.0",
    new_annual_electric_bill: "238.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "31941.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00408_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "183.0",
    qrn: "610064c9e1e1df7221e1b590",
    crn: "WMW-0DI-AAI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 4",
    addr1: "Im Großacker 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9813865",
    long: "7.9582946",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.9",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13250.0",
    production_ratio: "785.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "52.0",
    new_annual_electric_bill: "472.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21294.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00053_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "140 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "184.0",
    qrn: "60ffe6e88bf23f04c02c24da",
    crn: "0A0-0BB-NAH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 44 ",
    addr1: "Im Großacker 44",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9833269",
    long: "7.9577947",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.325",
    proposed_system_size_ac: "25.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21241.0",
    production_ratio: "807.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "25.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "81.0",
    new_annual_electric_bill: "272.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "33163.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00394_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "185.0",
    qrn: "60ffd88d8bf23f04c02c244c",
    crn: "ILW-8WQ-KJ0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 48",
    addr1: "Im Großacker 48",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98358",
    long: "7.9582",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7037.0",
    production_ratio: "747.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "788.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00458_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "85 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "186.0",
    qrn: "60ffe31ee1e1df7221e1b283",
    crn: "KI2-HP2-C1G",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 5",
    addr1: "Im Großacker 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.982198",
    long: "7.9575416",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "37.05",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "34882.0",
    production_ratio: "942.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "114.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "46683.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00409_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "187.0",
    qrn: "60ffd475e1e1df7221e1b210",
    crn: "48C-DIB-VES",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 50",
    addr1: "Im Großacker 50",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9836",
    long: "7.9581",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6961.0",
    production_ratio: "824.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "756.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00459_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "70 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "188.0",
    qrn: "60ffd41e8bf23f04c02c2426",
    crn: "8JC-INL-G6J",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 52",
    addr1: "Im Großacker 52",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98353",
    long: "7.95794",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.175",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4879.0",
    production_ratio: "791.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "19.0",
    new_annual_electric_bill: "981.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "60.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "7774.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00460_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "75 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "189.0",
    qrn: "60ffd3cae1e1df7221e1b205",
    crn: "T2W-Y4N-PY3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 54",
    addr1: "Im Großacker 54",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98349",
    long: "7.95786",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7128.0",
    production_ratio: "878.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "731.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00461_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "80 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "190.0",
    qrn: "610065488bf23f04c02c27d1",
    crn: "G5U-M27-DSS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 6",
    addr1: "Im Großacker 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98146865",
    long: "7.958110339",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "19.175",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16149.0",
    production_ratio: "843.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "59.0",
    new_annual_electric_bill: "365.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "24167.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00052_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "120 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "191.0",
    qrn: "61006cdde1e1df7221e1b5ac",
    crn: "URT-4LL-571",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 60",
    addr1: "Im Großacker 60",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9838",
    long: "7.95945",
    annul_electric_bill: "1699.77",
    current_annual_consumption: "4501.0",
    unit_electricity_bill_rate: "0.377649034",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12248.0",
    production_ratio: "943.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "281.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "16380.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00007_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "192.0",
    qrn: "60ffde22e1e1df7221e1b25b",
    crn: "P5P-OGQ-AJ0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 62",
    addr1: "Im Großacker 62",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98377",
    long: "7.95954",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10372.0",
    production_ratio: "840.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "572.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00433_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "193.0",
    qrn: "60ffe167e1e1df7221e1b273",
    crn: "W4D-34O-G6J",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 64",
    addr1: "Im Großacker 64",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9838512",
    long: "7.9598011",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.025",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8146.0",
    production_ratio: "678.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "37.0",
    new_annual_electric_bill: "773.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00423_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "194.0",
    qrn: "60ffdce1e1e1df7221e1b243",
    crn: "CPI-Q8N-UXW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 68",
    addr1: "Im Großacker 68",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9836002",
    long: "7.9594912",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13589.0",
    production_ratio: "890.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "473.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00451_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "195.0",
    qrn: "60ffdc728a3044201b305f8d",
    crn: "WD0-2VF-53M",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 7",
    addr1: "Im Großacker 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98264",
    long: "7.95767",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "43.225",
    proposed_system_size_ac: "40.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "35860.0",
    production_ratio: "830.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "40.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "133.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "55767.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00455_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "196.0",
    qrn: "610065b58a3044201b3062e6",
    crn: "LWR-HEH-27B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 8",
    addr1: "Im Großacker 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98152536",
    long: "7.957974276",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.225",
    proposed_system_size_ac: "16.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16364.0",
    production_ratio: "951.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "16.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "53.0",
    new_annual_electric_bill: "318.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21710.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00051_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "197.0",
    qrn: "60ffd31fe1e1df7221e1b1fa",
    crn: "60L-UKJ-LQU",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Großacker 9",
    addr1: "Im Großacker 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98254",
    long: "7.95789",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "34.45",
    proposed_system_size_ac: "33.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "28441.0",
    production_ratio: "826.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "8.0",
    total_inverter_capacity_1: "33.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "106.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "43407.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00463_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "198.0",
    qrn: "61000dc9e1e1df7221e1b3f8",
    crn: "DKJ-87V-AT6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 1",
    addr1: "Im Kreuzacker 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98047",
    long: "7.97386",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.175",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5282.0",
    production_ratio: "856.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "19.0",
    new_annual_electric_bill: "898.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "63.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "7774.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00236_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "199.0",
    qrn: "610012578a3044201b306189",
    crn: "N5W-5BY-A91",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 10",
    addr1: "Im Kreuzacker 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9804899",
    long: "7.9717917",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.975",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22095.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "83.0",
    new_annual_electric_bill: "246.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "33995.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00198_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "200.0",
    qrn: "61000f6de1e1df7221e1b40e",
    crn: "ERB-DS8-PRE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 11",
    addr1: "Im Kreuzacker 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802795",
    long: "7.9721351",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "21.125",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17632.0",
    production_ratio: "835.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "65.0",
    new_annual_electric_bill: "329.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "26624.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00229_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "201.0",
    qrn: "61000faee1e1df7221e1b413",
    crn: "O16-6JR-3U0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 13",
    addr1: "Im Kreuzacker 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802192",
    long: "7.9717896",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11117.0",
    production_ratio: "796.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "551.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00228_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "202.0",
    qrn: "60fff64b8a3044201b306098",
    crn: "MP3-8YT-2SU",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 15",
    addr1: "Im Kreuzacker 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.980099",
    long: "7.9714951",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "21.45",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16469.0",
    production_ratio: "768.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "66.0",
    new_annual_electric_bill: "398.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "27027.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00213_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "203.0",
    qrn: "61000d85e1e1df7221e1b3f3",
    crn: "OF8-EEL-XDP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 1A",
    addr1: "Im Kreuzacker 1A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9804645",
    long: "7.9737821",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7627.0",
    production_ratio: "810.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "724.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00238_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "204.0",
    qrn: "6100135f8a3044201b306195",
    crn: "CVV-UCF-K3Y",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 2",
    addr1: "Im Kreuzacker 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806317",
    long: "7.9731595",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "33.475",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27419.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "103.0",
    new_annual_electric_bill: "229.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "42185.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00194_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "205.0",
    qrn: "61000e3ae1e1df7221e1b3fc",
    crn: "DLA-B7M-3GW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 3",
    addr1: "Im Kreuzacker 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.980352",
    long: "7.9734827",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "24.05",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19397.0",
    production_ratio: "807.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "74.0",
    new_annual_electric_bill: "300.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "88.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "30303.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00235_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "206.0",
    qrn: "6100131a8bf23f04c02c264d",
    crn: "MFL-MHU-DP9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 4",
    addr1: "Im Kreuzacker 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805751",
    long: "7.9728964",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.475",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16779.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "63.0",
    new_annual_electric_bill: "385.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "25805.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00195_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "207.0",
    qrn: "61000e828bf23f04c02c261d",
    crn: "DLP-KS2-LFG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 5",
    addr1: "Im Kreuzacker 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9803321",
    long: "7.9731087",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11968.0",
    production_ratio: "768.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "52.0",
    new_annual_electric_bill: "524.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00233_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "208.0",
    qrn: "610012d88a3044201b306191",
    crn: "76F-MRH-VVE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 6",
    addr1: "Im Kreuzacker 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805506",
    long: "7.9725111",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6648.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "787.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00196_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "209.0",
    qrn: "61000edfe1e1df7221e1b405",
    crn: "51C-97H-DNA",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 7",
    addr1: "Im Kreuzacker 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9803213",
    long: "7.972764",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "21.125",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17133.0",
    production_ratio: "812.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "65.0",
    new_annual_electric_bill: "351.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "26624.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00232_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "210.0",
    qrn: "610012978a3044201b30618c",
    crn: "47A-JC6-G2N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 8",
    addr1: "Im Kreuzacker 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805306",
    long: "7.9721766",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "24.05",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19723.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "74.0",
    new_annual_electric_bill: "319.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "30303.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00197_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "211.0",
    qrn: "61000f1f8a3044201b306159",
    crn: "YO0-47Y-4NO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Im Kreuzacker 9",
    addr1: "Im Kreuzacker 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9803003",
    long: "7.9724697",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "23.4",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19358.0",
    production_ratio: "828.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "72.0",
    new_annual_electric_bill: "289.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "88.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29484.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00231_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "212.0",
    qrn: "610043968a3044201b306242",
    crn: "YWS-BXY-E59",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 11",
    addr1: "Jägerstraße 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9819779",
    long: "7.9660229",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8047.0",
    production_ratio: "826.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "695.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00221_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "213.0",
    qrn: "61003f458bf23f04c02c2700",
    crn: "1M1-V4W-4BG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 12",
    addr1: "Jägerstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9823389",
    long: "7.9653108",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5712.0",
    production_ratio: "799.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "877.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "64.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00253_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "214.0",
    qrn: "610042548bf23f04c02c2710",
    crn: "U9F-J9E-VNN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 13",
    addr1: "Jägerstraße 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9819933",
    long: "7.9663623",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.95",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12065.0",
    production_ratio: "808.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "46.0",
    new_annual_electric_bill: "500.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "18837.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00225_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "215.0",
    qrn: "61003e94e1e1df7221e1b4d3",
    crn: "960-JXJ-RJW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 14",
    addr1: "Jägerstraße 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98233",
    long: "7.96546",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6514.0",
    production_ratio: "912.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "755.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00256_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "216.0",
    qrn: "610042a7e1e1df7221e1b4ee",
    crn: "37M-N9W-LJM",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 15",
    addr1: "Jägerstraße 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9817267",
    long: "7.9662889",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "23.725",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19682.0",
    production_ratio: "830.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "73.0",
    new_annual_electric_bill: "279.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "29900.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00224_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "217.0",
    qrn: "61003e118bf23f04c02c26f7",
    crn: "HT4-8TT-3R2",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 16",
    addr1: "Jägerstraße 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98234",
    long: "7.96561",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.525",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3938.0",
    production_ratio: "713.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "17.0",
    new_annual_electric_bill: "1156.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "52.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "6968.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00259_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "218.0",
    qrn: "610043278a3044201b30623d",
    crn: "V9L-FHS-UOA",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 17",
    addr1: "Jägerstraße 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9814395",
    long: "7.9662551",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "27.3",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21817.0",
    production_ratio: "800.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "84.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "34398.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00222_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "219.0",
    qrn: "61003f9be1e1df7221e1b4d7",
    crn: "FEK-AY4-WAQ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 18",
    addr1: "Jägerstraße 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98234",
    long: "7.96576",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "11.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10152.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "342.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00250_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "220.0",
    qrn: "61004033e1e1df7221e1b4d9",
    crn: "2UW-XEW-4M4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 20",
    addr1: "Jägerstraße 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9823761",
    long: "7.9658956",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.1",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7608.0",
    production_ratio: "837.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "715.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11466.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00242_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "221.0",
    qrn: "610040828a3044201b30622a",
    crn: "LBY-TJR-NU2",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 22",
    addr1: "Jägerstraße 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98236",
    long: "7.96605",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "8.775",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7134.0",
    production_ratio: "813.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "27.0",
    new_annual_electric_bill: "463.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "11063.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00237_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "222.0",
    qrn: "610041e1e1e1df7221e1b4ea",
    crn: "A65-LOX-2KP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 4",
    addr1: "Jägerstraße 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98236",
    long: "7.96439",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "28.275",
    proposed_system_size_ac: "27.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "23742.0",
    production_ratio: "840.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "27.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "87.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "35620.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00226_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "223.0",
    qrn: "61004188e1e1df7221e1b4e5",
    crn: "41R-CUJ-8NF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 6",
    addr1: "Jägerstraße 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9822851",
    long: "7.9645989",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12762.0",
    production_ratio: "836.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "495.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00227_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "224.0",
    qrn: "61003d56e1e1df7221e1b4ce",
    crn: "QY1-75N-V62",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 8",
    addr1: "Jägerstraße 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9823051",
    long: "7.9650031",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8924.0",
    production_ratio: "886.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "631.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00260_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "225.0",
    qrn: "60eec1bf9eee013e34e64762",
    crn: "G3W-QQT-09X",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Jägerstraße 9",
    addr1: "Jägerstraße 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9819741",
    long: "7.9659381",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7695.0",
    production_ratio: "817.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "718.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "14137.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00220_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "226.0",
    qrn: "60ffd583e1e1df7221e1b217",
    crn: "PQL-IYK-KA0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 10",
    addr1: "Kageneckstraße 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806316",
    long: "7.9582831",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "31.85",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "25643.0",
    production_ratio: "806.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "98.0",
    new_annual_electric_bill: "227.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "40131.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00450_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "227.0",
    qrn: "610c1dcb6a2c2a7aedcc602a",
    crn: "OHW-XEP-DYO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 12",
    addr1: "Kageneckstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806607",
    long: "7.9580113",
    annul_electric_bill: "8100.0",
    current_annual_consumption: "24000.0",
    unit_electricity_bill_rate: "0.337555",
    bill_type: "annual",
    proposed_system_size_dc: "24.05",
    proposed_system_size_ac: "22.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22122.0",
    production_ratio: "920.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "22.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "74.0",
    new_annual_electric_bill: "2381.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "25253.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00501_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "Multifamily",
    business_type: "N/A",
    contact_url: "N/A",
    contact_website: "N/A",
    estimated_consumption: "24300 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "N/A",
    owner_type: "N/A",
    roof_area: "243.19 mÂ²",
    roof_type: "Flat roof",
    state_of_roof: "N/A",
    style_of_roof: "Dachbahn / Bitumen",
    type_of_solar_panel_if_already_installed: "N/A",
    year_of_roof_construction: "N/A",
  },
  {
    sl_no: "228.0",
    qrn: "610c1c5f41630e304eef613f",
    crn: "YBS-QT9-EML",
    utility_name: "Grunwelt Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 14",
    addr1: "Kageneckstraße 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98073",
    long: "7.95778",
    annul_electric_bill: "4800.0",
    current_annual_consumption: "21672.0",
    unit_electricity_bill_rate: "0.221448062",
    bill_type: "annual",
    proposed_system_size_dc: "24.05",
    proposed_system_size_ac: "22.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22122.0",
    production_ratio: "920.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "22.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "74.0",
    new_annual_electric_bill: "1326.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "25253.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00500_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "Multifamily",
    business_type: "N/A",
    contact_url: "N/A",
    contact_website: "N/A",
    estimated_consumption: "21700 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "N/A",
    owner_type: "N/A",
    roof_area: "217.42 mÂ²",
    roof_type: "Flat roof",
    state_of_roof: "N/A",
    style_of_roof: "Dachbahn / Bitumen",
    type_of_solar_panel_if_already_installed: "N/A",
    year_of_roof_construction: "N/A",
  },
  {
    sl_no: "229.0",
    qrn: "610c1ad76a2c2a7aedcc6013",
    crn: "0S2-H22-CRY",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 16",
    addr1: "Kageneckstraße 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98074294",
    long: "7.957478775",
    annul_electric_bill: "5500.0",
    current_annual_consumption: "16080.0",
    unit_electricity_bill_rate: "0.342113433",
    bill_type: "annual",
    proposed_system_size_dc: "14.625",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13441.0",
    production_ratio: "920.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "1817.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15362.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00499_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "Multifamily",
    business_type: "N/A",
    contact_url: "N/A",
    contact_website: "N/A",
    estimated_consumption: "16000 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "N/A",
    owner_type: "N/A",
    roof_area: "160.99 mÂ² ",
    roof_type: "Flat roof",
    state_of_roof: "N/A",
    style_of_roof: "Dachbahn / Bitumen",
    type_of_solar_panel_if_already_installed: "N/A",
    year_of_roof_construction: "N/A",
  },
  {
    sl_no: "230.0",
    qrn: "610c190b41630e304eef612d",
    crn: "X4H-8Q5-46M",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 18",
    addr1: "Kageneckstraße 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98079",
    long: "7.95731",
    annul_electric_bill: "6000.0",
    current_annual_consumption: "17604.0",
    unit_electricity_bill_rate: "0.340917587",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11944.0",
    production_ratio: "919.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "2331.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "62.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13650.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00498_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "Multifamily",
    business_type: "N/A",
    contact_url: "N/A",
    contact_website: "N/A",
    estimated_consumption: "17000 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "",
    owner_type: "N/A",
    roof_area: "170.23 mÂ²",
    roof_type: "Flat roof",
    state_of_roof: "N/A",
    style_of_roof: "Dachbahn / Bitumen",
    type_of_solar_panel_if_already_installed: "N/A",
    year_of_roof_construction: "N/A",
  },
  {
    sl_no: "231.0",
    qrn: "6108dee3e228ce427178be98",
    crn: "TWJ-T78-09K",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 2",
    addr1: "Kageneckstraße 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9811845",
    long: "7.9588691",
    annul_electric_bill: "4050.0",
    current_annual_consumption: "11664.0",
    unit_electricity_bill_rate: "0.34734321",
    bill_type: "annual",
    proposed_system_size_dc: "22.1",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19910.0",
    production_ratio: "901.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "68.0",
    new_annual_electric_bill: "769.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "27846.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00489_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "11,300 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "1.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: " 226.68 mÂ²",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "232.0",
    qrn: "6108f423ad29685ecdb8fce6",
    crn: "62G-NTF-OQR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 20",
    addr1: "Kageneckstraße 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98097",
    long: "7.95711",
    annul_electric_bill: "4450.0",
    current_annual_consumption: "12876.0",
    unit_electricity_bill_rate: "0.345550699",
    bill_type: "annual",
    proposed_system_size_dc: "5.85",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5267.0",
    production_ratio: "901.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "18.0",
    new_annual_electric_bill: "2721.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "39.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "7371.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00496_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "12900 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "1.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "258.85 mÂ²",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "233.0",
    qrn: "6108f1b1ad29685ecdb8fcdd",
    crn: "0JK-1VN-MJ7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 22",
    addr1: "Kageneckstraße 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98102",
    long: "7.95701",
    annul_electric_bill: "3400.0",
    current_annual_consumption: "9684.0",
    unit_electricity_bill_rate: "0.351236803",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14351.0",
    production_ratio: "902.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 4.2",
    total_inverter_capacity_2: "4.2",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "4.2",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "786.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00495_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "9650 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "1.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "193.44 mÂ²",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "234.0",
    qrn: "6108e8923fddc1652f421fcd",
    crn: "OH9-S8A-NB2",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 26",
    addr1: "Kageneckstraße 26",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98135",
    long: "7.95696",
    annul_electric_bill: "7200.0",
    current_annual_consumption: "21252.0",
    unit_electricity_bill_rate: "0.338751722",
    bill_type: "annual",
    proposed_system_size_dc: "18.85",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16983.0",
    production_ratio: "901.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "58.0",
    new_annual_electric_bill: "2415.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "23751.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00493_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "21300 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "213.25 mÂ²",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "235.0",
    qrn: "6108e0c5ad29685ecdb8fc9a",
    crn: "CJH-HI2-T9K",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 4",
    addr1: "Kageneckstraße 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9810437",
    long: "7.9587875",
    annul_electric_bill: "7000.0",
    current_annual_consumption: "20640.0",
    unit_electricity_bill_rate: "0.339061628",
    bill_type: "annual",
    proposed_system_size_dc: "23.075",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20786.0",
    production_ratio: "901.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "71.0",
    new_annual_electric_bill: "1944.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29068.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00490_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "20600 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "206.44 mÂ²",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "236.0",
    qrn: "6108e232e228ce427178beb1",
    crn: "6M5-B8E-X54",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 6",
    addr1: "Kageneckstraße 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.980893",
    long: "7.9587359",
    annul_electric_bill: "7300.0",
    current_annual_consumption: "21564.0",
    unit_electricity_bill_rate: "0.338600501",
    bill_type: "annual",
    proposed_system_size_dc: "28.275",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "25474.0",
    production_ratio: "901.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "87.0",
    new_annual_electric_bill: "1822.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "35620.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00491_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "21700 KWh",
    is_solar_panel_already_installed: "No",
    number_of_floors: "2.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "217.76 mÂ²",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "237.0",
    qrn: "60ffd53e8a3044201b305f59",
    crn: "CXU-6V5-E8G",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kageneckstraße 8",
    addr1: "Kageneckstraße 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806",
    long: "7.95855",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "44.85",
    proposed_system_size_ac: "42.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "33237.0",
    production_ratio: "742.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "42.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "138.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "56511.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00452_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "238.0",
    qrn: "6108d5a33fddc1652f421f6a",
    crn: "F5S-JJI-JOK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Kaiser & Kaiser Steuerberater",
    addr1: "Weilerstraße 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98144987",
    long: "7.963188569",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "55.9",
    proposed_system_size_ac: "50.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "45658.0",
    production_ratio: "817.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "50.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "172.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "70434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00488_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "239.0",
    qrn: "60ffe5798bf23f04c02c24c0",
    crn: "1CW-LKU-7E9",
    utility_name: "Prio Energie",
    rate_name: "Other",
    customer_name: "Kernzeit St. Peter eV",
    addr1: "Stockacker 84",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9795335",
    long: "7.9655286",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "12631.57895",
    unit_electricity_bill_rate: "0.19",
    bill_type: "annual",
    proposed_system_size_dc: "13.325",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8788.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "41.0",
    new_annual_electric_bill: "731.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "N",
    total_system_cost_: "16783.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00395_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "Kernzeit St. Peter eV",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "240.0",
    qrn: "61004bdae1e1df7221e1b522",
    crn: "HO0-SRL-LLS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "L133 5",
    addr1: "L133 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9843688",
    long: "7.9615459",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "20.475",
    proposed_system_size_ac: "18.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17798.0",
    production_ratio: "870.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "63.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "25805.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00178_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "242.0",
    qrn: "61002d938bf23f04c02c26cd",
    crn: "6K2-OCS-41L",
    utility_name: "Spalte2",
    rate_name: "Commercial",
    customer_name: "Mercado, spanische Spezialitä.ten",
    addr1: "Jägerstraße 2A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98251",
    long: "7.96403",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "8304.0",
    unit_electricity_bill_rate: "0.288867052",
    bill_type: "annual",
    proposed_system_size_dc: "29.9",
    proposed_system_size_ac: "27.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "26809.0",
    production_ratio: "897.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "Piko 10",
    total_inverter_capacity_2: "10.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "10.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "92.0",
    new_annual_electric_bill: "248.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "C",
    total_system_cost_: "31395.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00264_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "Mercado, spanische SpezialitÃ¤ten",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "243.0",
    qrn: "60ffec7ae1e1df7221e1b307",
    crn: "5UN-4JW-T4V",
    utility_name: "Spalte2",
    rate_name: "Commercial",
    customer_name: "NK SCHMUCKDESIGN NADJA ",
    addr1: "Hauptstraße 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9837152",
    long: "7.9644639",
    annul_electric_bill: "3370.8",
    current_annual_consumption: "12000.0",
    unit_electricity_bill_rate: "0.2809",
    bill_type: "annual",
    proposed_system_size_dc: "32.5",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27734.0",
    production_ratio: "854.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "100.0",
    new_annual_electric_bill: "453.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "C",
    total_system_cost_: "40950.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Commercial",
    combined_img: "SOLARHUB00371_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "XYZ",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "244.0",
    qrn: "60fffb288a3044201b3060c0",
    crn: "LNO-5PH-V4W",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 1",
    addr1: "Oberbirken 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97896",
    long: "7.97109",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.8",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16942.0",
    production_ratio: "815.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "64.0",
    new_annual_electric_bill: "387.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "26208.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00320_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "245.0",
    qrn: "60fff3d48a3044201b30607c",
    crn: "12S-QRW-DPB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 10",
    addr1: "Oberbirken 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9800162",
    long: "7.97435",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.7",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7113.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "752.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10962.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00340_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "246.0",
    qrn: "61000a048bf23f04c02c25ed",
    crn: "F72-FN1-AKR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 11",
    addr1: "Oberbirken 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979955",
    long: "7.9729924",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "49.4",
    proposed_system_size_ac: "49.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "38190.0",
    production_ratio: "774.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "7.0",
    total_inverter_capacity_1: "49.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "152.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "62244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00249_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "247.0",
    qrn: "60fff3958bf23f04c02c254a",
    crn: "EJ6-U7J-ORD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 12",
    addr1: "Oberbirken 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9801002",
    long: "7.9745412",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10563.0",
    production_ratio: "813.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "571.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "16380.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00341_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "248.0",
    qrn: "61000a468bf23f04c02c25f1",
    crn: "V9T-DU3-MG9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 13",
    addr1: "Oberbirken 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9800817",
    long: "7.9734012",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12993.0",
    production_ratio: "833.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "454.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00248_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "249.0",
    qrn: "60fff34de1e1df7221e1b327",
    crn: "I1N-NIY-PC7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 14",
    addr1: "Oberbirken 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.980159",
    long: "7.9747313",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.025",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9072.0",
    production_ratio: "755.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "37.0",
    new_annual_electric_bill: "677.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00342_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "250.0",
    qrn: "61000a978a3044201b306125",
    crn: "041-BAM-GSV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 15",
    addr1: "Oberbirken 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9801334",
    long: "7.973664",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8136.0",
    production_ratio: "808.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "699.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00247_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "251.0",
    qrn: "60fff3078a3044201b306071",
    crn: "IQT-C5K-AIN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 16",
    addr1: "Oberbirken 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98013022",
    long: "7.974850004",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8033.0",
    production_ratio: "824.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "700.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00343_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "252.0",
    qrn: "61000ad78a3044201b306128",
    crn: "702-P3I-N4B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 17",
    addr1: "Oberbirken 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802191",
    long: "7.9739524",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13183.0",
    production_ratio: "846.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "442.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00246_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "253.0",
    qrn: "60fff2c98bf23f04c02c253c",
    crn: "6HH-5VC-H1G",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 18",
    addr1: "Oberbirken 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802064",
    long: "7.9750187",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11613.0",
    production_ratio: "831.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "521.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00344_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "254.0",
    qrn: "61000b15e1e1df7221e1b3e6",
    crn: "D3G-7XH-0JK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 19",
    addr1: "Oberbirken 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802725",
    long: "7.9743025",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "30.55",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24228.0",
    production_ratio: "794.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "94.0",
    new_annual_electric_bill: "250.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "38493.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00245_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "255.0",
    qrn: "61000b968a3044201b306130",
    crn: "HF8-G8N-3TO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 19A",
    addr1: "Oberbirken 19A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9803004",
    long: "7.9744827",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8333.0",
    production_ratio: "802.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "703.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00244_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "256.0",
    qrn: "60fff2818bf23f04c02c2538",
    crn: "L6F-8GJ-COR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 20",
    addr1: "Oberbirken 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98019",
    long: "7.97518",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.1",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6498.0",
    production_ratio: "715.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "850.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "65.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11466.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00345_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "257.0",
    qrn: "61000b558bf23f04c02c2601",
    crn: "YOC-9E0-61A",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 21",
    addr1: "Oberbirken 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9803777",
    long: "7.9747199",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.025",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11459.0",
    production_ratio: "953.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "37.0",
    new_annual_electric_bill: "472.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00243_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "258.0",
    qrn: "60fff2468bf23f04c02c2535",
    crn: "Y35-24B-I89",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 22",
    addr1: "Oberbirken 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9804042",
    long: "7.9751863",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "22.75",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18444.0",
    production_ratio: "811.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "70.0",
    new_annual_electric_bill: "351.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "28665.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00346_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "259.0",
    qrn: "60fffade8a3044201b3060bd",
    crn: "45E-PPH-7A7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 3",
    addr1: "Oberbirken 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9789983",
    long: "7.9713588",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8322.0",
    production_ratio: "827.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "687.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00321_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "260.0",
    qrn: "60fff1628a3044201b306059",
    crn: "VTF-MO8-EL3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 4C",
    addr1: "Oberbirken 4C",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97954",
    long: "7.97293",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "27.625",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21116.0",
    production_ratio: "765.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "85.0",
    new_annual_electric_bill: "290.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "88.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "34814.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00349_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "261.0",
    qrn: "60fff1a78a3044201b30605d",
    crn: "DYW-R90-LMT",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 4D",
    addr1: "Oberbirken 4D",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9794947",
    long: "7.9725872",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7610.0",
    production_ratio: "710.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "775.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00348_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "262.0",
    qrn: "60ffef4e8bf23f04c02c2527",
    crn: "93E-E8U-SK3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 4E",
    addr1: "Oberbirken 4E",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9797492",
    long: "7.9731457",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.6",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12527.0",
    production_ratio: "804.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "48.0",
    new_annual_electric_bill: "493.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19656.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00350_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "263.0",
    qrn: "60ffd35d8bf23f04c02c241b",
    crn: "45O-4F5-7VP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 5",
    addr1: "Oberbirken 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9791134",
    long: "7.9715102",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6062.0",
    production_ratio: "718.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "893.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "63.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00466_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "264.0",
    qrn: "60fffa868bf23f04c02c2583",
    crn: "5KY-WN0-C5I",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 7A",
    addr1: "Oberbirken 7A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979354",
    long: "7.97189",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10841.0",
    production_ratio: "927.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "512.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00322_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "265.0",
    qrn: "60ffee9d8bf23f04c02c2524",
    crn: "IQ8-YL8-KL3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 8",
    addr1: "Oberbirken 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979882",
    long: "7.9737617",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10090.0",
    production_ratio: "777.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "612.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "16380.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00352_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "266.0",
    qrn: "610009be8a3044201b30611b",
    crn: "IIF-F44-H7J",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberbirken 9",
    addr1: "Oberbirken 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9796997",
    long: "7.9724658",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11192.0",
    production_ratio: "801.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "551.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00251_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "267.0",
    qrn: "6100da49e1e1df7221e1b6d3",
    crn: "2SJ-IOT-76S",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 1",
    addr1: "Oberleien 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9801181",
    long: "7.9587789",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "28.6",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24094.0",
    production_ratio: "843.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "88.0",
    new_annual_electric_bill: "227.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "36036.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00081_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "268.0",
    qrn: "60ffd2bbe1e1df7221e1b1f1",
    crn: "X7Q-1SD-TI0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 10",
    addr1: "Oberleien 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97984397",
    long: "7.959780738",
    annul_electric_bill: "4949.64",
    current_annual_consumption: "14400.0",
    unit_electricity_bill_rate: "0.343725",
    bill_type: "annual",
    proposed_system_size_dc: "31.85",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24322.0",
    production_ratio: "764.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "98.0",
    new_annual_electric_bill: "974.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "40131.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00004_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "210 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "269.0",
    qrn: "6100d8bce1e1df7221e1b6bb",
    crn: "UWR-0XU-JLV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 10",
    addr1: "Oberleien 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9798513",
    long: "7.9598069",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "27.95",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21759.0",
    production_ratio: "779.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "86.0",
    new_annual_electric_bill: "266.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "35217.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00088_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "270.0",
    qrn: "6100d99de1e1df7221e1b6c8",
    crn: "BCE-S10-8IH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 2",
    addr1: "Oberleien 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9798205",
    long: "7.9585124",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "30.875",
    proposed_system_size_ac: "28.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24067.0",
    production_ratio: "780.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "95.0",
    new_annual_electric_bill: "245.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "38909.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00084_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "271.0",
    qrn: "6100da0c8bf23f04c02c28d5",
    crn: "8D7-P7L-JHJ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 3",
    addr1: "Oberleien 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98016",
    long: "7.95921",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "29.575",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27129.0",
    production_ratio: "918.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "91.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "37258.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00082_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "272.0",
    qrn: "6100d962e1e1df7221e1b6c5",
    crn: "7I4-YER-P75",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 4",
    addr1: "Oberleien 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97979009",
    long: "7.958648184",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "25.35",
    proposed_system_size_ac: "24.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20170.0",
    production_ratio: "796.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 7.0",
    total_inverter_capacity_2: "7.0",
    inverter_quantity_2: "2.0",
    total_inverter_capacity_22: "14.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "78.0",
    new_annual_electric_bill: "289.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "88.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "31941.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00085_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "273.0",
    qrn: "6100d9d2e1e1df7221e1b6cc",
    crn: "LHB-UG0-I98",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 5",
    addr1: "Oberleien 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9802",
    long: "7.95968",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "27.3",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21431.0",
    production_ratio: "786.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "84.0",
    new_annual_electric_bill: "269.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "34398.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00083_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "274.0",
    qrn: "6100d92f8bf23f04c02c28cf",
    crn: "KL1-01F-DF1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 6",
    addr1: "Oberleien 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9798333",
    long: "7.9590782",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "29.9",
    proposed_system_size_ac: "28.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27604.0",
    production_ratio: "924.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "92.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "37674.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00086_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "275.0",
    qrn: "6100d8f2e1e1df7221e1b6c1",
    crn: "AA8-KFA-NJP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Oberleien 8",
    addr1: "Oberleien 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9798278",
    long: "7.9594323",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "24.9",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19724.0",
    production_ratio: "793.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "83.0",
    new_annual_electric_bill: "297.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "88.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "31374.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00087_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "276.0",
    qrn: "61004d528bf23f04c02c2755",
    crn: "UJE-I5Q-RQL",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reckenweg 2",
    addr1: "Reckenweg 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98589",
    long: "7.97233",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "21.125",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20489.0",
    production_ratio: "970.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "65.0",
    new_annual_electric_bill: "239.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "26624.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00157_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "277.0",
    qrn: "61004fd2e1e1df7221e1b536",
    crn: "RVG-105-VKB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reckenweg 3",
    addr1: "Reckenweg 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9857548",
    long: "7.972769",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "18.2",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17357.0",
    production_ratio: "954.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 7.0",
    total_inverter_capacity_2: "7.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "7.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "56.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "22932.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00144_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "278.0",
    qrn: "6100620e8bf23f04c02c27c0",
    crn: "XPP-8JR-1FI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reckenweg 4",
    addr1: "Reckenweg 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9862099",
    long: "7.9744619",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "25.35",
    proposed_system_size_ac: "24.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20894.0",
    production_ratio: "825.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 4.2",
    total_inverter_capacity_2: "4.2",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "4.2",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "78.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "31941.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00072_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "279.0",
    qrn: "61004dc0e1e1df7221e1b52f",
    crn: "FSQ-ANK-0GE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reckenweg 4A ",
    addr1: "Reckenweg 4A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9859921",
    long: "7.974538",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "29.9",
    proposed_system_size_ac: "28.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24938.0",
    production_ratio: "835.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "92.0",
    new_annual_electric_bill: "246.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "37674.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00150_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "280.0",
    qrn: "61004e4c8bf23f04c02c275b",
    crn: "62S-RVQ-6YJ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reckenweg 5",
    addr1: "Reckenweg 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9856931",
    long: "7.9745196",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6361.0",
    production_ratio: "851.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "493.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00147_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "281.0",
    qrn: "610064498a3044201b3062e0",
    crn: "LK3-TAI-64W",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reckenweg 5A",
    addr1: "Reckenweg 5A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9858503",
    long: "7.9741502",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "23.075",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18807.0",
    production_ratio: "816.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "71.0",
    new_annual_electric_bill: "229.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29068.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00073_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "282.0",
    qrn: "61004ec18bf23f04c02c275e",
    crn: "NJ1-L9O-081",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reckenweg 6A",
    addr1: "Reckenweg 6A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9842707",
    long: "7.9749574",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "33.8",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27604.0",
    production_ratio: "817.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "104.0",
    new_annual_electric_bill: "229.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "42588.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00145_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "283.0",
    qrn: "61002c898a3044201b306204",
    crn: "1WI-QG3-YQL",
    utility_name: "Spalte2",
    rate_name: "Commercial",
    customer_name: "Reiß Beck GmbH & Co. KG",
    addr1: "Jägerstraße 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9823",
    long: "7.96408",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "8304.0",
    unit_electricity_bill_rate: "0.288867052",
    bill_type: "annual",
    proposed_system_size_dc: "16.25",
    proposed_system_size_ac: "16.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15643.0",
    production_ratio: "963.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "16.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "50.0",
    new_annual_electric_bill: "415.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "C",
    total_system_cost_: "17063.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00265_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "ReiÃŸ Beck GmbH & Co. KG",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "284.0",
    qrn: "61005ff0e1e1df7221e1b57a",
    crn: "VMV-E0Q-AVM",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reichlegasse 1",
    addr1: "Reichlegasse 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9850428",
    long: "7.9717684",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "18.2",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16450.0",
    production_ratio: "904.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 7.0",
    total_inverter_capacity_2: "7.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "7.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "56.0",
    new_annual_electric_bill: "226.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "19110.0",
    unit_system_cost: "1.05",
    fit_type: "FIT_July_Commercial",
    combined_img: "SOLARHUB00090_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "285.0",
    qrn: "610063a98a3044201b3062db",
    crn: "VCY-3KS-25T",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reichlegasse 2",
    addr1: "Reichlegasse 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9850693",
    long: "7.9712622",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "17.225",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16423.0",
    production_ratio: "954.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "53.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21710.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00074_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "286.0",
    qrn: "6100632ce1e1df7221e1b58a",
    crn: "6JQ-V9J-QO4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reichlegasse 4",
    addr1: "Reichlegasse 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9845839",
    long: "7.9711491",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "64.025",
    proposed_system_size_ac: "58.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "51768.0",
    production_ratio: "809.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "50.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "197.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "80678.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00076_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "287.0",
    qrn: "6100628e8bf23f04c02c27c4",
    crn: "DJF-1WF-6HR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Reichlegasse 6",
    addr1: "Reichlegasse 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98426",
    long: "7.97125",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "47.125",
    proposed_system_size_ac: "43.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "37533.0",
    production_ratio: "797.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "40.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "145.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "59384.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00077_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "288.0",
    qrn: "6100e0ef8a3044201b30643c",
    crn: "XHF-107-MWG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 1",
    addr1: "Ringstraße 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98134",
    long: "7.96232",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "42.25",
    proposed_system_size_ac: "42.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "34529.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "42.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "130.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "53235.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00474_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "289.0",
    qrn: "6100d51be1e1df7221e1b698",
    crn: "FDR-47I-YEV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 10",
    addr1: "Ringstraße 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9807246",
    long: "7.9621502",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "29.9",
    proposed_system_size_ac: "28.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "23732.0",
    production_ratio: "794.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "92.0",
    new_annual_electric_bill: "239.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "37674.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00129_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "290.0",
    qrn: "6100d6bb8a3044201b3063ec",
    crn: "LR0-EPN-GG8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 11",
    addr1: "Ringstraße 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9809518",
    long: "7.9611874",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "29.25",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "23547.0",
    production_ratio: "806.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "90.0",
    new_annual_electric_bill: "238.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "36855.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00125_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "291.0",
    qrn: "6100d5598bf23f04c02c28af",
    crn: "QX9-C7O-ON3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 12",
    addr1: "Ringstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806705",
    long: "7.961874",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "25.025",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20166.0",
    production_ratio: "806.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "77.0",
    new_annual_electric_bill: "278.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "31525.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00128_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "292.0",
    qrn: "6100d73f8bf23f04c02c28bf",
    crn: "7BO-QQ7-VIX",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 13",
    addr1: "Ringstraße 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9809332",
    long: "7.960895",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "22.75",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18341.0",
    production_ratio: "807.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "70.0",
    new_annual_electric_bill: "323.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "28665.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00124_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "293.0",
    qrn: "6100d58f8bf23f04c02c28b2",
    crn: "MPV-KPM-YVY",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 14",
    addr1: "Ringstraße 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806993",
    long: "7.9614527",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "26.65",
    proposed_system_size_ac: "24.184",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "26416.0",
    production_ratio: "992.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "SolarEdge Technologies",
    inverter_id: "c9",
    inverter_model_1: "SE6000H-US [240V]",
    inverter_powerrating_1: "6.046",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "24.184",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "82.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "33579.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00010_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "294.0",
    qrn: "6100d7778bf23f04c02c28c3",
    crn: "63C-NLB-AQW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 15",
    addr1: "Ringstraße 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9809179",
    long: "7.9605557",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "37.375",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "31288.0",
    production_ratio: "838.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "115.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "47099.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00123_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "295.0",
    qrn: "6100d5c1e1e1df7221e1b69f",
    crn: "P50-O0N-2R4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 16",
    addr1: "Ringstraße 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806256",
    long: "7.9611196",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "30.225",
    proposed_system_size_ac: "28.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "29203.0",
    production_ratio: "967.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "93.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "38090.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00011_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "296.0",
    qrn: "6100dbc7e1e1df7221e1b6e1",
    crn: "MTD-NA7-WP3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 17",
    addr1: "Ringstraße 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9808",
    long: "7.95977",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8589.0",
    production_ratio: "801.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "677.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00065_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "297.0",
    qrn: "6100d5f7e1e1df7221e1b6a3",
    crn: "732-6C6-M77",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 18",
    addr1: "Ringstraße 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805896",
    long: "7.9607984",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "23.4",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22652.0",
    production_ratio: "969.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "72.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29484.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00012_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "298.0",
    qrn: "6100dbfae1e1df7221e1b6e5",
    crn: "NBJ-NNT-KW8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 19",
    addr1: "Ringstraße 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98080359",
    long: "7.959701604",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8814.0",
    production_ratio: "798.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "666.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00064_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "299.0",
    qrn: "6100e46ce1e1df7221e1b712",
    crn: "97L-KBS-7GB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 2",
    addr1: "Ringstraße 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98123",
    long: "7.96309",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "53.3",
    proposed_system_size_ac: "50.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "43487.0",
    production_ratio: "816.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "50.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "164.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "67158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00478_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "300.0",
    qrn: "6100dc308a3044201b306424",
    crn: "69G-Q52-SR0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 21",
    addr1: "Ringstraße 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98081",
    long: "7.95951",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.3",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11830.0",
    production_ratio: "828.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "44.0",
    new_annual_electric_bill: "501.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18018.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00063_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "301.0",
    qrn: "6100da818a3044201b30640d",
    crn: "M3C-JDM-P1H",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 22",
    addr1: "Ringstraße 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98052",
    long: "7.95987",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.575",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13582.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "51.0",
    new_annual_electric_bill: "437.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20878.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00071_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "302.0",
    qrn: "6100dc658a3044201b306427",
    crn: "E90-27H-G0P",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 23",
    addr1: "Ringstraße 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9808462",
    long: "7.9593101",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.625",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11569.0",
    production_ratio: "792.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "528.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00062_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "303.0",
    qrn: "6100dab88a3044201b306410",
    crn: "BXV-QSJ-693",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 24",
    addr1: "Ringstraße 24",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98054",
    long: "7.95966",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9102.0",
    production_ratio: "824.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "639.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00070_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "304.0",
    qrn: "6100daefe1e1df7221e1b6db",
    crn: "2KQ-DHP-BGO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 26",
    addr1: "Ringstraße 26",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98055",
    long: "7.95958",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.65",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11223.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "42.0",
    new_annual_electric_bill: "526.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17199.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00069_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "305.0",
    qrn: "6100db238a3044201b306416",
    crn: "I8V-9P1-VWW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 28",
    addr1: "Ringstraße 28",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98053",
    long: "7.95931",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12046.0",
    production_ratio: "789.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "514.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00068_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "306.0",
    qrn: "6100e1bb8bf23f04c02c2902",
    crn: "VTR-PNQ-48E",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 3",
    addr1: "Ringstraße 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9811855",
    long: "7.962306",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "27.3",
    proposed_system_size_ac: "25.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22384.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "25.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "91.0",
    new_annual_electric_bill: "274.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "34398.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00475_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "307.0",
    qrn: "6100db588a3044201b306419",
    crn: "KKM-K55-OM9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 30",
    addr1: "Ringstraße 30",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98055",
    long: "7.9592",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.225",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13976.0",
    production_ratio: "812.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "53.0",
    new_annual_electric_bill: "432.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21710.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00067_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "308.0",
    qrn: "6100db918bf23f04c02c28e2",
    crn: "D6R-IUQ-DIN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 32",
    addr1: "Ringstraße 32",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805768",
    long: "7.9590335",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9908.0",
    production_ratio: "847.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "585.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00066_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "309.0",
    qrn: "6100e5c48a3044201b306457",
    crn: "B5O-BW2-JC7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 4",
    addr1: "Ringstraße 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9809663",
    long: "7.9629383",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "55.9",
    proposed_system_size_ac: "50.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "46046.0",
    production_ratio: "824.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "50.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "172.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "70434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00479_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "310.0",
    qrn: "6100e28be1e1df7221e1b70a",
    crn: "N98-BXM-N36",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 5",
    addr1: "Ringstraße 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9809945",
    long: "7.9622523",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "34.775",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "28446.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "107.0",
    new_annual_electric_bill: "224.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "43810.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00476_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "311.0",
    qrn: "6100e6ea8a3044201b30645d",
    crn: "AUO-CDF-C45",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 6",
    addr1: "Ringstraße 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806783",
    long: "7.9628813",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "39.0",
    proposed_system_size_ac: "35.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "31593.0",
    production_ratio: "811.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "35.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "120.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "49140.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00480_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "312.0",
    qrn: "60ebee279eee013e34e64130",
    crn: "0EO-6F4-5EH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 7",
    addr1: "Ringstraße 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9809618",
    long: "7.9618841",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "23.075",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19144.0",
    production_ratio: "830.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "71.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "34612.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00127_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "313.0",
    qrn: "6100e7eae1e1df7221e1b71f",
    crn: "4SJ-B6A-BWR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 8",
    addr1: "Ringstraße 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98071372",
    long: "7.962600875",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "47.125",
    proposed_system_size_ac: "44.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "38706.0",
    production_ratio: "822.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "8.0",
    total_inverter_capacity_1: "44.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "145.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "59384.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00481_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "314.0",
    qrn: "6100d700e1e1df7221e1b6aa",
    crn: "W56-LII-D8H",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Ringstraße 9",
    addr1: "Ringstraße 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98097",
    long: "7.96155",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "28.925",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24224.0",
    production_ratio: "838.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "89.0",
    new_annual_electric_bill: "227.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "36452.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00126_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "315.0",
    qrn: "610018548bf23f04c02c267d",
    crn: "02G-Y4W-GVF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße",
    addr1: "Schauinslandstraße",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9801543",
    long: "7.9627351",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6833.0",
    production_ratio: "841.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "772.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00168_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "316.0",
    qrn: "6100d3c8e1e1df7221e1b68a",
    crn: "CO9-MRL-15O",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 10",
    addr1: "Schauinslandstraße 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.980413",
    long: "7.961658",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "24.7",
    proposed_system_size_ac: "24.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19579.0",
    production_ratio: "793.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "76.0",
    new_annual_electric_bill: "302.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "88.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "31122.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00137_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "317.0",
    qrn: "610016748a3044201b3061b4",
    crn: "X6K-RSJ-3F8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 11",
    addr1: "Schauinslandstraße 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97975768",
    long: "7.962283004",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.3",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4187.0",
    production_ratio: "665.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "21.0",
    new_annual_electric_bill: "1139.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "53.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "7938.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00173_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "318.0",
    qrn: "6100d4048a3044201b3063d5",
    crn: "RVT-J0J-6UV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 12",
    addr1: "Schauinslandstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9803944",
    long: "7.9613656",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.575",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13467.0",
    production_ratio: "813.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "51.0",
    new_annual_electric_bill: "445.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20878.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00134_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "319.0",
    qrn: "610016308bf23f04c02c266a",
    crn: "QSX-BUD-JSF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 13",
    addr1: "Schauinslandstraße 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97984",
    long: "7.96222",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "4.55",
    proposed_system_size_ac: "4.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3556.0",
    production_ratio: "782.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "14.0",
    new_annual_electric_bill: "1234.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "49.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "5733.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00174_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "320.0",
    qrn: "6100d43b8bf23f04c02c28a3",
    crn: "R5T-RL1-OGW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 14",
    addr1: "Schauinslandstraße 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98037005",
    long: "7.961036586",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4715.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "1072.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "56.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00133_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "321.0",
    qrn: "610015f18a3044201b3061b0",
    crn: "761-TMN-1G5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 15",
    addr1: "Schauinslandstraße 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97997",
    long: "7.962",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8295.0",
    production_ratio: "730.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "729.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00175_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "322.0",
    qrn: "6100d474e1e1df7221e1b692",
    crn: "3UM-F53-DGK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 16",
    addr1: "Schauinslandstraße 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98035181",
    long: "7.960727408",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5570.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "979.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "60.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00132_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "323.0",
    qrn: "610018968bf23f04c02c2684",
    crn: "YOI-FYL-W17",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 17",
    addr1: "Schauinslandstraße 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98018",
    long: "7.96241",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "30.875",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "23300.0",
    production_ratio: "755.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "95.0",
    new_annual_electric_bill: "258.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "38909.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00167_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "324.0",
    qrn: "6100d4ac8a3044201b3063dd",
    crn: "MQ8-IVP-IEJ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 18",
    addr1: "Schauinslandstraße 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98029853",
    long: "7.960397496",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.775",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5824.0",
    production_ratio: "664.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "27.0",
    new_annual_electric_bill: "952.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "61.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11063.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00131_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "325.0",
    qrn: "6100cf2f8bf23f04c02c2872",
    crn: "92B-7IE-E7J",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 19",
    addr1: "Schauinslandstraße 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98017",
    long: "7.96218",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9918.0",
    production_ratio: "848.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "584.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00166_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "326.0",
    qrn: "6100d2e4e1e1df7221e1b681",
    crn: "1C3-DPM-2AG",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 2",
    addr1: "Schauinslandstraße 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9804159",
    long: "7.9628397",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "18.2",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14722.0",
    production_ratio: "809.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "56.0",
    new_annual_electric_bill: "411.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "22932.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00141_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "327.0",
    qrn: "6100d4e38a3044201b3063e0",
    crn: "6JD-FLV-JIJ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 20",
    addr1: "Schauinslandstraße 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98025",
    long: "7.96001",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.775",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5792.0",
    production_ratio: "661.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "27.0",
    new_annual_electric_bill: "958.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "61.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11063.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00130_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "328.0",
    qrn: "6100cf7ae1e1df7221e1b661",
    crn: "TMT-MLA-43V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 21",
    addr1: "Schauinslandstraße 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98015",
    long: "7.9619001",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8631.0",
    production_ratio: "886.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "642.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00165_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "329.0",
    qrn: "6100cff38bf23f04c02c287a",
    crn: "B4J-SNJ-FC8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 23",
    addr1: "Schauinslandstraße 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98013",
    long: "7.9617",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.8",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5157.0",
    production_ratio: "662.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "24.0",
    new_annual_electric_bill: "1016.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "58.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9828.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00164_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "330.0",
    qrn: "6100d02d8a3044201b3063a6",
    crn: "R63-FFI-MHY",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 25",
    addr1: "Schauinslandstraße 25",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98009",
    long: "7.96142",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11328.0",
    production_ratio: "811.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "526.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00163_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "331.0",
    qrn: "610015ab8bf23f04c02c2660",
    crn: "7VY-MCT-V9S",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 27",
    addr1: "Schauinslandstraße 27",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97984",
    long: "7.96166",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.5",
    proposed_system_size_ac: "6.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5189.0",
    production_ratio: "799.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "6.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "20.0",
    new_annual_electric_bill: "942.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "61.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "8190.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00176_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "332.0",
    qrn: "610014fa8bf23f04c02c265a",
    crn: "ILU-E87-T61",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 29",
    addr1: "Schauinslandstraße 29",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97972",
    long: "7.9617",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9599.0",
    production_ratio: "953.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "562.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00177_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "333.0",
    qrn: "610018088bf23f04c02c2677",
    crn: "M1E-Q0W-7T7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 3",
    addr1: "Schauinslandstraße 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98005465",
    long: "7.962701472",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9419.0",
    production_ratio: "967.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "567.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00169_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "334.0",
    qrn: "6100153b8a3044201b3061aa",
    crn: "8VU-ADN-011",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 31",
    addr1: "Schauinslandstraße 31",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9796787",
    long: "7.961549796",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.85",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4390.0",
    production_ratio: "751.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "18.0",
    new_annual_electric_bill: "1067.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "56.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "7371.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00179_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "335.0",
    qrn: "610014bae1e1df7221e1b44d",
    crn: "L2H-LGY-G0W",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 33",
    addr1: "Schauinslandstraße 33",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97967564",
    long: "7.961372741",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.3",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11161.0",
    production_ratio: "781.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "44.0",
    new_annual_electric_bill: "554.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18018.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00180_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "336.0",
    qrn: "61001478e1e1df7221e1b447",
    crn: "HBI-6U8-52E",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 35",
    addr1: "Schauinslandstraße 35",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97974603",
    long: "7.961162333",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.475",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15455.0",
    production_ratio: "755.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "63.0",
    new_annual_electric_bill: "434.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "25805.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00181_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "337.0",
    qrn: "610014388a3044201b30619f",
    crn: "ASQ-R4G-ES6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 37",
    addr1: "Schauinslandstraße 37",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97980664",
    long: "7.96110793",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "2.925",
    proposed_system_size_ac: "3.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "2368.0",
    production_ratio: "810.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "3.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "9.0",
    new_annual_electric_bill: "1624.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "33.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "3679.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00182_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "338.0",
    qrn: "610013ece1e1df7221e1b440",
    crn: "E78-AFH-DI5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 39",
    addr1: "Schauinslandstraße 39",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9799",
    long: "7.96112",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.15",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5786.0",
    production_ratio: "810.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "22.0",
    new_annual_electric_bill: "870.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "64.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9009.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00184_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "339.0",
    qrn: "6100d31a8bf23f04c02c2896",
    crn: "9VL-CQ9-4GN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 4",
    addr1: "Schauinslandstraße 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98044463",
    long: "7.962595619",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13129.0",
    production_ratio: "825.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "451.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00140_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "340.0",
    qrn: "6100d0708a3044201b3063a8",
    crn: "Y7C-5UH-V0O",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 41",
    addr1: "Schauinslandstraße 41",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98013",
    long: "7.96115",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.525",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3939.0",
    production_ratio: "713.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "17.0",
    new_annual_electric_bill: "1156.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "52.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "6968.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00162_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "341.0",
    qrn: "6100cfbbe1e1df7221e1b665",
    crn: "FGP-K47-DHN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 43",
    addr1: "Schauinslandstraße 43",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98012282",
    long: "7.960948834",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.525",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4837.0",
    production_ratio: "876.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "17.0",
    new_annual_electric_bill: "952.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "61.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "6968.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00156_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "342.0",
    qrn: "6100d0b88a3044201b3063ad",
    crn: "909-IY4-L3N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 45",
    addr1: "Schauinslandstraße 45",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9801327",
    long: "7.9606645",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11489.0",
    production_ratio: "753.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "553.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00155_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "343.0",
    qrn: "6100d0ef8bf23f04c02c2881",
    crn: "Y8Q-QPJ-4P6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 47",
    addr1: "Schauinslandstraße 47",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98006242",
    long: "7.960393804",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.675",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9748.0",
    production_ratio: "770.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "39.0",
    new_annual_electric_bill: "632.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15977.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00154_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "344.0",
    qrn: "6100d1288a3044201b3063b5",
    crn: "2YW-1AU-H91",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 49",
    addr1: "Schauinslandstraße 49",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97975",
    long: "7.96064",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12479.0",
    production_ratio: "817.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "504.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00153_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "345.0",
    qrn: "610017c8e1e1df7221e1b461",
    crn: "064-SDB-Q9V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 5",
    addr1: "Schauinslandstraße 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9798907",
    long: "7.9627315",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.0",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8690.0",
    production_ratio: "966.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "611.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11340.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00170_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "346.0",
    qrn: "6100d162e1e1df7221e1b672",
    crn: "44I-XFQ-QKY",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 51",
    addr1: "Schauinslandstraße 51",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97967",
    long: "7.96075",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.775",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7197.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "27.0",
    new_annual_electric_bill: "756.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "69.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11063.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00152_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "347.0",
    qrn: "6100d1998a3044201b3063ba",
    crn: "4V9-3MH-FYD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 53",
    addr1: "Schauinslandstraße 53",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97956325",
    long: "7.960610483",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.7",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15621.0",
    production_ratio: "883.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "59.0",
    new_annual_electric_bill: "374.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "22302.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00151_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "348.0",
    qrn: "6100d20c8bf23f04c02c288d",
    crn: "RI0-SAC-WOW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 55",
    addr1: "Schauinslandstraße 55",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97955217",
    long: "7.960414928",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.575",
    proposed_system_size_ac: "15.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13581.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "15.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "51.0",
    new_annual_electric_bill: "443.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20878.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00149_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "349.0",
    qrn: "6100d242e1e1df7221e1b679",
    crn: "U2K-3P1-R2E",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 57",
    addr1: "Schauinslandstraße 57",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97963606",
    long: "7.960279945",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5203.0",
    production_ratio: "697.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "990.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "59.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00148_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "350.0",
    qrn: "6100d2778a3044201b3063c6",
    crn: "I6D-T5G-4F8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 59",
    addr1: "Schauinslandstraße 59",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9797228",
    long: "7.9602087",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.1",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7465.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "741.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11466.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00146_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "351.0",
    qrn: "6100d3538bf23f04c02c289b",
    crn: "YP9-LUT-MRD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 6",
    addr1: "Schauinslandstraße 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98044",
    long: "7.96225",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "22.425",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18117.0",
    production_ratio: "808.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "69.0",
    new_annual_electric_bill: "329.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "28262.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00139_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "352.0",
    qrn: "6100d1d08bf23f04c02c288a",
    crn: "HVS-0W2-X3R",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 61",
    addr1: "Schauinslandstraße 61",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97987928",
    long: "7.960164475",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9507.0",
    production_ratio: "813.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "643.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00143_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "353.0",
    qrn: "6100d2afe1e1df7221e1b67c",
    crn: "UDN-MGX-OYE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 63",
    addr1: "Schauinslandstraße 63",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97997175",
    long: "7.960159111",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.65",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11090.0",
    production_ratio: "813.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "42.0",
    new_annual_electric_bill: "559.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17199.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00142_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "354.0",
    qrn: "6100178a8a3044201b3061b9",
    crn: "147-D8M-JJI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 7",
    addr1: "Schauinslandstraße 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97981978",
    long: "7.962672491",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.2",
    proposed_system_size_ac: "4.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5036.0",
    production_ratio: "969.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "16.0",
    new_annual_electric_bill: "889.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "63.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "6552.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00171_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "355.0",
    qrn: "6100d38ae1e1df7221e1b687",
    crn: "YMI-XCU-W0X",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 8",
    addr1: "Schauinslandstraße 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9804316",
    long: "7.9619504",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11689.0",
    production_ratio: "766.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "535.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00138_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "356.0",
    qrn: "6100174de1e1df7221e1b45b",
    crn: "QRH-AC3-Y8V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schauinslandstraße 9",
    addr1: "Schauinslandstraße 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97976267",
    long: "7.962532595",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.9",
    proposed_system_size_ac: "16.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11152.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "16.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "52.0",
    new_annual_electric_bill: "636.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21294.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00172_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "357.0",
    qrn: "610011898a3044201b30617e",
    crn: "U2S-PQW-US5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 11",
    addr1: "Schulstraße 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9808093",
    long: "7.9724615",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "29.25",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "23938.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "90.0",
    new_annual_electric_bill: "233.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "36855.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00202_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "358.0",
    qrn: "60fff4168a3044201b306080",
    crn: "6F9-FMB-E22",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 12",
    addr1: "Schulstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98099335",
    long: "7.974230139",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.9",
    proposed_system_size_ac: "16.8",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13090.0",
    production_ratio: "775.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "16.8",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "52.0",
    new_annual_electric_bill: "484.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "21294.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00339_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "359.0",
    qrn: "6100113b8bf23f04c02c263f",
    crn: "2QW-UNJ-DCC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 13",
    addr1: "Schulstraße 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9807698",
    long: "7.9721927",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "19.175",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15771.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "59.0",
    new_annual_electric_bill: "380.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "24167.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00203_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "360.0",
    qrn: "60fff459e1e1df7221e1b334",
    crn: "2GF-27Q-FTM",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 14",
    addr1: "Schulstraße 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98105128",
    long: "7.974052474",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "19.825",
    proposed_system_size_ac: "18.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15344.0",
    production_ratio: "774.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "18.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "61.0",
    new_annual_electric_bill: "413.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "24973.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00338_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "361.0",
    qrn: "610011018a3044201b306176",
    crn: "INE-GPF-B0H",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 15",
    addr1: "Schulstraße 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.980728",
    long: "7.971777",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "37.375",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "29781.0",
    production_ratio: "797.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "115.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "47099.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00205_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "362.0",
    qrn: "610010708a3044201b30616e",
    crn: "BXH-9BS-HPT",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 15A",
    addr1: "Schulstraße 15A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9807536",
    long: "7.9713309",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.625",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11444.0",
    production_ratio: "783.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "536.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00209_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "363.0",
    qrn: "610007528a3044201b306104",
    crn: "IQQ-CUA-Y7B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 17",
    addr1: "Schulstraße 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805788",
    long: "7.9704636",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6766.0",
    production_ratio: "801.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "778.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00307_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "364.0",
    qrn: "6100070de1e1df7221e1b3be",
    crn: "VBB-GO0-2NW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 17A",
    addr1: "Schulstraße 17A",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98048",
    long: "7.97047",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.525",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3640.0",
    production_ratio: "659.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "17.0",
    new_annual_electric_bill: "1239.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "49.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "6968.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00308_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "365.0",
    qrn: "610006c28bf23f04c02c25cb",
    crn: "LLO-5HV-EWH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 19",
    addr1: "Schulstraße 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98022",
    long: "7.97048",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.925",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12985.0",
    production_ratio: "816.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "49.0",
    new_annual_electric_bill: "487.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20072.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00309_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "366.0",
    qrn: "61000bd98a3044201b306134",
    crn: "R1I-48L-F5C",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 1B",
    addr1: "Schulstraße 1B",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805222",
    long: "7.974513",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10671.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "556.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "16380.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00241_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "367.0",
    qrn: "61000d048bf23f04c02c260f",
    crn: "S3C-YHS-PGK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 1C",
    addr1: "Schulstraße 1C",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9805811",
    long: "7.9742579",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "38.025",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "30033.0",
    production_ratio: "790.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "117.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "47905.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00240_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "368.0",
    qrn: "60ffec648a3044201b306044",
    crn: "PY5-T4J-TUN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 20",
    addr1: "Schulstraße 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98109612",
    long: "7.973766095",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "22.75",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18060.0",
    production_ratio: "794.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "70.0",
    new_annual_electric_bill: "340.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "28665.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00337_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "369.0",
    qrn: "60fff498e1e1df7221e1b338",
    crn: "OPI-S8O-BJ0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 22",
    addr1: "Schulstraße 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98109521",
    long: "7.973738119",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.125",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6292.0",
    production_ratio: "775.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "25.0",
    new_annual_electric_bill: "829.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "66.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10244.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00336_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "370.0",
    qrn: "60fff4e28bf23f04c02c255d",
    crn: "Y8P-5PD-U8T",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 24",
    addr1: "Schulstraße 24",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9811588",
    long: "7.9733742",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8521.0",
    production_ratio: "795.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "683.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00335_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "371.0",
    qrn: "60fff5298a3044201b30608b",
    crn: "OYD-JL7-KUP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 26",
    addr1: "Schulstraße 26",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9811967",
    long: "7.9730129",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "33.475",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27536.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "103.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "42185.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00334_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "372.0",
    qrn: "61000d45e1e1df7221e1b3f0",
    crn: "UPV-47N-LPJ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 3",
    addr1: "Schulstraße 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9806587",
    long: "7.973789",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10626.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "582.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "16380.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00239_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "373.0",
    qrn: "6100139fe1e1df7221e1b43b",
    crn: "VEN-9IJ-P3G",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 5",
    addr1: "Schulstraße 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9807197",
    long: "7.9734711",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "22.75",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18630.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c5",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "70.0",
    new_annual_electric_bill: "347.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "28665.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00193_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "374.0",
    qrn: "6100120ae1e1df7221e1b427",
    crn: "0RW-O3T-MF2",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 7",
    addr1: "Schulstraße 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9808157",
    long: "7.9732109",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11439.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "518.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00200_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "375.0",
    qrn: "610011ce8a3044201b306181",
    crn: "QX4-POQ-DY0",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Schulstraße 9",
    addr1: "Schulstraße 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.980875",
    long: "7.9727877",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.65",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22366.0",
    production_ratio: "840.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "82.0",
    new_annual_electric_bill: "241.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "33579.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00201_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "376.0",
    qrn: "610044038a3044201b306245",
    crn: "0OP-A62-M6G",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Staatl. Gehörlosenschule 12",
    addr1: "Staatl. Gehörlosenschule 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9840696",
    long: "7.9554834",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "37.7",
    proposed_system_size_ac: "35.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "34870.0",
    production_ratio: "925.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "35.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "116.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "47502.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00219_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "377.0",
    qrn: "610046798a3044201b306258",
    crn: "NMJ-WNX-I09",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Staatl. Gehörlosenschule 13",
    addr1: "Staatl. Gehörlosenschule 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98501",
    long: "7.95789",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "39.975",
    proposed_system_size_ac: "38.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "36947.0",
    production_ratio: "925.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "7.0",
    total_inverter_capacity_1: "38.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "123.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "50375.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00211_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "378.0",
    qrn: "610044648bf23f04c02c271c",
    crn: "JON-YHC-P2C",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Staatl. Gehörlosenschule 14",
    addr1: "Staatl. Gehörlosenschule 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98413",
    long: "7.95578",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "39.0",
    proposed_system_size_ac: "35.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "36085.0",
    production_ratio: "926.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "Piko 10",
    total_inverter_capacity_2: "10.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "10.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "120.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "49140.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00218_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "379.0",
    qrn: "610044bc8bf23f04c02c271f",
    crn: "PHG-XRB-XXK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Staatl. Gehörlosenschule 15",
    addr1: "Staatl. Gehörlosenschule 15",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98417",
    long: "7.9561",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "37.05",
    proposed_system_size_ac: "34.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "35003.0",
    production_ratio: "945.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "34.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "114.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "46683.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00217_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "380.0",
    qrn: "610045478a3044201b30624c",
    crn: "5VR-J4U-4IK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Staatl. Gehörlosenschule 16",
    addr1: "Staatl. Gehörlosenschule 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98427",
    long: "7.95654",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "78.65",
    proposed_system_size_ac: "70.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "72802.0",
    production_ratio: "926.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "7.0",
    total_inverter_capacity_1: "70.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "242.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "99099.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00216_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "381.0",
    qrn: "610045dae1e1df7221e1b500",
    crn: "QQE-6PX-0G1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Staatl. Gehörlosenschule 17",
    addr1: "Staatl. Gehörlosenschule 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9845256",
    long: "7.956143",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "75.725",
    proposed_system_size_ac: "70.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "70046.0",
    production_ratio: "926.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "7.0",
    total_inverter_capacity_1: "70.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "233.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "95407.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00215_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "383.0",
    qrn: "6100ebec8a3044201b306472",
    crn: "3IN-2OL-CTV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 1",
    addr1: "Stockacker 1",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98036629",
    long: "7.96424243",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.55",
    proposed_system_size_ac: "16.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "124847.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "16.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "54.0",
    new_annual_electric_bill: "445.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "22113.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00485_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "384.0",
    qrn: "60ffe8388a3044201b306014",
    crn: "32A-2SE-RL4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 10",
    addr1: "Stockacker 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97959",
    long: "7.96335",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14378.0",
    production_ratio: "824.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "590.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00378_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "385.0",
    qrn: "60ffe7fa8a3044201b306012",
    crn: "PJH-ELP-XMC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 12",
    addr1: "Stockacker 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97953983",
    long: "7.963484763",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "18.525",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10171.0",
    production_ratio: "871.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "57.0",
    new_annual_electric_bill: "351.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "23335.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00380_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "386.0",
    qrn: "60ffe3908bf23f04c02c24b0",
    crn: "TV6-VDO-FE6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 13",
    addr1: "Stockacker 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98051",
    long: "7.9663",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.625",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16134.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "523.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00405_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "387.0",
    qrn: "60ffe7bde1e1df7221e1b2c2",
    crn: "4CX-L25-IUD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 14",
    addr1: "Stockacker 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97961047",
    long: "7.963788056",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11973.0",
    production_ratio: "661.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "899.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "63.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00381_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "388.0",
    qrn: "60ffe7818a3044201b30600c",
    crn: "8WW-AQP-KC1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 16",
    addr1: "Stockacker 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97955302",
    long: "7.963934236",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6436.0",
    production_ratio: "884.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "658.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00382_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "389.0",
    qrn: "60ffe2b98a3044201b305fcc",
    crn: "SJJ-0L9-YUD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 17",
    addr1: "Stockacker 17",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98038",
    long: "7.96612",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8330.0",
    production_ratio: "873.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "722.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00413_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "390.0",
    qrn: "60ffe7408a3044201b306004",
    crn: "0IJ-M3E-6I1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 18",
    addr1: "Stockacker 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97953858",
    long: "7.964140525",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7373.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "847.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "65.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00384_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "391.0",
    qrn: "60ffded2e1e1df7221e1b25f",
    crn: "73U-XFX-9KV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 19",
    addr1: "Stockaker 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98034",
    long: "7.96601",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7076.0",
    production_ratio: "757.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "696.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00414_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "392.0",
    qrn: "60ffe92a8bf23f04c02c24f3",
    crn: "4FY-CBH-NKD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 2",
    addr1: "Stockacker 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9800521",
    long: "7.9633287",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8603.0",
    production_ratio: "826.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "539.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00374_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "393.0",
    qrn: "60ffe7058bf23f04c02c24dd",
    crn: "UB5-ELQ-QFC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 20",
    addr1: "Stockacker 20",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9795411",
    long: "7.9642646",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.5",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11530.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "735.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17010.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00385_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "394.0",
    qrn: "60ffe002e1e1df7221e1b268",
    crn: "UWN-616-RHS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 21",
    addr1: "Stockacker 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98043219",
    long: "7.965731185",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.975",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8907.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "83.0",
    new_annual_electric_bill: "280.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "89.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "33995.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00415_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "395.0",
    qrn: "60ffdd158a3044201b305f99",
    crn: "N2W-CEJ-GTH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 22",
    addr1: "Stockacker 22",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98001231",
    long: "7.963694792",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.65",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "22111.0",
    production_ratio: "793.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "42.0",
    new_annual_electric_bill: "563.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17199.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00427_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "396.0",
    qrn: "60ffdf628a3044201b305fb6",
    crn: "YWS-73N-PCV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 23",
    addr1: "Stockacker 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9801938",
    long: "7.966029581",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10811.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "862.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "65.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00417_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "397.0",
    qrn: "60ffdf218bf23f04c02c248f",
    crn: "BMH-E7I-ULF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 25",
    addr1: "Stockacker 25",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9801601",
    long: "7.96636",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "24.375",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6855.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "75.0",
    new_annual_electric_bill: "312.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "30719.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00418_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "398.0",
    qrn: "60ffdcca8bf23f04c02c2478",
    crn: "KQW-XP9-IWR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 26",
    addr1: "Stockacker 26",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9797902",
    long: "7.9638214",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.95",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "19978.0",
    production_ratio: "814.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "46.0",
    new_annual_electric_bill: "493.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18837.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00429_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "399.0",
    qrn: "60ffdc898bf23f04c02c2472",
    crn: "SPB-VJO-739",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 28",
    addr1: "Stockacker 28",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979799",
    long: "7.964118787",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12166.0",
    production_ratio: "661.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "899.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "63.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00431_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "400.0",
    qrn: "6100ed6ce1e1df7221e1b738",
    crn: "CTW-J8O-0QC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 3",
    addr1: "Stockacker 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98037",
    long: "7.9647",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "58.5",
    proposed_system_size_ac: "56.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6436.0",
    production_ratio: "810.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "8.0",
    total_inverter_capacity_1: "56.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "180.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "73710.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00486_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "401.0",
    qrn: "60ffdc068bf23f04c02c246a",
    crn: "71T-8VI-V1X",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 30",
    addr1: "Stockacker 30",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97981092",
    long: "7.964362009",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "26.325",
    proposed_system_size_ac: "25.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "47329.0",
    production_ratio: "713.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "81.0",
    new_annual_electric_bill: "366.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "85.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "33163.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00432_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "402.0",
    qrn: "60ffdb858bf23f04c02c2461",
    crn: "I0Y-KRV-GMQ",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 32",
    addr1: "Stockacker 32",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98008764",
    long: "7.964271832",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18750.0",
    production_ratio: "866.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "565.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "77.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00436_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "403.0",
    qrn: "60ffdb12e1e1df7221e1b230",
    crn: "3LO-2P7-UTX",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 34",
    addr1: "Stockacker 34",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98004233",
    long: "7.964425679",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10122.0",
    production_ratio: "788.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "716.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00437_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "404.0",
    qrn: "60ffdac4e1e1df7221e1b22e",
    crn: "PU7-QWE-7CS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 36",
    addr1: "Stockacker 36",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9800913",
    long: "7.9645775",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7937.0",
    production_ratio: "748.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "705.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00438_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "405.0",
    qrn: "60ffda808a3044201b305f73",
    crn: "5PF-SHB-ABE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 38",
    addr1: "Stockacker 38",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98007833",
    long: "7.964700324",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.625",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8500.0",
    production_ratio: "790.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "528.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00439_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "406.0",
    qrn: "60ffe8ef8bf23f04c02c24ef",
    crn: "QPI-BCT-X3L",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 4",
    addr1: "Stockacker 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9799686",
    long: "7.9633222",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.65",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11545.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "42.0",
    new_annual_electric_bill: "550.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17199.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00375_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "407.0",
    qrn: "60ffd1fc8bf23f04c02c240f",
    crn: "421-D91-YNU",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 40",
    addr1: "Stockacker 40",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9798472",
    long: "7.9646915",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11198.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "441.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00435_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "408.0",
    qrn: "60ffdbcc8bf23f04c02c2464",
    crn: "DS6-5XE-YUH",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 42",
    addr1: "Stockacker 42",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9797872",
    long: "7.9646917",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "13.975",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7711.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "43.0",
    new_annual_electric_bill: "541.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "17602.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00434_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "409.0",
    qrn: "60ffe6c2e1e1df7221e1b2b4",
    crn: "1A9-VOQ-SOP",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 44",
    addr1: "Stockacker 44",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9796478",
    long: "7.9646175",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.25",
    proposed_system_size_ac: "15.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11456.0",
    production_ratio: "825.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "15.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "50.0",
    new_annual_electric_bill: "462.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20475.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00387_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "410.0",
    qrn: "60ffe6828bf23f04c02c24d3",
    crn: "F15-JHL-GHS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 46",
    addr1: "Stockacker 46",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979547",
    long: "7.9646743",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.025",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13404.0",
    production_ratio: "840.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "37.0",
    new_annual_electric_bill: "586.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00388_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "411.0",
    qrn: "60ffe6338a3044201b305ff3",
    crn: "82G-1A2-LPI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 48",
    addr1: "Stockacker 48",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97948773",
    long: "7.964785573",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10096.0",
    production_ratio: "666.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "1037.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "57.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00390_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "412.0",
    qrn: "6100eef28bf23f04c02c2936",
    crn: "MCA-O0M-KRY",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 5",
    addr1: "Stockacker 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9804037",
    long: "7.9651937",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "19.2",
    proposed_system_size_ac: "18.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4976.0",
    production_ratio: "805.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "6.0",
    total_inverter_capacity_1: "18.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Canadian Solar",
    module_id: "c3",
    module_model_number: "CS6K-300MS",
    watts_per_module: "300.0",
    module_quantity: "64.0",
    new_annual_electric_bill: "420.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "24192.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00487_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "413.0",
    qrn: "60ffe5f78bf23f04c02c24cc",
    crn: "76Y-TLB-HYI",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 50",
    addr1: "Stockacker 50",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.979527",
    long: "7.9650653",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "17.55",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15452.0",
    production_ratio: "768.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "54.0",
    new_annual_electric_bill: "470.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "22113.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00391_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "414.0",
    qrn: "60ffe5bae1e1df7221e1b2aa",
    crn: "CW4-8CP-QT9",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 52",
    addr1: "Stockacker 52 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9795442",
    long: "7.9651945",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13469.0",
    production_ratio: "794.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "724.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00393_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "415.0",
    qrn: "60ffd6b18a3044201b305f64",
    crn: "D88-0UM-SQS",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 54",
    addr1: "Stockacker 54",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97974889",
    long: "7.965134956",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.1",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7738.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "28.0",
    new_annual_electric_bill: "739.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11466.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00447_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "416.0",
    qrn: "60ffe8b2e1e1df7221e1b2d2",
    crn: "C5M-RM6-9JE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 6",
    addr1: "Stockacker 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9797926",
    long: "7.9633022",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.7",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7436.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "36.0",
    new_annual_electric_bill: "637.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14742.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00376_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "417.0",
    qrn: "60ffd6688a3044201b305f61",
    crn: "MHV-FJ1-77R",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 60",
    addr1: "Stockacker 60",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98009149",
    long: "7.965051285",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "3.575",
    proposed_system_size_ac: "3.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9565.0",
    production_ratio: "663.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "3.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "11.0",
    new_annual_electric_bill: "1624.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "33.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "4511.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00440_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "418.0",
    qrn: "60ffda3d8a3044201b305f71",
    crn: "QAC-FPC-R6R",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 62",
    addr1: "Stockacker 62",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98002854",
    long: "7.965085789",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "2367.0",
    production_ratio: "785.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "706.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "71.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00441_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "419.0",
    qrn: "60ffd9f7e1e1df7221e1b226",
    crn: "9KG-MK2-57B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 64",
    addr1: "Stockacker 64",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9800533",
    long: "7.9653117",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8158.0",
    production_ratio: "688.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "805.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "67.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00442_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "420.0",
    qrn: "60ffd9af8bf23f04c02c2451",
    crn: "D7Q-D74-4B7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 66",
    addr1: "Stockacker 66",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97997161",
    long: "7.965354615",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7377.0",
    production_ratio: "659.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "980.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "60.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00443_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "421.0",
    qrn: "60ffd76b8bf23f04c02c2446",
    crn: "4NQ-R1I-Q4N",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 68",
    addr1: "Stockacker 68",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97986545",
    long: "7.965159269",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.725",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5565.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "33.0",
    new_annual_electric_bill: "675.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13507.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00445_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "422.0",
    qrn: "60ffd6fc8bf23f04c02c2442",
    crn: "FUV-574-86I",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 70",
    addr1: "Stockacker 70",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97980222",
    long: "7.965154113",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8793.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "828.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "66.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00446_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "423.0",
    qrn: "60ffd7b5e1e1df7221e1b221",
    crn: "W16-DJC-F8V",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 72",
    addr1: "Stockacker 72",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97982367",
    long: "7.965529058",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.025",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6133.0",
    production_ratio: "884.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "37.0",
    new_annual_electric_bill: "529.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15158.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00444_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "424.0",
    qrn: "60ffde1b8bf23f04c02c2485",
    crn: "Q0E-GKX-BKX",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 74",
    addr1: "Stockacker 74",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97998676",
    long: "7.965847885",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10623.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "690.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "72.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00419_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "425.0",
    qrn: "60ffddd28a3044201b305fa4",
    crn: "6I7-RLJ-0M8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 76",
    addr1: "Stockacker 76",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97990955",
    long: "7.965861296",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8504.0",
    production_ratio: "821.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "645.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "74.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00420_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "426.0",
    qrn: "60ffdd948a3044201b305f9d",
    crn: "XXG-2F9-TLK",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 78",
    addr1: "Stockacker 78",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97980529",
    long: "7.965787982",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "6.175",
    proposed_system_size_ac: "6.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9335.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "6.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "19.0",
    new_annual_electric_bill: "946.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "61.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "7774.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00421_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "427.0",
    qrn: "60ffe876e1e1df7221e1b2ce",
    crn: "O2C-4A0-1NT",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 8",
    addr1: "Stockacker 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9796748",
    long: "7.9633169",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.675",
    proposed_system_size_ac: "12.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5059.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "12.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "39.0",
    new_annual_electric_bill: "593.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15977.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00377_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "428.0",
    qrn: "60ffdd57e1e1df7221e1b24d",
    crn: "MK6-WV7-5A1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 80",
    addr1: "Stockacker 80",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97972719",
    long: "7.9657853",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "8.45",
    proposed_system_size_ac: "8.4",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10387.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "8.4",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "26.0",
    new_annual_electric_bill: "772.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "68.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "10647.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00422_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "429.0",
    qrn: "60ffe5368a3044201b305feb",
    crn: "6DW-JCE-OT3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 86",
    addr1: "Stockacker 86",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97953451",
    long: "7.965831593",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "9.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6923.0",
    production_ratio: "661.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "9.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "899.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "63.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00396_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "430.0",
    qrn: "60ffe4d18a3044201b305fe6",
    crn: "Q4O-TW0-MB3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 88",
    addr1: "Stockacker 88",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9795089",
    long: "7.965961915",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "3.25",
    proposed_system_size_ac: "3.315",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6436.0",
    production_ratio: "660.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "3.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "10.0",
    new_annual_electric_bill: "1698.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "30.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "4095.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00398_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "431.0",
    qrn: "60ffe4888a3044201b305fe0",
    crn: "2TR-22R-GQ4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 90",
    addr1: "Stockacker 90",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9795799",
    long: "7.9660506",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "12.35",
    proposed_system_size_ac: "12.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "2142.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "12.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "38.0",
    new_annual_electric_bill: "590.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "15561.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00400_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "432.0",
    qrn: "60ffe4498bf23f04c02c24b5",
    crn: "48K-SES-EM8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 92",
    addr1: "Stockacker 92",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97962277",
    long: "7.966215218",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10119.0",
    production_ratio: "770.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "741.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "70.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00401_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "433.0",
    qrn: "60ffe3548a3044201b305fcf",
    crn: "1JI-Q2P-MKR",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 94",
    addr1: "Stockacker 94",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9796901",
    long: "7.966231311",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7749.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "828.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "66.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00402_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "434.0",
    qrn: "60ffe40d8a3044201b305fd6",
    crn: "2C2-GHH-3A1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 96",
    addr1: "Stockacker 96",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.97978845",
    long: "7.966247574",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "4.225",
    proposed_system_size_ac: "4.2",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6120.0",
    production_ratio: "813.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "4.2",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "13.0",
    new_annual_electric_bill: "1275.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "47.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "5317.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00403_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "435.0",
    qrn: "60ffe3cf8a3044201b305fd3",
    crn: "HVV-TJU-WC4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Stockacker 98",
    addr1: "Stockacker 98",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9798854",
    long: "7.966206",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "7.475",
    proposed_system_size_ac: "7.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "3431.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "7.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "23.0",
    new_annual_electric_bill: "829.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "66.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "9412.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00404_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "436.0",
    qrn: "6100d869e1e1df7221e1b6b6",
    crn: "OOU-U7G-C2P",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 10",
    addr1: "Weilerstraße 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9811535",
    long: "7.9605262",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.8",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6124.0",
    production_ratio: "766.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "64.0",
    new_annual_electric_bill: "400.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "26208.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00120_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "437.0",
    qrn: "60ffd25fe1e1df7221e1b1ed",
    crn: "INQ-A5A-IH5",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 10",
    addr1: "Weilerstraße 10",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9811535",
    long: "7.9605262",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "20.15",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "15921.0",
    production_ratio: "795.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "62.0",
    new_annual_electric_bill: "392.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "25389.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00468_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "140 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "438.0",
    qrn: "60fff4408bf23f04c02c2553",
    crn: "A7A-OED-IDF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 11",
    addr1: "Weilerstraße 11",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98184",
    long: "7.96199",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "5.85",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16013.0",
    production_ratio: "818.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "18.0",
    new_annual_electric_bill: "985.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "59.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "7371.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00303_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "439.0",
    qrn: "6100dc9a8a3044201b30642c",
    crn: "7SS-SD1-EJU",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 12",
    addr1: "Weilerstraße 12",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98103",
    long: "7.95996",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.425",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "4783.0",
    production_ratio: "667.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "29.0",
    new_annual_electric_bill: "907.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "63.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11882.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00061_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "440.0",
    qrn: "60fff33a8bf23f04c02c2542",
    crn: "4AB-H01-KF3",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 13",
    addr1: "Weilerstraße 13",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98196",
    long: "7.96196",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "19.175",
    proposed_system_size_ac: "17.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6285.0",
    production_ratio: "836.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "17.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "59.0",
    new_annual_electric_bill: "404.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "84.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "24167.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00304_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "441.0",
    qrn: "6100dcd18a3044201b30642e",
    crn: "L8M-C60-05T",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 14",
    addr1: "Weilerstraße 14",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98105",
    long: "7.95982",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16014.0",
    production_ratio: "668.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "886.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "64.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00060_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "442.0",
    qrn: "6100dd038bf23f04c02c28ee",
    crn: "I4D-5NP-A2O",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 16",
    addr1: "Weilerstraße 16",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98108",
    long: "7.95962",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "15.275",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "6509.0",
    production_ratio: "819.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "47.0",
    new_annual_electric_bill: "479.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "19253.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00059_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "443.0",
    qrn: "6100dd53e1e1df7221e1b6f4",
    crn: "CIW-4YE-3G6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 18",
    addr1: "Weilerstraße 18",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98111411",
    long: "7.959499301",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "14.95",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12498.0",
    production_ratio: "802.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "46.0",
    new_annual_electric_bill: "507.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "79.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18837.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00058_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "444.0",
    qrn: "61006a378bf23f04c02c27e9",
    crn: "838-EJB-C2C",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 19",
    addr1: "Weilerstraße 19",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98179",
    long: "7.96158",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "6.175",
    proposed_system_size_ac: "5.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "11982.0",
    production_ratio: "824.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "5.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "19.0",
    new_annual_electric_bill: "579.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "66.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "7774.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00033_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "445.0",
    qrn: "610068678bf23f04c02c27df",
    crn: "QMR-HLQ-YH1",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 21",
    addr1: "Weilerstraße 21",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98189",
    long: "7.96153",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "21.45",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "5084.0",
    production_ratio: "858.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "66.0",
    new_annual_electric_bill: "233.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "27027.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00036_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "446.0",
    qrn: "61006df58a3044201b306307",
    crn: "ACV-O47-JYE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 23",
    addr1: "Weilerstraße 23",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98168",
    long: "7.96114",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "20.15",
    proposed_system_size_ac: "18.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18392.0",
    production_ratio: "823.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 8.5",
    total_inverter_capacity_2: "8.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "8.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "62.0",
    new_annual_electric_bill: "243.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "86.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "25389.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00029_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "447.0",
    qrn: "61006d66e1e1df7221e1b5b0",
    crn: "P7Y-GLG-NOF",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 25",
    addr1: "Weilerstraße 25",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98172",
    long: "7.96118",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "13.0",
    proposed_system_size_ac: "11.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "16566.0",
    production_ratio: "820.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 5.5",
    inverter_powerrating_1: "5.5",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "11.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "40.0",
    new_annual_electric_bill: "350.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "16380.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00030_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "448.0",
    qrn: "61006ad6e1e1df7221e1b5a3",
    crn: "DWV-J4M-88B",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 27 ",
    addr1: "Weilerstraße 27",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98179",
    long: "7.96115",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "24.05",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10659.0",
    production_ratio: "857.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "74.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "30303.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00032_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "449.0",
    qrn: "610070e08a3044201b306313",
    crn: "7SC-S5N-1IC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 29",
    addr1: "Weilerstraße 29",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98158",
    long: "7.96078",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "14.625",
    proposed_system_size_ac: "14.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "20597.0",
    production_ratio: "858.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "14.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "45.0",
    new_annual_electric_bill: "317.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "82.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "18434.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00024_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "450.0",
    qrn: "60fff1d58bf23f04c02c2533",
    crn: "5KW-OC2-FH7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 3",
    addr1: "Weilerstraße 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98185",
    long: "7.96287",
    annul_electric_bill: "2585.88",
    current_annual_consumption: "7200.0",
    unit_electricity_bill_rate: "0.35915",
    bill_type: "annual",
    proposed_system_size_dc: "31.2",
    proposed_system_size_ac: "28.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "12535.0",
    production_ratio: "852.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "25.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "96.0",
    new_annual_electric_bill: "226.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "92.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "39312.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00367_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "451.0",
    qrn: "60ffd3668a3044201b305f42",
    crn: "AMW-YV5-GC7",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 3",
    addr1: "Weilerstraße 3",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98185",
    long: "7.96287",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "37.7",
    proposed_system_size_ac: "33.6",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "26558.0",
    production_ratio: "831.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 4.2",
    inverter_powerrating_1: "4.2",
    inverter_quantity_1: "8.0",
    total_inverter_capacity_1: "33.6",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "116.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "47502.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00462_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "452.0",
    qrn: "61007078e1e1df7221e1b5bb",
    crn: "3H9-JWY-HID",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 31",
    addr1: "Weilerstraße 31",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98166",
    long: "7.96074",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "31315.0",
    production_ratio: "843.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "380.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00025_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "453.0",
    qrn: "61006ff98a3044201b30630f",
    crn: "SDU-9CP-K1H",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 33",
    addr1: "Weilerstraße 33",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98173",
    long: "7.96071",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "8.775",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9582.0",
    production_ratio: "854.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "27.0",
    new_annual_electric_bill: "463.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "73.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "11063.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00026_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "454.0",
    qrn: "61006f5b8bf23f04c02c27f6",
    crn: "VMI-PPN-FB6",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 35",
    addr1: "Weilerstraße 35",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98185",
    long: "7.9606",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "13.325",
    proposed_system_size_ac: "11.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "7487.0",
    production_ratio: "825.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 3.0",
    total_inverter_capacity_2: "3.0",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "3.0",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "41.0",
    new_annual_electric_bill: "346.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "16783.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00027_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "455.0",
    qrn: "6100723ee1e1df7221e1b5c4",
    crn: "VNS-9AI-EJ4",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 37",
    addr1: "Weilerstraße 37",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98153",
    long: "7.96036",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "17.55",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "10990.0",
    production_ratio: "814.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "54.0",
    new_annual_electric_bill: "305.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "83.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "22113.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00021_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "456.0",
    qrn: "610071c28bf23f04c02c27fe",
    crn: "A91-Q1I-433",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 39",
    addr1: "Weilerstraße 39",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9816",
    long: "7.96033",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.375",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "14276.0",
    production_ratio: "857.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "35.0",
    new_annual_electric_bill: "379.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "14339.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00022_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "457.0",
    qrn: "60ffd3fb8bf23f04c02c2422",
    crn: "QOT-0CH-HD8",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 4",
    addr1: "Weilerstraße 4",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9813424",
    long: "7.9616543",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "30.875",
    proposed_system_size_ac: "28.29",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9747.0",
    production_ratio: "781.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "95.0",
    new_annual_electric_bill: "240.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "90.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "38909.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00454_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "458.0",
    qrn: "610071648a3044201b306318",
    crn: "M38-39W-0CD",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 41",
    addr1: "Weilerstraße 41",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98175",
    long: "7.96028",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.4",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24096.0",
    production_ratio: "856.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "32.0",
    new_annual_electric_bill: "409.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "13104.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00023_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "459.0",
    qrn: "61006bede1e1df7221e1b5a6",
    crn: "M5X-WPA-448",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 43",
    addr1: "Weilerstraße 43",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98179",
    long: "7.96025",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "11.05",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8900.0",
    production_ratio: "858.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "34.0",
    new_annual_electric_bill: "388.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "78.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "13923.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00016_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "460.0",
    qrn: "61090887e228ce427178bf18",
    crn: "K93-14L-YGO",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 45",
    addr1: "Weilerstraße 45",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98152",
    long: "7.95985",
    annul_electric_bill: "1782.24",
    current_annual_consumption: "4752.0",
    unit_electricity_bill_rate: "0.375042424",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "9474.0",
    production_ratio: "857.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "444.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "76.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00017_4_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "1.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "95 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "461.0",
    qrn: "61006ef88a3044201b30630d",
    crn: "THW-DMV-H85",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 47",
    addr1: "Weilerstraße 47",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98164",
    long: "7.95981",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "10.075",
    proposed_system_size_ac: "10.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8634.0",
    production_ratio: "810.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "31.0",
    new_annual_electric_bill: "441.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "12688.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00019_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "462.0",
    qrn: "6109062d767a314daebf4045",
    crn: "N9K-UXH-1HB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 49",
    addr1: "Weilerstraße 49",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98158",
    long: "7.95985",
    annul_electric_bill: "1719.12",
    current_annual_consumption: "4560.0",
    unit_electricity_bill_rate: "0.377010526",
    bill_type: "annual",
    proposed_system_size_dc: "9.75",
    proposed_system_size_ac: "8.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8153.0",
    production_ratio: "857.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 8.5",
    inverter_powerrating_1: "8.5",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "8.5",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "30.0",
    new_annual_electric_bill: "434.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "75.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "12285.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00018_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "1.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "91 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "463.0",
    qrn: "60fff26de1e1df7221e1b31d",
    crn: "8EK-87U-EVC",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 5",
    addr1: "Weilerstraße 5",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98188",
    long: "7.96262",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "33.15",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "8352.0",
    production_ratio: "833.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "102.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "41769.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00305_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "464.0",
    qrn: "61090dbbad29685ecdb8fd1b",
    crn: "0UK-8P6-GVE",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 51",
    addr1: "Weilerstraße 51",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98178",
    long: "7.95977",
    annul_electric_bill: "2156.52",
    current_annual_consumption: "5892.0",
    unit_electricity_bill_rate: "0.365998574",
    bill_type: "annual",
    proposed_system_size_dc: "16.25",
    proposed_system_size_ac: "15.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "27581.0",
    production_ratio: "836.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "5.0",
    total_inverter_capacity_1: "15.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "50.0",
    new_annual_electric_bill: "410.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "81.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "20475.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00020_3_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "1.0",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "117.83 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "465.0",
    qrn: "6100d7bc8a3044201b3063f4",
    crn: "NXJ-R13-JUB",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 6",
    addr1: "Weilerstraße 6",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9812791",
    long: "7.9612335",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "29.9",
    proposed_system_size_ac: "28.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "13580.0",
    production_ratio: "804.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "4.0",
    total_inverter_capacity_1: "28.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "92.0",
    new_annual_electric_bill: "235.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "91.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "37674.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00122_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "466.0",
    qrn: "61002d288bf23f04c02c26c9",
    crn: "S53-6LL-1LW",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 7",
    addr1: "Weilerstraße 7",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98187",
    long: "7.96242",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "29.9",
    proposed_system_size_ac: "30.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "24037.0",
    production_ratio: "881.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "30.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "92.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "37674.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00015_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "467.0",
    qrn: "6100d833e1e1df7221e1b6b3",
    crn: "BN1-FDU-U0Q",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 8",
    addr1: "Weilerstraße 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9812266",
    long: "7.9608628",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "23.075",
    proposed_system_size_ac: "21.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "26320.0",
    production_ratio: "822.0",
    inverter_efficiency: "96.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 7.0",
    inverter_powerrating_1: "7.0",
    inverter_quantity_1: "3.0",
    total_inverter_capacity_1: "21.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "71.0",
    new_annual_electric_bill: "301.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "88.0",
    "utility_electric_rate_escalator_%": "5.0",
    project_type: "R",
    total_system_cost_: "29068.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00121_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "468.0",
    qrn: "60ffc6ca8a3044201b305f14",
    crn: "5ES-0QO-BHX",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 8",
    addr1: "Weilerstraße 8",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9812266",
    long: "7.9608628",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "25.675",
    proposed_system_size_ac: "24.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "18946.0",
    production_ratio: "846.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 3.0",
    inverter_powerrating_1: "3.0",
    inverter_quantity_1: "8.0",
    total_inverter_capacity_1: "24.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "79.0",
    new_annual_electric_bill: "223.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "32357.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00471_4_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "160 m2",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "469.0",
    qrn: "60fffb7ce1e1df7221e1b36e",
    crn: "O6V-EUH-HYN",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Weilerstraße 9",
    addr1: "Weilerstraße 9",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.98177",
    long: "7.96202",
    annul_electric_bill: "1699.44",
    current_annual_consumption: "4500.0",
    unit_electricity_bill_rate: "0.37766",
    bill_type: "annual",
    proposed_system_size_dc: "21.45",
    proposed_system_size_ac: "20.0",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "21700.0",
    production_ratio: "834.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "Piko 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "2.0",
    total_inverter_capacity_1: "20.0",
    inverter_brand_2: "",
    inverter_model_2: "",
    total_inverter_capacity_2: "",
    inverter_quantity_2: "",
    total_inverter_capacity_22: "",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "66.0",
    new_annual_electric_bill: "232.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "87.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "27027.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00302_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "No",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
  {
    sl_no: "470.0",
    qrn: "60ffdb418a3044201b305f7d",
    crn: "GQ3-279-AUV",
    utility_name: "Prio Energie",
    rate_name: "Domestic",
    customer_name: "Zartener Str. 2",
    addr1: "Zartener Str. 2",
    city: "Stegen",
    state: "Deutschland",
    zip_code: "79252",
    lat: "47.9836253",
    long: "7.9596897",
    annul_electric_bill: "2400.0",
    current_annual_consumption: "6636.0",
    unit_electricity_bill_rate: "0.361771971",
    bill_type: "annual",
    proposed_system_size_dc: "16.575",
    proposed_system_size_ac: "15.5",
    dc_to__ac_ratio: "1.1",
    annual_solar_generation: "17889.0",
    production_ratio: "786.0",
    inverter_efficiency: "98.0",
    inverter_brand_1: "Kostal Solar Electric",
    inverter_id: "c9",
    inverter_model_1: "PLENTICORE plus 10",
    inverter_powerrating_1: "10.0",
    inverter_quantity_1: "1.0",
    total_inverter_capacity_1: "10.0",
    inverter_brand_2: "Kostal Solar Electric",
    inverter_model_2: "PLENTICORE plus 5.5",
    total_inverter_capacity_2: "5.5",
    inverter_quantity_2: "1.0",
    total_inverter_capacity_22: "5.5",
    module_brand: "Heckert Solar",
    module_id: "c3",
    module_model_number: "NeMo 2.0 60M 325W",
    watts_per_module: "325.0",
    module_quantity: "51.0",
    new_annual_electric_bill: "487.0",
    panel_degradation: "0.5",
    "utility_bill_replacement_%": "80.0",
    "utility_electric_rate_escalator_%": "2.0",
    project_type: "R",
    total_system_cost_: "20878.0",
    unit_system_cost: "1.26",
    fit_type: "FIT_July_Resedential",
    combined_img: "SOLARHUB00457_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    business_name: "",
    building_type: "",
    business_type: "",
    contact_url: "",
    contact_website: "",
    estimated_consumption: "",
    is_solar_panel_already_installed: "Yes",
    number_of_floors: "",
    number_of_inhabitants: "",
    owner_type: "",
    roof_area: "",
    roof_type: "",
    state_of_roof: "",
    style_of_roof: "",
    type_of_solar_panel_if_already_installed: "",
    year_of_roof_construction: "",
  },
];
