import React, { useEffect, useState } from "react";
import finalLogo from "Assets/finalLogo.svg";
import call from "Assets/community/call.svg";
import menu2 from "Assets/community/menu2.svg";
import { Avatar, Image } from "antd";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import firebase, { db } from "../../../../firebase";
import { openPopupWidget } from "react-calendly";
import MobileDrawer from "./MobileDrawer";

const MobileHeaderWrapper = styled("div")`
  .header_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .left_mobile {
    span {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.1em;
      color: #ffbf1b;
    }
    img {
      padding-right: 10px;
    }
  }
  .right_mobile {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

const MobileHeader = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [userDetail, setUserDetail] = useState();
  const [forIconChange, setForIconChange] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser) {
      db.collection("users")
        .doc(currentUser.uid)
        .onSnapshot((doc) => {
          setUserDetail(doc.data());
          const data = {
            username: doc.data()?.username,
          };
          const notificationData = {
            waiting_invitaion: doc.data()?.waiting_invitaion
              ? doc.data()?.waiting_invitaion
              : [],
            pending_invitaion: doc.data()?.pending_invitaion
              ? doc.data()?.pending_invitaion
              : [],
            accepted_invitaion: doc.data()?.accepted_invitaion
              ? doc.data()?.accepted_invitaion
              : [],
          };
          dispatch({ type: "SET_USERNAME_DISCOURSE_PASSWORD", payload: data });
          dispatch({ type: "SET_ALL_INVITAION", payload: notificationData });
        });
    }
  }, [currentUser]);
  const showDrawer = () => {
    setVisible(true);
  };
  return (
    <MobileHeaderWrapper>
      <div className="header_main">
        <div className="left_mobile">
          <img src={finalLogo} alt="" />
          <span>SolarHub</span>
        </div>
        <div className="right_mobile">
          {!userDetail?.img_url && !currentUser?.photoURL ? (
            <Avatar
              style={{ backgroundColor: "#00a2ae", verticalAlign: "middle" }}
              size="large"
            >
              {currentUser?.email[0]}
            </Avatar>
          ) : (
            <Avatar
              src={
                userDetail?.img_url
                  ? userDetail?.img_url
                  : currentUser?.photoURL
              }
              size={35}
            />
          )}
          <CustomButton
            url="https://calendly.com/solarhub?hide_gdpr_banner=1"
            setForIconChange={setForIconChange}
            forIconChange={forIconChange}
            prefill={{
              email: currentUser?.email,
              firstName: currentUser?.displayName
                ?.split(" ")
                .slice(0, -1)
                .join(" "),
              lastName: currentUser?.displayName
                ?.split(" ")
                .slice(-1)
                .join(" "),
              name: currentUser?.displayName,
              date: new Date(Date.now() + 86400000),
            }}
            pageSettings={{
              backgroundColor: "ffffff",
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: "00a2ff",
              textColor: "4d5055",
            }}
            utm={{
              utmCampaign: "Spring Sale 2019",
              utmContent: "Shoe and Shirts",
              utmMedium: "Ad",
              utmSource: "Facebook",
              utmTerm: "Spring",
            }}
          />
          <img src={menu2} alt="" onClick={showDrawer} />
        </div>
      </div>
      <MobileDrawer visible={visible} setVisible={setVisible} />
    </MobileHeaderWrapper>
  );
};

const CustomButton = ({
  url,
  prefill,
  pageSettings,
  utm,
  setForIconChange,
  forIconChange,
}) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <img style={{ cursor: "pointer" }} onClick={onClick} src={call} alt="" />
  );
};

export default MobileHeader;
