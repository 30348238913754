import React from "react";
import styled from "styled-components";
const MainContainer = styled("div")`
  position: relative;
  width: 213px;
  height: 58px;
  background: #ffffff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  transition: ease-in all 0.2s;
  &:hover {
    cursor: pointer;
    transform: scale(1.06);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MainContainer1 = styled("div")`
  display: flex;
  gap: 12px;
`;
const Container = styled("div")`
  width: 37px;
  height: 37px;
  background: #ffd75d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;
const Container1 = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  color: #2d4764;
`;
const ColorDot = styled("div")`
  position: absolute;
  width: 9px;
  height: 9px;
  right: 12px;
  top: 8px;
  background: #ffd75d;
  border-radius: 50%;
`;
const CardActive = ({ text, active, setSelected }) => {
  return (
    <MainContainer
      onClick={() => {
        setSelected((prev) => {
          if (prev === "chat") {
            return "schedule";
          } else return "chat";
        });
      }}
    >
      <ColorDot />
      <MainContainer1>
        <Container>{active}</Container>
        <Container1>{text}</Container1>
      </MainContainer1>
    </MainContainer>
  );
};

export default CardActive;
