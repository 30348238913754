import React, { useEffect, useState } from "react";
import styled from "styled-components";
import flash from "Assets/community/flash.svg";
import ladki from "Assets/community/ladki.svg";
import bookmark from "Assets/community/bookmark.svg";
import BookmarkFilled from "Assets/community/BookmarkFilled.svg";
import heart from "Assets/community/heart.svg";
import share from "Assets/community/share.svg";
import menu from "Assets/community/menu.svg";
import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Button } from "antd";
import parse from "html-react-parser";
import moment from "moment";
import { db } from "./../../../../../firebase";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
const TopicListWrapper = styled("div")`
  .listMain {
    min-height: 197px;
    width: 644px;
    margin-bottom: 28px;
    padding-top: 24px;
    padding-bottom: 10px;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 2px 2px 10px #d3d3d3;
    border-radius: 10px;
  }
  .first_container {
    display: flex;
    justify-content: flex-start;
    margin-left: 28px;
    align-items: flex-start;
    cursor: pointer;
  }
  .howare {
    margin-right: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .makeItFlex {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  .makeItFlex2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text_imp {
    font-weight: bold;
    font-size: 18px;
    color: #2d4764;
    margin-bottom: 3px;
  }
  .text_1 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #2d4764;
  }
  .text_2 {
    font-weight: 600;
    margin-left: 8px;
    font-size: 10px;
    line-height: 14px;
    color: #666666;
  }
  .secound_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 387px;
    padding-left: 91px;
    height: 22px;
  }
  .same_clase {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 78px;
    cursor: pointer;
    height: 22px;
    div {
      font-weight: 600;
      font-size: 10px;
      margin-left: 6px;
      line-height: 14px;
      color: #666666;
    }
  }
  .same_clasee {
    :hover {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: #edf5ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .descriotion {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 21px;
    padding-right: 6px;
    font-size: 16px;
    margin-top: 10px;
    line-height: 22px;
    color: #666666;
  }
  .withMenu {
    display: flex;
    justify-content: space-between;
    width: 505.21px;
    align-items: flex-start;
    min-height: 30px;
  }
  .emoji {
    height: 22px;
    width: 22px;
  }
  .lightbox img {
    width: 500px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  .meta {
    display: none;
  }
  .replyButton {
    width: 65px;
    height: 27px;
    background: #ffd75d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-right: 12px;
    :hover {
      background: #ffd75d;
    }
  }
  .input_main {
    background: #f4f4f4;
    border-radius: 10px;
    width: 98%;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    img {
      height: 36px;
      width: 36px;
      margin-right: 10px;
    }
  }
  .last_class {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  }
  .tags_contanier {
    display: flex;
    justify-content: flex-start;
    padding-right: 14px;
    gap: 7px;
    align-items: flex-start;
  }
  .tag {
    background: #f9f9f9;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #304864;
    border-radius: 16.9412px;
    height: 18px;
    width: 55.913055419921875px;
  }
  .ant-btn:hover {
    color: black;
    border: none;
  }
  .ant-btn:focus {
    color: none;
    border: none;
  }
  .new_update {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (max-width: 600px) {
    .listMain {
      width: 93%;
      margin: auto;
      background: rgba(249, 249, 249, 1);
      margin-bottom: 10px;
    }
    .first_container {
      margin: 0px;
      margin-left: 8px;
    }
    .descriotion {
      font-size: 15px;
    }
    .withMenu {
      width: auto;
    }
    .descriotion a {
      display: none;
    }
    .lightbox-wrapper img {
      width: 77%;
    }
    .secound_container {
      width: 65%;
      margin: auto;
      padding-left: 11px;
    }
  }
`;
const ITEM_HEIGHT = 48;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F4F4F4",
  },
}));
const TopicCard = ({
  data,
  topicData,
  setTopicData,
  setcategoryId,
  setPostTopic,
  setShowPost,
}) => {
  const dispatch = useDispatch();
  const [isBookmarked, setisBookmarked] = useState(false);
  console.log("sar", data);
  const [uid, topicId] = useSelector((state) => {
    return [state?.user?.currentUser?.uid, state?.savedTopic.topicId];
  });
  const bookMarkHandler = async (flag) => {
    let t = [...topicId];
    if (isBookmarked) {
      let t1 = [];
      for (let i = 0; i < t.length; i++) {
        if (t[i].id != data.id) t1.push(t[i]);
      }
      t = t1;
    } else {
      t.push({ id: data.id, categoryId });
    }
    const data1 = {
      topicIdList: t,
    };
    const res = await db.collection("savedTopics").doc(uid).set(data1);
    dispatch({ type: "SET_TopicId", payload: t });
    if (isBookmarked) {
      toast.success("Topic Removed Successfully");
    } else {
      toast.success("Topic Added Successfully");
    }
    setisBookmarked((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    for (let i = 0; i < topicId.length; i++) {
      if (topicId[i].id === data.id) {
        setisBookmarked(true);
        break;
      }
    }
  }, [topicId]);
  console.log(isBookmarked, "isBookmarked");

  return (
    <TopicListWrapper>
      <div className="listMain" style={{ background: "#f9f9f9" }}>
        <div className="new_update">
          <div
            className="first_container"
            onClick={() => {
              setShowPost(true);
              setPostTopic(data);
              for (let i = 0; i < topicId.length; i++) {
                if (topicId[i].id === data.id) {
                  setcategoryId(topicId[i].categoryId);
                  break;
                }
              }
            }}
          >
            {data?.posters?.length > 0 && data?.posters[0]?.avatar_template ? (
              <img
                src={`http://community.solarhub24.de${data.posters[0].avatar_template.replace(
                  "{size}",
                  "50"
                )}`}
                className="howare"
                alt=""
              />
            ) : (
              <img src={ladki} alt="" />
            )}
            <div className="makeItFlex">
              <div className="withMenu">
                <div className="text_imp">{data.title}</div>
              </div>
              <div className="makeItFlex2">
                <div className="text_1">{data?.posters[0]?.username}</div>
                <img src={flash} style={{ marginLeft: "4px" }} alt="" />
                <div className="text_2">
                  {moment(new Date(data.createdAt)).startOf("hour").fromNow()}
                </div>
              </div>
              <div className="descriotion">{parse(data.description)}</div>
            </div>
          </div>
        </div>
        <div className="last_class">
          <div className="secound_container">
            <div className="same_clase" onClick={() => {}}>
              <img src={heart} alt="" />
              <div>{data.likeCount} Likes</div>
            </div>
            <div className="same_clase">
              <img src={share} alt="" />
              <div>{data.replyCount} Replies</div>
            </div>
            <div className="same_clase">
              {isBookmarked ? (
                <img
                  src={BookmarkFilled}
                  alt=""
                  onClick={() => bookMarkHandler(true)}
                  height={"17px"}
                  width={"17px"}
                />
              ) : (
                <img
                  src={bookmark}
                  alt=""
                  onClick={() => bookMarkHandler(false)}
                  height={"17px"}
                  width={"17px"}
                />
              )}
            </div>
          </div>
          <div className="tags_contanier">
            {data?.tags?.length > 0
              ? data?.tags?.map((value) => {
                  return <div className="tag">@{value}</div>;
                })
              : ""}
          </div>
        </div>
      </div>
    </TopicListWrapper>
  );
};

export default TopicCard;
