import { Fragment, useState, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import { details } from "./content";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const AccordionContainer = styled(Accordion)`
  /* background: rgba(245, 196, 65, 0.05); */
`;

const Details = () => {
  return (
    <Container>
      {details.map((item, key) => {
        return (
          <AccordionContainer key={item.title}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${key}a-content`}
              id={`panel${key}a-header`}
            >
              <Typography style={{ color: "rgba(40, 51, 74, 1)" }}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.content}</Typography>
            </AccordionDetails>
          </AccordionContainer>
        );
      })}
    </Container>
  );
};

export default Details;
