import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cross from "./../../community/Invitaion/Assets/Cross";
import Tick from "./../../community/Invitaion/Assets/Tick";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const MainContainer = styled("div")`
  position: relative;
  width: 552px;
  height: 551px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 98%;
    position: absolute;
    bottom: 4px;
    height: 287px;
  }
`;
const CrossContainer = styled("div")`
  position: absolute;
  right: 30px;
  top: 26px;
  cursor: pointer;
`;
const SubContainer = styled("div")`
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Text1 = styled("div")`
  margin-top: 28px;
  font-weight: bold;
  font-size: 20px;
  color: #2d4764;
`;
const Text2 = styled("div")`
  margin-top: 14px;
  font-weight: normal;
  font-size: 16px;
`;
const ReduxContainer = styled("div")`
  .main_container {
    width: 436px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin: auto;
    display: flex;
    margin-top: 33px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-direction: column;
  }
  .text_1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
  }
`;

const ConfirmModal = ({ setIsModalVisible2 }) => {
  const dispatch = useDispatch();
  const [isDataPresent, setIsDataPresent] = useState(null);
  const details = useSelector(
    (state) => state?.notification?.accepted_invitaion
  );
  const isEnglish = useSelector((state) => state.global.isEnglish);

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  const getDay = (date) => {
    var A = date;
    var weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thursday";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";
    var r = weekdays[A.getDay()];
    return r;
  };
  const getDate = (today) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date =
      today.getFullYear() +
      " , " +
      monthNames[today.getMonth()] +
      " , " +
      today.getDate();
    return date;
  };

  useEffect(() => {
    if (details.length > 0) {
      setIsDataPresent(details[details.length - 1]);
    }
  }, [details]);

  return (
    <MainContainer>
      <CrossContainer
        onClick={() => {
          dispatch({ type: "CLOSE_MODAL" });
        }}
      >
        <Cross />
      </CrossContainer>
      <SubContainer>
        <Tick />
        <Text1>Confirmed</Text1>
        <Text2>
          {isEnglish
            ? "You are scheduled with David."
            : "Bestätigt. Du hast einen Austausch mit David geplant"}
        </Text2>
      </SubContainer>
      {isDataPresent !== null ? (
        <ReduxContainer>
          <div className="main_container">
            <div className="text_1">
              {isDataPresent.duration} Minute Meeting
            </div>
            <div className="text_1">
              {formatAMPM(new Date(isDataPresent.meeting_time.seconds * 1000))}{" "}
              -{" "}
              {formatAMPM(
                new Date(
                  isDataPresent.meeting_time.seconds * 1000 +
                    isDataPresent.duration * 60 * 1000
                )
              )}{" "}
              {`${getDay(
                new Date(isDataPresent.meeting_time.seconds * 1000)
              )}, ${getDate(
                new Date(isDataPresent.meeting_time.seconds * 1000)
              )}`}
            </div>
            <div className="text_1">India Standard Time</div>
          </div>
        </ReduxContainer>
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default ConfirmModal;
