import React, { useEffect, useState } from "react";
import styled from "styled-components";
import blueMark from "Assets/community/blueMark.svg";
import postCount1 from "Assets/community/postCount1.svg";
import axios from "axios";
import { useSelector } from "react-redux";

const FeatureContributersWrapper = styled("div")`
  .container {
    margin: auto;
    height: 217px;
    width: 235px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 15px;
    border-radius: 15px;
    background: #f9f9f9;
    border-radius: 15px;
    padding-bottom: 10px;
    overflow-y: scroll;
    @media (max-width: 500px) {
      width: 206px;
      overflow-x: hidden;
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .inside_container {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
  }
  .contributers_main {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .inside_contributers_main {
    height: 79px;
    padding-top: 10px;
    width: 207px;
    border-radius: 10px;
    margin-top: 6px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 10px;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 10px;
  }
  .scrooler {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex_text {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
  }
  .flex_muduler {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-family: Nunito;
      font-style: normal;
      padding-right: 9px;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #2d4764;
    }
  }
  .counts {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: #2d4764;
  }
  .text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #2d4764;
  }
  .main_count {
    display: flex;
    justify-content: center;
    padding-left: 7px;
    padding-top: 3px;
    align-items: center;
    img {
      padding-right: 5px;
      width: 24px;
      height: 26px;
    }
  }
  .container::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .container::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const FeatureContributers = () => {
  const [contributersData, setContributersData] = useState(null);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  useEffect(() => {
    const func = async () => {
      try {
        const res = await axios.get(
          "https://us-central1-solarhub-44450.cloudfunctions.net/api/general/featured-contributor"
        );
        setContributersData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    func();
  }, []);

  return (
    <FeatureContributersWrapper>
      <div className="container">
        <div className="inside_container">
          {isEnglish ? "Featured Contributers" : "Ausgewählte Mitwirkende"}
        </div>
        <div className="contributers_main">
          {contributersData !== null
            ? contributersData.map((data, key) => {
                return (
                  <div className="inside_contributers_main">
                    <div className="scrooler">
                      <img
                        style={{
                          marginRight: "10px",
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                        }}
                        src={`http://community.solarhub24.de/${data.userAvatar.replace(
                          "{size}",
                          "50"
                        )}`}
                        alt=""
                      />
                      <div className="flex_text">
                        <div className="flex_muduler">
                          <div>{data.userName}</div>
                          <img src={blueMark} alt="" />
                        </div>
                        <div className="text">
                          {isEnglish ? "Top performer" : "Top-Performer"}
                        </div>
                      </div>
                    </div>
                    <div className="main_count">
                      <img src={postCount1} alt="" />
                      <div className="counts">
                        {data.postCount} {isEnglish ? "posts" : "Beiträge"}
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </FeatureContributersWrapper>
  );
};

export default FeatureContributers;
