import React, { useState } from "react";
import styled from "styled-components";
import AllNotification from "./AllNotification/allNotification";
import PendingNotification from "./PendingNotification/pendingNotification";
import { useSelector } from "react-redux";
const HomeWrapper = styled("div")`
  .root {
    border: 2px solid #dfdfdf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 93vh;
    width: 100%;
    background: #f9f9f9;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
const HeaderText = styled("div")`
  display: flex;
  width: 100%;
  font-family: Nunito;
  font-weight: bold;
  font-size: 24px;
  color: #2d4764;
  margin-top: 28px;
  margin-left: 34px;
`;
const SubHeader = styled("div")`
  margin-top: 30px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    width: 79%;
  }
`;
const SubHeader1 = styled("div")`
  display: flex;
`;
const PendingCount = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  background: #df6569;
  border-radius: 50%;
`;

const BodyTab = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-weight: bold;
  font-size: 20px;
  width: ${(props) => {
    if (props.name == "all") {
      return "50px";
    } else {
      return "110px";
    }
  }};
  color: ${(props) => {
    if (props.name == props.selected) {
      return "#2D4764";
    } else {
      return "#C0C0C0";
    }
  }};
  border-bottom: ${(props) => {
    if (props.name == props.selected) {
      return "4px solid #FDC51C";
    }
  }};
  cursor: pointer;
`;
const Notification = () => {
  const pending_invitaion_Arr = useSelector(
    (state) => state?.notification?.pending_invitaion
  );
  const accepted_invitaion_Arr = useSelector(
    (state) => state?.notification?.accepted_invitaion
  );
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [selected, setSelected] = useState("all");
  return (
    <HomeWrapper>
      <div className="root">
        <HeaderText>
          {isEnglish ? "Notification" : "Benachrichtigungen"}
        </HeaderText>
        <SubHeader>
          <BodyTab
            name="all"
            selected={selected}
            onClick={() => {
              setSelected("all");
            }}
            className="allWidth"
          >
            {isEnglish ? "All" : "Alle"}
          </BodyTab>
          <SubHeader1>
            <BodyTab
              name="invitations"
              selected={selected}
              onClick={() => {
                setSelected("invitations");
              }}
            >
              {isEnglish ? "Invitations" : "Einladungen"}
            </BodyTab>
            <PendingCount>{pending_invitaion_Arr.length}</PendingCount>
          </SubHeader1>
        </SubHeader>
        {selected === "all"
          ? accepted_invitaion_Arr.map((data) => {
              return (
                <>
                  <AllNotification data={data} />
                </>
              );
            })
          : pending_invitaion_Arr.length > 0
          ? pending_invitaion_Arr.map((data) => {
              return (
                <>
                  <PendingNotification data={data} />
                </>
              );
            })
          : ""}
      </div>
    </HomeWrapper>
  );
};

export default Notification;
