import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  costsAndSavings,
  energyDetails,
  financingDetails,
} from "../utils/costs";
import firebase, { db } from "../firebase";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core";
import { colors } from "colors";
import * as geofire from "geofire-common";

const OpenInSave = () => {
  const dispatch = useDispatch();
  const useStyle = makeStyles((theme) => ({
    select_an_address: {
      color: "#4D7E6D",
      fontWeight: "bold",
      "&:hover": {
        color: colors.darkBlue,
      },
    },
  }));
  const classes = useStyle();
  return (
    <>
      <div>
        Please choose an address <br />{" "}
        <span
          className={classes.select_an_address}
          onClick={() => {
            dispatch({ type: "SET_USER_ADRESS_STATUS", payload: true });
          }}
        >
          Click here to select an address{" "}
        </span>
        You can not save a project without an address
      </div>
    </>
  );
};

export const useProjectTools = () => {
  const project = useSelector((state) => state.project);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const quote = useSelector((state) => state.quote);
  const isProjectSavedFromUseEffect = useSelector(
    (state) => state.global.isProjectSaved
  );
  const currentUser = useSelector((state) => state.user.currentUser);
  const financialDetails = financingDetails(project);
  const energysDetails = energyDetails(project);
  const costSaving = costsAndSavings(project);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const setLoading = (data) => dispatch({ type: "SET_LOADING", payload: data });
  const setProject = (data) => dispatch({ type: "SET_PROJECT", payload: data });
  const save = (project, direct_uid, redirect) => {
    let slug = pathname.substring(13);
    if (project?.quoteStatus === "default") {
      // toast.error(<OpenInSave />);
      dispatch({ type: "SET_USER_ADRESS_STATUS", payload: true });
      return;
    }
    if (project?.quoteStatus === "notFound") {
      toast.error(
        isEnglish
          ? "Please wait for sometime. We are creating the project for your address -Please check back after some time"
          : "Wir erstellen gerade deinen Projektplan für dein Gebäude - schau dich gerne etwas um. Wir melden uns sobald die Planung fertig ist."
      );
      return;
    }
    if (slug?.includes("saved")) {
    } else {
      setLoading(true);
    }
    const financialDetails = financingDetails(project);
    const energysDetails = energyDetails(project);
    const costSaving = costsAndSavings(project);
    let versionFromUrl = slug?.substr(-2);
    if (currentUser || direct_uid) {
      if (!slug?.includes("saved")) {
        versionFromUrl = "v1";
      }
      const uid = currentUser?.uid || direct_uid;
      db.collection("saved_projects")
        .doc(`${uid}${quote?.quote.qrn}${versionFromUrl}`)
        .set({
          project_details: {
            ...project,
            pid: uid + quote?.quote.qrn + versionFromUrl,
            // address: {
            //   ...project.address,
            //   number: 12,
            // },
          },
          uid: uid,
          qrn: quote?.quote.qrn,
          crn: quote?.quote?.crn,
          pid: uid + quote?.quote.qrn + versionFromUrl,
          projectImgUrl: quote?.quote?.img_url,
          financialDetails,
          energysDetails,
          costSaving,
          updatedData: {
            roof_type: quote?.quote?.roof_type || "Flat roof",
            roof_material: quote?.quote?.roof_material || "metal roof",
            roof_tilt: quote?.quote?.roof_tilt || "45°",
            roof_orientation:
              quote?.quote?.roof_orientation || "South orientation",
          },
          version: versionFromUrl,
          geohash: geofire.geohashForLocation([
            parseFloat(quote?.quote?.lat),
            parseFloat(quote?.quote.long),
          ]),
          lat: quote?.quote?.lat,
          long: quote?.quote?.long,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((doc) => {
          if (slug?.includes("saved")) {
          } else {
            toast.success(
              isEnglish
                ? "Project succesfully saved"
                : "Projekt erfolgreich gespeichert"
            );
            //
            setLoading(false);
          }
          if (redirect) {
            localStorage.clear();
            const setUrl =
              "/application/saved-" + uid + quote?.quote.qrn + versionFromUrl;
            console.log(setUrl, "setUrl");
            localStorage.setItem("goBackUrl", setUrl);
            dispatch({ type: "SET_BAK_TO_STEP_FOUR", payload: false });
            history.push({
              pathname: `/useroverview/${
                uid + quote?.quote.qrn + versionFromUrl
              }`,
              state: {
                qrn: quote?.quote.qrn,
                projectId: uid + quote?.quote.qrn + versionFromUrl,
              },
            });
          } else {
            history.push(
              "/application/saved-" + uid + quote?.quote.qrn + versionFromUrl
            );
          }
        })
        .catch((e) => console.error(e.message));
    } else {
      setLoading(false);
    }
  };

  const fetchConstants = () => {
    const country = "germany";
    db.collection("other_details/webConstants/germany")
      .get()
      .then((snap) => {
        let data = [];
        snap.forEach((doc) => {
          data.push(doc.data());
        });
        if (
          quote?.status === "default" ||
          quote?.status === "notFound" ||
          quote?.status === "matched"
        ) {
          setProject({
            ...project,
            quoteStatus: quote?.status,
            webConstants: { country: country, constants: data },
          });
          const url = window.location.href;
          if (url.includes("b2b") && url.includes("default")) {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2b" });
          } else {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2c" });
          }
        } else {
          setProject({
            ...project,
            webConstants: { country: country, constants: data },
          });
          const url = window.location.href;
          if (url.includes("b2b") && url.includes("default")) {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2b" });
          } else {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2c" });
          }
        }
      })
      .catch((e) => alert("Invalid url"));
  };

  const fetchSingleConstants = () => {
    db.collection("constants")
      .doc("webConstants")
      .get()
      .then((doc) => {
        console.log("doc", doc.data());
      });
  };

  return { save, fetchConstants, fetchSingleConstants };
};
