import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ladki from "Assets/community/ladki.svg";
import parse from "react-html-parser";
import menu from "Assets/community/menu.svg";
import sharee from "Assets/community/sharee.svg";
import heart from "Assets/community/heart.svg";
import like from "Assets/community/like.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { deletePostById, deletePostLike } from "api/discourse/get";
import axios from "axios";
import { getError } from "helperMethod";
import moment from "moment";

const InsideTopicPostWrapper = styled("div")`
  .inside_replymain {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    width: 560px;
  }
  .left_side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 36px;
      width: 36px;
    }
  }
  .right_side {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .allThings {
    display: flex;
    gap: 13px;
    height: 21px;
    align-items: center;
    img {
      cursor: pointer;
    }
    div {
      cursor: pointer;
    }
  }
  .imp {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .flex_text {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    color: #666666;
  }
  .sameText {
    font-weight: 600;
    font-size: 12px;
    color: #666666;
  }
  .other_same {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }
  .lastpart {
    width: 15px;
  }
  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffd75d;
    color: #ffd75d;
    box-shadow: 9999px 0 0 -5px #ffd75d;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dot-pulse::before,
  .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffd75d;
    color: #ffd75d;
  }

  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #ffd75d;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #ffd75d;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #ffd75d;
    }
    30% {
      box-shadow: 9984px 0 0 2px #ffd75d;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #ffd75d;
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #ffd75d;
    }
    30% {
      box-shadow: 9999px 0 0 2px #ffd75d;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #ffd75d;
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #ffd75d;
    }
    30% {
      box-shadow: 10014px 0 0 2px #ffd75d;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #ffd75d;
    }
  }
`;

const InsideTopicPost = ({
  data,
  key,
  setIsEditTrue,
  setEditedDecriptData,
  setDescritption,
  divRref,
  setShowReplyBox,
  setIsReplyTrue,
  setDetect,
  setPostId,
}) => {
  const [isOtherFunTrue, setIsOtherFucTrue] = useState(false);
  const [isLikeTrue, setIsLikeTrue] = useState(false);
  const [userLikeArray, setUserLikeArray] = useState([]);
  const userName = useSelector((state) => state?.user?.username);
  const dispatch = useDispatch();
  const [likeing, setIsLikeing] = useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);

  console.log(data);

  useEffect(() => {
    const func = async () => {
      try {
        const res = await axios.get(
          `https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/posts/${data.id}/user`
        );
        console.log(res, "rus");
        let userNameArry = [];
        for (let i = 0; i < res.data.post_action_users.length; i++) {
          userNameArry.push(res.data.post_action_users[i].username);
        }
        setUserLikeArray(userNameArry);
      } catch (error) {
        console.log(error);
      }
    };

    func();
  }, [isLikeTrue]);

  const decryptDescription = (realData) => {
    console.log(realData, "realData");
    let updatedDes = "";
    let link = null;
    let url = null;
    for (let i = 0; i < realData[0]?.props?.children.length; i++) {
      if (realData[0]?.props?.children[i]?.type === "a") {
        updatedDes =
          updatedDes +
          " " +
          realData[0]?.props?.children[i]?.props?.children[0];
      }
      if (!realData[0]?.props?.children[i]?.type) {
        updatedDes = updatedDes + realData[0]?.props?.children[i];
      }
      if (realData[0]?.props?.children[i]?.type === "img") {
        const lastPoint =
          realData[0]?.props?.children[i]?.props?.src.split(".");
        link = `![${realData[0]?.props?.children[i]?.props?.alt}|${
          realData[0]?.props?.children[i]?.props?.width
        }x${realData[0]?.props?.children[i]?.props?.height}](upload://${
          realData[0]?.props?.children[i]?.props[`data-base62-sha1`]
        }.${lastPoint[lastPoint.length - 1]})`;
        url = realData[0]?.props?.children[i]?.props?.src;
      }
    }
    return { updatedDes, link, url };
  };

  const deleteHandler = async (id, name) => {
    console.log(name, "name");
    console.log(userName, "userName");
    if (!userName) {
      setIsEditTrue(true);
      return toast.error("you don't have username");
    }
    if (userName !== name) {
      setIsEditTrue(true);
      return toast.error("you don't have permission to delete the post");
    }
    const result = await deletePostById(id, { username: userName });
    if (result) {
      toast.success("post deleted successfully");
      dispatch({ type: "SET_DISCORUSE_STATE_FOR_POST" });
      setIsEditTrue(true);
    }
  };

  const LikeHandler = async (id) => {
    console.log(id, "id");
    const datas = {
      username: userName,
      postId: id,
    };
    try {
      setIsLikeing(true);
      const result = await axios.post(
        "https://us-central1-solarhub-44450.cloudfunctions.net/api/topics/posts/like",
        datas
      );
      if (result.status === 200) {
        setIsLikeTrue(!isLikeTrue);
        dispatch({ type: "SET_DISCORUSE_STATE_FOR_POST" });
        setIsLikeing(false);
      } else {
        console.log(result.data.errors, "result");
        setIsLikeing(false);
      }
    } catch (err) {
      if (err?.response?.data?.errors?.length) {
        toast.error(getError(err?.response?.data?.errors));
      }
      setIsLikeing(false);
    }
  };

  const deleteLikeHandler = async (id) => {
    const datas = {
      username: userName,
    };
    setIsLikeing(true);
    const response = await deletePostLike(id, datas);
    if (response) {
      setIsLikeTrue(!isLikeTrue);
      dispatch({ type: "SET_DISCORUSE_STATE_FOR_POST" });
      setIsLikeing(false);
    } else {
      setIsLikeing(false);
    }
  };

  return (
    <InsideTopicPostWrapper>
      <div className="inside_replymain" key={key}>
        <div className="left_side">
          <img
            className="howare"
            src={
              data.userAvatar
                ? `http://community.solarhub24.de${data.userAvatar.replace(
                    "{size}",
                    "50"
                  )}`
                : ladki
            }
            style={{ marginRight: "10px" }}
            alt=""
          />
          <div className="line"></div>
        </div>
        <div className="right_side">
          <div className="flex_text">
            <span className="imp">{data.username}</span>
            {parse(data.description)}
          </div>
          <div className="allThings">
            <div className="sameText">
              {data.updateAt
                ? moment(new Date(data.updateAt)).startOf("hour").fromNow()
                : moment(new Date(data.createAt)).startOf("hour").fromNow()}
            </div>
            <div className="sameText">{data.likeCount} likes</div>
            <div
              className="other_same"
              onClick={() => {
                setIsReplyTrue(true);
                setIsEditTrue(false);
                setDetect(true);
                setShowReplyBox(true);
                setDescritption(`@${data.username}`);
              }}
            >
              {isEnglish ? "reply" : "Antwort"}
            </div>
            {isOtherFunTrue ? (
              <>
                <div
                  className="sameText"
                  onClick={() => {
                    if (userName !== data.username) {
                      return toast.error(
                        "you don't have permission to update the post"
                      );
                    }
                    setIsEditTrue(true);
                    setIsReplyTrue(false);
                    setEditedDecriptData(
                      decryptDescription(parse(data.description))
                    );
                    setPostId(data.id);
                    setShowReplyBox(true);
                  }}
                >
                  edit
                </div>
                <div
                  className="sameText"
                  onClick={() => deleteHandler(data.id, data.username)}
                >
                  {isEnglish ? "delete" : "löschen"}
                </div>
                <div
                  className="other_same"
                  onClick={() => {
                    setIsOtherFucTrue(false);
                    setDescritption("");
                    setDetect(false);
                    setIsReplyTrue(false);
                    setIsEditTrue(false);
                  }}
                >
                  {isEnglish ? "cancel" : "Abbrechen"}
                </div>
              </>
            ) : (
              ""
            )}
            {isOtherFunTrue ? (
              <img src={sharee} alt="" />
            ) : userName !== data.username ? (
              ""
            ) : (
              <img src={menu} onClick={() => setIsOtherFucTrue(true)} alt="" />
            )}
          </div>
        </div>
        <div className="lastpart">
          {data.username === userName ? (
            ""
          ) : !likeing ? (
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!userLikeArray.includes(userName)) {
                  LikeHandler(data.id);
                } else {
                  deleteLikeHandler(data.id);
                }
              }}
              src={
                isLikeTrue || userLikeArray.includes(userName) ? like : heart
              }
              alt=""
            />
          ) : (
            <div class="dot-pulse"></div>
          )}
        </div>
      </div>
    </InsideTopicPostWrapper>
  );
};
// yash
export default InsideTopicPost;
