import { default_quote_data } from "../../lib/default_quote";

const INITIAL_STATE = {
  quote: default_quote_data,
  errorMessage: undefined,
  imgUrl: "/images/application/application_step_1/img-loading1.gif",
  projectRequestTime: undefined,
  status: null,
  crn_requested: null,
  isImageIsFeatched: false,
};

const quoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_QUOTE_SUCCESS":
      return {
        ...state,
        quote: action.payload,
        status: "matched",
      };
    case "SET_CRN_REQUEST":
      return {
        ...state,
        crn_requested: action.payload,
      };
    case "FETCH_QUOTE_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        projectRequestTime: Date.now(),
        status: "notFound",
      };
    case "SET_IMG_URL":
      return {
        ...state,
        imgUrl: action.payload,
        isImageIsFeatched: true,
      };
    case "SET_QUOTE_ANALYZED":
      return {
        ...state,
        isQuoteAnalyzed: true,
      };
    default:
      return state;
  }
};

export default quoteReducer;
