import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { Button, makeStyles } from "@material-ui/core";
import DashboardHeader from "Components/dashboardHeader";
import FeatureContributers from "./FeatureContributers";
import Popular from "Assets/community/logo2.svg";
import { ReactComponent as Logo } from "Assets/community/logo1.svg";
import FAQ from "Assets/community/faaq.svg";
import Saved from "Assets/community/saved.svg";
import Profile from "Assets/community/setting.svg";
import noti from "Assets/community/noti.svg";
import RightSideOfPage from "./RightSideOfPage";
import { useSelector } from "react-redux";
import cometChat from "Assets/vectors/cometchat.svg";
import closeIcon from "Assets/vectors/closeicon.svg";
import MobileHeader from "./MobileHeader";

const SidebarWrapper = styled("div")`
  .navbar {
    background-color: white;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  .hub_button {
    background: #ffd75d;
    height: 42px;
    width: 205px;
    border: none;
    border-radius: 42px;
    margin-bottom: 18px;
    color: rgba(45, 71, 100, 1);
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-menu {
    background-color: rgba(255, 255, 255, 1);
    margin-top: 3px;
    width: 285px;
    height: 100vh;
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    display: flex;
    justify-content: start;
    flex-direction: column;
    transition: 850ms;
  }
  .solar_text {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    color: #ffff;
    div {
      font-family: Comfortaa;
      font-style: normal;
      font-weight: bold;
      width: 133px;
      font-size: 23.0681px;
      line-height: 26px;
      letter-spacing: 0.1em;
      color: #ffbf1b;
    }
  }
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  #active {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    /* dark blue */
    color: #2d4764;
  }
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 60px;
  }
  .nav-text .link {
    text-decoration: none;
    color: #c7c7c7;
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .nav-text > .link:hover {
    cursor: pointer;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #2d4764;
  }
  .nav-menu-items {
    padding: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-menu-items.open {
    width: 225px;
  }
  .upper_class {
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .upper_class::-webkit-scrollbar {
    display: none;
  }
  .navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .yourSpan {
    margin-left: 20px;
  }
  .new_class {
    width: 306px;
  }
`;

const useStyles = makeStyles((theme) => ({
  top_text: {
    margin: "0px",
    marginLeft: "20px",
  },
  cometChat: {
    backgroundColor: "white",
    padding: "10px 15px",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    height: "100%",
  },
  first_contaienr: {
    width: "712px",
    "@media (max-width: 600px)": {
      fontSize: "28px",
      width: "100%",
    },
    height: "100%",
    backgroundColor: "#ffffff",
  },
  secound_contaienr: {
    width: "403px",
    height: "100%",
    display: "flex",
    borderLeft: "1px solid #C4C4C4",
    borderBottom: "1px solid #C4C4C4",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "#ffff",
    padding: "15px",
  },
  text1: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "800",
    marginBottom: "10px",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#2D4764",
  },
  text2: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "25px",
    color: "#666666",
  },
  bottomFixedIcons: {
    display: "flex",
    justifyContent: "center",
    gap: 25,
    position: "fixed",
    bottom: "30px",
    right: "30px",
    alignItems: "center",
    zIndex: 100,
  },
}));

const Routes = [
  {
    title: "Home",
    path: "/community/home",
    icon: <Logo className="home__icon" />,
    cName: "nav-text",
    germanTitle: "Home",
  },
  {
    title: "Popular",
    path: "/community/popular",
    icon: <img src={Popular} alt="popular" />,
    cName: "nav-text",
    germanTitle: "Beliebte Themen",
  },
  {
    title: "Notification",
    path: "/community/notification",
    icon: <img src={noti} alt="notification" />,
    cName: "nav-text",
    germanTitle: "Benachrichtigungen",
  },
  {
    title: "FAQ",
    path: "/community/faq",
    icon: <img src={FAQ} alt="faq" />,
    cName: "nav-text",
    germanTitle: "FAQ",
  },
  {
    title: "Saved",
    path: "/community/saved",
    icon: <img src={Saved} alt="saved" />,
    cName: "nav-text",
    germanTitle: "Gespeicherte Themen",
  },
  {
    title: "Profile",
    path: "/community/profile",
    icon: <img src={Profile} alt="home" />,
    cName: "nav-text",
    germanTitle: "Profil",
  },
];

const Layout = ({ children }) => {
  const [show_chat, setshow_chat] = useState(false);
  let location = useLocation();
  const classes = useStyles();
  const currentUser = useSelector((state) => state.user.currentUser);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [minimized, setminimized] = useState(false);
  const [minimizedUser, setminimizedUser] = useState(null);
  const history = useHistory();

  const minimizedHandler = (check, context) => {
    if (check == "close") {
      setminimized(false);
    } else {
      setminimized((prev) => !prev);
    }
    if (context?.item) {
      setminimizedUser(context.item?.uid);
    } else {
      setminimizedUser();
    }
  };

  return (
    <SidebarWrapper>
      <div className="upper_class">
        {window.innerWidth > 500 ? <DashboardHeader /> : <MobileHeader />}
        <div
          style={{
            display: "flex",
            borderTop: "1px solid #DFDFDF",
            maxWidth: "1400px",
            margin: "auto",
          }}
        >
          {window.innerWidth > 600 ? (
            <div style={{ width: "285px" }}>
              <nav className="nav-menu">
                <div
                  className="solar_text"
                  style={{
                    justifyContent: isEnglish ? "center" : "flex-start",
                    paddingLeft: isEnglish ? "" : "34px",
                  }}
                >
                  <div>Solar Community</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ul className={`nav-menu-items ${!isEnglish && "open"}`}>
                    {Routes.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={item.cName}
                          id={location.pathname == item.path ? "active" : ""}
                        >
                          <div
                            className="link"
                            onClick={() => history.push(item.path)}
                          >
                            {item.icon}
                            <span
                              className="yourSpan"
                              id={
                                location.pathname == item.path ? "active" : ""
                              }
                            >
                              {isEnglish ? item.title : item.germanTitle}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <button
                  className="hub_button"
                  onClick={() => history.push("/community/knowledgehub")}
                >
                  {isEnglish ? "Knowledge hub" : "Solarwissen"}
                </button>
                <FeatureContributers />
              </nav>
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              height: "calc(100vh - 66px)",
              width: "100%",
              display: "flex",
            }}
          >
            <div className={classes.first_contaienr}>{children}</div>
            {window.innerWidth > 500 ? <RightSideOfPage /> : ""}
          </div>
        </div>
        <div className={classes.bottomFixedIcons}>
          {currentUser ? (
            <div
              style={{
                display:
                  window.innerWidth < 500 &&
                  window.location.pathname.includes("home")
                    ? "none"
                    : "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 58,
                minHeight: 51,
                userSelect: "none",
              }}
              onClick={() => {
                setshow_chat((prev) => !prev);
                setminimized(false);
              }}
              className={classes.cometChat}
            >
              <div>
                <img src={show_chat ? closeIcon : cometChat} alt="cometchat" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </SidebarWrapper>
  );
};

const cometChatstyle = (show_chat) => {
  let opacity = show_chat ? { opacity: 1 } : { opacity: 0 };
  let pointerEvent = show_chat ? {} : { pointerEvents: "none" };

  return {
    position: "fixed",
    right: 17,
    bottom: 100,
    zIndex: 100,
    transition: "all ease 0.5",
    ...opacity,
    ...pointerEvent,
  };
};

export default Layout;
