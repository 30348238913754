import { makeStyles } from "@material-ui/core";
import React from "react";
import NearByProjects from "../pages/home/partials/NearByProjects";
import styled from "styled-components";
import { useSelector } from "react-redux";

const RightSideOfPageWrapper = styled("div")`
  .navbar {
    background-color: white;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  .hub_button {
    background: #2d4764;
    height: 42px;
    width: 219px;
    border: none;
    border-radius: 42px;
    margin-bottom: 18px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    margin-left: 28px;
    margin-top: 15px;
  }
  .nav-menu {
    background-color: rgba(255, 255, 255, 1);
    margin-top: 3px;
    width: 279px;
    height: 100vh;
    margin-left: 8px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    position: fixed;
    top: 69px;
    left: -100%;
    transition: 850ms;
  }
  .solar_text {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    color: #ffff;
    div {
      font-family: Comfortaa;
      font-style: normal;
      font-weight: bold;
      width: 133px;
      font-size: 23.0681px;
      line-height: 26px;
      letter-spacing: 0.1em;
      color: #ffbf1b;
    }
  }
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  #active {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    /* dark blue */
    color: #2d4764;
  }
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 60px;
  }
  .nav-text a {
    text-decoration: none;
    color: #c7c7c7;
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .nav-text a:hover {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    /* dark blue */

    color: #2d4764;
  }
  .nav-menu-items {
    width: 100%;
    padding: 0px;
    padding-left: 65px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .yourSpan {
    margin-left: 20px;
  }
  @media (max-width: 600px) {
    .first_contaienr {
      width: 100%;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  top_text: {
    margin: "0px",
    marginLeft: "20px",
  },
  root: {
    width: "100%",
    display: "flex",
    height: "100%",
  },
  first_contaienr: {
    width: "89%",
    height: "100%",
    backgroundColor: "#F4F4F4",
  },
  secound_contaienr: {
    width: "357px",
    height: "100%",
    backgroundColor: "#ffff",
    padding: "15px",
    borderLeft: "1px solid #c4c4c4",
    "@media (max-width: 560px)": {
      border: "none",
      marginTop: "21px",
      width: "100%",
    },
  },
  text1: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "800",
    marginBottom: "10px",
    fontSize: "18px",
    lineHeight: "25px",
    color: "#2D4764",
  },
  text2: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "25px",
    color: "#666666",
  },
}));

const RightSideOfPage = () => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);

  return (
    <RightSideOfPageWrapper>
      <div className={classes.secound_contaienr}>
        <div
          className="new_class"
          // style={{ width: isEnglish ? "306px" : "334px" }}
        >
          <div className={classes.text1}>
            {isEnglish
              ? "Succesfully realized PV projects in your area"
              : "Erfolgreich umgesetzte Solarprojekte in deiner Gegend"}
          </div>
          <div className={classes.text2}>
            {isEnglish
              ? "find your local Solar Pioneers from your area Ihrer Umgebung derIhnen bei der Projektumsetzung weiterhelfen kann"
              : "Finde lokale Solar Pioniere aus deiner Gegend, die ihnen durch ihre Erfahrung bei der Umsetzung ihres Projektes weiterhelfen können"}
          </div>
          <NearByProjects />
        </div>
      </div>
    </RightSideOfPageWrapper>
  );
};

export default React.memo(RightSideOfPage);
