import React, { useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  TextField,
} from "@material-ui/core";
import evCar1 from "../../../Assets/evCar1.png";
import evCar2 from "../../../Assets/evCar2.png";
import evCar3 from "../../../Assets/evCar3.png";
import { YearlyConsumptionInput } from "./Company";
import center from "@turf/center";
import setCommaDot, { commaDotHandler, parseLocaleNumber } from "helperMethod";
import { calculated_consumption_b2b } from "utils/consumption";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    maxHeight: 345,
  },
  center0: {
    textAlign: "center",
    color: "#2D4764",
  },
  center: {
    width: "300px",
    textAlign: "center",
    color: "#2D4764",
  },
  center1: {
    width: "204px",
    textAlign: "center",
    color: "#2D4764",
    marginBottom: "40px",
  },
  option_container: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 530px)": {
      flexwrap: "wrap",
    },
  },
  ElectroOption: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ps_stepper: {
    display: "flex",
    flexDirection: "row",
    marginTop: "17px",
    alignItems: "center",
    justifyContent: "center",
  },
  ps_stepper_plus: {
    width: "35px",
    height: "35px",
    background: "#DFDFDF",
    border: "1px solid #DFDFDF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: "600",
  },
  ps_stepper_value: {
    width: "80px",
    height: "35px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1px solid #DFDFDF",
  },
  input_kelometer: {
    display: "flex",
    color: "#2D4764",
    width: "100%",
    marginBottom: "20px",
    marginTop: "20px",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  input_main: {
    height: "130px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  },
  button: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputStyle: {
    outline: "none",
    boxShadow: "none",
    height: "35px",
    width: "73px",
    textAlign: "center",
    border: "1px solid lightgray",
  },
  useElectricity: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "& h6": {
      fontSize: "18px",
      textAlign: "left",
      color: "#2D4764",
    },
  },
}));
const Image = (props) => {
  return (
    <>
      <img src={props.src} alt="" />
      <div
        style={{
          marginTop: "2px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#2D4764",
          fontSize: "14px",
        }}
      >
        {props.text}
      </div>
    </>
  );
};
const ElectroMobility = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption_b2b(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );
  const classes = useStyles();
  const [carCount, setCarCount] = useState(consumption_details.no_of_cars);
  const [energyCounsumption, setEnergyCounsumption] = useState(
    consumption_details.kwa_per_100km
  );
  const handleChange = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g, ""));
    setEnergyCounsumption(value);
    setConsumptionDetails({
      ...consumption_details,
      kwa_per_100km: value,
    });
    setTemp1(
      calculated_consumption_b2b({
        ...consumption_details,
        kwa_per_100km: value,
      })
    );
  };
  const { electroMobilityType } = consumption_details;
  const handleChange1 = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g, ""));
    setCarCount(value);
    setConsumptionDetails({
      ...consumption_details,
      no_of_cars: value,
    });
    setTemp1(
      calculated_consumption_b2b({
        ...consumption_details,
        no_of_cars: value,
      })
    );
  };
  return (
    <div>
      <h4 className={classes.center0}>
        {isEnglish
          ? "Are you planning to use electromobility in your company?"
          : "Sie planen den Einsatz von Elektromobilität in Ihrem Unternehmen?"}
      </h4>
      <div className={classes.option_container}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={consumption_details.electroMobilityType === "type 1"}
                  onChange={(checked) => {
                    setConsumptionDetails({
                      ...consumption_details,
                      electroMobilityType: "type 1",
                    });
                    setTemp1(
                      calculated_consumption_b2b({
                        ...consumption_details,
                        electroMobilityType: "type 1",
                      })
                    );
                  }}
                />
              }
              label={
                <Image
                  src={evCar1}
                  text={isEnglish ? "Available" : "Erhältlich"}
                />
              }
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={consumption_details.electroMobilityType === "type 2"}
                  onChange={(checked) => {
                    setConsumptionDetails({
                      ...consumption_details,
                      electroMobilityType: "type 2",
                    });
                    setTemp1(
                      calculated_consumption_b2b({
                        ...consumption_details,
                        electroMobilityType: "type 2",
                      })
                    );
                  }}
                />
              }
              label={
                <Image src={evCar2} text={isEnglish ? "Planned" : "Geplant"} />
              }
              labelPlacement="top"
            />{" "}
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  color="primary"
                  checked={consumption_details.electroMobilityType === "type 3"}
                  onChange={(checked) => {
                    setConsumptionDetails({
                      ...consumption_details,
                      electroMobilityType: "type 3",
                    });
                    setTemp1(
                      calculated_consumption_b2b({
                        ...consumption_details,
                        electroMobilityType: "type 3",
                      })
                    );
                  }}
                />
              }
              label={
                <Image
                  src={evCar3}
                  text={isEnglish ? "Not Planned" : "Nicht geplant"}
                />
              }
              labelPlacement="top"
            />{" "}
          </FormGroup>
        </FormControl>
      </div>
      <div className={classes.ElectroOption}>
        <div>
          <h4 className={classes.center}>
            {isEnglish
              ? " Electromobility per 100 km and vehicle in kWh (medium assumption)"
              : "Elektromobilität pro 100 km und Fahrzeug in kWh (mittlere Annahme)"}
          </h4>
          <div className={classes.ps_stepper}>
            <div
              className={classes.ps_stepper_plus}
              onClick={() => {
                setEnergyCounsumption((prevstate) => {
                  setConsumptionDetails({
                    ...consumption_details,
                    kwa_per_100km: prevstate - 1,
                  });
                  setTemp1(
                    calculated_consumption_b2b({
                      ...consumption_details,
                      kwa_per_100km: prevstate - 1,
                    })
                  );
                  return prevstate - 1;
                });
              }}
            >
              -
            </div>
            <input
              className={classes.inputStyle}
              value={energyCounsumption}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <div
              className={classes.ps_stepper_plus}
              onClick={() => {
                setEnergyCounsumption((prevstate) => {
                  setConsumptionDetails({
                    ...consumption_details,
                    kwa_per_100km: prevstate + 1,
                  });
                  setTemp1(
                    calculated_consumption_b2b({
                      ...consumption_details,
                      kwa_per_100km: prevstate + 1,
                    })
                  );
                  return prevstate + 1;
                });
              }}
            >
              +
            </div>
          </div>
        </div>
        <div>
          <h4 className={classes.center1}>
            {isEnglish ? "Number of cars" : "Anzahl der Autos"}
          </h4>
          <div className={classes.ps_stepper}>
            <div
              className={classes.ps_stepper_plus}
              onClick={() => {
                setCarCount((prevstate) => {
                  setConsumptionDetails({
                    ...consumption_details,
                    no_of_cars: prevstate - 1,
                  });
                  setTemp1(
                    calculated_consumption_b2b({
                      ...consumption_details,
                      no_of_cars: prevstate - 1,
                    })
                  );
                  return prevstate - 1;
                });
              }}
            >
              -
            </div>
            <input
              className={classes.inputStyle}
              value={carCount}
              onChange={(e) => {
                handleChange1(e);
              }}
            />
            <div
              className={classes.ps_stepper_plus}
              onClick={() => {
                setCarCount((prevstate) => {
                  setConsumptionDetails({
                    ...consumption_details,
                    no_of_cars: prevstate + 1,
                  });
                  setTemp1(
                    calculated_consumption_b2b({
                      ...consumption_details,
                      no_of_cars: prevstate + 1,
                    })
                  );
                  return prevstate + 1;
                });
              }}
            >
              +
            </div>
          </div>
        </div>
      </div>
      <div className={classes.input_kelometer}>
        <h4 className={classes.center0}>
          {isEnglish
            ? "Expected yearly kilometers travelled"
            : "Erwartete jährliche Kilometer pro Auto"}
        </h4>
        <TextField
          inputProps={{
            min: 0,
            style: { textAlign: "center", width: "100px" },
          }}
          placeholder="10,000 kms"
          value={`${consumption_details?.yearly_kms}`}
          onChange={(e) => {
            setConsumptionDetails({
              ...consumption_details,
              yearly_kms: e.target.value,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                yearly_kms: e.target.value,
              })
            );
          }}
        />
      </div>
      {electroMobilityType === "type 1" ? (
        <div className={classes.useElectricity}>
          <Checkbox
            color="primary"
            checked={consumption_details?.isCarChanrgeDuringSunshine}
            onChange={(e) => {
              console.log(e.target.checked, "checked");
              const checked = e.target.checked;
              if (checked) {
                if (consumption_details.self_supply + 0.2 >= 1) {
                  setConsumptionDetails({
                    ...consumption_details,
                    isCarChanrgeDuringSunshine: true,
                  });
                } else {
                  setConsumptionDetails({
                    ...consumption_details,
                    isCarChanrgeDuringSunshine: true,
                    self_supply: consumption_details.self_supply + 0.2,
                  });
                }
              } else {
                if (consumption_details?.isCarChanrgeDuringSunshine) {
                  if (consumption_details.self_supply + 0.2 >= 1) {
                    setConsumptionDetails({
                      ...consumption_details,
                      isCarChanrgeDuringSunshine: false,
                    });
                  } else {
                    setConsumptionDetails({
                      ...consumption_details,
                      isCarChanrgeDuringSunshine: false,
                      self_supply: consumption_details.self_supply - 0.2,
                    });
                  }
                } else {
                  setConsumptionDetails({
                    ...consumption_details,
                    isCarChanrgeDuringSunshine: false,
                  });
                }
              }
            }}
          />
          <h6>
            {isEnglish
              ? "Yes I can charge the cars mostly during sunshine hours (increase self supply by 20%)"
              : "Ja ich kann das Auto hauptsächlich während der Sonnenstunden laden (Eigenverbrauch um 20% erhöhen)"}
          </h6>
        </div>
      ) : (
        ""
      )}
      <YearlyConsumptionInput
        consumption={consumption}
        setConsumption={setConsumption}
        temp1={temp1}
        setTemp1={setTemp1}
      />
    </div>
  );
};

export default ElectroMobility;
