import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Group from "../SignUpSignIn/images/Group.svg";
import Close from "../../Assets/Close.svg";
import { ProductPopupWrapper } from "./ProductPopupStyles";
import FirstHeader from "./firstHeader/FirstHeader";
import ProductCard from "./productCard/ProductCard";
import { Empty } from "antd";
import { db } from "../../firebase";
import BatteryHeader from "./firstHeader/BatteryHeader";
import HeatPumpHeader from "./firstHeader/HeatPumpHeader";
import WallBoxHeader from "./firstHeader/WallBoxHeader";
import Slider from "react-slick";
import InverterHeader from "./firstHeader/InverterHeader";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import PopupCardForMobile from "./productCard/PopupCardForMobile";
import { useSelector } from "react-redux";

const ProductPopup = ({
  setOpen,
  open,
  data,
  type,
  isAdd,
  setIsAdd,
  setType,
}) => {
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const [list, setList] = useState([]);
  const [isListOpen, setIsListOpen] = useState(false);
  const [needToFilterList, setNeedToFilterList] = useState([]);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    if (isAdd) {
      setIsListOpen(isAdd);
    }
  }, [isAdd]);
  console.log(data, "sui");
  const handleClose = () => {
    setOpen(false);
    setList([]);
    setIsListOpen(false);
    setIsAdd(false);
    setType(null);
    setNeedToFilterList([]);
    setFilterData([]);
  };

  useEffect(() => {
    if (isAdd && type) {
      db.collection("products")
        .where("type", "==", type)
        .get()
        .then((snap) => {
          let data = [];
          let newsItem = {};
          snap.forEach((doc, key) => {
            newsItem = { ...doc.data(), newsId: doc.data().objectId };
            const datas = {
              item: newsItem,
              extended_warranty: false,
              quantity: 1,
            };
            data.push(datas);
          });
          setList(data);
          setFilterData(data);
        });
    }
  }, [type, open, isAdd]);

  useEffect(() => {
    if (needToFilterList?.length) {
      const result = list.filter((data) => {
        for (let i = 0; i < data?.item?.properties?.length; i++) {
          return needToFilterList?.includes(
            data?.item?.properties[i]?.title
              ? data?.item?.properties[i]?.title
              : data?.item?.properties[i]
          );
        }
      });
      setFilterData(result);
    } else {
      setFilterData(list);
    }
  }, [needToFilterList]);

  console.log(isAdd, "list");

  const component = {
    battery: (
      <BatteryHeader
        setNeedToFilterList={setNeedToFilterList}
        needToFilterList={needToFilterList}
        isListOpen={isListOpen}
        setFilterData={setFilterData}
        list={list}
      />
    ),
    heatpump: (
      <HeatPumpHeader
        setNeedToFilterList={setNeedToFilterList}
        needToFilterList={needToFilterList}
        isListOpen={isListOpen}
      />
    ),
    solar_panel: (
      <FirstHeader
        setNeedToFilterList={setNeedToFilterList}
        needToFilterList={needToFilterList}
        isListOpen={isListOpen}
      />
    ),
    wallbox: (
      <WallBoxHeader
        setNeedToFilterList={setNeedToFilterList}
        needToFilterList={needToFilterList}
        isListOpen={isListOpen}
      />
    ),
    inverter: (
      <InverterHeader
        setNeedToFilterList={setNeedToFilterList}
        needToFilterList={needToFilterList}
        isListOpen={isListOpen}
        setFilterData={setFilterData}
        list={list}
      />
    ),
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className="arrow_icon"
        style={{
          ...style,
          display:
            onClick === null || window.innerWidth < 383 ? "none" : "block",
          position: "absolute",
          right: window.innerWidth > 500 ? "-20px" : "-25px",
          top: window.innerWidth > 500 ? "40%" : "40%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIos
        className="arrow_icon"
        style={{
          ...style,
          display:
            onClick === null || window.innerWidth < 383 ? "none" : "block",
          position: "absolute",
          left: window.innerWidth > 500 ? "-25px" : "-22px",
          top: window.innerWidth > 500 ? "41%" : "40%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: window.innerWidth > 500 ? 3 : 1,
    slidesToScroll: 1,
    // afterChange: function (currentSlide) {
    //   console.log(currentSlide, "currentSlide");
    // },
    beforeChange: function (prev, next) {
      console.log(prev, next, "currentSlide");
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <ProductPopupWrapper>
        <div className="product_main">
          <img
            onMouseEnter={() => setWhichIconShouldBeVisible(true)}
            onMouseLeave={() => setWhichIconShouldBeVisible(false)}
            src={whichIconShouldBeVisible ? Close : Group}
            className="img_set"
            onClick={handleClose}
          />
          {component[type]}
          <div className="product_list_container">
            {!isAdd ? (
              window.innerWidth > 500 ? (
                <ProductCard
                  details={data?.project_details?.components?.items?.[type]}
                  mainData={data}
                  setList={setList}
                  setIsListOpen={setIsListOpen}
                  setOpen={setOpen}
                  setFilterData={setFilterData}
                  setNeedToFilterList={setNeedToFilterList}
                  isListOpen={isListOpen}
                  type={type}
                  list={list}
                />
              ) : (
                <PopupCardForMobile
                  details={data?.project_details?.components?.items?.[type]}
                  mainData={data}
                  setList={setList}
                  setIsListOpen={setIsListOpen}
                  setOpen={setOpen}
                  setFilterData={setFilterData}
                  setNeedToFilterList={setNeedToFilterList}
                  isListOpen={isListOpen}
                  type={type}
                  list={list}
                />
              )
            ) : (
              ""
            )}
            {/* {isListOpen ? <div className="linne"></div> : ""} */}
            {window.innerWidth > 500 ? (
              filterData?.length > 0 ? (
                filterData?.map((datanews, index) => {
                  return (
                    <>
                      <ProductCard
                        details={datanews}
                        setFilterData={setFilterData}
                        mainData={data}
                        isSelectTrue={true}
                        setIsListOpen={setIsListOpen}
                        isListOpen={isListOpen}
                        needToFilterList={needToFilterList}
                        setNeedToFilterList={setNeedToFilterList}
                        type={type}
                        setOpen={setOpen}
                        setList={setList}
                        list={list}
                      />
                      {index !== filterData.length - 1 ? (
                        <div className="linne"></div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              ) : isListOpen && !filterData?.length > 0 && list.length > 0 ? (
                <Empty
                  style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  description={
                    isEnglish
                      ? "No product available"
                      : "keine Produkte verfügbar"
                  }
                />
              ) : (
                ""
              )
            ) : filterData?.length > 0 ? (
              <Slider
                {...settings}
                style={{
                  width: window.innerWidth < 560 ? "94%" : "100%",
                  margin: window.innerWidth < 560 ? "auto" : "",
                }}
              >
                {filterData?.map((datanews, index) => {
                  return (
                    <>
                      <PopupCardForMobile
                        details={datanews}
                        setFilterData={setFilterData}
                        mainData={data}
                        isSelectTrue={true}
                        setIsListOpen={setIsListOpen}
                        isListOpen={isListOpen}
                        needToFilterList={needToFilterList}
                        setNeedToFilterList={setNeedToFilterList}
                        type={type}
                        setOpen={setOpen}
                        setList={setList}
                        list={list}
                      />
                    </>
                  );
                })}
              </Slider>
            ) : isListOpen && !filterData?.length > 0 && list.length > 0 ? (
              <Empty
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
                description={
                  isEnglish
                    ? "No product available"
                    : "keine Produkte verfügbar"
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </ProductPopupWrapper>
    </Dialog>
  );
};

export default ProductPopup;
