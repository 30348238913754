import React from "react";
import { Dialog } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LoadCircle from "Assets/vectors/LoadCircle";
import Hero from "Assets/Hero.svg";

const ProjectAnalyzedPopup = (props) => {
  return (
    <Dialog
      onClose={() => {
        props.setopen(false);
      }}
      open={props.open}
    >
      <div
        style={{
          padding: 40,
          width: 600,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div>
          <div style={{ fontSize: 20, color: "#2D4764" }}>
            Great News -<br /> we have successfully analyzed your roof!
          </div>
          <div style={{ fontSize: 16, marginTop: 10, width: 250 }}>
            You can start exploring your project now - if you need help reach
            out to our experts
          </div>
          <Button
            onClick={() =>
              window.location.assign(
                `/application/b2c/default-${props.address.complete}`
              )
            }
            startIcon={<LoadCircle />}
            style={{
              background: "#2D4764",
              color: "white",
              marginTop: 16,
              padding: 10,
            }}
          >
            LOAD YOUR PROJECTS
          </Button>
        </div>
        <div>
          <img src={Hero} height={200} width={200} alt="" />
        </div>
      </div>
    </Dialog>
  );
};

export default ProjectAnalyzedPopup;
