import { roofPitchFactor } from "../lib/roofPitchFactor_db";
import {
  calculated_consumption,
  calculated_consumption_b2b,
} from "./consumption";

export const getNumber = (x) => parseFloat(x ? x : 0);

export const totalComponentsCost = (project) => {
  let componentCosts = 0;
  if (project?.components?.items) {
    for (let i in project?.components?.items) {
      if (project?.components?.items[i].item) {
        const productCost =
          project?.components?.items[i]?.quantity *
          getNumber(
            project?.components?.items[i]?.item[
              project.isB2B ? "price_b2b" : "price_b2c"
            ]
          );
        const extendedWarrantyCost = project?.components?.items[i]
          ?.extended_warranty
          ? getNumber(
              project?.components?.items[i]?.item?.extended_warranty_cost ||
                productCost * 0.01
            )
          : 0;
        componentCosts = componentCosts + productCost;
      }
    }
  }
  return componentCosts;
};

export const totalProjectCost = (project) => {
  let total_project_cost =
    totalComponentsCost(project) + additionalCostWithConstants(project);

  return total_project_cost;
};

export const financingDetails = (project) => {
  const total_project_cost = totalProjectCost(project);
  const finacing_datails = {
    total_project_cost: total_project_cost,
    duration_in_years: 10,
    total_interest: total_project_cost * 0.0099 * 0.51 * 10,
    total_financing_amount:
      total_project_cost + total_project_cost * 0.0099 * 0.51 * 10,
    yearly_financing_amount:
      (total_project_cost + total_project_cost * 0.0099 * 0.51 * 10) / 10,
  };

  return finacing_datails;
};

export const energyDetails = (project) => {
  const consumption = project?.isB2B
    ? calculated_consumption_b2b(project?.consumption_details_b2b)
    : calculated_consumption(project?.consumption_details);
  const self_supply_val = project?.isB2B
    ? project?.consumption_details_b2b.self_supply
    : project?.consumption_details.self_supply;
  let solar_panel_quantity = project?.components?.items?.solar_panel?.quantity;
  const solar_panel_capacity = getNumber(
    project?.components?.items?.solar_panel?.item?.capacity
  );
  const roof_pitch_factor = getNumber(
    roofPitchFactor[project?.roofPitch]?.factor
  );
  const energy_details = {
    total_production:
      Math.round(
        solar_panel_quantity * solar_panel_capacity * roof_pitch_factor
      ) + getNumber(project?.increseCapacityFromAdminPanle),
    consumption: consumption,
    total_self_supply: Math.round(consumption * self_supply_val),
    feed_into_grid: Math.round(
      solar_panel_quantity * solar_panel_capacity * roof_pitch_factor -
        consumption * self_supply_val
    ),
  };

  return energy_details;
};

export const costsAndSavings = (project) => {
  const consumption = project?.isB2B
    ? calculated_consumption_b2b(project?.consumption_details_b2b)
    : calculated_consumption(project?.consumption_details);

  const self_supply_factor = project?.isB2B
    ? project?.consumption_details_b2b.self_supply
    : project?.consumption_details.self_supply;

  const price_per_kWH = project?.isB2B
    ? project?.consumption_details_b2b.price_per_kWH
    : project?.consumption_details.price_per_kWH;

  //self_supply_factor=0.1 for 10%

  //elec cost = 0.9 * consumption= (1-self_supply_factor)*consumption*price_per_kwh
  let feedin_savings = energyDetails(project)?.feed_into_grid * 0.0595;
  let elec_bill_after =
    (1 - self_supply_factor) * consumption * getNumber(price_per_kWH);
  const electricity_cost_before = consumption * getNumber(price_per_kWH);
  let saving_elec_bill = electricity_cost_before - elec_bill_after;
  const return_from_supply_to_grid =
    energyDetails(project)?.feed_into_grid * 0.0595;
  const costs_and_savings = {
    estimated_yearly_price_increase:
      project.costs_and_savings.estimated_yearly_price_increase,
    electricity_cost_before: saving_elec_bill,
    feedin_savings: energyDetails(project)?.feed_into_grid * 0.0595,
    total_savings: getNumber(
      electricity_cost_before + return_from_supply_to_grid
    ),
  };

  return costs_and_savings;
};

const additionalCostWithConstants = (project) => {
  let additionalCosts = 0;
  const total_panel = project?.components?.items.solar_panel?.quantity;
  const solar_panel_capacity = getNumber(
    project?.components?.items?.solar_panel?.item?.capacity
  );
  const total_production = total_panel * solar_panel_capacity;

  const getExtra = (constant) => {
    const type = "Fix for project";
    switch (constant.Type) {
      case "Fix for project":
        return getNumber(constant["price_b2c"]);

      case "Depending on kWh":
        return (getNumber(constant["price_b2c"]) * total_production) / 1000;

      case "Fix per panel":
        return getNumber(constant["price_b2c"]) * total_panel;
      case "percentage of project cost":
        return (
          (getNumber(constant["price_b2c"]) * totalComponentsCost(project)) /
          100
        );
      default:
        alert("not found");
    }
  };
  project?.webConstants?.constants?.map((constant) => {
    additionalCosts = additionalCosts + getExtra(constant);
  });

  return Math.round(additionalCosts);
};
