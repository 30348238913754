import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import InvviteSent from "./InviteSent";
import Index from "./MainPage/index";
const SelectTimeModal = ({
  isModalVisible,
  setIsModalVisible,
  sender_uid,
  data,
  receiver_uid,
}) => {
  const [inviteState, setInviteState] = useState(true);
  const [discriptiion, setdiscriptiion] = useState("");
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalVisible}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleCancel}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {inviteState ? (
          <div>
            <Index
              handleCancel={handleCancel}
              data={data}
              sender_uid={sender_uid}
              setdiscriptiion={setdiscriptiion}
              discriptiion={discriptiion}
              receiver_uid={receiver_uid}
              setInviteState={setInviteState}
            />
          </div>
        ) : (
          <InvviteSent handleCancel={handleCancel} />
        )}
      </Modal>
    </div>
  );
};

export default SelectTimeModal;
