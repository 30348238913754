import React, { useState } from "react";
import { Dialog, Button, makeStyles, createStyles } from "@material-ui/core";
import Hero from "../../Assets/Hero.svg";
import Close from "../../Assets/Close.svg";
import Group from "../SignUpSignIn/images/Group.svg";
import { useDispatch, useSelector } from "react-redux";
import { Rate } from "antd";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1124px",
    overflow: "auto",
    overflowX: "hidden",
    height: "634px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height:"auto",
      justifyContent:"flex-start"
    },
    justifyContent: "center",
    alignItems: "center",
  },
  button_group: {
    backgroundColor: "#FFD75D",
    border: "none",
    borderRadius: "16px",
    fontSize: "15px",
    height: "41px",
    width: "268px",
    fontWeight: "600px",
    [theme.breakpoints.down("xs")]: {
      width:"224px",
      height:"34px",
      padding:"9px",
      fontSize:"13px"
     },
    color: "#2D4764",
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
    marginBottom: "15px",
    textAlign: "center",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const WelcomeUserNoData = ({ open, setOpen }) => {
  const classes = useStyles();
  const dispatch=useDispatch()
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
  };
  return (
    <Dialog onClose={handleClose} open={open} maxWidth={false} style={{margin:"-16px"}}>
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => {
          setOpen(false)
          dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
          }}
        />
      </div>
      <div className={classes.root}>
        <div style={{ marginTop: "40px" }}>
          <Payment />
        </div>
        <div>
          <img src={Hero} style={{ height: "275px" }} alt="" />
        </div>
        <button
          className={classes.button_group}
          onClick={() => {
            setOpen(false);
            dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
          }}
          variant="contained"
        >
          {isEnglish ? "Discover your project" : "Entdecke dein Projekt"}
        </button>
      </div>
    </Dialog>
  );
};

const Payment = () => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const useStyles = makeStyles((theme:Theme) =>
    createStyles({
      root: {
        height: "200px",
        width: "100%",
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      rool:{
        [theme.breakpoints.down("xs")]: {
          textAlign:"center"
         },
      },
      h1: {
        fontFamily: "Nunito",
        fontWeight: "700",
        marginBottom: "3px",
        fontSize: "36px",
        [theme.breakpoints.down("xs")]: {
          fontSize:isEnglish? "20px" : "20px",
          textAlign:"center"
         },
        color: "#304864",
      },
      h3: {
        fontFamily: "Nunito",
        fontWeight: "600",
        fontSize: "25px",
        [theme.breakpoints.down("xs")]: {
          fontSize:"15px",
          textAlign:"center"
         },
        marginBottom: "7px",
        color: "#304864",
      },
      roof: {
        fontFamily: "Nunito",
        fontWeight: "400",
        marginBottom: "7px",
        [theme.breakpoints.down("xs")]: {
          width:"100%",
          fontSize:"14px"
         },
        fontSize: "22px",
        color: "#4D7E6D",
      },
    })
  );

  const classes = useStyles();
  const [star, setStar] = useState(4.5);
  const handleOnChage = (value) => {
    
    setStar(value);
  };
  return (
    <div className={classes.root}>
      <h1 className={classes.h1}>
        {isEnglish
          ? "Welcome back to your new energy concept"
          : "Willkommen zurück bei Ihrem Solarenergiekonzept"}
      </h1>
      <h3 className={classes.h3}>
        {isEnglish
          ? "We are now creating a preliminary design for you - together we can make it happen!"
          : "Wir erstellen ein erstes PV Design für sie - zusammen können wir das Projekt umsetzen!"}
      </h3>
      <h3 className={classes.roof}>
        <div style={{textAlign:"center"}}>
          {isEnglish
            ? "Our experts are configuring your solar project. "
            : "Unsere Experten konfigurieren Ihr Solarprojekt."}
        </div>
        <div className={classes.rool}>
          {isEnglish
            ? "In the meantime you can play around with the sample data."
            : "In der Zwischenzeit können Sie das System mit Beispieldaten kennenlernen."}
        </div>
      </h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
};

export default WelcomeUserNoData;
