import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import { DataGrid } from "@material-ui/data-grid";
import { db } from "../../../firebase";
import SelectNewQuote from "./DataGridColumns/SelectNewQuote";
// import { Button } from "antd";
const buttonStyle = {
  backgroundColor: "#3f51b5",
  color: "white",
};
const SavedProject = () => {
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState("");
  const [quotesNames, setQuotesNames] = useState([]);
  const [selected_items, setselected_items] = useState([]);
  const [AllUser, setAllUser] = useState({});
  const [allMatched, setAllMatched] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    featchUserDeatils();
    isRequestCome();
  }, []);

  useEffect(() => {
    db.collection("quotes")
      .get()
      .then((snapshot) => {
        let names = [];
        snapshot.forEach((doc) => {
          names.push(doc.data()?.addr1 + ' ' + doc.data()?.zip_code + ' ' + doc.data()?.isVerified + ' ' + doc.data()?.flagOfQuote + ' ' +doc.data()?.qrn);
        });
        setQuotesNames(names);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const featchUserDeatils = async () => {
    let map = {};
    const result = await db
      .collection("users")
      .get()
      .then((snapshot) => {
        return snapshot;
      })
      .catch((err) => {
        return null;
      });
    result.forEach((doc) => {
      map[doc.data().uid] = doc.data();
    });
    setAllUser(map);
  };

  const isRequestCome = async () => {
    let map = {};
    const result = await db
      .collection("matched")
      .get()
      .then((snapshot) => {
        return snapshot;
      })
      .catch((err) => {
        return null;
      });
    result.forEach((doc) => {
      map[doc.data().pid] = doc.data();
    });
    setAllMatched(map);
  };
  const getFormattedDate = (date) => {
    if (date === "Sat Jan 01 233233 00:00:00 GMT+0530 (India Standard Time)") {
      return "--------";
    }
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${daysOfWeek[date.getDay()]} ${date.getDate()}.${month}.${year}`;
  };
  const getStatusOfImage = (doc) => {
    if (doc?.project_details?.project_images?.house_top?.total?.length) {
      return true;
    } else if (
      doc?.project_details?.project_images?.electric_meter?.total?.length
    ) {
      return true;
    } else if (
      doc?.project_details?.project_images?.house_bottom?.total?.length
    ) {
      return true;
    }
    return false;
  };
  const featSavePorjectData = async () => {
    console.time();
    let data = [];
    const docRef = await db.collection("saved_projects").get();
    docRef.forEach((doc) => {
      delete doc.dealId;
      if (doc.data()?.pid && doc.data().pid.length === 54) {
        data.push({
          ...doc.data(),
          total_savings: doc.data()?.costSaving?.total_savings,
          total_production: doc.data()?.energysDetails?.total_production,
          yearly_financing_amount:
            doc.data()?.financialDetails?.yearly_financing_amount,
          pid: doc.data()?.pid,
          address: doc.data()?.project_details?.address?.complete,
          "select-new-quote":doc.data()?.project_details?.address?.address + ' ' + doc.data()?.project_details?.address?.postcode + ' ' + doc.data()?.project_details?.profile + ' ' + doc.data()?.qrn,
          updatedAt: getFormattedDate(
            new Date(
              doc.data()?.updatedAt?.seconds
                ? doc.data()?.updatedAt?.seconds * 1000
                : 233233
            )
          ),
          createdAt: getFormattedDate(
            new Date(
              doc.data()?.createdAt?.seconds
                ? doc.data()?.createdAt?.seconds * 1000
                : 233233
            )
          ),
          userName: AllUser[doc.data()?.uid]
            ? AllUser[doc.data()?.uid]?.fullName
            : "",
          userEmail: AllUser[doc.data()?.uid]
            ? AllUser[doc.data()?.uid]?.email
            : "",
          userRequesetToInstaller: allMatched[doc.data()?.pid] ? true : false,
          userUploadedImage: getStatusOfImage(doc.data()),
        });
      }
    });
    console.timeEnd();
    let maps = data.map((datas) => {
      if (datas.hasOwnProperty("dealId")) {
        console.log("yes on it");
        delete datas.dealId;
      }
      console.log(datas);
      return datas;
    });
    setRowData(maps);
  };

  useEffect(() => {
    if (Object.keys(AllUser).length > 0 && Object.keys(allMatched).length > 0) {
      featSavePorjectData();
    }
  }, [open, AllUser, allMatched, count]);
  const columns = [
    {
      field: "userName",
      width: 150,
      editable: false,
    },
    {
      field: "userEmail",
      editable: false,
      width: 175,
    },
    { field: "userRequesetToInstaller", editable: false, width: 260 },
    { field: "userUploadedImage", editable: false, width: 220 },
    {
      field: "pid",
      width: 400,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              window.open(
                `https://app.solarhub24.de/application/saved-${params.value}?admin=true`,
                "_blank" // <- This is what makes it open in a new window.
              );
            }}
          >
            Edit project
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              window.open(
                `https://app.solarhub24.de/application/custom-${params.value}`,
                "_blank" // <- This is what makes it open in a new window.
              );
            }}
          >
            Open project for only review
          </Button>
        </div>
      ),
    },
    {
      field: "address",
      editable: false,
      width: 360,
    },
    {
      field: "select-new-quote",
      width: 600,
      renderCell: (params) => (
        <SelectNewQuote
          quotesNames={quotesNames}
          params={params}
        />
      ),
    },
    {
      field: "updatedAt",
      editable: false,
      width: 300,
      sortComparator: (v1, v2) => {
        const final = v1.split(" ")[1].split(".");
        const fullFinal = `${final[1]}.${final[0]}.${final[2]}`;
        const date1 = new Date(fullFinal);
        const final2 = v2.split(" ")[1].split(".");
        const fullFinal2 = `${final2[1]}.${final2[0]}.${final2[2]}`;
        const date2 = new Date(fullFinal2);
        return date1 < date2 ? 1 : -1;
      },
    },
    {
      field: "createdAt",
      editable: false,
      width: 300,
      sortComparator: (v1, v2) => {
        const final = v1.split(" ")[1].split(".");
        const fullFinal = `${final[1]}.${final[0]}.${final[2]}`;
        const date1 = new Date(fullFinal);
        const final2 = v2.split(" ")[1].split(".");
        const fullFinal2 = `${final2[1]}.${final2[0]}.${final2[2]}`;
        const date2 = new Date(fullFinal2);
        return date1 < date2 ? 1 : -1;
      },
    },
    {
      field: "total_savings",
      editable: false,
      width: 175,
    },
    {
      field: "total_production",
      editable: false,
      width: 175,
    },
  ];
  const deleteSelectedQuotes = () => {
    if (selected_items) {
      selected_items.forEach((item) => {
        db.collection("saved_projects")
          .doc(item)
          .delete()
          .then(() => {
            toast.success(
              "project deleted sucessfully wait some time to see the effect"
            );
            setCount((count) => count + 1);
          })
          .catch((err) => console.log(err));
      });
    } else {
      alert("Select Items to Delete");
    }
  };
  return (
    <>
      <Accordion expanded={true} style={{ flex: "1", width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>saved project Management</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="ag-theme-alpine"
            style={{
              flex: "1",
              minHeight: "65vh",
            }}
          >
            <Button style={buttonStyle} onClick={deleteSelectedQuotes}>
              Delete Projects
            </Button>
            {rowData.length > 0 ? (
              <DataGrid
                rows={rowData}
                getRowId={(row) => row.pid}
                columns={columns}
                onCellEditCommit={(values, event) => {
                  // handleChangeRow(values, event);
                }}
                pageSize={10}
                selectionModel={selected_items}
                pagination
                rowsPerPageOptions={[5]}
                paginationMode="server"
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(selection) => {
                  setselected_items(selection);
                }}
              />
            ) : (
              ""
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SavedProject;
