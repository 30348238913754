import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import "react-image-gallery/styles/css/image-gallery.css";
import { colors } from "../../colors";
import Close from "Assets/Close.svg";
import Group from "popups/SignUpSignIn/images/Group.svg";
import Slider from "react-slick";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { convertToEmbedYoutubeLink } from "utils/utilsfunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "900px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h1": {
      margin: "10px 3px",
      width: "700px",
      fontSize: "25px",
      borderLeft: `3px solid ${colors.ctaSolid}`,
      paddingLeft: "10px",
    },
    "& p": {
      width: "700px",
      margin: "0px 3px 10px 0px",
    },
    "& h3": {
      width: "700px",
      margin: "25px 0px",
    },
    "& h5": {
      width: "700px",
      margin: "10px 0px",
      display: "flex",
      alignItem: "center",
      fontSize: "15px",
    },
  },

  img_container: {
    width: "700px",
    margin: "25px 0px",
    backgroundColor: "#f8f8f8",
  },
  container1: {
    width: "700px",
    display: "flex",
    "& img": {
      width: "25px",
      height: "25px",
      marginRight: "15px",
    },
  },
  container2: {
    width: "700px",
    display: "flex",
    "& img": {
      width: "20px",
      height: "20px",
      marginRight: "15px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  img: {
    objectFit: "contain",
    height: "500px",
  },
}));

export default function ProductDetails({ open, setOpen, item }) {
  const classes = useStyles();
  const [imageArray, setimageArray] = useState(null);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [video_link, setvideo_link] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const array = [
      item?.imgurl1,
      item?.imgurl2,
      item?.imgurl3,
      item?.imgurl4,
      item?.imgurl5,
    ];

    const imgArray = [];
    for (let i = 0; i < array.length; i++) {
      if (
        array[i] &&
        (array[i].includes(".jpg") ||
          array[i].includes(".png") ||
          array[i].includes(".jpeg") ||
          array[i].includes("solarhub-production"))
      ) {
        imgArray.push(array[i]);
      }
    }

    if (item?.video_link) {
      const embedLink = convertToEmbedYoutubeLink(item.video_link);
      setvideo_link(embedLink);
    }

    setimageArray(imgArray);
  }, [item]);

  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <ArrowForwardIos
        className="arrow_icon"
        style={{
          ...style,
          display: onClick === null ? "none" : "block",
          position: "absolute",
          right: window.innerWidth > 500 ? "-20px" : "-20px",
          top: "40%",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <ArrowBackIos
        className="arrow_icon"
        style={{
          ...style,
          display: onClick === null ? "none" : "block",
          position: "absolute",
          left: window.innerWidth > 500 ? "-30px" : "-10px",
          top: "40%",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: function (prev, next) {
      console.log(prev, next, "currentSlide");
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const tagToName = {
    solar_panel: "Solar Panel",
    inverter: "Inverter",
    battery: "Battery",
    wallbox: "Wall Box",
    heatpump: "Heat Pump",
  };
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={false}>
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.root}>
        <h1>{tagToName[item?.type]}</h1>
        <Typography>{item?.name}</Typography>
        <div className={classes.img_container} style={{}}>
          <Slider {...settings} style={{ width: "100%", height: 500 }}>
            {imageArray &&
              imageArray?.map((data, key) => {
                return (
                  <img
                    height={500}
                    className={classes.img}
                    src={data}
                    key={key}
                    alt=""
                  />
                );
              })}
            {video_link ? (
              <iframe
                width="100%"
                height="500"
                src={video_link}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : null}
          </Slider>
        </div>
        <h3>{isEnglish ? "Product Details:" : "Produktdetails:"}</h3>
        {Array.isArray(item?.bullet_point_list)
          ? item?.bullet_point_list?.map((point) => (
              <div className={classes.container2}>
                <img alt="" src={"/images/components/img2.png"} />
                <Typography>{point}</Typography>
              </div>
            ))
          : null}
        <Typography style={{ marginTop: "15px" }}>
          {isEnglish ? "Short Description" : "Beschreibung"}
        </Typography>
        <Typography style={{ fontSize: "14px" }}>
          {item?.short_description}
        </Typography>
        <Typography style={{ marginTop: "15px" }}>
          {isEnglish ? "Long Description" : "Produktbeschreibung"}
        </Typography>
        <Typography style={{ fontSize: "14px" }}>
          {item?.long_description}
        </Typography>
        {item?.Herstellerinformation ? (
          <>
            <Typography style={{ marginTop: "15px" }}>
              {isEnglish ? "Suppliers Information" : "Herstellerinformation"}
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              {item?.Herstellerinformation}
            </Typography>
          </>
        ) : (
          ""
        )}
        <h5>
          <a
            href={`${item?.pdf_url}`}
            target="_blank"
            style={{ color: "black", display: "flex" }}
          >
            <GetAppIcon style={{ marginRight: "15px" }} />
            {isEnglish ? "Download the PDF" : "Laden Sie das PDF herunter"}
          </a>
        </h5>
        <Typography style={{ marginTop: "15px" }}>
          {isEnglish ? "Gaurantee" : "Garantie"}
        </Typography>
        <Typography style={{ fontSize: "14px" }}>{item?.Garantie}</Typography>
        <Typography style={{ marginTop: "15px" }}>
          {isEnglish ? "Technical Product Data" : "Technische Produktdaten"}
        </Typography>
        {Array.isArray(item?.["Technische Produktdaten"])
          ? item?.["Technische Produktdaten"]?.map((detail) => (
              <div className={classes.container2}>
                <img alt="" src={"/images/components/img2.png"} />
                <Typography>{detail}</Typography>
              </div>
            ))
          : null}
        <Typography style={{ marginTop: "15px" }}>
          <h5>
            <GetAppIcon style={{ marginRight: "15px" }} />
            <a
              href={`${item?.warranty_link}`}
              target="_blank"
              style={{ color: "black" }}
            >
              {isEnglish ? "Checkout warrenty" : "Checkout Garantie"}
            </a>
          </h5>
        </Typography>
      </div>
    </Dialog>
  );
}
