import React, { useState } from "react";
import CopyImage1 from "../images/CopyImage1.png";
import { makeStyles, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import SelectYearlyConsumption from "../../../../Components/yearlyConsumption.dialog";
import { Progress } from "antd";
import { useSelector } from "react-redux";
import { commaDotHandler } from "helperMethod";

const colors = {
  darkRain: `linear-gradient(to left,#09152F,#1B4963)`,
  darkBlue: "#1B4963",
  greenHover: `linear-gradient(to left,#197061 ,#1B7A69)`,
  linear: `linear-gradient(to left,#FFE925 ,#FFAA39)`,
  sunny: `linear-gradient(to left,#FBDA61 ,#FFC371)`,
  sun: `linear-gradient(to left,#FBDA61 ,#F76B1C)`,
  sun_r: `linear-gradient(to right,#FBDA61 ,#F76B1C)`,
  fire: `linear-gradient(to left,#FF5F6D  ,#FFC371)`,
  orangeSolid: "#FFAA39",
  ctaSolid: "#FBDA61",
  lightYellow: "#FFF3CA",
};

const useStyles = makeStyles((theme) => ({
  finace_container1: {
    width: "100%",
    margin: "10px 0px 10px 0px",
    display: "flex",
    height: "102px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  fontClass: {
    backgroundColor: "#C4C4C4",
    color: "black",
    borderRadius: "16px",
    width: "76px",
    textAlign: "center",
    fontSize: "15px",
    "@media (max-width: 560px)": {
      fontSize: "10px",
      width: "150px",
    },
    marginLeft: "25px",
    paddingTop: "3px",
    alignSelf: "center",
    height: "30px",
    "@media (max-width: 560px)": {
      fontSize: "14px",
    },
    "@media (max-width: 560px)": {
      fontSize: "14px",
    },
  },
  lastTotal: {
    width: "500px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    height: "44px",
  },
  partOne: {
    width: "50%",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(242, 152, 67, 0.4)",
    color: "black",
    background:
      "linear-gradient(135deg, #FBDA61 2.88%, #FAD44C 45.33%, #EE7D3E 98.13%)",
  },
  carCo2: {
    width: "250px",
    height: "43px",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    background: "linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
    boxShadow: "0px 4px 10px rgba(242, 152, 67, 0.4)",
  },
  FlightCo2: {
    width: "150px",
    height: "43px",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    background: "linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
    boxShadow: "0px 4px 10px rgba(242, 152, 67, 0.4)",
  },
  partTwo: {
    width: "50%",
    textAlign: "center",
    color: "white",
    background: "#219653",
  },
  joint: {
    display: "flex",
  },
  co2: {
    display: "felx",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  familyButton: {
    backgroundColor: "#fff",
    color: "black",
    borderRadius: "25px",
    fontSize: "15px",
    "@media (max-width: 560px)": {
      fontSize: "10px",
      width: "150px",
    },
    alignSelf: "center",
    height: "30px",
    border: `1px solid ${colors.darkBlue}`,
  },
  startingDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 560px)": {
      marginTop: "0px",
    },
    height: "100%",
  },
}));
const DetailTab4 = ({
  costs_and_savings,
  energy_details,
  financing_details,
  setYearlyConsumptionDialog,
}) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const classes = useStyles();
  return (
    <div className={classes.startingDiv}>
      <Co2Savings
        a={`${Math.round(energy_details?.total_production * 0.0008)}`}
        isEnglish={isEnglish}
      />
    </div>
  );
};

const Co2Savings = ({ a, isEnglish }) => {
  const useStyles = makeStyles((theme) => ({
    finace_container1: {
      width: "100%",
      margin: "10px 0px 10px 0px",
      display: "flex",
      height: "102px",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
    forGoofH1: {
      color: "white",
      textAlign: "left",
      marginTop: "8px",
      marginLeft: "5px",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        marginTop: "6px",
      },
      fontSize: "20px",
    },
    fontClass: {
      backgroundColor: "#C4C4C4",
      color: "black",
      borderRadius: "16px",
      width: "76px",
      textAlign: "center",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "10px",
        width: "150px",
      },
      marginLeft: "18px",
      paddingTop: "3px",
      alignSelf: "center",
      height: "30px",
      "@media (max-width: 560px)": {
        fontSize: "14px",
      },
      "@media (max-width: 560px)": {
        fontSize: "14px",
      },
    },
    makeh1: {
      color: "#FFAA39",
      fontFamily: "Open Sans",
      fontWeight: "700",
      fontSize: "24px",
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
      },
    },
    forh3Co2: {
      fontFamily: "Open Sans",
      fontWeight: "700",
      fontSize: "20px",
      color: "#FFAA39",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
      },
      textDecoration: "underline",
    },
    lastTotal: {
      width: "500px",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginBottom: "10px",
        height: "35px",
      },
      height: "44px",
    },
    partOne: {
      width: "50%",
      textAlign: "center",
      boxShadow: "0px 4px 10px rgba(242, 152, 67, 0.4)",
      color: "black",
      background:
        "linear-gradient(135deg, #FBDA61 2.88%, #FAD44C 45.33%, #EE7D3E 98.13%)",
    },
    carCo2: {
      width: "167px",
      [theme.breakpoints.down("xs")]: {
        height: "35px",
        width: "144px",
      },
      height: "43px",
      background: "linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
      boxShadow: "0px 4px 10px rgba(242, 152, 67, 0.4)",
    },
    FlightCo2: {
      width: "105px",
      height: "43px",
      [theme.breakpoints.down("xs")]: {
        height: "35px",
      },
      background: "linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
      boxShadow: "0px 4px 10px rgba(242, 152, 67, 0.4)",
    },
    partTwo: {
      width: "50%",
      textAlign: "center",
      color: "white",
      background: "#219653",
    },
    joint: {
      display: "flex",
    },
    Mainco2: {
      height: "43px",
      width: `${a * 15}px`,
      [theme.breakpoints.down("xs")]: {
        width: `${a * 10}px`,
        height: "28px",
      },
      background:
        "linear-gradient(135deg, #FBDA61 2.88%, #FAD44C 45.33%, #EE7D3E 98.13%)",
      boxShadow: "0px 4px 10px rgba(242, 152, 67, 0.4)",
    },
    co2: {
      display: "felx",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    familyButton: {
      backgroundColor: "#fff",
      color: "black",
      borderRadius: "25px",
      fontSize: "15px",
      "@media (max-width: 560px)": {
        fontSize: "10px",
        width: "150px",
      },
      alignSelf: "center",
      height: "30px",
      border: `1px solid ${colors.darkBlue}`,
    },
    yourDiv: {
      backgroundColor: "#f8f8f8",
      borderRadius: "25px",
      width: "715px",
      height: "380px",
      [theme.breakpoints.down("xs")]: {
        width: "95%",
        margin: "5px",
      },
      margin: "auto",
    },
    forH1: {
      textAlign: "center",
      textTransform: "uppercase",
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontSize: "22px",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
      paddingTop: "30px",
    },
    innerDiv: {
      display: "flex",
      marginLeft: "40px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        width: "95%",
        height: "81%",
        marginLeft: "10px",
      },
      height: "75%",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "flex-start",
    },
    forH3: {
      marginLeft: "30px",
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontSize: "20px",
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        marginLeft: "13px",
        textAlign: "center",
        fontSize: "14px",
      },
      textAlign: "center",
      marginTop: "10px",
      fontWeight: "600",
    },
    nowH3: {
      marginTop: "8px",
      fontWeight: "700",
      fontSize: "20px",
      fontFamily: "Nunito",
      color: "white",
      [theme.breakpoints.down("xs")]: {
        marginTop: "4px",
      },
    },
    nowH32: {
      marginTop: "10px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "4px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className={classes.yourDiv}>
        <h1 className={classes.forH1}>
          {isEnglish
            ? "Emissions - The contribution of your PV Project"
            : "EMISSIONEN - DER BEITRAG IHRES PV-PROJEKTS"}
        </h1>
        <div className={classes.innerDiv}>
          <div className={classes.co2}>
            <h1 className={classes.makeh1}>
              {commaDotHandler(a, `${isEnglish ? "en" : "de"} `)}
              <span>
                {isEnglish
                  ? " tCO2-Savings of the system per year"
                  : " tCO2-Einsparung der Anlage pro Jahr"}
              </span>
            </h1>
            {/*             <h3 className={classes.forh3Co2}>
              {isEnglish
                ? "CO2-Savings of the system per year"
                : "CO2-Einsparung der Anlage pro Jahr"}
            </h3> */}
            <div className={classes.Mainco2}></div>
          </div>
          <div className={classes.joint}>
            <div className={classes.carCo2}>
              <h3 className={classes.forGoofH1}>{`${commaDotHandler(
                Math.floor((a * 1000000) / 620),
                `${isEnglish ? "en" : "de"}`
              )} km`}</h3>
            </div>
            <h3 className={classes.forH3}>
              {isEnglish
                ? "Saved flight kilometers"
                : "Eingesparte Flugkilometer"}
            </h3>
          </div>
          <div className={classes.joint}>
            <div className={classes.carCo2}>
              <h3 className={classes.forGoofH1}>{`${commaDotHandler(
                Math.floor((a * 1000000) / 150),
                `${isEnglish ? "en" : "de"}`
              )} km`}</h3>
            </div>
            <h3 className={classes.forH3}>
              {isEnglish
                ? "Saved kilometers by combustion car"
                : "Eingesparte Kilometer Verbrenner Auto"}
            </h3>
          </div>
          <div className={classes.joint}>
            <div className={classes.carCo2}>
              <h3 className={classes.forGoofH1}>{`${commaDotHandler(
                Math.floor((a * 1000000) / 7),
                `${isEnglish ? "en" : "de"}`
              )} km`}</h3>
            </div>
            <h3 className={classes.forH3}>
              {isEnglish
                ? "Saved kilometers by electric vehicle"
                : "Eingesparte Kilometer Elektroauto"}
            </h3>
          </div>
          {/*           <div className={classes.lastTotal}>
            <div className={classes.partOne}>
              <h3 className={classes.nowH32}>46%</h3>
            </div>
            <div className={classes.partTwo}>
              <h3 className={classes.nowH3}>
                {isEnglish ? "11,463kg" : "11.463kg"}
              </h3>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DetailTab4;
