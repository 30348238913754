import React, { useState } from "react";
import {
  Dialog,
  Button,
  makeStyles,
  createStyles,
  TextField,
  Card,
  InputAdornment,
} from "@material-ui/core";
import Hero from "../../Assets/Hero.svg";
import Close from "../../Assets/Close.svg";
import Group from "../SignUpSignIn/images/Group.svg";
import { useDispatch, useSelector } from "react-redux";
import { LocationOn as LocationOnIcon } from "@material-ui/icons";
import { useHistory } from "react-router";
import Geocoder from "react-mapbox-gl-geocoder";
import { colors } from "colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1124px",
    overflow: "auto",
    overflowX: "hidden",
    height: "700px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      justifyContent: "flex-start",
    },
    justifyContent: "center",
    alignItems: "center",
  },
  button_group: {
    backgroundColor: "#FFD75D",
    border: "none",
    borderRadius: "16px",
    fontSize: "15px",
    height: "41px",
    width: "268px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      fontSize: "13px",
      padding: "9px",
    },
    fontWeight: "600px",
    color: "#2D4764",
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
    marginBottom: "15px",
    textAlign: "center",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  resize: {
    fontSize: "10px",
  },
  searchInput: {
    zIndex: "3",
    background: "#FFFFFF",
    borderRadius: "336px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "274px",
    },
    "&.MuiInputBase-input .MuiInput-input": {
      fontSize: "10px !important",
    },
    "&:hover": {
      transform: "scale(1.01)",
      background: "rgba(253, 184, 0, 0.1)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    },
    width: "400px",
    "&.MuiFormControl-root div.MuiOutlinedInput-root": {
      borderRadius: "336px",
      border: "3px solid #FFD75D",
      paddingRight: 0,
    },
    "&.MuiFormControl-root div.MuiOutlinedInput-root .MuiInputAdornment-positionEnd .MuiTypography-root":
      {
        background:
          "linear-gradient(148.54deg, #FFE925 20.91%, #FFAA39 105.47%)",
        borderRadius: "336px",
        fontSize: "16px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
        fontFamily: "Nunito",
        height: "30px",
      },
    "&.MuiOutlinedInput-notchedOutline div.PrivateNotchedOutline-root-193 div.MuiOutlinedInput-notchedOutline .PrivateNotchedOutline-root-193":
      {
        borderStyle: "none",
      },
  },
  noBorder: {
    border: "none",
  },
  imgRes: {
    height: "246px",
    [theme.breakpoints.down("xs")]: {
      height: "184px",
    },
  },
  searchAndPlayButton: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    alignItems: "center",
    width: "90%",
  },
  buttonDiscover: {
    color: "rgba(45, 71, 100, 1)",
    backgroundColor: colors.ctaSolid,
    padding: "5px 0",
    [theme.breakpoints.down("xs")]: {
      width: "73px",
      position: "absolute",
      right: "-1px",
      fontSize: "10px",
    },
    width: "90px",
    "&:hover": {
      backgroundColor: colors.ctaSolid,
    },
    height: "41px",
    fontWeight: "600",
    borderRadius: "25px",
    textAlign: "center",
  },
  h1Helper: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
}));

function parseReverseGeo(geoData) {
  let locality, region, country, postcode, returnStr;
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text;
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text;
      }
      if (v.id.indexOf("region") >= 0) {
        region = v.text;
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text;
      }
    });
  }
  if (postcode && region && country) {
    returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`;
  } else {
    returnStr = geoData.place_name;
  }
  return {
    number: geoData.address,
    street: geoData.text,
    address: (geoData.text + " " + geoData.address).toLowerCase(),
    locality,
    region,
    postcode,
    country,
    concat: returnStr,
    complete: geoData.place_name,
    geometry: geoData.geometry,
  };
}

const MyInput = (props) => {
  const isEnglish = useSelector((state) => state?.global?.isEnglish);
  return (
    <TextField
      {...props}
      onChange={(e) => {
        props.onChange(e);
      }}
      InputProps={{ disableUnderline: true }}
      placeholder={isEnglish ? "Enter Location" : "Adresse eingeben"}
      style={{ width: window.innerWidth > 500 ? "250px" : "202px" }}
    />
  );
};

const SelectedInput = ({ selectedAddress, isEnglish, setSelectedAddress }) => {
  return (
    <TextField
      onClick={() => setSelectedAddress(null)}
      InputProps={{ disableUnderline: true }}
      value={selectedAddress.complete}
      placeholder={isEnglish ? "Enter Location" : "Adresse eingeben "}
      style={{ width: window.innerWidth > 500 ? "250px" : "202px" }}
    />
  );
};

const ResultItem = ({ item, onClick, children }) => {
  const useStyles = makeStyles((theme) => ({
    main: {
      width: "350px",
      height: "45px",
      zIndex: "1",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        width: "270px",
      },
      alignItems: "center",
      paddingLeft: "15px",
    },
  }));
  const classes = useStyles();

  console.log("item", children);
  // item = item.reverse();

  return (
    <>
      <div>
        {item ? (
          <Card item elevation="5" onClick={onClick} className={classes.main}>
            {children}
          </Card>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const WelcomeUserNoAddress = ({
  open,
  setOpen,
  isFromOverView,
  isBlank,
  comingForCommunity,
}) => {
  const status = useSelector((state) => state.quote.status);
  const isB2B = useSelector((state) => state.project.isB2B);
  const [selectedAddress, setSelectedAddress] = useState();
  const dispatch = useDispatch();
  let history = useHistory();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const onSelected = (_, item) => {
    const data = parseReverseGeo(item);
    setSelectedAddress(data);
  };

  const setQuote = () => {
    dispatch({ type: "SET_USER_SELECTING_ADDRESS", payload: true });
    if (isBlank) {
      if (isB2B) {
        setOpen(false);
        return window.open(
          "/application/default/b2b-" + selectedAddress?.complete,
          "_blank"
        );
      } else {
        setOpen(false);
        return window.open(
          "/application/default/b2c-" + selectedAddress?.complete,
          "_blank"
        );
      }
    }
    if (isB2B) {
      history.push("/application/b2b/default-" + selectedAddress?.complete);
    } else {
      history.push("/application/b2c/default-" + selectedAddress?.complete);
    }
    setOpen(false);
  };

  const queryParams = {
    country: "de",
    types: "address",
  };

  const mapAccess = {
    mapboxApiAccessToken:
      "pk.eyJ1IjoiY2hlbHVkbyIsImEiOiJjazRhMWY0eG8wMHFiM2ttdXk2cnEwMXNoIn0.qGONrbtgEqaTGD5beE7KBw",
  };

  const classes = useStyles();
  const handleClose = () => {
    dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
    setOpen(false);
    dispatch({ type: "SET_USER_ADRESS_STATUS", payload: false });
    dispatch({ type: "SET_USER_SELECTING_ADDRESS", payload: false });
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      style={{ margin: "-15px" }}
    >
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => {
            dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
            setOpen(false);
            dispatch({ type: "SET_USER_ADRESS_STATUS", payload: false });
          }}
        />
      </div>
      <div className={classes.root}>
        <div style={{ marginTop: "40px" }}>
          <Payment isFromOverView={isFromOverView} />
        </div>
        <TextField
          id="input-with-icon-textfield"
          //   label="Informationssuche"
          //placeholder={
          //  isEnglish ? "Information search" : "Informationssuche"
          //  }
          variant="outlined"
          size="small"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <LocationOnIcon
                    style={{
                      color: "#FFAA39",
                      padding: "5px 0",
                    }}
                  />
                  <div style={{ zIndex: "0" }}>
                    {selectedAddress ? (
                      <SelectedInput
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                      />
                    ) : (
                      <Geocoder
                        {...mapAccess}
                        onSelected={onSelected}
                        inputComponent={MyInput}
                        itemComponent={ResultItem}
                        hideOnSelect={true}
                        queryParams={queryParams}
                      />
                    )}
                  </div>
                </InputAdornment>
              </>
            ),
            classes: { notchedOutline: classes.noBorder },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={() => setQuote()}
                  className={classes.buttonDiscover}
                >
                  {isEnglish ? "Discover" : "Entdecken"}
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <div>
          <img src={Hero} className={classes.imgRes} alt="" />
        </div>
        <div>
          <button
            className={classes.button_group}
            onClick={() => {
              setOpen(false);
              dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
            }}
            variant="contained"
          >
            {isEnglish
              ? "Play around with sample"
              : "Jetzt mit Beispieldaten testen"}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const Payment = ({ isFromOverView }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const userAdressStatus = useSelector(
    (state) => state.user.isUserAdressEnterd
  );
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        height: "200px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          width: "93%",
          height: "auto",
          justifyContent: "flex-start",
        },
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      h1: {
        fontFamily: "Nunito",
        fontWeight: "700",
        [theme.breakpoints.down("xs")]: {
          fontSize: isEnglish ? "24px" : "20px",
        },
        marginBottom: "3px",
        fontSize: "36px",
        color: "#304864",
      },
      h3: {
        fontFamily: "Nunito",
        fontWeight: "600",
        fontSize: "25px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
          fontSize: "15px",
          textAlign: "center",
        },
        marginBottom: "7px",
        color: "#304864",
      },
      roof: {
        fontFamily: "Nunito",
        fontWeight: "400",
        marginBottom: "7px",
        fontSize: "22px",
        color: "#4D7E6D",
      },
      middle: {
        width: "900px",
        margin: "auto",
        marginBottom: "30px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          fontSize: "15px",
        },
      },
    })
  );

  const classes = useStyles();
  const [star, setStar] = useState(4.5);
  const handleOnChage = (value) => {
    setStar(value);
  };
  return (
    <div className={classes.root}>
      <h1 className={classes.h1}>
        {userAdressStatus
          ? isEnglish
            ? "Please select an adress to save your project"
            : "Bitte wählen Sie eine Adresse um ihr Projekt zu speichern"
          : isEnglish
          ? "Welcome to Solarhub"
          : "Willkommen bei Solarhub"}
      </h1>
      {!isFromOverView ? (
        <h3 className={classes.h3}>
          {isEnglish
            ? "With our Solar configurator you can learn about all options for your solar project."
            : "Mit dem Solar Konfigurator lernst Du ganz einfach alle Möglichkeiten für Dein Solarprojekt kennen."}
        </h3>
      ) : (
        ""
      )}
      {isFromOverView ? (
        <h3 className={classes.h3}>
          {isEnglish
            ? "Select any address from your saved projects to see the offers for that project"
            : "Select any address from your saved projects to see the offers for that project"}
        </h3>
      ) : (
        ""
      )}
      <h3 className={classes.roof}>
        <div className={classes.middle}>
          {isEnglish
            ? "To use your own data please enter your adress. In the next step our solar experts will create a preliminary design concept for your PV project. Or simply play around with the sample data to understand the options for a solar project."
            : "Um Dein eigenes Dach zu planen gib einfach Deine Adresse ein. Im nächsten Schritt werden unsere Solar-Experten ein Design Konzept erstellen. Bis dahin kannst Du Dich schonmal anhand der Beispieldaten mit den verschiedenen Möglichkeiten vertraut machen."}
        </div>
      </h3>
    </div>
  );
};

export default WelcomeUserNoAddress;
