import React from "react";

const Tick = () => {
  return (
    <svg
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="43.5" cy="43.5" r="41.5" stroke="#FFD75D" stroke-width="4" />
      <path
        d="M24 43.25L39.8421 59L67 32"
        stroke="#FFD75D"
        stroke-width="4"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Tick;
