import React from "react";

const Ignore = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="16"
          cy="16"
          r="14.925"
          stroke="#C0C0C0"
          stroke-width="2.15"
        />
        <path
          d="M10 22L15.75 16.25M21.5 10.5L15.75 16.25M15.75 16.25L10 10.5L21.5 22"
          stroke="#C0C0C0"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Ignore;
