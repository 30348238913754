import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import Arrow from "../Assets/Arrow";
import { useSelector } from "react-redux";
const MainContainer = styled("div")`
  width: 324px;
  height: 441px;
  background: #ffffff;
  @media (max-width: 600px) {
    width: 90%;
  }
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
`;
const SubContainer1 = styled("div")`
  position: relative;
  margin-top: 22px;
`;
const Text1 = styled("div")`
  font-weight: 600;
  font-size: 17px;
  color: #1b4963;
  margin-left: 23px;
`;
const SubContainer2 = styled("div")`
  position: relative;
  margin-top: 15px;
  margin-left: 26px;
`;
const ImgContainer = styled("img")`
  width: 185px;
  height: 156px;
  border-radius: 16px;
`;
const Circle = styled("div")`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #122e48;
  margin-top: 7px;
`;
const Text2 = styled("div")`
  color: #1b4963;
  font-weight: normal;
  font-size: 14px;
`;
const SubContainer31 = styled("div")`
  display: flex;
  gap: 10px;
  margin-top: 22px;
`;
const SubContainer3 = styled("div")`
  display: flex;
  gap: 10px;
  margin-top: 3px;
`;
const SubContainer4 = styled("div")`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 35px;
  border: 1.5px solid #2d4764;
  box-sizing: border-box;
  border-radius: 18px;
  cursor: pointer;
`;
const LeftSide = ({ data }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <MainContainer>
      <SubContainer1>
        <Text1>
          {isEnglish
            ? "Project configuration of"
            : "Projekt Konfiguration deines"}
        </Text1>
        <Text1>{isEnglish ? "your neighbour Peter Lustig" : "Nachbars"}</Text1>
        <div
          style={{
            position: "absolute",
            top: "2px",
            right: "16px",
          }}
        >
          <div
            style={{
              position: "relative",
              height: "48px",
              bottom: "11px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "18px",
                left: "4px",
                color: "#fbda61",
                fontSize: "13px",
              }}
            >
              {data.actualRatingData?.rating.toFixed(1)}
            </span>
            <CircularProgress
              style={{
                color: "#fbda61",
                marginBottom: "30px",
                width: "32px",
                height: "35px",
                marginTop: "11px",
              }}
              variant="determinate"
              value={data.actualRatingData?.rating * 2 * 10}
            />
          </div>
        </div>
      </SubContainer1>
      <SubContainer2>
        <ImgContainer src={data.projectData.projectImgUrl[0]} />
        <SubContainer31>
          <Circle />
          <Text2>
            {
              data.projectData?.project_details?.components?.items?.solar_panel
                ?.item?.name
            }
          </Text2>
        </SubContainer31>
        <SubContainer3>
          <Circle />
          <Text2>
            {
              data?.projectData?.project_details?.components?.items?.inverter
                ?.item?.name
            }
          </Text2>
        </SubContainer3>
        <SubContainer3>
          {data?.projectData?.project_details?.components?.items?.heatpump
            ?.item ? (
            <>
              <Circle />
              <Text2>
                {
                  data?.projectData?.project_details?.components?.items
                    ?.heatpump?.item
                }
              </Text2>
            </>
          ) : (
            ""
          )}
        </SubContainer3>
        <SubContainer3>
          {data?.projectData?.project_details?.components?.items?.wallbox
            ?.item ? (
            <>
              <Circle />
              <Text2>
                {
                  data?.projectData?.project_details?.components?.items?.wallbox
                    ?.item
                }
              </Text2>
            </>
          ) : (
            ""
          )}
        </SubContainer3>
        <SubContainer4
          onClick={() => {
            window.open(
              `${window.location.origin}/application/custom-${data?.projectData?.pid}`,
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          <span>
            <Arrow />
            {isEnglish ? "Project Detailpage" : "Projekt Detailseite"}
          </span>
        </SubContainer4>
      </SubContainer2>
    </MainContainer>
  );
};

export default LeftSide;
