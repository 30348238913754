import DashboardHeader from "Components/dashboardHeader";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import carbon_add from "Assets/application_step_1/carbon_add.svg";
import { MainContainer } from "pages/Application/useroverview/overviewStyles";
import zmdi_google from "Assets/application_step_1/zmdi_google.svg";
import { useDispatch, useSelector } from "react-redux";
import firebase, { db } from "../../../firebase";
import Slider from "react-slick";
import { costsAndSavings, energyDetails, financingDetails } from "utils/costs";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useHistory, useLocation, useParams } from "react-router";
import * as geofire from "geofire-common";
import { toast } from "react-toastify";
import UserOverViewCard from "./userOverViewCard/UserOverViewCard";
import Header from "./header/Header";
import MapView from "./mapview/MapView";
import WelcomeUserNoAddress from "popups/welcomeUserNoAddress/WecomeUserNoAddress";

const UserOverView = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const dummyProjectCount = useSelector(
    (state) => state.global.dummyProjectCount
  );
  const quote = useSelector((state) => state.quote);
  const [open, setOpen] = useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [savedprojectsVersion, setSavedProjectsVersion] = useState([]);
  const { state } = useLocation();
  const { qrn } = state;
  const [isSavedProject, setIsSavedProject] = useState(
    qrn === "60ebee279eee013e34e64130" ? false : true
  );
  const [isMapView, setIsMapView] = useState(false);
  const [letOpen, letSetOpen] = useState(false);
  const project = useSelector((state) => state?.project);
  const dispatch = useDispatch();
  const history = useHistory();
  let { projectId } = useParams();
  const calc_func = (
    electricity_cost_before,
    estimated_yearly_price_increase
  ) => {
    let temp = electricity_cost_before;
    console.log("final_temp", temp);
    console.log(estimated_yearly_price_increase);
    for (let i = 0; i < 25; i++) {
      temp =
        (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100 +
        temp;
    }
    return Math.round(temp);
  };

  const setProjectAsync = (data) => {
    dispatch({ type: "SET_PROJECT_ASYNC", payload: data });
  };
  console.log(savedprojectsVersion, "savedprojectsVersion");
  const setLoading = (data) => dispatch({ type: "SET_LOADING", payload: data });

  // useEffect(() => {
  //   if (qrn === "60ebee279eee013e34e64130") {
  //     setIsSavedProject(false);
  //   }
  // }, []);
  console.log(isSavedProject, "isSavedProject");

  useEffect(() => {
    if (projectId && projectId !== "60ebee279eee013e34e64130Project123") {
      db.collection("saved_projects")
        .doc(projectId)
        .get()
        .then((doc) => {
          setProjectAsync(doc.data()?.project_details);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (currentUser && !isSavedProject) {
      db.collection("saved_projects")
        .where("uid", "==", currentUser.uid)
        .get()
        .then((snap) => {
          const data = [];
          snap.forEach((doc) => {
            if (doc.data().pid.includes("v1")) {
              const a = Math.round(
                doc.data()?.financialDetails?.total_project_cost
              );
              const b = calc_func(
                parseFloat(doc.data()?.costSaving?.electricity_cost_before),
                parseFloat(
                  doc.data()?.project_details?.costs_and_savings
                    ?.estimated_yearly_price_increase
                )
              );
              const c =
                Math.round(
                  doc.data()?.project_details?.costs_and_savings?.feedin_savings
                ) * 20;
              data.push({ ...doc.data(), returnOfInvestment: b + c - a });
            }
          });
          setSavedProjectsVersion(data);
          console.log(data, "saved_project");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentUser, isSavedProject]);

  useEffect(() => {
    if (currentUser && isSavedProject && qrn !== "60ebee279eee013e34e64130") {
      console.log("yes you are right");
      db.collection("saved_projects")
        .where("qrn", "==", qrn)
        .where("uid", "==", currentUser.uid)
        .get()
        .then((snapshots) => {
          const data = [];
          snapshots.forEach((doc) => {
            const a = Math.round(
              doc.data()?.financialDetails?.total_project_cost
            );
            const b = calc_func(
              parseFloat(doc.data()?.costSaving?.electricity_cost_before),
              parseFloat(
                doc.data()?.project_details?.costs_and_savings
                  ?.estimated_yearly_price_increase
              )
            );
            const c =
              Math.round(
                doc.data()?.project_details?.costs_and_savings?.feedin_savings
              ) * 20;
            data.push({ ...doc.data(), returnOfInvestment: b + c - a });
          });
          setSavedProjectsVersion(data);
        })
        .catch((e) => console.log(e));
    } else {
      setSavedProjectsVersion([]);
      if (isSavedProject) {
        letSetOpen(true);
      }
    }
  }, [currentUser, qrn, dummyProjectCount, isSavedProject]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className="arrow_icon"
        style={{
          ...style,
          display:
            onClick === null || window.innerWidth < 383 ? "none" : "block",
          position: "absolute",
          right: window.innerWidth > 500 ? "-20px" : "-25px",
          top: window.innerWidth > 500 ? "40%" : "40%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIos
        className="arrow_icon"
        style={{
          ...style,
          display:
            onClick === null || window.innerWidth < 383 ? "none" : "block",
          position: "absolute",
          left: window.innerWidth > 500 ? "-25px" : "-22px",
          top: window.innerWidth > 500 ? "41%" : "40%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: window.innerWidth > 500 ? 3 : 1,
    slidesToScroll: 1,
    // afterChange: function (currentSlide) {
    //   console.log(currentSlide, "currentSlide");
    // },
    beforeChange: function (prev, next) {
      console.log(prev, next, "currentSlide");
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const save = () => {
    const financialDetails = financingDetails(project);
    const energysDetails = energyDetails(project);
    const costSaving = costsAndSavings(project);
    setLoading(true);
    if (currentUser) {
      const uid = currentUser?.uid;
      let versionLength;
      if (savedprojectsVersion?.length === 0) {
        versionLength = 2;
      } else {
        versionLength = savedprojectsVersion?.length + 1;
      }
      db.collection("saved_projects")
        .doc(uid + quote?.quote.qrn + `v${versionLength}`)
        .set({
          project_details: {
            ...project,
            pid: uid + quote?.quote.qrn + `v${versionLength}`,
          },
          uid: uid,
          qrn: quote?.quote.qrn,
          crn: quote?.quote?.crn,
          pid: uid + quote?.quote.qrn + `v${versionLength}`,
          projectImgUrl: quote?.quote?.img_url,
          financialDetails,
          energysDetails,
          costSaving,
          updatedData: {
            roof_type: quote?.quote?.roof_type || "Flat roof",
            roof_material: quote?.quote?.roof_material || "metal roof",
            roof_tilt: quote?.quote?.roof_tilt || "45°",
            roof_orientation:
              quote?.quote?.roof_orientation || "South orientation",
          },
          version: `v${versionLength}`,
          geohash: geofire.geohashForLocation([
            parseFloat(quote?.quote?.lat),
            parseFloat(quote?.quote.long),
          ]),
          lat: quote?.quote?.lat,
          long: quote?.quote?.long,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          toast.success(
            isEnglish
              ? "Solar Configuration new version created successfully"
              : "Weitere Konfiguration neu hinzufügen erfolgreich erstellt"
          );
          setLoading(false);
        })
        .catch((e) => console.error(e.message));
    } else {
      setLoading(false);
    }
  };

  const addDummyProject = () => {
    save();
    dispatch({ type: "DUMMYPROJECT_PROJECT_ADDED" });
  };

  const selectOfferHandler = (data) => {
    dispatch({ type: "SET_BAK_TO_STEP_FOUR", payload: true });
    history.push(`/application/saved-${data?.pid}`);
    dispatch({ type: "SET_COMING_OVERVIEW", payload: true });
  };

  return (
    <MainContainer>
      <DashboardHeader />
      {isMapView ? (
        <MapView
          addDummyProject={addDummyProject}
          setIsMapView={setIsMapView}
          selectOfferHandler={selectOfferHandler}
          isEnglish={isEnglish}
          isSavedProject={isSavedProject}
          setIsSavedProject={setIsSavedProject}
          scollarData={savedprojectsVersion}
        />
      ) : (
        <div className="mainDIv">
          <div className="under_main">
            <Header
              addDummyProject={addDummyProject}
              setIsMapView={setIsMapView}
              qrn={qrn}
              setIsSavedProject={setIsSavedProject}
              isSavedProject={isSavedProject}
            />
            {savedprojectsVersion?.length > 3 || window.innerWidth < 1000 ? (
              <div className="cardsContainer">
                <Slider
                  {...settings}
                  style={{
                    width: window.innerWidth < 560 ? "92%" : "100%",
                    margin: window.innerWidth < 560 ? "auto" : "",
                  }}
                >
                  {savedprojectsVersion?.map((data, index) => {
                    return (
                      <UserOverViewCard
                        data={data}
                        isSavedProject={isSavedProject}
                        key={index}
                        selectOfferHandler={selectOfferHandler}
                        isEnglish={isEnglish}
                      />
                    );
                  })}
                </Slider>
              </div>
            ) : (
              <div className="cardsContainer2">
                {savedprojectsVersion?.map((data, index) => {
                  return (
                    <UserOverViewCard
                      data={data}
                      isSavedProject={isSavedProject}
                      key={index}
                      selectOfferHandler={selectOfferHandler}
                      isEnglish={isEnglish}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <WelcomeUserNoAddress
            open={letOpen}
            isFromOverView={true}
            setOpen={letSetOpen}
          />
        </div>
      )}
    </MainContainer>
  );
};

export default UserOverView;
