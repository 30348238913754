import React from "react";

const Arrow = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "5px" }}
    >
      <path
        d="M10.9995 4.95778V0.75L15.7065 5.32625L19.4805 8.99542L15.6395 12.1075L10.9995 15.8671V11.7497C2.85347 11.1528 -0.000530243 15.7358 -0.000530243 15.7358C-0.000530243 12.8804 0.241468 9.91708 2.55047 7.67319C5.23447 5.06278 9.12147 4.87806 10.9995 4.95778Z"
        fill="#2D4764"
      />
    </svg>
  );
};

export default Arrow;
