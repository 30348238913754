import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Application from "./pages/Application/Application";
import "fontsource-roboto";
import "./App.css";
import { connect, useDispatch } from "react-redux";
import { setCurrentUser, setCurrentUserLoading } from "./redux/user/user.actions";
import firebase from "./firebase";
import Loader from "./Components/loader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "./pages/Payment/Payment";
import AdminLogin from "./pages/AdminPanel/Adminlogin";
import Utilities from "./pages/Utilities/Utilities";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/styles";
import Admin from "pages/AdminPanel/layouts/Admin.js";
import { createTheme } from "@material-ui/core";
import DashBoard from "pages/userdashboard/components/templates/DashBoard.jsx";
import FeedBack from "pages/Application/components/FeedBack";
import { useSelector } from "react-redux";
import * as actions from "./redux/chat/chat.action";

import SelectDay from "pages/Application/community/Invitaion/SelectDay";
import { db } from "./firebase";
import Routes from "pages/Application/community/partials/routes";
import UserOverView from "pages/Application/useroverview/UserOverView";

const promise = loadStripe("pk_live_51JEqYhItm0NLgHnUQQMUhyenZQn7TPsY9PbJg3hxZBuZ9Rgio0zNPCdvYWu6mJwzuLOg571LjanTGP1MXdotpDKv00gZaEtBtE");
function App({ setCurrentUser, setCurrentUserLoading }) {
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();

	useEffect(() => {
		firebase.auth().onAuthStateChanged(function (user) {
			// console.table(user);
			// console.log("onAuthStateChanged");
			setCurrentUserLoading(false);
			if (user) {
				setCurrentUser(user);
			} else {
				setCurrentUser(null);
			}
			// console.log("Calling setCurrentUserLoading with status, ", false);
		});
	}, [setCurrentUser, setCurrentUserLoading]);

	useEffect(() => {
		if (user?.currentUser) {
			const uid = user?.currentUser.uid;
			const name = user?.currentUser.displayName;
			const avatar = user?.currentUser.photoURL;
			dispatch(actions.myCometChat(uid, name, avatar));
		}
	}, [user?.currentUser]);

	const theme = createTheme({
		shape: {
			borderRadius: 10,
		},
	});

	useEffect(() => {
		const func = async () => {
			if (user.currentUser) {
				const topicRef = db.collection("savedTopics").doc(user.currentUser.uid);
				const doc = await topicRef.get();
				if (!doc.exists) {
					console.log("No such document!");
				} else {
					let temp = doc.data();
					dispatch({ type: "SET_TopicId", payload: temp.topicIdList });
				}
			}
		};

		func();
	}, [user]);

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Switch>
					<Route path='/utilities'>
						<Utilities />
					</Route>
					<Route path='/feedback'>
						<FeedBack />
					</Route>
					<Route path='/community'>
						<Routes />
					</Route>
					<Route path='/selectDay'>
						<SelectDay />
					</Route>
					<Route path='/useroverview/:projectId'>
						<UserOverView />
					</Route>
					<Route path='/admin' component={Admin} />
					<Route path='/login' component={AdminLogin} />
					<Route path='/userdashboard'>
						<Elements stripe={promise}>
							<DashBoard />
						</Elements>
					</Route>
					<Route path='/payment'>
						<Elements stripe={promise}>
							<Payment />
						</Elements>
					</Route>
					<Route path='/application'>
						<Elements stripe={promise}>
							<Application />
						</Elements>
					</Route>
					<Route path='/loader'>
						<Loader />
					</Route>
					<Route path='/'>
						<Redirect to='/admin' />
					</Route>
				</Switch>
			</Router>
			<ToastContainer />
		</ThemeProvider>
	);
}
// solar.
const mapDispatchToProps = (dispatch) => ({
	setCurrentUser: (user) => dispatch(setCurrentUser(user)),
	setCurrentUserLoading: (status) => dispatch(setCurrentUserLoading(status)),
});

export default connect(null, mapDispatchToProps)(App);
