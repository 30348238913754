import React, { useState } from "react";
import styled from "styled-components";
import Cross from "./../Assets/Cross";
import Maps from "./../Assets/Maps";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import Back from "../Assets/Back";
import { useSelector } from "react-redux";
import SelectDay from "pages/Application/community/Invitaion/SelectDay";

const MainContainer = styled("div")`
  position: relative;
  width: 1008px;
  height: 636px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 100%;
    height: 94vh;
    overflow-y: auto;
  }
  .newClass {
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column-reverse;
    }
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
  }
  .workClass {
    @media (max-width: 600px) {
      position: absolute;
      top: 20px;
      right: 15px;
    }
  }
`;
const CrossContainer = styled("div")`
  cursor: pointer;
`;
const SubContainer1 = styled("div")`
  @media (max-width: 600px) {
    margin-left: 22px;
  }
`;
const MainT1 = styled("div")`
  margin-top: 12px;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #ffbf1b;
`;
const MainT2 = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2d4764;
`;
const MainT3 = styled("div")`
  margin-top: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #343434;
`;
const MainT33 = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
const MainT4 = styled("div")`
  margin-top: 2px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #343434;
`;
const SubContainer2 = styled("div")`
  margin-top: 27px;
  display: flex;
  justify-content: center;
  gap: 30px;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
  }
`;
const SubContainer3 = styled("div")`
  position: absolute;
  width: 99px;
  height: 8px;
  background: #2d4764;
  border-radius: 5px;
  transform: translate(-100%, 500%);
  @media (max-width: 600px) {
    transform: translate(-163%, 100%);
  }
`;
const SubContainer4 = styled("div")`
  position: absolute;
  width: 99px;
  height: 8px;
  background: #eaeaea;
  border-radius: 5px;
  transform: translate(-100%, 500%);
  transform: translate(-100%, 500%);
  @media (max-width: 600px) {
    transform: translate(-163%, 100%);
  }
`;
const SubContainer5 = styled("div")`
  width: 50%;
  height: 8px;
  background: #2d4764;
  border-radius: 5px;
`;
const SubContainer6 = styled("div")`
  position: absolute;
  top: 39px;
  left: 27px;
  cursor: pointer;
  @media (max-width: 600px) {
    position: absolute;
    top: 18px;
    left: inherit;
    right: 39px !important;
    cursor: pointer;
  }
`;
const Index = ({
  handleCancel,
  setInviteState,
  sender_uid,
  data,
  discriptiion,
  receiver_uid,
  setdiscriptiion,
}) => {
  const [scheduleInvite, setScheduleInviteState] = useState(true);
  const [selected, setSelected] = useState("chat");
  const isEnglish = useSelector((state) => state.global.isEnglish);
  console.log(data, "hey data");
  return (
    <MainContainer>
      {scheduleInvite ? null : (
        <SubContainer6 onClick={() => [setScheduleInviteState(true)]}>
          <Back />
        </SubContainer6>
      )}
      <div className="newClass">
        <CrossContainer
          onClick={() => {
            handleCancel();
          }}
        >
          {selected === "chat" ? (
            <SubContainer3 />
          ) : scheduleInvite ? (
            <SubContainer4>
              <SubContainer5 />
            </SubContainer4>
          ) : (
            <SubContainer3 />
          )}
          <div className="workClass">
            <Cross />
          </div>
        </CrossContainer>
        <SubContainer1>
          <MainT1>{data.userData.fullName}</MainT1>
          <MainT2>
            {isEnglish
              ? "Propose a conversation to talk to your neighbour Peter Lustig"
              : "Schlage deinem Nachbar Peter Lustig eine Zeit vor um mit ihm zu sprechen"}
          </MainT2>
          <MainT33>
            <MainT3>{data.projectData.project_details.address.complete}</MainT3>
            <Maps />
          </MainT33>
          <MainT4>
            {isEnglish
              ? "12km distance to your location"
              : "12km Entfernung zu deiner Adresse"}
          </MainT4>
        </SubContainer1>
      </div>
      <SubContainer2>
        <LeftSide data={data} />
        {scheduleInvite ? (
          <RightSide
            setInviteState={setInviteState}
            setScheduleInviteState={setScheduleInviteState}
            setdiscriptiion={setdiscriptiion}
            discriptiion={discriptiion}
            selected={selected}
            sender_uid={sender_uid}
            receiver_uid={receiver_uid}
            setSelected={setSelected}
          />
        ) : (
          <SelectDay
            sender_uid={sender_uid}
            receiver_uid={receiver_uid}
            discriptiion={discriptiion}
            selected={selected}
            setInviteState={setInviteState}
          />
        )}
      </SubContainer2>
    </MainContainer>
  );
};

export default Index;
