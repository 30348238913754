import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SignUpSignInPopUp from "../../../popups/SignUpSignIn/signup.popup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import addbox from "Assets/addbox.svg";
import firebase from "../../../firebase";
import { colors } from "../../../colors";
import add_box from "./images/add_box.svg";
import horizontalStep3Add from "Assets/horizontalStep3Add.svg";
import boxGerman from "Assets/boxGerman.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import addboxTranlate from "Assets/addboxTranlate.svg";
import previewImage from "Assets/previewImage.png";
import previewImage1 from "Assets/previewImage1.png";
import previewImage2 from "Assets/previewImage2.png";
import { useDropzone } from "react-dropzone";
import Question from "./images/Question.svg";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    rootContainer: {
      maxWidth: "1200px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "13px",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
        padding: "5px",
        justifyContent: "flex-start",
        alignItems: "center",
      },
    },
    root: {
      width: "1000px",
      maxWidth: "1200px",
      minWidth: "450px",
      margin: "10px",
      [theme.breakpoints.down("xs")]: {
        width: "75%",
        borderRadius: "10px",
        position: "unset",
        border: "none",
        minWidth: "0px",
      },
    },
    container2: {
      width: "350px",
      display: "flex",
      "@media (max-width: 450px)": {
        justifyContent: "center",
      },
    },
    container2_form: {
      width: "250px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
      [theme.breakpoints.down("xs")]: {
        width: "258px",
      },
      "@media (max-width: 450px)": {
        alignItems: "center",
      },
    },
    imagesContainer: {
      width: "800px",
    },

    img: {
      width: "200px",
      height: "200px",
      objectFit: "cover",
      overflow: "hidden",
      margin: "15px",
    },
    imgHelper: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    container1: {
      display: "flex",
      flexWrap: "wrap",
      width: "60%",
      maxWidth: "650px",
      minWidth: "450px",
      "@media (max-width: 560px)": {
        justifyContent: "center",
        minWidth: "0px",
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    h1Helper: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
      textAlign: "center",
    },
    preiveImageDiv: {
      display: "flex",
      "@media (max-width: 530px)": {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    makeMode: {
      "@media (max-width: 530px)": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-around",
        alignItems: "center",
      },
    },
    preiveImageInsideDiv: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "4px",
      justifyContent: "space-between",
      "@media (max-width: 530px)": {
        flexDirection: "row",
        marginLeft: "0px",
      },
      "& img": {
        "@media (max-width: 530px)": {
          width: "49%",
          marginTop: "5px",
        },
      },
    },
    important: {
      position: "relative",
    },
    makeAbsolute: {
      position: "absolute",
      bottom: "5px",
      left: "15px",
      fontWeight: "600",
      fontSize: "14px",
      textTransform: "uppercase",
      color: "#FFFFFF",
    },
    preview: {
      height: "211px",
      "@media (max-width: 530px)": {
        height: "157px",
      },
    },
  })
);

function UploadPictures({
  isEnglish,
  isComingFromAdmin,
  currentUser,
  project,
  dispatchMethods,
}) {
  const { setProjectImages } = dispatchMethods;

  const classes = useStyles();

  const [signUpSignInPopup, setSignUpSignInPopup] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(false);
  const [whenToOpenPerticaluar, setWhenToOpenPerticaluar] = useState([
    true,
    false,
    false,
  ]);

  const logIn = () => {
    setRegisterStatus(true);
    setSignUpSignInPopup(true);
  };

  const logIn1 = () => {
    setRegisterStatus(false);
    setSignUpSignInPopup(true);
  };

  return (
    <div
      className={classes.rootContainer}
      style={{ height: currentUser ? "100%" : "23vh" }}
    >
      {currentUser || isComingFromAdmin ? (
        <>
          <UploadPictureSection
            currentUser={currentUser}
            expand={true}
            whenToOpenPerticaluarAll={whenToOpenPerticaluar}
            whenToOpenPerticaluar={whenToOpenPerticaluar[0]}
            setWhenToOpenPerticaluar={setWhenToOpenPerticaluar}
            index={0}
            project_images={project?.project_images}
            item={project?.project_images.house_top}
            setProjectImages={setProjectImages}
          />
          <UploadPictureSection
            currentUser={currentUser}
            whenToOpenPerticaluarAll={whenToOpenPerticaluar}
            index={1}
            whenToOpenPerticaluar={whenToOpenPerticaluar[1]}
            setWhenToOpenPerticaluar={setWhenToOpenPerticaluar}
            project_images={project?.project_images}
            item={project?.project_images.house_bottom}
            setProjectImages={setProjectImages}
          />
          <UploadPictureSection
            currentUser={currentUser}
            index={2}
            whenToOpenPerticaluarAll={whenToOpenPerticaluar}
            whenToOpenPerticaluar={whenToOpenPerticaluar[2]}
            setWhenToOpenPerticaluar={setWhenToOpenPerticaluar}
            project_images={project?.project_images}
            item={project?.project_images.electric_meter}
            setProjectImages={setProjectImages}
          />
        </>
      ) : (
        <>
          <h1 className={classes.h1Helper}>
            {isEnglish
              ? "Please log in to upload images"
              : "Bitte speichern Sie ihr Projekt oder loggen Sie sich ein um Bilder hochzuladen."}
          </h1>
          <div className={classes.makeMode}>
            <Button
              className={classes.butoon}
              style={{
                borderRadius: "5px",
                fontWeight: "bold",
                padding: "8px 20px",
                borderRadius: "10px",
                border: `1px solid #000000`,
                marginRight: "10px",
                backgroundColor: "transparent",
              }}
              onClick={logIn}
            >
              <PersonIcon
                style={{
                  marginRight: "3px",
                  marginBottom: "2px",
                  color: "black",
                }}
              />
              {isEnglish ? "Login" : "Anmeldung"}
            </Button>
            <Button
              className={classes.butoon2}
              style={{
                borderRadius: "5px",
                fontWeight: "bold",
                padding: "8px 20px",
                borderRadius: "10px",
                border: `1px solid #000000`,
                backgroundColor: "rgba(255, 194, 32, 1)",
                marginTop: "0px",
              }}
              onClick={logIn1}
            >
              <PersonIcon
                style={{
                  marginRight: "3px",
                  marginBottom: "2px",
                  color: "black",
                }}
              />
              {isEnglish ? "Register" : "Registrieren"}
            </Button>
          </div>
          <SignUpSignInPopUp
            open={signUpSignInPopup}
            registerStatus={registerStatus}
            setOpen={setSignUpSignInPopup}
            isEnglish={isEnglish}
            project={project}
          />
        </>
      )}
    </div>
  );
}

export default UploadPictures;

const TopHeader = ({ isEnglish, project }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: "24px",
      display: "flex",
      alignItems: "center",
    },
    count__container: {
      fontSize: "50px",
      color: colors.darkBlue,
    },
    h2Helper: {
      fontSize: "10px",
      "@media (max-width: 922px)": {
        fontSize: "5px",
      },
    },
    detail__container: {
      margin: "35px 15px",
      "& h1": {
        margin: "0px",
        fontSize: "25px",
        color: colors.darkBlue,
        "@media (max-width: 450px)": {
          fontSize: "15px",
          fontWeight: "bold",
        },
      },
      "& p": {
        margin: "0px",
        color: colors.darkBlue,
        "@media (max-width: 430px)": {
          fontSize: "12px",
        },
      },
    },
    savings_container: {
      display: "flex",
      alignItems: "center",
      justifySelf: "flex-end",
      marginLeft: "100px",
      borderBottom: "2px solid #E7E7E7",
      padding: "2px",
      "@media (max-width: 922px)": {
        flexDirection: "column",
        padding: "0",
        height: "auto",
      },
      "@media (max-width: 477px)": {
        height: "auto",
        marginLeft: "0px",
        display: "none",
      },
      height: "35px",
      borderRadius: "5px",
      "& h2": {
        color: colors.orangeSolid,
        fontSize: "25px",
        "@media (max-width: 922px)": {
          fontSize: "20px",
        },
      },
      "& p": {
        fontSize: "14px",
        marginLeft: "10px",
      },
      myclass: {
        marginLeft: "20px",
        "@media (max-width: 430px)": {
          marginLeft: "5px",
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.count__container}>2</div>
      <div className={classes.detail__container}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <h1>
            {isEnglish ? "UPLOAD YOUR IMAGES" : "Laden Sie ihre Bilder hoch"}
          </h1>
          <img className={classes.myclass} src={Question} alt="" />
        </div>
        {isEnglish ? (
          <p>
            FOR THE OPTIMAL PLANNING OF YOUR SYSTEM IT HELPS IF WE RECEIVE
            DETAILED INFORMATION FROM YOU
          </p>
        ) : (
          <p>
            Für die optimale Planung ihres Systems hilft es uns wenn wir
            detaillierte Informationen
          </p>
        )}
      </div>
    </div>
  );
};

const UploadPictureSection = ({
  item,
  project_images,
  whenToOpenPerticaluar,
  whenToOpenPerticaluarAll,
  setWhenToOpenPerticaluar,
  index,
  currentUser,
  setProjectImages,
  expand,
}) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const { type, total, title, options, description, selectedOption } = item;

  const setDescription = (text) => {
    let x = {};
    x[item.type] = { ...item, description: text };
    setProjectImages({ ...project_images, ...x });
  };
  const setSelectedOption = (option) => {
    let x = {};
    x[item.type] = { ...item, selectedOption: option };
    setProjectImages({ ...project_images, ...x });
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const dispatch = useDispatch();

  const setLoading = (isLoading) => {
    dispatch({ type: "SET_LOADING", payload: isLoading });
  };
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const addImgLocation = (imgLocation) => {
    let x = {};
    const newTotal = [...total];
    newTotal.push(imgLocation);
    x[type] = { ...item, total: newTotal };

    setProjectImages({ ...project_images, ...x });
  };
  const removeImageLocation = (imgLocation) => {
    let x = {};
    const newTotal = [];
    total.map((item) => {
      if (item !== imgLocation) {
        newTotal.push(item);
      }
    });
    x[type] = { ...item, total: newTotal };

    setProjectImages({ ...project_images, ...x });
  };
  const [imageToChange, setImageToChange] = useState(false);
  const uploadImage = (file) => {
    dispatch({ type: "SET_LOADING", payload: true });

    setLoading(true);
    const imgLocation =
      "uploadedImages/" + currentUser.uid + "/" + type + "_" + Date.now();
    firebase
      .storage()
      .ref(imgLocation)
      .put(file)
      .then((snapshot) => {
        toast.success("image uploaded successfully");
        addImgLocation(imgLocation);
        let newItem = [...whenToOpenPerticaluarAll];
        newItem[index === 2 ? 2 : index + 1] = true;
        setWhenToOpenPerticaluar(newItem);
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };

  return (
    <>
      <Accordion
        elevation={"5"}
        expanded={whenToOpenPerticaluar}
        onChange={() => {
          let newItem = [...whenToOpenPerticaluarAll];
          newItem[index] = !whenToOpenPerticaluar;
          setWhenToOpenPerticaluar(newItem);
        }}
        className={classes.root}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{isEnglish ? title.english : title.german}</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={classes.container1}>
            {total?.map((imgLocation) => (
              <Image
                imgLocation={imgLocation}
                removeImageLocation={removeImageLocation}
              />
            ))}
            {total?.length === 0 && expand ? (
              <div className={classes.preiveImageDiv}>
                <div className={classes.important}>
                  <img src={previewImage} className={classes.preview} alt="" />
                  <h3 className={classes.makeAbsolute}>
                    {isEnglish ? "Preview Image" : "Beispielbild"}
                  </h3>
                </div>
                <div className={classes.preiveImageInsideDiv}>
                  <img src={previewImage1} style={{ height: "105px" }} alt="" />
                  <img src={previewImage2} style={{ height: "101px" }} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}
            <Paper
              {...getRootProps({ className: "dropzone" })}
              style={{
                position: "relative",
                margin: "10px",
                zIndex: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <input
                {...getInputProps()}
                onChange={(e) => uploadImage(e.target.files[0])}
              />
              {window.innerWidth <= 530 ? (
                <img className={classes.imgHelper} src={horizontalStep3Add} />
              ) : isEnglish ? (
                <img
                  onMouseEnter={() => setImageToChange(true)}
                  onMouseLeave={() => setImageToChange(false)}
                  className={classes.imgHelper}
                  style={{ height: total?.length === 0 ? "" : "153px" }}
                  src={imageToChange ? addbox : add_box}
                />
              ) : (
                <img
                  onMouseEnter={() => setImageToChange(true)}
                  onMouseLeave={() => setImageToChange(false)}
                  className={classes.imgHelper}
                  style={{ height: total?.length === 0 ? "" : "153px" }}
                  src={imageToChange ? addboxTranlate : boxGerman}
                />
              )}
              {/*<p>Drag 'n' drop some files here, or click to select files</p>*/}
            </Paper>
          </div>
          <div className={classes.container2}>
            <Paper className={classes.container2_form}>
              <TextField
                margin="dense"
                id="outlined-basic"
                label={
                  isEnglish ? "Add description" : "Beschreibung hinzufügen"
                }
                variant="outlined"
                multiline
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: "200px", margin: "10px" }}
                className="ewc1--textInput"
              />

              <Typography
                style={{ width: "200px", fontSize: "12px", paddingLeft: "5px" }}
              >
                {item.type === "electric_meter"
                  ? isEnglish
                    ? "Electric Meter Type"
                    : "Typ des Stromzählers"
                  : isEnglish
                  ? "Building year of the roof"
                  : "Baujahr des Gebäudes"}
              </Typography>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                style={{
                  width: "200px",
                  border: "1px solid lightgray",
                  paddingLeft: "10px",
                }}
              >
                <MenuItem value="">
                  <em>{isEnglish ? "None" : "keiner"}</em>
                </MenuItem>
                {options.map((item) => (
                  <MenuItem value={isEnglish ? item.english : item.german}>
                    {isEnglish ? item.english : item.german}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const Image = ({ imgLocation, removeImageLocation }) => {
  const [imgUrl, setImgUrl] = useState("/images/img-loading1.gif");
  useEffect(() => {
    firebase
      .storage()
      .ref(imgLocation)
      .getDownloadURL()
      .then((url) => setImgUrl(url))
      .catch((e) => {
        console.error(e.message);
        setImgUrl("/images/placeholder.jpg");
      });
  }, []);

  const remove = () => {
    firebase
      .storage()
      .ref(imgLocation)
      .delete()
      .then((snapshot) => {
        toast.success("Image removed successfully");
        removeImageLocation(imgLocation);
        setImgUrl("images/placeholder.jpg");
      });
  };

  return (
    <div style={{ position: "relative", zIndex: "0", margin: "10px" }}>
      <img
        style={{
          position: "relative",
          width: "200px",
          height: "150px",
          zIndex: "0",
          objectFit: "cover",
          border: "1px solid lightgray",
        }}
        src={imgUrl}
      />
      <div
        style={{
          position: "relative",
          background: "rgba(255,255,255,0.3)",
          width: "200px",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "-25px",
          zIndex: "1",
          height: "25px",
        }}
      >
        <DeleteIcon
          onClick={() => remove()}
          style={{ color: colors.darkBlue }}
          color={"secondary"}
        />
      </div>
    </div>
  );
};
