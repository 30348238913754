import React, { useEffect, useState } from "react";
import { costsAndSavings, financingDetails } from "../../utils/costs";
import { useSelector } from "react-redux";
import { Card, makeStyles } from "@material-ui/core";
import { Typography } from "antd";
import { colors } from "../../colors";
import { commaDotHandler } from "helperMethod";
import DetaildCalculation from "pages/Application/partials/DetaildCalculation";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginLeft: "24px",
    display: "flex",
    alignItems: "center",

    // "@media (max-width: 450px)": {
    //   flexDirection: "column",
    // },
  },
  savings_container: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    justifySelf: "flex-end",
    padding: 10,
    // marginLeft: "100px",
    borderBottom: "2px solid #E7E7E7",
    padding: "2px",
    "@media (max-width: 922px)": {
      flexDirection: "column",
      padding: "0",
      height: "auto",
    },
    "@media (max-width: 477px)": {
      flexDirection: "row",
      borderBottom: "4px solid #E7E7E7",
      height: "auto",
      marginLeft: "0px",
    },
    "@media (max-width: 344px)": {
      borderBottom: "4px solid #E7E7E7",
    },
    height: "35px",
    borderRadius: "5px",
    "& h2": {
      color: colors.orangeSolid,
      fontSize: "25px",
      marginTop: "10px",
      marginRight: "5px",
      "@media (max-width: 922px)": {
        fontSize: "20px",
        width: "76px",
      },
      "@media (max-width: 344px)": {
        fontSize: "18px",
        width: "86px",
      },
    },
    "& p": {
      fontSize: "14px",
      marginLeft: "10px",
    },
    myclass: {
      marginLeft: "20px",
      "@media (max-width: 430px)": {
        marginLeft: "5px",
      },
    },
  },
}));
const CalculationButton = ({ activeStep }) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const classes = useStyles();
  const project = useSelector((state) => state.project);
  const [costs_and_savings, set_costs_and_savings] = useState({});
  const [finacing_datails, set_finacing_details] = useState({});
  useEffect(() => {
    set_costs_and_savings(costsAndSavings(project));
    set_finacing_details(financingDetails(project));
  }, [project]);
  const [news, setNews] = useState(false);
  return (
    <div>
      <Card
        elevation={0}
        className={classes.savings_container}
        onClick={() => {
          setNews(true);
        }}
      >
        <h2>
          {`${commaDotHandler(
            Math.round(
              costs_and_savings.electricity_cost_before +
                costs_and_savings.feedin_savings -
                finacing_datails.yearly_financing_amount
            ),
            `${isEnglish ? "en" : "de"}`
          )} €`}
        </h2>
        <Typography>
          {isEnglish ? "YEARLY SAVINGS IN €" : "Jährliche Einsparungen in €"}
        </Typography>
      </Card>
      <DetaildCalculation
        Visible={news}
        setVisible={setNews}
        project={project}
        costs_and_savings={costs_and_savings}
        financing_details={finacing_datails}
      />
    </div>
  );
};

export default CalculationButton;
