import React, { useState, useEffect } from "react";
import { Container, Button, CssBaseline, TextField, Grid, Paper, Dialog, FormControlLabel, Radio } from "@material-ui/core";
import { styles } from "./signup.popup.styles";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import finalLogo from "Assets/finalLogo.svg";
import finalText from "Assets/finalText.svg";
import { colors } from "../../colors";
import Google from "./images/Google.svg";
import Group from "./images/Group.svg";
import Close from "Assets/Close.svg";
import { useHistory } from "react-router-dom";
import { db } from "../../firebase";
import { useProjectTools } from "hooks/useProjectTools";
import { toast } from "react-toastify";

const ProjectPopup = ({ open, setOpen, project, registerStatus, userLikeToSave, onAutomateiclySave, signUpForWebinar }) => {
	const [title, setTitle] = useState({ english: "Save your Solarproject", german: "Speichern Sie ihr Solarprojekt" });
	const [subtitle, setSubTitle] = useState({ english: "Your path to your own energy", german: "Ihr Weg zu ihrer eigenen Energie" });

	const classes = styles();
	const [isRegistered, setIsRegistered] = useState(registerStatus);
	const isEnglish = useSelector((state) => state.global.isEnglish);
	const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const [flag, setFlag] = useState(true);

	useEffect(() => {
		if (signUpForWebinar) {
			setTitle({ english: "Register and join our next Webinar", german: "Registrieren Sie sich und nehmen Sie an unserem nächsten Webinar teil" });
			setSubTitle({
				english: "We will be happy to help you realize your renewable energy solution",
				german: "Wir helfen Ihnen gerne bei der Realisierung Ihrer Erneuerbare-Energien-Lösung",
			});
			setIsRegistered(false);
		}
	}, [signUpForWebinar]);

	const registerUserInDiscourse = async (userDetails) => {
		const data = {
			name: userDetails.fullName,
			email: userDetails.email,
			password: userDetails.disPassword,
			username: userDetails.username,
		};
		const url = `https://solarhub24.herokuapp.com/SolarHub-Discourse/user/createUser`;
		const response = await axios.post(url, data);
		console.log(response, "user response");
	};

	const genrateRandomUserName = (email) => {
		const result = uuidv4().split("-");
		let username = email + result[1];
		if (username.length >= 20) {
			let result = username.length - 20;
			username.slice(0, -result);
		}
		return username;
	};

	const genrateRandomPassword = (email) => {
		const password = email + uuidv4();
		return password;
	};

	return (
		<Dialog onClose={handleClose} open={open} maxWidth={false} style={{ margin: "-15px" }}>
			<div style={{ display: "flex", width: "100%", position: "relative" }}>
				<img
					onMouseEnter={() => setWhichIconShouldBeVisible(true)}
					onMouseLeave={() => setWhichIconShouldBeVisible(false)}
					src={whichIconShouldBeVisible ? Close : Group}
					className={classes.icon}
					onClick={() => setOpen(false)}
				/>
			</div>
			<div style={{ width: "90vw", maxWidth: "1300px" }}>
				<Grid container component='main' className={classes.root}>
					<CssBaseline />
					<Grid item xs={false} sm={4} md={6} className={classes.image} />
					<Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
						<div className={classes.removeWidth}>
							<div
								style={{
									display: "flex",
									marginBottom: "15px",
									marginTop: "10px",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<div />
								<div
									style={{
										display: "flex",
										height: "50px",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<img className={classes.logoResponsive} src={finalLogo} alt='img' />
									<img src={finalText} className={classes.logoResponsive} style={{ marginLeft: "15px" }} alt='' />
								</div>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
									textAlign: "center",
									// margin: "3rem auto 1rem auto",
								}}>
								<h1 className={classes.h1Class}>{isEnglish ? title.english : title.german}</h1>
								<h5 className={classes.h5Class}>{isEnglish ? subtitle.english : subtitle.german}</h5>
							</div>
						</div>
						{flag ? (
							<div className={classes.paper}>
								{isRegistered ? (
									<>
										<UserLoginForm
											isEnglish={isEnglish}
											genrateRandomUserName={genrateRandomUserName}
											genrateRandomPassword={genrateRandomPassword}
											onAutomateiclySave={onAutomateiclySave}
											userLikeToSave={userLikeToSave}
											project={project}
											registerUserInDiscourse={registerUserInDiscourse}
											setOpen={setOpen}
											setFlag={setFlag}
										/>{" "}
										<h3 className={classes.accountH1}>
											<span>{isEnglish ? "You don´t have an account yet?" : "Sie haben noch keinen Account"}</span>{" "}
											<Link onClick={() => setIsRegistered(false)} style={{ textDecoration: "none" }}>
												<span
													style={{
														color: colors.ctaSolid,
														textDecoration: "none",
													}}>
													{isEnglish ? "Register now" : "- Registrieren Sie sich jetzt"}
												</span>
											</Link>
										</h3>
										<h3 className={classes.accountH1}>
											<span>{isEnglish ? "Do you have any questions?" : "Sie haben Fragen?"}</span>{" "}
											<a
												style={{
													color: colors.ctaSolid,
													textDecoration: "none",
												}}
												href='mailto:info@solarhub24.de'>
												{isEnglish ? "Contact us now!" : "Jetzt Kontakt mit uns aufnehmen"}
											</a>
										</h3>
									</>
								) : (
									<>
										<UserSignUpForm
											isEnglish={isEnglish}
											onAutomateiclySave={onAutomateiclySave}
											userLikeToSave={userLikeToSave}
											project={project}
											genrateRandomPassword={genrateRandomPassword}
											genrateRandomUserName={genrateRandomUserName}
											setOpen={setOpen}
											registerUserInDiscourse={registerUserInDiscourse}
											setFlag={setFlag}
											signUpForWebinar={signUpForWebinar}
										/>
										<h3 style={{ margin: "15px 0px 0px -25px" }}>
											<span>{isEnglish ? "Already have an account?" : "Du hast bereits ein Konto? Jetzt einloggen"}</span>{" "}
											<Link onClick={() => setIsRegistered(true)} style={{ textDecoration: "none" }}>
												<span
													style={{
														color: colors.ctaSolid,
														textDecoration: "none",
													}}>
													Login now
												</span>
											</Link>
										</h3>
									</>
								)}
							</div>
						) : (
							<ForgotPassword isEnglish={isEnglish} setOpen={setOpen} setFlag={setFlag} />
						)}
					</Grid>
				</Grid>
			</div>
		</Dialog>
	);
};

export default ProjectPopup;

export const UserSignUpForm = ({
	isEnglish,
	project,
	setOpen,
	registerUserInDiscourse,
	genrateRandomPassword,
	genrateRandomUserName,
	onAutomateiclySave,
	setFlag,
	signUpForWebinar,
}) => {
	const classes = styles();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [user, setUser] = useState(null);
	const [error, setError] = useState();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = React.useState("");
	const { save } = useProjectTools();
	console.log(email, "email");

	const handleChange = (event) => {
		setRememberMe(event.target.value);
	};

	const createUserWithEmailAndPassword = async (email, password) => {
		const actionCodeSettings = {
			// URL you want to redirect back to. The domain (www.example.com) for this
			// URL must be in the authorized domains list in the Firebase Console.
			url: "solarhub-44450-staging.web.app",
			// This must be true.
			handleCodeInApp: true,
			dynamicLinkDomain: "solarhub24.de",
		};

		try {
			const userCred = await firebase.auth().createUserWithEmailAndPassword(email, password);
			await userCred.user.sendEmailVerification({ url: window.location.href });
			console.log(userCred, "userCred");
			return {
				user: userCred.user,
			};
		} catch (e) {
			return {
				error: e.message,
			};
		}
	};

	const signInWithUserEmailLink = async () => {
		console.log(email, password, "logged");
		const updateUserInfo = (userDetails, info) => {
			if (info == "login") {
				const docref = db.collection("users").doc(userDetails.uid);
				docref.get().then((doc) => {
					if (doc.exists) {
						docref.set({ ...userDetails });
					}
				});
			}
			if (info == "register") {
				registerUserInDiscourse(userDetails);
				db.collection("users")
					.doc(userDetails.uid)
					.set({ ...userDetails });
			}
		};
		if (email && password) {
			const { error, user } = await createUserWithEmailAndPassword(email, password);
			if (user) {
				user
					.updateProfile({
						displayName: firstName + " " + lastName,
					})
					.then(function () {
						const ind = email?.indexOf("@");
						const userDetails = {
							fullName: firstName + " " + lastName,
							email,
							username: genrateRandomUserName(email.slice(0, ind)),
							disPassword: genrateRandomPassword(email.slice(0, ind)),
							otherDetails: "other details",
							uid: user.uid,
						};
						setOpen(false);
						updateUserInfo(userDetails, "register");
						if (onAutomateiclySave) {
							save(project, user.uid);
						}
						toast.success("verification mail send");
					})
					.catch((error) => {
						alert(error.message);
					});
			}
			if (error) {
				console.log(error, "erorr");
				toast.error(error);
				return;
			}
			setUser(user ?? null);
		} else {
			toast.error("email and password can not be empty");
		}
	};

	const updateUserInfo = (userDetails, info) => {
		if (info == "register") {
			const docref = db.collection("users").doc(userDetails.uid);
			docref.get().then((doc) => {
				if (doc.exists) {
					if (!doc.data().username) {
						registerUserInDiscourse(userDetails);
					} else {
						delete userDetails.disPassword;
						delete userDetails.username;
					}
					if (doc.data().fullName) {
						delete userDetails.fullName;
					}
					docref.set({
						...doc.data(),
						...userDetails,
					});
				} else {
					registerUserInDiscourse(userDetails);
					db.collection("users")
						.doc(userDetails.uid)
						.set({ ...userDetails })
						.then((data) => {
							console.log(data, "datas");
						})
						.catch((error) => alert(error));
				}
			});
		}
	};

	const continueWithGoogle = (provider) => {
		if (provider === "google.com") {
			provider = new firebase.auth.GoogleAuthProvider();
		} else {
			provider = new firebase.auth.OAuthProvider(provider);
			console.log("inside provider");
		}
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				let user = result.user;
				const ind = user?.email?.indexOf("@");
				const userDetails = {
					fullName: user?.displayName,
					email: user?.email,
					username: genrateRandomUserName(user?.email.slice(0, ind)),
					disPassword: genrateRandomPassword(user?.email.slice(0, ind)),
					otherDetails: "other details",
					uid: user.uid,
				};
				setOpen(false);
				if (onAutomateiclySave) {
					save(project, user.uid);
				}
				updateUserInfo(userDetails, "register");
			})
			.catch((error) => {
				console.log("inside catch");
				alert(error);
			});
	};

	return (
		<Container maxWidth='xs'>
			<div style={{ marginTop: "0px", color: "#fff" }}>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					placeholder={isEnglish ? "Enter First Name" : "Geben Sie Ihren Vornamen ein"}
					autoComplete='email'
					autoFocus
					style={{
						marginBottom: "10px",
					}}
					className={classes.removeMargin}
					InputProps={{
						className: classes.multilineColor,
					}}
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					placeholder={isEnglish ? "Enter Last Name" : "Geben Sie Ihren Nachnamen ein"}
					autoFocus
					style={{
						marginBottom: "10px",
						color: "#fff",
						border: "1px solid white",
					}}
					InputProps={{
						className: classes.multilineColor,
					}}
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					placeholder={isEnglish ? "Enter Email" : "Geben Sie ihre E-Mail ein"}
					name='email'
					autoComplete='email'
					autoFocus
					style={{
						marginBottom: "10px",
						color: "#fff",
						border: "1px solid white",
					}}
					InputProps={{
						className: classes.multilineColor,
					}}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					placeholder={isEnglish ? "Choose Password" : "Wählen Sie ihr Passwort"}
					name='password'
					type='password'
					id='password'
					color={"#fff"}
					autoComplete='current-password'
					style={{
						marginBottom: "10px",
						color: "#fff",
						border: "1px solid white",
					}}
					InputProps={{
						className: classes.multilineColor,
					}}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<div style={{ marginTop: "10px" }}>
					<Grid container>
						<Grid item xs>
							<FormControlLabel
								value={rememberMe}
								control={<Radio style={{ color: "#2D4764" }} />}
								onChange={handleChange}
								label={isEnglish ? "Remember Password" : "Passwort merken in Login"}
								className={classes.radioLabel}
							/>
						</Grid>
						<Grid item style={{ marginTop: "10px" }}>
							<div
								className={classes.links}
								onClick={() => {
									setFlag(false);
								}}>
								{isEnglish ? "Forgot Password" : "Passwort vergessen"}
							</div>
						</Grid>
					</Grid>
				</div>
				<Button fullWidth variant='contained' className={classes.loginBtn} onClick={(e) => signInWithUserEmailLink(e)}>
					Jetzt Registrieren
				</Button>

				<div className={classes.btnContainer}>
					{/* <Button
            fullWidth
            variant="contained"
            startIcon={
              <img style={{ marginRight: "10px" }} src={Microsoft} alt="" />
            }
            className={classes.blueButton}
            onClick={(e) => continueWithGoogle("microsoft.com")}
          >
            {isEnglish ? "Login with Microsoft" : "Einloggen mit Microsoft"}
          </Button> */}
					{/* <Button
            fullWidth
            variant="contained"
            startIcon={
              <img style={{ marginRight: "10px" }} src={Apple} alt="" />
            }
            className={classes.whiteButton}
            onClick={(e) => continueWithGoogle("apple.com")}
          >
            {isEnglish ? "Login with Apple" : "Einloggen mit Apple"}
          </Button> */}
				</div>
				<div className={classes.btnContainer}>
					<Button
						fullWidth
						variant='contained'
						startIcon={<img style={{ marginRight: "10px" }} src={Google} alt='' />}
						className={classes.whiteButton}
						onClick={(e) => continueWithGoogle("google.com")}>
						{isEnglish ? "Login with Google" : "Einloggen mit Google"}
					</Button>
					{/* <Button
            fullWidth
            variant="contained"
            startIcon={
              <img style={{ marginRight: "10px" }} src={linkdin} alt="" />
            }
            className={classes.nextBlue}
            // onClick={(e) => continueWithGoogle("linkedin.com")}
          >
            {isEnglish ? "Login with LinkedIn" : "Einloggen mit LinkedIn"}
          </Button> */}
				</div>
			</div>
		</Container>
	);
};

export const UserLoginForm = ({
	isEnglish,
	project,
	setOpen,
	onAutomateiclySave,
	genrateRandomPassword,
	genrateRandomUserName,
	registerUserInDiscourse,
	setFlag,
}) => {
	const classes = styles();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [rememberMe, setRememberMe] = React.useState("");
	const { save } = useProjectTools();
	const history = useHistory();

	const pushBackToTheirLastProject = (uid) => {
		if (uid) {
			db.collection("saved_projects")
				// .orderBy("createdAt", "desc") // Order documents by added_at field in descending order
				.where("uid", "==", uid)
				.get()
				.then((snap) => {
					const all_projects = [];
					snap.forEach((doc) => {
						all_projects.push(doc.data());
					});
					all_projects.sort((a, b) => new Date(b.createdAt.seconds * 1000) - new Date(a.createdAt.seconds * 1000));
					if (all_projects.length > 0) {
						history.push("/application/saved-" + all_projects[0].pid);
					}
				})
				.catch((e) => alert(e));
		}
	};

	const handleChange = (event) => {
		setRememberMe(event.target.value);
	};

	const logInWithEmailPassword = (e) => {
		e.preventDefault();
		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((userCredential) => {
				// var user = userCredential.user;
				setOpen(false);
				if (onAutomateiclySave) {
					save(project, userCredential.user.uid);
				} else {
					pushBackToTheirLastProject(userCredential.user.uid);
				}
				// window.location.reload();
			})
			.catch((error) => alert(error.message));
	};

	const updateUserInfo = (userDetails, info) => {
		if (info == "login") {
			const docref = db.collection("users").doc(userDetails.uid);
			docref.get().then((doc) => {
				if (doc.exists) {
					if (doc.data().fullName) {
						delete userDetails.fullName;
					}
					docref.set({
						...doc.data(),
						...userDetails,
					});
				}
			});
		}
		if (info == "register") {
			console.log("user");
			const docref = db.collection("users").doc(userDetails.uid);
			docref.get().then((doc) => {
				if (doc.exists) {
					if (!doc.data().username) {
						registerUserInDiscourse(userDetails);
					} else {
						delete userDetails.disPassword;
						delete userDetails.username;
					}
					if (doc.data().fullName) {
						delete userDetails.fullName;
					}
					docref.set({
						...doc.data(),
						...userDetails,
					});
				} else {
					registerUserInDiscourse(userDetails);
					db.collection("users")
						.doc(userDetails.uid)
						.set({ ...userDetails })
						.then((data) => {
							console.log(data, "datas");
						})
						.catch((error) => alert(error));
				}
			});
		}
	};

	const continueWithGoogle = (provider) => {
		if (provider === "google.com") {
			provider = new firebase.auth.GoogleAuthProvider();
		} else {
			provider = new firebase.auth.OAuthProvider(provider);
		}
		if (onAutomateiclySave) {
			firebase
				.auth()
				.signInWithPopup(provider)
				.then((result) => {
					let user = result.user;
					const ind = user?.email.indexOf("@");
					const userDetails = {
						fullName: user?.displayName,
						email: user?.email,
						username: genrateRandomUserName(user?.email.slice(0, ind)),
						disPassword: genrateRandomPassword(user?.email.slice(0, ind)),
						otherDetails: "other details",
						uid: user.uid,
					};
					setOpen(false);
					if (onAutomateiclySave) {
						save(project, user.uid);
					} else {
						pushBackToTheirLastProject(user.uid);
					}
					updateUserInfo(userDetails, "register");
					console.log("reloading application");
					// window.location.reload();
				})
				.catch((error) => {});
		} else {
			firebase
				.auth()
				.signInWithPopup(provider)
				.then((result) => {
					let user = result.user;
					const ind = user?.email.indexOf("@");
					const userDetails = {
						fullName: user?.displayName,
						email: user?.email,
						username: genrateRandomUserName(user?.email.slice(0, ind)),
						disPassword: genrateRandomPassword(user?.email.slice(0, ind)),
						otherDetails: "other details",
						uid: user.uid,
					};
					updateUserInfo(userDetails, "register");
					setOpen(false);
					if (onAutomateiclySave) {
						save(project, user.uid);
					} else {
						pushBackToTheirLastProject(user.uid);
					}
					// window.location.reload();
				})
				.catch((error) => {
					alert(error);
				});
		}
	};
	return (
		<Container maxWidth='xs'>
			<div style={{ marginTop: "0px", color: "#fff" }}>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					placeholder='Email'
					name='email'
					autoComplete='email'
					autoFocus
					style={{
						marginBottom: "10px",
						color: "#fff",
						border: "1px solid white",
					}}
					InputProps={{
						className: classes.multilineColor,
					}}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					placeholder='Password'
					name='password'
					type='password'
					id='password'
					color={"#fff"}
					autoComplete='current-password'
					style={{
						marginBottom: "10px",
						color: "#fff",
						border: "1px solid white",
					}}
					InputProps={{
						className: classes.multilineColor,
					}}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<div style={{ marginTop: "10px" }}>
					<Grid container>
						<Grid item xs>
							<FormControlLabel
								value={rememberMe}
								control={<Radio style={{ color: "#2D4764" }} />}
								onChange={handleChange}
								label={isEnglish ? "Remember Password" : "Passwort merken in Login"}
								className={classes.radioLabel}
							/>
						</Grid>
						<Grid item style={{ marginTop: "10px" }}>
							<div
								className={classes.links}
								onClick={() => {
									setFlag(false);
								}}>
								{isEnglish ? "Forgot Password" : "Passwort vergessen"}
							</div>
						</Grid>
					</Grid>
				</div>
				<Button fullWidth variant='contained' className={classes.loginBtn} onClick={(e) => logInWithEmailPassword(e)}>
					Jetzt anmelden
				</Button>
				<div className={classes.btnContainer}>
					{/* <Button
            fullWidth
            variant="contained"
            startIcon={
              <img style={{ marginRight: "10px" }} src={Microsoft} alt="" />
            }
            className={classes.blueButton}
            onClick={(e) => continueWithGoogle("microsoft.com")}
          >
            {isEnglish ? "Login with Microsoft" : "Einloggen mit Microsoft"}
          </Button>
          <Button
            fullWidth
            variant="contained"
            startIcon={
              <img style={{ marginRight: "10px" }} src={Apple} alt="" />
            }
            className={classes.whiteButton}
            onClick={(e) => continueWithGoogle("apple.com")}
          >
            {isEnglish ? "Login with Apple" : "Einloggen mit Apple"}
          </Button> */}
				</div>
				<div className={classes.btnContainer}>
					<Button
						fullWidth
						variant='contained'
						startIcon={<img style={{ marginRight: "10px" }} src={Google} alt='' />}
						className={classes.whiteButton}
						onClick={(e) => continueWithGoogle("google.com")}>
						{isEnglish ? "Login with Google" : "Einloggen mit Google"}
					</Button>
					{/* <LinkedinAuth isEnglish={isEnglish} /> */}
				</div>
			</div>
		</Container>
	);
};

export const ForgotPassword = ({ isEnglish, setOpen, setFlag }) => {
	const classes = styles();
	const [email, setEmail] = useState("");

	const forgetPassword = () => {
		if (email === "") {
			toast.error("Please enter your Email");
			return;
		}
		var actionCodeSettings = {
			url: window.location.origin,
			handleCodeInApp: true,
		};
		firebase
			.auth()
			.sendPasswordResetEmail(email, actionCodeSettings)
			.then((result) => {
				toast.success("Email Sent!");
				setOpen(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<Container maxWidth='xs'>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<h3 className={classes.accountH1}>
					<span>{isEnglish ? "Enter your Registered Email" : "Geben Sie Ihre registrierte E-Mail-Adresse ein"}</span>
				</h3>
			</div>
			<div style={{ marginTop: "0px", color: "#fff" }}>
				<TextField
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					placeholder='Email'
					name='email'
					autoComplete='email'
					autoFocus
					style={{
						marginBottom: "10px",
						color: "#fff",
						border: "1px solid white",
					}}
					InputProps={{
						className: classes.multilineColor,
					}}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Button fullWidth variant='contained' className={classes.loginBtn} onClick={forgetPassword}>
					{isEnglish ? "Forget Password" : "Passwort vergessen"}
				</Button>
			</div>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<h3 className={classes.accountH1}>
					<span>{isEnglish ? "Have an Account?" : "Ein Konto haben?"}</span>{" "}
					<span
						style={{
							color: colors.ctaSolid,
							textDecoration: "none",
							cursor: "pointer",
						}}
						onClick={() => {
							setFlag(true);
						}}>
						{isEnglish ? "Login Now" : "- Jetzt einloggen"}
					</span>
				</h3>
			</div>
		</Container>
	);
};
