import { Button } from "@material-ui/core";
import React, { useState } from "react";
import zmdi_google from "Assets/application_step_1/zmdi_google.svg";
import carbon_add from "Assets/application_step_1/carbon_add.svg";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Switch } from "antd";
import WelcomeUserNoAddress from "popups/welcomeUserNoAddress/WecomeUserNoAddress";

const HeaderWrapper = styled("div")`
  .header_main {
    height: 84px;
    width: 1428px;
    border-radius: 22px;
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    display: flex;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .add_config {
    background: #ffffff;
    border: 1px solid #2d4764;
    border-radius: 36px;
    height: 40px;
    color: #2d4764;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sideByside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 36px;
    color: #2d4764;
    text-transform: uppercase;
    border: 1px solid #2d4764;
  }
  .left_side {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #2d4764;
  }
  .right_side {
    display: flex;
    gap: 11px;
  }
  @media (max-width: 1164px) {
    .header_main {
      flex-direction: column;
      width: 80% !important;
      height: 107px;
      justify-content: center;
    }
    .left_side {
      padding-bottom: 11px;
      display: none;
    }
    .right_side {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 651px) {
    .header_main {
      flex-direction: column;
      width: 80% !important;
      height: 210px;
      align-items: flex-start;
    }
    .left_side {
      display: none;
    }
    .right_side {
      flex-direction: column;
    }
  }
  @media (max-width: 650px) {
    .adjest_width {
      width: 84vw !important;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
  @media (max-width: 403px) {
    .left_side {
      font-size: 19px;
      flex-direction: column;
    }
    .map_view {
      font-size: 12px;
    }
  }
  @media (max-width: 320px) {
    .header_main {
      height: 217px !important;
    }
  }
`;
const Header = ({
  addDummyProject,
  setIsMapView,
  qrn,
  flag,
  isSavedProject,
  setIsSavedProject,
}) => {
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [open, setOpen] = useState(false);
  function onChange(checked) {
    console.log(checked, "checked");
    setIsSavedProject(checked);
  }
  return (
    <HeaderWrapper>
      <div className={`header_main`}>
        <div className="left_side">Overview of your energy configurations</div>
        <div className="right_side">
          {qrn === "60ebee279eee013e34e64130" ? (
            ""
          ) : (
            <Button
              startIcon={<img src={carbon_add} alt="" />}
              className="add_config"
              onClick={addDummyProject}
            >
              {isEnglish
                ? "Add configuration"
                : window.innerWidth < 347
                ? "konfiguration hinzufügen"
                : "konfiguration hinzufügen"}
            </Button>
          )}
          <div className="sideByside">
            <div className="ted">
              <Switch
                style={{
                  background: isSavedProject ? "#FFD75D" : "#1B4963",
                  marginRight: "7px",
                }}
                checked={isSavedProject}
                onChange={onChange}
              />
              {isSavedProject
                ? isEnglish
                  ? "saved projects"
                  : "Gespeicherte projekte"
                : isEnglish
                ? "Overview"
                : "Überblick"}
            </div>
          </div>
          <Button
            startIcon={<img src={carbon_add} alt="" />}
            className="add_config"
            onClick={() => {
              setOpen(true);
            }}
          >
            {isEnglish ? "Add project" : "projekte hinzufügen"}
          </Button>
          {flag ? (
            <Button
              startIcon={<img src={zmdi_google} alt="" />}
              className="map_view"
              style={{ width: isEnglish ? "132px" : "180px" }}
              onClick={() => setIsMapView(false)}
            >
              {isEnglish ? "Hide map" : "Karte ausblenden"}
            </Button>
          ) : (
            <Button
              startIcon={<img src={zmdi_google} alt="" />}
              className="map_view"
              style={{ width: isEnglish ? "132px" : "180px" }}
              onClick={() => setIsMapView(true)}
            >
              {isEnglish ? "map View" : "Kartenansicht"}
            </Button>
          )}
        </div>
        <WelcomeUserNoAddress open={open} isBlank={true} setOpen={setOpen} />
      </div>
    </HeaderWrapper>
  );
};

export default Header;
