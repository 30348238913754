import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import popup from "Assets/Info/popup.png";
import Kontakt from "Assets/Info/Kontakt.svg";
import Login from "Assets/Info/Login.svg";
import Home from "Assets/Info/Home.svg";
import project_details from "Assets/Info/project_details.svg";
import Roof from "Assets/Info/Roof.svg";
import Graph from "Assets/Info/Graph.svg";
import location from "Assets/Info/location.svg";
import Arrow from "Assets/Info/Arrow.svg";
import Arrow1 from "Assets/Info/Arrow1.png";
import { Progress } from "antd";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "678px",
    },
    leftContainer: {
      padding: "10px 35px",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "space-around",
      width: "517px",
      height: "250px",
      display: "flex",
    },
    rightContainer: {
      padding: "15px",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      background: "white",
    },
    rightContainerSubBody: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      gap: "16px",
    },
    rightContainerSubBodyImg: {
      position: "relative",
      height: "160px",
      width: "160px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    rightContainerSubBody1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    rightContainerSubBodyButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
    },
    rightContainerSubBodyButton1: {
      height: "30px",
      width: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#3E3637",
      borderRadius: "20px",
      color: "#FFFFFF",
      fontSize: "14px",
      cursor: "pointer",
    },
    rightContainerRatingValue: {
      position: "absolute",
      height: "100%",
      width: "100%",
      fontSize: "14px",
      color: "black",
      transform: "translate(15px,-58px)",
    },
    rightContainerName: {
      marginLeft: "8px",
      fontWeight: "400",
      fontSize: "18px",
      color: "#000000",
    },
    leftFooterLeft: {
      width: "30%",
      fontWeight: "600",
      color: "#718096",
      fontSize: "16px",
    },
    leftFooterRight: {
      fontWeight: "500",
      color: "#718096",
      fontSize: "16px",
    },
    leftSubFooter: {
      display: "flex",
    },
    projectdetails: {
      display: "flex",
      gap: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);
const Popup = ({ properties }) => {
  const classes = useStyles();

  const onclickHandler = () => {
    window.open(
      `${window.location.origin}/application/saved-${properties?.pid}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.rightContainer}>
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Progress percent={50} showInfo={false} />
          <div className={classes.rightContainerSubBody}>
            <div className={classes.rightContainerSubBodyImg}>
              <img
                src={properties?.projectImgUrl}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div className={classes.rightContainerSubBody1}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "16px",
                  }}
                >
                  <div style={{ height: "20px", width: "20px" }}>
                    <img
                      src={popup}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>{`${properties?.complate_address}`}</div>
                  <img src={Login} alt="" />
                  <img src={Home} alt="" />
                </div>
                <div
                  className={classes.projectdetails}
                  onClick={() => {
                    onclickHandler();
                  }}
                >
                  <div style={{ height: "20px", width: "20px" }}>
                    <img
                      src={Arrow1}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>Project Details</div>
                </div>
                <div style={{ display: "flex", gap: "16px" }}>
                  <div style={{ height: "20px", width: "20px" }}>
                    <img
                      src={Roof}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>{properties?.roof_type}</div>
                  <div>{properties?.roof_material}</div>
                  <div>{properties?.roof_tilt}</div>
                  <div>{properties?.roof_material}</div>
                </div>
                <div style={{ display: "flex", gap: "16px" }}>
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={Graph}
                      alt=""
                      style={{ align: "middle", height: "100%", width: "100%" }}
                    />
                  </div>
                  <div>{properties?.total_project_cost}€</div>
                </div>
                <div className={classes.rightContainerSubBodyButton}>
                  <button className={classes.rightContainerSubBodyButton1}>
                    Make an appointment
                  </button>
                  <div>En Solar GmbH, Stuttgart</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: "1.5rem" }}>
                  {properties?.total_production}kWp
                </div>
                <div style={{ height: "64px", position: "relative" }}>
                  {/* <Progress
                    style={{ height: "64px" }}
                    type="circle"
                    percent={76}
                    format={() => "4.3"}
                  />
                  <div className={classes.rightContainerRatingValue}>4.3</div> */}
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={location} alt="" />
                  </div>
                  <div>ID 2231</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
