import { Select, MenuItem, makeStyles } from "@material-ui/core";
import { Button } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Forward1 from "Assets/community/Forward1.svg";
import { TimeArray } from "helperMethod";
import TimezoneSelect from "react-timezone-select";
import { v4 as uuidv4 } from "uuid";
import { TimePicker } from "antd";
import Cross from "Assets/community/cross.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SelectDayWrapper = styled("div")`
  .time_main {
    width: 553px;
    padding: 10px;
    height: 441px;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    padding-top: 22px;
    position: relative;
    padding-left: 19px;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 600px) {
      width: 100%;
      height: auto;
      padding-bottom: 40px;
    }
  }
  .first_part {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .first_text {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #2d4764;
  }
  .secound_text {
    color: linear-gradient(226.42deg, #1b4963 8.93%, #09152f 110.98%);
    font-size: 14px;
  }
  .text2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .select_class {
    border: 1px solid #c0c0c0;
    box-sizing: border-box;
    border-radius: 16px;
    height: 27px;
    padding: 0px;
    width: 103px;
  }
  .ant-picker-dropdown {
    z-index: 1200000 !important;
  }
  .time_select {
    width: 182px;
  }
  .css-1s2u09g-control {
    border-radius: 30px;
  }
  .selected_days {
    width: 100%;
  }
  .first_container {
    display: flex;
    width: 100%;
  }
  .two_parts {
    display: flex;
    width: 531px;
    justify-content: space-between;
    padding-top: 9px;
    padding-bottom: 3px;
    align-items: center;
    @media (max-width: 600px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .makeitCenter {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .makeitCenter1 {
    display: flex;
    width: 60%;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .days {
    display: flex;
    gap: 4px;
  }
  .first_container_text {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .same_class {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    color: #2d4764;
    border: 1px solid #2d4764;
    box-sizing: border-box;
    border-radius: 22px;
    font-size: 14px;
  }
  .ant-picker {
    border-color: rgba(45, 71, 100, 1);
    border-radius: 27px;
    padding: 4px 15px 4px;
    width: 117px;
  }
  .ant-picker:hover {
    border-color: rgba(45, 71, 100, 1);
  }
  .ant-picker-focused {
    border-color: rgba(45, 71, 100, 1);
  }
  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      margin-top: 9px;
    }
  }
  .newText {
    margin-left: 8px;
    margin-right: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #2d4764;
  }
  .cross {
    cursor: pointer;
    padding-right: 14px;
  }
  .newClass {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding-top: 2px;
    text-decoration-line: underline;
    color: #2d4764;
    cursor: pointer;
    @media (max-width: 600px) {
      align-items: flex-start !important;
      padding-left: 4px;
      padding-top: 3px;
    }
  }
  .last_button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  .send_button {
    position: absolute;
    bottom: 19px;
    right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 167px;
    height: 35px;
    background: #ffd75d;
    border-radius: 42px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #2d4764;
    cursor: pointer;
  }
  .ant-btn {
    border: none;
  }
  .mobileThing {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;

const useStyles = makeStyles((theme) => ({
  rootFirstSelect: {
    paddingLeft: "19px",
    paddingRight: "0px !important",
    fontSize: "14px",
  },
}));

const SelectDay = ({
  sender_uid,
  receiver_uid,
  discriptiion,
  setdiscriptiion,
  setInviteState,
}) => {
  const classes = useStyles();
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [selectDayTime, setSelectDayTime] = useState([]);
  const [getError, setGetError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meetDuration, setMeetDuration] = useState("30min");
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const [idforUse, setId] = useState("");
  const data = [
    {
      id: "rushil123",
      days: [
        {
          dayName: "Sunday",
          isSelected: false,
        },
        {
          dayName: "Monday",
          isSelected: false,
        },
        {
          dayName: "Tuesday",
          isSelected: false,
        },
        {
          dayName: "Wednesday",
          isSelected: false,
        },
        {
          dayName: "Thursday",
          isSelected: false,
        },
        {
          dayName: "Friday",
          isSelected: false,
        },
        {
          dayName: "Saturday",
          isSelected: false,
        },
      ],
      fromHours: null,
      toHours: null,
    },
  ];
  useEffect(() => {
    setSelectDayTime(data);
  }, []);

  function get_time_diff(previousDate, currentDate) {
    var last = new Date(previousDate).getTime();
    var curr = new Date(currentDate).getTime();
    if (isNaN(last)) {
      return "";
    }
    console.log(last + " " + curr);
    return curr - last;
  }

  function onChangeFromTime(time, id) {
    let dummyData = selectDayTime;
    for (let i = 0; i < dummyData.length; i++) {
      if (dummyData[i].id === id) {
        if (time._d !== null) {
          dummyData[i].fromHours = time;
        }
      }
    }
    setSelectDayTime([...dummyData]);
    console.log(dummyData);
  }

  function CompareDate(previousDate, currentDate) {
    //Note: 00 is month i.e. January
    var dateOne = new Date(`${previousDate}`); //Year, Month, Date
    var dateTwo = new Date(`${currentDate}`); //Year, Month, Date
    if (dateTwo > dateOne) {
      return true;
    } else {
      return false;
    }
  }

  function onChangeToTIme(time, id) {
    if (time) {
      const result = selectDayTime.filter((data) => data.id === id);
      if (!CompareDate(result[0].fromHours, time._d)) {
        setGetError(true);
        return toast.error(
          "you selected less time then last please select valid time"
        );
      }
      const duration = returnMiliSecound();
      const diffrance = get_time_diff(result[0].fromHours, time._d);
      console.log(duration, diffrance);
      if (diffrance < duration) {
        setGetError(true);
        return toast.error("select valid slot");
      }
      let dummyData = selectDayTime;
      for (let i = 0; i < dummyData.length; i++) {
        if (dummyData[i].id === id) {
          if (time._d !== null) {
            dummyData[i].toHours = time;
          }
        }
      }
      setSelectDayTime([...dummyData]);
      setGetError(false);
    }
  }

  console.log(selectDayTime, "selectDayTime");

  const addNewDay = () => {
    if (getError) {
      return toast.error("select above time it is blank now");
    }
    const data = {
      id: uuidv4(),
      days: [
        {
          dayName: "Sunday",
          isSelected: false,
        },
        {
          dayName: "Monday",
          isSelected: false,
        },
        {
          dayName: "Tuesday",
          isSelected: false,
        },
        {
          dayName: "Wednesday",
          isSelected: false,
        },
        {
          dayName: "Thursday",
          isSelected: false,
        },
        {
          dayName: "Friday",
          isSelected: false,
        },
        {
          dayName: "Saturday",
          isSelected: false,
        },
      ],
      fromHours: null,
      toHours: null,
    };
    setSelectDayTime([...selectDayTime, data]);
  };

  const onClickSetDay = (id, index) => {
    console.log(id, index);
    let dummyData = selectDayTime;
    for (let i = 0; i < dummyData.length; i++) {
      if (dummyData[i].id === id) {
        for (let j = 0; j < dummyData[i].days.length; j++) {
          if (j === index) {
            dummyData[i].days[index].isSelected =
              !dummyData[i].days[index].isSelected;
          }
        }
      }
    }
    setSelectDayTime([...dummyData]);
  };

  const deleteCurrentField = (currentId) => {
    const result = selectDayTime.filter((data, index) => data.id !== currentId);
    setSelectDayTime(result);
  };

  const handleChange = (event) => {
    setMeetDuration(event.target.value);
  };

  const returnFinalData = () => {
    let send_data = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    };
    let copyData = selectDayTime;
    for (let i = 0; i < copyData.length; i++) {
      for (let j = 0; j < copyData[i].days.length; j++) {
        if (copyData[i].days[j].isSelected === true) {
          for (const [key, value] of Object.entries(send_data)) {
            if (key === copyData[i].days[j].dayName) {
              var today = new Date(copyData[i].fromHours._d);
              var today1 = new Date(copyData[i].toHours._d);
              value.push({
                FROM: today.getHours() + ":" + today.getMinutes(),
                TO: today1.getHours() + ":" + today1.getMinutes(),
              });
            }
          }
        }
      }
    }
    return send_data;
  };

  const isValueNull = () => {
    let dummyData = selectDayTime;
    let counter = 0;
    let isNullOnDay = false;
    let isNullDate = false;
    console.log(isNullOnDay, "isNullOnDay0");
    for (let i = 0; i < dummyData.length; i++) {
      for (let j = 0; j < dummyData[i].days.length; j++) {
        if (!dummyData[i].days[j].isSelected) {
          counter = counter + 1;
        }
        console.log(counter, "counter");
        if (counter === 7) {
          console.log("hey");
          isNullOnDay = true;
        }
      }
      console.log(isNullOnDay, "isNullOnDay");
      if (dummyData[i].fromHours === null || dummyData[i].toHours === null) {
        isNullDate = true;
      }
      if (isNullOnDay === true) {
        isNullOnDay = false;
        return { isNullOnDay: true, isNullDate };
      }
      counter = 0;
    }
    return { isNullOnDay, isNullDate };
  };

  const onSubmitHandler = async () => {
    console.log(isValueNull().isNullOnDay, "isNullOnDayInside");
    if (isValueNull().isNullOnDay === true) {
      return toast.error("please select day it cant't ne empty");
    }
    if (isValueNull().isNullDate === true) {
      return toast.error("plese select time slot time slot must be fill");
    }
    setLoading(true);
    const getData = returnFinalData();
    //just call api
    const data = {
      sender_uid,
      receiver_uid,
      description: discriptiion,
      meetDuration,
      getData,
    };
    console.log(data);
    try {
      const result = await axios.post(
        "https://solarhub24.herokuapp.com/SolarHub-Discourse/invitation/sendInvitation",
        data
      );
      if (result) {
        setInviteState(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setInviteState(false);
    }
  };

  function convertDurationtoSeconds(duration) {
    const [hours, minutes, seconds] = duration.split(":");
    return (
      (Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)) * 1000
    );
  }

  const returnMiliSecound = () => {
    if (meetDuration.includes("min")) {
      const result = meetDuration.substr("min", 2);
      console.log(result, "result");
      const newString = `00:${result}:00`;
      return convertDurationtoSeconds(newString);
    }
    if (meetDuration.includes("hr")) {
      const firstMeter = meetDuration.split("hr")[0];
      if (!meetDuration.includes(":")) {
        return convertDurationtoSeconds(`${firstMeter}:00:00`);
      }
      const secoundMeter = meetDuration.split("hr")[1].split(":")[1];
      const newString = `${firstMeter}:${secoundMeter}:00`;
      console.log(newString, "newString");
      return convertDurationtoSeconds(newString);
    }
  };

  return (
    <SelectDayWrapper>
      <div className="time_main">
        <div className="first_part">
          <div className="first_text">
            {isEnglish ? "Set your availability" : "Wann hättest du Zeit?"}
          </div>
          <div className="secound_text">
            {isEnglish
              ? " Let us know when you are available for meetings."
              : "Gib Peter bescheid wann du für einen Termin verfügbar wärst."}
          </div>
        </div>
        <div className="first_part">
          <div className="text2">
            {isEnglish ? "Meet duration" : "Dauer eures Austauschs"}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            className="select_class"
            style={{ padding: "0px" }}
            onChange={handleChange}
            classes={{ root: classes.rootFirstSelect }}
            IconComponent={() => (
              <img src={Forward1} style={{ marginRight: "10px" }} alt="" />
            )}
            disableUnderline
            defaultValue="30min"
          >
            {TimeArray.map((data, key) => {
              return <MenuItem value={data}>{data}</MenuItem>;
            })}
          </Select>
        </div>
        <div className="first_part">
          <div className="text2">{isEnglish ? "Time zone" : "Zeitzone"}</div>
          <TimezoneSelect
            className="time_select"
            value={selectedTimezone}
            onChange={setSelectedTimezone}
          />
        </div>
        <div className="selectd_days">
          <div className="first_container">
            <div className="makeitCenter">
              <div className="first_container_text">
                {isEnglish ? "Available days" : "Verfügbare Tage"}
              </div>
            </div>
            <div className="makeitCenter1">
              <div className="first_container_text">
                {isEnglish ? "Available Hours" : "Mögliche Uhrzeit"}
              </div>
            </div>
          </div>
          {selectDayTime.map((data, index) => {
            return (
              <div className="two_parts" key={index}>
                <div className="days">
                  {data.days.map((days, index) => {
                    return (
                      <div
                        className="same_class"
                        key={index}
                        style={{
                          backgroundColor:
                            days.isSelected === true ? "#FFD75D" : "",
                        }}
                        onClick={() => onClickSetDay(data.id, index)}
                      >
                        {days.dayName[0]}
                      </div>
                    );
                  })}
                </div>
                <div className="mobileThing">
                  <div className="time">
                    <TimePicker
                      use12Hours
                      clearIcon={false}
                      format="h:mm a"
                      onChange={(time) => {
                        onChangeFromTime(time, data.id);
                      }}
                    />
                    <div className="newText">to</div>
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      clearIcon={false}
                      value={data.toHours}
                      onChange={(time) => {
                        onChangeToTIme(time, data.id);
                      }}
                    />
                  </div>
                  <img
                    src={Cross}
                    alt=""
                    onClick={() => deleteCurrentField(data.id)}
                    className="cross"
                  />
                </div>
              </div>
            );
          })}
          <div
            className="newClass"
            onClick={() => {
              addNewDay();
            }}
          >
            {isEnglish ? "+Add" : "+Hinzufügen"}
          </div>
        </div>
        <div className="last_button">
          <Button
            loading={loading}
            className="send_button"
            onClick={onSubmitHandler}
          >
            {isEnglish ? "Send invite" : "Einladung senden"}
          </Button>
        </div>
      </div>
    </SelectDayWrapper>
  );
};

export default SelectDay;
