export const productsList = [
  {
    ID: "B1",
    Available: "Yes",
    name: "BYD B-Box Premium LVS 8.0",
    type: "battery",
    brand: "BYD",
    "allowed inverter": "SMA Sunny Island, Solaredge StorEdge",
    short_description:
      "Kapazität von 4 kWh bis 256 kWh\nMaximale Flexibilität für jede Anwendung mit bis zu 64 parallel geschalteten Modulen\nKompatibel mit marktführenden 1- und 3-Phasen-Wechselrichtern\nKobaltfreie Lithium-Eisen-Phosphat-Batterie (LFP): Maximale Sicherheit, Lebensdauer und Leistung\nLeistungsstarke Notstrom- und Off-Grid-Funktion\nDas patentierte interne Steckdesign erfordert keine zusätzliche Verkabelung\nEigenverbrauchsoptimierung für Wohn- und Gewerbeanwendungen",
    long_description:
      "Schließen Sie bis zu 16 Battery-Box LVS 16.0 parallel für \neine maximale Größe von 256 kWh. Das System kann \ndurch Hinzufügen zusätzlicher LVS-Module oder durch \nParallelschaltung mehrerer Türme mit 1 bis 4 Modulen \nspäter erweitert werden.",
    Highlights:
      "Kapazität von 4 kWh bis 256 kWh\nMaximale Flexibilität für jede Anwendung mit bis zu 64 parallel geschalteten Modulen\nKompatibel mit marktführenden 1- und 3-Phasen-Wechselrichtern\nKobaltfreie Lithium-Eisen-Phosphat-Batterie (LFP): Maximale Sicherheit, Lebensdauer und Leistung\nLeistungsstarke Notstrom- und Off-Grid-Funktion\nDas patentierte interne Steckdesign erfordert keine zusätzliche Verkabelung\nEigenverbrauchsoptimierung für Wohn- und Gewerbeanwendungen",
    "Technische Produktdaten":
      " Kapazitzät in kWh: 8\n    Nennspannung: 51,2 V\n    Maße (BxHxT) in mm: 650 x 711 x 298\n    Gewicht in kg: 109\n    Betriebstemperatur in °C: -10 bis 50\n    ",
    Garantie: "10 Jahre Garantie",
    Herstellerinformation:
      "BYD Company Limited ist der weltweit grösste Hersteller von von wiederaufladbaren Akkumulatoren sowie der grösste Automobilproduzenten Chinas, mit dem klaren Fokus auf Elektrofahrzeuge. Das 1995 in Shenzhen gegründete Unternehmen hat heute über 230'000 Mitarbeiter. Bereits fünf Jahre nach der Gründung wurde es zum global grössten Hersteller von wieder aufladbaren Batterien. ",
    bullet_point_list:
      '["Lithium-Eisenphosphat (LiFePO4)", "Niedervolt", "10 Jahre Produktgarantie"]',
    price_b2c: 5479,
    price_b2b: 4437.990000000001,
    color: '["white"]',
    subtype: "Niedervolt",
    video_link: "https://youtu.be/HgOH5dNr1rI",
    extended_warranty_cost: null,
    warranty_link: null,
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 8000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/generated/product/1/540_340_100/byd_b-box_lvs.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EVcOCluoqi1CqKMwGIwRMfAB49rJZGAFlTgJV0RPrb4U_Q?e=HregDm",
  },
  {
    ID: "B2",
    Available: "Yes",
    name: "BYD B-Box Premium HVS 7.7",
    type: "battery",
    brand: "BYD",
    "allowed inverter":
      "Fronius Symo Gen24 Plus, Fronius Symo Hybrid, Kostal Plenticore, Kostal Piko MP Plus, SMA Sunny Boy Storage ",
    short_description:
      "Leistungsstarke Not-/Ersatzstrom- und Off-Grid-Funktionalität\nPreisgekrönte Effizienz dank echter Serienschaltung\nDas patentierte modulare Steckdesign erfordert keine interne Verkabelung\nund ermöglicht maximale Flexibilität und Benutzerfreundlichkeit\nKobaltfreie Lithium-Eisen-Phosphat-Batterie (LFP): Maximale Sicherheit, Lebensdauer und Leistung\nKompatibel mit führenden 1- und 3-Phasen-Wechselrichtern\nZwei verschiedene Module zur Abdeckung des gesamten Systemgrößenbereichs\nHöchste Sicherheitsstandards wie VDE 2510-50",
    long_description:
      "Eine Battery-Box Premium HVS besteht aus 2 bis 5 HVS-\nBatteriemodulen, die in Reihe geschaltet sind, um eine\nKapazität von 5,1 bis 12,8 kWh zu erreichen.\nDie direkte Parallelschaltung von bis zu 3 identischen\nBattery-Box Premium HVS ermöglicht zusätzlich eine\nmaximale Kapazität von 38,4 kWh.\nDas System kann durch Hinzufügen zusätzlicher HVS-Module\noder paralleler HVS-Türme später erweitert werden",
    Highlights:
      "Leistungsstarke Not-/Ersatzstrom- und Off-Grid-Funktionalität\nPreisgekrönte Effizienz dank echter Serienschaltung\nDas patentierte modulare Steckdesign erfordert keine interne Verkabelung\nund ermöglicht maximale Flexibilität und Benutzerfreundlichkeit\nKobaltfreie Lithium-Eisen-Phosphat-Batterie (LFP): Maximale Sicherheit, Lebensdauer und Leistung\nKompatibel mit führenden 1- und 3-Phasen-Wechselrichtern\nZwei verschiedene Module zur Abdeckung des gesamten Systemgrößenbereichs\nHöchste Sicherheitsstandards wie VDE 2510-50",
    "Technische Produktdaten":
      " Kapazitzät in kWh: 7,7\n    Nennspannung: 307 V\nWirkungsgrad: ≥96%    \nMaße (BxHxT) in mm: 585 x 945 x 298\n    Gewicht in kg: 129\n    Betriebstemperatur in °C: -10 bis 50\n    ",
    Garantie: "10 Jahre Garantie",
    Herstellerinformation:
      "BYD Company Limited ist der weltweit grösste Hersteller von von wiederaufladbaren Akkumulatoren sowie der grösste Automobilproduzenten Chinas, mit dem klaren Fokus auf Elektrofahrzeuge. Das 1995 in Shenzhen gegründete Unternehmen hat heute über 230'000 Mitarbeiter. Bereits fünf Jahre nach der Gründung wurde es zum global grössten Hersteller von wieder aufladbaren Batterien. ",
    bullet_point_list: null,
    price_b2c: 6229,
    price_b2b: 5045.490000000001,
    color: '["white"]',
    subtype: "Hochvolt",
    video_link: "https://youtu.be/HgOH5dNr1rI",
    extended_warranty_cost: null,
    warranty_link: null,
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 7700,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/byd_b-box_hvs_hvm.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EcVVz2pvWZ9NjOtvjOswWPABEKESntxUP5EVlWNttD_yWQ?e=hC8Cnw",
  },
  {
    ID: "I1",
    Available: "Yes",
    name: "SMA Sunny Tripower 10.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der neue SMA Sunny Tripower 3.0–10.0 sichert maximale Solarerträge für größere Solaranlagen im privaten Bereich. Mit dem integrierten Service SMA Smart Connected bietet er Rundum-Komfort für Anlagenbetreiber und Installateure. So analysiert dieses den Betrieb des Wechselrichters und informiert bei Fehlermeldungen automatisch das Installateursunternehmen, wodurch ein optimaler Service des Produkts gewährleistet wird. Fehlerhafte und ans Internet angeschlossene Produkte können auf diese Weise innerhalb der Garantiezeit sofort ausgetauscht werden. Das Smart Connected ist bisher einzigartig in der Solarbranche und vollkommen kostenfrei. Das Gerät ist durch sein extrem leichtes Gewicht von 17 kg einfach zu installieren. Über die integrierte Weboberfläche lässt sich der Sunny Tripower schnell per Smartphone oder Tablet in Betrieb nehmen. SMA ShadeFix ist eine patentierte und integrierte Wechselrichter-Software, die den Ertrag von Photovoltaikanlagen in jeder Situation optimiert. Dank der stetigen Weiterentwicklung des bewährten MPP-Trackings der SMA PV-Wechselrichter, findet SMA ShadeFix auch bei verschatteten PV-Modulen zuverlässig den Punkt mit der aktuell höchsten Leistung. Die Vorteile liegen auf der Hand: mehr Ertrag, längere Lebensdauer und weniger Leistungselektronik auf dem Dach.",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      "Nennleistung in W: 10000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: A:2; B:1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 460 x 497 x 176\n    Gewicht in kg: 20,5\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation: null,
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 1959,
    price_b2b: 1586.7900000000002,
    color: '["blue"]',
    subtype: "standard",
    video_link: "https://youtu.be/UGxqbY7v_l0 ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 10000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_3.0-6.0.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EcwE3KY3p0FCsi_a-EIKRcMBVT8PMkUhMv1ivv6kU9hHDA?e=q9fQOc",
  },
  {
    ID: "I2",
    Available: "Yes",
    name: "SMA Sunny Tripower 20000",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der Sunny Tripower ist der ideale Wechselrichter für größere Anlagen im gewerblichen und industriellen Bereich. Mit seinem\nWirkungsgrad von 98,4 Prozent sorgt er nicht nur für außergewöhnlich hohe Erträge, sondern bietet durch sein Multistringkonzept\nin Verbindung mit einem weiten Eingangsspannungsbereich eine hohe Auslegungsflexibilität und Kompatibilität mit\nvielen verfügbaren PV-Modulen.\nZukunftweisend ist die Integration neuer Netzmanagementfunktionen wie z. B. Integrated Plant Control, die es erlaubt, eine\nBlindleistungsregelung am Netzanschlusspunkt allein durch den Wechselrichter durchzuführen. Hierbei können übergeordnete\nRegelungseinheiten entfallen und die Systemkosten gesenkt werden. Eine weitere Neuheit ist die Blindleistungsbereitstellung\nrund um die Uhr (Q on Demand 24/7).",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      "Nennleistung in W: 20000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 3\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 661 x 682 x 264\n    Gewicht in kg: 61\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation: null,
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 2594,
    price_b2b: 2101.1400000000003,
    color: '["blue"]',
    subtype: "standard",
    video_link: null,
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl ",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 20000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_tl-30(1).png",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EfwwexkBfTFIpUKdY7Hdvl8BmB1MhjdaZPf_9kCNG59yWw?e=buhbtj ",
  },
  {
    ID: "I3",
    Available: "Yes",
    name: "SMA Sunny Tripower 25000",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der Sunny Tripower ist der ideale Wechselrichter für größere Anlagen im gewerblichen und industriellen Bereich. Mit seinem\nWirkungsgrad von 98,4 Prozent sorgt er nicht nur für außergewöhnlich hohe Erträge, sondern bietet durch sein Multistringkonzept\nin Verbindung mit einem weiten Eingangsspannungsbereich eine hohe Auslegungsflexibilität und Kompatibilität mit\nvielen verfügbaren PV-Modulen.\nZukunftweisend ist die Integration neuer Netzmanagementfunktionen wie z. B. Integrated Plant Control, die es erlaubt, eine\nBlindleistungsregelung am Netzanschlusspunkt allein durch den Wechselrichter durchzuführen. Hierbei können übergeordnete\nRegelungseinheiten entfallen und die Systemkosten gesenkt werden. Eine weitere Neuheit ist die Blindleistungsbereitstellung\nrund um die Uhr (Q on Demand 24/7).",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      "Nennleistung in W: 25000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 3\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 661 x 682 x 264\n    Gewicht in kg: 61\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation: null,
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 2619,
    price_b2b: 2121.3900000000003,
    color: '["blue"]',
    subtype: "standard",
    video_link: null,
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 25000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_tl-30(1).png",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EfwwexkBfTFIpUKdY7Hdvl8BmB1MhjdaZPf_9kCNG59yWw?e=buhbtj",
  },
  {
    ID: "I4",
    Available: "Yes",
    name: "SMA Sunny Tripower 4.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der neue SMA Sunny Tripower 3.0–10.0 sichert maximale Solarerträge für größere Solaranlagen im privaten Bereich. Mit dem integrierten Service SMA Smart Connected bietet er Rundum-Komfort für Anlagenbetreiber und Installateure. So analysiert dieses den Betrieb des Wechselrichters und informiert bei Fehlermeldungen automatisch das Installateursunternehmen, wodurch ein optimaler Service des Produkts gewährleistet wird. Fehlerhafte und ans Internet angeschlossene Produkte können auf diese Weise innerhalb der Garantiezeit sofort ausgetauscht werden. Das Smart Connected ist bisher einzigartig in der Solarbranche und vollkommen kostenfrei. Das Gerät ist durch sein extrem leichtes Gewicht von 17 kg einfach zu installieren. Über die integrierte Weboberfläche lässt sich der Sunny Tripower schnell per Smartphone oder Tablet in Betrieb nehmen. SMA ShadeFix ist eine patentierte und integrierte Wechselrichter-Software, die den Ertrag von Photovoltaikanlagen in jeder Situation optimiert. Dank der stetigen Weiterentwicklung des bewährten MPP-Trackings der SMA PV-Wechselrichter, findet SMA ShadeFix auch bei verschatteten PV-Modulen zuverlässig den Punkt mit der aktuell höchsten Leistung. Die Vorteile liegen auf der Hand: mehr Ertrag, längere Lebensdauer und weniger Leistungselektronik auf dem Dach.",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      "Nennleistung in W: 4000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 435 x 470 x 176\n    Gewicht in kg: 17\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 1249,
    price_b2b: 1011.69,
    color: '["blue"]',
    subtype: "standard",
    video_link: "https://youtu.be/UGxqbY7v_l0 ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 4000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_3.0-6.0.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EXarkh1ximxLl8mLDYqzkXMBsZOciYRSYDIGUesu00IiWQ?e=mNRpj8",
  },
  {
    ID: "I5",
    Available: "Yes",
    name: "SMA Sunny Tripower 5.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der neue SMA Sunny Tripower 3.0–10.0 sichert maximale Solarerträge für größere Solaranlagen im privaten Bereich. Mit dem integrierten Service SMA Smart Connected bietet er Rundum-Komfort für Anlagenbetreiber und Installateure. So analysiert dieses den Betrieb des Wechselrichters und informiert bei Fehlermeldungen automatisch das Installateursunternehmen, wodurch ein optimaler Service des Produkts gewährleistet wird. Fehlerhafte und ans Internet angeschlossene Produkte können auf diese Weise innerhalb der Garantiezeit sofort ausgetauscht werden. Das Smart Connected ist bisher einzigartig in der Solarbranche und vollkommen kostenfrei. Das Gerät ist durch sein extrem leichtes Gewicht von 17 kg einfach zu installieren. Über die integrierte Weboberfläche lässt sich der Sunny Tripower schnell per Smartphone oder Tablet in Betrieb nehmen. SMA ShadeFix ist eine patentierte und integrierte Wechselrichter-Software, die den Ertrag von Photovoltaikanlagen in jeder Situation optimiert. Dank der stetigen Weiterentwicklung des bewährten MPP-Trackings der SMA PV-Wechselrichter, findet SMA ShadeFix auch bei verschatteten PV-Modulen zuverlässig den Punkt mit der aktuell höchsten Leistung. Die Vorteile liegen auf der Hand: mehr Ertrag, längere Lebensdauer und weniger Leistungselektronik auf dem Dach.",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      "Nennleistung in W: 5000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 435 x 470 x 176\n    Gewicht in kg: 17\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 1329,
    price_b2b: 1076.49,
    color: '["blue"]',
    subtype: "standard",
    video_link: "https://youtu.be/UGxqbY7v_l0 ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 5000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_3.0-6.0.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EXarkh1ximxLl8mLDYqzkXMBsZOciYRSYDIGUesu00IiWQ?e=mNRpj8",
  },
  {
    ID: "I6",
    Available: "Yes",
    name: "SMA Sunny Tripower 6.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der neue SMA Sunny Tripower 3.0–10.0 sichert maximale Solarerträge für größere Solaranlagen im privaten Bereich. Mit dem integrierten Service SMA Smart Connected bietet er Rundum-Komfort für Anlagenbetreiber und Installateure. So analysiert dieses den Betrieb des Wechselrichters und informiert bei Fehlermeldungen automatisch das Installateursunternehmen, wodurch ein optimaler Service des Produkts gewährleistet wird. Fehlerhafte und ans Internet angeschlossene Produkte können auf diese Weise innerhalb der Garantiezeit sofort ausgetauscht werden. Das Smart Connected ist bisher einzigartig in der Solarbranche und vollkommen kostenfrei. Das Gerät ist durch sein extrem leichtes Gewicht von 17 kg einfach zu installieren. Über die integrierte Weboberfläche lässt sich der Sunny Tripower schnell per Smartphone oder Tablet in Betrieb nehmen. SMA ShadeFix ist eine patentierte und integrierte Wechselrichter-Software, die den Ertrag von Photovoltaikanlagen in jeder Situation optimiert. Dank der stetigen Weiterentwicklung des bewährten MPP-Trackings der SMA PV-Wechselrichter, findet SMA ShadeFix auch bei verschatteten PV-Modulen zuverlässig den Punkt mit der aktuell höchsten Leistung. Die Vorteile liegen auf der Hand: mehr Ertrag, längere Lebensdauer und weniger Leistungselektronik auf dem Dach.",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      "Nennleistung in W: 6000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 435 x 470 x 176\n    Gewicht in kg: 17\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 1474,
    price_b2b: 1193.94,
    color: '["blue"]',
    subtype: "standard",
    video_link: "https://youtu.be/UGxqbY7v_l0 ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 6000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_3.0-6.0.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EXarkh1ximxLl8mLDYqzkXMBsZOciYRSYDIGUesu00IiWQ?e=mNRpj8 ",
  },
  {
    ID: "I7",
    Available: "Yes",
    name: "SMA Sunny Tripower 8.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der neue SMA Sunny Tripower 3.0–10.0 sichert maximale Solarerträge für größere Solaranlagen im privaten Bereich. Mit dem integrierten Service SMA Smart Connected bietet er Rundum-Komfort für Anlagenbetreiber und Installateure. So analysiert dieses den Betrieb des Wechselrichters und informiert bei Fehlermeldungen automatisch das Installateursunternehmen, wodurch ein optimaler Service des Produkts gewährleistet wird. Fehlerhafte und ans Internet angeschlossene Produkte können auf diese Weise innerhalb der Garantiezeit sofort ausgetauscht werden. Das Smart Connected ist bisher einzigartig in der Solarbranche und vollkommen kostenfrei. Das Gerät ist durch sein extrem leichtes Gewicht von 17 kg einfach zu installieren. Über die integrierte Weboberfläche lässt sich der Sunny Tripower schnell per Smartphone oder Tablet in Betrieb nehmen. SMA ShadeFix ist eine patentierte und integrierte Wechselrichter-Software, die den Ertrag von Photovoltaikanlagen in jeder Situation optimiert. Dank der stetigen Weiterentwicklung des bewährten MPP-Trackings der SMA PV-Wechselrichter, findet SMA ShadeFix auch bei verschatteten PV-Modulen zuverlässig den Punkt mit der aktuell höchsten Leistung. Die Vorteile liegen auf der Hand: mehr Ertrag, längere Lebensdauer und weniger Leistungselektronik auf dem Dach.",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      "Nennleistung in W: 8000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: A:2; B:1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 460 x 497 x 176\n    Gewicht in kg: 20,5\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 1749,
    price_b2b: 1416.69,
    color: '["blue"]',
    subtype: "standard",
    video_link: "https://youtu.be/UGxqbY7v_l0 ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 8000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_3.0-6.0.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EcwE3KY3p0FCsi_a-EIKRcMBVT8PMkUhMv1ivv6kU9hHDA?e=q9fQOc",
  },
  {
    ID: "I8",
    Available: "Yes",
    name: "Solaredge SE10K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 10000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1569,
    price_b2b: 1270.89,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 10000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I9",
    Available: "Yes",
    name: "Solaredge SE12.5K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 12500\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 260\nGewicht in kg: 33.2\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1629,
    price_b2b: 1319.49,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/Eao55saXUFhBrb7OmIuF1agBZgBvd9DAG9lsYUjIIBKhMw?e=cQ5mG9",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 12500,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EVu4bPJptgRMjv6CzsXL5G8BdWYJFIN4KJms4r-q6WkQQg?e=2kbl5r",
  },
  {
    ID: "I10",
    Available: "Yes",
    name: "Solaredge SE15K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 15000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 260\nGewicht in kg: 33.2\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1569,
    price_b2b: 1270.89,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/Eao55saXUFhBrb7OmIuF1agBZgBvd9DAG9lsYUjIIBKhMw?e=cQ5mG9",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 15000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EVu4bPJptgRMjv6CzsXL5G8BdWYJFIN4KJms4r-q6WkQQg?e=2kbl5r",
  },
  {
    ID: "I11",
    Available: "Yes",
    name: "Solaredge SE16K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 16000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 260\nGewicht in kg: 33.2\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1384,
    price_b2b: 1121.04,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/Eao55saXUFhBrb7OmIuF1agBZgBvd9DAG9lsYUjIIBKhMw?e=cQ5mG9",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 16000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EVu4bPJptgRMjv6CzsXL5G8BdWYJFIN4KJms4r-q6WkQQg?e=2kbl5r",
  },
  {
    ID: "I12",
    Available: "Yes",
    name: "Solaredge SE17K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 17000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 260\nGewicht in kg: 33.2\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1499,
    price_b2b: 1214.19,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/Eao55saXUFhBrb7OmIuF1agBZgBvd9DAG9lsYUjIIBKhMw?e=cQ5mG9",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 17000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EVu4bPJptgRMjv6CzsXL5G8BdWYJFIN4KJms4r-q6WkQQg?e=2kbl5r",
  },
  {
    ID: "I13",
    Available: "Yes",
    name: "Solaredge SE3K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 3000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 969,
    price_b2b: 784.8900000000001,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 3000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I14",
    Available: "Yes",
    name: "Solaredge SE4K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 4000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1139,
    price_b2b: 922.59,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 4000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I15",
    Available: "Yes",
    name: "Solaredge SE5K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 5000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1159,
    price_b2b: 938.7900000000001,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 5000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I16",
    Available: "Yes",
    name: "Solaredge SE6K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 6000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1279,
    price_b2b: 1035.99,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 6000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I17",
    Available: "Yes",
    name: "Solaredge SE7K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 7000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1319,
    price_b2b: 1068.39,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 7000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I18",
    Available: "Yes",
    name: "Solaredge SE8K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 8000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1439,
    price_b2b: 1165.5900000000001,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 8000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I19",
    Available: "Yes",
    name: "Solaredge SE9K - SetApp",
    type: "inverter",
    brand: "Solaredge",
    "allowed inverter": null,
    short_description:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    long_description:
      "Die PV-Wechselrichter von dem israelischen Hersteller SolarEdge verfügen über eine intelligente Anlagenüberwachung, die es ermöglicht jedes Solarmodul einzeln zu überwachen und im Fehlerfall zu warten. Des Weiteren können die Solarmodule im Brandfall durch das SafeDC System auf 1 V heruntergefahren werden und stellen somit keine Gefahr mehr für die löschenden Feuerwehrleute dar.",
    Highlights:
      "Optimal bei Verschattung der Dachfläche durch Bäume, Gauben, Satellitenschüsseln, etc.\nModuloptimierer regeln die Leistung der Solarmodule, sodass auch bei Verschattung oder verwinkelten Dächern optimale Stromerträge erreicht werden\nÜberwachung und Monitoring jedes einzelnen Moduls\nAnbindung eines Speichers mit einem zusätzlichen Wechselrichter von SolarEdge jederzeit möglich\nErhöhte Sicherheit, da sich die Module im Brandfall abschalten\nGarantieverlängerung optional auf 20 oder 25 Jahre\nVerbrauchersteuerung möglich\nDie Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich",
    "Technische Produktdaten":
      "Nennleistung in W: 9000\nWirkungsgrad in %: 97.5\nAnschlussphasen: 3\nAnzahl MPP-Tracker: 1\nAnzahl Stränge pro MPPT: 2\nMontage im Außenbereich: Ja\nMaße (BxHxT) in mm: 315 x 540 x 191\nGewicht in kg: 18.9\nBetriebstemperatur in °C: -40 bis 60",
    Garantie:
      'Die eingeschränkte Produktgarantie der SolarEdge Technologies Ltd. beträgt 12 Jahre. Sie deckt Mängel in der Verarbeitung und der Materialien des Wechselrichters ab. Falls der Wechselrichter nicht über die vereinbarte Beschaffenheit verfügt oder für den üblichen Gebrauch ungeeignet ist, wird der Wechselrichter entweder in einer SolarEdge-Einrichtung repariert, eine Gutschrift für den fehlerhaften Wechselrichter ausgestellt oder ein Austauschgerät zur Verfügung gestellt. SolarEdge übernimmt die Kosten des Austauschgeräts. Mit dem "ZOLAR Rundum-Schutz-Paket für 2 Jahre" sind die Servicekosten für die Deinstallation und Installation gedeckt.',
    Herstellerinformation:
      "SolarEdge ist ein innovativer Anbieter von Wechselrichtern. Das Unternehmen hat eine intelligente Wechselrichterlösung entwickelt, wobei Moduloptimierer mit jedem Solarmodul installiert werden, welche die Energiegewinnung auf Modulebene optimieren. Dadurch können auch Solaranlagen, welche partiell durch Bäume oder umstehende Gebäude verschattet werden, optimal betrieben werden.",
    bullet_point_list:
      '["Optimal bei verschatteten oder stark verwinkelten Dächern in Kombination mit Leistungsoptimierer", "Klein, leichtester seiner Klasse", "12 Jahre Produktgarantie"]',
    price_b2c: 1559,
    price_b2b: 1262.7900000000002,
    color: '["white"]',
    subtype: "standard",
    video_link: "https://youtu.be/TG2-qNRLeKc",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWdNZh28QcxEqtvTulbHzSgBpEC47x4Y7ZT1H787yZWWJw?e=9ajnhg",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 9000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/three-phase-inverter-1000x798(7).png ",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EeSA--_2Z8pOgIRTW8X0kw8BG1ksvnDi7N2nxhtRZfllHQ?e=hLErCB ",
  },
  {
    ID: "I20",
    Available: "Yes",
    name: "SMA Sunny Tripower 15000",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    long_description:
      "Der Sunny Tripower ist der ideale Wechselrichter für größere Anlagen im gewerblichen und industriellen Bereich. Mit seinem\nWirkungsgrad von 98,4 Prozent sorgt er nicht nur für außergewöhnlich hohe Erträge, sondern bietet durch sein Multistringkonzept\nin Verbindung mit einem weiten Eingangsspannungsbereich eine hohe Auslegungsflexibilität und Kompatibilität mit\nvielen verfügbaren PV-Modulen.\nZukunftweisend ist die Integration neuer Netzmanagementfunktionen wie z. B. Integrated Plant Control, die es erlaubt, eine\nBlindleistungsregelung am Netzanschlusspunkt allein durch den Wechselrichter durchzuführen. Hierbei können übergeordnete\nRegelungseinheiten entfallen und die Systemkosten gesenkt werden. Eine weitere Neuheit ist die Blindleistungsbereitstellung\nrund um die Uhr (Q on Demand 24/7).",
    Highlights:
      "\n    Ansteuerung und Visualisierung von Verbrauchern (z. B. Waschmaschine, Kühlschrank, Geschirrspüler) möglich\n    Mit einem zusätzlichen Wechselrichter ist der Anschluss eines Speichers möglich\n    Garantieverlängerung optional auf 15 Jahre\n    Montage des Wechselrichters in unbeheizten Räumen (wie z.B. Garagen) möglich\n    Die Leistung kann je nach Anforderung geregelt werden: 70% Regelung statisch/dynamisch oder Nulleinspeisung möglich\n    SMA ShadeFix integriert",
    "Technische Produktdaten":
      " Nennleistung in W: 15000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 3\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 661 x 682 x 264\n    Gewicht in kg: 61\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie:
      "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. \nGarantieleistungen:\nSMA trägt Kosten für neues Gerät oder Ersatzteile\nnach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort durch ZOLAR statt.\nDer Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Gutes Energiemanagement - Smart Home", "Deutschlands bekanntester Wechselrichterhersteller", "5 Jahre Produktgarantie"]',
    price_b2c: 2389,
    price_b2b: 1935.0900000000001,
    color: '["blue"]',
    subtype: "standard",
    video_link: null,
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EUpjYcAbCsZPvTkEBNTdqrUBgUCoTDd96rZ-JpCHBnCTjA?e=ngNiHl",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 15000,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/sma_stp_tl-30(1).png",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EfwwexkBfTFIpUKdY7Hdvl8BmB1MhjdaZPf_9kCNG59yWw?e=buhbtj",
  },
  {
    ID: "I21",
    Available: "Yes",
    name: "SMA Sunny Tripower Smart Energy 5.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    long_description:
      "Der Hybrid-Wechselrichter Sunny Tripower Smart Energy ist die 2-in-1-Lösung für die Solarstromversorgung im Eigenheim. Darin kombiniert SMA intelligente Technologien und integrierte Services zu einer platzsparenden Kompaktlösung - mehr als 30 Jahre Speichererfahrung machen es möglich. Anwender erzeugen, nutzen und speichern Solarstrom mit dem Sunny Tripower Smart Energy einfach und komfortabel. Das System lässt sich jederzeit erweitern und bindet auch Elektromobilität oder Wärmepumpen ein. Die integrierte Ersatzstromfunktion sichert die Stromversorgung des Haushalts auch beim Netzausfall. Solaranlagen im Eigenheim werden so zu ganzheitlichen und intelligenten Energiesystemen mit bis zu 100 Prozent solarer Eigenversorgung.",
    Highlights:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    "Technische Produktdaten":
      " Nennleistung in W: 5000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 500 x 598 x 173\n    Gewicht in kg: 30\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie: "10 Jahre Garantie",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Ersatzstromfunktion", "Deutschlands bekanntester Wechselrichterhersteller", "10 Jahre Produktgarantie"]',
    price_b2c: 2290,
    price_b2b: 1854.9,
    color: '["white"]',
    subtype: "hybrid",
    video_link: "https://youtu.be/tPunVP4wHSI",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EQwlo3yVlCFLvb4DzzV4ko4BF4K0LkDjbWWr6OvTTPNERA?e=XeBoH7",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 5000,
    imgurl1:
      "https://www.photovoltaik4all.de/media/image/c2/93/77/MA_Sunny_Tripower_Smart_Energy_01.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EZ0_7feLfO9AhJpYPTbhyHMBQVSVn0CrqgEwKTsu2EXUgw?e=tbJjxt",
  },
  {
    ID: "I22",
    Available: "Yes",
    name: "SMA Sunny Tripower Smart Energy 6.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    long_description:
      "Der Hybrid-Wechselrichter Sunny Tripower Smart Energy ist die 2-in-1-Lösung für die Solarstromversorgung im Eigenheim. Darin kombiniert SMA intelligente Technologien und integrierte Services zu einer platzsparenden Kompaktlösung - mehr als 30 Jahre Speichererfahrung machen es möglich. Anwender erzeugen, nutzen und speichern Solarstrom mit dem Sunny Tripower Smart Energy einfach und komfortabel. Das System lässt sich jederzeit erweitern und bindet auch Elektromobilität oder Wärmepumpen ein. Die integrierte Ersatzstromfunktion sichert die Stromversorgung des Haushalts auch beim Netzausfall. Solaranlagen im Eigenheim werden so zu ganzheitlichen und intelligenten Energiesystemen mit bis zu 100 Prozent solarer Eigenversorgung.",
    Highlights:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    "Technische Produktdaten":
      " Nennleistung in W: 6000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 500 x 598 x 173\n    Gewicht in kg: 30\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie: "10 Jahre Garantie",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Ersatzstromfunktion", "Deutschlands bekanntester Wechselrichterhersteller", "10 Jahre Produktgarantie"]',
    price_b2c: 2469,
    price_b2b: 1999.89,
    color: '["white"]',
    subtype: "hybrid",
    video_link: "https://youtu.be/tPunVP4wHSI ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EQwlo3yVlCFLvb4DzzV4ko4BF4K0LkDjbWWr6OvTTPNERA?e=XeBoH7",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 6000,
    imgurl1:
      "https://www.photovoltaik4all.de/media/image/c2/93/77/MA_Sunny_Tripower_Smart_Energy_01.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EZ0_7feLfO9AhJpYPTbhyHMBQVSVn0CrqgEwKTsu2EXUgw?e=tbJjxt",
  },
  {
    ID: "I23",
    Available: "Yes",
    name: "SMA Sunny Tripower Smart Energy 8.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    long_description:
      "Der Hybrid-Wechselrichter Sunny Tripower Smart Energy ist die 2-in-1-Lösung für die Solarstromversorgung im Eigenheim. Darin kombiniert SMA intelligente Technologien und integrierte Services zu einer platzsparenden Kompaktlösung - mehr als 30 Jahre Speichererfahrung machen es möglich. Anwender erzeugen, nutzen und speichern Solarstrom mit dem Sunny Tripower Smart Energy einfach und komfortabel. Das System lässt sich jederzeit erweitern und bindet auch Elektromobilität oder Wärmepumpen ein. Die integrierte Ersatzstromfunktion sichert die Stromversorgung des Haushalts auch beim Netzausfall. Solaranlagen im Eigenheim werden so zu ganzheitlichen und intelligenten Energiesystemen mit bis zu 100 Prozent solarer Eigenversorgung.",
    Highlights:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    "Technische Produktdaten":
      " Nennleistung in W: 8000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: 1\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 500 x 598 x 173\n    Gewicht in kg: 30\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie: "10 Jahre Garantie",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Ersatzstromfunktion", "Deutschlands bekanntester Wechselrichterhersteller", "10 Jahre Produktgarantie"]',
    price_b2c: 2790,
    price_b2b: 2259.9,
    color: '["white"]',
    subtype: "hybrid",
    video_link: "https://youtu.be/tPunVP4wHSI",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EQwlo3yVlCFLvb4DzzV4ko4BF4K0LkDjbWWr6OvTTPNERA?e=XeBoH7",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 8000,
    imgurl1:
      "https://www.photovoltaik4all.de/media/image/c2/93/77/MA_Sunny_Tripower_Smart_Energy_01.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EZ0_7feLfO9AhJpYPTbhyHMBQVSVn0CrqgEwKTsu2EXUgw?e=tbJjxt",
  },
  {
    ID: "I24",
    Available: "Yes",
    name: "SMA Sunny Tripower Smart Energy 10.0",
    type: "inverter",
    brand: "SMA",
    "allowed inverter": null,
    short_description:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    long_description:
      "Der Hybrid-Wechselrichter Sunny Tripower Smart Energy ist die 2-in-1-Lösung für die Solarstromversorgung im Eigenheim. Darin kombiniert SMA intelligente Technologien und integrierte Services zu einer platzsparenden Kompaktlösung - mehr als 30 Jahre Speichererfahrung machen es möglich. Anwender erzeugen, nutzen und speichern Solarstrom mit dem Sunny Tripower Smart Energy einfach und komfortabel. Das System lässt sich jederzeit erweitern und bindet auch Elektromobilität oder Wärmepumpen ein. Die integrierte Ersatzstromfunktion sichert die Stromversorgung des Haushalts auch beim Netzausfall. Solaranlagen im Eigenheim werden so zu ganzheitlichen und intelligenten Energiesystemen mit bis zu 100 Prozent solarer Eigenversorgung.",
    Highlights:
      "• DC-gekoppelter 3-phasiger Hybridwechselrichter\n• Kompatibel zu führenden Anbietern von Hochvoltbatterien\n• Einfache Integration in das SMA Home Energy System\n• Integrierte Backup-Funktion",
    "Technische Produktdaten":
      " Nennleistung in W: 10000\n    Wirkungsgrad in %: 98\n    Anschlussphasen: 3\n    Anzahl MPP-Tracker: 2\n    Anzahl Stränge pro MPPT: A:1; B:2\n    Montage im Außenbereich: Ja\n    Maße (BxHxT) in mm: 500 x 598 x 173\n    Gewicht in kg: 30\n    Betriebstemperatur in °C: -25 bis 60\n    optimale Betriebstemperatur in °C: -25 bis 40",
    Garantie: "10 Jahre Garantie",
    Herstellerinformation:
      "Die SMA Solar Technology AG – mit Sitz in Niestetal, nahe Kassel – ist nicht nur in Deutschland die klare Nummer 1, sondern der Weltmarktführer für die Herstellung von Wechselrichtern und weiterer Solar-Systemtechnik. Mit über 3.000 Mitarbeitern in 20 Ländern bietet SMA ein weltumspannendes Netz aus Ingenieuren, Technikern sowie Service- und Vertriebskräften. SMA Solar Technology bietet ein breit gefächertes Sortiment für große und kleine Solaranlagen und außerdem mit ihren Batteriewechselrichtern die Möglichkeit Hochvolt- oder Niedervoltbatterien zu integrieren.",
    bullet_point_list:
      '["Ersatzstromfunktion", "Deutschlands bekanntester Wechselrichterhersteller", "10 Jahre Produktgarantie"]',
    price_b2c: 3149,
    price_b2b: 2550.69,
    color: '["white"]',
    subtype: "hybrid",
    video_link: "https://youtu.be/tPunVP4wHSI",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EQwlo3yVlCFLvb4DzzV4ko4BF4K0LkDjbWWr6OvTTPNERA?e=XeBoH7",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 10000,
    imgurl1:
      "https://www.photovoltaik4all.de/media/image/c2/93/77/MA_Sunny_Tripower_Smart_Energy_01.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EZ0_7feLfO9AhJpYPTbhyHMBQVSVn0CrqgEwKTsu2EXUgw?e=tbJjxt",
  },
  {
    ID: "P1",
    Available: "Yes",
    name: "JA Solar JAM60S21-MR 385 Wp",
    type: "solar_panel",
    brand: "JA Solar",
    "allowed inverter": null,
    short_description:
      "120 monokristalline Zellen\nFür alle Schneelastzonen in Deutschland zugelassen\nFür hohe Windlasten geeignet\nBestes Preis-Leistungs-Verhältnis\nPERC-Technologie",
    long_description:
      "Die monokristallinen Solarmodule von JA Solar sind mit der PERC-Technologie ausgestattet. PERC (passivated emitter rear coating) ist ein Verfahren bei dem nicht nur die Vorderseite mit einer Passivierungsschicht versehen ist, sondern auch die Rückseite. Die Passivierungsschicht minimiert den Verlust von Ladungsträgern. Zusätzliche Fremdatome erhöhen die Leitfähigkeit der Zelle und die elektrischen Verluste werden gesenkt. Die Module sind IEC 61701 zertifiziert und deshalb ebenso in Landwirtschafts- oder Küstengebieten einsetzbar.",
    Highlights:
      "120 monokristalline Zellen\nFür alle Schneelastzonen in Deutschland zugelassen\nFür hohe Windlasten geeignet\nBestes Preis-Leistungs-Verhältnis\nPERC-Technologie",
    "Technische Produktdaten":
      "    Wirkungsgrad in %: 20.6\n    Leistung in Wp: 385\n    Zellentyp: Mono-Silizium\n    Zellenfarbe: schwarz\n    Rahmenfarbe: schwarz\n    Rückseitenfolie: weiß\n    Maße (BxHxT) in mm: 1052 x 1776 x 35\n    Gewicht in kg: 20.7",
    Garantie:
      "Die Module haben eine Produktgarantie auf die Verarbeitung und Funktionsfähigkeit von 12 Jahren. Zusätzlich garantiert der Hersteller eine lineare Leistungsgarantie, wobei die Module nach 25 Jahren noch mindestens 83,1 % der Nennleistung erbringen.",
    Herstellerinformation:
      "JA Solar Holdings Co. Ltd. mit Sitz in Shanghai (China) ist ein weltweit führender Hersteller leistungsstarker Photovoltaik-Produkte. Das im Mai 2005 gegründete und seit 2007 börsennotierte Unternehmen ist in den Geschäftsfeldern Wafer, Zellen und Module aktiv. Seinen Kunden bietet JA Solar qualitativ hochwertige Produkte mit überdurchschnittlich hohen Wirkungsgraden zu einem sehr guten Preis-Leistungsverhältnis an.",
    bullet_point_list:
      '["Monokristallines Glas-Folie Modul", "teilweise schwarz", "25 Jahre Leistungsgarantie (mind. 81%)"]',
    price_b2c: 174,
    price_b2b: 140.94,
    color: '["black"]',
    subtype: "Standard",
    video_link: null,
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EdWkxLG9u9NPmv11ZqFJSsgBNMCXSBoP0FeW9jd2iZPaVQ?e=fOiWmz ",
    "capacity (kwp)": 385,
    instock: "yes",
    applicable_max_capacity: null,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/jam60s20-380-mrbfr.jpg",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EZB2BuWCUZ9EpiA_kvindGABz8PKQuEilY42OkU8pjOzzQ?e=vm8sQz ",
  },
  {
    ID: "P2",
    Available: "Yes",
    name: "Meyer Burger Black 375 Wp",
    type: "solar_panel",
    brand: "Meyer Burger",
    "allowed inverter": null,
    short_description:
      "    Hochleistungsmodul für mehr Ertrag vom Dach\n    In Deutschland für alle Schneelastzonen zugelassen\n    Für besonders hohe Windlasten geeignet (z. B. an Küstengebieten)\n    Heterojunction/SmartWire-Technologie\n    Geeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten",
    long_description:
      "Das Beste. Von hier. Für morgen. Leistungsstark, nachhaltig, Made in Europe.\n\nMeyer Burger (MB) hat die bestehende Heterojunction (HJT)-Technologie weiterentwickelt und effizienter gemacht. Indem ihre Ingenieure unterschiedliche Silizium-Schichten miteinander kombiniert und eine einzigartige Zellstruktur geschaffen haben. Die Meyer Burger HJT-Zellen nehmen so deutlich mehr Sonnenenergie als herkömmliche Zellen auf und der umgewandelte elektrische Strom fließt mit weniger Widerstand durch das Modul. Und das auch bei schwachem Sonnenlicht, beispielsweise wenn es bewölkt ist. Zusätzliche Effizienzgewinne bringt die Verbindung der Zellen durch ihre patentierte SmartWire-Technologie (SWCT™): die hauchdünnen Verbindungsdrähte verringern die Abschattung auf den Solarzellen um bis zu 30 Prozent. Die Module gewinnen so mehr Energie und produzieren länger Strom - für spürbar mehr Leistung pro Fläche auf dem Dach. Die SWCT™ verbessert auch die Zellstabilität und macht die Module weniger anfällig für so genannte Mikrorisse, einem der häufigsten Gründe für Energieverluste bei Solarmodulen. Und das Beste daran: diese unvergleichliche Performance gibt es dank des umfassenden Patentschutzes nur bei Meyer Burger.",
    Highlights:
      "    Hochleistungsmodul für mehr Ertrag vom Dach\n    In Deutschland für alle Schneelastzonen zugelassen\n    Für besonders hohe Windlasten geeignet (z. B. an Küstengebieten)\n    Heterojunction/SmartWire-Technologie\n    Geeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten",
    "Technische Produktdaten":
      "\n    Wirkungsgrad in %: 20.9\n    Leistung in Wp: 375\n    Zellentyp: Mono-Silizium\n    Zellenfarbe: schwarz\n    Rahmenfarbe: schwarz\n    Rückseitenfolie: schwarz\n    Maße (BxHxT) in mm: 1041 x 1767 x 35\n    Gewicht in kg: 19.7",
    Garantie:
      "Die Module haben eine Produktgarantie auf die Verarbeitung und Funktionsfähigkeit von 25 Jahren. Zusätzlich garantiert der Hersteller eine lineare Leistungsgarantie, wobei die Module nach 25 Jahren noch mindestens 92 % der Nennleistung erbringen.",
    Herstellerinformation:
      "Meyer Burger mit Sitz in Thun (Schweiz) ist ein industrieller Hersteller von Solarzellen und Solarmodulen. In seinen deutschen Produktionsstätten in Bitterfeld-Wolfen (Solarzellen) und Freiberg (Solarmodule) nutzt das Unternehmen die proprietäre Heterojunction/SmartWire Technologie. Diese wurde an Forschungsstandorten in der Schweiz (Thun und Hauterive) entwickelt und am deutschen Maschinenbau-Standort in Hohenstein-Ernstthal für die Massenproduktion industrialisiert. Meyer Burger trieb jahrzehntelang als Technologie- und Maschinenlieferant die Entwicklung der globalen Photovoltaik-Industrie voran und setzte mit der Diamantdraht-Sägetechnologie, der PERC-Solarzellentechnologie sowie hoch-präzisen Messsystemen für Siliziumwafer, Solarzellen und -module Industriestandards. Seit einem Strategie-Wechsel im Jahr 2020 liegt der Fokus des Unternehmens auf der eigenen Produktion von Solarzellen und -modulen. Die Produktion startete Mitte 2021.\nIhr Vorsprung drängt sie jeden Tag zu weiterer Innovation. Innovationen, die über  Materialien und Handwerk hinausgehen und dafür sorgen, dass sich in jeder Hinsicht für das Produkt auszahlt. Für jeden. Für die Nachhaltigkeit. Für eine solare Zukunft!",
    bullet_point_list:
      '["Full Black", "Monokristallines Glas-Folie Modul", "25 Jahre Leistungsgarantie (mind. 92%)"]',
    price_b2c: 279,
    price_b2b: 225.99,
    color: '["full black"]',
    subtype: "Design",
    video_link: null,
    extended_warranty_cost: null,
    warranty_link: null,
    "capacity (kwp)": 375,
    instock: "yes",
    applicable_max_capacity: null,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/generated/product/1/540_340_100/210622_meyerburger_modul_black_front_s.png",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/ET11nBORqQ5Cs_n-rTd0XEYBV1arpij0g3-zCVfV50UN3A?e=WLpUF4",
  },
  {
    ID: "P3",
    Available: "Yes",
    name: "Meyer Burger White 380 Wp",
    type: "solar_panel",
    brand: "Meyer Burger",
    "allowed inverter": null,
    short_description:
      "    Hochleistungsmodul für mehr Ertrag vom Dach\n    In Deutschland für alle Schneelastzonen zugelassen\n    Für besonders hohe Windlasten geeignet (z. B. an Küstengebieten)\n    Heterojunction/SmartWire-Technologie\n    Geeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten",
    long_description:
      "Das Beste. Von hier. Für morgen. Leistungsstark, nachhaltig, Made in Europe.\n\nMeyer Burger (MB) hat die bestehende Heterojunction (HJT)-Technologie weiterentwickelt und effizienter gemacht. Indem ihre Ingenieure unterschiedliche Silizium-Schichten miteinander kombiniert und eine einzigartige Zellstruktur geschaffen haben. Die MB HJT-Zellen nehmen so deutlich mehr Sonnenenergie als herkömmliche Zellen auf und der umgewandelte elektrische Strom fließt mit weniger Widerstand durch das Modul. Auch bei schwachem Sonnenlicht, beispielsweise wenn es bewölkt ist. Zusätzliche Effizienzgewinne bringt die Verbindung der Zellen durch ihre patentierte SmartWire-Technologie (SWCT™). Denn die hauchdünnen Verbindungsdrähte verringern die Abschattung auf den Solarzellen um bis zu 30 Prozent. Die Module gewinnen so mehr Energie und produzieren länger Strom. Für spürbar mehr Leistung pro Fläche auf dem Dach. Und nicht nur das. SWCT™ verbessert auch die Zellstabilität und macht die Module weniger anfällig für so genannte Mikrorisse, einem der häufigsten Gründe für Energieverluste bei Solarmodulen. Und das Beste daran: diese unvergleichliche Performance gibt es dank des umfassenden Patentschutzes nur bei MB.",
    Highlights:
      "    Hochleistungsmodul für mehr Ertrag vom Dach\n    In Deutschland für alle Schneelastzonen zugelassen\n    Für besonders hohe Windlasten geeignet (z. B. an Küstengebieten)\n    Heterojunction/SmartWire-Technologie\n    Geeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten",
    "Technische Produktdaten":
      "Wirkungsgrad in %: 20.9\n    Leistung in Wp: 380\n    Zellentyp: Mono-Silizium\n    Zellenfarbe: schwarz\n    Rahmenfarbe: schwarz\n    Rückseitenfolie: weiß\n    Maße (BxHxT) in mm: 1041 x 1767 x 35\n    Gewicht in kg: 19.7",
    Garantie:
      "Die Module haben eine Produktgarantie auf die Verarbeitung und Funktionsfähigkeit von 25 Jahren. Zusätzlich garantiert der Hersteller eine lineare Leistungsgarantie, wobei die Module nach 25 Jahren noch mindestens 92 % der Nennleistung erbringen.",
    Herstellerinformation:
      "Meyer Burger mit Sitz in Thun (Schweiz) ist ein industrieller Hersteller von Solarzellen und Solarmodulen. In seinen deutschen Produktionsstätten in Bitterfeld-Wolfen (Solarzellen) und Freiberg (Solarmodule) nutzt das Unternehmen die proprietäre Heterojunction/SmartWire Technologie. Diese wurde an Forschungsstandorten in der Schweiz (Thun und Hauterive) entwickelt und am deutschen Maschinenbau-Standort in Hohenstein-Ernstthal für die Massenproduktion industrialisiert. Meyer Burger trieb jahrzehntelang als Technologie- und Maschinenlieferant die Entwicklung der globalen Photovoltaik-Industrie voran und setzte mit der Diamantdraht-Sägetechnologie, der PERC-Solarzellentechnologie sowie hoch-präzisen Messsystemen für Siliziumwafer, Solarzellen und -module Industriestandards. Seit einem Strategie-Wechsel im Jahr 2020 liegt der Fokus des Unternehmens auf der eigenen Produktion von Solarzellen und -modulen. Die Produktion startete Mitte 2021.\nIhr Vorsprung drängt sie jeden Tag zu weiterer Innovation. Innovationen, die über  Materialien und Handwerk hinausgehen und dafür sorgen, dass sich in jeder Hinsicht für das Produkt auszahlt. Für jeden. Für die Nachhaltigkeit. Für eine solare Zukunft!",
    bullet_point_list:
      '["teilweise schwarz", "Monokristallines Glas-Folie Modul", "25 Jahre Leistungsgarantie (mind. 92%)"]',
    price_b2c: 270,
    price_b2b: 218.70000000000002,
    color: '["black"]',
    subtype: "Premium",
    video_link: "https://youtu.be/vNkSHUrlsF0 ",
    extended_warranty_cost: null,
    warranty_link: null,
    "capacity (kwp)": 380,
    instock: "yes",
    applicable_max_capacity: null,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/generated/product/1/540_340_100/210622_meyerburger_modul_white_front_s.png",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EU9abn0LBExHrFQQCJUUPpUBWchfXDfn0P0Zti8p9NLBQA?e=d5A8LS",
  },
  {
    ID: "P4",
    Available: "Yes",
    name: "Trina Vertex S 400 Wp (BFR)",
    type: "solar_panel",
    brand: "Trina",
    "allowed inverter": null,
    short_description:
      "Monokristallines Glas-Folie-Modul\nIn Deutschland für alle Schneelastzonen zugelassen\nFür hohe Windlasten geeignet\nBestes Preis-Leistungs-Verhältnis\nGeeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten\nMulti-Busbar-Technologie",
    long_description:
      "Die monokristallinen Solarmodule von Trina basieren auf 210-Milimeter-Wafern und Multi-Busbar-Technologie. Zusammen mit der hochdichten Verschaltung werden dadurch Wirkungsgrade von über 20 % erreicht. Durch die Halbzellentechnologie wird selbst dann Strom erzeugt, wenn die Module teilweise verschattet sind - unabhängig von der Orientierung. Die Module sind IEC 61701 zertifiziert und deshalb ebenso in Landwirtschafts- oder Küstengebieten einsetzbar.",
    Highlights:
      "Monokristallines Glas-Folie-Modul\nIn Deutschland für alle Schneelastzonen zugelassen\nFür hohe Windlasten geeignet\nBestes Preis-Leistungs-Verhältnis\nGeeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten\nMulti-Busbar-Technologie",
    "Technische Produktdaten":
      "\n    Wirkungsgrad in %: 20.5\n    Leistung in Wp: 400\n    Zellentyp: Mono-Silizium\n    Zellenfarbe: schwarz\n    Rahmenfarbe: schwarz\n    Rückseitenfolie: weiß\n    Maße (BxHxT) in mm: 1096 x 1754 x 30\n    Gewicht in kg: 21",
    Garantie:
      "Die Module haben eine Produktgarantie auf die Verarbeitung und Funktionsfähigkeit von 15 Jahren. Zusätzlich garantiert der Hersteller eine lineare Leistungsgarantie, wobei die Module nach 25 Jahren noch mindestens 84,8 % der Nennleistung erbringen.",
    Herstellerinformation:
      "Trina Solar ist einer der größten Anbieter von Solarmodulen. Im Jahr 2015 hat das Unternehmen mehr als 5,74 GW (23 Mio. Solarmodule) gefertigt. Das Unternehmen wurde 1997 in der chinesischen Provinz Jiangsu gegründet und fertigt als vollintegrierter Hersteller nicht nur Solarmodule, sondern auch Ingots, Wafer und Solarzellen. Das Unternehmen ist seit 2006 an der New Yorker Börse notiert und gehörte wiederholt zur Fortune-Liste der 100 am schnellsten wachsenden Unternehmen.",
    bullet_point_list:
      '["teilweise schwarz", "Monokristallines Glas-Folie Modu", "25 Jahre Leistungsgarantie (mind. 84,8%)"]',
    price_b2c: 192,
    price_b2b: 155.52,
    color: '["black"]',
    subtype: "Standard",
    video_link: "https://youtu.be/0na9a8i_kjc ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EY33BcWYtIBPqKp6jlS1aH4Bnr0BCeMYdehlhGDSj_IRjA?e=SdBbZN ",
    "capacity (kwp)": 400,
    instock: "yes",
    applicable_max_capacity: null,
    imgurl1:
      "https://sauerlaender-solarmarkt.de/out/pictures/master/product/1/trinavertexstsm-395de09.08.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EXSXOB9w2RZAmUykNzUvdycBShcWmrCnTYsLdVzBOqJ_Kw?e=sW1ge1",
  },
  {
    ID: "P5",
    Available: "Yes",
    name: "Trina Vertex S Black 385 Wp",
    type: "solar_panel",
    brand: "Trina",
    "allowed inverter": null,
    short_description:
      "Komplett schwarzes Design für ein ästhetisches Dach\nIn Deutschland für alle Schneelastzonen zugelassen\nFür hohe Windlasten geeignet\nBestes Preis-Leistungs-Verhältnis\nGeeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten\nMulti-Busbar-Technologie",
    long_description:
      "Die monokristallinen Solarmodule von Trina basieren auf 210-Milimeter-Wafern und Multi-Busbar-Technologie. Zusammen mit der hochdichten Verschaltung werden dadurch Wirkungsgrade von über 20 % erreicht. Durch die Halbzellentechnologie wird selbst dann Strom erzeugt, wenn die Module teilweise verschattet sind - unabhängig von der Orientierung. Die Module sind IEC 61701 zertifiziert und deshalb ebenso in Landwirtschafts- oder Küstengebieten einsetzbar.",
    Highlights:
      "Komplett schwarzes Design für ein ästhetisches Dach\nIn Deutschland für alle Schneelastzonen zugelassen\nFür hohe Windlasten geeignet\nBestes Preis-Leistungs-Verhältnis\nGeeignet für den Einsatz in Küsten- sowie landwirtschaftlichen Gebieten\nMulti-Busbar-Technologie",
    "Technische Produktdaten":
      "    Wirkungsgrad in %: 20.5\n    Leistung in Wp: 385\n    Zellentyp: Mono-Silizium\n    Zellenfarbe: schwarz\n    Rahmenfarbe: schwarz\n    Rückseitenfolie: schwarz\n    Maße (BxHxT) in mm: 1096 x 1754 x 30\n    Gewicht in kg: 21",
    Garantie:
      "Die Module haben eine Produktgarantie auf die Verarbeitung und Funktionsfähigkeit von 15 Jahren. Zusätzlich garantiert der Hersteller eine lineare Leistungsgarantie, wobei die Module nach 25 Jahren noch mindestens 84,8% der Nennleistung erbringen",
    Herstellerinformation:
      "Trina Solar ist einer der größten Anbieter von Solarmodulen. Im Jahr 2015 hat das Unternehmen mehr als 5,74 GW (23 Mio. Solarmodule) gefertigt. Das Unternehmen wurde 1997 in der chinesischen Provinz Jiangsu gegründet und fertigt als vollintegrierter Hersteller nicht nur Solarmodule, sondern auch Ingots, Wafer und Solarzellen. Das Unternehmen ist seit 2006 an der New Yorker Börse notiert und gehörte wiederholt zur Fortune-Liste der 100 am schnellsten wachsenden Unternehmen.",
    bullet_point_list:
      '["komplett schwarzes Erscheinungsbild", "Monokristallines Glas-Folie Modu", "25 Jahre Leistungsgarantie (mind. 84,8%)"]',
    price_b2c: 182,
    price_b2b: 147.42000000000002,
    color: '["full black"]',
    subtype: "Design",
    video_link: "https://youtu.be/0na9a8i_kjc ",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EdnnAopYdwVIuznYo8H_llcBbRxDzFwEhWrAQb6WsRxnrQ?e=2QhcNe ",
    "capacity (kwp)": 385,
    instock: "yes",
    applicable_max_capacity: null,
    imgurl1:
      "https://www.photovoltaik4all.de/media/image/33/11/0a/Trina_Vertex_S_TSM-385DE09-05-385_Wp_-FB-_01.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EWTQ5aAq5fxKnbVhuCdPhc0BWqbu5QBk97wnwOK32J431w?e=3Fc0U0",
  },
  {
    ID: "W1",
    Available: "Yes",
    name: "SMA EV Charger 7,4",
    type: "wallbox",
    brand: "SMA",
    "allowed inverter": "SMA",
    short_description:
      "\n    zum Laden von Elektrofahrzeugen in Kombination mit dem Sunny Home Manager 2.0\n    Ladeleistung 1,3 kW bis 22 kW\n    Dreiphasige Einspeisung\n    Boost-Funktion\n    Blackout-Schutz\n    Ethernet/WLAN Schnittstelle\n    Abmaße: 460 mm x 357 mm x 122 mm\n    Kabellänge: 7,5 m\n    Herstellernummer: EVC22-3AC-10-1",
    long_description:
      "Mit dem SMA EV Charger tanken Solaranlagenbetreiber ihr Elektrofahrzeug intelligent und nachhaltig. Denn mit Solarstrom\nlassen sich die Netzbezugskosten beim Laden auf ein Minimum reduzieren. Egal ob Neu- oder Bestandsanlage, der SMA EV\nCharger ist flexibel einsetzbar und dank fest angeschlossenem Typ 2 Ladekabel mit allen gängigen Elektrofahrzeugen kompa-\ntibel. In Kombination mit dem Sunny Home Manager 2.0 laden Anwender ihr Elektrofahrzeug bevorzugt mit Solarstrom und\nbesonders schnell, wenn es darauf ankommt. Die Bedienung erfolgt einfach per Drehschalter am Gerät oder via SMA Energy\nApp. Die Boost-Funktion ermöglicht fast doppelt so schnelles Laden im Vergleich zu herkömmlichen Ladestationen. Auch kleine\nSolarleistungen lassen sich durch automatische Umschaltung von ein- und dreiphasigem Ladebetrieb maximal ausnutzen. Das\nintelligente System berücksichtigt dabei stets alle Verbraucher und vermeidet eine Überlastung des Hausanschlusse",
    Highlights:
      "\n    zum Laden von Elektrofahrzeugen in Kombination mit dem Sunny Home Manager 2.0\n    Ladeleistung 1,3 kW bis 22 kW\n    Dreiphasige Einspeisung\n    Boost-Funktion\n    Blackout-Schutz\n    Ethernet/WLAN Schnittstelle\n    Abmaße: 460 mm x 357 mm x 122 mm\n    Kabellänge: 7,5 m\n    Herstellernummer: EVC22-3AC-10-1",
    "Technische Produktdaten":
      " Ladeleistung in W: 1300-7400\n    Nennspannung: 230\nFahrzeuganschluss: Typ 2 Stecker\n       Maße (BxHxT) in mm: 460 x 357 x 122\n    Gewicht in kg: 8\n    Betriebstemperatur in °C: -25 bis 40\n    ",
    Garantie: "5 Jahre Garantie",
    Herstellerinformation:
      "Als global führender Spezialist für Photovoltaik-Systemtechnik schafft SMA heute die Voraussetzungen für die dezentrale, digitale und erneuerbare Energieversorgung von morgen. Dieser Aufgabe widmen sich über 3.000 SMA Mitarbeiter in 18 Ländern mit großer Leidenschaft. Mit unseren innovativen Lösungen für alle Photovoltaikanwendungen ermöglichen wir Menschen und Unternehmen weltweit mehr Unabhängigkeit in der Anwendung von Energie. Gemeinsam mit unseren Kooperationspartnern und Kunden machen wir den Umbau zu einer unabhängigen, dezentralen und erneuerbaren Energieversorgung möglich.",
    bullet_point_list:
      '["Ladeleistung 1,3 kW bis 7,4 kW", "zum Laden von Elektrofahrzeugen in Kombination mit dem Sunny Home Manager 2.0", "booster"]',
    price_b2c: 1219,
    price_b2b: 987.3900000000001,
    color: '["white"]',
    subtype: "Standard",
    video_link: "https://youtu.be/hbDhx2xGYhk",
    extended_warranty_cost: null,
    warranty_link: null,
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 7700,
    imgurl1:
      "https://www.solarmarkt.ch/artimg/m/95-Elektromobilitaet/SMA/BI_SMA_EV-Charger_AC-10.jpg",
    imgurl2: " ",
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EaIKF5vaublBqvvdexlRvGYB0LpTbtVL-dZ-H8-5aLPSQw?e=kf4oxF",
  },
  {
    ID: "W2",
    Available: "Yes",
    name: "SMA EV Charger 22",
    type: "wallbox",
    brand: "SMA",
    "allowed inverter": "SMA",
    short_description:
      "\n    zum Laden von Elektrofahrzeugen in Kombination mit dem Sunny Home Manager 2.0\n    Ladeleistung 1,3 kW bis 22 kW\n    Dreiphasige Einspeisung\n    Boost-Funktion\n    Blackout-Schutz\n    Ethernet/WLAN Schnittstelle\n    Abmaße: 460 mm x 357 mm x 122 mm\n    Kabellänge: 7,5 m\n    Herstellernummer: EVC22-3AC-10-1",
    long_description:
      "Mit dem SMA EV Charger tanken Solaranlagenbetreiber ihr Elektrofahrzeug intelligent und nachhaltig. Denn mit Solarstrom\nlassen sich die Netzbezugskosten beim Laden auf ein Minimum reduzieren. Egal ob Neu- oder Bestandsanlage, der SMA EV\nCharger ist flexibel einsetzbar und dank fest angeschlossenem Typ 2 Ladekabel mit allen gängigen Elektrofahrzeugen kompa-\ntibel. In Kombination mit dem Sunny Home Manager 2.0 laden Anwender ihr Elektrofahrzeug bevorzugt mit Solarstrom und\nbesonders schnell, wenn es darauf ankommt. Die Bedienung erfolgt einfach per Drehschalter am Gerät oder via SMA Energy\nApp. Die Boost-Funktion ermöglicht fast doppelt so schnelles Laden im Vergleich zu herkömmlichen Ladestationen. Auch kleine\nSolarleistungen lassen sich durch automatische Umschaltung von ein- und dreiphasigem Ladebetrieb maximal ausnutzen. Das\nintelligente System berücksichtigt dabei stets alle Verbraucher und vermeidet eine Überlastung des Hausanschlusse",
    Highlights:
      "\n    zum Laden von Elektrofahrzeugen in Kombination mit dem Sunny Home Manager 2.0\n    Ladeleistung 1,3 kW bis 22 kW\n    Dreiphasige Einspeisung\n    Boost-Funktion\n    Blackout-Schutz\n    Ethernet/WLAN Schnittstelle\n    Abmaße: 460 mm x 357 mm x 122 mm\n    Kabellänge: 7,5 m\n    Herstellernummer: EVC22-3AC-10-1",
    "Technische Produktdaten":
      "    Ladeleistung in W: 1300-22000\n    Nennspannung: 400\n    Fahrzeuganschluss: Typ 2 Stecker\n    Maße (BxHxT) in mm: 460 x 357 x 122\n    Gewicht in kg: 8\n    Betriebstemperatur in °C: -25 bis 40\n    ",
    Garantie: "5 Jahre Garantie",
    Herstellerinformation:
      "Als global führender Spezialist für Photovoltaik-Systemtechnik schafft SMA heute die Voraussetzungen für die dezentrale, digitale und erneuerbare Energieversorgung von morgen. Dieser Aufgabe widmen sich über 3.000 SMA Mitarbeiter in 18 Ländern mit großer Leidenschaft. Mit unseren innovativen Lösungen für alle Photovoltaikanwendungen ermöglichen wir Menschen und Unternehmen weltweit mehr Unabhängigkeit in der Anwendung von Energie. Gemeinsam mit unseren Kooperationspartnern und Kunden machen wir den Umbau zu einer unabhängigen, dezentralen und erneuerbaren Energieversorgung möglich.",
    bullet_point_list:
      '["Ladeleistung 1,3 kW bis 22 kW", "zum Laden von Elektrofahrzeugen in Kombination mit dem Sunny Home Manager 2.0", "ideal für Überschussladung"]',
    price_b2c: 1639,
    price_b2b: 1327.5900000000001,
    color: '["white"]',
    subtype: "Highspeed",
    video_link: "https://youtu.be/hbDhx2xGYhk",
    extended_warranty_cost: null,
    warranty_link: null,
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 22000,
    imgurl1:
      " https://www.elektroshopwagner.de/images/product_images/popup_images/228010_0.jpg ",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EaIKF5vaublBqvvdexlRvGYB0LpTbtVL-dZ-H8-5aLPSQw?e=kf4oxF",
  },
  {
    ID: "H1",
    Available: "Yes",
    name: "Viessmann VITOCAL 222-A",
    type: "heatpump",
    brand: "Viessmann",
    "allowed inverter": null,
    short_description:
      "Die Wärmepumpe Vitocal 222-A in Monoblock-Bauweise\nnutzt umweltfreundlich und kostengünstig die in der\nAußenluft enthaltene Wärme. Sie kann heizen und\nkühlen. Das Kompaktgerät verfügt über einen inte-\ngrierten 220-Liter-Warmwasserspeicher.",
    long_description:
      "Wärmepumpen-Kompaktgerät mit elektrischem Antrieb in\nMonoblockbauweise mit Außen- und Inneneinheit\n■ Für Raumbeheizung und Trinkwassererwärmung in Hei-\nzungsanlagen\n■ Inneneinheit mit Wärmepumpenregelung Vitotronic 200,\nintegriertem Speicher-Wassererwärmer 220 l Inhalt, Hoch-\neffizienz-Umwälzpumpe für den Sekundärkreis, eingebau-\ntem Heizwasser-Durchlauferhitzer, 3-Wege-Umschaltventil\nund Sicherheitsgruppe\n■ Mit Kühlfunktion „active cooling",
    Highlights:
      "Hochtemperaturwärmepumpe die auch für Sanierungen geeignet ist bei Vorlauftemp. Bis 65 Grad.",
    "Technische Produktdaten":
      "    Ladeleistung in W: 1300-22000\n    Nennspannung: 400\n    Fahrzeuganschluss: Typ 2 Stecker\n    Maße (BxHxT) in mm: 460 x 357 x 122\n    Gewicht in kg: 8\n    Betriebstemperatur in °C: -25 bis 40\n    ",
    Garantie: "10 Jahre auf Inverter",
    Herstellerinformation:
      "Als global führender Spezialist für Photovoltaik-Systemtechnik schafft SMA heute die Voraussetzungen für die dezentrale, digitale und erneuerbare Energieversorgung von morgen. Dieser Aufgabe widmen sich über 3.000 SMA Mitarbeiter in 18 Ländern mit großer Leidenschaft. Mit unseren innovativen Lösungen für alle Photovoltaikanwendungen ermöglichen wir Menschen und Unternehmen weltweit mehr Unabhängigkeit in der Anwendung von Energie. Gemeinsam mit unseren Kooperationspartnern und Kunden machen wir den Umbau zu einer unabhängigen, dezentralen und erneuerbaren Energieversorgung möglich.",
    bullet_point_list:
      '["bis 60 Grad Vorlauftemp", "Auch im Altbau", "scvhickes Design"]',
    price_b2c: 12000,
    price_b2b: 9720,
    color: '["white"]',
    subtype: "Standard",
    video_link: "https://youtu.be/hbDhx2xGYhk",
    extended_warranty_cost: null,
    warranty_link:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EY33BcWYtIBPqKp6jlS1aH4Bnr0BCeMYdehlhGDSj_IRjA?e=SdBbZN ",
    "capacity (kwp)": null,
    instock: "yes",
    applicable_max_capacity: 15000,
    imgurl1:
      " https://www.heizungsdiscount24.de/shop/images/products/main/Viessmann-Vitocal-222-A-Z016855.jpg",
    imgurl2: null,
    imgurl3: null,
    imgurl4: null,
    imgurl5: null,
    pdf_url:
      "https://solar24.sharepoint.com/:b:/s/Solarhub/EaIKF5vaublBqvvdexlRvGYB0LpTbtVL-dZ-H8-5aLPSQw?e=kf4oxF",
  },
];
