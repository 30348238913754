import React, { Component, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from 'Assets/Close.svg'
import Group from '../../../popups/SignUpSignIn/images/Group.svg'
import CopyToClipboard from "@vigosan/react-copy-to-clipboard";
  import { makeStyles, TextField ,  createStyles} from "@material-ui/core";
const styles = (theme: Theme) => createStyles({});

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function AlertDialog({ open, setOpen, url }) {
  const handleClose = () => {
    setOpen(false);
  };
  const classes=useStyles()
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
     <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </div>
      <DialogTitle id="alert-dialog-title">
        {"Use below URL to share the project"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <CopyURL url={url} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

class CopyURL extends Component {
  render() {
    return (
      <CopyToClipboard
        onCopy={({ success, text }) => {
          var msg = success ? "Copied!" : "Whoops, not copied!";
          this.button.innerHTML = msg;
          
        }}
        render={({ copy }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "25px",
            }}
          >
            <TextField
              style={{ width: "500px", margin: "15px" }}
              defaultValue={this.props.url}
              ref={(input) => {
                this.textInput = input;
              }}
              disabled
            />
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => copy(this.textInput.value)}
              ref={(button) => {
                this.button = button;
              }}
            >
              Copy
            </Button>
          </div>
        )}
      />
    );
  }
}
