import React, { useState, useEffect } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Group from "./../../../Assets/Group.png";
import House from "./../../../Assets/House.png";
import { Button, Input, TextField } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import { Typography } from "antd";
import SingleImg from "./../../../Assets/application_step_1/consumption.building.1.png";
import MultiImg from "./../../../Assets/application_step_1/consumption.building.2.png";
import { colors } from "../../../colors";
import {
  consumption_profiles,
  default_consumption_details,
} from "../../../utils/consumption";
import setCommaDot, {
  commaDotHandler,
  parseLocaleNumber,
  swapDotComma,
} from "helperMethod";
import { useSelector } from "react-redux";
import { calculated_consumption_b2b } from "utils/consumption";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 912,
    maxHeight: 778,
  },
  center: {
    textAlign: "center",
    color: "#2D4764",
  },
  main_container: {
    marginTop: "10px",
    width: "511",
    display: "flex",
    "@media (max-width: 530px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  input_main: {
    height: "151px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
  },
  inside_inputMain: {
    backgroundColor: " #EAEAEA",
    width: "289px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  },
  inside_main: {
    width: "143px",
    height: "140px",
    border: `1px solid #2D4764`,
    borderRadius: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    margin: "8px",
    "@media (max-width: 530px)": {
      marginBottom: "10px",
    },
    "& img": {
      height: "64px",
      marginTop: "20px",
      width: "76px",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(253, 184, 0, 0.1)",
    },
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Button_color: {
    backgroundColor:
      " linear-gradient(226.42deg, #1B4963 8.93%, #09152F 110.98%)",
  },
}));
const BusinessType = ({
  isEnglish,
  setOpen,
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
}) => {
  const [temp, setTemp] = useState(
    commaDotHandler(
      consumption_details?.price_per_kWH,
      `${isEnglish ? "en" : "de"}`
    )
  );
  const [temp1, setTemp1] = useState(
    commaDotHandler(
      calculated_consumption_b2b(consumption_details),
      `${isEnglish ? "en" : "de"}`
    )
  );
  React.useEffect(() => {
    setConsumptionDetails({
      ...consumption_details,
      business_type: "Office",
      kwa_per_employee: default_consumption_details["Office"].no_of_employees,
      no_of_employees: default_consumption_details["Office"].kwa_per_employee,
    });
    setTemp1(
      commaDotHandler(
        calculated_consumption_b2b(consumption_details),
        `${isEnglish ? "en" : "de"}`
      )
    );
  }, []);
  const classes = useStyles();
  return (
    <div>
      <h4 className={classes.center}>
        {isEnglish
          ? "  Which of the following business types matches you?"
          : "Welche der folgenden Geschäftsarten passt zu Ihnen?"}
      </h4>
      <div className={classes.main_container}>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Office",
              kwa_per_employee:
                default_consumption_details["Office"].no_of_employees,
              no_of_employees:
                default_consumption_details["Office"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "Office",
                kwa_per_employee:
                  default_consumption_details["Office"].no_of_employees,
                no_of_employees:
                  default_consumption_details["Office"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Office" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Office" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={SingleImg} alt="Home" />
          <h5 className={classes.center}> {isEnglish ? "Office" : "Büro"}</h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Manufacturer",
              kwa_per_employee:
                default_consumption_details["Manufacturer"].no_of_employees,
              no_of_employees:
                default_consumption_details["Manufacturer"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "Manufacturer",
                kwa_per_employee:
                  default_consumption_details["Manufacturer"].no_of_employees,
                no_of_employees:
                  default_consumption_details["Manufacturer"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Manufacturer" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Manufacturer" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>
            {" "}
            {isEnglish ? "Manufacturer" : "Hersteller"}
          </h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Restaurant",
              kwa_per_employee:
                default_consumption_details["Restaurant"].no_of_employees,
              no_of_employees:
                default_consumption_details["Restaurant"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "Restaurant",
                kwa_per_employee:
                  default_consumption_details["Restaurant"].no_of_employees,
                no_of_employees:
                  default_consumption_details["Restaurant"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Restaurant" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Restaurant" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>
            {" "}
            {isEnglish ? "Restaurant" : "Restaurant"}
          </h5>
        </div>
        {/* <div
          onClick={() =>
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Service",
            })
          }
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Service" && "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Service" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>Service</h5>
        </div> */}
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Administration",
              kwa_per_employee:
                default_consumption_details["Administration"].no_of_employees,
              no_of_employees:
                default_consumption_details["Administration"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "Administration",
                kwa_per_employee:
                  default_consumption_details["Administration"].no_of_employees,
                no_of_employees:
                  default_consumption_details["Administration"]
                    .kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Administration" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Administration" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>
            {" "}
            {isEnglish ? "Administration" : "Verwaltung"}
          </h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "University",
              kwa_per_employee:
                default_consumption_details["University"].no_of_employees,
              no_of_employees:
                default_consumption_details["University"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "University",
                kwa_per_employee:
                  default_consumption_details["University"].no_of_employees,
                no_of_employees:
                  default_consumption_details["University"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "University" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "University" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={SingleImg} alt="Home" />
          <h5 className={classes.center}>
            {" "}
            {isEnglish ? "University" : "Universität"}
          </h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "School",
              kwa_per_employee:
                default_consumption_details["School"].no_of_employees,
              no_of_employees:
                default_consumption_details["School"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "School",
                kwa_per_employee:
                  default_consumption_details["School"].no_of_employees,
                no_of_employees:
                  default_consumption_details["School"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "School" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "School" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}> {isEnglish ? "School" : "Schule"}</h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Shopping",
              kwa_per_employee:
                default_consumption_details["Shopping"].no_of_employees,
              no_of_employees:
                default_consumption_details["Shopping"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "Shopping",
                kwa_per_employee:
                  default_consumption_details["Shopping"].no_of_employees,
                no_of_employees:
                  default_consumption_details["Shopping"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Shopping" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Shopping" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>
            {" "}
            {isEnglish ? "Shopping" : "Das Einkaufen"}
          </h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Hotel",
              kwa_per_employee:
                default_consumption_details["Hotel"].no_of_employees,
              no_of_employees:
                default_consumption_details["Hotel"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "Hotel",
                kwa_per_employee:
                  default_consumption_details["Hotel"].no_of_employees,
                no_of_employees:
                  default_consumption_details["Hotel"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Hotel" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Hotel" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>
            {" "}
            {isEnglish ? "Hotel" : "Übernehmen"}
          </h5>
        </div>
        <div
          onClick={() => {
            setConsumptionDetails({
              ...consumption_details,
              business_type: "Other",
              kwa_per_employee:
                default_consumption_details["Other"].no_of_employees,
              no_of_employees:
                default_consumption_details["Other"].kwa_per_employee,
            });
            setTemp1(
              calculated_consumption_b2b({
                ...consumption_details,
                business_type: "Other",
                kwa_per_employee:
                  default_consumption_details["Other"].no_of_employees,
                no_of_employees:
                  default_consumption_details["Other"].kwa_per_employee,
              })
            );
          }}
          className={classes.inside_main}
          style={{
            backgroundColor:
              consumption_details.business_type === "Other" &&
              "rgba(253, 184, 0, 0.1)",
            border:
              consumption_details.business_type === "Other" &&
              `2px solid #2D4764`,
          }}
        >
          <img src={MultiImg} alt="Home" />
          <h5 className={classes.center}>
            {" "}
            {isEnglish ? "Other" : "Sonstiges"}
          </h5>
        </div>
      </div>
      <YearlyConsumptionInput
        consumption={consumption}
        setConsumption={setConsumption}
        consumption_details={consumption_details}
        setConsumptionDetails={setConsumptionDetails}
        temp={temp}
        setTemp={setTemp}
        temp1={temp1}
        setTemp1={setTemp1}
      />
    </div>
  );
};

export default BusinessType;
export const YearlyConsumptionInput = ({
  consumption,
  setConsumption,
  consumption_details,
  setConsumptionDetails,
  temp,
  setTemp,
  temp1,
  setTemp1,
}) => {
  const classes = useStyles();
  const isEnglish = useSelector((state) => state.global.isEnglish);
  return (
    <div className={classes.input_main}>
      <div className={classes.inside_inputMain}>
        <Typography>
          {isEnglish ? "Price per kWh €" : "Preis pro kWh €"}
        </Typography>
        <Input
          style={{ textAlign: "center", paddingLeft: "5px", width: "100px" }}
          placeholder={isEnglish ? "Price per kWh €" : "Preis pro kWh €"}
          value={temp}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(e) => {
            let value = e.target.value;
            if (isEnglish) {
              value = parseLocaleNumber(e.target.value, "en");
              setTemp(e.target.value);
            } else {
              value = parseLocaleNumber(e.target.value, "de");
              setTemp(e.target.value);
            }
            setConsumptionDetails({
              ...consumption_details,
              price_per_kWH: value,
            });
          }}
          // onBlur={() => {
          //   if (isNaN(consumption_details.price_per_kWH)) {
          //     setTemp(0.3);
          //     setConsumptionDetails({
          //       ...consumption_details,
          //       price_per_kWH: 0.3,
          //     });
          //   }
          // }}
        />
        <Typography>
          {isEnglish
            ? "Yearly consumption in kWh"
            : "Jährlicher Energieverbrauch in kWh"}
        </Typography>
        <Input
          placeholder={
            isEnglish
              ? "Yearly consumption in kWh"
              : "Jährlicher Energieverbrauch in kWh"
          }
          inputProps={{ style: { textAlign: "center" } }}
          style={{ textAlign: "center", paddingLeft: "5px", width: "100px" }}
          value={temp1}
          onChange={(e) => {
            let value = e.target.value;
            if (isEnglish) {
              value = parseLocaleNumber(value, "en");
              setTemp1(e.target.value);
            } else {
              value = parseLocaleNumber(value, "de");
              setTemp1(e.target.value);
            }
            setConsumption(value);
          }}
        />
      </div>
    </div>
  );
};
