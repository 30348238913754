import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Form, Input, Button, InputNumber, Checkbox } from "antd";
import { Dialog, AppBar, Toolbar, Typography } from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import * as geofire from "geofire-common";
import CloseIcon from "@material-ui/icons/Close";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../../../firebase";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const InstallerPopup = ({
  open,
  newObject,
  objectDetails,
  openMethod,
  setObjectDetails,
  setIsNewObject,
}) => {
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  const [imgurl1, setimgurl1] = useState("");
  const [type, setType] = useState("Classification");
  const [objectId, setObjectId] = useState("");

  useEffect(() => {
    setObjectId("");
    if (open) {
      if (newObject) {
        setObjectId(uuidv4());
      } else {
        setObjectId(objectDetails?.iid);
      }
    }
  }, [open, newObject, objectDetails]);

  console.log(objectId, "objectId");

  const onFinish = (values) => {
    const data = {
      geometry: {
        coordinates: [
          parseFloat(values.coordinates_y),
          parseFloat(values.coordinates_x),
        ],
        type: "Point",
      },
    };
    const finalData = {
      ...values,
      lat: parseFloat(values.coordinates_x),
      iid: `newinstaller${String(objectId).slice(28)}`,
      lng: parseFloat(values.coordinates_y),
      self_leads: [],
      geohash: geofire.geohashForLocation([
        parseFloat(values.coordinates_x),
        parseFloat(values.coordinates_y),
      ]),
      ...data,
    };
    delete finalData.coordinates_x;
    delete finalData.coordinates_y;
    console.log(finalData, "values");
    // const c = finalData.company_name.toLowerCase();
    // const d = finalData.address.toLowerCase();
    // var array1 = [];
    // var array2 = [];
    // for (let i = 0; i < c.length + 1; i++) {
    //   array1.push(c.substring(i, c.length));
    // }
    // for (let i = 0; i < d.length + 1; i++) {
    //   array2.push(d.substring(i, d.length));
    // }
    db.collection("installers")
      .doc(`newinstaller${String(objectId).slice(28)}`)
      .set({
        ...finalData,
        // company_filter_arr_rev: array1,
        // address_filter_arr_rev: array2,
      })
      .then(() => {
        toast.success("Saved");
        setReload(!reload);
        openMethod(false);
        setObjectDetails({});
        setIsNewObject(true);
      })
      .catch((error) => {
        toast.error("Error Occured");
        openMethod(false);
        setObjectDetails({});
        setIsNewObject(true);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <Dialog fullScreen open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                openMethod(false);
                setObjectDetails({});
                setIsNewObject(true);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add Key installers
            </Typography>
          </Toolbar>
        </AppBar>
        <Form
          name="basic"
          style={{
            margin: "auto",
            fontSize: "26px",
            marginTop: "30px",
            width: "80%",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            ["Größe der Installation in"]: "",
            ["Installaliert seit"]: "",
            ["Installationen in"]: "",
            ["Last Update"]: "",
            ["Lieferanten von Solarmodulen"]: "",
            ["Muttergesellschaft"]: "",
            ["Netzanlagen/Inselanlagen"]: "",
            ["Sonstiger Service"]: "",
            ["Wechselrichter-Lieferanten"]: "",
            address: "",
            admin_rating: 5,
            company_name: "",
            company_url: "",
            coordinates_x: 47.804911,
            coordinates_y: 12.274073,
            email: "",
            geohash: "",
            image_url: "",
            installer_status: false,
            is_registered: false,
            logo_url: "",
            user_rating: 5,
            telephone: "",
            website: "",
          }}
          autoComplete="off"
        >
          <Form.Item label="company_name" name="company_name" required>
            <Input />
          </Form.Item>
          <Form.Item label="address" name="address" required>
            <Input />
          </Form.Item>
          <Form.Item label="Installaliert seit" name="Installaliert seit">
            <Input />
          </Form.Item>
          <Form.Item label="email" name="email" required>
            <Input />
          </Form.Item>
          <Form.Item label="Installationen in" name="Installationen in">
            <Input />
          </Form.Item>
          <Form.Item label="coordinates_x (lat)" name="coordinates_x">
            <Input />
          </Form.Item>
          <Form.Item label="coordinates_y (long)" name="coordinates_y">
            <Input />
          </Form.Item>
          <Form.Item label="Last Update" name="Last Update">
            <Input />
          </Form.Item>
          <Form.Item
            label="Lieferanten von Solarmodulen"
            name="Lieferanten von Solarmodulen"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Muttergesellschaft" name="Muttergesellschaft">
            <Input />
          </Form.Item>
          <Form.Item
            label="Netzanlagen/Inselanlagen"
            name="Netzanlagen/Inselanlagen"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Sonstiger Service" name="Sonstiger Service">
            <Input />
          </Form.Item>
          <Form.Item
            label="Größe der Installation in"
            name="Größe der Installation in"
          >
            <Input />
          </Form.Item>
          <Form.Item label="admin_rating" name="admin_rating">
            <InputNumber />
          </Form.Item>
          <Form.Item label="user_rating" name="user_rating">
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Wechselrichter-Lieferanten"
            name="Wechselrichter-Lieferanten"
          >
            <Input />
          </Form.Item>
          <Form.Item label="company_url" name="company_url">
            <Input />
          </Form.Item>
          <Form.Item label="telephone" name="telephone">
            <Input />
          </Form.Item>
          <Form.Item label="logo_url" name="logo_url">
            <Input />
          </Form.Item>
          <Form.Item label="geohash" name="geohash">
            <Input />
          </Form.Item>
          <Form.Item label="image_url" name="image_url">
            <Input />
          </Form.Item>
          <Form.Item
            label="installer_status"
            name="installer_status"
            valuePropName="checked"
          >
            <Checkbox>Checkbox</Checkbox>
          </Form.Item>
          <Form.Item
            label="is_registered"
            name="is_registered"
            valuePropName="checked"
          >
            <Checkbox>Checkbox</Checkbox>
          </Form.Item>
          <Form.Item label="website" name="website">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: "300px", height: "50px" }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Dialog>
    </>
  );
};

export default InstallerPopup;
