import { useState, useEffect, Fragment } from "react";
import NormalFAQs from "./tabs/normal";
import ComponentsFAQs from "./tabs/components";
import PromotionsFAQs from "./tabs/promotions";
import InstalltionsFAQs from "./tabs/installations";
import search from "../assets/search.svg";
import { db } from "../../../../../firebase";
import { CircularProgress, Box } from "@material-ui/core";
import { MainContainer, StyledTabs, Tab, Content } from "./styles";
import CreateTopicModal from "../home/partials/CreateTopicModal";
import { useSelector } from "react-redux";

const FAQsMain = () => {
  const [value, setValue] = useState("FAQs");
  const [tab, settab] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [faqs, setfaqs] = useState(null);
  const [filtered_faqs, setfiltered_faqs] = useState(null);
  const [nofaqsfound, setnofaqsfound] = useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  useEffect(() => {
    db.collection("faqs")
      .get()
      .then((snapshots) => {
        const faqs = [];
        snapshots.forEach((doc) => {
          faqs.push(doc.data());
        });
        console.log("faqs", faqs);
        filterAllFAQs(faqs);
      })
      .catch((err) => console.log("err", err));
  }, []);

  useEffect(() => {
    if (searchText != "") {
      const filtered_data = {
        normalFAQs: faqs.normalFAQs.filter(
          (x) =>
            x.title.toLowerCase().includes(searchText.toLowerCase()) ||
            x.content.toLowerCase().includes(searchText.toLowerCase())
        ),
        componentFAQs: faqs.componentFAQs.filter(
          (x) =>
            x.title.toLowerCase().includes(searchText.toLowerCase()) ||
            x.content.toLowerCase().includes(searchText.toLowerCase())
        ),
        installationsFAQs: faqs.installationsFAQs.filter(
          (x) =>
            x.title.toLowerCase().includes(searchText.toLowerCase()) ||
            x.content.toLowerCase().includes(searchText.toLowerCase())
        ),
        promotionsFAQs: faqs.promotionsFAQs.filter(
          (x) =>
            x.title.toLowerCase().includes(searchText.toLowerCase()) ||
            x.content.toLowerCase().includes(searchText.toLowerCase())
        ),
      };
      const filtered_data_arr = [
        filtered_data.normalFAQs.length,
        filtered_data.componentFAQs.length,
        filtered_data.installationsFAQs.length,
        filtered_data.promotionsFAQs.length,
      ];

      filtered_data_arr.sort();
      filtered_data_arr.reverse();

      if (filtered_data_arr[0] > 0) {
        setnofaqsfound(false);
        setfiltered_faqs(filtered_data);
        for (let x in filtered_data) {
          if (filtered_data[x].length == filtered_data_arr[0]) {
            console.log("type", filtered_data[x][0].type);
            setValue(filtered_data[x][0].type);
            break;
          }
        }
      } else {
        setnofaqsfound(true);
        setfiltered_faqs(null);
      }
    } else {
      setfiltered_faqs(faqs);
      setnofaqsfound(false);
    }
  }, [searchText]);

  useEffect(() => {
    if (filtered_faqs) {
      switch (value) {
        case "FAQs":
          settab(
            <NormalFAQs
              faqs={filtered_faqs?.normalFAQs}
              searchText={searchText}
            />
          );
          break;
        case "Komponenten":
          settab(
            <ComponentsFAQs
              faqs={filtered_faqs?.componentFAQs}
              searchText={searchText}
            />
          );
          break;
        case "Installation":
          settab(
            <InstalltionsFAQs
              faqs={filtered_faqs?.installationsFAQs}
              searchText={searchText}
            />
          );
          break;
        case "Förderungen":
          settab(
            <PromotionsFAQs
              faqs={filtered_faqs?.promotionsFAQs}
              searchText={searchText}
            />
          );
          break;
      }
    }
  }, [filtered_faqs, value]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const filterAllFAQs = (faqs) => {
    setfaqs({
      normalFAQs: faqs.filter((x) => x.type == "FAQs"),
      componentFAQs: faqs.filter((x) => x.type == "Komponenten"),
      installationsFAQs: faqs.filter((x) => x.type == "Installation"),
      promotionsFAQs: faqs.filter((x) => x.type == "Förderungen"),
    });
    setfiltered_faqs({
      normalFAQs: faqs.filter((x) => x.type == "FAQs"),
      componentFAQs: faqs.filter((x) => x.type == "Komponenten"),
      installationsFAQs: faqs.filter((x) => x.type == "Installation"),
      promotionsFAQs: faqs.filter((x) => x.type == "Förderungen"),
    });
  };

  return (
    <MainContainer>
      <div className="title">FREQUENTLY ASKED QUESTIONS (FAQ)</div>
      <div className="search-container">
        <div className="search-bar">
          <div className="search-icon">
            <img src={search} alt="" />
          </div>
          <div>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              placeholder={isEnglish ? "Search the FAQ" : "Durchsuche die FAQ"}
            />
          </div>
        </div>
        <div className="new-topic" onClick={() => setOpen1(true)}>
          + New topic
        </div>
      </div>
      {filtered_faqs ? (
        <Fragment>
          <StyledTabs
            value={value}
            onChange={handleChange}
            style={{
              marginTop: 32,
            }}
          >
            <Tab
              className={value == "FAQs" ? "selected" : ""}
              value="FAQs"
              selected={value}
              onClick={() => handleChange("FAQs")}
            >
              FAQs
            </Tab>
            <Tab
              className={value == "Komponenten" ? "selected" : ""}
              value="Komponenten"
              selected={value}
              onClick={() => handleChange("Komponenten")}
            >
              Komponenten
            </Tab>
            <Tab
              className={value == "Förderungen" ? "selected" : ""}
              value="Förderungen"
              selected={value}
              onClick={() => handleChange("Förderungen")}
            >
              Förderungen
            </Tab>
            <Tab
              className={value == "Installation" ? "selected" : ""}
              value="Installation"
              selected={value}
              onClick={() => handleChange("Installation")}
            >
              Installation
            </Tab>
          </StyledTabs>
          <Content value={value}>{tab}</Content>
        </Fragment>
      ) : (
        <Box
          sx={{
            display: "flex",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {nofaqsfound ? (
            <div>No FAQs Found</div>
          ) : (
            <CircularProgress
              style={{
                color: "#fbda61",
              }}
            />
          )}
        </Box>
      )}
      <CreateTopicModal setOpen={setOpen1} open={open1} />
    </MainContainer>
  );
};

export default FAQsMain;
