import { db } from "../../firebase";

export const updateInverter = (payload) => {
  return {
    type: "UPDATE_INVERTER",
    payload: payload,
  };
};

export const updateInverterComponent = (project) => {
  return (dispatch) => {
    console.log("dispatching");
    const total_capacity =
      project?.components?.items?.solar_panel?.quantity *
      project?.components?.items?.solar_panel?.item?.capacity;
    console.log("total capacity", total_capacity);

    db.collection("products")
      .where("type", "==", "inverter")
      .get()
      .then((snapshots) => {
        const data = [];
        snapshots.forEach((doc) => {
          data.push(doc.data());
        });
        console.log("data", data);
        const filteredData = data.filter(
          (x) => parseFloat(x.applicable_max_capacity) >= total_capacity
        );
        console.log("filtereddata", filteredData);
        filteredData.sort((a, b) => {
          return (
            parseFloat(a.applicable_max_capacity) >
            parseFloat(b.applicable_max_capacity)
          );
        });
        console.log("sorted data", filteredData);
        if (filteredData.length > 0) {
          dispatch(updateInverter(filteredData[0]));
        }
      });
  };
};
