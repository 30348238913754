import React, { useEffect, useState, useRef } from "react";
import { MapViewWrapper } from "./mapViewStyles";
import Header from "../header/Header";
import UserCard from "./userCard/UserCard";
import Slider from "react-slick";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { GeoJSONSource } from "mapbox-gl";

const MapView = ({
  addDummyProject,
  setIsMapView,
  selectOfferHandler,
  isEnglish,
  isSavedProject,
  setIsSavedProject,
  scollarData,
}) => {
  const mapContainer = useRef(null);
  const [geoJsonData, setgeoJsonData] = useState(null);
  const map = useRef(null);
  const [lng, setLng] = useState(7.9591355);
  const [lat, setLat] = useState(47.9814838);
  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    const fire = scollarData?.map((data) => {
      const news = {
        type: "Feature",
        properties: {
          ...data,
        },
        geometry: {
          type: "Point",
          coordinates: [data.long, data.lat],
        },
      };
      return news;
    });
    const realMapData = {
      type: "FeatureCollection",
      features: fire,
    };
    console.log("realmap", realMapData);
    setgeoJsonData(realMapData);
  }, []);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/rlx007/ckw0lrqqp4te315tb4zehx7lj",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl());
  }, [map, geoJsonData, isSavedProject]);

  useEffect(() => {
    if (!map.current) return;
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [map]);

  useEffect(() => {
    if (!map.current) return;
    if (geoJsonData) {
      map.current.on("load", () => {
        const id = "places";

        console.log("sourceId", map.current.getSource(id));
        if (!map.current.getSource(id)) {
          console.log("jeojson", geoJsonData);
          map.current.addSource("places", {
            type: "geojson",
            data: geoJsonData,
          });
        }

        console.log("layerId", map.current.getLayer(id));
        if (!map.current.getLayer(id)) {
          map.current.addLayer({
            id: "places",
            type: "circle",
            source: "places",
            paint: {
              "circle-color": "#4264fb",
              "circle-radius": 6,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#ffffff",
            },
          });
        }
      });
    }
  }, [map, geoJsonData, isSavedProject]);

  useEffect(() => {
    if (geoJsonData) {
      var bounds = new mapboxgl.LngLatBounds();

      geoJsonData.features.forEach(function (feature) {
        bounds.extend(feature.geometry.coordinates);
      });

      map.current.fitBounds(bounds);
    }
  }, [geoJsonData, isSavedProject]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className="arrow_icon"
        style={{
          ...style,
          display:
            onClick === null || window.innerWidth < 383 ? "none" : "block",
          position: "absolute",
          right: window.innerWidth > 500 ? "-20px" : "-25px",
          top: window.innerWidth > 500 ? "40%" : "40%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIos
        className="arrow_icon"
        style={{
          ...style,
          display:
            onClick === null || window.innerWidth < 383 ? "none" : "block",
          position: "absolute",
          left: window.innerWidth > 500 ? "-25px" : "-22px",
          top: window.innerWidth > 500 ? "41%" : "40%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    accessibility: true,
    infinite: false,
    speed: 600,
    slidesToShow: window.innerWidth > 500 ? 3 : 1,
    slidesToScroll: 1,
    // afterChange: function (currentSlide) {
    //   console.log(currentSlide, "currentSlide");
    // },
    beforeChange: function (prev, next) {
      console.log(prev, next, "currentSlide");
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <MapViewWrapper>
      <div className="mapView_main">
        <Header
          addDummyProject={addDummyProject}
          setIsMapView={setIsMapView}
          setIsSavedProject={setIsSavedProject}
          isSavedProject={isSavedProject}
          flag={true}
        />
        <div className="our_main">
          <div className="left_side_map">
            <div className="relative map-outer-container">
              <div
                ref={mapContainer}
                className="map-container map-inside-container"
              ></div>
            </div>
          </div>
          <div className="right_side_map">
            {scollarData?.length && window.innerWidth > 700 ? (
              scollarData.map((data, index) => {
                return (
                  <UserCard
                    data={data}
                    key={index}
                    selectOfferHandler={selectOfferHandler}
                    isEnglish={isEnglish}
                  />
                );
              })
            ) : (
              <Slider
                {...settings}
                style={{
                  width: window.innerWidth < 560 ? "100%" : "100%",
                  margin: window.innerWidth < 560 ? "auto" : "",
                }}
              >
                {scollarData?.map((data, index) => {
                  return (
                    <UserCard
                      data={data}
                      key={index}
                      selectOfferHandler={selectOfferHandler}
                      isEnglish={isEnglish}
                      savedProject={isSavedProject}
                    />
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </MapViewWrapper>
  );
};

export default MapView;
