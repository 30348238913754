import React from "react";
import styled from "styled-components";
import Cross from "./Assets/Cross";
import Tick from "./Assets/Tick";
const MainContainer = styled("div")`
  position: relative;
  width: 512px;
  height: 312px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 98%;
    position: absolute;
    bottom: 4px;
  }
`;
const CrossContainer = styled("div")`
  position: absolute;
  right: 28px;
  top: 25px;
  cursor: pointer;
`;
const SubContainer = styled("div")`
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Text1 = styled("div")`
  margin-top: 28px;
  font-weight: bold;
  font-size: 20px;
  color: #2d4764;
`;
const Text2 = styled("div")`
  margin-top: 14px;
  font-weight: normal;
  font-size: 16px;
`;
const InviteSent = ({ handleCancel }) => {
  return (
    <MainContainer>
      <CrossContainer
        onClick={() => {
          handleCancel();
        }}
      >
        <Cross />
      </CrossContainer>
      <SubContainer>
        <Tick />
        <Text1>{isEnglish ? "Invitation sent" : "Einladung gesendet"}</Text1>
        <Text2>
          {isEnglish
            ? "Your invite has been sent sucsessfully."
            : "Deine Einladung wurde erfolgreich versendet"}
        </Text2>
      </SubContainer>
      {/* rushil */}
    </MainContainer>
  );
};

export default InviteSent;
