import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  Dialog,
  Button,
  makeStyles,
  Card,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { colors } from "../../colors";
import Polygon from "Assets/Polygon.svg";
import { Avatar, Progress, Rate } from "antd";
import Close from "../../Assets/Close.svg";
import Group from "../SignUpSignIn/images/Group.svg";
import ShowMore from "@tedconf/react-show-more";
import maleTelemarketer from "Assets/maleTelemarketer.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "666px",
    height: "279px",
    margin: "0px",
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: { minWidth: "60%", height: "744px" },
  rootContainer1: {
    width: "91%",
    margin: "auto",
    marginTop: "15px",
    marginBottom: "15px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
  },
  rootContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  heading: {
    display: "flex",
    width: "100%",
    marginTop: "32px",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    "& h4": {
      fontSize: "20px",
      fontWeight: "600",
    },
    alignItems: "center",
  },
  reviews: {
    display: "flex",
    height: "36px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    cursor: "pointer",
    "& h5": {
      color: "rgba(45, 71, 100, 1)",
      fontSize: "14px",
    },
  },
  showRating: {
    height: "210px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "520px",
    },
    alignItems: "cetner",
    width: "100%",
  },
  insideRating1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "80%",
    flex: 1,
    alignItems: "center",
    margin: "10px",
    "& img": {
      width: "113px",
      height: "81px",
      [theme.breakpoints.down("xs")]: {
        width: "135px",
        height: "89px",
      },
      borderRadius: "5px",
    },
  },
  insideRating2: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    flex: 3,
    alignItems: "flex-start",
    margin: "10px",
    "& h5": {
      fontWeight: "600",
      fontSize: "16px",
    },
    "& img": {
      width: "85px",
      height: "72px",
      borderRadius: "5px",
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
    "& h2": {
      fontSize: "18px",
      fontWeight: "600",
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
    "& div": {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
      "& h3": {
        marginRight: "5px",
      },
      color: "#666666",
      fontSize: "14px",
      fontWeight: "600",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  insideRating3: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flex: 2,
    height: "157px",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
    "& h4": {
      color: "#666666",
      fontWeight: "600",
      fontSize: "24px",
    },
    alignItems: "center",
  },
  button: {
    width: "139px",
    height: "40px",
    color: "#ffffff",
    background: "rgba(45, 71, 100, 1)",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      backgroundColor: "rgba(45, 71, 100, 1)",
    },
    borderRadius: "36px",
  },
  button2: {
    width: "139px",
    height: "40px",
    color: "rgba(45, 71, 100, 1)",
    paddingTop: "1px",
    textAlign: "center",
    border: "none",
    boxShadow: "none",
    background: "#ffffff",
    borderRadius: "36px",
  },
  imgName: {
    width: "109px",
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      width: "135px",
      height: "89px",
    },
    height: "72px",
    borderRadius: "5px",
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "0px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const FeedbackPopup = ({ data, setOpen, open, averageRating }) => {
  const [updatedRatingData, setUpdatedRatingsData] = useState([]);
  const classes = useStyles();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);
  const isEnglish = useSelector((state) => state.global.isEnglish);
  const returnProjectDetails = async (data) => {
    const datas = await db
      .collection("saved_projects")
      .doc(data?.pid)
      .get()
      .then((doc) => {
        //   if (!doc.data() === undefined) {
        //   projectData.push({ projectData: doc.data() });
        const obj = { ...doc.data() };
        return obj;
        //   }
      })
      .catch((err) => {
        return null;
      });
    return datas;
  };

  const returnUserDetails = async (data) => {
    const datas = await db
      .collection("users")
      .doc(data.uid)
      .get()
      .then((doc) => {
        //   if (!doc.data() === undefined) {
        const obj = { ...doc.data() };
        return obj;
        //   }
      })
      .catch((err) => {
        return null;
      });
    return datas;
  };

  function isEmptyObject(value) {
    return (
      value && value.constructor === Object && Object.keys(value).length === 0
    );
  }

  useEffect(() => {
    const func = async () => {
      let news = [];
      for (let i = 0; i < data.length; i++) {
        const projectData = await returnProjectDetails(data[i]);
        const userData = await returnUserDetails(data[i]);
        news.push({
          projectData: projectData,
          userData: userData,
          actualRatingData: data[i],
        });
      }
      let updatedNews = [];
      for (let i = 0; i < news.length; i++) {
        if (
          isEmptyObject(news[i].projectData) ||
          isEmptyObject(news[i].userData)
        ) {
        } else {
          updatedNews.push(news[i]);
        }
      }
      setUpdatedRatingsData(updatedNews);
    };

    func();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setUpdatedRatingsData([]);
  };

  const productName = (comingData, id) => {
    let news = [];
    for (const key in comingData) {
      for (const key1 in comingData[key].item) {
        if (key1 === "objectId") {
          if (comingData[key].item[key1] === id) {
            news.push(comingData[key].item);
          }
        }
      }
    }
    return news[0]?.name;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={false}
      classes={{ paper: classes.paper }}
      style={{ margin: "-15px" }}
    >
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <img
          onMouseEnter={() => setWhichIconShouldBeVisible(true)}
          onMouseLeave={() => setWhichIconShouldBeVisible(false)}
          src={whichIconShouldBeVisible ? Close : Group}
          className={classes.icon}
          onClick={() => {
            setOpen(false);
          }}
        />
      </div>
      <div className={classes.rootContainer}>
        <div className={classes.heading}>
          <h4>
            {isEnglish
              ? "Reviews from your neighbours"
              : "Bewertungen von deinen Nachbarn"}
          </h4>
          <div className={classes.reviews}>
            <Rate disabled={true} defaultValue={averageRating} />
            <h5>
              {isEnglish
                ? `${data?.length} reviews`
                : `${data?.length} Bewertungen`}
            </h5>
          </div>
        </div>
        {updatedRatingData?.length > 0 ? (
          <ShowMore items={updatedRatingData} replace={true} by={3}>
            {({ current, onMore }) => (
              <React.Fragment>
                {current.map((data) => (
                  <Card elevation={2} className={classes.rootContainer1}>
                    <div className={classes.showRating}>
                      <div className={classes.insideRating1}>
                        {data.userData.img_url ? (
                          <Avatar
                            src={data.userData.img_url}
                            style={{ objectFit: "cover" }}
                            size="large"
                          />
                        ) : (
                          <Avatar
                            style={{
                              color: "#f56a00",
                              backgroundColor: "#fde3cf",
                              objectFit: "cover",
                            }}
                            size="large"
                          >
                            {data.actualRatingData.email[0]}
                          </Avatar>
                        )}
                        <img
                          className={classes.imgName}
                          src={data.projectData.projectImgUrl[0]}
                          alt=""
                        />
                      </div>
                      <div className={classes.insideRating2}>
                        <h5>"{data.actualRatingData.comment}"</h5>
                        <div className={classes.bottom}>
                          <h2>
                            {data.projectData.project_details.address.complete}
                          </h2>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                width: "87%",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <h3>
                                {data.projectData.updatedData.roof_material}
                              </h3>
                              <h3>
                                {data.projectData.updatedData.roof_orientation}
                              </h3>
                              <h3>{data.projectData.updatedData.roof_tilt}</h3>
                              <h3>{data.projectData.updatedData.roof_type}</h3>
                            </div>
                            <h3>
                              {
                                data.projectData.project_details.components
                                  .items.solar_panel.item.name
                              }
                              :-{" "}
                              {
                                data.projectData.project_details.components
                                  .items.solar_panel.quantity
                              }{" "}
                              panels
                            </h3>
                            <h3>
                              {
                                data.projectData.project_details.components
                                  .items.inverter.item.name
                              }
                            </h3>
                            <h3>
                              {data?.projectData?.project_details?.components
                                ?.items?.heatpump?.item
                                ? data.projectData.project_details.components
                                    .items.heatpump.item.name
                                : ""}
                            </h3>
                            <h3>
                              {data?.projectData?.project_details?.components
                                ?.items?.battery?.item
                                ? data.projectData.project_details.components
                                    .items.battery.item.name
                                : ""}
                            </h3>
                            <h3>
                              {data?.projectData?.project_details?.components
                                ?.items?.wallbox?.item
                                ? data.projectData.project_details.components
                                    .items.wallbox.item.name
                                : ""}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className={classes.insideRating3}>
                        <div
                          style={{
                            position: "relative",
                            height: "70px",
                            bottom: "11px",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "31px",
                              left: "-8px",
                              color: "#fbda61",
                              fontSize: "21px",
                            }}
                          >
                            {data.actualRatingData.rating.toFixed(1)}
                          </span>
                          <CircularProgress
                            style={{
                              color: "#fbda61",
                              marginBottom: "30px",
                              width: "53px",
                              height: "93px",
                            }}
                            variant="determinate"
                            value={data.actualRatingData.rating * 2 * 10}
                          />
                        </div>
                        <h4>
                          {parseInt(
                            data.projectData.energysDetails.total_production /
                              1000
                          )}{" "}
                          kwp
                        </h4>
                        <Button
                          variant="outlined"
                          className={classes.button}
                          startIcon={
                            <img src={maleTelemarketer} alt="profile" />
                          }
                        >
                          Video call
                        </Button>
                      </div>
                    </div>
                  </Card>
                ))}
                {onMore ? (
                  <Button
                    variant="outlined"
                    className={classes.button2}
                    style={{ backgroundColor: "transparent" }}
                    disabled={!onMore}
                    endIcon={
                      <img
                        style={{ width: "8px" }}
                        src={Polygon}
                        alt="profile"
                      />
                    }
                    onClick={() => {
                      if (!!onMore) {
                        onMore();
                      }
                    }}
                  >
                    Show more
                  </Button>
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          </ShowMore>
        ) : (
          <Box
            sx={{
              display: "flex",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{
                color: "#fbda61",
              }}
            />
          </Box>
        )}
      </div>
    </Dialog>
  );
};

export default FeedbackPopup;
