import { getNumber } from "./costs";

//Calculated Consumption B2C

export const calculated_consumption = (consumption_details) => {
  if (!consumption_details.entered_consumption) {
    const {
      building_type,
      people,
      yearly_kms,
      isWaterHeating,
      electroMobilityType,
      isHeatPump,
    } = consumption_details;
    const calculated_consumption =
      consumption_based_on_building(building_type, people, isWaterHeating) +
      electro_mobility_cost(electroMobilityType, yearly_kms) +
      heat_pump_cost(isHeatPump);
    return calculated_consumption;
  } else {
    return Math.round(getNumber(consumption_details?.entered_consumption));
  }
};

const consumption_based_on_building = (
  building_type,
  people,
  isWaterHeating
) => {
  const consumption_details = {
    single: [
      [1750, 2750],
      [3600, 4400],
      [4300, 5600],
      [5000, 6200],
      [5500, 6700],
    ],
    multi: [
      [1600, 2300],
      [2500, 3500],
      [2900, 4500],
      [3800, 5500],
      [4300, 6300],
    ],
  };
  return consumption_details[building_type][people - 1][isWaterHeating ? 1 : 0];
};

const electro_mobility_cost = (electroMobilityType, yearly_kms) => {
  const elecro_mobility_cost_details = {
    "type 1": true,
    "type 2": true,
    "type 3": false,
  };

  return elecro_mobility_cost_details[electroMobilityType]
    ? yearly_kms * 0.18
    : 0;
};

const heat_pump_cost = (isHeatpump) => {
  return isHeatpump ? 3000 : 0;
};

export const consumption_profiles = {
  1: {
    name: ["Home Office - all day high consumption", "ganztägiger Verbrauch"],
    self_supply: 0.6,
    short_title: ["home office", "ganztägiger Verbrauch"],
  },
  2: {
    name: ["consumption in the morning and evening", "eher morgens und abends"],
    self_supply: 0.3,
    short_title: ["Morning and evening", "Morgens und abends"],
  },
  3: {
    name: ["only in the evening", "nur abends"],
    self_supply: 0.25,
    short_title: ["Only evening", "nur abends"],
  },
  4: {
    name: ["Multi family house", "Mehrfamilienhaus"],
    self_supply: 0.6,
    short_title: ["Multi family house", "Mehrfamilienhaus"],
  },
  5: {
    name: [
      "Family with children in schooling age",
      "FAMILIE MIT KINDERN IM SCHULPFLICHTIGEN ALTER",
    ],
    self_supply: 0.5,
    short_title: ["Family w. children", "Familie m. Kindern"],
  },
  6: {
    name: [
      "Family with small children",
      "FAMILIE MIT KLEINKINDERN ODER KINDER IM VORSCHULALTER",
    ],
    self_supply: 0.55,
    short_title: ["Family w. small children", "Familie m. kleinen Kindern"],
  },
  7: {
    name: [
      "Single or two person household",
      "EIN- ODER ZWEI-PERSONEN-HAUSHALT",
    ],
    self_supply: 0.55,
    short_title: [
      "Single / two Person household",
      "Einzel/ Zweipersonenhaushalt",
    ],
  },
  8: {
    name: [
      "Home Office / Retired home",
      "PERSONEN IM RUHESTAND (RENTE) ODER AUS DEM HOMEOFFICE ARBEITENDE PERSONEN",
    ],
    self_supply: 0.55,
    short_title: ["Home Office", "Heimbüro"],
  },
  9: {
    name: [
      "High energy consumption at night",
      "VERBRAUCH KONZENTRIERT SICH AUF DIE NACHT",
    ],
    self_supply: 0.55,
    short_title: ["High conusmption at night", "Hoher Verbrauch in der Nacht"],
  },
};

//Calculated Consumption B2B

export const default_consumption_details = {
  Office: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Manufacturer: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  Service: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Restaurant: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  Administration: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  University: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  School: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Shopping: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
  Hotel: {
    default: 1000,
    no_of_employees: 10,
    kwa_per_employee: 100,
  },
  Other: {
    default: 2000,
    no_of_employees: 50,
    kwa_per_employee: 150,
  },
};

export const calculated_consumption_b2b = (consumption_details_b2b) => {
  if (consumption_details_b2b?.entered_consumption === null) {
    const calculated_consumption =
      consumption_based_on_business(consumption_details_b2b) +
      consumption_based_on_employees(consumption_details_b2b) +
      electro_mobility_cost_b2b(consumption_details_b2b) +
      heat_pump_cost_b2b(consumption_details_b2b);
    return calculated_consumption;
  } else {
    return Math.round(getNumber(consumption_details_b2b?.entered_consumption));
  }
};

const consumption_based_on_business = (consumption_details_b2b) => {
  return default_consumption_details[consumption_details_b2b.business_type]
    .default;
};

const consumption_based_on_employees = (consumption_details_b2b) => {
  const { no_of_employees, kwa_per_employee } = consumption_details_b2b;
  return no_of_employees * kwa_per_employee;
};

const electro_mobility_cost_b2b = (consumption_details_b2b) => {
  const { electroMobilityType, yearly_kms, no_of_cars, kwa_per_100km } =
    consumption_details_b2b;
  const elecro_mobility_cost_details = {
    "type 1": true,
    "type 2": true,
    "type 3": false,
  };
  return elecro_mobility_cost_details[electroMobilityType]
    ? (yearly_kms * no_of_cars * kwa_per_100km) / 100
    : 0;
};

const heat_pump_cost_b2b = (consumption_details_b2b) => {
  const { heatPumpType, surface_area, kwa_per_surface_area } =
    consumption_details_b2b;
  const heat_pump_cost_details = {
    "type 1": true,
    "type 2": true,
    "type 3": false,
  };
  return heat_pump_cost_details[heatPumpType]
    ? surface_area * kwa_per_surface_area
    : 0;
};

export const consumption_profiles_b2b = {
  1: {
    name: [
      "Only during working hours 80% self supply",
      "Nur während der Arbeitszeit 80% Eigenversorgung",
    ],
    self_supply: 0.8,
    short_title: ["Only during working hours", "Nur während der Arbeitszeit"],
  },
  2: {
    name: [
      "Consumption in the morning and in the evening 40% self supply",
      "Verbrauch morgens und abends 40% Eigenversorgung",
    ],
    self_supply: 0.4,
    short_title: ["Morning and in the evening", "Morgens und abends"],
  },
  3: {
    name: [
      "All day continuous 80% self supply",
      "Ganztägig kontinuierliche 80% Eigenversorgung",
    ],
    self_supply: 0.8,
    short_title: ["All day continuous", "Den ganzen Tag durchgehend"],
  },
  4: {
    name: [
      "High consumption at night 70% self supply",
      "Hoher Verbrauch in der Nacht 70% Eigenversorgung",
    ],
    self_supply: 0.7,
    short_title: ["High consumption at night", "Hoher Verbrauch in der Nacht"],
  },
  5: {
    name: [
      "Only during night times 20% self supply",
      "Nur nachts 20% Selbstversorgung",
    ],
    self_supply: 0.2,
    short_title: ["Only during night", "Nur nachts"],
  },
  6: {
    name: [
      "Low electricity usage 10% self supply",
      "Geringer Stromverbrauch 10% Eigenversorgung",
    ],
    self_supply: 0.1,
    short_title: ["Only during working hours", "Geringer Stromverbrauch"],
  },
};
