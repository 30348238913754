import { db } from "../../../../firebase";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import search from "./assets/search.svg";
import { CircularProgress, Box } from "@material-ui/core";
import CreateTopicModal from "./home/partials/CreateTopicModal";
import { useSelector } from "react-redux";

const MainContainer = styled.div`
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background: #f9f9f9;
  height: 100%;
  @media (max-width: 600px) {
    padding: 20px 0px;
    gap: 24px;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    color: #28334a;
    @media (max-width: 600px) {
      font-size: 21px;
      text-align: center;
    }
  }

  .search-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    @media (max-width: 600px) {
      gap: 8px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .search-bar {
      position: relative;
      width: 500px;
      @media (max-width: 600px) {
        width: 100%;
      }
      height: 45px;
      .search-icon {
        position: absolute;
        top: 45%;
        left: 20px;
        transform: translateY(-50%);
        @media (max-width: 600px) {
          top: 53%;
        }
      }
      input {
        border: 1px solid #2d4764;
        border-radius: 59px;
        @media (max-width: 600px) {
          font-size: 15px;
          padding-left: 44px;
        }
        padding-left: 50px;
        background-color: transparent;
        resize: none;
        outline: none;
        height: 45px;
        width: 100%;
      }
    }
    .new-topic {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 131px;
      height: 42px;
      background: #ffd75d;
      border-radius: 45px;
      font-weight: 500;
      font-size: 15px;
      color: #2d4764;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }

  .container-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: auto;
    @media (max-width: 600px) {
      overflow-x: hidden;
      width: 109%;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 500px;
      @media (max-width: 600px) {
        overflow-x: hidden;
        width: 95%;
      }
      gap: 20px;
      padding: 20px 0px;
      margin: 5%;
      box-shadow: 0px 4px 4px #d8d8d8;
      border-radius: 10px;
      margin-top: 0;
      background: white;
      height: max-content;

      .sub-container {
        display: flex;
        flex-direction: column;
        @media (max-width: 600px) {
          width: 95%;
        }
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        .title {
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          color: #2d4764;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
        .tags {
          display: flex;
          align-items: center;
          gap: 8px;
          transform: translateY(-10px);
          span {
            color: #c0c0c0;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }

      .null {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #2d4764;
      }
    }
  }
  .important {
    @media (max-width: 600px) {
      width: 93vw !important;
      border-radius: 7px;
    }
  }
`;

const KnowledgeHub = () => {
  const [videos, setvideos] = useState(null);
  const [searchData, setsearchData] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [searchText, setsearchText] = useState("");
  const isEnglish = useSelector((state) => state.global.isEnglish);

  useEffect(() => {
    const knowledgehub = db.collection("knowledgehub");
    knowledgehub.get().then((snapshots) => {
      const videos_temp = [];
      snapshots.forEach((doc) => {
        videos_temp.push(doc.data());
      });
      setvideos(videos_temp);
    });
  }, []);

  useEffect(() => {
    if (searchText != "" && videos) {
      const new_data = [];
      for (let i = 0; i < videos.length; i++) {
        if (
          videos[i].title
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()) ||
          videos[i].tags.join(" ").includes(searchText.toLocaleLowerCase())
        ) {
          new_data.push(videos[i]);
        }
      }
      console.log("new data", new_data);
      if (new_data.length > 0) {
        setsearchData(new_data);
      } else {
        setsearchData(null);
      }
    }
  }, [searchText]);

  return (
    <MainContainer>
      <div className="title">SOLAR KNOWLEDGE HUB</div>
      <div className="search-container">
        <div className="search-bar">
          <div className="search-icon">
            <img src={search} alt="" />
          </div>
          <div>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              placeholder={
                window.innerWidth > 500
                  ? isEnglish
                    ? "Search the knowledge hub"
                    : "Durchsuche das Solarwissen"
                  : isEnglish
                  ? "Search the knowledge hub"
                  : "Durchsuche das Solarwissen"
              }
            />
          </div>
        </div>
        <div className="new-topic" onClick={() => setOpen1(true)}>
          + New topic
        </div>
      </div>
      <div className="container-wrapper">
        <div className="container">
          {searchText != "" ? (
            searchData ? (
              searchData.map((video) => {
                return (
                  <div className="sub-container">
                    <div className="title">{video.title}</div>
                    <div className="tags">
                      {video.tags.map((tag) => (
                        <span>#{tag}</span>
                      ))}
                    </div>
                    <div className="youtube-iframe">
                      <iframe
                        width="560"
                        height="315"
                        //   src={video.link}
                        src={`https://www.youtube.com/embed/${video.id}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="null">NO VIDEOS FOUND</div>
            )
          ) : videos ? (
            videos.map((video) => {
              return (
                <div className="sub-container">
                  <div className="title">{video.title}</div>
                  <div className="tags">
                    {video.tags.map((tag) => (
                      <span>#{tag}</span>
                    ))}
                  </div>
                  <div className="youtube-iframe">
                    <iframe
                      width="560"
                      height="315"
                      className="important"
                      //   src={video.link}
                      src={`https://www.youtube.com/embed/${video.id}`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  color: "#fbda61",
                }}
              />
            </Box>
          )}
        </div>
      </div>
      <CreateTopicModal setOpen={setOpen1} open={open1} />
    </MainContainer>
  );
};

export default KnowledgeHub;
