export const quotes = [
  {
    sl_no: 1,
    Responsible: "I.-S. Bohlmann",
    Designation: "Freibad Kiosk",
    addr1: "Forellenweg 31",
    crn: "2ON-F9A-PHN",
    qrn: "627ba008c0fbbf4a6f4e58da",
    bill_type: "annual",
    city: "K√∂nigstein",
    incr_bill_rate: 0.2291942726,
    last_update: 1652270127837,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Taunus",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 10,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00610_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 23,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 9085,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Kostal Solar Electric",
    "inverterDetails.0.inverter_model": "Piko 10",
    "inverterDetails.0.inverter_model_number": "10kW 3-Phase Inverter",
    "inverterDetails.0.inverter_model_number_value": 10000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 10,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.18259669,
    "backgroundImageBound.south": 8.452921266,
    "backgroundImageBound.west": 50.18288996,
    "backgroundImageBound.north": 8.453886861,
    "backgroundImageBound.east": "SOLARHUB00610_1_background.png",
    backgroundImageId: 0,
    exportYear1: 757.6322136,
    "monthly_post_solar_consumption.0": 654.288414,
    "monthly_post_solar_consumption.1": 439.6260223,
    "monthly_post_solar_consumption.2": 253.7915344,
    "monthly_post_solar_consumption.3": 74.97872925,
    "monthly_post_solar_consumption.4": 105.2788696,
    "monthly_post_solar_consumption.5": -14.47970581,
    "monthly_post_solar_consumption.6": 149.179718,
    "monthly_post_solar_consumption.7": 397.7939911,
    "monthly_post_solar_consumption.8": 569.9921875,
    "monthly_post_solar_consumption.9": 741.4018593,
    "monthly_post_solar_consumption.10": 773.4794235,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.21,
    avoidedCost: 54,
    energy_replacement: 0,
    importYear1: 1211,
    new_annual_electric_bill: 614,
    productionRatio: [
      115.3677864074707, 218.7115859985352, 433.3739776611328,
      619.2084655761719, 798.0212707519531, 767.7211303710938,
      887.4797058105469, 723.8202819824219, 475.2060089111328, 303.0078125,
      131.59814071655273, 99.52057647705078,
    ],
    solarGeneration_Monthly: 5574,
    solar_energy_generation: 1.750754714,
    solrad_annual: 0.4019213617,
    "solrad_monthly.0": 0.7007734179,
    "solrad_monthly.1": 1.385470867,
    "solrad_monthly.2": 2.311134577,
    "solrad_monthly.3": 3.136403322,
    "solrad_monthly.4": 3.291716576,
    "solrad_monthly.5": 3.624714375,
    "solrad_monthly.6": 2.780459642,
    "solrad_monthly.7": 1.654926419,
    "solrad_monthly.8": 0.9299015403,
    "solrad_monthly.9": 0.4809989035,
    "solrad_monthly.10": 0.3106349707,
    "solrad_monthly.11": 50,
    utilityBillReplacement: 4088,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "9085 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 9,085",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Kostal Solar Electric - Piko 10",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "9085 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 4,542.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "9085 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 6,813.75",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.14,
    "costAdderDetails.itemDetails.3.price": "9085 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 1,271.9",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 7000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 7,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 13627,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 13627,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 13627,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 573.665528,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 13627,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.1,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 573.665528,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 13627,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.1,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.1,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 42976,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 2,
    Responsible: "I.-S. Bohlmann",
    Designation: "Stadthalle",
    addr1: "Bischof-Kaller-Straße 3",
    crn: "JR3-R38-G7F",
    qrn: "627ba5a559300d0e2a808d95",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652271223795,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 150,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00611_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 416,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 164320,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 3,
    "inverterDetails.0.inverter_quantity": 150,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17768891,
    "backgroundImageBound.south": 8.471727173,
    "backgroundImageBound.west": 50.17886209,
    "backgroundImageBound.north": 8.475589554,
    "backgroundImageBound.east": "SOLARHUB00611_1_background.png",
    backgroundImageId: 124295.0646,
    exportYear1: -1580.910034,
    "monthly_post_solar_consumption.0": -4067.619141,
    "monthly_post_solar_consumption.1": -9975.259766,
    "monthly_post_solar_consumption.2": -14541.45508,
    "monthly_post_solar_consumption.3": -18584.56641,
    "monthly_post_solar_consumption.4": -17243.25146,
    "monthly_post_solar_consumption.5": -20528.8916,
    "monthly_post_solar_consumption.6": -17025.44629,
    "monthly_post_solar_consumption.7": -11054.86377,
    "monthly_post_solar_consumption.8": -6540.624268,
    "monthly_post_solar_consumption.9": -2027.203796,
    "monthly_post_solar_consumption.10": -1124.972961,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.02,
    avoidedCost: 1287,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 821,
    productionRatio: [
      2453.910034179687, 4940.619140625, 10848.259765625, 15414.455078125,
      19457.56640625, 18116.25146484375, 21401.8916015625, 17898.4462890625,
      11927.86376953125, 7413.624267578125, 2900.2037963867188,
      1997.9729614257808,
    ],
    solarGeneration_Monthly: 134772,
    solar_energy_generation: 2.778441668,
    solrad_annual: 0.5990796685,
    "solrad_monthly.0": 1.297425866,
    "solrad_monthly.1": 2.565311193,
    "solrad_monthly.2": 3.776274443,
    "solrad_monthly.3": 4.73397398,
    "solrad_monthly.4": 4.56927824,
    "solrad_monthly.5": 5.319602489,
    "solrad_monthly.6": 4.444552898,
    "solrad_monthly.7": 2.990162611,
    "solrad_monthly.8": 1.815910339,
    "solrad_monthly.9": 0.7259374857,
    "solrad_monthly.10": 0.5037913918,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 73944,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "164320 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 164,320",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "164320 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 82,160",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "164320 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 123,240",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "164320 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 19,718.4",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 246480,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 246480,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 246480,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 10376.24417,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 246480,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 10376.24417,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 246480,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": -139476,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 3,
    Responsible: "I.-S. Bohlmann",
    Designation: "Rathaus",
    addr1: "Burgweg 5",
    crn: "LAB-8SM-GHY",
    qrn: "627ba93ec0fbbf4a6f4e58fa",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652273619189,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 100,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00612_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 283,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 111785,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 2,
    "inverterDetails.0.inverter_quantity": 100,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.18219796,
    "backgroundImageBound.south": 8.463674787,
    "backgroundImageBound.west": 50.18249123,
    "backgroundImageBound.north": 8.464640382,
    "backgroundImageBound.east": "SOLARHUB00612_1_background.png",
    backgroundImageId: 74885.55061,
    exportYear1: -783.3474922,
    "monthly_post_solar_consumption.0": -2463.635086,
    "monthly_post_solar_consumption.1": -6037.608673,
    "monthly_post_solar_consumption.2": -8796.698792,
    "monthly_post_solar_consumption.3": -11292.76346,
    "monthly_post_solar_consumption.4": -10480.25003,
    "monthly_post_solar_consumption.5": -12521.40305,
    "monthly_post_solar_consumption.6": -10345.04031,
    "monthly_post_solar_consumption.7": -6636.698502,
    "monthly_post_solar_consumption.8": -3936.939354,
    "monthly_post_solar_consumption.9": -1035.177834,
    "monthly_post_solar_consumption.10": -555.9880314,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.03,
    avoidedCost: 815,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 764,
    productionRatio: [
      1656.3474922180176, 3336.6350860595703, 6910.608673095703,
      9669.698791503906, 12165.763458251953, 11353.250030517578,
      13394.403045654297, 11218.040313720703, 7509.698501586914,
      4809.939353942871, 1908.177833557129, 1428.988031387329,
    ],
    solarGeneration_Monthly: 85362,
    solar_energy_generation: 2.281716585,
    solrad_annual: 0.463742584,
    "solrad_monthly.0": 0.962575078,
    "solrad_monthly.1": 1.98655951,
    "solrad_monthly.2": 3.10477066,
    "solrad_monthly.3": 3.997953415,
    "solrad_monthly.4": 4.012701988,
    "solrad_monthly.5": 4.583814621,
    "solrad_monthly.6": 3.70816946,
    "solrad_monthly.7": 2.290127277,
    "solrad_monthly.8": 1.350866199,
    "solrad_monthly.9": 0.5622702241,
    "solrad_monthly.10": 0.3570471108,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 50303,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "111785 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 111,785",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "111785 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 55,892.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "111785 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 83,838.75",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "111785 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 13,414.2",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 167677,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 167677,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 167677,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 7058.818136,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 167677,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 7058.818136,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 167677,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "Oct 24, 2014",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": -60673,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 4,
    Responsible: "I.-S. Bohlmann",
    Designation: "HLZ/Feuerwehr",
    addr1: "Am Kaltenborn 3",
    crn: "FGC-OR0-U7S",
    qrn: "627bb34559300d0e2a808db7",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652352614130,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 100,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00613_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 241,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 95195,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 2,
    "inverterDetails.0.inverter_quantity": 100,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.1797467,
    "backgroundImageBound.south": 8.476597988,
    "backgroundImageBound.west": 50.18033327,
    "backgroundImageBound.north": 8.478529178,
    "backgroundImageBound.east": "SOLARHUB00613_1_background.png",
    backgroundImageId: 66166.39473,
    exportYear1: -733.6065826,
    "monthly_post_solar_consumption.0": -2506.5383,
    "monthly_post_solar_consumption.1": -5634.786133,
    "monthly_post_solar_consumption.2": -7703.202087,
    "monthly_post_solar_consumption.3": -9656.815186,
    "monthly_post_solar_consumption.4": -8820.284546,
    "monthly_post_solar_consumption.5": -10670.72192,
    "monthly_post_solar_consumption.6": -8998.710327,
    "monthly_post_solar_consumption.7": -6060.373047,
    "monthly_post_solar_consumption.8": -3830.136292,
    "monthly_post_solar_consumption.9": -954.3262329,
    "monthly_post_solar_consumption.10": -596.8940735,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.03,
    avoidedCost: 732,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 806,
    productionRatio: [
      1606.6065826416016, 3379.538299560547, 6507.7861328125, 8576.202087402344,
      10529.815185546875, 9693.284545898438, 11543.721923828125,
      9871.710327148438, 6933.373046875, 4703.136291503906, 1827.3262329101562,
      1469.8940734863281,
    ],
    solarGeneration_Monthly: 76643,
    solar_energy_generation: 3.006931543,
    solrad_annual: 0.6894225478,
    "solrad_monthly.0": 1.573722601,
    "solrad_monthly.1": 2.926796198,
    "solrad_monthly.2": 4.06825304,
    "solrad_monthly.3": 4.944757938,
    "solrad_monthly.4": 4.688844681,
    "solrad_monthly.5": 5.538435936,
    "solrad_monthly.6": 4.761600494,
    "solrad_monthly.7": 3.314445257,
    "solrad_monthly.8": 2.13464427,
    "solrad_monthly.9": 0.819580853,
    "solrad_monthly.10": 0.6226771474,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 42838,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "95195 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 95,195",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "95195 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 47,597.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "95195 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 71,396.25",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "95195 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 11,423.4",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 142792,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 142792,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 142792,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 6011.216561,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 142792,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 6011.216561,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 142792,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": -35788,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 5,
    Responsible: "I.-S. Bohlmann",
    Designation: "Heinrich Dorn Halle",
    addr1: "Am Hohlberg 15",
    crn: "U6V-F3Q-S84",
    qrn: "627ceab041e38b167f1f6de8",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652354525833,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 50,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00615_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 109,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 43055,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 50,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17397904,
    "backgroundImageBound.south": 8.448489602,
    "backgroundImageBound.west": 50.17427236,
    "backgroundImageBound.north": 8.449455198,
    "backgroundImageBound.east": "SOLARHUB00615_1_background.png",
    backgroundImageId: 27594.70408,
    exportYear1: 132.3148117,
    "monthly_post_solar_consumption.0": -718.7801514,
    "monthly_post_solar_consumption.1": -2370.617188,
    "monthly_post_solar_consumption.2": -3454.894226,
    "monthly_post_solar_consumption.3": -4414.797729,
    "monthly_post_solar_consumption.4": -3962.59729,
    "monthly_post_solar_consumption.5": -4925.136719,
    "monthly_post_solar_consumption.6": -4119.825806,
    "monthly_post_solar_consumption.7": -2573.959961,
    "monthly_post_solar_consumption.8": -1417.435547,
    "monthly_post_solar_consumption.9": 17.00157166,
    "monthly_post_solar_consumption.10": 214.0241547,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.06,
    avoidedCost: 364,
    energy_replacement: 0,
    importYear1: 235,
    new_annual_electric_bill: 885,
    productionRatio: [
      740.685188293457, 1591.780151367187, 3243.6171875, 4327.894226074219,
      5287.7977294921875, 4835.5972900390625, 5798.13671875, 4992.8258056640625,
      3446.9599609375, 2290.435546875, 855.9984283447266, 658.9758453369141,
    ],
    solarGeneration_Monthly: 38071,
    solar_energy_generation: 3.019212961,
    solrad_annual: 0.6925688386,
    "solrad_monthly.0": 1.589827299,
    "solrad_monthly.1": 2.94840312,
    "solrad_monthly.2": 4.085385323,
    "solrad_monthly.3": 4.954394817,
    "solrad_monthly.4": 4.698302746,
    "solrad_monthly.5": 5.553655624,
    "solrad_monthly.6": 4.78254509,
    "solrad_monthly.7": 3.324628592,
    "solrad_monthly.8": 2.151007175,
    "solrad_monthly.9": 0.822902441,
    "solrad_monthly.10": 0.6269346476,
    "solrad_monthly.11": 91,
    utilityBillReplacement: 19375,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "43055 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 43,055",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "43055 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 21,527.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "43055 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 32,291.25",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "43055 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 5,166.6",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 64582,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 64582,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 64582,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 2718.754468,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 64582,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 2718.754468,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 64582,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 38747,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 6,
    Responsible: "I.-S. Bohlmann",
    Designation: "Bügerhaus Falkenstein",
    addr1: "Scharderhohlweg 1",
    crn: "QLH-J6W-DQ8",
    qrn: "627cef0341e38b167f1f6def",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652356566969,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 100,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00616_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 248,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 97960,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 2,
    "inverterDetails.0.inverter_quantity": 100,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.19463434,
    "backgroundImageBound.south": 8.478526888,
    "backgroundImageBound.west": 50.19522073,
    "backgroundImageBound.north": 8.480458079,
    "backgroundImageBound.east": "SOLARHUB00616_1_background.png",
    backgroundImageId: 78539.0275,
    exportYear1: -924.1255341,
    "monthly_post_solar_consumption.0": -3043.449249,
    "monthly_post_solar_consumption.1": -6873.787048,
    "monthly_post_solar_consumption.2": -9211.590576,
    "monthly_post_solar_consumption.3": -11280.19153,
    "monthly_post_solar_consumption.4": -10172.53491,
    "monthly_post_solar_consumption.5": -12436.79578,
    "monthly_post_solar_consumption.6": -10729.98145,
    "monthly_post_solar_consumption.7": -7244.320313,
    "monthly_post_solar_consumption.8": -4665.992981,
    "monthly_post_solar_consumption.9": -1182.237152,
    "monthly_post_solar_consumption.10": -774.0209808,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.03,
    avoidedCost: 850,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 909,
    productionRatio: [
      1797.1255340576172, 3916.449249267578, 7746.787048339844,
      10084.590576171875, 12153.191528320312, 11045.534912109375,
      13309.795776367188, 11602.9814453125, 8117.3203125, 5538.992980957031,
      2055.2371520996094, 1647.020980834961,
    ],
    solarGeneration_Monthly: 89016,
    solar_energy_generation: 3.092256308,
    solrad_annual: 0.7248894572,
    "solrad_monthly.0": 1.701233625,
    "solrad_monthly.1": 3.083158016,
    "solrad_monthly.2": 4.177253723,
    "solrad_monthly.3": 4.998845577,
    "solrad_monthly.4": 4.708672047,
    "solrad_monthly.5": 5.600541592,
    "solrad_monthly.6": 4.880906105,
    "solrad_monthly.7": 3.431708097,
    "solrad_monthly.8": 2.272192955,
    "solrad_monthly.9": 0.8553668261,
    "solrad_monthly.10": 0.6723053455,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 44082,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "97960 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 97,960",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "97960 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 48,980",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "97960 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 73,470",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "97960 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 11,755.2",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 146940,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 146940,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 146940,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 6185.837872,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 146940,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 6185.837872,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 146940,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": -39936,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 7,
    Responsible: "I.-S. Bohlmann",
    Designation: "Betriebshof (Bauhof)",
    addr1: "Forenllenweg 1a",
    crn: "ET4-FV2-GVW",
    qrn: "627cf66959300d0e2a808ecf",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652357717579,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 100,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00617_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 230,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 90850,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 2,
    "inverterDetails.0.inverter_quantity": 100,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17843825,
    "backgroundImageBound.south": 8.464313027,
    "backgroundImageBound.west": 50.17902484,
    "backgroundImageBound.north": 8.466244217,
    "backgroundImageBound.east": "SOLARHUB00617_1_background.png",
    backgroundImageId: 59003.0856,
    exportYear1: -404.4485359,
    "monthly_post_solar_consumption.0": -1824.715141,
    "monthly_post_solar_consumption.1": -4833.511002,
    "monthly_post_solar_consumption.2": -7057.564941,
    "monthly_post_solar_consumption.3": -9018.376678,
    "monthly_post_solar_consumption.4": -8328.294373,
    "monthly_post_solar_consumption.5": -10039.06372,
    "monthly_post_solar_consumption.6": -8333.616516,
    "monthly_post_solar_consumption.7": -5280.263672,
    "monthly_post_solar_consumption.8": -3075.987946,
    "monthly_post_solar_consumption.9": -616.6084747,
    "monthly_post_solar_consumption.10": -190.6346016,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.03,
    avoidedCost: 664,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 765,
    productionRatio: [
      1277.4485359191895, 2697.7151412963867, 5706.511001586914,
      7930.56494140625, 9891.376678466797, 9201.294372558594,
      10912.063720703125, 9206.616516113281, 6153.263671875, 3948.9879455566397,
      1489.6084747314453, 1063.6346015930176,
    ],
    solarGeneration_Monthly: 69480,
    solar_energy_generation: 2.583157778,
    solrad_annual: 0.5787624717,
    "solrad_monthly.0": 1.234191775,
    "solrad_monthly.1": 2.390328646,
    "solrad_monthly.2": 3.491610765,
    "solrad_monthly.3": 4.390130997,
    "solrad_monthly.4": 4.182317257,
    "solrad_monthly.5": 4.883891582,
    "solrad_monthly.6": 4.087898731,
    "solrad_monthly.7": 2.846064568,
    "solrad_monthly.8": 1.720677376,
    "solrad_monthly.9": 0.6922302842,
    "solrad_monthly.10": 0.4997875392,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 40883,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "90850 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 90,850",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "90850 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 45,425",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "90850 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 68,137.5",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "90850 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 10,902",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 136275,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 136275,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 136275,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 5736.865769,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 136275,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 5736.865769,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 136275,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": -29271,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 8,
    Responsible: "I.-S. Bohlmann",
    Designation: "Stadtbibliothek/Bücherei",
    addr1: "Wiesbadener Str. 6",
    crn: "NFL-0H3-PGF",
    qrn: "627cfb4341e38b167f1f6e01",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652358792631,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 50,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00618_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 130,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 51350,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 50,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17724103,
    "backgroundImageBound.south": 8.469588203,
    "backgroundImageBound.west": 50.17782763,
    "backgroundImageBound.north": 8.471519393,
    "backgroundImageBound.east": "SOLARHUB00618_1_background.png",
    backgroundImageId: 36128.59753,
    exportYear1: -73.41900635,
    "monthly_post_solar_consumption.0": -1166.827332,
    "monthly_post_solar_consumption.1": -3168.236084,
    "monthly_post_solar_consumption.2": -4402.282227,
    "monthly_post_solar_consumption.3": -5499.96106,
    "monthly_post_solar_consumption.4": -4911.753906,
    "monthly_post_solar_consumption.5": -6095.286133,
    "monthly_post_solar_consumption.6": -5191.796997,
    "monthly_post_solar_consumption.7": -3394.026611,
    "monthly_post_solar_consumption.8": -2022.452576,
    "monthly_post_solar_consumption.9": -208.647644,
    "monthly_post_solar_consumption.10": 6.092041016,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.05,
    avoidedCost: 445,
    energy_replacement: 0,
    importYear1: 159,
    new_annual_electric_bill: 908,
    productionRatio: [
      946.4190063476562, 2039.8273315429688, 4041.236083984375, 5275.2822265625,
      6372.9610595703125, 5784.75390625, 6968.2861328125, 6064.7969970703125,
      4267.026611328124, 2895.4525756835938, 1081.6476440429688,
      866.9079589843749,
    ],
    solarGeneration_Monthly: 46605,
    solar_energy_generation: 3.084601641,
    solrad_annual: 0.7252984047,
    "solrad_monthly.0": 1.688053489,
    "solrad_monthly.1": 3.065370321,
    "solrad_monthly.2": 4.164494991,
    "solrad_monthly.3": 4.996508121,
    "solrad_monthly.4": 4.700261116,
    "solrad_monthly.5": 5.587339878,
    "solrad_monthly.6": 4.862151623,
    "solrad_monthly.7": 3.435652971,
    "solrad_monthly.8": 2.261983156,
    "solrad_monthly.9": 0.8557355404,
    "solrad_monthly.10": 0.6723752618,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 23108,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "51350 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 51,350",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "51350 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 25,675",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "51350 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 38,512.5",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "51350 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 6,162",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 77025,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 77025,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 77025,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 3242.576304,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 77025,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 3242.576304,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 77025,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 29931,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 9,
    Responsible: "I.-S. Bohlmann",
    Designation: "Kindertagesstätte",
    addr1: "Eppsteiner Str. 9",
    crn: "05B-CW6-17A",
    qrn: "627e312459300d0e2a808fbd",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652437824286,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 38,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00619_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 97,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 38315,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI 28TL-480",
    "inverterDetails.0.inverter_model_number":
      "28kVA 3-phase, transformerless utility interactive inverter",
    "inverterDetails.0.inverter_model_number_value": 28000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 28,
    "inverterDetails.0.utilized_capacity": "Kostal Solar Electric",
    "inverterDetails.1.inverter_model": "PLENTICORE plus 10",
    "inverterDetails.1.inverter_model_number": "10kW Inverter",
    "inverterDetails.1.inverter_model_number_value": 10000,
    "inverterDetails.1.PowerRating": 0,
    "inverterDetails.1.unitCost": 1,
    "inverterDetails.1.inverter_quantity": 10,
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17519407,
    "backgroundImageBound.south": 8.465704437,
    "backgroundImageBound.west": 50.17548738,
    "backgroundImageBound.north": 8.466670033,
    "backgroundImageBound.east": "SOLARHUB00619_1_background.png",
    backgroundImageId: 24381.00012,
    exportYear1: 166.4599609,
    "monthly_post_solar_consumption.0": -660.4025879,
    "monthly_post_solar_consumption.1": -2158.681396,
    "monthly_post_solar_consumption.2": -3074.052734,
    "monthly_post_solar_consumption.3": -3885.73291,
    "monthly_post_solar_consumption.4": -3448.719727,
    "monthly_post_solar_consumption.5": -4334.892578,
    "monthly_post_solar_consumption.6": -3666.440918,
    "monthly_post_solar_consumption.7": -2311.517822,
    "monthly_post_solar_consumption.8": -1297.489014,
    "monthly_post_solar_consumption.9": 65.53643799,
    "monthly_post_solar_consumption.10": 224.9331665,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.06,
    avoidedCost: 333,
    energy_replacement: 0,
    importYear1: 255,
    new_annual_electric_bill: 910,
    productionRatio: [
      706.5400390625, 1533.402587890625, 3031.681396484375, 3947.052734375,
      4758.73291015625, 4321.7197265625, 5207.892578125, 4539.44091796875,
      3184.517822265625, 2170.489013671875, 807.4635620117188,
      648.0668334960938,
    ],
    solarGeneration_Monthly: 34858,
    solar_energy_generation: 3.09354949,
    solrad_annual: 0.7266509533,
    "solrad_monthly.0": 1.701097608,
    "solrad_monthly.1": 3.082913876,
    "solrad_monthly.2": 4.177865982,
    "solrad_monthly.3": 5.002074718,
    "solrad_monthly.4": 4.708016396,
    "solrad_monthly.5": 5.599675179,
    "solrad_monthly.6": 4.879766941,
    "solrad_monthly.7": 3.438980579,
    "solrad_monthly.8": 2.27396059,
    "solrad_monthly.9": 0.857188642,
    "solrad_monthly.10": 0.6743990183,
    "solrad_monthly.11": 90,
    utilityBillReplacement: 17242,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "38315 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 38,315",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name":
      "Solectria Renewables - PVI 28TL-480 , Kostal Solar Electric - PLENTICORE plus 10",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "38315 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 19,157.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "38315 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 28,736.25",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "38315 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 4,597.8",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 57472,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 57472,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 57472,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 2419.439732,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 57472,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 2419.439732,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 57472,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 44903,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 10,
    Responsible: "I.-S. Bohlmann",
    Designation: "Clubheim 1.FC",
    addr1: "Falkensteiner Str. 26 A",
    crn: "BT3-0D6-F7T",
    qrn: "627e351c59300d0e2a808fc7",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652438868116,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 33.995,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00620_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 92,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 36340,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Fronius USA",
    "inverterDetails.0.inverter_model": "Fronius Symo 24.0-3 480",
    "inverterDetails.0.inverter_model_number":
      "23.995kW (480VAC) Utility interactive inverter",
    "inverterDetails.0.inverter_model_number_value": 23995,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 23.995,
    "inverterDetails.0.utilized_capacity": "FRONIUS",
    "inverterDetails.1.inverter_model": "FRONIUS SYMO 10.0-3M",
    "inverterDetails.1.inverter_model_number": "10kW Inverter",
    "inverterDetails.1.inverter_model_number_value": 10000,
    "inverterDetails.1.PowerRating": 0,
    "inverterDetails.1.unitCost": 1,
    "inverterDetails.1.inverter_quantity": 10,
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.18075122,
    "backgroundImageBound.south": 8.47632773,
    "backgroundImageBound.west": 50.1810445,
    "backgroundImageBound.north": 8.477293325,
    "backgroundImageBound.east": "SOLARHUB00620_1_background.png",
    backgroundImageId: 19822.09818,
    exportYear1: 311.3013306,
    "monthly_post_solar_consumption.0": -269.067627,
    "monthly_post_solar_consumption.1": -1593.265869,
    "monthly_post_solar_consumption.2": -2586.182129,
    "monthly_post_solar_consumption.3": -3467.449707,
    "monthly_post_solar_consumption.4": -3150.893799,
    "monthly_post_solar_consumption.5": -3894.455078,
    "monthly_post_solar_consumption.6": -3136.429932,
    "monthly_post_solar_consumption.7": -1827.226563,
    "monthly_post_solar_consumption.8": -827.1088867,
    "monthly_post_solar_consumption.9": 212.7496948,
    "monthly_post_solar_consumption.10": 405.9303894,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.07,
    avoidedCost: 290,
    energy_replacement: 0,
    importYear1: 357,
    new_annual_electric_bill: 834,
    productionRatio: [
      561.6986694335938, 1142.067626953125, 2466.265869140625, 3459.18212890625,
      4340.44970703125, 4023.893798828125, 4767.455078125, 4009.429931640625,
      2700.2265625, 1700.10888671875, 660.2503051757812, 467.0696105957031,
    ],
    solarGeneration_Monthly: 30299,
    solar_energy_generation: 2.837687254,
    solrad_annual: 0.6223160625,
    "solrad_monthly.0": 1.364649057,
    "solrad_monthly.1": 2.655142784,
    "solrad_monthly.2": 3.852069378,
    "solrad_monthly.3": 4.793002129,
    "solrad_monthly.4": 4.605522156,
    "solrad_monthly.5": 5.379841328,
    "solrad_monthly.6": 4.526517391,
    "solrad_monthly.7": 3.07431674,
    "solrad_monthly.8": 1.894988418,
    "solrad_monthly.9": 0.7502680421,
    "solrad_monthly.10": 0.5336132646,
    "solrad_monthly.11": 86,
    utilityBillReplacement: 16353,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "36340 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 36,340",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name":
      "Fronius USA - Fronius Symo 24.0-3 480 , FRONIUS - FRONIUS SYMO 10.0-3M",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "36340 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 18,170",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "36340 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 27,255",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "36340 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 4,360.8",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 54510,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 54510,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 54510,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 2294.746308,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 54510,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 2294.746308,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 54510,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 42996,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 12,
    Responsible: "I.-S. Bohlmann",
    Designation: "Vereinsheim SchneidhSG Blau Weiß",
    addr1: "Wiesbadener Str. 241a",
    crn: "BUP-2VD-IKA",
    qrn: "627e390e41e38b167f1f6f02",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652440675590,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 250,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00622_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 591,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 233445,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 5,
    "inverterDetails.0.inverter_quantity": 250,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17113693,
    "backgroundImageBound.south": 8.443922514,
    "backgroundImageBound.west": 50.1717236,
    "backgroundImageBound.north": 8.445853704,
    "backgroundImageBound.east": "SOLARHUB00622_1_background.png",
    backgroundImageId: 189310.3217,
    exportYear1: -2892.117096,
    "monthly_post_solar_consumption.0": -7063.137939,
    "monthly_post_solar_consumption.1": -15778.62891,
    "monthly_post_solar_consumption.2": -21909.83545,
    "monthly_post_solar_consumption.3": -27323.45947,
    "monthly_post_solar_consumption.4": -25137.57544,
    "monthly_post_solar_consumption.5": -30118.04199,
    "monthly_post_solar_consumption.6": -25497.00073,
    "monthly_post_solar_consumption.7": -16996.46045,
    "monthly_post_solar_consumption.8": -10714.51233,
    "monthly_post_solar_consumption.9": -3521.479614,
    "monthly_post_solar_consumption.10": -2358.072296,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.01,
    avoidedCost: 1908,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 856,
    productionRatio: [
      3765.1170959472656, 7936.137939453125, 16651.62890625, 22782.83544921875,
      28196.45947265625, 26010.575439453125, 30991.0419921875,
      26370.000732421875, 17869.46044921875, 11587.512329101562,
      4394.4796142578125, 3231.072296142578,
    ],
    solarGeneration_Monthly: 199787,
    solar_energy_generation: 3.018825293,
    solrad_annual: 0.6907256842,
    "solrad_monthly.0": 1.591577291,
    "solrad_monthly.1": 2.950828314,
    "solrad_monthly.2": 4.086326122,
    "solrad_monthly.3": 4.951385498,
    "solrad_monthly.4": 4.700030804,
    "solrad_monthly.5": 5.556031704,
    "solrad_monthly.6": 4.786002159,
    "solrad_monthly.7": 3.316741705,
    "solrad_monthly.8": 2.150455236,
    "solrad_monthly.9": 0.8210027814,
    "solrad_monthly.10": 0.6247943044,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 105050,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "233445 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 233,445",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "233445 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 116,722.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "233445 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 175,083.75",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "233445 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 28,013.4",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 350167,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 350167,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 350167,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 14741.22969,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 350167,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 14741.22969,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 350167,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": -243163,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 13,
    Responsible: "I.-S. Bohlmann",
    Designation: "Haus Michael/Asylunterkunft",
    addr1: "Bischof-Kaller-Straße 10",
    crn: "93C-KK4-AV9",
    qrn: "627e3fc141e38b167f1f6f09",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652441633016,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 50,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00623_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 123,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 48585,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 50,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17843014,
    "backgroundImageBound.south": 8.471789656,
    "backgroundImageBound.west": 50.17901673,
    "backgroundImageBound.north": 8.473720846,
    "backgroundImageBound.east": "SOLARHUB00623_1_background.png",
    backgroundImageId: 26800.88568,
    exportYear1: 178.7841797,
    "monthly_post_solar_consumption.0": -593.3937988,
    "monthly_post_solar_consumption.1": -2195.59375,
    "monthly_post_solar_consumption.2": -3375.693115,
    "monthly_post_solar_consumption.3": -4420.848877,
    "monthly_post_solar_consumption.4": -4053.210693,
    "monthly_post_solar_consumption.5": -4963.799316,
    "monthly_post_solar_consumption.6": -4055.460449,
    "monthly_post_solar_consumption.7": -2421.337769,
    "monthly_post_solar_consumption.8": -1255.527039,
    "monthly_post_solar_consumption.9": 65.63827515,
    "monthly_post_solar_consumption.10": 289.5566711,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.06,
    avoidedCost: 356,
    energy_replacement: 0,
    importYear1: 272,
    new_annual_electric_bill: 768,
    productionRatio: [
      694.2158203125, 1466.393798828125, 3068.59375, 4248.693115234375,
      5293.848876953125, 4926.210693359375, 5836.79931640625, 4928.46044921875,
      3294.337768554687, 2128.5270385742183, 807.3617248535156,
      583.4433288574219,
    ],
    solarGeneration_Monthly: 37277,
    solar_energy_generation: 2.84971714,
    solrad_annual: 0.6418565512,
    "solrad_monthly.0": 1.567018628,
    "solrad_monthly.1": 2.840751886,
    "solrad_monthly.2": 3.865094423,
    "solrad_monthly.3": 4.605172157,
    "solrad_monthly.4": 4.414984226,
    "solrad_monthly.5": 5.234072208,
    "solrad_monthly.6": 4.548773289,
    "solrad_monthly.7": 3.069926739,
    "solrad_monthly.8": 2.065701008,
    "solrad_monthly.9": 0.7615483999,
    "solrad_monthly.10": 0.5817058086,
    "solrad_monthly.11": 89,
    utilityBillReplacement: 21863,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "48585 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 48,585",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "48585 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 24,292.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "48585 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 36,438.75",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "48585 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 5,830.2",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 72877,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 72877,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 72877,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 3067.954993,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 72877,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 3067.954993,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 72877,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 28686,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 14,
    Responsible: "I.-S. Bohlmann",
    Designation: "Kinderhort - Mietobjekte? (Hort/Jugendhaus?)",
    addr1: "Klosterstr. 13",
    crn: "5W4-CB0-NRA",
    qrn: "627e4626c0fbbf4a6f4e5b50",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652443728714,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 10,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00624_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 31,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 12245,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Kostal Solar Electric",
    "inverterDetails.0.inverter_model": "PLENTICORE plus 10",
    "inverterDetails.0.inverter_model_number": "10kW Inverter",
    "inverterDetails.0.inverter_model_number_value": 10000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 10,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.18335184,
    "backgroundImageBound.south": 8.467574929,
    "backgroundImageBound.west": 50.1836451,
    "backgroundImageBound.north": 8.468540524,
    "backgroundImageBound.east": "SOLARHUB00624_1_background.png",
    backgroundImageId: 0,
    exportYear1: 657.5941277,
    "monthly_post_solar_consumption.0": 397.2843704,
    "monthly_post_solar_consumption.1": 7.425918579,
    "monthly_post_solar_consumption.2": -215.7035675,
    "monthly_post_solar_consumption.3": -419.0013275,
    "monthly_post_solar_consumption.4": -310.1211548,
    "monthly_post_solar_consumption.5": -544.1112366,
    "monthly_post_solar_consumption.6": -373.9595337,
    "monthly_post_solar_consumption.7": -10.4565506,
    "monthly_post_solar_consumption.8": 238.6018066,
    "monthly_post_solar_consumption.9": 632.0970783,
    "monthly_post_solar_consumption.10": 667.2415924,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.17,
    avoidedCost: 94,
    energy_replacement: 0,
    importYear1: 715,
    new_annual_electric_bill: 797,
    productionRatio: [
      215.4058723449707, 475.7156295776367, 865.5740814208984,
      1088.7035675048828, 1292.0013275146484, 1183.1211547851562,
      1417.1112365722656, 1246.9595336914062, 883.4565505981445,
      634.398193359375, 240.9029216766357, 205.75840759277344,
    ],
    solarGeneration_Monthly: 9750,
    solar_energy_generation: 2.994287491,
    solrad_annual: 0.6787812114,
    "solrad_monthly.0": 1.566706657,
    "solrad_monthly.1": 2.917710304,
    "solrad_monthly.2": 4.056871414,
    "solrad_monthly.3": 4.923366547,
    "solrad_monthly.4": 4.687697411,
    "solrad_monthly.5": 5.53502512,
    "solrad_monthly.6": 4.75748539,
    "solrad_monthly.7": 3.271846533,
    "solrad_monthly.8": 2.117632866,
    "solrad_monthly.9": 0.80854249,
    "solrad_monthly.10": 0.6097787619,
    "solrad_monthly.11": 71,
    utilityBillReplacement: 5510,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "12245 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 12,245",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name":
      "Kostal Solar Electric - PLENTICORE plus 10",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "12245 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 6,122.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "12245 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 9,183.75",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "12245 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 1,469.4",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 18367,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 18367,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 18367,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 773.2086852,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 18367,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 773.2086852,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 18367,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 80284,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 15,
    Responsible: "I.-S. Bohlmann",
    Designation: "Wärmepumpe Friedhof Mammolshain",
    addr1: "Hardtgrundweg 0",
    crn: "J1J-1AL-BYM",
    qrn: "627e5e6059300d0e2a808ffe",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652449919718,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 60,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00625_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 171,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 67545,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 50,
    "inverterDetails.0.utilized_capacity": "FRONIUS",
    "inverterDetails.1.inverter_model": "FRONIUS SYMO 10.0-3M",
    "inverterDetails.1.inverter_model_number": "10kW Inverter",
    "inverterDetails.1.inverter_model_number_value": 10000,
    "inverterDetails.1.PowerRating": 0,
    "inverterDetails.1.unitCost": 1,
    "inverterDetails.1.inverter_quantity": 10,
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.1696025,
    "backgroundImageBound.south": 8.493650264,
    "backgroundImageBound.west": 50.1701892,
    "backgroundImageBound.north": 8.495581455,
    "backgroundImageBound.east": "SOLARHUB00625_1_background.png",
    backgroundImageId: 46322.79803,
    exportYear1: -191.5445251,
    "monthly_post_solar_consumption.0": -1335.072083,
    "monthly_post_solar_consumption.1": -3810.439819,
    "monthly_post_solar_consumption.2": -5606.865234,
    "monthly_post_solar_consumption.3": -7193.51709,
    "monthly_post_solar_consumption.4": -6599.708496,
    "monthly_post_solar_consumption.5": -8001.463623,
    "monthly_post_solar_consumption.6": -6637.930908,
    "monthly_post_solar_consumption.7": -4178.731934,
    "monthly_post_solar_consumption.8": -2369.415466,
    "monthly_post_solar_consumption.9": -372.0368347,
    "monthly_post_solar_consumption.10": -26.07202148,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.04,
    avoidedCost: 543,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 841,
    productionRatio: [
      1064.5445251464844, 2208.0720825195312, 4683.4398193359375,
      6479.865234375, 8066.51708984375, 7472.70849609375, 8874.463623046875,
      7510.930908203125, 5051.73193359375, 3242.4154663085938,
      1245.0368347167969, 899.072021484375,
    ],
    solarGeneration_Monthly: 56799,
    solar_energy_generation: 2.741742373,
    solrad_annual: 0.5772736073,
    "solrad_monthly.0": 1.284504652,
    "solrad_monthly.1": 2.539283037,
    "solrad_monthly.2": 3.735179186,
    "solrad_monthly.3": 4.667612076,
    "solrad_monthly.4": 4.535634518,
    "solrad_monthly.5": 5.281478405,
    "solrad_monthly.6": 4.413101196,
    "solrad_monthly.7": 2.902304411,
    "solrad_monthly.8": 1.781891584,
    "solrad_monthly.9": 0.7023674846,
    "solrad_monthly.10": 0.4802812636,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 30395,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "67545 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 67,545",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name":
      "Solectria Renewables - PVI50kW-480 , FRONIUS - FRONIUS SYMO 10.0-3M",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "67545 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 33,772.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "67545 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 50,658.75",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "67545 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 8,105.4",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 101317,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 101317,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 101317,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 4265.213936,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 101317,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 4265.213936,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 101317,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 5687,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 16,
    Responsible: "I.-S. Bohlmann",
    Designation: "KIGA Schneidhain",
    addr1: "Am Hohlberg 25",
    crn: "H9F-YIY-HRK",
    qrn: "627e6392c0fbbf4a6f4e5b75",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652450855482,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 78,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00626_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 186,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 73470,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 50,
    "inverterDetails.0.utilized_capacity": "Solectria Renewables",
    "inverterDetails.1.inverter_model": "PVI 28TL-480",
    "inverterDetails.1.inverter_model_number":
      "28kVA 3-phase, transformerless utility interactive inverter",
    "inverterDetails.1.inverter_model_number_value": 28000,
    "inverterDetails.1.PowerRating": 0,
    "inverterDetails.1.unitCost": 1,
    "inverterDetails.1.inverter_quantity": 28,
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17439175,
    "backgroundImageBound.south": 8.447597919,
    "backgroundImageBound.west": 50.17497838,
    "backgroundImageBound.north": 8.449529109,
    "backgroundImageBound.east": "SOLARHUB00626_1_background.png",
    backgroundImageId: 46996.60237,
    exportYear1: -295.7135925,
    "monthly_post_solar_consumption.0": -1535.636353,
    "monthly_post_solar_consumption.1": -3826.274414,
    "monthly_post_solar_consumption.2": -5557.677246,
    "monthly_post_solar_consumption.3": -7210.039551,
    "monthly_post_solar_consumption.4": -6656.929199,
    "monthly_post_solar_consumption.5": -8023.811523,
    "monthly_post_solar_consumption.6": -6580.667358,
    "monthly_post_solar_consumption.7": -4178.997803,
    "monthly_post_solar_consumption.8": -2499.046539,
    "monthly_post_solar_consumption.9": -467.1051788,
    "monthly_post_solar_consumption.10": -164.7036133,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.04,
    avoidedCost: 549,
    energy_replacement: 0,
    importYear1: 158,
    new_annual_electric_bill: 783,
    productionRatio: [
      1168.7135925292969, 2408.6363525390625, 4699.274414062501,
      6430.67724609375, 8083.03955078125, 7529.92919921875, 8896.8115234375,
      7453.6673583984375, 5051.997802734375, 3372.046539306641,
      1340.1051788330078, 1037.70361328125,
    ],
    solarGeneration_Monthly: 57473,
    solar_energy_generation: 3.199479342,
    solrad_annual: 0.8448200822,
    "solrad_monthly.0": 2.103639364,
    "solrad_monthly.1": 3.459071159,
    "solrad_monthly.2": 4.272869587,
    "solrad_monthly.3": 4.830153465,
    "solrad_monthly.4": 4.391793728,
    "solrad_monthly.5": 5.348189831,
    "solrad_monthly.6": 4.920621395,
    "solrad_monthly.7": 3.726184607,
    "solrad_monthly.8": 2.672758818,
    "solrad_monthly.9": 0.9676039219,
    "solrad_monthly.10": 0.8560441732,
    "solrad_monthly.11": 94,
    utilityBillReplacement: 33062,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "73470 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 73,470",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name":
      "Solectria Renewables - PVI50kW-480 , Solectria Renewables - PVI 28TL-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "73470 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 36,735",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "73470 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 55,102.5",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "73470 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 8,816.4",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 110205,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 110205,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 110205,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 4639.378404,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 110205,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 4639.378404,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 110205,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": -3201,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 17,
    Responsible: "",
    Designation: "Wertstoffhof",
    addr1: "Forellenweg 1",
    crn: "1AW-G0Q-B1X",
    qrn: "62833b8b41e38b167f1f72b6",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652768160712,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 50,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00627_2_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 102,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 40290,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 50,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.1786738,
    "backgroundImageBound.south": 8.465180067,
    "backgroundImageBound.west": 50.17896709,
    "backgroundImageBound.north": 8.466145662,
    "backgroundImageBound.east": "SOLARHUB00627_1_background.png",
    backgroundImageId: 21875.69595,
    exportYear1: 285.9573364,
    "monthly_post_solar_consumption.0": -327.5378113,
    "monthly_post_solar_consumption.1": -1738.218628,
    "monthly_post_solar_consumption.2": -2828.077515,
    "monthly_post_solar_consumption.3": -3787.728516,
    "monthly_post_solar_consumption.4": -3460.940674,
    "monthly_post_solar_consumption.5": -4264.713623,
    "monthly_post_solar_consumption.6": -3430.324341,
    "monthly_post_solar_consumption.7": -1980.147339,
    "monthly_post_solar_consumption.8": -909.6798096,
    "monthly_post_solar_consumption.9": 185.0703125,
    "monthly_post_solar_consumption.10": 380.6446533,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.06,
    avoidedCost: 309,
    energy_replacement: 0,
    importYear1: 340,
    new_annual_electric_bill: 803,
    productionRatio: [
      587.0426635742188, 1200.5378112792969, 2611.218627929687,
      3701.077514648438, 4660.728515625, 4333.940673828125, 5137.713623046875,
      4303.3243408203125, 2853.147338867188, 1782.6798095703125, 687.9296875,
      492.3553466796875,
    ],
    solarGeneration_Monthly: 32352,
    solar_energy_generation: 2.447899103,
    solrad_annual: 0.4772591293,
    "solrad_monthly.0": 0.967900157,
    "solrad_monthly.1": 2.101205111,
    "solrad_monthly.2": 3.349667549,
    "solrad_monthly.3": 4.358017445,
    "solrad_monthly.4": 4.311701298,
    "solrad_monthly.5": 4.941104889,
    "solrad_monthly.6": 3.983562231,
    "solrad_monthly.7": 2.521693707,
    "solrad_monthly.8": 1.410797954,
    "solrad_monthly.9": 0.5934978127,
    "solrad_monthly.10": 0.3583821654,
    "solrad_monthly.11": 86,
    utilityBillReplacement: 18131,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "40290 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 40,290",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "40290 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 20,145",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "40290 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 30,217.5",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "40290 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 4,834.8",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 60435,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 60435,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 60435,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 2544.175254,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 60435,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 2544.175254,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 60435,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "Oct 24, 2014",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 37883,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 18,
    Responsible: "",
    Designation: "Falkenstein Alter Friedhof",
    addr1: "Am Bergschlag 2",
    crn: "PP6-WN6-P8U",
    qrn: "62833e9341e38b167f1f72c4",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652769821022,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 50,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00628_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 133,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 52535,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Solectria Renewables",
    "inverterDetails.0.inverter_model": "PVI50kW-480",
    "inverterDetails.0.inverter_model_number":
      "50 kW 480Vac Commercial Grid-Tied Solar PV Inverter",
    "inverterDetails.0.inverter_model_number_value": 50000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 50,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.19570456,
    "backgroundImageBound.south": 8.486096566,
    "backgroundImageBound.west": 50.19629094,
    "backgroundImageBound.north": 8.488027757,
    "backgroundImageBound.east": "SOLARHUB00628_1_background.png",
    backgroundImageId: 30373.32279,
    exportYear1: 89.14943504,
    "monthly_post_solar_consumption.0": -762.124939,
    "monthly_post_solar_consumption.1": -2487.733231,
    "monthly_post_solar_consumption.2": -3765.333344,
    "monthly_post_solar_consumption.3": -4907.671646,
    "monthly_post_solar_consumption.4": -4507.718643,
    "monthly_post_solar_consumption.5": -5492.222137,
    "monthly_post_solar_consumption.6": -4500.774551,
    "monthly_post_solar_consumption.7": -2732.205322,
    "monthly_post_solar_consumption.8": -1469.565041,
    "monthly_post_solar_consumption.9": -33.27958488,
    "monthly_post_solar_consumption.10": 196.1562099,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.05,
    avoidedCost: 390,
    energy_replacement: 0,
    importYear1: 219,
    new_annual_electric_bill: 778,
    productionRatio: [
      783.850564956665, 1635.1249389648438, 3360.73323059082, 4638.333343505859,
      5780.671646118164, 5380.718643188477, 6365.222137451172,
      5373.774551391602, 3605.2053222656255, 2342.5650405883794,
      906.2795848846436, 676.8437900543213,
    ],
    solarGeneration_Monthly: 40850,
    solar_energy_generation: 3.024396896,
    solrad_annual: 0.7159230709,
    "solrad_monthly.0": 1.769443631,
    "solrad_monthly.1": 3.100992918,
    "solrad_monthly.2": 4.08466053,
    "solrad_monthly.3": 4.780683517,
    "solrad_monthly.4": 4.515882492,
    "solrad_monthly.5": 5.396174431,
    "solrad_monthly.6": 4.777804852,
    "solrad_monthly.7": 3.334982872,
    "solrad_monthly.8": 2.298277617,
    "solrad_monthly.9": 0.8394556642,
    "solrad_monthly.10": 0.678478837,
    "solrad_monthly.11": 91,
    utilityBillReplacement: 23641,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "52535 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 52,535",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name": "Solectria Renewables - PVI50kW-480",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "52535 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 26,267.5",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "52535 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 39,401.25",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "52535 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 6,304.2",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 78802,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 78802,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 78802,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 3317.383939,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 78802,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 3317.383939,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 78802,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 25291,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 19,
    Responsible: "I.-S. Bohlmann",
    Designation: "Clubheim Fc Mammolshain",
    addr1: "Am Hasensprung 0",
    crn: "H1H-HJ7-16C",
    qrn: "62834e28c0fbbf4a6f4e5ef8",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652772773025,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 20,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00629_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 56,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 22120,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Kostal Solar Electric",
    "inverterDetails.0.inverter_model": "PLENTICORE plus 10",
    "inverterDetails.0.inverter_model_number": "10kW Inverter",
    "inverterDetails.0.inverter_model_number_value": 10000,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 2,
    "inverterDetails.0.inverter_quantity": 20,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.17162379,
    "backgroundImageBound.south": 8.486318756,
    "backgroundImageBound.west": 50.17191713,
    "backgroundImageBound.north": 8.487284352,
    "backgroundImageBound.east": "SOLARHUB00629_1_background.png",
    backgroundImageId: 9203.392242,
    exportYear1: 484.4251862,
    "monthly_post_solar_consumption.0": 46.12060547,
    "monthly_post_solar_consumption.1": -804.4855957,
    "monthly_post_solar_consumption.2": -1361.208069,
    "monthly_post_solar_consumption.3": -1854.567993,
    "monthly_post_solar_consumption.4": -1623.481445,
    "monthly_post_solar_consumption.5": -2117.252075,
    "monthly_post_solar_consumption.6": -1703.792969,
    "monthly_post_solar_consumption.7": -907.2658691,
    "monthly_post_solar_consumption.8": -313.8818359,
    "monthly_post_solar_consumption.9": 425.1086578,
    "monthly_post_solar_consumption.10": 526.8891602,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.1,
    avoidedCost: 188,
    energy_replacement: 0,
    importYear1: 475,
    new_annual_electric_bill: 890,
    productionRatio: [
      388.57481384277344, 826.87939453125, 1677.485595703125,
      2234.2080688476567, 2727.567993164062, 2496.4814453125, 2990.252075195313,
      2576.79296875, 1780.265869140625, 1186.8818359375, 447.891342163086,
      346.11083984375,
    ],
    solarGeneration_Monthly: 19680,
    solar_energy_generation: 3.020573616,
    solrad_annual: 0.6924930811,
    "solrad_monthly.0": 1.592161298,
    "solrad_monthly.1": 2.951551199,
    "solrad_monthly.2": 4.087645054,
    "solrad_monthly.3": 4.954807281,
    "solrad_monthly.4": 4.699843884,
    "solrad_monthly.5": 5.55603838,
    "solrad_monthly.6": 4.785866261,
    "solrad_monthly.7": 3.323916435,
    "solrad_monthly.8": 2.152810574,
    "solrad_monthly.9": 0.8228336573,
    "solrad_monthly.10": 0.6269181371,
    "solrad_monthly.11": 81,
    utilityBillReplacement: 9954,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "22120 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 22,120",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name":
      "Kostal Solar Electric - PLENTICORE plus 10",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "22120 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 11,060",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "22120 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 16,590",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.12,
    "costAdderDetails.itemDetails.3.price": "22120 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 2,654.4",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 10000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 10,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 33180,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 33180,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 33180,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 1396.8021,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 33180,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 1396.8021,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 33180,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.07,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 58695,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
  {
    sl_no: 20,
    Responsible: "J. Hormann",
    Designation: "KUSI",
    addr1: "Hauptstr. 13",
    crn: "4BX-6J7-73J",
    qrn: "628350a141e38b167f1f72db",
    bill_type: "annual",
    city: "K√∂nigstein im Taunus",
    incr_bill_rate: 0.2291942726,
    last_update: 1652774203630,
    quote_type: "R",
    source_email: "harsha@enact-systems.com",
    state: "Hessen",
    version: "v2",
    zip: 1000,
    annul_electric_bill: 2400,
    current_annual_consumption: 10476,
    custGenUpload: "FALSE",
    custom_generation_format: "Enact Format",
    derate: 14,
    isNEM: "Enact Format",
    monitoring_system: "Envoy-Enlighten Monitoring",
    proposedSystemSizeAC: 5.5,
    source_fn: "Harsha",
    source_ln: "Yadav",
    utility_electric_rate_escalator: 5,
    GACC_solar_energy_generation: 0,
    array_type_name: "Fixed (Open Rack)",
    array_type_value: 0,
    combined_img: "SOLARHUB00631_1_combined.png",
    img_url:
      "https://maps.googleapis.com/maps/api/staticmap?center=25.13902763042579, 55.220383765081806&zoom=19&size=2048x2048&sensor=false&maptype=satellite&scale=4&key=AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc",
    module_datasheetURL: "NA",
    module_logoURL: "NA",
    module_model_number: "Black 395",
    module_quantity: 14,
    module_notes: "Meyer Burger",
    module_select: 1,
    module_type: 0.5,
    panelDegradation: 5530,
    pv_sysytem_size_dc: 1,
    selectedTool: 395,
    watts_per_module: 1.1,
    dc_ac_ratio: 96,
    inv_eff: "Kostal Solar Electric",
    "inverterDetails.0.inverter_model": "PLENTICORE plus 5.5",
    "inverterDetails.0.inverter_model_number": "5.5kW Inverter",
    "inverterDetails.0.inverter_model_number_value": 5500,
    "inverterDetails.0.PowerRating": 0,
    "inverterDetails.0.unitCost": 1,
    "inverterDetails.0.inverter_quantity": 5.5,
    "inverterDetails.0.utilized_capacity": "",
    "inverterDetails.1.inverter_model": "",
    "inverterDetails.1.inverter_model_number": "",
    "inverterDetails.1.inverter_model_number_value": "",
    "inverterDetails.1.PowerRating": "",
    "inverterDetails.1.unitCost": "",
    "inverterDetails.1.inverter_quantity": "",
    "inverterDetails.1.utilized_capacity": 60,
    dayTimeConsumption: 400,
    exportTariff: 50.18180833,
    "backgroundImageBound.south": 8.46592843,
    "backgroundImageBound.west": 50.18239488,
    "backgroundImageBound.north": 8.467859621,
    "backgroundImageBound.east": "SOLARHUB00631_1_background.png",
    backgroundImageId: 0,
    exportYear1: 788.4985771,
    "monthly_post_solar_consumption.0": 693.7356033,
    "monthly_post_solar_consumption.1": 498.1382599,
    "monthly_post_solar_consumption.2": 358.9375763,
    "monthly_post_solar_consumption.3": 236.4788513,
    "monthly_post_solar_consumption.4": 283.1466827,
    "monthly_post_solar_consumption.5": 171.5772705,
    "monthly_post_solar_consumption.6": 277.1793213,
    "monthly_post_solar_consumption.7": 471.528656,
    "monthly_post_solar_consumption.8": 612.2836685,
    "monthly_post_solar_consumption.9": 774.5470886,
    "monthly_post_solar_consumption.10": 800.9830742,
    "monthly_post_solar_consumption.11": 0,
    annualBatteryUse: 0.21,
    avoidedCost: 44,
    energy_replacement: 0,
    importYear1: 1436,
    new_annual_electric_bill: 816,
    productionRatio: [
      84.50142288208008, 179.26439666748047, 374.8617401123047,
      514.0624237060547, 636.5211486816406, 589.8533172607422,
      701.4227294921874, 595.8206787109375, 401.4713439941406,
      260.7163314819336, 98.45291137695312, 72.01692581176758,
    ],
    solarGeneration_Monthly: 4509,
    solar_energy_generation: 2.749896049,
    solrad_annual: 0.6029606462,
    "solrad_monthly.0": 1.298102975,
    "solrad_monthly.1": 2.545200586,
    "solrad_monthly.2": 3.727520704,
    "solrad_monthly.3": 4.675719738,
    "solrad_monthly.4": 4.483896255,
    "solrad_monthly.5": 5.230128765,
    "solrad_monthly.6": 4.37842083,
    "solrad_monthly.7": 2.997544527,
    "solrad_monthly.8": 1.816380143,
    "solrad_monthly.9": 0.7270612717,
    "solrad_monthly.10": 0.515815258,
    "solrad_monthly.11": 41,
    utilityBillReplacement: 2489,
    IncomeTaxRebate: {},
    batteryCostAdderDetails: "Residential Sample",
    "costAdderDetails.profileName": "Module",
    "costAdderDetails.itemDetails.0.name": "Meyer Burger - Black 395",
    "costAdderDetails.itemDetails.0.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.0.priceType": 1,
    "costAdderDetails.itemDetails.0.price": "5530 Watts",
    "costAdderDetails.itemDetails.0.quantity": "‚Ç¨ 5,530",
    "costAdderDetails.itemDetails.0.totalCost": "Inverter",
    "costAdderDetails.itemDetails.1.name":
      "Kostal Solar Electric - PLENTICORE plus 5.5",
    "costAdderDetails.itemDetails.1.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.1.priceType": 0.5,
    "costAdderDetails.itemDetails.1.price": "5530 Watts",
    "costAdderDetails.itemDetails.1.quantity": "‚Ç¨ 2,765",
    "costAdderDetails.itemDetails.1.totalCost": "BOS Hardware",
    "costAdderDetails.itemDetails.2.name": "",
    "costAdderDetails.itemDetails.2.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.2.priceType": 0.75,
    "costAdderDetails.itemDetails.2.price": "5530 Watts",
    "costAdderDetails.itemDetails.2.quantity": "‚Ç¨ 4,147.5",
    "costAdderDetails.itemDetails.2.totalCost": "Adder",
    "costAdderDetails.itemDetails.3.name": "",
    "costAdderDetails.itemDetails.3.description": "‚Ç¨/Watt",
    "costAdderDetails.itemDetails.3.priceType": 0.15,
    "costAdderDetails.itemDetails.3.price": "5530 Watts",
    "costAdderDetails.itemDetails.3.quantity": "‚Ç¨ 829.5",
    "costAdderDetails.itemDetails.3.totalCost": "Labor & Overheads",
    "costAdderDetails.itemDetails.4.name": "",
    "costAdderDetails.itemDetails.4.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.4.priceType": 5000,
    "costAdderDetails.itemDetails.4.price": "N/A",
    "costAdderDetails.itemDetails.4.quantity": "‚Ç¨ 5,000",
    "costAdderDetails.itemDetails.4.totalCost": "Permit/Engg",
    "costAdderDetails.itemDetails.5.name": "",
    "costAdderDetails.itemDetails.5.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.5.priceType": 500,
    "costAdderDetails.itemDetails.5.price": "N/A",
    "costAdderDetails.itemDetails.5.quantity": "‚Ç¨ 500",
    "costAdderDetails.itemDetails.5.totalCost": "S&M Allocation",
    "costAdderDetails.itemDetails.6.name": "",
    "costAdderDetails.itemDetails.6.description": "Fixed ‚Ç¨",
    "costAdderDetails.itemDetails.6.priceType": 2500,
    "costAdderDetails.itemDetails.6.price": "N/A",
    "costAdderDetails.itemDetails.6.quantity": "‚Ç¨ 2,500",
    "costAdderDetails.itemDetails.6.totalCost": "NA",
    emailTemplateId: 0,
    "finCost.capitalCost.cashDiscount.value": "Fixed",
    "finCost.capitalCost.cashDiscount.type": 0,
    "finCost.capitalCost.taxRate": 8295,
    "finCost.capitalCost.totalSystemCost": 0,
    "finCost.capitalCost.effectivePrice": 8295,
    "finCost.capitalCost.calculatedSystemCost": "FALSE",
    "finCost.capitalCost.stateSalesTax": 0,
    "finCost.capitalCost.stateSalesTaxAmount": 8295,
    "finCost.capitalCost.systemCost": 0,
    "finCost.capitalCost.salesTaxPercentage": "Residential Sample",
    "finCost.costData.profile": "TRUE",
    "finCost.financing.financingRequired.compareToCashOnly": "TRUE",
    "finCost.financing.financingRequired.fundOptionA": "TRUE",
    "finCost.financing.financingRequired.fundOptionB":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionA.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionA.bank.planID": "FALSE",
    "finCost.financing.fundOptionA.comboFinance": 0,
    "finCost.financing.fundOptionA.combo.amount": 12,
    "finCost.financing.fundOptionA.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionA.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.APR": 500,
    "finCost.financing.fundOptionA.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionA.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionA.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionA.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionA.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionA.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionA.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionA.bankOptions.apr": 500,
    "finCost.financing.fundOptionA.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionA.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionA.bankOptions.Term": 0,
    "finCost.financing.fundOptionA.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionA.srec.period": "LumpSum",
    "finCost.financing.fundOptionA.srec.name": 0,
    "finCost.financing.fundOptionA.srec.amount": 0,
    "finCost.financing.fundOptionA.downPayment": 349.2005251,
    "finCost.financing.fundOptionA.monthlyPayments": 0.2,
    "finCost.financing.fundOptionA.dollarPerKWh": 8295,
    "finCost.financing.fundOptionA.finAmount": "FALSE",
    "finCost.financing.fundOptionA.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionA.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionA.ITC": 0,
    "finCost.financing.fundOptionA.ITCAmount": 26,
    "finCost.financing.fundOptionA.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionA.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionA.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionA.lcoe": 0,
    "finCost.financing.fundOptionA.depSavings": 0,
    "finCost.financing.fundOptionA.intrSavings": 0,
    "finCost.financing.fundOptionA.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionA.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionA.splitTerm": 0,
    "finCost.financing.fundOptionA.totalPaydown": 0,
    "finCost.financing.fundOptionA.payDownPercentage": 0,
    "finCost.financing.fundOptionA.addnDownpay": "FALSE",
    "finCost.financing.fundOptionA.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionA.itc": 0,
    "finCost.financing.fundOptionA.itcamount": 26,
    "finCost.financing.fundOptionA.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionA.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionA.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionA.utilityRateEscalator":
      "INHOUSE01:SWK Bank B2C, 2y, .99%",
    "finCost.financing.fundOptionB.bank.name": "INHOUSE01",
    "finCost.financing.fundOptionB.bank.planID": "FALSE",
    "finCost.financing.fundOptionB.comboFinance": 0,
    "finCost.financing.fundOptionB.combo.amount": 12,
    "finCost.financing.fundOptionB.combo.termInMonth": "FALSE",
    "finCost.financing.fundOptionB.combo.usrCombo": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.APR": 500,
    "finCost.financing.fundOptionB.bankOptions.Loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.Loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.term": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.contractorFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.contractorFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.leaseFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.leaseFees.value": "Fixed",
    "finCost.financing.fundOptionB.bankOptions.customerFees.type": 0,
    "finCost.financing.fundOptionB.bankOptions.customerFees.value": "fixed",
    "finCost.financing.fundOptionB.bankOptions.interestOnly.terms": 0,
    "finCost.financing.fundOptionB.bankOptions.interestOnly.amount": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAPrice": 0,
    "finCost.financing.fundOptionB.bankOptions.PPAEscalator": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaprice": 0,
    "finCost.financing.fundOptionB.bankOptions.ppaescalator": 0.0099,
    "finCost.financing.fundOptionB.bankOptions.apr": 500,
    "finCost.financing.fundOptionB.bankOptions.loanminlimit": 25000000,
    "finCost.financing.fundOptionB.bankOptions.loanmaxlimit": 2,
    "finCost.financing.fundOptionB.bankOptions.Term": 0,
    "finCost.financing.fundOptionB.bankOptions.yearlyPayments.0": 25,
    "finCost.financing.fundOptionB.srec.period": "LumpSum",
    "finCost.financing.fundOptionB.srec.name": 0,
    "finCost.financing.fundOptionB.srec.amount": 0,
    "finCost.financing.fundOptionB.downPayment": 349.2005251,
    "finCost.financing.fundOptionB.monthlyPayments": 0.2,
    "finCost.financing.fundOptionB.dollarPerKWh": 8295,
    "finCost.financing.fundOptionB.finAmount": "FALSE",
    "finCost.financing.fundOptionB.PersonalMortgageInterestDeduction": "FALSE",
    "finCost.financing.fundOptionB.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionB.ITC": 0,
    "finCost.financing.fundOptionB.ITCAmount": 26,
    "finCost.financing.fundOptionB.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionB.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionB.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionB.lcoe": 0,
    "finCost.financing.fundOptionB.depSavings": 0,
    "finCost.financing.fundOptionB.intrSavings": 0,
    "finCost.financing.fundOptionB.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionB.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionB.splitTerm": 0,
    "finCost.financing.fundOptionB.totalPaydown": 0,
    "finCost.financing.fundOptionB.payDownPercentage": 0,
    "finCost.financing.fundOptionB.addnDownpay": "FALSE",
    "finCost.financing.fundOptionB.personalMortgageInterestDeduction": "TRUE",
    "finCost.financing.fundOptionB.itc": 0,
    "finCost.financing.fundOptionB.itcamount": 26,
    "finCost.financing.fundOptionB.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionB.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionB.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionB.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.comboFinance": 0,
    "finCost.financing.fundOptionC.srec.period": "LumpSum",
    "finCost.financing.fundOptionC.srec.name": 0,
    "finCost.financing.fundOptionC.srec.amount": 0,
    "finCost.financing.fundOptionC.downPayment": 0,
    "finCost.financing.fundOptionC.monthlyPayments": 0.2,
    "finCost.financing.fundOptionC.dollarPerKWh": 0,
    "finCost.financing.fundOptionC.finAmount": "FALSE",
    "finCost.financing.fundOptionC.showAddnlTaxSvgsOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.showAddnlBonusOrDepreciation": "FALSE",
    "finCost.financing.fundOptionC.ITC": 0,
    "finCost.financing.fundOptionC.ITCAmount": 26,
    "finCost.financing.fundOptionC.ITCPercentage.value": "FALSE",
    "finCost.financing.fundOptionC.ITCPercentage.includeFee": 1,
    "finCost.financing.fundOptionC.ITCPercentage.termInYear": 0.08,
    "finCost.financing.fundOptionC.lcoe": 0,
    "finCost.financing.fundOptionC.depSavings": 0,
    "finCost.financing.fundOptionC.intrSavings": 0,
    "finCost.financing.fundOptionC.initialMonthlyPayment": 0,
    "finCost.financing.fundOptionC.adjustedMonthlyPayment": 0,
    "finCost.financing.fundOptionC.splitTerm": 0,
    "finCost.financing.fundOptionC.totalPaydown": 0,
    "finCost.financing.fundOptionC.payDownPercentage": 0,
    "finCost.financing.fundOptionC.addnDownpay": "TRUE",
    "finCost.financing.fundOptionC.itc": 0,
    "finCost.financing.fundOptionC.itcamount": 26,
    "finCost.financing.fundOptionC.itcpercentage.value": "TRUE",
    "finCost.financing.fundOptionC.itcpercentage.includeFee": 1,
    "finCost.financing.fundOptionC.itcpercentage.termInYear": 5,
    "finCost.financing.fundOptionC.utilityRateEscalator": "FALSE",
    "finCost.financing.fundOptionC.PersonalMortgageInterestDeduction": "None",
    "finCost.incentiveAndStateTaxCredit.profileName": 1414186003403,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update":
      "60af6d0a1e5df656597a9c24",
    "finCost.incentiveAndStateTaxCredit.selectedCredits._id": "SOLARHUB",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.account_id":
      "ProdRebate",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateId": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_term": "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_tiers.2.maxValue": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_annual":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.0.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.1.maxValue":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.tierName": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_tiers.2.maxValue":
      [],
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_tiers": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourth_rebate_term":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_name": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_fixed_year": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fa_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fit_rebate_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_cap_amount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_term": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.sales_tax_cap_value": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_amount":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dc_rebate_cap_type":
      "DC",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.rebateAcOrDcType":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.other_rebate_name":
      "none",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feed_tariff_name":
      "Fixed",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxCapType":
      "$/Yr",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.salesTaxType": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.dollarPerWattYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalDollarPerWatt": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbiYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalPBI": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFeedTariff": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.stateTaxCredit": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.fourthRebateYearly.0": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFourthRebate": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.totalFixedAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.PBIRebateAmount": 0,
    "finCost.incentiveAndStateTaxCredit.selectedCredits.feedTariffRebateAmount":
      "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.last_update_date": "",
    "finCost.incentiveAndStateTaxCredit.selectedCredits.pbirebateAmount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_dc":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_fixed_amount":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_total_price":
      "FALSE",
    "finCost.incentiveAndStateTaxCredit.selectedPreFinance.pre_finance_other_rebate": 0,
    "finCost.incentiveAndStateTaxCredit.maxSavings": 0,
    "finCost.preFinanceIncentive": 37486,
    "finCost.maxSavings": 0,
    fitRebate: "",
    inverter_model: "",
    inverter_model_number_value: "Margin Adjustment",
    "marginDetails.name": "",
    "marginDetails.description": "Fixed ‚Ç¨",
    "marginDetails.priceType": "N/A",
    "marginDetails.price": 0,
    "marginDetails.quantity": "‚Ç¨ 0",
    "marginDetails.totalCost": "TRUE",
    "marginDetails.isChecked": "Residential Sample",
    "operationAndMaintenanceDetails.profileName": [],
    "operationAndMaintenanceDetails.itemDetails": "",
    postPVName: 0,
    rebate: "NA",
    savings25B: 0,
    "selectedCostAdder.0": 1,
    "selectedCostAdder.1": [],
    srecAutoCredits: 0,
    tax_rate: "",
  },
];
